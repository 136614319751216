import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../config/app';

// eslint-disable-next-line max-lines-per-function

import Input from '../../../../../components/Input';
import Dropdown from '../../../../../components/Dropdown';
import pageCheckoutDefined from '../../components/pageCheckoutDefined';

const ButtonBuy = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { isShow } = props;

    const [btnConfig, setBtnConfig] = useState(pageCheckoutDefined.BTN_BUY_DEFAULT);

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'}`}>
            <div>
                <label className='ladiui label-16'>Tiêu đề nút</label>
                <div className='w-100-p mt-16'>
                    <label className='ladiui text-14'>Tên tiêu đề</label>
                    <Input className='mt-8' value={btnConfig.title} name='title' placeholder='Vd: Mua ngay' />
                </div>
            </div>
            <div className='btn-config-item'>
                <label className='ladiui label-16'>Kiểu chữ</label>
                <div className='display-flex-center gap-20 mt-16'>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Font chữ</label>
                        <Dropdown wrapperClassName='mt-8 w-100-p' placeHolder='Chọn font chữ' />
                    </div>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Cỡ chữ</label>
                        <Input className='mt-8 w-100-p' value={btnConfig.fontSize} name='fontSize' placeHolder='Nhập Cỡ chữ' />
                    </div>
                </div>
            </div>

            <div className='btn-config-item'>
                <label className='ladiui label-16'>Màu sắc</label>
                <div className='display-flex-center gap-20 mt-16'>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Nền nút</label>
                        <Dropdown wrapperClassName='mt-8 w-100-p' placeHolder='Chọn font chữ' />
                    </div>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Màu chữ</label>
                        <Dropdown wrapperClassName='mt-8 w-100-p' placeHolder='Chọn font chữ' />
                        {/* <Input className='mt-8 w-100-p' value={btnConfig.fontSize} name='fontSize' placeHolder='Nhập Cỡ chữ' /> */}
                    </div>
                </div>
            </div>
            <div className='btn-config-item'>
                <label className='ladiui label-16'>Bo góc</label>
                <div className='display-flex-center gap-20 mt-16'>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Bo góc</label>
                        <Input className='mt-8 w-50-p' value={btnConfig.fontSize} name='fontSize' placeHolder='Nhập Cỡ chữ' />
                    </div>
                </div>
            </div>
            <div className='btn-config-item'>
                <label className='ladiui label-16'>Padding</label>
                <div className='display-flex-center gap-20 mt-16 '>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Trên</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.padding.top}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Phải</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className=' ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.padding.right}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='display-flex-center gap-20 mt-16'>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Dưới</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.padding.bottom}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Trái</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.padding.left}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='btn-config-item'>
                <label className='ladiui label-16'>Margin</label>
                <div className='display-flex-center gap-20 mt-16 '>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Trên</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.margin.top}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Phải</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className=' ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.margin.right}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='display-flex-center gap-20 mt-16'>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Dưới</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.margin.bottom}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>Trái</label>
                        <div className='display-flex-center btn-tang-giam mt-8'>
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-remove' />
                            </div>
                            <Input
                                className='mt-8 w-100-p ladiui text-center'
                                value={btnConfig.margin.left}
                                name='fontSize'
                                placeHolder='Nhập Cỡ chữ'
                            />
                            <div className='btn-icon'>
                                <i className='ladiui icon-new ldicon-add' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ButtonBuy;
