import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import appConfig from '../../../config/app';

import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';

import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';

import producer from 'immer';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import { compact, map, find, debounce, findIndex, isEmpty } from 'lodash';
import Input from '../../../components/Input';
import baseHelper from '../../../helpers/BaseHelper';
import SelectOption from '../../../components/SelectOption';
import LoadingScene from '../../../components/LoadingScene';

import PanelProductSeoSocial from '../../../components/PanelProductSeoSocial';
import ValidatorHelper from '../../../helpers/ValidatorHelper';

import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';
import * as checkoutConfigTypes from '../../../redux/futures/checkout_config/types';

import Dropdown from '../../../components/Dropdown';

import Frame from './Frame';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Popover from '../../../components/Popover';
import { SketchPicker } from 'react-color';

import FormCart from './FormCart';
import FormCartEmpty from './FormCartEmpty';
import FormPaymentPage from './FormPaymentPage';
import ModalAddImage from '../../../components/ModalAddImage';
import ModalAddText from '../../../components/ModalAddText';
import ModalInfoApiKey from '../../../components/ModalInfoApiKey';

const LIST_LOCATION = [
    {
        code: 'left',
        name: 'Dưới cùng bên trái',
    },
    {
        code: 'right',
        name: 'Dưới cùng bên phải',
    },
];

const LIST_PLATFORM = [
    {
        code: 'Ladipage',
        name: 'Ladipage',
    },
    {
        code: 'WordPress',
        name: 'WordPress',
    },
    {
        code: 'Other',
        name: 'Khác',
    },
];

const timeRedirectPath = [
    {
        value: 5,
        name: '5 giây',
    },
    {
        value: 10,
        name: '10 giây',
    },
    {
        value: 15,
        name: '15 giây',
    },
    {
        value: 20,
        name: '20 giây',
    },
    {
        value: 0,
        name: 'Chuyển trực tiếp',
    },
];

const CDN = 'https://w.ladicdn.com/';

export default function PageCheckout(props) {
    // const { isReloadIframe } = props;
    let { pageCheckoutID } = useParams();

    const [isReloadIframe, setReloadIframe] = useState(false);

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);

    const fileReducer = useSelector((state) => state.file);
    const [interfaceOptions, setInterfaceOptions] = useState([]);

    const [currentPageCheckout, setCurrentPageCheckout] = useState({});

    const [htmlWidget, setHtmlWidget] = useState(FormCart.renderData());
    const [isShowPreviewButton, setIsShowPreviewButton] = useState(false);
    const [isShowPreviewButtonAddToCart, setIsShowPreviewButtonAddToCart] = useState(false);

    const [isShowModalInfoApiKey, setIsShowModalInfoApiKey] = useState(false);

    const [pageCheckout, setPageCheckout] = useState({
        page_checkout_id: '',
        name: '',
        total_product: '',
        checkout_config_id: '',
        product_ids: [],
        payment_redirect_url: '',
        payment_redirect_after: appConfig.DEFAULT_PAYMENT_REDIRECT_AFTER,
        domain: '',
        path: '',
        url_published: '',
        tag_ids: [],
        is_home: 0,
        types: appConfig.PAGE_CHECKOUT.EMBED.CODE,
        seo: {},
        is_sync_product: 0,
        api_key: '',
    });

    const [widgetConfig, setWidgetConfig] = useState({
        screen_code: 'CART',
        screen_name: '',
        widget_position: 'right',
        position_absolute: {
            bottom: 24,
            side: 24,
        },
        main_color: '#30A16F',
        text_color: '#FFF',
        btn_cart_icon: '',
        logo: 'ladiui/ladisales/icons/logo-ladisales.svg',
        background_image: '',
        platform: 'LadiPage',
    });

    const [btnConfig, setBtnConfig] = useState({
        btn_content: 'Mua ngay',
        background_color: '#30A16F',
        color: '#FFF',
        btn_border_radius: 2,
        font_size: 14,
        font_family: '"Roboto", sans-serif',
        btn_padding: '6px 12px',
        btn_margin: '14px 0px 0px 0px',
    });

    const [btnConfigAddToCart, setBtnConfigAddToCart] = useState({
        btn_content: 'Mua ngay',
        background_color: '#1C00C2',
        color: '#FFF',
        btn_border_radius: 2,
        font_size: 14,
        font_family: '"Roboto", sans-serif',
        btn_padding: '6px 12px',
        btn_margin: '14px 0px 0px 0px',
    });

    const [isShowApiKey, setIsShowApiKey] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState({});
    const [isShowModalAddImage, setIsShowModalAddImage] = useState(false);
    const [isShowModalAddText, setIsShowModalAddText] = useState(false);

    const [dataSelect, setDataSelect] = useState({});
    const [indexData, setIndexData] = useState(-1);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isShowBlockAddContent, setIsShowBlockAddContent] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const inputsRef = new Set();
    const panelProductCheckoutRef = React.createRef();
    const productSeoSocialRef = React.createRef();

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    });

    useEffect(() => {
        dispatch(checkoutConfigActions.list());
    }, []);

    useEffect(() => {
        if (interfaceOptions) {
            let data = {
                locations: interfaceOptions,
            };
            let content = '';
            switch (widgetConfig.screen_code) {
                case 'CART':
                    content = FormCart.renderData(data);
                    break;
                case 'CART_EMPTY':
                    content = FormCartEmpty.renderData(data);
                    break;
                case 'PAYMENT_PAGE':
                    content = FormPaymentPage.renderData({ logo_image: widgetConfig.logo });
                    break;
                default:
            }

            setHtmlWidget(content);
        }
    }, [interfaceOptions]);

    useEffect(() => {
        if (checkoutConfigReducer.action == checkoutConfigTypes.LIST_CHECKOUT_CONFIG) {
            if (checkoutConfigReducer.status) {
                if (!pageCheckout.checkout_config_id) {
                    const defaultCheckoutConfig = find(
                        checkoutConfigReducer.checkout_configs,
                        (item) => item.is_default == appConfig.STATUS.ACTIVE
                    );
                    if (defaultCheckoutConfig) {
                        setPageCheckout({
                            ...pageCheckout,
                            checkout_config_id: defaultCheckoutConfig.checkout_config_id,
                        });
                    }
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', checkoutConfigReducer.message, 'OK');
            }
        }
    }, [checkoutConfigReducer]);

    useEffect(() => {
        if (pageCheckoutID) {
            dispatch(pageCheckoutActions.show(pageCheckoutID));
        }
    }, [pageCheckoutID]);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
            setReloadIframe(!isReloadIframe);
            if (pageCheckoutReducer.status) {
                // list();
                dispatch(pageCheckoutActions.reload(currentPageCheckout.page_checkout_id));
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);

                // setIsShowModalInfoApiKey(true);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }

        if (pageCheckoutReducer.action == pageCheckoutTypes.SHOW_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                if (
                    pageCheckoutReducer.page_checkout.interface_options &&
                    typeof pageCheckoutReducer.page_checkout.interface_options == 'string'
                ) {
                    pageCheckoutReducer.page_checkout.interface_options = JSON.parse(pageCheckoutReducer.page_checkout.interface_options);
                } else {
                    pageCheckoutReducer.page_checkout.interface_options = [];
                }

                if (pageCheckoutReducer.page_checkout.widget_config && typeof pageCheckoutReducer.page_checkout.widget_config == 'string') {
                    pageCheckoutReducer.page_checkout.widget_config = JSON.parse(pageCheckoutReducer.page_checkout.widget_config);
                }

                setCurrentPageCheckout(pageCheckoutReducer.page_checkout);
                // setIsShowModalInfoApiKey(true);
                // setMode(appConfig.FORM_MODE.EDIT);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
        if (pageCheckoutReducer.action == pageCheckoutTypes.RELOAD_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                if (
                    pageCheckoutReducer.page_checkout.interface_options &&
                    typeof pageCheckoutReducer.page_checkout.interface_options == 'string'
                )
                    pageCheckoutReducer.page_checkout.interface_options = JSON.parse(pageCheckoutReducer.page_checkout.interface_options);
                else pageCheckoutReducer.page_checkout.interface_options = [];

                setCurrentPageCheckout(pageCheckoutReducer.page_checkout);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_CART_IMAGE) {
            if (fileReducer.status) {
                setWidgetConfig({
                    ...widgetConfig,
                    btn_cart_icon: fileReducer.cart_image_url,
                });
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_CART_LOGO) {
            if (fileReducer.status) {
                setWidgetConfig({
                    ...widgetConfig,
                    logo: fileReducer.cart_logo_url,
                });

                let content = '';
                if (widgetConfig.screen_code == 'PAYMENT_PAGE') {
                    content = FormPaymentPage.renderData({ logo_image: widgetConfig.logo });
                    setHtmlWidget(content);
                }
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_CART_BACK_GROUND) {
            if (fileReducer.status) {
                setWidgetConfig({
                    ...widgetConfig,
                    background_image: fileReducer.background_image_url,
                });
            }
        }
    }, [fileReducer]);

    useEffect(() => {
        document.getElementById('trigger-tab-config').click();

        init();
    }, []);

    useEffect(() => {
        init();
        if (currentPageCheckout.interface_options) {
            let tg = currentPageCheckout.interface_options;

            if (typeof currentPageCheckout.interface_options === 'string') tg = JSON.parse(currentPageCheckout.interface_options);
            setInterfaceOptions(tg);
        }

        if (currentPageCheckout.widget_config) {
            let widget = currentPageCheckout.widget_config;
            if (typeof currentPageCheckout.widget_config === 'string') widget = JSON.parse(currentPageCheckout.widget_config);
            setWidgetConfig({
                ...widget,
                screen_code: 'CART',
                screen_name: 'Giỏ hàng của bạn',
            });

            setBtnConfig({
                ...btnConfig,
                ...widget.buy_now_config,
            });

            setBtnConfigAddToCart({
                ...btnConfigAddToCart,
                ...widget.add_to_cart_config,
            });

            let content = '';
            let data = {
                title: widget.title,
                locations: interfaceOptions,
            };
            switch (widgetConfig.screen_code) {
                case 'CART':
                    content = FormCart.renderData(data);
                    break;
                case 'CART_EMPTY':
                    content = FormCartEmpty.renderData(data);
                    break;
                case 'PAYMENT_PAGE':
                    content = FormPaymentPage.renderData({ logo_image: widgetConfig.logo });
                    break;
                default:
            }

            setHtmlWidget(content);
        }
    }, [currentPageCheckout]);

    const init = () => {
        if (currentPageCheckout) {
            setPageCheckout(
                producer(pageCheckout, (draft) => {
                    map(draft, (value, key) => {
                        draft[key] =
                            !baseHelper.isEmpty(currentPageCheckout[key]) && currentPageCheckout[key] ? currentPageCheckout[key] : '';
                        if (key == 'path' && baseHelper.isEmpty(currentPageCheckout[key]) && currentPageCheckout.page_checkout_id) {
                            draft[key] = '' + currentPageCheckout.page_checkout_id;
                        }
                        if (key == 'types' && baseHelper.isEmpty(currentPageCheckout[key])) {
                            draft[key] = appConfig.PAGE_CHECKOUT.PRODUCTS.CODE;
                        }

                        if (key == 'is_home' && (baseHelper.isEmpty(currentPageCheckout[key]) || currentPageCheckout[key] == 0)) {
                            draft[key] = 0;
                        }
                        if (key == 'seo' && baseHelper.isEmpty(currentPageCheckout[key])) {
                            draft[key] = {
                                title: '',
                                description: '',
                                keywords: '',
                                image: '',
                                canonical_url: '',
                            };
                        }
                    });
                })
            );
        }
    };

    const onChangeInput = (event) => {
        setPageCheckout(
            producer(pageCheckout, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangePaymentRedirectAfter = (paymentRedirectAfter) => {
        setPageCheckout(
            producer(pageCheckout, (draft) => {
                draft.payment_redirect_after = baseHelper.parsePositiveInt(paymentRedirectAfter);
            })
        );
    };

    const onChangeWidget = async (event) => {
        let { name, value } = event.target;

        await setWidgetConfig(
            producer(widgetConfig, (draft) => {
                draft[name] = value;
            })
        );

        if (name == 'title') {
            let data = {
                title: value,
                locations: interfaceOptions,
            };
            let content = '';
            switch (widgetConfig.screen_code) {
                case 'CART':
                    content = FormCart.renderData(data);
                    break;
                case 'CART_EMPTY':
                    content = FormCartEmpty.renderData(data);
                    break;
                case 'PAYMENT_PAGE':
                    content = FormPaymentPage.renderData({ logo_image: widgetConfig.logo });
                    break;
                default:
            }

            setHtmlWidget(content);
        }
    };

    const onChangeConfigBtn = async (event) => {
        let { name, value } = event.target;

        await setBtnConfig(
            producer(btnConfig, (draft) => {
                draft[name] = value;
            })
        );
    };

    const onChangeConfigBtnAddToCart = async (event) => {
        let { name, value } = event.target;

        await setBtnConfigAddToCart(
            producer(btnConfigAddToCart, (draft) => {
                draft[name] = value;
            })
        );
    };

    const submit = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let productSeoSocial = productSeoSocialRef.current.getData();

        if (productSeoSocial) {
            if (productSeoSocial.title && productSeoSocial.title.length > 70) {
                window.LadiUI.toastCustom('warning', '', 'Tiêu đề vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.description && productSeoSocial.description.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Mô tả vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Từ khóa vượt quá số ký tự quy định!');
                return;
            }
        }

        if (pageCheckout.types == appConfig.PAGE_CHECKOUT.PRODUCTS.CODE) {
            if (pageCheckout.product_ids.length <= 0) {
                window.LadiUI.toastCustom('danger', '', t('PAGE_CHECKOUT.REQUIRED_PRODUCT'));
                return;
            }
        }

        if (pageCheckout.types == appConfig.PAGE_CHECKOUT.TAGS.CODE) {
            if (pageCheckout.tag_ids.length <= 0) {
                window.LadiUI.toastCustom('danger', '', t('PAGE_CHECKOUT.REQUIRED_PRODUCT_TAG'));
                return;
            }
        }

        const payloadCheckout = {
            ...pageCheckout,
            seo: { ...productSeoSocial },
        };

        const payloadWidget = {
            main_color: widgetConfig.main_color,
            text_color: widgetConfig.text_color,
            widget_position: widgetConfig.widget_position,
            btn_cart_icon: widgetConfig.btn_cart_icon,
            logo: widgetConfig.logo,
            background_image: widgetConfig.background_image,
            position_absolute: widgetConfig.position_absolute,
            class_btn_add_cart: widgetConfig.class_btn_add_cart,
            class_btn_open_cart: widgetConfig.class_btn_open_cart,
            class_btn_open_checkout: widgetConfig.class_btn_open_checkout,
            platform: widgetConfig.platform,
            buy_now_config: btnConfig,
            add_to_cart_config: btnConfigAddToCart,
        };

        let dataSend = {
            page_checkout: { ...payloadCheckout, interface_options: JSON.stringify(interfaceOptions), widget_config: payloadWidget },
        };

        dispatch(pageCheckoutActions.update(dataSend));
    };

    const ListBackgroundBtn = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${btnConfig.background_color == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setBackgroundBtn(value)}
                >
                    {btnConfig.background_color == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const ListBackgroundBtnAddToCart = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${btnConfigAddToCart.background_color == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setBackgroundBtnAddToCart(value)}
                >
                    {btnConfigAddToCart.background_color == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const handleShowPicker = () => {
        // setIsShowPicker(!isShowPicker);
        document.getElementById('picker-id-sdk').click();
    };

    const handleShowPickerBtnAddToCart = () => {
        // setIsShowPicker(!isShowPicker);
        document.getElementById('picker-id-sdk-add-to-cart').click();
    };

    const handleChangeCompletePicker = (color) => {
        setBtnConfig({
            ...btnConfig,
            background_color: color.hex,
        });
    };

    const handleChangePickerBtnAddToCart = (color) => {
        setBtnConfigAddToCart({
            ...btnConfigAddToCart,
            background_color: color.hex,
        });
    };

    const setBackgroundBtn = (color) => {
        setBtnConfig({
            ...btnConfig,
            background_color: color,
        });
    };

    const setBackgroundBtnAddToCart = (color) => {
        setBtnConfigAddToCart({
            ...btnConfigAddToCart,
            background_color: color,
        });
    };

    const selectScreen = (widget) => {
        setWidgetConfig({
            ...widgetConfig,
            screen_code: widget.code,
            screen_name: widget.name,
        });

        let content = '';
        switch (widget.code) {
            case 'CART':
                content = FormCart.renderData({ locations: interfaceOptions });
                break;
            case 'CART_EMPTY':
                content = FormCartEmpty.renderData({ locations: interfaceOptions });
                break;
            case 'PAYMENT_PAGE':
                content = FormPaymentPage.renderData({ logo_image: widgetConfig.logo });
                break;
            case 'BUTTON_BUY_NOW':
                content = '';
                setIsShowPreviewButton(true);
                setIsShowPreviewButtonAddToCart(false);
                break;
            case 'BUTTON_ADD_TO_CART':
                content = '';
                setIsShowPreviewButton(false);
                setIsShowPreviewButtonAddToCart(true);
                break;
            default:
        }

        setHtmlWidget(content);
    };

    const selectLocation = (selected) => {
        setWidgetConfig({
            ...widgetConfig,
            widget_position: selected.code,
        });
    };

    const selectPlatform = (selected) => {
        setWidgetConfig({
            ...widgetConfig,
            platform: selected.code,
        });
    };

    const setColorPicker = (color) => {
        setWidgetConfig({
            ...widgetConfig,
            main_color: color,
        });
    };

    const renderColorPickerItem = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${widgetConfig.main_color == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setColorPicker(value)}
                >
                    {widgetConfig.main_color == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const fileChangedHandlerCartImage = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadCartImage(form));
            }
        }
    };

    const fileChangedHandlerCartLogo = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadCartLogo(form));
            }
        }
    };

    const fileChangedHandlerCartBackground = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadCartBackground(form));
            }
        }
    };

    const removeBackgroundImage = () => {
        setWidgetConfig({
            ...widgetConfig,
            background_image: '',
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        const options = [...interfaceOptions];

        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(options[result.destination.droppableId].datas, result.source.index, result.destination.index);

        options[result.destination.droppableId].datas = items;

        setInterfaceOptions(options);
    };

    const handleDeleteItem = (index, index2) => {
        let location = [...interfaceOptions];
        location[index].datas.splice(index2, 1);
        setInterfaceOptions(location);
    };

    const handleEditItem = (item, index, indexD) => {
        setDataSelect(item);
        setSelectedIndex(index);
        setIndexData(indexD);

        if (item.type == 'IMAGE') {
            setIsShowModalAddImage(true);
        } else {
            setIsShowModalAddText(true);
        }
    };

    const handleShowBlockAddContent = (value, index) => {
        setIsShowBlockAddContent(!isShowBlockAddContent);
        setSelectedValue(value);
        setSelectedIndex(index);
    };

    const handleShowModalImage = () => {
        setIndexData(-1);
        setDataSelect({});
        setIsShowModalAddImage(true);
        setIsShowBlockAddContent(false);
    };

    const handleShowModalAddText = () => {
        setIndexData(-1);
        setDataSelect({});
        setIsShowModalAddText(true);
        setIsShowBlockAddContent(false);
    };

    const handleDeleteLocation = (value) => {
        let location = interfaceOptions.filter((item) => item.value != value);

        setInterfaceOptions(location);
    };

    const handleChangeLocation = (item) => {
        if (item) {
            setSelectedLocation(item);
        }
    };

    const handleAddLocationCheckout = () => {
        if (isEmpty(selectedLocation)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng chọn vị trí muốn thêm');
            return;
        }

        let locations = [...interfaceOptions];

        let findIndex = locations.findIndex((item) => item.value == selectedLocation.value);

        if (findIndex != -1) {
            window.LadiUI.toastCustom('warning', '', 'Vị trí đã tồn tại!');
            return;
        }

        locations.push({
            name: selectedLocation.name,
            value: selectedLocation.value,
            datas: [],
        });

        setInterfaceOptions(locations);
    };

    const onCancelModalAddImage = () => {
        setIsShowModalAddImage(false);
    };

    const onSubmitModalAddImage = (value, index) => {
        if (isEmpty(value)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập nội dung');
            return;
        }
        if (!ValidatorHelper.isValidHttpUrl(value.url)) {
            window.LadiUI.toastCustom('warning', '', 'Đường dẫn không đúng định dạng');
            return;
        }

        let checkoutLocationTg = [...interfaceOptions];
        let data = [...checkoutLocationTg[selectedIndex].datas];

        if (index < 0) data.push(value);
        else data[index] = value;

        checkoutLocationTg[selectedIndex].datas = data;

        setInterfaceOptions(checkoutLocationTg);
        setIndexData(-1);
        setIsShowModalAddImage(false);
        setIsShowBlockAddContent(false);
    };

    const onCancelModalAddText = () => {
        setIsShowModalAddText(false);
    };

    const onCancelModalInfoApi = () => {
        setIsShowModalInfoApiKey(false);
    };

    const onSubmitModalAddText = (value, index) => {
        if (isEmpty(value)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập nội dung');
            return;
        }
        let checkoutLocationTg = [...interfaceOptions];
        let data = checkoutLocationTg[selectedIndex].datas;

        if (index < 0) data.push(value);
        else data[index] = value;

        setInterfaceOptions(checkoutLocationTg);
        setIndexData(-1);

        setIsShowModalAddText(false);
        setIsShowBlockAddContent(false);
    };

    const handelChangeConfig = (checkoutConfigID) => {
        setPageCheckout(
            producer(pageCheckout, (draft) => {
                draft.checkout_config_id = checkoutConfigID;
            })
        );
    };

    const onClickCopyApiKey = (api_key) => {
        navigator.clipboard.writeText(api_key);
        window.LadiUI.toastCustom('success', '', 'Đã copy vào clipboard!');
    };

    const selectFontFamilyBtnConfig = (selected) => {
        setBtnConfig({
            ...btnConfig,
            font_family: selected.value,
        });
    };

    const selectFontFamilyBtnConfigAddToCart = (selected) => {
        setBtnConfigAddToCart({
            ...btnConfigAddToCart,
            font_family: selected.value,
        });
    };

    const removeAvatarStore = () => {
        setWidgetConfig({
            ...widgetConfig,
            btn_cart_icon: '',
        });
    };

    const removeCartLogo = () => {
        setWidgetConfig({
            ...widgetConfig,
            logo: '',
        });
    };

    const loadingSubmit =
        pageCheckoutReducer.loading &&
        [pageCheckoutTypes.CREATE_PAGE_CHECKOUT, pageCheckoutTypes.UPDATE_PAGE_CHECKOUT].includes(pageCheckoutReducer.waiting);

    const widgetStyle = {
        header_color: widgetConfig.main_color,
        text_color: widgetConfig.text_color,
        background_image: widgetConfig.background_image ? `${CDN}${widgetConfig.background_image}` : '',
        widget_position: widgetConfig.widget_position,
        bottom_distance: widgetConfig.position_absolute ? widgetConfig.position_absolute.bottom : '',
        side_distance: widgetConfig.position_absolute ? widgetConfig.position_absolute.side : '',
    };

    let widgetPositionClass = widgetConfig.widget_position == 'left' ? 'wg-bottom-left' : '';

    const styleConfigButton = {
        background: btnConfig.background_color,
        padding: btnConfig.btn_padding,
        margin: btnConfig.btn_margin,
        fontSize: btnConfig.font_size + 'px',
        borderRadius: parseInt(btnConfig.btn_border_radius),
        color: btnConfig.color,
        fontFamily: btnConfig.font_family,
        cursor: 'pointer',
    };

    const styleConfigButtonAddToCart = {
        background: btnConfigAddToCart.background_color,
        padding: btnConfigAddToCart.btn_padding,
        margin: btnConfigAddToCart.btn_margin,
        fontSize: btnConfigAddToCart.font_size + 'px',
        borderRadius: parseInt(btnConfigAddToCart.btn_border_radius),
        color: btnConfigAddToCart.color,
        fontFamily: btnConfigAddToCart.font_family,
        cursor: 'pointer',
    };

    return (
        <div id='modal-page-checkout' className='page-content' style={{ overflow: 'unset', position: 'relative' }}>
            {loadingSubmit && <LoadingScene blur={true} />}
            <div className='ladiui content-main-title flex-row mb-20'>
                <div className='ladiui flex-row'>
                    <div className='ladiui main-title'>{t('PAGE_CHECKOUT.UPDATE')}</div>
                </div>
                <div style={{ display: 'block' }} className='flex'>
                    <button onClick={(e) => props.history.push('/page-checkout')} className='ladiui btn btn-secondary mr-24 '>
                        {t('ACTIONS.REMOVE')}
                    </button>

                    <button
                        type='button'
                        // className={`ladiui btn btn-primary btn-lg btn-open-modal ${loadingSubmit ? "loader" : ""}`}
                        className={`ladiui btn btn-primary btn-lg btn-open-modal`}
                        onClick={submit}
                    >
                        <div>Lưu</div>
                    </button>
                </div>
            </div>
            <ul className='ladiui nav nav-tabs' id='myTab' role='tablist' style={{ marginBottom: '24px' }}>
                <li className='ladiui nav-item' role='presentation'>
                    <span
                        className='ladiui tab-link active'
                        id='trigger-tab-config'
                        data-toggle='tab'
                        data-parent='myTab'
                        data-target='tab-checkout-config'
                    >
                        {t('PAGE_CHECKOUT.TAB_CHECKOUT_CONFIG')}
                    </span>
                </li>
                <li className='ladiui nav-item' role='presentation'>
                    <span
                        className='ladiui tab-link active'
                        id='trigger-tab-sdk'
                        data-toggle='tab'
                        data-parent='myTab'
                        data-target='tab-sdk'
                    >
                        Cấu hình hiển thị
                    </span>
                </li>
                <li className='ladiui nav-item' role='presentation'>
                    <span
                        className='ladiui tab-link active'
                        id='trigger-tab-seo-social'
                        data-toggle='tab'
                        data-parent='myTab'
                        data-target='tab-seo-social'
                    >
                        Seo & Social
                    </span>
                </li>
            </ul>

            <div className='ladiui tab-content tab-config-sdk' id='myTabContent'>
                <div className='ladiui tab-pane fade show open' id='tab-sdk' role='tabpanel' aria-labelledby='trigger-tab-sdk'>
                    <div className='sdk-page'>
                        <div className='setting-sdk'>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>Platform</div>
                                    <div className='content'>
                                        <Dropdown
                                            id='platform'
                                            data={LIST_PLATFORM}
                                            onSelectItem={selectPlatform}
                                            currentKey={widgetConfig.platform}
                                            _key={'code'}
                                            _value={'name'}
                                            ref={(ref) => inputsRef.add(ref)}
                                            validations={false}
                                            placeHolder={t('Chọn platform')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>Màu sắc chủ đạo</div>
                                    <div className='content'>
                                        <div className='color-picker'>{renderColorPickerItem()}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>Màu sắc chữ</div>
                                    <div className='content'>
                                        <div className='widget-position'>
                                            <span
                                                className='color-item select-text-color white'
                                                onClick={() =>
                                                    setWidgetConfig({
                                                        ...widgetConfig,
                                                        text_color: '#FFF',
                                                    })
                                                }
                                            >
                                                {widgetConfig.text_color == '#FFF' && <i className='ladiui icon icon-checked'></i>}
                                            </span>
                                            <span
                                                className='color-item select-text-color black'
                                                onClick={() =>
                                                    setWidgetConfig({
                                                        ...widgetConfig,
                                                        text_color: '#000',
                                                    })
                                                }
                                            >
                                                {widgetConfig.text_color == '#000' && <i className='ladiui icon icon-checked'></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>Vị trí hiển thị</div>
                                    <div className='content'>
                                        <Dropdown
                                            id='screen'
                                            data={LIST_LOCATION}
                                            onSelectItem={selectLocation}
                                            currentKey={widgetConfig.widget_position}
                                            _key={'code'}
                                            _value={'name'}
                                            validationName={t('COMMON.STATE_OR_CITY')}
                                            ref={(ref) => inputsRef.add(ref)}
                                            validations={false}
                                            placeHolder={t('Chọn vị trí hiển thị')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'></div>
                                    <div className='content flex'>
                                        <div className='ladiui form-group mr-16'>
                                            <label className='ladiui-label'>Khoảng cách dưới (px)</label>
                                            <Input
                                                name='bottom_value'
                                                validationName={'Vui lòng nhập vào khoảng cách'}
                                                placeholder={'Khoảng cách dưới'}
                                                value={widgetConfig.position_absolute.bottom}
                                                onChange={(e) =>
                                                    setWidgetConfig({
                                                        ...widgetConfig,
                                                        position_absolute: {
                                                            ...widgetConfig.position_absolute,
                                                            bottom: e.target.value,
                                                        },
                                                    })
                                                }
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                        <div className='ladiui form-group ml-16'>
                                            <label className='ladiui-label'>
                                                {widgetConfig.widget_position == 'left' ? 'Khoảng cách lề trái' : 'Khoảng cách lề phải'}{' '}
                                                (px)
                                            </label>
                                            <Input
                                                name='side_value'
                                                validationName={'Vui lòng nhập vào khoảng cách'}
                                                placeholder={'Khoảng cách dưới'}
                                                value={widgetConfig.position_absolute.side}
                                                onChange={(e) =>
                                                    setWidgetConfig({
                                                        ...widgetConfig,
                                                        position_absolute: {
                                                            ...widgetConfig.position_absolute,
                                                            side: e.target.value,
                                                        },
                                                    })
                                                }
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>
                                        Biểu tượng giỏ hàng
                                        <br />
                                        <div className='sub-description'>
                                            Kích thước 128x128 (px)
                                            <br />
                                            (Định dạng .png, .jpg, .svg)
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='preview-store-logo'>
                                            <div id='store-logo'>
                                                <img
                                                    className='store-logo'
                                                    src={
                                                        widgetConfig.btn_cart_icon
                                                            ? `${CDN}${widgetConfig.btn_cart_icon}`
                                                            : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                    }
                                                    alt=''
                                                />
                                                <div id='store-logo-overlay'>
                                                    <div className='ladiui btn-group'>
                                                        <div className='ladiui dropdown'>
                                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                                <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                            </button>
                                                            <ul
                                                                className='ladiui dropdown-menu custom-dropdown-menu'
                                                                style={{ minWidth: '100px', textAlign: 'left' }}
                                                            >
                                                                <li>
                                                                    <label htmlFor='upload-avatar-store'>Thay ảnh</label>
                                                                </li>
                                                                <li
                                                                    className='ladiui dropdown-item danger-text'
                                                                    onClick={() => removeAvatarStore()}
                                                                >
                                                                    Xóa
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                type='file'
                                                id='upload-avatar-store'
                                                name='file'
                                                accept='image/*'
                                                multiple={false}
                                                onChange={fileChangedHandlerCartImage}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>
                                        Logo:
                                        <br />
                                        <div className='sub-description'>
                                            Kích thước 128x128 (px)
                                            <br />
                                            (Định dạng .png, .jpg, .svg)
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='preview-store-logo'>
                                            <div id='store-logo'>
                                                <img
                                                    className='store-logo'
                                                    src={
                                                        widgetConfig.logo
                                                            ? `${CDN}${widgetConfig.logo}`
                                                            : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                    }
                                                    alt=''
                                                />
                                                <div id='store-logo-overlay'>
                                                    <div className='ladiui btn-group'>
                                                        <div className='ladiui dropdown'>
                                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                                <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                            </button>
                                                            <ul
                                                                className='ladiui dropdown-menu custom-dropdown-menu'
                                                                style={{ minWidth: '100px', textAlign: 'left' }}
                                                            >
                                                                <li className='ladiui open-modal'>
                                                                    <label htmlFor='upload-cart-logo'>Thay ảnh</label>
                                                                </li>
                                                                <li
                                                                    className='ladiui dropdown-item danger-text'
                                                                    onClick={() => removeCartLogo()}
                                                                >
                                                                    Xóa
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                type='file'
                                                id='upload-cart-logo'
                                                name='file'
                                                accept='image/*'
                                                multiple={false}
                                                onChange={fileChangedHandlerCartLogo}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>
                                        Ảnh nền:
                                        <br />
                                        <div className='sub-description'>
                                            Kích thước 760x300 (px)
                                            <br />
                                            (Định dạng .png, .jpg, .svg)
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='cart-bg-image back-ground'>
                                            <div id='upload-background-overlay'>
                                                <label htmlFor='upload-background-store'>
                                                    <a>
                                                        <i className='ladiui icon ldicon-upload'></i>
                                                    </a>
                                                </label>
                                                <a className='white-background' onClick={removeBackgroundImage}>
                                                    <i className='ladiui icon icon-ldp-close'></i>
                                                </a>
                                            </div>
                                            <img
                                                src={
                                                    widgetConfig.background_image
                                                        ? `${CDN}${widgetConfig.background_image}`
                                                        : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                }
                                            />
                                        </div>
                                        <div className='ladiui button upload text-center'>
                                            <input
                                                type='file'
                                                id='upload-background-store'
                                                name='file'
                                                accept='image/*'
                                                multiple={false}
                                                onChange={fileChangedHandlerCartBackground}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id='block-custom-interface' className='block-custom-interface' style={{ width: '100%', maxWidth: 900 }}>
                                <div className='label-header mb-24'>
                                    <h3>{t('PAGE_CHECKOUT.CUSTOM_INTERFACE')}</h3>
                                    <p>{t('PAGE_CHECKOUT.SELECT_LOCATION_AND_ADD_CONTENT')}</p>
                                </div>
                                <div className='block-content'>
                                    <div className='ladiui block-list-location'>
                                        {interfaceOptions &&
                                            interfaceOptions.length > 0 &&
                                            interfaceOptions.map((item, index) => {
                                                return (
                                                    <DragDropContext onDragEnd={onDragEnd} key={index}>
                                                        <Droppable droppableId={`${index}`} id={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    key={index}
                                                                    className='block-item mb-24'
                                                                    ref={provided.innerRef}
                                                                    style={{
                                                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                                    }}
                                                                >
                                                                    <div className='legend-header flex'>
                                                                        <span>{item.name}</span>
                                                                        <span className='tooltip-icon' data-tooltip-position='right'>
                                                                            {' '}
                                                                            <i className='ladi-icon icon-c-question'></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className='list-item'>
                                                                        {item.datas &&
                                                                            item.datas.length > 0 &&
                                                                            item.datas.map((_item, _index) => {
                                                                                return (
                                                                                    <Draggable
                                                                                        key={_index}
                                                                                        draggableId={'' + _index}
                                                                                        index={_index}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <>
                                                                                                {_item.type == 'IMAGE' ? (
                                                                                                    <div
                                                                                                        className='item flex'
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                    >
                                                                                                        <div className='icon-move mr-12'>
                                                                                                            <img
                                                                                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                                                                alt=' '
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='w100 flex space-between content'>
                                                                                                            <div className='flex'>
                                                                                                                <div className='item-img mr-12'>
                                                                                                                    <svg
                                                                                                                        width='18'
                                                                                                                        height='18'
                                                                                                                        viewBox='0 0 18 18'
                                                                                                                        fill='none'
                                                                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                                                                    >
                                                                                                                        <path
                                                                                                                            d='M15 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H15C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15V3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0ZM5 4C5.29667 4 5.58668 4.08797 5.83335 4.2528C6.08003 4.41762 6.27229 4.65189 6.38582 4.92597C6.49935 5.20006 6.52906 5.50166 6.47118 5.79264C6.4133 6.08361 6.27044 6.35088 6.06066 6.56066C5.85088 6.77044 5.58361 6.9133 5.29264 6.97118C5.00166 7.02906 4.70006 6.99935 4.42597 6.88582C4.15189 6.77229 3.91762 6.58003 3.7528 6.33335C3.58797 6.08668 3.5 5.79667 3.5 5.5C3.5 5.10218 3.65804 4.72064 3.93934 4.43934C4.22064 4.15804 4.60218 4 5 4ZM16 14.83C16.0221 15.1177 15.9292 15.4023 15.7418 15.6217C15.5543 15.841 15.2876 15.977 15 16H3L10.57 9.18C10.6971 9.06406 10.863 8.99978 11.035 8.99978C11.207 8.99978 11.3729 9.06406 11.5 9.18L16 13.66V14.83Z'
                                                                                                                            fill='#6D6D6D'
                                                                                                                        />
                                                                                                                    </svg>
                                                                                                                </div>
                                                                                                                <div className='item-text'>
                                                                                                                    {_item.name}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='action-item'>
                                                                                                                <span
                                                                                                                    className='cursor-pointer'
                                                                                                                    style={{
                                                                                                                        marginRight: '22px',
                                                                                                                    }}
                                                                                                                    onClick={() =>
                                                                                                                        handleDeleteItem(
                                                                                                                            index,
                                                                                                                            _index
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg'
                                                                                                                        alt=' '
                                                                                                                    />
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    className='cursor-pointer'
                                                                                                                    onClick={() =>
                                                                                                                        handleEditItem(
                                                                                                                            _item,
                                                                                                                            index,
                                                                                                                            _index
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                                                                                                        alt=' '
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div
                                                                                                        className='item flex'
                                                                                                        ref={provided.innerRef}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                    >
                                                                                                        <div className='icon-move mr-12'>
                                                                                                            <img
                                                                                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                                                                alt=' '
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className='w100 flex space-between content'>
                                                                                                            <div className='flex'>
                                                                                                                <div className='item-img mr-12'>
                                                                                                                    <svg
                                                                                                                        width='18'
                                                                                                                        height='18'
                                                                                                                        viewBox='0 0 18 18'
                                                                                                                        fill='none'
                                                                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                                                                    >
                                                                                                                        <path
                                                                                                                            d='M18 2.75C18 2.02065 17.7103 1.32118 17.1945 0.805456C16.6788 0.289731 15.9793 0 15.25 0H2.75C2.02065 0 1.32118 0.289731 0.805456 0.805456C0.289731 1.32118 0 2.02065 0 2.75V15.25C0 15.9793 0.289731 16.6788 0.805456 17.1945C1.32118 17.7103 2.02065 18 2.75 18H15.25C15.9793 18 16.6788 17.7103 17.1945 17.1945C17.7103 16.6788 18 15.9793 18 15.25V2.75ZM11.25 8.5C11.44 8.50006 11.6229 8.57224 11.7618 8.70197C11.9006 8.8317 11.9851 9.0093 11.998 9.19888C12.011 9.38846 11.9515 9.57589 11.8316 9.7233C11.7117 9.87071 11.5402 9.9671 11.352 9.993L11.25 10H3.75C3.55998 9.99994 3.37706 9.92776 3.23821 9.79803C3.09936 9.6683 3.01493 9.4907 3.00197 9.30112C2.98902 9.11154 3.04852 8.92411 3.16843 8.7767C3.28835 8.62929 3.45975 8.5329 3.648 8.507L3.75 8.5H11.25ZM3.75 12.5H14.25C14.44 12.5001 14.6229 12.5722 14.7618 12.702C14.9006 12.8317 14.9851 13.0093 14.998 13.1989C15.011 13.3885 14.9515 13.5759 14.8316 13.7233C14.7117 13.8707 14.5402 13.9671 14.352 13.993L14.25 14H3.75C3.55998 13.9999 3.37706 13.9278 3.23821 13.798C3.09936 13.6683 3.01493 13.4907 3.00197 13.3011C2.98902 13.1115 3.04852 12.9241 3.16843 12.7767C3.28835 12.6293 3.45975 12.5329 3.648 12.507L3.75 12.5ZM14.25 4.5C14.44 4.50006 14.6229 4.57224 14.7618 4.70197C14.9006 4.8317 14.9851 5.0093 14.998 5.19888C15.011 5.38846 14.9515 5.57589 14.8316 5.7233C14.7117 5.87071 14.5402 5.9671 14.352 5.993L14.25 6H3.75C3.55998 5.99994 3.37706 5.92776 3.23821 5.79803C3.09936 5.6683 3.01493 5.4907 3.00197 5.30112C2.98902 5.11154 3.04852 4.92411 3.16843 4.7767C3.28835 4.62929 3.45975 4.5329 3.648 4.507L3.75 4.5H14.25Z'
                                                                                                                            fill='#6D6D6D'
                                                                                                                        />
                                                                                                                    </svg>
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className='item-text'
                                                                                                                    // dangerouslySetInnerHTML={{
                                                                                                                    //   __html: _item.name,
                                                                                                                    // }}
                                                                                                                >
                                                                                                                    {_item.name}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='action-item'>
                                                                                                                <span
                                                                                                                    className='cursor-pointer'
                                                                                                                    style={{
                                                                                                                        marginRight: '22px',
                                                                                                                    }}
                                                                                                                    onClick={() =>
                                                                                                                        handleDeleteItem(
                                                                                                                            index,
                                                                                                                            _index
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg'
                                                                                                                        alt=' '
                                                                                                                    />
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    className='cursor-pointer'
                                                                                                                    onClick={() =>
                                                                                                                        handleEditItem(
                                                                                                                            _item,
                                                                                                                            index,
                                                                                                                            _index
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                                                                                                        alt=' '
                                                                                                                    />
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </Draggable>
                                                                                );
                                                                            })}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                    <div className='action-footer flex space-between'>
                                                                        <a
                                                                            className='flex'
                                                                            style={{ paddingLeft: '26px' }}
                                                                            onClick={() => handleShowBlockAddContent(item.value, index)}
                                                                        >
                                                                            <img
                                                                                className='mr-8'
                                                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'
                                                                                alt=''
                                                                            />
                                                                            <h3>{t('PAGE_CHECKOUT.ADD_LOCATION')}</h3>
                                                                        </a>
                                                                        <a
                                                                            style={{ color: '#D94F45' }}
                                                                            onClick={() => handleDeleteLocation(item.value)}
                                                                        >
                                                                            <h3>{t('PAGE_CHECKOUT.DELETE_LOCATION')}</h3>
                                                                        </a>
                                                                    </div>

                                                                    {isShowBlockAddContent && selectedValue == item.value && (
                                                                        <div className='choose-content'>
                                                                            <ul className='list-choose-item'>
                                                                                <li onClick={() => handleShowModalImage()}>
                                                                                    <div className='image'>
                                                                                        <img
                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-choose-image.svg'
                                                                                            alt=' '
                                                                                            style={{ marginRight: '4px' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='content'>
                                                                                        <h3>{t('PAGE_CHECKOUT.IMAGE')}</h3>
                                                                                        <span>
                                                                                            <h3>{t('PAGE_CHECKOUT.ADD_IMAGE')}</h3>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                                <li onClick={() => handleShowModalAddText()}>
                                                                                    <div className='image'>
                                                                                        <img
                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-content.svg'
                                                                                            alt=' '
                                                                                            style={{ marginRight: '4px' }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='content'>
                                                                                        <h3>{t('PAGE_CHECKOUT.CONTENT')}</h3>
                                                                                        <span>
                                                                                            <h3>{t('PAGE_CHECKOUT.ADD_CONTENT')}</h3>
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className='block-custom-checkout mt-16'>
                                    <div className='add-block'>
                                        <div className='ladiui btn-group mr-12'>
                                            <div className='ladiui dropdown w100'>
                                                <button
                                                    className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                                    style={{ minWidth: '100%' }}
                                                    id='dropdownMenuButton3'
                                                    data-toggle='dropdown'
                                                >
                                                    <i></i>
                                                    <span className='ladiui dropdown-text'>
                                                        <h3>{t('PAGE_CHECKOUT.SELECT_LOCATION')}</h3>
                                                    </span>
                                                </button>
                                                <ul className='ladiui dropdown-menu w100'>
                                                    {appConfig.LOCATIONS_CHECKIN.map((item, index) => {
                                                        let index2 = -1;
                                                        if (interfaceOptions)
                                                            index2 = interfaceOptions.findIndex((item2) => item2.value === item.value);
                                                        if (index2 > -1) return null;
                                                        return (
                                                            <li key={index} onClick={() => handleChangeLocation(item)}>
                                                                <a className='ladiui dropdown-item' href='#'>
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <button type='input' className='ladiui btn btn-primary' onClick={() => handleAddLocationCheckout()}>
                                            <h3>{t('PAGE_CHECKOUT.ADD_DISPLAY_LOCATION')}</h3>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='line-bar' style={{ borderBottom: '1px solid #eee', margin: '24px 0', width: 900 }}></div>
                            <div id='block-custom-btn-checkout' className='block-custom-btn-checkout'>
                                <h3 className='title mb-24'>Cài đặt nút mua ngay</h3>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Tiêu đề</div>
                                        <div className='content'>
                                            <Input
                                                name='btn_content'
                                                validationName={'nội dung nút'}
                                                placeholder={'Nhập vào nội dung nút'}
                                                value={btnConfig.btn_content}
                                                onChange={onChangeConfigBtn}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validations={{ isRequired: true }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Màu nút</div>
                                        <div className='content'>
                                            <div className='color-picker'>
                                                {ListBackgroundBtn()}
                                                {!appConfig.LIST_COLOR_PICKER.includes(btnConfig.background_color) && (
                                                    <div
                                                        className={`color-item checked`}
                                                        style={{ backgroundColor: btnConfig.background_color }}
                                                    >
                                                        <i className='ladiui icon icon-checked'></i>
                                                    </div>
                                                )}
                                                <div className='color-item btn-add-background' onClick={() => handleShowPicker()}>
                                                    +
                                                </div>
                                                <Popover
                                                    wrapperPopoverProps={{ className: 'popover-picker' }}
                                                    styleCustom={{ bottom: 0, right: 120, position: 'absolute' }}
                                                    content={
                                                        <SketchPicker
                                                            color={btnConfig.background_color}
                                                            onChangeComplete={(color) => handleChangeCompletePicker(color)}
                                                        />
                                                    }
                                                >
                                                    <div id={`picker-id-sdk`}> </div>
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Màu chữ</div>
                                        <div className='content'>
                                            <div className='widget-position'>
                                                <span
                                                    className='color-item select-text-color white'
                                                    onClick={() =>
                                                        setBtnConfig({
                                                            ...btnConfig,
                                                            color: '#FFF',
                                                        })
                                                    }
                                                >
                                                    {btnConfig.color == '#FFF' && <i className='ladiui icon icon-checked'></i>}
                                                </span>
                                                <span
                                                    className='color-item select-text-color black'
                                                    onClick={() =>
                                                        setBtnConfig({
                                                            ...btnConfig,
                                                            color: '#000',
                                                        })
                                                    }
                                                >
                                                    {btnConfig.color == '#000' && <i className='ladiui icon icon-checked'></i>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Kiểu chữ</div>
                                        <div className='content flex'>
                                            <div className='ladiui form-group m-0 mr-16 '>
                                                <label className='ladiui-label'>Phông chữ</label>
                                                <Dropdown
                                                    id='font_family'
                                                    data={appConfig.LIST_FONT_FAMILIES}
                                                    onSelectItem={selectFontFamilyBtnConfig}
                                                    currentValue={btnConfig.font_family}
                                                    _key={'value'}
                                                    _value={'name'}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={false}
                                                    placeHolder={t('Chọn font chữ')}
                                                />
                                            </div>
                                            <div className='ladiui form-group m-0 ml-16'>
                                                <label className='ladiui-label'>Cỡ chữ (px)</label>
                                                <Input
                                                    name='font_size'
                                                    validationName={'cỡ chữ'}
                                                    placeholder={'Nhập vào cỡ chữ'}
                                                    value={btnConfig.font_size}
                                                    onChange={onChangeConfigBtn}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>kiểu hiển thị</div>
                                        <div className='content flex'>
                                            <div className='ladiui form-group m-0 mr-16 '>
                                                <label className='ladiui-label'>Bo góc (px)</label>
                                                <Input
                                                    name='btn_border_radius'
                                                    validationName={'bo góc'}
                                                    placeholder={'Nhập vào kích thước bo góc'}
                                                    value={btnConfig.btn_border_radius}
                                                    onChange={onChangeConfigBtn}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                            <div className='ladiui form-group m-0 ml-16'>
                                                <label className='ladiui-label'>Padding</label>
                                                <Input
                                                    name='btn_padding'
                                                    validationName={'khoảng cách'}
                                                    placeholder={'Ví dụ: 10px 20px 10px 20px'}
                                                    value={btnConfig.btn_padding}
                                                    onChange={onChangeConfigBtn}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                            <div className='ladiui form-group m-0 ml-16'>
                                                <label className='ladiui-label'>Margin</label>
                                                <Input
                                                    name='btn_margin'
                                                    validationName={'khoảng cách'}
                                                    placeholder={'Ví dụ: 10px 20px 10px 20px'}
                                                    value={btnConfig.btn_margin}
                                                    onChange={onChangeConfigBtn}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='line-bar' style={{ borderBottom: '1px solid #eee', margin: '24px 0', width: 900 }}></div>
                            <div id='block-custom-btn-checkout' className='block-custom-btn-checkout'>
                                <h3 className='title mb-24'>Cài đặt nút thêm vào giỏ hàng</h3>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Tiêu đề</div>
                                        <div className='content'>
                                            <Input
                                                name='btn_content'
                                                validationName={'nội dung nút'}
                                                placeholder={'Nhập vào nội dung nút'}
                                                value={btnConfigAddToCart.btn_content}
                                                onChange={onChangeConfigBtnAddToCart}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validations={{ isRequired: true }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Màu nút</div>
                                        <div className='content'>
                                            <div className='color-picker'>
                                                {ListBackgroundBtnAddToCart()}
                                                {!appConfig.LIST_COLOR_PICKER.includes(btnConfigAddToCart.background_color) && (
                                                    <div
                                                        className={`color-item checked`}
                                                        style={{ backgroundColor: btnConfigAddToCart.background_color }}
                                                    >
                                                        <i className='ladiui icon icon-checked'></i>
                                                    </div>
                                                )}
                                                <div
                                                    className='color-item btn-add-background'
                                                    onClick={() => handleShowPickerBtnAddToCart()}
                                                >
                                                    +
                                                </div>
                                                <Popover
                                                    wrapperPopoverProps={{ className: 'popover-picker' }}
                                                    styleCustom={{ bottom: 0, right: 120, position: 'absolute' }}
                                                    content={
                                                        <SketchPicker
                                                            color={btnConfigAddToCart.background_color}
                                                            onChangeComplete={(color) => handleChangePickerBtnAddToCart(color)}
                                                        />
                                                    }
                                                >
                                                    <div id={`picker-id-sdk-add-to-cart`}> </div>
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Màu chữ</div>
                                        <div className='content'>
                                            <div className='widget-position'>
                                                <span
                                                    className='color-item select-text-color white'
                                                    onClick={() =>
                                                        setBtnConfigAddToCart({
                                                            ...btnConfigAddToCart,
                                                            color: '#FFF',
                                                        })
                                                    }
                                                >
                                                    {btnConfigAddToCart.color == '#FFF' && <i className='ladiui icon icon-checked'></i>}
                                                </span>
                                                <span
                                                    className='color-item select-text-color black'
                                                    onClick={() =>
                                                        setBtnConfigAddToCart({
                                                            ...btnConfigAddToCart,
                                                            color: '#000',
                                                        })
                                                    }
                                                >
                                                    {btnConfigAddToCart.color == '#000' && <i className='ladiui icon icon-checked'></i>}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Kiểu chữ</div>
                                        <div className='content flex'>
                                            <div className='ladiui form-group m-0 mr-16 '>
                                                <label className='ladiui-label'>Phông chữ</label>
                                                <Dropdown
                                                    id='font_family'
                                                    data={appConfig.LIST_FONT_FAMILIES}
                                                    onSelectItem={selectFontFamilyBtnConfigAddToCart}
                                                    currentValue={btnConfigAddToCart.font_family}
                                                    _key={'value'}
                                                    _value={'name'}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={false}
                                                    placeHolder={t('Chọn font chữ')}
                                                />
                                            </div>
                                            <div className='ladiui form-group m-0 ml-16'>
                                                <label className='ladiui-label'>Cỡ chữ (px)</label>
                                                <Input
                                                    name='font_size'
                                                    validationName={'cỡ chữ'}
                                                    placeholder={'Nhập vào cỡ chữ'}
                                                    value={btnConfigAddToCart.font_size}
                                                    onChange={onChangeConfigBtnAddToCart}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>kiểu hiển thị</div>
                                        <div className='content flex'>
                                            <div className='ladiui form-group m-0 mr-16 '>
                                                <label className='ladiui-label'>Bo góc (px)</label>
                                                <Input
                                                    name='btn_border_radius'
                                                    validationName={'bo góc'}
                                                    placeholder={'Nhập vào kích thước bo góc'}
                                                    value={btnConfigAddToCart.btn_border_radius}
                                                    onChange={onChangeConfigBtnAddToCart}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                            <div className='ladiui form-group m-0 ml-16'>
                                                <label className='ladiui-label'>Padding</label>
                                                <Input
                                                    name='btn_padding'
                                                    validationName={'khoảng cách'}
                                                    placeholder={'Ví dụ: 10px 20px 10px 20px'}
                                                    value={btnConfigAddToCart.btn_padding}
                                                    onChange={onChangeConfigBtnAddToCart}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                            <div className='ladiui form-group m-0 ml-16'>
                                                <label className='ladiui-label'>Margin</label>
                                                <Input
                                                    name='btn_margin'
                                                    validationName={'khoảng cách'}
                                                    placeholder={'Ví dụ: 10px 20px 10px 20px'}
                                                    value={btnConfigAddToCart.btn_margin}
                                                    onChange={onChangeConfigBtnAddToCart}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='line-bar' style={{ borderBottom: '1px solid #eee', margin: '24px 0', width: 900 }}></div>
                            <div className='block-custom-html-selector'>
                                <h3 className='title mb-24'>HTML Selector</h3>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Nút thêm giỏ hàng</div>
                                        <div className='content'>
                                            <Input
                                                name='class_btn_add_cart'
                                                validationName={'nội dung nút'}
                                                placeholder={
                                                    'Nhập vào class (không chứa khoảng trắng, ký tự đặc biệt, cách nhau bằng dấu phẩy). Ví dụ: class-1, class-2,...'
                                                }
                                                value={widgetConfig.class_btn_add_cart}
                                                onChange={onChangeWidget}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Nút mở giỏ hàng</div>
                                        <div className='content'>
                                            <Input
                                                name='class_btn_open_cart'
                                                validationName={'nội dung nút'}
                                                placeholder={
                                                    'Nhập vào class (không chứa khoảng trắng, ký tự đặc biệt, cách nhau bằng dấu phẩy). Ví dụ: class-1, class-2,...'
                                                }
                                                value={widgetConfig.class_btn_open_cart}
                                                onChange={onChangeWidget}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='panel'>
                                    <div className='panel-line'>
                                        <div className='main-description'>Nút mở trang thanh toán</div>
                                        <div className='content'>
                                            <Input
                                                name='class_btn_open_checkout'
                                                validationName={'nội dung nút'}
                                                placeholder={
                                                    'Nhập vào class (không chứa khoảng trắng, ký tự đặc biệt, cách nhau bằng dấu phẩy). Ví dụ: class-1, class-2,...'
                                                }
                                                value={widgetConfig.class_btn_open_checkout}
                                                onChange={onChangeWidget}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='preview-sdk'>
                            <div className='select-screen'>
                                <div className='title'>Màn hình hiển thị</div>
                                <div className='dropdown-screen w100'>
                                    <Dropdown
                                        id='screen'
                                        data={appConfig.SCREEN_PAGE_CHECKOUT_SDK}
                                        onSelectItem={selectScreen}
                                        currentKey={widgetConfig.screen_code}
                                        _key={'code'}
                                        _value={'name'}
                                        validationName={t('COMMON.STATE_OR_CITY')}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={false}
                                        placeHolder={t('Chọn giao diện')}
                                    />
                                </div>
                            </div>
                            <div className='screen'>
                                <div id='ladisales'>
                                    {isShowPreviewButton && (!htmlWidget || htmlWidget == '') ? (
                                        <div className='block-show-button'>
                                            <button style={styleConfigButton}>{btnConfig.btn_content}</button>
                                        </div>
                                    ) : isShowPreviewButtonAddToCart && (!htmlWidget || htmlWidget == '') ? (
                                        <div className='block-show-button'>
                                            <button style={styleConfigButtonAddToCart}>{btnConfigAddToCart.btn_content}</button>
                                        </div>
                                    ) : (
                                        <Frame
                                            content={
                                                `<div class="ladichat-widget ${widgetPositionClass}" id="ladichat-widget">` +
                                                htmlWidget +
                                                `</div>` +
                                                `<a id="ladichat-widget-open" class="fab">
                      <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g id="Protype" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <path d="M0.625,27.375 L6,22 L24.625,22 C25.375,22 26.0208333,21.75 26.5625,21.25 C27.1041667,20.75 27.375,20.125 27.375,19.375 L27.375,3.375 C27.375,2.625 27.1041667,1.97916667 26.5625,1.4375 C26.0208333,0.895833333 25.375,0.625 24.625,0.625 L3.375,0.625 C2.625,0.625 1.97916667,0.895833333 1.4375,1.4375 C0.895833333,1.97916667 0.625,2.625 0.625,3.375 L0.625,27.375 Z M22,8.625 L6,8.625 L6,6 L22,6 L22,8.625 Z M22,12.625 L6,12.625 L6,10 L22,10 L22,12.625 Z M16.625,16.625 L6,16.625 L6,14 L16.625,14 L16.625,16.625 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
                          </g>
                      </svg>
                  </a>`
                                            }
                                            stylesheets={[
                                                'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i%7CRoboto:300,300i,400,500,500i,700,700i&amp;display=swap',
                                            ]}
                                            isWellcomePage={false}
                                            data={widgetStyle}
                                        ></Frame>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ladiui tab-pane fade' id='tab-checkout-config' role='tabpanel' aria-labelledby='trigger-tab-config'>
                    <div className='setting-content-left' style={{ width: 900 }}>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ paddingBottom: 8 }}>
                                Tên trang thanh toán
                            </label>
                            <div className='content'>
                                <Input
                                    name='name'
                                    validationName={t('PAGE_CHECKOUT.NAME')}
                                    placeholder={t('PAGE_CHECKOUT.PLACE_HOLDER_NAME')}
                                    value={pageCheckout.name}
                                    onChange={onChangeInput}
                                    ref={(ref) => inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                        </div>
                        <div className='ladiui form-group'>
                            <div>
                                <label className='ladiui-label' style={{ paddingBottom: 8 }}>
                                    {t('PRODUCTS.LB_CHECKOUT_CONFIG')}
                                </label>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                    }}
                                >
                                    <SelectOption
                                        wrapperStyle={{
                                            minWidth: '250px',
                                            width: '100%',
                                            display: 'inline-block',
                                            zIndex: 999,
                                        }}
                                        innerStyle={{ width: '100%', minWidth: '100%' }}
                                        customStyle={{ padding: '0px', width: '100%' }}
                                        wrapperClassName='select-option-checkout-config mr-24'
                                        innerClassName='ellipsis'
                                        currentKey={pageCheckout.checkout_config_id}
                                        headerItems={[
                                            {
                                                key: 'create',
                                                name: t('CHECKOUTS.ADD_CHECKOUT_CONFIG'),
                                                icon: 'icon-c-add',
                                                className: 'prevent-hide',
                                                is_header: true,
                                            },
                                            {
                                                key: 'refresh',
                                                name: t('COMMON.REFRESH_LIST'),
                                                icon: 'icon-sync',
                                                className: 'prevent-hide',
                                                is_header: true,
                                            },
                                        ]}
                                        headerKey={'key'}
                                        headerValue={'name'}
                                        items={checkoutConfigReducer.checkout_configs || []}
                                        _key={'checkout_config_id'}
                                        _value={'name'}
                                        onSelectItem={(item) => {
                                            if (item.checkout_config_id) {
                                                handelChangeConfig(item.checkout_config_id);
                                            } else {
                                                switch (item.key) {
                                                    case 'create':
                                                        window.open('/settings?type=setting-checkout&is_new=true&tab=CONFIG_CHECKOUT');
                                                        break;
                                                    case 'refresh':
                                                        dispatch(checkoutConfigActions.list());
                                                        break;
                                                }
                                            }
                                        }}
                                    />
                                    <a
                                        className='ladiui btn btn-outline-primary'
                                        target='_blank'
                                        href={`/settings?type=setting-checkout&checkout_config_id=${pageCheckout.checkout_config_id}&tab=CONFIG_CHECKOUT`}
                                    >
                                        {t('PRODUCTS.LB_EDIT_CHECKOUT_CONFIG')}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui form-group'>
                            <React.Fragment>
                                <div className='publish-times' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className='publish-path'>
                                        <label className='ladiui-label'>{t('PRODUCTS.LB_REDIRECT_PATH')}</label>
                                        <div>
                                            <Input
                                                name='payment_redirect_url'
                                                placeholder={t('PRODUCTS.LB_REDIRECT_PATH')}
                                                value={pageCheckout.payment_redirect_url}
                                                onChange={onChangeInput}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                    <div className='publish-redirect-after'>
                                        <label className='ladiui-label'>{t('PRODUCTS.LB_REDIRECT_AFTER')}</label>
                                        <div className='ladiui btn-group'>
                                            <SelectOption
                                                wrapperClassName='select-assignee'
                                                innerClassName='ellipsis'
                                                currentKey={pageCheckout.payment_redirect_after}
                                                _key='value'
                                                _value='name'
                                                items={timeRedirectPath || []}
                                                onSelectItem={(item) => onChangePaymentRedirectAfter(item.value)}
                                                innerStyle={{ maxWidth: '170px' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>

                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ paddingBottom: 8 }}>
                                Api key
                            </label>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: '1px solid #F1F3F4',
                                    padding: '6px 12px',
                                }}
                            >
                                {isShowApiKey ? pageCheckout.api_key : baseHelper.maskText(pageCheckout.api_key, 1)}
                                <ul className='flex'>
                                    <li className='mr-8'>
                                        <a style={{ color: '#000' }} onClick={() => setIsShowApiKey(!isShowApiKey)}>
                                            <i className={isShowApiKey ? 'ladi-icon icon-preview' : 'ladi-icon icon-b-preview'}></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a style={{ color: '#000' }} onClick={() => onClickCopyApiKey(pageCheckout.api_key)}>
                                            <i className='ladi-icon icon-copy'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ paddingBottom: 8 }}>
                                Mã tích hợp
                            </label>
                            <div className='content'>
                                <div type='text' className='sdk-script-content'>
                                    {`<script src="https://w.ladicdn.com/ladisales/sdk/sdk.js" id="${pageCheckout.api_key}" async></script>`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='ladiui tab-pane fade' id='tab-seo-social' role='tabpanel' aria-labelledby='trigger-tab-seo-social'>
                    <PanelProductSeoSocial ref={productSeoSocialRef} productInfoSeo={pageCheckout.seo || {}} />
                </div>
                {isShowModalAddImage && (
                    <ModalAddImage
                        onCancel={onCancelModalAddImage}
                        onSubmit={onSubmitModalAddImage}
                        visible={isShowModalAddImage}
                        dataSelect={dataSelect}
                        indexData={indexData}
                    />
                )}

                {isShowModalAddText && (
                    <ModalAddText
                        onCancel={onCancelModalAddText}
                        onSubmit={onSubmitModalAddText}
                        visible={isShowModalAddText}
                        dataSelect={dataSelect}
                        indexData={indexData}
                    />
                )}

                {isShowModalInfoApiKey && (
                    <ModalInfoApiKey
                        onCancel={onCancelModalInfoApi}
                        // onSubmit={onSubmitModalAddText}
                        visible={isShowModalInfoApiKey}
                        infoApi={pageCheckout.api_key}
                        indexData={indexData}
                    />
                )}
            </div>
        </div>
    );
}
