import React from 'react';
import PropTypes from 'prop-types';
import { find, remove, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import Modal from './Modal';

type Props = {};

class ModalAllTag extends React.Component<Props> {
    static propTypes = {
        id: PropTypes.string,
        allTags: PropTypes.array,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedTags: [],
        }
    }

    onSelect = (event, name) => {
        const { classList } = event.target;
        const { selectedTags } = this.state;
        if (find(selectedTags, tag => tag == name)) {

            remove(selectedTags, tag => tag == name);
            classList.remove('disabled-tag');
        } else {
            selectedTags.push(name);
            classList.add('disabled-tag');
        }

        this.setState({
            selectedTags,
        });
    }

    removeTag = (name) => {
        const { selectedTags } = this.state;

        remove(selectedTags, tag => tag == name);
        window.LadiUI.forEach(window.LadiUI.querySelector('.modal-all-tag .all-tags .tag'), function (e) {
            if (e.getAttribute('name') == name) {
                e.classList.remove('disabled-tag');
            }
        })

        this.setState({
            selectedTags,
        });
    }

    submit = () => {
        this.props.onSubmit(this.state.selectedTags);
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                id="modal-all-tag"
                wrapperClassName="modal-all-tag"
                allTags={this.props.allTags}
                visible={this.props.visible}
                onCancel={() => {
                    setTimeout(() => {
                        this.props.onCancel()
                    }, 0)
                }}
                onOk={() => {
                    setTimeout(() => {
                        this.submit();
                    }, 0)
                }}
                title={t('COMMON.TAGS')}
            >
                <div >
                    <div className="ladiui form-group ladiui-tags">
                        <label className="ladiui-label">{t('TAGS.SELECTED_TAG')}</label>
                        <div className="ladiui-tag-list">
                            {
                                map(this.state.selectedTags, (item, index) => <span key={index} className="tag">{item}<span className="close" onClick={() => this.removeTag(item)} /></span>)
                            }
                        </div>
                    </div>
                    <div className="ladiui form-group ladiui-tags all-tags">
                        <label className="ladiui-label">{t('TAGS.ALL_TAG')}</label>
                        <div className="ladiui-tag-list">
                            {
                                map(this.props.allTags, (item, index) => <span name={item.name} onClick={(event) => this.onSelect(event, item.name)} key={index} className="tag">{item.name}</span>)
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation('translation', { withRef: true })(ModalAllTag);