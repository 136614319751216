import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Image from "../../../../../components/Image";
import { map } from "lodash";
import Modal from "../../../../../components/Modal";
import { connect } from "react-redux";
import fileActions from "../../../../../redux/futures/file/actions";
import baseHelper from "../../../../../helpers/BaseHelper";

class ImageVariant extends React.Component {
  static propTypes = {
    variant: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    submit: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: null,
    };
  }

  onCancel = () => {
    this.props.onCancel();
  };

  fileChangedHandler = (event) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const form = baseHelper.getFormDataUpload(files);
      if (form) {
        this.props.uploadProductImages(form);
      }
    }
  };

  submit = () => {
    const { selected } = this.state;
    if (!selected) {
      window.LadiUI.toastCustom("danger", "", this.props.t("PRODUCTS.MSG_PLEASE_SELECT_ATLEAST_IMAGE"));
      return;
    }

    this.props.submit(selected);
  };

  select = (selected, event) => {
    this.setState({
      selected,
    });

    // Remove other selected
    window.LadiUI.forEach(window.LadiUI.querySelector("#modal-image-variant .item"), function (e) {
      e.classList.remove("selected-image-variant");
    });

    event.currentTarget.classList.add("selected-image-variant");
  };

  render() {
    const { t } = this.props;

    const { images, variant } = this.props;
    const { isLoading } = this.props;

    return (
      <Modal
        id="modal-image-variant"
        visible={this.props.visible}
        title={t("PRODUCTS.LABEL_PICK_IMAGE")}
        onCancel={this.onCancel}
        zIndex={1050}
        bodyStyles={{ minHeight: 450 }}
        footer={
          <div>
            <button onClick={this.onCancel} className="ladiui btn btn-secondary mr-8 flex">
              <i className="ladi-icon icon-e-remove mr-4" /> {t("ACTIONS.CLOSE")}
            </button>
            <div className="ladiui-button-upload mr-8" style={{ marginTop: 0 }}>
              <button type="button" className="ladiui btn btn-secondary">
                <i className="ladi-icon icon-data-upload" /> {t("COMMON.CHOOSE_FILES")}
              </button>
              <input
                type="file"
                name="file"
                accept="image/*"
                multiple="multiple"
                onChange={this.fileChangedHandler}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
            </div>
            <button onClick={this.submit} className={`ladiui btn btn-primary ${isLoading ? "loader" : ""}`} disabled={isLoading}>
              {" "}
              {t("ACTIONS.SAVE")}
            </button>
          </div>
        }
      >
        <div className="image-variant">
          {map(images, (item, index) => {
            return (
              <div
                key={index}
                className={`item ${item.src == variant.src ? "selected-image-variant" : ""}`}
                onClick={(event) => this.select(item, event)}
              >
                <Image className="image" src={item.src} width={100} height={100} />
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    uploadProductImages: (form) => dispatch(fileActions.uploadProductImages(form)),
  };
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(ImageVariant)
);
