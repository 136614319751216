import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_FORM_CONFIG,
    meta: {
        endpoint: endpoint.LIST_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const deleteFormConfig = (formConfigID) => ({
    type: types.DELETE_FORM_CONFIG,
    meta: {
        endpoint: endpoint.DELETE_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            form_config_id: formConfigID
        },
        hasAuth: true,
    }
});

const deletesFormConfig = (formConfigIDs) => ({
    type: types.DELETES_FORM_CONFIG,
    meta: {
        endpoint: endpoint.DELETES_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            form_config_ids: formConfigIDs
        },
        hasAuth: true,
    }
});

const create = (formConfig) => ({
    type: types.CREATE_FORM_CONFIG,
    meta: {
        endpoint: endpoint.CREATE_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: formConfig,
        hasAuth: true,
    }
});

const update = (formConfig) => ({
    type: types.UPDATE_FORM_CONFIG,

    meta: {
        endpoint: endpoint.UPDATE_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: formConfig,
        hasAuth: true,
    }
});

const reload = (formConfigID) => ({
    type: types.RELOAD_FORM_CONFIG,
    meta: {
        endpoint: endpoint.SHOW_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            form_config_id: formConfigID,
        },
        hasAuth: true,
    }
});

const show = (formConfigID) => ({
    type: types.SHOW_FORM_CONFIG,
    meta: {
        endpoint: endpoint.SHOW_FORM_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            form_config_id: formConfigID,
        },
        hasAuth: true,
    }
});

const showAccountConfig = ({form_account_id: formAccountID, type}) => ({
    type: types.SHOW_ACCOUNT_CONFIG,
    meta: {
        endpoint: endpoint.SHOW_ACCOUNT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            form_account_id: formAccountID,
            type
        },
        hasAuth: true,
    }
});

const addAccountConfig = (accountConfig) => ({
    type: types.ADD_ACCOUNT_CONFIG,
    meta: {
        endpoint: endpoint.ADD_ACCOUNT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            account_config: accountConfig
        },
        hasAuth: true,
    }
});

const cancelAccountConfig = (id) => ({
    type: types.CANCEL_ACCOUNT_CONFIG,
    meta: {
        endpoint: endpoint.CANCEL_ACCOUNT_CONFIG,
        method: REQUEST_METHOD.POST,
        body: {
            form_account_id: id
        },
        hasAuth: true,
    }
});

export default {
    list,
    delete: deleteFormConfig,
    deletes: deletesFormConfig,
    create,
    update,
    reload,
    show,
    showAccountConfig,
    addAccountConfig,
    cancelAccountConfig
}