import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { map, cloneDeep, find, includes, findIndex } from 'lodash';
// import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function HistoryContentPanel(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data, created_at } = props;

    const [title, setTitle] = useState('');
    const [content, setContent] = useState([]);
    const [isShowItem, setShowItem] = useState(false);

    useEffect(() => {
        if (baseHelper.isJson(data)) {
            const { title, content } = JSON.parse(data);
            setTitle(title || '');
            setContent(content || []);
        } else {
            setTitle('');
            setContent([]);
        }
    }, [data]);

    if (!baseHelper.isJson(data)) {
        return <h4>{data}</h4>;
    } else
        return (
            <div className='expand'>
                <h4 className='title' onClick={() => setShowItem(!isShowItem)}>
                    {title}
                    <i className={`ladi-icon icon-triangle-right ${isShowItem ? 'show' : ''}`} />
                </h4>
                <div className={`item ${isShowItem ? 'show' : 'hide'}`}>
                    {map(content, (value, key) => {
                        return (
                            <div key={key}>
                                <div className='key'>{key}</div>
                                <div className='value'>{value}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
}

export default HistoryContentPanel;
