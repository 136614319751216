export const DELETE_VARIANT = 'DELETE_VARIANT';
export const CREATE_VARIANT = 'CREATE_VARIANT';
export const UPDATE_VARIANT = 'UPDATE_VARIANT';
export const CHANGE_IMAGE_VARIANT = 'CHANGE_IMAGE_VARIANT';
export const SHOW_VARIANT = 'SHOW_VARIANT';
export const SEARCH_VARIANT = 'SEARCH_VARIANT';
export const GET_VARIANT_INFOS = 'GET_VARIANT_INFOS';
export const RE_ORDER_VARIANT = 'RE_ORDER_VARIANT';
export const TICKET_CREATION_FORM = 'TICKET_CREATION_FORM';
export const PACKAGE_CREATE = 'PACKAGE_CREATE';
export const PACKAGE_UPDATE = 'PACKAGE_UPDATE';
export const PACKAGE_UPDATE_POSITION = 'PACKAGE_UPDATE_POSITION';
export const PACKAGE_DELETE = 'PACKAGE_DELETE';
