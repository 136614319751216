import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input';

import courseAction from '../../../redux/futures/course/actions';
import * as courseTypes from '../../../redux/futures/course/types';

import DropdownCheckboxSearch from '../../../components/DropdownCheckboxSearch';

import { map, compact, includes } from 'lodash';

import appConfig from '../../../config/app';
import Dropdown from '../../../components/Dropdown';

// eslint-disable-next-line max-lines-per-function
function ModalAddStudent(props) {
    const { t, onClose, isShow, innerStyle, productId } = props;
    const wrapperRef = useRef();
    const dispatch = useDispatch();

    const courseReducer = useSelector((state) => state.course);

    const inputsRef = new Set();

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [listCustomers, setListCustomers] = useState([]);
    const [listCustomerDropdown, setListCustomerDropdown] = useState([]);

    const [dataSelectedCustomer, setDataSelectedCustomer] = useState([]);

    let [dataStudent, setDataStudent] = useState({
        student_type: 1,
        full_name: '',
        email: '',
        phone: '',
    });

    useEffect(() => {
        let payload = {
            selected_customer_ids:
                props.selectedStudents && props.selectedStudents.length > 0 ? props.selectedStudents.map((item) => item.customer_id) : [],
            search: {
                product_id: productId,
            },
            ...conditions,
        };
        dispatch(courseAction.courseCustomerListModal(payload));
    }, []);

    useEffect(() => {
        if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST_MODAL) {
            if (courseReducer.status) {
                let _data = [];
                let selected_customer_ids =
                    dataSelectedCustomer && dataSelectedCustomer.length > 0 ? dataSelectedCustomer.map((item) => item.value) : [];
                courseReducer.customers.map((item, index) => {
                    if (!selected_customer_ids.includes(item.customer_id)) {
                        _data.push({
                            name: (item.first_name ? item.first_name : '') + (item.last_name ? item.last_name : ''),
                            value: item.customer_id,
                            email: item.email ? item.email : '',
                        });
                    }
                });

                setListCustomers(_data);
                setListCustomerDropdown(_data);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
    }, [courseReducer]);

    useEffect(() => {
        let _listCustomers = [];

        let selected_customer_ids =
            dataSelectedCustomer && dataSelectedCustomer.length > 0 ? dataSelectedCustomer.map((item) => item.value) : [];

        if (listCustomers && listCustomers.length > 0) {
            map(listCustomers, (item, index) => {
                if (!selected_customer_ids.includes(item.value)) {
                    _listCustomers.push(item);
                }
            });
        }

        setListCustomerDropdown(_listCustomers);
    }, [dataSelectedCustomer]);

    const eventCancel = () => {
        onClose(false);
    };

    const onChangeDataStudent = (event) => {
        let { name, value } = event.target;

        setDataStudent({
            ...dataStudent,
            [name]: value,
        });
    };

    const handleAddStudent = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let payload = {};
        if (dataStudent.student_type == 1) {
            payload = {
                product_id: productId,
                full_name: dataStudent.full_name ? dataStudent.full_name : '',
                email: dataStudent.email ? dataStudent.email : '',
                phone: dataStudent.phone ? dataStudent.phone : '',
            };
            dispatch(courseAction.createCustomer(payload));
        } else {
            payload = {
                product_id: productId,
                customer_ids: dataSelectedCustomer && dataSelectedCustomer.length > 0 ? dataSelectedCustomer.map((item) => item.value) : [],
            };
            dispatch(courseAction.createManyCustomers(payload));
        }
    };

    const setSelectedStudent = (data) => {
        let _selectedDataCustomers = [...dataSelectedCustomer];

        if (data) {
            _selectedDataCustomers.push(data);
        }

        setDataSelectedCustomer(_selectedDataCustomers);
    };

    const handleDeleteItem = (index) => {
        let _selectedDataCustomers = [...dataSelectedCustomer];

        _selectedDataCustomers.splice(index, 1);
        setDataSelectedCustomer(_selectedDataCustomers);
    };

    const fetchListSelectedCustomer = () => {
        return dataSelectedCustomer.map((item, index) => {
            return (
                <tr key={index} className='ladiui table-vertical main'>
                    <td>{item.name}</td>
                    <td>{item.email ? item.email : ''}</td>
                    <td className='text-right'>
                        <div className='btn-delete cursor-pointer' onClick={() => handleDeleteItem(index)}>
                            <span>
                                {/* <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg' /> */}
                                <i className='ladiui icon-new new-ldicon-delete' />
                            </span>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const handleSearchDataCustomers = (value) => {
        let payload = {
            selected_customer_ids:
                props.selectedStudents && props.selectedStudents.length > 0 ? props.selectedStudents.map((item) => item.customer_id) : [],
            search: {
                product_id: productId,
                keyword: value,
            },
            ...conditions,
        };
        dispatch(courseAction.courseCustomerListModal(payload));
    };

    let isLoadingCreateCustomer =
        courseReducer.loading && includes([courseTypes.CREATE_CUSTOMER, courseTypes.CREATE_MANY_CUSTOMERS], courseReducer.waiting);

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-student'>
            <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>Thêm mới học viên</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body' style={{ position: 'initial' }}>
                        <div className='ladiui form-group flex'>
                            <div className='flex'>
                                <input
                                    id='radioType1'
                                    type='radio'
                                    className='ladiui form-check-input'
                                    name='radio-group'
                                    value={dataStudent.student_type}
                                    onChange={() => {
                                        setDataStudent({
                                            ...dataStudent,
                                            student_type: 1,
                                        });
                                    }}
                                    checked={dataStudent.student_type === 1}
                                />
                                <label htmlFor='radioType1'>Học viên chưa có tài khoản</label>
                            </div>
                            <div className='flex ml-24'>
                                <input
                                    id='radioType2'
                                    type='radio'
                                    className='ladiui form-check-input'
                                    name='radio-group'
                                    value={dataStudent.student_type}
                                    onChange={() => {
                                        setDataStudent({
                                            ...dataStudent,
                                            student_type: 2,
                                        });
                                    }}
                                    checked={dataStudent.student_type === 2}
                                />
                                <label htmlFor='radioType2'>Học viên đã có tài khoản</label>
                            </div>
                        </div>
                        {dataStudent.student_type == 1 && (
                            <>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>Họ và tên</label>
                                    <Input
                                        name='full_name'
                                        validationName={'tên tài khoản'}
                                        placeholder={'Nhập tên tài khoản'}
                                        value={dataStudent.full_name}
                                        onChange={(e) => onChangeDataStudent(e)}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>Email học viên</label>
                                    <Input
                                        name='email'
                                        validationName={'email'}
                                        placeholder={'Nhập email'}
                                        value={dataStudent.email}
                                        onChange={(e) => onChangeDataStudent(e)}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>Số điện thoại</label>
                                    <Input
                                        type='number'
                                        name='phone'
                                        validationName={'số điện thoại'}
                                        placeholder={'Nhập số điện thoại'}
                                        value={dataStudent.phone}
                                        onChange={(e) => onChangeDataStudent(e)}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                            </>
                        )}
                        {dataStudent.student_type == 2 && (
                            <>
                                <div className='flex mt-24'>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label'>Chọn học viên</label>
                                        {/* <DropdownCheckboxSearch
                                            data={listCustomers || []}
                                            _key='name'
                                            _value='value'
                                            pickTitle={'Tiêu đề'}
                                            placeHolder={'Chọn học viên'}
                                            onSubmit={(items) => {
                                                setSelectedStudent(items);
                                            }}
                                            innerClassName='w100 position-initial'
                                            styleCustomPeople={{ maxHeight: '450px', overflow: 'auto' }}
                                            buttonStyle={{
                                                width: '100%',
                                                paddingRight: '30px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: ' -webkit-box',
                                                WebkitLineClamp: 1,
                                                webkitBoxOrient: 'vertical',
                                                textAlign: 'left',
                                            }}
                                            selectedItems={[]}
                                            isShowBlockInputSearch={true}
                                            isCreateCategory={true}
                                            idBlock="dropdown-choose-customer"
                                        /> */}
                                        <Dropdown
                                            id='state'
                                            data={listCustomerDropdown || []}
                                            onSelectItem={setSelectedStudent}
                                            // currentKey={categoryInfo.parent_id}
                                            // currentValue={FIX_PARENT_CATEGORY[0].NAME}
                                            _key={'value'}
                                            _value={'name'}
                                            validationName={'học viên'}
                                            ref={(ref) => inputsRef.add(ref)}
                                            validations={false}
                                            innerClassName='dropdown-custom-lds'
                                            placeHolder={'Chọn học viên'}
                                            isShowBlockInputSearch={true}
                                            isDropdownSearchCallApi={true}
                                            dataSelectedValues={dataSelectedCustomer || []}
                                            handleSearchData={handleSearchDataCustomers}
                                            idBlock='dropdown-choose-customer'
                                            wrapperClassName='position-initial w100'
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {dataSelectedCustomer && dataSelectedCustomer.length > 0 && (
                            <table className={`ladiui table text-left`}>
                                <thead>
                                    <tr className='ladiui table-vertical'>
                                        <th scope='col' name='full_name'>
                                            Họ và tên
                                        </th>
                                        <th scope='col' name='email' className='text-left'>
                                            Email
                                        </th>
                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody>{fetchListSelectedCustomer()}</tbody>
                            </table>
                        )}
                    </div>

                    <div className='ladiui modal-footer'>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.REMOVE')}
                            </button>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className={`ladiui btn btn-primary ${isLoadingCreateCustomer ? 'loader' : ''}`}
                                onClick={() => handleAddStudent()}
                            >
                                {t('ACTIONS.ADD')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalAddStudent);
