import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";

ModalVTPOST.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
  vnPostAccount: PropTypes.any,
  isSubmitLoading: PropTypes.bool,
};

export default function ModalVTPOST(props) {
  const {
    paymentGatewayConfigured,
    vtPostAccount,
    isSubmitLoading,
    onCancel,
    onChangeViettelPostInput,
    vtPostMode,
    connect,
    inputsViettelPostRef,
    setVTPostModal,
    logo,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      id="modal-vtpost"
      title={!paymentGatewayConfigured ? t("SHIPPINGS.SET_UP_TRANSPORT") : t("SHIPPINGS.DETAIL_TRANSPORT")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={() => connect(appConfig.SHIPPING_PARTNERS.VTPOST.CODE)}
      visible={true}
      isActiveBtnSaveData={paymentGatewayConfigured ? true : false}
      width={600}
      logo={logo}
      bodyStyles={{ minHeight: "30vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("COMMON.TOKEN")}</label>
                <Input value={paymentGatewayConfigured.config.token} disabled={true} />
              </div>

              <div className="ladiui form-group">
                <label className="ladiui-label">{t("SETTINGS.VTPOST_PARTNER_CODE")}</label>
                <Input value={paymentGatewayConfigured.config.code} disabled={true} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("COMMON.EMAIL")}</label>
                  <Input
                    ref={(ref) => inputsViettelPostRef.add(ref)}
                    value={vtPostAccount.email}
                    validationName={t("COMMON.EMAIL")}
                    name="email"
                    onChange={(event) => onChangeViettelPostInput(event)}
                    validations={{ isRequired: true, email: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("COMMON.PASSWORD")}</label>
                  <Input
                    ref={(ref) => inputsViettelPostRef.add(ref)}
                    name="password"
                    type="password"
                    value={vtPostAccount.password}
                    validationName={t("COMMON.PASSWORD")}
                    onChange={(event) => onChangeViettelPostInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>
              </div>

              {baseHelper.isDevelopEnv() && (
                <div className="ladiui form-group flex">
                  <input
                    id="viettel_post_mode"
                    type="checkbox"
                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                    onChange={setVTPostModal}
                    checked={vtPostMode == appConfig.MODE.TEST}
                  />
                  <label htmlFor="viettel_post_mode"> {t("MODE.TEST")}</label>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
