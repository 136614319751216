import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import storeActions from '../../../redux/futures/store/actions';
import * as storeTypes from '../../../redux/futures/store/types';

import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import { Editor } from '@tinymce/tinymce-react';

export default function PageStorePolicy(props) {
    const dispatch = useDispatch();
    const queryURL = baseHelper.useQuery();
    const inputsRef = new Set();

    const storeReducer = useSelector((state) => state.store);
    const cookieReducer = useSelector((state) => state.cookieReducer);

    const LS_STORE_ID = useMemo(() => {
        return cookieReducer.STORE_ID;
    }, [cookieReducer.STORE_ID]);

    const [storeInfo, setStoreInfo] = useState({
        name: '',
        country_code: '',
        country_name: '',
        postal_code: '',
        state_id: '',
        state_name: '',
        district_id: '',
        district_name: '',
        ward_id: '',
        ward_name: '',
        order_prefix: '',
        ticket_seat_prefix: '',
        currency_code: '',
        currency_symbol: '',
        phone: '',
        address: '',
        timezone: '',
        logo_url: '',
        favicon_url: '',
        cover_url: '',
        description: '',
        terms_of_use: '',
        privacy_policy: '',
        refund_policy: '',
    });

    useEffect(() => {
        dispatch(storeActions.show(LS_STORE_ID));
    }, []);

    useEffect(() => {
        if (storeReducer.action == storeTypes.SHOW_STORE) {
            if (storeReducer.status) {
                setStoreInfo({
                    ...storeReducer.store,
                });
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storeReducer.message, 'OK');
            }
        }
        if (storeReducer.action == storeTypes.UPDATE_STORE) {
            if (storeReducer.status) {
                setTimeout(() => {
                    dispatch(storeActions.reload(LS_STORE_ID));
                }, 0);
                window.LadiUI.toastCustom('success', '', storeReducer.message);
                dispatch(storeActions.getUserInfo());
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storeReducer.message, 'OK');
            }
        }
    }, [storeReducer]);

    const handleEditorChangeTermsOfUse = (value) => {
        setStoreInfo({
            ...storeInfo,
            terms_of_use: value,
        });
    };
    const handleEditorChangePrivacyPolicy = (value) => {
        setStoreInfo({
            ...storeInfo,
            privacy_policy: value,
        });
    };
    const handleEditorChangeRefundPolicy = (value) => {
        setStoreInfo({
            ...storeInfo,
            refund_policy: value,
        });
    };

    const submit = () => {
        dispatch(
            storeActions.update({
                ...storeInfo,
            })
        );
    };

    return (
        <>
            <div className='page-store-policy page-general-setting-v4'>
                <div className='header-title'>
                    <div className='title-desc'>
                        <h3>Chính sách cửa hàng</h3>
                        <p>Các chính sách của cửa hàng sẽ hiện ở chân trang khi thanh toán.</p>
                    </div>
                    <div className='action'>
                        <button className='ladiui btn btn-primary' onClick={(e) => submit(e)}>
                            Lưu thay đổi
                        </button>
                    </div>
                </div>

                <div className='content'>
                    <div className='list-blocks'>
                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Điều khoản sử dụng</h3>
                                <p>Điều khoản dịch vụ la thỏa thuận pháp lý giữa nhà cung cấp dịch vụ và người muốn sử dụng dịch vụ.</p>
                            </div>
                            <div className='content-block'>
                                <div className='ladiui form-group mt-0'>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={storeInfo.terms_of_use}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',

                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={handleEditorChangeTermsOfUse}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='line-space-block'></div>
                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Chính sách bảo mật</h3>
                                <p>
                                    Chính sách bảo mật là một tuyên bố giải thích cách thức công ty thu thập, xử lý, lưu trữ và bảo mật
                                    thông tin của khách hàng với cửa hàng.
                                </p>
                            </div>
                            <div className='content-block'>
                                <div className='ladiui form-group mt-0'>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={storeInfo.privacy_policy}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',

                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={handleEditorChangePrivacyPolicy}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='line-space-block'></div>
                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Chính sách hoàn tiền</h3>
                                <p>
                                    Chính sách hoàn tiền là quy tắc với hoạt động hoàn lại tiền sau khi khách hàng mua một hoặc nhiều sản
                                    phẩm, dịch vụ của cửa hàng.
                                </p>
                            </div>
                            <div className='content-block'>
                                <div className='ladiui form-group mt-0'>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={storeInfo.refund_policy}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={handleEditorChangeRefundPolicy}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='line-pace-block'></div>
                    </div>
                </div>
                {/* <div className='action-footer'>
                    <button className='ladiui btn btn-primary' onClick={(e) => submit(e)}>Lưu thay đổi</button>
                </div> */}
            </div>
        </>
    );
}
