import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import BaseHelper from '../../../../helpers/BaseHelper';
import appConfig from '../../../../config/app';

import Input from '../../../../components/Input';
import Dropdown from '../../../../components/Dropdown';
import ModalAddProduct from '../components/ModalAddProduct';
import HandelSection from '../widgetConfig/components/HandelSection';
import pageCheckoutDefined from '../components/pageCheckoutDefined';
// eslint-disable-next-line max-lines-per-function

const SetupAfterPayment = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const handelSectionRef = useRef();
    const storeReducer = useSelector((state) => state.store);

    const { isShow, pageCheckout, thankyouConfig } = props;

    const [configEnable, setConfigEnable] = useState(true);
    const [products, setProduct] = useState([]);
    const [setting, setSetting] = useState({
        payment_redirect_after: 5,
        payment_redirect_url: '',
    });

    const [type, setType] = useState('THANKYOU');

    useImperativeHandle(ref, () => ({
        getData: () => {
            let sectionsData = handelSectionRef.current.getData();
            let config = {
                product_ids: products.map((item) => item.product_id),
                ...setting,
                sectionsData,
            };
            return config;
        },
    }));

    useEffect(() => {
        if (pageCheckout) {
            if (pageCheckout.upsell_products) setProduct(pageCheckout.upsell_products);

            let setttingTg = {
                payment_redirect_after: pageCheckout.payment_redirect_after,
                payment_redirect_url: pageCheckout.payment_redirect_url,
            };
            setSetting(setttingTg);
        }
    }, [pageCheckout]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('tab');

        history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const handleAddProduct = (data) => {
        let dataTg = [...products, ...data];
        dataTg = dataTg.filter((item, index) => dataTg.findIndex((_item) => _item.product_id == item.product_id) === index);

        setProduct(dataTg);
    };

    const handelDelete = (productID) => {
        let productTg = products.filter((item) => item.product_id != productID);
        setProduct(productTg);
    };

    return (
        <div className={`page-checkout-config-item  page-checkout-product-list ${isShow ? 'show' : 'hide'}`}>
            <div className={`config-body ${configEnable ? '' : 'not-active'}`}>
                <div className='ladiui  mt-20 pl-24 pr-24'>
                    <div className=''>
                        <label className='ladiui text-14 space-nowrap'>{t('SALE_CHANNEL.USING_PAGE_THANK')}</label>
                        <Input
                            className='mt-4'
                            name='payment_redirect_url'
                            value={setting.payment_redirect_url}
                            onChange={(e) => {
                                setSetting({
                                    ...setting,
                                    payment_redirect_url: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className='mt-20'>
                        <label className='ladiui text-14'>{t('PRODUCTS.LB_REDIRECT_AFTER')}</label>
                        <Dropdown
                            wrapperClassName='mt-4 w-100-p'
                            placeHolder='Chọn thời gian chờ'
                            data={appConfig.timeRedirectPath}
                            _key='value'
                            _value='name'
                            currentKey={setting.payment_redirect_after}
                            onSelectItem={(item) => {
                                setSetting({
                                    ...setting,
                                    payment_redirect_after: item.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='redirect-or p-24 m-0'>
                    <span className='redirect-or-value'>{t('ACTIONS.OR')} </span>
                    <div className='redirect-or-boder' />
                </div>
                <div className='p-24'>
                    <HandelSection
                        ref={handelSectionRef}
                        sectionData={thankyouConfig || pageCheckoutDefined.THANK_YOU_ITEM_DEFAULT}
                        type='THANK_PAGE'
                    />
                </div>
            </div>
        </div>
    );
});

export default SetupAfterPayment;
