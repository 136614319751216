import React from "react";
import { useTranslation } from "react-i18next";
import appConfig from "../../../../config/app";

export default function ItemPayment(props) {
  const {
    name,
    logo,
    setAcount,
    initData,
    setShowModal,
    isShowModal,
    dataGetWay,
    openInactiveModal,
    active,
    isLoadingActive,
    openDisconnectModal,
    description,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="item">
      <div className="action">
        <div className="text-image">
          {logo ? (
            <>
              <img src={logo} className="shipping-partner-logo" />
              <span className="sub-name">{name}</span>
            </>
          ) : (
            <span className="sub-name" style={{ marginTop: "0px" }}>
              {name}
            </span>
          )}
        </div>
        <div className="btn-action">
          {/* <span> */}
          <button
            type="button"
            className={`ladiui ml-16 btn btn-secondary btn-sm ${!dataGetWay ? "btn-payment-custom" : ""}`}
            onClick={() => {
              setAcount(initData);
              setShowModal(!isShowModal);
            }}
          >
            {dataGetWay ? t("ACTIONS.DETAIL") : t("ACTIONS.SETUP")}
          </button>
          {dataGetWay && (
            <>
              {dataGetWay.status == appConfig.STATUS.ACTIVE ? (
                <button onClick={() => openInactiveModal(dataGetWay)} className="ladiui ml-16 btn btn-danger btn-sm">
                  {t("ACTIONS.INACTIVE")}
                </button>
              ) : (
                <React.Fragment>
                  <button
                    onClick={() => active(dataGetWay)}
                    className={`ladiui ml-16 btn btn-primary btn-sm ${isLoadingActive ? "loader" : ""}`}
                  >
                    {t("ACTIONS.ENABLE")}
                  </button>

                  <button type="button" className="ladiui ml-16 btn btn-secondary btn-sm" onClick={() => openDisconnectModal(dataGetWay)}>
                    <i className="ladi-icon icon-bin red-color"></i>
                  </button>
                </React.Fragment>
              )}
            </>
          )}
        </div>
      </div>

      <div className="description">{description}</div>
    </div>
  );
}
