import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.CREATE_CUSTOMER_ADDRESS: {
            return {
                ...state,
                action: types.CREATE_CUSTOMER_ADDRESS,
                address: action.status ? action.payload.address : {},
                status: action.status,
                message: action.message,
            };
        }
        case types.UPDATE_CUSTOMER_ADDRESS: {
            return {
                ...state,
                action: types.UPDATE_CUSTOMER_ADDRESS,
                status: action.status,
                message: action.message,
            };
        }
        case types.DELETE_CUSTOMER_ADDRESS: {
            return {
                ...state,
                action: types.DELETE_CUSTOMER_ADDRESS,
                status: action.status,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
