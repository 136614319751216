import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ListTags from '../../../components/ListTags';
import ConfirmModal from '../../../components/ConfirmModal';
import ModalCancel from './ModalCancel';
import ModalConfirmPayment from '../OrderEdit/components/ModalConfirmPayment';

import * as orderTypes from '../../../redux/futures/order/types';

import * as customerTypes from '../../../redux/futures/customer/types';
import customerActions from '../../../redux/futures/customer/actions';

import orderActions from '../../../redux/futures/order/actions';
import printActions from '../../../redux/futures/print/actions';
import OrdersTableDetail from './OrdersTableDetail';
import ShippingDelivery from '../OrderEdit/components/ShippingDelivery';
import config from '../../../config/config';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
// function OrdersTable (props) {
export const OrdersTable = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const storeReducer = useSelector((state) => state.store);
    const orderReducer = useSelector((state) => state.order);
    const customerReducer = useSelector((state) => state.customer);

    const isLoadingOrder = useMemo(() => {
        return orderReducer.loading || false;
    }, [orderReducer.loading]);

    const orderData = useMemo(() => {
        return orderReducer.order || {};
    }, [orderReducer.order]);

    const customerSelected = useMemo(() => {
        return customerReducer.customer || {};
    }, [customerReducer.customer]);

    const { columnSeleted, _listOrder, setDuplication, handleClickTag, totalRecord, totalAmount, listOrderNow } = props;
    const [selectedIDs, setSelectedIDs] = useState([]);

    const [isShowModalCancel, setShowModalCancel] = useState(false);
    const [isShowModalConfirmPayment, setShowModalConfirmPayment] = useState(false);
    const [isShowModalShipping, setShowModalShipping] = useState(false);

    const [paymentDate, setPaymentDate] = useState({});
    const [shippingAddress, setShippingAddress] = useState({});
    const [listOrder, setListOrder] = useState([]);
    const [orderSelected, setOrderSelected] = useState(null);

    const listAction = [
        { value: 'PRINT', name: t('COMMON.PRINT') },
        { value: 'REMOVE_MARK_SPAM', name: t('ORDERS.REMOVE_MARK_SPAM') },
        { value: 'MARK_SPAM', name: t('ORDERS.MARK_SPAM') },
        { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
        { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
    ];

    useImperativeHandle(ref, () => ({
        getSelectedIDs: () => {
            return selectedIDs;
        },
    }));

    const eventSelectAction = (action) => {
        if (selectedIDs <= 0) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        switch (action) {
            case 'REMOVE_MARK_SPAM':
                window.LadiUI.showModal('confirm-remove-spam');
                break;
            case 'MARK_SPAM':
                window.LadiUI.showModal('confirm-mark-spam');
                break;
            case 'CANCEL_ORDER':
                setShowModalCancel(true);
                break;
            case 'CONFIRM_PAYMENT':
                openModalConfirmPayment();
                break;
            case 'PRINT':
                dispatch(printActions.printsOrder(selectedIDs));
                // this.props.prints(selectedIDs);
                break;
            default:
        }
    };

    useEffect(() => {
        setListOrder(_listOrder || []);
    }, [_listOrder]);

    useEffect(() => {
        const shippingAddress = baseHelper.isEmpty(orderData)
            ? {}
            : {
                  first_name: orderData.shipping_first_name,
                  last_name: orderData.shipping_last_name,
                  company: orderData.shipping_company,
                  phone: orderData.shipping_phone,
                  address: orderData.shipping_address,
                  apartment: orderData.shipping_apartment,
                  state: orderData.shipping_state,
                  country_code: orderData.shipping_country_code,
                  country_name: orderData.shipping_country_name,
                  state_id: orderData.shipping_state_id,
                  state_name: orderData.shipping_state_name,
                  district_id: orderData.shipping_district_id,
                  district_name: orderData.shipping_district_name,
                  ward_id: orderData.shipping_ward_id,
                  ward_name: orderData.shipping_ward_name,
                  postal_code: orderData.shipping_postal_code,
                  note: orderData.shipping_note,
              };
        setShippingAddress(shippingAddress);
    }, [orderData]);

    useEffect(() => {
        if (orderReducer.action == orderTypes.UPDATE_ORDER_ADDRESS || orderReducer.action == orderTypes.SHIPPING_UPDATE_ORDER_ADDRESS) {
            if (orderReducer.status) {
                setTimeout(() => {
                    dispatch(orderActions.show(orderData.order_id));
                }, 500);
            }
        }

        if (orderReducer.action == orderTypes.FULLFILL_ORDER) {
            if (orderReducer.status) {
                listOrderNow();
                setShowModalShipping(false);
                window.LadiUI.toastCustom('success', '', orderReducer.message);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }
    }, [orderReducer.action]);

    const cancelOrders = (reason) => {
        dispatch(orderActions.cancels(selectedIDs, reason));
    };

    const getPaymentStatus = (status) => {
        // const matched = find(appConfig.PAYMENT_STATUS, (item) => item.CODE == status);
        const matched = Object.values(appConfig.PAYMENT_STATUS).find((item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    const getShippingStatus = (status) => {
        // const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
        const matched = Object.values(appConfig.SHIPPING_STATUS).find((item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    const getPaymentMethod = (method) => {
        if (!method) return t('ORDERS.DRAFT_ORDER');
        // const matched = find(appConfig.PAYMENT_PARTNERS, (item) => item.CODE == method);
        const matched = Object.values(appConfig.PAYMENT_PARTNERS).find((item) => item.CODE == method);
        if (matched) {
            return matched.NAME;
        }
    };

    const openModalConfirmPayment = async () => {
        let orders = listOrder.filter((item) => selectedIDs.includes(item.order_id));
        let isOrder = false;

        for await (let item of orders) {
            if (
                item.is_draft === 1 ||
                item.payment_status !== appConfig.PAYMENT_STATUS.PENDING.CODE ||
                item.status !== appConfig.ORDER_STATUS.OPEN.CODE
            ) {
                isOrder = true;
                break;
            }
        }

        if (isOrder) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.CHOOSE_UNPAID_ORDERS'));
            return;
        }
        setShowModalConfirmPayment(true);
    };

    const confirmPayments = async (payment_gateway_code, payment_type, is_auto_shipping) => {
        setPaymentDate({ payment_gateway_code, payment_type, is_auto_shipping });
        setShowModalConfirmPayment(false);

        window.LadiUI.showModal('confirm-last-payment');
    };

    const confirmLastPayment = () => {
        let data = {
            ...paymentDate,
            order_ids: selectedIDs,
        };

        dispatch(orderActions.markAsPaids(data));
    };

    const checkAllItem = () => {
        if (selectedIDs.length < listOrder.length) {
            let ids = listOrder.map((item) => item.order_id);
            setSelectedIDs(ids);
        } else setSelectedIDs([]);
    };

    const checkItem = (orderID) => {
        let ids = [...selectedIDs];
        let index = ids.indexOf(orderID);

        if (index > -1) {
            ids.splice(index, 1);
        } else {
            ids.push(orderID);
        }

        setSelectedIDs(ids);
    };

    const getVariantSelected = (order) => {
        if (!order || !order.order_details) return [];

        const variants = order.order_details.map((item) => {
            const variant = {
                options: item.options,
                src: item.src,
                name: item.product_name || item.product_name_full,
                price: item.price,
                product_id: item.product_id,
                quantity: item.quantity,
                total: item.total,
                variant_id: item.product_variant_id,
                product_type: item.product_type,
                weight: item.weight,
                max_buy: item.max_buy,
                min_buy: item.min_buy,
            };

            if (item.discount_type) {
                variant.discount = {
                    fee: item.discount_fee,
                    note: item.discount_note,
                    type: item.discount_type,
                    value: item.discount_value,
                    maximum_discount: item.discount_maximum,
                };
            }

            return variant;
        });

        return variants;
    };

    const onChangeAddress = (address, addressType, fromShippingForm) => {
        let data;
        if (addressType == appConfig.ADDRESS_TYPE.BILLING) {
            // setCustomerShipping((pre) => {
            //     let tg = { ...pre, ...address };
            //     return tg;
            // });
            data = {
                order_id: orderData.order_id,
                billing_address: {
                    ...address,
                },
            };
        } else if (addressType == appConfig.ADDRESS_TYPE.BILLING_INFO) {
            // setCustomerBilling((pre) => {
            //     let tg = { ...pre, ...address };
            //     return tg;
            // });
            data = {
                order_id: orderData.order_id,
                billing_info: {
                    ...address,
                },
            };
        } else {
            // setCustomerShipping((pre) => {
            //     let tg = { ...pre, ...address };
            //     return tg;
            // });

            data = {
                order_id: orderData.order_id,
                shipping_address: {
                    ...address,
                },
            };
        }
        if (!fromShippingForm) {
            dispatch(orderActions.updateAddress(data));
        } else {
            dispatch(orderActions.updateShippingAddress(data));
        }
    };

    return (
        <>
            {isShowModalCancel && (
                <ModalCancel
                    visible={isShowModalCancel}
                    onOk={cancelOrders}
                    onCancel={() => setShowModalCancel(false)}
                    isLoading={isLoadingOrder}
                />
            )}

            <ConfirmModal
                id='confirm-mark-spam'
                title={t('ORDERS.MARK_SPAM')}
                content={t('ORDERS.MSG_MARK_SPAM_TO_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.OK')}
                onOk={() =>
                    dispatch(
                        orderActions.updateSpams({
                            order_ids: selectedIDs,
                            mark_spam: appConfig.STATUS.ACTIVE,
                        })
                    )
                }
                isLoading={isLoadingOrder}
            />

            <ConfirmModal
                id='confirm-remove-spam'
                title={t('ORDERS.REMOVE_MARK_SPAM')}
                content={t('ORDERS.MSG_REMOVE_MARK_SPAM_TO_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.OK')}
                onOk={() =>
                    dispatch(
                        orderActions.updateSpams({
                            order_ids: selectedIDs,
                            mark_spam: appConfig.STATUS.INACTIVE,
                        })
                    )
                }
                isLoading={isLoadingOrder}
            />

            {isShowModalConfirmPayment && (
                <ModalConfirmPayment
                    visible={isShowModalConfirmPayment}
                    onCancel={() => setShowModalConfirmPayment(false)}
                    onOk={confirmPayments}
                    isLoading={isLoadingOrder}
                    currentStoreID={storeReducer.userInfo.currentStore.store_id}
                />
            )}

            {isShowModalShipping && (
                <ShippingDelivery
                    orderID={orderData.order_id}
                    isShowModalShipping={isShowModalShipping}
                    hideModalShipping={() => setShowModalShipping(false)}
                    shippingAddress={shippingAddress}
                    addresses={customerSelected.addresses}
                    onChangeAddress={onChangeAddress}
                    weight={orderData.weight}
                    total={orderData.total}
                    note={orderData.shipping_note}
                    cod_amount={orderData.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE ? orderData.total : 0}
                    variantSelected={getVariantSelected(orderData)}
                />
            )}

            <ConfirmModal
                id='confirm-last-payment'
                title={t('ORDERS.CONFIRM_PAYMENT')}
                content={t('ORDERS.MSG_CONFIRM_LAST_PAYMENT', paymentDate.payment_gateway_code)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.OK')}
                onOk={confirmLastPayment}
                isBtnPrimary={true}
                isLoading={isLoadingOrder}
            />
            <div className='table-scroll pos-rel'>
                {selectedIDs.length > 0 && (
                    <LadiDropdownMenu
                        labelDropdown={t('ACTIONS.LABEL')}
                        listProp={listAction}
                        defaultTextProp={t('ACTIONS.TITLE')}
                        hideLabel={true}
                        cbProp={eventSelectAction}
                        classSize='btn-sm '
                        classDropdown='w-180 action-record-table'
                    />
                )}
                {totalRecord > 0 ? (
                    <div className='table-wrap ladiui pos-rel'>
                        <table className='ladiui table text-left table-order main-table'>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    {/* <th className='fixed-side fix-column' scope='col'></th> */}
                                    <th scope='col' className='ladiui checkall fixed-side fix-column'>
                                        <input
                                            id='checkAllItem'
                                            onClick={checkAllItem}
                                            type='checkbox'
                                            checked={selectedIDs.length == listOrder.length && listOrder.length > 0}
                                            onChange={() => {}}
                                            className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle ml-32'
                                        />
                                        <span className='ml-16'>{t('ORDERS.REF_NO')}</span>
                                    </th>
                                    {columnSeleted.map((item, index) => {
                                        return (
                                            <th scope='col' key={index} className={`${item.value == 'created_at' ? 'created_at' : ''}`}>
                                                {item.name}
                                            </th>
                                        );
                                    })}
                                    <th className='fix-column-right'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listOrder.map((order, index) => {
                                    const tagIDs = order.tag_ids ? order.tag_ids.split(appConfig.SEPARATOR.DEFAULT) : [];
                                    const tagNames = order.tag_names ? order.tag_names.split(appConfig.SEPARATOR.DEFAULT) : [];
                                    const tagColors = order.tag_colors ? order.tag_colors.split(appConfig.SEPARATOR.DEFAULT) : [];

                                    const isDuplicate = order.parent_id;
                                    const isShow = order.is_show == appConfig.STATUS.ACTIVE;
                                    let className = 'ladiui table-vertical main';
                                    if (order.status == appConfig.ORDER_STATUS.CANCELED.CODE) {
                                        className = `${className} order-cancel`;
                                    }

                                    if (isDuplicate) {
                                        className = `${className} duplicate`;
                                    }

                                    let isSelected = selectedIDs.includes(order.order_id);

                                    return (
                                        <React.Fragment key={order.order_id}>
                                            <tr className={`${className}`} style={tagIDs.length > 0 ? { borderBottom: 'none' } : {}}>
                                                <td className='lh-32 fix-column display-flex-center'>
                                                    <i
                                                        className={`icon-caret-down-fill ${
                                                            orderSelected == order.order_id ? '' : 'turn-right'
                                                        }`}
                                                        onClick={() => {
                                                            if (orderSelected == order.order_id) {
                                                                setOrderSelected(null);
                                                            } else {
                                                                setOrderSelected(order.order_id);
                                                                dispatch(orderActions.show(order.order_id));
                                                                dispatch(customerActions.show(order.customer_id));
                                                            }
                                                        }}
                                                    />

                                                    <input
                                                        type='checkbox'
                                                        onClick={(event) => checkItem(order.order_id)}
                                                        onChange={() => {}}
                                                        checked={isSelected}
                                                        className='ladiui checkbox size-checkbox form-check-input-checkbox ml-16'
                                                    />
                                                    <div className='ml-16 order-id'>
                                                        <a
                                                            className={`order-table-item block order-reference-no`}
                                                            href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.ORDER_UPDATE(
                                                                order.order_id
                                                            )}`}
                                                            target='_parent'
                                                        >{`${
                                                            storeReducer &&
                                                            storeReducer.userInfo.currentStore &&
                                                            storeReducer.userInfo.currentStore.order_prefix
                                                                ? storeReducer.userInfo.currentStore.order_prefix
                                                                : ''
                                                        }${order.reference_no}`}</a>

                                                        {order.total_child > 0 && (
                                                            <div
                                                                onClick={() => setDuplication(order.order_id, order.reference_no)}
                                                                className='duplucate-order'
                                                            >
                                                                {/* <i className='ladi-icon icon-duplicate mr-4' /> */}
                                                                {order.total_child} Đơn trùng
                                                            </div>
                                                        )}
                                                        {order.parent_id && (
                                                            <div
                                                                onClick={() => setDuplication(order.parent_id, order.parent_reference_no)}
                                                                className='duplucate-order'
                                                            >
                                                                {/* <i className='ladi-icon icon-duplicate mr-4' /># */}
                                                                {`${
                                                                    storeReducer &&
                                                                    storeReducer.userInfo.currentStore &&
                                                                    storeReducer.userInfo.currentStore.order_prefix
                                                                        ? storeReducer.userInfo.currentStore.order_prefix
                                                                        : ''
                                                                }${order.parent_reference_no}`}
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>

                                                {columnSeleted.map((item, index) => {
                                                    if (item.value == 'customer_first_name') {
                                                        // let url = `/customers?customer_id=${order.customer_id}`;

                                                        let query_ls = { customer_id: order.customer_id };

                                                        let url = `${config.BUILDER_LDP}${
                                                            appConfig.LADIPAGE_URL.CUSTOMER_LIST
                                                        }&query_ls=${JSON.stringify(query_ls)}`;

                                                        return (
                                                            <td key={index} className={`${!isShow && 'prevent-click'}`}>
                                                                <a href={url} target='_blank'>{`${baseHelper.getText(
                                                                    order.customer_last_name
                                                                )} ${baseHelper.getText(order.customer_first_name)}`}</a>
                                                            </td>
                                                        );
                                                    }
                                                    if (item.value == 'ordered_at')
                                                        return (
                                                            <td key={index} className='created_at'>
                                                                {baseHelper.formatStrToDate(order.ordered_at)}
                                                            </td>
                                                        );
                                                    if (item.value == 'created_at')
                                                        return (
                                                            <td key={index} className='created_at'>
                                                                {baseHelper.formatStrToDate(order.created_at)}
                                                            </td>
                                                        );

                                                    // if (item.value == 'payment_status') {
                                                    //     return (
                                                    //         <td
                                                    //             key={index}
                                                    //             className={`lh-32 ladiui display-flex-center payment-status-${
                                                    //                 order.payment_status ? order.payment_status.toLowerCase() : ''
                                                    //             }`}
                                                    //         >
                                                    //             {getPaymentStatus(order.payment_status)}

                                                    //             {order.payment_retry_get_status >= 5 &&
                                                    //                 order.payment_status != appConfig.PAYMENT_STATUS.SUCCESS && (
                                                    //                     <span
                                                    //                         style={{ marginLeft: 5 }}
                                                    //                         data-tooltip={t('ORDERS.PAYMENT_FAILED')}
                                                    //                         data-tooltip-position='top'
                                                    //                     >
                                                    //                         <i className='ladi-icon icon-c-warning' />
                                                    //                     </span>
                                                    //                 )}
                                                    //         </td>
                                                    //     );
                                                    // }

                                                    if (item.value == 'payment_method') {
                                                        return (
                                                            <td key={index}>
                                                                <div className='ladiui flex'>
                                                                    <span
                                                                        style={{ marginLeft: 5 }}
                                                                        data-tooltip={getPaymentStatus(order.payment_status)}
                                                                        data-tooltip-position='top'
                                                                    >
                                                                        <div
                                                                            className={`lh-32 ladiui display-flex-center payment-status-${
                                                                                order.payment_status
                                                                                    ? order.payment_status.toLowerCase()
                                                                                    : ''
                                                                            } ${order.is_draft == 1 ? 'draft-order' : ''}`}
                                                                        ></div>
                                                                    </span>

                                                                    <span>{getPaymentMethod(order.payment_method)}</span>
                                                                </div>
                                                            </td>
                                                        );
                                                    }
                                                    if (item.value == 'shipping_status') {
                                                        return (
                                                            <td
                                                                key={index}
                                                                className={`lh-32 ladiui display-flex-center shipping-status-${
                                                                    order.shipping_status ? order.shipping_status.toLowerCase() : ''
                                                                }`}
                                                            >
                                                                {getShippingStatus(order.shipping_status)}
                                                            </td>
                                                        );
                                                    }
                                                    if (item.value == 'total') {
                                                        return (
                                                            <td key={index} className='laidui text-right'>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    order.refund ? order.total - order.refund : order.total,
                                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                    if (item.value == 'assignee_id') {
                                                        if (order.assignee_id)
                                                            return (
                                                                <td key={index}>
                                                                    {baseHelper.getStaffNameById(
                                                                        storeReducer.userInfo.staffs,
                                                                        order.assignee_id,
                                                                        storeReducer.userInfo.ladi_uid
                                                                    )}
                                                                </td>
                                                            );
                                                        return (
                                                            <td key={index}>
                                                                {order.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                                                                    <button
                                                                        onClick={() => dispatch(orderActions.assign(order.order_id))}
                                                                        className={`ladiui btn btn-outline-primary btn-sm ${
                                                                            !isShow && 'prevent-click'
                                                                        }`}
                                                                    >
                                                                        {t('ORDERS.ASSIGN')}
                                                                    </button>
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                    if (item.value == 'creator_id') {
                                                        return (
                                                            <td key={index}>
                                                                {baseHelper.getStaffNameById(
                                                                    storeReducer.userInfo.staffs,
                                                                    order.creator_id,
                                                                    storeReducer.userInfo.ladi_uid
                                                                )}
                                                            </td>
                                                        );
                                                    }
                                                    return <td key={index}>{order[item.value]}</td>;
                                                })}
                                                <td className='text-right fix-column-right'>
                                                    <div className='ladiui btn-group order-action'>
                                                        <div className='ladiui dropdown hide-mt ba-c'>
                                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                                <i className='ladiui icon icon-ldp-dot'></i>
                                                            </button>
                                                            <ul className='ladiui dropdown-menu r-0'>
                                                                <li>
                                                                    <a
                                                                        className='ladiui dropdown-item'
                                                                        onClick={() => history.push(`/orders/${order.order_id}`)}
                                                                    >
                                                                        <i className='new-ldicon-edit' />
                                                                        <span>{t('ACTIONS.EDIT')} </span>
                                                                    </a>
                                                                </li>
                                                                {order.status == appConfig.ORDER_STATUS.OPEN.CODE &&
                                                                    order.mark_spam == appConfig.STATUS.ACTIVE && (
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() => {
                                                                                    dispatch(
                                                                                        orderActions.updateSpams({
                                                                                            order_ids: [order.order_id],
                                                                                            mark_spam: appConfig.STATUS.INACTIVE,
                                                                                        })
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {/* <i className='new-ldicon-folder-error' /> */}
                                                                                <span>{t('ORDERS.REMOVE_MARK_SPAM')}</span>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                {order.mark_spam == appConfig.STATUS.INACTIVE &&
                                                                    order.status == appConfig.ORDER_STATUS.OPEN.CODE && (
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() =>
                                                                                    dispatch(
                                                                                        orderActions.updateSpams({
                                                                                            order_ids: [order.order_id],
                                                                                            mark_spam: appConfig.STATUS.ACTIVE,
                                                                                        })
                                                                                    )
                                                                                }
                                                                            >
                                                                                {/* <i className='new-ldicon-folder-error' /> */}
                                                                                <span>{t('ORDERS.MARK_SPAM')}</span>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                {isDuplicate && (
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() =>
                                                                                dispatch(orderActions.removeDuplication(order.order_id))
                                                                            }
                                                                        >
                                                                            {t('ORDERS.REMOVE_DUPLICATION')}
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {tagIDs.length > 0 && (
                                                <tr className='ladiui table-vertical main custom-tag'>
                                                    <td
                                                        colSpan={columnSeleted.length + 2 < 5 ? columnSeleted.length + 2 : 5}
                                                        className='fix-column'
                                                    >
                                                        {tagIDs.length > 0 && (
                                                            <ListTags
                                                                classTagName='ml-64'
                                                                tagColors={tagColors}
                                                                tagValues={tagIDs}
                                                                tagNames={tagNames}
                                                                handleClickTagItem={handleClickTag}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                            {orderSelected && orderSelected == order.order_id && (
                                                <OrdersTableDetail
                                                    colCustom={columnSeleted.length + 2}
                                                    listOrderNow={listOrderNow}
                                                    orderSelected={orderSelected}
                                                    setShowModalCancel={setShowModalCancel}
                                                    setShowModalConfirmPayment={setShowModalConfirmPayment}
                                                    setShowModalShipping={setShowModalShipping}
                                                    setSelectedIDs={setSelectedIDs}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                            {/* <tfoot>
                    <tr>
                        <td colSpan={columnSeleted.length}>
                            <strong>{t('ORDERS.TOTAL_AMOUNT')}</strong>
                        </td>
                        <td colSpan={3}>
                            <strong className='pl-0'>
                                {baseHelper.formatMoneyPostFix(totalAmount, storeReducer.userInfo.currentStore.currency_symbol)}
                            </strong>
                        </td>
                    </tr>
                </tfoot> */}
                        </table>
                    </div>
                ) : (
                    <div className='table-empty'>
                        <i className='new-ldicon-search' />
                        <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                        <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                    </div>
                )}
            </div>
        </>
    );
});
// }
// export default OrdersTable;
