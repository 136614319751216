import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function NoAccess(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    return (
        <div className='iframe-open'>
            <div className='page-order page-no-access'>
                <div className='page-max'>
                    <div className='page-right'>
                        <div className='page-content'>
                            <div className='page-no-access-content'>
                                <i className='ladiui icon-new page-no-access-content-icon new-ldicon-lock-duotone' />
                                <div className='title'>Không có quyền truy cập</div>
                                <div className='sub-title'>Bạn chưa được thêm thành viên trong quản lý nhân viên của eCommerce</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
