import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import producer from "immer";

import storeActions from "../../../redux/futures/store/actions";
import * as storeTypes from "../../../redux/futures/store/types";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

const PanelCourseSettingTracking = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    let { isShow, currentTrackingData } = props;
    const { t } = useTranslation();

    const [tracking, setTracking] = useState({
        facebook_pixel: "",
        google_analytics_id: "",
        google_ads_id: "",
        google_ads_id_label: "",
        tiktok_pixel: "",
        google_tag_manager_id: "",
        custom_javascript_head: "",
        custom_javascript_body: "",
    });

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return tracking;
        },
    }));

    useEffect(() => {
        if (currentTrackingData) {
            setTracking({
                facebook_pixel: currentTrackingData?.facebook_pixel || "",
                google_analytics_id: currentTrackingData?.google_analytics_id || "",
                google_ads_id: currentTrackingData?.google_ads_id || "",
                google_ads_id_label: currentTrackingData?.google_ads_id_label || "",
                tiktok_pixel: currentTrackingData?.tiktok_pixel || "",
                google_tag_manager_id: currentTrackingData?.google_tag_manager_id || "",
                custom_javascript_head: currentTrackingData?.custom_javascript_head || "",
                custom_javascript_body: currentTrackingData?.custom_javascript_body || "",
            });
        }
    }, [currentTrackingData]);

    const onChangeInput = (event) => {
        // setTracking(
        //   producer(tracking, (draft) => {
        //     draft[event.target.name] = event.target.value;
        //   })
        // );
        setTracking({
            ...tracking,
            [event.target.name]: event.target.value
        })
    };

    return (
        <>
            <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>
                <div className='ls-flex ls-flex-col ls-items-start ls-gap-24'>
                    <div className='ls-setting-row'>
                        <div className='ls-setting-row-left'>
                            <div className='ls-setting-row-title'>
                                Chỉnh sửa mã chuyển đổi
                            </div>
                            <div className='ls-setting-row-description'>
                                Mã chuyển đổi là nơi kết nối với các nền tảng bán hàng
                            </div>
                        </div>
                        <div className='ls-setting-row-right'>
                            <div className="ladiui form-group mt-0">
                                <label className="ladiui-label new">{t("TRACKING.FB_PIXEL_ID")}</label>
                                <Input
                                    name="facebook_pixel"
                                    placeholder={t("TRACKING.FB_PIXEL_ID_PLACE_HOLDER")}
                                    value={tracking.facebook_pixel}
                                    onChange={onChangeInput}
                                />
                            </div>

                            <div className="ladiui form-group">
                                <label className="ladiui-label new">{t("TRACKING.GG_ANALYTICS_ID")}</label>
                                <Input
                                    name="google_analytics_id"
                                    placeholder={t("TRACKING.GG_ANALYTICS_ID_PLACE_HOLDER")}
                                    value={tracking.google_analytics_id}
                                    onChange={onChangeInput}
                                />
                            </div>

                            <div className="ladiui form-group">
                                <label className="ladiui-label new">{t("TRACKING.GG_ADS_ID")}</label>
                                <Input
                                    name="google_ads_id"
                                    placeholder={t("TRACKING.GG_ADS_ID_PLACE_HOLDER")}
                                    value={tracking.google_ads_id}
                                    onChange={onChangeInput}
                                />
                            </div>

                            <div className="ladiui form-group">
                                <label className="ladiui-label new">{t("TRACKING.GG_ADS_ID_LABEL")}</label>
                                <Input
                                    name="google_ads_id_label"
                                    placeholder={t("TRACKING.GG_ADS_ID_LABEL_PLACE_HOLDER")}
                                    value={tracking.google_ads_id_label}
                                    onChange={onChangeInput}
                                />
                            </div>

                            <div className="ladiui form-group">
                                <label className="ladiui-label new">{t("TRACKING.TT_PIXEL_ID")}</label>
                                <Input
                                    name="tiktok_pixel"
                                    placeholder={t("TRACKING.TT_PIXEL_ID_PLACE_HOLDER")}
                                    value={tracking.tiktok_pixel}
                                    onChange={onChangeInput}
                                />
                            </div>

                            <div className="ladiui form-group">
                                <label className="ladiui-label new">{t("TRACKING.GG_TAG_MANAGER_ID")}</label>
                                <Input
                                    name="google_tag_manager_id"
                                    placeholder={t("TRACKING.GG_TAG_MANAGER_ID_PLACE_HOLDER")}
                                    value={tracking.google_tag_manager_id}
                                    onChange={onChangeInput}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='ls-divide-1'></div>

                    <div className='ls-setting-row'>
                        <div className='ls-setting-row-left'>
                            <div className='ls-setting-row-title'>
                                Mã Javascript / CSS
                            </div>
                            <div className='ls-setting-row-description'>
                                Nội dung mã javascript
                            </div>
                        </div>
                        <div className='ls-setting-row-right block-suggestion-category'>
                            <div className="content-block ls-w-full">
                                <div className="ladiui form-group mt-0">
                                    <label className="ladiui-label new">{t("SETTINGS.TRACKING_BEFORE_HEAD")}</label>
                                    <AceEditor
                                        mode="javascript"
                                        theme="github"
                                        onChange={(newValue) => {
                                            const event = {
                                                target: {
                                                    name: "custom_javascript_head",
                                                    value: newValue,
                                                },
                                            };
                                            onChangeInput(event);
                                        }}
                                        name="custom_javascript_head"
                                        height="200px"
                                        width="100%"
                                        value={tracking.custom_javascript_head}
                                    />
                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("SETTINGS.TRACKING_BEFORE_BODY")}</label>
                                    <AceEditor
                                        mode="javascript"
                                        theme="github"
                                        onChange={(newValue) => {
                                            const event = {
                                                target: {
                                                    name: "custom_javascript_body",
                                                    value: newValue,
                                                },
                                            };
                                            onChangeInput(event);
                                        }}
                                        name="custom_javascript_body"
                                        height="200px"
                                        width="100%"
                                        value={tracking.custom_javascript_body}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

PanelCourseSettingTracking.propTypes = {};

export default PanelCourseSettingTracking;