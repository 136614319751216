import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const create = (address) => ({
    type: types.CREATE_CUSTOMER_ADDRESS,
    meta: {
        endpoint: endpoint.CUSTOMER_ADDRESS_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            address,
        },
        hasAuth: true,
    }
});

const update = (address) => ({
    type: types.UPDATE_CUSTOMER_ADDRESS,
    meta: {
        endpoint: endpoint.CUSTOMER_ADDRESS_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            address,
        },
        hasAuth: true,
    }
});

const deleteAddress = (customerID, addressID) => ({
    type: types.DELETE_CUSTOMER_ADDRESS,
    meta: {
        endpoint: endpoint.CUSTOMER_ADDRESS_DELETE,
        method: REQUEST_METHOD.POST,
        body: {
            customer: {
                customer_id: customerID,
                customer_address_id: addressID,
            },
        },
        hasAuth: true,
    }
});
export default {
    create,
    update,
    delete: deleteAddress,
}