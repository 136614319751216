import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line max-lines-per-function

const PageProduct = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { isShow } = props;
    const imageRef = useRef();

    const [setting, setSetting] = useState({});

    useImperativeHandle(ref, () => ({
        getData: () => {
            return {
                setting,
            };
        },
    }));

    const handelInput = (e) => {
        const { name, value } = e.target;

        setSetting({
            ...setting,
            [name]: !setting[name],
        });
    };

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'}`}>
            <div className='display-flex-center gap-8'>
                <input
                    type='checkbox'
                    name='isShowDescription'
                    onChange={handelInput}
                    checked={setting.isShowDescription || false}
                    className='ladiui checkbox size-checkbox form-check-input-checkbox ml-16'
                />
                <div>Hiển thị mô tả sản phẩm</div>
            </div>
            <div className='display-flex-center gap-8'>
                <input
                    type='checkbox'
                    name='isShowDescription'
                    onChange={handelInput}
                    checked={setting.isShowProductReview || false}
                    className='ladiui checkbox size-checkbox form-check-input-checkbox ml-16'
                />
                <div>Hiển thị đánh giá sản phẩm</div>
            </div>
            <div className='display-flex-center gap-8'>
                <input
                    type='checkbox'
                    name='isShowDescription'
                    onChange={handelInput}
                    checked={setting.isShowProductUpsell || false}
                    className='ladiui checkbox size-checkbox form-check-input-checkbox ml-16'
                />
                <div>Hiển thị sản phẩm bán kèm</div>
            </div>
        </div>
    );
});

export default PageProduct;
