import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ProductCategory from '../products/Category';
import Course from './Course';
import PageCourse from './PageCourse';
import PageStudentManagement from './PageStudentManagement';
import CourseCategory from '../products/CourseCategory';

export default function CourseV2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const { search } = useLocation();

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const [tabSelected, setTabSelected] = useState();

    useEffect(() => {
        const tab = queryStrings.get('tab');
        setTabSelected(tab || 0);
    }, [queryStrings]);

    const selectTab = (index) => {
        queryStrings.set('tab', index);

        props.history.replace({ search: queryStrings.toString() });
        setTabSelected(index);
    };

    return (
        <div className='page-order page-product-v2'>
            <div className='menu-left'>
                <div className='title'>{t('Khoá học')}</div>
                <div className='menu-list'>
                    <div
                        className={`menu-item ${tabSelected == 0 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(0);
                        }}
                    >
                        <i className='new-ldicon-box-sub-menu' />
                        <span>{t('Danh sách khoá học')}</span>
                    </div>

                    <div
                        className={`menu-item ${tabSelected == 1 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(1);
                        }}
                    >
                        <i className='new-ldicon-template' />
                        <span>{t('Danh mục khoá học')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 2 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(2);
                        }}
                    >
                        <i className='ladiui icon-new new-ldicon-user-group' />
                        <span>{t('Quản lý học viên')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 3 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(3);
                        }}
                    >
                        <i className='new-ldicon-review' />
                        <span>{t('Thiết lập giao diện')}</span>
                    </div>
                </div>
            </div>
            <div className='page-max'>
                <div className='page-right'>
                    {tabSelected == 0 && <Course location={location} history={props.history} t={t} />}
                    {tabSelected == 1 && <CourseCategory selectedProductType='Course' location={location} history={props.history} />}
                    {tabSelected == 2 && <PageStudentManagement />}
                    {tabSelected == 3 && <PageCourse />}
                </div>
            </div>
        </div>
    );
}
