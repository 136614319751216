import React from 'react';
import PropTypes from 'prop-types';
import { find, remove, map } from 'lodash';
import { withTranslation } from 'react-i18next';
import Modal from './Modal';
import Input from './Input';
import { cloneDeep, compact } from 'lodash';
import baseHelper from './../helpers/BaseHelper';
import ValidatorHelper from '../helpers/ValidatorHelper';
import fileActions from '../../src/redux/futures/file/actions';
import * as fileTypes from '../../src/redux/futures/file/types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ModalAddImage extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onOk: PropTypes.func,
        visible: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            name: props.dataSelect.name || '',
            type: props.dataSelect.type || '',
            image: props.dataSelect.image || '',
            url: props.dataSelect.url || '',
            position: null,
        };

        this.inputsRef = new Set();
    }

    submit = () => {
        let { name, image, url } = this.state;
        let data = {
            name,
            image,
            url,
            type: 'IMAGE',
        };
        this.props.onSubmit(data, this.props.indexData);
    };

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.fileReducer.action != nextProps.fileReducer.action) {
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_CHECKOUT_IMAGES) {
                if (nextProps.fileReducer.status) {
                    const { image } = this.state;
                    const newUploadedImage = map(nextProps.fileReducer.product_images, (item, index) => {
                        return item;
                    });

                    this.setState({
                        image: newUploadedImage[0],
                    });

                    // const productImages = {
                    //     product_id: this.state.product.product_id,
                    //     images: newUploadedImage
                    // };
                    // this.props.createImages(productImages);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                }
            }
        }

        if (this.props.dataSelect != nextProps.dataSelect) {
            this.setState({
                name: nextProps.dataSelect.name || '',
                type: nextProps.dataSelect.type || '',
                image: nextProps.dataSelect.image || '',
                url: nextProps.dataSelect.url || '',
            });
        }
    }

    validate = () => {
        // Validate
        const { t } = this.props;
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = errors.concat(this.locationRef.current.validate());
        errors = compact(errors);
        return errors;
    };
    onChangeInput = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadCheckoutImages(form);
            }
        }
    };

    render() {
        const { t, visible, onCancel } = this.props;
        const { name, image, url } = this.state;

        return (
            <Modal id='modal-add-image' visible={visible} onCancel={onCancel} onOk={this.submit} title='Hình ảnh' width={600}>
                <div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Tên gợi nhớ</label>
                        <Input
                            name='name'
                            validationName='nhập tên hiển thị'
                            placeholder='Nhập tóm tắt'
                            value={name}
                            onChange={this.onChangeInput}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        />
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Ảnh</label>
                        <div className='input-upload-file relative w100'>
                            <Input name='image' placeholder='chọn ảnh' disabled={true} value={image} />
                            <div className='ladiui-button-upload'>
                                <button type='button'>{t('COMMON.CHOOSE_FILES')}</button>
                                <input
                                    ref={(ref) => (this.fileInputRef = ref)}
                                    type='file'
                                    name='file'
                                    accept='image/*'
                                    // multiple="multiple"
                                    onChange={this.fileChangedHandler}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Đường dẫn (tùy chọn)</label>
                        <Input
                            name='url'
                            validationName='nhập đường dẫn url'
                            placeholder='https://app.ladisales.com'
                            value={url}
                            onChange={this.onChangeInput}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadCheckoutImages: (form) => dispatch(fileActions.uploadCheckoutImages(form)),
    };
};

const mapStateToProps = (state) => ({
    productImage: { ...state.productImage },
    productTag: { ...state.productTag },
    store: { ...state.store },
    fileReducer: { ...state.file },
    product: { ...state.product },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(ModalAddImage)));
