import React from 'react';
import PropTypes from 'prop-types';
import { compact, map, includes } from 'lodash';

import * as settingTypes from '../../../redux/futures/setting/types';
import settingActions from '../../../redux/futures/setting/actions';

import { connect } from 'react-redux';
import Input from '../../../components/Input';
import Dropdown from '../../../components/Dropdown';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import LoadingScene from '../../../components/LoadingScene';
import ConfirmModal from '../../../components/ConfirmModal';
import queryString from 'query-string';

import LadiPagination from '../../../components/LadiPagination';
class ModalSettingNotification extends React.Component {
    // static propTypes = {
    //     addresses: PropTypes.array,
    //     visible: PropTypes.bool,
    //     onCancel: PropTypes.func,

    // }

    constructor(props) {
        super(props);

        this.state = {
            is_show_modal: false,
            mode: appConfig.FORM_MODE.CREATE,
            custom_field_id: '',
            name: '',
            label: '',
            group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE,
            data_type: '',
            data_values: '',
            data_list: {
                page: 1,
                limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
                keyword: '',
                group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE,
                sort: {},
            },
            valueSearch: '',
            isValidationName: true,
            isDuplicates: false,
        };

        this.inputsRef = new Set();
    }

    componentDidMount() {
        // this.init(this.props);
        this.props.listByCustomField(this.state.data_list);

        let urlParam = window.location.href;

        if (urlParam && urlParam.includes('?is_new=true')) {
            this.setState({
                is_show_modal: true,
            });
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.settingReducer.action != nextProps.settingReducer.action) {
            if (nextProps.settingReducer.action == settingTypes.SETTING_ADD_CUSTOM_FIELD) {
                if (nextProps.settingReducer.status) {
                    this.props.listByCustomField(this.state.data_list);

                    this.setState({
                        name: '',
                        label: '',
                        data_type: '',
                        is_show_modal: false,
                    });
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
            if (nextProps.settingReducer.action == settingTypes.SETTING_DELETE_CUSTOM_FIELD) {
                if (nextProps.settingReducer.status) {
                    this.props.listByCustomField(this.state.data_list);
                    window.LadiUI.closeModal('confirm-delete-custom-field');
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
            if (nextProps.settingReducer.action == settingTypes.SETTING_UPDATE_CUSTOM_FIELD) {
                if (nextProps.settingReducer.status) {
                    this.props.listByCustomField(this.state.data_list);
                    this.setState({
                        name: '',
                        label: '',
                        data_type: '',
                        is_show_modal: false,
                    });
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
        }
    }

    openModalCreate = () => {
        this.setState({
            name: '',
            label: '',
            data_type: '',
            data_values: '',
            mode: appConfig.FORM_MODE.CREATE,
            is_show_modal: true,
        });
    };
    hideModal = () => {
        this.setState({
            is_show_modal: false,
        });
    };

    handleChangeCusTomFieldDataTypes = (item) => {
        this.setState({
            data_type: item.CODE,
        });
    };

    handleChangeCusTomFieldGroupTypes = (item) => {
        this.setState({
            group_type: item.CODE,
        });
    };

    onChangeInput = (event, isValidate) => {
        const { value } = event.target;
        if (isValidate) {
            const dataValidates = ['name', 'email', 'phone', 'address', 'country', 'state', 'district', 'ward', 'message', 'coupon'];
            const expression = /[^A-Za-z0-9-_]/;
            const regex = new RegExp(expression);

            // Bạn chỉ có thể sử dụng 'a-z,A-Z, 0-9, -_
            if (value.match(regex)) {
                this.setState({ isValidationName: false });
            } else if (dataValidates.includes(value) && this.state.group_type == appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE) {
                this.setState({
                    isValidationName: true,
                    isDuplicates: true,
                });
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                    isValidationName: true,
                    isDuplicates: false,
                });
            }
        }
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    setGroupData = (value) => {
        this.setState(
            {
                group_type: value,
                data_list: {
                    ...this.state.data_list,
                    group_type: value,
                    page: 1,
                    paged: 1,
                },
            },
            () => {
                this.props.listByCustomField(this.state.data_list);
            }
        );
    };

    openModalConfirmDelete = (id) => {
        this.setState({
            custom_field_id: id,
        });
        window.LadiUI.showModal('confirm-delete-custom-field');
    };

    deleteCustomField = () => {
        const customField = {
            custom_field_id: this.state.custom_field_id,
        };

        this.props.handleDeleteCustomField(customField);
    };

    openModalEdit = async (item) => {
        await this.setState({
            custom_field_id: item.custom_field_id,
            name: item.name,
            label: item.label,
            data_values: item.data_values,
            group_type: item.group_type,
            data_type: item.data_type,
            is_show_modal: true,
            mode: appConfig.FORM_MODE.EDIT,
        });
    };

    submit = () => {
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { custom_field_id, name, label, group_type, data_type, data_values } = this.state;
        let element = document.getElementById('show-message-error');

        const data = {
            name,
            label,
            group_type,
            data_type,
        };

        if (data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE) {
            data.data_values = data_values;
        }

        if (this.state.mode == appConfig.FORM_MODE.CREATE) {
            this.props.addCustomField(data);
        } else {
            data.custom_field_id = custom_field_id;
            this.props.updateCustomField(data);
        }
    };

    handleChangeKeyPress = (event) => {
        if (event.key === 'Enter') {
            const { valueSearch } = this.state;

            this.setState(
                {
                    data_list: {
                        ...this.state.data_list,
                        keyword: valueSearch,
                    },
                },
                () => {
                    this.props.listByCustomField(this.state.data_list);
                }
            );
        }
    };

    onChangeInputSearch = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onPageChange = (pageNumber) => {
        this.setState(
            {
                data_list: {
                    ...this.state.data_list,
                    page: pageNumber,
                    paged: pageNumber,
                },
            },
            () => {
                this.props.listByCustomField(this.state.data_list);
            }
        );
    };

    onChangeLimit = (option) => {
        this.setState(
            {
                data_list: {
                    ...this.state.data_list,
                    limit: option.value,
                    page: 1,
                    paged: 1,
                },
            },
            () => {
                this.props.listByCustomField(this.state.data_list);
            }
        );
    };

    handleSearchCustomField = () => {
        const { valueSearch } = this.state;
        this.setState(
            {
                data_list: {
                    ...this.state.data_list,
                    keyword: valueSearch,
                },
            },
            () => {
                this.props.listByCustomField(this.state.data_list);
            }
        );
    };
    render() {
        const { is_show_modal, data_list, conditions } = this.state;
        const { customFields, totalRecord } = this.props.settingReducer;
        const { t, visible } = this.props;
        const { limit, page } = this.state.data_list;
        let fromItem = 0;
        let toItem = 0;
        if ((customFields || []).length > 0) {
            fromItem = (page - 1) * limit + 1;
            toItem = fromItem + customFields.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
            'PAGINATION.OF'
        )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;
        const isLoading =
            this.props.settingReducer.loading &&
            includes(
                [
                    settingTypes.SETTING_LIST_NOTIFICATION_EMAIL,
                    settingTypes.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL,
                    settingTypes.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG,
                ],
                this.props.settingReducer.waiting
            );
        const isLoadingDelete =
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_DELETE_NOTIFICATION_EMAIL], this.props.settingReducer.waiting);

        return (
            <div
                className='page-setting'
                id='page-setting-custom-field'
                // onOk={this.submit}
            >
                {isLoading && <LoadingScene blur={true} />}

                <div className='block-title-action'>
                    <h3 className='title'>{t('SETTINGS.CUSTOM_FIELD')}</h3>
                    <div className='btn-action'>
                        <button type='button' onClick={this.openModalCreate} className='ladiui btn btn-primary btn-sm'>
                            <img
                                className='ladiui btn-custom-img'
                                src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                alt=''
                            />
                            {t('CUSTOM_FIELD.ADD_CUSTOM_FIELD')}
                        </button>
                    </div>
                </div>

                <div className='ladiui search-keyword' style={{ margin: '14px 0px 24px 0px' }}>
                    <Input
                        name='valueSearch'
                        className='input-search form-control'
                        validationName={t('CUSTOM_FIELD.NAME')}
                        placeholder={t('CUSTOM_FIELD.SEARCH')}
                        value={this.state.valueSearch}
                        onChange={this.onChangeInputSearch}
                        onKeyPress={this.handleChangeKeyPress}
                    />
                    <i className='ladi-icon icon-zoom' onClick={this.handleSearchCustomField}></i>
                </div>
                <div className='ladiui tab'>
                    <ul className='ladiui nav nav-tabs' id='myTab' role='tablist'>
                        <li className='ladiui nav-item' role='presentation'>
                            <span
                                className='ladiui tab-link active'
                                data-toggle='tab'
                                data-parent='myTab'
                                data-target='tab-customer'
                                onClick={() => this.setGroupData('CUSTOMER')}
                                id='tab-customer'
                            >
                                {t('CUSTOM_FIELD.CUSTOMER')}
                            </span>
                        </li>
                        <li className='ladiui nav-item' role='presentation'>
                            <span
                                className='ladiui tab-link'
                                data-toggle='tab'
                                data-parent='myTab'
                                data-target='tab-order'
                                onClick={() => this.setGroupData('ORDER')}
                                id='tab-order'
                            >
                                {t('CUSTOM_FIELD.ORDER')}
                            </span>
                        </li>
                        <li className='ladiui nav-item' role='presentation'>
                            <span
                                className='ladiui tab-link'
                                data-toggle='tab'
                                data-parent='myTab'
                                data-target='tab-product'
                                onClick={() => this.setGroupData('PRODUCT')}
                                id='tab-product'
                            >
                                {t('CUSTOM_FIELD.PRODUCT')}
                            </span>
                        </li>
                    </ul>
                </div>

                <div>
                    <div className='ladi-card ladiui-table-responsive'>
                        <table className='ladiui table text-left'>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col'>{t('CUSTOM_FIELD.DISPLAY_NAME')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.FIELD_NAME')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.DATA_TYPES')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.CREATED_AT')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.UPDATED_AT')}</th>
                                    <th scope='col'>{/* {t('CUSTOM_FIELD.ACTIONS')} */}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {map(customFields, (item, index) => {
                                    return (
                                        <tr key={index} className='ladiui table-vertical main'>
                                            <td scope='row'>{item.label}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.NAME
                                                    : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.NAME
                                                    : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.NAME
                                                    : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.NAME
                                                    : appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.NAME}
                                            </td>
                                            <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                            <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                                            <td className='text-right pd-0'>
                                                <div className='ladiui btn-group'>
                                                    <div className='ladiui dropdown hide-mt ba-c'>
                                                        <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                            <i className='ladiui icon icon-ldp-dot'></i>
                                                        </button>
                                                        <ul className='ladiui dropdown-menu r-0'>
                                                            <li>
                                                                <a
                                                                    onClick={() => this.openModalEdit(item)}
                                                                    className='ladiui dropdown-item'
                                                                >
                                                                    {t('ACTIONS.EDIT')}
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    onClick={() => this.openModalConfirmDelete(item.custom_field_id)}
                                                                    className='ladiui dropdown-item'
                                                                >
                                                                    {t('ACTIONS.DELETE')}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {totalRecord <= 0 && (
                                    <tr className='text-center'>
                                        <td colSpan='100%'>{t('NO_DATA')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {totalRecord > 0 && (
                        <LadiPagination
                            conditionsProp={data_list}
                            listName={t('PAGINATION.ITEMS')}
                            message={''}
                            items={customFields}
                            total={totalRecord}
                            limitAction={this.onChangeLimit}
                            pageAction={this.onPageChange}
                        />
                    )}
                </div>

                {is_show_modal && (
                    <Modal
                        id='modal-custom-field'
                        title={
                            this.state.mode == appConfig.FORM_MODE.CREATE
                                ? t('CUSTOM_FIELD.CREATE_CUSTOM_FIELD')
                                : t('CUSTOM_FIELD.EDIT_CUSTOM_FIELD')
                        }
                        visible={is_show_modal}
                        onCancel={this.hideModal}
                        onOk={this.submit}
                        bodyClassName='position-initial'
                        width={450}
                        hasFooter={this.state.isValidationName && !this.state.isDuplicates}
                    >
                        <div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.GROUP_TYPE')}</label>
                                <Dropdown
                                    data={appConfig.CUSTOM_FIELD.GROUP_TYPES}
                                    onSelectItem={(item) => this.handleChangeCusTomFieldGroupTypes(item)}
                                    _key='CODE'
                                    _value='NAME'
                                    currentKey={this.state.group_type}
                                    placeHolder={t('CUSTOM_FIELD.ENTER_GROUP_TYPE')}
                                    wrapperClassName='w100 position-initial'
                                    idBlock='dropdown-customer-field'
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.LABEL')}</label>
                                <Input
                                    name='label'
                                    validationName={t('CUSTOM_FIELD.LABEL')}
                                    placeholder={t('CUSTOM_FIELD.LABEL')}
                                    value={this.state.label}
                                    onChange={this.onChangeInput}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.NAME')}</label>
                                <Input
                                    id='show-message-error'
                                    name='name'
                                    validationName={t('CUSTOM_FIELD.ENTER_NAME')}
                                    placeholder={t('CUSTOM_FIELD.ENTER_NAME')}
                                    value={this.state.name}
                                    onChange={(e, isValidate) => this.onChangeInput(e, (isValidate = true))}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />
                                {!this.state.isValidationName && (
                                    <span className='show-error-text'>{t('CUSTOM_FIELD.VALIDATION_NAME')}</span>
                                )}
                                {this.state.isDuplicates && <span className='show-error-text'>{t('CUSTOM_FIELD.DUPLICATE_NAME')}</span>}
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.DATA_TYPES')}</label>
                                <Dropdown
                                    data={appConfig.CUSTOM_FIELD.DATA_TYPES}
                                    onSelectItem={(item) => this.handleChangeCusTomFieldDataTypes(item)}
                                    currentKey={this.state.data_type}
                                    _key='CODE'
                                    _value='NAME'
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    placeHolder={t('CUSTOM_FIELD.DATA_TYPES')}
                                    validations={{ isRequired: true }}
                                    validationName={t('CUSTOM_FIELD.DATA_TYPES')}
                                    wrapperClassName='w100 position-initial'
                                    idBlock='dropdown-customer-field'
                                />
                            </div>

                            {this.state.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE && (
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('CUSTOM_FIELD.VALUE')}</label>
                                    <textarea
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        name='data_values'
                                        className='ladiui textarea'
                                        value={this.state.data_values}
                                        onChange={this.onChangeInput}
                                        placeholder={t('CUSTOM_FIELD.HELP_VALUE_DROPDOWN')}
                                        validations={{ isRequired: true }}
                                        validationName={t('CUSTOM_FIELD.ENTER_DROPDOWN_VALUE')}
                                    />
                                </div>
                            )}
                        </div>
                    </Modal>
                )}

                <ConfirmModal
                    id='confirm-delete-custom-field'
                    title={t('SETTINGS.MSG_DELETE_CUSTOM_FIELD')}
                    content={t('SETTINGS.MSG_DELETE_CUSTOM_FIELD_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={this.deleteCustomField}
                    isLoading={isLoadingDelete}
                    maxWidth={450}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addCustomField: (data) => dispatch(settingActions.addCustomField(data)),
        listByCustomField: (data) => dispatch(settingActions.listByCustomField(data)),
        handleDeleteCustomField: (data) => dispatch(settingActions.deleteCustomField(data)),
        updateCustomField: (data) => dispatch(settingActions.updateCustomField(data)),
    };
};

const mapStateToProps = (state) => ({
    settingReducer: { ...state.setting },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalSettingNotification)
);
