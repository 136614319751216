import React from "react";

import PropTypes from "prop-types";

class CustomInputDate extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    id: PropTypes.any,
    value: PropTypes.string,
  };

  render() {
    const { value, onClick, onChange, customPlaceholder, id, customClass } =
      this.props;
    return (
      <div onClick={onClick}>
        <input
          type="text"
          className={`ladiui form-control ${customClass ? customClass : ""}`}
          onChange={onChange}
          placeholder={customPlaceholder}
          value={value}
          id={id}
        />
        <i className="ladi-icon icon-calendar-date-2 ladi-date-picker-icon"></i>
      </div>
    );
  }
}
export default CustomInputDate;
