import React from 'react';
import '../../assets/css/style.css';
import '../../assets/css/custom.css';
import '../../assets/css/responsive.css';
import '../../assets/css/setting.css';
import '../../assets/css/discount.css';
import '../../assets/css/integrate.css';
import '../../assets/css/sdk.css';
import '../../assets/css/home_page.css';

// import '@nosferatu500/react-sortable-tree/style.css';

import baseHelper from '../../helpers/BaseHelper';

import SideBar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { map } from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';

import storeActions from '../../redux/futures/store/actions';

import { connect } from 'react-redux';
import routes from '../../routes';

import { useLocation } from 'react-router-dom';
import ModalIntroduceVietQr from '../../components/ModalIntroduceVietQr';

import queryString from 'query-string';

import appConfig from '../../config/app';

class AppLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowModalIntroduceVietQr: false,
            isFrame: true,
        };
    }

    handler = (ev) => {
        if (typeof ev.data !== 'object') return;
        if (!ev.data.type) return;

        if (ev.data.type == 'LADIPAGE_CHANGE_URL') {
            let parser = ev.data;

            let url = ev.data.url;
            this.props.history.push(url);

            // if (parser.is_iframe) {
            //     if (parser.is_iframe == 'true') {
            //         baseHelper.setCookie(appConfig.COOKIE.IS_IFRAME, parser.is_iframe);
            //     }
            // }
            // if (parser.ladi_uid_owner) {
            //     baseHelper.setCookie(appConfig.COOKIE.LADI_UID_OWNER, parser.ladi_uid_owner);
            // }

            // if (parser.ladipage_store_id) {
            //     baseHelper.setCookie(appConfig.COOKIE.LADIPAGE_STORE_ID, parser.ladipage_store_id);
            // }
        }

        if (ev.data.type == 'LADIPAGE_CHANGE_WIDTH') {
            if (ev.data.width) document.body.style.setProperty('--ladipage-width', `${ev.data.width}px`);
        }
    };

    componentDidMount() {
        window.addEventListener('message', this.handler);
    }

    componentWillUnmount() {
        // you need to unbind the same listener that was binded.
        window.removeEventListener('message', null);
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    onOkNotification = () => {
        baseHelper.setCookie('NOTIFICATION_CREDIT', true, 86400);
        window.LadiUI.closeModal('confirm-notification-credit');
        window.LadiChat.push([['do', 'chat:open']]);
    };

    openNotification = () => {
        setTimeout(() => {
            window.LadiUI.showModal('confirm-notification-credit');
        }, 2000);
    };

    render() {
        const { scopes, userInfo } = this.props.store;
        let url = window.location.href;
        const { isFrame } = this.state;

        let isPageCheckIn = url.includes('/checkin');
        let isPageCheckQrCode = url.includes('/check-qrcode');
        let isPageCheckOutCreate = url.includes('/page-checkout/create');
        // let isPageCheckOutUpdate = url.includes('/page-checkout/update/');

        let isShowSideBar = isPageCheckIn || isPageCheckQrCode || isPageCheckOutCreate;

        let isDomainIPay = url.includes('app.ipay.com.vn');

        if (isDomainIPay) {
            document.title = 'iPay.com.vn - Nền tảng quản lý bán hàng thương mại điện tử';
        }

        const availableRoutes = routes.getRoutesByScopes(scopes, userInfo.is_show_course);
        // if (!notificationCredit && store && (store.app_credit <= 30)) {
        //     if (window.LadiUI) {
        //         this.openNotification();
        //     }
        // }

        const pageCheckIn = queryString.parse(this.props.location.search);

        return (
            // <section className='ladiui wrapper' style={isPageCheckQrCode || isPageCheckIn ? { minWidth: 'auto' } : {}}>
            // <section className={`ladiui wrapper ${isFrame ? 'iframe-open' : ''}`} style={isShowSideBar ? { minWidth: 'auto' } : {}}>
            <section className={`ladiui wrapper iframe-open`} style={isShowSideBar ? { minWidth: 'auto' } : {}}>
                {/* {!isPageCheckQrCode && !isPageCheckIn && <SideBar scopes={scopes} userInfo={userInfo} isDomainIPay={isDomainIPay} />} */}
                {!isShowSideBar && <SideBar scopes={scopes} userInfo={userInfo} isDomainIPay={isDomainIPay} />}
                <div
                    className='ladiui main-custom'
                    id='elm-main'
                    // style={isPageCheckQrCode || isPageCheckIn ? { marginLeft: 0, width: '100%' } : {}}
                    style={isShowSideBar ? { marginLeft: 0, width: '100%' } : {}}
                >
                    {/* {!isPageCheckQrCode && !isPageCheckIn && <Topbar availableRoutes={availableRoutes} />} */}
                    {/* <div className='ladiui content-main-full' style={isPageCheckQrCode || isPageCheckIn ? { padding: 0 } : {}}> */}
                    <div className='ladiui content-main-full' style={isShowSideBar ? { padding: 0 } : {}}>
                        <Switch>
                            {map(availableRoutes, (route, index) => (
                                <Route key={index} path={route.path} exact={route.exact} component={route.component} />
                            ))}
                            <Route render={() => <Redirect to='/orders' />} />
                        </Switch>
                    </div>
                    <Footer />
                </div>
                <ModalIntroduceVietQr
                    onClose={() => {
                        this.setState({
                            isShowModalIntroduceVietQr: !this.state.isShowModalIntroduceVietQr,
                        });
                    }}
                    isVietQrModal={true}
                    isShow={this.state.isShowModalIntroduceVietQr}
                />
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: (storeID) => dispatch(storeActions.getUserInfo(storeID)),
    };
};

const mapStateToProps = (state) => ({
    store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
