import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import NoData from "./NoDataV4";
import i18n from '../../../i18n';

class EmptyDataCustom extends React.Component {
    static propTypes = {
        status: PropTypes.string,
        title: PropTypes.string,
        icon: PropTypes.element,
        searching: PropTypes.bool,
    }

    static defaultProps = {
        status: "Mô tả",
        title: "Title no data",
        icon: <i className="ladiui icon new-ldicon-list"></i>,
        searching: false,
    }

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let {
            status, title, icon, searching,
        } = this.props;
        if (searching) {
            status = i18n.t('Thay đổi hoặc gỡ bỏ bộ lọc hiện tại để tìm kiếm kết quả bạn cần');
            title = i18n.t('Không tìm thấy kết quả nào');
            icon = <i className="ladiui icon new-ldicon-search"></i>;
        }
        return (
            <div className='no-data-wrapper'>
                <table className="ladiui text-left">
                    <tbody>
                        <NoData column={8} status={status} title={title} icon={icon} />
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmptyDataCustom));
