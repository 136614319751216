import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';
import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import DateRangePickerCompare from '../../components/DateRangePickerCompare';

import reportActions from '../../redux/futures/report/actions';
import * as reportTypes from '../../redux/futures/report/types';

import storePageActions from '../../redux/futures/store_page/actions';
import * as storePageTypes from '../../redux/futures/store_page/types';

import ReportTopProduct from './components/ReportTopProductV4';
import ReportBySource from './components/ReportBySourceV4';
import ReportByUTM from './components/ReportByUtm';
import ReportRevenue from './components/ReportRevenueV4';
import ReportByOrder from './components/ReportByOrder';
import { find, map } from 'lodash';

export default function ReportV4(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const _moment = baseHelper.getMoment();

    const now = _moment();
    const firstDay = now.set({
        date: 1,
    });

    let _firstDay = _moment().set({
        date: 1,
    });
    let _days = _moment().diff(firstDay, 'days');
    let _toDateCompare = _firstDay.subtract(1, 'days');
    let _fromDateCompare = _moment(_toDateCompare).subtract(_days, 'days');

    const reportReducer = useSelector((state) => state.report);
    const storePageReducer = useSelector((state) => state.storePage);
    const storeReducer = useSelector((state) => state.store);

    const { search } = useLocation();

    const [toDate, setToDate] = useState(_moment());
    const [fromDate, setFromDate] = useState(firstDay);

    const [fromDateCompare, setFromDateCompare] = useState(_fromDateCompare);
    const [toDateCompare, setToDateCompare] = useState(_toDateCompare);

    const [numOrderDays, setNumOrderDays] = useState([]);
    const [revenueDay, setRevenueDay] = useState([]);
    const [revenueByStaff, setRevenueByStaff] = useState([]);
    const [revenueSource, setRevenueSource] = useState([]);
    const [reportByStaff, setReportByStaff] = useState([]);
    const [storePages, setStorePages] = useState([]);
    const [pageCheckoutId, setPageCheckoutId] = useState('');

    const [revenueDayCompare, setRevenueDayCompare] = useState([]);
    const [numOrderDaysCompare, setNumOrderDaysCompare] = useState([]);
    const [reportByStaffCompare, setReportByStaffCompare] = useState([]);

    const [focusInput, setFocusInput] = useState(null);
    const [focusInputCompare, setFocusInputCompare] = useState(null);

    const [valueRevenue, setValueRevenue] = useState(0);
    const [valueOrderMedium, setValueOrderMedium] = useState(0);
    const [valueOrderSuccess, setValueOrderSuccess] = useState(0);
    const [valueOrderCancel, setValueOrderCancel] = useState(0);

    const [valueRevenueCompare, setValueRevenueCompare] = useState(0);
    const [valueOrderMediumCompare, setValueOrderMediumCompare] = useState(0);
    const [valueOrderSuccessCompare, setValueOrderSuccessCompare] = useState(0);
    const [valueOrderCancelCompare, setValueOrderCancelCompare] = useState(0);

    const [percentRevenue, setPercentRevenue] = useState(0);
    const [percentOrderMedium, setPercentOrderMedium] = useState(0);
    const [percentOrderSuccess, setPercentOrderSuccess] = useState(0);
    const [percentOrderCancel, setPercentOrderCancel] = useState(0);

    const [statusRevenue, setStatusRevenue] = useState(false);
    const [statusOrderMedium, setStatusOrderMedium] = useState(false);
    const [statusOrderSuccess, setStatusOrderSuccess] = useState(false);
    const [statusOrderCancel, setStatusOrderCancel] = useState(false);

    const [isCompare, setIsCompare] = useState(1);

    useEffect(() => {
        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        let newFromDate = fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;
        let newToDate = toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;

        let newFromDateCompare = fromDateCompare
            ? moment(new Date(fromDateCompare).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME)
            : null;
        let newToDateCompare = toDateCompare ? moment(new Date(toDateCompare).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;

        // let payloadReport = {}
        // this.props.overviewReport(newFromDate, newToDate, pageCheckoutId);

        let payload = {
            from_date: newFromDate,
            to_date: newToDate,
            page_checkout_id: pageCheckoutId,
            compare: {
                from_date: newFromDateCompare,
                to_date: newToDateCompare,
            },
        };

        dispatch(reportActions.overviewReport(payload));

        dispatch(storePageActions.storePageList(dataListStore));
    }, []);

    useEffect(() => {
        if (fromDate && toDate) {
            let newFromDate = fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;
            let newToDate = toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null;

            let newFromDateCompare = fromDateCompare
                ? moment(new Date(fromDateCompare).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME)
                : null;
            let newToDateCompare = toDateCompare
                ? moment(new Date(toDateCompare).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME)
                : null;

            let payload = {
                from_date: newFromDate,
                to_date: newToDate,
                page_checkout_id: pageCheckoutId,
                compare: {
                    from_date: newFromDateCompare,
                    to_date: newToDateCompare,
                },
            };

            dispatch(reportActions.overviewReport(payload));
        }
    }, [fromDate, toDate, pageCheckoutId, fromDateCompare, toDateCompare]);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_LIST) {
            if (storePageReducer.status) {
                setStorePages(storePageReducer.store_pages || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    useEffect(() => {
        if (reportReducer.action == reportTypes.OVERVIEW_REPORT) {
            if (reportReducer.status) {
                let dataReport = reportReducer.data;
                let dataReportCompare = reportReducer.data.report_compare;

                let _valueRevenue = dataReport.total_revenue || 0;
                let _valueOrderMedium = dataReport.average_order || 0;
                let _valueOrderSuccess = dataReport.total_order_success || 0;
                let _valueOrderCancel = dataReport.total_order_cancel || 0;

                let _valueRevenueCompare = dataReportCompare.total_revenue || 0;
                let _valueOrderMediumCompare = dataReportCompare.average_order || 0;
                let _valueOrderSuccessCompare = dataReportCompare.total_order_success || 0;
                let _valueOrderCancelCompare = dataReportCompare.total_order_cancel || 0;

                setRevenueDay(dataReport.revenue_day || []);
                setNumOrderDays(dataReport.num_orders_day || []);
                setRevenueByStaff(dataReport.revenue_by_staff || []);
                setRevenueSource(dataReport.revenue_source || []);
                setReportByStaff(dataReport.report_by_staff || []);

                setRevenueDayCompare(dataReportCompare.revenue_day || []);
                setNumOrderDaysCompare(dataReportCompare.num_orders_day || []);
                setReportByStaffCompare(dataReportCompare.report_by_staff || []);

                setValueRevenue(_valueRevenue);
                setValueOrderMedium(_valueOrderMedium.toFixed(0));
                setValueOrderSuccess(_valueOrderSuccess);
                setValueOrderCancel(_valueOrderCancel);

                setValueRevenueCompare(_valueRevenueCompare);
                setValueOrderMediumCompare(_valueOrderMediumCompare.toFixed(0));
                setValueOrderSuccessCompare(_valueOrderSuccessCompare);
                setValueOrderCancelCompare(_valueOrderCancelCompare);

                let _percentRevenue =
                    ((_valueRevenue - _valueRevenueCompare) * 100) / (_valueRevenueCompare > 0 ? _valueRevenueCompare : 1);
                let _percentOrderMedium =
                    ((_valueOrderMedium - _valueOrderMediumCompare) * 100) / (_valueOrderMediumCompare > 0 ? _valueOrderMediumCompare : 1);
                let _percentOrderSuccess =
                    ((_valueOrderSuccess - _valueOrderSuccessCompare) * 100) /
                    (_valueOrderSuccessCompare > 0 ? _valueOrderSuccessCompare : 1);
                let _percentOrderCancel =
                    ((_valueOrderCancel - _valueOrderCancelCompare) * 100) / (_valueOrderCancelCompare > 0 ? _valueOrderCancelCompare : 1);

                setPercentRevenue(_percentRevenue);
                setPercentOrderMedium(_percentOrderMedium);
                setPercentOrderSuccess(_percentOrderSuccess);
                setPercentOrderCancel(_percentOrderCancel);

                if (_valueRevenue > _valueRevenueCompare || _valueRevenue == _valueRevenueCompare) {
                    setStatusRevenue(true);
                } else {
                    setStatusRevenue(false);
                }

                if (_valueOrderMedium > _valueOrderMediumCompare || _valueOrderMedium == _valueOrderMediumCompare) {
                    setStatusOrderMedium(true);
                } else {
                    setStatusOrderMedium(false);
                }
                if (_valueOrderSuccess > _valueOrderSuccessCompare || _valueOrderSuccess == _valueOrderSuccessCompare) {
                    setStatusOrderSuccess(true);
                } else {
                    setStatusOrderSuccess(false);
                }
                if (_valueOrderCancel > _valueOrderCancelCompare || _valueOrderCancel == _valueOrderCancelCompare) {
                    setStatusOrderCancel(false);
                } else {
                    setStatusOrderCancel(true);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', reportReducer.message, 'OK');
            }
        }
    }, [reportReducer]);

    const getRoleStaff = (ladiUid) => {
        let role = 'Chưa xác định';
        let listStaffs = storeReducer.userInfo.staffs || [];

        let findItem = listStaffs.find((item) => item.ladi_uid == ladiUid);

        if (findItem) {
            switch (findItem.role) {
                case 'MANAGER':
                    role = 'Quản lý';
                    break;
                case 'OWNER':
                    role = 'Chủ sở hữu';
                    break;
                case 'STAFF':
                    role = 'Nhân viên';
                    break;
                default:
                    role = 'Chưa xác định';
                    break;
            }
        }

        return role;
    };

    return (
        <div className='page-report' id='page-report-new'>
            <div className='menu-header'>
                <div className='content'>
                    <div className='title'>
                        <h3>Báo cáo tổng quan</h3>
                        <span>Theo dõi hoạt động kinh doanh của cửa hàng</span>
                    </div>
                    <div className='actions block-search'>
                        <div className='ladiui flex-row'>
                            <div className='ladiui btn-group'>
                                <div className='ladiui dropdown'>
                                    <button
                                        className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                        data-toggle='dropdown'
                                        style={{ minWidth: 200, maxWidth: 200 }}
                                    >
                                        <i className='ladiui icon'></i>
                                        <span
                                            className='ladiui dropdown-text'
                                            style={{
                                                WebkitLineClamp: 1,
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {'Chọn cửa hàng'}
                                        </span>
                                    </button>
                                    <ul className='ladiui dropdown-menu'>
                                        <li
                                            onClick={() => {
                                                setPageCheckoutId('');
                                            }}
                                        >
                                            <a className='ladiui dropdown-item' href='#'>
                                                Tất cả cửa hàng
                                            </a>
                                        </li>
                                        {storePages.map((item, index) => (
                                            <li
                                                key={index}
                                                onClick={() => {
                                                    if (item.page_checkout_id) {
                                                        setPageCheckoutId(item.page_checkout_id);
                                                    }
                                                }}
                                            >
                                                <a className='ladiui dropdown-item' href='#'>
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <OrderDateRangePicker
                            startDateId='fromDate'
                            endDateId='toDate'
                            startDate={fromDate}
                            endDate={toDate}
                            onDatesChange={({ startDate, endDate }) => {
                                setFromDate(baseHelper.getFromDate(startDate));
                                setToDate(baseHelper.getToDate(endDate));
                            }}
                            focusedInput={focusInput}
                            onFocusChange={(focusedInput) => {
                                setFocusInput(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition='after'
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => {
                                return _moment().isSame(date, 'day');
                            }}
                            minimumNights={0}
                            showClearDates={true}
                            anchorDirection={'right'}
                        />
                        <DateRangePickerCompare
                            startDateId='fromDate'
                            endDateId='toDate'
                            currentStartDate={fromDate}
                            currentEndDate={toDate}
                            startDate={fromDateCompare}
                            endDate={toDateCompare}
                            onDatesChange={({ startDate, endDate }) => {
                                setFromDateCompare(baseHelper.getFromDate(startDate));
                                setToDateCompare(baseHelper.getToDate(endDate));
                            }}
                            setIsCompare={(code) => {
                                setIsCompare(code);
                            }}
                            focusedInput={focusInputCompare}
                            onFocusChange={(focusedInput) => {
                                setFocusInputCompare(focusedInput);
                            }}
                            showDefaultInputIcon={true}
                            inputIconPosition='after'
                            small={true}
                            hideKeyboardShortcutsPanel={true}
                            customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                            startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                            endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                            isOutsideRange={() => false}
                            isDayHighlighted={(date) => {
                                return _moment().isSame(date, 'day');
                            }}
                            minimumNights={0}
                            showClearDates={true}
                            anchorDirection={'right'}
                        />
                    </div>
                </div>
            </div>
            <div className='page-content-report'>
                <div className='ladiui page-content content-max-width'>
                    <div className='list-block-overviews'>
                        <div className='block-item'>
                            <div className='title'>
                                <div className='flex items-center'>
                                    <div className='icon'>
                                        <i className='new-ldicon-money-fill' />
                                    </div>
                                    <div className='text space-nowrap'>Doanh thu thuần</div>
                                </div>
                                {valueRevenueCompare && valueRevenueCompare > 0 ? (
                                    <div className='percent flex items-center'>
                                        {/* <i className='new-ldicon-arrow-up-fill'></i> */}
                                        {statusRevenue ? (
                                            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z'
                                                    fill='#35A500'
                                                />
                                            </svg>
                                        ) : (
                                            <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z'
                                                    fill='#E01A1A'
                                                />
                                            </svg>
                                        )}

                                        <span className={`space-nowrap ${statusRevenue ? 'percent-up' : 'percent-down'}`}>
                                            {baseHelper.formatMoneyPostFix(percentRevenue.toFixed(0), '%')}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='total-overview'>
                                <strong>{baseHelper.formatMoneyPostFix(valueRevenue)}</strong>
                                {valueRevenueCompare && valueRevenueCompare > 0 ? (
                                    <span>Doanh thu cũ: {baseHelper.formatMoneyPostFix(valueRevenueCompare)} </span>
                                ) : (
                                    <span>
                                        Doanh thu cũ: <span style={{ color: '#E01A1A' }}>Chưa có doanh thu</span>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='block-item'>
                            <div className='title'>
                                <div className='flex items-center'>
                                    <div className='icon'>
                                        <i className='new-ldicon-wallet-fill' />
                                    </div>
                                    <div className='text space-nowrap'>Giá trị trung bình</div>
                                </div>
                                {valueOrderMediumCompare && valueOrderMediumCompare > 0 ? (
                                    <div className='percent flex items-center'>
                                        {/* <i className='new-ldicon-arrow-up-fill'></i> */}
                                        {statusOrderMedium ? (
                                            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z'
                                                    fill='#35A500'
                                                />
                                            </svg>
                                        ) : (
                                            <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z'
                                                    fill='#E01A1A'
                                                />
                                            </svg>
                                        )}
                                        <span className={`space-nowrap ${statusOrderMedium ? 'percent-up' : 'percent-down'}`}>
                                            {baseHelper.formatMoneyPostFix(percentOrderMedium.toFixed(0), '%')}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='total-overview'>
                                <strong>{baseHelper.formatMoneyPostFix(valueOrderMedium)}</strong>
                                {valueOrderMediumCompare && valueOrderMediumCompare > 0 ? (
                                    <span>Doanh thu cũ: {baseHelper.formatMoneyPostFix(valueOrderMediumCompare)} </span>
                                ) : (
                                    <span>
                                        Doanh thu cũ: <span style={{ color: '#E01A1A' }}>Chưa có doanh thu</span>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='block-item'>
                            <div className='title'>
                                <div className='flex items-center'>
                                    <div className='icon'>
                                        <i className='new-ldicon-money-fill' />
                                    </div>
                                    <div className='text space-nowrap'>Đơn thành công</div>
                                </div>
                                {valueOrderSuccessCompare && valueOrderSuccessCompare > 0 ? (
                                    <div className='percent flex items-center'>
                                        {/* <i className='new-ldicon-arrow-up-fill'></i> */}
                                        {statusOrderSuccess ? (
                                            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z'
                                                    fill='#35A500'
                                                />
                                            </svg>
                                        ) : (
                                            <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z'
                                                    fill='#E01A1A'
                                                />
                                            </svg>
                                        )}
                                        <span className={`space-nowrap ${statusOrderSuccess ? 'percent-up' : 'percent-down'}`}>
                                            {baseHelper.formatMoneyPostFix(percentOrderSuccess.toFixed(0), '%')}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='total-overview'>
                                <strong>{valueOrderSuccess} đơn</strong>
                                {valueOrderSuccessCompare && valueOrderSuccessCompare > 0 ? (
                                    <span>Số đơn cũ: {valueOrderSuccessCompare} đơn</span>
                                ) : (
                                    <span>
                                        Số đơn cũ: <span style={{ color: '#E01A1A' }}>Chưa có đơn hàng</span>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='block-item'>
                            <div className='title'>
                                <div className='flex items-center'>
                                    <div className='icon'>
                                        <i className='new-ldicon-money-fill' />
                                    </div>
                                    <div className='text space-nowrap'>Đơn bị bủy</div>
                                </div>
                                {valueOrderCancelCompare && valueOrderCancelCompare > 0 ? (
                                    <div className='percent flex items-center'>
                                        {/* <i className='new-ldicon-arrow-up-fill'></i> */}
                                        {!statusOrderCancel ? (
                                            <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z'
                                                    fill='#35A500'
                                                />
                                            </svg>
                                        ) : (
                                            <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                <path
                                                    d='M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z'
                                                    fill='#E01A1A'
                                                />
                                            </svg>
                                        )}
                                        <span className={`space-nowrap ${!statusOrderCancel ? 'percent-up' : 'percent-down'}`}>
                                            {baseHelper.formatMoneyPostFix(percentOrderCancel.toFixed(0), '%')}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className='total-overview'>
                                <strong>{valueOrderCancel} đơn</strong>
                                {valueOrderCancelCompare && valueOrderCancelCompare > 0 ? (
                                    <span>Số đơn cũ: {valueOrderCancelCompare} đơn</span>
                                ) : (
                                    <span>
                                        Số đơn cũ: <span style={{ color: '#E01A1A' }}>Chưa có đơn hủy</span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='content-overview'>
                        <div className='content-left'>
                            <ReportRevenue
                                revenue_day={revenueDay}
                                revenue_day_compare={revenueDayCompare}
                                fromDate={fromDate}
                                toDate={toDate}
                                fromDateCompare={fromDateCompare}
                                toDateCompare={toDateCompare}
                                isCompare={isCompare}
                            />
                            <ReportByOrder
                                num_orders_day={numOrderDays}
                                num_orders_day_compare={numOrderDaysCompare}
                                queryOverTime={`from=${fromDate}&to=${toDate}`}
                                fromDate={fromDate}
                                toDate={toDate}
                                toDateCompare={toDateCompare}
                                fromDateCompare={fromDateCompare}
                                isCompare={isCompare}
                            />
                            <ReportTopProduct
                                fromDate={fromDate}
                                toDate={toDate}
                                fromDateCompare={fromDateCompare}
                                toDateCompare={toDateCompare}
                                pageCheckoutId={pageCheckoutId}
                            />
                        </div>
                        <div className='content-right'>
                            <ReportBySource revenue_source={revenueSource} />
                            <ReportByUTM />
                            <div className='block-content-item mt-20'>
                                <h3 className='mb-12'>Top nhân viên bán hàng</h3>
                                <div className='block-list-staffs'>
                                    {map(reportByStaff, (item, index) => {
                                        let _percentTotal = 0;
                                        let findItem = reportByStaffCompare.find((_item) => _item.ladi_uid == item.ladi_uid);

                                        if (findItem) {
                                            _percentTotal =
                                                ((item.total - findItem.total) * 100) / (findItem.total > 0 ? findItem.total : 1);
                                        }
                                        return (
                                            <div className='staff-item' key={index}>
                                                <div className='info'>
                                                    <div className='image'>
                                                        <img src='https://w.ladicdn.com/ladiui/ladisales/no-image.svg' />
                                                    </div>
                                                    <div className='name'>
                                                        <p>{item.first_name}</p>
                                                        <span>{getRoleStaff(item.ladi_uid)}</span>
                                                    </div>
                                                </div>
                                                <div className='total-price'>
                                                    <strong className='space-nowrap'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            item.total - item.shipping_fee - item.refund,
                                                            storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </strong>
                                                    {reportByStaffCompare && reportByStaffCompare.length > 0 && (
                                                        <div className='flex items-center mt-4 gap-4'>
                                                            {/* <i className='new-ldicon-arrow-down-fill' /> */}
                                                            {findItem && findItem.total > item.total ? (
                                                                <svg
                                                                    width='12'
                                                                    height='13'
                                                                    viewBox='0 0 12 13'
                                                                    fill='none'
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                >
                                                                    <path
                                                                        d='M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z'
                                                                        fill='#E01A1A'
                                                                    />
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    width='16'
                                                                    height='16'
                                                                    viewBox='0 0 16 16'
                                                                    fill='none'
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                >
                                                                    <path
                                                                        d='M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z'
                                                                        fill='#35A500'
                                                                    />
                                                                </svg>
                                                            )}
                                                            <span
                                                                className={`${
                                                                    findItem && findItem.total > item.total ? 'percent-down' : 'percent-up'
                                                                }`}
                                                            >
                                                                {baseHelper.formatMoneyPostFix(_percentTotal, '%')}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
