import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import { Editor } from '@tinymce/tinymce-react';
import Input from '../../../../components/Input';
import BaseHelper from '../../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function

export const ProductNameDes = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const nameRef = useRef();

    const { productData, setSelectedTabIndex, selectedTabIndex } = props;

    const [product, setProductData] = useState({});

    useImperativeHandle(ref, () => ({
        getData: () => {
            let productTg = {};
            if (!product.name) {
                return {
                    error: t('PRODUCTS.NAME_EMPTY', t('PRODUCTS.DIGITAL.DIGITAL').toLowerCase()),
                };
            }
            return product;
        },
        scrollIntoView: () => {
            nameRef.current.classList.add('error-empty');
            // nameRef.current.scrollIntoView({ behavior: 'smooth' });
        },
    }));
    useEffect(() => {
        if (productData) {
            setProductData({
                ...productData,
            });
        }
    }, [productData]);

    const handleChangeInput = (e) => {
        let { name, value } = e.target;
        if (name == 'name' && !BaseHelper.isEmpty(value)) {
            nameRef.current.classList.remove('error-empty');
        }
        setProductData({
            ...product,
            [name]: value,
        });
    };

    const handleEditorChange = (value) => {
        setProductData({
            ...product,
            description: value,
        });
    };

    return (
        <div className='ladiui block-content-item scroll-mt-150' ref={nameRef}>
            <h3>{t('PRODUCTS.DIGITAL.LABEL')}</h3>
            <div className='ladiui form-group '>
                <label className='ladiui-label new'>{t('PRODUCTS.DIGITAL.LB_NAME')}</label>
                <Input
                    className='ladiui form-control'
                    placeholder={t('PRODUCTS.DIGITAL.PH_NAME')}
                    name='name'
                    value={product.name}
                    onChange={(event) => handleChangeInput(event)}
                    validationName={t('PRODUCTS.DIGITAL.VLD_NAME')}
                    validations={{ isRequired: true }}
                />
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label new'>{t('PRODUCTS.SORT_DESCRIPTION')}</label>
                <Input
                    className='ladiui form-control'
                    placeholder={t('PRODUCTS.PH_SORT_DESCRIPTION')}
                    name='short_description'
                    value={product.short_description}
                    onChange={(event) => handleChangeInput(event)}
                />
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label new'>{t('PRODUCTS.DESCRIPTION')}</label>
                <Editor
                    tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                    licenseKey="gpl"
                    value={product.description}
                    init={{
                        selector: 'textarea',
                        min_height: 200,
                        max_height: 800,
                        autoresize_overflow_padding: 5,
                        autoresize_bottom_margin: 24,
                        plugins:
                            'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                        toolbar:
                            'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                        toolbar_drawer: 'sliding',
                        language_url: '../../../language/tinymce-vi.js',
                        content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',

                        // valid_elements: '*[*]',
                    }}
                    onEditorChange={handleEditorChange}
                />
            </div>
        </div>
    );
});
