import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.LIST_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const create = (data) => ({
    type: types.CREATE_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.CREATE_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const createV2 = (data) => ({
    type: types.CREATE_PAGE_CHECKOUT_V2,
    meta: {
        endpoint: endpoint.CREATE_PAGE_CHECKOUT_V2,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const update = (data) => ({
    type: types.UPDATE_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.UPDATE_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const updateV2 = (data) => ({
    type: types.UPDATE_PAGE_CHECKOUT_V2,
    meta: {
        endpoint: endpoint.UPDATE_PAGE_CHECKOUT_V2,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const show = (pageCheckoutID) => ({
    type: types.SHOW_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.SHOW_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            page_checkout_id: pageCheckoutID,
        },
        hasAuth: true,
    },
});

const showV2 = (pageCheckoutID) => ({
    type: types.SHOW_PAGE_CHECKOUT_V2,
    meta: {
        endpoint: endpoint.SHOW_PAGE_CHECKOUT_V2,
        method: REQUEST_METHOD.POST,
        body: {
            page_checkout_id: pageCheckoutID,
        },
        hasAuth: true,
    },
});

const reload = (pageCheckoutID) => ({
    type: types.RELOAD_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.SHOW_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            page_checkout_id: pageCheckoutID,
        },
        hasAuth: true,
    },
});

const _delete = (pageCheckoutID) => ({
    type: types.DELETE_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.DELETE_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            page_checkout_id: pageCheckoutID,
        },
        hasAuth: true,
    },
});

const deletes = (pageCheckoutIDs) => ({
    type: types.DELETES_PAGE_CHECKOUT,
    meta: {
        endpoint: endpoint.DELETES_PAGE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            page_checkout_ids: pageCheckoutIDs,
        },
        hasAuth: true,
    },
});

const pageCourseShow = () => ({
    type: types.PAGE_COUSE_SHOW,
    meta: {
        endpoint: endpoint.PAGE_COURSE_SHOW,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
});
const updateName = (data) => ({
    type: types.PAGE_CHECKOUT_UPDATE_NAME,
    meta: {
        endpoint: endpoint.PAGE_CHECKOUT_UPDATE_NAME,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const activeAffiliate = (data) => ({
    type: types.PAGE_COURSE_ACTIVE_AFFILIATE,
    meta: {
        endpoint: endpoint.PAGE_COURSE_ACTIVE_AFFILIATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

export default {
    list,
    create,
    createV2,
    show,
    showV2,
    reload,
    update,
    updateV2,
    delete: _delete,
    deletes,
    pageCourseShow,
    updateName,
    activeAffiliate
};
