/** @format */

import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link, NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';
import config from '../../config/config';

import * as pageCheckoutTypes from '../../redux/futures/page_checkout/types';
import pageCheckoutActions from '../../redux/futures/page_checkout/actions';
import LoadingTable from '../../components/LoadingTable';
import ConfirmModal from '../../components/ConfirmModal';
import ModalPageCheckout from './components/ModalPageCheckout';
import LadiPagination from '../../components/LadiPagination';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';

import { includes, map, remove } from 'lodash';

import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import ModalInfoApiKey from '../../components/ModalInfoApiKey';
import ModalCreateSalesChannel from './components/ModalCreateSalesChannel';
import Dropdown from '../../components/Dropdown';

export default function PageCheckout(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const isLoading = useMemo(() => {
        return pageCheckoutReducer.loading || false;
    }, [pageCheckoutReducer.loading]);

    const [activePage, setActivePage] = useState(1);
    const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);

    const [selectedIDs, setSelectedIDs] = useState([]);
    const [isShowModalCreateSalesChannel, setShowModalCreateSalesChannel] = useState(false);

    const [selectedApiKey, setSelectedApiKey] = useState('');
    const [isShowModalInfoApiKey, setIsShowModalInfoApiKey] = useState(false);
    const [tabSelected, setTabSelected] = useState(1);
    const [searchName, setSearchName] = useState('');
    const [isFrame, setIsFrame] = useState(true);
    const [pageTypeSelect, setPageTypeSelect] = useState('');

    const [sortBy, setSortBy] = useState({
        page_checkout_id: 'DESC',
    });

    let selectedID = '';

    useEffect(() => {
        if (queryStrings) {
            let tab = queryStrings.get('tab');
            if (tab) {
                setTabSelected(Number(tab));
            }

            let is_create = queryStrings.get('is_create');

            if (is_create) {
                setShowModalCreateSalesChannel(true);
            }
        }

        if (queryStrings && queryStrings.has('isShowApiKey')) {
            setSelectedApiKey(queryStrings.get('api_key'));
            setIsShowModalInfoApiKey(true);
        }
    }, [queryStrings]);

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            list(false);
        }, 200);
        return () => clearTimeout(delayInputTimeoutId);
    }, [selectedLimit, activePage]);

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
            list(true);
        }, 200);
        return () => clearTimeout(delayInputTimeoutId);
    }, [tabSelected, pageTypeSelect]);

    useEffect(() => {
        if ([pageCheckoutTypes.DELETE_PAGE_CHECKOUT, pageCheckoutTypes.DELETES_PAGE_CHECKOUT].includes(pageCheckoutReducer.action)) {
            if (pageCheckoutReducer.status) {
                list();
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                window.LadiUI.closeModal('confirm-page-checkout-delete');
                window.LadiUI.closeModal('confirm-page-checkout-deletes');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    const list = (resetActivePageFlag = true) => {
        if (resetActivePageFlag) {
            setActivePage(1);
        }
        // Reset selectedIDs
        setSelectedIDs([]);
        let data = {
            keyword: searchName,
            search: {
                // from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                // to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            },
            paged: activePage,
            limit: selectedLimit,
            sort: sortBy,
        };
        switch (tabSelected) {
            case 0:
                if (pageTypeSelect == '') {
                    data.search.type = '';
                }
                if (pageTypeSelect == 'single_product') {
                    data.search.type = appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE;
                }
                if (pageTypeSelect == 'tag') {
                    data.search.type = appConfig.PAGE_CHECKOUT.TAGS.CODE;
                }
                break;
            case 1:
                data.search.type = appConfig.PAGE_CHECKOUT.STORE.CODE;
                break;
            case 2:
                data.search.type = appConfig.PAGE_CHECKOUT.EMBED.CODE;
                break;
            case 2:
                data.search.type = '';
                break;
            default:
                break;
        }

        if (resetActivePageFlag) {
            delete data.keyword;
        }

        dispatch(pageCheckoutActions.list(data));
    };

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    const onChangeLimit = (option) => {
        setSelectedLimit(option.value);
        setActivePage(1);
    };

    /**
     * Change page
     */
    const onPageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    const checkAllItem = (event) => {
        const { checked } = event.target;

        let ids = [];
        if (checked) {
            ids = map(pageCheckoutReducer.page_checkouts, (item) => item.page_checkout_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            ids = [];
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        setSelectedIDs(ids);
    };

    const checkItem = (event, pageCheckoutID) => {
        const { checked } = event.target;

        let ids = [...selectedIDs];

        if (checked) {
            if (!includes(ids, pageCheckoutID)) {
                ids.push(pageCheckoutID);
            }

            if (ids.length == pageCheckoutReducer.page_checkouts.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(ids, (selectedID) => selectedID == pageCheckoutID);
        }

        setSelectedIDs(ids);
    };

    const sort = (event) => {
        const { classList } = event.target;

        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            setSortBy({
                [name]: 'ASC',
            });
        } else {
            classList.add('up');
            setSortBy({
                [name]: 'DESC',
            });
        }
        list();
    };

    const openModalCreate = (e) => {
        if (e) e.preventDefault();
        setShowModalCreateSalesChannel(true);
        // setCurrentPageCheckout({});
        // setMode(appConfig.FORM_MODE.CREATE);
        // props.history.push(`/page-checkout/create`);
    };

    const openModalCreateSDK = (e) => {
        if (e) e.preventDefault();
        props.history.push(`/page-checkout/sdk-create`);
    };

    const openModalEdit = (pageCheckout) => {
        if (pageCheckout && pageCheckout.types) {
            let is_iframe = true;
            // return;
            if (!is_iframe) {
                switch (pageCheckout.types) {
                    case appConfig.PAGE_CHECKOUT.PRODUCTS.CODE:
                    case appConfig.PAGE_CHECKOUT.TAGS.CODE:
                        return props.history.push(`/page-checkout/update-old/${pageCheckout.page_checkout_id}`);
                    case appConfig.PAGE_CHECKOUT.EMBED.CODE:
                        return props.history.push(`/page-checkout/sdk-update/${pageCheckout.page_checkout_id}`);
                    default:
                        return props.history.push(`/page-checkout/update/${pageCheckout.page_checkout_id}`);
                }
            } else {
                switch (pageCheckout.types) {
                    case appConfig.PAGE_CHECKOUT.PRODUCTS.CODE:
                    case appConfig.PAGE_CHECKOUT.TAGS.CODE:
                        return props.history.push(`/page-checkout/update-old/${pageCheckout.page_checkout_id}`);
                    case appConfig.PAGE_CHECKOUT.EMBED.CODE:
                        return props.history.push(`/page-checkout/sdk-update/${pageCheckout.page_checkout_id}`);
                    default:
                        window.open(
                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(pageCheckout.page_checkout_id)}`,
                            '_blank'
                        );
                    // return props.history.push(`/page-checkout/update/${pageCheckout.page_checkout_id}`);
                }
            }
        }
    };

    const getCheckoutOpen = (pageCheckout) => {
        if (pageCheckout && pageCheckout.types) {
            let is_iframe = true;

            if (!is_iframe) {
                switch (pageCheckout.types) {
                    case appConfig.PAGE_CHECKOUT.EMBED.CODE:
                        return `/page-checkout/sdk-update/${pageCheckout.page_checkout_id}`;
                    case appConfig.PAGE_CHECKOUT.PRODUCTS.CODE:
                    case appConfig.PAGE_CHECKOUT.TAGS.CODE:
                        return `/page-checkout/update-old/${pageCheckout.page_checkout_id}`;
                    default:
                        return `/page-checkout/update/${pageCheckout.page_checkout_id}`;
                }
            } else {
                switch (pageCheckout.types) {
                    case appConfig.PAGE_CHECKOUT.PRODUCTS.CODE:
                    case appConfig.PAGE_CHECKOUT.TAGS.CODE:
                        return `/page-checkout/update-old/${pageCheckout.page_checkout_id}`;
                    case appConfig.PAGE_CHECKOUT.EMBED.CODE:
                        return `/page-checkout/sdk-update/${pageCheckout.page_checkout_id}`;
                    default:
                        return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(pageCheckout.page_checkout_id)}`;
                    // return props.history.push(`/page-checkout/update/${pageCheckout.page_checkout_id}`);
                }
            }
        }
    };

    const openDeleteModal = (_selectedID) => {
        selectedID = _selectedID;
        window.LadiUI.showModal('confirm-page-checkout-delete');
    };

    const openDeletesModal = () => {
        if (selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-page-checkout-deletes');
    };

    const eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                conditions: {
                    ...this.state.conditions,
                    ...{ keyword: e.target.value, page: 1 },
                },
            });
        }
    };

    const eventSelectActions = (action) => {
        switch (action) {
            case 'DELETE':
                openDeletesModal();
                break;
            default:
        }
    };

    const onCancelModalInfoApi = () => {
        setIsShowModalInfoApiKey(false);
    };

    const { total_record: totalRecord, total_page: totalPage } = pageCheckoutReducer;

    let fromItem = 0;
    let toItem = 0;

    if ((pageCheckoutReducer.page_checkouts || []).length > 0) {
        fromItem = (activePage - 1) * selectedLimit + 1;
        toItem = fromItem + pageCheckoutReducer.page_checkouts.length - 1;
    }

    const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

    const selectTab = (index) => {
        if (index == tabSelected) return;

        let _queryStrings = queryStrings;
        _queryStrings.set('tab', index);

        history.replace({ search: _queryStrings.toString() });
    };

    return (
        <div className='page-content p-0'>
            <div className='header-page'>
                <div className='heading'>
                    <h1>{t('MENU.SALES_CHANNEL')}</h1>
                    <p>{t('SALE_CHANNEL.SUB_TITLE')}</p>
                </div>
                <div className='action-btn'>
                    {isFrame ? (
                        <button className='ladiui dropdown-toggle btn btn-md btn-primary btn-sm' onClick={openModalCreate}>
                            <img
                                className='ladiui btn-custom-img'
                                src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                alt=''
                            />
                            {t('SALE_CHANNEL.ADD_NEW')}
                        </button>
                    ) : (
                        <div className='btn-action ladiui dropdown hide-mt'>
                            <button className='ladiui dropdown-toggle btn btn-md btn-primary btn-sm' data-toggle='dropdown'>
                                <img
                                    className='ladiui btn-custom-img'
                                    src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                    alt=''
                                />
                                {t('SALE_CHANNEL.ADD_NEW')}
                            </button>
                            <ul className='ladiui dropdown-menu r-0 block-new-page-checkout' style={{ width: 400 }}>
                                <li>
                                    <a className='ladiui dropdown-item flex' onClick={openModalCreate}>
                                        <div className='pointer-events-none'>
                                            <i className='ladi-icon icon-page-checkout-ladisales'></i>
                                        </div>
                                        <div className='title-description pointer-events-none'>
                                            <h3>Tạo trang thanh toán trên Ladipage eCommerve</h3>
                                            <span>Tạo trang thanh toán của bạn trên nền tảng Ladipage eCommerce</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className='ladiui dropdown-item flex' onClick={openModalCreateSDK}>
                                        <div className='pointer-events-none'>
                                            <i className='ladi-icon icon-other-page-checkout'></i>
                                        </div>
                                        <div className='title-description pointer-events-none'>
                                            <h3>Tạo trang thanh toán cho nền tảng khác</h3>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>

            <div className='page-checkout-home-body'>
                <div className='order-tab '>
                    <ul className='ladiui nav nav-tabs overflow-hide' role='tab-order-all'>
                        <li className='ladiui nav-item' role='tab-order-all-item' onClick={() => selectTab(1)}>
                            <span className={`ladiui tab-link ${tabSelected == 1 ? 'active' : ''}`}>{t('PAGE_CHECKOUT_V2.Store')}</span>
                        </li>
                        <li className='ladiui nav-item' role='tab-order-all-item' onClick={() => selectTab(0)}>
                            <span className={`ladiui tab-link ${tabSelected == 0 ? 'active' : ''}`}>{t('MENU.PAGE_CHECKOUT')}</span>
                        </li>

                        {!isFrame && (
                            <li className='ladiui nav-item' role='tab-order-all-item' onClick={() => selectTab(2)}>
                                <span className={`ladiui tab-link ${tabSelected == 2 ? 'active' : ''}`}>Embed</span>
                            </li>
                        )}
                        {/* <li className='ladiui nav-item' role='tab-order-all-item' onClick={() => selectTab(3)}>
                            <span className={`ladiui tab-link ${tabSelected == 3 ? 'active' : ''}`}>Trang thanh toán Tag</span>
                        </li> */}
                    </ul>
                </div>
                <div className='page-checkout-search'>
                    <div className={`ladiui search-group ${tabSelected == 1 ? 'store' : ''}`}>
                        <i
                            className='ladiui icon icon-search'
                            onClick={() => {
                                list(false);
                            }}
                        ></i>
                        <input
                            id='keyword_search'
                            className='ladiui search-field dropdown-toggle form-control search-width'
                            name='searchName'
                            placeholder='Tìm kiếm kênh bán hàng'
                            aria-expanded='false'
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    list(false);
                                }
                            }}
                        />
                    </div>
                    {tabSelected == 0 && (
                        <Dropdown
                            data={appConfig.PAGE_CHECKOUT_TYPES || []}
                            onSelectItem={(item) => {
                                setPageTypeSelect(item.CODE);
                            }}
                            currentKey={pageTypeSelect}
                            _key={'CODE'}
                            _value={'NAME'}
                            validationName={t('loại sản phẩm')}
                            placeHolder={t('Lọc theo loại sản phẩm')}
                            wrapperClassName='w-auto'
                        />
                    )}
                </div>

                {isLoading ? (
                    <LoadingTable />
                ) : (
                    <div>
                        <div className='ladi-card ladiui-table-responsive page-checkout-table'>
                            {selectedIDs.length > 0 && totalRecord > 0 && (
                                <LadiDropdownMenu
                                    labelDropdown={t('ACTIONS.LABEL')}
                                    listProp={listAction}
                                    defaultTextProp={t('ACTIONS.TITLE')}
                                    hideLabel={true}
                                    cbProp={eventSelectActions}
                                    classSize='btn-sm '
                                    classDropdown='w-180 action-record-table'
                                />
                            )}

                            <table className={`ladiui table text-left ${isLoading ? 'loader' : ''}`}>
                                <thead style={{ position: 'relative' }}>
                                    <tr className='ladiui table-vertical'>
                                        <th scope='col' className='ladiui w-30p'>
                                            <input
                                                id='checkAllItem'
                                                onClick={checkAllItem}
                                                type='checkbox'
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle mr-20'
                                            />
                                            <span>
                                                {tabSelected == 0
                                                    ? t('PAGE_CHECKOUT.PLACE_HOLDER_NAME')
                                                    : tabSelected == 1
                                                    ? t('STORES.FULL_NAME')
                                                    : t('CHECKOUTS.CHECKOUT_CONFIG_NAME')}
                                            </span>
                                        </th>

                                        {tabSelected == 0 && (
                                            <th scope='col' name='total_product' className='text-left'>
                                                {t('PAGE_CHECKOUT.TOTAL_PRODUCT')}
                                            </th>
                                        )}
                                        {/* {tabSelected == 0 && (
                                            <th scope='col' name='total_product' className='text-left w-30p'>
                                                Sản phẩm
                                            </th>
                                        )} */}
                                        {tabSelected == 0 && (
                                            <th scope='col' name='total_product' className='text-left w-30p'>
                                                {t('DISCOUNTS.TYPE')}
                                            </th>
                                        )}
                                        <th scope='col' name='total_product' className='text-left'>
                                            {t('PAGE_CHECKOUT.TAB_CHECKOUT_CONFIG')}
                                        </th>
                                        <th
                                            scope='col'
                                            name='updated_at'
                                            onClick={sort}
                                            className={`text-left sorting ${sortBy['updated_at'] == 'DESC' ? 'up' : ''}`}
                                        >
                                            {t('COMMON.UPDATED_AT')}
                                        </th>
                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(pageCheckoutReducer.page_checkouts, (item) => {
                                        let isOpenNewTab = false;
                                        let is_iframe = true;

                                        if (is_iframe) {
                                            if (
                                                item.types == appConfig.PAGE_CHECKOUT.STORE.CODE ||
                                                item.types == appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE
                                            ) {
                                                isOpenNewTab = true;
                                            }
                                        }
                                        let urlUpdate = getCheckoutOpen(item);
                                        return (
                                            <tr key={item.page_checkout_id} className='ladiui table-vertical main'>
                                                <td scope='row ' className='display-flex-center'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            onClick={(event) => checkItem(event, item.page_checkout_id)}
                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-20'
                                                        />
                                                    </div>
                                                    {isOpenNewTab ? (
                                                        <a href={urlUpdate} target='_blank'>
                                                            {item.name}
                                                        </a>
                                                    ) : (
                                                        <NavLink to={urlUpdate}>{item.name}</NavLink>
                                                    )}
                                                </td>
                                                {tabSelected == 0 && <td>{item.total_product}</td>}
                                                {tabSelected == 0 && (
                                                    <td className='page-checkout-type'>{t(`PAGE_CHECKOUT_V2.${item.types}`)}</td>
                                                )}

                                                <td className='page-checkout-type'>{item.checkout_config_name || ''}</td>

                                                <td>{baseHelper.formatStrToDate(item.updated_at || item.created_at)}</td>

                                                <td className='text-right pd-0'>
                                                    <div className='action-table'>
                                                        <a className='open-page tooltip' href={item.url_published} target='_blank'>
                                                            <i className='ladiui icon-new icon-24 new-ldicon-open-link' />
                                                            <span
                                                                className='tooltiptext top-left'
                                                                tooltiptext={t('PAGE_CHECKOUT_V2.OPEN_PAGE')}
                                                            ></span>
                                                        </a>
                                                        <div className='ladiui btn-group'>
                                                            <div className='ladiui dropdown hide-mt ba-c'>
                                                                <button
                                                                    data-toggle='dropdown'
                                                                    className='ladiui-btn-dropdown dropdown-toggle'
                                                                >
                                                                    <i className='ladiui icon icon-ldp-dot'></i>
                                                                </button>
                                                                <ul className='ladiui dropdown-menu r-0'>
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() => openModalEdit(item)}
                                                                        >
                                                                            <i className='new-ldicon-edit' />
                                                                            <span> {t('ACTIONS.SETUP')} </span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() => openDeleteModal(item.page_checkout_id)}
                                                                        >
                                                                            <i className='ladiui icon-new new-ldicon-delete' />
                                                                            <span className='ladiui text-danger'>
                                                                                {t('ACTIONS.DELETE')}
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {totalRecord <= 0 && (
                            <div className='table-empty'>
                                <i className='new-ldicon-search' />
                                <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                                <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                            </div>
                        )}
                        {totalRecord > 0 && (
                            <LadiPagination
                                conditionsProp={{ page: activePage, limit: selectedLimit }}
                                listName={t('PAGINATION.ITEMS')}
                                message={''}
                                items={pageCheckoutReducer.page_checkouts}
                                total={totalRecord}
                                limitAction={onChangeLimit}
                                pageAction={onPageChange}
                            />
                        )}
                    </div>
                )}
            </div>

            {/* {isShowModal && (
        <ModalPageCheckout
          isReloadIframe={isReloadIframe}
          currentPageCheckout={currentPageCheckout}
          mode={mode}
          visible={isShowModal}
          onCancel={hideModal}
          onSubmit={submit}
          loading={isSubmitLoading}
        />
      )} */}
            {isShowModalCreateSalesChannel && (
                <ModalCreateSalesChannel
                    visible={isShowModalCreateSalesChannel}
                    hideModal={() => {
                        setShowModalCreateSalesChannel(false);
                        list();
                    }}
                />
            )}

            {isShowModalInfoApiKey && (
                <ModalInfoApiKey
                    onCancel={onCancelModalInfoApi}
                    // onSubmit={onSubmitModalAddText}
                    visible={isShowModalInfoApiKey}
                    infoApi={selectedApiKey}
                />
            )}

            <ConfirmModal
                id='confirm-page-checkout-delete'
                title={t('PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUT_TITLE')}
                content={t('PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUT_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => dispatch(pageCheckoutActions.delete(selectedID))}
                isLoading={isLoading}
            />

            <ConfirmModal
                id='confirm-page-checkout-deletes'
                title={t('PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUT_TITLE')}
                content={t('PAGE_CHECKOUT.MSG_DELETE_PAGE_CHECKOUTS_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => dispatch(pageCheckoutActions.deletes(selectedIDs))}
                isLoading={isLoading}
            />
        </div>
    );
}
