import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_STORE,
    meta: {
        endpoint: endpoint.STORE_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const deleteStores = (storeIDs) => ({
    type: types.DELETE_STORES,
    meta: {
        endpoint: endpoint.STORE_DELETE_IDS,
        method: REQUEST_METHOD.POST,
        body: {
            store_ids: storeIDs,
        },
        hasAuth: true,
    },
});

const deleteStore = (storeID) => ({
    type: types.DELETE_STORE,
    meta: {
        endpoint: endpoint.STORE_DELETE_ID,
        method: REQUEST_METHOD.POST,
        body: {
            store_id: storeID,
        },
        hasAuth: true,
    },
});

const create = (store, address) => ({
    type: types.CREATE_STORE,
    meta: {
        endpoint: endpoint.STORE_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            store,
            address,
        },
        hasAuth: true,
    },
});

const update = (store) => ({
    type: types.UPDATE_STORE,
    meta: {
        endpoint: endpoint.STORE_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            store: store,
        },
        hasAuth: true,
    },
});

const getUserInfo = () => ({
    type: types.GET_USER_INFO,
    meta: {
        endpoint: endpoint.STORE_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const show = (storeID) => ({
    type: types.SHOW_STORE,
    meta: {
        endpoint: endpoint.STORE_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            store_id: storeID,
        },
        hasAuth: true,
    },
});

const reload = (storeID) => ({
    type: types.RELOAD_STORE,
    meta: {
        endpoint: endpoint.STORE_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            store_id: storeID,
        },
        hasAuth: true,
    },
});

const applyDefaultAddress = (storeAddressID) => ({
    type: types.APPLY_DEFAULT_ADDRESS_STORE,
    meta: {
        endpoint: endpoint.APPLY_DEFAULT_ADDRESS_STORE,
        method: REQUEST_METHOD.POST,
        body: {
            store_address_id: storeAddressID,
        },
        hasAuth: true,
    },
});

const createNewIP = (data) => ({
    type: types.CREATE_NEW_IP,
    meta: {
        endpoint: endpoint.CREATE_NEW_IP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const deleteIP = (data) => ({
    type: types.DELETE_IP,
    meta: {
        endpoint: endpoint.DELETE_IP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const showTracking = () => ({
    type: types.SHOW_TRACKING_STORE,
    meta: {
        endpoint: endpoint.SHOW_TRACKING_STORE,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
});

const updateTracking = (tracking) => ({
    type: types.UPDATE_TRACKING_STORE,
    meta: {
        endpoint: endpoint.UPDATE_TRACKING_STORE,
        method: REQUEST_METHOD.POST,
        body: {
            tracking,
        },
        hasAuth: true,
    },
});

const updateStatus = (data) => ({
    type: types.UPDATE_STATUS_STORE,
    meta: {
        endpoint: endpoint.UPDATE_STATUS_STORE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

export default {
    list,
    deletes: deleteStores,
    delete: deleteStore,
    create,
    update,
    reload,
    show,
    getUserInfo,
    applyDefaultAddress,
    createNewIP,
    deleteIP,
    showTracking,
    updateTracking,
    updateStatus,
};
