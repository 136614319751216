import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../config/app';

import Input from '../../../../../components/Input';
import ImageUpload from '../../../../../components/ImageUpload';
import pageCheckoutDefined from '../../components/pageCheckoutDefined';
import HandelSection from './HandelSection';
import ImageLogoUpload from '../../../../../components/ImageLogoUpload';
import PageHomeChannel from './PageHomeChannel';

// eslint-disable-next-line max-lines-per-function

const PageHome = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageStoreConfig } = props;

    const imageRef = useRef();
    const handelSectionRef = useRef();
    const handelPageHomeChannelRef = useRef();

    const [setting, setSetting] = useState({
        store_name: '',
        store_address: '',
        reviews: 5,
        quantity_of_products_sold: 0,
        home_banner: '',
        logo_url: '',
    });

    const [channels, setChannels] = useState([]);
    const [sections, setSections] = useState([]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let sections = handelSectionRef.current.getData();
            // let channels = handelPageHomeChannelRef.current.getData();

            return {
                sections,
                setting: setting,
                // channels,
            };
        },
    }));

    useEffect(() => {
        let iframeElm = document.getElementById('iframe-page-checkout-dns');

        if (iframeElm && handelPageHomeChannelRef) {
            // let channels = handelPageHomeChannelRef.current.getData();

            const dataSend = {
                type: 'LADI_UPDATE_STORE_INFO',
                key: 'page_home_config',
                value: {
                    // channels,
                    setting: setting,
                },
            };

            iframeElm.contentWindow.postMessage(dataSend, '*');
        }
    }, [setting, channels]);

    useEffect(() => {
        let iframeElm = document.getElementById('iframe-page-checkout-dns');

        if (iframeElm && handelSectionRef) {
            let sections = handelSectionRef.current.getData();

            const dataSend = {
                type: 'LADI_UPDATE_STORE_WIDGET',
                key: 'page_home_config',
                value: sections,
            };

            iframeElm.contentWindow.postMessage(dataSend, '*');
        }
    }, [sections]);

    useEffect(() => {
        if (pageStoreConfig) {
            let settingTg = pageStoreConfig.setting || {};
            setSetting(settingTg);
            setChannels(pageStoreConfig.channels || []);
            setSections(pageStoreConfig.sections || []);
        }
    }, [pageStoreConfig]);

    const handleIputChange = (e) => {
        const { name, value } = e.target;
        setSetting({
            ...setting,
            [name]: value,
        });
    };

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'}`}>
            <div>
                <label className='ladiui label-14'>{t('STORES.EDIT_STORE')}</label>
                <div className='mt-20'>
                    <label className='ladiui text-14'>{t('STORES.FULL_NAME')}</label>
                    <Input
                        className='mt-4'
                        value={setting.store_name}
                        name='store_name'
                        placeholder={t('STORES.NAME_PLACE')}
                        onChange={handleIputChange}
                    />
                </div>
                <div className='mt-20'>
                    <label className='ladiui text-14'>{t('STORE_ADDRESS.TITLE')}</label>
                    <Input
                        className='mt-4'
                        value={setting.store_address}
                        name='store_address'
                        placeholder={t('STORE_ADDRESS.ADDRESS_PLA')}
                        onChange={handleIputChange}
                    />
                </div>
                <div className='ladiui flex-center-between gap-20 mt-20'>
                    <div className='w-100-p'>
                        <label className='ladiui text-14'>{t('STORES.POINT_LABLE')}</label>
                        <Input
                            className='mt-4'
                            value={setting.reviews}
                            onChange={(e) => {
                                setSetting({
                                    ...setting,
                                    reviews: e.target.value,
                                });
                            }}
                        />
                    </div>

                    <div className='w-100-p'>
                        <label className='ladiui text-14'>{t('STORES.TOTAL_PRODUCT')}</label>
                        <Input
                            className='mt-4'
                            value={setting.quantity_of_products_sold}
                            onChange={(e) => {
                                setSetting({
                                    ...setting,
                                    quantity_of_products_sold: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='mt-20 pt-20 ladiui border-t-50p'>
                <label className='ladiui label-14'>{t('STORES.STORE_LOGO')}</label>
                <div>
                    <div className='display-flex-center gap-16 mt-16'>
                        <ImageLogoUpload
                            type='LOGO_HOME_PAGE'
                            imageSrc={setting.logo_url}
                            setImageData={(value, type) => {
                                if (type == 'LOGO_HOME_PAGE')
                                    setSetting({
                                        ...setting,
                                        logo_url: value,
                                    });
                            }}
                        />

                        <div>
                            <div className='ladiui text-14 text-primary'>{t('STORES.LOGO_LABLE')}</div>
                            <div className='mt-4'>{t('STORES.LOGO_LABLE_SUB')}</div>
                        </div>
                    </div>
                </div>

                <div className='mt-20'>
                    <label className='ladiui text-14'>{t('STORES.STORE_BANNER')}</label>
                    <ImageUpload
                        indexSection='BANNER-HOME'
                        setImageSrc={setting.home_banner}
                        setImageData={(image) => {
                            if (image.indexSection == 'BANNER-HOME') {
                                setSetting({
                                    ...setting,
                                    home_banner: image.src,
                                });
                            }
                        }}
                    />
                </div>
            </div>
            {/* <div className='mt-20 pt-20 ladiui border-t-50p'>
                <label className='ladiui label-14'>Kênh tương tác</label>
                <div className='ladiui text-14 mt-4'>Hình ảnh về thương hiệu cửa hàng.</div>

                <PageHomeChannel ref={handelPageHomeChannelRef} sectionData={channels || []} />
            </div> */}
            <div className='mt-20 pt-20 ladiui border-t-50p'>
                <label className='ladiui label-14'>{t('STORES.STORE_CONTENT')}</label>
                <div className='ladiui text-14 mt-4'>{t('STORES.STORE_CONTENT_SUB')}</div>

                <div className='mt-20'>
                    <HandelSection ref={handelSectionRef} sectionData={sections || []} type='PAGE_HOME_CONFIG' />
                </div>
            </div>
        </div>
    );
});

export default PageHome;
