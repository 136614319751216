import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const moduleCreate = (data) => ({
  type: types.COURSE_MODULE_CREATE,
  meta: {
    endpoint: endpoint.COURSE_MODULE_CREATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});
const moduleList = (data) => ({
  type: types.COURSE_MODULE_LIST,
  meta: {
    endpoint: endpoint.COURSE_MODULE_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});
const moduleDelete = (data) => ({
  type: types.COURSE_MODULE_DELETE,
  meta: {
    endpoint: endpoint.COURSE_MODULE_DELETE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});
const moduleUpdate = (data) => ({
  type: types.COURSE_MODULE_UPDATE,
  meta: {
    endpoint: endpoint.COURSE_MODULE_UPDATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const lessonCreate = (data) => ({
  type: types.COURSE_LESSON_CREATE,
  meta: {
    endpoint: endpoint.COURSE_LESSON_CREATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const lessonDelete = (data) => ({
  type: types.COURSE_LESSON_DELETE,
  meta: {
    endpoint: endpoint.COURSE_LESSON_DELETE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const lessonUpdate = (data) => ({
  type: types.COURSE_LESSON_UPDATE,
  meta: {
    endpoint: endpoint.COURSE_LESSON_UPDATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const documentDelete = (data) => ({
  type: types.COURSE_DOCUMENT_DELETE,
  meta: {
    endpoint: endpoint.COURSE_DOCUMENT_DELETE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const replicationModule = (data) => ({
  type: types.REPLICATION_MODULE,
  meta: {
    endpoint: endpoint.REPLICATION_MODULE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const replicationLesson = (data) => ({
  type: types.REPLICATION_LESSON,
  meta: {
    endpoint: endpoint.REPLICATION_LESSON,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const moduleUpdatePosition = (data) => ({
  type: types.MODULE_UPDATE_POSITION,
  meta: {
    endpoint: endpoint.MODULE_UPDATE_POSITION,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const lessonUpdatePosition = (data) => ({
  type: types.LESSON_UPDATE_POSITION,
  meta: {
    endpoint: endpoint.LESSON_UPDATE_POSITION,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const documentCreate = (data) => ({
  type: types.COURSE_DOCUMENT_CREATE,
  meta: {
    endpoint: endpoint.COURSE_DOCUMENT_CREATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const courseCustomerList = (data) => ({
  type: types.COURSE_CUSTOMER_LIST,
  meta: {
    endpoint: endpoint.COURSE_CUSTOMER_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const courseCustomerListModal = (data) => ({
  type: types.COURSE_CUSTOMER_LIST_MODAL,
  meta: {
    endpoint: endpoint.COURSE_CUSTOMER_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const courseCustomerListOwnedCourse = (data) => ({
  type: types.COURSE_CUSTOMER_LIST_OWNED_COURSE,
  meta: {
    endpoint: endpoint.COURSE_CUSTOMER_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const lockCustomer = (data) => ({
  type: types.LOCK_CUSTOMER,
  meta: {
    endpoint: endpoint.LOCK_CUSTOMER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const cancelLockCustomer = (data) => ({
  type: types.CANCEL_LOCK_CUSTOMER,
  meta: {
    endpoint: endpoint.CANCEL_LOCK_CUSTOMER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const lockCourse = (data) => ({
  type: types.LOCK_COURSE,
  meta: {
    endpoint: endpoint.LOCK_COURSE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const cancelLockCourse = (data) => ({
  type: types.CANCEL_LOCK_COURSE,
  meta: {
    endpoint: endpoint.CANCEL_LOCK_COURSE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const createCustomer = (data) => ({
  type: types.CREATE_CUSTOMER,
  meta: {
    endpoint: endpoint.CREATE_CUSTOMER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const createManyCustomers = (data) => ({
  type: types.CREATE_MANY_CUSTOMERS,
  meta: {
    endpoint: endpoint.CREATE_MANY_CUSTOMERS,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const createAccount = (data) => ({
  type: types.CREATE_ACCOUNT,
  meta: {
    endpoint: endpoint.CREATE_ACCOUNT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const addCourseToStudent = (data) => ({
  type: types.ADD_COURSE_TO_STUDENT,
  meta: {
    endpoint: endpoint.ADD_COURSE_TO_STUDENT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  moduleCreate,
  moduleList,
  moduleDelete,
  moduleUpdate,
  lessonCreate,
  lessonDelete,
  lessonUpdate,
  documentDelete,
  replicationModule,
  replicationLesson,
  moduleUpdatePosition,
  lessonUpdatePosition,
  documentCreate,
  courseCustomerList,
  courseCustomerListModal,
  lockCustomer,
  cancelLockCustomer,
  lockCourse,
  cancelLockCourse,
  createCustomer,
  createManyCustomers,
  courseCustomerListOwnedCourse,
  createAccount,
  addCourseToStudent
};
