import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import ModalMembershipKey from './ModalMembershipKey';

import courseActions from '../../../redux/futures/course/actions';
import * as courseTypes from '../../../redux/futures/course/types';

import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import ConfirmModal from '../../../components/ConfirmModal';
import ModalReasonLock from './ModalReasonLock';
import ModalAddOwnedCourse from './ModalAddOwnedCourse';
import { find } from 'lodash';
// eslint-disable-next-line max-lines-per-function
function ModalOwnedCourse(props) {
    const {
        t,
        onClose,
        isShow,
        searchCourse,
        onFocusVariantInput,
        onChangeSearchVariantInput,
        searchVariantValue,
        onKeyDownInputOwnedCourse,
        searchOwnedCourse,
        customerID,
    } = props;
    const wrapperRef = useRef();

    const dispatch = useDispatch();

    const courseReducer = useSelector((state) => state.course);
    const productReducer = useSelector((state) => state.product);

    const [dataOwnedCourses, setDataOwnedCourses] = useState([]);

    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedReason, setSelectedReason] = useState('');
    const [isShowModalMemberShipKey, setIsShowModalMembershipKey] = useState(false);
    const [isShowModalReasonLock, setIsShowModalReasonLock] = useState(false);
    const [isShowModalAddCourse, setIsShowModalAddCourse] = useState(false);

    const [dataCourses, setDataCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [valueSearchOwnedCourse, setValueSearchOwnedCourse] = useState('');

    useEffect(() => {
        if (customerID) {
            setSelectedCustomerId(customerID);
        }
    }, [customerID]);

    useEffect(() => {
        if (props.dataOwnedCourses && props.dataOwnedCourses.length > 0) {
            setDataOwnedCourses(props.dataOwnedCourses);
        }
    }, [props.dataOwnedCourses]);

    useEffect(() => {
        if (productReducer.action == productTypes.SEARCH_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    setDataCourses(productReducer.products);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (courseReducer.action == courseTypes.LOCK_COURSE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                setIsShowModalMembershipKey(false);

                let payload = {
                    search: {
                        customer_id: selectedCustomerId,
                    },
                };
                dispatch(courseActions.courseCustomerListOwnedCourse(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.CANCEL_LOCK_COURSE) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                window.LadiUI.closeModal('confirm-enable-course');
                let payload = {
                    search: {
                        customer_id: selectedCustomerId,
                    },
                };
                dispatch(courseActions.courseCustomerListOwnedCourse(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST_OWNED_COURSE) {
            if (courseReducer.status) {
                if (courseReducer.customers) {
                    setDataOwnedCourses(courseReducer.customers);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.ADD_COURSE_TO_STUDENT) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                let payload = {
                    search: {
                        customer_id: selectedCustomerId,
                    },
                };
                dispatch(courseActions.courseCustomerListOwnedCourse(payload));
                setIsShowModalAddCourse(false);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
    }, [courseReducer]);

    const eventCancel = () => {
        onClose(false);
    };

    const handleMembershipKey = (item) => {
        if (item.customer_id && item.product_id) {
            setSelectedCustomerId(item.customer_id);
            setSelectedProductId(item.product_id);
            setIsShowModalMembershipKey(true);
        }
    };

    const handleActiveCourse = (item) => {
        if (item.customer_id && item.product_id) {
            setSelectedCustomerId(item.customer_id);
            setSelectedProductId(item.product_id);
            window.LadiUI.showModal('confirm-enable-course');
        }
    };

    const handleOpenModalReasonLock = (reason) => {
        if (reason) {
            setSelectedReason(reason);
            setIsShowModalReasonLock(true);
        }
    };

    const fetchListCourseItem = () => {
        return dataOwnedCourses.map((item, index) => {
            return (
                <tr key={index} className='ladiui table-vertical main'>
                    <td>
                        <span
                            style={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                width: '250px',
                            }}
                        >
                            {item.product_name ? item.product_name : ''}
                        </span>
                    </td>
                    <td>{item.creator_id ? 'Thêm bởi quản trị viên' : 'Học viên đăng ký'}</td>
                    <td>{item.created_at ? baseHelper.formatDateToStr(item.created_at) : ''}</td>
                    <td>
                        {item.is_lock == 0 ? (
                            <span className='ladiui flex-center-start'>
                                <i className='ldicon-status-success mr-8' />
                                Đang mở
                            </span>
                        ) : (
                            <span className='ladiui flex-center-start'>
                                <i className='ldicon-status-error mr-8' />
                                Đã khóa
                            </span>
                        )}
                    </td>
                    <td className='text-right'>
                        <div className='ladiui btn-group position-initial'>
                            <div className='ladiui dropdown hide-mt ba-c position-initial'>
                                <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle' style={{ minWidth: 'auto' }}>
                                    <i className='ladiui icon icon-ldp-dot'></i>
                                </button>
                                <ul className='ladiui dropdown-menu r-0 mr-24' style={{ width: '160px' }}>
                                    {item.is_lock == 1 ? (
                                        <>
                                            <li onClick={() => handleOpenModalReasonLock(item.lock_reason)}>
                                                <a className='ladiui dropdown-item space-nowrap'>Lý do khóa</a>
                                            </li>
                                            <li onClick={() => handleActiveCourse(item)}>
                                                <a className='ladiui dropdown-item space-nowrap'>Kích hoạt khóa học</a>
                                            </li>
                                        </>
                                    ) : (
                                        <li onClick={() => handleMembershipKey(item)}>
                                            <a className='ladiui dropdown-item space-nowrap'>Vô hiệu khóa học</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const handleLockCourse = (reason) => {
        let payload = {};

        payload = {
            product_id: selectedProductId,
            customer_id: selectedCustomerId,
            reason: reason,
        };

        dispatch(courseActions.lockCourse(payload));
    };

    const handleCancelLockCourse = () => {
        let payload = {
            product_id: selectedProductId,
            customer_id: selectedCustomerId,
        };

        dispatch(courseActions.cancelLockCourse(payload));
    };

    const selectCourse = (data) => {
        let course = { ...data };

        if (!course.price) {
            if (course.max_price == course.min_price) {
                course.price = course.max_price;
            }
        }

        if (find(selectedCourses, (item) => item.product_id == course.product_id)) {
            return;
        }
        let _selectedCourses = [...selectedCourses];

        _selectedCourses.push(course);

        setSelectedCourses(_selectedCourses);
    };

    const handleUnselectItem = (item) => {
        let _selectedCourses = [...selectedCourses];

        _selectedCourses = _selectedCourses.filter((value) => value.product_id != item.product_id);

        setSelectedCourses(_selectedCourses);
    };

    const handleAddCourses = () => {
        let payload = {
            product_ids: selectedCourses && selectedCourses.length > 0 ? selectedCourses.map((item) => item.product_id) : [],
            customer_id: selectedCustomerId,
        };

        dispatch(courseActions.addCourseToStudent(payload));
    };

    const onChangeSearchValueCourse = (event) => {
        let value = event.target.value;
        setValueSearchOwnedCourse(value);
    };

    const onKeyDownInputCourse = async (event) => {
        if (event.key === 'Enter') {
            let selectedProductIds =
                dataOwnedCourses && props.dataOwnedCourses.length > 0 ? dataOwnedCourses.map((item) => item.product_id) : [];
            await dispatch(productActions.search(valueSearchOwnedCourse, 'Course', null, selectedProductIds));
        }
    };

    const handleSearchCourse = async () => {
        let selectedProductIds =
            dataOwnedCourses && props.dataOwnedCourses.length > 0 ? dataOwnedCourses.map((item) => item.product_id) : [];
        await dispatch(productActions.search(valueSearchOwnedCourse, 'Course', null, selectedProductIds));
    };

    const handleShowModalAddCourse = () => {
        setIsShowModalAddCourse(true);

        let selectedProductIds =
            dataOwnedCourses && props.dataOwnedCourses.length > 0 ? dataOwnedCourses.map((item) => item.product_id) : [];
        dispatch(productActions.search('', 'Course', null, selectedProductIds));
    };
    const isLoadingAddOwnedCourse = courseReducer.loading && courseReducer.waiting == courseTypes.ADD_COURSE_TO_STUDENT;

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-owned-courses'>
                <div className='ladiui modal-dialog modal-dialog-centered w-800' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Khóa học đã sở hữu</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body position-initial'>
                            <div className='ladiui search-group'>
                                <input
                                    className='ladiui search-field dropdown-toggle form-control'
                                    value={searchVariantValue}
                                    placeholder={'Tìm kiếm khóa học'}
                                    data-toggle='dropdown'
                                    aria-expanded='false'
                                    onChange={onChangeSearchVariantInput}
                                    onKeyDown={onKeyDownInputOwnedCourse}
                                />
                                <i className='ladiui icon icon-search' onClick={searchOwnedCourse}></i>
                            </div>
                            <div className='list-courses'>
                                {dataOwnedCourses && dataOwnedCourses.length > 0 && (
                                    <table className={`ladiui table text-left`}>
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col'>Tên khóa học</th>
                                                <th scope='col' className='text-left'>
                                                    Phương thức đăng ký
                                                </th>
                                                <th scope='col' className='text-left'>
                                                    Thời gian sở hữu
                                                </th>
                                                <th scope='col' className='text-left'>
                                                    Trạng thái
                                                </th>
                                                <th scope='col' />
                                            </tr>
                                        </thead>
                                        <tbody>{fetchListCourseItem()}</tbody>
                                    </table>
                                )}
                                <div className='cursor-pointer pt-16'>
                                    <a className='flex-center-left cursor-pointer pd-0' onClick={() => handleShowModalAddCourse()}>
                                        <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                                        <span>Thêm khóa học</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui modal-footer '>
                            <div className='ladiui footer-modal-btn'>
                                <button
                                    type='button'
                                    data-dismiss='modal'
                                    data-target='add-product'
                                    className='ladiui btn btn-secondary'
                                    onClick={eventCancel}
                                >
                                    {t('ACTIONS.CLOSE')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowModalMemberShipKey && (
                <ModalMembershipKey
                    onClose={() => setIsShowModalMembershipKey(false)}
                    isShow={isShowModalMemberShipKey}
                    innerStyle={{ display: 'none' }}
                    handleLockCustomer={handleLockCourse}
                    isStudentCourse={true}
                />
            )}

            {isShowModalAddCourse && (
                <ModalAddOwnedCourse
                    onClose={() => setIsShowModalAddCourse(false)}
                    titleModal={'Danh sách khóa học'}
                    isShow={isShowModalAddCourse}
                    listVariant={dataCourses || []}
                    dataSelected={dataOwnedCourses || []}
                    innerStyle={{ display: 'none' }}
                    selectVariant={selectCourse}
                    unSelectItem={handleUnselectItem}
                    isSelectedTypeProduct={true}
                    isFeaturedCourse={true}
                    selectedProducts={selectedCourses || []}
                    onSubmit={handleAddCourses}
                    searchVariantValue={valueSearchOwnedCourse}
                    onChangeSearchVariantInput={onChangeSearchValueCourse}
                    isLoadingAddOwnedCourse={isLoadingAddOwnedCourse}
                    onKeyDownInputCourse={onKeyDownInputCourse}
                    searchVariant={handleSearchCourse}
                />
            )}

            {isShowModalReasonLock && (
                <ModalReasonLock
                    onClose={() => setIsShowModalReasonLock(false)}
                    isShow={isShowModalReasonLock}
                    innerStyle={{ display: 'none' }}
                    isStudentCourse={false}
                    selectedTitle='Lý do vô hiệu khóa học'
                    selectedReason={selectedReason}
                />
            )}

            <ConfirmModal
                id='confirm-enable-course'
                title={'Mở khóa học'}
                content={'Khóa học được mở, học sinh sẽ có thể tham gia khóa học trở lại. Bạn có muốn thực hiện hành động này?'}
                cancelText={t('ACTIONS.CANCEL')}
                okText={'Mở khóa học'}
                isBtnPrimary={true}
                onOk={handleCancelLockCourse}
            />
        </>
    );
}

export default withTranslation()(ModalOwnedCourse);
