import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.INVENTORY_LIST_VARIANT: {
            return {
                ...state,
                action: types.INVENTORY_LIST_VARIANT,
                variants: action.status ? action.payload.variants : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.INVENTORY_UPDATE_QUANTITY: {
            return {
                ...state,
                action: types.INVENTORY_UPDATE_QUANTITY,
                status: action.status,
                message: action.message,
            }
        }
        case types.INVENTORY_UPDATES_QUANTITY: {
            return {
                ...state,
                action: types.INVENTORY_UPDATES_QUANTITY,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

