import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                done: null,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                done: action.done,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.LIST_PRODUCT_CROSS_SELL,
                productCrossSells: action.status ? action.payload.product_cross_sells : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETES_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.DELETES_PRODUCT_CROSS_SELL,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.DELETE_PRODUCT_CROSS_SELL,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.CREATE_PRODUCT_CROSS_SELL,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.UPDATE_PRODUCT_CROSS_SELL,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.SHOW_PRODUCT_CROSS_SELL,
                productCrossSell: action.status ? action.payload.product_cross_sell : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.RELOAD_PRODUCT_CROSS_SELL: {
            return {
                ...state,
                action: types.RELOAD_PRODUCT_CROSS_SELL,
                productCrossSell: action.status ? action.payload.productCrossSell : {},
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

