import React from 'react';
import PropTypes from 'prop-types';
import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';
class Image extends React.Component {
    static propTypes = {
        src: PropTypes.string,
        width: PropTypes.any,
        resize: PropTypes.string,
        height: PropTypes.any,
        className: PropTypes.string,
        callbackError: PropTypes.func,
        prefixCDN: PropTypes.bool,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        width: '100%',
        height: '100%',
        resize: '',
        prefixCDN: true,
        onClick: function () {},
    };
    render() {
        const { width, height, className, resize, prefixCDN, onClick } = this.props;

        let src = baseHelper.getImageUrl(this.props.src, false, resize);
        // const extension = baseHelper.getExtension(src);

        // if (prefixCDN) {
        //     if (appConfig.EXTENSION_ALLOW_RESIZES.includes(extension)) {
        //         src = src ? `${appConfig.DOMAIN_CDN}/${resize}/${this.props.src}` : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg';
        //     } else {
        //         src = src ? `${appConfig.DOMAIN_CDN}/${this.props.src}` : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg';
        //     }
        // } else {
        //     src = src ? src : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg';
        // }
        return (
            <img
                onClick={onClick}
                style={{ objectFit: 'cover', cursor: 'pointer' }}
                className={className}
                src={`${src}` || 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'}
                onError={(e) => {
                    if (this.props.callbackError) {
                        this.props.callbackError();
                        return;
                    }

                    e.target.onerror = null;
                    e.target.src = 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg';
                }}
                width={width}
                height={height}
            ></img>
        );
    }
}

export default Image;
