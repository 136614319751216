import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import ProductSearch from './ProductSearch';
import ModalAddProduct from '../components/ModalAddProduct';
import BaseHelper from '../../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function

const ProductList = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageCheckout } = props;

    const storeReducer = useSelector((state) => state.store);

    const [products, setProduct] = useState([]);

    const [isShowModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            return products;
        },
    }));

    useEffect(() => {
        if (pageCheckout && pageCheckout.page_checkout_id) {
            let products = pageCheckout.products;
            setProduct(products || []);
        }
    }, [pageCheckout]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('tab');

        history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const handleAddProduct = (data) => {
        let dataTg = [...products, ...data];
        dataTg = dataTg.filter((item, index) => dataTg.findIndex((_item) => _item.product_id == item.product_id) === index);

        setProduct(dataTg);
    };

    const handelDeleteProduct = (index) => {
        let dataTg = [...products];
        dataTg.splice(index, 1);

        setProduct(dataTg);
    };

    return (
        <div className={`page-checkout-config-item  page-checkout-product-list ${isShow ? 'show' : 'hide'}`}>
            <div className='header'>
                <div className='display-flex-center gap-16'>
                    <div className='icon-back' onClick={backMenu}>
                        <i className='ldicon-chevron-left' />
                    </div>
                    <span className='ladiui title-header'>{t('PAGE_CHECKOUT.LIST_PRODUCT')}</span>
                </div>
            </div>
            <div className='product-list-body'>
                {/* <ProductSearch /> */}

                {products.length == 0 ? (
                    <div className='ladiui flex-column-center gap-12 pt-100 product-empty'>
                        <i className='ladiui icon-new new-ldicon-home' />
                        <div>Chưa có sản phẩm trong trang thanh toán</div>
                    </div>
                ) : (
                    <div className='product-list'>
                        <div className='product-item'>
                            <div className='product-name'>Sản phẩm</div>
                            <div className='product-price'>Giá bán</div>
                            <div className='product-delete'></div>
                        </div>
                        {products.map((item, index) => {
                            let imageSrc = BaseHelper.getImageUrl(item.src);
                            return (
                                <div key={index} className='product-item'>
                                    <div className='product-name display-flex-center gap-16'>
                                        <img className='product-image' src={imageSrc} />
                                        <div>{item.name}</div>
                                    </div>
                                    <div className='product-price'>
                                        <span>
                                            {BaseHelper.formatMoneyPostFix(
                                                item.min_price,
                                                storeReducer.userInfo.currentStore.currency_symbol
                                            )}
                                        </span>
                                        {/* {item.min_price != item.max_price && (
                                            <span>
                                                {' - '}
                                                {BaseHelper.formatMoneyPostFix(
                                                    item.max_price,
                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        )} */}
                                    </div>
                                    <div onClick={() => handelDeleteProduct(index)}>
                                        <i className='ladiui icon-new new-ldicon-delete' />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className='product-btn-add'>
                <div className='product-list-add' onClick={() => setShowModal(true)}>
                    <i className='ldicon-add' />
                    <span className='ladiui text-brand'>Thêm sản phẩm</span>
                </div>
            </div>
            {isShowModal && (
                <ModalAddProduct
                    isShow={isShowModal}
                    onClose={() => setShowModal(false)}
                    handleAddProduct={handleAddProduct}
                    selectedProduct={products || []}
                />
            )}
        </div>
    );
});

export default ProductList;
