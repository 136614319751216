import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import baseHelper from '../helpers/BaseHelper';
import Image from './Image';
import fileActions from '../redux/futures/file/actions';
import productImageActions from '../redux/futures/product_image/actions';
import * as productImageTypes from '../redux/futures/product_image/types';
import produce from 'immer';

import ValidatorHelper from '../helpers/ValidatorHelper';

import Popover from './Popover';
import Input from './Input';

export default function UploadImageCustom(props) {
    let { listImage, productID, handleSetImage, isCopy, isEditProduct, imageWidth, imageHeight, uploadId = 'upload-single-custom' } = props;
    const { t, i18n } = useTranslation();
    const popoverRef = useRef();

    const dispatch = useDispatch();
    const [imageSelect, setImageSelect] = useState({});
    const [imgSelected, setImgSelected] = useState({});

    const [targetIdx, setTargetIdx] = useState(-1);
    const [listImages, setListImages] = useState([]);

    const [selectedUrlImage, setSelectedUrlImage] = useState('');

    const uploadFile = useSelector((state) => {
        return state.file;
    });

    useEffect(() => {
        if (listImage && listImage.length > 0) {
            setImageSelect(listImage[0]);
            setListImages(listImage);
        } else {
            setImageSelect({});
            setListImages([]);
        }
    }, [JSON.stringify(listImage)]);

    const fileChangedHandler = (event) => {
       props.handleChangeImageUrl(event);
    };

    const setTargetImage = (targetIndex) => {
        setTargetIdx(targetIndex);
        handleClickInputFile();
    }

    const deleteImage = (item) => {
        if (!isEditProduct) {
            let imageList = listImages.filter((img) => img.src != item.src);
            props.handleSetImage(imageList);
        } else {
            props.handleDeleteImage(item);
        }
    };

    const handleClickInputFile = () => {
        document.getElementById(uploadId).click();
    };
 
    const handleAddImageUrl = () => {
        if (selectedUrlImage && ValidatorHelper.isValidHttpUrl(selectedUrlImage)) {
            props.handleSetListImageProducts(selectedUrlImage);
            popoverRef.current.instanceRef.hide();
            setSelectedUrlImage('');
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };

    return (
        <div className={`ladiui ls-upload-single-image ls-flex ls-justify-center ls-gap-16`}>
            {listImages.length == 0 ? (
                <div className='text-center image-none' style={{maxWidth: imageWidth ? imageWidth : 'auto'}}>
                    <div className='ladiui-button-image' style={{height: imageHeight || 'auto', width: imageWidth || 240}}>
                        <div className='btn-add-image'>
                            <div>
                                <i className='new-ldicon-photo ls-size-16'></i>
                            </div>
                            {
                                !props.isAlwayShowButtonUpload && (
                                    <div className='ls-flex ls-justify-center ls-w-full'>
                                        <div className='text-default ls-text-12 ls-flex'>
                                            <a className='pr-4' onClick={() => handleClickInputFile()}>
                                                Tải ảnh lên
                                            </a>
                                            <span>hoặc</span>
                                            <Popover
                                                ref={popoverRef}
                                                wrapperProps={{ className: 'relative' }}
                                                wrapperPopoverProps={{ className: 'popover-image-product' }}
                                                // isShowPopover={isShowPopover}
                                                content={
                                                    <div className='popover-add-image-product'>
                                                        <div className='popover'>
                                                            <div className='ladiui form-group'>
                                                                <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                                <Input
                                                                    className='ladiui form-control'
                                                                    placeholder='Nhập đường dẫn sản phẩm'
                                                                    name='image_url'
                                                                    value={selectedUrlImage}
                                                                    onChange={(event) => {
                                                                        setSelectedUrlImage(event.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='action-footer'>
                                                                <button
                                                                    className='ladiui btn btn-secondary'
                                                                    onClick={() => {
                                                                        popoverRef.current.instanceRef.hide();
                                                                    }}
                                                                >
                                                                    Hủy
                                                                </button>
                                                                <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                                    Thêm hình ảnh
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <a className='pl-4'>Thêm từ URL</a>
                                            </Popover>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className={`ls-upload-img-container ls-flex ls-gap-16`}>
                        {listImages.map((item, index) => {
                            let prefixCDN = true;
                            if (item?.src && item?.src.startsWith('http')) {
                                prefixCDN = false;
                            }
                            return (
                                <div className='ls-item-image ls-w-full relative' key={index} style={{maxWidth: imageWidth ? imageWidth : 'auto', height: imageHeight || 'auto', minWidth: imageWidth || 240}}>
                                    <Image
                                        prefixCDN={prefixCDN}
                                        src={baseHelper.getImageUrl(item.src || item, false, 's900')}
                                        key={index}
                                        onClick={() => {
                                            setImageSelect({
                                                ...imageSelect,
                                                src: item.src || '',
                                            });
                                        }}
                                    />
                                    <div className='ls-widget-actions'>
                                        <div
                                            className='ls-icon-action block-reup-img cursor-pointer'
                                            onClick={() => {
                                                setTargetImage(index);
                                            }}
                                            htmlFor={uploadId}
                                        >
                                            <i style={{ color: '#fff' }} className='ldicon-upload' />
                                        </div>
                                        <div
                                            className='ls-icon-action block-delete-img cursor-pointer'
                                            onClick={() => {
                                                deleteImage(item);
                                            }}
                                        >
                                            <i style={{ color: '#fff' }} className='ladi-icon icon-bin' />
                                        </div>
                                       
                                    </div>
                                </div>
                            );
                        })}
                        {
                            !props.isAlwayShowButtonUpload && (<>
                                {
                                    !props.isShowButtonUpload ? (
                                        <>
                                            {
                                                listImages?.length == 0 && (
                                                    <div className='ls-flex ls-items-center ls-justify-center ls-gap-4'>
                                                        <p>
                                                            <a onClick={() => handleClickInputFile()}>Tải ảnh lên</a>
                                                        </p>
                                                        <span className='block ls-my-4'>Hoặc</span>
                                                        <Popover
                                                            ref={popoverRef}
                                                            wrapperProps={{ className: 'relative' }}
                                                            wrapperPopoverProps={{ className: 'popover-image-product' }}
                                                            // isShowPopover={isShowPopover}
                                                            content={
                                                                <div className='popover-add-image-product'>
                                                                    <div className='popover'>
                                                                        <div className='ladiui form-group'>
                                                                            <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                                            <Input
                                                                                className='ladiui form-control'
                                                                                placeholder='Nhập đường dẫn sản phẩm'
                                                                                name='image_url'
                                                                                value={selectedUrlImage}
                                                                                onChange={(event) => {
                                                                                    setSelectedUrlImage(event.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className='action-footer'>
                                                                            <button
                                                                                className='ladiui btn btn-secondary'
                                                                                onClick={() => {
                                                                                    popoverRef.current.instanceRef.hide();
                                                                                }}
                                                                            >
                                                                                Hủy
                                                                            </button>
                                                                            <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                                                Thêm hình ảnh
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                        <a className=''>Thêm từ URL</a>
                                                        </Popover>
                                                    </div>
                                                )
                                            }
                                        </>
                                        
                                    ) : (
                                        <div className='text-center'>
                                            <div className='ladiui-button-upload-image'>
                                                <div>
                                                    <p>
                                                        <a onClick={() => handleClickInputFile()}>Tải ảnh lên</a>
                                                    </p>
                                                    <span className='block ls-my-4'>Hoặc</span>
                                                    <Popover
                                                        ref={popoverRef}
                                                        wrapperProps={{ className: 'relative' }}
                                                        wrapperPopoverProps={{ className: 'popover-image-product' }}
                                                        // isShowPopover={isShowPopover}
                                                        content={
                                                            <div className='popover-add-image-product'>
                                                                <div className='popover'>
                                                                    <div className='ladiui form-group'>
                                                                        <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                                        <Input
                                                                            className='ladiui form-control'
                                                                            placeholder='Nhập đường dẫn sản phẩm'
                                                                            name='image_url'
                                                                            value={selectedUrlImage}
                                                                            onChange={(event) => {
                                                                                setSelectedUrlImage(event.target.value);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='action-footer'>
                                                                        <button
                                                                            className='ladiui btn btn-secondary'
                                                                            onClick={() => {
                                                                                popoverRef.current.instanceRef.hide();
                                                                            }}
                                                                        >
                                                                            Hủy
                                                                        </button>
                                                                        <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                                            Thêm hình ảnh
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <a className='pl-4'>Thêm từ URL</a>
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </>)
                        }
                    </div>
                </>
            )}

            {
                props.isAlwayShowButtonUpload && (
                    <div className='ls-flex ls-items-center ls-justify-center ls-gap-4'>
                        <p>
                            <a onClick={() => handleClickInputFile()}>Tải ảnh lên</a>
                        </p>
                        <span className='block ls-my-4'>Hoặc</span>
                        <Popover
                            ref={popoverRef}
                            wrapperProps={{ className: 'relative' }}
                            wrapperPopoverProps={{ className: 'popover-image-product' }}
                            // isShowPopover={isShowPopover}
                            content={
                                <div className='popover-add-image-product'>
                                    <div className='popover'>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                            <Input
                                                className='ladiui form-control'
                                                placeholder='Nhập đường dẫn sản phẩm'
                                                name='image_url'
                                                value={selectedUrlImage}
                                                onChange={(event) => {
                                                    setSelectedUrlImage(event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className='action-footer'>
                                            <button
                                                className='ladiui btn btn-secondary'
                                                onClick={() => {
                                                    popoverRef.current.instanceRef.hide();
                                                }}
                                            >
                                                Hủy
                                            </button>
                                            <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                Thêm hình ảnh
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                        <a className=''>Thêm từ URL</a>
                        </Popover>
                       
                    </div>
                )
            }

            <input
                className='cursor-pointer display-none'
                id={uploadId}
                type='file'
                name='file-3'
                accept='image/*'
                onChange={fileChangedHandler}
                onClick={(event) => {
                    event.target.value = null;
                }}
            />
        </div>
    );
}
