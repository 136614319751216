import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { map, includes, remove, find, cloneDeep } from 'lodash';

/** Import component from my app */
import { withTranslation } from 'react-i18next';
// import ModalTicket from './ModalTicket';

import appConfig from '../../../config/app';
import * as variantTypes from '../../../redux/futures/product_variant/types';
import variantActions from '../../../redux/futures/product_variant/actions';

import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import ConfirmModal from '../../../components/ConfirmModal';
import baseHelper from '../../../helpers/BaseHelper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import produce from 'immer';
import LoadingScene from '../../../components/LoadingScene';
import packageSeatActions from '../../../redux/futures/package/actions';
import ModalPackage from './ModalPackage';

import { t } from 'i18next';
import ModalPackageV4 from './ModalPackageV4';
import productHelper from '../../../helpers/ProductHelper';

const PanelPackage = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { i18n, onClick, info, productID } = props;

    const storeReducer = useSelector((state) => state.store);
    const variantReducer = useSelector((state) => state.variant);
    const productReducer = useSelector((state) => state.product);

    const isLoadingDeleteVariant = useMemo(() => {
        return variantReducer.loading || false;
    }, [variantReducer.loading]);

    const [packageInfo, setPackages] = useState([]);
    const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);
    const [options, setOptions] = useState([
        {
            name: t('PRODUCTS.VARIANT_PACKAGE_DEFAULT'),
            position: 1,
            type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
            values: [],
        },
        {
            name: t('PRODUCTS.VARIANT_PACKAGE_TYPE_DEFAULT'),
            position: 2,
            type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
            values: [],
        },
    ]);
    const [currentProductInfo, setCurrentProductInfo] = useState({});
    const [selectedVariant, setSelectedVariant] = useState({});
    const [pkgSelectID, setPkgSelectID] = useState(-1);

    const [isShowModalPackage, setIsShowModalPackage] = useState(false);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },

        async getData() {
            let variants = [];
            let _options = [...options];

            for await (let _package of packageInfo) {
                let packageVariant = await getNewVariant(_package, _options);
                variants = [...variants, ...packageVariant.variant];

                _options[0].values.push({ name: _package.package_name });
                _options[1].values = [..._options[1].values, ...packageVariant.option];
            }

            return {
                variants: variants,
                options: _options,
            };
        },
    }));

    useEffect(() => {
        if (variantReducer.action == variantTypes.CREATE_VARIANT) {
            if (variantReducer.status) {
                setIsShowModalPackage(false);
                dispatch(productActions.reload(props.productID));
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.UPDATE_VARIANT) {
            if (variantReducer.status) {
                setIsShowModalPackage(false);
                dispatch(productActions.reload(props.productID));
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.DELETE_VARIANT) {
            if (variantReducer.status) {
                dispatch(productActions.reload(props.productID));
                window.LadiUI.closeModal('confirm-variant-delete');
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.PACKAGE_CREATE) {
            if (variantReducer.status) {
                dispatch(productActions.reload(props.productID));
                setIsShowModalPackage(false);
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.PACKAGE_UPDATE) {
            if (variantReducer.status) {
                dispatch(productActions.reload(props.productID));
                setIsShowModalPackage(false);
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.PACKAGE_UPDATE_POSITION) {
            if (variantReducer.status) {
                setIsShowModalPackage(false);
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                dispatch(productActions.reload(props.productID));
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.PACKAGE_DELETE) {
            if (variantReducer.status) {
                dispatch(productActions.reload(props.productID));
                window.LadiUI.closeModal('confirm-package-delete');
                setSelectedVariant({});
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
    }, [variantReducer.action]);

    useEffect(() => {
        if (productReducer.action == productTypes.UPDATE_PRODUCT) {
            if (productReducer.status) {
                dispatch(productActions.reload(props.productID));
                setIsShowModalPackage(false);
                window.LadiUI.toastCustom('success', '', productReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer.action]);

    useEffect(() => {
        initUpdate();
    }, [props.variants, props.options]);

    useEffect(() => {
        setCurrentProductInfo(props.currentProductInfo);
    }, [props.currentProductInfo]);

    const initUpdate = async () => {
        if (!props.options || props.options.length <= 0 || props.options[0].values.length <= 0) {
            setPackages([]);
            setOptions([
                {
                    name: t('PRODUCTS.VARIANT_PACKAGE_DEFAULT'),
                    position: 1,
                    type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
                    values: [],
                },
                {
                    name: t('PRODUCTS.VARIANT_PACKAGE_TYPE_DEFAULT'),
                    position: 2,
                    type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
                    values: [],
                },
            ]);
            return;
        }

        let packages = await productHelper.getPackages(props.options, props.variants);
        setPackages(packages);
    };

    const openModalPackageCreate = () => {
        setSelectedVariant({});
        setPkgSelectID(-1);
        setIsShowModalPackage(true);
        setMode(appConfig.FORM_MODE.CREATE);
    };

    const openModalPackageEdit = (_selectedVariant, index) => {
        setSelectedVariant(_selectedVariant);
        setPkgSelectID(index);
        setMode(appConfig.FORM_MODE.EDIT);
        setIsShowModalPackage(true);
    };

    const removeTicket = (index) => {
        let _packageInfo = [...packageInfo];
        _packageInfo.splice(index, 1);
        setPackages(_packageInfo);
    };

    const openModelDelete = (variant) => {
        setSelectedVariant(variant);

        window.LadiUI.showModal('confirm-variant-delete');
    };

    const handleDeletePackage = (item, index) => {
        if (item.option_value_id) {
            setSelectedVariant(item);
            window.LadiUI.showModal('confirm-package-delete');
        } else {
            let _packageInfo = [...packageInfo];
            _packageInfo.splice(index, 1);
            setPackages(_packageInfo);
        }
    };

    const filteredVariant = () => {
        return packageInfo.map((item, index) => {
            let infoPackage = productHelper.getPackageInfo(item.options);
            // const isSoldOut =
            //     variant.inventory_checked == appConfig.STATUS.ACTIVE &&
            //     baseHelper.parseInt(variant.total_sold) >= baseHelper.parseInt(variant.total_quantity);
            return (
                <Draggable key={index} draggableId={'' + (item.product_option_id || index)} index={index}>
                    {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                display: 'table-row',
                            }}
                            className={'ladiui table-vertical'}
                        >
                            <td>
                                <div className='flex items-center'>
                                    <i className='ldicon-drag-drop' {...provided.dragHandleProps} />
                                </div>
                            </td>
                            <td>
                                {item.package_name}
                                {/* {isSoldOut && (
                                    <div className='badge badge-child-light sold-out flex items-center'>
                                        <i className='ladi-icon icon-c-warning mr-4' />
                                        {t('COMMON.SOLD_OUT')}
                                    </div>
                                )} */}
                            </td>
                            {/* <td>{variant.sku}</td> */}
                            <td className='text-nowrap'>
                                {baseHelper.formatMoneyPostFix(infoPackage.minPrice, storeReducer?.userInfo?.currentStore?.currency_symbol)}
                            </td>
                            <td>{item.package_quantity_unit}</td>
                            {/* <td>{item.package_quantity}</td> */}
                            <td>{`${baseHelper.parseInt(infoPackage.total_sold)}`}</td>
                            <td className='text-right pd-0'>
                                <div className='ladiui btn-group'>
                                    <div className='ladiui dropdown hide-mt ba-c'>
                                        {/* <i className="ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5" data-toggle="dropdown"></i> */}
                                        <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                            <i className='ladiui icon icon-ldp-dot'></i>
                                        </button>
                                        <ul className='ladiui dropdown-menu r-0'>
                                            <li>
                                                <a className='ladiui dropdown-item' onClick={() => openModalPackageEdit(item, index)}>
                                                    {t('ACTIONS.EDIT')}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className='ladiui dropdown-item'
                                                    onClick={() => {
                                                        if (props.mode == appConfig.FORM_MODE.CREATE) {
                                                            removeTicket(index);
                                                        } else {
                                                            handleDeletePackage(item, index);
                                                        }
                                                    }}
                                                >
                                                    {t('ACTIONS.DELETE')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEndPackage = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(packageInfo, result.source.index, result.destination.index);

        if (props.mode == appConfig.FORM_MODE.CREATE) {
            setPackages(items);
        } else {
            if (!items || items.length == 0) return;

            let productOptionValueIDs = items.map((item) => Number(item.option_value_id));
            let dataSend = {
                product_id: productID,
                product_option_id: items[0].option_id,
                product_option_value_ids: productOptionValueIDs,
            };

            dispatch(variantActions.packageUpdatePosition(dataSend));
            setPackages(items);
        }
    };

    const createPackageV2 = async (_packageInfo) => {
        let packageInfoTg = [...packageInfo];
        let isExists = false;

        // nếu kiểm tra xem đang tạo mới hay cập nhật gói
        let foundOptionValue;
        foundOptionValue = packageInfoTg.find(
            (_package) => _package.package_name == _packageInfo.package_name && _package.position !== _packageInfo.position
        );
        if (foundOptionValue) {
            isExists = true;
        }

        // nếu trùng lên thì báo lỗi
        if (isExists) {
            window.LadiUI.toastCustom('danger', '', t('PRODUCTS.EXISTS_PACKAGE_NAME'));
            return;
        }

        // kiểm tra xem đang tạo mới dịch vụ hay cập nhật
        if (props.mode == appConfig.FORM_MODE.CREATE) {
            // nếu là tạo mới dịch vụ
            // kiểm tra xem modal đang là tạo mới hay cập nhật gói
            if (mode == appConfig.FORM_MODE.CREATE) {
                packageInfoTg.push(_packageInfo);
            } else {
                if (pkgSelectID > -1) {
                    packageInfoTg[pkgSelectID] = _packageInfo;
                }
            }

            setPackages(packageInfoTg);
            setIsShowModalPackage(false);
        } else {
            //nếu là tạo mới thì thêm option mới

            if (mode == appConfig.FORM_MODE.CREATE) {
                // nếu là tạo mới thì thêm tên mới vào option
                let dataSend = {
                    product_id: productID,
                    package_info: _packageInfo,
                };
                dispatch(variantActions.packageCreate(dataSend));
            } else {
                let dataSend = {
                    product_id: productID,
                    package_info: _packageInfo,
                };
                dispatch(variantActions.packageUpdate(dataSend));
            }
        }
    };

    const getNewVariant = async (_packageInfo, optionsOld) => {
        let newVariants = [];
        let newOption = [];
        let _options = _packageInfo.options;
        let index = 0;

        let optionValueOld = optionsOld[1].values.map((item) => item.name);

        for await (let option of _options) {
            let variant = {
                price: option.price,
                price_compare: 0,
                cost_per_item: 0,
                sku: option.sku,
                description: _packageInfo.description,
                src: _packageInfo.src,
                package_quantity_unit: _packageInfo.package_quantity_unit,
                package_quantity: option.package_quantity,
                package_addition_quantity: option.package_addition_quantity,
                inventory_checked: option.inventory_checked ? 1 : 0,
                total_quantity: option.inventory_checked ? option.total_quantity : 0,
                options: {
                    [options[0].name]: _packageInfo.package_name,
                    [options[1].name]: `${option.package_quantity}`,
                },
            };
            newVariants.push(variant);

            if (!optionValueOld.includes(option.package_quantity))
                newOption.push({
                    name: option.package_quantity,
                });
            index++;
        }
        return {
            variant: newVariants,
            option: newOption,
        };
    };

    return (
        <div className='block-panel-package-v4'>
            {(!packageInfo || packageInfo.length == 0) && (
                <div className='block-no-package'>
                    <h3>{t('PRODUCT_SERVICE_V4.PACKAGE_CREATE')}</h3>
                    <p>{t('PRODUCT_SERVICE_V4.PACKAGE_CREATE_DES')}</p>
                    <button type='button' onClick={openModalPackageCreate}>
                        <i className='icon-ldp-add'></i>
                        {t('PRODUCT_SERVICE_V4.PACKAGE_ADD')}
                    </button>
                </div>
            )}

            {packageInfo && packageInfo.length > 0 && (
                <div className='block-list-packages-v4'>
                    <DragDropContext onDragEnd={onDragEndPackage}>
                        <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                                <div
                                    className='ladi-card'
                                    ref={provided.innerRef}
                                    style={{
                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                    }}
                                >
                                    <table className='ladiui table text-left'>
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col' />
                                                <th scope='col'>{t('PRODUCTS.VARIANT_PACKAGE_DEFAULT')}</th>
                                                {/* <th scope='col'>{t('PRODUCT_SERVICES.SKU')}</th> */}
                                                <th scope='col'>{t('PRODUCT_SERVICE_V4.PRICE_MIN')}</th>
                                                <th scope='col'>{t('PRODUCT_SERVICES.PACKAGE_QUANTITY_UNIT')}</th>
                                                {/* <th scope='col'>{t('PRODUCT_SERVICES.QUANTITY_UNIT')}</th> */}
                                                <th scope='col'>{t('PRODUCT_SERVICE_V4.SOLD_NUM')}</th>
                                                <th scope='col' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredVariant()}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <a onClick={openModalPackageCreate} className='flex items-center mt-24'>
                        <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' alt='' />
                        <span>{t('Tạo gói mới')}</span>
                    </a>
                </div>
            )}

            <ConfirmModal
                id='confirm-variant-delete'
                title={t('PRODUCT_SERVICES.MSG_DELETE_TICKET_SEAT_TITLE')}
                content={t('PRODUCT_SERVICES.MSG_DELETE_TICKET_SEAT_CONTENT', selectedVariant.option_value_value)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => dispatch(variantActions.delete(props.productID, selectedVariant.product_variant_id))}
                isLoading={isLoadingDeleteVariant}
            />

            <ConfirmModal
                id='confirm-package-delete'
                title={t('PRODUCT_SERVICES.MSG_DELETE_TICKET_SEAT_TITLE')}
                content={t('PRODUCT_SERVICES.MSG_DELETE_TICKET_SEAT_CONTENT', selectedVariant.package_name)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() =>
                    dispatch(
                        variantActions.packageDelete({ product_id: props.productID, option_value_id: selectedVariant.option_value_id })
                    )
                }
                isLoading={isLoadingDeleteVariant}
            />

            {isShowModalPackage && (
                <ModalPackageV4
                    mode={mode}
                    currentVariantInfo={pkgSelectID > -1 ? packageInfo[pkgSelectID] : {}}
                    images={[]}
                    onCancel={() => setIsShowModalPackage(false)}
                    onSubmit={createPackageV2}
                    currentProductInfo={currentProductInfo || {}}
                    visible={isShowModalPackage}
                    openModelDelete={openModelDelete}
                    // isLoading={isSubmitLoading}
                />
            )}
        </div>
    );
});

export default PanelPackage;
