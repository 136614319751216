import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import ImageLogoUpload from '../../../../components/ImageLogoUpload';
import pageCheckoutDefined from '../components/pageCheckoutDefined';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import Input from '../../../../components/Input';
import config from '../../../../config/config';
import BaseHelper from '../../../../helpers/BaseHelper';
import { set } from 'lodash';

// eslint-disable-next-line max-lines-per-function

const CheckoutConfig = React.forwardRef((props, ref) => {
    const CDN = 'https://w.ladicdn.com/';
    const inputsRef = new Set();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageCheckout } = props;
    const [menuSelected, setMenuSelected] = useState('');

    const fileReducerDefault = useSelector((state) => state.file);

    const [seo, setSeo] = useState({});

    const fileChangedHandlerCartFavicon = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = BaseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadFavicon(form));
            }
        }
    };

    const removeCartFavicon = () => {
        setSeo({
            ...seo,
            favicon: '',
        });
    };

    const handleChangeImageUrl = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = BaseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadImages(form));
            }
        }
    };

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return seo;
        },
    }));
    useEffect(() => {
        if (fileReducerDefault.action == fileTypes.UPLOAD_IMAGES) {
            if (fileReducerDefault.status) {
                if (fileReducerDefault.url_images) {
                    setSeo({
                        ...seo,
                        image: fileReducerDefault.url_images[0],
                    });
                }
            }
        }

        if (fileReducerDefault.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducerDefault.status) {
                setSeo({
                    ...seo,
                    favicon: fileReducerDefault.favicon_url,
                });
            }
        }
    }, [fileReducerDefault]);
    useEffect(() => {
        if (queryStrings) {
            let menu = queryStrings.get('checkout-config');
            if (!menu) {
                setMenuSelected('');
            } else {
                setMenuSelected(menu);
            }
        }
    }, [queryStrings]);

    useEffect(() => {
        if (pageCheckout) {
            let seo = pageCheckout.seo || {};
            setSeo(seo);
        }
    }, [pageCheckout]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('checkout-config');
        if (!menuSelected) {
            _queryStrings.delete('tab');
        }
        history.replace({ search: _queryStrings.toString() });
    };
    const handleUpdateSetting = (name, value) => {
        setSeo((preData) => ({
            ...preData,
            [name]: value,
        }));
    };

    return (
        <div className={`page-checkout-config-item ${isShow ? 'show' : 'hide'}`}>
            {!menuSelected && (
                <>
                    <div className='header'>
                        <div className='display-flex-center gap-16'>
                            <div className='icon-back' onClick={backMenu}>
                                <i className='ldicon-chevron-left' />
                            </div>
                            <div className='display-flex-center gap-4'>
                                <span className='ladiui title-header'>Seo & Social</span>
                            </div>
                        </div>
                    </div>
                    <div className='widget-config-item'>
                        <div className='ladiui form-group config-panel'>
                            <div className='panel'>
                                <div className='panel-line'>
                                    <div className='main-description'>
                                        Favicon
                                        <br />
                                        <div className='sub-description' style={{ whiteSpace: 'nowrap' }}>
                                            Kích thước 16x16 (px)
                                            <br />
                                            (Định dạng .png, .jpg, .svg)
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='preview-store-logo'>
                                            <div id='store-logo'>
                                                <img
                                                    className='store-logo'
                                                    src={
                                                        seo.favicon
                                                            ? `${CDN}${seo.favicon}`
                                                            : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                    }
                                                    alt=''
                                                />
                                                <div id='store-logo-overlay'>
                                                    <div className='ladiui btn-group'>
                                                        <div className='ladiui dropdown'>
                                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                                <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                            </button>
                                                            <ul
                                                                className='ladiui dropdown-menu custom-dropdown-menu'
                                                                style={{ minWidth: '100px', textAlign: 'left' }}
                                                            >
                                                                <li className='ladiui open-modal'>
                                                                    <label htmlFor='upload-social-favicon'>Thay ảnh</label>
                                                                </li>
                                                                <li
                                                                    className='ladiui dropdown-item danger-text'
                                                                    onClick={() => removeCartFavicon()}
                                                                >
                                                                    Xóa
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <input
                                                type='file'
                                                id='upload-social-favicon'
                                                name='file'
                                                accept='image/*'
                                                multiple={false}
                                                onChange={fileChangedHandlerCartFavicon}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('SEO_SOCIAL.TITLE_PAGE')}</label>
                            <Input
                                ref={(ref) => inputsRef.add(ref)}
                                placeholder={t('SEO_SOCIAL.ENTER_TITLE')}
                                value={seo.title}
                                onChange={(event) => {
                                    setSeo({
                                        ...seo,
                                        title: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('SEO_SOCIAL.DESCRIPTION')}</label>
                            <textarea
                                ref={(ref) => inputsRef.add(ref)}
                                placeholder={t('SEO_SOCIAL.ENTER_DESCRIPTION')}
                                value={seo.description}
                                className='ladiui form-control'
                                onChange={(event) => {
                                    setSeo({
                                        ...seo,
                                        description: event.target.value,
                                    });
                                }}
                            ></textarea>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('SEO_SOCIAL.KEY_WORD')}</label>
                            <Input
                                ref={(ref) => inputsRef.add(ref)}
                                placeholder={t('SEO_SOCIAL.ENTER_KEY_WORD')}
                                value={seo.keywords}
                                onChange={(event) => {
                                    setSeo({
                                        ...seo,
                                        keywords: event.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Hình ảnh khi chia sẻ</label>
                            <div className='input-upload-file relative w100'>
                                <Input
                                    className='pr-100'
                                    ref={(ref) => inputsRef.add(ref)}
                                    value={seo.image}
                                    placeholder={t('SEO_SOCIAL.CHOOSE_PICTURE')}
                                    onChange={(event) => {
                                        setSeo({
                                            ...seo,
                                            image: event.target.value,
                                        });
                                    }}
                                />
                                <div className='ladiui-button-upload'>
                                    <button type='button' className='flex'>
                                        <i className='icon-export mr-4'></i>
                                        <span>Tải lên</span>
                                    </button>
                                    <input type='file' name='file' accept='image/*' onChange={handleChangeImageUrl} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export default CheckoutConfig;
