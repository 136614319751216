import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from './Input';

import fileActions from '../redux/futures/file/actions';
import * as fileTypes from '../redux/futures/file/types';

import productActions from '../redux/futures/product/actions';
import * as productTypes from '../redux/futures/product/types';

import DatePicker from './DatePicker/CustomDatePicker';
import { Editor } from '@tinymce/tinymce-react';

import SelectManyDropdown from './SelectManyDropdown';

import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';

import LoadingScene from './LoadingScene';
import ProductSearchV4 from './ProductSearchV4';

import moment from 'moment';
import { map, compact, find, includes } from 'lodash';
import Popover from './Popover';
import Image from './Image';
import ValidatorHelper from '../helpers/ValidatorHelper';

let CDN = 'https://w.ladicdn.com/';

// eslint-disable-next-line max-lines-per-function
function ModalAddProductView(props) {
    const { t, onClose, isShow, innerStyle, productId } = props;
    const wrapperRef = useRef();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const productReducer = useSelector((state) => state.product);

    const inputsRef = new Set();
    const popoverRef = useRef();

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });

    const eventCancel = () => {
        onClose(false);
    };

    const [selectedUrlImage, setSelectedUrlImage] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [dataProductView, setDataProductView] = useState({
        avatar: '',
        name: '',
        point: 5,
        images: [],
        date_created: new Date(),
        content: '',
    });

    useEffect(() => {
        if (props.selectedProductReview) {
            let _data = props.selectedProductReview;

            setDataProductView({
                avatar: _data.avatar ? _data.avatar : dataProductView.avatar,
                name: _data.name ? _data.name : dataProductView.name,
                point: _data.point ? _data.point : dataProductView.point,
                images: _data.images ? _data.images : dataProductView.images,
                date_created: _data.date_created ? new Date(_data.date_created) : dataProductView.date_created,
                content: _data.content ? _data.content : dataProductView.content,
                product_reviews_id: _data.product_reviews_id ? _data.product_reviews_id : null,
            });
        }
    }, [props.selectedProductReview]);

    useEffect(() => {
        if (props.isManageReview) {
            let payload = {
                search: {},
                paged: 1,
                limit: 20,
            };

            dispatch(productActions.list(payload));
        }
    }, [props.isManageReview]);

    useEffect(() => {
        if (productReducer.action == productTypes.LIST_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    let _data = [...products];

                    productReducer.products.map((item, index) => {
                        const isExitProduct = _data.some((_item) => _item.value == item.product_id);
                        if (!isExitProduct) {
                            _data.unshift({
                                name: item.name,
                                value: item.product_id,
                            });
                        }
                    });

                    setProducts(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_AVATAR) {
            if (fileReducer.status) {
                if (fileReducer.avatar_url) {
                    setDataProductView({
                        ...dataProductView,
                        avatar: fileReducer.avatar_url,
                    });
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_PRODUCT_REVIEW_IMAGES) {
            let _selectedImages = [...dataProductView.images];
            if (fileReducer.status) {
                const newUploadedImage = map(fileReducer.product_images, (item) => item);

                if (newUploadedImage && newUploadedImage.length > 0 && _selectedImages.length + newUploadedImage.length <= 6) {
                    map(newUploadedImage, (item, index) => {
                        _selectedImages.push(item);
                    });

                    setDataProductView({
                        ...dataProductView,
                        images: _selectedImages,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', 'Số lượng ảnh không được vượt quá 6', 'OK');
                }

                // selectedBanners.push(newUploadedImage);

                // setDataProductView({
                //     ...dataProductView,
                //     images: _selectedImages
                // })
            }
        }
    }, [fileReducer]);

    const removeAvatarReview = () => {
        setDataProductView({
            ...dataProductView,
            avatar: '',
        });
    };

    const fileChangedHandlerAvatarReview = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadAvatar(form));
            }
        }
    };

    const onChangeDataProductView = (e) => {
        let { name, value } = e.target;

        setDataProductView((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddView = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        if (!dataProductView.content || dataProductView.content == '') {
            window.LadiUI.toastCustom('danger', '', 'Vui lòng nhập nội dung đánh giá!');
            return;
        }

        if (props.isManageReview && !dataProductView.product_reviews_id) {
            let payload = {
                product_ids: selectedProducts && selectedProducts.length > 0 ? selectedProducts.map((item) => item.product_id) : [],
                review: {
                    ...dataProductView,
                },
            };

            props.handleCreateReviews(payload);
        } else {
            if (dataProductView.product_reviews_id) {
                let payload = {
                    review: {
                        ...dataProductView,
                    },
                };

                props.handleUpdateReview(payload);
            } else {
                let payload = {
                    product_id: props.productID,
                    review: {
                        ...dataProductView,
                    },
                };

                props.handleCreateReview(payload);
            }
        }
    };

    const handleClickInputFileImageReview = () => {
        document.getElementById('upload-list-image-review').click();
    };

    const fileChangedHandlerListImageReview = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadProductPreviewImages(form));
            }
        }
    };
    const handleDeleteImagePreview = (index) => {
        let _selectedImages = [...dataProductView.images];
        _selectedImages.splice(index, 1);

        setDataProductView({
            ...dataProductView,
            images: _selectedImages,
        });
    };

    const handleSearchData = (value) => {
        let payload = {
            search: {
                name: value,
            },
            paged: 1,
            limit: 20,
        };

        dispatch(productActions.list(payload));
    };

    const handleRefreshDataProduct = () => {
        let payload = {
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(productActions.list(payload));
    };

    const handleEditorChange = (value) => {
        setDataProductView({
            ...dataProductView,
            content: value,
        });
    };

    const onChangePointProductView = (value) => {
        let _point = parseInt(dataProductView.point);
        let _value = parseInt(value);

        setDataProductView({
            ...dataProductView,
            point: _point + _value < 0 ? 0 : _point + _value > 5 ? 5 : _point + _value,
        });
    };

    const onChangePointProductViewInput = (event) => {
        let { value } = event.target;
        let _value = parseInt(value);

        setDataProductView({
            ...dataProductView,
            point: value < 0 ? 0 : _value > 5 ? 5 : _value,
        });
    };

    const handleAddImageUrl = () => {
        if (selectedUrlImage && ValidatorHelper.isValidHttpUrl(selectedUrlImage)) {
            let _selectedImages = [...dataProductView.images];
            if (_selectedImages && _selectedImages.length < 6) {
                _selectedImages.push(selectedUrlImage);

                setDataProductView({
                    ...dataProductView,
                    images: _selectedImages,
                });
            } else {
                window.LadiUI.toastCustom('danger', '', 'Số lượng hình ảnh không vượt quá 6!');
            }
            popoverRef.current.instanceRef.hide();
            setSelectedUrlImage('');
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };

    const handleClickInputFile = () => {
        document.getElementById('input-upload-file').click();
    };
    const handleClickUploadFile2 = () => {
        document.getElementById('input-upload-file-2').click();
    };

    const handleSelectProductSearch = (product) => {
        let _selectedProducts = [...selectedProducts];

        if (find(_selectedProducts, (item) => item.product_id == product.product_id)) {
            return;
        }

        let productItem = {
            name: product.name,
            product_id: product.product_id,
            product_variant_id: product.product_variant_id,
            price: product.price || '',
            max_price: product.max_price || '',
            min_price: product.min_price || '',
            src: product.src || '',
        };

        _selectedProducts.push(productItem);
        setSelectedProducts(_selectedProducts);
    };

    const getPriceProduct = (item) => {
        let price = 0;
        if (!item.price) {
            if (item.min_price && item.max_price) {
                if (item.min_price == item.max_price) {
                    price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                } else {
                    price =
                        baseHelper.formatMoneyPostFix(item.min_price, storeInfo.currentStore.currency_symbol) +
                        ' - ' +
                        baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                }
            } else {
                price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
            }
        } else {
            price = baseHelper.formatMoneyPostFix(item.price, storeInfo.currentStore.currency_symbol);
        }

        return price;
    };

    const handleDeleteProductSelected = (index) => {
        let _selectedProducts = [...selectedProducts];
        _selectedProducts.splice(index, 1);
        setSelectedProducts(_selectedProducts);
    };

    const isLoadingUploadImage =
        fileReducer.loading && includes([fileTypes.UPLOAD_PRODUCT_REVIEW_IMAGES, fileTypes.UPLOAD_AVATAR], fileReducer.waiting);

    return (
        <>
            {isLoadingUploadImage && <LoadingScene blur={true} />}
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-product-review'>
                <div className='ladiui modal-dialog modal-dialog-centered w-900' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>
                                {dataProductView.product_reviews_id ? 'Sửa đánh giá' : 'Thêm mới đánh giá'}
                            </h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body' style={{ position: 'initial' }}>
                            <div className='block-content-item'>
                                <h3>Nội dung đánh giá</h3>
                                <div className='flex items-center'>
                                    <div className='ladiui form-group mt-24' style={{ width: '30%' }}>
                                        <label className='ladiui-label new'>Ảnh đại diện</label>
                                        <div className='panel'>
                                            <div className='panel-line'>
                                                <div className='content'>
                                                    <div className='preview-store-logo'>
                                                        <div id='store-logo' style={{ width: 'max-content' }}>
                                                            <img
                                                                className='store-logo'
                                                                src={
                                                                    dataProductView.avatar
                                                                        ? `${CDN}${dataProductView.avatar}`
                                                                        : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                }
                                                                alt=''
                                                            />
                                                            <div id='store-logo-overlay'>
                                                                <div className='ladiui btn-group'>
                                                                    <div className='ladiui dropdown'>
                                                                        <button
                                                                            data-toggle='dropdown'
                                                                            className='ladiui-btn-dropdown dropdown-toggle'
                                                                            style={{ minWidth: 'auto' }}
                                                                        >
                                                                            <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                                        </button>
                                                                        <ul
                                                                            className='ladiui dropdown-menu custom-dropdown-menu'
                                                                            style={{ minWidth: '100px', textAlign: 'left', left: 0 }}
                                                                        >
                                                                            <li className='ladiui open-modal'>
                                                                                <label htmlFor='upload-avatar-review'>Thay ảnh</label>
                                                                            </li>
                                                                            <li
                                                                                className='ladiui dropdown-item danger-text'
                                                                                onClick={() => removeAvatarReview()}
                                                                            >
                                                                                Xóa
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type='file'
                                                            id='upload-avatar-review'
                                                            name='file'
                                                            accept='image/*'
                                                            multiple={false}
                                                            onChange={fileChangedHandlerAvatarReview}
                                                            onClick={(event) => {
                                                                event.target.value = null;
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>Tên khách hàng</label>
                                        <Input
                                            name='name'
                                            placeholder={'Nhập tên khách hàng'}
                                            value={dataProductView.name}
                                            onChange={(e) => onChangeDataProductView(e)}
                                            validationName={'tên khách hàng'}
                                            ref={(ref) => inputsRef.add(ref)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                </div>
                                <div className='flex items-center gap-24'>
                                    <div className='ladiui form-group mt-24'>
                                        <label className='ladiui-label new'>Điểm đánh giá (Tối đa 5 điểm)</label>
                                        <div className='variants-quantity'>
                                            <div className='variant-quantity-up-down space-between w100'>
                                                <input
                                                    type='number'
                                                    name='point'
                                                    value={dataProductView.point}
                                                    onChange={(e) => onChangePointProductViewInput(e)}
                                                />
                                                <div className='variant-up-down'>
                                                    <i className='new-ldicon-arrow-up' onClick={() => onChangePointProductView(+1)} />
                                                    <i className='new-ldicon-arrow-down' onClick={() => onChangePointProductView(-1)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>Thời gian tạo</label>
                                        <div style={{ width: '100%' }}>
                                            <DatePicker
                                                name='start'
                                                // minDate={baseHelper.momentToDate(moment())}
                                                selected={dataProductView.date_created}
                                                onChange={(value) => {
                                                    setDataProductView({
                                                        ...dataProductView,
                                                        date_created: value,
                                                    });
                                                }}
                                                showTimeSelect
                                                timeFormat={appConfig.DEFAUT_TIME}
                                                timeIntervals={15}
                                                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                timeCaption='Time'
                                                customPlaceholder='Chọn thời gian'
                                                customClass='w100'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>Nội dung đánh giá</label>
                                    <textarea
                                        className='form-control'
                                        name='content'
                                        value={dataProductView.content}
                                        style={{
                                            width: '100%',
                                            minHeight: '150px',
                                            maxHeight: '250px',
                                            height: '100%',
                                            border: '1px solid #cfd3d8',
                                            padding: '16px 16px',
                                            borderRadius: 8,
                                        }}
                                        onChange={(e) => onChangeDataProductView(e)}
                                        placeholder='Nhập nội dung đánh giá'
                                    ></textarea>
                                </div>
                            </div>
                            <div className='block-content-item mt-20'>
                                <h3 className='mb-24'>
                                    Hình ảnh đánh giá ({dataProductView.images ? dataProductView.images.length : 0}/6)
                                </h3>
                                <div className='list-image-product-reviews'>
                                    {!dataProductView.images || dataProductView.images.length == 0 ? (
                                        <div className='text-center image-none'>
                                            <div className='ladiui-button-image'>
                                                <div className='btn-add-image'>
                                                    <div>
                                                        <img
                                                            src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-import-image.svg'}
                                                            alt=''
                                                        />
                                                    </div>
                                                    <div className='mt-16'>
                                                        <span className='text-default'>
                                                            <a className='pr-4' onClick={() => handleClickInputFile()}>
                                                                Tải ảnh lên
                                                            </a>
                                                            hoặc
                                                            <Popover
                                                                ref={popoverRef}
                                                                wrapperProps={{ className: 'relative' }}
                                                                wrapperPopoverProps={{ className: 'popover-image-product' }}
                                                                // isShowPopover={isShowPopover}
                                                                content={
                                                                    <div className='popover-add-image-product'>
                                                                        <div className='popover'>
                                                                            <div className='ladiui form-group'>
                                                                                <label className='ladiui-label new text-left'>
                                                                                    Đường dẫn hình ảnh
                                                                                </label>
                                                                                <Input
                                                                                    className='ladiui form-control'
                                                                                    placeholder='Nhập đường dẫn sản phẩm'
                                                                                    name='image_url'
                                                                                    value={selectedUrlImage}
                                                                                    onChange={(event) => {
                                                                                        setSelectedUrlImage(event.target.value);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className='action-footer'>
                                                                                <button
                                                                                    className='ladiui btn btn-secondary'
                                                                                    onClick={() => {
                                                                                        popoverRef.current.instanceRef.hide();
                                                                                    }}
                                                                                >
                                                                                    Hủy
                                                                                </button>
                                                                                <button
                                                                                    className='ladiui btn btn-primary'
                                                                                    onClick={handleAddImageUrl}
                                                                                >
                                                                                    Thêm hình ảnh
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            >
                                                                <a className='pl-4'>Thêm từ URL</a>
                                                            </Popover>
                                                        </span>
                                                        <p className='rec-size'>Kích thước khuyến nghị 400x400 (px)</p>
                                                    </div>
                                                </div>
                                                <input
                                                    className='cursor-pointer display-none'
                                                    id='input-upload-file'
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    multiple='multiple'
                                                    onChange={fileChangedHandlerListImageReview}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='list-images'>
                                            {map(dataProductView.images, (imageItem, index) => {
                                                let prefixCDN = true;
                                                if (imageItem && imageItem.startsWith('http')) {
                                                    prefixCDN = false;
                                                }

                                                return (
                                                    <div className='image-item' key={index}>
                                                        <div className='img'>
                                                            <Image prefixCDN={prefixCDN} src={imageItem} key={index} />
                                                        </div>
                                                        <div className='action-group'>
                                                            <div
                                                                className='block-delete-img cursor-pointer'
                                                                onClick={() => {
                                                                    handleDeleteImagePreview(index);
                                                                }}
                                                            >
                                                                <i style={{ color: '#fff' }} className='ladi-icon icon-bin' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className='text-center'>
                                                <div className='ladiui-button-upload-image'>
                                                    <div>
                                                        <p>
                                                            <a onClick={() => handleClickUploadFile2()}>Tải ảnh lên</a>
                                                        </p>
                                                        <span className='block my-8'>Hoặc</span>
                                                        <Popover
                                                            ref={popoverRef}
                                                            wrapperProps={{ className: 'relative' }}
                                                            wrapperPopoverProps={{ className: 'popover-image-product ' }}
                                                            // isShowPopover={isShowPopover}
                                                            content={
                                                                <div className='popover-add-image-product'>
                                                                    <div className='popover'>
                                                                        <div className='ladiui form-group'>
                                                                            <label className='ladiui-label new text-left'>
                                                                                Đường dẫn hình ảnh
                                                                            </label>
                                                                            <Input
                                                                                className='ladiui form-control'
                                                                                placeholder='Nhập đường dẫn sản phẩm'
                                                                                name='image_url'
                                                                                value={selectedUrlImage}
                                                                                onChange={(event) => {
                                                                                    setSelectedUrlImage(event.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className='action-footer'>
                                                                            <button
                                                                                className='ladiui btn btn-secondary'
                                                                                onClick={() => {
                                                                                    popoverRef.current.instanceRef.hide();
                                                                                }}
                                                                            >
                                                                                Hủy
                                                                            </button>
                                                                            <button
                                                                                className='ladiui btn btn-primary'
                                                                                onClick={handleAddImageUrl}
                                                                            >
                                                                                Thêm hình ảnh
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <a className='pl-4'>Thêm từ URL</a>
                                                        </Popover>
                                                    </div>
                                                    <input
                                                        className='cursor-pointer display-none'
                                                        id='input-upload-file-2'
                                                        type='file'
                                                        name='file'
                                                        accept='image/*'
                                                        multiple='multiple'
                                                        onChange={fileChangedHandlerListImageReview}
                                                        onClick={(event) => {
                                                            event.target.value = null;
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {props.isManageReview && !dataProductView.product_reviews_id && (
                                <div className='block-content-item mt-20'>
                                    <div className='header-top flex items-center space-between mb-20'>
                                        <h3>Danh sách sản phẩm áp dụng đánh giá</h3>
                                        <div className='info-help flex items-center'>
                                            <i className='ladi-icon icon-c-question mr-8' />
                                            <span>Tìm hiểu thêm</span>
                                        </div>
                                    </div>
                                    <ProductSearchV4 selectProduct={handleSelectProductSearch} />
                                    <div className='block-list-products-category-v4'>
                                        {selectedProducts && selectedProducts.length > 0 ? (
                                            <div className='list-products'>
                                                <table className='ladiui table text-left'>
                                                    <thead>
                                                        <tr className='ladiui table-vertical'>
                                                            <th width='50'></th>
                                                            <th>Tên sản phẩm</th>
                                                            <th>Giá sản phẩm</th>
                                                            <th className='text-right'></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {map(selectedProducts, (product, index) => {
                                                            return (
                                                                <tr key={index} className='ladiui table-vertical'>
                                                                    <td>
                                                                        <div className='flex image-product items-center'>
                                                                            <div className='img'>
                                                                                <img
                                                                                    src={
                                                                                        product.src
                                                                                            ? CDN + product.src
                                                                                            : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                                    }
                                                                                    width={60}
                                                                                    height={60}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{product.name}</td>
                                                                    <td>
                                                                        {/* {product.price
                                                                            ? baseHelper.formatMoneyPostFix(
                                                                                product.price,
                                                                                storeInfo.currentStore.currency_symbol
                                                                            )
                                                                            : 0} */}
                                                                        {getPriceProduct(product)}
                                                                    </td>
                                                                    <td className='text-right'>
                                                                        <a href='#' onClick={() => handleDeleteProductSelected(index)}>
                                                                            <i className='ladi-icon icon-bin' />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className='no-products'>
                                                <div className='icon'>
                                                    <i className='new-ldicon-template' />
                                                </div>
                                                <p className='text-center'>
                                                    Chưa có sản phẩm nào trong danh mục này.
                                                    <br />
                                                    Tìm và bổ sung bằng thanh tìm kiếm.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* <div className="block-content-product-view config-panel">
                                {
                                    props.isManageReview && !dataProductView.product_reviews_id
                                    &&
                                    <div className='ladiui form-group'>
                                        <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                                            Chọn sản phẩm
                                        </label>
                                        <SelectManyDropdown
                                            data={products}
                                            _key='name'
                                            _value='value'
                                            pickTitle={'Tiêu đề'}
                                            placeHolder={'Chọn sản phẩm'}
                                            onSubmit={(items) => {
                                                setSelectedProducts(items);
                                            }}
                                            innerClassName='w100'
                                            styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                            buttonStyle={{
                                                width: '100%',
                                                paddingRight: '30px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: ' -webkit-box',
                                                WebkitLineClamp: 1,
                                                webkitBoxOrient: 'vertical',
                                                textAlign: 'left',
                                            }}
                                            selectedItems={selectedProducts}
                                            isShowBlockInputSearchCallApi={true}
                                            isShowBlockInputSearch={true}
                                            isManageReview={true}
                                            isCourse={true}
                                            handleSearchData={handleSearchData}
                                            handleRefreshData={handleRefreshDataProduct}
                                        />
                                    </div>
                                }
                            </div> */}
                        </div>

                        <div className='ladiui modal-footer'>
                            <div className='ladiui footer-modal-btn'>
                                <button
                                    type='button'
                                    data-dismiss='modal'
                                    data-target='add-product'
                                    className='ladiui btn btn-secondary bold'
                                    onClick={eventCancel}
                                >
                                    {t('ACTIONS.CANCEL')}
                                </button>
                                <button
                                    type='button'
                                    data-dismiss='modal'
                                    data-target='add-product'
                                    className={`ladiui btn btn-primary bold ${props.isLoading ? 'loader' : ''}`}
                                    onClick={() => handleAddView()}
                                >
                                    {dataProductView.product_reviews_id ? t('ACTIONS.SAVE') : t('ACTIONS.ADD')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withTranslation()(ModalAddProductView);
