/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';

import storePageActions from '../../redux/futures/store_page/actions';
import * as storePageTypes from '../../redux/futures/store_page/types';

import LoadingTable from '../../components/LoadingTable';
import ConfirmModal from '../../components/ConfirmModal';
import LadiPagination from '../../components/LadiPagination';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';

import { includes, map, remove } from 'lodash';

export default function StorePage(props) {
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const [activePage, setActivePage] = useState(1);
    const [keyword, setKeyword] = useState('');
    const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
    const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);
    const [currentPageCheckout, setCurrentPageCheckout] = useState({});
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [isReloadIframe, setReloadIframe] = useState(false);

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const storePageReducer = useSelector((state) => state.storePage);

    let selectedID = '';
    // let selectedIDs = [];

    // let sortBy = {
    //   page_checkout_id: "DESC",
    // };
    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const Moment = baseHelper.getMoment();

    useEffect(() => {
        list();
    }, [selectedLimit]);

    useEffect(() => {
        list(false);
    }, [activePage]);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_DELETE) {
            if (storePageReducer.status) {
                window.LadiUI.toastCustom('success', '', storePageReducer.message);
                list();
                window.LadiUI.closeModal('confirm-page-store-delete');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
        if (storePageReducer.action == storePageTypes.STORE_PAGE_DELETES) {
            if (storePageReducer.status) {
                window.LadiUI.toastCustom('success', '', storePageReducer.message);
                list();
                window.LadiUI.closeModal('confirm-page-store-deletes');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    /**
     * Tim kiem theo name
     */
    const onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            list();
        }
    };

    const list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        if (resetActivePageFlag) {
            setActivePage(1);
        }

        // Reset selectedIDs
        setSelectedIDs([]);

        const data = {
            keyword: keyword,
            search: {
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            },
            paged: activePage,
            limit: selectedLimit,
        };

        dispatch(storePageActions.storePageList(data));
    };

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    const onChangeLimit = (option) => {
        setSelectedLimit(option.value);
        setActivePage(1);
    };

    /**
     * Change page
     */
    const onPageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    const checkAllItem = (event) => {
        const { checked } = event.target;

        let ids = [];
        if (checked) {
            ids = map(storePageReducer.store_pages, (item) => item.page_checkout_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            ids = [];
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        setSelectedIDs(ids);
    };

    const checkItem = (event, storePageID) => {
        const { checked } = event.target;

        let ids = [...selectedIDs];

        if (checked) {
            if (!includes(ids, storePageID)) {
                ids.push(storePageID);
            }

            if (ids.length == storePageReducer.store_pages.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(ids, (selectedID) => selectedID == storePageID);
        }

        setSelectedIDs(ids);
    };

    const openDeletesModal = () => {
        if (selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-page-store-deletes');
    };

    const eventSelectActions = (action) => {
        switch (action) {
            case 'DELETE':
                openDeletesModal();
                break;
            default:
        }
    };

    const handleDeleteStorePage = (_selectedID) => {
        if (_selectedID) {
            selectedID = _selectedID;
            window.LadiUI.showModal('confirm-page-store-delete');
        }
    };

    const openModalEdit = (pageCheckoutID) => {
        if (pageCheckoutID) {
            props.history.push(`/store-page/update/${pageCheckoutID}`);
        }
    };

    const { totalRecord, totalPage } = storePageReducer;
    let fromItem = 0;
    let toItem = 0;
    if ((storePageReducer.store_pages || []).length > 0) {
        fromItem = (activePage - 1) * selectedLimit + 1;
        toItem = fromItem + storePageReducer.store_pages.length - 1;
    }

    const isLoadingData = false;
    const isLoadingInfo = false;
    const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
        'PAGINATION.OF'
    )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

    const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

    return (
        <div id='home-page' className='customer-page'>
            <div>
                <div className='ladiui-table-actions'>
                    <div className='ladiui content-main-tool block-filter-condition flex-row align-item-baseline'>
                        <div className='ladiui search-group mr-24'>
                            <input
                                id='search'
                                className='ladiui search-field dropdown-toggle form-control search-width'
                                onChange={(event) => setKeyword(event.target.value)}
                                placeholder={t('PAGE_CHECKOUT.SEARCH')}
                                aria-expanded='false'
                                value={keyword}
                                onKeyDown={onKeyDownInput}
                            />
                            <i className='ladiui icon icon-search' onClick={list}></i>
                        </div>
                    </div>
                    <div className='action-btn'>
                        <button className='ladiui btn btn-primary btn-sm' onClick={() => props.history.push(`/store-page/create`)}>
                            <img
                                className='ladiui btn-custom-img'
                                src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                alt=''
                            />
                            {t('STORES.ADD_STORE')}
                        </button>
                    </div>
                </div>

                {totalRecord <= 0 ? (
                    <div className='block-no-data'>
                        <div>
                            <img src='https://w.ladicdn.com/ladiui/ladipage/icon-not-foder.svg' />
                            <p>Bạn chưa có cửa hàng nào</p>
                        </div>
                    </div>
                ) : (
                    <>
                        {isLoadingData ? (
                            <LoadingTable />
                        ) : (
                            <div>
                                <div className='ladi-card ladiui-table-responsive'>
                                    <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''}`}>
                                        <thead style={{ position: 'relative' }}>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col' className='ladiui checkall'>
                                                    <input
                                                        id='checkAllItem'
                                                        onClick={checkAllItem}
                                                        type='checkbox'
                                                        className='ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle'
                                                    />
                                                </th>

                                                {selectedIDs && selectedIDs.length <= 0 ? (
                                                    <th scope='col' name='name' className={`text-left`}>
                                                        {t('STORES.NAME')}
                                                    </th>
                                                ) : (
                                                    <th>
                                                        {t('STORES.NAME')}
                                                        <LadiDropdownMenu
                                                            labelDropdown={'Chọn hành động'}
                                                            listProp={listAction}
                                                            defaultTextProp={t('ACTIONS.TITLE')}
                                                            hideLabel={true}
                                                            cbProp={eventSelectActions}
                                                            classSize='btn-sm '
                                                            classDropdown='w-180 action-record-table'
                                                        />
                                                    </th>
                                                )}
                                                <th scope='col' name='name' className={`text-left`}>
                                                    {t('COMMON.STATUS')}
                                                </th>
                                                <th scope='col' name='created_at' className={`text-left`}>
                                                    {t('COMMON.UPDATED_AT')}
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    {'Đường dẫn'}
                                                </th>
                                                <th scope='col' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(storePageReducer.store_pages, (item) => {
                                                return (
                                                    <tr key={item.page_checkout_id} className='ladiui table-vertical main'>
                                                        <td scope='row'>
                                                            <input
                                                                type='checkbox'
                                                                onClick={(event) => checkItem(event, item.page_checkout_id)}
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                            />
                                                        </td>
                                                        <td style={{ maxWidth: '600px', paddingRight: '10px' }}>
                                                            <a
                                                                className='limit-text-vertical'
                                                                onClick={() => openModalEdit(item.page_checkout_id)}
                                                            >
                                                                {item.name}
                                                            </a>
                                                        </td>
                                                        <td>{item.status === 1 ? 'Hiển thị' : 'Không hiển thị'}</td>
                                                        <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                                                        <td>
                                                            <a href={item.url_published ? item.url_published : ''} target='_blank'>
                                                                {item.url_published ? item.url_published : ''}
                                                            </a>
                                                        </td>
                                                        <td className='text-right pd-0'>
                                                            <div className='ladiui btn-group'>
                                                                <div className='ladiui dropdown hide-mt ba-c'>
                                                                    <button
                                                                        data-toggle='dropdown'
                                                                        className='ladiui-btn-dropdown dropdown-toggle'
                                                                    >
                                                                        <i className='ladiui icon icon-ldp-dot'></i>
                                                                    </button>
                                                                    <ul className='ladiui dropdown-menu r-0'>
                                                                        <li onClick={() => openModalEdit(item.page_checkout_id)}>
                                                                            <a className='ladiui dropdown-item'>{t('ACTIONS.EDIT')}</a>
                                                                        </li>
                                                                        <li onClick={() => handleDeleteStorePage(item.page_checkout_id)}>
                                                                            <a className='ladiui dropdown-item'>{t('ACTIONS.DELETE')}</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {totalRecord <= 0 && (
                                                <tr className='text-center'>
                                                    <td colSpan='100%'>{t('NO_DATA')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                {totalRecord > 0 && (
                                    <LadiPagination
                                        conditionsProp={{ page: activePage, limit: selectedLimit }}
                                        listName={t('PAGINATION.ITEMS')}
                                        message={''}
                                        items={storePageReducer.store_pages}
                                        total={totalRecord}
                                        limitAction={onChangeLimit}
                                        pageAction={onPageChange}
                                    />
                                )}
                            </div>
                        )}
                    </>
                )}

                <ConfirmModal
                    id='confirm-page-store-delete'
                    title={'Xóa cửa hàng?'}
                    content={'Cửa hàng này sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa cửa hàng này ?'}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => dispatch(storePageActions.storePageDelete(selectedID))}
                    // isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-page-store-deletes'
                    title={'Xóa cửa hàng?'}
                    content={'Các cửa hàng được chọn sẽ bị xóa vĩnh viễn. Bạn có chắc muốn xóa những cửa hàng này ?'}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => dispatch(storePageActions.storePageDeletes(selectedIDs))}
                    // isLoading={isLoadingDelete}
                />
            </div>
        </div>
    );
}
