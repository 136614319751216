import validator from 'validator';
import baseHelper from './BaseHelper';
import punnycode from 'punycode/';
import isValidDomain from 'is-valid-domain';

class ValidatorHelper {
    isLength(data, max = undefined, min = 0) {
        return validator.isLength(data, min, max);
    }

    isLengthMoreThan(data = '', min) {
        return data.length < min;
    }

    isLengthLessThan(data = '', max) {
        return data.length > max;
    }

    isEmpty(data = '') {
        return baseHelper.isEmpty(data);
    }

    isPhone(data) {
        if (this.isEmpty(data)) {
            return true;
        }
        const regexp = /^(\+|0|00)?[0-9]{7,15}$/;
        return regexp.test(data)
        // return validator.isMobilePhone(data);
    }

    isEmail(data) {
        if (this.isEmpty(data)) {
            return true;
        }

        return validator.isEmail(data);
    }

    isDomain(data) {
        if (this.isEmpty(data)) {
            return true;
        }
        data = punnycode.toASCII(data);

        try {
            return isValidDomain(data);
        } catch (err) {
            return false;
        }
    }

    isURL(url) {
        if (this.isEmpty(url)) {
            return true;
        }

        const regex = new RegExp(
            '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$'
        );
        return regex.test(url);
    }
    isValidHttpUrl(string) {
        if (this.isEmpty(string)) {
            return true;
        }
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return true;
    }
}

export default new ValidatorHelper();
