import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import Input from '../../../components/Input';
import { Editor } from '@tinymce/tinymce-react';
import Location from '../../../components/Location';
import Dropdown from '../../../components/Dropdown';
import timezones from '../../../config/timezone';
import CURRENCIES from '../../../config/currencies';
import Image from '../../../components/Image';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';
import storeActions from '../../../redux/futures/store/actions';
import * as storeTypes from '../../../redux/futures/store/types';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { map, compact } from 'lodash';

export default function PageGeneralSetting(props) {
    const dispatch = useDispatch();
    const queryURL = baseHelper.useQuery();
    const inputsRef = new Set();
    const inputGeneralRef = new Set();
    const locationRef = useRef();

    const fileReducer = useSelector((state) => state.file);
    const storeReducer = useSelector((state) => state.store);
    const cookieReducer = useSelector((state) => state.cookieReducer);

    const LS_STORE_ID = useMemo(() => {
        return cookieReducer.STORE_ID;
    }, [cookieReducer.STORE_ID]);

    const [storeInfo, setStoreInfo] = useState({
        name: '',
        country_code: '',
        country_name: '',
        postal_code: '',
        state_id: '',
        state_name: '',
        district_id: '',
        district_name: '',
        ward_id: '',
        ward_name: '',
        order_prefix: '',
        ticket_seat_prefix: '',
        currency_code: '',
        currency_symbol: '',
        phone: '',
        address: '',
        timezone: '',
        logo_url: '',
        favicon_url: '',
        cover_url: '',
        description: '',
        terms_of_use: '',
        privacy_policy: '',
        refund_policy: '',
    });

    useEffect(() => {
        dispatch(storeActions.show(LS_STORE_ID));
        window.onbeforeunload = function () {
            return null;
        };
        // window.onbeforeunload = function () {
        //     // Your Code here
        //     return null; // return null to avoid pop up
        // };
    }, []);

    useEffect(() => {
        if (storeReducer.action == storeTypes.SHOW_STORE) {
            if (storeReducer.status) {
                setStoreInfo({
                    ...storeReducer.store,
                });
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storeReducer.message, 'OK');
            }
        }
        if (storeReducer.action == storeTypes.UPDATE_STORE) {
            if (storeReducer.status) {
                setTimeout(() => {
                    dispatch(storeActions.reload(LS_STORE_ID));
                }, 0);
                window.LadiUI.toastCustom('success', '', storeReducer.message);
                dispatch(storeActions.getUserInfo());
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storeReducer.message, 'OK');
            }
        }
    }, [storeReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_LOGO_URL) {
            if (fileReducer.status) {
                setStoreInfo({
                    ...storeInfo,
                    logo_url: fileReducer.logo_url,
                });
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducer.status) {
                setStoreInfo({
                    ...storeInfo,
                    favicon_url: fileReducer.favicon_url,
                });
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_COVER_URL) {
            if (fileReducer.status) {
                setStoreInfo({
                    ...storeInfo,
                    cover_url: fileReducer.cover_url,
                });
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    const onChangeInput = (event) => {
        let { name, value } = event.target;
        if (name == 'order_prefix') {
            setStoreInfo({
                ...storeInfo,
                [name]: value.toUpperCase(),
            });
        } else {
            setStoreInfo({
                ...storeInfo,
                [name]: value,
            });
        }
    };

    // const handleUpdate = (value, editor) => {
    //     const length = editor.getContent({ format: 'text' }).length;
    //     if (length <= sizeLimit) {
    //       setValue(value);
    //       setLength(length);
    //     }
    //   };

    const onChangeDescription = (value, editor) => {
        setStoreInfo({
            ...storeInfo,
            description: value,
        });
    };

    const onChangeLocation = (address) => {
        setStoreInfo({
            ...storeInfo,
            ...address,
        });
    };

    const selectTimezone = (selectedItem) => {
        setStoreInfo({
            ...storeInfo,
            timezone: selectedItem.CODE,
        });
    };

    const selectCurrency = (selectedItem) => {
        setStoreInfo({
            ...storeInfo,
            currency_code: selectedItem.code,
            currency_symbol: selectedItem.symbol_native,
        });
    };

    const fileChangedHandler = (event, type) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                switch (type) {
                    case 'logo':
                        dispatch(fileActions.uploadLogo(form));
                        break;
                    case 'favicon':
                        dispatch(fileActions.uploadFavicon(form));
                        break;
                    case 'cover':
                        dispatch(fileActions.uploadCover(form));
                        break;
                }
            }
        }
    };

    const submit = (event) => {
        event.preventDefault();
        const expression = /^[a-zA-Z0-9]{0,10}$/;
        const regex = new RegExp(expression);

        // Validate
        let errors = [];
        const _inputGeneralRef = compact(Array.from(inputGeneralRef));
        map(_inputGeneralRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });
        errors = errors.concat(locationRef.current.validate());
        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            let elm = document.getElementById('trigger-tab-general');
            if (elm) elm.click();
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const address = locationRef.current.getData();

        let { ticket_seat_prefix, order_prefix } = storeInfo;
        let isNotMatch = false;

        if (order_prefix) {
            if (order_prefix.match(regex)) {
                isNotMatch = false;
            } else {
                isNotMatch = true;
            }
        }

        if (isNotMatch) {
            let order_prefix_input = document.getElementById('order_prefix_input');
            order_prefix_input.classList.add('input_err');
            window.LadiUI.toastCustom('danger', '', 'Tiền tố đơn hàng không chứa các ký tự đặc biệt, tối đa 10 ký tự!');
        } else {
            let order_prefix_input = document.getElementById('order_prefix_input');
            order_prefix_input.classList.remove('input_err');

            dispatch(
                storeActions.update({
                    ...storeInfo,
                    ...address,
                })
            );
        }
    };

    return (
        <>
            <div className='page-general-setting-v4'>
                <div className='header-title'>
                    <div className='title-desc'>
                        <h3>Thông tin cửa hàng</h3>
                        <p>Thông tin chi tiết về cửa hàng của bạn</p>
                    </div>
                    <div className='action'>
                        <button className='ladiui btn btn-primary' onClick={(e) => submit(e)}>
                            Lưu thay đổi
                        </button>
                    </div>
                </div>
                <div className='content'>
                    <div className='list-blocks'>
                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Thông tin cơ bản</h3>
                                <p>Tên và thông tin xuất hiện trên trang cửa hàng</p>
                            </div>
                            <div className='content-block'>
                                <div className='ladiui form-group mt-0'>
                                    <label className='ladiui-label new'>{t('STORES.NAME')}</label>
                                    <Input
                                        ref={(ref) => inputGeneralRef.add(ref)}
                                        name='name'
                                        validationName={t('STORES.NAME')}
                                        placeholder={t('STORES.NAME')}
                                        value={storeInfo.name}
                                        onChange={onChangeInput}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>{t('COMMON.DESCRIPTION')}</label>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={storeInfo.description}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={onChangeDescription}
                                        onChange={() => { }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='line-space-block'></div>

                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Địa chỉ cửa hàng</h3>
                                <p>
                                    Thông tin địa chỉ sẽ hiển thị trên trang cửa hàng, hóa đơn bán hàng, và được sử dụng để tính phí vận
                                    chuyển khi thanh toán.
                                </p>
                            </div>
                            <div className='content-block'>
                                <div className='ladiui form-group mt-0'>
                                    <label className='ladiui-label new'>{t('COMMON.ADDRESS')}</label>
                                    <Input
                                        name='address'
                                        validationName={t('COMMON.ADDRESS')}
                                        placeholder={t('COMMON.ADDRESS')}
                                        value={storeInfo.address}
                                        onChange={onChangeInput}
                                        ref={(ref) => inputGeneralRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='flex gap-16 mt-24'>
                                    <div className='ladiui form-group mt-0'>
                                        <label className='ladiui-label new'>{t('COMMON.PHONE')}</label>
                                        <Input
                                            name='phone'
                                            validationName={t('COMMON.PHONE')}
                                            placeholder={t('COMMON.PHONE')}
                                            value={storeInfo.phone}
                                            onChange={onChangeInput}
                                            ref={(ref) => inputGeneralRef.add(ref)}
                                            validations={{ phone: true, isRequired: true }}
                                        />
                                    </div>
                                    <div className='ladiui form-group mt-0'>
                                        <label className='ladiui-label new'>{t('COMMON.POSTAL')}</label>
                                        <Input name='postal_code' value={storeInfo.postal_code} onChange={onChangeInput} />
                                    </div>
                                </div>
                                <div className='ladiui form-group' style={{ display: 'unset' }}>
                                    <Location
                                        ref={locationRef}
                                        wrapperClassName='ladiui form-group'
                                        wrapperProps={{ style: { margin: 'auto' } }}
                                        address={storeInfo}
                                        autoLoad={true}
                                        onChangeInput={onChangeLocation}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='line-space-block'></div>

                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Tiêu chuẩn và định dạng</h3>
                                <p>
                                    Các tiêu chuẩn và định dạng được sử dụng để tính toán những thứ như giá cả sản phẩm, trọng lượng và thời
                                    gian đặt đơn hàng...
                                </p>
                            </div>
                            <div className='content-block'>
                                <div className='flex gap-16'>
                                    <div className='ladiui form-group mt-0'>
                                        <label className='ladiui-label new'>{t('COMMON.TIMEZONE')}</label>
                                        <Dropdown
                                            data={timezones}
                                            onSelectItem={selectTimezone}
                                            currentKey={storeInfo.timezone}
                                            _key={'CODE'}
                                            _value={'NAME'}
                                            isShowBlockInputSearch={true}
                                        />
                                    </div>
                                    <div className='ladiui form-group mt-0'>
                                        <label className='ladiui-label new'>{t('STORES.CURRENCY_CODE')}</label>

                                        <Dropdown
                                            data={CURRENCIES}
                                            onSelectItem={selectCurrency}
                                            currentKey={storeInfo.currency_symbol}
                                            currentValue={storeInfo.currency_symbol}
                                            _key={'code'}
                                            _value={'code'}
                                            isShowBlockInputSearch={true}
                                        />
                                    </div>
                                </div>
                                <div className='flex gap-16 mt-24'>
                                    <div className='ladiui form-group mt-0'>
                                        <label className='ladiui-label new'>{t('STORES.TICKET_SEAT_PREFIX')}</label>
                                        <Input
                                            ref={(ref) => inputGeneralRef.add(ref)}
                                            name='ticket_seat_prefix'
                                            validationName={t('STORES.TICKET_SEAT_PREFIX')}
                                            placeholder='DH'
                                            value={storeInfo.ticket_seat_prefix}
                                            onChange={onChangeInput}
                                        />
                                    </div>
                                    <div className='ladiui form-group mt-0'>
                                        <label className='ladiui-label new'>{t('STORES.ORDER_PREFIX')}</label>
                                        <Input
                                            ref={(ref) => inputGeneralRef.add(ref)}
                                            id='order_prefix_input'
                                            name='order_prefix'
                                            validationName={t('STORES.ORDER_PREFIX')}
                                            placeholder='Nhập tiền tố đơn hàng'
                                            value={storeInfo.order_prefix}
                                            onChange={onChangeInput}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='line-space-block'></div>

                        <div className='block-item'>
                            <div className='title-block'>
                                <h3>Thương hiệu</h3>
                                <p>Những yếu tố thương hiệu được sử dụng phổ biến trên các kênh bán hàng.</p>
                            </div>
                            <div className='content-block'>
                                <div className='group-store-logo flex gap-24'>
                                    <div className='text-center image setting-block-image'>
                                        <Image src={storeInfo.logo_url} width={150} />
                                        <div className='ladiui-button-upload image block-upload-image'>
                                            <div className='block-overlay' style={{ marginTop: '30px' }}>
                                                <label htmlFor="setting-logo">
                                                    <i className='ladi-icon icon-data-upload' /> <br />
                                                    <p>{t('COMMON.CHOOSE_FILES')}</p>
                                                </label>
                                                <p>Logo(150x150px)</p>

                                                <input
                                                    id="setting-logo"
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    onChange={(event) => fileChangedHandler(event, 'logo')}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-center image setting-block-image'>
                                        <Image src={storeInfo.favicon_url} width={150} />
                                        <div className='ladiui-button-upload image block-upload-image'>
                                            <div className='block-overlay' style={{ marginTop: '30px' }} >
                                                <label htmlFor="setting-favicon">
                                                    <i className='ladi-icon icon-data-upload' /> <br />
                                                    <p>{t('COMMON.CHOOSE_FILES')}</p>
                                                </label>
                                                <p>Favicon(150x150px)</p>

                                                <input
                                                    id="setting-favicon"
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    onChange={(event) => fileChangedHandler(event, 'favicon')}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='text-center image setting-block-image'>
                                        <Image src={storeInfo.cover_url} width={300} />
                                        <div className='ladiui-button-upload image block-upload-image'>
                                            <div className='block-overlay' style={{ marginTop: '30px' }} >
                                                <label htmlFor="setting-banner">
                                                    <i className='ladi-icon icon-data-upload' /> <br />
                                                    <p>{t('COMMON.CHOOSE_FILES')}</p>
                                                </label>
                                                <p>Banner(90x404px)</p>

                                                <input
                                                    id="setting-banner"
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    onChange={(event) => fileChangedHandler(event, 'cover')}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='line-space-block'></div>
                    </div>
                </div>
                {/* <div className='action-footer'>
                    <button className='ladiui btn btn-primary' onClick={(e) => submit(e)}>Lưu thay đổi</button>
                </div> */}
            </div>
        </>
    );
}
