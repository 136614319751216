import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const list = (data) => ({
  type: types.LIST_TICKET_SEAT,
  meta: {
    endpoint: endpoint.TICKET_SEAT_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const update = (data) => ({
  type: types.UPDATE_TICKET_SEAT,
  meta: {
    endpoint: endpoint.TICKET_SEAT_UPDATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const changeCheckIn = (data) => ({
  type: types.CHANGE_CHECK_IN,
  meta: {
    endpoint: endpoint.TICKET_SEAT_CHANGE_CHECK_IN,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const _delete = (ticketSeatID) => ({
  type: types.DELETE_TICKET_SEAT,
  meta: {
    endpoint: endpoint.TICKET_SEAT_DELETE,
    method: REQUEST_METHOD.POST,
    body: {
      ticket_seat_id: ticketSeatID,
    },
    hasAuth: true,
  },
});

const checkIn = (data) => ({
  type: types.CHECK_IN_TICKET_SEAT,
  meta: {
    endpoint: endpoint.TICKET_SEAT_CHECK_IN,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const importSeatTicket = (form) => ({
  type: types.IMPORT_TICKET_SEAT,
  meta: {
    endpoint: endpoint.IMPORT_TICKET_SEAT,
    method: REQUEST_METHOD.POST,
    form,
    hasAuth: true,
  },
});

const exportSeatTicket = (eventId) => ({
  type: types.EXPORT_TICKET_SEAT,
  meta: {
    endpoint: endpoint.EXPORT_TICKET_SEAT,
    method: REQUEST_METHOD.POST,
    body: {
      event_id: eventId,
    },
    hasAuth: true,
  },
});

const downloadTemplateTicketSeat = () => ({
  type: types.DOWNLOAD_TEMPLATE_TICKET_SEAT,
  meta: {
    endpoint: endpoint.DOWNLOAD_TEMPLATE_TICKET_SEAT,
    method: REQUEST_METHOD.POST,
    body: {},
    hasAuth: true,
  },
});

const showTicketSeat = (data) => ({
  type: types.SHOW_TICKET_SEAT,
  meta: {
    endpoint: endpoint.TICKET_SEAT_SHOW,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  list,
  update,
  delete: _delete,
  changeCheckIn,
  checkIn,
  importSeatTicket,
  exportSeatTicket,
  downloadTemplateTicketSeat,
  showTicketSeat,
};
