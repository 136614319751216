import React from 'react';

class LoadingScene extends React.Component {
    render() {
        const { blur } = this.props;
        return (
            <div
                className={`builder-loading ${blur ? 'builder-loading-blur' : ''}`}
                style={{
                    // backgroundImage: `url('https://w.ladicdn.com/ladiui/ladipage-loading.gif')`,
                    backgroundImage: `url('https://w.ladicdn.com/ladiui/icons/ladi-loading.svg')`,
                }}
            ></div>
        );
    }
}

export default LoadingScene;
