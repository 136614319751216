import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Customer from './Customer';
import CustomerTags from './CustomerTags/CustomerTags';
import CustomerCustomField from './CustomerCustomField/CustomerCustomField';

export default function CustomerV2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { search } = useLocation();

    // order_tags
    const orderTagReducer = useSelector((state) => state.orderTag);

    const order_tags = useMemo(() => {
        return orderTagReducer.order_tags || [];
    }, [orderTagReducer.order_tags]);

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    // const queryStrings = new URLSearchParams(location.search);
    // const tab = queryStrings.get('tab');

    const [listOrder, setListOrder] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [tabSelected, setTabSelected] = useState(1);
    const [tagSelected, setTagSelected] = useState([]);
    const [isShowSearch, setShowSearch] = useState(false);
    const [searchTag, setSearchTag] = useState('');

    const [filters, setFilter] = useState({
        fromDate: moment(new Date()).subtract(30, 'd'),
        toDate: moment(new Date()),
    });

    useEffect(() => {
        const tab = queryStrings.get('tab');
        // if (tab == 1) {
        //     dispatch(orderTagAction.listAll());
        // }
        setTabSelected(tab || 1);
    }, [queryStrings]);

    // useEffect(() => {
    //     if (queryStrings && order_tags.length > 0) {
    //         let tags = queryStrings.get('tags');
    //         if (!tags) {
    //             setTagSelected([]);
    //         } else {
    //             tags = tags.split('-');
    //             let tagTg = order_tags.filter((item) => tags.includes(item.order_tag_id + ''));
    //             setTagSelected(tagTg);
    //         }
    //     }
    // }, [queryStrings, order_tags]);

    const selectTab = (index) => {
        let _queryStrings = new URLSearchParams();
        _queryStrings.set('tab', index);
        props.history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const selectTags = (data) => {
        let index = tagSelected.findIndex((item) => item.order_tag_id == data.order_tag_id);
        let tagTg = [...tagSelected];

        if (index == -1) {
            tagTg.push(data);
        } else {
            tagTg.splice(index, 1);
        }

        // let _queryStrings = new URLSearchParams();
        let tags = queryStrings.get('tags');
        if (!tags) tags = [];
        else tags = tags.split('-');

        let index2 = tags.findIndex((item) => item == data.order_tag_id);
        if (index2 == -1) {
            tags.push(data.order_tag_id);
        } else {
            tags.splice(index2, 1);
        }

        tags = tags.join('-');

        queryStrings.set('tags', tags);
        props.history.replace({ search: queryStrings.toString() });
        // setTagSelected(tagTg);
    };

    return (
        <div className='page-order'>
            <div className='menu-left'>
                <div className='title'>{t('CUSTOMERS.TITLE')}</div>
                <div className='menu-list'>
                    <div
                        className={`menu-item ${tabSelected == 1 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(1);
                        }}
                    >
                        <i className='new-ldicon-inbox' /> <span>{t('CUSTOMER_V2.CUSTOMER_MANAGENMENT')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 2 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(2);
                        }}
                    >
                        <i className='new-ldicon-tag' /> <span>{t('ORDERS.TAB_TITLE.ORDER_TAG')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 3 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(3);
                        }}
                    >
                        <i className='new-ldicon-custom-field' /> <span>{t('ORDERS.TAB_TITLE.ORDER_CUSTOM')}</span>
                    </div>
                </div>
            </div>
            <div className='page-max'>
                <div className='page-right'>
                    {tabSelected == 1 && <Customer location={location} history={props.history} />}
                    {tabSelected == 2 && <CustomerTags location={location} />}
                    {tabSelected == 3 && <CustomerCustomField location={location} />}
                </div>
            </div>
        </div>
    );
}
