/** Import default package */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/** Import from third party */
import { cloneDeep, map, compact } from 'lodash';

/** Import component from my app */
import Input from '../../../../components/Input';

import { withTranslation } from 'react-i18next';
import appConfig from '../../../../config/app';
import Location from '../../../../components/Location';
import baseHelper from '../../../../helpers/BaseHelper';

class PanelAddressGHN extends React.Component {
    static propTypes = {
        address: PropTypes.object,
        mode: PropTypes.string.isRequired,
        isRequiredState: PropTypes.bool,
        isRequiredDistrict: PropTypes.bool,
        isRequiredWard: PropTypes.bool,
        isRequiredPhone: PropTypes.bool,
    };

    static defaultProps = {
        isRequiredState: false,
        isRequiredDistrict: false,
        isRequiredWard: false,
        isRequiredPhone: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            address: {
                address: '',
                country_code: '',
                country_name: '',
                postal_code: '',
                state_id: '',
                state_name: '',
                district_id: '',
                district_name: '',
                ward_id: '',
                ward_name: '',
                phone: '',
            },
        };
        this.initialState = cloneDeep(this.state);
        this.ref = React.createRef();
        this.inputsRef = new Set();
        this.locationRef = React.createRef();
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }
        if (this.props.address != nextProps.address) {
            if (this.props.mode == appConfig.FORM_MODE.CREATE) {
                this.setState({
                    address: {
                        ...this.state.address,
                        first_name: nextProps.address.first_name,
                        last_name: nextProps.address.last_name,
                        phone: nextProps.address.phone,
                    },
                });
            } else {
                this.init(nextProps);
            }
        }
    }

    init = (props) => {
        const newAddress = {};
        map(this.state.address, (value, key) => {
            newAddress[key] = !baseHelper.isEmpty(props.address[key]) ? props.address[key] : '';
        });

        this.setState({
            address: newAddress,
        });
    };
    /*******************************HANDLE INPUT***************************/
    onChangeAddressInput = (event) => {
        this.setState({
            address: {
                ...this.state.address,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeLocation = (address) => {
        this.setState({
            address: {
                ...this.state.address,
                ...address,
            },
        });
    };

    /*****************************************************************/

    validate = () => {
        // Validate
        const { t } = this.props;
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = errors.concat(this.locationRef.current.validate());

        errors = compact(errors);

        return errors;
    };

    getData = () => {
        return { ...this.state.address, ...this.locationRef.current.getData() };
    };

    render() {
        const { t, styleCustom } = this.props;
        const { address } = this.state;
        const { language } = this.props.store.userInfo;

        return (
            <div>
                <div className='ladiui-row'>
                    <div className='col-50'>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('CUSTOMERS.PHONE')}</label>
                            <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name='phone'
                                validationName={t('CUSTOMERS.PHONE')}
                                placeholder={t('CUSTOMERS.PHONE')}
                                value={address.phone}
                                onChange={this.onChangeAddressInput}
                                validations={{
                                    isRequired: this.props.isRequiredPhone,
                                    phone: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-50'>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('CUSTOMERS.ADDRESS')}</label>
                            <Input
                                name='address'
                                placeholder={t('CUSTOMERS.ADDRESS')}
                                validationName={t('CUSTOMERS.ADDRESS')}
                                value={address.address}
                                onChange={this.onChangeAddressInput}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: true }}
                            />
                        </div>
                    </div>
                </div>
                <Location
                    wrapperClassName='mt-20'
                    ref={this.locationRef}
                    address={address}
                    autoLoad={this.props.mode == appConfig.FORM_MODE.EDIT ? true : false}
                    onChangeInput={this.onChangeLocation}
                    validations={{
                        isRequiredCountry: true,
                        isRequiredState: this.props.isRequiredState,
                        isRequiredDistrict: this.props.isRequiredDistrict,
                        isRequiredWard: this.props.isRequiredWard,
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    store: { ...state.store },
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(PanelAddressGHN)
);
