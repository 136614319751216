/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* global FB */
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { cloneDeep, map, compact, isEmpty, includes, forEach, filter } from 'lodash';
import axios from 'axios';
import ContentEditable from 'react-contenteditable';
import LoadingScene from '../../../components/LoadingScene';
import appConfig, { endpoint } from '../../../config/app';
import Input from '../../../components/Input';
import Dropdown from '../../../components/Dropdown';
import { appLocalStorage } from '../../../localforage';

// import * as types from '../../../redux/futures/integration/types';
// import * as oauthTypes from '../../../redux/futures/oauth/types';
import * as apiTypes from '../../../redux/futures/api/types';

import { iconIntegration } from '../../../config/constans';

import apiAction from '../../../redux/futures/api/actions';
// import action from '../../../redux/futures/integration/actions';
// import oauthAction from '../../../redux/futures/oauth/actions';
import * as formAccountTypes from '../../../redux/futures/form_account/types';
import formAccountActions from '../../../redux/futures/form_account/actions';

import Modal from '../../../components/ModalV4';
import { integrationTypes } from '../../../config/constans';

class ModalIntegrationInfo extends React.Component {
    constructor(props) {
        super(props);
        // this.statusChangeCallback = this.statusChangeCallback.bind(this);

        this.state = {
            id: '',
            name: '',
            selectedType: props.typeIntegration,
            selectedSmsType: 2,
            allTypes: [
                { type: 'ALL', name: 'Tất cả nền tảng', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.HARAVAN.CODE, name: 'Haravan', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.SAPO.CODE, name: 'Sapo', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.SHOPIFY.CODE, name: 'Shopify', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.WORDPRESS.CODE, name: 'Wordpress', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.KIOTVIET.CODE, name: 'KiotViet', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.GOOGLE_SHEET.CODE, name: 'Google Sheets', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.NHANH.CODE, name: 'Nhanh.vn', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.GET_RESPONSE.CODE, name: 'Getresponse', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE, name: 'ActiveCampaign', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.HUBSPOT.CODE, name: 'Hubspot', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE, name: 'Infusionsoft', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.CONVERTKIT.CODE, name: 'Convertkit', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.MAIL_CHIMP.CODE, name: 'MailChimp', isShowCustomer: false },
            ],
            secretKey: '',
            apiKey: '',
            activePage: 1,
            isCreate: false,
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            showFooter: true,
            error: '',
            selectedPage: {},
            isShowModalListPost: false,
            connected: false,
            pages: [],
            oaInfo: {
                oa_id: '',
                token: '',
                name: '',
            },
            token: '',
            integration: {},
            apiUrl: '',
            shopID: '',
            refreshToken: '',
            folderID: '',
            emailVerify: '',
            userName: '',
            isShowModalEditor: false,
            userID: '',
            teamID: '',
            teamName: '',
            apiKeyStore: '',
            accountID: '',
            mode: this.props.mode || null,
            code: '',
            showApiKey: false,
        };

        this.initialState = cloneDeep(this.state);
        this.ref = React.createRef();
        this.inputsRef = new Set();
        this.fileInputRef = React.createRef();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        window.LadiUI.init();

        // window.fbAsyncInit = function () {
        //     FB.init({
        //         appId: appConfig.FLOW.FACEBOOK.APP_ID,
        //         cookie: true,
        //         xfbml: true,
        //         version: 'v12.0', // 'v12.0',
        //         autoLogAppEvents: true,
        //     });
        //     FB.AppEvents.logPageView();
        // };

        // Load the SDK asynchronously
        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            const js = d.createElement(s);
            js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

        if (this.props.currentInfo) {
            if (this.props.currentInfo.type === appConfig.FORM_TYPE.SHOPIFY.CODE) {
                this.props.getApiInfo();
            }
            this.init(this.props.currentInfo);
        }

        if (this.props.typeIntegration) {
            this.setState({
                selectedType: this.props.typeIntegration,
            });
        }

        if (window.FB) {
            // Read the entire document for `fb-*` classnames
            FB.XFBML.parse();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillUnmount() {
        this.setState({
            ...this.initialState,
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (nextProps.typeIntegration !== this.props.typeIntegration && nextProps.typeIntegration) {
            this.setState({
                selectedType: nextProps.typeIntegration,
            });
        }

        if (this.props.visible !== nextProps.visible) {
            this.setState({
                ...this.initialState,
            });
        }

        if (this.props.currentInfo !== nextProps.currentInfo) {
            this.init(nextProps.currentInfo);
        }

        if (this.props.formAccountReducer.action !== nextProps.formAccountReducer.action) {
            switch (nextProps.formAccountReducer.action) {
                case formAccountTypes.INTEGRATE_FORM_ACCOUNT_UPDATE: {
                    if (nextProps.formAccountReducer.status) {
                        window.LadiUI.toastCustom('success', '', nextProps.formAccountReducer.message);
                        this.props.onSubmit();
                        this.props.onSuccess(`?tab=my`);
                        this.onCancel();
                    } else {
                        window.LadiUI.showErrorMessage('Thông báo', nextProps.formAccountReducer.message, 'OK');
                    }
                    break;
                }
                case formAccountTypes.INTEGRATE_HARAVAN: {
                    if (nextProps.formAccountReducer.status) {
                        const width = 500;
                        const height = 650;
                        const left = window.screen.width / 2 - width / 2;
                        const top = window.screen.height / 2 - height / 2;
                        const targetWin = window.open(
                            nextProps.formAccountReducer.url,
                            'popupAuthHaravan',
                            `width=${width}, height=${height}, left=${left}, top=${top}`
                        );

                        targetWin.focus();
                        // window.addEventListener('message', this.eventListenerHaravan, false);
                        window.callBackEventHaravan = (event) => {
                            let data = {};
                            // if (event.origin !== `${window.location.protocol}//${window.location.hostname}`) {
                            //     return;
                            // }
                            data.token = event.data && event.data.code ? event.data.code : '';
                            data.shopID = event.data && event.data.shop ? event.data.shop : '';
                            if (
                                data &&
                                !isEmpty(data.token.toString()) &&
                                !isEmpty(data.shopID.toString()) &&
                                typeof data.token === 'string'
                            ) {
                                this.setState(
                                    {
                                        token: event.data.code,
                                        shopID: event.data.shop,
                                    },
                                    () => {
                                        this.submit();
                                    }
                                );
                            }
                        };
                    } else {
                        window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                    }
                    break;
                }

                case formAccountTypes.INTEGRATE_CREATE_HARAVAN:
                    if (nextProps.formAccountReducer.status) {
                        window.LadiUI.toastCustom('success', '', nextProps.formAccountReducer.message);
                        this.props.onSubmit();
                        this.props.onSuccess(`?tab=my`);
                        this.onCancel();
                    }
                    break;

                case formAccountTypes.INTEGRATE_SAPO: {
                    if (nextProps.formAccountReducer.status) {
                        const width = 500;
                        const height = 650;
                        const left = window.screen.width / 2 - width / 2;
                        const top = window.screen.height / 2 - height / 2;
                        const targetWin = window.open(
                            nextProps.formAccountReducer.url,
                            'popupAuth',
                            `width=${width}, height=${height}, left=${left}, top=${top}`
                        );

                        targetWin.focus();
                        // window.addEventListener('message', this.eventListener, false);
                        window.callBackEventSapo = (event) => {
                            let data = {};
                            data.token = event.data && event.data.code ? event.data.code : '';
                            data.store_url = event.data && event.data.store ? event.data.store : '';
                            if (
                                data &&
                                !isEmpty(data.token.toString()) &&
                                !isEmpty(data.store_url.toString()) &&
                                typeof data.token === 'string'
                            ) {
                                this.setState(
                                    {
                                        token: event.data.code,
                                        apiUrl: event.data.store,
                                    },
                                    () => {
                                        this.submit();
                                    }
                                );
                            }
                        };
                    } else {
                        window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                    }
                    break;
                }

                case formAccountTypes.INTEGRATE_CREATE_SAPO:
                    if (nextProps.formAccountReducer.status) {
                        window.LadiUI.toastCustom('success', '', nextProps.formAccountReducer.message);
                        this.props.onSubmit();
                        this.props.onSuccess(`?tab=my`);
                        this.onCancel();
                    }
                    break;

                case formAccountTypes.INTEGRATE_NHANH: {
                    if (nextProps.formAccountReducer.status) {
                        const width = 500;
                        const height = 650;
                        const left = window.screen.width / 2 - width / 2;
                        const top = window.screen.height / 2 - height / 2;
                        const targetWin = window.open(
                            nextProps.formAccountReducer.url,
                            'popupAuth',
                            `width=${width}, height=${height}, left=${left}, top=${top}`
                        );

                        targetWin.focus();
                        window.callBackEventNhanh = (event) => {
                            let data = {};
                            data.token = event.data && event.data.code ? event.data.code : '';
                            if (data && !isEmpty(data.token.toString()) && typeof data.token === 'string') {
                                this.setState(
                                    {
                                        token: event.data.code,
                                    },
                                    () => {
                                        this.submit();
                                    }
                                );
                            }
                        };
                    } else {
                        window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                    }
                    break;
                }

                case formAccountTypes.INTEGRATE_CREATE_NHANH:
                    if (nextProps.formAccountReducer.status) {
                        window.LadiUI.toastCustom('success', '', nextProps.formAccountReducer.message);
                        this.props.onSubmit();
                        this.props.onSuccess(`?tab=my`);
                        this.onCancel();
                    }
                    break;

                case formAccountTypes.INTEGRATE_GOOGLE_SHEETS: {
                    if (nextProps.formAccountReducer.status) {
                        const width = 500;
                        const height = 650;
                        const left = window.screen.width / 2 - width / 2;
                        const top = window.screen.height / 2 - height / 2;
                        const targetWin = window.open(
                            nextProps.formAccountReducer.url,
                            'popupAuth',
                            `width=${width}, height=${height}, left=${left}, top=${top}`
                        );

                        targetWin.focus();
                        window.callBackEventGoogleSheet = (event) => {
                            let data = {};
                            data.token = event.data && event.data.code ? event.data.code : '';
                            if (data && !isEmpty(data.token.toString()) && typeof data.token === 'string') {
                                this.setState(
                                    {
                                        token: event.data.code,
                                    },
                                    () => {
                                        // this.submit();
                                        // let payloadSubmitData = {
                                        //     code: this.state.token,
                                        //     id: this.state.id
                                        // }
                                        // this.props.createGoogleSheet(payloadSubmitData)
                                    }
                                );
                            }
                        };
                    } else {
                        window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                    }
                    break;
                }

                case formAccountTypes.INTEGRATE_OAUTH2_GOOGLE_SHEETS:
                    if (nextProps.formAccountReducer.status) {
                        window.LadiUI.toastCustom('success', '', nextProps.formAccountReducer.message);
                        this.props.onSubmit();
                        this.props.onSuccess(`?tab=my`);
                        this.onCancel();
                    }
                    break;

                case formAccountTypes.INTEGRATE_OAUTH2_GET_LINK: {
                    if (nextProps.formAccountReducer.status) {
                        const width = 500;
                        const height = 650;
                        const left = window.screen.width / 2 - width / 2;
                        const top = window.screen.height / 2 - height / 2;
                        const targetWin = window.open(
                            nextProps.formAccountReducer.url,
                            'popupAuth',
                            `width=${width}, height=${height}, left=${left}, top=${top}`
                        );

                        targetWin.focus();
                        window.callBackEventInfusionSoft = (event) => {
                            let data = {};
                            data.token = event.data && event.data.code ? event.data.code : '';
                            if (data && !isEmpty(data.token.toString()) && typeof data.token === 'string') {
                                this.setState(
                                    {
                                        token: event.data.code,
                                    },
                                    () => {
                                        this.submit();
                                    }
                                );
                            }
                        };
                    } else {
                        window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                    }
                    break;
                }

                case formAccountTypes.INTEGRATE_OAUTH_ACCESS:
                    if (nextProps.formAccountReducer.status) {
                        let account = nextProps.formAccountReducer.account || {};
                        const data = {
                            name: this.state.name,
                            type: this.props.integrationInfo.type,
                            access_token: account.access_token ? account.access_token : '',
                            refresh_token: account.refresh_token ? account.refresh_token : '',
                        };
                        this.props.update(data);
                        // window.LadiUI.toastCustom("success", "", nextProps.formAccountReducer.message);
                        // this.props.onSubmit();
                        // this.props.onSuccess(`?tab=my`);
                        // this.onCancel();
                    }
                    break;

                default:
                    break;
            }
        }
    }

    eventListenerZalo = (event) => {
        const data = {};
        if (event.origin !== `${window.location.protocol}//${window.location.hostname}`) {
            return;
        }
        if (!isEmpty(event.data.payload)) {
            return;
        }
        data.token = event.data;
        if (data && !isEmpty(data.token) && !isEmpty(data.token.toString()) && typeof data.token === 'string') {
            this.setState({
                token: event.data,
            });

            this.props.getInfoZalo(data);
        }
    };

    // eventListenerHaravan = (event) => {
    //     let data = {};
    //     if (event.origin !== `${window.location.protocol}//${window.location.hostname}`) {
    //         return;
    //     }
    //     if (!isEmpty(event.data.payload)) {
    //         return;
    //     }

    //     data.token = event.data && event.data.code ? event.data.code : '';
    //     data.shopID = event.data && event.data.shop ? event.data.shop : '';
    //     if (data && !isEmpty(data.token.toString()) && !isEmpty(data.shopID.toString()) && typeof data.token === 'string') {
    //         this.setState({
    //             token: event.data.code,
    //             shopID: event.data.shop,
    //         }, () => {
    //             this.submit();
    //         });
    //     }
    // }

    eventListener = (event) => {
        if (event.origin !== `${window.location.protocol}//${window.location.hostname}`) {
            return;
        }
        if (!isEmpty(event.data.payload)) {
            return;
        }

        if (!isEmpty(event.data) && !isEmpty(event.data.toString()) && typeof event.data === 'string') {
            this.setState(
                {
                    code: event.data,
                },
                () => {
                    switch (this.state.selectedType) {
                        case appConfig.INTEGRATION.TYPE.SAPO:
                            this.sapoAccess();
                            break;
                        case appConfig.INTEGRATION.TYPE.SHOPIFY:
                            this.shopifyAccess();
                            break;
                        case appConfig.INTEGRATION.TYPE.GETRESPONSE:
                            this.getresponseAccess();
                            break;
                        case appConfig.INTEGRATION.TYPE.INFUSION_SOFT:
                            this.infusionsoftAccess();
                            break;
                        case appConfig.INTEGRATION.TYPE.HUBSPOT:
                            this.hubspotAccess();
                            break;
                        case appConfig.INTEGRATION.TYPE.GOOGLE_SHEET:
                            this.googleSheetsAccess();
                            break;
                        case appConfig.INTEGRATION.TYPE.NHANH:
                            this.nhanhAccess();
                            break;
                        default:
                            break;
                    }
                }
            );
        }
    };

    init = (currentInfo) => {
        if (isEmpty(currentInfo)) return;
        switch (currentInfo.type) {
            case appConfig.FORM_TYPE.MAIL_CHIMP.CODE: {
                this.setState({
                    apiKey: currentInfo.api_key ? currentInfo.api_key : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.HARAVAN.CODE:
            case appConfig.FORM_TYPE.SAPO.CODE:
            case appConfig.FORM_TYPE.SHOPIFY.CODE: {
                this.setState({
                    apiUrl: currentInfo.api_url ? currentInfo.api_url : '',
                    token: currentInfo.token ? currentInfo.token : '',
                    shopID: currentInfo.store_id ? currentInfo.store_id : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.WORDPRESS.CODE: {
                this.setState({
                    apiUrl: currentInfo.api_url ? currentInfo.api_url : '',
                    apiKey: currentInfo.token ? currentInfo.token : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.KIOTVIET.CODE: {
                this.setState({
                    apiKey: currentInfo.api_key ? currentInfo.api_key : '',
                    userName: currentInfo.user_name ? currentInfo.user_name : '',
                    clientID: currentInfo.client_id ? currentInfo.client_id : '',
                    shopID: currentInfo.store_id ? currentInfo.store_id : '',
                    secretKey: currentInfo.secret_key ? currentInfo.secret_key : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.HUBSPOT.CODE:
            case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
            case appConfig.FORM_TYPE.CONVERTKIT.CODE: {
                this.setState({
                    secretKey: currentInfo.secret_key ? currentInfo.secret_key : '',
                    apiKey: currentInfo.api_key ? currentInfo.api_key : '',
                });
                break;
            }
            case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE: {
                this.setState({
                    apiUrl: currentInfo.api_url ? currentInfo.api_url : '',
                    apiKey: currentInfo.token ? currentInfo.token : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE: {
                this.setState({
                    shopID: currentInfo.store_id ? currentInfo.store_id : '',
                    token: currentInfo.token ? currentInfo.token : '',
                    refreshToken: currentInfo.refresh_token ? currentInfo.refresh_token : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE: {
                this.setState({
                    shopID: currentInfo.email ? currentInfo.email : '',
                    token: currentInfo.access_token ? currentInfo.access_token : '',
                    refreshToken: currentInfo.refresh_token ? currentInfo.refresh_token : '',
                    folderID: currentInfo.folder_id ? currentInfo.folder_id : '',
                });
                break;
            }

            case appConfig.FORM_TYPE.NHANH.CODE: {
                this.setState({
                    shopID: currentInfo.store_id ? currentInfo.store_id : '',
                    token: currentInfo.token ? currentInfo.token : '',
                });
                break;
            }
            default:
                break;
        }

        this.setState({
            id: currentInfo.id,
            name: currentInfo.name,
            selectedType: currentInfo.type,
        });
    };

    onCancel = () => {
        this.props.onCancel();
    };

    copy = (data) => {
        const { t } = this.props;
        navigator.clipboard.writeText(data);
        window.LadiUI.toast('success', 'Thông báo', t('SETTINGS.COPY_SUCCESS'), 5000, 'bottom-left');
    };

    submit = () => {
        const { integrationInfo } = this.props;
        // event.preventDefault();

        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toast('danger', 'Error', errorMessage, '5000', 'bottom-left');
            return;
        }

        const {
            name,
            id,
            secretKey,
            apiKey,
            brandName,
            integration,
            selectedPage,
            token,
            oaInfo,
            apiUrl,
            shopID,
            clientID,
            refreshToken,
            folderID,
            userName,
        } = this.state;

        const data = {
            name,
            type: integrationInfo.type,
            secret_key: secretKey,
            api_key: apiKey,
            brand_name: brandName,
            api_url: apiUrl,
            user_name: userName,
        };
        if (this.props.mode === appConfig.FORM_MODE.EDIT) {
            data.id = id;
        }

        const IntegrationType = integrationInfo.type;

        if (IntegrationType === appConfig.FORM_TYPE.HARAVAN.CODE) {
            if (isEmpty(token) || isEmpty(shopID)) {
                window.LadiUI.toast('danger', 'Error', this.props.t('Kết nối tài khoản trước khi lưu'), '5000', 'bottom-left');
                return;
            }

            data.token = token;
            data.shop_id = shopID;

            if (this.props.mode === appConfig.FORM_MODE.EDIT) {
                this.props.update(data);
            } else {
                this.props.createHaravan(data);
            }
        }
        if (IntegrationType === appConfig.FORM_TYPE.SAPO.CODE) {
            if (isEmpty(token)) {
                window.LadiUI.toast('danger', 'Error', this.props.t('Kết nối tài khoản trước khi lưu'), '5000', 'bottom-left');
                return;
            }

            data.code = token;
            data.store_url = apiUrl;

            if (this.props.mode === appConfig.FORM_MODE.EDIT) {
                this.props.update(data);
            } else {
                this.props.createSapo(data);
            }
        }

        if (IntegrationType === appConfig.FORM_TYPE.SHOPIFY.CODE) {
            if (isEmpty(token)) {
                window.LadiUI.toast('danger', 'Error', this.props.t('Kết nối tài khoản trước khi lưu'), '5000', 'bottom-left');
                return;
            }

            data.token = token;
        }

        if (IntegrationType === appConfig.FORM_TYPE.CONVERTKIT.CODE || IntegrationType === appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE) {
            data.token = apiKey;
            this.props.update(data);
        }

        if (IntegrationType === appConfig.FORM_TYPE.MAIL_CHIMP.CODE) {
            this.props.update(data);
        }
        // if (IntegrationType === appConfig.FORM_TYPE.HUBSPOT.CODE) {
        //     data.token = token;
        //     data.refresh_token = refreshToken;
        //     if (isEmpty(token)) {
        //         window.LadiUI.toast('danger', 'Error', this.props.t('INTEGRATIONS.CONNECT_BEFORE_SAVE'), '5000', 'bottom-left');
        //         return;
        //     }
        // }

        if (
            IntegrationType === appConfig.FORM_TYPE.HUBSPOT.CODE ||
            IntegrationType === appConfig.FORM_TYPE.GET_RESPONSE.CODE ||
            IntegrationType === appConfig.FORM_TYPE.KIOTVIET.CODE
        ) {
            data.shop_id = shopID;
            data.client_id = clientID;
            data.api_key = apiKey;

            this.props.update(data);
        }

        if (IntegrationType === appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
            if (isEmpty(token)) {
                window.LadiUI.toast('danger', 'Error', this.props.t('Kết nối tài khoản trước khi lưu'), '5000', 'bottom-left');
                return;
            }

            data.code = token;

            if (this.props.mode === appConfig.FORM_MODE.EDIT) {
                this.props.update(data);
            } else {
                this.props.createGoogleSheet(data);
            }
        }

        if (IntegrationType === appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE) {
            if (isEmpty(token)) {
                window.LadiUI.toast('danger', 'Error', this.props.t('Kết nối tài khoản trước khi lưu'), '5000', 'bottom-left');
                return;
            }

            data.code = token;

            if (this.props.mode === appConfig.FORM_MODE.EDIT) {
                this.props.update(data);
            } else {
                this.props.createInfusionSoft(data);
            }
        }

        if (IntegrationType === appConfig.FORM_TYPE.NHANH.CODE) {
            if (isEmpty(token)) {
                window.LadiUI.toast('danger', 'Error', this.props.t('Kết nối tài khoản trước khi lưu'), '5000', 'bottom-left');
                return;
            }

            data.token = token;
            data.shop_id = shopID;

            if (this.props.mode === appConfig.FORM_MODE.EDIT) {
                this.props.update(data);
            } else {
                this.props.createNhanh(data);
            }
        }
    };

    onChangeIntegrationName = (event) => {
        this.setState({
            name: event.target.value,
        });
    };

    onChangeApiUrl = (event) => {
        this.setState({
            apiUrl: event.target.value,
        });
    };

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onChangeApiKey = (event) => {
        this.setState({
            apiKey: event.target.value,
        });
    };

    onChangeClientID = (event) => {
        this.setState({
            clientID: event.target.value,
        });
    };

    onChangeShopID = (event) => {
        this.setState({
            shopID: event.target.value,
        });
    };

    onChangeSecretKey = (event) => {
        this.setState({
            secretKey: event.target.value,
        });
    };

    onChangeUserNameNhanh = (event) => {
        this.setState({
            userName: event.target.value,
        });
    };

    async getPages(accessToken) {
        const req = {
            method: 'get',
            url: `https://graph.facebook.com/v10.0/me/accounts?limit=50&access_token=${accessToken}`,
        };

        await axios(req).then((response) => {
            this.handlePages(response);
        });
    }

    handlePages(response) {
        const data = response.data.data;
        const pages = map(data, (item) => ({ token: item.access_token, name: item.name, id: item.id }));

        if (data) {
            this.setState({
                pages,
                selectedPage: {},
                integration: {
                    ...this.state.integration,
                    pages,
                },
            });

            forEach(this.state.integration.pages, (page) => (page.selected = false));
            window.LadiUI.init();

            // save local storage
            appLocalStorage.setItem(appConfig.LOCAL_FORAGE.FAN_PAGE_LIST, JSON.stringify(pages));
        }
    }

    async getInfo(accessToken) {
        // //get name & id
        const req = {
            method: 'get',
            url: `https://graph.facebook.com/v10.0//me?access_token=${accessToken}`,
        };

        await axios(req).then((response) => {
            this.handleInfo(response);
        });
    }

    handleInfo(response) {
        const name = response.data.name;
        const id = response.data.id;

        if (name && id) {
            this.setState({
                fb_name: name,
                id,
                integration: {
                    ...this.state.integration,
                    fb_id: id,
                    fb_name: name,
                },
            });
        }
    }

    async getAvatar(accessToken) {
        const req = {
            method: 'get',
            url: `https://graph.facebook.com/v10.0/me/picture?redirect=false&height=200&width=200&access_token=${accessToken}`,
        };

        await axios(req).then((response) => {
            this.handleAvatar(response);
        });
    }

    handleAvatar(response) {
        const data = response.data.data;

        if (data) {
            this.setState({
                fb_avatar_url: data.url,
                integration: {
                    ...this.state.integration,
                    fb_avatar_url: data.url,
                },
            });
        }
    }

    connectPage = () => {
        // validate
        if (!this.state.selectedPage.id) {
            window.LadiUI.toast('danger', 'Error', this.props.t('FLOWS.MSG_PAGE_REQUIRED'), '5000', 'bottom-left');
            return;
        }

        this.props.connectPage({
            integration: this.state.integration,
            page_id: this.state.selectedPage.id,
            token: this.state.selectedPage.token,
        });
    };

    selectValue = (selected) => {
        this.setState({
            selectedPage: selected,
            integration: {
                ...this.state.integration,
                connected: true,
                pageID: selected.id,
                token: selected.token,
            },
        });

        const array = filter(this.state.integration.pages, (page) => page.selected);

        if (!isEmpty(array) && selected.id !== array[0].id) {
            this.setState({
                connected: false,
            });
        } else if (!isEmpty(array)) {
            this.setState({
                connected: true,
            });
        }
    };

    onPaste = (ev) => {
        ev.preventDefault();
        const text = ev.clipboardData.getData('text');
        document.execCommand('insertText', false, text);
    };

    handleEditorChange = (content, type) => {
        if (content.length > 300000) {
            window.LadiUI.toast('danger', 'Error', 'Nội dung Email không vượt quá 300.000 kí tự', '5000', 'bottom-left');
            return;
        }
        this.onSubmitModalEditor(content, type);
    };

    rendersFooter = () => {
        return (
            <Editor
                tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                licenseKey="gpl"
                value={''}
                init={{
                    entity_encoding: 'raw',
                    selector: 'textarea',
                    min_height: 200,
                    max_height: 800,
                    autoresize_overflow_padding: 5,
                    autoresize_bottom_margin: 24,
                    plugins:
                        'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                    toolbar:
                        'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                    toolbar_drawer: 'sliding',
                    language_url: '../../../language/tinymce-vi.js',
                    content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                    paste_as_text: true,
                    extended_valid_elements: 'a[data|href|class|style]',
                }}
                onEditorChange={(e) => this.handleEditorChange(e, 'ADDRESS')}
            />
        );
    };

    getLinkHaravan = () => {
        const { apiUrl } = this.state;
        const { t } = this.props;

        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate && ref.props.name !== 'integrationName') {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toast('danger', 'Error', errorMessage, '5000', 'bottom-left');
            return;
        }

        if (isEmpty(apiUrl)) {
            window.LadiUI.toast('danger', 'Error', t('INTEGRATIONS.API_URL_NULL'), '5000', 'bottom-left');
            return;
        }

        this.props.getLinkHaravan();
    };

    renderHaravan = () => {
        const { t, integrationInfo } = this.props;
        const { apiUrl, token, shopID } = this.state;

        return (
            <>
                <div className='w-80-p'>
                    <div className='ladiui form-group-custom flex align-items-start flex-column'>
                        <label className='ladiui text-secondary mb-4'>{t('API URL')}</label>
                        <div className='connect-fb w-full'>
                            <div className='flex gap-8'>
                                <Input
                                    className='ladiui  form-control'
                                    name='api_url'
                                    placeholder={t('Nhập API URL. vd:https//haravan.com')}
                                    validationName={t('API URL')}
                                    value={apiUrl}
                                    onChange={this.onChangeApiUrl}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />

                                <button
                                    type='button'
                                    className='ladiui btn btn-secondary'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.getLinkHaravan();
                                    }}
                                >
                                    <div className='flex justify-center align-items-center gap-8'>
                                        <img src={iconIntegration.CHECK_FILL} />
                                        <span className='text-secondary'>{t('Xác thực')}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    getLinkSapo = () => {
        const { apiUrl } = this.state;
        const { t } = this.props;

        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate && ref.props.name !== 'integrationName') {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toast('danger', 'Error', errorMessage, '5000', 'bottom-left');
            return;
        }

        if (isEmpty(apiUrl)) {
            window.LadiUI.toast('danger', 'Error', t('INTEGRATIONS.API_URL_NULL'), '5000', 'bottom-left');
            return;
        }
        const data = {
            store_url: apiUrl,
        };
        this.props.getLinkSapo(data);
    };

    sapoAccess = () => {
        const { apiUrl, code } = this.state;
        const data = {
            store_url: apiUrl,
            code,
        };
        this.props.sapoAccess(data);
    };

    renderSapo = () => {
        const { t } = this.props;
        const { apiUrl } = this.state;
        return (
            <>
                <div className='w-80-p'>
                    <div className='ladiui form-group-custom flex align-items-start flex-column'>
                        <label className='ladiui text-secondary mb-4'>{t('API URL')}</label>
                        <div className='connect-fb w-full'>
                            <div className='flex gap-8'>
                                <Input
                                    className='ladiui form-control'
                                    name='api_url'
                                    placeholder={t('Nhập API URL. vd:https//mysapo.com')}
                                    validationName={t('API URL')}
                                    value={apiUrl}
                                    onChange={this.onChangeApiUrl}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />
                                <button
                                    type='button'
                                    className='ladiui btn btn-secondary'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.getLinkSapo();
                                    }}
                                >
                                    <div className='flex justify-center align-items-center gap-8'>
                                        <img src={iconIntegration.CHECK_FILL} />
                                        <span className='text-secondary'>{t('Xác thực')}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    getLinkShopify = () => {
        const { apiUrl } = this.state;
        const { t } = this.props;

        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toast('danger', 'Error', errorMessage, '5000', 'bottom-left');
            return;
        }

        if (isEmpty(apiUrl)) {
            window.LadiUI.toast('danger', 'Error', t('INTEGRATIONS.API_URL_NULL'), '5000', 'bottom-left');
            return;
        }
        const data = {
            store_url: apiUrl,
        };
        this.props.getLinkShopify(data);
    };

    shopifyAccess = () => {
        const { apiUrl, code } = this.state;
        const data = {
            store_url: apiUrl,
            code,
            mode: this.props.mode,
        };
        this.props.shopifyAccess(data);
    };

    renderShopify = () => {
        const { t } = this.props;
        const { apiUrl, apiKeyStore } = this.state;

        const webhookUrl = `https://api.service.ladiflow.com/1.0/webhook/shopify/${apiKeyStore}`;
        return (
            <div className='w-80-p'>
                <div className='ladiui form-group-custom d-flex flex-column'>
                    <label className='ladiui text-secondary-v3 margin-bottom-4'>{t('INTEGRATIONS.API_URL')}</label>
                    <div className='connect-fb'>
                        <div style={{ display: 'flex' }}>
                            <Input
                                className='ladiui  form-control'
                                name='api_url'
                                placeholder={t('INTEGRATIONS.PLACEHOLDER_SHOPIFY')}
                                validationName={t('INTEGRATIONS.API_URL')}
                                value={apiUrl}
                                onChange={this.onChangeApiUrl}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: true }}
                            />
                        </div>
                    </div>
                </div>

                <div className='ladiui form-group-custom d-flex flex-column'>
                    <label className='ladiui text-secondary-v3 margin-bottom-4'>{t('INTEGRATIONS.WEBHOOK_URL')}</label>
                    <div className='row flex relative'>
                        <Input
                            disabled={true}
                            onChange={() => { }}
                            value={webhookUrl}
                            className='ladiui form-control'
                            style={{ marginRight: '12px' }}
                        />
                        <div className='w-80-p'>
                            <button className='ladiui btn' onClick={() => this.copy(webhookUrl)}>
                                {t('SETTINGS.COPY')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderWordpress = () => {
        const { t } = this.props;
        const { apiUrl, apiKey } = this.state;
        return (
            <div className='w-80-p'>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Api Url')}</label>
                    <div className='w-full' style={{ display: 'flex' }}>
                        <Input
                            className='ladiui  form-control'
                            name='api_url'
                            placeholder={t('Nhập Api Url')}
                            validationName={t('Api Url')}
                            value={apiUrl}
                            onChange={this.onChangeApiUrl}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        // disabled={token}
                        />
                    </div>
                </div>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Api Key')}</label>
                    <div className='password w-full relative'>
                        <Input
                            className='ladiui  form-control'
                            name='apiKey'
                            placeholder={t('Nhập Api Key')}
                            validationName={t('Api Key')}
                            value={apiKey}
                            onChange={this.onChangeApiKey}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            type={this.state.showApiKey ? 'text' : 'password'}
                        />
                        <i
                            className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showApiKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                }`}
                            onClick={() => {
                                this.setState({ showApiKey: !this.state.showApiKey });
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    };

    renderKiotViet = () => {
        const { t } = this.props;
        const { userName, clientID, secretKey } = this.state;
        return (
            <div className='w-80-p'>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Tên kết nối')}</label>
                    <div className='w-full' style={{ display: 'flex' }}>
                        <Input
                            className='ladiui  form-control'
                            name='api_url'
                            placeholder={t('Nhập tên kết nối')}
                            validationName={t('tên kết nối')}
                            value={userName}
                            onChange={this.onChangeUserNameNhanh}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        />
                    </div>
                </div>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Client ID')}</label>
                    <div className='w-full' style={{ display: 'flex' }}>
                        <Input
                            className='ladiui  form-control'
                            name='client_id'
                            placeholder={t('')}
                            validationName={t('Client ID')}
                            value={clientID}
                            onChange={this.onChangeClientID}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                        />
                    </div>
                </div>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Mã bảo mật')}</label>
                    <div className='password relative w-full'>
                        <Input
                            className='ladiui  form-control'
                            name='secretKey'
                            placeholder={t('Nhập mã bảo mật')}
                            validationName={t('mã bảo mật')}
                            value={secretKey}
                            onChange={this.onChangeSecretKey}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            type={this.state.showApiKey ? 'text' : 'password'}
                        />
                        <i
                            className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showApiKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                }`}
                            onClick={() => {
                                this.setState({ showApiKey: !this.state.showApiKey });
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    };

    getLinkGetresponse = () => {
        const { apiUrl } = this.state;
        const { t } = this.props;
        // if (isEmpty(apiUrl)) {
        //     window.LadiUI.toast('danger', 'Error', t('INTEGRATIONS.API_URL_NULL'), '5000', 'bottom-left');
        //     return;
        // }
        const data = {
            store_url: apiUrl,
        };
        this.props.getLinkGetresponse(data);
    };

    getresponseAccess = () => {
        const { apiUrl, code } = this.state;
        const data = {
            store_url: apiUrl,
            code,
            mode: this.props.mode,
        };
        this.props.getresponseAccess(data);
    };

    renderGetresponse = () => {
        const { t } = this.props;
        const { apiKey } = this.state;
        return (
            <div className='w-80-p'>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Api Key')}</label>
                    <div className='password relative w-full'>
                        <Input
                            className='ladiui  form-control'
                            name='apiKey'
                            placeholder={t('Nhập Api Key')}
                            validationName={t('Api Key')}
                            value={apiKey}
                            onChange={this.onChangeApiKey}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            type={this.state.showApiKey ? 'text' : 'password'}
                        />
                        <i
                            className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showApiKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                }`}
                            onClick={() => {
                                this.setState({ showApiKey: !this.state.showApiKey });
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    };

    // getLinkHubspot = () => {
    //     const { apiUrl } = this.state;
    //     const { t } = this.props;

    //     let errors = [];
    //     const inputsRef = compact(Array.from(this.inputsRef));
    //     map(inputsRef, (ref) => {
    //         if (ref.validate) {
    //             const error = ref.validate();
    //             if (error.length > 0) {
    //                 errors = errors.concat(error);
    //             }
    //         }
    //     });

    //     errors = compact(errors);
    //     if (errors.length > 0) {
    //         const errorMessage = errors.join('<br/>');
    //         window.LadiUI.toast('danger', 'Error', errorMessage, '5000', 'bottom-left');
    //         return;
    //     }

    //     this.props.getLinkHubspot();
    // }

    hubspotAccess = () => {
        const { code } = this.state;

        const data = {
            code,
            mode: this.props.mode,
        };
        this.props.hubspotAccess(data);
    };

    renderHubspot = () => {
        const { t } = this.props;
        const { apiKey } = this.state;
        return (
            <div className='w-80-p'>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Api Key')}</label>
                    <div className='password relative w-full'>
                        <Input
                            className='ladiui  form-control'
                            name='apiKey'
                            placeholder={t('Nhập Api Key')}
                            validationName={t('Api Key')}
                            value={apiKey}
                            onChange={this.onChangeApiKey}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            type={this.state.showApiKey ? 'text' : 'password'}
                        />
                        <i
                            className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showApiKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                }`}
                            onClick={() => {
                                this.setState({ showApiKey: !this.state.showApiKey });
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    };

    getLinkInfusionsoft = () => {
        const { apiUrl, selectedType } = this.state;

        const data = {
            store_url: apiUrl,
        };
        this.props.getLinkInfusionsoft(selectedType);
    };

    infusionsoftAccess = () => {
        const { apiUrl, code } = this.state;
        const data = {
            store_url: apiUrl,
            code,
            mode: this.props.mode,
        };
        this.props.infusionsoftAccess(data);
    };

    renderInfusionsoft = () => {
        const { t, integrationInfo } = this.props;
        const { token } = this.state;
        return (
            <div className='flex flex-column justify-start align-items-start flex-item-start w-full mt-16'>
                {!token ? (
                    <Fragment>
                        <button
                            type='button'
                            className='ladiui btn btn-secondary'
                            onClick={(e) => {
                                e.preventDefault();
                                this.getLinkInfusionsoft();
                            }}
                        >
                            <div className='flex flex-item-start justify-center align-items-center gap-8'>
                                <img className='ladiui size-13' src={integrationInfo.iconNegative} alt=''></img>
                                <span className='text-secondary'>{t('Kết nối với InfusionSoft')}</span>
                            </div>
                        </button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className='flex flex-item-start flex-column justify-start align-items-start gap-8'>
                            {token ? (
                                <div className='flex justify-center flex-item-start align-items-center gap-8'>
                                    <img src={iconIntegration.CHECK_FILL} />
                                    <span className='text-black font-500'>{t('Kết nối thành công')}</span>
                                </div>
                            ) : (
                                <div className='flex flex-item-start justify-center align-items-center gap-8'>
                                    <i className='ladiui icon new-ldicon-circledanger-fill'> </i>
                                    <span className='text-black font-500'>{t('Kết nối thất bại')}</span>
                                </div>
                            )}
                            <a
                                className='text-primary line-height-20'
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.getLinkInfusionsoft();
                                }}
                            >
                                {t('Đăng xuất và sử dụng tài khoản khác')}
                            </a>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    };

    getLinkGoogleSheets = () => {
        const { apiUrl } = this.state;

        const data = {
            store_url: apiUrl,
        };
        this.props.getLinkGoogleSheets(data);
    };

    googleSheetsAccess = () => {
        const { apiUrl, code } = this.state;
        if (!code) return;
        const data = {
            store_url: apiUrl,
            code,
            mode: this.props.mode,
        };
        this.props.googleSheetsAccess(data);
    };

    renderGoogleSheets = () => {
        const { t, integrationInfo } = this.props;
        const { shopID, token, authenticatedGoogle } = this.state;
        return (
            <div className='flex flex-column justify-start align-items-start w-full flex-item-start mt-16'>
                {!token ? (
                    <Fragment>
                        <button
                            type='button'
                            className='ladiui btn btn-secondary'
                            onClick={(e) => {
                                e.preventDefault();
                                this.getLinkGoogleSheets();
                            }}
                        >
                            <div className='flex flex-item-start justify-center align-items-center gap-8'>
                                <img className='ladiui size-13' src={integrationInfo.iconNegative} alt=''></img>
                                <span className='text-secondary'>
                                    {t('Kết nối với')} {t(integrationInfo.name)}
                                </span>
                            </div>
                        </button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className='flex flex-column flex-item-start justify-start align-items-start gap-8 w-full'>
                            {token ? (
                                <div className='flex flex-item-start justify-center align-items-center gap-8'>
                                    <img src={iconIntegration.CHECK_FILL} />
                                    <span className='text-black font-500'>{t('Kết nối thành công')}</span>
                                </div>
                            ) : (
                                <div className='flex flex-item-start justify-center align-items-center gap-8'>
                                    <i className='ladiui icon new-ldicon-circledanger-fill'> </i>
                                    <span className='text-black font-500'>{t('Kết nối thất bại')}</span>
                                </div>
                            )}
                            <a
                                className='text-primary line-height-20'
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.getLinkGoogleSheets();
                                }}
                            >
                                {t('Đăng xuất và sử dụng tài khoản khác')}
                            </a>

                            {/* <div className='flex flex-item-start flex-column w-80-p'>
                                    <label className="ladiui text-secondary mb-4">{t('Tài khoản email')}</label>
                                    <Input
                                        className="ladiui form-control"
                                        name="shop_id"
                                        placeholder={t('Tài khoản email')}
                                        validationName={t('tài khoản email')}
                                        value={shopID}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                        disabled={true}
                                    />
                                </div> */}
                        </div>
                    </Fragment>
                )}
            </div>
        );
    };

    renderConvertkit = () => {
        const { t } = this.props;
        const { secretKey, apiKey } = this.state;
        return (
            <div className='w-80-p'>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Api Key')}</label>
                    <div className='password w-full relative'>
                        <Input
                            className='ladiui form-control'
                            name='apiKey'
                            placeholder={t('Nhập Api Key')}
                            validationName={t('Api Key')}
                            value={apiKey}
                            onChange={this.onChangeApiKey}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            type={this.state.showApiKey ? 'text' : 'password'}
                        />
                        <i
                            className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showApiKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                }`}
                            onClick={() => {
                                this.setState({ showApiKey: !this.state.showApiKey });
                            }}
                        ></i>
                    </div>
                </div>
                <div className='ladiui form-group-custom flex flex-item-start flex-column'>
                    <label className='ladiui text-secondary mb-4'>{t('Secret Key')}</label>
                    <div className='password w-full relative'>
                        <Input
                            className='ladiui form-control'
                            name='secretKey'
                            placeholder={t('Nhập Secret Key')}
                            validationName={t('Secret Key')}
                            value={secretKey}
                            onChange={this.onChangeSecretKey}
                            ref={(ref) => this.inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            type={this.state.showSecretKey ? 'text' : 'password'}
                        />
                        <i
                            className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showSecretKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                }`}
                            onClick={() => {
                                this.setState({ showSecretKey: !this.state.showSecretKey });
                            }}
                        ></i>
                    </div>
                </div>
            </div>
        );
    };

    getLinkNhanh = () => {
        this.props.getLinkNhanh();
    };

    nhanhAccess = () => {
        const { code } = this.state;
        if (!code) return;
        const data = {
            code,
            mode: this.props.mode,
        };
        this.props.nhanhAccess(data);
    };

    renderNhanh = () => {
        const { t, integrationInfo } = this.props;
        const { shopID, token } = this.state;

        return (
            <div className='flex flex-column justify-start align-items-start w-full mt-16'>
                {!token ? (
                    <Fragment>
                        <button
                            type='button'
                            className='ladiui btn btn-secondary'
                            onClick={(e) => {
                                e.preventDefault();
                                this.getLinkNhanh();
                            }}
                        >
                            <div className='flex justify-center align-items-center gap-8'>
                                <img className='ladiui size-13' src={integrationInfo.iconNegative} alt=''></img>
                                <span className='text-secondary'>{t('Kết nối với nhanh')}</span>
                            </div>
                        </button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className='flex flex-column justify-start align-items-start gap-8'>
                            {token ? (
                                <div className='flex justify-center align-items-center gap-8'>
                                    <img src={appConfig.ICON.CHECK_FILL} />
                                    <span className='text-black font-500'>{t('Kết nối thành công')}</span>
                                </div>
                            ) : (
                                <div className='flex justify-center align-items-center gap-8'>
                                    <i className='ladiui icon new-ldicon-circledanger-fill'> </i>
                                    <span className='text-black font-500'>{t('Kết nối thất bại')}</span>
                                </div>
                            )}
                            <a
                                className='text-primary line-height-20'
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.getLinkNhanh();
                                }}
                            >
                                {t('Đăng xuất và sử dụng tài khoản khác')}
                            </a>
                            <div className='d-flex flex-column w-80-p'>
                                <label className='ladiui text-secondary-v3 margin-bottom-4'>{t('INTEGRATIONS.BUSINESS_ID_ACCOUNT')}</label>
                                <Input
                                    className='ladiui form-control'
                                    name='shop_id'
                                    placeholder={t('INTEGRATIONS.EMAIL_ACCOUNT')}
                                    validationName={t('INTEGRATIONS.EMAIL_ACCOUNT')}
                                    value={shopID}
                                    disabled={true}
                                    readOnly='readonly'
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        );
    };
    renderItem = (type) => {
        const { t, integrationInfo } = this.props;
        const { secretKey, apiKey, brandName, phoneTest, error, pages, selectedPage, token } = this.state;

        switch (type) {
            case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
                return (
                    <div className='flex flex-column justify-start align-items-start flex-item-start w-full'>
                        <div className='ladiui form-group-custom w-80-p flex flex-item-start flex-column'>
                            <label className='ladiui text-secondary mb-4'>{t('Api Key')}</label>
                            <div className='password w-full relative'>
                                <Input
                                    className='ladiui  form-control'
                                    name='apiKey'
                                    placeholder={t('Api Key')}
                                    validationName={t('Nhập Api Key')}
                                    value={apiKey}
                                    onChange={this.onChangeApiKey}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                    type={this.state.showApiKey ? 'text' : 'password'}
                                />
                                <i
                                    className={`ladiui icon icon-show absolute top-10 right-10 ${this.state.showApiKey ? 'icon-ldp-view' : 'icon-ldp-hide'
                                        }`}
                                    onClick={() => {
                                        this.setState({ showApiKey: !this.state.showApiKey });
                                    }}
                                ></i>
                            </div>
                        </div>
                    </div>
                );
            case appConfig.FORM_TYPE.HARAVAN.CODE:
                return this.renderHaravan();
            case appConfig.FORM_TYPE.SAPO.CODE:
                return this.renderSapo();
            case appConfig.FORM_TYPE.SHOPIFY.CODE:
                return this.renderShopify();
            case appConfig.FORM_TYPE.WORDPRESS.CODE:
                return this.renderWordpress();
            case appConfig.FORM_TYPE.KIOTVIET.CODE:
                return this.renderKiotViet();
            case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
                return this.renderGetresponse();
            case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
                return this.renderWordpress();
            case appConfig.FORM_TYPE.HUBSPOT.CODE:
                return this.renderHubspot();
            case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
                return this.renderInfusionsoft();
            case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
                return this.renderGoogleSheets();
            case appConfig.FORM_TYPE.CONVERTKIT.CODE:
                return this.renderConvertkit();
            case appConfig.FORM_TYPE.NHANH.CODE:
                return this.renderNhanh();
            default:
                break;
        }
    };

    renderButtonSave = () => {
        const { t, integrationInfo } = this.props;
        const selectedType = integrationInfo.type;

        switch (selectedType) {
            default:
                return (
                    <button
                        type='button'
                        className='ladiui btn btn-primary btn-tb custom-page-lists'
                        onClick={this.submit}
                        id='button-integration-create'
                    >
                        <div className='ladiui btn-custom-text'>{t('Lưu liên kết')}</div>
                    </button>
                );
        }
    };

    renderHeaderStep1 = (type) => {
        const { integrationInfo, t } = this.props;
        switch (type) {
            case appConfig.FORM_TYPE.NHANH.CODE:
            case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
            case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
                return (
                    <>
                        <div className='text-secondary font-500 text-16'>{t('Kết nối với tài khoản')}</div>
                        <div className='text-secondary'>{t('Nhấn vào nút dưới đây để kết nối')}</div>
                    </>
                );
            default:
                return (
                    <>
                        <div className='text-secondary font-500 text-16'>{t('Thiết lập tài khoản')}</div>
                        <div className='text-secondary'>
                            <span>Nhập các thông tin cần thiết để kết nối với tài khoản {integrationInfo.name}</span>
                        </div>
                    </>
                );
        }
    };

    render() {
        const { t, integrationInfo, visible } = this.props;
        let { isShowModalEditor, mode, name } = this.state;

        const title = t('Thông tin tích hợp');

        // const isIntegrationReducerLoading = this.props.integrationReducer.loading
        //     && (includes([
        //         types.CREATE_INTEGRATION,
        //         types.UPDATE_INTEGRATION,
        //         types.SEND_EMAIL,
        //         types.SHOW_INTEGRATION,
        //         types.GET_TOKEN_FB,
        //         types.CONNECT_PAGE,
        //         types.REFRESH_TOKEN,
        //         types.LIST_ADVERTISER_TIKTOK,
        //         types.GET_TOKEN_FB,
        //     ], this.props.integrationReducer.waitting));

        const isLoadingAccoungReducer =
            this.props.formAccountReducer.loading &&
            includes(
                [
                    formAccountTypes.INTEGRATE_CREATE_HARAVAN,
                    formAccountTypes.INTEGRATE_CREATE_NHANH,
                    formAccountTypes.INTEGRATE_CREATE_SAPO,
                    formAccountTypes.INTEGRATE_CREATE_SHOPIFY,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_DELETES,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_IN_FORM_CONFIG_LIST,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_LIST,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_UPDATE,
                    formAccountTypes.INTEGRATE_GOOGLE_SHEETS,
                    formAccountTypes.INTEGRATE_HARAVAN,
                    formAccountTypes.INTEGRATE_NHANH,
                    formAccountTypes.INTEGRATE_OAUTH2_GET_LINK,
                    formAccountTypes.INTEGRATE_OAUTH2_GOOGLE_SHEETS,
                    formAccountTypes.INTEGRATE_OAUTH_ACCESS,
                    formAccountTypes.INTEGRATE_SAPO,
                    formAccountTypes.INTEGRATE_SHOPIFY,
                    formAccountTypes.LIST_ALL_CONNECT,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_DELETES,
                ],
                this.props.formAccountReducer.waitting
            );

        const isLoading = isLoadingAccoungReducer;
        return (
            <>
                {isLoading ? <LoadingScene blur={true} /> : null}
                <Modal
                    visible={visible}
                    id='modal-integration-info'
                    zIndex={99}
                    title={title}
                    titleIcon={<i className='ladiui new-ldicon-app size-24'></i>}
                    onCancel={this.onCancel}
                    onOk={this.submit}
                    width={812}
                    hasFooter={false}
                >
                    <div className='flex flex-column justify-start align-items-start mb-24'>
                        <div className='flex flex-row justify-between items-center w-full'>
                            <div className={'select-box-item p-0 pointer-events-none'}>
                                <div className='select-box-item-icon'>
                                    <img className='ladiui' src={integrationInfo.icon} alt=''></img>
                                </div>
                                <div className='select-box-item-info'>
                                    <div className='flex justify-between items-center w-full'>
                                        <div className='text-secondary font-500 text-18' style={{ '--line': 1 }}>
                                            {t(integrationInfo.name)}
                                        </div>
                                    </div>
                                    <div className='text-left text-secondary text-14 ellipsis-text' style={{ '--line': 2 }}>
                                        {t(integrationInfo.description)}
                                    </div>
                                </div>
                            </div>

                            <div className='ladiui'>
                                {mode === appConfig.FORM_MODE.EDIT ? (
                                    <>{this.renderButtonSave()}</>
                                ) : (
                                    <button
                                        type='button'
                                        className='ladiui btn btn-primary btn-tb custom-page-lists'
                                        onClick={() => {
                                            this.setState({ mode: appConfig.FORM_MODE.EDIT });
                                        }}
                                        id='button-integration-create'
                                    >
                                        <div className='ladiui btn-custom-text'>{t('Thêm liên kết')}</div>
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className='hr-01 mt-24 mb-24'></div>

                        {mode !== appConfig.FORM_MODE.EDIT && (
                            <>
                                <div className='text-secondary text-16 font-500'>{t('Tổng quan')}</div>
                                <div className='text-14 text-secondary mt-24 line-height-24' style={{ maxWidth: 'calc(100% - 120px)' }}>
                                    {integrationInfo.content ? integrationInfo.content : ''}
                                </div>
                            </>
                        )}

                        {mode === appConfig.FORM_MODE.EDIT && (
                            <>
                                <div className='flex flex-column justify-start align-items-start w-full gap-5'>
                                    <div className='text-secondary-v3'>{t('Bước')} 1</div>
                                    {this.renderHeaderStep1(integrationInfo.type)}
                                </div>
                                {this.renderItem(integrationInfo.type)}

                                <div className='hr-border-02 mt-24 mb-24'></div>
                                {integrationInfo.type != appConfig.FORM_TYPE.GOOGLE_SHEET.CODE && (
                                    <>
                                        <div className='flex flex-column justify-start align-items-start w-full gap-5'>
                                            <div className='text-secondary'>
                                                {'Bước 2'}
                                                {/* {authenticatedFB ? '3' : '2'} */}
                                            </div>

                                            <div className='text-secondary font-500 text-16'>{t('Tên tích hợp')}</div>

                                            <div className='text-secondary'>{t('Đặt tên cho tích hợp để dễ dàng quản lý')}</div>
                                        </div>
                                        <div className='w-full'>
                                            <div className='ladiui form-group-custom w-80-p flex align-items-start flex-column'>
                                                <label className='ladiui text-secondary mb-4'>{t('Tên tích hợp')}</label>
                                                <Input
                                                    className='ladiui  form-control'
                                                    name='integrationName'
                                                    placeholder={t('tên tích hợp')}
                                                    validationName={t('Tên')}
                                                    value={name}
                                                    onChange={this.onChangeIntegrationName}
                                                    ref={(ref) => this.inputsRef.add(ref)}
                                                    validations={{ isRequired: true }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Modal>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    update: (data) => dispatch(formAccountActions.update(data)),
    getLinkHaravan: (data) => dispatch(formAccountActions.haravan(data)),
    getLinkSapo: (data) => dispatch(formAccountActions.sapo(data)),
    createHaravan: (data) => dispatch(formAccountActions.createHaravan(data)),
    createSapo: (data) => dispatch(formAccountActions.createSapo(data)),
    getLinkNhanh: () => dispatch(formAccountActions.nhanh()),
    createNhanh: (data) => dispatch(formAccountActions.createNhanh(data)),
    getLinkGoogleSheets: (data) => dispatch(formAccountActions.googleSheets(data)),
    createGoogleSheet: (data) => dispatch(formAccountActions.oauth2GoogleSheets(data)),
    getLinkInfusionsoft: (data) => dispatch(formAccountActions.oauth2GetLink(data)),
    createInfusionSoft: (data) => dispatch(formAccountActions.oauthAccess(data)),
});

const mapStateToProps = (state) => ({
    integrationReducer: { ...state.integration },
    formAccountReducer: { ...state.formAccount },
    apiReducer: { ...state.api },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalIntegrationInfo));
