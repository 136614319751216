import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import TagSelect from '../../../../components/TagSelect';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as filterTypes from '../../../../redux/futures/filter/types';
import filterActions from '../../../../redux/futures/filter/actions';
import BaseHelper from '../../../../helpers/BaseHelper';

function ModalColumeCondition(props) {
    const dispatch = useDispatch();
    const wrapperRef = useRef();
    const conditionRef = useRef();
    const { t } = useTranslation();

    const { visible, hideModal, filters = [], filterSelected } = props;
    const [loading, setLoading] = useState(false);
    const [typeSave, setTypeSave] = useState(appConfig.CONDITION_SAVE_TYPE.NEW.value);
    const [searchValue, setSearchValue] = useState('');
    const [columnSelected, setColumnSelected] = useState([]);
    const [allColumn, setColumnAll] = useState(appConfig.ORDER_COLUMN);

    useEffect(() => {
        if (filterSelected && filterSelected.config) {
            let column = filterSelected.config.columns;
            let _allColumn = [...allColumn];

            if (BaseHelper.isEmpty(column)) {
                column = appConfig.ORDER_COLUMN.filter((item) => item.isSelected);
            }
            _allColumn = allColumn.map((item) => {
                let _column = column.find((_item) => _item.value == item.value);
                if (_column) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
                return item;
            });
            setColumnAll(_allColumn);
            setColumnSelected(column);
        }
    }, [filterSelected]);
    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        let dataCreate = { ...filterSelected };
        dataCreate.config.columns = columnSelected;

        dispatch(filterActions.update(dataCreate));
    };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);
        list = list.map((item, index) => {
            item.position = index;
            return item;
        });

        return list;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const _columnSelected = reorder(columnSelected, result.source.index, result.destination.index);
        setColumnSelected(_columnSelected);
    };

    return (
        <Modal
            width={800}
            id='modal-colume-condition'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('CONDITIONS_FILTER.EDIT_COLUMN')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('CONDITIONS_FILTER.SAVE')}
            isLoading={loading}
            position_unset={true}
        >
            <div className='content-column-condition'>
                <div className='column-selected'>
                    <div className='title'>{t('CONDITIONS_FILTER.COLUMN_SELECTED')}</div>
                    <div className='sub-title'>{t('CONDITIONS_FILTER.COLUMN_SELECTED_SUB')}</div>
                    <div className='search mt-20'>
                        <i className='new-ldicon-search ' />
                        <input
                            className='search-column'
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={t('CONDITIONS_FILTER.SEARCH_COLUMN')}
                        />
                    </div>
                    <ul className='columns'>
                        {allColumn.map((item, index) => {
                            if (searchValue) {
                                let searchValueTg = BaseHelper.getAliasName(searchValue);
                                let nameTg = BaseHelper.getAliasName(item.name);
                                if (!nameTg.includes(searchValueTg)) return null;
                            }
                            return (
                                <li
                                    key={index}
                                    onClick={() => {
                                        let _allColumn = [...allColumn];
                                        let _columnSelected = [...columnSelected];
                                        _allColumn[index].isSelected = !_allColumn[index].isSelected;

                                        if (!_allColumn[index].isSelected) {
                                            let indexCol = _columnSelected.findIndex((_item) => _item.value == item.value);
                                            if (indexCol > -1) {
                                                _columnSelected.splice(indexCol, 1);
                                            }
                                        } else {
                                            item.position = _columnSelected.length;
                                            _columnSelected.push(item);
                                        }
                                        setColumnAll(_allColumn);
                                        setColumnSelected(_columnSelected);
                                    }}
                                >
                                    <input
                                        className='ladiui checkbox form-check-input-checkbox'
                                        type='checkbox'
                                        onChange={() => {}}
                                        checked={item.isSelected ? true : false}
                                    />
                                    <span>{item.name}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className='column-sort'>
                    <div className='title'>{t('CONDITIONS_FILTER.COLUMN_SORT')}</div>
                    <div className='sub-title'>{t('CONDITIONS_FILTER.COLUMN_SORT_SUB')}</div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                                <div
                                    className='column-list-sort'
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {columnSelected.map((item, index) => (
                                        <Draggable key={index} draggableId={`${item.position}`} index={item.position}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className='column-item-sort'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className='column-title'>
                                                        <i className='icon-move' /> {item.name}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </Modal>
    );
}

export default ModalColumeCondition;
