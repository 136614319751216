/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { Editor } from '@tinymce/tinymce-react';
import SelectOption from '../../../components/SelectOption';
import appConfig from '../../../config/app';
import settingActions from '../../../redux/futures/setting/actions';
import * as settingTypes from '../../../redux/futures/setting/types';

import { find, map } from 'lodash';

class ModalSettingTemplateEmail extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        isLoading: PropTypes.bool,
        isLoadingRestore: PropTypes.bool,
        templates: PropTypes.array,
    };

    constructor(props) {
        super(props);

        const templateOrder =
            find(props.templates, (item) => item.type == appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_PRODUCT_PHYSICAL_SUCCESS.CODE) || {};
        this.state = {
            selectedTemplateEmail: appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_PRODUCT_PHYSICAL_SUCCESS.CODE,
            content: templateOrder.content,
            isShowModalGlossary: false,
        };
    }

    componentDidMount() {
        this.props.listTemplateEmail();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.templates != nextProps.templates) {
            const templateOrder = find(nextProps.templates, (item) => item.type == this.state.selectedTemplateEmail);
            this.setState({
                content: templateOrder.content,
            });
        }
    }

    onSelectItem = (item) => {
        const templateOrder = find(this.props.templates, (_item) => _item.type == item.CODE) || {};
        this.setState({
            selectedTemplateEmail: item.CODE,
            content: templateOrder.content,
        });
    };

    handleEditorChange = (value, editor) => {
        this.setState({
            content: value,
        });
    };

    restore = () => {
        const { selectedTemplateEmail } = this.state;
        const templateOrder = find(this.props.templates, (_item) => _item.type == selectedTemplateEmail);

        this.props.restore(templateOrder.store_template_id);
    };

    submit = () => {
        const { selectedTemplateEmail, content } = this.state;
        const templateOrder = find(this.props.templates, (_item) => _item.type == selectedTemplateEmail);

        this.props.onSubmit({
            store_template_id: templateOrder.store_template_id,
            content,
        });
    };

    openModalGlossary = () => {
        this.setState({
            isShowModalGlossary: true,
        });
    };

    hideModalGlossary = () => {
        this.setState({
            isShowModalGlossary: false,
        });
    };

    getDefaultDataGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_address}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_EMAIL')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{created_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order_prefix}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.ORDER_PREFIX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{reference_no}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.REFERENCE_NO')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.CUSTOMER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NOTE')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.price}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_TOTAL')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PAYMENT')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{discount_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    getModalGlossary = () => {
        const { t } = this.props;

        const { selectedTemplateEmail } = this.state;

        let data;
        switch (selectedTemplateEmail) {
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_PRODUCT_PHYSICAL_SUCCESS.CODE:
                data = this.successOrderProductGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_PRODUCT_SERVICE_SUCCESS.CODE:
                data = this.successOrderServiceGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_PRODUCT_EVENT_SUCCESS.CODE:
                data = this.successOrderEventGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_CREATED.CODE:
                data = this.getCreateOrderGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_COMPLETE_NOTIFICATION.CODE:
                data = this.completedOrderGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_SHIPPING.CODE:
                data = this.orderShippingGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_PAYMENT_SUCCESS.CODE:
                data = this.paymentSuccessGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_NOTIFICATION_TO_SHOP.CODE:
                data = this.notifiToShopGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_ORDER_CANCEL.CODE:
                data = this.notiCancelOrderGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_INFO_TICKET_EVENT.CODE:
                data = this.EventTicketInfoGlossary();
                break;
            case appConfig.TEMPLATE_EMAIL.EMAIL_INFO_TICKET_SERVICE.CODE:
                data = this.ServiceTicketInfoGlossary();
                break;
            default:
                data = this.getDefaultDataGlossary();
                break;
        }
        return (
            <Modal
                id='modal-setting-template-email'
                title={t('COMMON.GLOSSARY')}
                visible={this.state.isShowModalGlossary}
                onCancel={this.hideModalGlossary}
                width={650}
                bodyStyles={{ minHeight: '40vh' }}
                hasFooter={false}
            >
                {data}
            </Modal>
        );
    };

    getTemplateEmailName = (value) => {
        const matchedDiscount = find(appConfig.TEMPLATE_EMAIL, (item) => item.CODE == value);
        return matchedDiscount.NAME;
    };

    getCreateOrderGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    completedOrderGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    orderShippingGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>

                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.tracking_number}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TRACKING_NUMBER')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    paymentSuccessGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    notifiToShopGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    notiCancelOrderGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reason_cancel}}'}</td>
                        <td>{t('GLOSSARY.ORDER.REASON_CANCEL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    successOrderProductGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note_topping}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ORDER_NOTE_TOPPING')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    successOrderServiceGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note_topping}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ORDER_NOTE_TOPPING')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    successOrderEventGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note_topping}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ORDER_NOTE_TOPPING')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    EventTicketInfoGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.EVENT_TICKET_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{event.name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.EVENT_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.TICKET.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.code}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.option_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.OPTION_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CUSTOMER_FULL_NAME')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    ServiceTicketInfoGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.SERVICE_TICKET_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{service.name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.SERVICE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.TICKET.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.code}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.option_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.OPTION_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CUSTOMER_FULL_NAME')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    render() {
        const { t } = this.props;

        return (
            <div
                className='page-setting'
                id='page-setting-template-email'
                title={t('SETTINGS.TEMPLATE_EMAIL')}
            // visible={this.props.visible}
            // onCancel={this.props.onCancel}
            // width={1000}
            // bodyStyles={{ minHeight: '40vh' }}
            // isLoading={this.props.isLoading}
            // footer={
            //     <div className="ladiui-modal-footer">
            //         <button onClick={this.restore} className={`ladiui button primary icon icon-refresh ${this.props.isLoadingRestore ? 'loader' : ''}`} disabled={this.props.isLoadingRestore}> {t('ACTIONS.RESTORE_TEMPLATE_PRINT')}</button>
            //         <button onClick={this.submit} className={`ladiui button primary icon icon-floppy-disk ${this.props.isLoading ? 'loader' : ''}`} disabled={this.props.isLoading}> {t('ACTIONS.SAVE')}</button>
            //     </div>
            // }
            >
                <div className='block-title-action'>
                    <h3 className='title'>{t('SETTINGS.TEMPLATE_EMAIL')}</h3>
                    <div className='btn-action'>
                        <button type='button' onClick={this.restore} className='back-default ladiui btn btn-outline-secondary btn-sm'>
                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-restore.svg' alt='' className='mr-8' />
                            {t('SETTINGS.BACK_TO_DEFAULT')}
                        </button>
                        <button type='button' onClick={this.submit} className='ladiui btn btn-primary ml-16'>
                            {t('SETTINGS.SAVE_CHANGE')}
                        </button>
                    </div>
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('COMMON.SELECT_TEMPLATE_EMAIL')}</label>
                    <div className='ladiui dropdown'>
                        <button
                            className='ladiui btn btn-outline-light dropdown-toggle'
                            data-toggle='dropdown'
                            style={{ width: '100%', paddingRight: '30px' }}
                        >
                            {this.getTemplateEmailName(this.state.selectedTemplateEmail)}
                        </button>
                        <ul className='ladiui dropdown-menu custom-form-dynamic' style={{ width: '100%' }}>
                            {map(appConfig.TEMPLATE_EMAIL, (item, index) => (
                                <li key={index} onClick={() => this.onSelectItem(item)}>
                                    <a className='ladiui dropdown-item' href='#'>
                                        {item.NAME}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='content-editor max-width-content' style={{ marginTop: '24px' }}>
                    <div className='title-header'>
                        <h4>{t('SETTINGS.CONTENT')}</h4>
                        <span>
                            <a className='ladiui-link pull-right' onClick={this.openModalGlossary}>
                                {t('COMMON.GLOSSARY')}
                            </a>
                        </span>
                    </div>
                    <Editor
                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                        licenseKey="gpl"
                        value={this.state.content}
                        init={{
                            selector: 'textarea',
                            min_height: 200,
                            max_height: 800,
                            autoresize_overflow_padding: 5,
                            autoresize_bottom_margin: 24,
                            plugins:
                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                            toolbar:
                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                            toolbar_drawer: 'sliding',
                            // language_url: '../../../language/tinymce-vi.js',
                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',

                            // valid_elements: "*[*]",
                        }}
                        onEditorChange={this.handleEditorChange}
                    />
                </div>

                {this.getModalGlossary()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listTemplateEmail: () => dispatch(settingActions.listTemplateEmail()),
    };
};

const mapStateToProps = (state) => ({
    settingReducer: { ...state.setting },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalSettingTemplateEmail)
);
