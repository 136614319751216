import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _, { map, compact, filter, includes, find } from 'lodash';
import Modal from '../../../components/Modal';
import appConfig from '../../../config/app';

import formAccountActions from '../../../redux/futures/form_account/actions';
import * as formAccountTypes from '../../../redux/futures/form_account/types';

import * as formConfigTypes from '../../../redux/futures/form_config/types';

import baseHelper from '../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { produce } from 'immer';
import SelectOption from '../../../components/SelectOption';
import LoadingScene from '../../../components/LoadingScene';
import Dropdown from '../../../components/Dropdown';

ModalFormAccountConfig.propTypes = {
    accountConfig: PropTypes.object,
    onSubmit: PropTypes.func,
};

const GOOGLE_SHEET_MODE = {
    CREATE: 0,
    MANUALLY: 1,
};

export default function ModalFormAccountConfig(props) {
    const formAccountReducer = useSelector((state) => state.formAccount);
    const formConfigReducer = useSelector((state) => state.formConfigReducer);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const inputsRef = new Set();
    const inputsGoogleSheetRef = new Set();

    const { isModalEdit } = props;

    const [accountConfig, setAccountConfig] = useState(props.accountConfig);
    const [sheetName, setSheetName] = useState('');
    const [googleSheetMode, setGoogleSheetMode] = useState(GOOGLE_SHEET_MODE.CREATE);
    const [sheetID, setSheetID] = useState('');
    const [isShowModalGoogleSheet, setIsShowModalGoogleSheet] = useState(false);

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    });

    useEffect(() => {
        loadData();

        if (
            [
                appConfig.FORM_TYPE.CONVERTKIT.CODE,
                appConfig.FORM_TYPE.HUBSPOT.CODE,
                ,
                appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE,
                appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE,
            ].includes(accountConfig.type)
        ) {
            loadCustomFields();
        }

        return function cleanup() {
            dispatch(formAccountActions.resetData());
        };
    }, []);

    useEffect(() => {
        loadWorksheets();
    }, [accountConfig.sheet_id]);

    useEffect(() => {
        if ([appConfig.FORM_TYPE.GET_RESPONSE.CODE, appConfig.FORM_TYPE.MAIL_CHIMP.CODE].includes(accountConfig.type)) {
            loadCustomFields({ campaign_id: accountConfig.campaign_id });
        }
    }, [accountConfig.campaign_id]);

    useEffect(() => {
        if (formAccountReducer.action == formAccountTypes.INTEGRATE_FORM_ACCOUNT_LOAD_DATA) {
            if (formAccountReducer.status) {
                if (accountConfig.type == appConfig.FORM_TYPE.MAIL_CHIMP.CODE) {
                    if (!accountConfig.campaign_id) {
                        const campaign = formAccountReducer.form_account_data.campaigns
                            ? formAccountReducer.form_account_data.campaigns[0]
                            : {};

                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.campaign_id = campaign.id;
                            })
                        );
                    }
                }

                if (accountConfig.type == appConfig.FORM_TYPE.GET_RESPONSE.CODE) {
                    if (!accountConfig.campaign_id) {
                        const campaign = formAccountReducer.form_account_data.campaigns
                            ? formAccountReducer.form_account_data.campaigns[0]
                            : {};
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.campaign_id = campaign.id;
                            })
                        );
                    }
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', formAccountReducer.message, 'OK');
            }
        }

        if (formAccountReducer.action == formAccountTypes.INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET) {
            if (formAccountReducer.status) {
                if (!accountConfig.sheet_id) {
                    const sheet = formAccountReducer.sheets && formAccountReducer.sheets[0] ? formAccountReducer.sheets[0] : {};
                    setAccountConfig(
                        produce(accountConfig, (draft) => {
                            draft.sheet_id = sheet.id;
                            draft.sheet_name = sheet.name;
                        })
                    );
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', formAccountReducer.message, 'OK');
            }
        }

        if (
            [
                formAccountTypes.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET,
                formAccountTypes.INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID,
            ].includes(formAccountReducer.action)
        ) {
            if (formAccountReducer.status) {
                setAccountConfig(
                    produce(accountConfig, (draft) => {
                        draft.sheet_id = formAccountReducer.sheet.id;
                        draft.sheet_name = formAccountReducer.sheet.name;
                    })
                );
                setIsShowModalGoogleSheet(false);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', formAccountReducer.message, 'OK');
            }
        }
    }, [formAccountReducer]);

    const loadData = (keyword) => {
        if (accountConfig.type == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
            dispatch(
                formAccountActions.loadSheets({
                    id: accountConfig.form_account_id,
                    type: accountConfig.type,
                })
            );
        } else {
            dispatch(
                formAccountActions.loadData({
                    id: accountConfig.form_account_id,
                    type: accountConfig.type,
                    limit: appConfig.LIMIT_LOAD_FORM_ACCOUNT,
                    keyword,
                })
            );
        }
    };

    const loadCustomFields = (data = {}) => {
        dispatch(
            formAccountActions.loadCustomFields({
                ...data,
                id: accountConfig.form_account_id,
                type: accountConfig.type,
            })
        );
    };

    const loadWorksheets = () => {
        if (accountConfig.sheet_id) {
            dispatch(
                formAccountActions.loadWorksheets({
                    id: accountConfig.form_account_id,
                    sheet_id: accountConfig.sheet_id,
                    type: accountConfig.type,
                })
            );
        }
    };

    const submit = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const result = produce(accountConfig, (draft) => {
            const customFields = [];

            draft.custom_fields.forEach((item) => {
                if (!baseHelper.isEmpty(item.ladi_name) && !baseHelper.isEmpty(item.name) && !baseHelper.isEmpty(item.value)) {
                    customFields.push(item);
                }
            });

            draft.custom_fields = customFields;
        });

        if (accountConfig.type != appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
            if (baseHelper.isEmpty(result.custom_fields)) {
                window.LadiUI.toastCustom('danger', '', t('FORM_CONFIG.REQUIRED_CUSTOM_FIELDS'));
                return;
            }
        }

        if (validateByFormAccountType(result)) {
            props.onSubmit(result);
        }
    };

    const submitSheet = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsGoogleSheetRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        if (googleSheetMode == GOOGLE_SHEET_MODE.CREATE) {
            dispatch(
                formAccountActions.createSheet({
                    id: accountConfig.form_account_id,
                    name: sheetName,
                })
            );
        } else {
            dispatch(
                formAccountActions.selectManuallySheetID({
                    id: accountConfig.form_account_id,
                    spreadsheet_id: sheetID,
                })
            );
        }
    };

    const openModalCreateSheet = () => {
        setGoogleSheetMode(GOOGLE_SHEET_MODE.CREATE);
        setSheetName('');
        setIsShowModalGoogleSheet(true);
    };

    const openModalManuallySheetID = () => {
        setGoogleSheetMode(GOOGLE_SHEET_MODE.MANUALLY);
        setSheetID('');
        setIsShowModalGoogleSheet(true);
    };

    const validateByFormAccountType = (formConfigAccount) => {
        switch (formConfigAccount.type) {
            case appConfig.FORM_TYPE.CONVERTKIT.CODE:
                if (baseHelper.isEmpty(formConfigAccount.courses)) {
                    window.LadiUI.toastCustom('danger', '', t('FORM_CONFIG.CONVERT_KIT_REQUIRED_SEQUENCE'));
                    return false;
                }
        }

        return true;
    };

    const getPanelMappingFields = () => {
        let key = 'key';
        let value = 'label';

        switch (accountConfig.type) {
            case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
                key = 'customFieldId';
                value = 'name';
                break;
            case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
                key = 'tag';
                value = 'name';
                break;
            case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
                key = 'id';
                value = 'title';
                break;
            case appConfig.FORM_TYPE.HUBSPOT.CODE:
            case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
                key = 'name';
                value = 'label';
                break;
        }
        return (
            <div className='mapping-fields'>
                <table className='ladiui table text-left' style={{ minWidth: 'auto' }}>
                    <tbody>
                        {map(accountConfig.custom_fields, (item, index) => (
                            <tr key={index} className='ladiui table-vertical'>
                                <td>
                                    <Input
                                        value={item.ladi_name}
                                        onChange={(event) => {
                                            setAccountConfig(
                                                produce(accountConfig, (draft) => {
                                                    draft.custom_fields[index].ladi_name = event.target.value;
                                                })
                                            );
                                        }}
                                        placeholder={t('FORM_CONFIG.LADI_NAME_PLACE_HOLDER')}
                                    />
                                </td>
                                <td style={{ textAlign: 'center', width: 20, padding: '0 16px' }}>
                                    <i className='ladi-icon icon-sync'></i>
                                </td>
                                <td>
                                    <SelectOption
                                        wrapperStyle={{ zIndex: accountConfig.custom_fields.length - index }}
                                        items={formAccountReducer.custom_fields || []}
                                        _key={key}
                                        _value={value}
                                        pickTitle={t('FORM_CONFIG.FORM_ACCOUNT_CONFIG_SELECT')}
                                        onSelectItem={(item) => {
                                            setAccountConfig(
                                                produce(accountConfig, (draft) => {
                                                    draft.custom_fields[index].name = item[value];
                                                    draft.custom_fields[index].value = item[key];
                                                })
                                            );
                                        }}
                                        currentKey={item.value}
                                        customStyle={{ width: '100%' }}
                                        innerClassName='w100'
                                    />
                                </td>
                                <td style={{ textAlign: 'right', width: 20, paddingLeft: 16 }}>
                                    <a
                                        onClick={() => {
                                            setAccountConfig(
                                                produce(accountConfig, (draft) => {
                                                    draft.custom_fields.splice(index, 1);
                                                })
                                            );
                                        }}
                                        style={{ color: '#6d6d6d' }}
                                    >
                                        <i className='ladi-icon icon-bin' />
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='btn-add-custom-fields'>
                    <button
                        onClick={() => {
                            setAccountConfig(
                                produce(accountConfig, (draft) => {
                                    draft.custom_fields.push({
                                        ladi_name: '',
                                        name: '',
                                        value: '',
                                    });
                                })
                            );
                        }}
                        className='ladiui btn btn-secondary'
                    >
                        {t('FORM_CONFIG.FORM_ACCOUNT_ADD_CUSTOM_FIELD')}
                    </button>
                </div>
            </div>
        );
    };

    const getNewItems = (items, item) => {
        const newItems = items ? [...items] : [];
        const index = newItems.findIndex((_item) => _item == item.id);
        if (index !== -1) {
            newItems.splice(index, 1);
        } else {
            newItems.push(item.id);
        }

        return newItems;
    };

    const getSelectedAllItems = (items) => {
        if (!items) {
            return [];
        }
        return map(items, (item) => item.id);
    };

    const getConvertKitPanel = () => {
        const courses = formAccountReducer.form_account_data ? formAccountReducer.form_account_data.courses : [];
        const tags = formAccountReducer.form_account_data ? formAccountReducer.form_account_data.tags : [];
        return (
            <React.Fragment>
                <PanelFormAccountFields
                    header={t('FORM_CONFIG.CONVERT_KIT_SEQUENCE')}
                    items={courses}
                    selectedFields={accountConfig.courses || []}
                    onLoadData={loadData}
                    onSelectItem={(item) => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.courses = getNewItems(accountConfig.courses || [], item);
                            })
                        );
                    }}
                    onSelectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.courses = getSelectedAllItems(courses);
                            })
                        );
                    }}
                    onDeselectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.courses = [];
                            })
                        );
                    }}
                />
                <PanelFormAccountFields
                    header={t('FORM_CONFIG.TAG')}
                    items={tags}
                    selectedFields={accountConfig.tags || []}
                    onLoadData={loadData}
                    onSelectItem={(item) => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.tags = getNewItems(accountConfig.tags || [], item);
                            })
                        );
                    }}
                    onSelectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.tags = getSelectedAllItems(tags);
                            })
                        );
                    }}
                    onDeselectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.tags = [];
                            })
                        );
                    }}
                />
            </React.Fragment>
        );
    };

    const getActiveCampaignPanel = () => {
        const list = formAccountReducer.form_account_data ? formAccountReducer.form_account_data.list : [];
        return (
            <React.Fragment>
                <PanelFormAccountFields
                    header={t('FORM_CONFIG.ACTIVE_CAMPAIGN_LIST')}
                    items={list}
                    selectedFields={accountConfig.list}
                    onLoadData={loadData}
                    onSelectItem={(item) => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.list = getNewItems(accountConfig.list, item);
                            })
                        );
                    }}
                    onSelectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.list = getSelectedAllItems(list);
                            })
                        );
                    }}
                    onDeselectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.list = [];
                            })
                        );
                    }}
                />
            </React.Fragment>
        );
    };

    const getInfusionSoftLadi = () => {
        const tags = formAccountReducer.form_account_data ? formAccountReducer.form_account_data.tags : [];
        return (
            <React.Fragment>
                <PanelFormAccountFields
                    header={t('FORM_CONFIG.TAG')}
                    items={tags}
                    selectedFields={accountConfig.tags || []}
                    onLoadData={loadData}
                    onSelectItem={(item) => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.tags = getNewItems(accountConfig.tags || [], item);
                            })
                        );
                    }}
                    onSelectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.tags = getSelectedAllItems(tags);
                            })
                        );
                    }}
                    onDeselectAll={() => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.tags = [];
                            })
                        );
                    }}
                />
            </React.Fragment>
        );
    };

    const getGoogleSheetPanel = () => {
        const sheets = formAccountReducer.sheets || [];
        const worksheets = formAccountReducer.worksheets || [];
        const isLoadingCreate =
            formAccountReducer.loading &&
            [
                formAccountTypes.INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET,
                formAccountTypes.INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID,
            ].includes(formAccountReducer.waiting);
        return (
            <div className='form-config-account google-sheet mt-24'>
                <div className='ladiui form-group margin-top-20'>
                    <label className='ladiui-label m-bt-10 label-custom-fields'>{t('FORM_CONFIG.GOOGLE_SHEET_PICK')}</label>
                    <div className='google-sheet-sheet'>
                        <Dropdown
                            data={sheets}
                            _key='id'
                            _name='name'
                            onSelectItem={(item) => {
                                setAccountConfig(
                                    produce(accountConfig, (draft) => {
                                        draft.sheet_id = item.id;
                                        draft.sheet_name = item.name;
                                    })
                                );
                            }}
                            currentKey={accountConfig.sheet_id}
                            currentValue={accountConfig.sheet_name}
                            validationName={t('FORM_CONFIG.GOOGLE_SHEET_PICK')}
                            ref={(ref) => inputsRef.add(ref)}
                            validations={{ isRequired: true }}
                            placeHolder={t('FORM_CONFIG.GOOGLE_SHEET_PLACE_HOLDER')}
                            renderHeaders={() => (
                                <div onClick={openModalManuallySheetID} className='ladiui dropdown-item'>
                                    {t('FORM_CONFIG.GOOGLE_SHEET_HEADER')}
                                </div>
                            )}
                        />
                        <button
                            style={{ backgroundColor: '#f4f5f8' }}
                            onClick={openModalCreateSheet}
                            className='action ladiui btn btn-secondary'
                        >
                            {t('FORM_CONFIG.GOOGLE_SHEET_CREATE_TITLE')}
                        </button>
                    </div>
                </div>

                <div className='ladiui form-group margin-top-20'>
                    <label className='ladiui-label m-bt-10 label-custom-fields'>{t('FORM_CONFIG.GOOGLE_WORK_SHEET_PICK')}</label>
                    <Dropdown
                        data={worksheets}
                        _key='id'
                        _name='name'
                        onSelectItem={(item) => {
                            setAccountConfig(
                                produce(accountConfig, (draft) => {
                                    draft.work_sheet_id = item.id;
                                    draft.work_sheet_name = item.name;
                                })
                            );
                        }}
                        currentKey={accountConfig.work_sheet_id}
                        validationName={t('FORM_CONFIG.GOOGLE_WORK_SHEET_PICK')}
                        ref={(ref) => inputsRef.add(ref)}
                        validations={{ isRequired: true }}
                        placeHolder={t('FORM_CONFIG.GOOGLE_WORK_SHEET_PLACE_HOLDER')}
                        renderHeaders={() => (
                            <div onClick={loadWorksheets} className='ladiui dropdown-item'>
                                {t('COMMON.REFRESH_LIST')}
                            </div>
                        )}
                    />
                </div>
                {isShowModalGoogleSheet && (
                    <Modal
                        id='modal-google-sheet'
                        title={t('FORM_CONFIG.GOOGLE_SHEET_MANGE')}
                        visible={isShowModalGoogleSheet}
                        onCancel={() => setIsShowModalGoogleSheet(false)}
                        onOk={submitSheet}
                        width={400}
                        isLoading={isLoadingCreate}
                        okText={
                            googleSheetMode == GOOGLE_SHEET_MODE.CREATE
                                ? t('FORM_CONFIG.GOOGLE_SHEET_CREATE')
                                : t('FORM_CONFIG.GOOGLE_SHEET_MANUALLY')
                        }
                    >
                        <label className='ladiui-label'>{t('FORM_CONFIG.GOOGLE_SHEET_NAME')}</label>
                        {googleSheetMode == GOOGLE_SHEET_MODE.CREATE ? (
                            <Input
                                ref={(ref) => inputsGoogleSheetRef.add(ref)}
                                validationName={t('FORM_CONFIG.GOOGLE_SHEET_NAME')}
                                placeholder={t('FORM_CONFIG.GOOGLE_SHEET_NAME_PLACE_HOLDER')}
                                value={sheetName}
                                onChange={(event) => setSheetName(event.target.value)}
                                validations={{ isRequired: true }}
                            />
                        ) : (
                            <Input
                                ref={(ref) => inputsGoogleSheetRef.add(ref)}
                                validationName={t('FORM_CONFIG.GOOGLE_SHEET_NAME')}
                                placeholder={t('FORM_CONFIG.GOOGLE_SHEET_ID_PLACE_HOLDER')}
                                value={sheetID}
                                onChange={(event) => setSheetID(event.target.value)}
                                validations={{ isRequired: true }}
                            />
                        )}
                    </Modal>
                )}
            </div>
        );
    };

    const getForm = (type) => {
        switch (type) {
            case appConfig.FORM_TYPE.CONVERTKIT.CODE:
                return getConvertKitPanel();
            case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
                return getInfusionSoftLadi();
            case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
                return getActiveCampaignPanel();
            case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
                return getGoogleSheetPanel();
        }
    };

    const getCampaignField = () => {
        const campaigns = (formAccountReducer.form_account_data && formAccountReducer.form_account_data.campaigns) || [];

        return (
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('FORM_CONFIG.FORM_ACCOUNT_CAMPAIGN')}</label>
                <Dropdown
                    data={campaigns}
                    onSelectItem={(item) => {
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.campaign_id = item.id;
                            })
                        );
                    }}
                    currentKey={accountConfig.campaign_id}
                    _key={'id'}
                    _value={'name'}
                />
            </div>
        );
    };

    const isLoadingData = formAccountReducer.loading || formConfigReducer.loading;

    const isLoadingSaveConfig = formAccountReducer.loading && includes([formConfigTypes.ADD_ACCOUNT_CONFIG], formAccountReducer.waiting);

    return (
        <Modal
            id='modal-setting-form-config-account'
            // title={t("FORM_CONFIG.FORM_ACCOUNT_CONFIG")}
            title={`${isModalEdit ? 'Chỉnh sửa cấu hình' : 'Thêm tài khoản liên kết'}`}
            sub_title={`${isModalEdit ? '' : 'Kết nối nền tảng bạn muốn tích hợp với LadiSales'}`}
            visible={props.visible}
            onCancel={props.onCancel}
            onOk={submit}
            width={800}
            bodyStyles={{ minHeight: '50vh', maxHeight: '75vh', overflow: 'scroll' }}
            isLoading={isLoadingData}
            isCustomFooter={true}
            footer={
                <div className='ladiui-modal-footer'>
                    {!isModalEdit || isModalEdit == 'false' ? (
                        <button className='ladiui btn btn-secondary flex items-center' onClick={props.handleBackPage}>
                            <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M4.64375 9.85623L0.14375 5.35623C0.0513399 5.26066 -0.000317797 5.13292 -0.000317791 4.99998C-0.000317785 4.86704 0.0513399 4.7393 0.14375 4.64373L4.64375 0.143731C4.7409 0.0639991 4.86423 0.0232532 4.98976 0.0294182C5.11528 0.0355833 5.23403 0.0882185 5.3229 0.177086C5.41176 0.265953 5.4644 0.384699 5.47056 0.510225C5.47673 0.635751 5.43598 0.759083 5.35625 0.856231L1.70625 4.49998L11.5 4.49998C11.6326 4.49998 11.7598 4.55266 11.8536 4.64643C11.9473 4.7402 12 4.86737 12 4.99998C12 5.13259 11.9473 5.25977 11.8536 5.35353C11.7598 5.4473 11.6326 5.49998 11.5 5.49998L1.70625 5.49998L5.35625 9.14373C5.43598 9.24088 5.47673 9.36421 5.47056 9.48974C5.4644 9.61526 5.41176 9.73401 5.32289 9.82288C5.23403 9.91174 5.11528 9.96438 4.98976 9.97054C4.86423 9.97671 4.7409 9.93596 4.64375 9.85623V9.85623Z'
                                    fill='#AFAFAF'
                                />
                            </svg>
                            <span className='ml-8'>Quay lại</span>
                        </button>
                    ) : (
                        <div></div>
                    )}
                    <div className='flex'>
                        <button onClick={props.onCancel} className='ladiui btn btn-secondary mr-12'>
                            {t('ACTIONS.REMOVE')}
                        </button>
                        <button
                            onClick={submit}
                            className={`ladiui btn btn-primary ${isLoadingData || isLoadingSaveConfig ? 'loader' : ''}`}
                        >
                            Lưu cấu hình
                        </button>
                    </div>
                </div>
            }
        >
            {isLoadingData && <LoadingScene blur={true} />}
            {!isModalEdit || isModalEdit === 'false' ? (
                <div className='list-steps-integrate'>
                    <ul>
                        <li>
                            <div className='step-number' style={{ background: '#D8E7FE', color: '#3C72F9' }}>
                                1
                            </div>
                            <span style={{ color: '#0E233C', fontWeight: 500 }}>Chọn nền tảng tích hợp</span>
                        </li>
                        <li>
                            <div className='step-number' style={{ background: '#D8E7FE', color: '#3C72F9' }}>
                                2
                            </div>
                            <span style={{ color: '#0E233C', fontWeight: 500 }}>Liên kết tài khoản</span>
                        </li>
                        <li>
                            <div className='step-number' style={{ background: '#D8E7FE', color: '#1c00c2' }}>
                                3
                            </div>
                            <span style={{ color: '#0E233C', fontWeight: 500 }}>Cấu hình tích hợp</span>
                        </li>
                    </ul>
                </div>
            ) : (
                ''
            )}
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('FORM_CONFIG.FORM_ACCOUNT_CONFIG_NAME')}</label>
                <Input
                    ref={(ref) => inputsRef.add(ref)}
                    validationName={t('FORM_CONFIG.FORM_ACCOUNT_CONFIG_NAME')}
                    placeholder={t('FORM_CONFIG.FORM_ACCOUNT_CONFIG_NAME_PLACE_HOLDER')}
                    value={accountConfig.name}
                    onChange={(event) =>
                        setAccountConfig(
                            produce(accountConfig, (draft) => {
                                draft.name = event.target.value;
                            })
                        )
                    }
                    validations={{ isRequired: true }}
                />
            </div>
            {includes([appConfig.FORM_TYPE.GET_RESPONSE.CODE, appConfig.FORM_TYPE.MAIL_CHIMP.CODE], accountConfig.type) &&
                getCampaignField()}

            {accountConfig.type != appConfig.FORM_TYPE.GOOGLE_SHEET.CODE && getPanelMappingFields()}
            {getForm(accountConfig.type)}
        </Modal>
    );
}

PanelFormAccountFields.propTypes = {
    header: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    selectedFields: PropTypes.array.isRequired,
    onLoadData: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    onDeselectAll: PropTypes.func.isRequired,
};

PanelFormAccountFields.defaultProps = {
    selectedFields: [],
    items: [],
};
function PanelFormAccountFields(props) {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState('');
    const [selectAllItemColor, setSelectAllItemColor] = useState('#000');
    const [cancelAllItemColor, setCancelAllItemColor] = useState('#000');

    const getFilteredItems = () => {
        return filter(props.items, (item) => baseHelper.getAliasName(item.name).includes(baseHelper.getAliasName(keyword)));
    };

    return (
        <div className='ladiui form-group margin-top-20'>
            <label className='ladiui-label m-bt-10 label-custom-fields'>
                <span>{props.header}</span>
                <a className='margin-left-15 a-link' onClick={props.onLoadData}>
                    {t('FORM_CONFIG.FORM_ACCOUNT_FIELDS_REFRESH')}
                </a>
                <a
                    className='margin-left-15 a-link'
                    onClick={props.onSelectAll}
                    style={{ color: selectAllItemColor }}
                    onMouseOver={() => setSelectAllItemColor('#265dfb')}
                    onMouseOut={() => setSelectAllItemColor('#000')}
                >
                    {t('FORM_CONFIG.FORM_ACCOUNT_FIELDS_SELECT_ALL')}
                </a>
                <a
                    className='margin-left-15 a-link'
                    onClick={props.onDeselectAll}
                    style={{ color: cancelAllItemColor }}
                    onMouseOver={() => setCancelAllItemColor('#265dfb')}
                    onMouseOut={() => setCancelAllItemColor('#000')}
                >
                    {t('FORM_CONFIG.FORM_ACCOUNT_FIELDS_DESELECT_ALL')}
                </a>
            </label>
            <div className='integrations-config-search-tag mb-16'>
                <Input
                    placeholder={t('FORM_CONFIG.SEARCH_PLACEHOLDER')}
                    value={keyword}
                    onChange={(event) => {
                        setKeyword(event.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            props.onLoadData(keyword);
                        }
                    }}
                />
            </div>
            <div className='integrations-config-list-sequence'>
                {map(getFilteredItems(), (item) => {
                    let checked = false;
                    if (props.selectedFields.includes(item.id)) {
                        checked = true;
                    }
                    return (
                        <div className='ladiui form-group flex' key={item.id}>
                            <input
                                checked={checked}
                                onChange={() => props.onSelectItem(item)}
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-4'
                            />
                            <label className='ladiui label'> {item.name}</label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
