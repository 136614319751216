import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import orderDefined from '../OrderDefined';

import * as filterTypes from '../../../redux/futures/filter/types';
import filterActions from '../../../redux/futures/filter/actions';

import * as orderTypes from '../../../redux/futures/order/types';
import orderActions from '../../../redux/futures/order/actions';

import printActions from '../../../redux/futures/print/actions';
import productVariantAction from '../../../redux/futures/product_variant/actions';

import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import PopoverDiscount from './components/PopoverDiscount';
import PopoverShipping from './components/PopoverShipping';
import PopoverCoupon from './components/PopoverCoupon';
import ModalRefund from '../OrderEdit/components/ModalRefund';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
export const OrderPaymentInfo = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const {
        variantSelected,
        removeShipping,
        applyShipping,
        shippingMethods,
        shippingFee,
        isEditableOrder,
        orderData,
        removeDiscount,
        applyDiscount,
        selectDiscount,
        customerSelected,
        openModalConfirmCreateOrder,
        getSubTotal,
        getTotal,
        restockAll,
        isCanceled,
    } = props;

    const storeReducer = useSelector((state) => state.store);
    const orderReducer = useSelector((state) => state.order);
    const variantReducer = useSelector((state) => state.variant);

    const isLoadingRefund = useMemo(() => {
        return orderReducer.loading || false;
    }, [orderReducer.loading]);

    const [isShowModalRefund, setShowModalRefund] = useState(false);

    // useEffect(() => {
    // if (orderData.status == appConfig.ORDER_STATUS.CANCELED.CODE) {
    //     setCancel(true);
    // } else setCancel(false);
    // if (orderData.restock_all == appConfig.STATUS.ACTIVE) setRestockAll(true);
    // else setRestockAll(false);
    // }, [orderData]);

    useEffect(() => {
        if (orderReducer.action == orderTypes.REFUND_ORDER) {
            if (orderReducer.status) {
                window.LadiUI.toastCustom('success', '', orderReducer.message);
                setShowModalRefund(false);
                dispatch(orderActions.reload(orderData.order_id));
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }
    }, [orderReducer.action]);

    // useImperativeHandle(ref, () => ({
    //     getData: () => {
    //         return products;
    //     },
    // }));

    const refund = (data) => {
        dispatch(orderActions.refund(data));
    };

    return (
        <>
            {isShowModalRefund && (
                <ModalRefund
                    orderID={orderData.order_id}
                    paymentStatus={orderData.payment_status}
                    refundAll={orderData.order_paid == 0}
                    visible={isShowModalRefund}
                    onOk={refund}
                    onCancel={() => setShowModalRefund(false)}
                    isLoading={isLoadingRefund}
                />
            )}
            <div className='order-payment mt-20 ladiui container-place'>
                <div className='ladiui flex-center-between'>
                    <div className='payment-title'>
                        <label className='ladiui label-18 '>{t('ORDERS.PAYMENT')}</label>
                    </div>
                    {!isEditableOrder && orderData.order_id && (
                        <div
                            className={`ladiui tag mb-12 payment-${
                                orderData.payment_status
                                    ? orderData.payment_status.toLowerCase()
                                    : appConfig.PAYMENT_STATUS.PENDING.CODE.toLowerCase()
                            }`}
                        >
                            {orderDefined.getPaymentStatus(orderData.payment_status)}
                        </div>
                    )}
                </div>

                <div className='payment-info'>
                    <div className='paymnet-item ladiui text-14'>
                        <span className=''>{t('ORDERS.TOTAL_AMOUNT')}</span>{' '}
                        <span>{baseHelper.formatMoneyPostFix(getSubTotal(), storeReducer.userInfo.currentStore.currency_symbol)}</span>
                    </div>

                    <div className='paymnet-item'>
                        {isEditableOrder ? (
                            <PopoverShipping
                                disabledApply={variantSelected.length == 0}
                                removeShipping={removeShipping}
                                applyShipping={applyShipping}
                                shippingMethods={shippingMethods}
                            />
                        ) : (
                            <span>{`${t('ORDERS.SHIPPING')}${shippingFee.name ? ` - ${shippingFee.name} ` : ''}`}</span>
                        )}
                        <span>
                            + {baseHelper.formatMoneyPostFix(shippingFee.fee || 0, storeReducer.userInfo.currentStore.currency_symbol)}
                        </span>
                    </div>

                    <div className='paymnet-item'>
                        {isEditableOrder ? (
                            <PopoverCoupon
                                disabledApply={variantSelected.length == 0}
                                removeDiscount={removeDiscount}
                                applyDiscount={applyDiscount}
                                selectDiscount={selectDiscount}
                            />
                        ) : (
                            <span>
                                <span>{`${t('ORDERS.DISCOUNT')}${
                                    selectDiscount.code || selectDiscount.note ? `- ${selectDiscount.code || selectDiscount.note}` : ''
                                }`}</span>
                            </span>
                        )}
                        <span>
                            -{' '}
                            {baseHelper.formatMoneyPostFix(
                                baseHelper.calculateDiscountFee(
                                    getSubTotal(),
                                    selectDiscount ? selectDiscount.type || 2 : 2,
                                    selectDiscount ? selectDiscount.value || 0 : 0
                                ),
                                storeReducer.userInfo.currentStore.currency_symbol
                            )}
                        </span>
                    </div>
                    {orderData.discount_shipping_value > 0 && (
                        <div className='paymnet-item'>
                            <span>
                                <span>{`${t('ORDERS.DISCOUNT_SHIPPING')} - ${orderData.discount_shipping_code}`}</span>
                            </span>

                            <span>
                                -{' '}
                                {baseHelper.formatMoneyPostFix(
                                    orderData.discount_shipping_value,
                                    storeReducer.userInfo.currentStore.currency_symbol
                                )}
                            </span>
                        </div>
                    )}
                    {(isEditableOrder || orderData.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE) && (
                        <div className='paymnet-item ladiui text-14 fw-500 text-primary'>
                            <span>{t('ORDERS.CUSTOMER_NEED_PAYMENT')}</span>
                            <span>{baseHelper.formatMoneyPostFix(getTotal(), storeReducer.userInfo.currentStore.currency_symbol)}</span>
                        </div>
                    )}

                    {orderData.paid > 0 && (
                        <div className='paymnet-item'>
                            <div className='ladiui text-14 text-primary'>{t('ORDERS.CUSTOMER_PAYMENT')}</div>
                            <div className='ladiui text-14 text-primary'>
                                {baseHelper.formatMoneyPostFix(orderData.paid, storeReducer.userInfo.currentStore.currency_symbol)}
                            </div>
                        </div>
                    )}

                    {orderData.refund > 0 && (
                        <div className='paymnet-item'>
                            <div className='ladiui text-14 text-primary'>{t('ORDERS.REFUND')}</div>
                            <div className='ladiui text-14 text-primary'>
                                {baseHelper.formatMoneyPostFix(orderData.refund, storeReducer.userInfo.currentStore.currency_symbol)}
                            </div>
                        </div>
                    )}
                    {orderData.refund > 0 && (
                        <div className='paymnet-item'>
                            <div className='ladiui text-14 text-primary'>{t('ORDERS.ORDER_PAID')}</div>
                            <div className='ladiui text-14 text-primary'>
                                {baseHelper.formatMoneyPostFix(orderData.order_paid, storeReducer.userInfo.currentStore.currency_symbol)}
                            </div>
                        </div>
                    )}

                    {!isCanceled && !restockAll && orderData.payment_status == appConfig.PAYMENT_STATUS.SUCCESS.CODE && (
                        <div className='item-info'>
                            <div className=''>
                                {!baseHelper.isEmpty(orderData.refund) &&
                                    baseHelper.parseFloat(orderData.refund) == baseHelper.parseFloat(orderData.total) && (
                                        <span className='footer-lable'>{t('ORDERS.REFUND_ALL')}</span>
                                    )}
                            </div>
                        </div>
                    )}

                    {orderData && orderData.order_id && (
                        <>
                            {!isCanceled && !restockAll && !isEditableOrder && (
                                <div className='mt-12 pt-12 ladiui border-t-50p'>
                                    {/* Phương thức thanh toán  */}
                                    <div className='paymnet-item'>
                                        <div className='flex'>
                                            <span className='ladiui text-14-b500'>{t('ORDERS.CONDITION_TITLE.PAYMENT_METHOD')}</span>
                                        </div>
                                        <div>
                                            <span className='ladiui text-14-b500'>
                                                {appConfig.PAYMENT_PARTNERS[orderData.payment_method]
                                                    ? appConfig.PAYMENT_PARTNERS[orderData.payment_method].NAME
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                    {/* Hình thức thanh toán  */}
                                    {orderData.payment_type && orderData.payment_type != orderData.payment_method && (
                                        <div className='paymnet-item mt-12'>
                                            <div className='flex'>
                                                <span className='ladiui text-14 text-primary'>{t('ORDERS.PAYMENT_TYPE')}</span>
                                            </div>
                                            <div>
                                                <span className='ladiui text-14 text-primary'>
                                                    {orderDefined.getPaymentType(orderData.payment_type)}
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    {/* nếu là thanh toán trả góp alepay  */}
                                    {orderData.payment_installment_month && orderData.payment_installment_month > 0 && (
                                        <div className='paymnet-item mt-12'>
                                            <div className='flex'>
                                                <span className='ladiui text-14 text-primary'>{t('ORDERS.ALEPAY_INSTALMENT')}</span>
                                            </div>
                                            <div>
                                                <span className='ladiui text-14 text-primary'>{orderData.payment_installment_month}</span>
                                            </div>
                                        </div>
                                    )}

                                    {/* Nếu là thanh toán VA  */}
                                    {orderData.payment_method == appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE &&
                                    orderData.payment_vnpt_epay_data &&
                                    orderData.payment_vnpt_epay_data.is_va ? (
                                        <>
                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_NAME')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_vnpt_epay_data.va_name}
                                                </div>
                                            </div>

                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_NUMBER')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_vnpt_epay_data.va_number}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}

                                    {orderData.payment_method == appConfig.PAYMENT_PARTNERS.APPOTA.CODE &&
                                    orderData.payment_appota_pay_data &&
                                    orderData.payment_appota_pay_data.is_va ? (
                                        <>
                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_BANK_NAME')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_appota_pay_data.bankCode}
                                                </div>
                                            </div>

                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_NAME')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_appota_pay_data.va_name}
                                                </div>
                                            </div>

                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_NUMBER')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_appota_pay_data.va_number}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}

                                    {orderData.payment_method == appConfig.PAYMENT_PARTNERS.FINAN.CODE &&
                                    orderData.payment_finan_pay_data ? (
                                        <>
                                            {/* <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_BANK_NAME')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_finan_pay_data.bankCode}
                                                </div>
                                            </div> */}

                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_NAME')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_finan_pay_data.va_name}
                                                </div>
                                            </div>

                                            <div className='paymnet-item mt-12'>
                                                <div className='ladiui text-14 text-primary'>{t('ORDERS.VA_NUMBER')}</div>
                                                <div className='ladiui text-14 text-primary'>
                                                    {orderData.payment_finan_pay_data.va_number}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}

                                    {orderData.transaction_id && (
                                        <div className='paymnet-item mt-12'>
                                            <div className='flex'>
                                                <span className='ladiui text-14 text-primary'>{t('ORDERS.TRADING_CODE_LADISALES')}</span>
                                            </div>
                                            <div>
                                                <span className='ladiui text-14 text-primary'>{orderData.transaction_id}</span>
                                            </div>
                                        </div>
                                    )}
                                    {orderData.gateway_transaction_id && orderData.gateway_transaction_id != orderData.transaction_id && (
                                        <div className='paymnet-item mt-12'>
                                            <div className='flex'>
                                                <span className='ladiui text-14 text-primary'>{t('ORDERS.TRADING_CODE')}</span>
                                            </div>
                                            <div>
                                                <span className='ladiui text-14 text-primary'>{orderData.gateway_transaction_id}</span>
                                            </div>
                                        </div>
                                    )}

                                    {orderData.transaction_status_id &&
                                        orderData.transaction_status_id != orderData.gateway_transaction_id && (
                                            <div className='paymnet-item mt-12'>
                                                <div className='flex'>
                                                    <span className='ladiui text-14 text-primary'>
                                                        {t('ORDERS.TRADING_CODE')} {orderDefined.getPaymentType(orderData.payment_type)}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className='ladiui text-14 text-primary'>{orderData.transaction_status_id}</span>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            )}
                        </>
                    )}
                </div>
                {orderData && orderData.status != appConfig.ORDER_STATUS.CANCELED.CODE && (
                    <div className='payment-action ladiui flex-center-end footer'>
                        {isEditableOrder ? (
                            <>
                                <button
                                    className={`ladiui btn btn-secondary btn-sm mr-16 ${
                                        variantSelected.length == 0 || !customerSelected.customer_id ? 'disable' : ''
                                    }`}
                                    onClick={() => openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.PENDING.CODE)}
                                >
                                    {t('ORDERS.MARK_AS_PENDING')}
                                </button>
                                <button
                                    className={`ladiui btn btn-primary btn-sm ${
                                        variantSelected.length == 0 || !customerSelected.customer_id ? 'disable' : ''
                                    }`}
                                    onClick={() => {
                                        openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.SUCCESS.CODE);
                                    }}
                                >
                                    {t('ORDERS.MARK_AS_PAID')}
                                </button>
                            </>
                        ) : (
                            <>
                                {orderData.restock_all == appConfig.STATUS.INACTIVE && (
                                    <>
                                        {orderData.order_paid > 0 ? (
                                            <button
                                                onClick={() => setShowModalRefund(true)}
                                                className='ladiui btn btn-sm btn-secondary refund'
                                            >
                                                {t('ORDERS.REFUND')}
                                            </button>
                                        ) : (
                                            <button className='ladiui btn btn-sm btn-secondary ' onClick={() => setShowModalRefund(true)}>
                                                {' '}
                                                {t('ORDERS.REFUND_INVENTORY')}
                                            </button>
                                        )}
                                    </>
                                )}
                                {orderData && orderData.payment_status != appConfig.PAYMENT_STATUS.SUCCESS.CODE && (
                                    <button
                                        className={`ladiui btn btn-sm btn-primary  ml-16`}
                                        onClick={() => openModalConfirmCreateOrder(appConfig.PAYMENT_STATUS.SUCCESS.CODE)}
                                    >
                                        {t('ORDERS.CONFIRM_PAYMENT')}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
});
