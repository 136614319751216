import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, find, includes, map, compact } from 'lodash';

import staffActions from '../../../../redux/futures/staff/actions';

import settingActions from '../../../../redux/futures/setting/actions';
import * as settingTypes from '../../../../redux/futures/setting/types';

import shippingActions from '../../../../redux/futures/shipping/actions';

import shippingRateActions from '../../../../redux/futures/shipping_rate/actions';
import * as shippingRateTypes from '../../../../redux/futures/shipping_rate/types';

import { connect } from 'react-redux';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../../../components/ConfirmModal';
import ModalShippingRate from './components/ModalShippingRate';
import Modal from '../../../../components/Modal';
import ModalShippingRateDetail from './components/ModalShippingRateDetail';
import addressConfigActions from '../../../../redux/futures/address_config/actions';

import ItemShipping from '../ModalShipping/ItemShipping';
import ModalGHN from '../ModalShipping/ModalGHN';
import ModalGHTK from '../ModalShipping/ModalGHTK';
import ModalVNPOST from '../ModalShipping/ModalVNPost';
import ModalVTPOST from '../ModalShipping/ModalVTPost';

class ModalSettingShipping extends React.Component {
    static propTypes = {
        shippingRates: PropTypes.array,
    };

    constructor(props) {
        super(props);

        this.state = {
            ghnAccount: {
                token: '',
                shop_id: '',
                client_order_code: '',
            },
            ghnMode: appConfig.MODE.TEST,
            ghtkAccount: {
                email: '',
                password: '',
            },
            ghtkMode: appConfig.MODE.TEST,
            vnPostAccount: {
                customer_code: '',
                // token: ''
            },
            vnPostMode: appConfig.MODE.TEST,
            viettelPostAccount: {
                email: '',
                password: '',
            },
            viettelPostMode: appConfig.MODE.TEST,
            selectedPartner: {
                shipping_api_store_config_id: '',
                code: '',
                name: '',
            },
            selectedShippingRate: {},
            selectedShippingRateDetail: {},
            isShowGHNSetting: false,
            isShowGHTKSetting: false,
            isShowVNPOSTSetting: false,
            isShowViettelPostSetting: false,
            isShowModalShippingRate: false,
            isShowModalShippingRateDetail: false,
            shippingRateDetailMode: null,
            tabActiveSelected: 'SHIPPING_TAB_SERVICE',
        };

        this.initialState = cloneDeep(this.state);
        this.inputsGHNRef = new Set();
        this.inputsGHTKRef = new Set();
        this.inputsVNPOSTRef = new Set();
        this.inputsViettelPostRef = new Set();
    }

    componentDidMount() {
        this.props.listPartners();
        this.props.listShippingRate();
        this.props.listStates('VN');
    }

    componentDidUpdate() {
        window.LadiUI.init();
        window.LadiUI.customInit();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.partners != nextProps.partners) {
            this.resetState();
        }

        if (this.props.settingReducer.action != nextProps.settingReducer.action) {
            if (nextProps.settingReducer.action == settingTypes.SETTING_SHIPPING_DISCONNECT) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    window.LadiUI.closeModal('confirm-disconnect-shipping');
                    this.props.listPartners();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.settingReducer.message, 'OK');
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_SHIPPING_INACTIVE) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    window.LadiUI.closeModal('confirm-inactive-shipping');
                    this.props.listPartners();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.settingReducer.message, 'OK');
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_SHIPPING_ACTIVE) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    this.props.listPartners();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.settingReducer.message, 'OK');
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_SHIPPING_CONNECT) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    this.props.listPartners();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.settingReducer.message, 'OK');
                }
            }
        }

        if (this.props.shippingRate.action != nextProps.shippingRate.action) {
            if (nextProps.shippingRate.action == shippingRateTypes.SHIPPING_RATE_CREATE) {
                if (nextProps.shippingRate.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingRate.message);
                    this.setState({
                        isShowModalShippingRate: false,
                    });

                    this.props.listShippingRate();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingRate.message, 'OK');
                }
            }

            if (nextProps.shippingRate.action == shippingRateTypes.SHIPPING_RATE_DETAIL_UPDATE) {
                if (nextProps.shippingRate.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingRate.message);
                    this.setState({
                        isShowModalShippingRateDetail: false,
                    });
                    this.props.listShippingRate();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingRate.message, 'OK');
                }
            }

            if (nextProps.shippingRate.action == shippingRateTypes.SHIPPING_RATE_DETAIL_CREATE) {
                if (nextProps.shippingRate.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingRate.message);
                    this.setState({
                        isShowModalShippingRateDetail: false,
                    });

                    this.props.listShippingRate();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingRate.message, 'OK');
                }
            }

            if (nextProps.shippingRate.action == shippingRateTypes.SHIPPING_RATE_DELETE) {
                if (nextProps.shippingRate.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingRate.message);
                    window.LadiUI.closeModal('confirm-shipping-rate-delete');
                    this.props.listShippingRate();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingRate.message, 'OK');
                }
            }

            if (nextProps.shippingRate.action == shippingRateTypes.SHIPPING_RATE_DETAIL_DELETE) {
                if (nextProps.shippingRate.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingRate.message);
                    window.LadiUI.closeModal('confirm-shipping-rate-detail-delete');
                    this.props.listShippingRate();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingRate.message, 'OK');
                }
            }
        }
    }

    resetState = () => {
        this.setState({
            ...cloneDeep(this.initialState),
        });
    };

    onChangeInput = (event) => {
        this.setState({
            staff: {
                ...this.state.staff,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeGHNInput = (event) => {
        this.setState({
            ghnAccount: {
                ...this.state.ghnAccount,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeGHTKInput = (event) => {
        this.setState({
            ghtkAccount: {
                ...this.state.ghtkAccount,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeVNPOSTInput = (event) => {
        this.setState({
            vnPostAccount: {
                ...this.state.vnPostAccount,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeViettelPostInput = (event) => {
        this.setState({
            viettelPostAccount: {
                ...this.state.viettelPostAccount,
                [event.target.name]: event.target.value,
            },
        });
    };

    selectRole = (role) => {
        this.setState({
            staff: {
                ...this.state.staff,
                role,
            },
        });
    };

    active = (selectedPartner) => {
        this.setState(
            {
                selectedPartner,
            },
            () => {
                this.props.active(this.state.selectedPartner.code, this.state.selectedPartner.shipping_api_store_config_id);
            }
        );
    };

    openDisconnectModal = (selectedPartner) => {
        this.setState(
            {
                selectedPartner,
            },
            () => {
                window.LadiUI.showModal('confirm-disconnect-shipping');
            }
        );
    };

    openInactiveModal = (selectedPartner) => {
        this.setState(
            {
                selectedPartner,
            },
            () => {
                window.LadiUI.showModal('confirm-inactive-shipping');
            }
        );
    };

    partnerHasBeenConfigured = (partnerCode) => {
        const { partners } = this.props;
        const foundPartner = find(partners, (item) => item.code == partnerCode);
        return foundPartner;
    };

    openModalShippingRate = () => {
        this.setState({
            isShowModalShippingRate: true,
        });
    };

    hideModalShippingRate = () => {
        this.setState({
            isShowModalShippingRate: false,
        });
    };

    createShippingRate = (shippingRate) => {
        this.props.createShippingRate(shippingRate);
    };

    openModalDetailCreate = (selectedShippingRate) => {
        this.setState({
            selectedShippingRate,
            selectedShippingRateDetail: {},
            shippingRateDetailMode: appConfig.FORM_MODE.CREATE,
            isShowModalShippingRateDetail: true,
        });
    };

    openModalDetailEdit = (selectedShippingRate, selectedShippingRateDetail) => {
        this.setState({
            selectedShippingRate,
            selectedShippingRateDetail,
            shippingRateDetailMode: appConfig.FORM_MODE.EDIT,
            isShowModalShippingRateDetail: true,
        });
    };

    openModalConfirmDeleteDetail = (selectedShippingRate, selectedShippingRateDetail) => {
        this.setState(
            {
                selectedShippingRate,
                selectedShippingRateDetail,
            },
            () => {
                window.LadiUI.showModal('confirm-shipping-rate-detail-delete');
            }
        );
    };

    openModalConfirmDeleteShippingRate = (selectedShippingRate) => {
        this.setState(
            {
                selectedShippingRate,
            },
            () => {
                window.LadiUI.showModal('confirm-shipping-rate-delete');
            }
        );
    };

    hideModalShippingRateDetail = () => {
        this.setState({
            isShowModalShippingRateDetail: false,
        });
    };

    submitShippingRateDetail = (shippingRateDetail) => {
        if (this.state.shippingRateDetailMode == appConfig.FORM_MODE.CREATE) {
            this.props.createShippingRateDetail(shippingRateDetail);
        } else {
            this.props.updateShippingRateDetail(shippingRateDetail);
        }
    };

    getGHNForm = () => {
        const { ghnAccount, isShowGHNSetting, selectedPartner } = this.state;
        const { t } = this.props;
        const isSubmitLoading =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActive =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const partnerConfigured = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.GHN.CODE);

        if (partnerConfigured) {
            return (
                <div>
                    {isShowGHNSetting && (
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.TOKEN')}</label>
                                <Input value={partnerConfigured.config.token} disabled={true} />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SHIPPINGS.GHN_SHOP_ID')}</label>
                                <Input value={partnerConfigured.config.shop_id} disabled={true} />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SHIPPINGS.CLIENT_ORDER_CODE')}</label>
                                <Input value={partnerConfigured.config.client_order_code} disabled={true} />
                            </div>
                        </div>
                    )}
                    <div className='partner-footer'>
                        <div className='left'>
                            <button onClick={this.toogleGHNSetting} className='ladiui button secondary'>
                                {isShowGHNSetting ? t('COMMON.HIDE') : t('COMMON.DETAIL')}
                            </button>
                            {partnerConfigured.status == appConfig.STATUS.ACTIVE ? (
                                <button onClick={() => this.openInactiveModal(partnerConfigured)} className='ladiui button danger'>
                                    {t('ACTIONS.INACTIVE')}
                                </button>
                            ) : (
                                <React.Fragment>
                                    <button
                                        onClick={() => this.active(partnerConfigured)}
                                        className={`ladiui button primary ${isLoadingActive ? 'loader' : ''}`}
                                    >
                                        {t('ACTIONS.ENABLE')}
                                    </button>
                                    <button onClick={() => this.openDisconnectModal(partnerConfigured)} className='ladiui button danger'>
                                        {t('ACTIONS.STOP_USING')}
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isShowGHNSetting ? (
                    <div className='partner-footer'>
                        <div className='right'>
                            <button onClick={this.toogleGHNSetting} className='ladiui button secondary'>
                                {t('ACTIONS.SETUP')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.TOKEN')}</label>
                                <Input
                                    ref={(ref) => this.inputsGHNRef.add(ref)}
                                    value={ghnAccount.token}
                                    validationName={t('COMMON.TOKEN')}
                                    name='token'
                                    onChange={this.onChangeGHNInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SHIPPINGS.GHN_SHOP_ID')}</label>
                                <Input
                                    ref={(ref) => this.inputsGHNRef.add(ref)}
                                    name='shop_id'
                                    value={ghnAccount.shop_id}
                                    validationName={t('COMMON.GHN_SHOP_ID')}
                                    onChange={this.onChangeGHNInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>

                            <div className='ladiui form-group client-order-code'>
                                <label className='ladiui-label'>
                                    {t('SHIPPINGS.CLIENT_ORDER_CODE')}
                                    <span data-tooltip={t('SHIPPINGS.LB_TT_PAYMENT_GUIDE')} data-tooltip-position='right'>
                                        {' '}
                                        <i className='ladi-icon icon-c-question' />
                                    </span>
                                </label>
                                <Input
                                    ref={(ref) => this.inputsGHNRef.add(ref)}
                                    name='client_order_code'
                                    value={ghnAccount.client_order_code}
                                    onChange={this.onChangeGHNInput}
                                />
                            </div>

                            {baseHelper.isDevelopEnv() && (
                                <div className='ladiui form-group'>
                                    <input
                                        id='ghn_mode'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                        onChange={() => {
                                            this.setState({
                                                ghnMode: !this.state.ghnMode,
                                            });
                                        }}
                                        checked={this.state.ghnMode == appConfig.MODE.TEST}
                                    />
                                    <label htmlFor='ghn_mode'> {t('MODE.TEST')}</label>
                                </div>
                            )}
                        </div>

                        <div className='partner-footer'>
                            <div className='right'>
                                <button className='ladiui button secondary' onClick={this.toogleGHNSetting}>
                                    <i className='ladi-icon icon-e-remove' />
                                    {t('ACTIONS.CANCEL')}
                                </button>
                                <button
                                    className={`ladiui button primary icon icon-floppy-disk ${isSubmitLoading ? 'loader' : ''}`}
                                    onClick={() => this.connect(appConfig.SHIPPING_PARTNERS.GHN.CODE)}
                                    disabled={isSubmitLoading}
                                >
                                    {' '}
                                    {t('ACTIONS.SAVE')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    getGHTKForm = () => {
        const { ghtkAccount, isShowGHTKSetting, selectedPartner } = this.state;
        const { t } = this.props;
        const isSubmitLoading =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHTK.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActive =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHTK.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const partnerConfigured = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.GHTK.CODE);
        if (partnerConfigured) {
            return (
                <div>
                    {isShowGHTKSetting && (
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SETTINGS.GHTK_SHOP_CODE')}</label>
                                <Input value={partnerConfigured.config.code} disabled={true} />
                            </div>
                        </div>
                    )}

                    <div className='partner-footer'>
                        <div className='left'>
                            <button onClick={this.toogleGHTKSetting} className='ladiui button secondary'>
                                {isShowGHTKSetting ? t('COMMON.HIDE') : t('COMMON.DETAIL')}
                            </button>
                            {partnerConfigured.status == appConfig.STATUS.ACTIVE ? (
                                <button onClick={() => this.openInactiveModal(partnerConfigured)} className='ladiui button danger'>
                                    {t('ACTIONS.INACTIVE')}
                                </button>
                            ) : (
                                <React.Fragment>
                                    <button
                                        onClick={() => this.active(partnerConfigured)}
                                        className={`ladiui button primary ${isLoadingActive ? 'loader' : ''}`}
                                    >
                                        {t('ACTIONS.ENABLE')}
                                    </button>
                                    <button onClick={() => this.openDisconnectModal(partnerConfigured)} className='ladiui button danger'>
                                        {t('ACTIONS.STOP_USING')}
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isShowGHTKSetting ? (
                    <div className='partner-footer'>
                        <div className='right'>
                            <button onClick={this.toogleGHTKSetting} className='ladiui button secondary'>
                                {t('ACTIONS.SETUP')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.EMAIL')}</label>
                                <Input
                                    ref={(ref) => this.inputsGHTKRef.add(ref)}
                                    value={ghtkAccount.email}
                                    validationName={t('COMMON.EMAIL')}
                                    name='email'
                                    onChange={this.onChangeGHTKInput}
                                    validations={{ isRequired: true, email: true }}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.PASSWORD')}</label>
                                <Input
                                    ref={(ref) => this.inputsGHTKRef.add(ref)}
                                    name='password'
                                    type='password'
                                    value={ghtkAccount.password}
                                    validationName={t('COMMON.PASSWORD')}
                                    onChange={this.onChangeGHTKInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                        </div>

                        {baseHelper.isDevelopEnv() && (
                            <div className='ladiui form-group'>
                                <input
                                    id='ghtk_mode'
                                    type='checkbox'
                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                    onChange={() => {
                                        this.setState({
                                            ghtkMode: !this.state.ghtkMode,
                                        });
                                    }}
                                    checked={this.state.ghtkMode == appConfig.MODE.TEST}
                                />
                                <label htmlFor='ghtk_mode'> {t('MODE.TEST')}</label>
                            </div>
                        )}
                        <div className='partner-footer'>
                            <div className='right'>
                                <button className='ladiui button secondary' onClick={this.toogleGHTKSetting}>
                                    <i className='ladi-icon icon-e-remove' />
                                    {t('ACTIONS.CANCEL')}
                                </button>
                                <button
                                    className={`ladiui button primary icon icon-floppy-disk ${isSubmitLoading ? 'loader' : ''}`}
                                    onClick={() => this.connect(appConfig.SHIPPING_PARTNERS.GHTK.CODE)}
                                    disabled={isSubmitLoading}
                                >
                                    {' '}
                                    {t('ACTIONS.SAVE')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    getVNPOSTForm = () => {
        const { vnPostAccount, isShowVNPOSTSetting, selectedPartner } = this.state;
        const { t } = this.props;
        const isSubmitLoading =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VNPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActive =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VNPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const partnerConfigured = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.VNPOST.CODE);
        if (partnerConfigured) {
            return (
                <div>
                    {isShowVNPOSTSetting && (
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('VNPOST.CUSTOMER_CODE')}</label>
                                <Input value={partnerConfigured.config.customer_code} disabled={true} />
                            </div>
                        </div>
                    )}
                    <div className='partner-footer'>
                        <div className='left'>
                            <button onClick={this.toogleVNPOSTSetting} className='ladiui button secondary'>
                                {isShowVNPOSTSetting ? t('COMMON.HIDE') : t('COMMON.DETAIL')}
                            </button>
                            {partnerConfigured.status == appConfig.STATUS.ACTIVE ? (
                                <button onClick={() => this.openInactiveModal(partnerConfigured)} className='ladiui button danger'>
                                    {t('ACTIONS.INACTIVE')}
                                </button>
                            ) : (
                                <React.Fragment>
                                    <button
                                        onClick={() => this.active(partnerConfigured)}
                                        className={`ladiui button primary ${isLoadingActive ? 'loader' : ''}`}
                                    >
                                        {t('ACTIONS.ENABLE')}
                                    </button>
                                    <button onClick={() => this.openDisconnectModal(partnerConfigured)} className='ladiui button danger'>
                                        {t('ACTIONS.STOP_USING')}
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isShowVNPOSTSetting ? (
                    <div className='partner-footer'>
                        <div className='right'>
                            <button onClick={this.toogleVNPOSTSetting} className='ladiui button secondary'>
                                {t('ACTIONS.SETUP')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('VNPOST.CUSTOMER_CODE')}</label>
                                <Input
                                    ref={(ref) => this.inputsVNPOSTRef.add(ref)}
                                    value={vnPostAccount.customer_code}
                                    validationName={t('VNPOST.CUSTOMER_CODE')}
                                    name='customer_code'
                                    onChange={this.onChangeVNPOSTInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>

                            {/* <div className="ladiui form-group">
                                <label className="ladiui-label">{t('COMMON.TOKEN')}</label>
                                <Input
                                    ref={(ref) => this.inputsVNPOSTRef.add(ref)}
                                    name="token"
                                    value={vnPostAccount.token}
                                    validationName={t('COMMON.TOKEN')}
                                    onChange={this.onChangeVNPOSTInput}
                                    validations={{ isRequired: true }}
                                />
                            </div> */}
                            {baseHelper.isDevelopEnv() && (
                                <div className='ladiui form-group'>
                                    <input
                                        id='vnpost_mode'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                        onChange={() => {
                                            this.setState({
                                                vnPostMode: !this.state.vnPostMode,
                                            });
                                        }}
                                        checked={this.state.vnPostMode == appConfig.MODE.TEST}
                                    />
                                    <label htmlFor='vnpost_mode'> {t('MODE.TEST')}</label>
                                </div>
                            )}
                        </div>

                        <div className='partner-footer'>
                            <div className='right'>
                                <button className='ladiui button secondary' onClick={this.toogleVNPOSTSetting}>
                                    <i className='ladi-icon icon-e-remove' />
                                    {t('ACTIONS.CANCEL')}
                                </button>
                                <button
                                    className={`ladiui button primary icon icon-floppy-disk ${isSubmitLoading ? 'loader' : ''}`}
                                    onClick={() => this.connect(appConfig.SHIPPING_PARTNERS.VNPOST.CODE)}
                                    disabled={isSubmitLoading}
                                >
                                    {' '}
                                    {t('ACTIONS.SAVE')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    getViettelPostForm = () => {
        const { viettelPostAccount, isShowViettelPostSetting, selectedPartner } = this.state;
        const { t } = this.props;
        const isSubmitLoading =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VTPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActive =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VTPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const partnerConfigured = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.VTPOST.CODE);
        if (partnerConfigured) {
            return (
                <div>
                    {isShowViettelPostSetting && (
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.TOKEN')}</label>
                                <Input value={partnerConfigured.config.token} disabled={true} />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('SETTINGS.VTPOST_PARTNER_CODE')}</label>
                                <Input value={partnerConfigured.config.code} disabled={true} />
                            </div>
                        </div>
                    )}

                    <div className='partner-footer'>
                        <div className='left'>
                            <button onClick={this.toogleViettelPostSetting} className='ladiui button secondary'>
                                {isShowViettelPostSetting ? t('COMMON.HIDE') : t('COMMON.DETAIL')}
                            </button>
                            {partnerConfigured.status == appConfig.STATUS.ACTIVE ? (
                                <button onClick={() => this.openInactiveModal(partnerConfigured)} className='ladiui button danger'>
                                    {t('ACTIONS.INACTIVE')}
                                </button>
                            ) : (
                                <React.Fragment>
                                    <button
                                        onClick={() => this.active(partnerConfigured)}
                                        className={`ladiui button primary ${isLoadingActive ? 'loader' : ''}`}
                                    >
                                        {t('ACTIONS.ENABLE')}
                                    </button>
                                    <button onClick={() => this.openDisconnectModal(partnerConfigured)} className='ladiui button danger'>
                                        {t('ACTIONS.STOP_USING')}
                                    </button>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                {!isShowViettelPostSetting ? (
                    <div className='partner-footer'>
                        <div className='right'>
                            <button onClick={this.toogleViettelPostSetting} className='ladiui button secondary'>
                                {t('ACTIONS.SETUP')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='partner-account'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.EMAIL')}</label>
                                <Input
                                    ref={(ref) => this.inputsViettelPostRef.add(ref)}
                                    value={viettelPostAccount.email}
                                    validationName={t('COMMON.EMAIL')}
                                    name='email'
                                    onChange={this.onChangeViettelPostInput}
                                    validations={{ isRequired: true, email: true }}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('COMMON.PASSWORD')}</label>
                                <Input
                                    ref={(ref) => this.inputsViettelPostRef.add(ref)}
                                    name='password'
                                    type='password'
                                    value={viettelPostAccount.password}
                                    validationName={t('COMMON.PASSWORD')}
                                    onChange={this.onChangeViettelPostInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                        </div>

                        {baseHelper.isDevelopEnv() && (
                            <div className='ladiui form-group'>
                                <input
                                    id='viettel_post_mode'
                                    type='checkbox'
                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                    onChange={() => {
                                        this.setState({
                                            viettelPostMode: !this.state.viettelPostMode,
                                        });
                                    }}
                                    checked={this.state.viettelPostMode == appConfig.MODE.TEST}
                                />
                                <label htmlFor='viettel_post_mode'> {t('MODE.TEST')}</label>
                            </div>
                        )}
                        <div className='partner-footer'>
                            <div className='right'>
                                <button className='ladiui button secondary' onClick={this.toogleViettelPostSetting}>
                                    <i className='ladi-icon icon-e-remove' />
                                    {t('ACTIONS.CANCEL')}
                                </button>
                                <button
                                    className={`ladiui button primary icon icon-floppy-disk ${isSubmitLoading ? 'loader' : ''}`}
                                    onClick={() => this.connect(appConfig.SHIPPING_PARTNERS.VTPOST.CODE)}
                                    disabled={isSubmitLoading}
                                >
                                    {' '}
                                    {t('ACTIONS.SAVE')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    getShippingRateForm = () => {
        const { t, shippingRates } = this.props;
        const isLoadingCreateShippingRate =
            this.props.shippingRate.loading && shippingRateTypes.SHIPPING_RATE_CREATE === this.props.shippingRate.waiting;
        const isSubmitLoadingShippingRateDetail =
            this.props.shippingRate.loading &&
            includes(
                [shippingRateTypes.SHIPPING_RATE_DETAIL_CREATE, shippingRateTypes.SHIPPING_RATE_DETAIL_UPDATE],
                this.props.shippingRate.waiting
            );
        const isLoadingDeleteShippingRateDetail =
            this.props.shippingRate.loading && shippingRateTypes.SHIPPING_RATE_DETAIL_DELETE === this.props.shippingRate.waiting;
        const isLoadingDeleteShippingRate =
            this.props.shippingRate.loading && shippingRateTypes.SHIPPING_RATE_DELETE === this.props.shippingRate.waiting;

        return (
            <div>
                <div className='ladi-card'>
                    <div className='list-shipping-content'>
                        {map(shippingRates, (item, index) => {
                            const note = (
                                <div key={index} className='block-list-shipping'>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            backgroundColor: '#f1f3f4',
                                            padding: '11px 0px 11px 16px',
                                        }}
                                    >
                                        <div>
                                            <span className='title-heading-shipping'>Khu vực {item.state_name}</span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <button
                                                onClick={() => this.openModalDetailCreate(item)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px',
                                                    height: 'auto',
                                                    border: 'none',
                                                }}
                                                type='button'
                                                className='ladiui btn btn-outline-secondary'
                                            >
                                                <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' alt='' />
                                                <span
                                                    style={{
                                                        padding: '0px 5px 0px 9px',
                                                        color: 'var(--main-primary)',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Thêm phí vận chuyển
                                                </span>
                                            </button>
                                            <button
                                                onClick={() => this.openModalConfirmDeleteShippingRate(item)}
                                                style={{ height: 'auto', padding: '0px 18px', border: 'none' }}
                                                type='button'
                                                className='ladiui btn btn-outline-secondary'
                                            >
                                                <i className='ladi-icon icon-bin red-color'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='list-content-shipping-rate'>
                                        <table className='ladiui table text-left'>
                                            <thead>
                                                <tr className='ladiui table-vertical'>
                                                    <th scope='col' style={{ width: '600px' }} className={`text-left`}>
                                                        Phương thức vận chuyển
                                                    </th>
                                                    <th scope='col'>Tối thiểu</th>
                                                    <th scope='col' style={{ width: 200 }}>
                                                        Tối đa
                                                    </th>
                                                    <th scope='col'>Phí vận chuyển</th>
                                                    <th scope='col'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {map(item.data, (_item, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            className={`ladiui table-vertical ${
                                                                index == item.data.length - 1 ? 'last-row' : ''
                                                            }`}
                                                        >
                                                            <td>{_item.name}</td>
                                                            <td>
                                                                {_item.type == appConfig.SHIPPING_RATE_DETAIL_TYPE.PRICE.value
                                                                    ? baseHelper.formatMoneyPostFix(
                                                                          _item.down_price,
                                                                          this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                      ) + ' '
                                                                    : _item.down_weight + 'g '}
                                                            </td>
                                                            <td>
                                                                {_item.type == appConfig.SHIPPING_RATE_DETAIL_TYPE.PRICE.value
                                                                    ? !_item.up_price
                                                                        ? t('SHIPPING_RATES.UNLIMITED')
                                                                        : baseHelper.formatMoneyPostFix(
                                                                              _item.up_price,
                                                                              this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                          ) + ' '
                                                                    : !_item.up_weight
                                                                    ? t('SHIPPING_RATES.UNLIMITED')
                                                                    : _item.up_weight + 'g '}
                                                            </td>
                                                            <td>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    _item.fee,
                                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </td>
                                                            <td className='text-right'>
                                                                <div className='ladiui btn-group'>
                                                                    <div className='ladiui dropdown hide-mt ba-c'>
                                                                        {/* <i
                                      className="ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5"
                                      data-toggle="dropdown"
                                    ></i> */}
                                                                        <button
                                                                            data-toggle='dropdown'
                                                                            className='ladiui-btn-dropdown dropdown-toggle'
                                                                        >
                                                                            <i className='ladiui icon icon-ldp-dot'></i>
                                                                        </button>
                                                                        <ul className='ladiui dropdown-menu r-0'>
                                                                            <li>
                                                                                <a
                                                                                    onClick={() => this.openModalDetailEdit(item, _item)}
                                                                                    className='ladiui dropdown-item'
                                                                                >
                                                                                    {t('ACTIONS.EDIT')}
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    onClick={() =>
                                                                                        this.openModalConfirmDeleteDetail(item, _item)
                                                                                    }
                                                                                    className='ladiui dropdown-item'
                                                                                >
                                                                                    {t('ACTIONS.DELETE')}
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                            return note;
                        })}
                    </div>
                </div>
                {this.state.isShowModalShippingRate && (
                    <ModalShippingRate
                        onCancel={this.hideModalShippingRate}
                        onSubmit={this.createShippingRate}
                        visible={this.state.isShowModalShippingRate}
                        isLoading={isLoadingCreateShippingRate}
                        _states={this.props._states}
                    />
                )}

                {this.state.isShowModalShippingRateDetail && (
                    <ModalShippingRateDetail
                        mode={this.state.shippingRateDetailMode}
                        onCancel={this.hideModalShippingRateDetail}
                        onSubmit={this.submitShippingRateDetail}
                        visible={this.state.isShowModalShippingRateDetail}
                        isLoading={isSubmitLoadingShippingRateDetail}
                        shippingRate={this.state.selectedShippingRate}
                        shippingRateDetail={this.state.selectedShippingRateDetail}
                    />
                )}

                <ConfirmModal
                    id='confirm-shipping-rate-delete'
                    title={t('SHIPPING_RATES.MSG_SHIPPING_RATE_TITLE_DELETE', this.state.selectedShippingRate.state_name)}
                    content={t('SHIPPING_RATES.MSG_SHIPPING_RATE_CONTENT_DELETE', this.state.selectedShippingRate.state_name)}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deleteShippingRate(this.state.selectedShippingRate.shipping_rate_id)}
                    isLoading={isLoadingDeleteShippingRate}
                />

                <ConfirmModal
                    id='confirm-shipping-rate-detail-delete'
                    title={t('SHIPPING_RATES.MSG_SHIPPING_RATE_DETAIL_TITLE_DELETE', this.state.selectedShippingRate.state_name)}
                    content={t(
                        'SHIPPING_RATES.MSG_SHIPPING_RATE_DETAIL_CONTENT_DELETE',
                        this.state.selectedShippingRateDetail.name,
                        this.state.selectedShippingRate.state_name
                    )}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deleteShippingRateDetail(this.state.selectedShippingRateDetail.shipping_rate_detail_id)}
                    isLoading={isLoadingDeleteShippingRateDetail}
                />
            </div>
        );
    };

    toogleGHNSetting = () => {
        this.setState({
            isShowGHNSetting: !this.state.isShowGHNSetting,
        });
    };

    toogleGHTKSetting = () => {
        this.setState({
            isShowGHTKSetting: !this.state.isShowGHTKSetting,
        });
    };

    toogleVNPOSTSetting = () => {
        this.setState({
            isShowVNPOSTSetting: !this.state.isShowVNPOSTSetting,
        });
    };

    toogleViettelPostSetting = () => {
        this.setState({
            isShowViettelPostSetting: !this.state.isShowViettelPostSetting,
        });
    };

    setGHNModal = () => {
        this.setState({
            ghnMode: !this.state.ghnMode,
        });
    };
    setGHTKModal = () => {
        this.setState({
            ghtkMode: !this.state.ghtkMode,
        });
    };
    setVNPOSTModal = () => {
        this.setState({
            vnPostMode: !this.state.vnPostMode,
        });
    };
    setVTPostModal = () => {
        this.setState({
            viettelPostMode: !this.state.viettelPostMode,
        });
    };

    connect = (partnerCode) => {
        this.setState(
            {
                selectedPartner: {
                    code: partnerCode,
                },
            },
            () => {
                // Validate
                let errors = [];

                let currentInputsRef = null;
                switch (this.state.selectedPartner.code) {
                    case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                        currentInputsRef = this.inputsGHNRef;
                        break;
                    case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                        currentInputsRef = this.inputsGHTKRef;
                        break;
                    case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                        currentInputsRef = this.inputsVNPOSTRef;
                        break;
                    case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
                        currentInputsRef = this.inputsViettelPostRef;
                        break;
                }

                if (currentInputsRef) {
                    const inputsRef = compact(Array.from(currentInputsRef));
                    map(inputsRef, (ref) => {
                        if (ref.validate) {
                            errors = errors.concat(ref.validate());
                        }
                    });

                    errors = compact(errors);
                    if (errors.length > 0) {
                        const errorMessage = errors.join('<br/>');
                        window.LadiUI.toastCustom('danger', '', errorMessage);
                        return;
                    }
                }

                let data;
                switch (partnerCode) {
                    case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                        const { ghnAccount, ghnMode } = this.state;

                        data = {
                            shipping_partner_code: appConfig.SHIPPING_PARTNERS.GHN.CODE,
                            ghn_account: {
                                token: ghnAccount.token,
                                shop_id: ghnAccount.shop_id,
                                client_order_code: ghnAccount.client_order_code,
                            },
                        };

                        if (baseHelper.isDevelopEnv()) {
                            data.mode = ghnMode;
                        } else {
                            data.mode = appConfig.MODE.PRODUCTION;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                        const { ghtkAccount, ghtkMode } = this.state;
                        data = {
                            shipping_partner_code: appConfig.SHIPPING_PARTNERS.GHTK.CODE,
                            ghtk_account: {
                                email: ghtkAccount.email,
                                password: ghtkAccount.password,
                            },
                        };
                        if (baseHelper.isDevelopEnv()) {
                            data.mode = ghtkMode;
                        } else {
                            data.mode = appConfig.MODE.PRODUCTION;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                        const { vnPostAccount, vnPostMode } = this.state;
                        data = {
                            shipping_partner_code: appConfig.SHIPPING_PARTNERS.VNPOST.CODE,
                            vnpost_account: {
                                ...vnPostAccount,
                            },
                        };
                        if (baseHelper.isDevelopEnv()) {
                            data.mode = vnPostMode;
                        } else {
                            data.mode = appConfig.MODE.PRODUCTION;
                        }
                        break;
                    case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
                        const { viettelPostAccount, viettelPostMode } = this.state;
                        data = {
                            shipping_partner_code: appConfig.SHIPPING_PARTNERS.VTPOST.CODE,
                            viettel_post_account: {
                                ...viettelPostAccount,
                            },
                        };
                        if (baseHelper.isDevelopEnv()) {
                            data.mode = viettelPostMode;
                        } else {
                            data.mode = appConfig.MODE.PRODUCTION;
                        }
                        break;
                    default:
                        return;
                }

                this.props.connect(data);
            }
        );
    };

    render() {
        const { selectedPartner } = this.state;
        const { t } = this.props;
        const isLoadingDisconnect =
            this.props.settingReducer.loading && settingTypes.SETTING_SHIPPING_DISCONNECT === this.props.settingReducer.waiting;
        const isLoadingInactive =
            this.props.settingReducer.loading && settingTypes.SETTING_SHIPPING_INACTIVE === this.props.settingReducer.waiting;

        const isSubmitLoadingGHN =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActiveGHN =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHN.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const isSubmitLoadingGHTK =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHTK.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActiveGHTK =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.GHTK.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const isSubmitLoadingVNPost =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VNPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActiveVNPost =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VNPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const isSubmitLoadingVTPost =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VTPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_CONNECT], this.props.settingReducer.waiting);
        const isLoadingActiveVTPost =
            selectedPartner.code == appConfig.SHIPPING_PARTNERS.VTPOST.CODE &&
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_SHIPPING_ACTIVE], this.props.settingReducer.waiting);

        const VTPostGateWay = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.VTPOST.CODE);
        const GHNGateWay = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.GHN.CODE);
        const GHTKGateWay = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.GHTK.CODE);
        const VNPOSTGateWay = this.partnerHasBeenConfigured(appConfig.SHIPPING_PARTNERS.VNPOST.CODE);

        return (
            <div className='page-setting page-general-setting-v4' id='page-setting-shipping' title={t('SETTINGS.SHIPPING')}>
                <div className='header-title'>
                    <div className='title-desc'>
                        <h3>Vận chuyển</h3>
                    </div>

                    <div className='action btn-add-new'>
                        <button
                            className={`ladiui btn btn-primary ${this.state.tabActiveSelected == 'SHIPPING_TAB_RATE' ? '' : 'hide'}`}
                            onClick={this.openModalShippingRate}
                            // style={{ opacity: `${this.state.tabActiveSelected == 'SHIPPING_TAB_RATE' ? 1 : 0}` }}
                        >
                            <img
                                className='ladiui btn-custom-img'
                                src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                alt=''
                            />
                            {t('SHIPPING_RATES.ADD_SHIPPING_RATE')}
                        </button>
                    </div>
                </div>

                <div className='mt-24'>
                    <div className='ladiui tab'>
                        <ul className='ladiui nav nav-tabs' id='myTab' role='tablist'>
                            <li className='ladiui nav-item' role='presentation'>
                                <span
                                    className='ladiui tab-link active'
                                    data-toggle='tab'
                                    data-parent='myTab'
                                    data-target='tab-service'
                                    onClick={() => this.setState({ tabActiveSelected: 'SHIPPING_TAB_SERVICE' })}
                                    id='trigger-tab-service'
                                >
                                    {t('SETTINGS.SHIPPING_TAB_SERVICE')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' role='presentation'>
                                <span
                                    className='ladiui tab-link'
                                    data-toggle='tab'
                                    data-parent='myTab'
                                    data-target='tab-shipping-rate'
                                    onClick={() => this.setState({ tabActiveSelected: 'SHIPPING_TAB_RATE' })}
                                    id='trigger-tab-rate'
                                >
                                    {t('SETTINGS.SHIPPING_TAB_RATE')}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className='ladiui-tab-content ladiui tab-content' id='myTabContent'>
                        <div className='ladiui-tab-item ladiui tab-pane fade show open' id='tab-service'>
                            <div className='list-shipping'>
                                <ItemShipping
                                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-ghn.svg'}
                                    description={t('SETTINGS.GHN_DESCRIPTION')}
                                    name='Giao hàng nhanh'
                                    isShowModal={this.state.isShowGHNSetting}
                                    dataGateWay={GHNGateWay}
                                    initData={this.state.ghnAccount}
                                    active={this.active}
                                    openDisconnectModal={this.openDisconnectModal}
                                    openInactiveModal={this.openInactiveModal}
                                    isLoadingActive={isLoadingActiveGHN}
                                    setShowModal={this.toogleGHNSetting}
                                />

                                <ItemShipping
                                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-ghtk.svg'}
                                    description={t('SETTINGS.GHTK_DESCRIPTION')}
                                    name='GHTK'
                                    isShowModal={this.state.isShowGHTKSetting}
                                    dataGateWay={GHTKGateWay}
                                    initData={this.state.ghtkAccount}
                                    active={this.active}
                                    openDisconnectModal={this.openDisconnectModal}
                                    openInactiveModal={this.openInactiveModal}
                                    isLoadingActive={isLoadingActiveGHTK}
                                    setShowModal={this.toogleGHTKSetting}
                                />

                                <ItemShipping
                                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-vnpost.svg'}
                                    description={t('SETTINGS.VNPOST_DESCRIPTION')}
                                    name='VNPost'
                                    isShowModal={this.state.isShowVNPOSTSetting}
                                    dataGateWay={VNPOSTGateWay}
                                    initData={this.state.vnPostAccount}
                                    active={this.active}
                                    openDisconnectModal={this.openDisconnectModal}
                                    openInactiveModal={this.openInactiveModal}
                                    isLoadingActive={isLoadingActiveVNPost}
                                    setShowModal={this.toogleVNPOSTSetting}
                                />

                                <ItemShipping
                                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-vtpost.svg'}
                                    description={t('SETTINGS.VIETTELPOST_DESCRIPTION')}
                                    name='Viettel Post'
                                    isShowModal={this.state.isShowViettelPostSetting}
                                    dataGateWay={VTPostGateWay}
                                    initData={this.state.viettelPostAccount}
                                    active={this.active}
                                    openDisconnectModal={this.openDisconnectModal}
                                    openInactiveModal={this.openInactiveModal}
                                    isLoadingActive={isLoadingActiveVTPost}
                                    setShowModal={this.toogleViettelPostSetting}
                                />
                            </div>
                        </div>

                        <div className='ladiui-tab-item ladiui tab-pane fade' id='tab-shipping-rate'>
                            {this.getShippingRateForm()}
                        </div>

                        {this.state.isShowGHNSetting && (
                            <ModalGHN
                                onCancel={this.toogleGHNSetting}
                                paymentGatewayConfigured={GHNGateWay}
                                ghnAccount={this.state.ghnAccount}
                                isSubmitLoading={isSubmitLoadingGHN}
                                onChangeGHNInput={this.onChangeGHNInput}
                                ghnMode={this.state.ghnMode}
                                connect={this.connect}
                                inputsGHNRef={this.inputsGHNRef}
                                setGHNModal={this.setGHNModal}
                                logo={'https://w.ladicdn.com/ladiui/ladisales/logo-ghn.svg'}
                            />
                        )}

                        {this.state.isShowGHTKSetting && (
                            <ModalGHTK
                                onCancel={this.toogleGHTKSetting}
                                paymentGatewayConfigured={GHTKGateWay}
                                ghtkAccount={this.state.ghtkAccount}
                                isSubmitLoading={isSubmitLoadingGHTK}
                                onChangeGHTKInput={this.onChangeGHTKInput}
                                ghtkMode={this.state.ghtkMode}
                                connect={this.connect}
                                inputsGHTKRef={this.inputsGHTKRef}
                                setGHTKModal={this.setGHTKModal}
                                logo={'https://w.ladicdn.com/ladiui/ladisales/logo-ghtk.svg'}
                            />
                        )}

                        {this.state.isShowVNPOSTSetting && (
                            <ModalVNPOST
                                onCancel={this.toogleVNPOSTSetting}
                                paymentGatewayConfigured={VNPOSTGateWay}
                                vnPostAccount={this.state.vnPostAccount}
                                isSubmitLoading={isSubmitLoadingVNPost}
                                onChangeVNPOSTInput={this.onChangeVNPOSTInput}
                                vnPostMode={this.state.vnPostMode}
                                connect={this.connect}
                                inputsVNPOSTRef={this.inputsVNPOSTRef}
                                setVNPOSTModal={this.setVNPOSTModal}
                                logo={'https://w.ladicdn.com/ladiui/ladisales/logo-vnpost.svg'}
                            />
                        )}

                        {this.state.isShowViettelPostSetting && (
                            <ModalVTPOST
                                onCancel={this.toogleViettelPostSetting}
                                paymentGatewayConfigured={VTPostGateWay}
                                vtPostAccount={this.state.viettelPostAccount}
                                isSubmitLoading={isSubmitLoadingVTPost}
                                onChangeViettelPostInput={this.onChangeViettelPostInput}
                                vtPostMode={this.state.viettelPostMode}
                                connect={this.connect}
                                inputsViettelPostRef={this.inputsViettelPostRef}
                                setVTPostModal={this.setVTPostModal}
                                logo={'https://w.ladicdn.com/ladiui/ladisales/logo-vtpost.svg'}
                            />
                        )}
                    </div>
                </div>

                <ConfirmModal
                    id='confirm-disconnect-shipping'
                    title={t('SETTINGS.MSG_SHIPPING_TITLE_DISCONNECT', selectedPartner.name)}
                    content={t('SETTINGS.MSG_SHIPPING_CONTENT_DISCONNECT', selectedPartner.name)}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.APPLY')}
                    onOk={() =>
                        this.props.disconnect(this.state.selectedPartner.code, this.state.selectedPartner.shipping_api_store_config_id)
                    }
                    isLoading={isLoadingDisconnect}
                    maxWidth={500}
                />

                <ConfirmModal
                    id='confirm-inactive-shipping'
                    title={t('SETTINGS.MSG_SHIPPING_TITLE_INACTIVE', selectedPartner.name)}
                    content={t('SETTINGS.MSG_SHIPPING_CONTENT_INACTIVE', selectedPartner.name)}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.APPLY')}
                    onOk={() =>
                        this.props.inactive(this.state.selectedPartner.code, this.state.selectedPartner.shipping_api_store_config_id)
                    }
                    isLoading={isLoadingInactive}
                    maxWidth={500}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: () => dispatch(staffActions.list()),
        connect: (data) => dispatch(settingActions.shippingConnect(data)),
        disconnect: (partnerCode, paymentGatewayAPIStoreConfigID) =>
            dispatch(settingActions.shippingDisconnect(partnerCode, paymentGatewayAPIStoreConfigID)),
        active: (partnerCode, paymentGatewayAPIStoreConfigID) =>
            dispatch(settingActions.shippingActive(partnerCode, paymentGatewayAPIStoreConfigID)),
        inactive: (partnerCode, paymentGatewayAPIStoreConfigID) =>
            dispatch(settingActions.shippingInactive(partnerCode, paymentGatewayAPIStoreConfigID)),
        listPartners: () => dispatch(shippingActions.listPartners()),
        listShippingRate: () => dispatch(shippingRateActions.list()),
        listStates: (countryCode) => dispatch(addressConfigActions.listStates(countryCode)),
        createShippingRate: (shippingRate) => dispatch(shippingRateActions.create(shippingRate)),
        deleteShippingRate: (shippingRateID) => dispatch(shippingRateActions.delete(shippingRateID)),
        createShippingRateDetail: (shippingRateDetail) => dispatch(shippingRateActions.createDetail(shippingRateDetail)),
        updateShippingRateDetail: (shippingRateDetail) => dispatch(shippingRateActions.updateDetail(shippingRateDetail)),
        deleteShippingRateDetail: (shippingRateDetailID) => dispatch(shippingRateActions.deleteDetail(shippingRateDetailID)),
    };
};

const mapStateToProps = (state) => ({
    settingReducer: { ...state.setting },
    shipping: { ...state.shipping },
    shippingRate: { ...state.shippingRate },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(ModalSettingShipping));
