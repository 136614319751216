import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';

const upgrade = (storeID, month, packageID) => ({
    type: types.UPGRADE_PACKAGE,
    meta: {
        endpoint: endpoint.PACKAGE_UPGRADE,
        method: REQUEST_METHOD.POST,
        body: [
            {
                store_id: storeID,
                month,
                package_id: packageID,
            }
        ],
        hasAuth: true,
    }
});

export default {
    upgrade,
}