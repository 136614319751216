import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../helpers/BaseHelper';
import LadiPagination from './LadiPagination';
import Input from './Input';
import { compact, map } from 'lodash';
// eslint-disable-next-line max-lines-per-function
let CDN = 'https://w.ladicdn.com/';
function ModalChooseTypeLesson(props) {
    const {
        t,
        onClose,
        isShow,
        onCreateNewLesson,
    } = props;
    const wrapperRef = useRef();

    const [lessonName, setLessonName] = useState('');
    const [lessonType, setLessonType] = useState('Video');

    const inputsRef = new Set();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const dispatch = useDispatch();

    const eventCancel = () => {
        onClose(false);
    };

    const handleCreateNewLesson = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        if (lessonType == 'Video') {
            onCreateNewLesson({
                name: lessonName,
                url_thumbnail: '',
                url_video: '',
                product_video_id: null,
                description: '',
                is_trial: false,
                documents: [],
                video_platform: 'Youtube',
                lesson_type: lessonType
            });
        } else if (lessonType == 'Post') {
            onCreateNewLesson({
                name: lessonName,
                post_content: '',
                description: '',
                is_trial: false,
                documents: [],
                lesson_type: lessonType
            })
        }
    };

    const onChangeLessonNameInput = (event) => {
        setLessonName(event.target.value);
    };

    const handleChooseType = (type) => {
        setLessonType(type);
    }

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-choose-type-lesson'>
            <div className='ladiui modal-dialog modal-dialog-centered' style={{ width: '400px' }} ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>Chọn loại nội dung chính</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body'>
                        <div className='choose-content-custom'>
                            <div className='ladiui form-group m-0'>
                                <label className='ladiui-label'>Tên bài giảng mới</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    name='name'
                                    validationName={'tên bài giảng'}
                                    placeholder='Nhập vào tên bài giảng'
                                    value={lessonName}
                                    onChange={onChangeLessonNameInput}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='list-box-content'>
                                <div className={`box-content ${lessonType == 'Video' ? 'active' : ''}`} onClick={() => {
                                    handleChooseType('Video')
                                }}>
                                    <img src='https://w.ladicdn.com/ladiui/icons/ldicon-video.svg' alt='' />
                                    <span className='box-name'>Video</span>
                                </div>
                                <div className={`box-content ${lessonType == 'Post' ? 'active' : ''}`} onClick={() => {
                                    handleChooseType('Post')
                                }}>
                                    <img src='https://w.ladicdn.com/ladiui/icons/ldicon-new-file.svg' alt='' />
                                    <span className='box-name'>Bài viết</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='ladiui modal-footer-custom'>
                        <div className='ladiui footer-modal-btn ls-flex ls-justify-end ls-w-full'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary bold'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.REMOVE')}
                            </button>

                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-primary bold ml-16'
                                onClick={() => handleCreateNewLesson()}
                            >
                                {t('Thêm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalChooseTypeLesson);
