export default {
    "Pacific/Pago_Pago": {
        "CODE": "Pacific/Pago_Pago",
        "NAME": "(GMT-11:00) American Samoa"
    },
    "Pacific/Midway": {
        "CODE": "Pacific/Midway",
        "NAME": "(GMT-11:00) Midway Island"
    },
    "Pacific/Honolulu": {
        "CODE": "Pacific/Honolulu",
        "NAME": "(GMT-10:00) Hawaii"
    },
    "America/Juneau": {
        "CODE": "America/Juneau",
        "NAME": "(GMT-09:00) Alaska"
    },
    "America/Los_Angeles": {
        "CODE": "America/Los_Angeles",
        "NAME": "(GMT-08:00) Pacific Time (US & Canada)"
    },
    "America/Tijuana": {
        "CODE": "America/Tijuana",
        "NAME": "(GMT-08:00) Tijuana"
    },
    "America/Phoenix": {
        "CODE": "America/Phoenix",
        "NAME": "(GMT-07:00) Arizona"
    },
    "America/Chihuahua": {
        "CODE": "America/Chihuahua",
        "NAME": "(GMT-07:00) Chihuahua"
    },
    "America/Mazatlan": {
        "CODE": "America/Mazatlan",
        "NAME": "(GMT-07:00) Mazatlan"
    },
    "America/Denver": {
        "CODE": "America/Denver",
        "NAME": "(GMT-07:00) Mountain Time (US & Canada)"
    },
    "America/Guatemala": {
        "CODE": "America/Guatemala",
        "NAME": "(GMT-06:00) Central America"
    },
    "America/Chicago": {
        "CODE": "America/Chicago",
        "NAME": "(GMT-06:00) Central Time (US & Canada)"
    },
    "America/Mexico_City": {
        "CODE": "America/Mexico_City",
        "NAME": "(GMT-06:00) Guadalajara, Mexico City"
    },
    "America/Monterrey": {
        "CODE": "America/Monterrey",
        "NAME": "(GMT-06:00) Monterrey"
    },
    "America/Regina": {
        "CODE": "America/Regina",
        "NAME": "(GMT-06:00) Saskatchewan"
    },
    "America/Bogota": {
        "CODE": "America/Bogota",
        "NAME": "(GMT-05:00) Bogota"
    },
    "America/New_York": {
        "CODE": "America/New_York",
        "NAME": "(GMT-05:00) Eastern Time (US & Canada)"
    },
    "America/Indiana/Indianapolis": {
        "CODE": "America/Indiana/Indianapolis",
        "NAME": "(GMT-05:00) Indiana (East)"
    },
    "America/Lima": {
        "CODE": "America/Lima",
        "NAME": "(GMT-05:00) Lima, Quito"
    },
    "America/Halifax": {
        "CODE": "America/Halifax",
        "NAME": "(GMT-04:00) Atlantic Time (Canada)"
    },
    "America/Caracas": {
        "CODE": "America/Caracas",
        "NAME": "(GMT-04:00) Caracas"
    },
    "America/Guyana": {
        "CODE": "America/Guyana",
        "NAME": "(GMT-04:00) Georgetown"
    },
    "America/La_Paz": {
        "CODE": "America/La_Paz",
        "NAME": "(GMT-04:00) La Paz"
    },
    "America/Puerto_Rico": {
        "CODE": "America/Puerto_Rico",
        "NAME": "(GMT-04:00) Puerto Rico"
    },
    "America/Santiago": {
        "CODE": "America/Santiago",
        "NAME": "(GMT-04:00) Santiago"
    },
    "America/St_Johns": {
        "CODE": "America/St_Johns",
        "NAME": "(GMT-03:30) Newfoundland"
    },
    "America/Sao_Paulo": {
        "CODE": "America/Sao_Paulo",
        "NAME": "(GMT-03:00) Brasilia"
    },
    "America/Argentina/Buenos_Aires": {
        "CODE": "America/Argentina/Buenos_Aires",
        "NAME": "(GMT-03:00) Buenos Aires"
    },
    "America/Godthab": {
        "CODE": "America/Godthab",
        "NAME": "(GMT-03:00) Greenland"
    },
    "America/Montevideo": {
        "CODE": "America/Montevideo",
        "NAME": "(GMT-03:00) Montevideo"
    },
    "Atlantic/South_Georgia": {
        "CODE": "Atlantic/South_Georgia",
        "NAME": "(GMT-02:00) Mid-Atlantic"
    },
    "Atlantic/Azores": {
        "CODE": "Atlantic/Azores",
        "NAME": "(GMT-01:00) Azores"
    },
    "Atlantic/Cape_Verde": {
        "CODE": "Atlantic/Cape_Verde",
        "NAME": "(GMT-01:00) Cape Verde Is."
    },
    "Europe/London": {
        "CODE": "Europe/London",
        "NAME": "(GMT+00:00) Edinburgh, London"
    },
    "Europe/Lisbon": {
        "CODE": "Europe/Lisbon",
        "NAME": "(GMT+00:00) Lisbon"
    },
    "Africa/Monrovia": {
        "CODE": "Africa/Monrovia",
        "NAME": "(GMT+00:00) Monrovia"
    },
    "Etc/UTC": {
        "CODE": "Etc/UTC",
        "NAME": "(GMT+00:00) UTC"
    },
    "Europe/Amsterdam": {
        "CODE": "Europe/Amsterdam",
        "NAME": "(GMT+01:00) Amsterdam"
    },
    "Europe/Belgrade": {
        "CODE": "Europe/Belgrade",
        "NAME": "(GMT+01:00) Belgrade"
    },
    "Europe/Berlin": {
        "CODE": "Europe/Berlin",
        "NAME": "(GMT+01:00) Berlin"
    },
    "Europe/Zurich": {
        "CODE": "Europe/Zurich",
        "NAME": "(GMT+01:00) Bern, Zurich"
    },
    "Europe/Bratislava": {
        "CODE": "Europe/Bratislava",
        "NAME": "(GMT+01:00) Bratislava"
    },
    "Europe/Brussels": {
        "CODE": "Europe/Brussels",
        "NAME": "(GMT+01:00) Brussels"
    },
    "Europe/Budapest": {
        "CODE": "Europe/Budapest",
        "NAME": "(GMT+01:00) Budapest"
    },
    "Africa/Casablanca": {
        "CODE": "Africa/Casablanca",
        "NAME": "(GMT+00:00) Casablanca"
    },
    "Europe/Copenhagen": {
        "CODE": "Europe/Copenhagen",
        "NAME": "(GMT+01:00) Copenhagen"
    },
    "Europe/Dublin": {
        "CODE": "Europe/Dublin",
        "NAME": "(GMT+00:00) Dublin"
    },
    "Europe/Ljubljana": {
        "CODE": "Europe/Ljubljana",
        "NAME": "(GMT+01:00) Ljubljana"
    },
    "Europe/Madrid": {
        "CODE": "Europe/Madrid",
        "NAME": "(GMT+01:00) Madrid"
    },
    "Europe/Paris": {
        "CODE": "Europe/Paris",
        "NAME": "(GMT+01:00) Paris"
    },
    "Europe/Prague": {
        "CODE": "Europe/Prague",
        "NAME": "(GMT+01:00) Prague"
    },
    "Europe/Rome": {
        "CODE": "Europe/Rome",
        "NAME": "(GMT+01:00) Rome"
    },
    "Europe/Sarajevo": {
        "CODE": "Europe/Sarajevo",
        "NAME": "(GMT+01:00) Sarajevo"
    },
    "Europe/Skopje": {
        "CODE": "Europe/Skopje",
        "NAME": "(GMT+01:00) Skopje"
    },
    "Europe/Stockholm": {
        "CODE": "Europe/Stockholm",
        "NAME": "(GMT+01:00) Stockholm"
    },
    "Europe/Vienna": {
        "CODE": "Europe/Vienna",
        "NAME": "(GMT+01:00) Vienna"
    },
    "Europe/Warsaw": {
        "CODE": "Europe/Warsaw",
        "NAME": "(GMT+01:00) Warsaw"
    },
    "Africa/Algiers": {
        "CODE": "Africa/Algiers",
        "NAME": "(GMT+01:00) West Central Africa"
    },
    "Europe/Zagreb": {
        "CODE": "Europe/Zagreb",
        "NAME": "(GMT+01:00) Zagreb"
    },
    "Europe/Athens": {
        "CODE": "Europe/Athens",
        "NAME": "(GMT+02:00) Athens"
    },
    "Europe/Bucharest": {
        "CODE": "Europe/Bucharest",
        "NAME": "(GMT+02:00) Bucharest"
    },
    "Africa/Cairo": {
        "CODE": "Africa/Cairo",
        "NAME": "(GMT+02:00) Cairo"
    },
    "Africa/Harare": {
        "CODE": "Africa/Harare",
        "NAME": "(GMT+02:00) Harare"
    },
    "Europe/Helsinki": {
        "CODE": "Europe/Helsinki",
        "NAME": "(GMT+02:00) Helsinki"
    },
    "Asia/Jerusalem": {
        "CODE": "Asia/Jerusalem",
        "NAME": "(GMT+02:00) Jerusalem"
    },
    "Europe/Kaliningrad": {
        "CODE": "Europe/Kaliningrad",
        "NAME": "(GMT+02:00) Kaliningrad"
    },
    "Europe/Kiev": {
        "CODE": "Europe/Kiev",
        "NAME": "(GMT+02:00) Kyiv"
    },
    "Africa/Johannesburg": {
        "CODE": "Africa/Johannesburg",
        "NAME": "(GMT+02:00) Pretoria"
    },
    "Europe/Riga": {
        "CODE": "Europe/Riga",
        "NAME": "(GMT+02:00) Riga"
    },
    "Europe/Sofia": {
        "CODE": "Europe/Sofia",
        "NAME": "(GMT+02:00) Sofia"
    },
    "Europe/Tallinn": {
        "CODE": "Europe/Tallinn",
        "NAME": "(GMT+02:00) Tallinn"
    },
    "Europe/Vilnius": {
        "CODE": "Europe/Vilnius",
        "NAME": "(GMT+02:00) Vilnius"
    },
    "Asia/Baghdad": {
        "CODE": "Asia/Baghdad",
        "NAME": "(GMT+03:00) Baghdad"
    },
    "Europe/Istanbul": {
        "CODE": "Europe/Istanbul",
        "NAME": "(GMT+03:00) Istanbul"
    },
    "Asia/Kuwait": {
        "CODE": "Asia/Kuwait",
        "NAME": "(GMT+03:00) Kuwait"
    },
    "Europe/Minsk": {
        "CODE": "Europe/Minsk",
        "NAME": "(GMT+03:00) Minsk"
    },
    "Europe/Moscow": {
        "CODE": "Europe/Moscow",
        "NAME": "(GMT+03:00) Moscow, St. Petersburg"
    },
    "Africa/Nairobi": {
        "CODE": "Africa/Nairobi",
        "NAME": "(GMT+03:00) Nairobi"
    },
    "Asia/Riyadh": {
        "CODE": "Asia/Riyadh",
        "NAME": "(GMT+03:00) Riyadh"
    },
    "Asia/Tehran": {
        "CODE": "Asia/Tehran",
        "NAME": "(GMT+03:30) Tehran"
    },
    "Asia/Muscat": {
        "CODE": "Asia/Muscat",
        "NAME": "(GMT+04:00) Abu Dhabi, Muscat"
    },
    "Asia/Baku": {
        "CODE": "Asia/Baku",
        "NAME": "(GMT+04:00) Baku"
    },
    "Europe/Samara": {
        "CODE": "Europe/Samara",
        "NAME": "(GMT+04:00) Samara"
    },
    "Asia/Tbilisi": {
        "CODE": "Asia/Tbilisi",
        "NAME": "(GMT+04:00) Tbilisi"
    },
    "Europe/Volgograd": {
        "CODE": "Europe/Volgograd",
        "NAME": "(GMT+03:00) Volgograd"
    },
    "Asia/Yerevan": {
        "CODE": "Asia/Yerevan",
        "NAME": "(GMT+04:00) Yerevan"
    },
    "Asia/Kabul": {
        "CODE": "Asia/Kabul",
        "NAME": "(GMT+04:30) Kabul"
    },
    "Asia/Yekaterinburg": {
        "CODE": "Asia/Yekaterinburg",
        "NAME": "(GMT+05:00) Ekaterinburg"
    },
    "Asia/Karachi": {
        "CODE": "Asia/Karachi",
        "NAME": "(GMT+05:00) Islamabad, Karachi"
    },
    "Asia/Tashkent": {
        "CODE": "Asia/Tashkent",
        "NAME": "(GMT+05:00) Tashkent"
    },
    "Asia/Kolkata": {
        "CODE": "Asia/Kolkata",
        "NAME": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    "Asia/Colombo": {
        "CODE": "Asia/Colombo",
        "NAME": "(GMT+05:30) Sri Jayawardenepura"
    },
    "Asia/Kathmandu": {
        "CODE": "Asia/Kathmandu",
        "NAME": "(GMT+05:45) Kathmandu"
    },
    "Asia/Almaty": {
        "CODE": "Asia/Almaty",
        "NAME": "(GMT+06:00) Almaty"
    },
    "Asia/Dhaka": {
        "CODE": "Asia/Dhaka",
        "NAME": "(GMT+06:00) Astana, Dhaka"
    },
    "Asia/Urumqi": {
        "CODE": "Asia/Urumqi",
        "NAME": "(GMT+06:00) Urumqi"
    },
    "Asia/Rangoon": {
        "CODE": "Asia/Rangoon",
        "NAME": "(GMT+06:30) Rangoon"
    },
    "Asia/Bangkok": {
        "CODE": "Asia/Bangkok",
        "NAME": "(GMT+07:00) Bangkok, Hanoi"
    },
    "Asia/Jakarta": {
        "CODE": "Asia/Jakarta",
        "NAME": "(GMT+07:00) Jakarta"
    },
    "Asia/Krasnoyarsk": {
        "CODE": "Asia/Krasnoyarsk",
        "NAME": "(GMT+07:00) Krasnoyarsk"
    },
    "Asia/Novosibirsk": {
        "CODE": "Asia/Novosibirsk",
        "NAME": "(GMT+07:00) Novosibirsk"
    },
    "Asia/Shanghai": {
        "CODE": "Asia/Shanghai",
        "NAME": "(GMT+08:00) Beijing"
    },
    "Asia/Chongqing": {
        "CODE": "Asia/Chongqing",
        "NAME": "(GMT+08:00) Chongqing"
    },
    "Asia/Hong_Kong": {
        "CODE": "Asia/Hong_Kong",
        "NAME": "(GMT+08:00) Hong Kong"
    },
    "Asia/Irkutsk": {
        "CODE": "Asia/Irkutsk",
        "NAME": "(GMT+08:00) Irkutsk"
    },
    "Asia/Kuala_Lumpur": {
        "CODE": "Asia/Kuala_Lumpur",
        "NAME": "(GMT+08:00) Kuala Lumpur"
    },
    "Australia/Perth": {
        "CODE": "Australia/Perth",
        "NAME": "(GMT+08:00) Perth"
    },
    "Asia/Singapore": {
        "CODE": "Asia/Singapore",
        "NAME": "(GMT+08:00) Singapore"
    },
    "Asia/Taipei": {
        "CODE": "Asia/Taipei",
        "NAME": "(GMT+08:00) Taipei"
    },
    "Asia/Ulaanbaatar": {
        "CODE": "Asia/Ulaanbaatar",
        "NAME": "(GMT+08:00) Ulaanbaatar"
    },
    "Asia/Tokyo": {
        "CODE": "Asia/Tokyo",
        "NAME": "(GMT+09:00) Osaka, Sapporo, Tokyo"
    },
    "Asia/Seoul": {
        "CODE": "Asia/Seoul",
        "NAME": "(GMT+09:00) Seoul"
    },
    "Asia/Yakutsk": {
        "CODE": "Asia/Yakutsk",
        "NAME": "(GMT+09:00) Yakutsk"
    },
    "Australia/Adelaide": {
        "CODE": "Australia/Adelaide",
        "NAME": "(GMT+09:30) Adelaide"
    },
    "Australia/Darwin": {
        "CODE": "Australia/Darwin",
        "NAME": "(GMT+09:30) Darwin"
    },
    "Australia/Brisbane": {
        "CODE": "Australia/Brisbane",
        "NAME": "(GMT+10:00) Brisbane"
    },
    "Australia/Melbourne": {
        "CODE": "Australia/Melbourne",
        "NAME": "(GMT+10:00) Canberra, Melbourne"
    },
    "Pacific/Guam": {
        "CODE": "Pacific/Guam",
        "NAME": "(GMT+10:00) Guam"
    },
    "Australia/Hobart": {
        "CODE": "Australia/Hobart",
        "NAME": "(GMT+10:00) Hobart"
    },
    "Pacific/Port_Moresby": {
        "CODE": "Pacific/Port_Moresby",
        "NAME": "(GMT+10:00) Port Moresby"
    },
    "Australia/Sydney": {
        "CODE": "Australia/Sydney",
        "NAME": "(GMT+10:00) Sydney"
    },
    "Asia/Vladivostok": {
        "CODE": "Asia/Vladivostok",
        "NAME": "(GMT+10:00) Vladivostok"
    },
    "Asia/Magadan": {
        "CODE": "Asia/Magadan",
        "NAME": "(GMT+11:00) Magadan"
    },
    "Pacific/Noumea": {
        "CODE": "Pacific/Noumea",
        "NAME": "(GMT+11:00) New Caledonia"
    },
    "Pacific/Guadalcanal": {
        "CODE": "Pacific/Guadalcanal",
        "NAME": "(GMT+11:00) Solomon Is."
    },
    "Asia/Srednekolymsk": {
        "CODE": "Asia/Srednekolymsk",
        "NAME": "(GMT+11:00) Srednekolymsk"
    },
    "Pacific/Auckland": {
        "CODE": "Pacific/Auckland",
        "NAME": "(GMT+12:00) Auckland, Wellington"
    },
    "Pacific/Fiji": {
        "CODE": "Pacific/Fiji",
        "NAME": "(GMT+12:00) Fiji"
    },
    "Asia/Kamchatka": {
        "CODE": "Asia/Kamchatka",
        "NAME": "(GMT+12:00) Kamchatka"
    },
    "Pacific/Majuro": {
        "CODE": "Pacific/Majuro",
        "NAME": "(GMT+12:00) Marshall Is."
    },
    "Pacific/Chatham": {
        "CODE": "Pacific/Chatham",
        "NAME": "(GMT+12:45) Chatham Is."
    },
    "Pacific/Tongatapu": {
        "CODE": "Pacific/Tongatapu",
        "NAME": "(GMT+13:00) Nuku'alofa"
    },
    "Pacific/Apia": {
        "CODE": "Pacific/Apia",
        "NAME": "(GMT+13:00) Samoa"
    },
    "Pacific/Fakaofo": {
        "CODE": "Pacific/Fakaofo",
        "NAME": "(GMT+13:00) Tokelau Is."
    }
}