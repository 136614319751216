import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as customerTypes from '../../../../redux/futures/customer/types';
import customerActions from '../../../../redux/futures/customer/actions';

import * as ticketSeatTypes from '../../../../redux/futures/ticket_seat/types';
import ticketSeatActions from '../../../../redux/futures/ticket_seat/actions';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalTicketSeatEditCustomer(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible, hideModal, productSelected = {} } = props;

    const ticketSeatReducer = useSelector((state) => state.ticketSeatReducer);

    const loading = useMemo(() => {
        return ticketSeatReducer.loading || false;
    }, [ticketSeatReducer.loading]);

    const [customer, setCustomer] = useState('');

    useEffect(() => {
        if (ticketSeatReducer.action == ticketSeatTypes.SHOW_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                setCustomer(ticketSeatReducer.ticketSeat);
            }
        }
    }, [ticketSeatReducer.action]);

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        dispatch(ticketSeatActions.update(customer));
    };

    const customerEdit = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setCustomer((pre) => {
            let tg = { ...pre };
            tg[name] = value;
            return tg;
        });
    };

    return (
        <Modal
            width={800}
            id='modal-ticket-edit-customer'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('TICKET_SEAT.UPDATE_CUSTOMER')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('ACTIONS.SAVE')}
            isLoading={loading}
            position_unset={true}
        >
            <div className='content-ticket-edit-customer content-customer-create'>
                <div>
                    <label>
                        {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE
                            ? t('TICKET_SEAT.CODE')
                            : t('PRODUCT_SERVICES.SKU')}
                    </label>
                    <Input className='mt-4' type='text' name='first_name' value={customer.code} disabled={true} onChange={() => {}} />
                </div>

                <div className='mt-16 customer-email-phone'>
                    <div className='item'>
                        <label>{t('COMMON.FIRST_NAME')}</label>
                        <Input
                            className='mt-4'
                            type='text'
                            name='first_name'
                            value={customer.first_name}
                            onChange={customerEdit}
                            placeholder={t('COMMON.FIRST_NAME')}
                        />
                    </div>
                    <div className='item'>
                        <label>{t('COMMON.LAST_NAME')}</label>
                        <Input
                            className='mt-4'
                            type='text'
                            name='last_name'
                            value={customer.last_name}
                            onChange={customerEdit}
                            placeholder={t('COMMON.LAST_NAME')}
                        />
                    </div>
                </div>

                <div className='mt-16 customer-email-phone'>
                    <div className='item'>
                        <label>{t('COMMON.EMAIL')}</label>
                        <Input className='mt-4' type='text' name='email' value={customer.email} onChange={customerEdit} />
                    </div>
                    <div className='item'>
                        <label>{t('COMMON.PHONE')}</label>
                        <Input
                            className='mt-4'
                            type='text'
                            name='phone'
                            value={customer.phone}
                            onChange={customerEdit}
                            placeholder='Vd: 0981111111'
                        />
                    </div>
                </div>

                <div className='mt-16'>
                    <label>{t('CUSTOMERS.ADDRESS')}</label>
                    <Input className='mt-4' type='text' name='address' value={customer.address} onChange={customerEdit} />
                </div>
            </div>
        </Modal>
    );
}

export default ModalTicketSeatEditCustomer;
