import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import baseHelper from '../../../helpers/BaseHelper';

import * as checkoutConfigTypes from '../../../redux/futures/checkout_config/types';
import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';

import * as productTypes from '../../../redux/futures/product/types';
import productActions from '../../../redux/futures/product/actions';

import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';
import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';

import Dropdown from '../../../components/Dropdown';
import config from '../../../config/config';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCreateSalesChannel(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { visible, hideModal } = props;

    // const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
    const productReducer = useSelector((state) => state.product);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    // const checkout_configs = useMemo(() => {
    //     return checkoutConfigReducer.checkout_configs || [];
    // }, [checkoutConfigReducer.checkout_configs]);

    const products = useMemo(() => {
        return productReducer.products || [];
    }, [productReducer.products]);

    const [pageCheckout, setPageCheckout] = useState({
        name: '',
        types: appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE,
        is_one_page_checkout: true,
        // checkout_config_id: null,
    });

    const [productSelected, setProductSelected] = useState(null);

    const loading = useMemo(() => {
        return pageCheckoutReducer.loading || false;
    }, [pageCheckoutReducer.loading]);

    useEffect(() => {
        // dispatch(checkoutConfigActions.list());
        dispatch(productActions.searchV2({}));
    }, []);

    useEffect(() => {
        if (queryStrings) {
            let type_create = queryStrings.get('type_create');
            let product_id = '';

            if (type_create && type_create != appConfig.PAGE_CHECKOUT_TYPE.STORE) {
                type_create = appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT;
                product_id = queryStrings.get('product_id');
                if (product_id) setProductSelected(Number(product_id));
            }
            let tab = queryStrings.get('tab');

            if (!type_create) {
                if (tab == 0) type_create = appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT;
                else {
                    type_create = appConfig.PAGE_CHECKOUT_TYPE.STORE;
                }
            }

            setPageCheckout((pre) => ({
                ...pre,
                types: type_create,
            }));
        }
    }, [queryStrings]);

    useEffect(() => {
        if (products) {
            let productsTg = products[0] || {};
            setProductSelected((pre) => {
                if (pre) return pre;
                return productsTg.product_id;
            });
        }
    }, [products]);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                let page_checkout = pageCheckoutReducer.page_checkout || {};

                if (page_checkout.page_checkout_id) {
                    let is_iframe = true;
                    if (!is_iframe) history.push(`/page-checkout/update/${page_checkout.page_checkout_id}`);
                    else {
                        window.open(`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(page_checkout.page_checkout_id)}`);
                        hideModal();
                    }
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer.action]);

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        if (!pageCheckout.name) {
            window.LadiUI.toastCustom('danger', '', t('SALE_CHANNEL.PAGE_NAME_EMPTY'));
            return;
        }

        let dataSend = {
            page_checkout: {
                ...pageCheckout,
                product_ids: [productSelected],
            },
        };

        dispatch(pageCheckoutActions.create(dataSend));
    };

    return (
        <Modal
            id='modal-create-sales-channel'
            title={t('SALE_CHANNEL.ADD_NEW')}
            sub_title={t('SALE_CHANNEL.SUB_TITLE')}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            width={800}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('SALE_CHANNEL.ADD_NEW')}
            isLoading={loading}
            position_unset={true}
        >
            <div>
                <div>
                    <label className='ladiui text-14'>{t('SALE_CHANNEL.PAGE_NAME')}</label>
                    <Input
                        className='mt-4'
                        placeholder={t('SALE_CHANNEL.PAGE_NAME_PLH')}
                        value={pageCheckout.name}
                        onChange={(e) => {
                            setPageCheckout({
                                ...pageCheckout,
                                name: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className='mt-20'>
                    <label className='ladiui text-14'>{t('SALE_CHANNEL.PAGE_TYPE')}</label>
                    <div className='mt-8 ladiui flex-center-between gap-20'>
                        <div
                            className={`ladiui selected-box-tem  ${pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE ? 'active' : ''
                                }`}
                            onClick={() => {
                                setPageCheckout({
                                    ...pageCheckout,
                                    types: appConfig.PAGE_CHECKOUT.STORE.CODE,
                                });
                            }}
                        >
                            <div className=' box-icon'>
                                <i className='ladiui icon-new new-ldicon-storefront' />
                            </div>
                            <div className='box-title'>
                                <div className='ladiui label-16'>{t('PAGE_CHECKOUT_V2.Store')}</div>
                                <p className='mt-8 ladiui text-14'>{t('SALE_CHANNEL.STORE_CREATE_SUB')}</p>
                            </div>
                        </div>
                        <div
                            className={`ladiui selected-box-tem ${pageCheckout.types == appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE ? 'active' : ''
                                }`}
                            onClick={() => {
                                setPageCheckout({
                                    ...pageCheckout,
                                    types: appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE,
                                });
                            }}
                        >
                            <div className=' box-icon'>
                                <i className='ladiui icon-new new-ldicon-box' />
                            </div>
                            <div className='box-title'>
                                <div className='ladiui label-16'>{t('MENU.PAGE_CHECKOUT')}</div>
                                <p className='mt-8 ladiui text-14'>{t('SALE_CHANNEL.PRODUCT_PAGE_CREATE_SUB')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                {pageCheckout.types == appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE && (
                    <div className='mt-20'>
                        <div className='ladiui text-14'>{t('MENU.PRODUCTS')}</div>
                        <Dropdown
                            wrapperClassName='mt-4 w-100-p'
                            position_unset={true}
                            widthListCustom='calc(100% - 48px)'
                            data={products}
                            _key='product_id'
                            _name='name'
                            currentKey={productSelected}
                            onSelectItem={(item) => {
                                setProductSelected(item.product_id);
                            }}
                            isShowBlockInputSearch={true}
                            isDropdownSearchCallApi={true}
                            handleSearchData={(value) => {
                                dispatch(productActions.searchV2({ search: value }));
                            }}
                            selectedItemClass="ellipsis w-100-p"
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ModalCreateSalesChannel;
