import React from "react";
import WidgetStyle from "../../../config/WidgetStyle";
import PropTypes from "prop-types";
import { values } from "lodash";

class Frame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      widget_position: "",
      bottom_distance: 0,
      side_distance: 0,
    };

    this.openSVG = `<svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Protype" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path d="M19.5,21 C19.875,21 20.21875,20.84375 20.53125,20.53125 C20.84375,20.21875 21,19.875 21,19.5 C21,19.125 20.84375,18.78125 20.53125,18.46875 L12.5625,10.5 L20.53125,2.53125 C20.84375,2.21875 21,1.875 21,1.5 C21,1.125 20.84375,0.78125 20.53125,0.46875 C20.21875,0.15625 19.875,0 19.5,0 C19.125,0 18.78125,0.15625 18.46875,0.46875 L10.5,8.4375 L2.53125,0.46875 C2.21875,0.15625 1.875,0 1.5,0 C1.125,0 0.78125,0.15625 0.46875,0.46875 C0.15625,0.78125 0,1.125 0,1.5 C0,1.875 0.15625,2.21875 0.46875,2.53125 L8.4375,10.5 L0.46875,18.46875 C0.15625,18.78125 0,19.125 0,19.5 C0,19.875 0.15625,20.21875 0.46875,20.53125 C0.78125,20.84375 1.125,21 1.5,21 C1.875,21 2.21875,20.84375 2.53125,20.53125 L10.5,12.5625 L18.46875,20.53125 C18.78125,20.84375 19.125,21 19.5,21 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
        </svg>`;

    this.closeSVG = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.89246 20.7505C6.4053 20.7505 6.82104 20.3347 6.82104 19.8219C6.82104 19.3091 6.4053 18.8933 5.89246 18.8933C5.37961 18.8933 4.96387 19.3091 4.96387 19.8219C4.96387 20.3347 5.37961 20.7505 5.89246 20.7505Z" fill="white"/>
    <path d="M18.8915 20.7505C19.4043 20.7505 19.8201 20.3347 19.8201 19.8219C19.8201 19.3091 19.4043 18.8933 18.8915 18.8933C18.3786 18.8933 17.9629 19.3091 17.9629 19.8219C17.9629 20.3347 18.3786 20.7505 18.8915 20.7505Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.89246 19.6433C5.79382 19.6433 5.71387 19.7233 5.71387 19.8219C5.71387 19.9205 5.79382 20.0005 5.89246 20.0005C5.99109 20.0005 6.07104 19.9205 6.07104 19.8219C6.07104 19.7233 5.99109 19.6433 5.89246 19.6433ZM4.21387 19.8219C4.21387 18.8948 4.9654 18.1433 5.89246 18.1433C6.81952 18.1433 7.57105 18.8948 7.57105 19.8219C7.57105 20.749 6.81952 21.5005 5.89246 21.5005C4.9654 21.5005 4.21387 20.749 4.21387 19.8219Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8915 19.6433C18.7928 19.6433 18.7129 19.7233 18.7129 19.8219C18.7129 19.9205 18.7928 20.0005 18.8915 20.0005C18.9901 20.0005 19.0701 19.9205 19.0701 19.8219C19.0701 19.7233 18.9901 19.6433 18.8915 19.6433ZM17.2129 19.8219C17.2129 18.8948 17.9644 18.1433 18.8915 18.1433C19.8185 18.1433 20.5701 18.8948 20.5701 19.8219C20.5701 20.749 19.8185 21.5005 18.8915 21.5005C17.9644 21.5005 17.2129 20.749 17.2129 19.8219Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.499023 1.25C0.499023 0.835786 0.83481 0.5 1.24902 0.5H4.03479C4.41069 0.5 4.72853 0.778273 4.77821 1.15088L5.31049 5.14294H20.7494C20.9741 5.14294 21.1869 5.24369 21.3294 5.41745C21.4719 5.59122 21.5289 5.8197 21.4848 6.04003L19.6276 15.3259C19.5575 15.6765 19.2497 15.9288 18.8922 15.9288H5.89197C5.51607 15.9288 5.19823 15.6506 5.14855 15.278L3.37815 2H1.24902C0.83481 2 0.499023 1.66421 0.499023 1.25ZM5.51049 6.64294L6.5486 14.4288H18.2774L19.8345 6.64294H5.51049Z" fill="white"/>
    </svg>
    `;
  }

  static propTypes = {
    content: PropTypes.string.isRequired,
    stylesheets: PropTypes.arrayOf(PropTypes.string),
  };

  /**
   * Called after mounting the component. Triggers initial update of
   * the iframe
   */
  componentDidMount() {
    this._updateIframe();
  }

  /**
   * Called each time the props changes. Triggers an update of the iframe to
   * pass the new content
   */
  componentDidUpdate() {
    this._updateIframe();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.data != nextProps.data) {
      this.setState({
        ...this.state,
        widget_position: nextProps.data.widget_position,
        bottom_distance: parseInt(nextProps.data.bottom_distance),
        side_distance: parseInt(nextProps.data.side_distance),
      });
    }
  }

  addEvent = () => {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const btn = document.getElementById("ladichat-widget-open");

    const iconClose = document.getElementById("icon-close-cart-widget");

    if (btn) {
      btn.addEventListener("click", () => {
        this.showCartWidget();
      });
    }

    if (iconClose) {
      iconClose.addEventListener("click", () => {
        this.showCartWidget();
      });
    }

    const input = document.getElementById("ladichat-typing-message");
    if (input) {
      input.addEventListener("paste", (e) => this.onPaste(e));
    }
  };

  onPaste = (ev) => {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    ev.preventDefault();
    var text = ev.clipboardData.getData("text");
    document.execCommand("insertText", false, text);
  };
  /**
   * Updates the iframes content and inserts stylesheets.
   * TODO: Currently stylesheets are just added for proof of concept. Implement
   * and algorithm which updates the stylesheets properly.
   */
  _updateIframe() {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const head = document.getElementsByTagName("head")[0];
    document.body.innerHTML = this.props.content;
    head.innerHTML = "";
    this.props.stylesheets.forEach((url) => {
      const ref = document.createElement("link");
      ref.rel = "stylesheet";
      ref.type = "text/css";
      ref.href = url;
      head.appendChild(ref);
    });
    const style = document.createElement("style");
    style.setAttribute("id", "style-ladichat");
    style.textContent = WidgetStyle.renderCss(this.props.data);
    head.appendChild(style);
    this.addEvent();
    const el = document.getElementById("ladichat-widget-container");
    const btn = document.getElementById("ladichat-widget-open");

    if (el.classList.contains("is-visible")) {
      el.classList.remove("is-visible");
      btn.classList.remove("open");
      btn.classList.add("close");
      btn.innerHTML = this.closeSVG;
    } else {
      el.classList.add("is-visible");
      btn.classList.remove("close");
      btn.classList.add("open");
      btn.innerHTML = this.openSVG;
    }
    const formPopup = document.getElementById("ladichat-form-popup-body");
    const headerWelcome = document.getElementById("header-welcome");

    if (formPopup && headerWelcome) {
      let height = headerWelcome.getBoundingClientRect().height;
      height = height + 35;
      formPopup.style.height = `calc(100% - ${height}px)`;
    }
  }

  showCartWidget() {
    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const el = document.getElementById("ladichat-widget-container");
    const btn = document.getElementById("ladichat-widget-open");

    if (el.classList.contains("is-visible")) {
      el.classList.remove("is-visible");
      btn.classList.remove("open");
      btn.classList.add("close");
      btn.innerHTML = this.closeSVG;
    } else {
      el.classList.add("is-visible");
      btn.classList.remove("close");
      btn.classList.add("open");
      btn.innerHTML = this.openSVG;
    }
    // this.setState({ isOpen: true });
  }

  /**
   * This component renders just and iframe
   */
  render() {
    let { widget_position, bottom_distance, side_distance } = this.state;
    let customStyle = {};

    if (widget_position && widget_position == "left") {
      customStyle = {
        paddingBottom: bottom_distance,
        paddingLeft: side_distance,
      };
    }

    if (widget_position && widget_position == "right") {
      customStyle = {
        paddingBottom: bottom_distance,
        paddingRight: side_distance,
      };
    }
    return <iframe ref="iframe" className="ladisales-widget-frame" style={customStyle} />;
  }
}

export default Frame;
