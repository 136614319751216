import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import shippingActions from '../../redux/futures/shipping/actions';
import { includes, map, remove, find } from 'lodash';
import moment from 'moment';

import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import baseHelper from '../../helpers/BaseHelper';

import * as types from '../../redux/futures/shipping/types';

import * as printTypes from '../../redux/futures/print/types';
import printActions from '../../redux/futures/print/actions';

import * as filterTypes from '../../redux/futures/filter/types';
import filterActions from '../../redux/futures/filter/actions';

import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../components/ConfirmModal';
import { appLocalStorage } from '../../localforage';
import SelectOption from '../../components/SelectOption';
import LoadingScene from '../../components/LoadingScene';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import LadiPagination from '../../components/LadiPagination';

import FilterConditionShipping from './components/FilterConditionShipping';
import FilterConditionOrderv2 from '../orders/components/FilterCondition/FilterConditionOrderv2';
import config from '../../config/config';

class Shipping extends React.Component {
    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.tabs = {
            all: 'ALL',
            paymentPending: 'PAYMENT_PENDING',
            paymentReceived: 'PAYMENT_RECEIVED',
            shippingReady: 'SHIPPING_READY',
            search: 'SEARCH',
        };
        this.state = {
            activePage: 1,
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
            dataConditions: {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            },
            searchStatus: '',
            searchName: '',
            searchPartner: '',
            searchPaymentStatus: '',
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentShipping: {},
            mode: '',
            userInfo: {},
            fromDate: null,
            toDate: null,
            conditions: defaultConditions,
            selectedIDs: [],
            tabSelected: this.tabs.all,
            totalAmount: 0,
            isShowFilterCondition: false,
            isShowModalSaveCondition: false,
            filterCustom: [],
            filterCustomShow: [],
            filterCustomDropdown: [],
            search_v2: {},
        };

        this.sortBy = { shipping_id: 'DESC' };
        this.selectedID = '';
        // this.selectedIDs = [];
        this.formRef = React.createRef();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            const owner = find(result.staffs, (item) => item.ladi_uid == result.ladi_uid);
            owner.name = this.props.t('COMMON.ME');

            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.list();
        this.props.filterList({ type: appConfig.FILTER_TYPE.SHIPPING });
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
            if (nextProps.shippingReducer.action == types.CREATE_SHIPPING) {
                if (nextProps.shippingReducer.status) {
                    this.setState({
                        currentShipping: {},
                    });

                    this.list();
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);

                    setTimeout(() => {
                        window.LadiUI.closeModal(this.formRef.current.ref.current);
                    }, 500);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.shippingReducer.message);
                }
            }

            if (nextProps.shippingReducer.action == types.UPDATE_SHIPPING) {
                if (nextProps.shippingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);

                    this.list();
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.shippingReducer.message);
                }
            }

            if (includes([types.DELETE_SHIPPING, types.DELETE_SHIPPINGS], nextProps.shippingReducer.action)) {
                if (nextProps.shippingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);

                    this.list();

                    window.LadiUI.closeModal('confirm-shipping-delete');
                    window.LadiUI.closeModal('confirm-shipping-deletes');
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.shippingReducer.message);
                }
            }

            if (nextProps.shippingReducer.action == types.LIST_SHIPPING) {
                if (nextProps.shippingReducer.status) {
                    this.setState({
                        totalAmount: nextProps.shippingReducer.total,
                    });
                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.shippingReducer.message);
                }
            }
        }

        if (this.props.printReducer.action != nextProps.printReducer.action) {
            if (nextProps.printReducer.action == printTypes.PRINTS_SHIPPING) {
                if (nextProps.printReducer.status) {
                    baseHelper.print(nextProps.printReducer.source);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.printReducer.message);
                }
            }
        }

        if (this.props.filterReducer.action != nextProps.filterReducer.action) {
            if (nextProps.filterReducer.action == filterTypes.FILTER_CREATE) {
                if (nextProps.filterReducer.status) {
                    this.props.filterList({ type: appConfig.FILTER_TYPE.SHIPPING });
                    this.setState({
                        isShowModalSaveCondition: false,
                    });
                } else {
                    this.setState({
                        filterCustom: [],
                        filterCustomShow: [],
                        filterCustomDropdown: [],
                    });
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_LIST) {
                if (nextProps.filterReducer.status) {
                    let filters = [...nextProps.filterReducer.filters];

                    let filterCustomShow = filters.splice(0, 2);
                    this.setState(
                        {
                            filterCustom: nextProps.filterReducer.filters,
                            filterCustomShow,
                            filterCustomDropdown: filters,
                        },
                        () => {
                            if (!this.state.tabSelected) {
                                let queryStrings = new URLSearchParams(this.props.location.search);
                                let orderTab = queryStrings.get('order_tab');
                                if (!orderTab) orderTab = appConfig.ORDER_FILTER_TYPE.ALL;
                                this.onChangeTab(orderTab);
                            }
                        }
                    );
                } else {
                    this.setState({
                        filterCustom: [],
                        filterCustomShow: [],
                        filterCustomDropdown: [],
                    });
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_DELETE) {
                if (nextProps.filterReducer.status) {
                    window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                    this.props.filterList({ type: appConfig.FILTER_TYPE.SHIPPING });
                    this.setState({
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_UPDATE) {
                if (nextProps.filterReducer.status) {
                    // window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                    this.props.filterList({ type: appConfig.FILTER_TYPE.SHIPPING });
                    this.setState({
                        isShowModalColumnFilter: false,
                        isShowModalSaveCondition: false,
                        isShowModalFilterRename: false,
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_DUPLICATE) {
                if (nextProps.filterReducer.status) {
                    // window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);

                    this.props.filterList({ type: appConfig.FILTER_TYPE.SHIPPING });

                    this.setState({
                        isShowModalColumnFilter: false,
                        isShowModalSaveCondition: false,
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }
        }
    }

    onChangeTab = (tabID) => {
        this.clearFilter();

        switch (tabID) {
            case this.tabs.shippingReady:
                this.setState({
                    searchStatus: appConfig.SHIPPING_STATUS.READY_TO_PICK.CODE,
                });
                break;
            case this.tabs.paymentPending:
                this.setState({
                    searchPaymentStatus: appConfig.SHIPPING_PAYMENT_STATUS.PENDING.CODE,
                });
                break;
            case this.tabs.paymentReceived:
                this.setState({
                    searchPaymentStatus: appConfig.SHIPPING_PAYMENT_STATUS.PAID.CODE,
                });
                break;
        }

        setTimeout(() => {
            this.setState(
                {
                    tabSelected: tabID,
                },
                () => {
                    this.list();
                }
            );
        }, 200);
    };

    clearFilter = () => {
        this.setState({
            searchName: '',
            fromDate: null,
            toDate: null,
            searchStatus: '',
            searchPartner: '',
            searchPaymentStatus: '',
        });
    };
    /**
     * list shipping
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const {
            searchName,
            selectedLimit,
            // searchPartner,
            // searchStatus,
            // searchPaymentStatus,
            tabSelected,
            fromDate,
            toDate,
            conditions,
            search_v2,
        } = this.state;

        let activePage = conditions.page;
        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;
            this.setState({
                conditions,
            });
        }

        // Reset selectedIDs
        // this.selectedIDs = [];

        this.setState({
            selectedIDs: [],
        });

        let searchStatus = '';
        let searchPartner = '';
        let searchPaymentStatus = '';

        if (search_v2.status && search_v2.status.value) {
            searchStatus = search_v2.status.value;
        }
        if (search_v2.partner && search_v2.partner.value) {
            searchPartner = search_v2.partner.value;
        }
        if (search_v2.payment && search_v2.payment.value) {
            searchPaymentStatus = search_v2.payment.value;
        }

        const data = {
            search: {
                name: searchName,
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                status: searchStatus,
                payment_status: searchPaymentStatus,
                partner_code: searchPartner,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        switch (tabSelected) {
            case this.tabs.paymentPending:
                data.search.payment_status = appConfig.SHIPPING_PAYMENT_STATUS.PENDING.CODE;
                break;
            case this.tabs.paymentReceived:
                data.search.payment_status = appConfig.SHIPPING_PAYMENT_STATUS.PAID.CODE;
                break;
            case this.tabs.shippingReady:
                data.search.status = appConfig.SHIPPING_STATUS.READY_TO_PICK.CODE;
                break;
            case this.tabs.search:
                data.search = {
                    ...data.search,
                    status: searchStatus,
                    partner_code: searchPartner,
                    payment_status: searchPaymentStatus,
                };
                break;
        }

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (status) => {
        this.setState(
            {
                searchStatus: status,
                tabSelected: this.tabs.search,
            },
            () => {
                document.getElementById('triggerTabSearch').click();
                this.list(true);
            }
        );
    };

    /**
     * Tim kiem theo partner
     */
    onChangeSearchPartner = (partner) => {
        this.setState(
            {
                searchPartner: partner,
                tabSelected: this.tabs.search,
            },
            () => {
                document.getElementById('triggerTabSearch').click();
                this.list(true);
            }
        );
    };

    /**
     * Tim kiem theo payment
     */
    onChangeSearchPaymentStatus = (status) => {
        this.setState(
            {
                searchPaymentStatus: status,
                tabSelected: this.tabs.search,
            },
            () => {
                document.getElementById('triggerTabSearch').click();
                this.list(true);
            }
        );
    };

    onChangeStaff = (staff) => {
        let staffID = staff ? staff.ladi_uid : null;

        this.setState(
            {
                tabSelected: this.tabs.search,
            },
            () => {
                document.getElementById('triggerTabSearch').click();
                this.list(true);
            }
        );
    };
    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { selectedIDs } = this.state;
        const shippings = this.props.shippingReducer.shippings;

        let ids = [];
        if (selectedIDs.length < shippings.length) {
            ids = shippings.map((item) => item.shipping_id);
        }

        this.setState({
            selectedIDs: ids,
        });
    };

    checkItem = (shippingID) => {
        let { selectedIDs } = this.state;

        let index = selectedIDs.indexOf(shippingID);

        if (index > -1) {
            selectedIDs.splice(index, 1);
        } else {
            selectedIDs.push(shippingID);
        }
        this.setState({
            selectedIDs: selectedIDs,
        });
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({}, () => this.list());
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-shipping-delete');
    };

    openOptionDeletesModal = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-shipping-deletes');
    };

    prints = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.prints(this.state.selectedIDs);
    };

    /****************************************************************/

    getStatusName = (status) => {
        if (!status) {
            return this.props.t('STATUS.ALL_STATUS');
        }

        const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    getPaymentStatusName = (paymentStatus) => {
        if (!paymentStatus) {
            return this.props.t('PAYMENT_STATUS.ALL_STATUS');
        }

        const matched = find(appConfig.SHIPPING_PAYMENT_STATUS, (item) => item.CODE == paymentStatus);
        if (matched) {
            return matched.NAME;
        }
    };

    eventSelectAction = (action) => {
        switch (action) {
            // case 'REMOVE_MARK_SPAM':
            //     this.openRemoveSpamModal();
            //     break;
            // case 'MARK_SPAM':
            //     this.openMarkSpamModal();
            //     break;
            // case 'CANCEL_ORDER':
            //     this.openModalCancel();
            //     break;
            // case 'CONFIRM_PAYMENT':
            //     this.openModalConfirmPayment();
            //     break;
            case 'DELETE':
                this.openOptionDeletesModal();
                break;
            case 'PRINT':
                this.prints();
                break;
            default:
        }
    };

    clearFilter = (callback) => {
        this.setState(
            {
                searchStatus: '',
                searchPartner: '',
                searchPaymentStatus: '',
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        // let filter = {
        //     tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
        //     searchName: ""
        // }

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case 'status':
                    this.setState({
                        searchStatus: condition.valueFilter.value,
                    });
                    break;
                case 'partner':
                    this.setState({
                        searchPartner: condition.valueFilter.value,
                    });
                    break;
                case 'payment':
                    this.setState({
                        searchPaymentStatus: condition.valueFilter.value,
                    });
                    break;
                default:
                    break;
            }
        }

        // this.setState({
        //     ...filter
        // }, () => {
        //     this.list();
        // })
        this.list();
    };

    openModalSaveCondition = () => {
        this.setState({
            isShowModalSaveCondition: true,
        });
    };

    setDataCondition = async (data) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        let index = conditions.findIndex((item) => item.value == data.value);
        if (index > -1) {
            conditions[index] = data;
        } else conditions.push(data);

        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
                tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
            },
            () => {
                this.list();
            }
        );
    };

    removeCondition = async (index) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        if (index > -1) conditions.splice(index, 1);
        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
            },
            () => {
                this.list();
            }
        );
    };

    parseSearch = (conditions) => {
        let search = {};

        for (const condition of conditions) {
            switch (condition.value) {
                case 'status':
                case 'partner':
                case 'payment':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.fieldSelected.value,
                    };
                    break;
                default:
                    break;
            }
        }

        return search;
    };

    render() {
        const { t } = this.props;
        const { totalRecord, totalPage } = this.props.shippingReducer;
        const {
            activePage,
            selectedLimit,
            searchStatus,
            searchName,
            searchPartner,
            searchPaymentStatus,
            userInfo,
            tabSelected,
            conditions,
            conditionProp,
            isShowFilterCondition,
            dataConditions,
            selectedIDs,
            filterCustomShow,
            filterCustomDropdown,
        } = this.state;
        const shippings = this.props.shippingReducer.shippings || [];
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.shippingReducer.shippings || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.shippingReducer.shippings.length - 1;
        }
        const isLoadingData = this.props.shippingReducer.loading && this.props.shippingReducer.waiting == types.LIST_SHIPPING;
        const isLoadingDelete =
            this.props.shippingReducer.loading &&
            includes([types.DELETE_SHIPPING, types.DELETE_SHIPPINGS], this.props.shippingReducer.waiting);
        const isLoadingPrint = this.props.printReducer.loading && this.props.printReducer.waiting == printTypes.PRINTS_SHIPPING;

        // const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t('PAGINATION.OF')} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;
        const msgShowItem = ``;

        const SHIPPING_STATUS = {};
        map(appConfig.SHIPPING_STATUS, (item, key) => {
            if (item.CODE != appConfig.SHIPPING_STATUS.PENDING.CODE) {
                SHIPPING_STATUS[key] = item;
            }
        });

        const listAction = [
            { value: 'PRINT', name: t('COMMON.PRINT') },
            { value: 'DELETE', name: t('ACTIONS.DELETE') },
        ];
        const staffs = userInfo.staffs;

        return (
            <div className='page-content'>
                {isLoadingPrint && <LoadingScene blur={true} />}
                <div className='header-page'>
                    <div className='heading'>
                        <h1>{t('SHIPPINGS.TITLE')}</h1>
                        <p>{t('SHIPPINGS.SUB_TITLE')}</p>
                    </div>
                </div>

                <div className='block-filter'>
                    <div className='block-search'>
                        <div className='ladiui search-group'>
                            <i className='ladiui icon icon-search' onClick={this.list}></i>
                            <input
                                id='keyword_search'
                                className='ladiui search-field dropdown-toggle form-control search-width'
                                name='searchName'
                                placeholder={t('SHIPPINGS.SEARCH_SHIPPING')}
                                aria-expanded='false'
                                value={searchName}
                                onChange={this.onChangeInput}
                                onKeyDown={this.onKeyDownInput}
                            />
                        </div>
                        <div className='block-filter-button'>
                            <div className='date-filter'>
                                <OrderDateRangePicker
                                    startDateId='fromDate'
                                    endDateId='toDate'
                                    startDate={this.state.fromDate}
                                    endDate={this.state.toDate}
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState(
                                            {
                                                fromDate: baseHelper.getFromDate(startDate),
                                                toDate: baseHelper.getToDate(endDate),
                                            },
                                            () => {
                                                if ((startDate && endDate) || (!startDate && !endDate)) {
                                                    this.list();
                                                }
                                            }
                                        );
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput) => {
                                        this.setState({ focusedInput });
                                    }}
                                    showDefaultInputIcon={true}
                                    inputIconPosition='after'
                                    small={true}
                                    hideKeyboardShortcutsPanel={true}
                                    customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                    isOutsideRange={() => false}
                                    isDayHighlighted={(date) => {
                                        return this.moment().isSame(date, 'day');
                                    }}
                                    minimumNights={0}
                                    showClearDates={true}
                                    anchorDirection={'right'}
                                />
                            </div>
                            <button
                                type='button'
                                className='btn-advanced-filter'
                                onClick={() => this.setState({ isShowFilterCondition: !isShowFilterCondition })}
                            >
                                <div className='ladiui'>{t('COMMON.MORE_FILTER')}</div>
                                <i className='ldicon-sort' />
                            </button>
                        </div>
                    </div>
                    {isShowFilterCondition && (
                        <div className='block-condition mt-12'>
                            <FilterConditionOrderv2
                                openModalSaveCondition={this.openModalSaveCondition}
                                setDataCondition={this.setDataCondition}
                                removeCondition={this.removeCondition}
                                conditionProp={conditionProp}
                                conditionFields={appConfig.SHIPPING_CONDITION_FILEDS}
                                dataConditions={dataConditions}
                                staffs={staffs}
                                isSave={false}
                                // products={productLists}
                                // allTags={allTags}
                                // allStorePages={allStorePages}
                            />
                        </div>
                    )}
                </div>

                <div className='page-shipping'>
                    <div className='order-tab'>
                        <ul className='ladiui nav nav-tabs overflow-hide'>
                            <li className='ladiui nav-item' id='tabAll'>
                                <span
                                    data-tab='tabAll'
                                    className={`ladiui tab-link ${tabSelected === this.tabs.all ? 'active' : ''}`}
                                    onClick={() => this.onChangeTab(this.tabs.all)}
                                >
                                    {t('SHIPPINGS.TAB_ALL')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabShippingPending'>
                                <span
                                    data-tab='tabShippingPending'
                                    className={`ladiui tab-link ${tabSelected === this.tabs.shippingReady ? 'active' : ''}`}
                                    onClick={() => this.onChangeTab(this.tabs.shippingReady)}
                                >
                                    {t('SHIPPINGS.TAB_SHIPPING_READY')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabPaymentPending'>
                                <span
                                    data-tab='tabPaymentPending'
                                    className={`ladiui tab-link ${tabSelected === this.tabs.paymentPending ? 'active' : ''}`}
                                    onClick={() => this.onChangeTab(this.tabs.paymentPending)}
                                >
                                    {t('SHIPPINGS.TAB_PAYMENT_PENDING')}
                                </span>
                            </li>
                            <li className='ladiui nav-item' id='tabPaymentReceived'>
                                <span
                                    data-tab='tabPaymentReceived'
                                    className={`ladiui tab-link ${tabSelected === this.tabs.paymentReceived ? 'active' : ''}`}
                                    onClick={() => this.onChangeTab(this.tabs.paymentReceived)}
                                >
                                    {t('SHIPPINGS.TAB_PAYMENT_RECEIVED')}
                                </span>
                            </li>
                            <li
                                className='ladiui nav-item'
                                id='tabSearch'
                                style={this.state.tabSelected == this.tabs.search ? { display: 'block' } : { display: 'none' }}
                            >
                                <span
                                    id='triggerTabSearch'
                                    className={`ladiui tab-link ${tabSelected === this.tabs.search ? 'active' : ''}`}
                                    data-tab='tabSearch'
                                >
                                    {t('ORDERS.TAB_SEARCH')}
                                </span>
                            </li>
                        </ul>
                    </div>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card table-wrap ladiui-table-responsive'>
                                <table className={`ladiui table text-left shipping-table table-order`}>
                                    <thead style={{ position: 'relative' }}>
                                        <tr className='ladiui table-vertical'>
                                            <th scope='col' className='ladiui'>
                                                <input
                                                    id='checkAllItem'
                                                    onClick={this.checkAllItem}
                                                    checked={selectedIDs.length == shippings.length && selectedIDs.length > 0}
                                                    onChange={() => {}}
                                                    type='checkbox'
                                                    className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-16'
                                                />
                                                <span>{t('SHIPPINGS.TITLE')}</span>
                                                {selectedIDs.length > 0 && (
                                                    <LadiDropdownMenu
                                                        labelDropdown={'Chọn hành động'}
                                                        listProp={listAction}
                                                        defaultTextProp={t('ACTIONS.TITLE')}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectAction}
                                                        classSize='btn-sm '
                                                        classDropdown='w-180 action-table-order-tag '
                                                    />
                                                )}
                                            </th>
                                            <th scope='col'>{t('SHIPPINGS.SHIPPING_CODE')}</th>
                                            <th scope='col'>{t('COMMON.CREATED_AT')}</th>

                                            <th
                                                scope='col'
                                                name='refernce_no'
                                                onClick={this.sort}
                                                className={`sorting ${this.sortBy['refernce_no'] == 'DESC' ? 'up' : ''}`}
                                            >
                                                {t('MENU.ORDERS')}
                                            </th>
                                            <th scope='col'>{t('CUSTOMERS.TITLE')}</th>
                                            <th scope='col'>{t('COMMON.PHONE')}</th>
                                            <th
                                                scope='col'
                                                name='status'
                                                onClick={this.sort}
                                                className={`sorting ${this.sortBy['status'] == 'DESC' ? 'up' : ''}`}
                                            >
                                                {t('STATUS.TITLE')}
                                            </th>
                                            <th
                                                scope='col'
                                                name='payment_status'
                                                onClick={this.sort}
                                                className={`sorting ${this.sortBy['payment_status'] == 'DESC' ? 'up' : ''}`}
                                            >
                                                {t('SHIPPINGS.COD_TITLE')}
                                            </th>
                                            <th scope='col'>{t('COMMON.TOTAL')}</th>
                                            <th scope='col'>{t('ORDERS.ASSIGNEE')}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(shippings, (shipping) => {
                                            let isSelected = selectedIDs.includes(shipping.shipping_id);

                                            let query_ls = { customer_id: shipping.customer_id };

                                            let url_customer = `${config.BUILDER_LDP}${
                                                appConfig.LADIPAGE_URL.CUSTOMER_LIST
                                            }&query_ls=${JSON.stringify(query_ls)}`;

                                            return (
                                                <tr
                                                    key={shipping.shipping_id}
                                                    className={
                                                        shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE
                                                            ? 'ladiui table-vertical main shipping-cancel'
                                                            : 'ladiui table-vertical main'
                                                    }
                                                >
                                                    <td>
                                                        <input
                                                            type='checkbox'
                                                            onClick={(event) => this.checkItem(shipping.shipping_id)}
                                                            onChange={() => {}}
                                                            checked={isSelected}
                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-16'
                                                        />

                                                        <NavLink to={`/shippings/${shipping.shipping_id}`}>{shipping.shipping_id}</NavLink>
                                                    </td>
                                                    <td>{baseHelper.getTrackingHref(shipping)}</td>
                                                    <td>
                                                        {baseHelper.formatStrToDate(shipping.created_at)}
                                                        {/* <NavLink to={`/shippings/${shipping.shipping_id}`}>
                                                            {baseHelper.formatStrToDate(shipping.created_at)}
                                                        </NavLink> */}
                                                    </td>
                                                    <td>
                                                        <a
                                                            className='order-table-item'
                                                            // href={`/orders/${shipping.order_id}`}
                                                            href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.ORDER_UPDATE(
                                                                shipping.order_id
                                                            )}`}
                                                            target='_blank'
                                                        >{`#${shipping.reference_no}`}</a>
                                                    </td>
                                                    <td>
                                                        <a href={url_customer} target='_blank'>{`${baseHelper.getText(
                                                            shipping.shipping_last_name
                                                        )} ${baseHelper.getText(shipping.shipping_first_name)}`}</a>
                                                    </td>
                                                    <td>{shipping.shipping_phone}</td>
                                                    <td>{this.getStatusName(shipping.status)}</td>
                                                    <td>{this.getPaymentStatusName(shipping.payment_status)}</td>
                                                    <td>
                                                        {baseHelper.formatMoneyPostFix(
                                                            shipping.total,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </td>
                                                    <td className='text-center'>
                                                        {baseHelper.getStaffNameById(
                                                            userInfo.staffs,
                                                            shipping.creator_id,
                                                            userInfo.ladi_uid
                                                        )}
                                                    </td>
                                                    <td className='text-right pd-0'>
                                                        <div className='ladiui btn-group'>
                                                            <div className='ladiui dropdown hide-mt ba-c'>
                                                                {/* <i className="ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5" data-toggle="dropdown"></i> */}
                                                                <button
                                                                    data-toggle='dropdown'
                                                                    className='ladiui-btn-dropdown dropdown-toggle'
                                                                >
                                                                    <i className='ladiui icon icon-ldp-dot'></i>
                                                                </button>
                                                                <ul className='ladiui dropdown-menu r-0'>
                                                                    <li>
                                                                        <NavLink
                                                                            className='ladiui dropdown-item'
                                                                            to={`/shippings/${shipping.shipping_id}`}
                                                                        >
                                                                            <i className='new-ldicon-edit' />
                                                                            <span> {t('ACTIONS.EDIT')} </span>
                                                                        </NavLink>
                                                                    </li>
                                                                    {shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE && (
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() =>
                                                                                    this.openOptionDeleteModal(shipping.shipping_id)
                                                                                }
                                                                            >
                                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                                <span className='ladiui text-danger'>
                                                                                    {t('ACTIONS.DELETE')}
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                        {/* {totalRecord <= 0 && (
                                            <tr className='text-center'>
                                                <td colSpan='100%'>{t('NO_DATA')}</td>
                                            </tr>
                                        )} */}
                                    </tbody>
                                    {totalRecord > 0 && (
                                        <tfoot>
                                            <tr>
                                                <td colSpan={8}>
                                                    <strong>{t('ORDERS.TOTAL_AMOUNT')}</strong>
                                                </td>
                                                <td colSpan={3}>
                                                    <strong className='pl-0'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            this.state.totalAmount,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                            </div>
                            {totalRecord > 0 ? (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('CUSTOM_FIELD.ORDER')}
                                    message={msgShowItem}
                                    items={this.props.shippingReducer.shippings}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            ) : (
                                <div className='table-empty'>
                                    <i className='new-ldicon-search' />
                                    <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                                    <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <ConfirmModal
                    id='confirm-shipping-delete'
                    title={t('SHIPPINGS.MSG_DELETE_SHIPPING_TITLE')}
                    content={t('SHIPPINGS.MSG_DELETE_SHIPPING_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(this.selectedID)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-shipping-deletes'
                    title={t('SHIPPINGS.MSG_DELETE_SHIPPING_TITLE')}
                    content={t('SHIPPINGS.MSG_DELETE_SHIPPINGS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deletes(this.state.selectedIDs)}
                    isLoading={isLoadingDelete}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(shippingActions.list(data)),
        deletes: (shippingIDs) => dispatch(shippingActions.deletes(shippingIDs)),
        delete: (shippingID) => dispatch(shippingActions.delete(shippingID)),
        prints: (shippingIDs) => dispatch(printActions.printsShipping(shippingIDs)),
        filterList: (data) => dispatch(filterActions.list(data)),
        filterDelete: (data) => dispatch(filterActions.deletes(data)),
        filterDuplicate: (data) => dispatch(filterActions.duplicate(data)),
    };
};

const mapStateToProps = (state) => ({
    shippingReducer: { ...state.shipping },
    printReducer: { ...state.print },
    storeReducer: { ...state.store },
    filterReducer: { ...state.filterReducer },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Shipping));
