import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { map, find, includes } from 'lodash';

export default function ListItemByScope(props) {
    const dispatch = useDispatch();
    let { dataScope, currentScope, selectedStaff } = props;

    const [isShow, setIsShow] = useState(false);

    const handleSelectScope = (scope) => {
        setIsShow(!isShow);
    }

    const handleChangeScopeItem = (valueScope, scopeItem) => {
        props.onChangeGroupScopeItem(currentScope, valueScope)
    }

    const onChangeScope = (_key) => {
        props.onChangeScope(_key)
    }

    const groups = selectedStaff.scopes[currentScope];

    return (
        <>
            <div className="list-item">
                <div className="scope-name">
                    <div className="flex">
                        <input
                            type='checkbox'
                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                            onChange={() => onChangeScope(currentScope)}
                            checked={groups == true}
                        />
                        <span>{dataScope.title}</span>
                    </div>
                    {
                        dataScope.groups
                        &&
                        <div className="show-list-permission cursor-pointer" onClick={() => handleSelectScope(dataScope)}>
                            {
                                !isShow
                                    ?
                                    <i className="new-ldicon-arrow-down" />
                                    :
                                    <i className="new-ldicon-arrow-up" />
                            }
                        </div>
                    }
                </div>
                {
                    isShow && dataScope.groups
                    &&
                    <div className="list-permissions">
                        {map(dataScope.groups, (scopeItem, _key) => {
                            return (
                                <div className="list-permission-item" key={_key}>
                                    <label className="flex items-center">
                                        <input
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            onChange={() => handleChangeScopeItem(_key, scopeItem)}
                                            checked={groups == true || includes(groups, _key)}
                                        />
                                        <span>{scopeItem.title}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </>
    )
}