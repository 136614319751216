import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';

const list = () => ({
    type: types.LIST_STAFFS,
    meta: {
        endpoint: endpoint.STAFF_LIST,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const create = (data) => ({
    type: types.CREATE_STAFF,
    meta: {
        endpoint: endpoint.STAFF_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const update = (staff) => ({
    type: types.UPDATE_STAFF,
    meta: {
        endpoint: endpoint.STAFF_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            staff,
        },
        hasAuth: true,
    },
});

const remove = (staffID) => ({
    type: types.REMOVE_STAFF,
    meta: {
        endpoint: endpoint.STAFF_REMOVE,
        method: REQUEST_METHOD.POST,
        body: {
            staff_id: staffID,
        },
        hasAuth: true,
    },
});

export default {
    list,
    create,
    update,
    remove,
};
