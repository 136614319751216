/** @format */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';

import Dropdown from '../../../components/Dropdown';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import productActions from '../../../redux/futures/product/actions';
import fileActions from '../../../redux/futures/file/actions';
import actionsProductTag from '../../../redux/futures/product_tag/actions';
import actionsProduct from '../../../redux/futures/product/actions';

import storePageActions from '../../../redux/futures/store_page/actions';
import * as storePageTypes from '../../../redux/futures/store_page/types';

import productCategoryActions from '../../../redux/futures/product_category/actions';

import * as productTypes from '../../../redux/futures/product/types';
import * as productCategoryTypes from '../../../redux/futures/product_category/types';

import ModalAddProduct from '../../orders/OrderCreate/components/ModalAddProduct';
import produce from 'immer';
import { find, includes, map } from 'lodash';
import Image from '../../../components/Image';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import NumberInput from '../../../components/NumberInput';
import SelectManyDropdown from '../../../components/SelectManyDropdown';
import Tag from '../../../components/Tag';
import SelectManyDropdownStore from '../../../components/SelectManyDropdownStore';

const CDN = 'https://w.ladicdn.com/';

function ModalCreateCourse(props) {
    const { t, i18n, showModal, onCancel, currentCategoryID, isModalEdit, selectedType } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const listProduct = [
        appConfig.PRODUCT_TYPE.PHYSICAL.CODE,
        appConfig.PRODUCT_TYPE.F_B.CODE,
        appConfig.PRODUCT_TYPE.COMBO.CODE,
        appConfig.PRODUCT_TYPE.COURSE.CODE,
    ];

    const productCategoryReducer = useSelector((state) => state.productCategory);
    const storePageReducer = useSelector((state) => state.storePage);
    const storeReducer = useSelector((state) => state.store);

    const tagRef = useRef();
    const productNameRef = useRef();

    const [description, setDescription] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);

    const [product, setProduct] = useState({
        name: '',
        price: 0,
        sku: '',
        display_type: 0,
        price_compare: 0,
        cost_per_item: 0,
    });

    const [valid, setValid] = useState({
        name: true,
    });

    const listAllTags = useSelector((state) => {
        if (state.productTag.action === 'ASYNC_END' && state.productTag.allTags) return state.productTag.allTags;
        else return [];
    });

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });
    // const productInfo = useSelector(state => {
    //     return state.product.product;
    // })

    const productState = useSelector((state) => state.product);

    useEffect(() => {
        let payload = {
            search: {
                type: 'Course',
            },
        };
        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
        dispatch(actionsProductTag.listAll());
        dispatch(productCategoryActions.listSelectCategory(payload));
    }, []);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_LIST) {
            if (storePageReducer.status) {
                if (storePageReducer.store_pages && storePageReducer.store_pages.length > 0) {
                    let _data = [];
                    storePageReducer.store_pages.map((item, index) => {
                        _data.push({
                            name: item.name,
                            value: item.page_checkout_id,
                        });
                    });

                    setStores(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    let _data = [...categories];
                    productCategoryReducer.listSelects.map((item, index) => {
                        const isExitCategory = _data.some((_item) => _item.value == item.product_category_id);
                        if (!isExitCategory) {
                            _data.push({
                                level: item.level,
                                name: item.name,
                                value: item.product_category_id,
                            });
                        }
                    });

                    setCategories(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (!productState.loading) setLoading(false);
        const productInfo = productState.product;

        if (productState.action === 'CREATE_PRODUCT' && productInfo && productInfo.product_id) {
            history.push(`/courses/update/${productInfo.product_id}`);
        }

        if (productState.action === 'CREATE_PRODUCT' && !productState.status) {
            window.LadiUI.toastCustom('danger', '', productState.message);
        }
    }, [productState]);

    // useEffect(() => {
    //     if (productInfo && productState.product_id) {
    //     }
    // }, [productInfo])

    const handleEditorChange = (value) => {
        setDescription(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name' && value) {
            setValid({
                name: true,
            });
        }

        setProduct((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submit = () => {
        let errors = [];
        if (productNameRef) {
            const error = productNameRef.current.validate();
            if (error.length > 0) {
                errors = [...errors, ...error];
            }
        }

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('warning', '', errorMessage);
            return;
        }

        const products = {
            is_show_all_store: selectedStores.length == stores.length ? 1 : 0,
            name: product.name,
            alias_name: baseHelper.getAliasName(product.name),
            description: description,
            inventory_checked: 0,
            allow_sold_out: 0,
            type: 'Course',
            display_type: product.display_type,
            status: 'Active',
            external_link: '',
            min_buy: 1,
            max_buy: null,
            tags: tagRef.current.getData(),
            options: [],
            variants: [
                {
                    price: product.price,
                    price_compare: product.price_compare,
                    cost_per_item: product.cost_per_item,
                    sku: product.sku,
                    weight: '',
                    weight_unit: 'g',
                    inventory_checked: 0,
                    allow_sold_out: 0,
                    quantity: 0,
                    min_buy: 1,
                    max_buy: null,
                },
            ],
            custom_fields: [],
            images: [],
            product_up_sells: [],
            product_category_ids: selectedCategories || [],
            page_checkout_ids: selectedStores || [],
        };

        setLoading(true);

        dispatch(actionsProduct.create(products));
    };

    const handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: 'Course',
            },
        };
        dispatch(productCategoryActions.listSelectCategory(payload));
    };

    const handleRefreshDataStores = () => {
        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    };

    const handleSearchData = (value) => {
        const dataListStore = {
            keyword: value,
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    };

    return (
        <>
            <Modal
                id='modal-create-category'
                title={!isModalEdit ? t('COURSES.ADD_COURSE') :  t('COURSES.ADD_COURSE')}
                visible={showModal}
                onCancel={onCancel}
                width={600}
                isLoading={isLoading}
                okText={!isModalEdit ? 'Tạo khoá học' : 'Lưu'}
                onOk={submit}
                hasFooter={true}
            >
                <div className='ladiui form-group mb-0'>
                    <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                        Tên khóa học
                    </label>
                    <Input
                        className={`ladiui form-control input-price ${valid.name ? '' : 'valid-input-error'}`}
                        ref={productNameRef}
                        name='name'
                        validationName={'tên khóa học'}
                        placeholder='Nhập tên khóa học'
                        value={product.name}
                        onChange={handleChange}
                        validations={{ isRequired: true }}
                    />
                </div>

                <div className='ladiui form-group'>
                    <div className='ladiui item-form flex'>
                        <div className='ladiui form-group mb-0 mt-0 mr-12'>
                            <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                                Mã khóa học
                            </label>
                            <Input
                                ref={productNameRef}
                                name='sku'
                                validationName={'mã khóa học'}
                                placeholder='Nhập mã khóa học'
                                value={product.sku}
                                onChange={handleChange}
                                validations={{ isRequired: false }}
                            />
                        </div>
                        <div className='ladiui form-group mt-0 mb-0 ml-12'>
                            <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                                {t('PRODUCTS.PRICE')}
                            </label>
                            <NumberInput
                                name='price'
                                suffix={
                                    storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                        ? storeInfo.currentStore.currency_symbol
                                        : 'đ'
                                }
                                value={product.price}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>

                {storeReducer.userInfo.email && appConfig.WHITE_LIST_EMAIL.includes(storeReducer.userInfo.email) && (
                    <div className='ladiui form-group mb-0'>
                        <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                            Cửa hàng
                        </label>
                        <SelectManyDropdownStore
                            data={stores}
                            _key='name'
                            _value='value'
                            pickTitle={'Tiêu đề'}
                            placeHolder={'Chọn cửa hàng'}
                            onSubmit={(items) => {
                                setSelectedStores(items);
                            }}
                            innerClassName='w100 dropdown-select-custom'
                            styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                            buttonStyle={{
                                width: '100%',
                                paddingRight: '30px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: ' -webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                textAlign: 'left',
                            }}
                            selectedItems={selectedStores}
                            isShowBlockInputSearch={true}
                            isCreateCategory={true}
                            isCourse={true}
                            handleRefreshData={handleRefreshDataStores}
                            handleSearchData={handleSearchData}
                        />
                    </div>
                )}

                <div className='ladiui form-group mb-0'>
                    <label className='ladiui title-text-form-lever2' htmlFor='exampleInputPassword1'>
                        Chọn danh mục khóa học
                    </label>
                    <SelectManyDropdown
                        data={categories}
                        _key='name'
                        _value='value'
                        pickTitle={'Tiêu đề'}
                        placeHolder={'Chọn danh mục'}
                        onSubmit={(items) => {
                            setSelectedCategories(items);
                        }}
                        innerClassName='w100 dropdown-select-custom'
                        styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                        buttonStyle={{
                            width: '100%',
                            paddingRight: '30px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: ' -webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            textAlign: 'left',
                        }}
                        selectedItems={selectedCategories}
                        isShowBlockInputSearch={true}
                        isCreateCategory={true}
                        isCourse={true}
                        handleRefreshData={handleRefreshDataCategories}
                    />
                </div>

                <div className='ladiui form-group'>
                    <Tag
                        tabIndex={6}
                        ref={tagRef}
                        id='product-tag'
                        allTags={listAllTags}
                        // selectedTags={tags}
                    />
                </div>
            </Modal>
        </>
    );
}

export default withTranslation()(ModalCreateCourse);
