import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../../config/app';

import pageCheckoutDefined from '../../../components/pageCheckoutDefined';
import WidgetCustomer from './WidgetCustomer';
import WidgetShipping from './WidgetShipping';
import WidgetPayment from './WidgetPayment';
// eslint-disable-next-line max-lines-per-function

const Payment = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageCheckout, paymentConfig = {} } = props;

    const widgetCustomerRef = useRef();
    const widgetShippingRef = useRef();
    const widgetPaymentRef = useRef();

    const [paymentWidgetSelected, setPaymentWidgetSelected] = useState('');
    const [menuSelected, setMenuSelected] = useState('');

    useImperativeHandle(ref, () => ({
        getData: () => {
            let widgetCustomer = [];
            let widgetShipping = [];
            let widgetPayment = [];

            if (widgetCustomerRef && widgetCustomerRef.current) {
                widgetCustomer = widgetCustomerRef.current.getData();
            }
            if (widgetShippingRef && widgetShippingRef.current) {
                widgetShipping = widgetShippingRef.current.getData();
            }
            if (widgetPaymentRef && widgetPaymentRef.current) {
                widgetPayment = widgetPaymentRef.current.getData();
            }

            let widgetConfig = {
                widget_customer: widgetCustomer,
                widget_shipping: widgetShipping,
                widget_payment: widgetPayment,
            };
            return widgetConfig;
        },
    }));

    useEffect(() => {
        if (queryStrings) {
            let menu = queryStrings.get('widget-menu');
            if (!menu) {
                setMenuSelected('');
            } else {
                setMenuSelected(menu);
            }

            let paymentMenu = queryStrings.get('payment-menu');
            if (!paymentMenu) {
                setPaymentWidgetSelected('');
            } else {
                setPaymentWidgetSelected(paymentMenu);
            }
        }
    }, [queryStrings]);

    const selectMenu = (action) => {
        let _queryStrings = queryStrings;
        _queryStrings.set('payment-menu', action);

        history.replace({ search: _queryStrings.toString() });
    };

    return (
        <div className={`page-checkout-config-item ${isShow ? 'show' : 'hide'}`}>
            {!paymentWidgetSelected && menuSelected == 'PAYMENT' && (
                <div className='widget-menu'>
                    {pageCheckoutDefined.WIDGET_PAYMENT_MENU.map((item, index) => {
                        return (
                            <div key={index} className='widget-menu-item' onClick={() => selectMenu(item.action)}>
                                <div className='display-flex-center gap-16'>
                                    <i className={` ladiui icon-new ${item.iconClass}`} />
                                    <span className='widget-menu-label'>{item.title}</span>
                                </div>
                                <i className='ldicon-chevron-right' />
                            </div>
                        );
                    })}
                </div>
            )}

            <WidgetCustomer
                ref={widgetCustomerRef}
                isShow={paymentWidgetSelected == 'WIDGET_CUSTOMER'}
                widgetConfig={paymentConfig.widget_customer}
            />

            <WidgetShipping
                ref={widgetShippingRef}
                isShow={paymentWidgetSelected == 'WIDGET_SHIPPING'}
                widgetConfig={paymentConfig.widget_shipping}
            />

            <WidgetPayment
                ref={widgetPaymentRef}
                isShow={paymentWidgetSelected == 'WIDGET_PAYMENT'}
                widgetConfig={paymentConfig.widget_payment}
            />
        </div>
    );
});

export default Payment;
