import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.STORE_PAGE_LIST: {
      return {
        ...state,
        action: types.STORE_PAGE_LIST,
        store_pages: action.status ? action.payload.page_checkouts : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.STORE_PAGE_DELETE: {
      return {
        ...state,
        action: types.STORE_PAGE_DELETE,
        status: action.status,
        message: action.message,
      };
    }
    case types.STORE_PAGE_DELETES: {
      return {
        ...state,
        action: types.STORE_PAGE_DELETES,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
