import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import pageCheckoutDefined from '../../components/pageCheckoutDefined';
import HandelSection from './HandelSection';

// eslint-disable-next-line max-lines-per-function

const Cart = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, cartConfig } = props;

    const handelSectionRef = useRef();

    useImperativeHandle(ref, () => ({
        getData: () => {
            let cartSections = handelSectionRef.current.getData();
            return cartSections;
        },
    }));

    useEffect(() => {}, []);

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'}`}>
            <div>
                <label className='ladiui label-14'> {t('SALE_CHANNEL.CART_STYLE_LB')}</label>
                <div className='ladiui text-14'>{t('SALE_CHANNEL.CART_STYLE_SUB_LB')}</div>
            </div>
            <HandelSection ref={handelSectionRef} sectionData={cartConfig || pageCheckoutDefined.CART_ITEM_DEFAULT} type='CART' />
        </div>
    );
});

export default Cart;
