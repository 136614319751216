import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import Popover from '../../../../../components/Popover';
import appConfig from '../../../../../config/app';

// eslint-disable-next-line max-lines-per-function

const PopoverAddSection = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { addImage, addTextEditer, type, addProductBlock, addUpSellBlock } = props;

    const popoverRef = useRef();

    const handelAddImage = () => {
        if (addImage) addImage();
        popoverRef.current.instanceRef.hide();
    };

    const handelAddEditer = () => {
        if (addTextEditer) addTextEditer();
        popoverRef.current.instanceRef.hide();
    };
    const handelAddProduct = () => {
        if (addProductBlock) addProductBlock();
        popoverRef.current.instanceRef.hide();
    };
    const handelAddUpSell = () => {
        if (addUpSellBlock) addUpSellBlock();
        popoverRef.current.instanceRef.hide();
    };

    return (
        <div className='ladiui pos-rel'>
            <Popover
                ref={popoverRef}
                wrapperPopoverProps={{ className: 'popover-action-page-checkout' }}
                content={
                    <div className='action-add-content'>
                        <label className='ladiui label-14'>{t('PAGE_CHECKOUT.CONTENT')}</label>
                        <div className='add-content-new'>
                            {type != 'SETUP_FOOTER' && (
                                <div className='add-content-item' onClick={handelAddImage}>
                                    <div className='icon-antion'>
                                        <i className='ladiui icon-new new-ldicon-photo' />
                                    </div>
                                    <div className=''>
                                        <div className='ladiui text-14 text-primary'>{t('PAGE_CHECKOUT.IMAGE')}</div>
                                        <div className='mt-4 ladiui text-14'>{t('PRODUCT_SERVICE_V4.IMAGE_UPLOAD_LB')}</div>
                                    </div>
                                </div>
                            )}

                            <div className='add-content-item' onClick={handelAddEditer}>
                                <div className='icon-antion'>
                                    <i className='ladiui icon-new new-ldicon-create-content' />
                                </div>
                                <div className=''>
                                    <div className='ladiui text-14 text-primary'>{t('SALE_CHANNEL.TEXT_EDIT_LB')}</div>
                                    <div className='mt-4 ladiui text-14'>{t('SALE_CHANNEL.TEXT_EDIT_SUB_LB')}</div>
                                </div>
                            </div>
                            {[
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.PAGE_HOME_CONFIG,
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.CART,
                                // appConfig.PAGE_CHECKOUT_SECTION_TYPE.THANK_PAGE,
                            ].includes(type) && (
                                <div className='add-content-item' onClick={handelAddProduct}>
                                    <div className='icon-antion'>
                                        <i className='ladiui icon-new new-ldicon-box' />
                                    </div>
                                    <div className=''>
                                        <div className='ladiui text-14 text-primary'>{t('SALE_CHANNEL.PRODUCT_SECTION_LB')}</div>
                                        <div className='mt-4 ladiui text-14'>{t('SALE_CHANNEL.PRODUCT_SECTION_SUB_LB')}</div>
                                    </div>
                                </div>
                            )}
                            {[
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.CART,
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.WIDGET_CUSTOMER,
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.WIDGET_PAYMENT,
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.WIDGET_SHIPPING,
                                appConfig.PAGE_CHECKOUT_SECTION_TYPE.THANK_PAGE,
                            ].includes(type) && (
                                <div className='add-content-item' onClick={handelAddUpSell}>
                                    <div className='icon-antion'>
                                        <i className='ladiui icon-new new-ldicon-box' />
                                    </div>
                                    <div className=''>
                                        <div className='ladiui text-14 text-primary'>{t('SALE_CHANNEL.PRODUCT_UPSELL_LB')}</div>
                                        <div className='mt-4 ladiui text-14'>{t('SALE_CHANNEL.PRODUCT_UPSELL_SUB_LB')}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                }
            >
                <div className='btn-add-section'>
                    <i className='ldicon-add' />
                    <span className='ladiui text-brand'>{t('SALE_CHANNEL.SECTION_ADD_LB')}</span>
                </div>
            </Popover>
        </div>
    );
});

export default PopoverAddSection;
