import React, { useState, useRef, useEffect, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';

import * as orderTypes from '../../../../redux/futures/order/types';

import Input from '../../../../components/Input';
import Popover from '../../../../components/Popover';
import NumberInput from '../../../../components/NumberInput';
import Dropdown from '../../../../components/Dropdown';

function PopperCheckout(props) {
    const { t } = useTranslation();
    const { checkoutLink, isGenerateCheckout, getLinkCheckout } = props;

    const popoverRef = useRef();

    const storeReducer = useSelector((state) => state.store);
    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
    // const orderReducer = useSelector((state) => state.order);

    const checkoutConfigs = useMemo(() => {
        return checkoutConfigReducer.checkout_configs || [];
    }, [checkoutConfigReducer.checkout_configs]);

    const [url, setUrl] = useState();
    const [selectedCheckoutConfig, setCheckoutConfig] = useState({});

    // useEffect(() => {
    //     if (orderReducer.action == orderTypes.GENERATE_CHECKOUT_ORDER) {
    //         if (orderReducer.status) {
    //             popoverRef.current.instanceRef.show();
    //         }
    //     }
    // }, [orderReducer.action]);

    useEffect(() => {
        setUrl(checkoutLink);
    }, [checkoutLink]);

    // useEffect(() => {
    //     if (!isGenerateCheckout) getLinkCheckout();
    // }, [isGenerateCheckout]);

    useEffect(() => {
        if (!selectedCheckoutConfig || !selectedCheckoutConfig.checkout_config_id) {
            let selectedCheckoutConfigTg = checkoutConfigs[0] || {};
            setCheckoutConfig(selectedCheckoutConfigTg);
        }
    }, [checkoutConfigs]);

    useEffect(() => {
        if (selectedCheckoutConfig.checkout_config_id) {
            setUrl(`${checkoutLink}?checkout_config_id=${selectedCheckoutConfig.checkout_config_id}`);
        }
    }, [selectedCheckoutConfig]);

    const remove = () => {
        // removeShipping();
        popoverRef.current.instanceRef.hide();
    };

    const onShow = () => {
        if (!checkoutLink) {
            getLinkCheckout();
        }
    };

    return (
        <Popover
            ref={popoverRef}
            onShow={onShow}
            wrapperProps={{ className: 'price-option' }}
            wrapperPopoverProps={{ className: 'price-box popover-checkout ladiui popover-right' }}
            content={
                <div>
                    <div className='popover-shipping'>
                        <div className='display-flex-center'>
                            <div className='mr-16 max-w-200px'>
                                <div className='ladiui text-14 mb-4'>{t('SETTINGS.CHECKOUT_CONFIG')}</div>
                                <Dropdown
                                    data={checkoutConfigs}
                                    currentKey={selectedCheckoutConfig.checkout_config_id}
                                    wrapperClassName='checkout-config-list'
                                    onSelectItem={(item) => setCheckoutConfig(item)}
                                    _key='checkout_config_id'
                                    _value='name'
                                />
                            </div>
                            <div>
                                <div className='ladiui text-14 mb-4'>{t('PRODUCTS.LB_PATH')}</div>

                                <div
                                    className='url-checkout display-flex-center'
                                    data-tooltip={`${checkoutLink}?checkout_config_id=${selectedCheckoutConfig.checkout_config_id}`}
                                    data-tooltip-position='top'
                                    onClick={() => {
                                        baseHelper.copyToClipboard(
                                            `${checkoutLink}?checkout_config_id=${selectedCheckoutConfig.checkout_config_id}`
                                        );
                                    }}
                                >
                                    <p className='mr-12 url'> {url}</p>
                                    <div>
                                        <i className='ladiui icon-new new-ldicon-copy' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex-end actions'>
                        <button className='ladiui btn btn-secondary btn-sm  mr-8' data-dissmiss='discount-popup' onClick={remove}>
                            {t('ACTIONS.REMOVE')}
                        </button>
                    </div>
                </div>
            }
        >
            <button className={`ladiui btn btn-secondary display-flex-center `} onClick={() => {}}>
                <i className='ldicon-link mr-8' />
                {t('ORDERS.GET_LINK_CHECKOUT_CREATE')}
            </button>
        </Popover>
    );
}
export default PopperCheckout;
