import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';
import SelectOption from '../../../components/SelectOption';
import LoadingTable from '../../../components/LoadingTable';

import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";

import reportActions from "../../../redux/futures/report/actions";
import * as reportTypes from "../../../redux/futures/report/types";

export default function ReportBySource(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    let { revenue_source } = props;

    const reportReducer = useSelector((state) => state.report);
    const storeReducer = useSelector((state) => state.store);

    const [isLoading, setIsLoading] = useState(false);

    const getOption = () => {

        if (!revenue_source || revenue_source.length === 0) return [];
        let seriesData = [];
        seriesData = revenue_source.map((item) => {
            if (!item.refund)
                item.refund = 0;
            return {
                name: item.source,
                y: item.total - item.refund,
                num_order: item.num_order
            };
        });
        let options = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            subtitle: {
                text: ""
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    showInLegend: true
                },
            },

            legend: {
                // display: "flex"
                style: {
                    display: "none"
                },
                itemStyle: {
                    fontSize: '12px',
                    letterSpacing: '0.25px',
                    fontWeight: '500',
                    color: '#0A1944',
                    backgroundColor: "red",
                    padding: "8px 12px"
                },
            },
            tooltip: {
                valueSuffix: "đ",
                useHTML: true,
                formatter: function () {
                    let text = ``;
                    text += `
                        <br/>
                            <div class="text-tooltip">  
                            <div class="point-tolltip" style="background:${this.point.color}"></div> 
                            <div>
                                ${'Doanh thu'}: ${baseHelper.formatMoneyPostFix(this.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
                            </div> 
                            </div>
                            <br/>
                            <div class="text-tooltip">  
                            <div class="point-tolltip" style="background:${this.point.color}"></div> 
                            <div>
                            ${'Số lượng'}: ${baseHelper.formatNumber(this.point.num_order)}
                            </div> 
                        </div>
                        `
                    return (
                        `${this.point.name}
                            <br/>
                        ${text}`
                    );
                },
            },
            series: [
                {
                    name: "Doanh thu",
                    data: seriesData,
                    size: "90%",
                    innerSize: "80%",
                    dataLabels: {
                        formatter: function () {
                            return this.y > 5 ? this.point.name : null;
                        },
                        color: "#ffffff",
                        distance: -30
                    }
                }
            ],
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: '100%'
                        },
                        chartOptions: {
                            series: [
                                {},
                                {
                                    id: "versions",
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        };
        return options;
    }

    return (
        <div className='block-content-item'>
            <div className="chart-item revenue-stage-chart" id='report-by-source-new'>
                <h3>{t("REPORTS.REVENUE_BY_SOURCE")}</h3>
                <div className="content-chart">{!isLoading ? <PieChart highcharts={Highcharts} options={getOption()} containerProps={{ style: { height: "300px" } }} /> : <LoadingTable />}</div>
            </div>
        </div>
    );
}
