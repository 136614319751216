import React from 'react';
import Modal from '../../../../components/Modal';
import PropTypes from 'prop-types';
import baseHelper from '../../../../helpers/BaseHelper';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { compact, map, isEqual, find, includes, remove, cloneDeep } from 'lodash';

import orderActions from '../../../../redux/futures/order/actions';
import * as orderTypes from '../../../../redux/futures/order/types';

import NumberInput from '../../../../components/NumberInput';
import { appLocalStorage } from '../../../../localforage';
import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';

class ModalRefund extends React.Component {
    static propTypes = {
        orderID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        paymentStatus: PropTypes.string.isRequired,
        refundAll: PropTypes.bool,
        visible: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onOk: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        refundAll: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            orderDetails: [],
            selectedIDs: [],
            tickets: [],
            shippingRefund: 0,
            available_refund: 0,
            discount_fee: 0,
            refund: 0,
            restock: false,
            refundNote: '',
            userInfo: {
                currentStore: {},
            },
        };

        this.formRefundRef = new Set();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.order.action != nextProps.order.action) {
            if (nextProps.order.action == orderTypes.SUGGEST_REFUND) {
                if (nextProps.order.status) {
                    const listTicket = cloneDeep(nextProps.order.tickets);
                    const listDetails = cloneDeep(nextProps.order.orderDetails);
                    for (let i = 0; i < listDetails.length; i++) {
                        for (let k = 0; k < listTicket.length; k++) {
                            if (listTicket[k].order_detail_id == listDetails[i].order_detail_id) {
                                listTicket[k].price = listDetails[i].price;
                            }
                        }
                    }
                    this.setState({
                        orderDetails: listDetails,
                        shippingRefund: nextProps.order.shippingRefund,
                        available_refund: nextProps.order.available_refund,
                        discount_fee: nextProps.order.discount_fee,
                        refund: nextProps.order.refund,
                        shipping_refund: nextProps.order.shipping_refund,
                        sub_total: nextProps.order.sub_total,
                        total_quantity: nextProps.order.total_quantity,
                        tickets: listTicket
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }
        }
    }

    componentDidMount() {
        this.suggest();
    }

    suggest = () => {
        const data = {
            order_id: this.props.orderID,
            order_details: this.state.orderDetails,
            shipping_refund: this.state.shippingRefund,
        };
        if (!isEqual(this.oldData, data)) {
            this.oldData = data;
            this.props.suggest(data);
        }
    };

    refund = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.formRefundRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const data = {
            order_id: this.props.orderID,
            restock: this.state.restock,
            order_details: this.state.orderDetails,
            ticket_seat_ids: this.state.selectedIDs
        };

        if (this.props.paymentStatus == appConfig.PAYMENT_STATUS.SUCCESS.CODE) {
            data.refund = this.state.refund;
        }

        this.props.onOk(data);
    };

    // onChangeQuantity = (event, index) => {
    //     const { orderDetails } = this.state;

    //     const selected = orderDetails[index];
    //     selected.quantity = baseHelper.parseInt(event.target.value);

    //     this.setState({
    //         orderDetails,
    //     });
    // };
    onChangeQuantity = (value, index, max = -1) => {
        const { orderDetails } = this.state;
        const selected = orderDetails[index];
        const message = this.props.t('ORDERS.MSG_MAX_REFUND');

        if (value < 0) {
            return;
        }

        if (max > -1 && value > max) {
            window.LadiUI.toastCustom('danger', '', message);
            return;
        }
        selected.quantity = baseHelper.parseInt(value);

        this.setState(
            {
                orderDetails,
            },
            () => {
                this.suggest();
            }
        );
    };

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    checkItem = (event, ticketID) => {
        const { checked } = event.target;
        const { selectedIDs, orderDetails, tickets } = this.state;
        let item = false;
        let index = false;
        for (let i = 0; i < orderDetails.length; i++) {
            for (let k = 0; k < tickets.length; k++) {
                if (tickets[k].order_detail_id == orderDetails[i].order_detail_id && tickets[k].ticket_seat_id == ticketID) {
                    index = i;
                    item = orderDetails[i];
                    break;
                }
            }
        }
        if (checked) {
            if (!includes(selectedIDs, ticketID)) {
                selectedIDs.push(ticketID);
            }

            if (selectedIDs.length == this.state.tickets.length) {
                document.getElementById('checkAllItem').checked = true;
            }

            this.onChangeQuantity(item.quantity + 1, index, item.available_quantity);
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(selectedIDs, (selectedTicketID) => selectedTicketID == ticketID);
            this.onChangeQuantity(item.quantity - 1, index, item.available_quantity);
        }

        this.setState({
            selectedIDs,
        });
    };

    checkAllItem = (event) => {
        const { checked } = event.target;
        const { orderDetails } = this.state;
        let selectedIDs = [];
        if (checked) {
            selectedIDs = map(this.state.tickets, (ticket) => ticket.ticket_seat_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
            for (let i = 0; i < orderDetails.length; i++) {
                orderDetails[i].quantity = orderDetails[i].available_quantity;

            }
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
            for (let i = 0; i < orderDetails.length; i++) {
                orderDetails[i].quantity = 0;
            }
        }

        this.setState({
            selectedIDs,
            orderDetails
        },
            () => {
                this.suggest();
            });
    };

    render() {
        const { t, refundAll } = this.props;
        const { selectedIDs, tickets, orderDetails, shippingRefund, restock, refundNote } = this.state;
        const currencyCode = this.state.userInfo.currentStore.currency_code;
        const currencySymbol = this.state.userInfo.currentStore.currency_symbol;
        const { subTotal, discountFee, shippingFee, availableShippingRefund, availableRefund, totalQuantity } = this.props.order;
        console.log('tickets = ', tickets, '-orderDetails:', orderDetails)
        const isLoadingSuggest = this.props.order.loading && orderTypes.SUGGEST_REFUND == this.props.order.waiting;
        const isRefundMode = this.props.paymentStatus == appConfig.PAYMENT_STATUS.SUCCESS.CODE && !refundAll;

        return (
            <Modal
                id='modal-refund'
                title={isRefundMode ? t('ORDERS.REFUND') : t('ORDERS.RESTOCK')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.refund}
                width={1070}
                bodyClassName={`modal-refund-content`}
                // bodyStyles={{ minHeight: '35vh' }}
                isLoading={isLoadingSuggest}
                disableOk={isRefundMode ? this.state.refund <= 0 : !restock}
            >
                <div className='ladiui flex-start-between '>
                    <div className='w-578'>
                        <div className='ladiui container-place w-578'>
                            {tickets.length > 0 && <React.Fragment>
                                <div className='table-wrap'>
                                    <table className='ladiui table text-left table-order main-table'>
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th className='ladiui checkall'>
                                                    <input
                                                        id='checkAllItem'
                                                        onClick={this.checkAllItem}
                                                        type='checkbox'
                                                        className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                    />
                                                </th>

                                                {/* <th scope='col' className='ladiui checkall fixed-side fix-column'>
                                                    <span className=''>{t('TICKET_SEAT.CODE')}</span>

                                                </th> */}
                                                <th scope='col'>Danh sách vé</th>
                                                <th scope='col' >Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tickets.map((item, index) => {
                                                let isSelected = selectedIDs.includes(item.ticket_seat_id);
                                                // if (item.status == appConfig.TICKET_SEAT_STATUS.CANCELED) {
                                                //     return <tr key={item.ticket_seat_id}></tr>;
                                                // }
                                                return (
                                                    <React.Fragment key={item.ticket_seat_id}>
                                                        <tr className={`ladiui table-vertical main`}>
                                                            <td className=' fix-column '>
                                                                <div className='display-flex-center '>
                                                                    <input
                                                                        type='checkbox'
                                                                        onClick={(event) => this.checkItem(event, item.ticket_seat_id)}
                                                                        onChange={() => { }}
                                                                        checked={isSelected}
                                                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-16'
                                                                    />
                                                                    {/* <div className=''>{item.code}</div> */}
                                                                </div>
                                                            </td>
                                                            <td key={index}>
                                                                {item.option_name}
                                                                <div>
                                                                    {item.first_name && <div>{item.first_name}</div>}
                                                                    {item.email && <div>{item.email}</div>}
                                                                    {item.phone && <div>{item.phone}</div>}
                                                                </div>
                                                            </td>

                                                            <td >
                                                                {baseHelper.formatMoneyPostFix(item.price, currencyCode)}
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment>}
                            {tickets.length == 0 && <React.Fragment>
                                <div className='ladiui label-18'>Danh sách hoàn trả</div>
                                <div className='variant-selected-list'>
                                    <div className='variant-selected-item display-flex-center gap-24'>
                                        <div className=' w-290 ladiui heading-label-2'>{t('MENU.PRODUCTS')}</div>
                                        {/* <div className='variant-price-discount ladiui heading-label-2'> {t('COMMON.PRICE')} </div> */}
                                        <div className=' w-80 ladiui heading-label-2'> {t('COMMON.QUANTITY')} </div>
                                        <div className='w-120 ladiui heading-label-2 ladiui text-right'> {t('COMMON.TOTAL_AMOUNT')} </div>
                                    </div>
                                    {orderDetails.map((item, index) => {
                                        let imageSrc = baseHelper.getImageUrl(item.src);
                                        return (
                                            <div className='variant-selected-item display-flex-center gap-24' key={index}>
                                                <div className='variant-refund w-290  display-flex-center'>
                                                    <img className='ladiui variant-image-48 mr-24' src={imageSrc} />

                                                    <div className='text-heading-02 ladiui label-16'>{item.product_name}</div>
                                                </div>
                                                <div className='w-80'>
                                                    {item.available_quantity <= 0 && t('ORDERS.PRODUCT_RESTOCK_ALL')}
                                                    {/* {item.available_quantity > 0 && (
                                                    <Input
                                                        id={`quantity-${item.order_detail_id}`}
                                                        type='number'
                                                        min={0}
                                                        max={item.available_quantity}
                                                        value={item.quantity}
                                                        onChange={(event) => this.onChangeQuantity(event, index)}
                                                        onBlur={() => this.suggest()}
                                                        style={{ textAlign: 'center' }}
                                                    />
                                                )} */}
                                                    {item.available_quantity > 0 && (
                                                        <div className='variant-quantity-up-down'>
                                                            {/* <span>{item.quantity}</span> */}
                                                            <input
                                                                type='number'
                                                                max={item.available_quantity}
                                                                value={item.quantity}
                                                                onChange={(e) =>
                                                                    this.onChangeQuantity(e.target.value, index, item.available_quantity)
                                                                }
                                                            />
                                                            <div className='variant-up-down'>
                                                                <i
                                                                    className='new-ldicon-arrow-up'
                                                                    onClick={() =>
                                                                        this.onChangeQuantity(item.quantity + 1, index, item.available_quantity)
                                                                    }
                                                                />
                                                                <i
                                                                    className='new-ldicon-arrow-down'
                                                                    onClick={() =>
                                                                        this.onChangeQuantity(item.quantity - 1, index, item.available_quantity)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='w-120 ladiui text-right'>
                                                    {baseHelper.formatMoneyPostFix(item.total, currencyCode)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </React.Fragment>
                            }
                        </div>
                        <div className='w-578 ladiui container-place mt-20'>
                            <div className='ladiui label-18'>{t('ORDERS.REFUND_NOTE_LABEL')}</div>
                            <textarea
                                className='order-note-value mt-24'
                                placeholder={t('ORDERS.REFUND_NOTE_LABEL')}
                                value={refundNote}
                                onChange={(e) => this.setState({ refundNote: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className='w-424'>
                        <div className='ladiui container-place w-424'>
                            <div className='ladiui label-18 mb-24'>Nội dung hoàn trả</div>
                            {isRefundMode && (
                                <>
                                    {availableRefund > 0 && (
                                        <div className=''>
                                            <div className='pb-20 ladiui flex-center-between'>
                                                <span> {t('ORDERS.MONEY_CAN_REFUND')}:</span>
                                                {baseHelper.formatMoneyPostFix(availableRefund, currencyCode)}
                                            </div>

                                            {/* <div className='ladiui-row'>
                                        <div className='col-50'>
                                            <label className='ladiui-label'>{t('ORDERS.LB_REFUND')}</label>
                                        </div>
                                        <div className='col-50'>
                                            <NumberInput
                                                name='refund'
                                                prefix={currencySymbol}
                                                validationName={t('ORDERS.LB_REFUND_VALIDATION')}
                                                value={this.state.refund}
                                                onChange={this.onChangeInput}
                                                validations={{
                                                    max: availableRefund,
                                                }}
                                                ref={(ref) => this.formRefundRef.add(ref)}
                                            />
                                        </div>
                                    </div> */}
                                        </div>
                                    )}
                                    {/* {availableShippingRefund > 0 && 
                                    } */}
                                </>
                            )}
                            <div className='mt-20 pt-20 ladiui border-t-50p'>
                                {isRefundMode && availableShippingRefund > 0 && (
                                    <div className='ladiui flex-center-between'>
                                        <div>
                                            {`${t('ORDERS.SHIPPING')} (${t(
                                                'ORDERS.REMAIN',
                                                baseHelper.formatMoneyPostFix(availableShippingRefund, currencyCode)
                                            )})`}{' '}
                                        </div>
                                        <NumberInput
                                            groupClassName='w-150'
                                            className='w-150'
                                            name='shippingRefund'
                                            prefix={currencySymbol}
                                            value={shippingRefund}
                                            onChange={this.onChangeInput}
                                            onBlur={this.suggest}
                                        />
                                    </div>
                                )}

                                {isRefundMode && availableRefund > 0 && (
                                    <div className='ladiui form-group' style={{ marginTop: 25 }}>
                                        <div className='ladiui flex-center-between'>
                                            <div className=''>
                                                <label className='ladiui-label'>{t('ORDERS.LB_REFUND')}</label>
                                            </div>
                                            <div className=''>
                                                <NumberInput
                                                    groupClassName='w-150'
                                                    className='w-150'
                                                    name='refund'
                                                    prefix={currencySymbol}
                                                    validationName={t('ORDERS.LB_REFUND_VALIDATION')}
                                                    value={this.state.refund}
                                                    onChange={this.onChangeInput}
                                                    validations={{
                                                        max: availableRefund,
                                                    }}
                                                    ref={(ref) => this.formRefundRef.add(ref)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='ladiui form-group ladiui display-flex-center'>
                                    <input
                                        id='restock'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        onChange={() => {
                                            if (!this.state.restock) {
                                                if (totalQuantity <= 0) {
                                                    return;
                                                }
                                            }
                                            this.setState({
                                                restock: !this.state.restock,
                                            });
                                        }}
                                        checked={restock}
                                    />
                                    <label htmlFor='restock'> {t('ORDERS.RESTOCK_PRODUCT', totalQuantity)}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        suggest: (data) => dispatch(orderActions.suggestRefund(data)),
        refund: (data) => dispatch(orderActions.refund(data)),
    };
};

const mapStateToProps = (state) => ({
    order: { ...state.order },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalRefund));
