import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Input from '../../../../components/Input';
import { map, find } from 'lodash';
import ModalAddCategory from '../ModalAddCategory';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import productCategoryActions from '../../../../redux/futures/product_category/actions';
import * as productCategoryTypes from '../../../../redux/futures/product_category/types';

import baseHelper from '../../../../helpers/BaseHelper';

import appConfig from '../../../../config/app';

import { SketchPicker } from 'react-color';

import Popover from '../../../../components/Popover';

import { SimpleTreeItemWrapper, FolderTreeItemWrapper, SortableTree, TreeItemComponentProps, TreeItems } from 'dnd-kit-sortable-tree';

// import 'react-sortable-tree/style.css';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ModalAddMenuItem from '../ModalAddMenuItem';
import Dropdown from '../../../../components/Dropdown';

const CDN = 'https://w.ladicdn.com/';

const PanelSettingMenuBanner = forwardRef((props, ref) => {
    let { isShow, currentDataMenu, handlePreviewPageCourse, fontList } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const productCategoryReducer = useSelector((state) => state.productCategory);

    const courseReducer = useSelector((state) => state.course);

    const [categories, setCategories] = useState([]);
    const [dataConfig, setDataConfig] = useState({
        background: '#002333',
        text_color: '#FFFFFF',
    });

    const [heroSection, setHeroSection] = useState({
        background: '#002333',
        text_color: '#FFFFFF',
    });

    const [isShowInputAddLink, setIsShowInputAddLink] = useState(false);
    const [indexBanner, setIndexBanner] = useState('');
    const [isShowBlockAddCategory, setIsShowBlockAddCategory] = useState(false);

    const [valueSeachCategory, setValueSearchCategory] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesLength, setSelectedCategoriesLength] = useState(0);

    const [verticalMenus, setVerticalMenus] = useState([]);
    const [horizontalMenus, setHorizontalMenus] = useState([]);
    const [isShowModalAddCategory, setIsShowModalAddCategory] = useState(false);
    const [isShowModalEditMenuItem, setIsShowModalEditMenuItem] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState({
        index: null,
        value: null,
        type: appConfig.MENU_CONFIG_TYPES.VERTICAL
    });
    const [font, setFont] = useState(null);

    const inputsRef = new Set();
    const wrapperRef = useRef();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                background: dataConfig.background,
                text_color: dataConfig.text_color,
                hero_section: {
                    background: heroSection.background,
                    text_color: heroSection.text_color,
                },
                menu_category_ids: selectedCategories && selectedCategories.length > 0 ? selectedCategories : [],
                vertical_menu: verticalMenus && verticalMenus.length > 0 ? verticalMenus : [],
                horizontal_menu: horizontalMenus && horizontalMenus.length > 0 ? horizontalMenus : [],
                font: font,
                // menu_category_ids: selectedCategories && selectedCategories.length > 0 ? getDataMenuCategoryIds() : [],
            };
        },
    }));

    useEffect(() => {
        if (productCategoryReducer && productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    setCategories(productCategoryReducer.listSelects);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (currentDataMenu) {
            setDataConfig({
                background: currentDataMenu.background || '#002333',
                text_color: currentDataMenu.text_color || '#FFFFFF',
            });
            if (currentDataMenu.menu_categories && currentDataMenu.menu_categories.length > 0) {
                let data = [];
                currentDataMenu.menu_categories.map((item) => {
                    data.push({
                        ...item,
                        title: item.name ? item.name : '',
                        children: item.children && item.children.length > 0 ? item.children : [],
                    });
                });
                // setSelectedCategories(currentDataMenu.menu_categories);
                setSelectedCategories(data);
            }

            if (currentDataMenu.font) {
                setFont(currentDataMenu.font || null);
            }

            if (currentDataMenu.vertical_menu && currentDataMenu.vertical_menu.length > 0) {
                setVerticalMenus(currentDataMenu.vertical_menu || []);
            }

            if (currentDataMenu.horizontal_menu && currentDataMenu.horizontal_menu.length > 0) {
                setHorizontalMenus(currentDataMenu.horizontal_menu || []);
            }

            if (currentDataMenu && currentDataMenu.hero_section) {
                setHeroSection({
                    background: currentDataMenu.hero_section.background || '#002333',
                    text_color: currentDataMenu.hero_section.text_color || '#FFFFFF',
                });
            }
        }
    }, [currentDataMenu]);

    const countLengthMenus = async (menuIds) => {
        let length = 0;
        for await (let menu of menuIds) {
            length = length + 1;
            if (menu.children) {
                let length2 = await countLengthMenus(menu.children);
                length = length + length2;
            }
        }

        return length;
    };

    useEffect(() => {
        const getMenuSelectedLength = async () => {
            let _selectedCategoriesLength = await countLengthMenus(selectedCategories);
            setSelectedCategoriesLength(_selectedCategoriesLength);
        };
        if (selectedCategories && selectedCategories.length > 0) {
            getMenuSelectedLength();
        }
    }, [selectedCategories]);

    const onChangeInput = (event) => {
        let { value } = event.target;
        setDataConfig(
            produce(dataConfig, (draft) => {
                draft[event.target.name] = value;
            })
        );
    };

    const setColorPicker = (color) => {
        setDataConfig({
            ...dataConfig,
            background: color,
        });
    };

    const renderColorPickerItem = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${dataConfig.background == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setColorPicker(value)}
                >
                    {dataConfig.background == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const renderHeroSectionColorPickerItem = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${heroSection.background == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setColorPickerHeroSection(value)}
                >
                    {heroSection.background == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    const setColorPickerHeroSection = (color) => {
        setHeroSection({
            ...heroSection,
            background: color,
        });
    };

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    const onKeyDownInput = (event, _index) => {
        if (event.key === 'Enter') {
            let selectedBanners = dataConfig.banners || [];
            selectedBanners.map((item, index) => {
                if (index == _index) {
                    item.link = event.target.value;
                }
            });

            setDataConfig({
                ...dataConfig,
                banners: selectedBanners,
            });

            setIsShowInputAddLink(false);
        }
    };

    const handleShowBlockAddCategory = (status) => {
        if (status) {
            let payload = {
                search: {
                    type: 'Course',
                },
            };
            dispatch(productCategoryActions.listSelectCategory(payload));
        }
        setValueSearchCategory('');
        setIsShowBlockAddCategory(status);
    };

    const handleAddCategories = (data) => {
        // let contents = [];
        let contents = [...selectedCategories];
        if (data && data.length > 0) {
            data.map((item) => {
                contents.push({
                    ...item,
                    title: item.name ? item.name : '',
                    children: [],
                });
            });
        }
        setSelectedCategories(contents);

        setIsShowBlockAddCategory(false);
    };

    // const handleDeleteCategoryItem = (item) => {
    //   let _selectedDeleteCategories = selectedCategories.filter((_item) => _item.product_category_id != item.product_category_id);

    //   if (_selectedDeleteCategories) {
    //     setSelectedCategories(_selectedDeleteCategories);
    //   }
    // };

    // Kéo thả giao diện

    //hàm giúp thực hiện việc sắp xếp khi kéo thả
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // const onDragEndCategory = (result) => {
    //   if (!result.destination) {
    //     return;
    //   }

    //   const _selectedCategories = reorder(selectedCategories, result.source.index, result.destination.index);

    //   setSelectedCategories(_selectedCategories);
    // };

    // const listCategoryItem = () => {
    //   return selectedCategories.map((item, index) => {
    //     return (
    //       <Draggable key={index} draggableId={"" + index} index={index}>
    //         {(provided, snapshot) => (
    //           <div
    //             key={index}
    //             className="category-list-item"
    //             ref={provided.innerRef}
    //             {...provided.draggableProps}
    //             {...provided.dragHandleProps}
    //             style={{
    //               ...provided.draggableProps.style,
    //               userSelect: "none",
    //               background: snapshot.isDragging ? "#e8f0fe" : "none",
    //             }}
    //           >
    //             <div className="content-top-item">
    //               <div className="icon-name flex">
    //                 <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg" />
    //                 <span className="ml-16 ladiui-label pb-0 w100">{item.name}</span>
    //               </div>
    //               <div className="action-right">
    //                 <div className="btn-delete cursor-pointer" onClick={() => handleDeleteCategoryItem(item)}>
    //                   <i className="ladi-icon icon-bin"></i>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //       </Draggable>
    //     );
    //   });
    // };

    const handleActionPreviewPageCourse = () => {
        handlePreviewPageCourse();
    };

    const handleShowBackgroundPicker = () => {
        document.getElementById('picker-id-background-color').click();
    };

    const handleChangeBackgroundPicker = (color) => {
        setDataConfig({
            ...dataConfig,
            background: color.hex,
        });
    };

    const handleShowHeroSectionPicker = () => {
        document.getElementById('picker-id-hero-section').click();
    };

    const handleChangeBackgroundHeroSectionPicker = (color) => {
        setHeroSection({
            ...heroSection,
            background: color.hex,
        });
    };

    const handleChangeTreeData = (data) => {
        setSelectedCategories(data);
    };

    const deleteTableTreeItem = async (menus, productCateId) => {
        let _selectedCates = [];
        let isExit = menus.some((value) => value.product_category_id == productCateId);
        if (isExit) {
            _selectedCates = menus.filter((item) => item.product_category_id != productCateId);
            return _selectedCates;
        }

        for await (let menu of menus) {
            if (menu.children) {
                menu.children = await deleteTableTreeItem(menu.children, productCateId);
            }
            _selectedCates.push(menu);
        }

        return _selectedCates;
    };

    const handleDeleteTableTree = async (e, data) => {
        e.stopPropagation();
        if (data.product_category_id) {
            let _selectedCates = [];
            let isExit = selectedCategories.some((value) => value.product_category_id == data.product_category_id);
            if (isExit) {
                _selectedCates = selectedCategories.filter((item) => item.product_category_id != data.product_category_id);
                setSelectedCategories(_selectedCates);
                return;
            }

            for await (let item of selectedCategories) {
                if (item.children) {
                    item.children = await deleteTableTreeItem(item.children, data.product_category_id);
                }
                _selectedCates.push(item);
            }

            setSelectedCategories(_selectedCates);
        }
    };

    const TreeItem = React.forwardRef((props, ref) => {
        return (
            <SimpleTreeItemWrapper {...props} ref={ref}>
                <div className='tree-item-content-action'>
                    <span>{props.item.name}</span>
                    <span
                        onClick={(event) => handleDeleteTableTree(event, props.item)}
                        style={{
                            position: 'absolute',
                            right: 10,
                            cursor: 'pointer',
                        }}
                    >
                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg' />
                    </span>
                </div>
            </SimpleTreeItemWrapper>
        );
    });

    const handleDeleteVerticalMenuItem = (menuIndex, _item) => {
        let _verticalMenus = [...verticalMenus];
        let _verticalMenusDeleted = _verticalMenus.filter((item, index) => index != menuIndex);
        if (_verticalMenusDeleted) {
            setVerticalMenus(_verticalMenusDeleted);
        }
    };

    const handleDeleteHorizontalMenuItem = (menuIndex, _item) => {
        let _horizontalMenus = [...horizontalMenus];
        let _horizontalMenusDeleted = _horizontalMenus.filter((item, index) => index != menuIndex);
        if (_horizontalMenusDeleted) {
            setHorizontalMenus(_horizontalMenusDeleted);
        }
    };

    const handleEditMenuItem = (index, item, type) => {
        setIsShowModalEditMenuItem(true);
        setSelectedMenuItem({
            data: item,
            index,
            type,
            isUpdate: true
        });
    };

    const renderVerticalMenuItem = () => {
        return verticalMenus.map((item, index) => {
            return (
                <Draggable key={index} draggableId={'' + index} index={index}>
                    {(provided, snapshot) => (
                        <div
                            className='category-item ls-rounded-4'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                            }}
                        >
                            <div className='category-name'>
                                <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' />
                                <div className='flex ml-16 menu-item-content'>
                                    {
                                        item.icon ? <i className="material-symbols-outlined">{item.icon}</i> :  <i className="icon-empty">{item.icon}</i>
                                    }
                                   
                                    <a className='ladiui-label pb-0 ls-font-medium'>
                                        {item.title}
                                    </a>
                                </div>
                            </div>
                        
                            <div className='action-item'>
                                <span className='btn-edit cursor-pointer'>
                                    <img
                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                        onClick={() => handleEditMenuItem(index, item, appConfig.MENU_CONFIG_TYPES.VERTICAL)}
                                    />
                                </span>
                                <span
                                    className='btn-delete cursor-pointer ml-16'
                                    onClick={() => handleDeleteVerticalMenuItem(index, item)}
                                >
                                    <i className='ladi-icon icon-bin' />
                                </span>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
    };

    const renderHorizontalMenuItem = () => {
        return horizontalMenus.map((item, index) => {
            return (
                <Draggable key={index} draggableId={'' + index} index={index}>
                    {(provided, snapshot) => (
                        <div
                            className='category-item ls-rounded-4'
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                            }}
                        >
                            <div className='category-name'>
                                <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' />
                                <div className='flex ml-16 menu-item-content'>
                                    {
                                        item.icon ? <i className="material-symbols-outlined">{item.icon}</i> :  <i className="icon-empty">{item.icon}</i>
                                    }
                                   
                                    <a className='ladiui-label pb-0 ls-font-medium'>
                                        {item.title}
                                    </a>
                                </div>
                            </div>
                        
                            <div className='action-item'>
                                <span className='btn-edit cursor-pointer'>
                                    <img
                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                        onClick={() => handleEditMenuItem(index, item, appConfig.MENU_CONFIG_TYPES.HORIZONTAL)}
                                    />
                                </span>
                                <span
                                    className='btn-delete cursor-pointer ml-16'
                                    onClick={() => handleDeleteHorizontalMenuItem(index, item)}
                                >
                                    <i className='ladi-icon icon-bin' />
                                </span>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
    };

    const handleAddMenuItem = (type = appConfig.MENU_CONFIG_TYPES.VERTICAL) => {
        setIsShowModalEditMenuItem(true);
        setSelectedMenuItem({
            data: null,
            type,
            isUpdate: false
        });
    }

    const handleCreateMenuItem = (menuType, payload) => {
        if (menuType == appConfig.MENU_CONFIG_TYPES.VERTICAL) {
            let _verticalMenus = [...verticalMenus];
            _verticalMenus.push(payload)
            setVerticalMenus(_verticalMenus);
        } else if (menuType == appConfig.MENU_CONFIG_TYPES.HORIZONTAL) {
            let _horizontalMenus = [...horizontalMenus];
            _horizontalMenus.push(payload)
            setHorizontalMenus(_horizontalMenus);
        }

        setIsShowModalEditMenuItem(false);
    }

    const handleUpdateMenuItem = (menuType, menuIndex, payload) => {
        if (menuType == appConfig.MENU_CONFIG_TYPES.VERTICAL) {
            let _verticalMenus = [...verticalMenus];
            _verticalMenus = _verticalMenus.map((item, index) => {
                if (index == menuIndex) {
                    return {
                        ...item,
                        ...payload,
                    }
                }

                return item;
            });
            setVerticalMenus(_verticalMenus);
        } else if (menuType == appConfig.MENU_CONFIG_TYPES.HORIZONTAL) {
            let _horizontalMenus = [...horizontalMenus];
            _horizontalMenus = _horizontalMenus.map((item, index) => {
                if (index == menuIndex) {
                    return {
                        ...item,
                        ...payload,
                    }
                }

                return item;
            });
            setHorizontalMenus(_horizontalMenus);
        }

        setIsShowModalEditMenuItem(false);
    }

    const onDragEndVerticalMenuItem = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(verticalMenus, result.source.index, result.destination.index);
        setVerticalMenus(items);
    };

    const onDragEndHorizontalMenuItem = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(horizontalMenus, result.source.index, result.destination.index);
        setHorizontalMenus(items);
    };

    return (
        <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>

            <div className='ls-flex ls-flex-col ls-items-start ls-gap-24'>
                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Thiết lập menu và màu sắc
                        </div>
                        <div className='ls-setting-row-description'>
                            Màu sắc và font chữ chủ đạo áp dụng cho toàn bộ giao diện một cách tự động.
                        </div>
                    </div>
                    <div className='ls-setting-row-right'>
                        <div>
                            <h3 className='ladiui-label pb-0 flex'>
                                Màu sắc chủ đạo
                                <div
                                    className='item-tracking'
                                    data-tooltip={'Áp dụng với thanh Menu, nút CTA và nút thêm vào giỏ hàng'}
                                    data-tooltip-position='right'
                                >
                                    <span className='flex'>
                                        <i className='ladi-icon icon-c-question ml-8' />
                                    </span>
                                </div>
                            </h3>
                            <div className='flex'>
                                {/* <div className='ladiui form-group pr-24' style={{ borderRight: '1px solid #e5e5e5' }}> */}
                                <div className='ladiui form-group pr-24'>
                                    {/* <label className='ladiui-label bold-400'>Màu nền</label> */}
                                    <div className='content'>
                                        <div className='color-picker flex relative ls-flex-wrap'>
                                            {renderColorPickerItem()}
                                            {!appConfig.LIST_COLOR_PICKER.includes(dataConfig.background) && (
                                                <div className={`color-item checked`} style={{ backgroundColor: dataConfig.background }}>
                                                    <i
                                                        className='ladiui icon icon-checked'
                                                        style={
                                                            dataConfig.background == '#FFFFFF' || dataConfig.background == '#ffffff'
                                                                ? { backgroundColor: '#6d6d6d' }
                                                                : {}
                                                        }
                                                    ></i>
                                                </div>
                                            )}
                                            <div className='color-item btn-add-background' onClick={() => handleShowBackgroundPicker()}>
                                                +
                                            </div>
                                            <Popover
                                                wrapperPopoverProps={{ className: 'popover-picker' }}
                                                styleCustom={{ bottom: '-150', right: '0', position: 'absolute', zIndex: '99999' }}
                                                content={
                                                    <SketchPicker
                                                        color={dataConfig.background}
                                                        onChangeComplete={(color) => handleChangeBackgroundPicker(color)}
                                                    />
                                                }
                                            >
                                                <div id={`picker-id-background-color`}> </div>
                                            </Popover>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='ladiui form-group ml-24'>
                                    <label className='ladiui-label bold-400'>Màu chữ</label>
                                    <div className='content'>
                                        <div className='flex'>
                                            <span
                                                className='color-item select-text-color white'
                                                onClick={() =>
                                                    setDataConfig({
                                                        ...dataConfig,
                                                        text_color: '#FFFFFF',
                                                    })
                                                }
                                            >
                                                {dataConfig.text_color == '#FFFFFF' && <i className='ladiui icon icon-checked'></i>}
                                            </span>
                                            <span
                                                className='color-item select-text-color black'
                                                onClick={() =>
                                                    setDataConfig({
                                                        ...dataConfig,
                                                        text_color: '#000000',
                                                    })
                                                }
                                            >
                                                {dataConfig.text_color == '#000000' && <i className='ladiui icon icon-checked'></i>}
                                            </span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='ls-flex ls-flex-col ls-items-start ls-gap-4 w-full mt-24'>
                            <h3 className='ladiui-label pb-0 flex'>
                                Font chữ chủ đạo
                            </h3>
                            <div className='ls-flex ls-flex-col ls-items-start ls-gap-12 w-full'>
                                <Dropdown
                                    id="font-select"
                                    data={fontList}
                                    onSelectItem={(item) => {
                                        setFont(item);
                                        const fontFamily = item?.family;
                                        baseHelper.loadGoogleFont(fontFamily);
                                        document.getElementById('preview-text').style.fontFamily = fontFamily;
                                    }}
                                    currentKey={font?.family}
                                    currentValue={font?.family}
                                    _key={"family"}
                                    _value={"family"}
                                    validationName={'Font'}
                                    placeHolder={t("Chọn font")}
                                    isShowBlockInputSearch={true}
                                    wrapperClassName='dropdown-fonts w-full'
                                />

                                <div id="preview-text">
                                    Đây là đoạn văn mẫu để xem trước font chữ.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Menu dọc
                        </div>
                        <div className='ls-setting-row-description'>
                            Hiển thị bên dưới các menu chính của trang nằm bên trái màn hình
                        </div>
                    </div>
                    <div className='ls-setting-row-right block-suggestion-category'>
                        {verticalMenus && verticalMenus.length > 0 && (
                            <DragDropContext onDragEnd={onDragEndVerticalMenuItem}>
                                <Droppable droppableId='droppable' type='droppableItem'>
                                    {(provided, snapshot) => (
                                        <div
                                            className='list-categories w-full'
                                            ref={provided.innerRef}
                                            style={{
                                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                            }}
                                        >
                                            {renderVerticalMenuItem()}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}

                        <div className='ladiui border-none btn-add-option mb-12' onClick={() => handleAddMenuItem(appConfig.MENU_CONFIG_TYPES.VERTICAL)}>
                            <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                            <div className='btn-add-product'>Thêm menu</div>
                        </div>
                    </div>
                </div>

                <div className='ls-divide-1'></div>

                <div className='ls-setting-row'>
                    <div className='ls-setting-row-left'>
                        <div className='ls-setting-row-title'>
                            Menu ngang
                        </div>
                        <div className='ls-setting-row-description'>
                            Hiển thị thanh thông tin trên cùng của trang
                        </div>
                    </div>
                    <div className='ls-setting-row-right block-suggestion-category'>
                        {horizontalMenus && horizontalMenus.length > 0 && (
                            <DragDropContext onDragEnd={onDragEndHorizontalMenuItem}>
                                <Droppable droppableId='droppable' type='droppableItem'>
                                    {(provided, snapshot) => (
                                        <div
                                            className='list-categories w-full'
                                            ref={provided.innerRef}
                                            style={{
                                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                            }}
                                        >
                                            {renderHorizontalMenuItem()}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}

                        <div className='ladiui border-none btn-add-option mb-12' onClick={() => handleAddMenuItem(appConfig.MENU_CONFIG_TYPES.HORIZONTAL)}>
                            <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                            <div className='btn-add-product'>Thêm menu</div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowBlockAddCategory && (
                <ModalAddCategory
                    onClose={() => handleShowBlockAddCategory(false)}
                    isShow={isShowBlockAddCategory}
                    dataSelectCategories={categories || []}
                    selectedCategories={selectedCategories || []}
                    handleAddCategories={handleAddCategories}
                    innerStyle={{ display: 'none' }}
                />
            )}

            {isShowModalEditMenuItem && (
                <ModalAddMenuItem
                    isShow={isShowModalEditMenuItem}
                    onClose={(status) => setIsShowModalEditMenuItem(status)}
                    selectedData={selectedMenuItem}
                    handleCreateMenuItem={handleCreateMenuItem}
                    handleUpdateMenuItem={handleUpdateMenuItem}
                />
            )}
        </div>
    );
});
PanelSettingMenuBanner.propTypes = {};

export default PanelSettingMenuBanner;
