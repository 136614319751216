import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import { omit } from "lodash";
import i18n from "../i18n";
import "react-dates/lib/css/_datepicker.css";
import "../assets/css/ladi_daterangepicker.css";
import baseHelper from "../helpers/BaseHelper";

class DateRangePickerCompare extends React.Component {
    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();

        let { currentStartDate, currentEndDate } = this.props;

        let _startDate = this.moment(new Date(currentStartDate));
        let _endDate = this.moment(new Date(currentEndDate));


        let _days = _endDate.diff(_startDate, 'days');

        let _toDateCompare = _startDate.subtract(1, "days");
        let _fromDateCompare = this.moment(_toDateCompare).subtract(_days, "days");

        let _toDateYearCompare = this.moment(new Date(currentEndDate)).subtract(1, "year");
        let _fromDateYearCompare = this.moment(new Date(currentStartDate)).subtract(1, "year");

        let _toDateMonthCompare = this.moment(new Date(currentEndDate)).subtract(1, "month");
        let _fromDateMonthCompare = this.moment(new Date(currentStartDate)).subtract(1, "month");


        // this.previousPeriod =

        // this.last7Day = this.moment().subtract(7, "day");
        // this.last30Day = this.moment().subtract(30, "day");
        // this.firstDayOfMonth = this.moment().startOf("month");
        // this.firstDayOfLastMonth = this.moment().subtract(1, "month").startOf("month");
        // this.lastDayOfLastMonth = this.moment().subtract(1, "month").endOf("month");

        this.presets = [
            {
                text: "Không so sánh",
                // start: this.moment().subtract(1, "day"),
                // end: this.moment().subtract(1, "day"),
                start: null,
                end: null,
                all: false,
                code: ''
            },
            {
                text: "Kỳ trước",
                start: _fromDateCompare,
                end: _toDateCompare,
                all: false,
                code: 1
            },
            {
                text: "Tháng trước",
                start: _fromDateMonthCompare,
                end: _toDateMonthCompare,
                all: false,
                code: 2,
            },
            {
                text: "Năm ngoái",
                start: _fromDateYearCompare,
                end: _toDateYearCompare,
                all: false,
                code: 3
            },
            // {
            //   text: i18n.t("DATE.ALL_TIME"),
            //   start: null,
            //   end: null,
            //   all: true,
            // },
        ];
    }

    isSameDay = (a, b) => {
        if (!this.moment.isMoment(a) || !this.moment.isMoment(b)) return false;
        // Compare least significant, most likely to change units first
        // Moment's isSame clones moment inputs and is a tad slow
        return a.date() === b.date() && a.month() === b.month() && a.year() === b.year();
    };

    renderDatePresets = () => {
        const { startDate, endDate } = this.props;

        return (
            <div className="custom-btn-date-panel" style={{ justifyContent: "start" }}>
                {this.presets.map(({ text, start, end, all, code }) => {
                    const isSelected = (this.isSameDay(start, startDate) && this.isSameDay(end, endDate)) || (!startDate && !endDate && all);
                    return (
                        <button
                            key={text}
                            type="button"
                            className={`custom-btn-date ${isSelected ? "selected" : ""}`}
                            onClick={() => {
                                this.props.onDatesChange({
                                    startDate: start, endDate: end
                                })
                                this.props.setIsCompare(code);
                            }}
                        >
                            {text}
                        </button>
                    );
                })
                }
            </div >
        );
    };

    render() {
        // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
        // example wrapper but are not props on the SingleDatePicker itself and
        // thus, have to be omitted.
        const props = omit(this.props, ["autoFocus", "autoFocusEndDate", "initialStartDate", "initialEndDate", "presets"]);

        const isAllTime = !this.props.startDate && !this.props.endDate;

        const styleAllTime = {
            position: "absolute",
            zIndex: "1",
            background: "#fff",
            width: "210px",
            top: "1px",
            height: "38px",
            left: "1px",
            lineHeight: "38px",
            paddingLeft: "15px",
        };

        return (
            <div style={{ position: "relative" }}>
                {isAllTime ? (
                    <div style={styleAllTime}>
                        {/* {i18n.t("DATE.CHOOSE_DATE")} */}
                        So sánh
                        <i className="ladiui icon icon-calendar-date"></i>
                    </div>
                ) : (
                    ""
                )}
                <DateRangePicker {...props} renderCalendarInfo={this.renderDatePresets} />
            </div>
        );
    }
}

export default DateRangePickerCompare;
