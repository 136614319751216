/** @format */

import React from 'react';

import { connect } from 'react-redux';

import * as productTypes from '../../redux/futures/product/types';
import productActions from '../../redux/futures/product/actions';

import * as variantTypes from '../../redux/futures/product_variant/types';
import variantActions from '../../redux/futures/product_variant/actions';

import { find, includes, isEmpty, map, remove } from 'lodash';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';
import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../components/ConfirmModal';
import LoadingScene from '../../components/LoadingScene';
import { appLocalStorage } from '../../localforage';
import queryString from 'query-string';

import * as productTagTypes from '../../redux/futures/product_tag/types';
import productTagActions from '../../redux/futures/product_tag/actions';
import Tag from '../../components/Tag';

import * as productCategoryTypes from '../../redux/futures/product_category/types';
import productCategoryActions from '../../redux/futures/product_category/actions';

import produce from 'immer';
import FilterConditionCourse from './components/FilterConditionCourse';
import LadiPagination from '../../components/LadiPagination';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import ListTags from '../../components/ListTags';
import Dropdown from '../../components/Dropdown';
import SelectOption from '../../components/SelectOption';
import config from '../../config/config';
import ModalCreateCourse from '../products/components/ModalCreateCourse';
import FilterConditionOrderv2 from '../orders/components/FilterCondition/FilterConditionOrderv2';

class Course extends React.Component {
    constructor(props) {
        super(props);
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.state = {
            activePage: 1,

            searchProductName: '',
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentProductInfo: {},
            userInfo: {},
            selectedIDs: [],
            conditions: defaultConditions,
            productType: '',
            tag_ids: [],
            product_category_ids: [],
            type: '',
            isShowModalCourse: false,
            currentCategoryID: null,
            isShowFilterCondition: false,
            dataConditions: {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            },
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
        };

        this.selectedID = '';
        this.selectedProduct = {};

        this.sortBy = {
            product_id: 'DESC',
        };
        this.searchTagRef = React.createRef();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.list();

        let { product_id: productID, variant_id: variantID } = queryString.parse(this.props.location.search);
        if (productID) {
            let url = this.handelProductEdit(productID);
            window.open(url, '_parent');
            return;
        }

        this.props.listAllTags({
            type: 'Course',
        });
        let payload = {
            search: {
                type: 'Course',
            },
        };
        this.props.listAllCategories(payload);

        const { isShowMessageErr, message } = queryString.parse(this.props.location.search);

        if (isShowMessageErr && message) {
            window.LadiUI.showErrorMessage('Thông báo', message, 'OK');
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.product.action != nextProps.product.action) {
            if (nextProps.product.action === productTypes.RELOAD_PRODUCT) {
                if (nextProps.product.status) {
                    this.setState({
                        currentProductInfo: nextProps.product.product,
                    });
                }
            }

            if (nextProps.product.action === productTypes.UPDATE_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.product.message);
                    this.props.reload(this.state.currentProductInfo.product_id);
                    this.list();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }

            if (includes([productTypes.DELETE_PRODUCT, productTypes.DELETE_PRODUCTS], nextProps.product.action)) {
                if (nextProps.product.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.product.message);

                    this.list();

                    window.LadiUI.closeModal('confirm-product-delete');
                    window.LadiUI.closeModal('confirm-product-deletes');
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }

            if (nextProps.product.action == productTypes.LIST_PRODUCT) {
                if (nextProps.product.status) {
                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.product.message, 'OK');
                }
            }
        }

        if (this.props.variant.action != nextProps.variant.action) {
            if (nextProps.variant.action == variantTypes.GET_VARIANT_INFOS) {
                if (nextProps.variant.status) {
                    const variantInfos = map(nextProps.variant.variantInfos, (item) => {
                        const variantName = `${item.product_variant_id}:1|${this.selectedProduct.name} ${item.name ? `(${item.name})` : ''
                            }`;
                        return variantName;
                    });
                    const variantInfo = variantInfos.join('\r\n');
                    baseHelper.copyToClipboard(variantInfo);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.variant.message, 'OK');
                }
            }
        }
    }

    /**
     * list product
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const { searchProductName, selectedLimit, conditions, search_v2 } = this.state;
        let activePage = conditions.page;

        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;

            this.setState({
                conditions: conditions,
            });
        }

        // Reset selectedIDs
        this.setState({
            selectedIDs: [],
        });
        const tagIDs = this.state.tag_ids ? this.state.tag_ids : [];
        const productCategoryIds = this.state.product_category_ids ? this.state.product_category_ids : [];
        // const tagIDs = this.searchTagRef.current.getIDs();

        const data = {
            search_v2,
            search: {
                name: searchProductName,
                tag_ids: tagIDs,
                product_category_ids: productCategoryIds,
                type: 'COURSE',
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        let inputValue = event?.target?.value;

        this.setState({
            [event.target.name]: event.target.value,
        }, () => {
            if (inputValue == '') {
                this.list();
            }
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDs = [];
        if (checked) {
            selectedIDs = map(this.props.product.products, (product) => product.product_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs,
        });
    };

    checkItem = (event, productID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, productID)) {
                selectedIDs.push(productID);
            }

            if (selectedIDs.length == this.props.product.products.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(selectedIDs, (selectedProductID) => selectedProductID == productID);
        }

        this.setState({
            selectedIDs,
        });
    };

    deletes = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.deletes(this.state.selectedIDs);
    };

    deleteItem = (productID) => {
        this.props.delete(productID);
    };

    sort = (event) => {
        const { classList } = event.target;

        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }
        this.setState({}, () => this.list());
    };

    openModalEdit = (productID) => {
        // this.props.show(productID);
        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.COURSE_UPDATE(productID)}`;
        // this.props.history.push(`/courses/update/${productID}`);
        return url;
    };

    handelProductEdit = (productID) => {
        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.COURSE_UPDATE(productID)}`;
        return url;
        // this.props.history.push(`/courses/update/${productID}`);
    };

    handleCopyCourse = (productID) => {
        let query_ls = { is_copy: true };

        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.COURSE_UPDATE(productID)}&query_ls=${JSON.stringify(query_ls)}`;
        return url;

        // this.props.history.push(`/courses/update/${productID}?is_copy=true`);
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-product-delete');
    };

    openOptionDeletesModal = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-product-deletes');
    };

    copyVariantInfo = (product) => {
        this.selectedProduct = product;
        this.props.getVariantInfos(product.product_id);
    };

    eventSelectAction = (action) => {
        switch (action) {
            case 'DELETE':
                this.openOptionDeletesModal();
                break;
            default:
        }
    };
    clearFilter = (callback) => {
        this.setState(
            {
                tag_ids: [],
                product_category_ids: [],
                type: '',
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case 'tag_ids':
                    this.setState({
                        tag_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;
                case 'product_category_ids':
                    this.setState({
                        product_category_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;
                default:
                    break;
            }
        }
        this.list();
    };

    getNameProductByStatus = (status) => {
        const matched = find(appConfig.PRODUCT_STATUS, (item) => item.CODE == status) || {};
        if (matched) {
            return matched.NAME;
        }
    };

    handleClickTag = (item, selectedTags) => {
        this.setState(
            {
                tag_ids: [...this.state.tag_ids, item],
            },
            () => {
                this.list();
            }
        );
    };

    openModalCreate = () => {
        // let url = `/product-category/create?type=${this.state.type}`;
        // this.props.history.push(url);
        this.setState({
            isShowModalCourse: true,
        });
    };

    hideModalCreate = () => {
        this.setState({
            isShowModalCourse: false,
        });
    };

    parseSearch = (conditions) => {
        let search = {};

        for (const condition of conditions) {
            switch (condition.value) {
                case 'tags':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.multiSelected.map((item) => item.value),
                    };
                    break;
                case 'product_category':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.multiSelected.map((item) => item.value),
                    };
                    break;
                // case 'page_checkout_ids':
                //     filter.page_checkout_ids = [condition.valueFilter.value];
                //     break;
                default:
                    break;
            }
        }

        return search;
    };

    setDataCondition = async (data) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        let index = conditions.findIndex((item) => item.value == data.value);
        if (index > -1) {
            conditions[index] = data;
        } else conditions.push(data);

        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);
        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
                tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
            },
            () => {
                this.list();
            }
        );
    };

    removeCondition = async (index) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        if (index > -1) conditions.splice(index, 1);
        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
            },
            () => {
                this.list();
            }
        );
    };

    render() {
        const { t } = this.props;
        const { totalRecord, totalPage } = this.props.product;
        const { activePage, selectedLimit, searchProductName, userInfo, conditions, isShowFilterCondition, conditionProp, dataConditions } = this.state;
        let fromItem = 0;
        let toItem = 0;

        if ((this.props.product.products || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.product.products.length - 1;
        }

        const isLoadingData = this.props.product.loading && this.props.product.waiting == productTypes.LIST_PRODUCT;
        const isLoadingInfo =
            (this.props.product.loading && includes([productTypes.SHOW_PRODUCT, productTypes.COPY_PRODUCT], this.props.product.waiting)) ||
            (this.props.variant.loading && this.props.variant.waiting == variantTypes.GET_VARIANT_INFOS);
        const isLoadingDelete =
            this.props.product.loading && includes([productTypes.DELETE_PRODUCT, productTypes.DELETE_PRODUCTS], this.props.product.waiting);
        const isSubmitLoading =
            this.props.product.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], this.props.product.waiting);
        const isLoadingReload = this.props.product.loading && productTypes.RELOAD_PRODUCT == this.props.product.waiting;

        const allTags = this.props.productTagReducer.allTags || [];

        const allCategories = this.props.productCategory.listSelects || [];

        const allStorePages = this.props.storePageReducer?.store_pages || [];

        const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

        return (
            <div className='page-content page-course'>
                {isLoadingReload && <LoadingScene blur={true} />}

                <div className='header-page'>
                    <div className='heading'>
                        <h1>Danh sách khoá học</h1>
                        <p>Quản lý các khóa học của bạn một cách dễ dàng.</p>
                    </div>

                    <div className='action-btn flex items-center'>
                        <button
                            type='button'
                            className='ladiui btn btn-primary btn-lg btn-open-modal'
                            data-toggle='modal'
                            onClick={() => {
                                // this.props.history.push(`/courses/create`);
                                this.openModalCreate();
                            }}
                        >
                            <img
                                className='ladiui btn-custom-img'
                                src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                alt=''
                            />
                            <div className='ladiui btn-custom-text '>{t('COURSES.ADD_COURSE')}</div>
                        </button>
                    </div>
                </div>

                <div className='block-filter'>
                    <div className='block-search ls-w-full'>
                        <div className='ladiui search-group ls-w-full'>
                            <input
                                id='keyword_search'
                                className='ladiui search-field dropdown-toggle form-control search-width ls-w-full'
                                name='searchProductName'
                                placeholder='Tìm kiếm'
                                aria-expanded='false'
                                value={searchProductName}
                                onChange={this.onChangeInput}
                                onKeyDown={this.onKeyDownInput}
                            />
                            <i className='ladiui icon icon-search' onClick={this.list}></i>
                            {/* {
                                searchProductName != '' && <i className='ladiui icon icon-ldp-close ls-clear-search-input' onClick={() => {this.setState({searchProductName: ''}, () => {
                                    this.list();
                                })}}></i>
                            } */}
                        </div>

                        {/* <SelectOption
                            items={allCategories || []}
                            onSelectItem={(item) => {
                                this.setState(
                                    {
                                        product_category_ids: item.product_category_id ? [item.product_category_id] : [],
                                    },
                                    () => {
                                        this.list();
                                    }
                                );
                            }}
                            currentKey={this.state.product_category_ids[0] || ''}
                            _key={'product_category_id'}
                            _value={'name'}
                            validationName={t('Danh mục khoá học')}
                            placeHolder={t('Chọn danh mục')}
                            validations={{ isRequired: true }}
                            wrapperClassName='w-auto'
                            isShowDelete={true}
                        />

                        <SelectOption
                            items={allTags || []}
                            onSelectItem={(item) => {
                                this.setState(
                                    {
                                        tag_ids: item.product_tag_id ? [item.product_tag_id] : [],
                                    },
                                    () => {
                                        this.list();
                                    }
                                );
                            }}
                            isShowDelete={true}
                            currentKey={this.state.tag_ids[0] || ''}
                            _key={'product_tag_id'}
                            _value={'name'}
                            validationName={t('Tag')}
                            placeHolder={t('Chọn Tag')}
                            validations={{ isRequired: true }}
                            // idBlock='dropdown-confirm-payment'
                            wrapperClassName='w-auto'
                        /> */}

                        <button
                            type='button'
                            className='btn-advanced-filter'
                            onClick={() => this.setState({ isShowFilterCondition: !isShowFilterCondition })}
                        >
                            <div className='ladiui'>Lọc nâng cao</div>
                            <i className='ldicon-sort' />
                        </button>
                    </div>
                    {isShowFilterCondition && (
                        <div className='block-condition mt-12'>
                            <FilterConditionOrderv2
                                isSave={false}
                                setDataCondition={this.setDataCondition}
                                removeCondition={this.removeCondition}
                                conditionProp={conditionProp}
                                conditionFields={appConfig.PRODUCT_CONDITION_FILEDS}
                                dataConditions={dataConditions}
                                allTags={allTags}
                                allStorePages={allStorePages}
                                parentType={'COURSE'}
                            />
                        </div>
                    )}
                </div>

                

                <div className='block-content'>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card ladiui-table-responsive'>
                                <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''} table-list-course`}>
                                    <thead style={{ position: 'relative' }}>
                                        <tr className='ladiui table-vertical'>
                                            <th className='ladiui checkall'>
                                                <input
                                                    id='checkAllItem'
                                                    onClick={this.checkAllItem}
                                                    type='checkbox'
                                                    className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                />
                                            </th>
                                            {this.state.selectedIDs.length <= 0 ? (
                                                <th
                                                    scope='col'
                                                    name='name'
                                                    style={{ width: '600px' }}
                                                    onClick={this.sort}
                                                    className={`text-left sorting ${this.sortBy['name'] == 'DESC' ? 'up' : ''}`}
                                                >
                                                    {t('COURSES.NAME')}
                                                </th>
                                            ) : (
                                                <th scope='col' style={{ width: '600px' }}>
                                                    {t('COURSES.NAME')}
                                                    <LadiDropdownMenu
                                                        labelDropdown={'Chọn hành động'}
                                                        listProp={listAction}
                                                        defaultTextProp={t('ACTIONS.TITLE')}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectAction}
                                                        classSize='btn-sm'
                                                        classDropdown='w-180 action-record-table'
                                                    />
                                                </th>
                                            )}
                                            <th scope='col'>{t('COMMON.PRODUCT_STATUS')}</th>
                                            <th scope='col'>{t('COMMON.CREATOR')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(this.props.product.products, (product, index) => {
                                            let tagIds = [];
                                            let tagValues = [];
                                            const tags = product.tags ? product.tags.split(',') : [];
                                            if (tags) {
                                                map(tags, (_item) => {
                                                    let id = _item.split(':')[0];
                                                    let name = _item.split(':')[1];
                                                    tagIds.push(id);
                                                    tagValues.push(name);
                                                });
                                            }
                                            return (
                                                <React.Fragment key={index}>
                                                    <tr
                                                        key={product.product_id}
                                                        className='ladiui table-vertical main'
                                                        style={tags.length > 0 ? { borderBottom: 'none' } : {}}
                                                    >
                                                        <td>
                                                            <input
                                                                type='checkbox'
                                                                onClick={(event) => this.checkItem(event, product.product_id)}
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                            />
                                                        </td>
                                                        <td style={{ maxWidth: '600px', paddingRight: '10px' }}>
                                                            <a
                                                                className='limit-text-vertical'
                                                                href={this.handelProductEdit(product.product_id)}
                                                                target='_parent'
                                                            >
                                                                {product.name}
                                                            </a>
                                                        </td>
                                                        <td>{this.getNameProductByStatus(product.status)}</td>
                                                        <td>
                                                            {baseHelper.getStaffNameById(
                                                                userInfo.staffs,
                                                                product.creator_id,
                                                                userInfo.ladi_uid
                                                            )}
                                                        </td>
                                                        <td className='text-right pd-0'>
                                                            <div className='ladiui btn-group'>
                                                                <div className='ladiui dropdown hide-mt ba-c'>
                                                                    <button
                                                                        data-toggle='dropdown'
                                                                        className='ladiui-btn-dropdown dropdown-toggle'
                                                                    >
                                                                        <i className='ladiui icon icon-ldp-dot'></i>
                                                                    </button>
                                                                    <ul className='ladiui dropdown-menu r-0'>
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                href={this.handelProductEdit(product.product_id)}
                                                                                target='_parent'
                                                                            >
                                                                                {t('ACTIONS.EDIT')}
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                href={this.handleCopyCourse(product.product_id)}
                                                                                target="_parent"
                                                                            >
                                                                                {t('COURSES.COPY_COURSE')}
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() => this.copyVariantInfo(product)}
                                                                            >
                                                                                {t('PRODUCTS.GET_INFO')}
                                                                            </a>
                                                                        </li>
                                                                        {product.url_published && (
                                                                            <li>
                                                                                <a
                                                                                    className='ladiui dropdown-item'
                                                                                    target='_blank'
                                                                                    onClick={() => {
                                                                                        window.open(product.url_published);
                                                                                    }}
                                                                                >
                                                                                    {t('COMMON.PREVIEW')}
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() =>
                                                                                    this.openOptionDeleteModal(product.product_id)
                                                                                }
                                                                            >
                                                                                {t('ACTIONS.DELETE')}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {tags && tags.length > 0 && (
                                                        <tr className='ladiui table-vertical main custom-tag'>
                                                            <td></td>
                                                            <td colSpan={10}>
                                                                {tags.length > 0 && (
                                                                    <ListTags
                                                                        tagValues={tagIds}
                                                                        tagColors={[]}
                                                                        tagNames={tagValues}
                                                                        handleClickTagItem={this.handleClickTag}
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                        {totalRecord <= 0 && (
                                            <tr className='text-center'>
                                                <td colSpan='100%'>{t('NO_DATA')}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('PAGINATION.ITEMS')}
                                    message={''}
                                    items={this.props.product.products}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            )}
                        </div>
                    )}
                </div>

                {this.state.isShowModalCourse && (
                    <ModalCreateCourse
                        showModal={this.state.isShowModalCourse}
                        loading={isSubmitLoading}
                        isModalEdit={this.state.isModalEdit}
                        reloadListCate={this.list}
                        onCancel={this.hideModalCreate}
                        selectedType={this.state.type}
                    />
                )}

                <ConfirmModal
                    id='confirm-product-delete'
                    title={t('PRODUCTS.MSG_DELETE_PRODUCT_TITLE')}
                    content={t('PRODUCTS.MSG_DELETE_PRODUCT_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(this.selectedID)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-product-deletes'
                    title={t('PRODUCTS.MSG_DELETE_PRODUCT_TITLE')}
                    content={t('PRODUCTS.MSG_DELETE_PRODUCTS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deletes(this.state.selectedIDs)}
                    isLoading={isLoadingDelete}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(productActions.list(data)),
        deletes: (productIDs) => dispatch(productActions.deletes(productIDs)),
        delete: (productID) => dispatch(productActions.delete(productID)),
        show: (productID) => dispatch(productActions.show(productID)),
        reload: (productID) => dispatch(productActions.reload(productID)),
        copy: (productID) => dispatch(productActions.copy(productID)),
        create: (product) => dispatch(productActions.create(product)),
        update: (product) => dispatch(productActions.update(product)),
        getVariantInfos: (productID) => dispatch(variantActions.getVariantInfos(productID)),
        listAllTags: (data) => dispatch(productTagActions.listAll(data)),
        listAllCategories: (data) => dispatch(productCategoryActions.listSelectCategory(data)),
    };
};

const mapStateToProps = (state) => ({
    product: { ...state.product },
    productCategory: { ...state.productCategory },
    variant: { ...state.variant },
    productTagReducer: { ...state.productTag },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(Course);
