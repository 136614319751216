import React, { useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../../config/app';

import Input from '../../../../components/Input';
import Popover from '../../../../components/Popover';
import NumberInput from '../../../../components/NumberInput';

function PopoverShipping(props) {
    const { t } = useTranslation();
    const { disabledApply, removeShipping, applyShipping, shippingMethods } = props;
    const popoverRef = useRef();

    const storeReducer = useSelector((state) => state.store);
    const [shippingType, setShippingType] = useState(appConfig.SHPPING_TYPES[0]);
    const [shipping, setShipping] = useState({
        fee: 0,
        name: '',
    });

    const remove = () => {
        removeShipping();
        popoverRef.current.instanceRef.hide();
    };
    const apply = () => {
        applyShipping({
            type: shippingType.type,
            ...shipping,
        });
        popoverRef.current.instanceRef.hide();
    };

    return (
        <Popover
            ref={popoverRef}
            wrapperProps={{ className: 'price-option' }}
            wrapperPopoverProps={{ className: 'price-box' }}
            // onShow={onShow}
            content={
                <div>
                    <div className='popover-shipping'>
                        <div className='list-item-v2'>
                            {appConfig.SHPPING_TYPES.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='item'
                                        onClick={() => {
                                            setShippingType(item);
                                            setShipping({});
                                        }}
                                    >
                                        <input
                                            type='radio'
                                            className='ladiui form-check-input'
                                            name='radio-group'
                                            onChange={() => {}}
                                            checked={shippingType.type == item.type}
                                        />
                                        <label>{item.name}</label>
                                    </div>
                                );
                            })}

                            {shippingMethods.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='item'
                                        onClick={() => {
                                            setShippingType({ type: appConfig.SHIPPING_TYPE.AVAILABLE });
                                            setShipping(item);
                                        }}
                                    >
                                        <input
                                            type='radio'
                                            className='ladiui form-check-input'
                                            name='radio-group'
                                            onChange={() => {}}
                                            checked={shipping.shipping_rate_detail_id == item.shipping_rate_detail_id}
                                        />
                                        <label>{item.name}</label>
                                    </div>
                                );
                            })}
                        </div>
                        {shippingType.type == 2 && (
                            <div className='shiping-fee mt-12'>
                                <div className='shipping-fee-name'>
                                    <label className='ladiui text-14'>{t('ORDERS.SHIPPING_RATE_NAME')}</label>
                                    <Input
                                        className='mt-4'
                                        type='text'
                                        name='name'
                                        value={shipping.name}
                                        onChange={(event) => {
                                            // event.persist();
                                            let value = event.target.value;
                                            setShipping((pre) => {
                                                let tg = { ...pre };
                                                tg.name = value;
                                                return tg;
                                            });
                                        }}
                                    />
                                </div>
                                <div className='shipping-fee-value'>
                                    <label className='ladiui text-14'>{t('SHIPPINGS.FEE')}</label>
                                    <NumberInput
                                        name='fee'
                                        className='mt-4'
                                        min={0}
                                        suffix={storeReducer.userInfo.currentStore.currency_symbol}
                                        value={shipping.fee}
                                        onChange={(event) => {
                                            setShipping((pre) => {
                                                let tg = { ...pre };
                                                tg.fee = event.target.value;
                                                return tg;
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex-end actions'>
                        <button className='ladiui btn btn-secondary btn-sm  mr-8' data-dissmiss='discount-popup' onClick={remove}>
                            {t('ACTIONS.REMOVE')}
                        </button>

                        <button
                            className={`ladiui btn btn-primary btn-sm  ${disabledApply ? 'disabled' : ''}`}
                            disabled={disabledApply}
                            data-dissmiss='discount-popup'
                            onClick={apply}
                        >
                            {t('ACTIONS.APPLY')}
                        </button>
                    </div>
                </div>
            }
        >
            <span>
                <a className='ladiui-custom-popup-init'>{`${t('ORDERS.SHIPPING_FEE_EDIT')}`}</a>
            </span>
        </Popover>
    );
}
export default PopoverShipping;
