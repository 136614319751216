import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import appConfig from '../../../../../config/app';

import Popover from '../../../../../components/Popover';

// eslint-disable-next-line max-lines-per-function

const PopoverAddChannel = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { addChannel } = props;

    const popoverRef = useRef();

    const handelAddChannel = (data) => {
        addChannel(data);
        popoverRef.current.instanceRef.hide();
    };

    return (
        <div className='ladiui pos-rel'>
            <Popover
                ref={popoverRef}
                wrapperPopoverProps={{ className: 'popover-action-page-checkout add-channel' }}
                content={
                    <div className='action-add-content'>
                        <label className='ladiui label-14'>Loại kênh tương tác</label>
                        <div className='add-content-new'>
                            {appConfig.LIST_CHANELS.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='add-content-item'
                                        onClick={() => {
                                            handelAddChannel(item);
                                        }}
                                    >
                                        <div className='icon-antion'>
                                            <img src={item.image_url} />
                                        </div>
                                        <div className=''>
                                            <div className='ladiui text-14 text-primary'>{item.name}</div>
                                            <div className='mt-4 ladiui text-14'>{`Liên hệ qua kênh ${item.name}.`}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
            >
                <div className='btn-add-section'>
                    <i className='ldicon-add' />
                    <span className='ladiui text-brand'>Thêm kênh tương tác</span>
                </div>
            </Popover>
        </div>
    );
});

export default PopoverAddChannel;
