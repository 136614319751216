import i18next from 'i18next';

export default {
    MENU_LEFT: [
        {
            iconClass: 'ladiui icon-new new-ldicon-setting',
            title: i18next.t('SETTINGS.GENERAL'),
            action: 'GENERAL',
        },
        {
            iconClass: 'new-ldicon-bill',
            title: i18next.t('SETTINGS.STORE_POLICY'),
            action: 'STORE_POLICY',
        },
        {
            iconClass: 'ladiui icon-new ldicon-delivery-truck',
            title: i18next.t('SETTINGS.SHIPPING'),
            action: 'SHIPPING',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-bankcard',
            title: i18next.t('SETTINGS.PAYMENT'),
            action: 'PAYMENT',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-cart',
            title: i18next.t('SETTINGS.CHECKOUT_CONFIG'),
            action: 'CONFIG_CHECKOUT',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-storefront',
            title: i18next.t('SETTINGS.STORE_ADDRESS'),
            action: 'STORE_ADDRESS',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-user-group',
            title: i18next.t('SETTINGS.ACCOUNT'),
            action: 'ACCOUNT',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-notify',
            title: i18next.t('SETTINGS.NOTIFICATION'),
            action: 'NOTIFICATION',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-website-2',
            title: i18next.t('SETTINGS.DOMAIN'),
            action: 'DOMAIN',
        },
        {
            iconClass: 'ladiui icon-new ldicon-api',
            title: i18next.t('SETTINGS.API'),
            action: 'API',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-user-asign',
            title: i18next.t('SETTINGS.AUTO_ASSIGN'),
            action: 'AUTO_ASSIGN',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-sequence',
            title: i18next.t('SETTINGS.WEB_HOOK'),
            action: 'WEB_HOOK',
        },
        {
            iconClass: 'ladiui icon-new ldicon-ip',
            title: i18next.t('SETTINGS.IP'),
            action: 'IP',
        },
        {
            iconClass: 'ladiui icon-new new-ldicon-code-2',
            title: i18next.t('SETTINGS.TRACKING'),
            action: 'TRACKING',
        },
        {
            iconClass: 'ladiui icon-new ldicon-info',
            title: i18next.t('PACKAGE.TITLE'),
            action: 'CREDIT',
        },
    ],
};
