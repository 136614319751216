/** @format */

import React from 'react';

import { connect } from 'react-redux';
import discountActions from '../../redux/futures/discount/actions';
import { includes, map, remove, find, omit } from 'lodash';
import moment from 'moment';
import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/discount/types';
import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../components/ConfirmModal';
import { appLocalStorage } from '../../localforage';
import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import FilterConditionDiscount from './components/FilterConditionDiscount';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import LadiPagination from '../../components/LadiPagination';
import ModalPromoCode from './components/ModalPromoCode';
import ModalExportDiscount from './components/ModalExportDiscount';
import queryString from 'query-string';
class Discount extends React.Component {
    constructor(props) {
        super(props);

        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.state = {
            activePage: 1,
            searchName: '',
            searchStatus: '',
            searchUsedStatus: '',
            searchRule: '',
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentDiscountInfo: {},
            mode: '',
            isShowModalPromoCode: false,
            userInfo: {},
            fromDate: null,
            toDate: null,
            focusedInput: null,
            conditions: defaultConditions,
            selectedIDs: [],
            defaultRuleType: appConfig.DISCOUNT.RULE_TYPE.CODE.CODE,
            isShowModalExportDiscount: false,
        };

        this.sortBy = {
            discount_id: 'DESC',
        };

        this.selectedID = '';
        // this.selectedIDs = [];
        this.formRef = React.createRef();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        let paramUrl = queryString.parse(this.props.location.search);
        if (paramUrl.is_new && paramUrl.selected_discount_id) {
            this.setState({
                currentDiscountInfo: null,
                mode: appConfig.FORM_MODE.EDIT,
            });

            this.props.show(paramUrl.selected_discount_id);
        }

        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.discountReducer.action != nextProps.discountReducer.action) {
            if (nextProps.discountReducer.action === types.SHOW_DISCOUNT) {
                if (nextProps.discountReducer.status) {
                    this.setState({
                        mode: appConfig.FORM_MODE.EDIT,
                        currentDiscountInfo: nextProps.discountReducer.discount,
                        isShowModalPromoCode: true,
                        defaultRuleType: nextProps.discountReducer.discount.rule_type,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discountReducer.message, 'OK');
                }
            }

            if (nextProps.discountReducer.action === types.CREATE_DISCOUNT) {
                if (nextProps.discountReducer.status) {
                    this.setState({
                        currentDiscountInfo: null,
                        isShowModalPromoCode: false,
                    });
                    this.list();

                    window.LadiUI.toastCustom('success', '', nextProps.discountReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discountReducer.message, 'OK');
                }
            }

            if (nextProps.discountReducer.action === types.UPDATE_DISCOUNT) {
                if (nextProps.discountReducer.status) {
                    this.props.reload(this.state.currentDiscountInfo.discount_id);

                    this.list();

                    window.LadiUI.toastCustom('success', '', nextProps.discountReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discountReducer.message, 'OK');
                }
            }

            if (nextProps.discountReducer.action == types.EXPORT_DISCOUNT) {
                if (nextProps.discountReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.discountReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discountReducer.message, 'OK');
                }
            }

            if (
                includes(
                    [types.DELETE_DISCOUNT, types.DELETE_DISCOUNTS, types.ACTIVE_DISCOUNT, types.EXPIRE_DISCOUNT],
                    nextProps.discountReducer.action
                )
            ) {
                if (nextProps.discountReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.discountReducer.message);
                    this.setState({
                        selectedIDs: [],
                    });
                    this.list();

                    window.LadiUI.closeModal('confirm-discount-delete');
                    window.LadiUI.closeModal('confirm-discount-deletes');
                    window.LadiUI.closeModal('confirm-discount-apply');
                    window.LadiUI.closeModal('confirm-discount-stop-apply');
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discountReducer.message, 'OK');
                }
            }

            if (nextProps.discountReducer.action == types.LIST_DISCOUNT) {
                if (nextProps.discountReducer.status) {
                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.discountReducer.message, 'OK');
                }
            }
        }
    }

    /**
     * list discount
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const { searchName, selectedLimit, searchUsedStatus, searchStatus, searchRule, conditions, fromDate, toDate } = this.state;
        let activePage = conditions.page;
        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;

            this.setState({
                conditions,
            });
        }

        // Reset selectedIDs
        this.selectedIDs = [];

        const data = {
            search: {
                used: searchUsedStatus,
                name: searchName,
                status: searchStatus,
                rule_type: searchRule,
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsTg = [];
        if (checked) {
            selectedIDsTg = map(this.props.discountReducer.discounts, (discount) => discount.discount_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs: selectedIDsTg,
        });
    };

    checkItem = (event, discountID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, discountID)) {
                selectedIDs.push(discountID);
            }

            if (selectedIDs.length == this.props.discountReducer.discounts.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(selectedIDs, (selectedDiscountID) => selectedDiscountID == discountID);
        }

        this.setState({
            selectedIDs: selectedIDs,
        });
    };

    deletes = () => {
        if (this.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.deletes(this.selectedIDs);
    };

    deleteItem = (discountID) => {
        this.props.delete(discountID);
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({}, () => this.list());
    };

    openModalCreate = (type) => {
        // this.setState({
        //     currentDiscountInfo: null,
        //     mode: appConfig.FORM_MODE.CREATE,
        //     isShowModalPromoCode: true,
        //     defaultRuleType: type,
        // });
        if (type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            this.props.history.push('/discount-promotion/create?rule_type=1');
        }
        if (type == appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE) {
            this.props.history.push('/discount-promotion/create?rule_type=2');
        }
    };

    openModalEdit = (discount) => {
        // if (discount.discount_id) {
        //     this.setState({
        //         currentDiscountInfo: null,
        //         mode: appConfig.FORM_MODE.EDIT,
        //     });

        //     this.props.show(discount.discount_id);
        // }

        if (discount.discount_id) {
            if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
                this.props.history.push(`/discount-promotion/update/${discount.discount_id}?rule_type=1`);
            }
            if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE) {
                this.props.history.push(`/discount-promotion/update/${discount.discount_id}?rule_type=2`);
            }
        }
    };

    hideModal = () => {
        this.setState({
            isShowModalPromoCode: false,
        });
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-discount-delete');
    };

    openOptionDeletesModal = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-discount-deletes');
    };

    openModalApply = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-discount-apply');
    };

    openModalStopApply = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-discount-stop-apply');
    };

    /**
     * Tim kiem theo status
     */
    onChangeSearchStatus = (status) => {
        this.setState(
            {
                searchStatus: status,
            },
            () => {
                this.list();
            }
        );
    };

    onChangeSearchRule = (rule) => {
        this.setState(
            {
                searchRule: rule,
            },
            () => {
                this.list();
            }
        );
    };

    getStatusName = (status) => {
        if (!status) {
            return this.props.t('DISCOUNTS.STATUS.ALL_STATUS');
        }

        const matched = find(appConfig.DISCOUNT.STATUS, (item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    getRuleName = (ruleType) => {
        if (!ruleType) {
            return this.props.t('DISCOUNTS.RULE_TYPE.ALL_RULE_TYPE');
        }

        const matched = find(appConfig.DISCOUNT.RULE_TYPE, (item) => item.CODE == ruleType);
        if (matched) {
            return matched.NAME;
        }
    };

    submit = (discount) => {
        if (this.state.mode === appConfig.FORM_MODE.CREATE) {
            this.props.create(omit(discount, ['discount_id']));
        } else {
            this.props.update(discount);
        }
    };

    filterActionProp = (conditionRecived) => {
        this.setState({
            conditions: { ...conditionRecived },
        });
    };

    eventSearchKeyboard = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                conditions: {
                    ...this.state.conditions,
                    ...{ keyword: e.target.value, page: 1 },
                },
            });
        }
    };

    eventClickSearchButton = () => {
        const keywordSearch = document.getElementById('keyword_search').value;
        this.setState({
            ...this.state.conditions,
            ...{ keyword: keywordSearch, page: 1 },
        });
    };

    eventSelectActions = (action) => {
        switch (action) {
            case 'DELETE_DISCOUNT':
                this.openOptionDeletesModal();
                break;
            default:
        }
    };

    clearFilter = (callback) => {
        this.setState(
            {
                searchStatus: '',
                searchRule: '',
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        // let filter = {
        //     tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
        //     searchName: ""
        // }

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case 'status':
                    // filter.searchStatus = condition.valueFilter.value;
                    this.setState({
                        searchStatus: condition.valueFilter.value,
                    });
                    break;
                case 'rule_type':
                    // filter.searchRule = condition.listFilter.map(item => item.value);
                    this.setState({
                        searchRule: condition.valueFilter.value,
                    });
                    break;
                default:
                    break;
            }
        }

        // this.setState({
        //     ...filter
        // }, () => {
        //     this.list();
        // })
        this.list();
    };

    exportDiscount = (type) => {
        let ids;
        const { selectedIDs } = this.state;
        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_DISCOUNT.ALL_DISCOUNT:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_DISCOUNT.ON_PAGE:
                const onPageIDs = map(this.props.discountReducer.discounts, (item) => item.discount_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_DISCOUNT.SELECTED_DISCOUNT:
                if (baseHelper.isEmpty(selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = selectedIDs;
                break;
        }

        const { searchName, selectedLimit, searchUsedStatus, searchStatus, searchRule, conditions, fromDate, toDate } = this.state;

        // Reset selectedIDs
        this.selectedIDs = [];

        const data = {
            ids: ids,
            search: {
                used: searchUsedStatus,
                name: searchName,
                status: searchStatus,
                rule_type: searchRule,
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            },
        };

        this.props.exportDiscount(data);
    };

    exportTransaction = () => { };
    /****************************************************************/
    render() {
        const { t } = this.props;
        const moment = baseHelper.getMoment();

        const { totalRecord, totalPage } = this.props.discountReducer;
        const { activePage, selectedLimit, searchName, searchUsedStatus, searchStatus, searchRule, userInfo, fromDate, toDate, focusedInput, conditions } =
            this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.discountReducer.discounts || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.discountReducer.discounts.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
            'PAGINATION.OF'
        )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

        const isLoadingData = this.props.discountReducer.loading && this.props.discountReducer.waiting == types.LIST_DISCOUNT;
        const isLoadingInfo = this.props.discountReducer.loading && this.props.discountReducer.waiting == types.SHOW_DISCOUNT;
        const isLoadingDelete =
            this.props.discountReducer.loading &&
            includes([types.DELETE_DISCOUNT, types.DELETE_DISCOUNTS], this.props.discountReducer.waiting);
        const isSubmitLoading =
            this.props.discountReducer.loading &&
            includes([types.CREATE_DISCOUNT, types.UPDATE_DISCOUNT], this.props.discountReducer.waiting);
        const isLoadingApply = this.props.discountReducer.loading && this.props.discountReducer.waiting == types.ACTIVE_DISCOUNT;
        const isLoadingStopApply = this.props.discountReducer.loading && this.props.discountReducer.waiting == types.EXPIRE_DISCOUNT;

        const isLoadingExportDiscount =
            this.props.discountReducer.loading && includes([types.EXPIRE_DISCOUNT], this.props.discountReducer.waiting);

        const isLoadingExportTransaction =
            this.props.discountReducer.loading && includes([types.EXPORT_TRANSACTION], this.props.discountReducer.waiting);

        const listAction = [{ value: 'DELETE_DISCOUNT', name: t('ACTIONS.DELETE') }];

        return (
            <div className='page-content page-discount page-discount-v4'>
                <div className='menu-header'>
                    <div className='content'>
                        <div className='title-actions'>
                            <div className='title-desc'>
                                <h3>Khuyến mãi</h3>
                                <p>Cung cấp các ưu đãi giảm giá để thúc đẩy doanh số cửa hàng</p>
                            </div>
                            <div className='actions'>
                                <button
                                    className='ladiui btn btn-light'
                                    onClick={() =>
                                        this.setState({
                                            isShowModalExportDiscount: true,
                                        })
                                    }
                                >
                                    <img
                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-export.svg'
                                        style={{ marginBottom: '-2px', marginRight: '10px' }}
                                    />
                                    {'Xuất mã khuyến mãi'}
                                </button>
                                <div className='btn-action ladiui dropdown hide-mt'>
                                    <button type='button' className='ladiui dropdown-toggle btn btn-primary' data-toggle='dropdown'>
                                        <img
                                            className='ladiui btn-custom-img'
                                            src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                            alt=''
                                        />

                                        {t('DISCOUNTS.ADD_DISCOUNT')}
                                    </button>
                                    <ul
                                        className='ladiui dropdown-menu r-0 block-new-page-checkout block-select-add-coupon'
                                        style={{ width: 400 }}
                                    >
                                        <li>
                                            <a
                                                className='ladiui dropdown-item flex'
                                                onClick={() => this.openModalCreate(appConfig.DISCOUNT.RULE_TYPE.CODE.CODE)}
                                            >
                                                <div>
                                                    <i className='ldicon-discount-coupon'></i>
                                                </div>
                                                <div className='title-description'>
                                                    <h3>Tạo mã khuyến mãi</h3>
                                                    <span>Khách hàng nhận khuyến mãi bằng cách nhập mã khi thanh toán.</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className='ladiui dropdown-item flex'
                                                onClick={() => this.openModalCreate(appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE)}
                                            >
                                                <div>
                                                    <i className='ladi-icon ldicon-buying-time'></i>
                                                </div>
                                                <div className='title-description'>
                                                    <h3>Tạo chương trình khuyến mãi</h3>
                                                    <span>Khách hàng nhận khuyến mãi tự động khi đáp ứng đủ điều kiện khi nhận hàng.</span>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='block-filter-search block-search mt-24 block-filter'>
                            <div className='ladiui search-group'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width'
                                    name='searchName'
                                    placeholder='Tìm kiếm'
                                    aria-expanded='false'
                                    value={searchName}
                                    onChange={this.onChangeInput}
                                    onKeyDown={this.onKeyDownInput}
                                />
                                <i className='ladiui icon icon-search' onClick={this.list}></i>
                            </div>
                            <div className='list-filter'>
                                <div className='ladiui btn-group'>
                                    <div className='ladiui dropdown'>
                                        <button
                                            className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                            data-toggle='dropdown'
                                        >
                                            <i className='ladiui icon'></i>
                                            <span className='ladiui dropdown-text'>{'Hình thức khuyến mãi'}</span>
                                        </button>
                                        <ul className='ladiui dropdown-menu'>
                                            {appConfig.LIST_RULE_TYPE.map((item) => (
                                                <li
                                                    key={item.value}
                                                    onClick={(e) =>
                                                        this.setState(
                                                            {
                                                                searchRule: item.value,
                                                            },
                                                            () => {
                                                                this.list();
                                                            }
                                                        )
                                                    }
                                                >
                                                    <a className='ladiui dropdown-item' href='#'>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className='ladiui btn-group'>
                                    <div className='ladiui dropdown'>
                                        <button
                                            className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                            data-toggle='dropdown'
                                        >
                                            <i className='ladiui icon'></i>
                                            <span className='ladiui dropdown-text'>{'Trạng thái'}</span>
                                        </button>
                                        <ul className='ladiui dropdown-menu'>
                                            {appConfig.DISCOUNT_LIST_STATUS.map((item) => (
                                                <li
                                                    key={item.value}
                                                    onClick={(e) =>
                                                        this.setState(
                                                            {
                                                                searchStatus: item.value,
                                                            },
                                                            () => {
                                                                this.list();
                                                            }
                                                        )
                                                    }
                                                >
                                                    <a className='ladiui dropdown-item' href='#'>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='ladiui btn-group'>
                                    <div className='ladiui dropdown'>
                                        <button
                                            className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                            data-toggle='dropdown'
                                        >
                                            <i className='ladiui icon'></i>
                                            <span className='ladiui dropdown-text'>{'Sử dụng'}</span>
                                        </button>
                                        <ul className='ladiui dropdown-menu'>
                                            {appConfig.DISCOUNT_LIST_USE_STATUS.map((item) => (
                                                <li
                                                    key={item.value}
                                                    onClick={(e) =>
                                                        this.setState(
                                                            {
                                                                searchUsedStatus: item.value,
                                                            },
                                                            () => {
                                                                this.list();
                                                            }
                                                        )
                                                    }
                                                >
                                                    <a className='ladiui dropdown-item' href='#'>
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='date-filter'>
                                    <OrderDateRangePicker
                                        startDateId='fromDate'
                                        endDateId='toDate'
                                        startDate={fromDate}
                                        endDate={toDate}
                                        onDatesChange={({ startDate, endDate }) => {
                                            this.setState(
                                                {
                                                    fromDate: baseHelper.getFromDate(startDate),
                                                    toDate: baseHelper.getToDate(endDate),
                                                },
                                                () => {
                                                    if ((startDate && endDate) || (!startDate && !endDate)) {
                                                        this.list();
                                                    }
                                                }
                                            );
                                        }}
                                        focusedInput={focusedInput}
                                        onFocusChange={(focusedInput) => this.setState({ focusedInput: focusedInput })}
                                        showDefaultInputIcon={true}
                                        inputIconPosition='after'
                                        small={true}
                                        hideKeyboardShortcutsPanel={true}
                                        customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                        startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                        endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                        isOutsideRange={() => false}
                                        isDayHighlighted={(date) => {
                                            return moment().isSame(date, 'day');
                                        }}
                                        minimumNights={0}
                                        showClearDates={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='ladiui-table-actions'>
                    <div className='ladiui content-main-tools block-filter-condition flex-row' style={{ justifyContent: 'flex-start' }}>
                        <div className='date-filter mr-24'>
                            <OrderDateRangePicker
                                startDateId='fromDate'
                                endDateId='toDate'
                                startDate={fromDate}
                                endDate={toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState(
                                        {
                                            fromDate: baseHelper.getFromDate(startDate),
                                            toDate: baseHelper.getToDate(endDate),
                                        },
                                        () => {
                                            if ((startDate && endDate) || (!startDate && !endDate)) {
                                                this.list();
                                            }
                                        }
                                    );
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={(focusedInput) => this.setState({ focusedInput: focusedInput })}
                                showDefaultInputIcon={true}
                                inputIconPosition='after'
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment().isSame(date, 'day');
                                }}
                                minimumNights={0}
                                showClearDates={true}
                            />
                        </div>
                        <div className='ladiui flex-row mr-24'>
                            <div className='ladiui btn-group'>
                                <div className='ladiui dropdown'>
                                    <button
                                        className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                        data-toggle='dropdown'
                                    >
                                        <i className='ladiui icon'></i>
                                        <span className='ladiui dropdown-text'>{'Hình thức khuyến mãi'}</span>
                                    </button>
                                    <ul className='ladiui dropdown-menu'>
                                        {appConfig.LIST_RULE_TYPE.map((item) => (
                                            <li
                                                key={item.value}
                                                onClick={(e) =>
                                                    this.setState(
                                                        {
                                                            searchRule: item.value,
                                                        },
                                                        () => {
                                                            this.list();
                                                        }
                                                    )
                                                }
                                            >
                                                <a className='ladiui dropdown-item' href='#'>
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='ladiui flex-row mr-24'>
                            <div className='ladiui btn-group'>
                                <div className='ladiui dropdown'>
                                    <button
                                        className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                        data-toggle='dropdown'
                                    >
                                        <i className='ladiui icon'></i>
                                        <span className='ladiui dropdown-text'>{'Trạng thái'}</span>
                                    </button>
                                    <ul className='ladiui dropdown-menu'>
                                        {appConfig.DISCOUNT_LIST_STATUS.map((item) => (
                                            <li
                                                key={item.value}
                                                onClick={(e) =>
                                                    this.setState(
                                                        {
                                                            searchStatus: item.value,
                                                        },
                                                        () => {
                                                            this.list();
                                                        }
                                                    )
                                                }
                                            >
                                                <a className='ladiui dropdown-item' href='#'>
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui search-group'>
                            <input
                                id='keyword_search'
                                className='ladiui search-field dropdown-toggle form-control search-width'
                                name='searchName'
                                placeholder='Tìm kiếm'
                                aria-expanded='false'
                                value={searchName}
                                onChange={this.onChangeInput}
                                onKeyDown={this.onKeyDownInput}
                            />
                            <i className='ladiui icon icon-search' onClick={this.list}></i>
                        </div>

                        <div className='ladiui btn-group custom-btn-list-landingpage'>
                            <button
                                className='ladiui btn btn-light'
                                onClick={() =>
                                    this.setState({
                                        isShowModalExportDiscount: true,
                                    })
                                }
                            >
                                <img
                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-export.svg'
                                    style={{ marginBottom: '-2px', marginRight: '10px' }}
                                />
                                {'Xuất mã khuyến mãi'}
                            </button>
                        </div>
                    </div>
                    <div className='action-btn'>
                        <div className='btn-action ladiui dropdown hide-mt'>
                            <button type='button' className='ladiui dropdown-toggle btn btn-primary btn-sm' data-toggle='dropdown'>
                                <img
                                    className='ladiui btn-custom-img'
                                    src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                    alt=''
                                />

                                {t('DISCOUNTS.ADD_DISCOUNT')}
                            </button>
                            <ul
                                className='ladiui dropdown-menu r-0 block-new-page-checkout block-select-add-coupon'
                                style={{ width: 400 }}
                            >
                                <li>
                                    <a
                                        className='ladiui dropdown-item flex'
                                        onClick={() => this.openModalCreate(appConfig.DISCOUNT.RULE_TYPE.CODE.CODE)}
                                    >
                                        <div>
                                            <i className='ldicon-discount-coupon'></i>
                                        </div>
                                        <div className='title-description'>
                                            <h3>Tạo mã khuyến mãi</h3>
                                            <span>Khách hàng nhận khuyến mãi bằng cách nhập mã khi thanh toán.</span>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className='ladiui dropdown-item flex'
                                        onClick={() => this.openModalCreate(appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE)}
                                    >
                                        <div>
                                            <i className='ladi-icon ldicon-buying-time'></i>
                                        </div>
                                        <div className='title-description'>
                                            <h3>Tạo chương trình khuyến mãi</h3>
                                            <span>Khách hàng nhận khuyến mãi tự động khi đáp ứng đủ điều kiện khi nhận hàng.</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div> */}

                <div className='ladiui content-max-width'>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card ladiui-table-responsive'>
                                <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''}`}>
                                    <thead style={{ position: 'relative' }}>
                                        <tr className='ladiui table-vertical'>
                                            <th scope='col' className='ladiui checkall'>
                                                <input
                                                    id='checkAllItem'
                                                    onClick={this.checkAllItem}
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle'
                                                />
                                            </th>
                                            {this.state.selectedIDs.length <= 0 ? (
                                                <th
                                                    scope='col'
                                                    name='code'
                                                    onClick={this.sort}
                                                    className={`sorting ${this.sortBy['code'] == 'DESC' ? 'up' : ''}`}
                                                >
                                                    {t('DISCOUNTS.CODE')}
                                                </th>
                                            ) : (
                                                <th scope='col'>
                                                    {t('DISCOUNTS.CODE')}
                                                    <LadiDropdownMenu
                                                        labelDropdown={'Chọn hành động'}
                                                        listProp={listAction}
                                                        defaultTextProp={t('ACTIONS.TITLE')}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectActions}
                                                        classSize='btn-sm '
                                                        classDropdown='w-180 action-record-table'
                                                        style={{
                                                            left: '30px',
                                                        }}
                                                    />
                                                </th>
                                            )}
                                            <th
                                                scope='col'
                                                name='rule_type'
                                                onClick={this.sort}
                                                className={`sorting ${this.sortBy['rule_type'] == 'DESC' ? 'up' : ''}`}
                                            >
                                                {t('DISCOUNTS.RULE_TYPE.TITLE')}
                                            </th>
                                            <th scope='col'>{t('STATUS.TITLE')}</th>
                                            <th scope='col'>{t('DISCOUNTS.USED')}</th>
                                            <th scope='col'>{t('COMMON.START_DATE')}</th>
                                            <th scope='col'>{t('COMMON.END_DATE')}</th>
                                            <th scope='col'>{t('COMMON.CREATOR')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(this.props.discountReducer.discounts, (discount) => {
                                            return (
                                                <tr key={discount.discount_id} className='ladiui table-vertical main'>
                                                    <td scope='row'>
                                                        <input
                                                            type='checkbox'
                                                            onClick={(event) => this.checkItem(event, discount.discount_id)}
                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                        />
                                                    </td>
                                                    <td>
                                                        <a onClick={() => this.openModalEdit(discount)} className='ladiui'>
                                                            {`${discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE
                                                                ? discount.code
                                                                : discount.name
                                                                }`}
                                                        </a>
                                                    </td>
                                                    <td>{`${discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE
                                                        ? t('DISCOUNTS.RULE_TYPE.CODE')
                                                        : t('DISCOUNTS.RULE_TYPE.PROMOTION')
                                                        }`}</td>
                                                    <td>
                                                        <div className='discount-status-v4'>
                                                            {discount.status == appConfig.DISCOUNT.STATUS.ACTIVE.CODE && (
                                                                <div className='active flex items-center' />
                                                            )}
                                                            {discount.status == appConfig.DISCOUNT.STATUS.EXPIRED.CODE && (
                                                                <div className='expried flex items-center' />
                                                            )}
                                                            {discount.status == appConfig.DISCOUNT.STATUS.PENDING.CODE && (
                                                                <div className='pending flex items-center' />
                                                            )}
                                                            {discount.status == appConfig.DISCOUNT.STATUS.ACTIVE.CODE &&
                                                                t('DISCOUNTS.STATUS.ACTIVE')}
                                                            {discount.status == appConfig.DISCOUNT.STATUS.EXPIRED.CODE &&
                                                                t('DISCOUNTS.STATUS.EXPIRED')}
                                                            {discount.status == appConfig.DISCOUNT.STATUS.PENDING.CODE &&
                                                                t('DISCOUNTS.PENDING')}
                                                        </div>
                                                    </td>
                                                    <td>{discount.used}</td>
                                                    <td>{baseHelper.formatStrToDate(discount.start_date)}</td>
                                                    <td>
                                                        {discount.end_date
                                                            ? baseHelper.formatStrToDate(discount.end_date)
                                                            : t('DISCOUNTS.FOREVER')}
                                                    </td>
                                                    <td>
                                                        {baseHelper.getStaffNameById(
                                                            userInfo.staffs,
                                                            discount.creator_id,
                                                            userInfo.ladi_uid
                                                        )}
                                                    </td>
                                                    <td className='text-right pd-0'>
                                                        <div className='ladiui btn-group'>
                                                            <div className='ladiui dropdown hide-mt ba-c'>
                                                                <button
                                                                    data-toggle='dropdown'
                                                                    className='ladiui-btn-dropdown dropdown-toggle'
                                                                >
                                                                    <i className='ladiui icon icon-ldp-dot'></i>
                                                                </button>
                                                                <ul className='ladiui dropdown-menu r-0 w-160'>
                                                                    {discount.status !== appConfig.DISCOUNT.STATUS.EXPIRED.CODE && (
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() =>
                                                                                    this.openModalStopApply(discount.discount_id)
                                                                                }
                                                                            >
                                                                                <i className='ldicon-circle-check2 ladiui icon-new' />
                                                                                <span> {t('ACTIONS.STOP_APPLY')}</span>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                    {discount.status == appConfig.DISCOUNT.STATUS.EXPIRED.CODE && (
                                                                        <li>
                                                                            <a
                                                                                onClick={() => this.openModalApply(discount.discount_id)}
                                                                                className='ladiui dropdown-item'
                                                                            >
                                                                                <i className='ldicon-circle-check2 ladiui icon-new' />
                                                                                <span>{t('ACTIONS.APPLY')}</span>
                                                                            </a>
                                                                        </li>
                                                                    )}
                                                                    <li>
                                                                        <a
                                                                            onClick={() => this.openModalEdit(discount)}
                                                                            className='ladiui dropdown-item'
                                                                        >
                                                                            <i className='new-ldicon-edit' />
                                                                            <span> {t('ACTIONS.EDIT')} </span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() => this.openOptionDeleteModal(discount.discount_id)}
                                                                        >
                                                                            <i className='ladiui icon-new new-ldicon-delete' />
                                                                            <span className='ladiui text-danger'>
                                                                                {t('ACTIONS.DELETE')}
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {totalRecord <= 0 && (
                                <div className='table-empty'>
                                    <i className='new-ldicon-search' />
                                    <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                                    <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                                </div>
                            )}
                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('PAGINATION.ITEMS')}
                                    message={''}
                                    items={this.props.discountReducer.discounts}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            )}
                        </div>
                    )}
                </div>

                {this.state.isShowModalPromoCode && (
                    <ModalPromoCode
                        ref={this.formRef}
                        mode={this.state.mode}
                        defaultRuleType={this.state.defaultRuleType}
                        currentDiscountInfo={this.state.currentDiscountInfo}
                        onCancel={this.hideModal}
                        onSubmit={this.submit}
                        visible={this.state.isShowModalPromoCode}
                        isLoading={isSubmitLoading}
                    />
                )}

                {this.state.isShowModalExportDiscount && (
                    <ModalExportDiscount
                        visible={this.state.isShowModalExportDiscount}
                        isLoadingExportDiscount={isLoadingExportDiscount}
                        isLoadingExportTransaction={isLoadingExportTransaction}
                        onExportDiscount={this.exportDiscount}
                        onExportTransaction={this.exportTransaction}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportDiscount: false,
                            });
                        }}
                    />
                )}

                <ConfirmModal
                    id='confirm-discount-delete'
                    title={t('DISCOUNTS.MSG_DELETE_DISCOUNT_TITLE')}
                    content={t('DISCOUNTS.MSG_DELETE_DISCOUNT_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(this.selectedID)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-discount-deletes'
                    title={t('DISCOUNTS.MSG_DELETE_DISCOUNT_TITLE')}
                    content={t('DISCOUNTS.MSG_DELETE_DISCOUNTS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deletes(this.state.selectedIDs)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-discount-apply'
                    title={t('DISCOUNTS.MSG_APPLY_TITLE')}
                    content={t('DISCOUNTS.MSG_APPLY_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.APPLY')}
                    onOk={() => this.props.active(this.selectedID)}
                    isLoading={isLoadingApply}
                />

                <ConfirmModal
                    id='confirm-discount-stop-apply'
                    title={t('DISCOUNTS.MSG_STOP_APPLY_TITLE')}
                    content={t('DISCOUNTS.MSG_STOP_APPLY_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.STOP_APPLY')}
                    onOk={() => this.props.expire(this.selectedID)}
                    isLoading={isLoadingStopApply}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(discountActions.list(data)),
        deletes: (discountIDs) => dispatch(discountActions.deletes(discountIDs)),
        delete: (discountID) => dispatch(discountActions.delete(discountID)),
        show: (discountID) => dispatch(discountActions.show(discountID)),
        reload: (discountID) => dispatch(discountActions.reload(discountID)),
        create: (discount) => dispatch(discountActions.create(discount)),
        update: (discount) => dispatch(discountActions.update(discount)),
        active: (discountID) => dispatch(discountActions.active(discountID)),
        expire: (discountID) => dispatch(discountActions.expire(discountID)),
        exportDiscount: (data) => dispatch(discountActions.exportDiscount(data)),
    };
};

const mapStateToProps = (state) => ({
    discountReducer: { ...state.discount },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Discount));
