import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

const signOut = () => ({
    type: types.SIGNOUT,
});


const renewToken = (storeID, appCode = appConfig.APP.LS) => ({
    type: types.RENEW_TOKEN,
    meta: {
        endpoint: endpoint.AUTH_RENEW_TOKEN,
        method: REQUEST_METHOD.POST,
        body: {
            token: baseHelper.getCookie(appConfig.COOKIE.SSID),
            store_id: storeID,
            app_code: appCode,
        },
        hasAuth: false,

    }
});

const signInSuccess = (ladiUID, storeID) => ({
    type: types.SIGNIN_SUCCESS,
    payload: {
        ladi_uid: ladiUID,
        store_id: storeID,
    }
});

export default {
    signOut,
    renewToken,
    signInSuccess,
}