import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_CHECKOUT,
    meta: {
        endpoint: endpoint.LIST_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const show = (checkoutID) => ({
    type: types.SHOW_CHECKOUT,
    meta: {
        endpoint: endpoint.SHOW_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            checkout_id: checkoutID
        },
        hasAuth: true,
    }
});

const reload = (checkoutID) => ({
    type: types.SHOW_CHECKOUT,
    meta: {
        endpoint: endpoint.SHOW_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            checkout_id: checkoutID
        },
        hasAuth: true,
    }
});

const update = (data) => ({
    type: types.UPDATE_CHECKOUT,
    meta: {
        endpoint: endpoint.UPDATE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const deletes = (data) => ({
    type: types.DELETE_CHECKOUT,
    meta: {
        endpoint: endpoint.DELETE_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const sendNotification = (checkoutID) => ({
    type: types.SEND_NOTIFICATION_CHECKOUT,
    meta: {
        endpoint: endpoint.SEND_NOTIFICATION_CHECKOUT,
        method: REQUEST_METHOD.POST,
        body: {
            checkout_id: checkoutID
        },
        hasAuth: true,
    }
});

export default {
    list,
    show,
    reload,
    update,
    deletes,
    sendNotification
}