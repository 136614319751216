import React from 'react';
import PropTypes from 'prop-types';
import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Popover from '../../../../components/Popover';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import NumberInput from '../../../../components/NumberInput';
import Dropdown from '../../../../components/DropDownNew';

class PopoverDiscount extends React.Component {
    static propTypes = {
        discount: PropTypes.object,
        apply: PropTypes.func,
        remove: PropTypes.func,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            discount: {
                type: appConfig.DISCOUNT.TYPE.FIXED.value,
                value: '',
                note: '',
            },
            disabledApply: false,
            discountType: {
                value: appConfig.DISCOUNT.TYPE.FIXED.value,
                name: appConfig.DISCOUNT.TYPE.FIXED.name,
            },
        };

        this.initialState = cloneDeep(this.state);
        this.popoverRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }
    }

    onChangeDiscountValue = (event) => {
        this.setState(
            {
                discount: {
                    ...this.state.discount,
                    value: event.target.value,
                },
            },
            () => {
                this.checkDisabledApply();
            }
        );
    };

    checkDisabledApply = () => {
        if (this.state.discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && this.state.discount.value > 100) {
            this.setState({
                disabledApply: true,
            });
        } else {
            this.setState({
                disabledApply: false,
            });
        }
    };

    onChangeDiscountType = (event, type) => {
        this.setState(
            {
                discount: {
                    ...this.state.discount,
                    type,
                },
            },
            () => {
                this.checkDisabledApply();
            }
        );
    };

    onChangeDiscountReason = (event) => {
        const { discount } = this.state;
        discount[event.target.name] = event.target.value;
        this.setState({
            discount,
        });
    };

    onShow = () => {
        if (this.props.discount) {
            this.setState({
                discount: cloneDeep(this.props.discount),
            });
        } else {
            this.setState({
                ...cloneDeep(this.initialState),
            });
        }
    };

    apply = () => {
        this.props.apply({
            type: this.state.discountType.value,
            value: this.state.discount.value,
            note: this.state.discount.note,
        });
        this.popoverRef.current.instanceRef.hide();
    };

    remove = () => {
        this.props.remove();
        this.popoverRef.current.instanceRef.hide();
    };

    render() {
        const { t } = this.props;
        const { discount, discountType } = this.state;
        const isActivePercent = discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value;
        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;
        return (
            <Popover
                ref={this.popoverRef}
                wrapperProps={{ className: 'price-option' }}
                wrapperPopoverProps={{ className: 'price-box' }}
                onShow={this.onShow}
                content={
                    <div>
                        <div className='popover-discount'>
                            <div className='popover-discount-type-value'>
                                <div className='discount-type'>
                                    <label className='ladiui text-14'> {t('DISCOUNTS.RULE_TYPE.TITLE')}</label>
                                    <div className='ladiui btn-group mt-4'>
                                        <div className='ladiui dropdown'>
                                            <button className={`ladiui btn btn-outline-light dropdown-toggle`} data-toggle='dropdown'>
                                                {discountType.name}
                                            </button>
                                            <ul className={`ladiui dropdown-menu`}>
                                                {Object.values(appConfig.DISCOUNT.TYPE).map((item, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            onClick={() => {
                                                                this.setState({
                                                                    discountType: item,
                                                                });
                                                            }}
                                                        >
                                                            <a className='ladiui dropdown-item'>{item.name}</a>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='discount-value'>
                                    <label className='ladiui text-14'>{t('DISCOUNTS.DISCOUNT_VALUE')}</label>

                                    <NumberInput
                                        name='value'
                                        className='mt-4'
                                        min={0}
                                        suffix={discountType.value == appConfig.DISCOUNT.TYPE.PERCENT.value ? '%' : currencySymbol}
                                        max={discountType.value == appConfig.DISCOUNT.TYPE.PERCENT.value ? 100 : null}
                                        value={discount.value}
                                        onChange={(event) => this.onChangeDiscountValue(event)}
                                        isDiscountType={discountType.value}
                                    />
                                </div>
                            </div>
                            <div className='discount-reason'>
                                <label className='ladiui text-14'>{t('COMMON.REASON')}</label>
                                <Input
                                    className='mt-4'
                                    type='text'
                                    name='note'
                                    value={discount.note}
                                    onChange={this.onChangeDiscountReason}
                                />
                            </div>
                        </div>
                        <div className='flex-end actions'>
                            <button className='ladiui btn btn-secondary btn-sm  mr-8' data-dissmiss='discount-popup' onClick={this.remove}>
                                {t('ACTIONS.REMOVE')}
                            </button>

                            <button
                                className={`ladiui btn btn-primary btn-sm  ${this.state.disabledApply ? 'disabled' : ''}`}
                                disabled={this.state.disabledApply}
                                data-dissmiss='discount-popup'
                                onClick={this.apply}
                            >
                                {t('ACTIONS.APPLY')}
                            </button>
                        </div>
                    </div>
                }
            >
                {this.props.children}
            </Popover>
        );
    }
}

const mapStateToProps = (state) => ({
    store: { ...state.store },
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(withTranslation()(PopoverDiscount));
