import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';

// eslint-disable-next-line max-lines-per-function
function Header(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const { productData, setSelectedTabIndex, selectedTabIndex, submit } = props;

    return (
        <div className='ladiui header-o'>
            <div className='ladiui header'>
                <div className='ladiui order-breadcrumbs'>
                    <span
                        className='back-btn'
                        onClick={() => {
                            // if (history.length > 1) history.goBack();
                            // else
                            history.push('/products');
                        }}
                    >
                        {t('PRODUCTS.PRODUCT_LIST')}
                    </span>
                    <i className='icon ldicon-chevron-right' />
                    <span className='ladiui label-16'>
                        {productData && productData.name ? productData.name : t('PRODUCTS.LB_ADD_PRODUCT_DIGITAL')}
                    </span>
                </div>
                <div className='order-head-actions'>
                    {productData && productData.product_id ? (
                        <>
                            <button
                                className={`ladiui btn btn-md btn-primary `}
                                onClick={() => {
                                    submit();
                                    // if (isEditableOrder) fullSubmit();
                                    // else partialSubmit();
                                }}
                            >
                                {t('PRODUCTS.DIGITAL.SAVE')}
                            </button>
                        </>
                    ) : (
                        <button
                            className={`ladiui btn btn-md btn-primary `}
                            onClick={() => {
                                submit();
                            }}
                        >
                            {t('PRODUCTS.DIGITAL.CREATE')}
                        </button>
                    )}
                </div>
            </div>
            <div className='product-tabs-header ladiui header pb-0'>
                <ul className='ladiui nav nav-tabs'>
                    <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(1)}>
                        <span className={`ladiui tab-link ${selectedTabIndex === 1 ? 'active' : ''}`}>Thông tin chung</span>
                    </li>
                    {productData && productData.product_id && productData.ticket_creation_form > 0 && (
                        <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(2)}>
                            <span className={`ladiui tab-link ${selectedTabIndex === 2 ? 'active' : ''}`}>Quản lý mã</span>
                        </li>
                    )}
                    <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(3)}>
                        <span className={`ladiui tab-link ${selectedTabIndex === 3 ? 'active' : ''}`}>Sản phẩm Upsell</span>
                    </li>
                    <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(4)}>
                        <span className={`ladiui tab-link ${selectedTabIndex === 4 ? 'active' : ''}`}>Thiết lập SEO & Social</span>
                    </li>
                    <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(5)}>
                        <span className={`ladiui tab-link ${selectedTabIndex === 5 ? 'active' : ''}`}>Highlight</span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
