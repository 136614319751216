import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import TagSelect from '../../../../components/TagSelect';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import { map } from 'lodash';
import { object } from 'prop-types';

import * as filterTypes from '../../../../redux/futures/filter/types';
import filterActions from '../../../../redux/futures/filter/actions';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalSaveCondition(props) {
    const dispatch = useDispatch();
    const wrapperRef = useRef();
    const conditionRef = useRef();
    const { t } = useTranslation();

    const { visible, hideModalSaveCondition, filters = [], dataConditions, selectedType = 'ORDER' } = props;
    const [typeSave, setTypeSave] = useState(appConfig.CONDITION_SAVE_TYPE.NEW.value);
    const [conditionName, setConditionName] = useState('');
    const [conditionNameError, setConditionNameError] = useState('');
    const [filterSelected, setFilterSelected] = useState(filters ? filters[0] : {});

    const onCancel = () => {
        hideModalSaveCondition();
    };

    const onOk = () => {
        if (typeSave == appConfig.CONDITION_SAVE_TYPE.NEW.value && !conditionName) {
            setConditionNameError(t('CONDITIONS_FILTER.NANE_EMPTY'));
            return;
        }
        if (!dataConditions || !dataConditions.conditions || dataConditions.conditions.length == 0) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.REQUIRE_ONE_CONDITION'));
            return;
        }
        // setLoading(true);
        let column = appConfig.ORDER_COLUMN.filter((item) => item.isSelected);
        let dataCreate = {
            type: selectedType,
            name: conditionName,
            config: {
                dataConditions,
                columns: column,
            },
        };
        if (typeSave == appConfig.CONDITION_SAVE_TYPE.NEW.value) dispatch(filterActions.create(dataCreate));
        else {
            dataCreate = { ...filterSelected };
            dataCreate.config.dataConditions = dataConditions;

            dispatch(filterActions.update(dataCreate));
        }
    };

    return (
        <Modal
            width={600}
            id='modal-save-condition'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('CONDITIONS_FILTER.SAVE')}
            sub_title={t('CONDITIONS_FILTER.DESCRIPTION_SAVE')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('CONDITIONS_FILTER.SAVE')}
            isLoading={props.isLoading}
            position_unset={true}
        >
            <div className='content-save-condition'>
                <div className='list-item-v2'>
                    <div
                        className='item'
                        onClick={() => {
                            setTypeSave(appConfig.CONDITION_SAVE_TYPE.NEW.value);
                        }}
                    >
                        <input
                            type='radio'
                            className='ladiui form-check-input'
                            name='radio-group'
                            onChange={() => {}}
                            checked={typeSave == appConfig.CONDITION_SAVE_TYPE.NEW.value}
                        />
                        <label>{appConfig.CONDITION_SAVE_TYPE.NEW.name}</label>
                    </div>

                    <div
                        className='item'
                        onClick={() => {
                            setTypeSave(appConfig.CONDITION_SAVE_TYPE.UPDATE.value);
                        }}
                    >
                        <input
                            type='radio'
                            className='ladiui form-check-input'
                            name='radio-group'
                            onChange={() => {}}
                            checked={typeSave == appConfig.CONDITION_SAVE_TYPE.UPDATE.value}
                        />
                        <label>{appConfig.CONDITION_SAVE_TYPE.UPDATE.name}</label>
                    </div>
                </div>

                {typeSave == appConfig.CONDITION_SAVE_TYPE.NEW.value && (
                    <div className='mt-16'>
                        <label>{t('CONDITIONS_FILTER.CONDITION_NAME')}</label>
                        <Input
                            className={'mt-4'}
                            value={conditionName ? conditionName : ''}
                            onChange={(e) => setConditionName(e.target.value)}
                        />
                        {conditionNameError && <div className='ladiui error-input'> {conditionNameError}</div>}
                    </div>
                )}
                {typeSave == appConfig.CONDITION_SAVE_TYPE.UPDATE.value && (
                    <div className='mt-16 flex flex-column'>
                        <label>{t('CONDITIONS_FILTER.CONDITION_SELECT')}</label>

                        <div className='ladiui btn-group mt-12 pb-4 position-unset'>
                            <div className='ladiui dropdown w-100-p position-unset'>
                                <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                                    <i className='ladiui icon'></i>
                                    <span className='ladiui dropdown-text'>
                                        {filterSelected ? filterSelected.name : t('CONDITIONS_FILTER.SELECT_VALUE')}
                                    </span>
                                </button>
                                <ul className='ladiui dropdown-menu filter-list'>
                                    {filters.map((item, index) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                setFilterSelected(item);
                                            }}
                                        >
                                            <span className='ladiui dropdown-item'>{item.name}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {conditionNameError && <div className='ladiui error-input'> {conditionNameError}</div>}
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ModalSaveCondition;
