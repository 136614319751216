/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { map, keys, find, remove, forEach, cloneDeep, uniq, compact, debounce, includes } from 'lodash';

import productActions from '../../../../../redux/futures/product/actions';
import * as productTypes from '../../../../../redux/futures/product/types';

import productTagActions from '../../../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../../../redux/futures/product_tag/types';

import customerTagActions from '../../../../../redux/futures/customer_tag/actions';
import * as customerTagTypes from '../../../../../redux/futures/customer_tag/types';

import customerActions from '../../../../../redux/futures/customer/actions';
import * as customerTypes from '../../../../../redux/futures/customer/types';

import appConfig from '../../../../../config/app';
import PropTypes from 'prop-types';

import baseHelper from '../../../../../helpers/BaseHelper';

import { appLocalStorage } from '../../../../../localforage';
import Dropdown from '../../../../../components/Dropdown';
import Tag from '../../../../../components/Tag';
class RuleItem extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        rules: PropTypes.array,
        indexRule: PropTypes.number,
        removeRule: PropTypes.func,
    };

    constructor(props) {
        super(props);

        let toStaffs = props.to || {};

        if (props.priority == appConfig.OTHER_ASSIGN_PRIORITY && props.ignore) {
            toStaffs = props.ignore || {};
        }

        let selectedOther = appConfig.ASSIGN_OTHER_STATUS.DEFAULT.CODE;
        if (props.to) {
            selectedOther = appConfig.ASSIGN_OTHER_STATUS.TO_STAFF.CODE;
        } else if (props.ignore) {
            selectedOther = appConfig.ASSIGN_OTHER_STATUS.IGNORE_STAFF.CODE;
        }

        this.state = {
            allStaffs: [],
            data: props.data || {},
            rules: props.rules || [],
            toStaffs,
            ignoreStaffs: props.ignore || {},
            search: {
                product: '',
                productTag: '',
                customer: '',
                customerTag: '',
            },
            input: {
                urlPage: '',
            },
            displaySearch: {
                product: props.t('ASSIGN.SEARCH_PRODUCT'),
                productTag: props.t('ASSIGN.SEARCH_PRODUCT_TAG'),
                customer: props.t('ASSIGN.SEARCH_CUSTOMER'),
                customerTag: props.t('ASSIGN.SEARCH_CUSTOMER_TAG'),
            },
            restRules: appConfig.ASSIGN_RULE,
            restStaffs: [],
            pickTitle: props.t('ASSIGN.PICK'),
            selectedOther,
        };

        this.SEARCH_TYPE = {
            PRODUCT: 'product',
            PRODUCT_TAG: 'productTag',
            CUSTOMER: 'customer',
            CUSTOMER_TAG: 'customerTag',
        };

        this.search = debounce(this.search, 500);

        this.urlPageRef = React.createRef();
        this.utmSourceRef = React.createRef();
        this.utmMediumRef = React.createRef();
        this.utmCampaignRef = React.createRef();
        this.utmTermRef = React.createRef();
        this.utmContentRef = React.createRef();
        this.productRef = React.createRef();
        this.productTagRef = React.createRef();
        this.staffRef = React.createRef();
    }

    componentDidMount() {
        const foundProducts = cloneDeep(this.props.productReducer.products || []);

        if (foundProducts.length == 0) {
            this.search(this.SEARCH_TYPE.PRODUCT);
        }
        const foundProductTags = cloneDeep(this.props.productTagReducer.productTags || []);
        if (foundProductTags.length == 0) {
            this.search(this.SEARCH_TYPE.PRODUCT_TAG);
        }

        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState(
                {
                    allStaffs: result.staffs,
                },
                () => {
                    this.normalizeRestStaffs();
                }
            );
        });

        this.normalizeRestRules();
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    isOtherAssign() {
        return this.props.priority == appConfig.OTHER_ASSIGN_PRIORITY;
    }

    /******************************SEARCH***********************************************/
    onChangeSearchInput = (value, name) => {
        // const { value } = event.target;
        this.setState(
            {
                search: {
                    ...this.state.search,
                    [name]: value,
                },
            },
            () => this.search(name)
        );
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownsearchInput = (event) => {
        const { name } = event.target;
        if (event.key === 'Enter') {
            this.search(name);
        }
    };

    search = (name) => {
        const { search } = this.state;
        switch (name) {
            case this.SEARCH_TYPE.PRODUCT:
                this.props.searchProduct(search.product);
                break;
            case this.SEARCH_TYPE.PRODUCT_TAG:
                this.props.searchProductTags(search.productTag);
                break;
            case this.SEARCH_TYPE.CUSTOMER:
                this.props.searchCustomer(search.customer);
                break;
            case this.SEARCH_TYPE.CUSTOMER_TAG:
                this.props.searchCustomerTags(search.customerTag);
                break;
        }
    };

    /**************************PRODUCT********************************/
    selectProduct = (item, index) => {
        const { rules } = this.state;
        const products = rules[index][appConfig.ASSIGN_RULE.PRODUCT.CODE];

        if (find(products, (_item) => _item.product_id == item.product_id)) {
            return;
        }
        products.push(item);

        this.setState({
            rules,
            search: {
                ...this.state.search,
                product: '',
            },
        });
    };

    removeProduct = (itemProduct, index) => {
        const { rules } = this.state;
        const products = rules[index][appConfig.ASSIGN_RULE.PRODUCT.CODE];
        remove(products, (_item, _index) => _item.product_id == itemProduct.product_id);
        this.setState({
            rules,
        });
    };
    /**************************************************************/

    /**************************UTM********************************/

    addUtm = (newUtm, index, type) => {
        const { rules } = this.state;
        const utm = rules[index][type];
        if (!includes(utm, newUtm)) {
            utm.push(newUtm);
            this.setState({
                rules,
            });
        }
    };

    removeUtm = (remoUtm, index, type) => {
        const { rules } = this.state;
        const utm = rules[index][type];
        remove(utm, (_item, _index) => _item == remoUtm);

        this.setState({
            rules,
        });
    };
    /**************************************************************/

    /**************************PRODUCT_TAG********************************/
    selectProductTag = (item, index) => {
        this.setState(
            {
                displaySearch: {
                    ...this.state.displaySearch,
                    productTag: '',
                },
            },
            () => {
                this.setState({
                    search: {
                        ...this.state.search,
                        productTag: '',
                    },
                    displaySearch: {
                        ...this.state.displaySearch,
                        productTag: this.props.t('ASSIGN.SEARCH_PRODUCT_TAG'),
                    },
                });
            }
        );

        const { rules } = this.state;

        const tags = rules[index][appConfig.ASSIGN_RULE.PRODUCT_TAG.CODE];

        if (find(tags, (_item) => _item.product_tag_id == item.product_tag_id)) {
            return;
        }

        tags.push(item);

        this.setState({
            rules,
        });
    };

    removeProductTag = (itemProductTag, index) => {
        const { rules } = this.state;
        const tags = rules[index][appConfig.ASSIGN_RULE.PRODUCT_TAG.CODE];

        remove(tags, (_item, _index) => _item.product_tag_id == itemProductTag.product_tag_id);

        this.setState({
            rules,
        });
    };
    /**************************************************************/

    /**************************RULE********************************/
    onChangeSelectRule = (selected, index) => {
        const { rules } = this.state;

        rules[index] = {
            [selected]: [],
        };

        this.setState(
            {
                rules,
            },
            () => {
                this.normalizeRestRules();
            }
        );
    };

    addNewRule = (selectedRule) => {
        const { rules } = this.state;

        rules.push({
            [selectedRule.CODE]: [],
        });

        this.setState(
            {
                rules,
            },
            () => {
                this.normalizeRestRules();
            }
        );
    };

    removeRule = (index) => {
        let { rules } = this.state;

        if (rules.length <= 1) {
            this.props.removeRule();
            return;
        }

        rules.splice(index, 1);

        this.setState(
            {
                rules,
            },
            () => {
                this.normalizeRestRules();
            }
        );
    };

    normalizeRestRules = () => {
        let restRules = cloneDeep(appConfig.ASSIGN_RULE);
        delete restRules.OTHER;

        const { rules } = this.state;
        forEach(rules, (item) => {
            const key = keys(item)[0];

            map(restRules, (value, _key) => {
                if (value['CODE'] == key) {
                    delete restRules[_key];
                }
            });
        });

        this.setState({
            restRules,
        });
    };
    /**************************************************************/

    /******************************STAFF*****************************/
    selectStaff = (staff) => {
        const { toStaffs } = this.state;

        if (toStaffs[staff.ladi_uid]) {
            return;
        }

        toStaffs[staff.ladi_uid] = 1;

        this.setState({
            toStaffs,
        });
    };

    removeStaff = (staff) => {
        const { toStaffs } = this.state;
        const ladiUID = staff.ladi_uid;
        delete toStaffs[ladiUID];

        this.setState({
            toStaffs,
        });
    };

    normalizeRestStaffs = () => {
        let { allStaffs, toStaffs } = this.state;

        let restStaffs = cloneDeep(allStaffs);

        remove(restStaffs, (item) => toStaffs[item.ladi_uid]);

        this.setState({
            restStaffs,
        });
    };
    /**************************************************************/

    getProductInfo(index) {
        const { t } = this.props;
        const { rules, search } = this.state;

        const products = compact(rules[index][appConfig.ASSIGN_RULE.PRODUCT.CODE]);
        const foundProducts = cloneDeep(this.props.productReducer.products || []);
        // Loại bỏ những product đã được thêm
        // remove(foundProducts, (item) => find(products, (_item) => item.product_id == _item.product_id));
        const loadingSearchProduct = this.props.productReducer.loading && this.props.productReducer.waiting == productTypes.SEARCH_PRODUCT;

        return (
            <div>
                <div className='m-bt-10'>
                    <span className='title-rule'> {t('MENU.PRODUCTS')}</span>
                    <i onClick={() => this.removeRule(index)} className='ladi-icon icon-bin' />
                </div>

                <Tag
                    wrapperStyle={{
                        position: 'relative',
                        minWidth: '400px',
                    }}
                    className='m-0'
                    allTags={foundProducts}
                    selectedTags={products}
                    loading={loadingSearchProduct}
                    placeholderInput={t('ASSIGN.ENTER_PRODUCT_NAME')}
                    onFocusInput={() => {
                        if (foundProducts.length == 0) {
                            this.search(this.SEARCH_TYPE.PRODUCT);
                        }
                    }}
                    hideTitle={true}
                    onSelectTag={(item) => this.selectProduct(item, index)}
                    onRemoveTag={(item) => this.removeProduct(item, index)}
                    onSearchApi={(value) => this.onChangeSearchInput(value, this.SEARCH_TYPE.PRODUCT)}
                    id={index + ''}
                />
            </div>
        );
    }

    getProductTagInfo(index) {
        const { t } = this.props;
        const { rules, search } = this.state;
        const foundProductTags = cloneDeep(this.props.productTagReducer.productTags || []);
        const loadingSearchProductTag =
            this.props.productTagReducer.loading && this.props.productTagReducer.waiting == productTagTypes.SEARCH_PRODUCT_TAG;
        const tags = compact(rules[index][appConfig.ASSIGN_RULE.PRODUCT_TAG.CODE]);

        return (
            <div>
                <div className='m-bt-10'>
                    <span className='title-rule'> {t('ASSIGN_RULE.PRODUCT_TAG')}</span>
                    <i onClick={() => this.removeRule(index)} className='ladi-icon icon-bin' />
                </div>
                <Tag
                    wrapperStyle={{
                        position: 'relative',
                        minWidth: '400px',
                    }}
                    className='m-0'
                    allTags={foundProductTags}
                    selectedTags={tags}
                    loading={loadingSearchProductTag}
                    placeholderInput={t('ASSIGN.SEARCH_PRODUCT_TAG')}
                    onFocusInput={() => {
                        if (foundProductTags.length == 0) {
                            this.search(this.SEARCH_TYPE.PRODUCT_TAG);
                        }
                    }}
                    hideTitle={true}
                    onSelectTag={(item) => this.selectProductTag(item, index)}
                    onRemoveTag={(item) => this.removeProductTag(item, index)}
                    onSearchApi={(value) => this.onChangeSearchInput(value, this.SEARCH_TYPE.PRODUCT_TAG)}
                />
            </div>
        );
    }

    getUtmInfo(index, type, title, placeholderInput) {
        const { t } = this.props;
        const { rules, input } = this.state;
        const urlPages = compact(rules[index][type]);

        return (
            <div className='utm-info'>
                <div className='m-bt-10'>
                    <span className='title-rule'> {title}</span>
                    <i onClick={() => this.removeRule(index)} className='ladi-icon icon-bin' />
                </div>

                <Tag
                    wrapperStyle={{
                        position: 'relative',
                        minWidth: '400px',
                    }}
                    className='m-0'
                    allTags={[]}
                    selectedTags={urlPages}
                    onFocusInput={() => null}
                    hideTitle={true}
                    placeholderInput={placeholderInput}
                    onNewTag={(item) => this.addUtm(item, index, type)}
                    onRemoveTag={(item) => this.removeUtm(item, index, type)}
                    id={`${index}-${type}`}
                />
            </div>
        );
    }

    getOtherInfo = () => {
        const { selectedOther } = this.state;

        return (
            <div className='ladiui'>
                <Dropdown
                    wrapperClassName={''}
                    data={appConfig.ASSIGN_OTHER_STATUS}
                    onSelectItem={(item) => {
                        this.setState({
                            selectedOther: item.CODE,
                        });
                    }}
                    currentKey={selectedOther}
                    _key={'CODE'}
                    _value={'NAME'}
                />
            </div>
        );
    };

    //Chọn nhân viên
    getStaffInfo = (id) => {
        const { t } = this.props;
        const { restStaffs, toStaffs, pickTitle, allStaffs } = this.state;
        let listselect = [];
        for (let staff of allStaffs) {
            if (toStaffs && toStaffs[staff.ladi_uid] == 1) {
                listselect.push(staff);
            }
        }
        return (
            <Tag
                wrapperStyle={{
                    position: 'relative',
                    minWidth: '400px',
                }}
                tabIndex={6}
                // ref={this.staffRef}
                id={`staff-tag-rule-${id}`}
                allTags={allStaffs || []}
                selectedTags={listselect}
                title={t('ASSIGN.PICK')}
                placeholderInput={t('ASSIGN.SEARCH_STAFF')}
                hideTitle={true}
                onSelectTag={this.selectStaff}
                onRemoveTag={this.removeStaff}
            />
        );
    };

    getData = () => {
        const { selectedOther } = this.state;
        const rules = [];

        const toStaffs = this.state.toStaffs || {};
        const { priority } = this.props;
        if (priority == appConfig.OTHER_ASSIGN_PRIORITY) {
            if (selectedOther == appConfig.ASSIGN_OTHER_STATUS.DEFAULT.CODE) {
                return;
            }

            rules.push({
                [appConfig.ASSIGN_RULE.OTHER.CODE]: true,
            });

            const result = {
                rules,
                using: appConfig.ASSIGN_STRATEGY.BASIC,
            };

            if (selectedOther == appConfig.ASSIGN_OTHER_STATUS.TO_STAFF.CODE) {
                result.to = toStaffs;
            } else {
                result.ignore = toStaffs;
            }

            return result;
        }

        forEach(this.state.rules, (item) => {
            const key = keys(item)[0];

            const found = find(appConfig.ASSIGN_RULE, (item) => item.CODE == key);

            if (!found) {
                return false;
            }

            // let values = map(item[key], _item => {

            let values = map(item[key], (_item) => {
                switch (key) {
                    case appConfig.ASSIGN_RULE.PRODUCT.CODE:
                    case appConfig.ASSIGN_RULE.PRODUCT_TAG.CODE:
                        // case appConfig.ASSIGN_RULE.CUSTOMER.CODE:
                        // case appConfig.ASSIGN_RULE.CUSTOMER_TAG.CODE:
                        if (_item) {
                            return _item.product_id || _item.customer_id || _item.product_tag_id || _item.customer_tag_id;
                        }
                    default:
                        return _item;
                }
            });

            // });

            values = uniq(values);
            values = compact(values);

            if (!baseHelper.isEmpty(values)) {
                rules.push({
                    [key]: values,
                });
            }
        });

        if (baseHelper.isEmpty(rules)) {
            return null;
        }

        return {
            rules,
            to: toStaffs,
            using: appConfig.ASSIGN_STRATEGY.BASIC,
        };
    };

    render() {
        const { t } = this.props;
        const { rules, restRules, selectedOther } = this.state;

        if (!this.isOtherAssign() && rules.length <= 0) {
            return null;
        }

        // const { rules, search } = this.state;
        // const products = compact(rules[index][appConfig.ASSIGN_RULE.PRODUCT.CODE]);
        const RulePanel = map(rules, (rule, index) => {
            const key = keys(rule)[0];
            const values = rule[key];

            let foundKey;

            map(appConfig.ASSIGN_RULE, (item, _key) => {
                if (item.CODE == key) {
                    foundKey = _key;
                }
            });

            return (
                <div key={index} className='rule-item'>
                    <div>
                        {key == appConfig.ASSIGN_RULE.PRODUCT.CODE && this.getProductInfo(index)}

                        {key == appConfig.ASSIGN_RULE.PRODUCT_TAG.CODE && this.getProductTagInfo(index)}

                        {key == appConfig.ASSIGN_RULE.URL_PAGE.CODE &&
                            this.getUtmInfo(index, key, t('ASSIGN_RULE.URL_PAGE'), t('ASSIGN.PLACEHOLDER_URL_PAGE'))}

                        {key == appConfig.ASSIGN_RULE.UTM_SOURCE.CODE &&
                            this.getUtmInfo(index, key, t('ASSIGN_RULE.UTM_SOURCE'), t('ASSIGN.PLACEHOLDER_UTM_SOURCE'))}

                        {key == appConfig.ASSIGN_RULE.UTM_MEDIUM.CODE &&
                            this.getUtmInfo(index, key, t('ASSIGN_RULE.UTM_MEDIUM'), t('ASSIGN.PLACEHOLDER_UTM_MEDIUM'))}

                        {key == appConfig.ASSIGN_RULE.UTM_CAMPAIGN.CODE &&
                            this.getUtmInfo(index, key, t('ASSIGN_RULE.UTM_CAMPAIGN'), t('ASSIGN.PLACEHOLDER_UTM_CAMPAIGN'))}

                        {key == appConfig.ASSIGN_RULE.UTM_TERM.CODE &&
                            this.getUtmInfo(index, key, t('ASSIGN_RULE.UTM_TERM'), t('ASSIGN.PLACEHOLDER_UTM_TERM'))}

                        {key == appConfig.ASSIGN_RULE.UTM_CONTENT.CODE &&
                            this.getUtmInfo(index, key, t('ASSIGN_RULE.UTM_CONTENT'), t('ASSIGN.PLACEHOLDER_UTM_CONTENT'))}
                    </div>
                </div>
            );
        });

        if (this.isOtherAssign()) {
            return (
                <div className='ladiui rule-group condition-group other'>
                    <label className='ladiui-label'>{t('ASSIGN.OTHER_RULE')}</label>
                    <div>
                        <div className='rule'>{this.getOtherInfo()}</div>
                        {selectedOther != appConfig.ASSIGN_OTHER_STATUS.DEFAULT.CODE && (
                            <div className='assign'>{this.getStaffInfo('rule-02')}</div>
                        )}
                    </div>
                </div>
            );
        }

        // rule item
        return (
            <div className='ladiui rule-group condition-group'>
                <label className='ladiui-label'>{t('ASSIGN.RULE', this.props.indexRule + 1)}</label>
                <div className='rule-content'>
                    <div>
                        <div className='rule'>{RulePanel}</div>
                        <div className='assign'>{this.getStaffInfo('rule-01')}</div>
                    </div>

                    {!baseHelper.isEmpty(restRules) && (
                        <Dropdown
                            wrapperClassName='add-auto-assign'
                            innerClassName='btn-add-auto-assign p-0'
                            iconClass="ladi-icon icon ldicon-add-circle"
                            // urlIcon={'https://w.ladicdn.com/ladiui/ladisales/icons/IonIosAddCircleOutline.svg'}
                            data={this.state.restRules}
                            onSelectItem={this.addNewRule}
                            placeHolder={t('ASSIGN.ADD_RULE')}
                            _key='CODE'
                            _value='NAME'
                        />
                    )}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchProduct: (name) => dispatch(productActions.search(name)),
        searchProductTags: (name) => dispatch(productTagActions.search(name)),
        searchCustomer: (name) => dispatch(customerActions.search(name)),
        searchCustomerTags: (name) => dispatch(customerTagActions.search(name)),
    };
};

const mapStateToProps = (state) => ({
    productReducer: { ...state.product },
    productTagReducer: { ...state.productTag },
    customerReducer: { ...state.customer },
    customerTagReducer: { ...state.customerTag },
    store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(RuleItem));
