import React from 'react';
import PropTypes from 'prop-types';
import { compact, map, includes } from 'lodash';

import * as settingTypes from '../../../redux/futures/setting/types';
import settingActions from '../../../redux/futures/setting/actions';

import { connect } from 'react-redux';
import Input from '../../../components/Input';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import LoadingScene from '../../../components/LoadingScene';
import ConfirmModal from '../../../components/ConfirmModal';

class ModalSettingNotification extends React.Component {
    static propTypes = {
        addresses: PropTypes.array,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        callbackReloadAddress: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isShowModal: false,
            mode: null,
            emailSelected: '',
            isNotificationShop: false,
            isNotificationCustomer: false,
        };

        this.inputsRef = new Set();
    }

    componentDidMount() {
        this.init(this.props);
        this.props.listNotificationEmail();
    }

    init(props) {
        if (props.settingReducer) {
            this.setState({
                isNotificationCustomer: props.settingReducer.notification_email_configs
                    ? props.settingReducer.notification_email_configs.is_notification_customer
                        ? true
                        : false
                    : false,
                isNotificationShop: props.settingReducer.notification_email_configs
                    ? props.settingReducer.notification_email_configs.is_notification_shop
                        ? true
                        : false
                    : false,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        // if (this.props.settingReducer != nextProps.settingReducer) {
        //   this.init(nextProps);
        // }

        if (this.props.settingReducer.action != nextProps.settingReducer.action) {
            if (nextProps.settingReducer.action == settingTypes.SETTING_LIST_NOTIFICATION_EMAIL) {
                if (nextProps.settingReducer.status) {
                    this.init(nextProps);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
            if (nextProps.settingReducer.action == settingTypes.SETTING_CREATE_NOTIFICATION_EMAIL) {
                if (nextProps.settingReducer.status) {
                    this.props.listNotificationEmail();

                    this.setState({
                        isShowModal: false,
                    });
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL) {
                if (nextProps.settingReducer.status) {
                    this.props.listNotificationEmail();

                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_DELETE_NOTIFICATION_EMAIL) {
                if (nextProps.settingReducer.status) {
                    this.props.listNotificationEmail();
                    window.LadiUI.closeModal('confirm-delete-email');
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
        }
    }

    onChangeInput = (event) => {
        this.setState({
            email: event.target.value,
        });
    };

    createNewEmail = () => {
        // Validate Input
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        this.props.create(this.state.email);
    };

    submit = () => {
        this.props.updateNotificationEmailConfigs({
            is_notification_shop: this.state.isNotificationShop,
            is_notification_customer: this.state.isNotificationCustomer,
        });
    };

    changeStatus = (emailSelected, status) => {
        this.setState(
            {
                emailSelected,
            },
            () => {
                this.props.changeStatus(this.state.emailSelected, status);
            }
        );
    };

    delete = (emailSelected) => {
        this.setState(
            {
                emailSelected,
            },
            () => {
                window.LadiUI.showModal('confirm-delete-email');
            }
        );
    };

    getModalEmail = () => {
        const { t } = this.props;
        const isLoading =
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_CREATE_NOTIFICATION_EMAIL], this.props.settingReducer.waiting);
        return (
            <Modal
                visible={this.state.isShowModal}
                id='modal-add-notification-email'
                zIndex={9999}
                width={500}
                title={t('SETTINGS.TITLE_ADD_NOTIFICATION_EMAIL')}
                onCancel={() =>
                    this.setState({
                        isShowModal: false,
                    })
                }
                onOk={this.createNewEmail}
                isLoading={isLoading}
            >
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('COMMON.EMAIL')}</label>
                    <Input
                        name='email'
                        validationName={t('COMMON.EMAIL')}
                        placeholder={t('COMMON.EMAIL')}
                        value={this.state.email}
                        onChange={this.onChangeInput}
                        ref={(ref) => this.inputsRef.add(ref)}
                        validations={{ email: true, isRequired: true }}
                    />
                </div>
            </Modal>
        );
    };

    render() {
        const { t, visible } = this.props;
        const { notification_emails: notificationEmails } = this.props.settingReducer;
        const isLoading =
            this.props.settingReducer.loading &&
            includes(
                [
                    settingTypes.SETTING_LIST_NOTIFICATION_EMAIL,
                    // settingTypes.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL,
                    settingTypes.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG,
                ],
                this.props.settingReducer.waiting
            );
        const isLoadingDelete =
            this.props.settingReducer.loading &&
            includes([settingTypes.SETTING_DELETE_NOTIFICATION_EMAIL], this.props.settingReducer.waiting);
        return (
            <div
                className='page-setting'
                id='page-setting-notification-email'
                // onOk={this.submit}
            >
                {isLoading && <LoadingScene blur={true} />}
                <div className='table-address'>
                    <div className='block-title-action mb-12'>
                        <h3 className='title'>Thông báo</h3>
                        <div className='btn-action'>
                            <a
                                type='button'
                                className='ladiui link'
                                style={{ display: 'flex' }}
                                onClick={() => {
                                    this.setState({
                                        isShowModal: true,
                                        email: '',
                                    });
                                }}
                            >
                                <span className='mr-8'>
                                    <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' alt='' />
                                </span>
                                {t('SETTINGS.CREATE_EMAIL')}
                            </a>
                        </div>
                    </div>

                    <div className='ladi-card'>
                        <table className='ladiui table text-left'>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col' name='email' className='text-left'>
                                        {t('COMMON.EMAIL')}
                                    </th>
                                    <th scope='col' name='address' className='text-left'>
                                        {t('COMMON.STATUS')}
                                    </th>
                                    <th scope='col' />
                                </tr>
                            </thead>
                            <tbody>
                                {map(notificationEmails, (item) => {
                                    return (
                                        <tr
                                            key={item.email}
                                            className={
                                                item.status == appConfig.STATUS.INACTIVE
                                                    ? 'ladiui table-vertical main'
                                                    : 'ladiui table-vertical main'
                                            }
                                        >
                                            <td>{item.email}</td>
                                            <td>
                                                <div className='ladiui item-form switch'>
                                                    <label className='ladiui switch'>
                                                        <input
                                                            type='checkbox'
                                                            onChange={() =>
                                                                this.changeStatus(
                                                                    item.email,
                                                                    item.status == appConfig.STATUS.ACTIVE
                                                                        ? appConfig.STATUS.INACTIVE
                                                                        : appConfig.STATUS.ACTIVE
                                                                )
                                                            }
                                                            checked={item.status == appConfig.STATUS.ACTIVE}
                                                        />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td className='text-right pd-0'>
                                                <div className='ladiui btn-group'>
                                                    <div className='ladiui dropdown hide-mt ba-c'>
                                                        <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                            <i className='ladiui icon icon-ldp-dot'></i>
                                                        </button>
                                                        <ul className='ladiui dropdown-menu r-0'>
                                                            {/* <li>
                                <a
                                  className="ladiui dropdown-item"
                                  onClick={() =>
                                    this.changeStatus(
                                      item.email,
                                      item.status == appConfig.STATUS.ACTIVE ? appConfig.STATUS.INACTIVE : appConfig.STATUS.ACTIVE
                                    )
                                  }
                                >
                                  {item.status == appConfig.STATUS.ACTIVE ? t("ACTIONS.OFF") : t("ACTIONS.ON")}
                                </a>
                              </li> */}
                                                            <li>
                                                                <a className='ladiui dropdown-item' onClick={() => this.delete(item.email)}>
                                                                    {t('ACTIONS.DELETE')}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}

                                {baseHelper.isEmpty(notificationEmails) && (
                                    <tr className='text-center'>
                                        <td colSpan='100%'>{t('NO_DATA')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className='ladiui form-group' style={{ marginTop: 15 }}>
                        <label className='flex'>
                            <input
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                value={this.state.isNotificationShop}
                                onChange={(event) => {
                                    this.setState({
                                        isNotificationShop: !this.state.isNotificationShop,
                                    });
                                }}
                                checked={this.state.isNotificationShop || false}
                            />
                            <span>{t('SETTINGS.NOTIFICATION_SHOP')}</span>
                        </label>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='flex'>
                            <input
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                value={this.state.isNotificationCustomer}
                                onChange={(event) => {
                                    this.setState({
                                        isNotificationCustomer: !this.state.isNotificationCustomer,
                                    });
                                }}
                                checked={this.state.isNotificationCustomer || false}
                            />
                            <span>{t('SETTINGS.NOTIFICATION_CUSTOMER')}</span>
                        </label>
                    </div>
                    <div className='ladiui form-group'>
                        <button className='ladiui btn btn-primary btn-sm' style={{ float: 'right' }} onClick={this.submit}>
                            {t('SETTINGS.SAVE_CHANGE')}
                        </button>
                    </div>
                </div>

                {this.getModalEmail()}

                <ConfirmModal
                    id='confirm-delete-email'
                    title={t('SETTINGS.MSG_DELETE_NOTIFICATION_EMAIL')}
                    content={t('SETTINGS.MSG_DELETE_NOTIFICATION_EMAIL_CONTENT', this.state.emailSelected)}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(this.state.emailSelected)}
                    isLoading={isLoadingDelete}
                    maxWidth={450}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create: (email) => dispatch(settingActions.createNotificationEmail(email)),
        changeStatus: (email, status) => dispatch(settingActions.changeStatusNotificationEmail(email, status)),
        delete: (email) => dispatch(settingActions.deleteNotificationEmail(email)),
        listNotificationEmail: () => dispatch(settingActions.listNotificationEmail()),
        updateNotificationEmailConfigs: (notificationEmailConfigs) =>
            dispatch(settingActions.updateNotificationEmailConfigs(notificationEmailConfigs)),
    };
};

const mapStateToProps = (state) => ({
    settingReducer: { ...state.setting },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalSettingNotification)
);
