import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import * as orderTypes from '../../../redux/futures/order/types';
import orderActions from '../../../redux/futures/order/actions';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
export const ShippingPayment = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { openModalShippingFee, shipping, getNamPaymentByCode, onChangePaymentStatus } = props;

    const storeReducer = useSelector((state) => state.store);
    const orderReducer = useSelector((state) => state.order);
    const variantReducer = useSelector((state) => state.variant);

    const refund = (data) => {
        dispatch(orderActions.refund(data));
    };

    return (
        <>
            <div className='order-payment'>
                <div className='ladiui flex-center-between'>
                    <div className='payment-title'>
                        <label className='ladiui label-18 '>{t('ORDERS.PAYMENT')}</label>
                    </div>
                </div>

                <div className='payment-info'>
                    <div className='paymnet-item ladiui text-14'>
                        <span className=''>{t('ORDERS.TOTAL_AMOUNT')}</span>
                        <span>{baseHelper.formatMoneyPostFix(shipping.total, storeReducer.userInfo.currentStore.currency_symbol)}</span>
                    </div>
                </div>

                <div className='payment-info'>
                    <div className='paymnet-item ladiui text-14'>
                        <div className='flex'>
                            <span className='mr-8'>{t('SHIPPINGS.FEE')}</span>
                            <i onClick={openModalShippingFee} style={{ cursor: 'pointer' }} className='ladi-icon icon-edit'></i>
                        </div>

                        <span>{baseHelper.formatMoneyPostFix(shipping.fee, storeReducer.userInfo.currentStore.currency_symbol)}</span>
                    </div>
                </div>

                <div className='payment-info'>
                    <div className='paymnet-item ladiui text-14'>
                        <span className=''>{t('SHIPPINGS.COD_AMOUNT')}</span>
                        <span>
                            {baseHelper.formatMoneyPostFix(shipping.cod_amount, storeReducer.userInfo.currentStore.currency_symbol)}
                        </span>
                    </div>
                </div>
            </div>
            <div className='payment-action ladiui flex-center-between footer'>
                <div>{t('SHIPPINGS.SHIPPING_PAYMENT_STATUS')}</div>

                <div className='ladiui btn-group' style={{ width: '300px', float: 'right' }}>
                    <div className='ladiui btn-group w100'>
                        <div className='ladiui dropdown w100'>
                            <button
                                style={{ minWidth: '100%' }}
                                className='ladiui btn btn-outline-light dropdown-toggle'
                                data-toggle='dropdown'
                            >
                                {getNamPaymentByCode(shipping.payment_status)}
                            </button>
                            <ul className='ladiui dropdown-menu w100'>
                                {Object.values(appConfig.SHIPPING_PAYMENT_STATUS).map((option) => {
                                    return (
                                        <li key={option.CODE} onClick={() => onChangePaymentStatus(shipping.shipping_id, option.CODE)}>
                                            <a className='ladiui dropdown-item'>{option.NAME}</a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
