import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';

import * as orderTagTypes from '../../redux/futures/order_tag/types';
import orderTagActions from '../../redux/futures/order_tag/actions';

import LoadingTable from '../../components/LoadingTable';
import LadiPagination from '../../components/LadiPagination';
import ModalOrderTagCreate from '../orders/OrderCreate/components/ModalOrderTagCreate';
import ConfirmModal from '../../components/ConfirmModal';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';

export default function PageOrderTag(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

    const { search } = useLocation();

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    // const tab = queryStrings.get('tab');

    const storeReducer = useSelector((state) => state.store);
    const orderTagReducer = useSelector((state) => state.orderTag);

    const isLoadingData = useMemo(() => {
        return orderTagReducer.loading || false;
    }, [orderTagReducer.loading]);

    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };
    const [conditions, setConditions] = useState(defaultConditions);
    const [searchName, setSearchName] = useState('');
    const [totalRecord, setTotalRecord] = useState(0);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [isShowModalCreate, setShowModalCreate] = useState(false);
    const [tagSelected, setTagSelected] = useState([]);

    const [listTag, setListTag] = useState([]);

    const [total, setTotalAmount] = useState(0);

    useEffect(() => {
        list();
    }, [conditions]);

    useEffect(() => {
        if (orderTagReducer.action == orderTagTypes.LIST_ORDER_TAG) {
            if (orderTagReducer.status) {
                setListTag(orderTagReducer.allTags);
                setTotalRecord(orderTagReducer.totalRecord);
            }
        }

        if (orderTagReducer.action == orderTagTypes.ORDER_TAG_DELETE) {
            if (orderTagReducer.status) {
                window.LadiUI.toastCustom('success', '', orderTagReducer.message);
                window.LadiUI.closeModal('confirm-tag-delete');
                setTagSelected([]);
                setSelectedIDs([]);
                list();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderTagReducer.message, 'OK');
        }
    }, [orderTagReducer.action]);

    // useEffect(() => {
    //     // add a default parameter if not set
    //     // if (!searchParams.get('query')) {
    //       // this now throws a TypeScript compiler error
    //       // @ts-expect-error
    //       searchParams.set('query', 'pizza');
    //       history.replace({ search: searchParams.toString() });
    //     // }
    //   }, [queryStrings]);

    const selectTab = (index) => {
        // let _queryStrings = new URLSearchParams();
        // _queryStrings.set('tab', index);
        // props.history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const list = () => {
        const data = {
            search: {
                name: searchName,
            },
            paged: conditions.page,
            limit: conditions.limit,
        };
        dispatch(orderTagActions.list(data));
    };

    const openModalEdit = (item) => {
        setTagSelected(item);
        setShowModalCreate(true);
    };
    const openDeleteModal = (item) => {
        setSelectedIDs([item.order_tag_id]);
        window.LadiUI.showModal('confirm-tag-delete');
    };

    const onChangeLimit = (limit) => {
        setConditions((pre) => {
            let tg = { ...pre };

            tg.limit = Number(limit.value);

            if (tg.limit > totalRecord) tg.page = 1;
            return tg;
        });
    };
    const onPageChange = (page) => {
        setConditions((pre) => {
            let tg = { ...pre };
            tg.page = page;
            return tg;
        });
    };
    const checkAllItem = () => {
        if (selectedIDs.length < listTag.length) {
            let ids = listTag.map((item) => item.order_tag_id);
            setSelectedIDs(ids);
        } else setSelectedIDs([]);
    };
    const checkItem = (id) => {
        let ids = [...selectedIDs];
        let index = ids.indexOf(id);

        if (index > -1) {
            ids.splice(index, 1);
        } else {
            ids.push(id);
        }

        setSelectedIDs(ids);
    };

    const deleteItem = () => {
        let data = {
            order_tag: {},
        };

        if (!selectedIDs || selectedIDs.length < 1) {
            window.LadiUI.toastCustom('error', '', t('TAGS.EMPTY'));
            return;
        }

        if (selectedIDs.length == 1) data.order_tag.order_tag_id = selectedIDs[0];
        if (selectedIDs.length > 1) data.order_tag.order_tag_ids = selectedIDs;

        dispatch(orderTagActions.deleteTag(data));
    };

    const eventSelectAction = (action) => {
        if (selectedIDs <= 0) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        switch (action) {
            case 'DELETE':
                window.LadiUI.showModal('confirm-tag-delete');
                break;
            default:
        }
    };

    return (
        <div className='page-content'>
            <div className='header-page'>
                <div className='heading'>
                    <h1>{t('TAGS.TITLE')}</h1>
                    <p>{t('TAGS.SUB_TITLE_ORDER')}</p>
                </div>
                <div className='button-action'>
                    <button type='button' className='btn-order-create' onClick={() => setShowModalCreate(true)}>
                        <i className='icon-ldp-add' />
                        <div className='ladiui btn-custom-text '>{t('TAGS.CREATE_NEW')}</div>
                    </button>
                </div>
            </div>
            <div className='block-filter'>
                <div className='block-search'>
                    <div className='ladiui search-group w-100-p'>
                        <i className='ladiui icon icon-search' onClick={list}></i>
                        <input
                            id='keyword_search'
                            className='ladiui search-field dropdown-toggle form-control search-width'
                            name='searchName'
                            placeholder={t('TAGS.SEARCH_TAG')}
                            aria-expanded='false'
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    list();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {isLoadingData ? (
                <LoadingTable />
            ) : (
                <div className='block-content'>
                    <div className='ladi-card table-order-tag ladiui-table-responsive table-wrap ladiui pos-rel'>
                        {selectedIDs.length > 0 && (
                            <LadiDropdownMenu
                                labelDropdown={'Chọn hành động'}
                                listProp={listAction}
                                defaultTextProp={t('ACTIONS.TITLE')}
                                hideLabel={true}
                                cbProp={eventSelectAction}
                                classSize='btn-sm '
                                classDropdown='w-180 action-record-table'
                            />
                        )}

                        <table className='ladiui table text-left table-order main-table'>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col' className='ladiui w-60-p checkall fix-column fixed-side '>
                                        <input
                                            id='checkAllItem'
                                            onClick={checkAllItem}
                                            type='checkbox'
                                            checked={selectedIDs.length == listTag.length && selectedIDs.length > 0}
                                            onChange={() => {}}
                                            className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-16'
                                        />
                                        <span>{t('TAGS.COL_TAG')} </span>
                                    </th>

                                    <th scope='col'>{t('TAGS.COL_ORDER')}</th>

                                    <th scope='col'>{t('COMMON.CREATED_AT')}</th>
                                    {/* <th scope='col'>{t('COMMON.UPDATED_AT')}</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {listTag.map((item, index) => {
                                    let isSelected = selectedIDs.includes(item.order_tag_id);
                                    return (
                                        <tr key={index} className='ladiui table-vertical main'>
                                            <td className=' fix-column w-60-p display-flex-center '>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        onClick={(event) => checkItem(item.order_tag_id)}
                                                        onChange={() => {}}
                                                        checked={isSelected}
                                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-16'
                                                    />
                                                </div>
                                                <span
                                                    onClick={() => openModalEdit(item)}
                                                    style={item.color ? { backgroundColor: item.color } : {}}
                                                    className='order-tag'
                                                >
                                                    {item.name}
                                                </span>
                                            </td>

                                            <td>{item.quantity}</td>
                                            <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                            {/* <td>{baseHelper.formatStrToDate(item.updated_at)}</td> */}
                                            <td className='text-right pd-0'>
                                                <div className='ladiui btn-group'>
                                                    <div className='ladiui dropdown hide-mt ba-c'>
                                                        <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                            <i className='ladiui icon icon-ldp-dot'></i>
                                                        </button>
                                                        <ul className='ladiui dropdown-menu r-0'>
                                                            <li>
                                                                <a
                                                                    className='ladiui dropdown-item'
                                                                    onClick={() => {
                                                                        openModalEdit(item);
                                                                    }}
                                                                >
                                                                    <i className='new-ldicon-edit' />
                                                                    <span> {t('ACTIONS.EDIT')} </span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className='ladiui dropdown-item' onClick={() => openDeleteModal(item)}>
                                                                    <i className='ladiui icon-new new-ldicon-delete' />
                                                                    <span className='ladiui text-danger'>{t('ACTIONS.DELETE')}</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {/* {totalRecord <= 0 && (
                                    <tr className='text-center'>
                                        <td colSpan='100%'>{t('NO_DATA')}</td>
                                    </tr>
                                )} */}
                            </tbody>
                        </table>
                    </div>
                    {totalRecord > 0 ? (
                        <LadiPagination
                            conditionsProp={conditions}
                            listName={t('PAGINATION.ITEMS')}
                            message={''}
                            items={listTag}
                            total={totalRecord}
                            limitAction={onChangeLimit}
                            pageAction={onPageChange}
                        />
                    ) : (
                        <div className='table-empty'>
                            <i className='new-ldicon-search' />
                            <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                            <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                        </div>
                    )}
                </div>
            )}

            <ModalOrderTagCreate
                visible={isShowModalCreate}
                hideModal={() => setShowModalCreate(false)}
                selectedTag={() => list()}
                isUpdate={tagSelected && tagSelected.order_tag_id}
                _tagSelected={tagSelected}
            />

            <ConfirmModal
                id='confirm-tag-delete'
                title={t('TAGS.MSG_DELETE_TAG_TITLE')}
                content={t('TAGS.MSG_DELETE_TAG_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => deleteItem()}
                isLoading={isLoadingData}
                onCancel={() => setSelectedIDs([])}
            />
        </div>
    );
}
