import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as ticketSeatTypes from '../../../../redux/futures/ticket_seat/types';
import ticketSeatActions from '../../../../redux/futures/ticket_seat/actions';

import LadiDropdownMenu from '../../../../components/LadiDropdownMenu';
import BaseHelper from '../../../../helpers/BaseHelper';
import LadiPagination from '../../../../components/LadiPagination';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalTicketSeat(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // const listAction = [{ value: 'EDIT', name: t('COMMON.EDIT') }];
    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };

    const { productSelected = {}, orderData = {}, visible, hideModal, showReSend, setShowModalTicketEditCustomer } = props;

    const ticketSeatReducer = useSelector((state) => state.ticketSeatReducer);

    const _ticketSeats = useMemo(() => {
        return ticketSeatReducer.ticketSeats || [];
    }, [ticketSeatReducer.ticketSeats]);

    const totalRecord = useMemo(() => {
        return ticketSeatReducer.totalRecord || 0;
    }, [ticketSeatReducer.totalRecord]);

    const loading = useMemo(() => {
        return ticketSeatReducer.loading || false;
    }, [ticketSeatReducer.loading]);

    const [selectedIDs, setSelectedIDs] = useState([]);
    const [ticketSeats, setTicketSeats] = useState([]);
    const [searchName, setSearchName] = useState('');
    const [conditions, setConditions] = useState(defaultConditions);

    useEffect(() => {
        setTicketSeats(_ticketSeats || []);
    }, [_ticketSeats]);

    useEffect(() => {
        if (ticketSeatReducer.action == ticketSeatTypes.CHANGE_CHECK_IN) {
            if (ticketSeatReducer.status) {
                let ticket_seat_id = ticketSeatReducer.ticket_seat_id;

                let index = ticketSeats.findIndex((item) => item.ticket_seat_id == ticket_seat_id);
                if (index > -1) {
                    ticketSeats[index].checked_in = (ticketSeats[index].checked_in + 1) % 2;
                    setTicketSeats(ticketSeats);
                }
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), ticketSeatReducer.message, 'OK');
            }
        }
        if (ticketSeatReducer.action == ticketSeatTypes.UPDATE_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                let ticketSeat = ticketSeatReducer.ticketSeat || {};
                let ticket_seat_id = ticketSeat.ticket_seat_id;

                let index = ticketSeats.findIndex((item) => item.ticket_seat_id == ticket_seat_id);
                if (index > -1) {
                    ticketSeats[index] = { ...ticketSeats[index], ...ticketSeat };
                    setTicketSeats(ticketSeats);
                }
                setShowModalTicketEditCustomer(false);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), ticketSeatReducer.message, 'OK');
            }
        }
    }, [ticketSeatReducer.action]);

    useEffect(() => {
        list();
    }, [productSelected, conditions]);

    const list = () => {
        // console.log('productSelected ; ', productSelected);
        if (!orderData.order_id || !productSelected.product_variant_id) return;

        const data = {
            search: {
                ticket_id: productSelected.product_variant_id,
                order_id: orderData.order_id,

                keyword: searchName,
            },

            paged: conditions.page,
            limit: conditions.limit,
            // sort: sortBy,
        };

        dispatch(ticketSeatActions.list(data));
    };

    const onOk = () => { };
    const onCancel = () => {
        hideModal();
    };

    const checkInTicket = (index) => {
        let ticket = ticketSeats[index];

        if (!ticket) return;

        let data = {
            ticket_seat_id: ticket.ticket_seat_id,
            checked_in: (ticket.checked_in + 1) % 2,
        };
        dispatch(ticketSeatActions.changeCheckIn(data));
    };

    const editTicket = (item) => {
        dispatch(ticketSeatActions.showTicketSeat({ code: item.code }));
        setShowModalTicketEditCustomer(true);
    };

    return (
        <Modal
            id='modal-ticket-seat-list'
            title={
                productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE
                    ? t('TICKET_SEAT.LABEL_EVENT')
                    : t('TICKET_SEAT.LABEL_SERVICE')
            }
            sub_title={
                productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE
                    ? t('TICKET_SEAT.SUB_LABEL_EVENT')
                    : t('TICKET_SEAT.SUB_LABEL_SERVICE')
            }
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            width={1000}
            isLoading={loading}
            position_unset={true}
            hasFooter={false}
        >
            <div>
                <div className='display-flex-center gap-16'>
                    <div className='ladiui search-group w-100-p'>
                        <i className='ladiui icon icon-search' onClick={list}></i>
                        <input
                            id='keyword_search'
                            className='ladiui search-field dropdown-toggle form-control search-width'
                            name='searchName'
                            placeholder={t('ORDERS.SEARCH_ORDER')}
                            aria-expanded='false'
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    list();
                                }
                            }}
                        />
                    </div>
                    <button type='button' className='ladiui btn btn-md btn-secondary btn-advanced-filter' onClick={showReSend}>
                        {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE
                            ? t('TICKET_SEAT.RE_SEND')
                            : t('TICKET_SEAT.RE_SEND_CODE')}
                    </button>
                </div>
                <div className='mt-20'>
                    <div className='table-wrap'>
                        <table className='ladiui table text-left table-order main-table'>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col' className='ladiui checkall fixed-side fix-column'>
                                        {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE ? (
                                            <span className=''>{t('TICKET_SEAT.CODE')}</span>
                                        ) : (
                                            <span className=''>{t('PRODUCT_SERVICES.SKU')}</span>
                                        )}
                                    </th>

                                    {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE ? (
                                        <th scope='col'>{t('PRODUCTS.VARIANT_TICKET_DEFAULT')}</th>
                                    ) : (
                                        <th scope='col'>{t('PRODUCTS.VARIANT_PACKAGE_DEFAULT')}</th>
                                    )}
                                    <th scope='col'>{t('CUSTOMERS.LABEL')}</th>
                                    {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE && (
                                        <th scope='col'>{t('TICKET_SEAT.STAFF_CONFIRM')}</th>
                                    )}
                                    <th scope='col'>{t('ORDERS.ORDERED_AT')}</th>
                                    {/* <th scope='col'>{t('COMMON.STATUS')}</th> */}
                                    {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE ? (
                                        <th scope='col' className='ladiui text-center'>
                                            {t('TICKET_SEAT.CHECK_IN')}
                                        </th>
                                    ) : (
                                        <th scope='col' className='ladiui text-center'>
                                            {t('PRODUCT_SERVICES.USED')}
                                        </th>
                                    )}
                                    <th className='ladiui action-table'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {ticketSeats.map((item, index) => {
                                    let isSelected = selectedIDs.includes(item.ticket_seat_id);

                                    return (
                                        <React.Fragment key={item.ticket_seat_id}>
                                            <tr className={`ladiui table-vertical main`}>
                                                <td className=' fix-column '>
                                                    <div className='display-flex-center '>
                                                        {/* <input
                                                            type='checkbox'
                                                            onClick={(event) => checkItem(item.ticket_seat_id)}
                                                            onChange={() => {}}
                                                            checked={isSelected}
                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-16'
                                                        /> */}
                                                        <div className=''>{item.code}</div>
                                                    </div>
                                                </td>
                                                <td key={index}>{`${item.product_name} ${item.option_name}`}</td>
                                                <td>
                                                    <div>
                                                        {item.first_name && <div>{item.first_name}</div>}
                                                        {item.email && <div>{item.email}</div>}
                                                        {item.phone && <div>{item.phone}</div>}
                                                    </div>
                                                </td>
                                                {productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE && (
                                                    <td>{item.user_checkin || ''}</td>
                                                )}
                                                <td>{BaseHelper.formatDateToStr(item.ordered_at, appConfig.DEFAULT_FORMAT_DATE_TIME)}</td>
                                                {/* <td>{item.status}</td> */}
                                                <td className='ladiui text-center'>
                                                    {item.status == appConfig.TICKET_SEAT_STATUS.CANCELED && "Vé Đã hủy"}
                                                    {item.status == appConfig.TICKET_SEAT_STATUS.ACTIVE &&

                                                        <div className='ladiui item-form switch'>
                                                            <label className='ladiui switch m-0'>
                                                                <input
                                                                    type='checkbox'
                                                                    checked={item.checked_in}
                                                                    onChange={() => {
                                                                        checkInTicket(index);
                                                                    }}
                                                                />
                                                                <span className='ladiui slider round'></span>
                                                            </label>
                                                        </div>
                                                    }
                                                </td>
                                                <td className='text-right pd-0 ladiui action-table'>
                                                    <div className='ladiui btn-group'>
                                                        <div className='ladiui dropdown hide-mt ba-c'>
                                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                                <i className='ladiui icon icon-ldp-dot'></i>
                                                            </button>
                                                            <ul className='ladiui dropdown-menu r-0'>
                                                                <li>
                                                                    <a className='ladiui dropdown-item' onClick={() => editTicket(item)}>
                                                                        {t('ACTIONS.EDIT')}
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {totalRecord > 0 && (
                        <LadiPagination
                            conditionsProp={conditions}
                            listName={
                                productSelected.product_type == appConfig.PRODUCT_TYPE.EVENT.CODE
                                    ? t('TICKET_SEAT.CODE')
                                    : t('PRODUCT_SERVICES.SKU')
                            }
                            items={ticketSeats}
                            total={totalRecord}
                            limitAction={(item) => {
                                setConditions((pre) => {
                                    let tg = { ...pre };
                                    tg.limit = item.value;
                                    return tg;
                                });
                            }}
                            pageAction={(item) => {
                                setConditions((pre) => {
                                    let tg = { ...pre };
                                    tg.page = item;
                                    return tg;
                                });
                            }}
                        />
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default ModalTicketSeat;
