import * as types from './types';

const setData = (type, data) => ({
    type: type,
    payload: {
        data,
    },
});

const setDataLadipage = (data) => {
    return {
        type: types.SET_LADIPAGE_INFO,
        payload: {
            data,
        },
    };
};
const setSSID = (data) => {
    return {
        type: types.SET_SSID,
        payload: {
            data,
        },
    };
};
const setStoreID = (data) => {
    return {
        type: types.SET_LS_STORE_ID,
        payload: {
            data,
        },
    };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { setData, setDataLadipage, setSSID, setStoreID };
