import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import { cloneDeep, find, remove, map } from "lodash";

class DropdownTag extends React.Component {
  static propTypes = {
    allTags: PropTypes.array,
    selectedTags: PropTypes.array,
    readOnly: PropTypes.bool,
    updateTag: PropTypes.func,
    innerClassName: PropTypes.string,
  };

  static defaultProps = {
    readOnly: false,
  };

  constructor(props) {
    super(props);

    const availableTags = cloneDeep(props.allTags) || [];
    remove(availableTags, (tag) => find(props.selectedTags, (_tag) => _tag.name == tag.name));

    this.state = {
      availableTags,
      selectedTags: cloneDeep(props.selectedTags) || [],
      search: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.allTags != nextProps.allTags) {
      this.normalizeTags(nextProps.allTags);
    }

    if (this.props.selectedTags != nextProps.selectedTags) {
      this.setState(
        {
          selectedTags: cloneDeep(nextProps.selectedTags) || [],
        },
        () => this.normalizeTags(this.props.allTags)
      );
    }
  }

  normalizeTags = (allTags) => {
    const availableTags = cloneDeep(allTags) || [];

    remove(availableTags, (tag) => find(this.state.selectedTags, (_tag) => _tag.name == tag.name));

    this.setState({
      availableTags,
    });
  };

  onChangeInput = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  removeTag = (item) => {
    const { selectedTags } = this.state;
    if (find(selectedTags, (tag) => tag.name == item.name)) {
      remove(selectedTags, (tag) => tag.name == item.name);

      this.setState(
        {
          selectedTags,
        },
        () => this.normalizeTags(this.props.allTags)
      );

      if (this.props.updateTag) {
        this.props.updateTag({
          delete_tag: item,
        });
      }
    }
  };

  addTag = (item) => {
    const { selectedTags } = this.state;
    if (!find(selectedTags, (tag) => tag.name == item.name)) {
      selectedTags.push(item);

      this.setState(
        {
          selectedTags,
        },
        () => this.normalizeTags(this.props.allTags)
      );

      if (this.props.updateTag) {
        this.props.updateTag({
          create_tag: item,
        });
      }
    }
  };

  getData = () => {
    return this.state.selectedTags;
  };

  render() {
    const { readOnly, t, innerClassName } = this.props;
    return (
      <div className={`tag-color`} style={this.props.wrapperStyle}>
        <div className="ladiui-tags">
          <div className="ladiui-tag-list">
            {map(this.state.selectedTags, (item, index) => (
              <span style={!item.color ? { zIndex: 1 } : { zIndex: 1, backgroundColor: item.color }} key={index} className="tag">
                <span className="tag-name"> {item.name}</span>
                {!readOnly && (
                  <span
                    style={item.color ? { backgroundColor: item.color } : {}}
                    className="close-tag-icon"
                    onClick={() => this.removeTag(item)}
                  >
                    <i className="btn-close cursor-pointer icon-close-no-bg-16" />
                  </span>
                )}
              </span>
            ))}
            {!readOnly && (
              <Dropdown
                wrapperClassName="dropdown-tag"
                data={this.state.availableTags}
                onSelectItem={this.addTag}
                placeHolder={t("TAGS.ADD_TAG")}
                idBlock="block-dropdown-tag-custom"
                innerClassName={innerClassName}
                urlIcon={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"}
                _key="order_tag_id"
                renderItem={(item, index) => {
                  return (
                    <div
                      key={index}
                      className="item"
                      style={item.color ? { backgroundColor: item.color, color: "var(--main-default)" } : {}}
                      data-value={item["order_tag_id"]}
                    >
                      {item["name"]}
                    </div>
                  );
                }}
                innerBtnAddTag={
                  <div>
                    <div style={{ margin: "0 10px 2px" }}>
                      <div className="ladiui flex flex-start border-none cursor-pointer" onClick={() => this.props.openModalAddTag()}>
                        {/* <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" /> */}
                        <div className="btn-add-product customer">{"Tạo tag mới"}</div>
                      </div>
                    </div>
                  </div>
                }
                wrapperStyle={this.state.availableTags.length <= 0 ? { display: "none" } : { display: "block" }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translation", { withRef: true })(DropdownTag);
