/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import { Editor } from '@tinymce/tinymce-react';
import SelectOption from '../../../components/SelectOption';
import appConfig from '../../../config/app';
import settingActions from '../../../redux/futures/setting/actions';
import * as settingTypes from '../../../redux/futures/setting/types';
import { find, map } from 'lodash';

class ModalSettingTemplatePrint extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func,
        isLoading: PropTypes.bool,
        isLoadingRestore: PropTypes.bool,
        templates: PropTypes.array,
    };

    constructor(props) {
        super(props);

        const templateOrder = find(props.templates, (item) => item.type == appConfig.TEMPLATE_PRINT.PRINT_ORDER.CODE) || {};
        this.state = {
            selectedTemplatePrint: appConfig.TEMPLATE_PRINT.PRINT_ORDER.CODE,
            content: templateOrder.content,
            isShowModalGlossary: false,
        };
    }

    componentDidMount() {
        this.props.listTemplatePrint();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.templates != nextProps.templates) {
            const templateOrder = find(nextProps.templates, (item) => item.type == this.state.selectedTemplatePrint);
            this.setState({
                content: templateOrder.content,
            });
        }
    }

    onSelectItem = (item) => {
        const templateOrder = find(this.props.templates, (_item) => _item.type == item.CODE);
        this.setState({
            selectedTemplatePrint: item.CODE,
            content: templateOrder.content,
        });
    };

    handleEditorChange = (value, editor) => {
        this.setState({
            content: value,
        });
    };

    restore = () => {
        const { selectedTemplatePrint } = this.state;
        const templateOrder = find(this.props.templates, (_item) => _item.type == selectedTemplatePrint);

        this.props.restore(templateOrder.store_template_id);
    };

    submit = () => {
        const { selectedTemplatePrint, content } = this.state;
        const templateOrder = find(this.props.templates, (_item) => _item.type == selectedTemplatePrint);

        this.props.onSubmit({
            store_template_id: templateOrder.store_template_id,
            content,
        });
    };

    openModalGlossary = () => {
        this.setState({
            isShowModalGlossary: true,
        });
    };

    hideModalGlossary = () => {
        this.setState({
            isShowModalGlossary: false,
        });
    };

    getOrderGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_address}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_email}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{created_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.CUSTOMER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_address}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_NOTE')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PAYMENT')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{discount_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            Thông tin hoá đơn
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{billing_company}}'}</td>
                        <td>Tên công ty</td>
                    </tr>
                    <tr>
                        <td>{'{{billing_email}}'}</td>
                        <td>Email công ty</td>
                    </tr>
                    <tr>
                        <td>{'{{billing_tax_code}}'}</td>
                        <td>Mã số thuế công ty</td>
                    </tr>
                    <tr>
                        <td>{'{{billing_company_address}}'}</td>
                        <td>Địa chỉ công ty</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    getShippingGlossary = () => {
        const { t } = this.props;

        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_partner_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PARTER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_address}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_email}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{created_at}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order_prefix}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.ORDER_PREFIX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{reference_no}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.SHIPPING.CUSTOMER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_customer_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{sort_code}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SORT_CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{cod_amount}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.COD_AMOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{note}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.NOTE')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.SHIPPING.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    getModalGlossary = () => {
        const { t } = this.props;

        const { selectedTemplatePrint } = this.state;
        let data;
        switch (selectedTemplatePrint) {
            case appConfig.TEMPLATE_PRINT.PRINT_ORDER.CODE:
                data = this.getOrderGlossary();
                break;
            case appConfig.TEMPLATE_PRINT.PRINT_SHIPPING.CODE:
                data = this.getShippingGlossary();
                break;
        }
        return (
            <Modal
                id='modal-setting-template-print'
                title={t('COMMON.GLOSSARY')}
                visible={this.state.isShowModalGlossary}
                onCancel={this.hideModalGlossary}
                width={650}
                bodyStyles={{ minHeight: '40vh' }}
                hasFooter={false}
            >
                {data}
            </Modal>
        );
    };

    getTemplatePrintName = (value) => {
        const matchedDiscount = find(appConfig.TEMPLATE_PRINT, (item) => item.CODE == value);
        return matchedDiscount.NAME;
    };

    render() {
        const { t } = this.props;

        return (
            <div className='page-setting' id='page-setting-template-print' title={t('SETTINGS.TEMPLATE_PRINT')}>
                <div className='block-title-action'>
                    <h3 className='title'>{t('SETTINGS.TEMPLATE_PRINT')}</h3>
                    <div className='btn-action'>
                        <button type='button' onClick={this.restore} className='back-default ladiui btn btn-outline-secondary btn-small'>
                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-restore.svg' className='mr-8' alt='' />
                            {t('SETTINGS.BACK_TO_DEFAULT')}
                        </button>
                        <button type='button' onClick={this.submit} className='ladiui btn btn-primary ml-16'>
                            {t('SETTINGS.SAVE_CHANGE')}
                        </button>
                    </div>
                </div>
                <div className='ladiui form-group'>
                    {/* <label className="ladiui-label">{t('COMMON.SELECT_TEMPLATE_PRINT')}</label>
                    <SelectOption
                        items={[appConfig.TEMPLATE.PRINT_ORDER, appConfig.TEMPLATE.PRINT_SHIPPING]}
                        onSelectItem={this.onSelectItem}
                        currentKey={this.state.selectedTemplatePrint}
                        _key="CODE"
                        _value="NAME"
                        wrapperStyle={{ maxWidth: 150 }}
                    /> */}
                    <label className='ladiui-label'>{t('COMMON.SELECT_TEMPLATE_PRINT')}</label>
                    <div className='ladiui dropdown'>
                        <button
                            className='ladiui btn btn-outline-light dropdown-toggle'
                            data-toggle='dropdown'
                            style={{ width: '100%', paddingRight: '30px' }}
                        >
                            {this.getTemplatePrintName(this.state.selectedTemplatePrint)}
                            {/* {t('COMMON.SELECT_TEMPLATE_PRINT')} */}
                        </button>
                        <ul className='ladiui dropdown-menu custom-form-dynamic' style={{ width: '100%' }}>
                            {map(appConfig.TEMPLATE_PRINT, (item, index) => (
                                <li key={index} onClick={() => this.onSelectItem(item)}>
                                    <a className='ladiui dropdown-item' href='#'>
                                        {item.NAME}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='content-editor max-width-content' style={{ marginTop: '24px' }}>
                    <div className='title-header'>
                        <h4>{t('SETTINGS.CONTENT')}</h4>
                        <span>
                            <a className='ladiui-link pull-right' onClick={this.openModalGlossary}>
                                {t('COMMON.GLOSSARY')}
                            </a>
                        </span>
                    </div>
                    <Editor
                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                        licenseKey="gpl"
                        value={this.state.content}
                        init={{
                            selector: 'textarea',
                            min_height: 200,
                            max_height: 800,
                            autoresize_overflow_padding: 5,
                            autoresize_bottom_margin: 24,
                            plugins:
                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                            toolbar:
                                'bold italic underline | lineheight | alignleft aligncenter alignright alignjustify | formatselect fontsizeselect | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview print code | insertfile',
                            toolbar_drawer: 'sliding',
                            // language_url: '../../../language/tinymce-vi.js',
                            extended_valid_elements: 'svg[id|val]',
                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                        }}
                        onEditorChange={this.handleEditorChange}
                    />
                </div>

                {this.getModalGlossary()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listTemplatePrint: () => dispatch(settingActions.listTemplatePrint()),
    };
};

const mapStateToProps = (state) => ({
    settingReducer: { ...state.setting },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalSettingTemplatePrint)
);
