import { Component } from "react";
import appConfig from "../../../config/app";

// Màn hình preview giỏ hàng trống
class FormCartEmptyTemplate extends Component {
  renderData(content) {
    let CDN = "https://w.ladicdn.com/";

    let contentHeaderLocation = [];
    let contentFooterLocation = [];

    if (content && content.locations) {
      content.locations.map((item, index) => {
        if (item.value === 7) {
          contentHeaderLocation = item.datas;
        } else {
          contentFooterLocation = item.datas;
        }
      });
    }

    const handleShowContentHeader = (data) => {
      let html = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === "IMAGE") {
          html += `<img src=${CDN}${data[i].image} />`;
        } else {
          html += `<div class="content-header-cart">${data[i].content}</div>`;
        }
      }
      return html;
    };

    const handleShowContentFooter = (data) => {
      let html = "";
      for (let i = 0; i < data.length; i++) {
        if (data[i].type === "IMAGE") {
          html += `<img src=${CDN}${data[i].image} />`;
        } else {
          html += `<div class="content-footer-cart">${data[i].content}</div>`;
        }
      }
      return html;
    };

    return `<div class="ladichat-widget-container" id="ladichat-widget-container">
        <div>
          <div class="ladichat-startpage" id="ladichat-startpage">
            <div class="header-start-page">
              <div class="header-background"></div>
              <div class="intro-store">
                <span>Giỏ hàng (0)</span>
                <span id="icon-close-cart-widget"><i class="ladi-icon icon-close"></i></span>
              </div>
            </div>
            <div class="ladichat-startpage-body" id="ladichat-startpage-body">
              <div class="extra-content content-sdk-ladisale">
                <div class="module-border-wrap" id="agent-module">
                  <div class="list-products-cart-sdk">
                    <div class="block-location-header-cart">
                      ${contentHeaderLocation && contentHeaderLocation.length > 0 ? handleShowContentHeader(contentHeaderLocation) : ""}
                    </div>
                    <div class="cart-empty">
                        <span class="icon-cart-empty">
                            <i class="ladi-icon icon-shopping-cart"></i>
                        </span>
                        <span>Chưa có sản phẩm trong giỏ hàng!</span>
                    </div>
                  </div>
                  <div class="block-location-footer-cart">
                    ${contentFooterLocation && contentFooterLocation.length > 0 ? handleShowContentFooter(contentFooterLocation) : ""}
                      <div class="total-amount-payment">
                        <div class="total-amount">
                          <span class="">Tổng đơn hàng</span>
                          <span class="total">0đ</span>
                        </div>
                        <button class="payment-btn disabled">Thanh toán</button>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>`;
  }
}

export default new FormCartEmptyTemplate();
