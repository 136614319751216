import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const list = (data) => ({
  type: types.LIST_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const show = (customerID) => ({
  type: types.SHOW_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      customer_id: customerID,
    },
    hasAuth: true,
  },
});

const deleteCustomers = (customerIDs) => ({
  type: types.DELETE_CUSTOMERS,
  meta: {
    endpoint: endpoint.CUSTOMER_DELETE_IDS,
    method: REQUEST_METHOD.POST,
    body: {
      customer_ids: customerIDs,
    },
    hasAuth: true,
  },
});

const deleteCustomer = (customerID) => ({
  type: types.DELETE_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_DELETE_ID,
    method: REQUEST_METHOD.POST,
    body: {
      customer_id: customerID,
    },
    hasAuth: true,
  },
});

const create = (customer) => ({
  type: types.CREATE_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      customer: customer,
    },
    hasAuth: true,
  },
});

const update = (customer) => ({
  type: types.UPDATE_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_UPDATE,
    method: REQUEST_METHOD.POST,
    body: {
      customer: customer,
    },
    hasAuth: true,
  },
});

const search = (name) => ({
  type: types.SEARCH_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_SEARCH,
    method: REQUEST_METHOD.POST,
    body: {
      search: name,
    },
    hasAuth: true,
  },
});

const reload = (customerID) => ({
  type: types.RELOAD_CUSTOMER,
  meta: {
    endpoint: endpoint.CUSTOMER_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      customer_id: customerID,
    },
    hasAuth: true,
  },
});

const applyDefaultAddress = (customerID, customerAddressID) => ({
  type: types.APPLY_DEFAULT_ADDRESS_CUSTOMER,
  meta: {
    endpoint: endpoint.APPLY_DEFAULT_ADDRESS_CUSTOMER,
    method: REQUEST_METHOD.POST,
    body: {
      customer_id: customerID,
      customer_address_id: customerAddressID,
    },
    hasAuth: true,
  },
});

const exportCustomer = (data) => ({
  type: types.EXPORT_CUSTOMER,
  meta: {
    endpoint: endpoint.EXPORT_CUSTOMER,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const importCustomer = (form) => ({
  type: types.IMPORT_CUSTOMER,
  meta: {
    endpoint: endpoint.IMPORT_CUSTOMER,
    method: REQUEST_METHOD.POST,
    form,
    hasAuth: true,
  },
});

const updateCustomfieldByCustomer = (data) => ({
  type: types.UPDATE_CUSTOM_FIELD_BY_CUSTOMER,
  meta: {
    endpoint: endpoint.UPDATE_CUSTOM_FIELD_BY_CUSTOMER,
    method: REQUEST_METHOD.POST,
    body: {
      ...data,
    },
    hasAuth: true,
  },
});

const verifyAccount = (data) => ({
  type: types.VERIFY_ACCOUNT,
  meta: {
    endpoint: endpoint.VERIFY_ACCOUNT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const getPasswordToken = (data) => ({
  type: types.GET_PASSWORD_TOKEN,
  meta: {
    endpoint: endpoint.GET_PASSWORD_TOKEN,
    method: REQUEST_METHOD.POST,
    body: {
      email: data
    },
    hasAuth: true,
  },
});

export default {
  list,
  show,
  deletes: deleteCustomers,
  delete: deleteCustomer,
  create,
  update,
  search,
  reload,
  applyDefaultAddress,
  exportCustomer,
  importCustomer,
  updateCustomfieldByCustomer,
  verifyAccount,
  getPasswordToken
};
