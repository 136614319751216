import React from 'react';
import PropTypes from 'prop-types';
import { map, remove } from 'lodash';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { iconIntegration } from '../../../../config/constans';

ModalHaravan.propTypes = {
    formAccount: PropTypes.any,
    onChangeInput: PropTypes.func,
};

export default function ModalHaravan(props) {
    const { formAccount, inputRefs, onChangeInput, getLinkApiUrl, mode, handleSubmit, selectedDataAccessAccount } = props;
    const { t } = useTranslation();

    const fixWidthInput = formAccount && formAccount.token ? '75%' : '100%';

    return (
        <div>
            <div className='content'>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('INTEGRATE.ACCOUNT.LB_NAME')}</label>
                    <Input
                        ref={(ref) => inputRefs.add(ref)}
                        name='name'
                        validationName={t('INTEGRATE.ACCOUNT.LB_NAME')}
                        placeholder={t('INTEGRATE.ACCOUNT.LB_NAME')}
                        value={formAccount.name}
                        onChange={onChangeInput}
                        validations={{ isRequired: true }}
                    />
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('INTEGRATE.ACCOUNT.LB_API_URL')}</label>
                    <div
                        style={{
                            display: formAccount && formAccount.token ? 'flex' : 'block',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Input
                            ref={(ref) => inputRefs.add(ref)}
                            name='api_url'
                            validationName={t('INTEGRATE.ACCOUNT.LB_API_URL')}
                            placeholder={t('INTEGRATE.ACCOUNT.LB_API_ULR_HARAVAN')}
                            value={formAccount.api_url}
                            onChange={onChangeInput}
                            validations={{ isRequired: true }}
                            disabled={formAccount.token ? true : false}
                        // style={{ width: fixWidthInput }}
                        />
                        {/* <div className='connect'>
                            <div className='button-connect'>
                                {formAccount && formAccount.token && (
                                    <button className='ladiui btn btn-secondary' onClick={getLinkApiUrl}>
                                        Đồng bộ lại
                                    </button>
                                )}
                            </div>
                        </div> */}
                    </div>
                </div>

                {
                    mode !== appConfig.FORM_MODE.EDIT
                    &&
                    <>
                        {
                            selectedDataAccessAccount && selectedDataAccessAccount.form_account_id ? (
                                <div className='flex items-center flex-column mt-24 justify-start gap-8'>
                                    <div className='flex flex-item-start justify-center gap-8'>
                                        <img src={iconIntegration.CHECK_FILL} />
                                        <span className='text-black font-500'>{t('Kết nối thành công')}</span>
                                    </div>
                                    <a
                                        className='text-primary line-height-20'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit();
                                        }}
                                    >
                                        {t('Đăng xuất và sử dụng tài khoản khác')}
                                    </a>
                                </div>
                            ) : (
                                <div className='text-center mt-24'>
                                    <button className='ladiui btn btn-secondary flex items-center mx-auto' onClick={() => handleSubmit()}>
                                        <img src='https://w.ladicdn.com/ladiui/icons/ldicon-logo-haravan.svg' />
                                        <span className='ml-8'>Kết nối với Haravan</span>
                                    </button>
                                </div>
                            )
                        }
                    </>
                }
            </div>
        </div>
    );
}
