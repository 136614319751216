import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";

ModalVNPOST.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
  vnPostAccount: PropTypes.any,
  isSubmitLoading: PropTypes.bool,
};

export default function ModalVNPOST(props) {
  const {
    paymentGatewayConfigured,
    vnPostAccount,
    isSubmitLoading,
    onCancel,
    onChangeVNPOSTInput,
    vnPostMode,
    connect,
    inputsVNPOSTRef,
    setVNPOSTModal,
    logo,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      id="modal-vnpost"
      title={!paymentGatewayConfigured ? t("SHIPPINGS.SET_UP_TRANSPORT") : t("SHIPPINGS.DETAIL_TRANSPORT")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={() => connect(appConfig.SHIPPING_PARTNERS.VNPOST.CODE)}
      visible={true}
      isActiveBtnSaveData={paymentGatewayConfigured ? true : false}
      width={600}
      logo={logo}
      bodyStyles={{ minHeight: "20vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("VNPOST.CUSTOMER_CODE")}</label>
                <Input value={paymentGatewayConfigured.config.customer_code} disabled={true} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("VNPOST.CUSTOMER_CODE")}</label>
                  <Input
                    ref={(ref) => inputsVNPOSTRef.add(ref)}
                    value={vnPostAccount.customer_code}
                    validationName={t("VNPOST.CUSTOMER_CODE")}
                    name="customer_code"
                    onChange={(event) => onChangeVNPOSTInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>
                {baseHelper.isDevelopEnv() && (
                  <div className="ladiui form-group flex">
                    <input
                      id="vnpost_mode"
                      type="checkbox"
                      className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                      onChange={setVNPOSTModal}
                      checked={vnPostMode == appConfig.MODE.TEST}
                    />
                    <label htmlFor="vnpost_mode"> {t("MODE.TEST")}</label>
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
