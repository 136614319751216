import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.API_LIST_THIRD_PARTY: {
            return {
                ...state,
                action: types.API_LIST_THIRD_PARTY,
                status: action.status,
                message: action.message,
                third_parties: action.status ? action.payload.third_parties : []
            }
        }
        case types.API_REVOKE_TOKEN: {
            return {
                ...state,
                action: types.API_REVOKE_TOKEN,
                status: action.status,
                message: action.message,
            }
        }
        case types.API_RELOAD_THIRD_PARTY: {
            return {
                ...state,
                action: types.API_RELOAD_THIRD_PARTY,
                status: action.status,
                message: action.message,
                third_parties: action.status ? action.payload.third_parties : []
            }
        }
        case types.API_RESET_API_KEY: {
            return {
                ...state,
                action: types.API_RESET_API_KEY,
                status: action.status,
                message: action.message,
                api_key: action.status ? action.payload.api_key : {}
            }
        }
        default:
            return state;
    }
}

