import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import { isEmpty, map } from 'lodash';
import ModalAddImage from '../../../components/ModalAddImage';
import ModalAddText from '../../../components/ModalAddText';
import ModalInteractiveChannel from '../../../components/ModalInterActiveChannel';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';
import ValidatorHelper from '../../../helpers/ValidatorHelper';
import baseHelper from '../../../helpers/BaseHelper';

import appConfig from '../../../config/app';

import { SketchPicker } from 'react-color';

import Popover from '../../../components/Popover';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const CDN = 'https://w.ladicdn.com/';

const PanelSettingFooterPage = forwardRef((props, ref) => {
    const { isShow, currentDataFooter, handlePreviewPageCourse } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);
    const [interfaceOptions, setInterfaceOptions] = useState([]);
    const courseReducer = useSelector((state) => state.course);
    const [tabSelected, setTabSelected] = useState('');
    const inputsRef = new Set();

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isShowBlockAddContent, setIsShowBlockAddContent] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const [isShowModalAddImage, setIsShowModalAddImage] = useState(false);
    const [isShowModalAddText, setIsShowModalAddText] = useState(false);
    const [isShowModalInterActiveChanel, setIsShowModalInterActiveChanel] = useState(false);

    const [dataSelect, setDataSelect] = useState({});
    const [indexData, setIndexData] = useState(-1);

    const [dataFooter, setDataFooter] = useState({
        background: '#002333',
        color: '#FFFFFF',
        logo: '',
    });

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            let _content = [];
            interfaceOptions.map((item) => {
                _content.push(item.datas);
            });
            return {
                background: dataFooter.background,
                text_color: dataFooter.color,
                content: interfaceOptions,
            };
        },
    }));

    useEffect(() => {
        if (currentDataFooter) {
            setDataFooter({
                background: currentDataFooter.background || '#002333',
                color: currentDataFooter.text_color || '#FFFFFF',
            });
            setInterfaceOptions(currentDataFooter.content || []);
        }
    }, [currentDataFooter]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_FOOTER_LOGO) {
            if (fileReducer.status) {
                setDataFooter({
                    ...dataFooter,
                    logo: fileReducer.cart_logo_url,
                });
            }
        }
    }, [fileReducer]);

    const setColorPicker = (color) => {
        setDataFooter({
            ...dataFooter,
            background: color,
        });
    };

    const renderColorPickerItem = () => {
        return appConfig.LIST_COLOR_PICKER.map((value, index) => {
            return (
                <div
                    className={`color-item ${dataFooter.background == value ? 'checked' : ''}`}
                    key={index}
                    style={{ backgroundColor: value }}
                    onClick={() => setColorPicker(value)}
                >
                    {dataFooter.background == value && <i className='ladiui icon icon-checked'></i>}
                </div>
            );
        });
    };

    // const fileChangedHandlerCartLogo = (event) => {
    //   if (event.target.files.length > 0) {
    //     const uploadFile = event.target.files[0];
    //     const form = baseHelper.getFormDataUpload([uploadFile]);
    //     if (form) {
    //       dispatch(fileActions.uploadFooterLogo(form));
    //     }
    //   }
    // };

    // const removeCartLogo = () => {
    //   setDataFooter({
    //     ...dataFooter,
    //     logo: "",
    //   });
    // };

    // --------------------------------------------------------Thêm vị trí hiển thị---------------------------------------------------------------------------

    const handleAddLocation = () => {
        let _interfaceOptions = [...interfaceOptions];
        let locationName = '';

        switch (interfaceOptions.length) {
            case 0:
                locationName = 'Cột 1';
                break;
            case 1:
                locationName = 'Cột 2';
                break;
            case 2:
                locationName = 'Cột 3';
                break;
            case 3:
                locationName = 'Cột 4';
                break;
            default:
        }

        let location = {
            name: locationName,
            value: interfaceOptions.length + 1,
            datas: [],
        };

        _interfaceOptions.push(location);

        setInterfaceOptions(_interfaceOptions);
    };

    const handleShowBlockAddContent = (value, index) => {
        setIsShowBlockAddContent(!isShowBlockAddContent);
        setSelectedValue(value);
        setSelectedIndex(index);
    };

    const handleDeleteLocation = (value) => {
        let location = interfaceOptions.filter((item) => item.value != value);

        location.map((item, index) => {
            item.name = 'Cột ' + (index + 1);
            item.value = index + 1;
        });

        setInterfaceOptions(location);
    };

    const handleShowModalImage = () => {
        setIndexData(-1);
        setDataSelect({});
        setIsShowModalAddImage(true);
        setIsShowBlockAddContent(false);
    };

    const onSubmitModalAddImage = (value, index) => {
        if (isEmpty(value.name)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập tên gợi nhớ');
            return;
        }
        if (!ValidatorHelper.isValidHttpUrl(value.url)) {
            window.LadiUI.toastCustom('warning', '', 'Đường dẫn không đúng định dạng');
            return;
        }

        let checkoutLocationTg = [...interfaceOptions];
        let data = [...checkoutLocationTg[selectedIndex].datas];

        if (index < 0) data.push(value);
        else data[index] = value;

        checkoutLocationTg[selectedIndex].datas = data;

        setInterfaceOptions(checkoutLocationTg);
        setIndexData(-1);
        setIsShowModalAddImage(false);
        setIsShowBlockAddContent(false);
    };

    const handleShowModalAddText = () => {
        setIndexData(-1);
        setDataSelect({});
        setIsShowModalAddText(true);
        setIsShowBlockAddContent(false);
    };

    const onSubmitModalAddText = (value, index) => {
        if (isEmpty(value.name)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập tên gợi nhớ');
            return;
        }
        let checkoutLocationTg = [...interfaceOptions];
        let data = checkoutLocationTg[selectedIndex].datas;

        if (index < 0) data.push(value);
        else data[index] = value;

        setInterfaceOptions(checkoutLocationTg);
        setIndexData(-1);

        setIsShowModalAddText(false);
        setIsShowBlockAddContent(false);
    };

    const handleDeleteItem = (index, index2) => {
        let location = [...interfaceOptions];
        location[index].datas.splice(index2, 1);
        setInterfaceOptions(location);
    };

    const handleEditItem = (item, index, indexD) => {
        setDataSelect(item);
        setSelectedIndex(index);
        setIndexData(indexD);

        if (item.type == 'IMAGE') {
            setIsShowModalAddImage(true);
        } else if (item.type == 'TEXT') {
            setIsShowModalAddText(true);
        } else {
            setIsShowModalInterActiveChanel(true);
        }
    };

    const handleShowModalInterActiveChanel = () => {
        setIndexData(-1);
        setDataSelect({});
        setIsShowModalInterActiveChanel(true);
        setIsShowBlockAddContent(false);
    };

    const onSubmitModalInterActiveChanel = (value, index) => {
        if (isEmpty(value)) {
            window.LadiUI.toastCustom('warning', '', 'Vui lòng nhập nội dung');
            return;
        }
        let checkoutLocationTg = [...interfaceOptions];
        let data = checkoutLocationTg[selectedIndex].datas;

        if (index < 0) data.push(value);
        else data[index] = value;

        setInterfaceOptions(checkoutLocationTg);
        setIndexData(-1);

        setIsShowModalInterActiveChanel(false);
        setIsShowBlockAddContent(false);
    };

    // -------------------------------------kéo thả----------------------------------

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEndIntefaceItem = (result) => {
        const options = [...interfaceOptions];

        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }

        const items = reorder(options[result.destination.droppableId].datas, result.source.index, result.destination.index);

        options[result.destination.droppableId].datas = items;

        setInterfaceOptions(options);
    };

    const handleSubmitData = () => {
        props.handleSubmitData();
    };

    const handleActionPreviewPageCourse = () => {
        handlePreviewPageCourse();
    };

    const handleShowFooterBackgroundPicker = () => {
        document.getElementById('picker-id-background-footer').click();
    };

    const handleChangeFooterBackgroundPicker = (color) => {
        setDataFooter({
            ...dataFooter,
            background: color.hex,
        });
    };

    return (
        <div className='page-setting' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='block-title-action'>
                <div className='title'>
                    <h3>Thiết lập chân trang</h3>
                </div>
                <div className='actions flex'>
                    <button className='ladiui btn btn-outline-primary mr-24' onClick={() => handleActionPreviewPageCourse()}>
                        Xem trước
                    </button>
                    <button className='ladiui btn btn-primary' onClick={() => handleSubmitData()}>
                        Lưu trang khóa học
                    </button>
                </div>
            </div>
            <div className='setting-content-menu-banner config-panel'>
                <div className='flex mt-16'>
                    <div className='ladiui mb-0 pr-24' style={{ borderRight: '1px solid #e5e5e5' }}>
                        <label className='ladiui-label'>Màu nền</label>
                        <div className='mt-4'>
                            <div className='color-picker flex relative'>
                                {renderColorPickerItem()}
                                {!appConfig.LIST_COLOR_PICKER.includes(dataFooter.background) && (
                                    <div className={`color-item checked`} style={{ backgroundColor: dataFooter.background }}>
                                        <i
                                            className='ladiui icon icon-checked'
                                            style={
                                                dataFooter.background == '#FFFFFF' || dataFooter.background == '#ffffff'
                                                    ? { backgroundColor: '#6d6d6d' }
                                                    : {}
                                            }
                                        ></i>
                                    </div>
                                )}
                                <div className='color-item btn-add-background' onClick={() => handleShowFooterBackgroundPicker()}>
                                    +
                                </div>
                                <Popover
                                    wrapperPopoverProps={{ className: 'popover-picker' }}
                                    styleCustom={{ bottom: '-150', right: '-220', position: 'absolute', zIndex: '999' }}
                                    content={
                                        <SketchPicker
                                            color={dataFooter.background}
                                            onChangeComplete={(color) => handleChangeFooterBackgroundPicker(color)}
                                        />
                                    }
                                >
                                    <div id={`picker-id-background-footer`}> </div>
                                </Popover>
                            </div>
                        </div>
                    </div>
                    <div className='ladiui mb-0 ml-24'>
                        <label className='ladiui-label'>Màu chữ</label>
                        <div className='mt-4'>
                            <div className='flex'>
                                <span
                                    className='color-item select-text-color white'
                                    onClick={() =>
                                        setDataFooter({
                                            ...dataFooter,
                                            color: '#FFFFFF',
                                        })
                                    }
                                >
                                    {dataFooter.color == '#FFFFFF' && <i className='ladiui icon icon-checked'></i>}
                                </span>
                                <span
                                    className='color-item select-text-color black'
                                    onClick={() =>
                                        setDataFooter({
                                            ...dataFooter,
                                            color: '#000000',
                                        })
                                    }
                                >
                                    {dataFooter.color == '#000000' && <i className='ladiui icon icon-checked'></i>}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="panel">
            <div className="panel-line">
              <div className="main-description">Màu chữ</div>
              <div className="content">
                <div className="flex">
                  <span
                    className="color-item select-text-color white"
                    onClick={() =>
                      setDataFooter({
                        ...dataFooter,
                        color: "#FFFFFF",
                      })
                    }
                  >
                    {dataFooter.color == "#FFFFFF" && <i className="ladiui icon icon-checked"></i>}
                  </span>
                  <span
                    className="color-item select-text-color black"
                    onClick={() =>
                      setDataFooter({
                        ...dataFooter,
                        color: "#000000",
                      })
                    }
                  >
                    {dataFooter.color == "#000000" && <i className="ladiui icon icon-checked"></i>}
                  </span>
                </div>
              </div>
            </div>
          </div> */}
                </div>
                <div id='block-custom-interface' className='block-custom-interface'>
                    <div className='title mb-24'>
                        <h3>Tùy chỉnh nội dung ({interfaceOptions.length}/4)</h3>
                        <span className='sub-title'>Mỗi cột nội dung sẽ hiển thị ở chân trang khóa học theo thứ tự từ trái sang phải.</span>
                    </div>
                    <div className='block-content'>
                        <div className='ladiui block-list-location'>
                            {interfaceOptions &&
                                interfaceOptions.length > 0 &&
                                interfaceOptions.map((item, index) => {
                                    return (
                                        <DragDropContext onDragEnd={onDragEndIntefaceItem} key={index}>
                                            <Droppable droppableId={`${index}`} id={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        key={index}
                                                        className='block-item mb-24'
                                                        ref={provided.innerRef}
                                                        style={{
                                                            background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                        }}
                                                    >
                                                        <div className='legend-header flex'>
                                                            <span>{item.name}</span>
                                                            <span className='tooltip-icon' data-tooltip-position='right'>
                                                                <i className='ladi-icon icon-c-question'></i>
                                                            </span>
                                                        </div>

                                                        <div className='list-item'>
                                                            {item.datas &&
                                                                item.datas.length > 0 &&
                                                                item.datas.map((_item, _index) => {
                                                                    return (
                                                                        <Draggable key={_index} draggableId={'' + _index} index={_index}>
                                                                            {(provided, snapshot) => (
                                                                                <>
                                                                                    {_item.type == 'IMAGE' ? (
                                                                                        <div
                                                                                            className='item ladiui flex-center-start'
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            <div className='icon-move mr-12'>
                                                                                                <img
                                                                                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                                                    alt=' '
                                                                                                />
                                                                                            </div>
                                                                                            <div className='w100 ladiui flex-center-between item-content'>
                                                                                                <div className='ladiui flex-center-start'>
                                                                                                    <div className='item-img mr-11'>
                                                                                                        <i className='ldicon-image' />
                                                                                                    </div>
                                                                                                    <div className='item-text'>
                                                                                                        {_item.name}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='action-item'>
                                                                                                    <span
                                                                                                        className='cursor-pointer'
                                                                                                        style={{
                                                                                                            marginRight: '22px',
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleDeleteItem(index, _index)
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg'
                                                                                                            alt=' '
                                                                                                        />
                                                                                                    </span>
                                                                                                    <span
                                                                                                        className='cursor-pointer'
                                                                                                        onClick={() =>
                                                                                                            handleEditItem(
                                                                                                                _item,
                                                                                                                index,
                                                                                                                _index
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                                                                                            alt=' '
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : _item.type == 'TEXT' ? (
                                                                                        <div
                                                                                            className='item ladiui flex-center-start'
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            <div className='icon-move mr-12'>
                                                                                                <img
                                                                                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                                                    alt=' '
                                                                                                />
                                                                                            </div>
                                                                                            <div className='w100 ladiui flex-center-between item-content'>
                                                                                                <div className='ladiui flex-center-start'>
                                                                                                    <div className='item-img mr-11'>
                                                                                                        <i className='ldicon-menu' />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className='item-text'
                                                                                                        // dangerouslySetInnerHTML={{
                                                                                                        //     __html: _item.name,
                                                                                                        // }}
                                                                                                    >
                                                                                                        {_item.name}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='action-item'>
                                                                                                    <span
                                                                                                        className='cursor-pointer'
                                                                                                        style={{
                                                                                                            marginRight: '22px',
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleDeleteItem(index, _index)
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg'
                                                                                                            alt=' '
                                                                                                        />
                                                                                                    </span>
                                                                                                    <span
                                                                                                        className='cursor-pointer'
                                                                                                        onClick={() =>
                                                                                                            handleEditItem(
                                                                                                                _item,
                                                                                                                index,
                                                                                                                _index
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                                                                                            alt=' '
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div
                                                                                            className='item ladiui flex-center-start'
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            {...provided.dragHandleProps}
                                                                                        >
                                                                                            <div className='icon-move mr-12'>
                                                                                                <img
                                                                                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                                                    alt=' '
                                                                                                />
                                                                                            </div>
                                                                                            <div className='w100 ladiui flex-center-between item-content'>
                                                                                                <div className='ladiui flex-center-start'>
                                                                                                    <div className='item-img mr-11'>
                                                                                                        <i className='ldicon-user' />
                                                                                                    </div>
                                                                                                    <div className='item-text'>
                                                                                                        {_item.name}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='action-item'>
                                                                                                    <span
                                                                                                        className='cursor-pointer'
                                                                                                        style={{
                                                                                                            marginRight: '22px',
                                                                                                        }}
                                                                                                        onClick={() =>
                                                                                                            handleDeleteItem(index, _index)
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-delete.svg'
                                                                                                            alt=' '
                                                                                                        />
                                                                                                    </span>
                                                                                                    <span
                                                                                                        className='cursor-pointer'
                                                                                                        onClick={() =>
                                                                                                            handleEditItem(
                                                                                                                _item,
                                                                                                                index,
                                                                                                                _index
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <img
                                                                                                            src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-edit.svg'
                                                                                                            alt=' '
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </Draggable>
                                                                    );
                                                                })}
                                                            {provided.placeholder}
                                                        </div>

                                                        <div className='action-footer flex space-between'>
                                                            <a
                                                                className='flex'
                                                                style={{ paddingLeft: '26px' }}
                                                                onClick={() => handleShowBlockAddContent(item.value, index)}
                                                            >
                                                                <img
                                                                    className='mr-8'
                                                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'
                                                                    alt=''
                                                                />
                                                                <h3>{t('PAGE_CHECKOUT.ADD_LOCATION')}</h3>
                                                            </a>
                                                            <a
                                                                style={{ color: '#D94F45' }}
                                                                onClick={() => handleDeleteLocation(item.value)}
                                                            >
                                                                <h3>{t('PAGE_CHECKOUT.DELETE_LOCATION')}</h3>
                                                            </a>
                                                        </div>
                                                        {isShowBlockAddContent && selectedValue == item.value && (
                                                            <div className='choose-content'>
                                                                <ul className='list-choose-item'>
                                                                    <li onClick={() => handleShowModalImage()}>
                                                                        <div className='image'>
                                                                            <img
                                                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-choose-image.svg'
                                                                                alt=' '
                                                                                style={{ marginRight: '4px' }}
                                                                            />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h3>{t('PAGE_CHECKOUT.IMAGE')}</h3>
                                                                            <span>
                                                                                <h3>Chèn một hình ảnh vào vị trí đã chọn</h3>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                    <li onClick={() => handleShowModalAddText()}>
                                                                        <div className='image'>
                                                                            <img
                                                                                src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-content.svg'
                                                                                alt=' '
                                                                                style={{ marginRight: '4px' }}
                                                                            />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h3>{t('PAGE_CHECKOUT.CONTENT')}</h3>
                                                                            <span>
                                                                                <h3>Chèn một đoạn nội dung vào vị trí đã chọn</h3>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                    <li onClick={() => handleShowModalInterActiveChanel()}>
                                                                        <div className='image'>
                                                                            <img
                                                                                src='https://w.ladicdn.com/ladiui/icons/ldicon-user.svg'
                                                                                alt=' '
                                                                                style={{ marginRight: '4px' }}
                                                                            />
                                                                        </div>
                                                                        <div className=''>
                                                                            <h3>Kênh tương tác</h3>
                                                                            <span>
                                                                                <h3>Chọn một nhóm kênh tương tác vào vị trí đã chọn</h3>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    );
                                })}
                        </div>
                    </div>
                    {interfaceOptions && interfaceOptions.length < 4 && (
                        <div className='btn-add-location mt-24'>
                            <button className='ladiui btn btn-secondary flex' onClick={handleAddLocation}>
                                <i className='cta-add mr-8'></i>
                                <span>Thêm cột</span>
                            </button>
                        </div>
                    )}
                </div>

                {isShowModalAddImage && (
                    <ModalAddImage
                        onCancel={() => setIsShowModalAddImage(false)}
                        onSubmit={onSubmitModalAddImage}
                        visible={isShowModalAddImage}
                        dataSelect={dataSelect}
                        indexData={indexData}
                    />
                )}

                {isShowModalAddText && (
                    <ModalAddText
                        onCancel={() => setIsShowModalAddText(false)}
                        onSubmit={onSubmitModalAddText}
                        visible={isShowModalAddText}
                        dataSelect={dataSelect}
                        indexData={indexData}
                    />
                )}

                {isShowModalInterActiveChanel && (
                    <ModalInteractiveChannel
                        onCancel={() => setIsShowModalInterActiveChanel(false)}
                        onSubmit={onSubmitModalInterActiveChanel}
                        visible={isShowModalInterActiveChanel}
                        dataSelect={dataSelect}
                        indexData={indexData}
                    />
                )}
            </div>
        </div>
    );
});
PanelSettingFooterPage.propTypes = {};

export default PanelSettingFooterPage;
