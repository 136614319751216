import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.FILTER_LIST: {
            return {
                ...state,
                action: types.FILTER_LIST,
                filters: action.status ? action.payload.filters : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.FILTER_CREATE: {
            return {
                ...state,
                action: types.FILTER_CREATE,
                filter: action.status ? action.payload.filter : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.FILTER_UPDATE: {
            return {
                ...state,
                action: types.FILTER_UPDATE,
                status: action.status,
                message: action.message,
            };
        }
        case types.FILTER_DELETE: {
            return {
                ...state,
                action: types.FILTER_DELETE,
                status: action.status,
                message: action.message,
            };
        }
        case types.FILTER_DUPLICATE: {
            return {
                ...state,
                action: types.FILTER_DUPLICATE,
                filter: action.status ? action.payload.filter : [],
                status: action.status,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
