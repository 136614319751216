import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import LoadingTable from '../../../components/LoadingTable';

import ModalCancel from './ModalCancel';
import ModalConfirmPayment from '../OrderEdit/components/ModalConfirmPayment';

import * as orderTypes from '../../../redux/futures/order/types';
import orderActions from '../../../redux/futures/order/actions';

import printActions from '../../../redux/futures/print/actions';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function OrdersTableDetail(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { colCustom, listOrderNow, orderSelected, setShowModalCancel, setShowModalConfirmPayment, setSelectedIDs, setShowModalShipping } =
        props;
    const storeReducer = useSelector((state) => state.store);
    const orderReducer = useSelector((state) => state.order);

    // const [isLoading, setLoading] = useState(false);

    const isLoading = useMemo(() => {
        return orderReducer.loading || false;
    }, [orderReducer.loading]);

    const order = useMemo(() => {
        return orderReducer.order || {};
    }, [orderReducer.order]);

    const orderDetails = useMemo(() => {
        return order.order_details || [];
    }, [order.order_details]);

    useEffect(() => {
        if (orderReducer.action == orderTypes.CANCEL_ORDER) {
            if (orderReducer.order.status) {
                setShowModalCancel(false);
                listOrderNow();
            }
        }
    }, [orderReducer]);

    const paymentOrder = () => {
        setSelectedIDs([order.order_id]);
        setShowModalConfirmPayment(true);
    };

    return (
        <>
            <tr>
                <td colSpan={colCustom} className=''>
                    <div className='table-detail-order-detail'>
                        {isLoading ? (
                            <LoadingTable />
                        ) : (
                            orderSelected &&
                            orderSelected == order.order_id && (
                                <>
                                    <div className='orđer-info'>
                                        <div className='table-detail-products'>
                                            <div>{t('PRODUCTS.TITLE')}</div>
                                            {orderDetails.map((item, index) => {
                                                let imageSrc = baseHelper.getImageUrl(item.src);
                                                let optionNames = item.options
                                                    ? item.options.map((option) => option.option_value_value)
                                                    : [];
                                                let optionName = optionNames.join(', ');
                                                return (
                                                    <div key={index} className='product-info'>
                                                        <div className='product-name-image'>
                                                            <img className='product-image' src={imageSrc} alt='product-image' />
                                                            <div>
                                                                <div className='product-name'>
                                                                    {item.product_name || item.product_name_full}
                                                                </div>
                                                                <div className='product-options'>{optionName}</div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='product-price'>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    item.last_price,
                                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </div>
                                                            <div className='product-quantity'>x{item.quantity}</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {order.discount_fee > 0 && (
                                                <div className='order-total'>
                                                    {` Khuyến mãi ${
                                                        order.discount_code ? `( ${order.discount_code} )` : ''
                                                    }: - ${baseHelper.formatMoneyPostFix(
                                                        order.discount_fee,
                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                    )}`}
                                                </div>
                                            )}
                                            {order.shipping_fee > 0 && (
                                                <div className='order-total'>
                                                    {` Vận chuyển: ${baseHelper.formatMoneyPostFix(
                                                        order.shipping_fee,
                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                    )}`}
                                                </div>
                                            )}
                                            <div className='order-total'>
                                                {` Tổng: ${baseHelper.formatMoneyPostFix(
                                                    order.total,
                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                )}`}
                                            </div>
                                        </div>

                                        <div className='table-detail-customer-info'>
                                            <div> {t('CUSTOMERS.LABEL')}</div>
                                            <div className='customer-info'>
                                                <div className='customer-info-item'>
                                                    <div className='info-label'> {t('ORDERS.CUSTOMER_FULL_NAME')} </div>
                                                    <div className='info-value'>{order.customer_first_name}</div>
                                                </div>
                                                <div className='customer-info-item'>
                                                    <div className='info-label'> {t('ORDERS.PHONE')} </div>
                                                    <div className='info-value'>{order.customer_phone}</div>
                                                </div>
                                                <div className='customer-info-item'>
                                                    <div className='info-label'>{t('COMMON.EMAIL')}</div>
                                                    <div className='info-value'>{order.customer_email}</div>
                                                </div>
                                                <div className='customer-info-item'>
                                                    <div className='info-label'>{t('CUSTOMERS.ADDRESS')}</div>
                                                    <div className='info-value'>{baseHelper.getAddressShippingOrder(order)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order-action'>
                                        <div className='action-left'>
                                            {order.status == appConfig.ORDER_STATUS.OPEN.CODE &&
                                                order.mark_spam == appConfig.STATUS.INACTIVE && (
                                                    <div
                                                        className='action-item'
                                                        onClick={() => {
                                                            dispatch(
                                                                orderActions.updateSpams({
                                                                    order_ids: [order.order_id],
                                                                    mark_spam: appConfig.STATUS.ACTIVE,
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        <i className='new-ldicon-folder-error ' /> {t('ORDERS.MARK_SPAM')}
                                                    </div>
                                                )}
                                            {order.status == appConfig.ORDER_STATUS.OPEN.CODE &&
                                                order.mark_spam == appConfig.STATUS.ACTIVE && (
                                                    <div
                                                        className='action-item'
                                                        onClick={() => {
                                                            dispatch(
                                                                orderActions.updateSpams({
                                                                    order_ids: [order.order_id],
                                                                    mark_spam: appConfig.STATUS.INACTIVE,
                                                                })
                                                            );
                                                        }}
                                                    >
                                                        <i className='new-ldicon-folder-error ' /> {t('ORDERS.REMOVE_MARK_SPAM')}
                                                    </div>
                                                )}
                                            <div
                                                className='action-item'
                                                onClick={() => {
                                                    if (order && order.order_id) dispatch(printActions.printOrder(order.order_id));
                                                }}
                                            >
                                                <i className='ldicon-printer ' /> {t('ORDERS.PRINT_ORDER')}
                                            </div>
                                            {order.status == appConfig.ORDER_STATUS.OPEN.CODE && (
                                                <div
                                                    className='action-item'
                                                    onClick={() => {
                                                        setSelectedIDs([order.order_id]);
                                                        setShowModalCancel(true);
                                                    }}
                                                >
                                                    <i className='ldicon-circle-close' /> {t('ORDERS.CANCEL_ORDER')}
                                                </div>
                                            )}
                                        </div>
                                        <div className='display-flex-center gap-16'>
                                            {order.status == appConfig.ORDER_STATUS.OPEN.CODE &&
                                                order.restock_all == appConfig.STATUS.INACTIVE &&
                                                order.shipping_status == appConfig.SHIPPING_STATUS.PENDING.CODE && (
                                                    <div className='action-right' onClick={() => setShowModalShipping(true)}>
                                                        {t('SHIPPINGS.LABEL')}
                                                    </div>
                                                )}
                                            {order.status == appConfig.ORDER_STATUS.OPEN.CODE &&
                                                order.restock_all == appConfig.STATUS.INACTIVE &&
                                                order.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE && (
                                                    <div className='action-right' onClick={paymentOrder}>
                                                        {t('ORDERS.PAYMENT')}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </td>
            </tr>
        </>
    );
}

export default OrdersTableDetail;
