import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import appConfig from '../../../config/app';

import ticketSeatActions from '../../../redux/futures/ticket_seat/actions';
import * as ticketSeatTypes from '../../../redux/futures/ticket_seat/types';
import LoadingTable from '../../../components/LoadingTable';
import * as productTypes from '../../../redux/futures/product/types';
import productActions from '../../../redux/futures/product/actions';

import { map, includes, find, compact } from 'lodash';
import baseHelper from '../../../helpers/BaseHelper';
import Pagination from 'react-js-pagination';
import produce from 'immer';
import Input from '../../../components/Input';
import ConfirmModal from '../../../components/ConfirmModal';
import Modal from '../../../components/Modal';
import SelectOption from '../../../components/SelectOption';
import LadiPagination from '../../../components/LadiPagination';

export default function PanelPackageSeat(props) {
    const { serviceID, orderID, ticketCreationForm, currentProductInfo, options } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ticketSeatReducer = useSelector((state) => state.ticketSeatReducer);
    const storeReducer = useSelector((state) => state.store);

    const [ticketSeats, setTicketSeats] = useState([]);

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [search, setSearch] = useState({
        keyword: '',
    });

    const [selectedTicketSeat, setSelectedTicketSeat] = useState({});
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowImportTicketSeatModal, setIsShowImportTicketSeatModal] = useState(false);
    const [selectTicket, setSelectTicket] = useState(
        currentProductInfo && currentProductInfo.variants ? currentProductInfo.variants[0] : {}
    );
    const inputsRef = new Set();

    const sortBy = {};

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    }, []);

    useEffect(() => {
        if (serviceID || orderID) {
            list();
        }
    }, [serviceID, orderID]);

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    });

    useEffect(() => {
        if (currentProductInfo && !baseHelper.isEmpty(currentProductInfo.variants)) {
            setSelectTicket(currentProductInfo.variants[0]);
        }
    }, [currentProductInfo]);

    useEffect(() => {
        if (ticketSeatReducer.action == ticketSeatTypes.LIST_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                setTicketSeats(produce(ticketSeatReducer.ticketSeats, (draft) => {}));
            } else {
                window.LadiUI.toastCustom('danger', '', ticketSeatReducer.message);
            }
        }

        if (ticketSeatReducer.action == ticketSeatTypes.UPDATE_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                list();
                window.LadiUI.toastCustom('success', '', ticketSeatReducer.message);
                hideModal();
            } else {
                window.LadiUI.toastCustom('danger', '', ticketSeatReducer.message);
            }
        }

        if (ticketSeatReducer.action == ticketSeatTypes.DELETE_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                list();
                window.LadiUI.toastCustom('success', '', ticketSeatReducer.message);
            } else {
                window.LadiUI.toastCustom('danger', '', ticketSeatReducer.message);
            }
        }

        if (ticketSeatReducer.action == ticketSeatTypes.IMPORT_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                list();
                dispatch(productActions.show(currentProductInfo.product_id));
                window.LadiUI.toastCustom('success', '', ticketSeatReducer.message);
            } else {
                window.LadiUI.toastCustom('danger', '', ticketSeatReducer.message);
            }
            hideImportTicketSeatModal();
        }

        if (ticketSeatReducer.action == ticketSeatTypes.EXPORT_TICKET_SEAT) {
            if (ticketSeatReducer.status) {
                window.LadiUI.toastCustom('success', '', ticketSeatReducer.message);
            } else {
                window.LadiUI.toastCustom('danger', '', ticketSeatReducer.message);
            }
        }
    }, [ticketSeatReducer]);

    useEffect(() => {
        if (serviceID || orderID) {
            list();
        }
    }, [conditions]);

    const list = () => {
        const data = {
            search: {
                event_id: props.serviceID,
                order_id: props.orderID,
                ...search,
            },
            paged: conditions.page,
            limit: conditions.limit,
            sort: sortBy,
        };

        dispatch(ticketSeatActions.list(data));
    };

    const submit = () => {
        let errors = [];
        const _inputRef = compact(Array.from(inputsRef));
        map(_inputRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });
        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        dispatch(ticketSeatActions.update(selectedTicketSeat));
    };

    const hideModal = () => {
        setIsShowModal(false);
    };

    const openDeleteModal = (ticketSeat) => {
        setSelectedTicketSeat(produce(ticketSeat, (draft) => {}));
        window.LadiUI.showModal('confirm-delete');
    };

    const hideImportTicketSeatModal = () => {
        setIsShowImportTicketSeatModal(false);
    };

    const openImportTicketSeatModal = () => {
        setIsShowImportTicketSeatModal(true);
    };

    const onChangeLimit = (option) => {
        let conditionsTg = { ...conditions };
        conditionsTg.limit = option.value;
        setConditions(conditionsTg);
    };

    const onPageChange = (pageNumber) => {
        let conditionsTg = { ...conditions };
        conditionsTg.page = pageNumber;
        setConditions(conditionsTg);
    };

    const onChangeInput = (event) => {
        if (event.target.name == 'checked_in') {
            setSelectedTicketSeat(
                produce(selectedTicketSeat, (draft) => {
                    draft.checked_in = draft.checked_in == appConfig.STATUS.ACTIVE ? appConfig.STATUS.INACTIVE : appConfig.STATUS.ACTIVE;
                })
            );
            return;
        }
        setSelectedTicketSeat(
            produce(selectedTicketSeat, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeCheckedIn = (ticketSeat) => {
        let checkedIn = ticketSeat.checked_in == appConfig.STATUS.ACTIVE ? appConfig.STATUS.INACTIVE : appConfig.STATUS.ACTIVE;
        setTicketSeats(
            produce(ticketSeats, (draft) => {
                const foundIndex = draft.findIndex((item) => item.ticket_seat_id == ticketSeat.ticket_seat_id);
                draft[foundIndex].checked_in = checkedIn;
            })
        );

        dispatch(
            ticketSeatActions.changeCheckIn({
                ticket_seat_id: ticketSeat.ticket_seat_id,
                checked_in: checkedIn,
            })
        );
    };

    const importSeatTicket = (event) => {
        const file = event.target.files[0];
        const ticketId = selectTicket.product_variant_id;
        const serviceID = currentProductInfo.product_id;

        const form = new FormData();
        form.set('ticket_id', ticketId);
        form.set('event_id', serviceID);
        form.append('file', file, file.name);

        dispatch(ticketSeatActions.importSeatTicket(form));

        event.target.value = '';
    };

    const exportSeatTicket = () => {
        const serviceID = currentProductInfo.product_id;
        dispatch(ticketSeatActions.exportSeatTicket(serviceID));
    };
    const setIsShowModalTicket = () => {
        document.getElementById('trigger-tab-variants-create').click();
    };

    const exportSampleDocument = () => {
        dispatch(ticketSeatActions.downloadTemplateTicketSeat());
    };

    const { totalRecord, totalPage } = ticketSeatReducer;
    let fromItem = 0;
    let toItem = 0;
    if ((ticketSeatReducer.ticketSeats || []).length > 0) {
        fromItem = (conditions.page - 1) * conditions.limit + 1;
        toItem = fromItem + ticketSeatReducer.ticketSeats.length - 1;
    }
    const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
        'PAGINATION.OF'
    )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

    const isLoadingSubmit =
        ticketSeatReducer.loading &&
        includes([ticketSeatTypes.UPDATE_TICKET_SEAT, ticketSeatTypes.IMPORT_TICKET_SEAT], ticketSeatReducer.waiting);
    const isLoadingData = ticketSeatReducer.loading && includes([ticketSeatTypes.LIST_TICKET_SEAT], ticketSeatReducer.waiting);
    const isLoadingDelete = ticketSeatReducer.loading && includes([ticketSeatTypes.DELETE_TICKET_SEAT], ticketSeatReducer.waiting);

    return isLoadingData ? (
        <LoadingTable />
    ) : (
        <div className='block-content-item'>
            <div className='header-top flex items-center space-between mb-20'>
                <h3>Quản lý mã dịch vụ</h3>
                <div className='info-help flex items-center'>
                    {ticketCreationForm == appConfig.TICKET_CREATION_FORM.IMPORT.CODE && (!options || options.length > 0) && (
                        <a className='flex mr-16' onClick={exportSampleDocument}>
                            <span className='mr-8'>
                                <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-download-code.svg' alt='' />
                            </span>
                            <span>{t('TICKET_CREATION_FORM.DOWNLOAD_TICKET_SERVICE')}</span>
                        </a>
                    )}
                    <div className='flex items-center'>
                        <i className='ladi-icon icon-c-question mr-8'></i>
                        <span>Tìm hiểu thêm</span>
                    </div>
                </div>
            </div>
            {!orderID && (!options || options.length <= 0) && (!ticketSeats || ticketSeats.length <= 0) ? (
                <h3 className='text-center ticket-noData'>
                    {t('TICKET_CREATION_FORM.NO_PACKAGE')}
                    <a href='#' onClick={() => setIsShowModalTicket()}>
                        {' '}
                        {t('TICKET_CREATION_FORM.CREATE_PACKAGE')}{' '}
                    </a>
                </h3>
            ) : (
                <div className='block-content-ticket-seat'>
                    <div className='header-action'>
                        <div className='action-item pull-left'>
                            <div className='ladiui search-keyword'>
                                <input
                                    type='text'
                                    id='search'
                                    placeholder={t('COMMON.SEARCH')}
                                    name='searchName'
                                    value={search.keyword}
                                    onChange={(event) => {
                                        setSearch(
                                            produce(search, (draft) => {
                                                draft.keyword = event.target.value;
                                            })
                                        );
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            if (conditions.page == 1) {
                                                list();
                                            } else {
                                                onPageChange(1);
                                            }
                                        }
                                    }}
                                    className='ladiui form-control'
                                />
                                <i className='ladi-icon icon-zoom' onClick={() => list()} />
                            </div>
                        </div>
                        {ticketCreationForm == appConfig.TICKET_CREATION_FORM.IMPORT.CODE && (!options || options.length > 0) && (
                            <div className='action-item pull-right flex gap-16'>
                                <button className='flex ladiui btn btn-secondary' onClick={openImportTicketSeatModal}>
                                    <span className='flex mr-8'>
                                        <img
                                            src='https://w.ladicdn.com/ladiui/icons/new-ldicon-import-file.svg'
                                            width={16}
                                            height={16}
                                            alt=''
                                        />
                                    </span>
                                    <span>{t('TICKET_CREATION_FORM.IMPORT_PACKAGE_SEAT')}</span>
                                </button>

                                <button className='flex ladiui btn btn-secondary' onClick={exportSeatTicket}>
                                    <span className='flex mr-8'>
                                        <img
                                            src='https://w.ladicdn.com/ladiui/icons/icon-ldp-export-ladipage.svg'
                                            width={16}
                                            height={16}
                                            alt=''
                                        />
                                    </span>
                                    <span>{t('TICKET_CREATION_FORM.EXPORT_PACKAGE_SEAT')}</span>
                                </button>
                            </div>
                        )}
                    </div>

                    {(!options || options.length <= 0) && ticketSeats && (
                        <h3 className='text-center ticket-noData'>
                            {t('TICKET_CREATION_FORM.NO_PACKAGE')}
                            <a href='#' onClick={() => setIsShowModalTicket()}>
                                {' '}
                                {t('TICKET_CREATION_FORM.CREATE_PACKAGE')}{' '}
                            </a>
                        </h3>
                    )}

                    <div className='ladi-card ladiui-table-responsive'>
                        <table className={`ladiui table text-left`}>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col'>{t('PRODUCT_EVENTS.TBL_CODE')}</th>
                                    <th scope='col'>{t('PRODUCTS.VARIANT_PACKAGE_DEFAULT')}</th>
                                    <th scope='col'>Thông tin khách hàng</th>
                                    <th scope='col'>{t('COMMON.CREATED_AT')}</th>
                                    <th scope='col'>{t('COMMON.ORDERED_AT')}</th>
                                    <th scope='col'>{t('COMMON.STATUS')}</th>
                                    <th scope='col'>{t('TICKET_CREATION_FORM.PACKAGE_STATUS')}</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {map(ticketSeats, (item, index) => {
                                    return (
                                        <tr key={index} className='ladiui table-vertical'>
                                            <td
                                                className={
                                                    item.status == appConfig.TICKET_SEAT_STATUS.CANCELED ? 'code-ticket-canceled' : ''
                                                }
                                            >{`${storeReducer.userInfo.currentStore.ticket_seat_prefix}${item.code}`}</td>
                                            <td>{item.option_name}</td>
                                            <td>
                                                <div className='block-user-info'>
                                                    <p className='user-name'>
                                                        {`${baseHelper.getText(item.last_name)} ${baseHelper.getText(item.first_name)}`}
                                                    </p>
                                                    <p>{item.email}</p>
                                                    <p>{item.phone}</p>
                                                </div>
                                            </td>
                                            <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                            <td>{baseHelper.formatStrToDate(item.ordered_at)}</td>
                                            <td>
                                                {/* <div className='onoffswitch m-t-0 ticket-seat'>
                                                    <input
                                                        type='checkbox'
                                                        name='onoffswitch'
                                                        className='onoffswitch-checkbox'
                                                        id={`myonoffswitch_${item.ticket_seat_id}`}
                                                        checked={item.checked_in == appConfig.STATUS.ACTIVE}
                                                        onChange={() => onChangeCheckedIn(item)}
                                                        disabled={item.status != appConfig.TICKET_SEAT_STATUS.ACTIVE}
                                                    />
                                                    <label className='onoffswitch-label' htmlFor={`myonoffswitch_${item.ticket_seat_id}`}>
                                                        <span className='onoffswitch-inner'></span>
                                                        <span className='onoffswitch-switch'></span>
                                                    </label>
                                                </div> */}
                                                <div className='ladiui item-form switch'>
                                                    <label className='ladiui switch m-0'>
                                                        <input
                                                            type='checkbox'
                                                            onChange={() => onChangeCheckedIn(item)}
                                                            checked={item.checked_in == appConfig.STATUS.ACTIVE}
                                                            disabled={item.status != appConfig.TICKET_SEAT_STATUS.ACTIVE}
                                                        />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                {item.status != appConfig.TICKET_SEAT_STATUS.CANCELED ? (
                                                    // <div className='onoffswitch m-t-0 ticket-status'>
                                                    //     <input
                                                    //         type='checkbox'
                                                    //         name='check_ticket_status'
                                                    //         className='onoffswitch-checkbox'
                                                    //         disabled={true}
                                                    //         checked={item.status == appConfig.STATUS.ACTIVE}
                                                    //     />
                                                    //     <label className='onoffswitch-label'>
                                                    //         <span className='onoffswitch-inner'></span>
                                                    //         <span className='onoffswitch-switch'></span>
                                                    //     </label>
                                                    // </div>
                                                    <div className='block-ckeck-ticket-status'>
                                                        {item.status ? (
                                                            <div className='sold flex items-center'>Đã bán</div>
                                                        ) : (
                                                            <div className='not-sold flex items-center'>Chưa bán</div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    // <div className='onoffswitch m-t-0 ticket-status'>
                                                    //     <span className='cancel-ticket'>{t('ACTIONS.CANCELED')}</span>
                                                    // </div>
                                                    <div className='block-package-cancel flex items-center'>{t('ACTIONS.CANCELED')}</div>
                                                )}
                                            </td>
                                            <td className='text-right pd-0'>
                                                {item.status != appConfig.TICKET_SEAT_STATUS.CANCELED && (
                                                    <div className='ladiui btn-group'>
                                                        <div className='ladiui dropdown hide-mt ba-c'>
                                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                                <i className='ladiui icon icon-ldp-dot'></i>
                                                            </button>
                                                            <ul className='ladiui dropdown-menu r-0'>
                                                                <li>
                                                                    <a
                                                                        className='ladiui dropdown-item'
                                                                        onClick={() => {
                                                                            setSelectedTicketSeat(produce(item, (draft) => {}));
                                                                            setIsShowModal(true);
                                                                        }}
                                                                    >
                                                                        {t('ACTIONS.EDIT')}
                                                                    </a>
                                                                </li>
                                                                {item.status == appConfig.STATUS.INACTIVE && (
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() => openDeleteModal(item)}
                                                                        >
                                                                            {t('ACTIONS.DELETE')}
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}

                                {totalRecord <= 0 && (
                                    <tr className='text-center'>
                                        <td colSpan='100%'>
                                            <div className='table-no-data my-24'>{t('NO_DATA')}</div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {totalRecord > 0 && (
                        <LadiPagination
                            conditionsProp={conditions}
                            listName={t('PAGINATION.ITEMS')}
                            message={''}
                            items={ticketSeats}
                            total={totalRecord}
                            limitAction={onChangeLimit}
                            pageAction={onPageChange}
                        />
                    )}
                    {totalRecord > 0 && (
                        <div className='ladiui pagination-block'>
                            <ConfirmModal
                                id='confirm-delete'
                                title={t('PRODUCT_EVENTS.MSG_DELETE_TICKET_SEAT_TITLE')}
                                content={t(
                                    'PRODUCT_EVENTS.MSG_DELETE_TICKET_SEAT_CONTENT',
                                    `${storeReducer.userInfo.currentStore.ticket_seat_prefix}${selectedTicketSeat.code}`
                                )}
                                cancelText={t('ACTIONS.CANCEL')}
                                okText={t('ACTIONS.DELETE')}
                                onOk={() => {
                                    dispatch(ticketSeatActions.delete(selectedTicketSeat.ticket_seat_id));
                                }}
                                isLoading={isLoadingDelete}
                            />

                            {isShowModal && (
                                <Modal
                                    id='modal-ticket-seat'
                                    title={t('PRODUCT_EVENTS.TT_EDIT_TICKET_SEAT')}
                                    visible={isShowModal}
                                    onCancel={hideModal}
                                    onOk={submit}
                                    isLoading={isLoadingSubmit}
                                    width={600}
                                >
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>{t('PRODUCT_EVENTS.TBL_CODE')}</label>
                                        <Input
                                            value={`${storeReducer.userInfo.currentStore.ticket_seat_prefix}${selectedTicketSeat.code}`}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='flex gap-20'>
                                        <div className='ladiui form-group mt-24'>
                                            <label className='ladiui-label new'>{t('COMMON.FIRST_NAME')}</label>
                                            <Input
                                                name='first_name'
                                                validationName={t('COMMON.FIRST_NAME')}
                                                placeholder={t('COMMON.FIRST_NAME')}
                                                value={selectedTicketSeat.first_name}
                                                onChange={onChangeInput}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>{t('COMMON.LAST_NAME')}</label>
                                            <Input
                                                name='last_name'
                                                validationName={t('COMMON.LAST_NAME')}
                                                placeholder={t('COMMON.LAST_NAME')}
                                                value={selectedTicketSeat.last_name}
                                                onChange={onChangeInput}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex gap-20'>
                                        <div className='ladiui form-group mt-24'>
                                            <label className='ladiui-label new'>{t('COMMON.EMAIL')}</label>
                                            <Input
                                                name='email'
                                                validationName={t('COMMON.EMAIL')}
                                                placeholder={t('COMMON.EMAIL')}
                                                value={selectedTicketSeat.email}
                                                onChange={onChangeInput}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validations={{ email: true }}
                                            />
                                        </div>
                                    </div>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label'>{t('COMMON.ADDRESS')}</label>
                                        <Input
                                            name='address'
                                            placeholder={t('COMMON.ADDRESS')}
                                            value={selectedTicketSeat.address}
                                            onChange={onChangeInput}
                                        />
                                    </div>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>{t('COMMON.NOTE')}</label>
                                        <textarea
                                            className='ladiui textarea'
                                            name='note'
                                            placeholder={t('COMMON.NOTE')}
                                            value={selectedTicketSeat.note || ''}
                                            onChange={onChangeInput}
                                        />
                                    </div>
                                    <div className='ladiui-row flex mt-24'>
                                        <div className='col-50'>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label new'>{t('COMMON.STATUS_CHECK_IN')}</label>
                                                <div className='onoffswitch ticket-seat'>
                                                    <input
                                                        type='checkbox'
                                                        name='checked_in'
                                                        className='onoffswitch-checkbox'
                                                        id={`myonoffswitch_selected_${selectedTicketSeat.ticket_seat_id}`}
                                                        checked={selectedTicketSeat.checked_in == appConfig.STATUS.ACTIVE}
                                                        disabled={selectedTicketSeat.status <= 0}
                                                        onChange={onChangeInput}
                                                    />
                                                    <label
                                                        className='onoffswitch-label'
                                                        htmlFor={`myonoffswitch_selected_${selectedTicketSeat.ticket_seat_id}`}
                                                    >
                                                        <span className='onoffswitch-inner'></span>
                                                        <span className='onoffswitch-switch'></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-50'>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label new'>{t('COMMON.STATUS_TICKET')}</label>
                                                <div className='onoffswitch ticket-status'>
                                                    <input
                                                        type='checkbox'
                                                        name='check_ticket_status'
                                                        className='onoffswitch-checkbox'
                                                        checked={selectedTicketSeat.status == appConfig.STATUS.ACTIVE}
                                                        disabled={true}
                                                    />
                                                    <label className='onoffswitch-label'>
                                                        <span className='onoffswitch-inner'></span>
                                                        <span className='onoffswitch-switch'></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            )}
                        </div>
                    )}

                    {isShowImportTicketSeatModal && (
                        <Modal
                            id='import-ticket-seat-modal'
                            title={t('TICKET_CREATION_FORM.import_PACKAGE')}
                            visible={isShowImportTicketSeatModal}
                            onCancel={hideImportTicketSeatModal}
                            okText={t('TICKET_CREATION_FORM.CHOSE_FILE')}
                            isLoading={isLoadingSubmit}
                            width={400}
                            bodyClassName='position-initial'
                            onOk={(event) => {
                                event.preventDefault();
                                document.getElementById('uploadFile').click();
                            }}
                        >
                            <div className='ladiui form-group w100'>
                                <label className='ladiui-label'>{t('PRODUCT_EVENTS.TAB_SERVICE')}</label>
                                <SelectOption
                                    currentKey={selectTicket.product_variant_id}
                                    items={currentProductInfo ? currentProductInfo.variants : []}
                                    _key={'product_variant_id'}
                                    _value={'option_name'}
                                    onSelectItem={(item) => {
                                        setSelectTicket(item);
                                    }}
                                    customStyle={{ width: '100%' }}
                                    innerClassName='w100'
                                    customClassName='position-initial'
                                    wrapperClassName='position-initial w100'
                                    idBlock='modal-import-package-seat-v4'
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <input
                                    id='uploadFile'
                                    type='file'
                                    name='file'
                                    accept='.xlsx, .xls'
                                    onChange={importSeatTicket}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </Modal>
                    )}
                </div>
            )}
        </div>
    );
}
