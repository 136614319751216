import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.LIST_CHECKOUT: {
      return {
        ...state,
        action: types.LIST_CHECKOUT,
        checkouts: action.status ? action.payload.checkouts : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        total: action.status ? action.payload.total : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHOW_CHECKOUT: {
      return {
        ...state,
        action: types.SHOW_CHECKOUT,
        status: action.status,
        message: action.message,
        checkout: action.status ? action.payload.checkout : {},
      };
    }
    case types.RELOAD_CHECKOUT: {
      return {
        ...state,
        action: types.RELOAD_CHECKOUT,
        status: action.status,
        message: action.message,
        checkout: action.status ? action.payload.checkout : {},
      };
    }
    case types.UPDATE_CHECKOUT: {
      return {
        ...state,
        action: types.UPDATE_CHECKOUT,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_CHECKOUT: {
      return {
        ...state,
        action: types.DELETE_CHECKOUT,
        status: action.status,
        message: action.message,
      };
    }
    case types.SEND_NOTIFICATION_CHECKOUT: {
      return {
        ...state,
        action: types.SEND_NOTIFICATION_CHECKOUT,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
