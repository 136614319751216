/** @format */

import axios from 'axios';
import * as commonTypes from '../futures/common/types';
import { SIGNOUT } from '../futures/auth/types';

import { GET_USER_INFO } from '../futures/store/types';
import { LIST_COUNTRIES, LIST_STATES, LIST_DISTRICTS, LIST_WARDS } from '../futures/address_config/types';
import appConfig, { status, endpoint as Endpoint } from '../../config/app';

import { includes, omit, has } from 'lodash';
import baseHelper from '../../helpers/BaseHelper';
import { appIndexedDB, appLocalStorage } from '../../localforage';
import { saveAs } from 'file-saver';

export const REQUEST_METHOD = {
    GET: 'get',
    POST: 'post',
};

const serviceMiddleware = (store) => (next) => (action) => {
    next({ type: commonTypes.ASYNC_START, waiting: action.type });
    if (action.meta) {
        const { endpoint, body, method, hasAuth, form, stream, hasStoreID, showNotify, isStream = false } = action.meta;
        const SSID = store.getState().cookieReducer.SSID;
        const storeID = store.getState().cookieReducer.STORE_ID;

        if (form) {
            let data = {};
            for (let [key, prop] of form) {
                data[key] = JSON.parse(JSON.stringify(prop));
            }

            data = JSON.parse(JSON.stringify(data, null, 2));
        }

        const req = {
            method: method,
            url: endpoint,
            headers: requestHeaders(hasAuth, form, hasStoreID, isStream, SSID, storeID),
            data: body ? body : form,
        };

        // nếu là upload bunnyvideo thì timeout 10 phút
        if (endpoint.includes(Endpoint.BUNNY_CREATE_VIDEO)) {
            req.timeout = 10 * 60 * 1000;
        }

        axios(req)
            .then(function (response) {
                let { data } = response;
                if (has(response.headers, 'store-id')) {
                    action.new_store_id = response.headers['store-id'];
                }

                switch (data.code) {
                    case status.OK:
                    case status.CREATED:
                    case '00':
                        action.status = true;
                        action.payload = data.data;
                        action.message = data.message;

                        // Handle cache data
                        cacheData(action);
                        break;
                    case status.UNAUTHORIZED:
                        baseHelper.removeCookie(appConfig.COOKIE.SSID);
                        baseHelper.removeCookie(appConfig.COOKIE.STORE_ID);
                        // next({ type: SIGNOUT });
                        return;
                    case status.FORBIDDEN:
                        action.status = false;
                        action.message = data.message;
                        break;
                    default:
                        action.status = false;
                        action.message = data.message;
                        break;
                }

                if (showNotify) {
                    if (action.status) {
                        window.LadiUI.toastCustom('success', 'Thông báo', data.message);
                    } else {
                        window.LadiUI.showErrorMessage('Thông báo', data.message, 'OK');
                    }
                }

                if (
                    (action.type.indexOf('EXPORT_') >= 0 ||
                        action.type.indexOf('DOWNLOAD_') >= 0 ||
                        action.type.indexOf('IMPORT_PRODUCT') >= 0 ||
                        action.type.indexOf('IMPORT_EVENT') >= 0 ||
                        action.type.indexOf('IMPORT_SERVICE') >= 0) &&
                    action.status
                ) {
                    const fileBuffer = data.data.buffer;
                    if (fileBuffer) {
                        const blob = new Blob([new Uint8Array(fileBuffer.data)]);
                        saveAs(blob, data.data.name);
                    }
                }

                next(action);
                next({ type: commonTypes.ASYNC_END, done: action.type });
            })
            .catch(function (error) {
                action.status = false;
                action.message = error.message;

                if (error.response && error.response.data) action.message = error.response.data.message;

                window.LadiUI.showErrorMessage('Thông báo', action.message, 'OK');

                // next(action);
                // window.LadiUI.toastCustom('danger', '', error.message);
                next({ type: commonTypes.ASYNC_END, done: action.type });
            });

        return;
    }

    next(action);
    next({ type: commonTypes.ASYNC_END, done: action.type });
};

function requestHeaders(hasAuth = true, form, hasStoreID = true, stream = false, SSID = '', _storeID = '') {
    let headers = form
        ? {
              'Content-Type': 'multipart/form-data',
          }
        : {
              'Content-Type': 'application/json',
          };

    if (stream) {
        headers = {
            'Content-Type': 'application/octet-stream',
        };
    }

    if (hasStoreID) {
        headers['store-id'] = _storeID;
    }

    if (!hasAuth) {
        return headers;
    }

    headers.authorization = SSID;

    return headers;
}

async function cacheData(action) {
    if (action.type == GET_USER_INFO && !action.no_store) {
        appLocalStorage.setItem(appConfig.LOCAL_FORAGE.USER_INFO, omit(action.payload, ['stores', 'scope']));
        const moment = baseHelper.getMoment();
        // moment.tz.setDefault(action.payload.timezone);
        moment.locale(action.payload.language);

        return;
    }

    if (action.type == LIST_COUNTRIES) {
        await appIndexedDB.setItem(appConfig.LOCAL_FORAGE.COUNTRY_KEY, action.payload.countries);
        return;
    }

    if (action.type == LIST_STATES) {
        const countryCode = action.meta.body.country_code;
        let states = (await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.STATE_KEY)) || {};
        states[countryCode] = action.payload.states;

        await appIndexedDB.setItem(appConfig.LOCAL_FORAGE.STATE_KEY, states);
        return;
    }

    if (action.type == LIST_DISTRICTS) {
        const countryCode = action.meta.body.country_code;
        const stateID = action.meta.body.state_id;

        let districts = (await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.DISTRICT_KEY)) || {};
        if (!districts[countryCode]) {
            districts[countryCode] = {};
        }
        districts[countryCode][stateID] = action.payload.districts;

        await appIndexedDB.setItem(appConfig.LOCAL_FORAGE.DISTRICT_KEY, districts);
        return;
    }

    if (action.type == LIST_WARDS) {
        const countryCode = action.meta.body.country_code;
        const stateID = action.meta.body.state_id;
        const districtID = action.meta.body.district_id;

        let wards = (await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.WARD_KEY)) || {};
        if (!wards[countryCode]) {
            wards[countryCode] = {};
        }

        if (!wards[countryCode][stateID]) {
            wards[countryCode][stateID] = {};
        }
        wards[countryCode][stateID][districtID] = action.payload.wards;

        await appIndexedDB.setItem(appConfig.LOCAL_FORAGE.WARD_KEY, wards);
        return;
    }
}

export default serviceMiddleware;
