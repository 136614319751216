import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import Input from '../../../components/Input';
import { Editor } from '@tinymce/tinymce-react';
import { map, compact, cloneDeep, find, pick, remove, includes, produce } from 'lodash';
import Dropdown from '../../../components/Dropdown';
import DatePicker from '../../../components/DatePicker/CustomDatePicker';
import timezones from '../../../config/timezone';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import actionsProductTag from '../../../redux/futures/product_tag/actions';

import actionsProduct from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import productImageActions from '../../../redux/futures/product_image/actions';
import * as productImageTypes from '../../../redux/futures/product_image/types';

import storePageActions from '../../../redux/futures/store_page/actions';
import * as storePageTypes from '../../../redux/futures/store_page/types';

import * as productCategoryTypes from '../../../redux/futures/product_category/types';
import productCategoryActions from '../../../redux/futures/product_category/actions';

import ListImageProduct from '../../../components/ListProductImageV2';
import ConfirmModal from '../../../components/ConfirmModal';
import SelectManyDropdown from '../../../components/SelectManyDropdown';
import SelectManyDropdownStore from '../../../components/SelectManyDropdownStore';
import SelectOption from '../../../components/SelectOption';

import { ProductTags } from '../../products/components/ProductTags';
import PanelCustomField from '../../../components/PanelCustomField';
import PanelTicketV4 from './PanelTicketV4';
import PanelTicketSeat from './PanelTicketSeat';
import PanelProductUpsellV4 from '../../../components/PanelProductUpsellV4';

import PanelProductSellSocial from '../../../components/PanelProductSeoSocial';

import LoadingScene from '../../../components/LoadingScene';
import config from '../../../config/config';
import PanelPageProduct from '../../../components/PanelPageProduct';

const EVENT_TYPE = [
    {
        CODE: 'Online',
        NAME: 'Online',
    },
    {
        CODE: 'Offline',
        NAME: 'Offline',
    },
];

function ModalEvent(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { t, i18n, onClick, info } = props;

    const inputsRef = new Set();
    const productTagRef = useRef();
    const panelCustomFieldRef = useRef();
    const panelCreateTicketRef = useRef();
    const panelSeoSocialRef = useRef();
    const highlightProductRef = useRef();

    const { productID } = useParams();

    const moment = baseHelper.getMoment();

    const productCategoryReducer = useSelector((state) => state.productCategory);
    const storePageReducer = useSelector((state) => state.storePage);
    const productImageReducer = useSelector((state) => state.productImage);
    const storeReducer = useSelector((state) => state.store);
    const fileReducer = useSelector((state) => state.file);
    const productReducer = useSelector((state) => state.product);

    const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);

    const [product, setProduct] = useState({
        product_id: '',
        product_variant_id: '',
        name: '',
        alias_name: '',
        domain: '',
        path: '',
        payment_redirect_url: '',
        payment_redirect_after: appConfig.DEFAULT_PAYMENT_REDIRECT_AFTER,
        url_published: '',
        description: '',
        quantity: 0,
        start_date: baseHelper.momentToDate(moment()),
        end_date: baseHelper.momentToEventDate(moment()),
        timezone: 'Asia/Bangkok',
        location: '',
        content: '',
        host_name: '',
        contact_phone: '',
        type: appConfig.PRODUCT_TYPE.EVENT.CODE,
        checkout_config_id: '',
        status: appConfig.PRODUCT_STATUS.ACTIVE.CODE,
        external_link: '',
        ticket_creation_form: appConfig.TICKET_CREATION_FORM.AUTO.CODE,
        interface_options: [],
        tags: [],
        select_many_service: true,
        cta: 0,
        selectedIconCTA: 'cta-add',
        seo: {},
        event_type: 'online',
        sku: '',
        is_one_time: false,
        highlight_display: {},
        quantity_sold: 0,
        is_free_ship: 1,
        is_show_coupon: 0,
        short_description: '',
        page_checkout: null,
    });

    const listProduct = [
        appConfig.PRODUCT_TYPE.PHYSICAL.CODE,
        appConfig.PRODUCT_TYPE.F_B.CODE,
        appConfig.PRODUCT_TYPE.COMBO.CODE,
        appConfig.PRODUCT_TYPE.EVENT.CODE,
        appConfig.PRODUCT_TYPE.SERVICE.CODE,
    ];

    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [isShowAllStore, setIsShowAllStore] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [isEditProduct, setIsEditProduct] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const [selectedTypeProduct, setSelectedTypeProduct] = useState('');

    const [variants, setVariants] = useState([]);
    const [options, setOptions] = useState([]);
    const [images, setImages] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [productTags, setProductTags] = useState([]);

    const [selectedImage, setSelectedImage] = useState('');
    const [isShowPopover, setIsShowPopover] = useState(true);

    const [isShowLimitQtyProduct, setIsShowLimitQtyProduct] = useState(false);
    const [isShowExternalLink, setIsShowExternalLink] = useState(false);

    const [selectedProductUpsells, setSelectedProductUpsells] = useState([]);

    const [isCopyEvent, setIsCopyEvent] = useState(false);

    useEffect(() => {
        let payload = {
            search: {
                type: 'Product',
            },
        };

        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        let type = queryURL.get('type');
        if (!type || !listProduct.includes(type)) {
            type = appConfig.PRODUCT_TYPE.PHYSICAL.CODE;
        }

        setSelectedTypeProduct(type);

        if (productID && !queryURL.get('is_copy')) {
            dispatch(actionsProduct.show(productID));
            setIsEditProduct(true);
            setMode(appConfig.FORM_MODE.EDIT);
            setIsCopyEvent(false);
        }

        if (productID && queryURL.get('is_copy')) {
            dispatch(actionsProduct.show(productID));
            setIsEditProduct(false);
            setMode(appConfig.FORM_MODE.CREATE);
            setIsCopyEvent(true);
        }

        dispatch(storePageActions.storePageList(dataListStore));
        dispatch(actionsProductTag.listAll());
        dispatch(productCategoryActions.listSelectCategory(payload));
    }, []);

    useEffect(() => {
        if (productImageReducer.action == productImageTypes.PRODUCT_IMAGE_CREATE) {
            if (productImageReducer.status) {
                let _images = [...images];
                _images.push(...productImageReducer.images);

                setImages(_images);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }
        if (productImageReducer.action == productImageTypes.PRODUCT_IMAGE_DELETE) {
            if (productImageReducer.status) {
                let _images = [...images];

                remove(_images, (image) => image.src_hash == selectedImage.src_hash);

                setImages(_images);
                setSelectedImage('');
                window.LadiUI.toastCustom('success', '', productImageReducer.message);
                window.LadiUI.closeModal('confirm-image-delete');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }
    }, [productImageReducer]);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_LIST) {
            if (storePageReducer.status) {
                if (storePageReducer.store_pages && storePageReducer.store_pages.length > 0) {
                    let _data = [];
                    let _stores = [];
                    storePageReducer.store_pages.map((item, index) => {
                        _data.push({
                            name: item.name,
                            value: item.page_checkout_id,
                        });

                        _stores.push(item.page_checkout_id);
                    });
                    if (!isEditProduct) setSelectedStores(_stores);
                    setStores(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    useEffect(() => {
        if (productReducer.action == productTypes.SHOW_PRODUCT) {
            if (productReducer.status) {
                let _dataProduct = { ...productReducer.product };
                // setProductTags(_dataProduct.tags || []);

                setSelectedTypeProduct(_dataProduct.type);
                // if (isCopyEvent) {
                //     initCreateEvent(_dataProduct);
                // } else {
                //     initUpdateEvent(_dataProduct);
                // }
                initDataEvent(_dataProduct);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.RELOAD_PRODUCT) {
            if (productReducer.status) {
                let _dataProduct = { ...productReducer.product };
                setProductTags(_dataProduct.tags || []);

                setSelectedTypeProduct(_dataProduct.type);
                initDataEvent(_dataProduct);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.CREATE_PRODUCT) {
            if (productReducer.status) {
                window.LadiUI.toastCustom('success', '', productReducer.message);
                history.push(`/events/update/${productReducer.product.product_id}`);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        // if (productReducer.action == productTypes.UPDATE_PRODUCT) {
        //     if (productReducer.status) {
        //         window.LadiUI.toastCustom('success', '', productReducer.message);
        //         dispatch(actionsProduct.reload(product.product_id))
        //     } else {
        //         window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
        //     }
        // }
    }, [productReducer]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    let _data = [...categories];
                    productCategoryReducer.listSelects.map((item, index) => {
                        const isExitCategory = _data.some((_item) => _item.value == item.product_category_id);
                        if (!isExitCategory) {
                            _data.push({
                                level: item.level,
                                name: item.name,
                                value: item.product_category_id,
                            });
                        }
                    });

                    setCategories(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_PRODUCT_IMAGES) {
            if (fileReducer.status) {
                const target = fileReducer.target;
                const newUploadedImage = map(fileReducer.product_images, (item, index) => {
                    return {
                        src: item,
                        position: images.length + index,
                    };
                });

                if (!isEditProduct) {
                    let listImage = [...images];
                    listImage.push(...newUploadedImage);
                    setImages(listImage);
                } else {
                    if (target !== false && target >= 0) {
                        if (newUploadedImage.length > 0) {
                            const img = newUploadedImage[0];
                            const updated = images[target];
                            updated.src = img.src;
                            updated.product_id = product.product_id;
                            dispatch(productImageActions.updateSrc(updated));
                        }
                    } else {
                        const productImages = {
                            product_id: product.product_id,
                            images: newUploadedImage,
                        };
                        dispatch(productImageActions.create(productImages));
                    }
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    const getIconCTA = (code) => {
        const matched = find(appConfig.LIST_CTA, (item) => item.CODE == code) || {};
        if (matched) {
            return matched.CLASS_ICON;
        }
        return 'cta-add';
    };

    const initDataEvent = async (dataProduct) => {
        let currentProductInfo = cloneDeep(dataProduct);

        if (isCopyEvent) {
            currentProductInfo = await baseHelper.normalizeCopyProduct({ ...currentProductInfo });
        }

        let _customFields = currentProductInfo.custom_fields || [];
        let _images = currentProductInfo.images || [];
        let _tags = currentProductInfo.tags || [];
        let _seo = currentProductInfo.seo || {};
        let _options = currentProductInfo.options || [];

        let _variants = currentProductInfo.variants || [];

        let _productUpSells = currentProductInfo.product_up_sells || [];

        delete currentProductInfo.tags;
        delete currentProductInfo.options;
        delete currentProductInfo.variants;
        delete currentProductInfo.images;
        delete currentProductInfo.relate_products;
        delete currentProductInfo.product_up_sells;

        let _product = { ...currentProductInfo };

        if (currentProductInfo.product_category_ids && currentProductInfo.product_category_ids.length > 0) {
            setSelectedCategories(currentProductInfo.product_category_ids);
        }

        if (currentProductInfo.page_checkout_ids) {
            setSelectedStores(currentProductInfo.page_checkout_ids);
        }

        if (currentProductInfo.tags && currentProductInfo.tags.length > 0) {
            setProductTags(currentProductInfo.tags);
        }

        if (currentProductInfo.is_show_all_store && currentProductInfo.is_show_all_store > 0) {
            setIsShowAllStore(true);
        } else {
            setIsShowAllStore(false);
        }

        let newProduct = {};
        map(product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(_product[key]) ? _product[key] : '';
            if (key == 'path' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = '' + _product.product_id;
            }
            if (key == 'cta' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = 0;
            }

            if (key == 'selectedIconCTA' && baseHelper.isEmpty(_product['cta'])) {
                newProduct[key] = 'cta-add';
            }

            if (key == 'selectedIconCTA' && !baseHelper.isEmpty(_product['cta'])) {
                newProduct['selectedIconCTA'] = getIconCTA(_product['cta']);
            }

            if (key == 'seo' && baseHelper.isEmpty(_product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        if (newProduct.start_date) {
            newProduct.start_date = baseHelper.momentToDate(moment(newProduct.start_date));
        }

        if (newProduct.end_date) {
            newProduct.end_date = baseHelper.momentToDate(moment(newProduct.end_date));
        }
        if (baseHelper.isEmpty(_options)) {
            newProduct.product_variant_id = _variants[0].product_variant_id;
        }

        if (variants) {
            newProduct.variants = _variants;
        }

        const newCustomFields = !baseHelper.isEmpty(_customFields) ? _customFields : [];

        if (newProduct.external_link) {
            setIsShowExternalLink(true);
        }

        const newVariants = _variants || [];
        const newImages = _images || [];
        const newOptions = _options || [];
        const newProductUpSells = _productUpSells || [];

        setProduct({ ...newProduct });
        setVariants(newVariants);
        setImages(newImages);
        setOptions(newOptions);
        setSelectedProductUpsells(newProductUpSells);
        setProductTags(_tags || []);
        setCustomFields(newCustomFields);
    };

    const getVariantReq = (variants) => {
        const {
            product_id,
            product_variant_id,
            name,
            description,
            quantity,
            start_date,
            end_date,
            timezone,
            location,
            content,
            host_name,
            contact_phone,
            type,
        } = product;
        if (variants.length <= 0) {
            const defaultVariant = {
                product_id,
                product_variant_id,
                name,
                description,
                quantity: 0,
                start_date,
                end_date,
                timezone,
                location,
                content,
                host_name,
                contact_phone,
                type,
                inventory_checked: appConfig.STATUS.ACTIVE,
            };

            variants.push(defaultVariant);
        }

        return variants;
    };

    const submit = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let { variants, options } = panelCreateTicketRef.current.getData();

        variants = getVariantReq(variants);

        const productSeoSocial = panelSeoSocialRef.current.getData();
        const productTagData = productTagRef.current.getData();
        const productCustomField = panelCustomFieldRef.current.getData();

        if (productSeoSocial) {
            if (productSeoSocial.title && productSeoSocial.title.length > 70) {
                window.LadiUI.toastCustom('warning', '', 'Tiêu đề vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.description && productSeoSocial.description.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Mô tả vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Từ khóa vượt quá số ký tự quy định!');
                return;
            }
        }

        let highlight_display = {};
        let quantity_sold = 0;
        if (highlightProductRef && highlightProductRef.current) {
            let highlights = highlightProductRef.current.getData();
            highlight_display = highlights.highlight_display;
            quantity_sold = Number(highlights.quantity_sold);
        }

        let productReq = {};
        if (mode == appConfig.FORM_MODE.CREATE) {
            productReq = {
                // ...pick(product, [
                //     'name',
                //     'alias_name',
                //     'description',
                //     'quantity',
                //     'start_date',
                //     'end_date',
                //     'timezone',
                //     'location',
                //     'content',
                //     'host_name',
                //     'contact_phone',
                //     'type',
                //     'checkout_config_id',
                //     'status',
                //     'external_link',
                //     'ticket_creation_form',
                //     'event_type',
                //     'cta',
                //     'is_one_time',
                // ]),
                ...product,
                alias_name: baseHelper.getAliasName(product.name),
                type: appConfig.PRODUCT_TYPE.EVENT.CODE,
                tags: productTagData,
                options,
                custom_fields: productCustomField,
                variants,
                images,
                // interface_options: JSON.stringify(product.interface_options),
                product_up_sells: selectedProductUpsells,
                seo: { ...productSeoSocial },
                is_show_all_store: isShowAllStore,
                page_checkout_ids: selectedStores || [],
                product_category_ids: selectedCategories || [],
                highlight_display,
                quantity_sold,
            };
        } else {
            // if (this.state.tabIndexActive == this.TAB_EVENTS.EVENT_CHECKOUT) {
            //     if (baseHelper.isEmpty(options)) {
            //         window.LadiUI.toastCustom('danger', '', this.props.t('PRODUCT_EVENTS.REQUIRED_AT_LEAST_TICKET'));
            //         return;
            //     }

            //     if (!this.panelProductCheckoutRef.current.validate()) {
            //         return;
            //     }
            // }

            productReq = {
                // ...pick(product, [
                //     'product_id',
                //     'name',
                //     'alias_name',
                //     'domain',
                //     'path',
                //     'description',
                //     'quantity',
                //     'start_date',
                //     'end_date',
                //     'timezone',
                //     'location',
                //     'content',
                //     'host_name',
                //     'contact_phone',
                //     'type',
                //     'checkout_config_id',
                //     'status',
                //     'payment_redirect_url',
                //     'payment_redirect_after',
                //     'external_link',
                //     'select_many_service',
                //     'event_type',
                //     'cta',
                //     'is_one_time',
                // ]),
                ...product,
                alias_name: baseHelper.getAliasName(product.name),
                type: appConfig.PRODUCT_TYPE.EVENT.CODE,
                variants,
                tags: productTagData,
                images,
                custom_fields: productCustomField,
                product_up_sells: selectedProductUpsells,
                // interface_options: JSON.stringify(product.interface_options),
                is_publish: true,
                seo: { ...productSeoSocial },
                is_show_all_store: isShowAllStore,
                page_checkout_ids: selectedStores || [],
                product_category_ids: selectedCategories || [],
                highlight_display,
                quantity_sold,
            };
        }

        if (!isEditProduct) {
            dispatch(actionsProduct.create(productReq));
        } else {
            dispatch(actionsProduct.update(productReq));
        }
    };

    const handleChangeInput = (event) => {
        let { name, value } = event.target;

        setProduct({
            ...product,
            [name]: value,
        });
    };

    const handleEditorChange = (value) => {
        setProduct({
            ...product,
            description: value,
        });
    };

    const selectEventType = (eventType) => {
        setProduct({
            ...product,
            event_type: eventType.CODE,
        });
    };

    const onChangeDateTime = (value, name) => {
        if (product.end_date) {
            if (name == 'start_date') {
                if (value.getTime() >= product.end_date.getTime()) {
                    window.LadiUI.toastCustom('danger', '', t('VALIDATION.END_DATE_AFTER_START_DATE'));
                    return;
                }
            } else {
                if (product.start_date.getTime() >= value.getTime()) {
                    window.LadiUI.toastCustom('danger', '', t('VALIDATION.END_DATE_AFTER_START_DATE'));
                    return;
                }
            }
        }
        setProduct((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const selectTimezone = (selectedItem) => {
        setProduct({
            ...product,
            timezone: selectedItem.CODE,
        });
    };

    const handleDeleteImage = (item) => {
        setSelectedImage(item);
        // openConfirmDeleteModal(item);
        window.LadiUI.showModal('confirm-image-delete');
    };

    const deleteImageProduct = () => {
        dispatch(productImageActions.delete(product.product_id, selectedImage.src_hash));
    };

    const handleSetImage = (_images) => {
        setImages(_images);
    };

    const handleSetListImageProducts = (urlImage) => {
        let _images = [...images];
        const imageItem = [
            {
                src: urlImage,
                position: images.length + 1,
            },
        ];

        if (!isEditProduct) {
            let listImage = [...images];
            listImage.push(...imageItem);
            setImages(listImage);
        } else {
            const productImages = {
                product_id: product.product_id,
                images: imageItem,
            };
            dispatch(productImageActions.create(productImages));
        }

        setIsShowPopover(false);
    };

    const handleRefreshDataStores = () => {
        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    };

    const handleSearchData = (value) => {
        const dataListStore = {
            keyword: value,
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    };

    const handleChangeCheckAllStore = (checked) => {
        if (!checked) {
            setSelectedStores([]);
        }
        setIsShowAllStore(checked);
    };

    const selectTicketCreationForm = (selectedItem) => {
        setProduct({
            ...product,
            ticket_creation_form: selectedItem.CODE,
        });
    };

    const handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: 'Product',
            },
        };
        dispatch(productCategoryActions.listSelectCategory(payload));
    };

    const onChangeCTA = (item) => {
        setProduct({
            ...product,
            cta: item.CODE,
            selectedIconCTA: item.CLASS_ICON,
        });
    };

    const handleSelectProductUpsell = (variant) => {
        let _selectedProducts = [...selectedProductUpsells];

        if (find(_selectedProducts, (item) => item.product_variant_id == variant.product_variant_id)) {
            return;
        }

        let productItem = {
            product_id: variant.product_id,
            name: variant.name,
            product_variant_id: variant.product_variant_id,
            position: _selectedProducts.length,
            src: variant.src,
            price: variant.price,
            quantity: 1,
        };

        _selectedProducts.push(productItem);

        setSelectedProductUpsells(_selectedProducts);
        // let variant = { ...dataVariant };
        // variant.quantity = 1;
        // variant.position = 0;

        // let _selectedProductUpsells = [...selectedProductUpsells];

        // if (find(_selectedProductUpsells, (item) => item.product_variant_id == variant.product_variant_id)) {
        //     return;
        // }

        // const newSelectedVariants = produce(_selectedProductUpsells, (draft) => {
        //     Object.values(draft).forEach((item, index) => {
        //         item.position = index + 1;
        //         if (selectedTypeProduct !== appConfig.PRODUCT_TYPE.COMBO.CODE) {
        //             item.quantity = 1;
        //         }
        //     });

        //     draft.splice(0, 0, variant);
        // });

        // setSelectedProductUpsells(newSelectedVariants)
    };

    const handleDeleteProductUpsell = (productVariantID) => {
        let _products = selectedProductUpsells.filter((item) => item.product_variant_id != productVariantID);

        setSelectedProductUpsells(_products);
    };

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);
        list = list.map((item, index) => {
            item.position = index;
            return item;
        });

        return list;
    };

    const onDragEndProductUpSell = (result) => {
        if (!result.destination) {
            return;
        }

        const _products = reorder(selectedProductUpsells, result.source.index, result.destination.index);
        setSelectedProductUpsells(_products);
    };

    const isLoadingProduct =
        productReducer.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], productReducer.waiting);

    const getUrlPageCheckout = (type, url, page_checkout_id) => {
        let is_iframe = true;

        if (!is_iframe) {
            return url;
        }
        switch (type) {
            case 'CREATE':
                return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_LIST}&query_ls=${JSON.stringify({
                    is_create: true,
                    type_create: appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT,
                    product_id: page_checkout_id,
                })}`;
            case 'UPDATE':
                return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(page_checkout_id)}`;
            default:
                break;
        }
    };

    return (
        <div className='page-product' id='page-product-new'>
            {isLoadingProduct && <LoadingScene blur={true} />}
            <div className='menu-header'>
                <div className='content'>
                    {/* <div className='top-menu-header'>
                        <ul>
                            <li className='cursor-pointer' onClick={() => history.push('/products')}>
                                <span>Danh sách sản phẩm</span>
                            </li>
                            <li>
                                {isEditProduct ? (
                                    <strong>{product.name ? product.name : 'Chỉnh sửa sự kiện'}</strong>
                                ) : (
                                    <strong>Tạo sự kiện</strong>
                                )}
                            </li>
                        </ul>
                    </div> */}

                    <div className='title-action'>
                        <h3>{isEditProduct ? 'Chỉnh sửa sự kiện' : 'Tạo sự kiện'}</h3>
                        <button onClick={submit} className='ladiui btn btn-primary'>
                            {isEditProduct ? 'Lưu sự kiện' : 'Tạo sự kiện'}
                        </button>
                    </div>

                    <div className='product-tabs-header'>
                        <ul className='ladiui nav nav-tabs'>
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(1)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 1 ? 'active' : ''}`}>Thông tin sự kiện</span>
                            </li>
                            {isEditProduct && (
                                <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(2)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 2 ? 'active' : ''}`}>Quản lý mã sự kiện</span>
                                </li>
                            )}
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(3)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 3 ? 'active' : ''}`}>Sản phẩm bán kèm</span>
                            </li>
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(4)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 4 ? 'active' : ''}`}>Thiết lập SEO & Social</span>
                            </li>
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(5)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 5 ? 'active' : ''}`}>Highlight</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='page-content-product-new'>
                <div className='ladiui page-content content-max-width'>
                    <div className='ladiui tab-content form-setting max-width-content' id='myTabContent'>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 1 ? 'show open' : ''}`} id='tab-product-info'>
                            <div className='page-product-info'>
                                <div className='content-left'>
                                    <div className='block-content-item'>
                                        <h3>Thông tin sự kiện</h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Tên sự kiện</label>
                                            <Input
                                                className='ladiui form-control'
                                                placeholder='Nhập tên sự kiện'
                                                name='name'
                                                value={product.name}
                                                onChange={(event) => handleChangeInput(event)}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validationName={t('tên sự kiện')}
                                                validations={{ isRequired: true }}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Mô tả ngắn (Hiển thị trong mô tả sự kiện)</label>
                                            <Input
                                                className='ladiui form-control'
                                                placeholder='Nhập mô tả ngắn sự kiện'
                                                name='short_description'
                                                value={product.short_description}
                                                onChange={(event) => handleChangeInput(event)}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validationName={t('mô tả ngắn sự kiện')}
                                                validations={{ isRequired: false }}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Mô tả sự kiện</label>
                                            <Editor
                                                tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                                licenseKey="gpl"
                                                value={product.description}
                                                init={{
                                                    selector: 'textarea',
                                                    min_height: 200,
                                                    max_height: 800,
                                                    autoresize_overflow_padding: 5,
                                                    autoresize_bottom_margin: 24,
                                                    plugins: 'preview powerpaste  fullscreen lists checklist autoresize',
                                                    toolbar: `bold italic underline | lineheight | alignleft aligncenter alignright alignjustify | 
                                                        outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen 
                                                        formatpainter removeformat`,
                                                    toolbar_drawer: 'sliding',
                                                    // language_url: '../../../../language/tinymce-vi',
                                                    content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                                    // valid_elements: '*[*]',
                                                }}
                                                onEditorChange={handleEditorChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>Hình thức và thời gian tổ chức sự kiện</h3>
                                        <div className='flex gap-16'>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label new'>Hình thức tổ chức</label>
                                                <Dropdown
                                                    data={EVENT_TYPE}
                                                    onSelectItem={selectEventType}
                                                    currentKey={product.event_type}
                                                    _key={'CODE'}
                                                    _value={'NAME'}
                                                />
                                            </div>
                                            {/* <div className='ladiui form-group'>
                                                <label className='ladiui-label new'>Đường dẫn tham gia sự kiện</label>
                                                <Input
                                                    ref={(ref) => inputsRef.add(ref)}
                                                    name='location'
                                                    validationName={t('PRODUCT_EVENTS.EVENT_LINK')}
                                                    placeholder={t('PRODUCT_EVENTS.PLACE_LINK')}
                                                    value={product.location}
                                                    onChange={handleChangeInput}
                                                />
                                            </div> */}
                                            {product.event_type == appConfig.EVENT_TYPE.OFFLINE ? (
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label new'>{t('PRODUCT_EVENTS.EVENT_LOCATION')}</label>
                                                    <Input
                                                        ref={(ref) => inputsRef.add(ref)}
                                                        name='location'
                                                        validationName={t('địa điểm tham gia sự kiện')}
                                                        placeholder={t('Nhập địa điểm tham gia sự kiện')}
                                                        value={product.location}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            ) : (
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label new'>Đường dẫn tham gia sự kiện</label>
                                                    <Input
                                                        ref={(ref) => inputsRef.add(ref)}
                                                        name='location'
                                                        validationName={t('PRODUCT_EVENTS.EVENT_LINK')}
                                                        placeholder={t('PRODUCT_EVENTS.PLACE_LINK')}
                                                        value={product.location}
                                                        onChange={handleChangeInput}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className='flex gap-16'>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label new'>{t('COMMON.START_DATE')}</label>
                                                <DatePicker
                                                    name='start_date'
                                                    minDate={baseHelper.momentToDate(moment())}
                                                    selected={product.start_date}
                                                    onChange={(value) => onChangeDateTime(value, 'start_date')}
                                                    showTimeSelect
                                                    timeFormat={appConfig.DEFAUT_TIME}
                                                    timeIntervals={15}
                                                    dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                    timeCaption='Time'
                                                />
                                            </div>
                                            <div className='ladiui form-group'>
                                                <label className='ladiui-label new'>{t('COMMON.END_DATE')}</label>
                                                <DatePicker
                                                    name='end_date'
                                                    minDate={product.start_date}
                                                    selected={product.end_date}
                                                    onChange={(value) => onChangeDateTime(value, 'end_date')}
                                                    showTimeSelect
                                                    timeFormat={appConfig.DEFAUT_TIME}
                                                    timeIntervals={15}
                                                    dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                    timeCaption='Time'
                                                />
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className='ladiui form-group' style={{ width: 'calc(50% - 8px)' }}>
                                                <label className='ladiui-label new'>{t('COMMON.TIMEZONE')}</label>
                                                <Dropdown
                                                    data={timezones}
                                                    onSelectItem={selectTimezone}
                                                    currentKey={product.timezone}
                                                    _key={'CODE'}
                                                    _value={'NAME'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>Loại vé sự kiện</h3>
                                        <PanelTicketV4
                                            ref={panelCreateTicketRef}
                                            inputsRef={inputsRef}
                                            currentProductInfo={product}
                                            productID={product.product_id}
                                            variants={variants || []}
                                            options={options || []}
                                            mode={mode}
                                            isCopyEvent={isCopyEvent}
                                        // openVariantID={this.props.openVariantID}
                                        // isShowModalTicket={this.state.isShowModalTicket}
                                        />
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>Hình ảnh sự kiện</h3>

                                        <div className='block-list-images mt-24'>
                                            <ListImageProduct
                                                listImage={images}
                                                handleDeleteImage={(item) => handleDeleteImage(item)}
                                                handleSetImage={(items) => handleSetImage(items)}
                                                isEditProduct={isEditProduct}
                                                productID={product.product_id}
                                                handleSetListImageProducts={handleSetListImageProducts}
                                                isShowPopover={isShowPopover}
                                                isCopy={isCopyEvent}
                                            // handleSetImageCopy={this.handleSetImageCopy}
                                            />
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>Thiết lập nâng cao</h3>
                                        <div className='advanced-settings'>
                                            <div className='item'>
                                                <div className='ladiui item-form switch mr-8'>
                                                    <label className='ladiui switch m-0'>
                                                        <input
                                                            type='checkbox'
                                                            checked={product.is_one_time}
                                                            onChange={() => {
                                                                setProduct({
                                                                    ...product,
                                                                    is_one_time: !product.is_one_time,
                                                                });
                                                            }}
                                                        />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div>
                                                <span>Cho phép mỗi khách hàng chỉ được mua sự kiện một lần</span>
                                            </div>
                                            <div className='item'>
                                                <div className='ladiui item-form switch mr-8'>
                                                    <label className='ladiui switch m-0'>
                                                        <input
                                                            type='checkbox'
                                                            checked={product.select_many_service}
                                                            onChange={() => {
                                                                setProduct({
                                                                    ...product,
                                                                    select_many_service: !product.select_many_service,
                                                                });
                                                            }}
                                                        />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div>
                                                <span>Cho phép khách hàng mua nhiều loại vé trong mỗi sự kiện</span>
                                            </div>
                                            <div className='item'>
                                                {/* <div className='ladiui item-form switch mr-8'>
                                                    <label className='ladiui switch m-0'>
                                                        <input
                                                            type='checkbox'
                                                            onChange={() => {
                                                                setIsShowExternalLink(!isShowExternalLink);
                                                            }}
                                                            checked={isShowExternalLink}
                                                        />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div> */}
                                                {/* <div
                                                    onClick={() => {
                                                        setIsShowExternalLink(!isShowExternalLink);
                                                    }}
                                                > */}
                                                <div
                                                    className='ladiui flex-center mr-4'
                                                    onClick={() => {
                                                        setIsShowExternalLink(!isShowExternalLink);
                                                    }}
                                                >
                                                    <i className={`icon-caret-down-fill ${!isShowExternalLink ? 'turn-right' : ''}`} />
                                                </div>
                                                {/* </div> */}
                                                <span>Thêm đường dẫn trang Landingpage giới thiệu sự kiện</span>
                                            </div>
                                            {isShowExternalLink && (
                                                <div className='ladiui form-group mt-0 pt-12 pb-12'>
                                                    <label className='ladiui-label new'>{t('Đường dẫn trang giới thiệu sự kiện')}</label>
                                                    <Input
                                                        ref={(ref) => inputsRef.add(ref)}
                                                        name='external_link'
                                                        validationName={t('PRODUCTS.LB_EXTERNAL_LINK')}
                                                        placeholder={t('PRODUCTS.LB_EXTERNAL_LINK_PLACEHOLDER')}
                                                        value={product.external_link}
                                                        onChange={(event) => handleChangeInput(event)}
                                                        validations={{ isURL: true }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='content-right'>
                                    <div className='block-content-item'>
                                        <div className='flex items-center space-between'>
                                            <h3>Hiển thị sự kiện</h3>
                                            <div className='ladiui item-form switch'>
                                                <label className='ladiui switch m-0'>
                                                    <input
                                                        type='checkbox'
                                                        onChange={(event) => {
                                                            setProduct({
                                                                ...product,
                                                                status:
                                                                    product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE
                                                                        ? appConfig.PRODUCT_STATUS.INACTIVE.CODE
                                                                        : appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                                                            });
                                                        }}
                                                        checked={product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE}
                                                    />
                                                    <span className='ladiui slider round'></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Cửa hàng</label>
                                            <SelectManyDropdownStore
                                                data={stores || []}
                                                _key='name'
                                                _value='value'
                                                pickTitle={'Tiêu đề'}
                                                placeHolder={'Chọn cửa hàng'}
                                                onSubmit={(items) => {
                                                    setSelectedStores(items);
                                                }}
                                                isShowAllStore={isShowAllStore}
                                                innerClassName='w100'
                                                styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                                buttonStyle={{
                                                    width: '100%',
                                                    paddingRight: '30px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: ' -webkit-box',
                                                    WebkitLineClamp: 1,
                                                    WebkitBoxOrient: 'vertical',
                                                    textAlign: 'left',
                                                }}
                                                selectedItems={selectedStores || []}
                                                isShowBlockInputSearch={false}
                                                isCreateCategory={true}
                                                isCourse={true}
                                                handleRefreshData={handleRefreshDataStores}
                                                handleSearchData={handleSearchData}
                                                handleChangeCheckAllStore={handleChangeCheckAllStore}
                                            />
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-24'>
                                        <h3>Hình thức tạo vé sự kiện</h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>{t('PRODUCT_EVENTS.TICKET_CREATION_FORM')}</label>
                                            <Dropdown
                                                data={appConfig.TICKET_CREATION_FORM}
                                                onSelectItem={selectTicketCreationForm}
                                                currentKey={product.ticket_creation_form}
                                                _key={'CODE'}
                                                _value={'NAME'}
                                                disabled={isEditProduct}
                                            />
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>Phân loại sự kiện</h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Danh mục sản phảm</label>
                                            <SelectManyDropdown
                                                data={categories || []}
                                                _key='name'
                                                _value='value'
                                                pickTitle={'Tiêu đề'}
                                                placeHolder={'Chọn danh mục'}
                                                onSubmit={(items) => {
                                                    setSelectedCategories(items);
                                                }}
                                                innerClassName='w100'
                                                styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                                buttonStyle={{
                                                    width: '100%',
                                                    paddingRight: '30px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: ' -webkit-box',
                                                    WebkitLineClamp: 1,
                                                    WebkitBoxOrient: 'vertical',
                                                    textAlign: 'left',
                                                }}
                                                selectedItems={selectedCategories || []}
                                                isShowBlockInputSearch={true}
                                                isCreateCategory={true}
                                                handleRefreshData={handleRefreshDataCategories}
                                            // handleSearchData={handleSearchData}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Tag sự kiện</label>
                                            <ProductTags ref={productTagRef} productTag={productTags} />
                                        </div>
                                    </div>
                                    {isEditProduct && (
                                        <div className='block-content-item mt-20'>
                                            <h3>Trang thanh toán</h3>
                                            <div className='ladiui form-group'>
                                                {product.page_checkout ? (
                                                    <div className='ladiui flex gap-12'>
                                                        <a
                                                            className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                                            // href={`/page-checkout/update/${product.page_checkout.page_checkout_id}`}
                                                            href={getUrlPageCheckout(
                                                                'UPDATE',
                                                                `/page-checkout/update/${product.page_checkout.page_checkout_id}`,
                                                                product.page_checkout.page_checkout_id
                                                            )}
                                                            target='_blank'
                                                        >
                                                            Chỉnh Sửa
                                                        </a>
                                                        <a
                                                            className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                                            href={product.url_published}
                                                            target='_blank'
                                                        >
                                                            Truy cập
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={getUrlPageCheckout(
                                                            'CREATE',
                                                            `/page-checkout?is_create=true&product_id=${product.product_id}&type_create=${appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT}`,
                                                            product.product_id
                                                        )}
                                                        target='_parent'
                                                    >
                                                        {t('PAGE_CHECKOUT.ADD')}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className='block-content-item mt-20'>
                                        <h3>Nút CTA</h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Chọn nút</label>
                                            <SelectOption
                                                wrapperClassName='select-assignee w100'
                                                innerClassName='ellipsis w100 flex'
                                                currentKey={product.cta}
                                                classIconCTA={product.selectedIconCTA}
                                                customClassName='w100'
                                                _key='CODE'
                                                _value='NAME'
                                                items={appConfig.LIST_CTA || []}
                                                onSelectItem={(item) => {
                                                    onChangeCTA(item);
                                                }}
                                            />
                                        </div>
                                        <div className='block-info-help flex items-center'>
                                            <i className='ladi-icon icon-c-question mr-8'></i>
                                            <span>Nội dung nút mua hàng ở trang bán sự kiện.</span>
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3 className='mb-24'>Trường tùy chỉnh</h3>
                                        <PanelCustomField
                                            ref={panelCustomFieldRef}
                                            customFields={customFields}
                                            groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE}
                                        />
                                        <div className='block-info-help flex items-center'>
                                            <i className='ladi-icon icon-c-question mr-8'></i>
                                            <span>Lưu trữ thông tin sự kiện theo nhu cầu của bạn.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 2 ? 'show open' : ''}`} id='tab-event-code-management'>
                            {/* <div className='block-content-item'>
                                <div className='header-top flex items-center space-between mb-20'>
                                    <h3>Quản lý mã sự kiện</h3>
                                    <div className='info-help flex items-center'>
                                        <i className='ladi-icon icon-c-question mr-8'></i>
                                        <span>Tìm hiểu thêm</span>
                                    </div>
                                </div> */}
                            <PanelTicketSeat
                                eventID={product.product_id}
                                ticketCreationForm={product.ticket_creation_form}
                                currentProductInfo={product}
                                options={options || []}
                            />
                            {/* </div> */}
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 3 ? 'show open' : ''}`} id='tab-product-upsell'>
                            <PanelProductUpsellV4
                                selectedProductUpsells={selectedProductUpsells}
                                handleSelectProductUpsell={handleSelectProductUpsell}
                                handleDeleteProductUpsell={handleDeleteProductUpsell}
                                onDragEndProductUpSell={onDragEndProductUpSell}
                                productInfo={product}
                                selectedTypeProduct={selectedTypeProduct}
                            />
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 4 ? 'show open' : ''}`} id='tab-product-seo-social'>
                            <div className='page-content-product-seo-social'>
                                <div className='block-content-item'>
                                    <h3>Thiết lập SEO & Social</h3>
                                    <PanelProductSellSocial ref={panelSeoSocialRef} productInfoSeo={product.seo || {}} />
                                    <div className='block-info-help flex items-center'>
                                        <i className='ladi-icon icon-c-question mr-8'></i>
                                        <span>
                                            Thiết lập SEO & Social giúp khách hàng dễ dàng tìm kiếm sản phẩm trên các công cụ tìm kiếm như
                                            Google.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 5 ? 'show open' : ''}`} id='tab-product-highlight'>
                            <div className='page-content-product-seo-social'>
                                <div className='block-content-item'>
                                    <PanelPageProduct
                                        ref={highlightProductRef}
                                        quantitySold={product.quantity_sold || ''}
                                        highlightDisplay={product.highlight_display || {}}
                                        productType={product.type}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmModal
                style={{ zIndex: 1050 }}
                id='confirm-image-delete'
                title={t('PRODUCTS.MSG_DELETE_IMAGE_TITLE')}
                content={t('PRODUCTS.MSG_DELETE_IMAGE_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={deleteImageProduct}
            // isLoading={isLoadingDelete}
            />
        </div>
    );
}

export default withTranslation()(ModalEvent);
