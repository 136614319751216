import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import baseHelper from "../../../helpers/BaseHelper";
import Image from "../../../components/Image";
import { isEmpty, map } from "lodash";

import discountActions from "../../../redux/futures/discount/actions";
import * as discountTypes from "../../../redux/futures/discount/types";
import { color } from "highcharts";
import appConfig from "../../../config/app";
// eslint-disable-next-line max-lines-per-function
function ModalChooseIcon(props) {
  const { t, onClose, isShow, selectedDiscounts = [], onSelectIcon } = props;
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const [valueSearch, setValueSearch] = useState("");
  const [iconList, setIconList] = useState(appConfig.MATERIAL_ICONS);

  const handleSearch = (e) => {
    e.preventDefault();
    let payload = {
      search: {
        name: valueSearch,
        rule_type: 1,
        is_show_web: true
      },
    };
    dispatch(discountActions.list(payload));
  };

  const eventCancel = () => {
    onClose(false);
  };

  const handleChooseIcon = (value) => {
    onSelectIcon(value)
    onClose(false);
  };

  const getValueByType = (item) => {
    let symbol = " đ";
    let amount = 0;
    if (item.type == 1) {
      amount = item.value;
      symbol = " %";
    } else {
      amount = item.value;
      symbol = " đ";
    }

    return amount + symbol;
  };

  const onChangeSearchInput = (e) => {
    setValueSearch(e.target.value);

    if (isEmpty(e.target.value)) {
      setIconList(appConfig.MATERIAL_ICONS);
    } else {
        const searchingIconList = appConfig.MATERIAL_ICONS.filter((item) => item.includes(String(e.target.value).toLowerCase()))
        setIconList(searchingIconList);
    }
  };

  const handleEnterSearch = (event) => {
    if (event.key === "Enter") {
      let payload = {
        search: {
          name: valueSearch,
          rule_type: 1,
          is_show_web: true
        },
      };
      dispatch(discountActions.list(payload));
    }
  };

  return (
    <div className={`ladiui modal ${isShow ? "show" : ""}`} id="modal-choose-icon">
      <div className="ladiui modal-dialog modal-dialog-centered w-600" ref={wrapperRef}>
        <div className="ladiui modal-content ">
          <div className="ladiui modal-header ">
            <h2 className="ladiui modal-title ">Chọn biểu tượng</h2>
            <button data-dismiss="modal" data-target="add-product" onClick={eventCancel} className="ladiui modal-close">
              <i className="ladiui icon icon-ldp-close"></i>
            </button>
          </div>
          <div className="ladiui modal-body">
            <div className="ladiui search-group search-icon">
              <div className="ladiui search-group">
                <input
                  className="ladiui search-field dropdown-toggle form-control"
                  value={valueSearch}
                  placeholder={"Tìm kiếm icon"}
                  data-toggle="dropdown"
                  onKeyPress={(e) => handleEnterSearch(e)}
                  aria-expanded="false"
                  onChange={(e) => onChangeSearchInput(e)}
                />
                <i className="ladiui icon icon-search" onClick={(e) => handleSearch(e)}></i>
              </div>
            </div>

            {iconList && iconList.length > 0 && (
              <div className={`list-icon`}>
                {map(iconList, (icon, index) => {
                      return (
                        <div key={index} class="icon-item" onClick={() => handleChooseIcon(icon)}>
                          <i class="material-symbols-outlined">{icon}</i>
                        </div>
                      );
                    })}
              </div>
            )}
          </div>

          <div className="ladiui modal-footer ">
            <div className="ladiui footer-modal-btn">
              <button
                type="button"
                data-dismiss="modal"
                data-target="add-product"
                className="ladiui btn btn-secondary bold"
                onClick={eventCancel}
              >
                Đóng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default withTranslation()(ModalChooseIcon);
