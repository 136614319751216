import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { withTranslation } from 'react-i18next';
import appConfig from '../config/app';
import { t } from 'i18next';

function ModalExportProduct(props) {
    const { isLoadingExportData, onCancel, visible } = props;

    const [productType, setProductType] = useState('');
    const [condition, setCondition] = useState('');

    const handleExportData = () => {
        if (productType && condition) {
            props.onExportData(productType, condition);
        } else {
            window.LadiUI.toastCustom('danger', '', 'Vui lòng chọn loại dữ liệu và điều kiện xuất để thực hiện hành động !');
        }
    };

    const onChangeProductType = (e) => {
        setProductType(e.target.value);
    };

    const handleChangeCondition = (e) => {
        setCondition(e.target.value);
    };
    return (
        <Modal
            id='modal-export-data-by-type'
            title={t('Xuất danh sách sản phẩm')}
            visible={visible}
            onCancel={onCancel}
            width={600}
            footer={
                <div className='ladiui-modal-footer'>
                    <button onClick={onCancel} className='ladiui btn btn-secondary'>
                        {t('ACTIONS.CANCEL')}
                    </button>
                    <button
                        onClick={handleExportData}
                        className={`ladiui btn btn-primary ${isLoadingExportData ? 'loader' : ''}`}
                        disabled={isLoadingExportData}
                    >
                        Xuất danh sách
                    </button>
                </div>
            }
        >
            <div className='block-content'>
                <div className='content-item content-left'>
                    <h3>Loại dữ liệu</h3>
                    <div className='block-list'>
                        <div className='item'>
                            <input
                                id='product'
                                type='radio'
                                name='radio-group'
                                className='ladiui form-check-input'
                                value={appConfig.PRODUCT_TYPE.PHYSICAL.CODE}
                                onChange={(e) => onChangeProductType(e)}
                                checked={productType == appConfig.PRODUCT_TYPE.PHYSICAL.CODE}
                            />
                            <label htmlFor='product'>{'Danh sách sản phẩm'}</label>
                        </div>
                        <div className='item'>
                            <input
                                id='event'
                                type='radio'
                                name='radio-group'
                                className='ladiui form-check-input'
                                value={appConfig.PRODUCT_TYPE.EVENT.CODE}
                                onChange={onChangeProductType}
                                checked={productType == appConfig.PRODUCT_TYPE.EVENT.CODE}
                            />
                            <label htmlFor='event'>{'Danh sách sự kiện'}</label>
                        </div>
                        <div className='item'>
                            <input
                                id='service'
                                type='radio'
                                name='radio-group'
                                className='ladiui form-check-input'
                                value={appConfig.PRODUCT_TYPE.SERVICE.CODE}
                                onChange={onChangeProductType}
                                checked={productType == appConfig.PRODUCT_TYPE.SERVICE.CODE}
                            />
                            <label htmlFor='service'>{'Danh sách dịch vụ'}</label>
                        </div>
                    </div>
                </div>
                <div className='content-item content-right'>
                    <h3>Điều kiện xuất</h3>
                    <div className='block-list'>
                        <div className='item'>
                            <input
                                id='onPage'
                                type='radio'
                                name='condition-group'
                                className='ladiui form-check-input'
                                value={appConfig.EXPORT_PRODUCT.ON_PAGE}
                                onChange={(e) => handleChangeCondition(e)}
                                checked={condition == appConfig.EXPORT_PRODUCT.ON_PAGE}
                            />
                            <label htmlFor='onPage'>Trang hiện tại</label>
                        </div>

                        <div className='item'>
                            <input
                                id='allEvent'
                                type='radio'
                                name='condition-group'
                                className='ladiui form-check-input'
                                value={appConfig.EXPORT_PRODUCT.ALL_PRODUCT}
                                onChange={(e) => handleChangeCondition(e)}
                                checked={condition == appConfig.EXPORT_PRODUCT.ALL_PRODUCT}
                            />
                            <label htmlFor='allEvent'>Tất cả</label>
                        </div>

                        <div className='item'>
                            <input
                                id='selectedEvent'
                                type='radio'
                                name='condition-group'
                                className='ladiui form-check-input'
                                value={appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT}
                                onChange={(e) => handleChangeCondition(e)}
                                checked={condition == appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT}
                            />
                            <label htmlFor='selectedEvent'>Đã được chọn</label>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="item">
          <input
            id="onPage"
            type="radio"
            name="radio-group"
            className="ladiui form-check-input"
            value={appConfig.EXPORT_EVENT.ON_PAGE}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_EVENT.ON_PAGE}
          />
          <label htmlFor="onPage">{t("PRODUCT_EVENTS.EXPORT_ON_PAGE")}</label>
        </div> */}
        </Modal>
    );
}
export default withTranslation()(ModalExportProduct);
