import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { appLocalStorage } from '../../../localforage';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import pageCheckoutDefined from './components/pageCheckoutDefined';

import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';
import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';

import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';

import LoadingScene from '../../../components/LoadingScene';
import Header from './Header';
import ProductList from './productList/ProductList';
import WidgetConfig from './widgetConfig/WidgetConfig';
import SetupAfterPayment from './setupAfterPayment/SetupAfterPayment';
import SocialConfig from './socialConfig/SocialConfig';
import CheckoutConfig from './checkoutConfig/CheckoutConfig';
import { unstable_batchedUpdates } from 'react-dom/cjs/react-dom.development';

// eslint-disable-next-line max-lines-per-function
function PageCheckoutCreateV2(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { pageCheckoutID } = useParams();

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const headRef = useRef();
    const productListRef = useRef();
    const checkoutConfigRef = useRef();
    const widgetConfigRef = useRef();
    const setupAfterPaymentRef = useRef();
    const setupSeoSocialRef = useRef();
    const leftContentRef = useRef();

    const [tabSelected, setTabSelected] = useState('');
    const [pageCheckout, setPageCheckout] = useState({});
    // const [checkoutConfig, setCheckoutConfig] = useState({});
    const [dnsUrl, setDnsUrl] = useState(``);
    const [pageDns, setPageDns] = useState(pageCheckoutDefined.DNS_PAGE[0]);
    const [isShowDesktop, setShowDesktop] = useState(true);
    const [iframeReset, setIframeReset] = useState(1);
    const [heightPage, setHeightPage] = useState('100%');
    const [isShowMini, setShowMini] = useState(false);
    const [timeNow, setTimeNow] = useState(Date.now());

    const pageCheckoutLoading = useMemo(() => {
        return pageCheckoutReducer.loading || false;
    }, [pageCheckoutReducer.loading]);

    useEffect(() => {
        dispatch(checkoutConfigActions.list());
    }, []);

    useEffect(() => {
        if (!leftContentRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            // Do what you want to do when the size of the element changes
            let lefHeight = leftContentRef.current.clientHeight;
            let winHeight = window.innerHeight;

            if (lefHeight > winHeight) setHeightPage(`${lefHeight + 20}px`);
            else setHeightPage('100%');
        });
        resizeObserver.observe(leftContentRef.current);
        return () => resizeObserver.disconnect(); // clean up
    }, []);

    useEffect(() => {
        if (queryStrings) {
            let tab = queryStrings.get('tab');
            if (!tab) {
                setTabSelected('');
            } else {
                setTabSelected(tab);
            }
        }
    }, [queryStrings]);

    useEffect(() => {
        if (pageCheckoutID) {
            dispatch(pageCheckoutActions.show(pageCheckoutID));
            // setIsEditProduct(true);
            // setMode(appConfig.FORM_MODE.EDIT);
        }
    }, [pageCheckoutID]);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                let page_checkout = pageCheckoutReducer.page_checkout || {};
                // setPageCheckout(page_checkout);
                history.push(`/page-checkout/update/${page_checkout.page_checkout_id}`);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }

        if (pageCheckoutReducer.action == pageCheckoutTypes.UPDATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);
                dispatch(pageCheckoutActions.show(pageCheckoutID));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }

        if (pageCheckoutReducer.action == pageCheckoutTypes.SHOW_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                let page_checkout = pageCheckoutReducer.page_checkout || {};
                let checkout_config = page_checkout.checkout_config || {};
                setPageCheckout(page_checkout);

                // setDnsUrl(`${baseHelper.getDomainPageCheckout(page_checkout.url_published)}/?preview=true`);
                let url = `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/page/${page_checkout.path}${pageDns.endPoint
                    }`;
                if (page_checkout.types != appConfig.PAGE_CHECKOUT_TYPE.STORE)
                    url = `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/${page_checkout.path}${pageDns.endPoint}`;
                if (pageDns.type == 'PAGE_CHECKOUT') {
                    url += `&checkout_config_id=${page_checkout.checkout_config_id}`;
                }
                if (page_checkout.url_published && page_checkout.url_published != '') {
                    url = page_checkout.url_published + '?';
                }
                setDnsUrl(url);
                setIframeReset(iframeReset + 1);
                // setCheckoutConfig(checkout_config);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer.action]);

    const selectTab = (index) => {
        let _queryStrings = queryStrings;
        _queryStrings.set('tab', index);

        history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const handleCreatePageCheckout = async () => {
        let products = pageCheckout.products || [];

        if (productListRef && productListRef.current) {
            products = productListRef.current.getData();

            // if (!products || products.length == 0) {
            //     window.LadiUI.toastCustom('danger', '', 'Bạn chưa chọn sản phấm nào');
            //     return;
            // }
        }

        const checkoutConfig = checkoutConfigRef.current.getData();
        if (!checkoutConfig || checkoutConfig.error) {
            window.LadiUI.toastCustom('danger', '', checkoutConfig.error);
            return;
        }
        const widgetConfig = widgetConfigRef.current.getData();
        const seoConfig = setupSeoSocialRef.current.getData();
        // const setupAfterPayment = setupAfterPaymentRef.current.getData();
        const headData = headRef.current.getData();

        let dataSend = {
            page_checkout: {
                name: headData.name,
                page_checkout_id: pageCheckoutID,
                page_config: {
                    widget_config: widgetConfig,
                    setup_after_payment: widgetConfig.setupAfterPayment,
                },
                product_ids: products.map((item) => item.product_id),
                checkout_config: checkoutConfig,
                domain:
                    pageCheckout.types == appConfig.PAGE_CHECKOUT_TYPE.STORE ? appConfig.DOMAIN_CHECKOUT_DEFAULT : headData.domainSelect,
                path: headData.path,
                domain_store: headData.domainStoreSelect,
                seo: seoConfig
            },
        };

        if (!pageCheckoutID) {
            dispatch(pageCheckoutActions.create(dataSend));
        } else dispatch(pageCheckoutActions.update(dataSend));
    };
    return (
        <div className='page-checkout-v2'>
            {pageCheckoutLoading && <LoadingScene blur={true} />}
            <Header
                ref={headRef}
                handleCreatePageCheckout={handleCreatePageCheckout}
                pageCheckout={pageCheckout}
                isShowDesktop={isShowDesktop}
                setShowDesktop={setShowDesktop}
                setDnsUrl={setDnsUrl}
                pageDns={pageDns}
                setPageDns={setPageDns}
            />

            <div className='page-checkout-body'>
                <div className='page-checkout-content' style={{ height: heightPage }}>
                    <div className='content-popup' ref={leftContentRef}>
                        {isShowMini ? (
                            <div className='content-header-mini' onClick={() => setShowMini(false)}>
                                <div className='ladiui flex gap-8'>
                                    <i className='ladiui icon-new new-ldicon-setting' />
                                    <span className='mini-title'>
                                        {pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE ? 'Thiết lập trang' : 'Thiết lập'}
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className='content-header'>
                                    <div className='ladiui flex gap-8'>
                                        <i className='ladiui icon-new new-ldicon-setting' />
                                        <span className='title'>
                                            {pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE ? 'Thiết lập trang' : 'Thiết lập'}
                                        </span>
                                    </div>
                                    <i className='ladiui icon-new new-ldicon-minus2' onClick={() => setShowMini(true)} />
                                </div>
                                <div className='content-left'>
                                    {!tabSelected && (
                                        <div className='menu-list'>
                                            {pageCheckoutDefined.PAGE_CHECKOUT_MENU.map((item, index) => {
                                                if (
                                                    pageCheckout &&
                                                    pageCheckout.types != appConfig.PAGE_CHECKOUT.STORE.CODE &&
                                                    item.action == 'PRODUCT_LIST'
                                                )
                                                    return null;
                                                return (
                                                    <div
                                                        key={index}
                                                        className='menu-item ladiui flex-center-between'
                                                        onClick={() => selectTab(item.action)}
                                                    >
                                                        <div>
                                                            <div className='display-flex-center gap-8'>
                                                                <i className={`ladiui icon-new ${item.iconClass}`} />
                                                                <span className='ladiui menu-title'>{item.title}</span>
                                                            </div>{' '}
                                                            {item.sub_title && <div className='mt-8 text-sub-title'>{item.sub_title}</div>}
                                                        </div>
                                                        <i className='ladiui icon-new ldicon-chevron-right' />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {pageCheckout && pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE && (
                                        <ProductList
                                            ref={productListRef}
                                            isShow={tabSelected == 'PRODUCT_LIST'}
                                            pageCheckout={pageCheckout}
                                        />
                                    )}
                                    {/* {tabSelected == 'CHECKOUT_CONFIG' && ( */}
                                    <CheckoutConfig
                                        ref={checkoutConfigRef}
                                        isShow={tabSelected == 'CHECKOUT_CONFIG'}
                                        pageCheckout={pageCheckout}
                                    />
                                    {/* )} */}
                                    {/* {tabSelected == 'WIDGET_CONFIG' && ( */}
                                    <WidgetConfig
                                        ref={widgetConfigRef}
                                        isShow={tabSelected == 'WIDGET_CONFIG'}
                                        pageCheckout={pageCheckout}
                                        setDnsUrl={setDnsUrl}
                                        pageDns={pageDns}
                                        setPageDns={setPageDns}
                                    />
                                    {/* )} */}
                                    {/* {tabSelected == 'SETUP_AFTER_PAYMENT' && ( */}
                                    <SetupAfterPayment
                                        ref={setupAfterPaymentRef}
                                        isShow={tabSelected == 'SETUP_AFTER_PAYMENT'}
                                        pageCheckout={pageCheckout}
                                    />
                                    {/* )} */}
                                    <SocialConfig
                                        ref={setupSeoSocialRef}
                                        isShow={tabSelected == 'SOCIAL_CONFIG'}
                                        pageCheckout={pageCheckout}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className='content-right'>
                        <div className='content-right-body'>
                            <iframe
                                id='iframe-page-checkout-dns'
                                key={iframeReset}
                                // src={`${dnsUrl}&preview_type=${isShowDesktop ? 'desktop' : 'mobile'}`}
                                src={`${dnsUrl}&preview_type=desktop&v=${timeNow}`}
                                width={isShowDesktop ? 1300 : 375}
                                className='page-checkout-dns'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageCheckoutCreateV2;
