import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';

import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';

const Welcome = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const fileReducer = useSelector((state) => state.file);

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const [tabSelected, setTabSelected] = useState('DOMAIN_SETTING');

    const inputsRef = new Set();

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {};
        },
    }));

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.CREATE_PAGE_CHECKOUT) {
            if (pageCheckoutReducer.status) {
                window.LadiUI.toastCustom('success', '', pageCheckoutReducer.message);

                history.push('/page-course');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer]);

    const handleActiveNow = () => {
        let dataDefault = {
            page_checkout: {
                checkout_config_id: null,
                domain: null,
                types: 'Course',
                name: 'Trang khóa học',
                featured_product_ids: [],
                config_category: [],
                seo: {},
                config_course: {
                    menu_banner: {
                        background: '#002333',
                        text_color: '#FFFFFF',
                        menu_category_ids: [],
                        logo: '',
                        banners: '',
                        suggested_category_ids: [],
                    },
                    footer: {
                        background: '#002333',
                        text_color: '#FFFFFF',
                        content: [],
                    },
                },
            },
        };

        dispatch(pageCheckoutActions.create(dataDefault));
    };

    return (
        <div className='page-course-welcome'>
            <div className='content'>
                <div className='left-content'>
                    <h3 className='title'>Bắt đầu tạo trang khóa học của bạn</h3>
                    <div className='block-list-functions'>
                        <li>
                            <i className='ldicon-circle-check mr-8'></i>
                            <span>Quản lý toàn bộ khóa học trên một nền tảng duy nhất.</span>
                        </li>
                        <li>
                            <i className='ldicon-circle-check mr-8'></i>
                            <span>Tiếp thị và thu hút người dùng tham gia khóa học.</span>
                        </li>
                        <li>
                            <i className='ldicon-circle-check mr-8'></i>
                            <span>Tối ưu hóa quá trình mua bán khóa học</span>
                        </li>
                    </div>
                    <button type='button' className='ladiui btn btn-primary' onClick={() => handleActiveNow()}>
                        Kích hoạt ngay
                    </button>
                </div>
                <div className='right-content'>
                    <div className='block-image'>
                        <img src='https://w.ladicdn.com/ladiui/icons/ldicon-image-khoahoc.svg' />
                    </div>
                </div>
            </div>
        </div>
    );
});
Welcome.propTypes = {};

export default Welcome;
