import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import validatorHelper from '../helpers/ValidatorHelper';
import i18n from '../i18n';

class Dropdown extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        onSelectItem: PropTypes.func,
        currentKey: PropTypes.any,
        currentValue: PropTypes.any,
        _key: PropTypes.string,
        _value: PropTypes.string,
        validations: PropTypes.shape({
            isRequired: PropTypes.bool,
        }),
        placeHolder: PropTypes.string,
        renderItem: PropTypes.func,
        wrapperStyle: PropTypes.object,
        wrapperClassName: PropTypes.string,
        renderHeaders: PropTypes.func,
    };

    static defaultProps = {
        _key: 'code',
        _value: 'name',
        validations: {
            isRequired: false,
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            searchInput: '',
            // visibleData: props.data,
        };

        this.ref = React.createRef();
        this.inputRef = React.createRef();

        this.reset = this.reset.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }
    }

    getValueByKey = (_key) => {
        let matchedItem = {};
        map(this.props.data, (item) => {
            if (item[this.props._key] == _key) {
                matchedItem = item;
            }
        });
        return matchedItem[this.props._value];
    };

    onChangeSearchInput = (event) => {
        const { value } = event.target;
        // let visibleData = map(this.props.data, item => {
        //     if (baseHelper.getAliasName(item[_value]).indexOf(baseHelper.getAliasName(value)) > -1) {
        //         return item;
        //     }
        // });

        // visibleData = compact(visibleData);

        this.setState({
            searchInput: value,
        });
    };

    onSelectItem = (selected) => {
        if (selected[this.props._key] == this.props.currentKey) {
            return;
        }
        this.props.onSelectItem(selected);

        this.reset();
    };

    validate = () => {
        const errors = [];
        if (!this.ref.current) {
            return errors;
        }

        const { classList } = this.inputRef.current;
        classList.remove('error');

        let validateStatus = true;

        let { currentKey, validationName } = this.props;

        const { isRequired } = this.props.validations;
        if (isRequired) {
            if (validatorHelper.isEmpty(currentKey)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.ENTER', validationName));
            }
        }

        if (!validateStatus) {
            classList.add('error');
        }

        return errors;
    };

    renderItems = () => {
        const { data, _key, _value } = this.props;

        const items = map(data, (item, index) => {
            if (this.props.renderItem) {
                const element = this.props.renderItem(item, index);

                return React.cloneElement(element, {
                    onClick: () => this.onSelectItem(item),
                });
            }

            return (
                <li key={index}>
                    <a className='ladiui dropdown-item' onClick={() => this.onSelectItem(item)} data-value={item[_key]}>
                        {item[_value]}
                    </a>
                </li>
            );

            // return <div onClick={() => this.onSelectItem(item)} key={index} className="item" data-value={item[_key]}>{item[_value]}</div>
        });
        return items;
    };
    reset() {
        this.setState({
            searchInput: '',
            // visibleData: this.props.data,
        });

        // ReactDOM.findDOMNode(this).querySelector('.ladiui.text').classList.remove('hide');
    }

    render() {
        const { id, currentKey, currentValue, placeHolder, wrapperStyle, wrapperClassName, renderHeaders, data } = this.props;
        const { searchInput } = this.state;
        const displayData = currentValue || this.getValueByKey(currentKey) || placeHolder;
        return (
            <div className={`ladiui dropdown ${wrapperClassName ? wrapperClassName : ''}`}>
                <button className='ladiui btn btn-md btn-outline-light dropdown-toggle' data-toggle='dropdown'>
                    {currentValue}
                </button>
                <ul className='ladiui dropdown-menu'>
                    {/* {
                    renderHeaders
                        &&
                        renderHeaders()
                    } */}
                    {this.renderItems()}
                </ul>
            </div>
        );
    }
}

export default Dropdown;
