import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.RENEW_TOKEN: {
      return {
        ...state,
        action: types.RENEW_TOKEN,
        status: action.status,
        message: action.message,
      };
    }
    case types.GET_USER_INFO: {
      return {
        ...state,
        action: types.GET_USER_INFO,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
