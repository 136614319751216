import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';


const list = () => ({
    type: types.ASSIGN_LIST,
    meta: {
        endpoint: endpoint.ASSIGN_LIST,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const reload = () => ({
    type: types.ASSIGN_RELOAD,
    meta: {
        endpoint: endpoint.ASSIGN_LIST,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const create = (data) => ({
    type: types.ASSIGN_CREATE,
    meta: {
        endpoint: endpoint.ASSIGN_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const update = (data) => ({
    type: types.ASSIGN_UPDATE,
    meta: {
        endpoint: endpoint.ASSIGN_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const applyBasic = (data) => ({
    type: types.ASSIGN_APPLY_BASIC,
    meta: {
        endpoint: endpoint.ASSIGN_APPLY_BASIC,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const createAdvanced = (data) => ({
    type: types.ASSIGN_CREATE_ADVANCED,
    meta: {
        endpoint: endpoint.ASSIGN_CREATE_ADVANCED,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const updateStoreAssignStatus = (status) => ({
    type: types.ASSIGN_UPDATE_STORE_STATUS,
    meta: {
        endpoint: endpoint.ASSIGN_UPDATE_STORE_STATUS,
        method: REQUEST_METHOD.POST,
        body: {
            status
        },
        hasAuth: true,
    },
});

const updateOnlineStatus = (status) => ({
    type: types.ASSIGN_UPDATE_ONLINE_STATUS,
    meta: {
        endpoint: endpoint.ASSIGN_UPDATE_ONLINE_STATUS,
        method: REQUEST_METHOD.POST,
        body: {
            status
        },
        hasAuth: true,
    },
});

export default {
    list,
    reload,
    create,
    update,
    applyBasic,
    createAdvanced,
    updateStoreAssignStatus,
    updateOnlineStatus
}