import React from 'react';
import PropTypes from 'prop-types';
import { map, remove } from 'lodash';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';

import { iconIntegration } from '../../../../config/constans';

// ModalGoogleSheet.propTypes = {
//     onCancel: PropTypes.func,
// }

export default function ModalGoogleSheet(props) {

    const { mode, formAccount, handleSubmit, selectedDataAccessAccount } = props;

    const { t } = useTranslation();

    return (
        <div>
            <div className="content">
                {
                    mode == appConfig.FORM_MODE.EDIT
                    && <div className="ladiui form-group">
                        <label className="ladiui-label">{t('COMMON.EMAIL')}</label>
                        <input type="text" style={{ padding: "10px", borderRadius: '5px', background: '#e8e8e8' }} disabled className="ladiui form-control" value={formAccount.email} />
                    </div>
                }
                {/* <div className="ladiui form-group">
                    <div className="text-center" style={{ marginTop: '40px' }}>
                        {t('INTEGRATE.ACCOUNT.LB_GOOGLE_SHEET')}
                    </div>
                </div> */}
                {
                    mode !== appConfig.FORM_MODE.EDIT
                    &&
                    <>
                        {
                            selectedDataAccessAccount && selectedDataAccessAccount.form_account_id
                                ?
                                <div className="flex items-center flex-column justify-start mt-24 gap-8">
                                    <div className='flex flex-item-start justify-center gap-8'>
                                        <img src={iconIntegration.CHECK_FILL} />
                                        <span className='text-black font-500'>{t('Kết nối thành công')}</span>
                                    </div>
                                    <a
                                        className="text-primary line-height-20"
                                        onClick={(e) => {
                                            e.preventDefault(); handleSubmit();
                                        }}>
                                        {t('Đăng xuất và sử dụng tài khoản khác')}
                                    </a>
                                </div>
                                :
                                <div className='text-center mt-24'>
                                    <button className='ladiui btn btn-secondary flex items-center mx-auto' onClick={() => handleSubmit()}>
                                        <img src='https://w.ladicdn.com/ladiui/icons/ldicon-logo-sheets.svg' />
                                        <span className='ml-8'>Kết nối với Google Sheets</span>
                                    </button>
                                </div>
                        }
                    </>
                }
            </div>

        </div>
    )
}

