import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import productCategoryActions from '../../../redux/futures/product_category/actions';
import * as productCategoryTypes from '../../../redux/futures/product_category/types';
import produce from 'immer';
import { Editor } from '@tinymce/tinymce-react';
import Input from '../../../components/Input';
import { map } from 'lodash';
import Dropdown from '../../../components/Dropdown';
import DatePicker from '../../../components/DatePicker/CustomDatePicker';

import Popover from '../../../components/Popover';

import ValidatorHelper from '../../../helpers/ValidatorHelper';

import ProductSearch from '../../orders/OrderCreate/components/ProductSearch';
import Image from '../../../components/Image';

import { t } from 'i18next';

const CDN = 'https://w.ladicdn.com/';

function PanelPageAddReview(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();

    const inputsRef = new Set();

    const popoverRef = useRef();

    const productReducer = useSelector((state) => state.product);
    const productCategoryReducer = useSelector((state) => state.productCategory);
    const fileReducer = useSelector((state) => state.file);

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });

    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [selectedUrlImage, setSelectedUrlImage] = useState('');

    const [dataProductView, setDataProductView] = useState({
        avatar: '',
        name: '',
        point: 5,
        images: [],
        date_created: new Date(),
        content: '',
    });

    useEffect(() => {
        if (props.isManageReview) {
            let payload = {
                search: {},
                paged: 1,
                limit: 20,
            };

            dispatch(productActions.list(payload));
        }
    }, []);

    useEffect(() => {
        if (productReducer.action == productTypes.LIST_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    let _data = [...products];

                    productReducer.products.map((item, index) => {
                        const isExitProduct = _data.some((_item) => _item.value == item.product_id);
                        if (!isExitProduct) {
                            _data.unshift({
                                name: item.name,
                                value: item.product_id,
                            });
                        }
                    });

                    setProducts(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_AVATAR) {
            if (fileReducer.status) {
                if (fileReducer.avatar_url) {
                    setDataProductView({
                        ...dataProductView,
                        avatar: fileReducer.avatar_url,
                    });
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
        if (fileReducer.action == fileTypes.UPLOAD_PRODUCT_REVIEW_IMAGES) {
            let _selectedImages = [...dataProductView.images];
            if (fileReducer.status) {
                const newUploadedImage = map(fileReducer.product_images, (item) => item);

                if (newUploadedImage && newUploadedImage.length > 0 && _selectedImages.length + newUploadedImage.length <= 6) {
                    map(newUploadedImage, (item, index) => {
                        _selectedImages.push(item);
                    });

                    setDataProductView({
                        ...dataProductView,
                        images: _selectedImages,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', 'Số lượng ảnh không được vượt quá 6', 'OK');
                }
            }
        }
    }, [fileReducer]);

    const removeAvatarReview = () => {
        setDataProductView({
            ...dataProductView,
            avatar: '',
        });
    };

    const fileChangedHandlerAvatarReview = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadAvatar(form));
            }
        }
    };

    const onChangeDataProductView = (e) => {
        let { name, value } = e.target;

        setDataProductView((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChangePointProductView = (value) => {
        setDataProductView({
            ...dataProductView,
            point: dataProductView + value,
        });
    };

    const handleEditorChange = (value) => {
        setDataProductView({
            ...dataProductView,
            content: value,
        });
    };

    const handleSelectProduct = (product) => {
        let _selectedProducts = [...selectedProducts];
        let productItem = {
            name: product.name,
            product_id: product.product_id,
            product_variant_id: product.product_variant_id,
            price: product.price,
            src: product.src || '',
        };

        _selectedProducts.push(productItem);

        setSelectedProducts(_selectedProducts);
    };

    const handleDeleteProductReview = (index) => {
        let _selectedProducts = [...selectedProducts];
        _selectedProducts.splice(index, 1);
        setSelectedProducts(_selectedProducts);
    };

    const handleClickInputFile = () => {
        document.getElementById('input-upload-file').click();
    };

    const handleAddImageUrl = () => {
        if (selectedUrlImage && ValidatorHelper.isValidHttpUrl(selectedUrlImage)) {
            // props.handleSetListImageProducts(selectedUrlImage);
            popoverRef.current.instanceRef.hide();
            setSelectedUrlImage('');
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };

    const fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadProductPreviewImages(form));
            }
        }
    };

    return (
        <div className='page-category-v4'>
            <div className='menu-header'>
                <div className='content'>
                    <div className='top-menu-header'>
                        <ul className='flex items-center'>
                            <li className='cursor-pointer' onClick={() => history.push('/products')}>
                                <span>Quản lý đánh giá</span>
                            </li>
                            <li>
                                <strong>Tạo đánh giá</strong>
                            </li>
                        </ul>
                    </div>
                    <div className='title-action'>
                        <h3>Tạo đánh giá mới</h3>
                        <button className='ladiui btn btn-primary'>Tạo đánh giá</button>
                    </div>
                </div>
            </div>
            <div className='page-content-category'>
                <div className='page-content'>
                    <div className='page-category-info'>
                        <div className='content-left'>
                            <div className='block-content-item'>
                                <h3>Nội dung đánh giá</h3>
                                <div className='flex items-center'>
                                    <div className='ladiui form-group' style={{ width: '30%' }}>
                                        <label className='ladiui-label new'>Ảnh đại diện</label>
                                        <div className='panel'>
                                            <div className='panel-line'>
                                                <div className='content'>
                                                    <div className='preview-store-logo'>
                                                        <div id='store-logo' style={{ width: 'max-content' }}>
                                                            <img
                                                                className='store-logo'
                                                                src={
                                                                    dataProductView.avatar
                                                                        ? `${CDN}${dataProductView.avatar}`
                                                                        : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                }
                                                                alt=''
                                                            />
                                                            <div id='store-logo-overlay'>
                                                                <div className='ladiui btn-group'>
                                                                    <div className='ladiui dropdown'>
                                                                        <button
                                                                            data-toggle='dropdown'
                                                                            className='ladiui-btn-dropdown dropdown-toggle'
                                                                            style={{ minWidth: 'auto' }}
                                                                        >
                                                                            <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                                        </button>
                                                                        <ul
                                                                            className='ladiui dropdown-menu custom-dropdown-menu'
                                                                            style={{ minWidth: '100px', textAlign: 'left', left: 0 }}
                                                                        >
                                                                            <li className='ladiui open-modal'>
                                                                                <label htmlFor='upload-avatar-review'>Thay ảnh</label>
                                                                            </li>
                                                                            <li
                                                                                className='ladiui dropdown-item danger-text'
                                                                                onClick={() => removeAvatarReview()}
                                                                            >
                                                                                Xóa
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <input
                                                            type='file'
                                                            id='upload-avatar-review'
                                                            name='file'
                                                            accept='image/*'
                                                            multiple={false}
                                                            onChange={fileChangedHandlerAvatarReview}
                                                            onClick={(event) => {
                                                                event.target.value = null;
                                                            }}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>Tên khách hàng</label>
                                        <Input
                                            name='name'
                                            validationName={'tên khách hàng'}
                                            placeholder={'Nhập tên khách hàng'}
                                            value={dataProductView.name}
                                            onChange={(e) => onChangeDataProductView(e)}
                                            ref={(ref) => inputsRef.add(ref)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                </div>
                                <div className='flex items-center gap-24'>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>Điểm đánh giá</label>
                                        <div className='variants-quantity'>
                                            <div className='variant-quantity-up-down space-between w100'>
                                                <input
                                                    type='number'
                                                    value={dataProductView.point}
                                                    onChange={(e) => onChangeDataProductView(e)}
                                                />
                                                <div className='variant-up-down'>
                                                    <i className='new-ldicon-arrow-up' onClick={() => onChangePointProductView(+1)} />
                                                    <i className='new-ldicon-arrow-down' onClick={() => onChangePointProductView(-1)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>Thời gian tạo</label>
                                        <div style={{ width: '100%' }}>
                                            <DatePicker
                                                name='start'
                                                // minDate={baseHelper.momentToDate(moment())}
                                                selected={dataProductView.date_created}
                                                onChange={(value) => {
                                                    setDataProductView({
                                                        ...dataProductView,
                                                        date_created: value,
                                                    });
                                                }}
                                                showTimeSelect
                                                timeFormat={appConfig.DEFAUT_TIME}
                                                timeIntervals={15}
                                                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                timeCaption='Time'
                                                customPlaceholder='Chọn thời gian'
                                                customClass='w100'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>Nội dung đánh giá</label>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={dataProductView.content}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </div>
                            </div>
                            <div className='block-content-item mt-20'>
                                <h3 className='mb-24'>
                                    Hình ảnh đánh giá ({dataProductView.images ? dataProductView.images.length : 0}/6)
                                </h3>
                                <div className='list-image-product-reviews'>
                                    {!dataProductView.images || dataProductView.images.length == 0 ? (
                                        <div className='text-center image-none'>
                                            <div className='ladiui-button-image'>
                                                <div className='btn-add-image'>
                                                    <div>
                                                        <img
                                                            src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-import-image.svg'}
                                                            alt=''
                                                        />
                                                    </div>
                                                    <div className='mt-16'>
                                                        <span className='text-default'>
                                                            <a className='pr-4' onClick={() => handleClickInputFile()}>
                                                                Tải ảnh lên
                                                            </a>
                                                            hoặc
                                                            <Popover
                                                                ref={popoverRef}
                                                                wrapperProps={{ className: 'relative' }}
                                                                wrapperPopoverProps={{ className: 'popover-image-product' }}
                                                                // isShowPopover={isShowPopover}
                                                                content={
                                                                    <div className='popover-add-image-product'>
                                                                        <div className='popover'>
                                                                            <div className='ladiui form-group'>
                                                                                <label className='ladiui-label new text-left'>
                                                                                    Đường dẫn hình ảnh
                                                                                </label>
                                                                                <Input
                                                                                    className='ladiui form-control'
                                                                                    placeholder='Nhập đường dẫn sản phẩm'
                                                                                    name='image_url'
                                                                                    value={selectedUrlImage}
                                                                                    onChange={(event) => {
                                                                                        setSelectedUrlImage(event.target.value);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className='action-footer'>
                                                                                <button
                                                                                    className='ladiui btn btn-secondary'
                                                                                    onClick={() => {
                                                                                        popoverRef.current.instanceRef.hide();
                                                                                    }}
                                                                                >
                                                                                    Hủy
                                                                                </button>
                                                                                <button
                                                                                    className='ladiui btn btn-primary'
                                                                                    onClick={handleAddImageUrl}
                                                                                >
                                                                                    Thêm hình ảnh
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            >
                                                                <a className='pl-4'>Thêm từ URL</a>
                                                            </Popover>
                                                        </span>
                                                        <p className='rec-size'>Kích thước khuyến nghị 400x400 (px)</p>
                                                    </div>
                                                </div>
                                                <input
                                                    className='cursor-pointer display-none'
                                                    id='input-upload-file'
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    multiple='multiple'
                                                    onChange={fileChangedHandler}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='list-images'>
                                            {map(dataProductView.images, (imageItem, index) => {
                                                let prefixCDN = true;
                                                if (imageItem && imageItem.startsWith('http')) {
                                                    prefixCDN = false;
                                                }

                                                return (
                                                    <div className='image-item' key={index}>
                                                        <div className='img'>
                                                            <Image
                                                                prefixCDN={prefixCDN}
                                                                src={imageItem}
                                                                key={index}
                                                            // onClick={() => {
                                                            //     setImageSelect({
                                                            //         ...imageSelect,
                                                            //         src: item.src || ''
                                                            //     })
                                                            // }}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='block-content-item mt-20'>
                                <h3 className='mb-24'>Đánh giá cho sản phẩm</h3>
                                <ProductSearch isNotShowBtnCreateProduct={true} selectVariant={handleSelectProduct} />
                                <div className='block-list-products-category-v4 list-product-reviews'>
                                    {selectedProducts && selectedProducts.length > 0 ? (
                                        <div className='list-products'>
                                            <table className='ladiui table text-left'>
                                                <thead>
                                                    <tr className='ladiui table-vertical'>
                                                        <th width='50'></th>
                                                        <th>Tên sản phẩm</th>
                                                        <th>Giá sản phẩm</th>
                                                        <th className='text-right'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {map(selectedProducts, (product, index) => {
                                                        return (
                                                            <tr className='ladiui table-vertical'>
                                                                <td>
                                                                    <div className='flex image-product items-center'>
                                                                        <div className='img'>
                                                                            <img
                                                                                src={
                                                                                    product.src
                                                                                        ? CDN + product.src
                                                                                        : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                                }
                                                                                width={60}
                                                                                height={60}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{product.name}</td>
                                                                <td>
                                                                    {product.price
                                                                        ? baseHelper.formatMoneyPostFix(
                                                                            product.price,
                                                                            storeInfo.currentStore.currency_symbol
                                                                        )
                                                                        : 0}
                                                                </td>
                                                                <td className='text-right'>
                                                                    <a href='#' onClick={() => handleDeleteProductReview(index)}>
                                                                        <i className='ladi-icon icon-bin' />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='no-products'>
                                            <div className='icon'>
                                                <i className='new-ldicon-template' />
                                            </div>
                                            <p className='text-center'>
                                                Chưa có sản phẩm nào trong danh mục này.
                                                <br />
                                                Tìm và bổ sung bằng thanh tìm kiếm.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='content-right'>
                            <div className='block-content-item'>
                                <div className='flex items-center space-between'>
                                    <h3>Hiển thị danh mục</h3>
                                    <div className='ladiui item-form switch'>
                                        <label className='ladiui switch m-0'>
                                            <input
                                                type='checkbox'
                                            // onChange={(event) => {
                                            //     setCategoryInfo({
                                            //         ...categoryInfo,
                                            //         isShow: !categoryInfo.isShow
                                            //     })
                                            // }}
                                            // checked={categoryInfo.isShow}
                                            />
                                            <span className='ladiui slider round'></span>
                                        </label>
                                    </div>
                                </div>
                                <div className='block-info-help flex items-center'>
                                    <i className='ladi-icon icon-c-question mr-8' />
                                    <span>Hiển thị danh mục trên các cửa hàng cụ thể</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withTranslation()(PanelPageAddReview);
