import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';

ModalShopify.propTypes = {
    formAccount: PropTypes.any,
    onChangeInput: PropTypes.func
}

export default function ModalShopify(props) {

    const { formAccount, inputRefs, onChangeInput, getLinkApiUrl } = props;
    const { t } = useTranslation();

    const fixWidthInput = formAccount && formAccount.token ? "75%" : "100%";


    return (
        <div>
            <div className="content">
                <div className="ladiui form-group">
                    <label className="ladiui-label">{t('INTEGRATE.ACCOUNT.LB_NAME')}</label>
                    <Input
                        ref={(ref) => inputRefs.add(ref)}
                        name="name"
                        validationName={t('INTEGRATE.ACCOUNT.LB_NAME')}
                        placeholder={t('INTEGRATE.ACCOUNT.LB_NAME')}
                        value={formAccount.name}
                        onChange={onChangeInput}
                        validations={{ isRequired: true }}
                    />
                </div>
                <div className="ladiui form-group">
                    <label className="ladiui-label">{t('INTEGRATE.ACCOUNT.LB_API_URL')}</label>
                    <div style={{ display: formAccount && formAccount.token ? "flex" : "block", alignItems: "center", justifyContent: "space-between" }}>
                        <Input
                            ref={(ref) => inputRefs.add(ref)}
                            name="api_url"
                            validationName={t('INTEGRATE.ACCOUNT.LB_API_URL')}
                            placeholder={t('INTEGRATE.ACCOUNT.LB_API_ULR_SHOPIFY')}
                            value={formAccount.api_url}
                            onChange={onChangeInput}
                            validations={{ isRequired: true }}
                            style={{ width: fixWidthInput }}
                            disabled={formAccount.token ? true : false}
                        />
                        <div className="connect">
                            <div className="button-connect">
                                {
                                    formAccount && formAccount.token
                                    &&
                                    <button className="ladiui btn btn-secondary" onClick={getLinkApiUrl}>Đồng bộ lại</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

