import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const updateStatus = (orderID, paymentID, status) => ({
    type: types.UPDATE_PAYMENT_STATUS,
    meta: {
        endpoint: endpoint.PAYMENT_UPDATE_STATUS,
        method: REQUEST_METHOD.POST,
        body: {
            order_id: orderID,
            payment_id: paymentID,
            status,
        },
        hasAuth: true,
    }
});

const listGateways = (showAll = false) => ({
    type: types.PAYMENT_LIST_GATEWAYS,
    meta: {
        endpoint: endpoint.PAYMENT_LIST_GATEWAYS,
        method: REQUEST_METHOD.POST,
        body: {
            show_all: showAll
        },
        hasAuth: true,
    }
});

export default {
    updateStatus,
    listGateways,
}