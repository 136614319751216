import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';

import pageCheckoutDefined from '../components/pageCheckoutDefined';

import pageCheckoutActions from '../../../../redux/futures/page_checkout/actions';

import ButtonAddCart from './components/ButtonAddCart';
import ButtonBuy from './components/ButtonBuy';
import Cart from './components/Cart';
import GeneralSetting from './components/GeneralSetting';
import Payment from './components/PaymentWidget/Payment';
import PageHome from './components/PageHome';
import PageProduct from './components/PageProduct';
import SetupAfterPayment from '../setupAfterPayment/SetupAfterPayment';
import PageFooter from './components/PageFooter';

// eslint-disable-next-line max-lines-per-function

const WidgetConfig = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageCheckout, setDnsUrl, setPageDns } = props;

    const cartRef = useRef();
    const generalSettingRef = useRef();
    const paymentRef = useRef();
    const pageHomeRef = useRef();
    const pageProductRef = useRef();
    const setupAfterPaymentRef = useRef();
    const pageFooterRef = useRef();

    const [menuSelected, setMenuSelected] = useState('');
    const [paymentWidgetSelected, setPaymentWidgetSelected] = useState('');
    const [widgetConfig, setWidgetConfig] = useState({});

    useImperativeHandle(ref, () => ({
        getData: () => {
            let cartConfig = [];
            let generalSetting = {};
            let payment = {};
            let pageHome = {};
            let pageProduct = {};
            let footerConfig = [];

            if (cartRef && cartRef.current) {
                cartConfig = cartRef.current.getData();
            }
            if (generalSettingRef && generalSettingRef.current) {
                generalSetting = generalSettingRef.current.getData();
            }
            if (paymentRef && paymentRef.current) {
                payment = paymentRef.current.getData();
            }
            if (pageHomeRef && pageHomeRef.current) {
                pageHome = pageHomeRef.current.getData();
            }
            if (pageProductRef && pageProductRef.current) {
                pageProduct = pageProductRef.current.getData();
            }
            let setupAfterPayment = setupAfterPaymentRef.current.getData();

            if (pageFooterRef && pageFooterRef.current) {
                footerConfig = pageFooterRef.current.getData();
                pageHome.channels = footerConfig.channels;
                delete footerConfig.channels;
            }
            let thank_you_config = setupAfterPayment.sectionsData;
            delete setupAfterPayment.sectionsData;

            let widgetConfig = {
                cart_config: cartConfig,
                general_setting: generalSetting,
                payment_config: payment,
                page_home_config: pageHome,
                page_product_config: pageProduct,
                setupAfterPayment,
                footer_config: footerConfig,
                thank_you_config,
            };
            return widgetConfig;
        },
    }));

    useEffect(() => {
        if (pageCheckout) {
            let widgetConfigTg = pageCheckout.widget_config || {};
            setWidgetConfig(widgetConfigTg);
        }
    }, [pageCheckout]);

    useEffect(() => {
        if (queryStrings) {
            let menu = queryStrings.get('widget-menu');
            if (!menu) {
                setMenuSelected('');
            } else {
                setMenuSelected(menu);
            }

            let paymentMenu = queryStrings.get('payment-menu');
            if (!paymentMenu) {
                setPaymentWidgetSelected('');
            } else {
                setPaymentWidgetSelected(paymentMenu);
            }
        }
    }, [queryStrings]);

    const backMenu = () => {
        let _queryStrings = queryStrings;

        let paymentMenu = _queryStrings.get('payment-menu');
        let widgetMenu = _queryStrings.get('widget-menu');

        if (paymentMenu) {
            _queryStrings.delete('payment-menu');
            history.replace({ search: _queryStrings.toString() });
            return;
        }

        if (widgetMenu) {
            _queryStrings.delete('widget-menu');
            history.replace({ search: _queryStrings.toString() });
            return;
        }

        _queryStrings.delete('tab');

        history.replace({ search: _queryStrings.toString() });
    };

    const selectMenu = (action) => {
        let _queryStrings = queryStrings;
        _queryStrings.set('widget-menu', action);

        switch (action) {
            case 'PAGE_HOME_CONFIG':
                let url = `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/page/${pageCheckout.path}/?preview=true`;
                if (pageCheckout.types != appConfig.PAGE_CHECKOUT_TYPE.STORE)
                    url = `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/${pageCheckout.path}/?preview=true`;
                setDnsUrl(url);

                setPageDns(pageCheckoutDefined.DNS_PAGE[0]);
                break;
            case 'CART':
                setDnsUrl(
                    `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/page/${
                        pageCheckout.path
                    }/carts/preview?preview=true`
                );

                setPageDns(pageCheckoutDefined.DNS_PAGE[1]);
                break;
            case 'PAYMENT':
                setDnsUrl(
                    `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/page/${
                        pageCheckout.path
                    }/checkouts/preview?preview=true&checkout_config_id=${pageCheckout.checkout_config_id}`
                );
                setPageDns(pageCheckoutDefined.DNS_PAGE[2]);
                break;

            default:
                break;
        }

        history.replace({ search: _queryStrings.toString() });
    };

    const getNameMenu = () => {
        if (!menuSelected) return '';

        let menu = pageCheckoutDefined.WIDGET_MENU.find((item) => item.action == menuSelected);
        if (menu) return menu.title;
        return '';
    };

    const getNamePaymentMenu = () => {
        if (!paymentWidgetSelected) return '';

        let menu = pageCheckoutDefined.WIDGET_PAYMENT_MENU.find((item) => item.action == paymentWidgetSelected);
        if (menu) return menu.title;
        return '';
    };

    return (
        <div className={`page-checkout-config-item ${isShow ? 'show' : 'hide'}`}>
            <div className='header'>
                <div className='display-flex-center gap-16'>
                    <div className='icon-back' onClick={backMenu}>
                        <i className='ldicon-chevron-left' />
                    </div>
                    <div className='display-flex-center gap-4'>
                        <span className='ladiui title-header'>{t('PAGE_CHECKOUT.MENU.WIDGET_CONFIG')}</span>

                        {menuSelected && !paymentWidgetSelected && (
                            <>
                                <i className='ldicon-chevron-right' />
                                <span className='ladiui title-header'>{getNameMenu()} </span>
                            </>
                        )}

                        {paymentWidgetSelected && (
                            <>
                                <i className='ldicon-chevron-right' />
                                <span className='ladiui title-header'>{getNamePaymentMenu()} </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {!menuSelected && !paymentWidgetSelected && (
                <div className='widget-menu'>
                    {pageCheckoutDefined.WIDGET_MENU.map((item, index) => {
                        if (!item.types.includes(pageCheckout.types)) return null;
                        return (
                            <div key={index} className='widget-menu-item' onClick={() => selectMenu(item.action)}>
                                <div className='display-flex-center gap-16'>
                                    <i className={` ladiui icon-new ${item.iconClass}`} />
                                    <span className='widget-menu-label'>{item.title}</span>
                                </div>
                                <i className='ldicon-chevron-right' />
                            </div>
                        );
                    })}
                </div>
            )}
            {/* <ButtonAddCart isShow={menuSelected == 'BTN_ADD_CART'} /> */}
            {/* <ButtonBuy isShow={menuSelected == 'BTN_BUY'} /> */}

            <Cart ref={cartRef} isShow={menuSelected == 'CART'} cartConfig={widgetConfig.cart_config} />
            <GeneralSetting
                ref={generalSettingRef}
                isShow={menuSelected == 'GENERAL_SETTINGS'}
                generalConfig={widgetConfig.general_setting}
                pageCheckout={pageCheckout}
            />
            <Payment ref={paymentRef} isShow={menuSelected == 'PAYMENT'} paymentConfig={widgetConfig.payment_config} />
            <PageHome ref={pageHomeRef} isShow={menuSelected == 'PAGE_HOME_CONFIG'} pageStoreConfig={widgetConfig.page_home_config} />
            <PageProduct ref={pageProductRef} isShow={menuSelected == 'PAGE_PRODUCT_CONFIG'} />
            <SetupAfterPayment
                ref={setupAfterPaymentRef}
                isShow={menuSelected == 'SETUP_AFTER_PAYMENT'}
                pageCheckout={pageCheckout}
                thankyouConfig={widgetConfig.thank_you_config}
            />
            <PageFooter
                ref={pageFooterRef}
                isShow={menuSelected == 'SETUP_FOOTER'}
                footerConfig={widgetConfig.footer_config}
                pageStoreConfig={widgetConfig.page_home_config}
            />
        </div>
    );
});

export default WidgetConfig;
