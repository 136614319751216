import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import appConfig, { endpoint } from "../../../config/app";
import i18n from "../../../i18n";

const list = (data) => ({
  type: types.LIST_SHIPPING,
  meta: {
    endpoint: endpoint.SHIPPING_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const deleteShippings = (shippingIDs) => ({
  type: types.DELETE_SHIPPINGS,
  meta: {
    endpoint: endpoint.SHIPPING_DELETE_IDS,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_ids: shippingIDs,
    },
    hasAuth: true,
  },
});

const deleteShipping = (shippingID) => ({
  type: types.DELETE_SHIPPING,
  meta: {
    endpoint: endpoint.SHIPPING_DELETE_ID,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_id: shippingID,
    },
    hasAuth: true,
  },
});

const show = (shippingID) => ({
  type: types.SHOW_SHIPPING,
  meta: {
    endpoint: endpoint.SHIPPING_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_id: shippingID,
    },
    hasAuth: true,
  },
});

const create = (shipping) => ({
  type: types.CREATE_SHIPPING,
  meta: {
    endpoint: endpoint.SHIPPING_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      shipping: shipping,
    },
    hasAuth: true,
  },
});

const update = (shipping) => ({
  type: types.UPDATE_SHIPPING,
  meta: {
    endpoint: endpoint.SHIPPING_UPDATE,
    method: REQUEST_METHOD.POST,
    body: {
      shipping: shipping,
    },
    hasAuth: true,
  },
});

const updateFee = (shippingID, fee) => ({
  type: types.SHIPPING_UPDATE_FEE,
  meta: {
    endpoint: endpoint.SHIPPING_UPDATE_FEE,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_id: shippingID,
      fee,
    },
    hasAuth: true,
  },
});

const updateTrackingNumber = (shippingID, trackingNumber) => ({
  type: types.SHIPPING_UPDATE_TRACKING_NUMBER,
  meta: {
    endpoint: endpoint.SHIPPING_UPDATE_TRACKING_NUMBER,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_id: shippingID,
      tracking_number: trackingNumber,
    },
    hasAuth: true,
  },
});

const updateStatus = (shippingID, status) => ({
  type: types.SHIPPING_UPDATE_STATUS,
  meta: {
    endpoint: endpoint.SHIPPING_UPDATE_STATUS,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_id: shippingID,
      status,
    },
    hasAuth: true,
  },
});

const updatePaymentStatus = (shippingID, paymentStatus) => ({
  type: types.SHIPPING_UPDATE_PAYMENT_STATUS,
  meta: {
    endpoint: endpoint.SHIPPING_UPDATE_PAYMENT_STATUS,
    method: REQUEST_METHOD.POST,
    body: {
      shipping_id: shippingID,
      payment_status: paymentStatus,
    },
    hasAuth: true,
  },
});

const listPartners = (type = null) => ({
  type: types.SHIPPING_LIST_PARTNERS,
  meta: {
    endpoint: endpoint.SHIPPING_LIST_PARTNERS,
    method: REQUEST_METHOD.POST,
    body: { type },
    hasAuth: true,
  },
});

const listHubs = (partnerCode) => {
  return {
    type: types.SHIPPING_LIST_HUBS,
    meta: {
      endpoint: endpoint.SHIPPING_LIST_HUBS,
      method: REQUEST_METHOD.POST,
      body: {
        shipping_partner_code: partnerCode,
      },
      hasAuth: true,
    },
  };
};

const listServices = (data) => ({
  type: types.SHIPPING_LIST_SERVICES,
  meta: {
    endpoint: endpoint.SHIPPING_LIST_SERVICES,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const listStations = (data) => ({
  type: types.SHIPPING_LIST_STATIONS,
  meta: {
    endpoint: endpoint.SHIPPING_LIST_STATIONS,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const resetServices = () => ({
  type: types.SHIPPING_RESET_SERVICES,
});

const resetFee = () => ({
  type: types.SHIPPING_RESET_FEE,
});

const getFee = (data) => ({
  type: types.SHIPPING_GET_FEE,
  meta: {
    endpoint: endpoint.SHIPPING_GET_FEE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const cancel = (orderNo, shippingID) => ({
  type: types.SHIPPING_CANCEL,
  meta: {
    endpoint: endpoint.SHIPPING_CANCEL,
    method: REQUEST_METHOD.POST,
    body: {
      order_no: orderNo,
      shipping_id: shippingID,
    },
    hasAuth: true,
  },
});

const listOtherHubs = () => {
  return {
    type: types.SHIPPING_LIST_OTHER_HUBS,
  };
};

const listGhnHubs = () => {
  return {
    type: types.SHIPPING_LIST_GHN_HUBS,
  };
};

const listGhtkHubs = () => {
  return {
    type: types.SHIPPING_LIST_GHTK_HUBS,
  };
};

const listVnPostHubs = () => {
  return {
    type: types.SHIPPING_LIST_VNPOST_HUBS,
  };
};

const listVtPostHubs = () => {
  return {
    type: types.SHIPPING_LIST_VTPOST_HUBS,
  };
};

export default {
  show,
  list,
  deletes: deleteShippings,
  delete: deleteShipping,
  create,
  update,
  listPartners,
  listServices,
  resetServices,
  resetFee,
  listHubs,
  getFee,
  cancel,
  updateFee,
  updateTrackingNumber,
  updateStatus,
  updatePaymentStatus,
  listOtherHubs,
  listGhnHubs,
  listGhtkHubs,
  listVnPostHubs,
  listVtPostHubs,
  listStations,
};
