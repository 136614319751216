import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as customerAddressTypes from '../../../../redux/futures/customer_address/types';
import customerActions from '../../../../redux/futures/customer/actions';
import customerAddressActions from '../../../../redux/futures/customer_address/actions';
import Location from '../../../../components/Location';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCustomerAddressCreate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible, hideModal, selectedAddress, customer, isShowListAddressBilling, selectedBilling } = props;

    const customerReducer = useSelector((state) => state.customer);
    const customerAddressReducer = useSelector((state) => state.customerAddress);

    const loading = useMemo(() => {
        return customerAddressReducer.loading || false;
    }, [customerAddressReducer.loading]);

    const [address, setAddress] = useState({
        country_code: 'VN',
        country_name: 'Việt Nam',
    });

    useEffect(() => {
        if (customerAddressReducer.action == customerAddressTypes.CREATE_CUSTOMER_ADDRESS) {
            if (customerAddressReducer.status) {
                if (isShowListAddressBilling) {
                    selectedBilling(customerAddressReducer.address, true);
                } else selectedAddress(customerAddressReducer.address, true);
                hideModal();
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), customerAddressReducer.message, 'OK');
            }
        }
    }, [customerAddressReducer.action]);

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        let dataSend = {
            ...customer,
            ...address,
        };

        delete dataSend.address_default;
        delete dataSend.addresses;

        dispatch(customerAddressActions.create(dataSend));
    };

    const customerEdit = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setAddress((pre) => {
            let tg = { ...pre };
            tg[name] = value;
            return tg;
        });
    };

    const onChangeLocation = (data) => {
        setAddress((pre) => {
            let tg = { ...pre, ...data };
            return tg;
        });
    };

    return (
        <Modal
            width={600}
            id='modal-customer-address-create'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('CUSTOMERS.ADDRESS_NEW')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('CUSTOMERS.ADDRESS_BTN')}
            isLoading={loading}
            position_unset={true}
        >
            <div className=''>
                <div className='customer-location'>
                    <Location
                        position_unset={false}
                        address={address}
                        autoLoad={true}
                        onChangeInput={onChangeLocation}
                        validations={{
                            isRequiredCountry: true,
                            isRequiredState: true,
                            isRequiredDistrict: true,
                            isRequiredWard: true,
                        }}
                    />
                </div>
                <div className='mt-16'>
                    <label>{t('CUSTOMERS.ADDRESS')}</label>
                    <Input className='mt-4' type='text' name='address' value={address.address} onChange={customerEdit} />
                </div>
            </div>
        </Modal>
    );
}

export default ModalCustomerAddressCreate;
