import appConfig from './app';
import i18n from '../i18n';

export const integrationCategoryKeys = {
    ecommerce: 'ecommerce',
    crm: 'crm',
    email: 'email',
    other: 'other',
};

export const iconIntegration = {
    SAPO: 'https://w.ladicdn.com/s600x600/5e548427be8067214346f471/gcalls-logo-doi-tac-sapo-1-20210708084900.png',
    HARAVAN: 'https://builder.ladipage.com/img/haravan.png',
    SHOPIFY: 'https://builder.ladipage.com/img/shopify.png',
    WORDPRESS: 'https://builder.ladipage.com/img/wordpress.png',
    KIOTVIET: 'https://builder.ladipage.com/img/kiotviet.png',
    GETRESPONSE: 'https://builder.ladipage.com/img/get_response.png',
    ACTIVE_CAMPAIGN: 'https://builder.ladipage.com/img/active_campaign.png',
    HUBSPOT: 'https://builder.ladipage.com/img/hubspot.png',
    INFUSION_SOFT: 'https://builder.ladipage.com/img/infusion_soft.png',
    GOOGLE_SHEET: 'https://builder.ladipage.com/img/google_sheet.png',
    CONVERTKIT: 'https://builder.ladipage.com/img/convertkit.png',
    MAIL_CHIMP: 'https://builder.ladipage.com/img/mail_chimp.png',
    NHANH: 'https://builder.ladipage.com/img/nhanh_vn.png',
    GOOGLE_CALENDAR: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-calendar.svg',
    GOOGLE_FORM: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-forms.svg',
    CHECK_FILL: 'https://w.ladicdn.com/ladiui/icons/new-ldicon-circlecheck-fill-colored.svg',
};

export const integrationCategories = [
    {
        title: 'Thương mại điện tử',
        value: integrationCategoryKeys.ecommerce,
    },
    {
        title: 'Email',
        value: integrationCategoryKeys.email,
    },
    {
        title: 'CRM',
        value: integrationCategoryKeys.crm,
    },
    {
        title: 'Khác',
        value: integrationCategoryKeys.other,
    },
];

export const integrationTypes = [
    {
        category: integrationCategoryKeys.ecommerce,
        type: appConfig.FORM_TYPE.HARAVAN.CODE,
        name: appConfig.FORM_TYPE.HARAVAN.NAME,
        isShowCustomer: true,
        icon: iconIntegration.HARAVAN,
        description: appConfig.FORM_TYPE.HARAVAN.DESCRIPTION,
        content: `Haravan là một nền tảng công nghệ cung cấp cho các doanh nghiệp các giải pháp về thương mại điện tử tương tự như Shopify: từ cửa hàng đến online, từ Website đến facebook, diễn đàn, sàn thương mại điện tử. Haravan cho phép người dùng mở tài khoản và lập một gian hàng riêng với tên miền miễn phí dạng tenmien.myharavan.com hoặc bạn cũng có thể đầu tư để mua tên miền riêng cho nhãn hàng.`,
    },
    {
        category: integrationCategoryKeys.ecommerce,
        type: appConfig.FORM_TYPE.SAPO.CODE,
        name: appConfig.FORM_TYPE.SAPO.NAME,
        isShowCustomer: true,
        icon: iconIntegration.SAPO,
        description: appConfig.FORM_TYPE.SAPO.DESCRIPTION,
        content:
            'Sapo là một trong những phần mềm quản lý doanh nghiệp bán hàng uy tín tại Việt Nam. Thương hiệu Sapo được nhiều người biết đến và sử dụng vì có quy mô lớn cũng như sở hữu nền tảng quản lý và bán hàng đa kênh. Với nhiều tính năng ưu việt, Sapo hiện là một phần mềm quản lý bán lẻ, giải pháp bán hàng online được nhiều doanh nghiệp đánh giá cao.',
    },
    {
        category: integrationCategoryKeys.ecommerce,
        type: appConfig.FORM_TYPE.SHOPIFY.CODE,
        name: appConfig.FORM_TYPE.SHOPIFY.NAME,
        isShowCustomer: true,
        icon: iconIntegration.SHOPIFY,
        description: appConfig.FORM_TYPE.SHOPIFY.DESCRIPTION,
        content:
            'Shopify là nền tảng thương mại điện tử cho phép bạn tạo website bán hàng online dựa trên mô hình Cloud SaaS. Tại đây, bạn có thể tạo cho mình một website bán hàng online với tất cả những tính năng như đăng sản phẩm, giỏ hàng, thanh toán, quản lý hàng hóa hay kết nối mạng xã hội…',
    },
    {
        category: integrationCategoryKeys.ecommerce,
        type: appConfig.FORM_TYPE.WORDPRESS.CODE,
        name: appConfig.FORM_TYPE.WORDPRESS.NAME,
        isShowCustomer: true,
        icon: iconIntegration.WORDPRESS,
        description: appConfig.FORM_TYPE.WORDPRESS.DESCRIPTION,
        content: 'Nội dung mô tả tích hợp WordPress',
    },
    {
        category: integrationCategoryKeys.ecommerce,
        type: appConfig.FORM_TYPE.KIOTVIET.CODE,
        name: appConfig.FORM_TYPE.KIOTVIET.NAME,
        isShowCustomer: true,
        icon: iconIntegration.KIOTVIET,
        description: appConfig.FORM_TYPE.KIOTVIET.DESCRIPTION,
        content:
            'KiotViet là một nền tảng quản lý bán hàng và kinh doanh dành cho các doanh nghiệp nhỏ và vừa tại Việt Nam.Nền tảng này giúp các doanh nghiệp quản lý hàng tồn kho, đơn hàng, thanh toán và các hoạt động kinh doanh khác một cách hiệu quả.Tích hợp Kiotviet với Ladipage eCommerce giúp bạn thu thập thông tin khách hàng, đơn hàng và tương tác với họ trên nhiều kênh khác nhau như Email, SMS, ZNS...',
    },
    {
        category: integrationCategoryKeys.other,
        type: appConfig.FORM_TYPE.GOOGLE_SHEET.CODE,
        name: appConfig.FORM_TYPE.GOOGLE_SHEET.NAME,
        isShowCustomer: true,
        icon: iconIntegration.GOOGLE_SHEET,
        description: appConfig.FORM_TYPE.GOOGLE_SHEET.DESCRIPTION,
        content:
            'Google Sheets là một ứng dụng xử lý bảng tính trực tuyến được cung cấp bởi Google. Google Sheets thường được sử dụng để quản lý dữ liệu số, tính toán, phân tích dữ liệu và thực hiện các tác vụ liên quan đến bảng tính trong môi trường trực tuyến và cộng tácTích hợp Google giúp bạn có thể tương tác với khách hàng khi thông tin của họ thay đổi trên Google Sheet. Đồng thời bạn cũng có thể đồng bộ dữ liệu từ Ladipage eCommerce về Google Sheet',
    },
    {
        category: integrationCategoryKeys.other,
        type: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE,
        name: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.NAME,
        isShowCustomer: true,
        icon: iconIntegration.INFUSION_SOFT,
        description: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.DESCRIPTION,
        content:
            'HubSpot là phần mềm tiếp thị và công cụ quản lý tương tác khách hàng (CRM). Tích hợp Hubspot để nhận và đồng bộ dữ liệu với LadiSales. Giúp bạn dễ dàng tương tác với khách hàng của mình thông qua các kênh tương tác khác nhau như Email, SMS...',
    },
    {
        category: integrationCategoryKeys.ecommerce,
        type: appConfig.FORM_TYPE.NHANH.CODE,
        name: appConfig.FORM_TYPE.NHANH.NAME,
        isShowCustomer: true,
        icon: iconIntegration.NHANH,
        description: appConfig.FORM_TYPE.NHANH.DESCRIPTION,
        content:
            'Nhanh.vn là phần mềm quản lý bán hàng với tính năng khá toàn diện. Phần mềm hỗ trợ người dùng đồng bộ shop bán hàng trên các sàn thương mại điện tử. Đồng thời cung cấp các tính năng quản lý đơn hàng, sản phẩm, tồn kho, vận chuyển.',
        iconNegative: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-nhanh.svg',
    },
    {
        category: integrationCategoryKeys.email,
        type: appConfig.FORM_TYPE.GET_RESPONSE.CODE,
        name: appConfig.FORM_TYPE.GET_RESPONSE.NAME,
        isShowCustomer: true,
        icon: iconIntegration.GETRESPONSE,
        description: appConfig.FORM_TYPE.GET_RESPONSE.DESCRIPTION,
        content:
            'LadiSales có thể nhận dữ liệu khách hàng từ Getresponse để tương tác và cũng đồng bộ dữ liệu khách hàng từ LadiSales về Getresponse.',
    },
    {
        category: integrationCategoryKeys.email,
        type: appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE,
        name: appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.NAME,
        isShowCustomer: true,
        icon: iconIntegration.ACTIVE_CAMPAIGN,
        description: appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.DESCRIPTION,
        content:
            'LadiSales có thể nhận dữ liệu khách hàng từ ActiveCampaign để tương tác và cũng đồng bộ dữ liệu khách hàng từ LadiSales về ActiveCampaign.',
    },
    {
        category: integrationCategoryKeys.crm,
        type: appConfig.FORM_TYPE.HUBSPOT.CODE,
        name: appConfig.FORM_TYPE.HUBSPOT.NAME,
        isShowCustomer: true, // chờ update auth sau
        icon: iconIntegration.HUBSPOT,
        description: appConfig.FORM_TYPE.HUBSPOT.DESCRIPTION,
        content:
            'HubSpot là phần mềm tiếp thị và công cụ quản lý tương tác khách hàng (CRM). Tích hợp Hubspot để nhận và đồng bộ dữ liệu với LadiSales. Giúp bạn dễ dàng tương tác với khách hàng của mình thông qua các kênh tương tác khác nhau như Email, SMS...',
        iconNegative: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-hubspot.svg',
    },
    {
        category: integrationCategoryKeys.email,
        type: appConfig.FORM_TYPE.CONVERTKIT.CODE,
        name: appConfig.FORM_TYPE.CONVERTKIT.NAME,
        isShowCustomer: true,
        icon: iconIntegration.CONVERTKIT,
        description: appConfig.FORM_TYPE.CONVERTKIT.DESCRIPTION,
        content:
            'LadiSales có thể nhận dữ liệu khách hàng từ ActiveCampaign để tương tác và cũng đồng bộ dữ liệu khách hàng từ LadiSales về ActiveCampaign.',
    },
    {
        category: integrationCategoryKeys.email,
        type: appConfig.FORM_TYPE.MAIL_CHIMP.CODE,
        name: appConfig.FORM_TYPE.MAIL_CHIMP.NAME,
        isShowCustomer: true,
        icon: iconIntegration.MAIL_CHIMP,
        description: appConfig.FORM_TYPE.MAIL_CHIMP.DESCRIPTION,
        content:
            'LadiSales có thể nhận dữ liệu khách hàng từ MailChimp để tương tác và cũng đồng bộ dữ liệu khách hàng từ LadiSales về MailChimp.',
    },
];
