import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from './Modal';
import Input from './Input';

// eslint-disable-next-line max-lines-per-function
let CDN = 'https://w.ladicdn.com/';
function ModalAddDomain(props) {
    const { t } = useTranslation();
    const { onClose, isShowModal, submit, isSubmitLoading, type = 'PAGE_CHECKOUT_PRODUCT' } = props;
    const [domain, setDomain] = useState({
        domain: '',
        types: '',
    });

    useEffect(() => {
        if (!type) {
            setDomain((prevState) => ({
                ...prevState,
                types: type,
            }));
        }
    }, [type]);

    const onChangeDomain = (event) => {
        let { name, value } = event.target;
        setDomain((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    return (
        <Modal
            id='modal-domain'
            title={t('DOMAIN.ADD')}
            visible={isShowModal}
            onCancel={onClose}
            onOk={submit}
            isLoading={isSubmitLoading}
            bodyClassName='position-initial'
            okText={t('DOMAIN.CREATE')}
            width={600}
        >
            <div className='ladiui form-group'>
                <label className='ladiui-label new'>{t('DOMAIN.NAME')}</label>
                <Input
                    name='domain'
                    validationName={t('STORES.LB_DOMAIN')}
                    placeholder={`${t('STORES.PL_DOMAIN')}`}
                    value={domain.domain}
                    onChange={(event) => onChangeDomain(event)}
                    validations={{ isDomain: true }}
                />
            </div>
            {/* <div className='ladiui form-group'>
                <label className='ladiui-label new'>{'Loại tên miền'}</label>
                <Dropdown
                    data={appConfig.DOMAIN_TYPE}
                    onSelectItem={(data) => {
                        handleChangDomainType(data);
                    }}
                    currentKey={domain.types}
                    wrapperClassName='position-initial w100'
                    idBlock='dropdown-domain-custom'
                    _key='CODE'
                    _value='NAME'
                    placeHolder={'Chọn loại'}
                    // dropup={dropup}
                />
            </div> */}
        </Modal>
    );
}

export default ModalAddDomain;
