import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes } from 'lodash';

ModalPaypal.propTypes = {
    onCancel: PropTypes.func,
    paymentGatewayConfigured: PropTypes.any,
};

export default function ModalPaypal(props) {
    const {
        paymentGatewayConfigured,
        onCancel,
        upDateDataCofigPayment,
        connect,
        isSubmitLoading,
        onchangeDataUpdatePayPal,
        paypalRef,
        payPalAccount,
        onChangePaypalInput,
        payPalMode,
        setPaypalModal,
        dataUpdatePayPal,
        logo,
    } = props;

    const { t } = useTranslation();
    return (
        <Modal
            id='modal-paypal-pay'
            title={!paymentGatewayConfigured ? t('PAYMENTS.SET_UP_PAYMENT_METHOD') : t('PAYMENTS.DETAIL_PAYMENT_METHOD')}
            onCancel={onCancel}
            hasFooter={true}
            onOk={
                !paymentGatewayConfigured
                    ? () => connect(appConfig.PAYMENT_PARTNERS.PAYPAL.CODE)
                    : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.PAYPAL.CODE, paymentGatewayConfigured)
            }
            visible={true}
            width={1000}
            logo={logo}
            bodyStyles={{ minHeight: '40vh' }}
        >
            <div className='content mt-0'>
                {paymentGatewayConfigured ? (
                    <React.Fragment>
                        <React.Fragment>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => paypalRef.add(ref)}
                                        value={dataUpdatePayPal.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(e) => onchangeDataUpdatePayPal(e)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.PAYPAL.CLIENT_ID')}</label>
                                    <Input value={paymentGatewayConfigured.config.client_id} disabled={true} />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.PAYPAL.CLIENT_SECRET')}</label>
                                    <Input value={paymentGatewayConfigured.config.client_secret} disabled={true} />
                                </div>

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        defaultValue={paymentGatewayConfigured.config.payment_guide}
                                        onChange={(e) => onchangeDataUpdatePayPal(e)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                                            disabled={true}
                                        />
                                        <label htmlFor='paypal_mode'> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => paypalRef.add(ref)}
                                        value={payPalAccount.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(event) => onChangePaypalInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.PAYPAL.CLIENT_ID')}</label>
                                    <Input
                                        ref={(ref) => paypalRef.add(ref)}
                                        value={payPalAccount.client_id}
                                        validationName={t('PAYMENTS.PAYPAL.CLIENT_ID')}
                                        name='client_id'
                                        onChange={(event) => onChangePaypalInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.PAYPAL.CLIENT_SECRET')}</label>
                                    <Input
                                        ref={(ref) => paypalRef.add(ref)}
                                        value={payPalAccount.client_secret}
                                        validationName={t('PAYMENTS.PAYPAL.CLIENT_SECRET')}
                                        name='client_secret'
                                        onChange={(event) => onChangePaypalInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        value={payPalAccount.payment_guide}
                                        onChange={(event) => onChangePaypalInput(event)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            id='paypal_mode'
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            onChange={() => setPaypalModal(!payPalMode)}
                                            checked={payPalMode == appConfig.MODE.TEST}
                                        />
                                        <label htmlFor='paypal_mode'> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}
