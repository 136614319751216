import React, { useState, useRef, useMemo, useEffect } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';

import * as productVariantTypes from '../../../../redux/futures/product_variant/types';
import productVariantAction from '../../../../redux/futures/product_variant/actions';

import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import LoadingTable from '../../../../components/LoadingTable';
import OrderDefined from '../../OrderDefined';
import NumberInput from '../../../../components/NumberInput';

function ProductSearch(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { selectVariant, isNotShowBtnCreateProduct, displayProductType, parentID, searchType } = props;

    const storeReducer = useSelector((state) => state.store);
    const variantReducer = useSelector((state) => state.variant);

    const variants = useMemo(() => {
        return variantReducer.variants || [];
    }, [variantReducer.variants]);

    const variantLoading = useMemo(() => {
        return variantReducer.loading || false;
    }, [variantReducer.loading]);

    const [productSearch, setProductSearch] = useState('');
    const [isShowVariants, setShowVariant] = useState(false);
    const [isShowProductCustom, setShowProductCustom] = useState(false);
    const [customVariant, setCustomVariant] = useState({});
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        if (variantReducer.action == productVariantTypes.SEARCH_VARIANT) {
            if (variantReducer.status) {
                if (totalPage != variantReducer.totalPage) setTotalPage(variantReducer.totalPage);

                if (variantReducer.paged != page) {
                    setPage(variantReducer.paged);
                }
            }
        }
    }, [variantReducer.action]);

    useEffect(() => {
        let getData = setTimeout(() => {
            setPage(1);
            let payload = {
                search: productSearch,
                ignore_product_ids: parentID ? [parentID] : [],
                product_type: displayProductType ? displayProductType : '',
                search_type: searchType ? searchType : '',
            };
            dispatch(productVariantAction.search(payload));
        }, 800);
        return () => clearTimeout(getData);
    }, [productSearch, displayProductType, parentID, searchType]);

    const onChangePage = (_page) => {
        setPage(_page);
        let payload = {
            search: productSearch,
            paged: _page,
            ignore_product_ids: parentID ? [parentID] : [],
            product_type: displayProductType ? displayProductType : '',
            search_type: searchType ? searchType : '',
        };
        dispatch(productVariantAction.search(payload));
    };

    const searchFocus = (e) => {
        setShowVariant(true);
    };

    const searchBlur = (e) => {
        setShowVariant(false);
    };

    const productVariantSearch = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        let payload = {
            search: productSearch,
            paged: page,
            ignore_product_ids: parentID ? [parentID] : [],
            product_type: displayProductType ? displayProductType : '',
            search_type: searchType ? searchType : '',
        };
        dispatch(productVariantAction.search(payload));
    };

    const createNewProduct = () => {
        if (baseHelper.isEmpty(customVariant.name)) {
            window.LadiUI.toastCustom('danger', '', 'Chưa nhập tên sản phẩm');
            return;
        }

        if (baseHelper.isEmpty(customVariant.price)) {
            window.LadiUI.toastCustom('danger', '', 'Chưa nhập giá sản phẩm');
            return;
        }

        if (baseHelper.isEmpty(customVariant.quantity)) {
            window.LadiUI.toastCustom('danger', '', 'Chưa nhập số lượng sản phẩm');
            return;
        }

        selectVariant(customVariant, Number(customVariant.quantity));
        setShowProductCustom(false);
        setCustomVariant({});
    };

    const onChangeCustomVariant = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCustomVariant((pre) => {
            let tg = { ...pre };
            tg[name] = value;
            return tg;
        });
    };

    return (
        <>
            {/* modal tạo sản phẩm tuỳ chỉnh */}
            {isShowProductCustom && (
                <Modal
                    id='modal-new-product-custom'
                    title={t('PRODUCTS.PRODUCT_CUSTOM_TITEL')}
                    visible={isShowProductCustom}
                    onCancel={() => setShowProductCustom(false)}
                    onOk={createNewProduct}
                    width={600}
                    okText={t('PRODUCTS.ADD_TITLE')}
                    cancelText={t('ACTIONS.REMOVE')}
                >
                    <div className='info'>
                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui text-14'>{t('PRODUCTS.NAME')}</label>
                            <Input
                                className='mt-4'
                                name='name'
                                onChange={onChangeCustomVariant}
                                value={customVariant.name}
                                validationName={t('PRODUCTS.NAME')}
                                validations={{ isRequired: true }}
                                // ref={(ref) => this.formProductCreateRef.add(ref)}
                            />
                        </div>
                        <div className='mt-16 display-flex-center gap-16'>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui text-14'>{t('PRODUCTS.PRICE')}</label>
                                <NumberInput
                                    className='mt-4'
                                    name='price'
                                    onChange={onChangeCustomVariant}
                                    value={customVariant.price}
                                    prefix={storeReducer.userInfo.currentStore.currency_symbol}
                                />
                            </div>
                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui text-14'>{t('COMMON.QUANTITY')}</label>

                                <Input
                                    className='mt-4'
                                    name='quantity'
                                    type='number'
                                    onChange={onChangeCustomVariant}
                                    value={customVariant.quantity}
                                    validationName={t('COMMON.QUANTITY')}
                                    validations={{ isRequired: true }}
                                    // ref={(ref) => this.formProductCreateRef.add(ref)}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            {isShowVariants && <div className='ladiui backdrop' onClick={searchBlur} />}
            <div className='ladiui search-group product-search' style={{ zIndex: 15 }}>
                <i className='ladiui icon icon-search' onClick={productVariantSearch}></i>
                <input
                    id='keyword_search'
                    className='ladiui search-field dropdown-toggle form-control search-width'
                    name='searchName'
                    placeholder={t('PRODUCTS.SEARCH_PRODUCT')}
                    aria-expanded='false'
                    value={productSearch}
                    onFocus={searchFocus}
                    // onBlur={searchBlur}
                    onChange={(e) => {
                        setProductSearch(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            productVariantSearch();
                        }
                    }}
                />
                <div className={`product-variant-search ${isShowVariants ? 'show' : ''}`}>
                    {!isNotShowBtnCreateProduct && (
                        <div
                            className='product-custom-btn'
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowProductCustom(true);
                                searchBlur();
                            }}
                        >
                            <i className='new-ldicon-add-circle' />{' '}
                            <span className='ladiui text-14-brand'>{t('PRODUCTS.PRODUCT_CUSTOM_TITEL')}</span>
                        </div>
                    )}

                    <div className='product-variant-list'>
                        {variantLoading ? (
                            <LoadingTable />
                        ) : (
                            variants.map((item, index) => {
                                let imageSrc = baseHelper.getImageUrl(item.src);
                                let optionNames = item.options ? item.options.map((option) => option.option_value_value) : [];
                                let optionName = optionNames.join(', ');
                                let isNotOpenForSale = baseHelper.isNotOpenForSale(item);
                                let isSoldOut = baseHelper.isSoldOut(item);
                                let restQuantity = OrderDefined.getRestQuantity(item);

                                // if (!baseHelper.isAvailableVariant(item)) return null;

                                return (
                                    <div
                                        className={`product-variant-item ${isNotOpenForSale || isSoldOut ? 'disable' : ''}`}
                                        key={index}
                                        onClick={() => {
                                            if (isNotOpenForSale || isSoldOut) return;
                                            searchBlur();
                                            selectVariant(item);
                                        }}
                                    >
                                        <div className='image-name-option display-flex-center'>
                                            <img src={imageSrc} alt='variant-iamge' className='mr-8' />
                                            <div className='name-option '>
                                                <div className='variant-name ladiui label-16'>{item.name}</div>
                                                <div className='variant-option mt-4 ladiui text-14'>{optionName}</div>
                                            </div>
                                        </div>
                                        <div className='variant-price-stock'>
                                            <div className='ladiui flex-column-end'>
                                                <div className='variant-price ladiui label-16'>
                                                    {baseHelper.formatMoneyPostFix(
                                                        item.price,
                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </div>
                                                <div className='mt-4'>
                                                    {isNotOpenForSale ? (
                                                        <span className='ladiui text-danger'>{t('PRODUCTS.OVER_TIME')}</span>
                                                    ) : isSoldOut ? (
                                                        <span className='ladiui text-danger'>{t('PRODUCTS.SOLD_OUT')}</span>
                                                    ) : (
                                                        restQuantity > 0 && (
                                                            <div>
                                                                <span className='ladiui text-14'>{t('PRODUCTS.TOTAL_QUANTITY')} </span>
                                                                {': '}
                                                                <span className='ladiui text-14 text-primary'>
                                                                    {baseHelper.formatNumber(restQuantity)}
                                                                </span>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>

                    <div className='variant-page footer'>
                        <i
                            className={`ldicon-chevron-left ${page == 1 ? 'disable' : ''}`}
                            onClick={() => {
                                if (page == 1) return;
                                onChangePage(page - 1);
                            }}
                        />

                        <i
                            className={`ldicon-chevron-right ${page >= totalPage ? 'disable' : ''}`}
                            onClick={() => {
                                if (page >= totalPage) return;
                                onChangePage(page + 1);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProductSearch;
