import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.DELETE_OPTION: {
      return {
        ...state,
        action: types.DELETE_OPTION,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_UPDATE_OPTION: {
      return {
        ...state,
        action: types.CREATE_UPDATE_OPTION,
        status: action.status,
        message: action.message,
      };
    }
    case types.SAVE_DATA_OPTION_NAME: {
      return {
        ...state,
        action: types.SAVE_DATA_OPTION_NAME,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
