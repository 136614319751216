import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';
import SelectOption from '../../../components/SelectOption';
import LoadingTable from '../../../components/LoadingTable';

import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";

import reportActions from "../../../redux/futures/report/actions";
import * as reportTypes from "../../../redux/futures/report/types";

export default function ReportByUTM(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const reportReducer = useSelector((state) => state.report);
    const storeReducer = useSelector((state) => state.store);

    const [isLoading, setIsLoading] = useState(false);
    const [selectedUTM, setSelectedUTM] = useState(0)

    const [utmSource, setUtmSource] = useState([]);
    const [utmData, setUtmData] = useState([]);
    const [utmCampaign, setUtmCampaign] = useState([]);
    const [utmTerm, setUtmTerm] = useState([]);
    const [utmContent, setUtmContent] = useState([]);
    const [utmMedium, setUtmMedium] = useState([]);

    const listUTM = [
        {
            name: t('REPORTS.UTM.SOURCE'),
            value: 0,
        },
        {
            name: t('REPORTS.UTM.CAMPAIGN'),
            value: 1,
        },
        {
            name: t('REPORTS.UTM.TERM'),
            value: 2,
        },
        {
            name: t('REPORTS.UTM.MEDIUM'),
            value: 3,
        },
        {
            name: t('REPORTS.UTM.CONTENT'),
            value: 4,
        },
    ];

    useEffect(() => {
        if (reportReducer.action == reportTypes.OVERVIEW_REPORT) {
            if (reportReducer.status) {
                let dataUTM = reportReducer.data.revenue_utm;
                if (dataUTM) {
                    setUtmSource(dataUTM.rp_utm_source || []);
                    setUtmData(dataUTM.rp_utm_source || []);
                    setUtmCampaign(dataUTM.rp_utm_campaign || []);
                    setUtmTerm(dataUTM.rp_utm_term || []);
                    setUtmContent(dataUTM.rp_utm_content || []);
                    setUtmMedium(dataUTM.rp_utm_medium || []);
                }

            } else {
                window.LadiUI.showErrorMessage('Thông báo', reportReducer.message, 'OK');
            }
        }
    }, [reportReducer])

    useEffect(() => {
        switch (selectedUTM) {
            case 0:
                setUtmData(utmSource);
                break;
            case 1:
                setUtmData(utmCampaign);
                break;
            case 2:
                setUtmData(utmTerm);
                break;
            case 3:
                setUtmData(utmMedium);
                break;
            case 4:
                setUtmData(utmContent);
                break;

            default:
                break;
        }
    }, [selectedUTM])


    const getOption = () => {
        if (!utmData || utmData.length === 0) return [];
        let dataChart = [];

        for (const item of utmData) {
            if (!item.refund)
                item.refund = 0;

            if (!item.utm_name) {
                item.utm_name = t("REPORTS.UTM.NULL")
            }

            if (dataChart.length < 9) {
                dataChart.push({
                    name: item.utm_name,
                    y: item.total - item.refund,
                    num_order: item.num_order
                })
            } else if (dataChart.length === 9) {
                dataChart.push({
                    name: t("REPORTS.UTM.ORTHER"),
                    y: item.total - item.refund,
                    num_order: item.num_order
                })
            } else {
                dataChart[9].y += item.total - item.refund
                dataChart[9].num_order += item.num_order
            }
        }

        let options = {
            chart: {
                type: "pie"
            },
            title: {
                text: ""
            },
            subtitle: {
                text: ""
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    showInLegend: true
                },
            },

            legend: {
                display: "flex"
            },
            tooltip: {
                valueSuffix: "đ",
                useHTML: true,
                formatter: function () {
                    let text = ``;
                    text += `
                        <br/>
                        <div class="text-tooltip">  
                        <div class="point-tolltip" style="background:${this.point.color}"></div> 
                        <div>
                            ${t("REPORTS.UTM.TABLE.REVENUE")}: ${baseHelper.formatMoneyPostFix(this.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
                        </div> 
                        </div>
                        <br/>
                        <div class="text-tooltip">  
                        <div class="point-tolltip" style="background:${this.point.color}"></div> 
                        <div>
                        ${t("REPORTS.UTM.TABLE.NUM")}: ${baseHelper.formatNumber(this.point.num_order)}
                        </div> 
                        </div>
                        `
                    return (
                        `${this.point.name}
                    <br/>
                        ${text}`
                    );
                },
            },
            series: [
                {
                    name: "Doanh thu",
                    data: dataChart,
                    size: "90%",
                    innerSize: "80%",
                    dataLabels: {
                        formatter: function () {
                            return this.y > 5 ? this.point.name : null;
                        },
                        color: "#ffffff",
                        distance: -30
                    }
                }
            ],
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: '100%'
                        },
                        chartOptions: {
                            series: [
                                {},
                                {
                                    id: "versions",
                                    dataLabels: {
                                        enabled: true
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        };
        return options;
    }

    return (
        <div className='block-content-item mt-20'>
            <div className="chart-item revenue-stage-chart" id='report-by-utm-new'>
                <div className='flex items-center space-between'>
                    <h3>Doanh thu theo UTM</h3>
                    <SelectOption
                        wrapperStyle={{ minWidth: 150 }}
                        items={listUTM}
                        _key='value'
                        _value='name'
                        currentKey={selectedUTM}
                        onSelectItem={(item) => {
                            setSelectedUTM(item.value);
                        }}
                        innerStyle={{ border: "none" }}
                    />
                </div>
                <div className="content-chart">{!isLoading ? <PieChart highcharts={Highcharts} options={getOption()} containerProps={{ style: { height: "300px" } }} /> : <LoadingTable />}</div>
            </div>
        </div>
    );
}
