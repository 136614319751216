import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import baseHelper from '../../../../helpers/BaseHelper';
import appConfig from '../../../../config/app';

import Tag from '../../../../components/Tag';
import NumberInput from '../../../../components/NumberInput';
import Input from '../../../../components/Input';

import actionsProductTag from '../../../../redux/futures/product_tag/actions';

import actionsProduct from '../../../../redux/futures/product/actions';
import * as productTypes from '../../../../redux/futures/product/types';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import * as productCategoryTypes from '../../../../redux/futures/product_category/types';
import productCategoryActions from '../../../../redux/futures/product_category/actions';

import productImageActions from '../../../../redux/futures/product_image/actions';
import * as productImageTypes from '../../../../redux/futures/product_image/types';

import storePageActions from '../../../../redux/futures/store_page/actions';
import * as storePageTypes from '../../../../redux/futures/store_page/types';

import * as productTypeRedux from '../../../../redux/futures/product/types';
import actionsStore from '../../../../redux/futures/store/actions';
import { Editor } from '@tinymce/tinymce-react';
import { map, compact, cloneDeep, find, pick, remove, includes } from 'lodash';

import Dropdown from '../../../../components/Dropdown';
import SelectOption from '../../../../components/SelectOption';

import SelectManyDropdown from '../../../../components/SelectManyDropdown';
import SelectManyDropdownStore from '../../../../components/SelectManyDropdownStore';

import ListImageProduct from '../../../../components/ListProductImageV2';
// import PanelCreateVariant from './PanelCreateVariantV4';

import ProductSearchV4 from '../../../../components/ProductSearchV4';
import ProductSearch from '../../../orders/OrderCreate/components/ProductSearch';
import PanelProductSellSocial from '../../../../components/PanelProductSeoSocial';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ConfirmModal from '../../../../components/ConfirmModal';

// import PanelEditVariant from '../ModalEdit/components/PanelEditVariantV4';

import PanelCreateVariant from './PanelCreateVariant';
import PanelEditVariant from '../ModalEdit/components/PanelEditVariant';

import PanelCustomField from '../../../../components/PanelCustomField';

import { ProductTags } from '../ProductTags';

import PanelProductUpsellV4 from '../../../../components/PanelProductUpsellV4';
import LoadingScene from '../../../../components/LoadingScene';

import produce from 'immer';
import config from '../../../../config/config';
import PanelPageProduct from '../../../../components/PanelPageProduct';

// function useQuery() {
//     const { search } = useLocation();

//     return React.useMemo(() => new URLSearchParams(search), [search]);
// }

const CDN = 'https://w.ladicdn.com/';

function ProductCreate(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { t, i18n, onClick, info } = props;

    // const location = useLocation();
    // const queryStrings = new URLSearchParams(location.search);

    const { productID } = useParams();

    const listProduct = [appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.F_B.CODE, appConfig.PRODUCT_TYPE.COMBO.CODE];

    const tagRef = useRef();
    const productNameRef = useRef();
    const productTagRef = useRef();
    const panelCreateVariantRef = useRef();
    const panelEditVariantRef = useRef();
    const panelSeoSocialRef = useRef();
    const panelCustomFieldRef = useRef();
    const highlightProductRef = useRef();
    const inputsRef = new Set();

    const productCategoryReducer = useSelector((state) => state.productCategory);
    const storePageReducer = useSelector((state) => state.storePage);
    const productImageReducer = useSelector((state) => state.productImage);
    const storeReducer = useSelector((state) => state.store);
    const fileReducer = useSelector((state) => state.file);
    const productReducer = useSelector((state) => state.product);

    const [isLoading, setLoading] = useState(false);
    const [isEditProduct, setIsEditProduct] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [selectedImage, setSelectedImage] = useState('');

    const [selectedTabIndex, setSelectedTabIndex] = useState(1);

    const [product, setProduct] = useState({
        product_id: '',
        product_variant_id: '',
        name: '',
        price: 0,
        description: '',
        short_description: '',
        display_type: 0,
        price_compare: 0,
        cost_per_item: 0,
        cta: 0,
        selectedIconCTA: 'cta-add',
        quantity: 0,
        min_buy: 1,
        max_buy: '',
        is_one_time: false,
        sku: '',
        weight_unit: '',
        weight: '',
        inventory_checked: appConfig.INVENTORY_STATUS.INACTIVE.CODE,
        allow_sold_out: appConfig.STATUS.INACTIVE,
        external_link: '',
        seo: {},
        status: appConfig.PRODUCT_STATUS.ACTIVE.CODE,
        page_checkout: null,
        url_published: '',
        type: '',
        highlight_display: {},
        quantity_sold: 0,
    });

    const [selectedProductUpsells, setSelectedProductUpsells] = useState([]);

    const [variants, setVariants] = useState([]);
    const [options, setOptions] = useState([]);

    const [images, setImages] = useState([]);
    const [customFields, setCustomFields] = useState([]);

    const [productTags, setProductTags] = useState([]);

    const [valid, setValid] = useState({
        name: true,
    });

    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [isShowAllStore, setIsShowAllStore] = useState(false);

    const [isShowPriceCompare, setIsShowPriceCompare] = useState(false);
    const [isShowLimitQtyProduct, setIsShowLimitQtyProduct] = useState(false);
    const [isShowWarehouseSetting, setIsShowWarehouseSetting] = useState(false);
    const [isShowExternalLink, setIsShowExternalLink] = useState(false);

    const [selectedTypeProduct, setSelectedTypeProduct] = useState('');
    const [isCopyProduct, setIsCopyProduct] = useState(false);

    const listAllTags = useSelector((state) => {
        if (state.productTag.action === 'ASYNC_END' && state.productTag.allTags) return state.productTag.allTags;
        else return [];
    });

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });

    useEffect(() => {
        let payload = {
            search: {
                type: 'Product',
            },
        };

        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        let type = queryURL.get('type');
        if (!type || !listProduct.includes(type)) {
            type = appConfig.PRODUCT_TYPE.PHYSICAL.CODE;
        }

        if (productID && queryURL.get('is_copy')) {
            dispatch(actionsProduct.show(productID));
            setIsCopyProduct(true);
            setIsEditProduct(false);
        }

        if (productID && !queryURL.get('is_copy')) {
            dispatch(actionsProduct.show(productID));
            setIsCopyProduct(false);
            setIsEditProduct(true);
        }

        setSelectedTypeProduct(type);

        dispatch(storePageActions.storePageList(dataListStore));
        dispatch(actionsProductTag.listAll());
        dispatch(productCategoryActions.listSelectCategory(payload));
    }, []);

    useEffect(() => {
        if (productReducer.action == productTypes.SHOW_PRODUCT) {
            if (productReducer.status) {
                let _dataProduct = { ...productReducer.product };
                setProductTags(_dataProduct.tags || []);
                setSelectedTypeProduct(_dataProduct.type);
                if (isCopyProduct) {
                    initCreateProduct(_dataProduct);
                } else {
                    initUpdateProduct(_dataProduct);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.RELOAD_PRODUCT) {
            if (productReducer.status) {
                let _dataProduct = { ...productReducer.product };
                setProductTags(_dataProduct.tags || []);
                initUpdateProduct(_dataProduct);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }

        if (productReducer.action == productTypes.CREATE_PRODUCT) {
            if (productReducer.status) {
                window.LadiUI.toastCustom('success', '', productReducer.message);
                window.open(baseHelper.getUrlProduct(productReducer.product.type, productReducer.product.product_id), '_parent');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.UPDATE_PRODUCT) {
            if (productReducer.status) {
                window.LadiUI.toastCustom('success', '', productReducer.message);
                dispatch(actionsProduct.reload(product.product_id));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    const getIconCTA = (code) => {
        const matched = find(appConfig.LIST_CTA, (item) => item.CODE == code) || {};
        if (matched) {
            return matched.CLASS_ICON;
        }
        return 'cta-add';
    };

    const initCreateProduct = async (props) => {
        let currentProductInfo = cloneDeep(props);
        currentProductInfo = await baseHelper.normalizeCopyProduct(currentProductInfo);

        const customFields = currentProductInfo.custom_fields || [];
        delete currentProductInfo.path;
        delete currentProductInfo.url_published;
        let { seo } = currentProductInfo;
        let _product = { ...currentProductInfo };

        let newProduct = {};

        map(product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(_product[key]) ? _product[key] : '';
            if (key == 'min_buy' && baseHelper.isEmpty(_product[key])) {
                newProduct[key] = 1;
            }
            if (key == 'seo' && baseHelper.isEmpty(_product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        if (newProduct.external_link) {
            setIsShowExternalLink(true);
        }

        if (newProduct.max_buy && newProduct.max_buy > 0) {
            setIsShowLimitQtyProduct(true);
        }

        if (newProduct.price_compare && newProduct.price_compare > 0) {
            setIsShowPriceCompare(true);
        }

        let isCheckedWareHouse = checkWareHouseStatus(newProduct);

        setIsShowWarehouseSetting(isCheckedWareHouse);

        const newCustomFields = !baseHelper.isEmpty(customFields) ? customFields : [];

        if (currentProductInfo.is_show_all_store && currentProductInfo.is_show_all_store > 0) {
            setIsShowAllStore(true);
        } else {
            setIsShowAllStore(false);
        }
        setProduct(newProduct);
        setImages(currentProductInfo.images || []);
        setVariants(currentProductInfo.variants || []);
        setOptions(currentProductInfo.options || []);
        setCustomFields(newCustomFields || {});
        setSelectedProductUpsells(currentProductInfo.product_up_sells || []);

        setSelectedStores(currentProductInfo.page_checkout_ids || []);
        setProductTags(currentProductInfo.tags || []);
        setSelectedCategories(currentProductInfo.product_category_ids || []);
    };

    const checkWareHouseStatus = (dataProduct) => {
        let status = false;
        if (dataProduct.sku || dataProduct.weight || dataProduct.inventory_checked) {
            status = true;
        }

        return status;
    };

    const initUpdateProduct = (props) => {
        let currentProductInfo = cloneDeep(props);

        let _customFields = currentProductInfo.custom_fields || [];
        // let { tags, images, seo } = currentProductInfo;
        let _images = currentProductInfo.images || [];
        let _tags = currentProductInfo.tags || [];
        let _seo = currentProductInfo.seo || {};
        let _options = currentProductInfo.options || [];

        let _variants = currentProductInfo.variants || [];

        let _productUpSells = currentProductInfo.product_up_sells || [];

        delete currentProductInfo.tags;
        delete currentProductInfo.options;
        delete currentProductInfo.variants;
        delete currentProductInfo.images;
        delete currentProductInfo.relate_products;
        delete currentProductInfo.product_up_sells;

        let _product = { ...currentProductInfo };

        if (currentProductInfo.product_category_ids && currentProductInfo.product_category_ids.length > 0) {
            // this.setState({
            //     product_category_ids: currentProductInfo.product_category_ids,
            // });
            setSelectedCategories(currentProductInfo.product_category_ids);
        }

        if (currentProductInfo.page_checkout_ids) {
            setSelectedStores(currentProductInfo.page_checkout_ids);
        }

        if (currentProductInfo.tags && currentProductInfo.tags.length > 0) {
            setProductTags(currentProductInfo.tags);
        }

        if (currentProductInfo.is_show_all_store && currentProductInfo.is_show_all_store > 0) {
            setIsShowAllStore(true);
        } else {
            setIsShowAllStore(false);
        }

        let newProduct = {};
        map(product, (value, key) => {
            newProduct[key] = !baseHelper.isEmpty(_product[key]) ? _product[key] : '';
            if (key == 'path' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = '' + _product.product_id;
            }
            if (key == 'cta' && baseHelper.isEmpty(newProduct[key])) {
                newProduct[key] = 0;
            }

            if (key == 'selectedIconCTA' && baseHelper.isEmpty(_product['cta'])) {
                newProduct[key] = 'cta-add';
            }

            if (key == 'selectedIconCTA' && !baseHelper.isEmpty(_product['cta'])) {
                newProduct['selectedIconCTA'] = getIconCTA(_product['cta']);
            }

            if (key == 'seo' && baseHelper.isEmpty(_product[key])) {
                newProduct['seo'] = {
                    title: '',
                    description: '',
                    keywords: '',
                    image: '',
                    canonical_url: '',
                };
            }
        });

        // Assign default variant to product
        if (_options.length <= 0) {
            newProduct = {
                ...newProduct,
                ...pick(_variants[0], [
                    'product_variant_id',
                    'price',
                    'sku',
                    'price_compare',
                    'cost_per_item',
                    'weight',
                    'weight_unit',
                    'quantity',
                    'min_buy',
                    'max_buy',
                    'display_type',
                    'inventory_checked',
                    'allow_sold_out',
                ]),
            };

            _variants.length = 0;
        }

        if (newProduct.external_link) {
            setIsShowExternalLink(true);
        }
        if (newProduct.max_buy && newProduct.max_buy > 0) {
            setIsShowLimitQtyProduct(true);
        }

        if (newProduct.price_compare && newProduct.price_compare > 0) {
            setIsShowPriceCompare(true);
        }

        let isCheckedWareHouse = checkWareHouseStatus(newProduct);

        setIsShowWarehouseSetting(isCheckedWareHouse);

        const newCustomFields = !baseHelper.isEmpty(_customFields) ? _customFields : [];

        const newTags = _tags || [];
        const newVariants = _variants || [];
        const newImages = _images || [];
        const newOptions = _options || [];
        const newProductUpSells = _productUpSells || [];

        setProduct({ ...newProduct });
        setVariants(newVariants);
        setImages(newImages);
        setOptions(newOptions);
        setSelectedProductUpsells(newProductUpSells);
        setCustomFields(newCustomFields);
    };

    useEffect(() => {
        if (productImageReducer.action == productImageTypes.PRODUCT_IMAGE_CREATE) {
            if (productImageReducer.status) {
                let _images = [...images];
                _images.push(...productImageReducer.images);

                setImages(_images);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }
        if (productImageReducer.action == productImageTypes.PRODUCT_IMAGE_DELETE) {
            if (productImageReducer.status) {
                let _images = [...images];

                remove(_images, (image) => image.src_hash == selectedImage.src_hash);

                setImages(_images);
                setSelectedImage('');
                window.LadiUI.toastCustom('success', '', productImageReducer.message);
                window.LadiUI.closeModal('confirm-image-delete');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }
    }, [productImageReducer]);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_LIST) {
            if (storePageReducer.status) {
                if (storePageReducer.store_pages && storePageReducer.store_pages.length > 0) {
                    let _data = [];
                    let _stores = [];

                    storePageReducer.store_pages.map((item, index) => {
                        _data.push({
                            name: item.name,
                            value: item.page_checkout_id,
                        });

                        _stores.push(item.page_checkout_id);
                    });

                    if (!isEditProduct && !isCopyProduct) setSelectedStores(_stores);

                    setStores(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    let _data = [...categories];
                    productCategoryReducer.listSelects.map((item, index) => {
                        const isExitCategory = _data.some((_item) => _item.value == item.product_category_id);
                        if (!isExitCategory) {
                            _data.push({
                                level: item.level,
                                name: item.name,
                                value: item.product_category_id,
                            });
                        }
                    });

                    setCategories(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_PRODUCT_IMAGES) {
            if (fileReducer.status) {
                const target = fileReducer.target;

                const newUploadedImage = map(fileReducer.product_images, (item, index) => {
                    return {
                        src: item,
                        position: images.length + index,
                    };
                });
                console.log(" target index ", target, '-isEditProduct:', isEditProduct, '-newUploadedImage=', JSON.stringify(newUploadedImage));
                if (!isEditProduct) {
                    let listImage = [...images];
                    listImage.push(...newUploadedImage);
                    setImages(listImage);
                } else {
                    if (target !== false && target >= 0) {
                        if (newUploadedImage.length > 0) {
                            const img = newUploadedImage[0];
                            const updated = images[target];
                            updated.src = img.src;
                            updated.product_id = product.product_id;
                            dispatch(productImageActions.updateSrc(updated));
                        }
                    } else {
                        const productImages = {
                            product_id: product.product_id,
                            images: newUploadedImage,
                        };
                        dispatch(productImageActions.create(productImages));
                    }
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    const handleEditorChange = (value) => {
        setProduct({
            ...product,
            description: value,
        });
    };

    const handleChangeInput = (event) => {
        let { name, value } = event.target;

        setProduct({
            ...product,
            [name]: value,
        });
    };

    const handleSetImage = (_images) => {
        setImages(_images);
    };

    const handleSetListImageProducts = (urlImage) => {
        let _images = [...images];
        const imageItem = [
            {
                src: urlImage,
                position: images.length + 1,
            },
        ];

        // _images.push(imageItem);

        // setImages(_images);

        if (!isEditProduct) {
            let listImage = [...images];
            listImage.push(...imageItem);
            setImages(listImage);
        } else {
            const productImages = {
                product_id: product.product_id,
                images: imageItem,
            };
            dispatch(productImageActions.create(productImages));
        }
    };

    const handleRefreshDataStores = () => {
        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    };

    const handleSearchData = (value) => {
        const dataListStore = {
            keyword: value,
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    };

    const handleChangeCheckAllStore = (checked) => {
        if (!checked) {
            setSelectedStores([]);
        }
        setIsShowAllStore(checked);
    };

    const handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: 'Product',
            },
        };
        dispatch(productCategoryActions.listSelectCategory(payload));
    };

    const onChangeCTA = (item) => {
        setProduct({
            ...product,
            cta: item.CODE,
            selectedIconCTA: item.CLASS_ICON,
        });
    };

    const getVariantReq = (variants) => {
        const {
            product_id,
            product_variant_id,
            price,
            price_compare,
            cost_per_item,
            sku,
            weight,
            weight_unit,
            inventory_checked,
            allow_sold_out,
            quantity,
            min_buy,
            max_buy,
        } = product;

        if (variants.length <= 0) {
            const defaultVariant = {
                product_id,
                product_variant_id,
                price,
                price_compare,
                cost_per_item,
                sku,
                weight,
                weight_unit,
                inventory_checked,
                allow_sold_out,
                quantity,
                min_buy,
                max_buy,
            };

            if (isCopyProduct) {
                delete defaultVariant.product_id;
                delete defaultVariant.product_variant_id;
            }

            variants.push(defaultVariant);
        }

        return variants;
    };

    const submit = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let createData = {};
        let _options = [];

        if (panelCreateVariantRef.current) {
            createData = panelCreateVariantRef.current.getData();
            _options = createData.options;
        }

        if (panelEditVariantRef.current) {
            createData = panelEditVariantRef.current.getData();
            _options = options;
        }
        let highlight_display = {};
        let quantity_sold = 0;
        if (highlightProductRef && highlightProductRef.current) {
            let highlights = highlightProductRef.current.getData();
            highlight_display = highlights.highlight_display;
            quantity_sold = Number(highlights.quantity_sold);
        }

        if (isEditProduct) {
        }

        let payloadSubmit = {};

        const productSeoSocial = panelSeoSocialRef.current.getData();
        // const productUpSells = panelCreateVariantRef.current.getData();

        const productCustomField = panelCustomFieldRef.current.getData();
        const productTagData = productTagRef.current.getData();

        if (productSeoSocial) {
            if (productSeoSocial.title && productSeoSocial.title.length > 70) {
                window.LadiUI.toastCustom('warning', '', 'Tiêu đề vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.description && productSeoSocial.description.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Mô tả vượt quá số ký tự quy định!');
                return;
            }
            if (productSeoSocial.keywords && productSeoSocial.keywords.length > 170) {
                window.LadiUI.toastCustom('warning', '', 'Từ khóa vượt quá số ký tự quy định!');
                return;
            }
        }

        if (_options && _options.length > 0) {
            // let createData = {};
            let _variants = [];

            _variants = createData.variants;
            let newOptions = createData.options;

            payloadSubmit = {
                ...product,
                type: selectedTypeProduct,
                alias_name: baseHelper.getAliasName(product.name),
                options: newOptions,
                variants: _variants,
                custom_fields: productCustomField || [],
                images: images,
                product_category_ids: selectedCategories || [],
                page_checkout_ids: selectedStores || [],
                is_show_coupon: true,
                is_show_all_store: isShowAllStore,
                seo: { ...productSeoSocial },
                product_up_sells: selectedProductUpsells || [],
                tags: productTagData || [],
                highlight_display,
                quantity_sold,
            };
        } else {
            const createData = panelCreateVariantRef.current.getData();

            let _variants = createData.variants;
            let newOptions = createData.options;

            _variants = getVariantReq(_variants);

            payloadSubmit = {
                ...product,
                type: selectedTypeProduct,
                alias_name: baseHelper.getAliasName(product.name),
                options: newOptions,
                // variants: [
                //     {
                //         price: product.price,
                //         price_compare: product.price_compare,
                //         cost_per_item: product.cost_per_item,
                //         sku: '',
                //         weight: '',
                //         weight_unit: 'g',
                //         inventory_checked: 0,
                //         allow_sold_out: 0,
                //         quantity: 0,
                //         min_buy: 1,
                //         max_buy: null,
                //     },
                // ],
                variants: _variants,
                custom_fields: productCustomField || [],
                images: images,
                product_up_sells: selectedProductUpsells || [],
                product_category_ids: selectedCategories || [],
                page_checkout_ids: selectedStores || [],
                is_show_coupon: true,
                is_show_all_store: isShowAllStore,
                seo: { ...productSeoSocial },
                tags: productTagData || [],
                highlight_display,
                quantity_sold,
            };
        }

        if (!isEditProduct) {
            dispatch(actionsProduct.create(payloadSubmit));
        } else {
            dispatch(actionsProduct.update(payloadSubmit));
        }
    };

    const handleSelectProductUpsell = (dataVariant) => {
        // let _selectedProducts = [...selectedProductUpsells];

        // if (find(_selectedProducts, (item) => item.product_id == product.product_id)) {
        //     return;
        // }

        // let productItem = {
        //     name: product.name,
        //     product_id: product.product_id,
        //     product_variant_id: product.product_variant_id,
        //     price: product.price || '',
        //     max_price: product.max_price || '',
        //     min_price: product.min_price || '',
        //     src: product.src || '',
        //     quantity: selectedTypeProduct == appConfig.PRODUCT_TYPE.COMBO.CODE ? 1 : 0

        // }

        // _selectedProducts.push(productItem);
        // setSelectedProductUpsells(_selectedProducts);

        let variant = { ...dataVariant };
        variant.quantity = 1;
        variant.position = 0;

        let _selectedProductUpsells = [...selectedProductUpsells];

        if (find(_selectedProductUpsells, (item) => item.product_variant_id == variant.product_variant_id)) {
            return;
        }

        const newSelectedVariants = produce(_selectedProductUpsells, (draft) => {
            Object.values(draft).forEach((item, index) => {
                item.position = index + 1;
                if (selectedTypeProduct !== appConfig.PRODUCT_TYPE.COMBO.CODE) {
                    item.quantity = 1;
                }
            });

            draft.splice(0, 0, variant);
        });

        setSelectedProductUpsells(newSelectedVariants);
    };

    const handleDeleteProductUpsell = (productVariantID) => {
        let _products = selectedProductUpsells.filter((item) => item.product_variant_id != productVariantID);

        setSelectedProductUpsells(_products);
    };

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);
        list = list.map((item, index) => {
            item.position = index;
            return item;
        });

        return list;
    };

    const onDragEndProductUpSell = (result) => {
        if (!result.destination) {
            return;
        }

        const _products = reorder(selectedProductUpsells, result.source.index, result.destination.index);
        setSelectedProductUpsells(_products);
    };

    const handleDeleteImage = (item) => {
        setSelectedImage(item);
        openConfirmDeleteModal(item);
    };

    const openConfirmDeleteModal = (selectedImage) => {
        // this.selectedImage = selectedImage;

        window.LadiUI.showModal('confirm-image-delete');
    };

    const deleteImageProduct = () => {
        dispatch(productImageActions.delete(product.product_id, selectedImage.src_hash));
    };

    const getVariantPanel = () => {
        const hasNoVariant = variants.length == 0;

        if (hasNoVariant || (!hasNoVariant && isCopyProduct)) {
            return (
                <PanelCreateVariant
                    ref={panelCreateVariantRef}
                    variants={variants}
                    inputsRef={inputsRef}
                    product={product}
                    options={options}
                    images={images}
                />
            );
        }

        return (
            <PanelEditVariant
                ref={panelEditVariantRef}
                product={product}
                variants={variants}
                options={options}
                images={images}
            // openVariantID={this.props.openVariantID}
            />
        );
    };

    const changeQuantityVariant = (value, index) => {
        const newProductUpsells = produce(selectedProductUpsells, (draft) => {
            if (draft[index].quantity + value <= 1) {
                draft[index].quantity = 1;
            } else {
                draft[index].quantity = parseInt(draft[index].quantity) + parseInt(value);
            }
        });
        setSelectedProductUpsells(newProductUpsells);
    };

    const handleChangeQuantityUpsell = (e, index) => {
        let { value } = e.target;
        const _newVariants = selectedProductUpsells.map((item, _index) => {
            if (_index == index) {
                item.quantity = !value || value <= 0 ? 1 : value;
            }

            return item;
        });

        setSelectedProductUpsells(_newVariants);
    };

    const getPriceProduct = (item) => {
        let price = 0;
        if (!item.price) {
            if (item.min_price && item.max_price) {
                if (item.min_price == item.max_price) {
                    price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                } else {
                    price =
                        baseHelper.formatMoneyPostFix(item.min_price, storeInfo.currentStore.currency_symbol) +
                        ' - ' +
                        baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                }
            } else {
                price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
            }
        } else {
            price = baseHelper.formatMoneyPostFix(item.price, storeInfo.currentStore.currency_symbol);
        }

        return price;
    };

    const isLoadingProduct =
        productReducer.loading && includes([productTypes.CREATE_PRODUCT, productTypes.UPDATE_PRODUCT], productReducer.waiting);

    const getUrlPageCheckout = (type, url, page_checkout_id) => {
        let is_iframe = true;

        if (!is_iframe) {
            return url;
        }
        switch (type) {
            case 'CREATE':
                return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_LIST}&query_ls=${JSON.stringify({
                    is_create: true,
                    type_create: appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT,
                    product_id: page_checkout_id,
                })}`;
            case 'UPDATE':
                return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(page_checkout_id)}`;
            default:
                break;
        }
    };

    return (
        <div className='page-product' id='page-product-new'>
            {isLoadingProduct && <LoadingScene blur={true} />}
            <div className='menu-header'>
                <div className='content'>
                    <div className='title-action'>
                        <h3>
                            {isEditProduct && selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                ? 'Chỉnh sửa sản phẩm'
                                : isEditProduct && selectedTypeProduct == appConfig.PRODUCT_TYPE.COMBO.CODE
                                    ? 'Chỉnh sửa combo'
                                    : !isEditProduct && selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                        ? 'Tạo sản phẩm mới'
                                        : 'Tạo combo mới'}
                        </h3>
                        <button onClick={submit} className='ladiui btn btn-primary'>
                            {isEditProduct && selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                ? 'Lưu sản phẩm'
                                : isEditProduct && selectedTypeProduct == appConfig.PRODUCT_TYPE.COMBO.CODE
                                    ? 'Lưu combo'
                                    : !isEditProduct && selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                        ? 'Tạo sản phẩm'
                                        : 'Tạo combo'}
                        </button>
                    </div>
                    <div className='product-tabs-header'>
                        <ul className='ladiui nav nav-tabs'>
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(1)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 1 ? 'active' : ''}`}>
                                    {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE ? 'Thông tin sản phẩm' : 'Thông tin combo'}
                                </span>
                            </li>
                            {selectedTypeProduct != appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE ? (
                                <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(2)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 2 ? 'active' : ''}`}>Quản lý mẫu mã</span>
                                </li>
                            ) : (
                                ''
                            )}
                            {selectedTypeProduct != appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE ? (
                                <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(3)}>
                                    <span className={`ladiui tab-link ${selectedTabIndex === 3 ? 'active' : ''}`}>
                                        {selectedTypeProduct == appConfig.PRODUCT_TYPE_CONFIG.PHYSICAL.CODE
                                            ? t('PRODUCTS.PRODUCT_UP_SELL')
                                            : selectedTypeProduct == appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE
                                                ? t('PRODUCTS.PRODUCT_COMBO')
                                                : t('PRODUCTS.PRODUCT_TOPPING')}
                                    </span>
                                </li>
                            ) : (
                                ''
                            )}
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(4)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 4 ? 'active' : ''}`}>Thiết lập SEO & Social</span>
                            </li>
                            <li className={`ladiui nav-item`} onClick={() => setSelectedTabIndex(5)}>
                                <span className={`ladiui tab-link ${selectedTabIndex === 5 ? 'active' : ''}`}>Highlight</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='page-content-product-new'>
                <div className='ladiui page-content content-max-width'>
                    <div className='ladiui tab-content form-setting max-width-content' id='myTabContent'>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 1 ? 'show open' : ''}`} id='tab-product-info'>
                            <div className='page-product-info'>
                                <div className='content-left'>
                                    <div className='block-content-item'>
                                        <h3>
                                            {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                ? 'Thông tin sản phẩm'
                                                : 'Thông tin combo'}
                                        </h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>
                                                {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE ? 'Tên sản phẩm' : 'Tên combo'}
                                            </label>
                                            <Input
                                                className='ladiui form-control'
                                                placeholder={
                                                    selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                        ? 'Nhập tên sản phẩm'
                                                        : 'Nhập tên combo'
                                                }
                                                name='name'
                                                value={product.name}
                                                onChange={(event) => handleChangeInput(event)}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validationName={
                                                    selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE ? 'tên sản phẩm' : 'tên combo'
                                                }
                                                validations={{ isRequired: true }}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>
                                                Mô tả ngắn (Hiển thị trong mô tả{' '}
                                                {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO ? 'sản phẩm' : 'combo'})
                                            </label>
                                            <Input
                                                className='ladiui form-control'
                                                placeholder={`Nhập mô tả ngắn`}
                                                name='short_description'
                                                value={product.short_description}
                                                onChange={(event) => handleChangeInput(event)}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validationName={t('mô tả ngắn')}
                                                validations={{ isRequired: false }}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>
                                                {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                    ? 'Mô tả sản phẩm'
                                                    : 'Mô tả combo'}
                                            </label>
                                            <Editor
                                                tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                                licenseKey="gpl"
                                                value={product.description}
                                                init={{
                                                    selector: 'textarea',
                                                    min_height: 200,
                                                    max_height: 800,
                                                    autoresize_overflow_padding: 5,
                                                    autoresize_bottom_margin: 24,
                                                    plugins:
                                                        'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                                    toolbar:
                                                        'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                                    toolbar_drawer: 'sliding',
                                                    language_url: '../../../language/tinymce-vi.js',
                                                    content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                                    // valid_elements: '*[*]',
                                                }}
                                                onEditorChange={handleEditorChange}
                                            />
                                        </div>
                                    </div>
                                    {selectedTypeProduct == appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE ? (
                                        <div className='block-content-item mt-20'>
                                            <div className='header-top flex items-center space-between mb-20'>
                                                <h3>{'Thành phần combo'}</h3>
                                                <div className='info-help flex items-center'>
                                                    <i className='ladi-icon icon-c-question mr-8'></i>
                                                    <span>Tìm hiểu thêm</span>
                                                </div>
                                            </div>
                                            <ProductSearch
                                                isNotShowBtnCreateProduct={true}
                                                selectVariant={handleSelectProductUpsell}
                                                parentID={product.product_id || null}
                                                displayProductType={selectedTypeProduct || ''}
                                                searchType='UpSell'
                                            />
                                            <div className='block-list-selected-product-upsells'>
                                                {!selectedProductUpsells || selectedProductUpsells.length == 0 ? (
                                                    <div className='none-product'>
                                                        <div className='img-text text-center'>
                                                            <img src='https://w.ladicdn.com/ladiui/icons/new-ldicon-template.svg' />
                                                            <p>Chưa có sản phẩm nào !</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='block-list'>
                                                        <DragDropContext onDragEnd={onDragEndProductUpSell}>
                                                            <Droppable droppableId='droppable'>
                                                                {(provided, snapshot) => (
                                                                    <table
                                                                        className='ladiui table text-left'
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                                        }}
                                                                    >
                                                                        <thead>
                                                                            <tr className='ladiui table-vertical'>
                                                                                <th width='50'></th>
                                                                                <th>Tên sản phẩm</th>
                                                                                <th>Giá sản phẩm</th>
                                                                                {selectedTypeProduct &&
                                                                                    selectedTypeProduct ==
                                                                                    appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE && (
                                                                                        <th className='text-left' width={100}>
                                                                                            {t('PRODUCTS.QTY')}
                                                                                        </th>
                                                                                    )}
                                                                                <th className='text-right'></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {map(selectedProductUpsells, (product, index) => {
                                                                                return (
                                                                                    <Draggable
                                                                                        key={index}
                                                                                        draggableId={'' + index}
                                                                                        index={index}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <tr
                                                                                                key={index}
                                                                                                className='ladiui table-vertical'
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                style={{
                                                                                                    ...provided.draggableProps.style,
                                                                                                    userSelect: 'none',
                                                                                                    background: snapshot.isDragging
                                                                                                        ? '#e8f0fe'
                                                                                                        : 'none',
                                                                                                }}
                                                                                            >
                                                                                                <td>
                                                                                                    <div className='flex image-product items-center'>
                                                                                                        <i
                                                                                                            className='ldicon-drag-drop'
                                                                                                            {...provided.dragHandleProps}
                                                                                                        />
                                                                                                        <div className='img'>
                                                                                                            <img
                                                                                                                src={
                                                                                                                    product.src
                                                                                                                        ? CDN + product.src
                                                                                                                        : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                                                                }
                                                                                                                width={60}
                                                                                                                height={60}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>{product.name}</td>
                                                                                                <td>
                                                                                                    {/* {product.price
                                                                                                            ? baseHelper.formatMoneyPostFix(
                                                                                                                product.price,
                                                                                                                storeInfo.currentStore.currency_symbol
                                                                                                            )
                                                                                                            : 0} */}
                                                                                                    {getPriceProduct(product)}
                                                                                                </td>
                                                                                                {selectedTypeProduct &&
                                                                                                    selectedTypeProduct ==
                                                                                                    appConfig.PRODUCT_TYPE.COMBO
                                                                                                        .CODE && (
                                                                                                        <td className='text-left'>
                                                                                                            {/* <div className='item-variant-price'>
                                                                                                                    <button
                                                                                                                        className='ladiui btn btn-sm'
                                                                                                                        onClick={() => changeQuantityVariant(-1, index)}
                                                                                                                    >
                                                                                                                        -
                                                                                                                    </button>
                                                                                                                    <Input
                                                                                                                        id={`quantity-${product.variant_id}`}
                                                                                                                        type='number'
                                                                                                                        className='item-variant-price-input'
                                                                                                                        min={1}
                                                                                                                        value={product.quantity}
                                                                                                                        style
                                                                                                                        disabled={true}
                                                                                                                        style={{ textAlign: 'center' }}
                                                                                                                    />
                                                                                                                    <button
                                                                                                                        className='ladiui btn btn-sm'
                                                                                                                        onClick={() => changeQuantityVariant(1, index)}
                                                                                                                    >
                                                                                                                        +
                                                                                                                    </button>
                                                                                                                </div> */}
                                                                                                            <div className='variants-quantity'>
                                                                                                                <div className='variant-quantity-up-down'>
                                                                                                                    <input
                                                                                                                        type='number'
                                                                                                                        value={
                                                                                                                            product.quantity
                                                                                                                        }
                                                                                                                        onChange={(e) =>
                                                                                                                            handleChangeQuantityUpsell(
                                                                                                                                e,
                                                                                                                                index
                                                                                                                            )
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <div className='variant-up-down'>
                                                                                                                        <i
                                                                                                                            className='new-ldicon-arrow-up'
                                                                                                                            onClick={() =>
                                                                                                                                changeQuantityVariant(
                                                                                                                                    +1,
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                        <i
                                                                                                                            className='new-ldicon-arrow-down'
                                                                                                                            onClick={() =>
                                                                                                                                changeQuantityVariant(
                                                                                                                                    -1,
                                                                                                                                    index
                                                                                                                                )
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    )}
                                                                                                <td className='text-right'>
                                                                                                    <a
                                                                                                        href='#'
                                                                                                        onClick={() =>
                                                                                                            handleDeleteProductUpsell(
                                                                                                                product.product_variant_id
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='ladi-icon icon-bin' />
                                                                                                    </a>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </Draggable>
                                                                                );
                                                                            })}
                                                                        </tbody>
                                                                        {provided.placeholder}
                                                                    </table>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {!isEditProduct || (isEditProduct && variants.length == 0) ? (
                                        <div className='block-content-item mt-20'>
                                            <h3>
                                                {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE ? 'Giá sản phẩm' : 'Giá combo'}
                                            </h3>
                                            <div className='flex flex-row gap-16'>
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label new'>Giá bán</label>
                                                    <NumberInput
                                                        name='price'
                                                        suffix={
                                                            storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                                                ? storeInfo.currentStore.currency_symbol
                                                                : 'đ'
                                                        }
                                                        value={product.price}
                                                        onChange={(event) => handleChangeInput(event)}
                                                    />
                                                </div>
                                                <div className='ladiui form-group'>
                                                    <label className='ladiui-label new'>Giá nhập hàng</label>
                                                    <NumberInput
                                                        name='cost_per_item'
                                                        suffix={
                                                            storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                                                ? storeInfo.currentStore.currency_symbol
                                                                : 'đ'
                                                        }
                                                        value={product.cost_per_item}
                                                        onChange={(event) => handleChangeInput(event)}
                                                    />
                                                </div>
                                                {isShowPriceCompare && (
                                                    <div className='ladiui form-group'>
                                                        <label className='ladiui-label new'>Giá so sánh</label>
                                                        <NumberInput
                                                            name='price_compare'
                                                            suffix={
                                                                storeInfo.currentStore && storeInfo.currentStore.currency_symbol
                                                                    ? storeInfo.currentStore.currency_symbol
                                                                    : 'đ'
                                                            }
                                                            value={product.price_compare}
                                                            onChange={(event) => handleChangeInput(event)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className='flex flex-start mt-24'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                    name='is_show_price_compare'
                                                    onChange={() => {
                                                        setIsShowPriceCompare(!isShowPriceCompare);
                                                    }}
                                                    checked={isShowPriceCompare}
                                                />
                                                <span>Hiển thị giá so sánh</span>
                                            </div>
                                            <div className='block-info-help flex items-center'>
                                                <i className='ladi-icon icon-c-question mr-8'></i>
                                                <span>
                                                    Nhập giá so sánh cao hơn giá bán để hiển thị mức giảm giá. Giá so sánh thường có đường
                                                    gạch ngang chữ.
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className='block-content-item mt-20'>
                                        <h3>
                                            {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                ? 'Hình ảnh sản phẩm'
                                                : 'Hình ảnh combo'}
                                        </h3>

                                        <div className='block-list-images mt-24'>
                                            <ListImageProduct
                                                listImage={images}
                                                handleDeleteImage={(item) => handleDeleteImage(item)}
                                                handleSetImage={(items) => handleSetImage(items)}
                                                isEditProduct={isEditProduct}
                                                productID={product.product_id}
                                                handleSetListImageProducts={handleSetListImageProducts}
                                            // isCopy={isCopyProduct}
                                            // handleSetImageCopy={this.handleSetImageCopy}
                                            />
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>Thiết lập nâng cao</h3>
                                        <div className='advanced-settings'>
                                            {!variants ||
                                                (variants.length == 0 && (
                                                    <>
                                                        <div className='item'>
                                                            <div className='ladiui item-form switch mr-8'>
                                                                <label className='ladiui switch m-0'>
                                                                    <input
                                                                        type='checkbox'
                                                                        checked={product.is_one_time}
                                                                        onChange={() => {
                                                                            setProduct({
                                                                                ...product,
                                                                                is_one_time: !product.is_one_time,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <span className='ladiui slider round'></span>
                                                                </label>
                                                            </div>
                                                            <span>{`Cho phép mỗi khách hàng chỉ được mua ${selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                                ? 'sản phẩm'
                                                                : 'combo'
                                                                } một lần`}</span>
                                                        </div>

                                                        <div className='item'>
                                                            <div
                                                                className='ladiui flex-center mr-4'
                                                                onClick={() => {
                                                                    setIsShowLimitQtyProduct(!isShowLimitQtyProduct);
                                                                }}
                                                            >
                                                                <i
                                                                    className={`icon-caret-down-fill ${!isShowLimitQtyProduct ? 'turn-right' : ''
                                                                        }`}
                                                                />
                                                            </div>
                                                            <span>{`Số lượng  ${selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                                ? 'sản phẩm'
                                                                : 'combo'
                                                                } được mua trên mỗi đơn hàng`}</span>
                                                        </div>
                                                        {isShowLimitQtyProduct && (
                                                            <div className='flex gap-16 py-12'>
                                                                <div className='ladiui form-group mt-0'>
                                                                    <label className='ladiui-label new'>{t('PRODUCTS.PR_MIN_BUY')}</label>
                                                                    <NumberInput
                                                                        name='min_buy'
                                                                        value={product.min_buy}
                                                                        onChange={(event) => handleChangeInput(event)}
                                                                        onlyNumber={true}
                                                                        thousandSeparator={false}
                                                                        decimalScale={0}
                                                                        allowNegative={true}
                                                                        validations={{
                                                                            min: 1,
                                                                        }}
                                                                        ref={(ref) => inputsRef.add(ref)}
                                                                        validationName={t('PRODUCTS.PR_MIN_BUY')}
                                                                    />
                                                                </div>
                                                                <div className='ladiui form-group mt-0'>
                                                                    <label className='ladiui-label new'>{t('PRODUCTS.PR_MAX_BUY')}</label>
                                                                    <NumberInput
                                                                        name='max_buy'
                                                                        value={product.max_buy}
                                                                        onChange={(event) => handleChangeInput(event)}
                                                                        onlyNumber={true}
                                                                        thousandSeparator={false}
                                                                        decimalScale={0}
                                                                        allowNegative={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}

                                            {!variants ||
                                                (variants.length == 0 && (
                                                    <>
                                                        <div className='item'>
                                                            <div
                                                                className='ladiui flex-center mr-4'
                                                                onClick={() => {
                                                                    setIsShowWarehouseSetting(!isShowWarehouseSetting);
                                                                }}
                                                            >
                                                                <i
                                                                    className={`icon-caret-down-fill ${!isShowWarehouseSetting ? 'turn-right' : ''
                                                                        }`}
                                                                />
                                                            </div>

                                                            <span>Thiết lập kho hàng và vận chuyển</span>
                                                        </div>
                                                        {isShowWarehouseSetting && (
                                                            <>
                                                                <div className='flex gap-16 py-12'>
                                                                    <div className='ladiui form-group mt-0'>
                                                                        <label className='ladiui-label new'>{t('PRODUCTS.SKU')}</label>
                                                                        <Input
                                                                            name='sku'
                                                                            value={product.sku}
                                                                            placeholder='Nhập mã SKU'
                                                                            onChange={(event) => handleChangeInput(event)}
                                                                        />
                                                                    </div>
                                                                    <div className='ladiui form-group mt-0 relative'>
                                                                        <label className='ladiui-label new'>{t('COMMON.WEIGHT')}</label>
                                                                        <NumberInput
                                                                            name='weight'
                                                                            value={product.weight}
                                                                            placeholder='Khối lượng'
                                                                            onChange={(event) => handleChangeInput(event)}
                                                                            onlyNumber={true}
                                                                        />
                                                                        <SelectOption
                                                                            customStyle={{
                                                                                width: '42px',
                                                                                height: '42px',
                                                                                position: 'absolute',
                                                                                top: '24px',
                                                                                right: '0px',
                                                                            }}
                                                                            directionUp={true}
                                                                            wrapperClassName='mini-dropdown'
                                                                            innerClassName='select-weight mini-dropdown mini-dropdown-pd custom-weight min-h-auto'
                                                                            currentValue={product.weight_unit}
                                                                            items={appConfig.WEIGHT_UNITS}
                                                                            _key='CODE'
                                                                            _value='NAME'
                                                                            onSelectItem={(item) => {
                                                                                setProduct({
                                                                                    ...product,
                                                                                    weight_unit: item.CODE,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='py-12'>
                                                                    <div className='ladiui form-group flex items-center mt-0'>
                                                                        <input
                                                                            type='checkbox'
                                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                                            onChange={() => {
                                                                                setProduct(
                                                                                    {
                                                                                        ...product,
                                                                                        inventory_checked:
                                                                                            product.inventory_checked ==
                                                                                                appConfig.INVENTORY_STATUS.ACTIVE.CODE
                                                                                                ? appConfig.INVENTORY_STATUS.INACTIVE.CODE
                                                                                                : appConfig.INVENTORY_STATUS.ACTIVE.CODE,
                                                                                    },
                                                                                    () => {
                                                                                        setProduct({
                                                                                            ...product,
                                                                                            allow_sold_out:
                                                                                                product.inventory_checked ==
                                                                                                    appConfig.INVENTORY_STATUS.ACTIVE.CODE
                                                                                                    ? appConfig.STATUS.ACTIVE
                                                                                                    : appConfig.STATUS.INACTIVE,
                                                                                        });
                                                                                    }
                                                                                );
                                                                            }}
                                                                            checked={
                                                                                product.inventory_checked ==
                                                                                appConfig.INVENTORY_STATUS.ACTIVE.CODE
                                                                            }
                                                                        />
                                                                        <span>Quản lý tồn kho</span>
                                                                    </div>
                                                                    <div className='ladiui form-group' style={{ width: 'calc(50% - 8px)' }}>
                                                                        <label className='ladiui-label new'>Số lượng hàng tồn kho</label>
                                                                        <NumberInput
                                                                            name='quantity'
                                                                            value={product.quantity}
                                                                            placeholder='Khối lượng'
                                                                            onChange={(event) => handleChangeInput(event)}
                                                                            onlyNumber={true}
                                                                            disabled={!product.inventory_checked}
                                                                        />
                                                                    </div>
                                                                    <div className='ladiui form-group flex items-center'>
                                                                        <input
                                                                            type='checkbox'
                                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                                            disabled={!product.inventory_checked}
                                                                            id='allowPurchaseWhenSoldOut'
                                                                            onChange={(event) => {
                                                                                // this.setState({
                                                                                //     product: {
                                                                                //         ...this.state.product,
                                                                                //         allow_sold_out:
                                                                                //             this.state.product.allow_sold_out ==
                                                                                //                 appConfig.STATUS.ACTIVE
                                                                                //                 ? appConfig.STATUS.INACTIVE
                                                                                //                 : appConfig.STATUS.ACTIVE,
                                                                                //     },
                                                                                // });
                                                                                setProduct({
                                                                                    ...product,
                                                                                    allow_sold_out:
                                                                                        product.allow_sold_out == appConfig.STATUS.ACTIVE
                                                                                            ? appConfig.STATUS.INACTIVE
                                                                                            : appConfig.STATUS.ACTIVE,
                                                                                });
                                                                            }}
                                                                            checked={product.allow_sold_out == appConfig.STATUS.ACTIVE}
                                                                        />
                                                                        <span>{`Cho phép bán ${selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                                            ? 'sản phẩm'
                                                                            : 'combo'
                                                                            } khi đã hết hàng`}</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ))}
                                            <div className='item'>
                                                {/* <div className='ladiui item-form switch mr-8'>
                                                    <label className='ladiui switch m-0'>
                                                        <input
                                                            type='checkbox'
                                                            onChange={() => {
                                                                setIsShowExternalLink(!isShowExternalLink);
                                                            }}
                                                            checked={isShowExternalLink}
                                                        />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div> */}
                                                <div
                                                    className='ladiui flex-center mr-4'
                                                    onClick={() => {
                                                        setIsShowExternalLink(!isShowExternalLink);
                                                    }}
                                                >
                                                    <i className={`icon-caret-down-fill ${!isShowExternalLink ? 'turn-right' : ''}`} />
                                                </div>
                                                <span>{`Thêm đường dẫn trang Landingpage giới thiệu ${selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE ? 'sản phẩm' : 'combo'
                                                    }`}</span>
                                            </div>
                                            {isShowExternalLink && (
                                                <div className='ladiui form-group mt-0 pt-12 pb-12'>
                                                    <label className='ladiui-label new'>{`Đường dẫn trang giới thiệu  ${selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE ? 'sản phẩm' : 'combo'
                                                        }`}</label>
                                                    <Input
                                                        ref={(ref) => inputsRef.add(ref)}
                                                        name='external_link'
                                                        validationName={t('PRODUCTS.LB_EXTERNAL_LINK')}
                                                        placeholder={t('PRODUCTS.LB_EXTERNAL_LINK_PLACEHOLDER')}
                                                        value={product.external_link}
                                                        onChange={(event) => handleChangeInput(event)}
                                                        validations={{ isURL: true }}
                                                    />
                                                </div>
                                            )}
                                            {/* <div className='item'>
                                                <div className='ladiui item-form switch mr-8'>
                                                    <label className='ladiui switch m-0'>
                                                        <input type='checkbox' checked={true} onChange={() => {}} />
                                                        <span className='ladiui slider round'></span>
                                                    </label>
                                                </div>
                                                <span>Hiển thị đề xuất khuyến mãi trên trang sản phẩm</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='content-right'>
                                    <div className='block-content-item'>
                                        <div className='flex items-center space-between'>
                                            <h3>
                                                {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                    ? 'Hiển thị sản phẩm'
                                                    : 'Hiển thị combo'}
                                            </h3>
                                            <div className='ladiui item-form switch'>
                                                <label className='ladiui switch m-0'>
                                                    <input
                                                        type='checkbox'
                                                        onChange={(event) => {
                                                            setProduct({
                                                                ...product,
                                                                status:
                                                                    product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE
                                                                        ? appConfig.PRODUCT_STATUS.INACTIVE.CODE
                                                                        : appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                                                            });
                                                        }}
                                                        checked={product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE}
                                                    />
                                                    <span className='ladiui slider round'></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Cửa hàng</label>
                                            <SelectManyDropdownStore
                                                data={stores || []}
                                                _key='name'
                                                _value='value'
                                                pickTitle={'Tiêu đề'}
                                                placeHolder={'Chọn cửa hàng'}
                                                onSubmit={(items) => {
                                                    setSelectedStores(items);
                                                }}
                                                isShowAllStore={isShowAllStore}
                                                innerClassName='w100'
                                                styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                                buttonStyle={{
                                                    width: '100%',
                                                    paddingRight: '30px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: ' -webkit-box',
                                                    WebkitLineClamp: 1,
                                                    WebkitBoxOrient: 'vertical',
                                                    textAlign: 'left',
                                                }}
                                                selectedItems={selectedStores || []}
                                                isShowBlockInputSearch={false}
                                                isCreateCategory={true}
                                                isCourse={true}
                                                handleRefreshData={handleRefreshDataStores}
                                                handleSearchData={handleSearchData}
                                                handleChangeCheckAllStore={handleChangeCheckAllStore}
                                            />
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3>
                                            {selectedTypeProduct != appConfig.PRODUCT_TYPE.COMBO.CODE
                                                ? 'Phân loại sản phẩm'
                                                : 'Phân loại combo'}
                                        </h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Danh mục sản phẩm</label>
                                            <SelectManyDropdown
                                                data={categories || []}
                                                _key='name'
                                                _value='value'
                                                pickTitle={'Tiêu đề'}
                                                placeHolder={'Chọn danh mục'}
                                                onSubmit={(items) => {
                                                    setSelectedCategories(items);
                                                }}
                                                innerClassName='w100'
                                                styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                                                buttonStyle={{
                                                    width: '100%',
                                                    paddingRight: '30px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: ' -webkit-box',
                                                    WebkitLineClamp: 1,
                                                    WebkitBoxOrient: 'vertical',
                                                    textAlign: 'left',
                                                }}
                                                selectedItems={selectedCategories || []}
                                                isShowBlockInputSearch={true}
                                                isCreateCategory={true}
                                                handleRefreshData={handleRefreshDataCategories}
                                            // handleSearchData={handleSearchData}
                                            />
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Tag sản phẩm</label>
                                            <ProductTags ref={productTagRef} productTag={productTags} />
                                        </div>
                                    </div>

                                    {isEditProduct && product.type != appConfig.PRODUCT_TYPE.F_B.CODE && (
                                        <div className='block-content-item mt-20'>
                                            <h3>Trang thanh toán</h3>
                                            <div className='ladiui form-group'>
                                                {/* {product.page_checkout && (
                                                <a href={`/page-checkout/update/${product.page_checkout.page_checkout_id}`} target='_blank'>
                                                    {product.page_checkout.name}
                                                </a>
                                            )} */}
                                                {product.page_checkout ? (
                                                    <div className='ladiui flex gap-12'>
                                                        <a
                                                            className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                                            // href={`/page-checkout/update/${product.page_checkout.page_checkout_id}`}
                                                            href={getUrlPageCheckout(
                                                                'UPDATE',
                                                                `/page-checkout/update/${product.page_checkout.page_checkout_id}`,
                                                                product.page_checkout.page_checkout_id
                                                            )}
                                                            target='_blank'
                                                        >
                                                            Chỉnh Sửa
                                                        </a>
                                                        <a
                                                            className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                                            href={product.url_published}
                                                            target='_blank'
                                                        >
                                                            Truy cập
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <a
                                                        href={getUrlPageCheckout(
                                                            'CREATE',
                                                            `/page-checkout?is_create=true&product_id=${product.product_id}&type_create=${appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT}`,
                                                            product.product_id
                                                        )}
                                                        target='_parent'
                                                    >
                                                        {t('PAGE_CHECKOUT.ADD')}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className='block-content-item mt-20'>
                                        <h3>Nút CTA</h3>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new'>Chọn nút</label>
                                            <SelectOption
                                                wrapperClassName='select-assignee w100'
                                                innerClassName='ellipsis w100 flex'
                                                currentKey={product.cta}
                                                classIconCTA={product.selectedIconCTA}
                                                customClassName='w100'
                                                _key='CODE'
                                                _value='NAME'
                                                items={appConfig.LIST_CTA || []}
                                                onSelectItem={(item) => {
                                                    onChangeCTA(item);
                                                }}
                                            />
                                        </div>
                                        <div className='block-info-help flex items-center'>
                                            <i className='ladi-icon icon-c-question mr-8'></i>
                                            <span>Nội dung nút mua hàng ở trang sản phẩm.</span>
                                        </div>
                                    </div>
                                    <div className='block-content-item mt-20'>
                                        <h3 className='mb-24'>Trường tùy chỉnh</h3>
                                        <PanelCustomField
                                            ref={panelCustomFieldRef}
                                            customFields={customFields}
                                            groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE}
                                        />
                                        <div className='block-info-help flex items-center'>
                                            <i className='ladi-icon icon-c-question mr-8'></i>
                                            <span>Lưu trữ thông tin sản phẩm theo nhu cầu của bạn.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 2 ? 'show open' : ''}`} id='tab-product-variants'>
                            <div className='page-content-product-variants'>
                                {/* <PanelCreateVariant
                                    ref={panelCreateVariantRef}
                                    variants={variants}
                                    inputsRef={inputsRef}
                                    product={product}
                                    options={options}
                                    images={images}
                                /> */}
                                {getVariantPanel()}
                            </div>
                        </div>

                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 3 ? 'show open' : ''}`} id='tab-product-upsell'>
                            <div className='page-content-product-upsell'>
                                <PanelProductUpsellV4
                                    selectedProductUpsells={selectedProductUpsells}
                                    handleSelectProductUpsell={handleSelectProductUpsell}
                                    handleDeleteProductUpsell={handleDeleteProductUpsell}
                                    onDragEndProductUpSell={onDragEndProductUpSell}
                                    productInfo={product}
                                    selectedTypeProduct={selectedTypeProduct}
                                />
                            </div>
                        </div>

                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 4 ? 'show open' : ''}`} id='tab-product-seo-social'>
                            <div className='page-content-product-seo-social'>
                                <div className='block-content-item'>
                                    <h3>Thiết lập SEO & Social</h3>
                                    <PanelProductSellSocial ref={panelSeoSocialRef} productInfoSeo={product.seo || {}} />
                                    <div className='block-info-help flex items-center'>
                                        <i className='ladi-icon icon-c-question mr-8'></i>
                                        <span>
                                            Thiết lập SEO & Social giúp khách hàng dễ dàng tìm kiếm sản phẩm trên các công cụ tìm kiếm như
                                            Google.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 5 ? 'show open' : ''}`} id='tab-product-highlight'>
                            <div className='page-content-product-seo-social'>
                                <div className='block-content-item'>
                                    <PanelPageProduct
                                        ref={highlightProductRef}
                                        quantitySold={product.quantity_sold || ''}
                                        highlightDisplay={product.highlight_display || {}}
                                        productType={product.type}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmModal
                style={{ zIndex: 1050 }}
                id='confirm-image-delete'
                title={t('PRODUCTS.MSG_DELETE_IMAGE_TITLE')}
                content={t('PRODUCTS.MSG_DELETE_IMAGE_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={deleteImageProduct}
            // isLoading={isLoadingDelete}
            />
        </div>
    );
}

export default withTranslation()(ProductCreate);
