import React from 'react';
import PropTypes from 'prop-types';
import { map, find, compact, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../components/Modal';
import Input from '../../../../../components/Input';
import appConfig from '../../../../../config/app';
import baseHelper from '../../../../../helpers/BaseHelper';
import NumberInput from '../../../../../components/NumberInput';

type Props = {};

class ModalShippingRateDetail extends React.Component<Props> {
    static propTypes = {
        mode: PropTypes.string,
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        isLoading: PropTypes.bool,
        shippingRate: PropTypes.object,
        shippingRateDetail: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            shippingRateDetail: {
                shipping_rate_detail_id: '',
                shipping_rate_id: '',
                name: '',
                description: '',
                type: appConfig.SHIPPING_RATE_DETAIL_TYPE.PRICE.value,
                down_price: 0,
                up_price: '',
                down_weight: 0,
                up_weight: '',
                fee: 0,
                adjust_fee: [],
            },
        };

        this.inputsRef = new Set();
    }

    componentDidMount() {
        window.LadiUI.init();

        this.init(this.props);
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.shippingRateDetail != nextProps.shippingRateDetail) {
            this.init(nextProps);
        }
    }

    init = (props) => {
        if (!baseHelper.isEmpty(props.shippingRateDetail)) {
            const newShippingRateDetail = {};
            map(this.state.shippingRateDetail, (value, key) => {
                newShippingRateDetail[key] = !baseHelper.isEmpty(props.shippingRateDetail[key]) ? props.shippingRateDetail[key] : '';
            });

            this.setState({
                shippingRateDetail: newShippingRateDetail,
            });
        }
    };

    getNameByType = (value) => {
        const matched = find(appConfig.SHIPPING_RATE_DETAIL_TYPE, (item) => item.value == value);
        return matched.name;
    };

    onChangeType = (type) => {
        this.setState({
            shippingRateDetail: {
                ...this.state.shippingRateDetail,
                type: type,
            },
        });
    };

    onChangeInput = (event) => {
        this.setState({
            shippingRateDetail: {
                ...this.state.shippingRateDetail,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeGlobalFee = (event) => {
        const adjustFee = this.state.shippingRateDetail.adjust_fee;
        map(adjustFee, (item) => {
            item.last_fee = baseHelper.parseFloat(item.fee) + baseHelper.parseFloat(event.target.value);
        });

        this.setState({
            shippingRateDetail: {
                ...this.state.shippingRateDetail,
                adjust_fee: adjustFee,
                fee: event.target.value,
            },
        });
    };

    onChangeAdjustFee = (value, index) => {
        const adjustFee = this.state.shippingRateDetail.adjust_fee;

        adjustFee[index].fee = value;
        adjustFee[index].last_fee = baseHelper.parseFloat(value) + baseHelper.parseFloat(this.state.shippingRateDetail.fee);

        this.setState({
            shippingRate: {
                ...this.state.shippingRateDetail,
                adjust_fee: adjustFee,
            },
        });
    };

    onChangeDeliverySupport = (index) => {
        const adjustFee = this.state.shippingRateDetail.adjust_fee;
        adjustFee[index].delivery_support = !adjustFee[index].delivery_support;

        this.setState({
            shippingRate: {
                ...this.state.shippingRateDetail,
                adjust_fee: adjustFee,
            },
        });
    };

    getAdjustFeeForm = () => {
        if (this.props.mode == appConfig.FORM_MODE.CREATE) {
            return null;
        }

        if (this.props.shippingRate.state_id == appConfig.OTHER_STATE) {
            return null;
        }

        const { shippingRateDetail } = this.state;
        const { t } = this.props;
        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;

        return (
            <div className='ladiui form-group'>
                <label className='ladiui-label pb-0 '>{t('SHIPPING_RATES.DISTRICT_FEE')}</label>
                <div className='ladiui adjust-fee block-item-district mt-4'>
                    {map(shippingRateDetail.adjust_fee, (item, index) => (
                        <div key={index} className='item-district'>
                            <div className='ladiui form-group mb-0'>
                                <input
                                    id={`item item-adjust-fee-${index}`}
                                    type='checkbox'
                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                    onChange={() => this.onChangeDeliverySupport(index)}
                                    checked={item.delivery_support}
                                />
                                <label htmlFor={`item item-adjust-fee-${index}`}> {item.name}</label>
                            </div>

                            <div className='ladiui form-group mt-0'>
                                <label className='ladiui-label'>{t('SHIPPING_RATES.ADJUST_FEE')}</label>
                                <NumberInput
                                    prefix={currencySymbol}
                                    value={item.fee}
                                    onChange={(event) => this.onChangeAdjustFee(event.target.value, index)}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label text-right'>{t('SHIPPING_RATES.LAST_FEE')}</label>
                                <label className='ladiui-label text-right' style={{ fontWeight: 400, color: '#8697a8' }}>
                                    {baseHelper.formatMoneyPostFix(item.last_fee, this.props.store.userInfo.currentStore.currency_symbol)}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    submit = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { t } = this.props;
        // Check range
        const { shippingRateDetail } = this.state;
        if (shippingRateDetail.type == appConfig.SHIPPING_RATE_DETAIL_TYPE.PRICE.value) {
            if (!baseHelper.isEmpty(shippingRateDetail.up_price)) {
                if (baseHelper.parseFloat(shippingRateDetail.up_price) < baseHelper.parseFloat(shippingRateDetail.down_price)) {
                    window.LadiUI.toastCustom('danger', '', t('SHIPPING_RATES.MSG_WRONG_PRICE_RANGE'));
                    return;
                }
            }
        } else {
            if (!baseHelper.isEmpty(shippingRateDetail.up_weight)) {
                if (baseHelper.parseFloat(shippingRateDetail.up_weight) < baseHelper.parseFloat(shippingRateDetail.down_weight)) {
                    window.LadiUI.toastCustom('danger', '', t('SHIPPING_RATES.MSG_WRONG_WEIGHT_RANGE'));
                    return;
                }
            }
        }

        let _shippingRateDetail = cloneDeep(shippingRateDetail);
        if (this.props.mode == appConfig.FORM_MODE.CREATE) {
            delete _shippingRateDetail.shipping_rate_detail_id;
        }

        _shippingRateDetail.shipping_rate_id = this.props.shippingRate.shipping_rate_id;
        this.props.onSubmit(_shippingRateDetail);
    };

    render() {
        const { t } = this.props;
        const { shippingRateDetail } = this.state;
        const title =
            this.props.mode === appConfig.FORM_MODE.CREATE
                ? t('SHIPPING_RATES.ADD_SHIPPING_RATE_DETAIL_TITLE', this.props.shippingRate.state_name)
                : t('SHIPPING_RATES.EDIT_SHIPPING_RATE_DETAIL_TITLE', this.props.shippingRate.state_name);
        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;

        return (
            <Modal
                id='modal-shipping-rate-detail'
                title={title}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                isLoading={this.props.isLoading}
                width={800}
            >
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('SHIPPING_RATES.NAME')}</label>
                    <Input
                        ref={(ref) => this.inputsRef.add(ref)}
                        name='name'
                        validationName={t('SHIPPING_RATES.NAME')}
                        placeholder={t('SHIPPING_RATES.NAME_PLACE_HOLER')}
                        value={shippingRateDetail.name}
                        onChange={this.onChangeInput}
                        validations={{ isRequired: true }}
                    />
                </div>

                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('PRODUCTS.DESCRIPTION')}</label>
                    <textarea
                        name='description'
                        className='ladiui textarea'
                        value={shippingRateDetail.description}
                        onChange={this.onChangeInput}
                    />
                </div>

                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('SHIPPING_RATES.TYPE.TITLE')}</label>
                    {/* <div className="ladiui-select-options" style={{ zIndex: 6 }}>
                        <span className="ladiui-select-init">{this.getNameByType(shippingRateDetail.type)}</span>
                        <input type="hidden" name="select" defaultValue />
                        <ul>
                            {
                                map(appConfig.SHIPPING_RATE_DETAIL_TYPE, (item, index) =>
                                    <li key={index} className="select-item" onClick={() => { this.onChangeType(item.value) }}><a data-value={item.value}>{item.name}</a></li>
                                )
                            }
                        </ul>
                    </div> */}
                    <div className='ladiui dropdown' style={{ width: '100%' }}>
                        <button className='ladiui btn btn-outline-light dropdown-toggle' data-toggle='dropdown' style={{ width: '100%' }}>
                            {this.getNameByType(shippingRateDetail.type)}
                        </button>
                        <ul className='ladiui dropdown-menu custom-form-dynamic' style={{ width: '100%' }}>
                            {map(appConfig.SHIPPING_RATE_DETAIL_TYPE, (item, index) => (
                                <li key={index} onClick={() => this.onChangeType(item.value)}>
                                    <a className='ladiui dropdown-item' href='#'>
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {shippingRateDetail.type == appConfig.SHIPPING_RATE_DETAIL_TYPE.PRICE.value && (
                    <div className='ladiui form-group'>
                        <div className='ladiui-row flex'>
                            <div className='col-50'>
                                <div className='ladiui form-group mb-0'>
                                    <label className='ladiui-label'>{t('SHIPPING_RATES.DOWN_PRICE')}</label>
                                    <NumberInput
                                        name='down_price'
                                        prefix={currencySymbol}
                                        validationName={t('SHIPPING_RATES.DOWN_PRICE')}
                                        value={shippingRateDetail.down_price}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                </div>
                            </div>

                            <div className='col-50'>
                                <div className='ladiui form-group mb-0'>
                                    <label className='ladiui-label'>{t('SHIPPING_RATES.UP_PRICE')}</label>
                                    <NumberInput
                                        name='up_price'
                                        prefix={currencySymbol}
                                        validationName={t('SHIPPING_RATES.UP_PRICE')}
                                        value={shippingRateDetail.up_price}
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {shippingRateDetail.type == appConfig.SHIPPING_RATE_DETAIL_TYPE.WEIGHT.value && (
                    <div className='ladiui form-group'>
                        <div className='ladiui-row flex'>
                            <div className='col-50'>
                                <div className='ladiui form-group mb-0'>
                                    <label className='ladiui-label'>{t('SHIPPING_RATES.DOWN_WEIGHT')}</label>
                                    <Input
                                        name='down_weight'
                                        type='number'
                                        currency={true}
                                        prefix='g'
                                        formatter={(value) => `g ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => `${value}`.replace(/[g, ]/g, '')}
                                        validationName={t('SHIPPING_RATES.DOWN_WEIGHT')}
                                        value={shippingRateDetail.down_weight}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                </div>
                            </div>

                            <div className='col-50'>
                                <div className='ladiui form-group mb-0'>
                                    <label className='ladiui-label'>{t('SHIPPING_RATES.UP_WEIGHT')}</label>
                                    <Input
                                        name='up_weight'
                                        type='number'
                                        currency={true}
                                        prefix='g'
                                        formatter={(value) => `g ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value) => `${value}`.replace(/[g, ]/g, '')}
                                        validationName={t('SHIPPING_RATES.UP_WEIGHT')}
                                        value={shippingRateDetail.up_weight}
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('SHIPPING_RATES.FEE')}</label>
                    <NumberInput
                        name='fee'
                        prefix={currencySymbol}
                        validationName={t('SHIPPING_RATES.FEE')}
                        value={shippingRateDetail.fee}
                        onChange={this.onChangeGlobalFee}
                        validations={{ isRequired: true }}
                        ref={(ref) => this.inputsRef.add(ref)}
                    />
                </div>

                {this.getAdjustFeeForm()}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    store: { ...state.store },
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalShippingRateDetail)
);
