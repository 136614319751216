import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Dropdown from './Dropdown';

import productConfigActions from '../redux/futures/product/actions';
import orderActions from '../redux/futures/order/actions';
import * as productTypes from '../redux/futures/product/types';
import * as checkoutConfigTypes from '../redux/futures/checkout_config/types';

import * as domainTypes from '../redux/futures/domain/types';

import Input from './Input';
import { find } from 'lodash';
import appConfig from '../config/app';
import LoadingScene from './LoadingScene';
import DatePicker from '../components/DatePicker/CustomDatePicker';
import baseHelper from '../helpers/BaseHelper';

const PanelCustomField = forwardRef((props, ref) => {
    PanelCustomField.propTypes = {
        customFields: PropTypes.array || PropTypes.object,
    };
    const { groupType, actionType, dropup, isShowOrderCF = true } = props;

    const productReducer = useSelector((state) => state.product);
    const orderReducer = useSelector((state) => state.order);
    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);
    const domainReducer = useSelector((state) => state.domainReducer);

    const [customFieldValues, setCustomFieldValues] = useState(produce(props.customFields || [], (draft) => {}));
    const [valueDropDown, setValueDropDown] = useState('');
    const dataCustomFields = productReducer.customFields || [];

    const [activeFormEdit, setActiveFormEdit] = useState(false);
    const [isShowBlockCustomField, setIsShowBlockCustomField] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dataList = {
        group_type: props.groupType,
        list_all: true,
    };
    const moment = baseHelper.getMoment();
    useEffect(() => {
        dispatch(productConfigActions.listCustomField(dataList));
    }, []);

    useEffect(() => {
        if (!baseHelper.isEmpty(props.customFields)) {
            let selectedCustomFields = [...props.customFields];
            for (let customField of customFieldValues) {
                const foundCustomFieldValue = find(selectedCustomFields, (item) => item.custom_field_id == customField.custom_field_id);
                if (!foundCustomFieldValue) {
                    selectedCustomFields.push({
                        ...customField,
                    });
                }
            }

            setCustomFieldValues(selectedCustomFields);
        }
    }, [props.customFields]);

    useEffect(() => {
        if (productReducer.action == productTypes.UPDATE_CUSTOM_FIELD_BY_ORDER) {
            if (productReducer.status) {
                window.LadiUI.toastCustom('success', '', productReducer.message);
                dispatch(orderActions.reload(props.orderID));
            } else {
                // window.LadiUI.toastCustom("danger", "", productReducer.message);
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (!baseHelper.isEmpty(productReducer.customFields)) {
            const newCustomFieldValues = [];
            let typeTg = '';

            for (let customField of productReducer.customFields) {
                if (customField.group_type === props.groupType) {
                    typeTg = customField.group_type;
                    // newCustomFieldValues.push(customField);

                    const foundCustomFieldValue = find(customFieldValues, (item) => item.custom_field_id == customField.custom_field_id);
                    if (!foundCustomFieldValue) {
                        delete customField.created_at;
                        delete customField.creator_id;
                        delete customField.updated_at;

                        newCustomFieldValues.push({
                            ...customField,
                            value: '',
                        });
                    }
                }
            }
            if (typeTg == props.groupType) {
                setCustomFieldValues(
                    produce(customFieldValues, (draft) => {
                        draft.push(...newCustomFieldValues);
                    })
                );
            }
        }
    }, [productReducer.customFields]);

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    });

    useImperativeHandle(ref, () => ({
        getData() {
            return produce(customFieldValues, (draft) => {});
        },
    }));

    const getValueByCustomFieldID = (id, isShowDate = false) => {
        const result = find(customFieldValues, (item) => item.custom_field_id == id);
        if (result && result.value) {
            if (isShowDate) {
                return baseHelper.momentToDate(moment(result.value));
            }
            return result.value;
        }
        return '';
    };

    const onChangeCustomFieldInput = (e, customField) => {
        const updatedArrays = produce(customFieldValues, (draft) => {
            const result = find(draft, (item) => item.custom_field_id == customField);
            result.value = e.target.value;
        });
        setCustomFieldValues(updatedArrays);
    };

    const onChangeDate = (value, customField) => {
        const updatedArrays = produce(customFieldValues, (draft) => {
            const result = find(draft, (item) => item.custom_field_id == customField);
            result.value = value;
        });

        setCustomFieldValues(updatedArrays);
    };

    const handleChangeDropdown = (item, customFieID) => {
        const updatedArrays = produce(customFieldValues, (draft) => {
            const result = find(draft, (item) => item.custom_field_id == customFieID);
            result.value = item.CODE;
        });
        setCustomFieldValues(updatedArrays);
        setValueDropDown(item.NAME);
    };

    const getDataDropdown = (customField) => {
        const customFieldValue = find(customFieldValues, (item) => item.custom_field_id == customField.custom_field_id);
        let dropdownValue = '';
        if (customFieldValue && customField.data_values) {
            dropdownValue = customField.data_values.split('\n');
            if (dropdownValue) {
                const data = dropdownValue.map((item, index) => {
                    item = {
                        NAME: item.split('|')[0],
                        CODE: item.split('|')[1],
                    };
                    return item;
                });
                return data;
            }
        }
        return [];
    };

    const saveCustomField = () => {
        const orderID = props.orderID;
        const data = {
            order_id: orderID,
            custom_fields: customFieldValues,
        };
        dispatch(productConfigActions.updateCustomFieldByOrder(data));
    };

    const handleCancelForm = () => {
        setActiveFormEdit(!activeFormEdit);
        setCustomFieldValues(produce(props.customFields, (draft) => {}));
    };
    const handleActiveForm = () => {
        setActiveFormEdit(!activeFormEdit);
        setIsShowBlockCustomField(true);
    };

    const toggleBlockCustomField = () => {
        setIsShowBlockCustomField(!isShowBlockCustomField);
    };

    const isLoadingCheckoutConfig =
        checkoutConfigReducer.loading && checkoutConfigReducer.waiting == checkoutConfigTypes.LIST_CHECKOUT_CONFIG;
    const isLoadingVerifiedDomain = domainReducer.loading && domainReducer.waiting == domainTypes.LIST_VERIFIED_DOMAIN;

    const orderType = appConfig.CUSTOM_FIELD.GROUP_TYPES.ORDER.CODE;
    const productType = appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE;
    const customerType = appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE;
    const actionEdit = appConfig.FORM_MODE.EDIT;
    const actionCreate = appConfig.FORM_MODE.CREATE;
    return (
        <React.Fragment>
            {(isLoadingCheckoutConfig || isLoadingVerifiedDomain) && <LoadingScene blur={true} />}
            <div className={`ladiui form-group mt-0 mb-0 custom-fields ${isShowOrderCF ? '' : 'hide'}`}>
                {/* {
                    groupType == orderType &&
                    <div className="hidden-custom-field">
                        <a href="#" onClick={() => { toggleBlockCustomField() }} >
                            <i className="ladi-icon icon-down-arrow"></i>
                        </a>
                    </div>
                } */}
                {/* {
                    groupType == orderType && actionType == actionEdit
                    &&
                    <div className="customer-info customer-edit-custom-field">
                        {
                            !activeFormEdit
                                ?
                                <a className="ladiui" onClick={() => { handleActiveForm() }} >{t('ACTIONS.EDIT')}</a>
                                :
                                <>
                                    <a className="ladiui" onClick={() => { handleCancelForm() }} >{t('CUSTOM_FIELD.CANCEL')}</a>
                                    <a className="ladiui" onClick={() => { saveCustomField() }} >{t('CUSTOM_FIELD.SAVE')}</a>
                                </>
                        }
                    </div>
                } */}
                {
                    // groupType == orderType && isShowBlockCustomField || groupType == productType || groupType == customerType ?
                    groupType == orderType || groupType == productType || groupType == customerType ? (
                        <div className={groupType == orderType ? 'custom-field-content' : 'custom-field-content'}>
                            {/* {dataCustomFields.map((item, index) => { */}
                            {customFieldValues.map((item, index) => {
                                switch (item.data_type) {
                                    case appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.CODE: {
                                        return (
                                            <div key={index} className='date-item mt-20'>
                                                <label className='ladiui-label'>{item.label}</label>
                                                {
                                                    // groupType == orderType && actionType == actionEdit && !activeFormEdit
                                                    //     ?
                                                    //     <span>{getValueByCustomFieldID(item.custom_field_id)}</span>
                                                    //     :
                                                    <Input
                                                        name='name'
                                                        type='number'
                                                        placeholder={t('PRODUCTS.ENTER_CONTENT_VALUES')}
                                                        value={getValueByCustomFieldID(item.custom_field_id)}
                                                        onChange={(e) => {
                                                            onChangeCustomFieldInput(e, item.custom_field_id);
                                                        }}
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                    case appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.CODE: {
                                        return (
                                            <div key={index} className='date-item mt-20'>
                                                <label className='ladiui-label'>{item.label}</label>
                                                {
                                                    <DatePicker
                                                        selected={getValueByCustomFieldID(item.custom_field_id, true)}
                                                        onChange={(value) => {
                                                            onChangeDate(value, item.custom_field_id);
                                                        }}
                                                        dateFormat={appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE}
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                    case appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.CODE: {
                                        return (
                                            <div key={index} className='date-item mt-20'>
                                                <label className='ladiui-label'>{item.label}</label>
                                                {
                                                    <Input
                                                        name='name'
                                                        type='text'
                                                        placeholder={t('PRODUCTS.ENTER_CONTENT')}
                                                        value={getValueByCustomFieldID(item.custom_field_id)}
                                                        onChange={(e) => {
                                                            onChangeCustomFieldInput(e, item.custom_field_id);
                                                        }}
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                    case appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.CODE: {
                                        return (
                                            <div key={index} className='date-item mt-20'>
                                                <label className='ladiui-label'>{item.label}</label>
                                                {
                                                    <textarea
                                                        className='ladiui textarea'
                                                        value={getValueByCustomFieldID(item.custom_field_id)}
                                                        onChange={(e) => {
                                                            onChangeCustomFieldInput(e, item.custom_field_id);
                                                        }}
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                    case appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE: {
                                        return (
                                            <div key={index} className='date-item mt-20'>
                                                <label className='ladiui-label'>{item.label}</label>
                                                {
                                                    <Dropdown
                                                        data={getDataDropdown(item)}
                                                        onSelectItem={(data) => {
                                                            handleChangeDropdown(data, item.custom_field_id);
                                                        }}
                                                        currentKey={getValueByCustomFieldID(item.custom_field_id)}
                                                        _key='CODE'
                                                        _value='NAME'
                                                        placeHolder={t('CUSTOM_FIELD.CHOOSE_DATA')}
                                                        // wrapperClassName='w100 position-initial'
                                                        wrapperClassName='w100'
                                                        idBlock='dropdown-customer-field'
                                                    />
                                                }
                                            </div>
                                        );
                                    }
                                }
                            })}
                        </div>
                    ) : (
                        ''
                    )
                }
            </div>
        </React.Fragment>
    );
});

export default PanelCustomField;
