import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.LIST_TICKET_SEAT: {
            return {
                ...state,
                action: types.LIST_TICKET_SEAT,
                ticketSeats: action.status ? action.payload.ticket_seats : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                totalCheckin: action.status ? action.payload.total_checkin : 0,
                productName: action.status ? action.payload.product_name : '',
                status: action.status,
                message: action.message,
            };
        }
        case types.SHOW_TICKET_SEAT: {
            return {
                ...state,
                action: types.SHOW_TICKET_SEAT,
                ticketSeat: action.status ? action.payload.ticketSeat : {},
                status: action.status,
                message: action.message,
            };
        }
        case types.UPDATE_TICKET_SEAT: {
            return {
                ...state,
                action: types.UPDATE_TICKET_SEAT,
                ticketSeat: action.status ? action.payload.ticketSeat : {},
                status: action.status,
                message: action.message,
            };
        }
        case types.CHANGE_CHECK_IN: {
            return {
                ...state,
                action: types.CHANGE_CHECK_IN,
                status: action.status,
                message: action.message,
                ticket_seat_id: action.status ? action.payload.ticket_seat_id : null,
            };
        }
        case types.DELETE_TICKET_SEAT: {
            return {
                ...state,
                action: types.DELETE_TICKET_SEAT,
                status: action.status,
                message: action.message,
            };
        }
        case types.CHECK_IN_TICKET_SEAT: {
            return {
                ...state,
                action: types.CHECK_IN_TICKET_SEAT,
                status: action.status,
                message: action.message,
                product_id: action.status ? action.payload.product_id : null,
                product_type: action.status ? action.payload.product_type : null,
                ticket_seat_id: action.status ? action.payload.ticket_seat_id : null,
                ticket_seat_status: action.status ? action.payload.status : 0,
            };
        }
        case types.IMPORT_TICKET_SEAT: {
            return {
                ...state,
                action: types.IMPORT_TICKET_SEAT,
                status: action.status,
                message: action.message,
            };
        }
        case types.EXPORT_TICKET_SEAT: {
            return {
                ...state,
                action: types.EXPORT_TICKET_SEAT,
                status: action.status,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
