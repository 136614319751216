export const INTEGRATE_FORM_ACCOUNT_LIST = 'INTEGRATE_FORM_ACCOUNT_LIST';
export const INTEGRATE_FORM_ACCOUNT_IN_FORM_CONFIG_LIST = 'INTEGRATE_FORM_ACCOUNT_IN_FORM_CONFIG_LIST';
export const INTEGRATE_FORM_ACCOUNT_SHOW = 'INTEGRATE_FORM_ACCOUNT_SHOW';
export const INTEGRATE_FORM_ACCOUNT_UPDATE = 'INTEGRATE_FORM_ACCOUNT_UPDATE';
export const INTEGRATE_FORM_ACCOUNT_DELETES = 'INTEGRATE_FORM_ACCOUNT_DELETES';
export const INTEGRATE_OAUTH2_GET_LINK = 'INTEGRATE_OAUTH2_GET_LINK';
export const INTEGRATE_OAUTH_ACCESS = 'INTEGRATE_OAUTH_ACCESS';
export const INTEGRATE_FORM_ACCOUNT_LOAD_DATA = 'INTEGRATE_FORM_ACCOUNT_LOAD_DATA';
export const INTEGRATE_FORM_ACCOUNT_LOAD_CUSTOM_FIELDS = 'INTEGRATE_FORM_ACCOUNT_LOAD_CUSTOM_FIELDS';
export const INTEGRATE_FORM_ACCOUNT_RESET_DATA = 'INTEGRATE_FORM_ACCOUNT_RESET_DATA';
export const INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET = 'INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET';
export const INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET = 'INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET';
export const INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET = 'INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET';
export const INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID = 'INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID';
export const INTEGRATE_GOOGLE_SHEETS = 'INTEGRATE_GOOGLE_SHEETS';
export const INTEGRATE_OAUTH2_GOOGLE_SHEETS = 'INTEGRATE_OAUTH2_GOOGLE_SHEETS';
export const INTEGRATE_HARAVAN = 'INTEGRATE_HARAVAN';
export const INTEGRATE_CREATE_HARAVAN = 'INTEGRATE_CREATE_HARAVAN';
export const INTEGRATE_SAPO = 'INTEGRATE_SAPO';
export const INTEGRATE_CREATE_SAPO = 'INTEGRATE_CREATE_SAPO';
export const INTEGRATE_SHOPIFY = 'INTEGRATE_SHOPIFY';
export const INTEGRATE_CREATE_SHOPIFY = 'INTEGRATE_CREATE_SHOPIFY';
export const INTEGRATE_NHANH = 'INTEGRATE_NHANH';
export const INTEGRATE_CREATE_NHANH = 'INTEGRATE_CREATE_NHANH';
export const LIST_ALL_CONNECT = 'LIST_ALL_CONNECT';




