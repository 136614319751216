import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../../../../../../config/app';
import baseHelper from '../../../../../../helpers/BaseHelper';
import pageCheckoutDefined from '../../../components/pageCheckoutDefined';

import HandelSection from '../HandelSection';

// eslint-disable-next-line max-lines-per-function

const WidgetShipping = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, widgetConfig } = props;

    const handelSectionRef = useRef();

    useImperativeHandle(ref, () => ({
        getData: () => {
            let sections = handelSectionRef.current.getData();

            return sections;
        },
    }));

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'}`}>
            <div>
                <div className='ladiui text-14'>Thêm các hiển thị cần thiết ở trang thông tin vận chuyển</div>
            </div>
            <HandelSection
                ref={handelSectionRef}
                sectionData={widgetConfig || pageCheckoutDefined.WIDGET_SHIPPING_DEFAULT}
                type='WIDGET_SHIPPING'
            />
        </div>
    );
});

export default WidgetShipping;
