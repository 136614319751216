import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_DOMAIN,
    meta: {
        endpoint: endpoint.LIST_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const listVerified = (data) => ({
    type: types.LIST_VERIFIED_DOMAIN,
    meta: {
        endpoint: endpoint.LIST_VERIFIED_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const _delete = (domain) => ({
    type: types.DELETE_DOMAIN,
    meta: {
        endpoint: endpoint.DELETE_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: {
            domain: domain,
        },
        hasAuth: true,
    },
});

const create = (data) => ({
    type: types.CREATE_DOMAIN,
    meta: {
        endpoint: endpoint.CREATE_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const verify = (domain) => ({
    type: types.VERIFY_DOMAIN,
    meta: {
        endpoint: endpoint.VERIFY_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: {
            domain: domain,
        },
        hasAuth: true,
    },
});

const markDefault = (domain) => ({
    type: types.MARK_DEFAULT_DOMAIN,
    meta: {
        endpoint: endpoint.MARK_DEFAULT_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: {
            domain,
        },
        hasAuth: true,
    },
});

const removeDefault = (domain) => ({
    type: types.REMOVE_DEFAULT_DOMAIN,
    meta: {
        endpoint: endpoint.REMOVE_DEFAULT_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: {
            domain,
        },
        hasAuth: true,
    },
});

const listDomainStore = (data) => ({
    type: types.LIST_DOMAIN_STORE,
    meta: {
        endpoint: endpoint.LIST_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: {
            ...data,
            types: 'PAGE_STORE',
        },
        hasAuth: true,
    },
});

export default {
    list,
    listVerified,
    delete: _delete,
    create,
    verify,
    markDefault,
    removeDefault,
    listDomainStore,
};
