import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import * as pageCheckoutTypes from '../../../redux/futures/page_checkout/types';
import pageCheckoutActions from '../../../redux/futures/page_checkout/actions';

import domainActions from '../../../redux/futures/domain/actions';
import Dropdown from '../../../components/Dropdown';
import pageCheckoutDefined from './components/pageCheckoutDefined';
import Popover from '../../../components/Popover';
import SelectOption from '../../../components/SelectOption';
import Input from '../../../components/Input';
import ModalAddDomain from '../../../components/ModalAddDomain';
import config from '../../../config/config';

// eslint-disable-next-line max-lines-per-function
const Header = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const popoverRef = useRef();

    const domainReducer = useSelector((state) => state.domainReducer);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const { handleCreatePageCheckout, pageCheckout, isShowDesktop, setShowDesktop, setDnsUrl, pageDns, setPageDns } = props;

    // const [pageDns, setPageDns] = useState(pageCheckoutDefined.DNS_PAGE[0]);
    const [domainSelect, setDomainSelect] = useState('');
    const [domainStoreSelect, setDomainStoreSelect] = useState('');
    const [path, setPath] = useState('');
    const [isChangeName, setOnChangeName] = useState(false);
    const [pageCheckoutName, setPageCheckoutName] = useState('');
    const [isShowModalAddDomain, setShowModalAddDomain] = useState(false);
    const [domainAddType, setDomainAddType] = useState('PAGE_STORE');

    const isLoadingDomain = useMemo(() => {
        return domainReducer.loading || false;
    }, [domainReducer.loading]);

    const domains = useMemo(() => {
        return domainReducer.domains || [];
    }, [domainReducer.domains]);

    const storeDomains = useMemo(() => {
        return domainReducer.store_domains || [];
    }, [domainReducer.store_domains]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            return { name: pageCheckoutName, domainSelect, path, domainStoreSelect };
        },
    }));

    useEffect(() => {
        listDomain();
        listDomainStore();

        if (pageCheckout.types == appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE) setPageDns(pageCheckoutDefined.DNS_PRODUCT[0]);

        setPageCheckoutName(pageCheckout.name);
        setOnChangeName(false);
    }, [pageCheckout]);

    useEffect(() => {
        setDomainSelect(pageCheckout.domain);
        setPath(pageCheckout.path);

        if (domains && domains.length > 0 && !pageCheckout.domain) setDomainSelect(domains[0].domain);
    }, [domains, pageCheckout]);

    useEffect(() => {
        setDomainStoreSelect(pageCheckout.domain_store);

        // if (storeDomains && storeDomains.length > 0 && !pageCheckout.domain_store) setDomainStoreSelect(storeDomains[0].domain);
    }, [storeDomains, pageCheckout]);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.PAGE_CHECKOUT_UPDATE_NAME) {
            if (pageCheckoutReducer.status) {
                setOnChangeName(false);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', pageCheckoutReducer.message, 'OK');
            }
        }
    }, [pageCheckoutReducer.action]);

    const listDomain = () => {
        let dataSend = {
            types: appConfig.DOMAIN_TYPE[0].CODE,
            is_verified: appConfig.STATUS.ACTIVE,
        };
        dispatch(domainActions.list(dataSend));
    };

    const listDomainStore = () => {
        if (pageCheckout && pageCheckout.types) {
            let dataSend = {
                is_verified: appConfig.STATUS.ACTIVE,
            };

            if (pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE) dataSend.types = appConfig.DOMAIN_TYPE[1].CODE;

            dispatch(domainActions.listDomainStore(dataSend));
        }
    };

    const handleSaveName = (e) => {
        e.preventDefault();
        let dataSend = {
            page_checkout: {
                page_checkout_id: pageCheckout.page_checkout_id,
                name: pageCheckoutName,
            },
        };
        dispatch(pageCheckoutActions.updateName(dataSend));
    };

    return (
        // <div className=' page-checkout-head'>
        <div className='page-checkout-head'>
            {isShowModalAddDomain && (
                <ModalAddDomain
                    isShowModal={isShowModalAddDomain}
                    onClose={() => setShowModalAddDomain(false)}
                    isSubmitLoading={isLoadingDomain}
                    type={domainAddType}
                />
            )}
            <div className='ladiui display-flex-center gap-16 page-checkout-back-name'>
                <div
                    className='display-flex-center'
                    onClick={() => {
                        let is_iframe = true;
                        // if (is_iframe) {
                        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_LIST}`;
                        if (pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE) url += `&query_ls=${JSON.stringify({ tab: 1 })}`;
                        window.open(url, '_blank');

                        // } else history.push('/page-checkout');
                    }}
                >
                    <i className='ladiui icon-new new-ldicon-logout' />
                </div>
                {!isChangeName ? (
                    <div className='ladiui flex-center gap-8'>
                        <span className='ladiui label-16 page-checkout-name'>
                            {pageCheckoutName ? pageCheckoutName : t('PAGE_CHECKOUT.MY_PAGE')}
                        </span>
                        <div className='ladiui flex' onClick={() => setOnChangeName(true)}>
                            <i className='new-ldicon-edit' />
                        </div>
                    </div>
                ) : (
                    <div className='ladiui flex-center gap-8 page-checkout-name-input'>
                        <Input
                            value={pageCheckoutName}
                            onChange={(e) => {
                                setPageCheckoutName(e.target.value);
                            }}
                        />

                        <div className='ladiui flex' onClick={handleSaveName}>
                            <i className='ldicon-save ladiui icon-new' />
                        </div>
                    </div>
                )}
            </div>

            <div className='page-type-view-type'>
                <div className='page-type'>
                    <Dropdown
                        innerClassName='ladiui boder-none w-200 btn-page-view'
                        placeHolder='Chọn trang thanh toán'
                        data={
                            pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE
                                ? pageCheckoutDefined.DNS_PAGE
                                : pageCheckoutDefined.DNS_PRODUCT
                        }
                        currentKey={pageDns.type}
                        iconClass={pageDns.icon}
                        _key='type'
                        _value='title'
                        onSelectItem={(item) => {
                            setPageDns(item);

                            let url = `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/page/${pageCheckout.path}${
                                item.endPoint
                            }`;
                            if (pageCheckout.types != appConfig.PAGE_CHECKOUT_TYPE.STORE)
                                url = `${baseHelper.getDomainPageCheckout(appConfig.URL_CHECKOUT_DEFAULT)}/${pageCheckout.path}${
                                    item.endPoint
                                }`;
                            if (item.type == 'PAGE_CHECKOUT') {
                                url += `&checkout_config_id=${pageCheckout.checkout_config_id}`;
                            }
                            setDnsUrl(url);
                        }}
                    />
                </div>
                <div className='icon-desktop-mobile-content'>
                    <div className='icon-desktop-mobile'>
                        <div className={`icon-item tooltip ${isShowDesktop ? 'active' : ''}`} onClick={() => setShowDesktop(true)}>
                            <i className='ladiui icon-new new-ldicon-desktop' />
                            <span className='tooltiptext bottom' tooltiptext='Desktop'></span>
                        </div>
                        <div className={`icon-item tooltip  ${isShowDesktop ? '' : 'active'}`} onClick={() => setShowDesktop(false)}>
                            <i className='ladiui icon-new new-ldicon-mobile' />
                            <span className='tooltiptext bottom' tooltiptext='Mobile'></span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ladiui head-actions display-flex-center gap-16'>
                <Popover
                    ref={popoverRef}
                    wrapperProps={{ className: 'price-option' }}
                    wrapperPopoverProps={{ className: 'price-box popover-checkout ladiui popover-right' }}
                    content={
                        <div>
                            <div className='popover-shipping'>
                                {pageCheckout && pageCheckout.types == appConfig.PAGE_CHECKOUT.STORE.CODE ? (
                                    <div className=''>
                                        <div className='ladiui flex gap-8'>
                                            <a href={`https://${appConfig.DOMAIN_CHECKOUT_DEFAULT}/page/${path}`} target='_blank'>
                                                {`https://${appConfig.DOMAIN_CHECKOUT_DEFAULT}/page/${path}`}
                                            </a>
                                            <i
                                                className='ladiui icon-new new-ldicon-copy'
                                                onClick={() =>
                                                    baseHelper.copyToClipboard(`https://${appConfig.DOMAIN_CHECKOUT_DEFAULT}/page/${path}`)
                                                }
                                            />
                                        </div>
                                        <div className='mt-8 ladiui text-12 text-secondary'>{t('PAGE_CHECKOUT_V2.DOMAIN_DEFAULT_SUB')}</div>
                                        <div className='redirect-or'>
                                            <div className='redirect-or-boder' />
                                        </div>

                                        <div className=' w-100-p mt-20'>
                                            <div className='ladiui text-14 mb-4'>Sử dụng tền miền riêng</div>
                                            <div className='display-flex-center gap-8 mt-8'>
                                                <SelectOption
                                                    headerItems={pageCheckoutDefined.DOMAIN_STORE_HEADER_ITEMS}
                                                    customClassName='btn-select-domain-store'
                                                    headerKey={'key'}
                                                    headerValue={'name'}
                                                    items={storeDomains || []}
                                                    currentKey={domainStoreSelect}
                                                    classBtnCustom='btn-url-page-checkout'
                                                    innerClassName='btn-selected'
                                                    placeHolder='Chọn tên miền'
                                                    _key={'domain'}
                                                    _value={'domain'}
                                                    isShowDelete={true}
                                                    onSelectItem={(item) => {
                                                        if (!item) {
                                                            setDomainStoreSelect('');
                                                            return;
                                                        }
                                                        if (item.domain) {
                                                            setDomainStoreSelect(item.domain);
                                                        } else {
                                                            switch (item.key) {
                                                                case 'create':
                                                                    let is_iframe = true;
                                                                    if (is_iframe) {
                                                                        let query_ls = { is_new: true };
                                                                        window.open(
                                                                            `${config.BUILDER_LDP}${
                                                                                appConfig.LADIPAGE_URL.SETTING_DOMAIN
                                                                            }&query_ls=${JSON.stringify(query_ls)}`
                                                                        );
                                                                    } else window.open('/settings?is_new=true&tab=DOMAIN');
                                                                    // setDomainAddType('PAGE_STORE');
                                                                    // setShowModalAddDomain(true);

                                                                    break;
                                                                case 'refresh':
                                                                    listDomainStore();
                                                                    break;
                                                            }
                                                        }
                                                    }}
                                                />

                                                <div
                                                    className={`coppy-btn ${!domainStoreSelect ? 'disabled' : ''}`}
                                                    onClick={() => {
                                                        if (domainStoreSelect) {
                                                            baseHelper.copyToClipboard(baseHelper.domainToUrl(domainStoreSelect));
                                                        }
                                                    }}
                                                >
                                                    <i className='ladiui icon-new new-ldicon-copy' />
                                                </div>

                                                {/* {domainStoreSelect ? (
                                                    <a
                                                        className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                                        href={`${baseHelper.domainToUrl(domainStoreSelect, false)}`}
                                                        target='_blank'
                                                    >
                                                        Truy cập
                                                    </a>
                                                ) : (
                                                    <div className='redirect-btn ladiui btn btn-outline-primary btn-md' disabled>
                                                        Truy cập
                                                    </div>
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='display-flex-center'>
                                        <div className='mr-16 w-100-p'>
                                            <div className='ladiui text-14 mb-4'>Chọn tên miền</div>
                                            <div className='display-flex-center gap-12'>
                                                <SelectOption
                                                    customClassName='w-100-p'
                                                    headerItems={pageCheckoutDefined.DOMAIN_PRODUCT_HEADER_ITEMS}
                                                    headerKey={'key'}
                                                    headerValue={'name'}
                                                    items={domains || []}
                                                    currentKey={domainSelect}
                                                    currentValue={domainSelect}
                                                    placeHolder='Chọn tên miền'
                                                    _key={'domain'}
                                                    _value={'domain'}
                                                    onSelectItem={(item) => {
                                                        if (item.domain) {
                                                            setDomainSelect(item.domain);
                                                        } else {
                                                            switch (item.key) {
                                                                case 'create':
                                                                    // window.open('/settings?is_new=true&tab=DOMAIN');
                                                                    // setDomainAddType('PAGE_CHECKOUT_PRODUCT');
                                                                    // setShowModalAddDomain(true);
                                                                    let is_iframe = true;
                                                                    if (is_iframe) {
                                                                        window.open(
                                                                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_DOMAIN}`
                                                                        );
                                                                    } else window.open('/settings?is_new=true&tab=DOMAIN');
                                                                    break;
                                                                case 'refresh':
                                                                    listDomain();
                                                                    break;
                                                            }
                                                        }
                                                    }}
                                                />
                                                <div>/</div>
                                                <div className='path-input'>
                                                    <Input className='pr-32' value={path} onChange={(e) => setPath(e.target.value)} />
                                                    <i
                                                        className='ladiui icon-new new-ldicon-copy'
                                                        onClick={() => {
                                                            baseHelper.copyToClipboard(`${baseHelper.domainToUrl(domainSelect)}/${path}`);
                                                        }}
                                                    />
                                                </div>

                                                <a
                                                    className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                                    href={`${baseHelper.domainToUrl(domainSelect)}/${path}`}
                                                    target='_blank'
                                                >
                                                    Truy cập
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                >
                    <div
                        className={`ladiui page-checkout-link display-flex-center gap-8`}
                        onClick={() => {
                            // if (isEditableOrder) fullSubmit();
                            // else partialSubmit();
                        }}
                    >
                        <i className='ladiui icon-new new-ldicon-link2' />
                        {t('PAGE_CHECKOUT.SETUP_LINK')}
                    </div>
                </Popover>

                <button
                    className={`ladiui btn btn-sm btn-secondary text-14 text-primary`}
                    onClick={() => {
                        let url = pageCheckout.url_published;
                        if (domainStoreSelect) url = baseHelper.domainToUrl(domainStoreSelect);
                        window.open(url);
                    }}
                >
                    Xem trang
                </button>

                <button className={`ladiui btn btn-sm btn-primary `} onClick={handleCreatePageCheckout}>
                    Cập nhật
                </button>
            </div>
        </div>
        // </div>
    );
});

export default Header;
