import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../config/app';
import Dropdown from '../../../components/Dropdown';

import printActions from '../../../redux/futures/print/actions';

// eslint-disable-next-line max-lines-per-function
function Header(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const { submit, print, shippingID } = props;

    return (
        <div className='ladiui header-o'>
            <div className='ladiui header'>
                <div className='ladiui order-breadcrumbs'>
                    <span
                        className='back-btn'
                        onClick={() => {
                            if (history.length > 1) history.goBack();
                            else history.push('/orders?tab=3');
                        }}
                    >
                        {t('SHIPPINGS.TITLE')}
                    </span>
                    <i className='icon ldicon-chevron-right' />
                    <span className='ladiui label-16'> {`${t('SHIPPINGS.TITLE')} #${shippingID}`} </span>
                </div>
                <div className='order-head-actions'>
                    <button className={`ladiui btn btn-md btn-secondary display-flex-center `} onClick={print}>
                        <i className='ldicon-printer mr-8' />
                        {t('SHIPPINGS.PRINT')}
                    </button>

                    <button className={`ladiui btn btn-md btn-primary `} onClick={submit}>
                        {t('SHIPPINGS.SAVE')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Header;
