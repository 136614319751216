import {
    SIGNIN_SUCCESS
} from '../futures/auth/types';

import socket from '../../socket';

const notificationMiddleware = store => next => action => {
    if (action.type === SIGNIN_SUCCESS) {
        // Khởi tạo socket khi đã đăng nhập thành công
        socket.init(action.payload.store_id);
    }

    next(action);
};

export default notificationMiddleware;
