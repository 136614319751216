import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { useTranslation } from "react-i18next";
import producer from "immer";

import storeActions from "../../../redux/futures/store/actions";
import * as storeTypes from "../../../redux/futures/store/types";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";

export default function PageSettingTracking(props) {
    const { t } = useTranslation();
    const storeReducer = useSelector((state) => state.store);

    const dispatch = useDispatch();
    const [tracking, setTracking] = useState({
        facebook_pixel: "",
        google_analytics_id: "",
        google_ads_id: "",
        google_ads_id_label: "",
        tiktok_pixel: "",
        google_tag_manager_id: "",
        custom_javascript_head: "",
        custom_javascript_body: "",
    });

    useEffect(() => {
        dispatch(storeActions.showTracking());
    }, []);

    useEffect(() => {
        if (storeReducer.action == storeTypes.UPDATE_TRACKING_STORE) {
            if (storeReducer.status) {
                window.LadiUI.toastCustom("success", "", storeReducer.message);
            } else {
                window.LadiUI.toastCustom("danger", "", storeReducer.message);
            }
        }

        if (storeReducer.action == storeTypes.SHOW_TRACKING_STORE) {
            if (storeReducer.status) {
                setTracking(storeReducer.tracking);
            } else {
                window.LadiUI.toastCustom("danger", "", storeReducer.message);
            }
        }
    }, [storeReducer]);

    const onChangeInput = (event) => {
        // setTracking(
        //   producer(tracking, (draft) => {
        //     draft[event.target.name] = event.target.value;
        //   })
        // );
        setTracking({
            ...tracking,
            [event.target.name]: event.target.value
        })
    };

    const submit = () => {
        dispatch(storeActions.updateTracking(JSON.stringify(tracking)));
    };

    return (
        <>
            <div className="page-setting-tracking-v4">
                <div className="header-title">
                    <div className="title-desc">
                        <h3>Mã chuyển đổi</h3>
                        <p>
                            Kiểm tra và quản lý các thông tin về mã chuyển đổi.
                        </p>
                    </div>
                    <div className='action'>
                        <button className='ladiui btn btn-primary' onClick={submit}>Lưu thay đổi</button>
                    </div>
                </div>
                <div className="content">
                    <div className="list-blocks">
                        <div className="block-item">
                            <div className="title-block">
                                <h3>Chỉnh sửa mã chuyển đổi</h3>
                                <p>Mã chuyển đổi là nơi kết nối với các nền tảng bán hàng.</p>
                            </div>
                            <div className="content-block">
                                <div className="ladiui form-group mt-0">
                                    <label className="ladiui-label new">{t("TRACKING.FB_PIXEL_ID")}</label>
                                    <Input
                                        name="facebook_pixel"
                                        placeholder={t("TRACKING.FB_PIXEL_ID_PLACE_HOLDER")}
                                        value={tracking.facebook_pixel}
                                        onChange={onChangeInput}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("TRACKING.GG_ANALYTICS_ID")}</label>
                                    <Input
                                        name="google_analytics_id"
                                        placeholder={t("TRACKING.GG_ANALYTICS_ID_PLACE_HOLDER")}
                                        value={tracking.google_analytics_id}
                                        onChange={onChangeInput}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("TRACKING.GG_ADS_ID")}</label>
                                    <Input
                                        name="google_ads_id"
                                        placeholder={t("TRACKING.GG_ADS_ID_PLACE_HOLDER")}
                                        value={tracking.google_ads_id}
                                        onChange={onChangeInput}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("TRACKING.GG_ADS_ID_LABEL")}</label>
                                    <Input
                                        name="google_ads_id_label"
                                        placeholder={t("TRACKING.GG_ADS_ID_LABEL_PLACE_HOLDER")}
                                        value={tracking.google_ads_id_label}
                                        onChange={onChangeInput}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("TRACKING.TT_PIXEL_ID")}</label>
                                    <Input
                                        name="tiktok_pixel"
                                        placeholder={t("TRACKING.TT_PIXEL_ID_PLACE_HOLDER")}
                                        value={tracking.tiktok_pixel}
                                        onChange={onChangeInput}
                                    />
                                </div>

                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("TRACKING.GG_TAG_MANAGER_ID")}</label>
                                    <Input
                                        name="google_tag_manager_id"
                                        placeholder={t("TRACKING.GG_TAG_MANAGER_ID_PLACE_HOLDER")}
                                        value={tracking.google_tag_manager_id}
                                        onChange={onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="line-space-block"></div>
                        <div className="block-item">
                            <div className="title-block">
                                <h3>Mã Javascript / CSS</h3>
                                <p>Nội dung mã javascript</p>
                            </div>
                            <div className="content-block">
                                <div className="ladiui form-group mt-0">
                                    <label className="ladiui-label new">{t("SETTINGS.TRACKING_BEFORE_HEAD")}</label>
                                    <AceEditor
                                        mode="javascript"
                                        theme="github"
                                        onChange={(newValue) => {
                                            const event = {
                                                target: {
                                                    name: "custom_javascript_head",
                                                    value: newValue,
                                                },
                                            };
                                            onChangeInput(event);
                                        }}
                                        name="custom_javascript_head"
                                        height="200px"
                                        width="100%"
                                        value={tracking.custom_javascript_head}
                                    />
                                </div>
                                <div className="ladiui form-group">
                                    <label className="ladiui-label new">{t("SETTINGS.TRACKING_BEFORE_BODY")}</label>
                                    <AceEditor
                                        mode="javascript"
                                        theme="github"
                                        onChange={(newValue) => {
                                            const event = {
                                                target: {
                                                    name: "custom_javascript_body",
                                                    value: newValue,
                                                },
                                            };
                                            onChangeInput(event);
                                        }}
                                        name="custom_javascript_body"
                                        height="200px"
                                        width="100%"
                                        value={tracking.custom_javascript_body}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="line-space-block"></div>
                    </div>
                </div>
            </div>
        </>
    )
}