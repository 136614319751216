import React, { useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../../config/app';

import Input from '../../../../components/Input';
import Popover from '../../../../components/Popover';
import NumberInput from '../../../../components/NumberInput';

function PopoverCoupon(props) {
    const { t } = useTranslation();
    const { disabledApply, removeDiscount, applyDiscount, selectDiscount } = props;
    const popoverRef = useRef();
    const storeReducer = useSelector((state) => state.store);
    const [type, setType] = useState(appConfig.ORDER_COUPON_TYPES[0]);
    const [discountType, setDiscountType] = useState(appConfig.DISCOUNT.TYPE.FIXED);

    const [discount, setDiscount] = useState({
        value: 0,
        note: '',
        code: '',
    });

    const remove = () => {
        removeDiscount();
        popoverRef.current.instanceRef.hide();
    };
    const apply = () => {
        if (discountType.value == appConfig.DISCOUNT.TYPE.PERCENT && discount.value > 100) {
            window.LadiUI.toastCustom('danger', '', t('DISCOUNTS.DISCOUNT_MAX'));
            return;
        }
        applyDiscount({
            ...discount,
            type: discountType.value,
        });
        popoverRef.current.instanceRef.hide();
    };

    return (
        <Popover
            ref={popoverRef}
            wrapperProps={{ className: 'price-option' }}
            wrapperPopoverProps={{ className: 'price-box' }}
            // onShow={onShow}
            content={
                <div>
                    <div className='popover-discount'>
                        <div className='ladiui value-list'>
                            {appConfig.ORDER_COUPON_TYPES.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='value-item'
                                        onClick={() => {
                                            setType(item);
                                            setDiscount({
                                                value: 0,
                                                note: '',
                                                code: '',
                                            });
                                        }}
                                    >
                                        <input
                                            type='radio'
                                            className='ladiui form-check-input'
                                            name='radio-group'
                                            onChange={() => {}}
                                            checked={type.value == item.value}
                                        />
                                        <label>{item.name}</label>
                                    </div>
                                );
                            })}
                        </div>
                        {type.value == appConfig.STATUS.INACTIVE ? (
                            <div className='mt-12'>
                                <div className='popover-discount-type-value'>
                                    <div className='discount-type'>
                                        <label className='ladiui text-14'>{t('DISCOUNTS.RULE_TYPE.TITLE')}</label>
                                        <div className='ladiui btn-group mt-4'>
                                            <div className='ladiui dropdown'>
                                                <button className={`ladiui btn btn-outline-light dropdown-toggle`} data-toggle='dropdown'>
                                                    {discountType.name}
                                                </button>
                                                <ul className={`ladiui dropdown-menu`}>
                                                    {Object.values(appConfig.DISCOUNT.TYPE).map((item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                onClick={() => {
                                                                    setDiscountType(item);
                                                                }}
                                                            >
                                                                <a className='ladiui dropdown-item'>{item.name}</a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='discount-value'>
                                        <label className='ladiui text-14'>{t('DISCOUNTS.DISCOUNT_VALUE')}</label>

                                        <NumberInput
                                            name='value'
                                            className='mt-4'
                                            min={0}
                                            suffix={
                                                discountType.value == appConfig.DISCOUNT.TYPE.PERCENT.value
                                                    ? '%'
                                                    : storeReducer.userInfo.currentStore.currency_symbol
                                            }
                                            max={discountType.value == appConfig.DISCOUNT.TYPE.PERCENT.value ? 100 : null}
                                            value={discount.value}
                                            onChange={(event) => {
                                                setDiscount((pre) => {
                                                    let tg = { ...pre };
                                                    tg.value = event.target.value;
                                                    return tg;
                                                });
                                            }}
                                            isDiscountType={discountType.value}
                                        />
                                    </div>
                                </div>
                                <div className='discount-reason'>
                                    <label className='ladiui text-14'>{t('COMMON.REASON')}</label>
                                    <Input
                                        className='mt-4'
                                        type='text'
                                        name='note'
                                        value={discount.note}
                                        onChange={(event) => {
                                            let value = event.target.value;
                                            setDiscount((pre) => {
                                                let tg = { ...pre };
                                                tg.note = value;
                                                return tg;
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='discount-value mt-12'>
                                <label className='ladiui text-14'>{t('DISCOUNTS.COUPON_USE')}</label>
                                <Input
                                    className='mt-4'
                                    type='text'
                                    name='code'
                                    value={discount.code}
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        setDiscount((pre) => {
                                            let tg = { ...pre };
                                            tg.code = value;
                                            return tg;
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex-end actions'>
                        <button className='ladiui btn btn-secondary btn-sm  mr-8' data-dissmiss='discount-popup' onClick={remove}>
                            {t('ACTIONS.REMOVE')}
                        </button>

                        <button
                            className={`ladiui btn btn-primary btn-sm  ${disabledApply ? 'disabled' : ''}`}
                            disabled={disabledApply}
                            data-dissmiss='discount-popup'
                            onClick={apply}
                        >
                            {t('ACTIONS.APPLY')}
                        </button>
                    </div>
                </div>
            }
        >
            <span>
                <a className='ladiui-custom-popup-init'>{`${t('ORDERS.ADD_DISCOUNT')} ${
                    selectDiscount && selectDiscount.code ? `(${selectDiscount.code})` : ''
                }  ${selectDiscount && selectDiscount.note ? `(${selectDiscount.note})` : ''}`}</a>
            </span>
        </Popover>
    );
}
export default PopoverCoupon;
