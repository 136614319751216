import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncAutoComplete from '../components/AsyncAutoComplete';
import { cloneDeep, remove, find, uniq, map, forEach } from 'lodash';
import { withTranslation } from 'react-i18next';
import ModalAllTag from './ModalAllTag';
import { utimes } from 'fs';

function TagSelect(props) {
    const { allTags, selectedTags, t, setSelected, title, className, placeholder, isSearchApi, handleCallApi, _key = 'value' } = props;

    const [search, setSearch] = useState('');
    const [listTag, setListTag] = useState([]);

    const onChangeInput = (event) => {
        setSearch(event.target.value);
        if (isSearchApi) {
            handleCallApi(event.target.value);
        }
    };

    useEffect(() => {
        normalizeRecentTag();
    }, [selectedTags, allTags]);

    const onSelect = (item) => {
        setSearch('');
        // selectedTags.push(item)
        setSelected((pre) => {
            const tg = [...pre];
            tg.push(item);
            return tg;
        });
    };

    const removeTag = (index) => {
        setSelected((pre) => {
            let tg = [...pre];
            tg.splice(index, 1);
            return tg;
        });
    };

    const normalizeRecentTag = () => {
        const listTg = allTags.filter((item) => selectedTags.findIndex((item2) => item2[_key] === item[_key]) === -1);

        setListTag(listTg);
    };

    return (
        <div className={`ladiui form-group clearfix form-tags ${className ? className : ''}`}>
            {/* {
                <div className="ladiui-tags-label">
                    <label className="ladiui-label">{title}</label>
                </div>
            } */}
            <div className='ladiui tags'>
                {map(selectedTags, (item, index) => {
                    return (
                        <span style={{ zIndex: 1 }} key={index} className='ladiui-btn-tag-X tag'>
                            {item.name}
                            {/* <img
                className="ladiui span-img"
                src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-close-no-bg.svg"}
                alt=""
                onClick={() => {
                  removeTag(index);
                }}
              /> */}

                            <i
                                onClick={() => {
                                    removeTag(index);
                                }}
                                className='ladiui span-img pointer icon-close-no-bg-16'
                            />
                        </span>
                    );
                })}

                <AsyncAutoComplete
                    items={listTag}
                    getItemValue={(item) => item.name}
                    renderItem={(item) => {
                        return !item.isDefault ? (
                            <div key={item.name}>
                                {
                                    <div className='item'>
                                        <span className='tag-item'>{item.name}</span>
                                    </div>
                                }
                            </div>
                        ) : (
                            <div key={-1}></div>
                        );
                    }}
                    value={search}
                    onChange={onChangeInput}
                    onSelect={(value, item) => {
                        onSelect(item);
                    }}
                    shouldItemRender={(item, value) => {
                        if (!isSearchApi) {
                            return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1;
                        } else {
                            return item.name;
                        }
                    }}
                    placeholder={title}
                    inputProps={{
                        // onFocus: this.onFocus,
                        // onKeyPress: (event) => { if (event.key === 'Enter') { this.addNewTag(event.target.value) } },
                        className: 'ladiui form-control border-none input-no-focus',
                        placeholder: `${title}`,
                        style: { minHeight: '100%' },
                        tabIndex: 1,
                    }}
                    hasDefaultItem={true}
                    isItemSelectable={(item, value) => {
                        if (item.isDefault) {
                            return false;
                        }
                        return true;
                    }}
                    showWhenEmpty={() => (
                        <span data-tooltip={t('PRODUCTS.DONT_CHECK_INVENTORY')} data-tooltip-position='right'>
                            N/A
                        </span>
                    )}
                />
            </div>
        </div>
    );
}

export default withTranslation('translation', { withRef: true })(TagSelect);
