import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';

import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as paymentTypes from '../../../../redux/futures/payment/types';
import paymentActions from '../../../../redux/futures/payment/actions';

import Dropdown from '../../../../components/Dropdown';
import PanelAddress from '../../../../components/PanelAddress';
import Location from '../../../../components/Location';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalOrderPayment(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        visible,
        hideModal,
        isPendingPaymentStatus = false,
        total,
        submit,
        paymentSelected,
        setPaymentSelected,
        emailNotification,
        setEmailNotification,
        isEditableOrder,
        fullSubmit,
        orderData = {},
        markAsPaid,
    } = props;

    const storeReducer = useSelector((state) => state.store);
    const customerReducer = useSelector((state) => state.customer);
    const paymentReducer = useSelector((state) => state.payment);

    const [listPaymentTypes, setPaymentType] = useState([]);

    const loading = useMemo(() => {
        return customerReducer.loading || false;
    }, [customerReducer.loading]);

    const gateways = useMemo(() => {
        return paymentReducer.gateways || [];
    }, [paymentReducer.gateways]);

    useEffect(() => {
        dispatch(paymentActions.listGateways());
    }, []);

    // useEffect(()=>{
    //     if(paymentReducer.action == paymentTypes.PAYMENT_LIST_GATEWAYS){
    //         if(paymentReducer.status){

    //         }
    //     }
    // },[paymentReducer.action])

    useEffect(() => {
        if (gateways && gateways.length > 0) {
            let gateway = gateways[0] || {};

            if (orderData && orderData.payment_method) {
                gateway = gateways.find((item) => item.code == orderData.payment_method);

                if (!gateway) gateway = gateways[0] || {};
            }
            let config = gateway.config || {};

            setPaymentSelected({
                method: gateway.code,
                name: gateway.name,
                type: config.active_payment_methods ? config.active_payment_methods[0] || '' : '',
            });
            setPaymentType(config.active_payment_methods || []);
        }
    }, [gateways, orderData.payment_method]);

    const getDataPaymentTypes = (types) => {
        let dataListTypes = [];
        if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.APPOTA.CODE) {
            appConfig.SN_PAYMENT_APPOTA_METHODS.map((value, index) => {
                let findIndex = types.findIndex((item) => item == value.CODE);
                if (findIndex !== -1) {
                    dataListTypes.push({
                        code: value.CODE,
                        name: value.NAME,
                    });
                }
            });
        } else {
            appConfig.SN_PAYMENT_METHODS.map((value, index) => {
                let findIndex = types.findIndex((item) => item == value.CODE);
                if (findIndex !== -1) {
                    dataListTypes.push({
                        code: value.CODE,
                        name: value.NAME,
                    });
                }
            });
        }
        return dataListTypes;
    };

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        if (orderData && orderData.order_id) {
            if (isEditableOrder) fullSubmit(true);
            else markAsPaid();
        } else submit();

        hideModal();
    };

    return (
        <Modal
            width={600}
            id='modal-customer-update'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('ORDERS.CONFIRM_PAYMENT')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('ACTIONS.SAVE')}
            isLoading={loading}
            position_unset={true}
            hasFooter={true}
            footer={
                <div>
                    <div className='ladiui flex-center-between w-100-p'>
                        <div className='display-flex-center'>
                            <input
                                type='checkbox'
                                onClick={(event) => setEmailNotification(!emailNotification)}
                                onChange={() => {}}
                                checked={emailNotification}
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                            />
                            <span className='ladiiui text-14'>{t('COMMON.EMAIL_NOTIFICATION')}</span>
                        </div>
                        <div className='display-flex-center'>
                            <button onClick={onCancel} className='ladiui btn btn-secondary '>
                                {t('ACTIONS.REMOVE')}
                            </button>

                            <button onClick={onOk} className={`ladiui btn btn-primary ${loading ? 'loader' : ''}`} disabled={loading}>
                                {t('ACTIONS.SAVE')}
                            </button>
                        </div>
                    </div>
                </div>
            }
        >
            <p>
                <span>{isPendingPaymentStatus ? t('ORDERS.MSG_PAYMENT_PENDING_CONFIRM') : t('ORDERS.MSG_PAYMENT_SUCCESS_CONFIRM')}</span>
            </p>
            <p>
                <span>{isPendingPaymentStatus ? t('ORDERS.MSG_PAYMENT_PENDING_CONTENT1') : t('ORDERS.MSG_PAYMENT_SUCCESS_CONTENT1')}</span>
            </p>
            <p className='pt-24'>
                <strong>
                    {isPendingPaymentStatus
                        ? t(
                              'ORDERS.MSG_PAYMENT_PENDING_CONTENT2',
                              baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol)
                          )
                        : t(
                              'ORDERS.MSG_PAYMENT_SUCCESS_CONTENT2',
                              baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol)
                          )}
                </strong>
            </p>
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('PAYMENT_METHODS.METHOD_LABEL')}</label>
                <Dropdown
                    wrapperClassName='w-100-p'
                    data={gateways}
                    onSelectItem={(item) => {
                        setPaymentSelected({
                            method: item.code,
                            name: item.name,
                            type: '',
                        });
                        setPaymentType(item.config.active_payment_methods);
                    }}
                    currentKey={paymentSelected.method}
                    currentValue={paymentSelected.name}
                    _key={'code'}
                    _value={'name'}
                    validationName={t('PAYMENT_METHODS.METHOD_LABEL')}
                    placeHolder={t('PAYMENT_METHODS.METHOD_LABEL')}
                    validations={{ isRequired: true }}
                    position_unset={true}
                    widthListCustom='calc(100% - 48px)'
                />
            </div>
            {!baseHelper.isEmpty(listPaymentTypes) && (
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('PAYMENTS.LB_SELECT_PAYMENT_TYPE')}</label>
                    <Dropdown
                        wrapperClassName='w-100-p'
                        data={getDataPaymentTypes(listPaymentTypes)}
                        onSelectItem={(item) => {
                            setPaymentSelected((pre) => {
                                let tg = { ...pre };
                                tg.type = item.code;
                                tg.type_name = item.name;

                                return tg;
                            });
                        }}
                        currentKey={paymentSelected.type}
                        currentValue={paymentSelected.type_name}
                        _key={'code'}
                        _value={'name'}
                        validationName={t('PAYMENTS.LB_PAYMENT_VALIDATION')}
                        placeHolder={t('PAYMENTS.CHOOSE_PAYMENT_TYPE')}
                        validations={{ isRequired: true }}
                        position_unset={true}
                        widthListCustom='calc(100% - 48px)'
                    />
                </div>
            )}
            {storeReducer.userInfo.currentStore.store_id == '3319' && (
                <div className='ladiui form-group'>
                    <div className=' flex flex-start'>
                        <input
                            type='checkbox'
                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                            name='is_auto_shipping'
                            onChange={(event) =>
                                setPaymentSelected((pre) => {
                                    let tg = { ...pre };
                                    tg.is_auto_shipping = !tg.is_auto_shipping || false;

                                    return tg;
                                })
                            }
                            checked={paymentSelected.is_auto_shipping}
                        />
                        {t('PAYMENTS.AUTO_SHIPPING')}
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default ModalOrderPayment;
