import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker, TwitterPicker } from 'react-color';

import appConfig from '../../../../../config/app';
import BaseHelper from '../../../../../helpers/BaseHelper';

import Dropdown from '../../../../../components/Dropdown';
import Popover from '../../../../../components/Popover';
import Input from '../../../../../components/Input';
import ImageUpload from '../../../../../components/ImageUpload';
import ImageLogoUpload from '../../../../../components/ImageLogoUpload';
import pageCheckoutDefined from '../../components/pageCheckoutDefined';

// eslint-disable-next-line max-lines-per-function

const GeneralSetting = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { isShow, generalConfig, pageCheckout } = props;
    const imageRef = useRef();

    const [setting, setSetting] = useState({
        title_font: appConfig.LIST_FONT_FAMILIES[0],
        text_font: appConfig.LIST_FONT_FAMILIES[0],
        main_color: '#EE1D52',
        backgroundType: {
            singleColor: {
                isEnable: false,
                color: '#DCD7F8',
            },
            gradientColor: {
                isEnable: true,
                direction: pageCheckoutDefined.GRADIENT_DIRECTION[2].value,
                color1: '#1C00C2',
                color2: '#00DEBB',
                color3: '#5FFBF1',
            },
            image: {
                isEnable: false,
                url: '',
            },
        },
    });

    const [tabSelected, setTabSelected] = useState('');
    const [menuSelected, setMenuSelected] = useState('');

    useImperativeHandle(ref, () => ({
        getData: () => {
            return setting;
        },
    }));

    useEffect(() => {
        let iframeElm = document.getElementById('iframe-page-checkout-dns');

        if (iframeElm) {
            const dataSend = {
                type: 'LADI_UPDATE_STORE_WIDGET',
                key: 'general_setting',
                value: setting,
            };

            iframeElm.contentWindow.postMessage(dataSend, '*');
        }
    }, [setting]);

    useEffect(() => {
        if (generalConfig) {
            setSetting({
                ...setting,
                ...generalConfig,
            });
        }
    }, [generalConfig]);

    useEffect(() => {
        if (queryStrings) {
            let tab = queryStrings.get('tab');
            if (!tab) {
                setTabSelected('');
            } else {
                setTabSelected(tab);
            }

            let menu = queryStrings.get('widget-menu');
            if (!menu) {
                setMenuSelected('');
            } else {
                setMenuSelected(menu);
            }
        }
    }, [queryStrings]);

    const handleUpdateSetting = (name, value) => {
        setSetting((preData) => ({
            ...preData,
            [name]: value,
        }));
    };

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'}`}>
            {pageCheckout.types == appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT && (
                <div className='ladiui-label style-switch'>
                    <div className='ladiui item-form switch '>
                        <label className='ladiui switch'>
                            <input
                                type='checkbox'
                                name='using_new_style'
                                value={setting.using_new_style}
                                onChange={(event) => {
                                    handleUpdateSetting('using_new_style', !setting.using_new_style);
                                }}
                                checked={setting.using_new_style || false}
                            />
                            <span className='ladiui slider round'></span>
                        </label>
                    </div>
                    <span>Sử dụng giao diện mới</span>
                </div>
            )}
            <div>
                <label className='ladiui label-14'>Favicon</label>
                <div className='ladiui text-14 mt-4'>Hình ảnh hiển thị trên tab trình duyệt của trang.</div>
                <div>
                    <div className='display-flex-center gap-16 mt-16'>
                        <ImageLogoUpload
                            type='FAVI_ICON_SETTING'
                            imageSrc={setting.favicon}
                            setImageData={(value, type) => {
                                if (type == 'FAVI_ICON_SETTING') handleUpdateSetting('favicon', value);
                            }}
                        />

                        <div>
                            <div className='ladiui text-14 text-primary'>Favicon</div>
                            <div className='mt-4'>Hiển thị trên tab trình duyệt</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-20 pt-20 ladiui border-t-50p'>
                <label className='ladiui label-14'>Kiểu chữ</label>
                <div className='ladiui text-14 mt-4'>Kiểu chữ của tiêu đề và văn bản trong trang.</div>
                <div className='mt-20'>
                    <label className='ladiui text-14'>Tiêu đề</label>
                    <Dropdown
                        wrapperClassName='mt-4 w-100-p'
                        placeHolder='Chọn font tiêu đề'
                        data={appConfig.LIST_FONT_FAMILIES}
                        _key='name'
                        _value='name'
                        currentKey={setting.title_font.name}
                        onSelectItem={(value) => {
                            handleUpdateSetting('title_font', value);
                        }}
                    />
                </div>
                <div className='mt-20'>
                    <label className='ladiui text-14'>Văn bản</label>

                    <Dropdown
                        wrapperClassName='mt-4 w-100-p'
                        placeHolder='Chọn Font văn bản'
                        data={appConfig.LIST_FONT_FAMILIES}
                        _key='name'
                        _value='name'
                        currentKey={setting.text_font.name}
                        onSelectItem={(value) => {
                            handleUpdateSetting('text_font', value);
                        }}
                    />
                </div>
            </div>
            <div className='mt-20 pt-20 ladiui border-t-50p'>
                <label className='ladiui label-14'>Màu chủ đạo</label>
                <div className='ladiui text-14 mt-4'>Màu sắc của nút, hộp kiểm, nút radio và kẻ viền.</div>

                <div className='mt-20'>
                    {/* <div className='ladiui text-14'>Màu chủ đạo</div> */}
                    <div className=' change-color-block'>
                        <Popover
                            wrapperProps={{ className: 'w-100-p' }}
                            wrapperPopoverProps={{ className: 'popover-picker' }}
                            styleCustom={{ bottom: 50, left: 0, position: 'absolute' }}
                            content={
                                <SketchPicker
                                    color={setting.main_color}
                                    onChange={(color) => {
                                        handleUpdateSetting('main_color', BaseHelper.addAlpha(color.hex, color.rgb.a));
                                    }}
                                />
                            }
                        >
                            <div className='color-change w-100-p' style={{ background: setting.main_color }}></div>
                        </Popover>
                        <Input
                            className='color-input'
                            value={setting.main_color}
                            onChange={(e) => {
                                handleUpdateSetting('main_color', e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='mt-20 pt-20 ladiui border-t-50p'>
                <label className='ladiui label-14'>Kiểu giao diện nền</label>
                <div className='ladiui text-14 mt-4'>Tuỳ chỉnh hình ảnh của nền trang.</div>

                <div className='mt-20 background-config'>
                    <div
                        className={`background-config-item ${
                            setting.backgroundType && setting.backgroundType.singleColor.isEnable ? 'active' : ''
                        }`}
                        onClick={() => {
                            let backgroundTypeTg = { ...setting.backgroundType };

                            backgroundTypeTg.singleColor.isEnable = true;
                            backgroundTypeTg.gradientColor.isEnable = false;
                            backgroundTypeTg.image.isEnable = false;

                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                        }}
                    >
                        <div className='background background-color' style={{ backgroundColor: setting.backgroundType.singleColor.color }}>
                            <i className='theme-background' />
                        </div>
                        <div className='ladiui text-14 text-center mt-4'>Màu đơn sắc</div>
                    </div>

                    <div
                        className={`background-config-item ${setting.backgroundType.gradientColor.isEnable ? 'active' : ''}`}
                        onClick={() => {
                            let backgroundTypeTg = { ...setting.backgroundType };

                            backgroundTypeTg.singleColor.isEnable = false;
                            backgroundTypeTg.gradientColor.isEnable = true;
                            backgroundTypeTg.image.isEnable = false;
                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                        }}
                    >
                        <div
                            className='background background-gradient'
                            style={{
                                background: `linear-gradient(${setting.backgroundType.gradientColor.direction} ,
                                                            ${setting.backgroundType.gradientColor.color1},
                                                            ${setting.backgroundType.gradientColor.color2}, 
                                                            ${setting.backgroundType.gradientColor.color3})`,
                            }}
                        >
                            <i className='theme-background' />
                        </div>
                        <div className='ladiui text-14 text-center mt-4'>Màu Gradient</div>
                    </div>

                    <div
                        className={`background-config-item ${setting.backgroundType.image.isEnable ? 'active' : ''}`}
                        onClick={() => {
                            let backgroundTypeTg = { ...setting.backgroundType };

                            backgroundTypeTg.singleColor.isEnable = false;
                            backgroundTypeTg.gradientColor.isEnable = false;
                            backgroundTypeTg.image.isEnable = true;
                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                        }}
                    >
                        <div
                            className='background background-image'
                            style={{
                                backgroundImage: `url("${BaseHelper.getImageUrl(setting.backgroundType.image.url)}"`,
                            }}
                        >
                            <i className='theme-background' />
                        </div>
                        <div className='ladiui text-14 text-center mt-4'>Hình ảnh</div>
                    </div>
                </div>

                {setting.backgroundType.singleColor.isEnable && (
                    <div className='mt-20'>
                        <div className='ladiui text-14'>Chọn màu nền</div>
                        <div className=' change-color-block mt-8'>
                            <Popover
                                wrapperProps={{ className: 'w-100-p' }}
                                wrapperPopoverProps={{ className: 'popover-picker' }}
                                styleCustom={{ bottom: 50, left: 0, position: 'absolute' }}
                                content={
                                    <TwitterPicker
                                        color={setting.main_color}
                                        onChange={(color) => {
                                            let backgroundTypeTg = { ...setting.backgroundType };
                                            backgroundTypeTg.singleColor.color = BaseHelper.addAlpha(color.hex, color.rgb.a);
                                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                                        }}
                                    />
                                }
                            >
                                <div
                                    className='color-change w-100-p'
                                    style={{ background: setting.backgroundType.singleColor.color }}
                                ></div>
                            </Popover>
                            <Input
                                className='color-input'
                                value={setting.main_color}
                                onChange={(e) => {
                                    let backgroundTypeTg = { ...setting.backgroundType };
                                    backgroundTypeTg.singleColor.color = e.target.value;
                                    handleUpdateSetting('backgroundType', backgroundTypeTg);
                                }}
                            />
                        </div>
                    </div>
                )}
                {setting.backgroundType.gradientColor.isEnable && (
                    <div className='mt-20'>
                        <div>
                            <div className='ladiui text-14'>Hướng dải màu</div>
                            <div className='gradient-directions mt-12'>
                                {pageCheckoutDefined.GRADIENT_DIRECTION.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`gradient-direction-item ${
                                                setting.backgroundType.gradientColor.direction == item.value ? 'active' : ''
                                            }`}
                                            onClick={() => {
                                                let backgroundTypeTg = { ...setting.backgroundType };
                                                backgroundTypeTg.gradientColor.direction = item.value;
                                                handleUpdateSetting('backgroundType', backgroundTypeTg);
                                            }}
                                        >
                                            <i className={` ladiui icon-new ${item.icon}`} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className='mt-20'>
                            <div className='ladiui text-14'>Chọn dải màu Gradient</div>
                            <div className='mt-12 display-flex-center gap-12'>
                                <div className=' change-color-block'>
                                    <Popover
                                        wrapperProps={{ className: 'w-100-p' }}
                                        wrapperPopoverProps={{ className: 'popover-picker' }}
                                        styleCustom={{ bottom: 50, left: 0, position: 'absolute' }}
                                        content={
                                            <TwitterPicker
                                                color={setting.backgroundType.gradientColor.color1}
                                                onChange={(color) => {
                                                    let backgroundTypeTg = setting.backgroundType;
                                                    backgroundTypeTg.gradientColor.color1 = BaseHelper.addAlpha(color.hex, color.rgb.a);
                                                    handleUpdateSetting('backgroundType', backgroundTypeTg);
                                                }}
                                            />
                                        }
                                    >
                                        <div
                                            className='color-change w-100-p'
                                            style={{ background: setting.backgroundType.gradientColor.color1 }}
                                        ></div>
                                    </Popover>
                                    <Input
                                        className='color-input'
                                        value={setting.backgroundType.gradientColor.color1}
                                        onChange={(e) => {
                                            let backgroundTypeTg = setting.backgroundType;
                                            backgroundTypeTg.gradientColor.color1 = e.target.value;
                                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                                        }}
                                    />
                                </div>
                                <div className=' change-color-block'>
                                    <Popover
                                        wrapperProps={{ className: 'w-100-p' }}
                                        wrapperPopoverProps={{ className: 'popover-picker' }}
                                        styleCustom={{ bottom: 50, left: 0, position: 'absolute' }}
                                        content={
                                            <TwitterPicker
                                                color={setting.backgroundType.gradientColor.color2}
                                                onChange={(color) => {
                                                    let backgroundTypeTg = setting.backgroundType;
                                                    backgroundTypeTg.gradientColor.color2 = BaseHelper.addAlpha(color.hex, color.rgb.a);
                                                    handleUpdateSetting('backgroundType', backgroundTypeTg);
                                                }}
                                            />
                                        }
                                    >
                                        <div
                                            className='color-change w-100-p'
                                            style={{ background: setting.backgroundType.gradientColor.color2 }}
                                        ></div>
                                    </Popover>
                                    <Input
                                        className='color-input'
                                        value={setting.backgroundType.gradientColor.color2}
                                        onChange={(e) => {
                                            let backgroundTypeTg = setting.backgroundType;
                                            backgroundTypeTg.gradientColor.color2 = e.target.value;
                                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                                        }}
                                    />
                                </div>
                                <div className=' change-color-block'>
                                    <Popover
                                        wrapperProps={{ className: 'w-100-p' }}
                                        wrapperPopoverProps={{ className: 'popover-picker' }}
                                        styleCustom={{ bottom: 50, left: 0, position: 'absolute' }}
                                        content={
                                            <TwitterPicker
                                                color={setting.backgroundType.gradientColor.color3}
                                                onChange={(color) => {
                                                    let backgroundTypeTg = setting.backgroundType;
                                                    backgroundTypeTg.gradientColor.color3 = BaseHelper.addAlpha(color.hex, color.rgb.a);
                                                    handleUpdateSetting('backgroundType', backgroundTypeTg);
                                                }}
                                            />
                                        }
                                    >
                                        <div
                                            className='color-change w-100-p'
                                            style={{ background: setting.backgroundType.gradientColor.color3 }}
                                        ></div>
                                    </Popover>
                                    <Input
                                        className='color-input'
                                        value={setting.backgroundType.gradientColor.color3}
                                        onChange={(e) => {
                                            let backgroundTypeTg = setting.backgroundType;
                                            backgroundTypeTg.gradientColor.color3 = e.target.value;
                                            handleUpdateSetting('backgroundType', backgroundTypeTg);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {tabSelected == 'WIDGET_CONFIG' && menuSelected == 'GENERAL_SETTINGS' && setting.backgroundType.image.isEnable && (
                    <div>
                        <div> Ảnh nền</div>
                        <ImageUpload
                            indexSection='BACKGROUND-SETTING'
                            setImageSrc={setting.backgroundType.image.url}
                            setImageData={(image) => {
                                if (image.indexSection == 'BACKGROUND-SETTING') {
                                    let backgroundTypeTg = { ...setting.backgroundType };
                                    backgroundTypeTg.image.url = image.src;
                                    handleUpdateSetting('backgroundType', backgroundTypeTg);
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
});

export default GeneralSetting;
