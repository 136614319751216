import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import * as productCategoryTypes from '../../../redux/futures/product_category/types';
import productCategoryActions from '../../../redux/futures/product_category/actions';

import * as productTagTypes from '../../../redux/futures/product_tag/types';
import productTagActions from '../../../redux/futures/product_tag/actions';

import storePageActions from '../../../redux/futures/store_page/actions';
import * as storePageTypes from '../../../redux/futures/store_page/types';

import { ProductNameDes } from './components/ProductNameDes';
import { ProductVersion } from './ProductVersion';
import { ProductImage } from './components/ProductImage';
import { ProductTags } from '../components/ProductTags';

import Input from '../../../components/Input';
import Dropdown from '../../../components/Dropdown';
import SelectManyDropdown from '../../../components/SelectManyDropdown';
import SelectOption from '../../../components/SelectOption';
import PanelCustomField from '../../../components/PanelCustomField';
import SelectManyDropdownStore from '../../../components/SelectManyDropdownStore';
import config from '../../../config/config';

// eslint-disable-next-line max-lines-per-function
export const ProductInfo = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { productData, isEditProduct } = props;

    const productCategoryReducer = useSelector((state) => state.productCategory);
    const storePageReducer = useSelector((state) => state.storePage);

    const categories = useMemo(() => {
        let categories = productCategoryReducer.listSelects || [];
        categories = categories.map((item) => {
            return { level: item.level, name: item.name, value: item.product_category_id };
        });
        return categories;
    }, [productCategoryReducer.listSelects]);

    const productTagRef = useRef();
    const productNameRef = useRef();
    const productImageRef = useRef();
    const productVariantRef = useRef();
    const panelCustomFieldRef = useRef();
    const ticketFormRef = useRef();

    const defaultProduct = {
        name: '',
        short_description: '',
        description: '',
        url_published: '',
        domain: '',
        path: '',
        external_link: '',
        unit: '',
        is_one_time: 0,
        cta: 0,
        seo: '',
        select_many_service: appConfig.ACTIVE,
        is_show_all_store: appConfig.ACTIVE,
        ticket_creation_form: -1,
        status: appConfig.PRODUCT_STATUS.ACTIVE.CODE,
    };

    const [product, setProduct] = useState(defaultProduct);
    const [images, setImages] = useState([]);
    // const [categories, setCategories] = useState([]);
    const [productTags, setProductTags] = useState([]);

    const [isShowPopover, setIsShowPopover] = useState(true);
    const [isShowExternalLink, setShowExternalLink] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [isShowAllStore, setShowAllStore] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let productNameDes = productNameRef.current.getData();
            if (productNameDes && productNameDes.error) {
                productNameRef.current.scrollIntoView();
                return {
                    error: productNameDes.error,
                };
            }

            let productVariants = productVariantRef.current.getData();

            if (productVariants && productVariants.error) {
                productVariantRef.current.scrollIntoView();
                return {
                    error: productVariants.error,
                };
            }

            let productImages = productImageRef.current.getData();
            let { variants: variantTg, options } = productVariants;

            if (!variantTg || variantTg.length == 0) {
                variantTg = [
                    {
                        product_id: '',
                        product_variant_id: '',
                        name: productNameDes.name,
                        quantity: 0,
                        timezone: 'Asia/Bangkok',
                        location: '',
                        host_name: '',
                        contact_phone: '',
                        type: appConfig.PRODUCT_TYPE.DIGITAL.CODE,
                        inventory_checked: appConfig.INVENTORY_STATUS.INACTIVE.CODE,
                        position: 0,
                    },
                ];
            }

            if (product.ticket_creation_form == -1) {
                ticketFormRef.current.classList.add('error-empty');
                ticketFormRef.current.scrollIntoView({ behavior: 'smooth' });
                return {
                    error: t('PRODUCTS.TICKET_FORM_EMPTY'),
                };
            }

            let tags = productTagRef.current.getData();
            let custom_fields = panelCustomFieldRef.current.getData();
            let page_checkout_ids = selectedStores;

            let is_show_all_store = false;

            if (page_checkout_ids.length == stores.length) is_show_all_store = true;

            let _product = {
                ...product,
                ...productNameDes,
                product_category_ids: selectedCategories,
                tags,
                alias_name: baseHelper.getAliasName(productNameDes.name),
                variants: variantTg,
                options,
                images: productImages,
                custom_fields,
                page_checkout_ids,
                is_show_all_store,
            };

            return _product;
        },
    }));

    useEffect(() => {
        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        dispatch(storePageActions.storePageList(dataListStore));
    }, []);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_LIST) {
            if (storePageReducer.status) {
                if (storePageReducer.store_pages && storePageReducer.store_pages.length > 0) {
                    let _data = [];
                    let _stores = [];
                    storePageReducer.store_pages.map((item, index) => {
                        _data.push({
                            name: item.name,
                            value: item.page_checkout_id,
                        });

                        _stores.push(item.page_checkout_id);
                    });

                    // setSelectedStores(_stores);
                    setStores(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    useEffect(() => {
        // if (product.external_link) setShowExternalLink(true);
        // else setShowExternalLink(false);

        if (productData && (productData.product_id || !isEditProduct)) {
            setProduct({
                name: productData.name,
                short_description: productData.short_description,
                description: productData.description,
                url_published: productData.url_published,
                domain: productData.domain,
                path: productData.path,
                external_link: productData.external_link,
                unit: productData.unit,
                is_one_time: productData.is_one_time,
                cta: productData.cta,
                seo: productData.seo,
                select_many_service: productData.select_many_service,
                is_show_all_store: productData.is_show_all_store,
                product_id: productData.product_id,
                ticket_creation_form: productData.ticket_creation_form,
                status: productData.status,
                page_checkout: productData.page_checkout,
            });
            setSelectedCategories(productData.product_category_ids || []);
            setProductTags(productData.tags || []);
            setCustomFields(productData.custom_fields || []);
            setImages(productData.images || []);

            if (productData.page_checkout_ids) setSelectedStores(productData.page_checkout_ids);
            if (productData.is_show_all_store) setShowAllStore(true);
        }
    }, [JSON.stringify(productData), isEditProduct]);

    const handleDeleteImage = () => {};
    const handleSetImage = () => {};

    const handleRefreshDataCategories = () => {
        let payload = {
            search: {
                type: 'Product',
            },
        };
        dispatch(productCategoryActions.listSelectCategory(payload));
    };

    const selectTicketCreationForm = (data) => {
        ticketFormRef.current.classList.remove('error-empty');
        setProduct({ ...product, ticket_creation_form: data.CODE });
    };
    const onChangeCTA = (item) => {
        setProduct({
            ...product,
            cta: item.CODE,
            selectedIconCTA: item.CLASS_ICON,
        });
    };

    const handleRefreshDataStores = () => {};

    const handleSearchData = () => {};
    const handleChangeCheckAllStore = (checked) => {
        if (!checked) {
            setSelectedStores([]);
        }
        setShowAllStore(checked);
    };

    const getUrlPageCheckout = (type, url, page_checkout_id) => {
        let is_iframe = true;

        if (!is_iframe) {
            return url;
        }
        switch (type) {
            case 'CREATE':
                return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_LIST}&query_ls=${JSON.stringify({
                    is_create: true,
                    type_create: appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT,
                    product_id: page_checkout_id,
                })}`;
            case 'UPDATE':
                return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(page_checkout_id)}`;
            default:
                break;
        }
    };

    return (
        <div className='page-product-info'>
            <div className='content-left'>
                <ProductNameDes ref={productNameRef} productData={product} />
                <ProductVersion ref={productVariantRef} vadiantData={product.vvariants || []} productData={productData} />

                <div className='block-content-item mt-20'>
                    <h3>{t('PRODUCTS.IMAGE')}</h3>
                    <div className='block-list-images mt-24'>
                        <ProductImage
                            ref={productImageRef}
                            listImage={images}
                            handleDeleteImage={(item) => handleDeleteImage(item)}
                            handleSetImage={(items) => handleSetImage(items)}
                            isEditProduct={product.product_id ? true : false}
                            productID={product.product_id}
                            isShowPopover={isShowPopover}
                        />
                    </div>
                </div>
                <div className='block-content-item mt-20'>
                    <h3>{t('PRODUCTS.ADVANCED_SETTINGS')}</h3>
                    <div className='advanced-settings'>
                        <div className='item'>
                            <div className='ladiui item-form switch mr-8'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        checked={product.is_one_time || false}
                                        onChange={() => {
                                            setProduct({
                                                ...product,
                                                is_one_time: !product.is_one_time,
                                            });
                                        }}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <span>{t('PRODUCTS.BUY_ONCE')}</span>
                        </div>
                        <div className='item'>
                            <div className='ladiui item-form switch mr-8'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        checked={product.select_many_service || false}
                                        onChange={() => {
                                            setProduct({
                                                ...product,
                                                select_many_service: !product.select_many_service,
                                            });
                                        }}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <span>{t('PRODUCTS.ALLOW_SELECT_MANY_VERSION')}</span>
                        </div>
                        <div className='item'>
                            <div className='ladiui item-form switch mr-8'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        onChange={() => {
                                            if (isShowExternalLink) {
                                                setProduct({
                                                    ...product,
                                                    external_link: '',
                                                });
                                            }
                                            setShowExternalLink(!isShowExternalLink);
                                        }}
                                        checked={isShowExternalLink || false}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <span>{t('PRODUCTS.LANDING_URL')}</span>
                        </div>
                        {isShowExternalLink && (
                            <div className='ladiui form-group mt-0 pt-12 pb-12'>
                                <label className='ladiui-label new'>{t('PRODUCTS.INTRODUCTION_PAGE_LINK')}</label>
                                <Input
                                    // ref={(ref) => inputsRef.add(ref)}
                                    name='external_link'
                                    validationName={t('PRODUCTS.INTRODUCTION_PAGE_LINK')}
                                    placeholder={t('PRODUCTS.LB_EXTERNAL_LINK_PLACEHOLDER')}
                                    value={product.external_link}
                                    onChange={(event) => setProduct({ ...product, external_link: event.target.value })}
                                    validations={{ isURL: true }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='content-right'>
                <div className='block-content-item'>
                    <div className='flex items-center space-between'>
                        <h3>{t('PRODUCTS.LB_STATUS')}</h3>
                        <div className='ladiui item-form switch'>
                            <label className='ladiui switch m-0'>
                                <input
                                    type='checkbox'
                                    onChange={(event) => {
                                        setProduct({
                                            ...product,
                                            status:
                                                product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE
                                                    ? appConfig.PRODUCT_STATUS.INACTIVE.CODE
                                                    : appConfig.PRODUCT_STATUS.ACTIVE.CODE,
                                        });
                                    }}
                                    checked={product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE}
                                />
                                <span className='ladiui slider round'></span>
                            </label>
                        </div>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label new'>Cửa hàng</label>
                        <SelectManyDropdownStore
                            data={stores || []}
                            _key='name'
                            _value='value'
                            pickTitle={'Tiêu đề'}
                            placeHolder={'Chọn cửa hàng'}
                            onSubmit={(items) => {
                                setSelectedStores(items);
                            }}
                            isShowAllStore={isShowAllStore}
                            innerClassName='w100'
                            styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                            buttonStyle={{
                                width: '100%',
                                paddingRight: '30px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: ' -webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                textAlign: 'left',
                            }}
                            selectedItems={selectedStores || []}
                            isShowBlockInputSearch={false}
                            isCreateCategory={true}
                            isCourse={true}
                            handleRefreshData={handleRefreshDataStores}
                            handleSearchData={handleSearchData}
                            handleChangeCheckAllStore={handleChangeCheckAllStore}
                        />
                    </div>
                </div>
                <div className={`ladiui block-content-item mt-24 scroll-mt-150 `} ref={ticketFormRef}>
                    <h3>{t('PRODUCTS.DIGITAL.CODE_GENERATION_FORM')}</h3>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label new'>{t('Hình thức tạo mã')}</label>
                        <Dropdown
                            // disabled={product.ticket_creation_form > -1}
                            disabled={product.product_id}
                            data={appConfig.SERVICE_TICKET_CREATION_FORM}
                            onSelectItem={selectTicketCreationForm}
                            currentKey={product.ticket_creation_form}
                            _key={'CODE'}
                            _value={'NAME'}
                        />
                    </div>
                </div>
                <div className='block-content-item mt-20'>
                    <h3>{t('PRODUCTS.DIGITAL.PRODUCT_CLASS')}</h3>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label new'>{t('PRODUCTS.DIGITAL.CATEGORY')}</label>
                        <SelectManyDropdown
                            data={categories || []}
                            _key='name'
                            _value='value'
                            pickTitle={'Tiêu đề'}
                            placeHolder={'Chọn danh mục'}
                            onSubmit={(items) => {
                                setSelectedCategories(items);
                            }}
                            innerClassName='w100'
                            styleCustomPeople={{ left: 0, maxHeight: '450px', overflow: 'auto' }}
                            buttonStyle={{
                                width: '100%',
                                paddingRight: '30px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: ' -webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                textAlign: 'left',
                            }}
                            selectedItems={selectedCategories || []}
                            isShowBlockInputSearch={true}
                            isCreateCategory={true}
                            handleRefreshData={handleRefreshDataCategories}
                            // handleSearchData={handleSearchData}
                        />
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label new'>{t('PRODUCTS.DIGITAL.TAG')}</label>
                        <ProductTags ref={productTagRef} productTag={productTags} />
                    </div>
                </div>
                <div className='block-content-item mt-20'>
                    <h3>{t('PAGE_CHECKOUT.TITLE')}</h3>
                    <div className='ladiui form-group'>
                        {product.page_checkout ? (
                            <div className='ladiui flex gap-12'>
                                <a
                                    className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                    // href={`/page-checkout/update/${product.page_checkout.page_checkout_id}`}
                                    href={getUrlPageCheckout(
                                        'UPDATE',
                                        `/page-checkout/update/${product.page_checkout.page_checkout_id}`,
                                        product.page_checkout.page_checkout_id
                                    )}
                                    target='_blank'
                                >
                                    Chỉnh Sửa
                                </a>
                                <a
                                    className='redirect-btn ladiui btn btn-outline-primary btn-md'
                                    href={product.url_published}
                                    target='_blank'
                                >
                                    Truy cập
                                </a>
                            </div>
                        ) : (
                            <a
                                href={getUrlPageCheckout(
                                    'CREATE',
                                    `/page-checkout?is_create=true&product_id=${product.product_id}&type_create=${appConfig.PAGE_CHECKOUT_TYPE.SINGLE_PRODUCT}`,
                                    product.product_id
                                )}
                                target='_parent'
                            >
                                {t('PAGE_CHECKOUT.ADD')}
                            </a>
                        )}
                    </div>
                </div>
                <div className='block-content-item mt-20'>
                    <h3>{t('PRODUCTS.DIGITAL.BTN_CTA')}</h3>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label new'>{t('PRODUCTS.DIGITAL.BTN_CTA_SELECT')}</label>
                        <SelectOption
                            wrapperClassName='select-assignee w100'
                            innerClassName='ellipsis w100 flex'
                            currentKey={product.cta}
                            classIconCTA={product.selectedIconCTA}
                            customClassName='w100'
                            _key='CODE'
                            _value='NAME'
                            items={appConfig.LIST_CTA || []}
                            onSelectItem={(item) => {
                                onChangeCTA(item);
                            }}
                        />
                    </div>
                    <div className='block-info-help flex items-center'>
                        <i className='ladi-icon icon-c-question mr-8'></i>
                        <span>Nội dung nút mua hàng ở trang sản phẩm.</span>
                    </div>
                </div>
                <div className='block-content-item mt-20'>
                    <h3 className='mb-24'>Trường tùy chỉnh</h3>
                    <PanelCustomField
                        ref={panelCustomFieldRef}
                        customFields={customFields}
                        // customFields={[]}
                        groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE}
                    />
                    <div className='block-info-help flex items-center'>
                        <i className='ladi-icon icon-c-question mr-8'></i>
                        <span>Lưu trữ thông tin sản phẩm theo nhu cầu của bạn.</span>
                    </div>
                </div>
            </div>
        </div>
    );
});
