import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import Image from '../../../../components/Image';
import appConfig from '../../../../config/app';
import config from '../../../../config/config';
// eslint-disable-next-line max-lines-per-function
function ModalAddProduct(props) {
    const {
        t,
        onClose,
        isShow,
        listVariant,
        currency_symbol,
        openModalNewProduct,
        selectVariant,
        searchVariant,
        onFocusVariantInput,
        onChangeSearchVariantInput,
        searchVariantValue,
        innerStyle,
        selectedProducts = [],
        isSelectedTypeProduct,
        titleModal,
        placeholder,
        isFeaturedCourse,
        isCategory,
    } = props;
    const wrapperRef = useRef();

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                onClose(false);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const styleInvalid = { border: '1px solid var(--danger-color)' };
    const styleValid = { boder: '1px solid var(--main-line)' };

    const defaultValidated = {
        fullName: false,
        email: false,
        phone: false,
        status: false,
    };
    const defaultIsValidated = {
        fullName: true,
        email: true,
        phone: true,
        status: true,
    };

    const dispatch = useDispatch();

    // const [affiliate, setAffiliate] = useState(dataAffiliate);

    const [valid, setValid] = useState(defaultValidated);

    const eventCancel = () => {
        onClose(false);
    };

    const handleProductEdit = (type, productID) => {
        if (!isCategory) {
            switch (type) {
                case appConfig.PRODUCT_TYPE.SERVICE.CODE:
                    if (baseHelper.isIframe()) {
                        window.open(
                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID)}&product_update_url=services`,
                            '_blank'
                        );
                        return;
                    }
                    window.open(`/services/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                case appConfig.PRODUCT_TYPE.DIGITAL.CODE:
                    if (baseHelper.isIframe()) {
                        window.open(
                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID)}&product_update_url=digital`,
                            '_blank'
                        );
                        return;
                    }
                    window.open(`/digital/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                case appConfig.PRODUCT_TYPE.EVENT.CODE:
                    if (baseHelper.isIframe()) {
                        window.open(
                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID)}&product_update_url=events`,
                            '_blank'
                        );
                        return;
                    }
                    window.open(`/events/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                case appConfig.PRODUCT_TYPE.COURSE.CODE:
                    if (baseHelper.isIframe()) {
                        window.open(
                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID)}&product_update_url=courses`,
                            '_blank'
                        );
                        return;
                    }
                    window.open(`/courses/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
                default:
                    if (baseHelper.isIframe()) {
                        window.open(
                            `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_UPDATE(productID)}&product_update_url=products`,
                            '_blank'
                        );
                        return;
                    }
                    window.open(`/products/update/${productID}`, '_blank', 'noopener,noreferrer');
                    break;
            }
        }
    };

    const handleCategoryEdit = (item) => {
        if (baseHelper.isIframe()) {
            window.open(`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_CATEGORY_UPDATE(item.product_category_id)}`, '_blank');
            return;
        }
        switch (item.type) {
            case 'Product':
                window.open(`/product-category/update/${item.product_category_id}`, '_blank', 'noopener,noreferrer');
                break;
            case 'Course':
                window.open(`/product-category/update/${item.product_category_id}`, '_blank', 'noopener,noreferrer');
                break;
            default:
        }
    };

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='add-product'>
            <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '> {titleModal ? titleModal : t('PRODUCTS.ADD_TITLE')}</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body'>
                        <div className='ladiui search-group'>
                            <input
                                className='ladiui search-field dropdown-toggle form-control'
                                value={searchVariantValue}
                                placeholder={placeholder ? placeholder : 'Tìm kiếm sản phẩm'}
                                data-toggle='dropdown'
                                aria-expanded='false'
                                onFocus={onFocusVariantInput}
                                onChange={onChangeSearchVariantInput}
                            />
                            <i className='ladiui icon icon-search' onClick={searchVariant}></i>
                        </div>
                        <div className='cursor-pointer' style={innerStyle}>
                            <a className='flex-center-left cursor-pointer my-24 pd-0' onClick={openModalNewProduct}>
                                <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                <div> {t('PRODUCTS.ADD_NEW')}</div>
                            </a>
                        </div>

                        <div className={`list-product ${innerStyle ? 'mt-24' : ''}`}>
                            {listVariant.map((item, index) => {
                                let isSelected = selectedProducts.some((value) => {
                                    if (value.product_variant_id) {
                                        return value.product_variant_id == item.product_variant_id;
                                        // return selectedProducts.some((value) => value.product_variant_id == item.product_variant_id);
                                    } else if (value.variant_id) {
                                        return value.variant_id == item.product_variant_id;
                                        // return (isSelected = selectedProducts.some((value) => value.variant_id == item.product_variant_id));
                                    } else if (value.product_category_id) {
                                        return value.product_category_id == item.product_category_id;
                                        // return selectedProducts.some((value) => value.product_category_id == item.product_category_id);
                                    } else {
                                        return value.product_id == item.product_id;
                                        // return selectedProducts.some((value) => value.product_id == item.product_id);
                                    }
                                });
                                return (
                                    <div key={index} className='ls-flex ls-items-center item-product-add-new space-between'>
                                        <div className='ls-flex ls-items-center ls-gap-12'>
                                            <div className='ls-no-image-block'>
                                                <i className='new-ldicon-photo ls-size-24'></i>
                                            </div>
                                            {isCategory ? (
                                                <div className='w-310 product-name' onClick={() => handleCategoryEdit(item)}>
                                                    {item.name}
                                                </div>
                                            ) : (
                                                <div
                                                    className='w-310 product-name'
                                                    onClick={() => handleProductEdit(item.type, item.product_id)}
                                                >
                                                    {item.name}
                                                </div>
                                            )}
                                            {isCategory && item.isShow == 0 ? (
                                                <span style={{ fontSize: '12px', color: '#D94F45' }}>(Danh mục ẩn)</span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {!isSelectedTypeProduct && (
                                            <div className='w-100'>{baseHelper.formatMoneyPostFix(item.price, currency_symbol)}</div>
                                        )}
                                        <div>
                                            {baseHelper.isAvailableVariant(item) ? (
                                                isSelected ? (
                                                    <span className='ls-text-secondary'>{t('COMMON.SELECTED')}</span>
                                                ) : (
                                                    <button
                                                        className='ladiui btn-sm ls-btn-outline-primary'
                                                        onClick={() => selectVariant(item)}
                                                    >
                                                        {t('ACTIONS.SELECT')}
                                                    </button>
                                                )
                                            ) : (
                                                <span style={{ color: '#d94f45' }}>{t('COMMON.SOLD_OUT')}</span>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='ladiui modal-footer '>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary bold'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.DONE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalAddProduct);
