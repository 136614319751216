import React from 'react';
import Input from '../../../components/Input';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';

import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';

class ModalTrackingNumber extends React.Component {
    static propTypes = {
        shippingID: PropTypes.number,
        trackingNumber: PropTypes.string,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            trackingNumber: props.trackingNumber,
        }

        this.initialState = cloneDeep(this.state);
    }
    componentDidMount() {
        this.setState({
            trackingNumber: cloneDeep(this.props.trackingNumber),
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.trackingNumber != nextProps.trackingNumber) {
            this.setState({
                trackingNumber: cloneDeep(nextProps.trackingNumber),
            })
        }
    }

    resetState = () => {
        this.setState({
            ...cloneDeep(this.initialState),
        })
    }

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        })
    }

    submit = () => {
        this.props.onSubmit(this.props.shippingID, this.state.trackingNumber);
    }

    render() {
        const { t } = this.props;
        const { trackingNumber } = this.state;

        return (
            <Modal
                id='modal-shipping-trackingNumber'
                title={t('SHIPPINGS.UPDATE_TITLE_TRACKING_NUMBER')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                isLoading={this.props.isLoading}
                width={350}
                bodyClassName="unset-modal"
            >
                <Input
                    name="trackingNumber"
                    value={trackingNumber}
                    onChange={this.onChangeInput}
                    placeholder={t('SHIPPINGS.PLACE_HOLDER_TRACKING_NUMBER')}
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalTrackingNumber));