import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }
            
            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_COUNTRIES: {
            return {
                ...state,
                action: types.LIST_COUNTRIES,
                status: action.status,
                message: action.message,
                countries: action.status ? action.payload.countries : [],
            }
        }
        case types.LIST_STATES: {
            return {
                ...state,
                action: types.LIST_STATES,
                status: action.status,
                message: action.message,
                states: action.status ? action.payload.states : [],
            }
        }
        case types.LIST_DISTRICTS: {
            return {
                ...state,
                action: types.LIST_DISTRICTS,
                status: action.status,
                message: action.message,
                districts: action.status ? action.payload.districts : [],
            }
        }
        case types.LIST_WARDS: {
            return {
                ...state,
                action: types.LIST_WARDS,
                status: action.status,
                message: action.message,
                wards: action.status ? action.payload.wards : [],
            }
        }
        case types.ADDRESS_CONFIG_RESET_COUNTRIES: {
            return {
                ...state,
                action: types.ADDRESS_CONFIG_RESET_COUNTRIES,
                status: true,
                message: '',
                countries: [],
                states: [],
                districts: [],
                wards: []
            }
        }
        case types.ADDRESS_CONFIG_RESET_STATES: {
            return {
                ...state,
                action: types.ADDRESS_CONFIG_RESET_STATES,
                status: true,
                message: '',
                states: [],
                districts: [],
                wards: []
            }
        }
        case types.ADDRESS_CONFIG_RESET_DISTRICTS: {
            return {
                ...state,
                action: types.ADDRESS_CONFIG_RESET_DISTRICTS,
                status: true,
                message: '',
                districts: [],
                wards: []
            }
        }
        case types.ADDRESS_CONFIG_RESET_WARDS: {
            return {
                ...state,
                action: types.ADDRESS_CONFIG_RESET_WARDS,
                status: true,
                message: '',
                wards: []
            }
        }
        case types.ADDRESS_CONFIG_RESET_ALL: {
            return {
                ...state,
                action: types.ADDRESS_CONFIG_RESET_ALL,
                status: true,
                message: '',
                countries: [],
                states: [],
                districts: [],
                wards: []
            }
        }
        default:
            return state;
    }
}

