import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";
import { withTranslation } from "react-i18next";

import appConfig from "../../../config/app";

class ModalExportProduct extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    isLoadingExportProduct: PropTypes.bool,
    isLoadingExportTransaction: PropTypes.bool,
    onExportProduct: PropTypes.func,
    onExportTransaction: PropTypes.func,
    onCancel: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      selected: appConfig.EXPORT_PRODUCT.ON_PAGE,
    };
  }

  onChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };

  exportProduct = () => {
    this.props.onExportProduct(this.state.selected);
  };

  exportTransaction = () => {
    this.props.onExportTransaction(this.state.selected);
  };

  render() {
    const { selected } = this.state;
    const { t, visible, onCancel, isLoadingExportProduct } = this.props;

    return (
      <Modal
        id="modal-export-data"
        title={t("PRODUCTS.EXPORT_PRODUCT")}
        visible={visible}
        onCancel={onCancel}
        onOk={this.exportProduct}
        cancelText={t("ACTIONS.REMOVE")}
        okText={t("ACTIONS.EXPORT_DATA")}
        width={520}
        hasFooter={true}
      >
        <div className="item">
          <input
            type="radio"
            className="ladiui form-check-input"
            id="onPage"
            value={appConfig.EXPORT_PRODUCT.ON_PAGE}
            name="radio-group"
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_PRODUCT.ON_PAGE}
          />
          <label htmlFor="onPage">{t("PRODUCTS.EXPORT_ON_PAGE")}</label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="ladiui form-check-input"
            id="allProduct"
            name="radio-group"
            value={appConfig.EXPORT_PRODUCT.ALL_PRODUCT}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_PRODUCT.ALL_PRODUCT}
          />
          <label htmlFor="allProduct">{t("PRODUCTS.EXPORT_ALL_PRODUCT")}</label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="ladiui form-check-input"
            id="selectedProduct"
            name="radio-group"
            value={appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_PRODUCT.SELECTED_PRODUCT}
          />
          <label htmlFor="selectedProduct">{t("PRODUCTS.EXPORT_SELECTED_PRODUCT")}</label>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalExportProduct);
