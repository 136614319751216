/** Import from third party */

import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { map, includes, remove, find, cloneDeep } from 'lodash';

/** Import component from my app */
import { withTranslation } from 'react-i18next';
import ModalTicket from './ModalTicket';

import appConfig from '../../../config/app';
import * as variantTypes from '../../../redux/futures/product_variant/types';
import variantActions from '../../../redux/futures/product_variant/actions';

import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import ConfirmModal from '../../../components/ConfirmModal';
import baseHelper from '../../../helpers/BaseHelper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import produce from 'immer';
import LoadingScene from '../../../components/LoadingScene';
import ticketSeatActions from '../../../redux/futures/ticket_seat/actions';
import { t } from 'i18next';

const PanelTicket = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { i18n, onClick, info } = props;

    const inputsRef = new Set();
    const formTicketRef = useRef();

    const storeReducer = useSelector((state) => state.store);
    const variantReducer = useSelector((state) => state.variant);
    const productReducer = useSelector((state) => state.product);

    const [variants, setVariants] = useState([]);
    const [mode, setMode] = useState(appConfig.FORM_MODE.EDIT);
    const [options, setOptions] = useState([
        {
            name: t('PRODUCTS.VARIANT_TICKET_DEFAULT'),
            position: 1,
            type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
            values: [],
        },
    ]);
    const [currentProductInfo, setCurrentProductInfo] = useState({});
    const [selectedVariant, setSelectedVariant] = useState({});
    const [isShowModalTicket, setIsShowModalTicket] = useState(false);

    const openModalTicketCreate = () => {
        // this.setState({
        //     selectedVariant: {},
        //     mode: appConfig.FORM_MODE.CREATE,
        //     isShowModalTicket: true,
        // });
        setSelectedVariant({});
        setIsShowModalTicket(true);
        setMode(appConfig.FORM_MODE.CREATE);
    };

    // const getData = () => {
    //     // let { variants, options } = cloneDeep(this.state);

    //     if (variants.length <= 0) {
    //         options = [];
    //     }

    //     remove(options, (option) => !option.values || option.values.length <= 0);
    //     map(variants, (item, index) => (item.position = index));

    //     return {
    //         variants,
    //         options,
    //     };
    // };

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },

        getData() {
            let _variants = cloneDeep(variants);
            let _options = cloneDeep(options);
            if (_variants.length <= 0) {
                _options = [];
            }

            remove(_options, (option) => !option.values || option.values.length <= 0);
            map(_variants, (item, index) => (item.position = index));
            return {
                variants: _variants,
                options: _options,
            };
        },
    }));

    useEffect(() => {
        setCurrentProductInfo(props.currentProductInfo);
    }, [props.currentProductInfo]);

    useEffect(() => {
        if (productReducer.action == productTypes.UPDATE_PRODUCT) {
            if (productReducer.status) {
                dispatch(productActions.reload(props.productID));
                setIsShowModalTicket(false);
                window.LadiUI.toastCustom('success', '', productReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (variantReducer.action == variantTypes.CREATE_VARIANT) {
            if (variantReducer.status) {
                setIsShowModalTicket(false);
                dispatch(productActions.reload(props.productID));
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.UPDATE_VARIANT) {
            if (variantReducer.status) {
                setIsShowModalTicket(false);
                dispatch(productActions.reload(props.productID));
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
        if (variantReducer.action == variantTypes.DELETE_VARIANT) {
            if (variantReducer.status) {
                dispatch(productActions.reload(props.productID));
                window.LadiUI.closeModal('confirm-variant-delete');
                window.LadiUI.toastCustom('success', '', variantReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', variantReducer.message, 'OK');
            }
        }
    }, [variantReducer]);

    useEffect(() => {
        if (!props.options || props.options.length <= 0 || props.options[0].values.length <= 0) {
            setVariants([]);
            setOptions([
                {
                    name: t('PRODUCTS.VARIANT_TICKET_DEFAULT'),
                    position: 1,
                    type: appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE,
                    values: [],
                },
            ]);
            return;
        }

        setVariants(
            produce(props.variants, (draft) => {
                draft.forEach((item) => {
                    delete item.package_quantity_unit;
                    if (props.mode == appConfig.FORM_MODE.EDIT) {
                        item.option_value_value = item.options[0].option_value_value.name;
                        item.variant_id = item.product_variant_id;
                        item.options[0][props.options[0].name] = item.options[0].option_value_value.name;
                    } else {
                        item.option_value_value = item.options[props.options[0].name];
                    }
                });
            })
        );
        setOptions(props.options);
    }, [props.variants, props.options]);

    const createTicket = (variant) => {
        let _variants = [...variants];
        let _options = [...options];
        let isExists = false;

        if (mode == appConfig.FORM_MODE.CREATE) {
            const foundOptionValue = find(_options[0].values, (item) => item.name == variant.option_value_value);
            if (foundOptionValue) {
                isExists = true;
            }
        } else {
            let foundOptionValue;
            if (props.mode == appConfig.FORM_MODE.EDIT) {
                foundOptionValue = find(
                    options[0].values,
                    (item) => item.name != variant.options[0][_options[0].name] && item.name == variant.option_value_value
                );
            } else {
                foundOptionValue = find(
                    _options[0].values,
                    (item) => item.name != variant.options[_options[0].name] && item.name == variant.option_value_value
                );
            }
            if (foundOptionValue) {
                isExists = true;
            }
        }

        if (isExists) {
            window.LadiUI.toastCustom('danger', '', t('PRODUCTS.EXISTS_TICKET_NAME'));
            return;
        }

        if (props.mode == appConfig.FORM_MODE.CREATE) {
            if (mode == appConfig.FORM_MODE.CREATE) {
                _options = produce(_options, (draft) => {
                    draft[0].values.push({
                        name: variant.option_value_value,
                    });
                });

                variant.options = {
                    [_options[0].name]: variant.option_value_value,
                };
                _variants = produce(_variants, (draft) => {
                    draft.push(variant);
                });
            } else {
                _options = produce(_options, (draft) => {
                    const foundOptionValue = draft[0].values.find((item) => item.name == variant.options[_options[0].name]);
                    foundOptionValue.name = variant.option_value_value;
                });

                _variants = produce(_variants, (draft) => {
                    const fountVariantIndex = draft.findIndex(
                        (item) => item.options[_options[0].name] == variant.options[_options[0].name]
                    );
                    variant.options = {
                        [_options[0].name]: variant.option_value_value,
                    };
                    draft[fountVariantIndex] = variant;
                });
            }

            // this.setState({
            //     variants,
            //     options,
            //     isShowModalTicket: false,
            // });
            setVariants(_variants);
            setOptions(_options);
            setIsShowModalTicket(false);
        } else {
            if (mode == appConfig.FORM_MODE.CREATE) {
                _options = produce(_options, (draft) => {
                    draft[0].values.push({
                        name: variant.option_value_value,
                    });
                });

                variant.options = [
                    {
                        option_id: _options[0].option_id,
                        [_options[0].name]: variant.option_value_value,
                        option_value_value: {
                            name: variant.option_value_value,
                        },
                    },
                ];
                variant.product_id = props.productID;

                if (_options && _options.length > 0 && _options[0].values.length > 1) {
                    // this.props.create(variant);
                    dispatch(variantActions.create(variant));
                } else {
                    variant.product_variant_id = props.variants[0].product_variant_id;
                    variant.options = {
                        [_options[0].name]: variant.option_value_value,
                    };
                    const productReq = {
                        product_id: props.productID,
                        variants: [variant],
                        options: _options,
                    };
                    // this.props.updateProduct(productReq);
                    dispatch(productActions.update(productReq));
                }
            } else {
                const foundOptionValue = find(_options[0].values, (item) => item.name == variant.options[0][_options[0].name]);
                foundOptionValue.name = variant.option_value_value;

                variant.options[0][_options[0].name] = variant.option_value_value;
                variant.options[0].option_value_value = {
                    name: variant.option_value_value,
                };

                // this.props.update(variant);

                dispatch(variantActions.update(variant));
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }
        const items = reorder(variants, result.source.index, result.destination.index);
        setVariants(items);
    };

    const openModalTicketEdit = (_selectedVariant) => {
        setSelectedVariant(_selectedVariant);
        setMode(appConfig.FORM_MODE.EDIT);
        setIsShowModalTicket(true);
    };

    const removeTicket = (index) => {
        setVariants(
            produce(variants, (draft) => {
                draft.splice(index, 1);
            })
        );
        setOptions(
            produce(options, (draft) => {
                const foundIndex = draft[0].values.findIndex((item) => item.name == variants[index].option_value_value);
                draft[0].values.splice(foundIndex, 1);
            })
        );
    };

    const openModelDelete = (variant) => {
        setSelectedVariant(variant);

        window.LadiUI.showModal('confirm-variant-delete');
    };

    const handleCopyTicketId = (ticketID) => {
        navigator.clipboard.writeText(ticketID);
        window.LadiUI.toastCustom('success', '', 'Đã copy vào clipboard!');
    };

    const filteredVariant = () => {
        return variants.map((variant, index) => {
            const isAvailableVariant = baseHelper.isAvailableVariant(variant);
            return (
                <Draggable key={variant.variant_id || index} draggableId={'' + (variant.variant_id || index)} index={index}>
                    {(provided, snapshot) => (
                        <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            style={{
                                ...provided.draggableProps.style,
                                userSelect: 'none',
                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                display: 'table-row',
                            }}
                            className={!isAvailableVariant ? 'ladiui table-vertical' : 'ladiui table-vertical'}
                        >
                            <td>
                                <div className='flex items-center'>
                                    <i className='ldicon-drag-drop' {...provided.dragHandleProps} />
                                </div>
                            </td>
                            <td>
                                {variant.option_value_value}
                                {!isAvailableVariant && (
                                    <span className='badge badge-child-light sold-out flex items-center'>
                                        <i className='ladi-icon icon-c-warning mr-4' />
                                        <React.Fragment>
                                            {baseHelper.isNotOpenForSale(variant) ? t('COMMON.NOT_OPEN_FOR_SALE') : t('COMMON.SOLD_OUT')}
                                        </React.Fragment>
                                    </span>
                                )}
                            </td>
                            <td className='space-nowrap'>
                                {baseHelper.formatMoneyPostFix(variant.price, storeReducer?.userInfo?.currentStore?.currency_symbol)}
                            </td>
                            <td>{variant.total_quantity}</td>
                            <td>{variant.total_sold}</td>
                            <td>
                                <button
                                    className='cursor-pointer tooltip'
                                    style={{ backgroundColor: 'transparent' }}
                                    onClick={() => handleCopyTicketId(variant.variant_id)}
                                >
                                    {variant.variant_id}
                                    <span className='tooltiptext right' tooltiptext={'Copy'}></span>
                                </button>
                            </td>
                            <td className='space-nowrap'>{variant.sku}</td>
                            <td className='text-right pd-0'>
                                <div className='ladiui btn-group'>
                                    <div className='ladiui dropdown hide-mt ba-c'>
                                        <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                            <i className='ladiui icon icon-ldp-dot'></i>
                                        </button>
                                        <ul className='ladiui dropdown-menu r-0'>
                                            <li>
                                                <a className='ladiui dropdown-item' onClick={() => openModalTicketEdit(variant)}>
                                                    {t('ACTIONS.EDIT')}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className='ladiui dropdown-item'
                                                    onClick={() => {
                                                        if (props.mode == appConfig.FORM_MODE.CREATE) {
                                                            removeTicket(index);
                                                        } else {
                                                            openModelDelete(variant);
                                                        }
                                                    }}
                                                >
                                                    {t('ACTIONS.DELETE')}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )}
                </Draggable>
            );
        });
    };

    return (
        <div className='block-panel-ticket-v4'>
            {(!options || options.length <= 0 || options[0].values.length <= 0) && (
                <div className='block-no-ticket'>
                    <h3>Thêm loại vé cho sự kiện này</h3>
                    <p>Khách hàng sẽ mua vé để tham gia sự kiện</p>
                    <button type='button' onClick={openModalTicketCreate}>
                        <i className='icon-ldp-add'></i>
                        Tạo loại vé mới
                    </button>
                </div>
            )}
            {variants && variants.length > 0 && (
                <div className='block-list-tickets'>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='droppable'>
                            {(provided, snapshot) => (
                                <div
                                    className='ladi-card'
                                    ref={provided.innerRef}
                                    style={{
                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                    }}
                                >
                                    <table className='ladiui table text-left'>
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col' />
                                                <th scope='col' style={{ width: '40%' }}>
                                                    {t('PRODUCTS.VARIANT_TICKET_DEFAULT')}
                                                </th>
                                                <th scope='col'>{t('PRODUCTS.PRICE')}</th>
                                                <th scope='col'>{t('PRODUCT_EVENTS.QUANTITY')}</th>
                                                <th scope='col'>{t('PRODUCT_EVENTS.LB_TOTAL_SOLD')}</th>
                                                <th scope='col'>{t('PRODUCTS.ID')}</th>
                                                <th scope='col'>{t('PRODUCTS.SKU')}</th>
                                                <th scope='col' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredVariant()}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <a onClick={openModalTicketCreate} className='flex items-center mt-24'>
                        <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' alt='' />
                        <span>{t('PRODUCTS.LB_ADD_VARIANT_TICKET')}</span>
                    </a>
                </div>
            )}

            {isShowModalTicket && (
                <ModalTicket
                    ref={formTicketRef}
                    mode={mode}
                    currentVariantInfo={selectedVariant}
                    images={[]}
                    onCancel={() => setIsShowModalTicket(false)}
                    onSubmit={createTicket}
                    currentProductInfo={currentProductInfo}
                    visible={isShowModalTicket}
                    // isLoading={isSubmitLoading}
                />
            )}

            <ConfirmModal
                id='confirm-variant-delete'
                title={t('PRODUCT_EVENTS.MSG_DELETE_PRODUCT_VARIANT_EVENT_TITLE')}
                content={t('PRODUCT_EVENTS.MSG_DELETE_PRODUCT_VARIANT_EVENT_CONTENT', selectedVariant.option_value_value)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => dispatch(variantActions.delete(props.productID, selectedVariant.product_variant_id))}
                // isLoading={isLoadingDeleteVariant}
            />
        </div>
    );
});
export default PanelTicket;
