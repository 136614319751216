import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as customerTypes from '../../../../redux/futures/customer/types';
import customerActions from '../../../../redux/futures/customer/actions';

import Dropdown from '../../../../components/DropDownNew';
import PanelAddress from '../../../../components/PanelAddress';
import Location from '../../../../components/Location';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCustomerUpdate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible, hideModal, _customer, updateCustomer } = props;

    const customerReducer = useSelector((state) => state.customer);

    const [customer, setCustomer] = useState({});
    const [isUpdate, setUpdate] = useState(false);

    const loading = useMemo(() => {
        return customerReducer.loading || false;
    }, [customerReducer.loading]);

    const onCancel = () => {
        hideModal();
    };

    useEffect(() => {
        setCustomer(_customer);
    }, [_customer]);

    useEffect(() => {
        if (customerReducer.action == customerTypes.UPDATE_CUSTOMER) {
            if (customerReducer.status) {
                updateCustomer(customer);
                hideModal();
            } else {
                window.LadiUI.showErrorMessage('Thông báo', customerReducer.message, 'OK');
            }
        }
    }, [customerReducer.action]);

    const onOk = () => {
        if (!customer.first_name) {
            window.LadiUI.toastCustom('danger', '', t('CUSTOMERS.NAME_EMPTY'));
            return;
        }
        if (!customer.email && customer.phone) {
            window.LadiUI.toastCustom('danger', '', t('CUSTOMERS.EMAIL_OR_PHONE_EMPTY'));
            return;
        }

        let dataSend = {
            customer_id: customer.customer_id,
            first_name: customer.first_name,
            email: customer.email,
            phone: customer.phone,
        };
        if (isUpdate) {
            dispatch(customerActions.update(dataSend));
            return;
        }
        updateCustomer(customer);
        hideModal();
    };

    const customerEdit = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setCustomer((pre) => {
            let tg = { ...pre };
            tg[name] = value;
            return tg;
        });
    };

    return (
        <Modal
            width={600}
            id='modal-customer-update'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('CUSTOMERS.ADD_CUSTOMER')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('ACTIONS.SAVE')}
            isLoading={loading}
            position_unset={true}
            hasFooter={true}
            footer={
                <div>
                    <div className='ladiui flex-center-between w-100-p'>
                        <div className='display-flex-center'>
                            <input
                                type='checkbox'
                                onClick={(event) => setUpdate(!isUpdate)}
                                onChange={() => {}}
                                checked={isUpdate}
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                            />
                            <span className='ladiiui text-14 text-primary'>{t('CUSTOMERS.CHECK_UPDATE')}</span>
                        </div>
                        <div className='display-flex-center'>
                            <button onClick={onCancel} className='ladiui btn btn-secondary '>
                                {t('ACTIONS.REMOVE')}
                            </button>

                            <button onClick={onOk} className={`ladiui btn btn-primary ${loading ? 'loader' : ''}`} disabled={loading}>
                                {t('ACTIONS.SAVE')}
                            </button>
                        </div>
                    </div>
                </div>
            }
        >
            <div className='content-customer-create'>
                <div>
                    <label>{t('CUSTOMERS.FULL_NAME')}</label>
                    <Input className='mt-4' type='text' name='first_name' value={customer.first_name} onChange={customerEdit} />
                </div>
                <div className='mt-16 customer-email-phone'>
                    <div className='item'>
                        <label>{t('COMMON.EMAIL')}</label>
                        <Input className='mt-4' type='text' name='email' value={customer.email} onChange={customerEdit} />
                    </div>
                    <div className='item'>
                        <label>{t('COMMON.PHONE')}</label>
                        <Input
                            className='mt-4'
                            type='text'
                            name='phone'
                            value={customer.phone}
                            onChange={customerEdit}
                            placeholder='Vd: 0981111111'
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalCustomerUpdate;
