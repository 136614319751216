import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import baseHelper from '../helpers/BaseHelper';
import appConfig from '../config/app';

import storeAction from '../redux/futures/store/actions';
import * as storeTypes from '../redux/futures/store/types';

import Modal from '../components/Modal';
import Input from '../components/Input';
import config from '../config/config';

export default function Onboarding(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const storeReducer = useSelector((state) => state.store);
    const loading = useMemo(() => {
        return storeReducer.loading || false;
    }, [storeReducer.loading]);

    const currentStore = useMemo(() => {
        if (!storeReducer.userInfo) return {};
        return storeReducer.userInfo.currentStore || {};
    }, [storeReducer.userInfo]);

    const [isShowModalOnBoard, setShowModalOnBoard] = useState(true);
    const [onBoardActive, setOnBoardActive] = useState(1);

    const [isShowModalCreate, setShowModalCreate] = useState(false);
    const [isUsingDefault, setUsingDefault] = useState(false);
    const [storeName, setStoreName] = useState('');
    const [storeNameError, setStoreNameError] = useState('');
    const [pageCheckoutID, setPageCheckoutID] = useState('');

    const [isShowModalCreateSuccess, setShowModalCreateSuccess] = useState(false);

    const onboadingData = [
        {
            id: 1,
            title: t('ONBOARDING.TITLE_TAB1'),
            content: t('ONBOARDING.CONTENT_TAB1'),
            src: 'https://w.ladicdn.com/ladiui/icons/new-ldicon-image-active-ecommerce1.png',
        },
        {
            id: 2,
            title: t('ONBOARDING.TITLE_TAB2'),
            content: t('ONBOARDING.CONTENT_TAB2'),
            src: 'https://w.ladicdn.com/ladiui/icons/new-ldicon-image-active-ecommerce2.png',
        },
        {
            id: 3,
            title: t('ONBOARDING.TITLE_TAB3'),
            content: t('ONBOARDING.CONTENT_TAB3'),
            src: 'https://w.ladicdn.com/ladiui/icons/new-ldicon-image-active-ecommerce3.png',
        },
        {
            id: 4,
            title: t('ONBOARDING.TITLE_TAB4'),
            content: t('ONBOARDING.CONTENT_TAB4'),
            src: 'https://w.ladicdn.com/ladiui/icons/new-ldicon-image-active-ecommerce4.png',
        },
    ];

    useEffect(() => {
        setStoreName(currentStore.name || '');
    }, [currentStore]);

    useEffect(() => {
        if (storeReducer.action == storeTypes.UPDATE_STATUS_STORE) {
            if (storeReducer.status) {
                if (storeReducer.page_checkout_id) setPageCheckoutID(storeReducer.page_checkout_id);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), storeReducer.message, 'OK');
            }
        }
    }, [storeReducer.action]);

    const updateStore = () => {
        if (!storeName) {
            setStoreNameError(t('ONBOARDING.ERROR_NAME'));
            return;
        }
        if (storeName.length > 200) {
            setStoreNameError(t('ONBOARDING.ERROR_NAME_MAX'));
            return;
        }

        if (!currentStore) {
            return;
        }

        let storeUpdate = {
            store: { name: storeName, isDefault: isUsingDefault },
        };

        dispatch(storeAction.updateStatus(storeUpdate));
        setShowModalCreateSuccess(true);
    };

    // const openLink = (url) => {
    //     dispatch(storeAction.getUserInfo());
    //     setTimeout(() => {
    //         history.push(url);
    //     }, 1000);
    // };

    // const

    return (
        <div className='iframe-open'>
            <div className='page-order'>
                <div className='page-max'>
                    <div className='page-right'>
                        <div className='page-content'>
                            <div className='page-onboading'>
                                <img
                                    className='page-onboading-image'
                                    src='https://w.ladicdn.com/ladiui/icons/new-ldicon-image-onboarding-ecommerce.png'
                                />

                                <div className='page-onboading-content'>
                                    <div className='title'>eCcommerce</div>
                                    <div className='sub-title'>{t('ONBOARDING.DESCRIPTION')}</div>
                                    <button className='ladiui btn btn-primary btn-md' onClick={() => setShowModalOnBoard(true)}>
                                        {t('ACTIONS.ENABLE')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                id='onboarding-account'
                visible={isShowModalOnBoard}
                onCancel={() => {}}
                onOk={() => {}}
                title=''
                width={460}
                hasFooter={false}
                hasHeader={false}
            >
                {onboadingData.map((item, index) => {
                    return (
                        <div key={index} className={`${item.id == onBoardActive ? 'show' : 'hide'} onboading-item`}>
                            <img className='onboading-image' src={item.src} />
                            <div className='onboading-item-content'>
                                <div className='title'>{item.title}</div>
                                <div className='content'>{item.content}</div>
                            </div>
                        </div>
                    );
                })}

                <div className='onboading-page-dot'>
                    <div className={`ladiui pagination-dot ${onBoardActive == 1 ? 'active' : ''}`} />
                    <div className={`ladiui pagination-dot ${onBoardActive == 2 ? 'active' : ''}`} />
                    <div className={`ladiui pagination-dot ${onBoardActive == 3 ? 'active' : ''}`} />
                    <div className={`ladiui pagination-dot ${onBoardActive == 4 ? 'active' : ''}`} />
                </div>
                <div className='onboading-item-action'>
                    <button
                        className='ladiui btn btn-secondary btn-md'
                        onClick={() => {
                            setShowModalCreate(true);
                            setShowModalOnBoard(false);
                        }}
                    >
                        {t('ACTIONS.SKIP')}
                    </button>
                    <button
                        className='ladiui btn btn-primary btn-md'
                        onClick={() => {
                            if (onBoardActive < 4) setOnBoardActive(onBoardActive + 1);
                            else {
                                setShowModalCreate(true);
                                setShowModalOnBoard(false);
                            }
                        }}
                    >
                        {t('ACTIONS.NEXT')}
                    </button>
                </div>
            </Modal>

            <Modal
                id='create-account'
                visible={isShowModalCreate}
                onCancel={() => {}}
                onOk={() => {}}
                title=''
                width={460}
                hasFooter={false}
                hasHeader={false}
                loading={loading}
            >
                <div className='store-create-name'>
                    <div className='title'>{t('STORES.STORE_CREATE_HOLDER')}</div>
                    <Input
                        className={`ladiui mt-24 ${storeNameError ? 'error' : ''}`}
                        value={storeName}
                        onChange={(e) => {
                            setStoreName(e.target.value);
                            setStoreNameError('');
                        }}
                        placeholder={t('ONBOARDING.NAME_PLACEHOLER')}
                    />
                    {storeNameError && <div className='ladiui input-sub-error'>{storeNameError} </div>}

                    <div className='mt-24 laidui flex gap-8'>
                        <div>
                            <input
                                id='store-using-default'
                                type='checkbox'
                                checked={isUsingDefault}
                                onClick={() => setUsingDefault((pre) => !pre)}
                                className='ladiui checkbox form-check-input-checkbox'
                            />
                        </div>
                        <span className=''>{t('ONBOARDING.LABEL_DEFAULT')}</span>
                    </div>
                    <button className='ladiui btn btn-md btn-primary w-100-p mt-24 btn-start' onClick={updateStore}>
                        {t('ACTIONS.INITTIALIZATION')}
                    </button>
                </div>
            </Modal>

            <Modal
                id='create-account-success'
                visible={isShowModalCreateSuccess}
                onCancel={() => {}}
                onOk={() => {}}
                title=''
                width={460}
                hasFooter={false}
                hasHeader={false}
            >
                <div className='create-account-success-content'>
                    {loading ? (
                        <div className='create-account-loading'>
                            <img className='image-loading' src='https://w.ladicdn.com/ladiui/icons/ldicon-loading2.svg' />
                            <div className='title'>{t('ONBOARDING.INIT')}</div>
                            <div className='sub-title mt-4'>{t('ONBOARDING.INIT_CONTENT')}</div>
                        </div>
                    ) : (
                        <div className='create-account-success'>
                            <div className='header'>
                                <div className='bg-hoa-hoet'>
                                    <div className='check-success'>
                                        <i className='ladiui icon-new ldicon-circle-check' />
                                    </div>
                                </div>
                                <div className='title mt-24'>{t('ONBOARDING.INIT_SUCCESS')}</div>
                                <div className='sub-title mt-8'>{t('ONBOARDING.INIT_SUCCESS_TITLE')}</div>
                            </div>
                            <div className='list-actions mt-32'>
                                <div className='action-item'>
                                    <div className='antion-name'>
                                        <i className='new-ldicon-circlecheck-fill' />
                                        <span>{t('ONBOARDING.INIT_ACTION_1')}</span>
                                    </div>
                                    <a
                                        className='action'
                                        href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_SHIPPING}`}
                                        target='_parent'
                                    >
                                        {t('ACTIONS.SETUP')}
                                    </a>
                                </div>
                                <div className='action-item'>
                                    <div className='antion-name'>
                                        <i className='new-ldicon-circlecheck-fill' />
                                        <span>{t('ONBOARDING.INIT_ACTION_2')}</span>
                                    </div>
                                    <div
                                        className='action'
                                        href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_PAYMENT_GATEWAY}`}
                                        target='_parent'
                                        // onClick={() => {
                                        //     openLink('/settings?tab=PAYMENT');
                                        // }}
                                    >
                                        {t('ACTIONS.SETUP')}
                                    </div>
                                </div>
                                <div className='action-item'>
                                    <div className='antion-name'>
                                        <i className='new-ldicon-circlecheck-fill' />
                                        <span>{t('ONBOARDING.INIT_ACTION_3')}</span>
                                    </div>
                                    <div
                                        className='action'
                                        href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_PAYMENT}`}
                                        target='_parent'

                                        // onClick={() => {
                                        //     openLink('/settings?tab=CONFIG_CHECKOUT');
                                        // }}
                                    >
                                        {t('ACTIONS.SETUP')}
                                    </div>
                                </div>
                            </div>
                            <div className='ladiui flex-center-between gap-24 mt-32'>
                                <a
                                    className='ladiui btn btn-md btn-secondary w-100-p'
                                    href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.ORDER_LIST}`}
                                    target='_parent'
                                    // onClick={() => {
                                    //     openLink('/orders?order_tab=ALL');
                                    // }}
                                >
                                    {t('ONBOARDING.GO_TO_DASHBOARD')}
                                </a>
                                {pageCheckoutID && (
                                    <a
                                        className='ladiui btn btn-md btn-primary w-100-p'
                                        href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PAGE_CHECKOUT_UPDATE(pageCheckoutID)}`}
                                        target='_parent'
                                        // onClick={() => {
                                        //     openLink('/page-checkout?tab=1');
                                        // }}
                                    >
                                        {t('ONBOARDING.GO_TO_STORE')}
                                    </a>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}
