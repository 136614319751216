import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";
import { withTranslation } from "react-i18next";

import appConfig from "../../../config/app";

class ModalExportCustomer extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    isLoadingExportCustomer: PropTypes.bool,
    isLoadingExportTransaction: PropTypes.bool,
    onExportCustomer: PropTypes.func,
    onExportTransaction: PropTypes.func,
    onCancel: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      selected: appConfig.EXPORT_CUSTOMER.ON_PAGE,
    };
  }

  onChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };

  exportCustomer = () => {
    this.props.onExportCustomer(this.state.selected);
  };

  exportTransaction = () => {
    this.props.onExportTransaction(this.state.selected);
  };

  render() {
    const { selected } = this.state;
    const { t, visible, onCancel, isLoadingExportCustomer } = this.props;

    return (
      <Modal
        id="modal-export-data"
        title={t("CUSTOMERS.EXPORT_CUSTOMER")}
        visible={visible}
        onCancel={onCancel}
        onOk={this.exportCustomer}
        cancelText={t("ACTIONS.REMOVE")}
        okText={t("ACTIONS.EXPORT_DATA")}
        width={520}
        hasFooter={true}
      >
        <div className="item">
          <input
            id="onPage"
            type="radio"
            name="radio-group"
            className="ladiui form-check-input"
            value={appConfig.EXPORT_CUSTOMER.ON_PAGE}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_CUSTOMER.ON_PAGE}
          />
          <label htmlFor="onPage">{t("CUSTOMERS.EXPORT_ON_PAGE")}</label>
        </div>

        <div className="item">
          <input
            id="allCustomer"
            type="radio"
            className="ladiui form-check-input"
            name="radio-group"
            value={appConfig.EXPORT_CUSTOMER.ALL_CUSTOMER}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_CUSTOMER.ALL_CUSTOMER}
          />
          <label htmlFor="allCustomer">{t("CUSTOMERS.EXPORT_ALL_CUSTOMER")}</label>
        </div>

        <div className="item">
          <input
            id="selectedCustomer"
            type="radio"
            className="ladiui form-check-input"
            name="radio-group"
            value={appConfig.EXPORT_CUSTOMER.SELECTED_CUSTOMER}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_CUSTOMER.SELECTED_CUSTOMER}
          />
          <label htmlFor="selectedCustomer">{t("CUSTOMERS.EXPORT_SELECTED_CUSTOMER")}</label>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalExportCustomer);
