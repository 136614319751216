import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import fileActions from '../redux/futures/file/actions';
import * as fileTypes from '../redux/futures/file/types';

import Input from './Input';
import baseHelper from '../helpers/BaseHelper';
import { isEmpty } from 'lodash';

const CDN = 'https://w.ladicdn.com/';

const PanelProductSeoSocialSetting = forwardRef((props, ref) => {
    let { isShowTitle, isShowImageSharing } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducerDefault = useSelector((state) => state.file);

    const [descriptionPage, setDescriptionPage] = useState('');
    const [keyWordPage, setKeyWordPage] = useState('');
    const [titlePage, setTittlePage] = useState('');
    const [urlImage, setUrlImage] = useState('');
    const [urlFavicon, setUrlFavicon] = useState('');

    const inputsRef = new Set();

    const handleChangeImageUrl = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadImages(form));
            }
        }
    };

    // useEffect(() => {
    //   if (fileReducerDefault.status) {
    //     if (fileReducerDefault.url_images) {
    //       setUrlImage(fileReducerDefault.url_images[0]);
    //     }
    //     if (fileReducerDefault.favicon_url) {
    //       setUrlFavicon("https://w.ladicdn.com/" + fileReducerDefault.favicon_url[0]);
    //     }
    //   }
    // }, [fileReducerDefault]);

    useEffect(() => {
        if (fileReducerDefault.action == fileTypes.UPLOAD_IMAGES) {
            if (fileReducerDefault.status) {
                if (fileReducerDefault.url_images) {
                    setUrlImage(fileReducerDefault.url_images[0]);
                }
            }
        }

        if (fileReducerDefault.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducerDefault.status) {
                setUrlFavicon(fileReducerDefault.favicon_url);
            }
        }
    }, [fileReducerDefault]);

    useEffect(() => {
        if (props.productInfoSeo && !isEmpty(props.productInfoSeo)) {
            let { title, description, keywords, favicon, image } = props.productInfoSeo;
            setTittlePage(title);
            setDescriptionPage(description);
            setUrlImage(image);
            setKeyWordPage(keywords);
            setUrlFavicon(favicon);
        }
    }, [props.productInfoSeo]);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                title: titlePage ? titlePage : '',
                description: descriptionPage ? descriptionPage : '',
                keywords: keyWordPage ? keyWordPage : '',
                image: urlImage ? urlImage : '',
                favicon: urlFavicon ? urlFavicon : '',
            };
        },
    }));

    const fileChangedHandlerCartFavicon = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadFavicon(form));
            }
        }
    };

    const removeCartFavicon = () => {
        setUrlFavicon('');
    };

    return (
        <>
            <div className='ladiui form-group mt-0'>
                <label className='ladiui-label'>{t('SEO_SOCIAL.TITLE_PAGE')}</label>
                <Input
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_TITLE')}
                    value={titlePage}
                    onChange={(event) => {
                        setTittlePage(event.target.value);
                    }}
                />
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('SEO_SOCIAL.DESCRIPTION')}</label>
                <textarea
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_DESCRIPTION')}
                    value={descriptionPage}
                    className='ladiui form-control'
                    onChange={(event) => {
                        setDescriptionPage(event.target.value);
                    }}
                ></textarea>
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('SEO_SOCIAL.KEY_WORD')}</label>
                <Input
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_KEY_WORD')}
                    value={keyWordPage}
                    onChange={(event) => {
                        setKeyWordPage(event.target.value);
                    }}
                />
            </div>

            {
                isShowImageSharing && (
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>Hình ảnh khi chia sẻ</label>
                        <div className='ls-flex ls-justify-start ls-items-center ls-gap-12'>
                            <Input
                                className='pr-100'
                                ref={(ref) => inputsRef.add(ref)}
                                value={urlImage}
                                placeholder={t('SEO_SOCIAL.CHOOSE_PICTURE')}
                                onChange={(event) => {
                                    setUrlImage(event.target.value);
                                }}
                            />
                            <button className='ls-btn-outline-primary ls-flex ls-justify-center ls-items-center ls-gap-4' onClick={() => { document.getElementById('share-link-img').click() }}>
                                <i className='icon-export'></i>
                                <span>Tải lên</span>
                            </button>

                            <input id='share-link-img' type='file' name='file' accept='image/*' onChange={handleChangeImageUrl} style={{display: 'none'}}/>

                        </div>
                    </div>
                )
            }
        </>
    );
});

PanelProductSeoSocialSetting.propTypes = {
    titlePage: PropTypes.string,
    descriptionPage: PropTypes.string,
    img_publish: PropTypes.string,
    favicon: PropTypes.string,
};

export default PanelProductSeoSocialSetting;
