import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../config/app';
import Dropdown from '../../../../../components/Dropdown';
import pageCheckoutDefined from '../../components/pageCheckoutDefined';

// eslint-disable-next-line max-lines-per-function

const Setting = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const { search } = useLocation();

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageCheckout } = props;

    const [setting, setSetting] = useState({
        config_language: appConfig.LANGUAGES.VI.code,
    });
    const [checkoutConfigID, setCheckoutConfigID] = useState(null);

    useImperativeHandle(ref, () => ({
        getData: () => {
            return setting;
        },
    }));

    useEffect(() => {
        if (pageCheckout && pageCheckout.page_checkout_id) {
            let checkout_config = pageCheckout.checkout_config || {};
            if (checkout_config.checkout_config_id) {
                setCheckoutConfigID(checkout_config.checkout_config_id);

                let config = checkout_config.config || {};

                let settingTg = {
                    isShowDiscountInput: config.config_discount ? config.config_discount.enable_discount || false : false,
                    isShowDiscountList: config.isShowCoupon,
                    isShowShippingMethod: config.config_shipping_method
                        ? config.config_shipping_method.enable_shipping_method || false
                        : false,
                    isShowOnePageCheckout: config.config_one_page_checkout
                        ? config.config_one_page_checkout.enable_one_page_checkout || false
                        : false,
                    isShowInvoice: config.isShowInvoice,
                    hidden_multi_national: config.hidden_multi_national,
                    config_language: config.config_language,
                    // isShowStoreInfo:
                };
                setSetting(settingTg);
            }
        }
    }, [pageCheckout]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('checkout-config');

        history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const handelInput = (e) => {
        const { name, value } = e.target;

        setSetting({
            ...setting,
            [name]: !setting[name] || false,
        });
    };

    return (
        <div className={`page-checkout-config-item  page-checkout-product-list ${isShow ? 'show' : 'hide'}`}>
            <div className='header'>
                <div className='display-flex-center gap-16'>
                    <div className='icon-back' onClick={backMenu}>
                        <i className='ldicon-chevron-left' />
                    </div>
                    <span className='ladiui title-header'>{t('PAGE_CHECKOUT.MENU.SETTING')}</span>
                </div>
            </div>
            <div className='config-body'>
                <div className='config-language'>
                    <label className='ladiui label-16'>Ngôn ngữ trang thanh toán</label>
                    <Dropdown
                        wrapperClassName='mt-16 w-100-p'
                        data={appConfig.LANGUAGES}
                        currentKey={setting.config_language}
                        onSelectItem={(item) => {
                            setSetting({ ...setting, config_language: item.code });
                        }}
                    />
                </div>
                <div className='config-more'>
                    <label className='ladiui label-16'>Thiết lập hiển thị</label>
                    {pageCheckoutDefined.SETTING_SHOW.map((item, index) => {
                        return (
                            <div key={index} className='display-flex-center gap-8 mt-20'>
                                <input
                                    type='checkbox'
                                    name={item.value}
                                    onChange={handelInput}
                                    checked={setting[item.value] || false}
                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                />
                                <div>{item.title}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
});

export default Setting;
