import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { histories: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_ORDER_HISTORY: {
            return {
                ...state,
                action: types.LIST_ORDER_HISTORY,
                histories: action.status ? action.payload.order_histories : [],
                hasShowMore: action.status ? action.payload.has_show_more : false,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_ORDER_HISTORY: {
            return {
                ...state,
                action: types.DELETE_ORDER_HISTORY,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_ORDER_HISTORY: {
            return {
                ...state,
                action: types.CREATE_ORDER_HISTORY,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_ORDER_HISTORY: {
            return {
                ...state,
                action: types.UPDATE_ORDER_HISTORY,
                status: action.status,
                message: action.message,
            }
        }
        case types.WS_NEW_ORDER_HISTORY: {
            return {
                ...state,
                action: types.WS_NEW_ORDER_HISTORY,
                histories: action.payload.histories
            }
        }
        case types.WS_UPDATED_ORDER_HISTORY: {
            return {
                ...state,
                action: types.WS_UPDATED_ORDER_HISTORY,
                histories: action.payload.histories
            }
        }
        case types.WS_DELETED_ORDER_HISTORY: {
            return {
                ...state,
                action: types.WS_DELETED_ORDER_HISTORY,
                histories: action.payload.histories
            }
        }
        default:
            return state;
    }
}

