import React from 'react';

import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { cloneDeep, map, includes, find, remove, compact, filter, forEach } from 'lodash';
import moment from 'moment';

import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';

import * as types from '../../redux/futures/order/types';
import orderActions from '../../redux/futures/order/actions';

import * as orderTagTypes from '../../redux/futures/order_tag/types';
import orderTagActions from '../../redux/futures/order_tag/actions';

import * as printTypes from '../../redux/futures/print/types';
import printActions from '../../redux/futures/print/actions';

import * as productType from '../../redux/futures/product/types';
import productActions from '../../redux/futures/product/actions';

import * as filterTypes from '../../redux/futures/filter/types';
import filterActions from '../../redux/futures/filter/actions';

import paymentActions from '../../redux/futures/payment/actions';

import storePageActions from '../../redux/futures/store_page/actions';
import storePageTypes from '../../redux/futures/store_page/types';

import LoadingScene from '../../components/LoadingScene';

import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';

import { appLocalStorage } from '../../localforage';
import queryString from 'query-string';

import ModalExportOrder from './components/ModalExportOrder';

import socket from '../../socket';

import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import SelectOption from '../../components/SelectOption';
import ConfirmModal from '../../components/ConfirmModal';
import ItemFilter from './components/ItemFilter';
import ModalCancel from './components/ModalCancel';
import ModalConfirmPayment from './OrderEdit/components/ModalConfirmPayment';
import FilterConditionOrder from './components/FilterConditionOrder';
import FilterConditionOrderv2 from './components/FilterCondition/FilterConditionOrderv2';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import LadiPagination from '../../components/LadiPagination';
import ListTags from '../../components/ListTags';

import produce from 'immer';
import { type } from 'i18next-sprintf-postprocessor';
import ModalSaveCondition from './components/FilterCondition/ModalSaveCondition';
import ModalColumeCondition from './components/FilterCondition/ModalColumeCondition';
import ModalRename from './components/FilterCondition/ModalRename';
import { OrdersTable } from './components/OrdersTable';
import Dropdown from '../../components/Dropdown';
class Order extends React.Component {
    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();

        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        // const location = useLocation();

        this.state = {
            listOrder: [],
            totalAmount: 0,
            dataConditions: {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            },
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
            activePage: 1,
            searchName: '',
            searchStatus: appConfig.ORDER_STATUS.OPEN.CODE,
            searchSpam: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
            searchPaymentStatus: [],
            searchPaymentMethod: [],
            searchPaymentType: [],
            searchShippingStatus: [],
            searchProductTypes: [],
            product_ids: [],
            searchUtm: {
                urlPage: '',
                source: '',
                medium: '',
                campaign: '',
                term: '',
                content: '',
                msgID: '',
                ref: '',
                ad_id: '',
                fb_id: '',
            },
            searchIP: '',
            searchAssigneeID: null,
            searchCreatorID: null,
            searchTag: [],
            // fromDate: null,
            // toDate: null,
            fromDate: moment(new Date()).subtract(30, 'd'),
            toDate: moment(new Date()),
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentOrderInfo: {},
            mode: '',
            userInfo: {},
            page_checkout_ids: [],
            isShowModalExportOrder: false,
            tabSelected: null,
            focusedInput: null,
            duplicationID: '',
            duplicationNo: '',
            selectedTags: [],
            isShowModalCancel: false,
            isShowModalConfirmPayment: false,
            payment_gateway_code: '',
            is_auto_shipping: false,
            payment_type: '',
            selectedIDs: [],
            conditions: defaultConditions,
            isShowFilterCondition: false,
            filterCustom: [],
            filterCustomShow: [],
            filterCustomDropdown: [],
            search_v2: {},
            isLoadingRemoveFilter: false,
            filterAction: {},
            isShowModalSaveCondition: false,
            isShowModalConfirmDeleteFilter: false,
            isShowModalColumnFilter: false,
            isShowModalFilterRename: false,
            columnSeleted: appConfig.ORDER_COLUMN.filter((item) => item.isSelected),
            // queryStrings: new URLSearchParams(location.search),
        };

        this.sortBy = {};

        this.selectedID = '';
        // this.selectedIDs = [];
        this.formEditRef = React.createRef();
        this.searchTagRef = React.createRef();
        this.orderTableRef = React.createRef();
        this.room = appConfig.SOCKET.ROOMS.ORDER;
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            const owner = find(result.staffs, (item) => item.ladi_uid == result.ladi_uid);
            if (!owner) return;
            owner.name = this.props.t('COMMON.ME');

            this.setState(
                {
                    userInfo: result,
                },
                () => {
                    this.subscribe();
                }
            );
        });
    }

    componentDidMount() {
        // this.list();
        const self = this;
        socket.getInstance().on(appConfig.SOCKET.EVENTS.ASSIGNED_ORDER, (data) => {
            self.wsAssignedOrder(data);
        });

        socket.getInstance().on(appConfig.SOCKET.EVENTS.NEW_ORDER, (data) => {
            self.wsAppendNewOrder(data);
        });

        const payloadListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        this.props.searchProduct();
        this.props.listStores(payloadListStore);
        this.props.listAllTags();
        this.props.filterList({ type: 'ORDER' });
    }

    componentWillUnmount() {
        socket.getInstance().emit(appConfig.SOCKET.EVENTS.LEAVE_ORDER, { room: this.room });
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.order.action != nextProps.order.action) {
            if (nextProps.order.action === types.SHOW_ORDER) {
                if (nextProps.order.status) {
                    this.setState({
                        mode: appConfig.FORM_MODE.EDIT,
                        currentOrderInfo: nextProps.order.order,
                    });
                }
            }

            if (nextProps.order.action === types.UPDATE_ORDER) {
                if (nextProps.order.status) {
                    this.props.show(this.state.currentOrderInfo.order_id);

                    this.list();

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == types.LIST_ORDER) {
                if (nextProps.order.status) {
                    this.setState({
                        listOrder: nextProps.order.orders,
                        totalAmount: nextProps.order.total,
                    });

                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == types.ASSIGN_ORDER) {
                if (nextProps.order.status) {
                    this.list();
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action == types.EXPORT_ORDER) {
                if (nextProps.order.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.UPDATE_SPAMS) {
                if (nextProps.order.status) {
                    this.list();

                    window.LadiUI.closeModal('confirm-mark-spam');
                    window.LadiUI.closeModal('confirm-remove-spam');

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.REMOVE_DUPLICATION_ORDER) {
                if (nextProps.order.status) {
                    this.list();

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.CANCEL_ORDERS) {
                if (nextProps.order.status) {
                    this.list();

                    this.setState({
                        isShowModalCancel: false,
                    });

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }

            if (nextProps.order.action === types.MARK_AS_PAIDS) {
                if (nextProps.order.status) {
                    this.list();

                    this.setState({
                        isShowModalConfirmPayment: false,
                    });

                    window.LadiUI.closeModal('confirm-last-payment');

                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.order.message, 'OK');
                }
            }
        }

        if (this.props.printReducer.action != nextProps.printReducer.action) {
            if (nextProps.printReducer.action == printTypes.PRINTS_ORDER) {
                if (nextProps.printReducer.status) {
                    baseHelper.print(nextProps.printReducer.source);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.printReducer.message, 'OK');
                }
            }
            if (nextProps.printReducer.action == printTypes.PRINT_ORDER) {
                if (nextProps.printReducer.status) {
                    baseHelper.print(nextProps.printReducer.source);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.printReducer.message, 'OK');
                }
            }
        }

        if (this.props.filterReducer.action != nextProps.filterReducer.action) {
            if (nextProps.filterReducer.action == filterTypes.FILTER_CREATE) {
                if (nextProps.filterReducer.status) {
                    this.props.filterList({ type: 'ORDER' });
                    this.setState({
                        isShowModalSaveCondition: false,
                    });
                } else {
                    this.setState({
                        filterCustom: [],
                        filterCustomShow: [],
                        filterCustomDropdown: [],
                    });
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_LIST) {
                if (nextProps.filterReducer.status) {
                    let filters = [...nextProps.filterReducer.filters];

                    let filterCustomShow = filters.splice(0, 2);
                    this.setState(
                        {
                            filterCustom: nextProps.filterReducer.filters,
                            filterCustomShow,
                            filterCustomDropdown: filters,
                        },
                        () => {
                            if (!this.state.tabSelected) {
                                let queryStrings = new URLSearchParams(this.props.location.search);
                                let orderTab = queryStrings.get('order_tab');
                                if (!orderTab) orderTab = appConfig.ORDER_FILTER_TYPE.ALL;
                                this.onChangeTab(orderTab);
                            }
                        }
                    );
                } else {
                    this.setState({
                        filterCustom: [],
                        filterCustomShow: [],
                        filterCustomDropdown: [],
                    });
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_DELETE) {
                if (nextProps.filterReducer.status) {
                    window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                    this.props.filterList({ type: 'ORDER' });
                    this.setState({
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_UPDATE) {
                if (nextProps.filterReducer.status) {
                    // window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.order.message);
                    this.props.filterList({ type: 'ORDER' });
                    this.setState({
                        isShowModalColumnFilter: false,
                        isShowModalSaveCondition: false,
                        isShowModalFilterRename: false,
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }

            if (nextProps.filterReducer.action == filterTypes.FILTER_DUPLICATE) {
                if (nextProps.filterReducer.status) {
                    // window.LadiUI.closeModal('confirm-remove-filter');
                    window.LadiUI.toastCustom('success', '', nextProps.filterReducer.message);

                    this.props.filterList({ type: 'ORDER' });

                    this.setState({
                        isShowModalColumnFilter: false,
                        isShowModalSaveCondition: false,
                        filterActions: {},
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.filterReducer.message, 'OK');
                }
            }
        }

        if (this.props.location.search != nextProps.location.search) {
            let queryStrings = new URLSearchParams(nextProps.location.search);
            let orderTab = queryStrings.get('order_tab');

            if (!orderTab) orderTab = appConfig.ORDER_FILTER_TYPE.ALL;
            // this.onChangeTab(orderTab);
            // do something

            let tags = queryStrings.get('tags');
            if (!tags) tags = [];
            else tags = tags.split('-');

            this.setState(
                {
                    searchTag: tags,
                },
                () => {
                    this.onChangeTab(orderTab);
                }
            );
        }
    }

    subscribe() {
        const { tabSelected } = this.state;
        switch (tabSelected) {
            case appConfig.ORDER_FILTER_TYPE.DRAFT:
            case appConfig.ORDER_FILTER_TYPE.DUPLICATION:
            case appConfig.ORDER_FILTER_TYPE.SEARCH_ALL:
            case appConfig.ORDER_FILTER_TYPE.ALL:
                socket.getInstance().emit(appConfig.SOCKET.EVENTS.JOIN_ORDER, { room: this.room });
                break;
            case appConfig.ORDER_FILTER_TYPE.SPAM:
            case appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING:
            case appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING:
                socket.getInstance().emit(appConfig.SOCKET.EVENTS.LEAVE_ORDER, { room: this.room });
                break;
        }
    }

    wsAppendNewOrder = (newOrder) => {
        const { role, ladi_uid: ladiUID } = this.state.userInfo;

        const validOrder =
            includes([appConfig.ROLE.OWNER.CODE, appConfig.ROLE.MANAGER.CODE], role) ||
            !newOrder.assignee_id ||
            newOrder.assignee_id == ladiUID ||
            newOrder.creator_id == ladiUID;
        if (!validOrder) {
            return;
        }
        const { selectedLimit } = this.state;

        // Kiểm tra xem order đã tồn tại trong danh sách hiện tại hay chưa
        let orders = cloneDeep(this.props.order.orders || []);
        let matched = find(orders, (item) => item.order_id == newOrder.order_id);

        if (matched) {
            return false;
        }

        // Kiểm tra xem order có bị trùng không?
        // Nếu không tìm thấy thì bye bye
        if (newOrder.parent_id) {
            matched = find(orders, (item) => item.order_id == newOrder.parent_id);

            if (!matched) {
                return;
            }
        }

        let totalRecord = cloneDeep(this.props.order.totalRecord);
        if (matched) {
            matched.total_child = baseHelper.parseInt(matched.total_child) + 1;
        } else {
            totalRecord++;
            orders.unshift(newOrder);
            orders = orders.slice(0, selectedLimit);
        }

        this.props.wsAppendNewOrder(orders, totalRecord);
    };

    wsAssignedOrder = (data) => {
        let orders = cloneDeep(this.props.order.orders);
        const matched = find(orders, (item) => item.order_id == data.order_id);

        if (!matched) {
            return;
        }

        matched.assignee_id = data.assignee_id;
        this.props.wsAssignedOrder(orders);
    };

    parseSearch = (conditions) => {
        let search = {};

        for (const condition of conditions) {
            switch (condition.value) {
                case 'status':
                case 'creator':
                case 'staff':
                case 'spam':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.fieldSelected.value,
                    };
                    break;
                case 'payment_status':
                case 'payment_shipping_status':
                case 'payment_method':
                case 'product_ids':
                case 'product_type':
                case 'tags':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.multiSelected.map((item) => item.value),
                    };
                    break;
                case 'utm_medium':
                case 'ip':

                case 'utm_page':
                case 'utm_source':
                case 'utm_campaign':
                case 'utm_term':
                case 'utm_content':
                case 'msg_id':
                case 'ref':
                case 'ad_id':
                case 'fb_id':
                    search[condition.value] = {
                        operator: condition.operator.CODE,
                        value: condition.valueFilter,
                    };
                    break;
                // case 'page_checkout_ids':
                //     filter.page_checkout_ids = [condition.valueFilter.value];
                //     break;
                default:
                    break;
            }
        }

        return search;
    };

    /**
     * list order
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const {
            searchName,
            selectedLimit,
            fromDate,
            toDate,
            searchSpam,
            conditions,
            conditionProp,
            searchStatus,
            searchPaymentStatus,
            searchPaymentMethod,
            searchPaymentType,
            searchShippingStatus,
            searchProductTypes,
            product_ids,
            searchUtm,
            searchTag,
            tabSelected,
            searchAssigneeID,
            searchCreatorID,
            searchIP,
            duplicationID,
            page_checkout_ids,
            search_v2,
        } = this.state;

        let activePage = conditions.page;

        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;
            this.setState({
                conditions,
            });
        }

        // Reset selectedIDs
        // this.selectedIDs = [];
        this.setState({
            selectedIDs: [],
        });

        // let tagIDs = this.searchTagRef.current.getIDs();
        // tagIDs = compact(tagIDs);

        let arrayPaymentType = [];
        for (let item of searchPaymentType) {
            arrayPaymentType = [...arrayPaymentType, ...JSON.parse(item)];
        }

        // let search = this.parseSearch();

        let queryStrings = new URLSearchParams(this.props.location.search);

        let tags = queryStrings.get('tags');
        if (!tags) tags = [];
        else tags = tags.split('-');

        const data = {
            search_v2,
            search: {
                mark_spam: searchSpam,
                name: searchName,
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                tag_ids: tags,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        data.type = tabSelected;

        if (!Object.values(appConfig.ORDER_FILTER_TYPE).includes(tabSelected)) {
            data.type = appConfig.ORDER_FILTER_TYPE.SEARCH_ALL;
        }

        if (tabSelected == appConfig.ORDER_FILTER_TYPE.DUPLICATION) {
            data.parent_id = duplicationID;
        }

        const { customer_id: customerID } = queryString.parse(this.props.location.search);

        if (customerID) {
            data.search_v2.customer_id = customerID;
        }

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsTg = [];
        if (checked) {
            selectedIDsTg = map(this.props.order.orders, (order) => order.order_id);
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs: selectedIDsTg,
        });
    };

    checkItem = (event, orderID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, orderID)) {
                selectedIDs.push(orderID);
            }

            if (selectedIDs.length == this.props.order.orders.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            let elm = document.getElementById('checkAllItem');
            if (elm) elm.checked = false;
            remove(selectedIDs, (selectedOrderID) => selectedOrderID == orderID);
        }
        this.setState({
            selectedIDs,
        });
    };

    deletes = () => {
        const { selectedIDs } = this.state;

        if (selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        this.props.deletes(selectedIDs);
    };

    deleteItem = (orderID) => {
        this.props.delete(orderID);
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({}, () => this.list());
    };

    getPaymentStatus = (status) => {
        const matched = find(appConfig.PAYMENT_STATUS, (item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    getShippingStatus = (status) => {
        const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == status);
        if (matched) {
            return matched.NAME;
        }
    };

    getPaymentMethod = (method) => {
        const matched = find(appConfig.PAYMENT_PARTNERS, (item) => item.CODE == method);
        if (matched) {
            return matched.NAME;
        }
    };

    onChangeTab = (tabID, event) => {
        if (event) {
            event.stopPropagation();
        }
        this.clearFilter(() => {
            let search_v2 = {};

            let orderTabDefault = Object.values(appConfig.ORDER_FILTER_TYPE);
            let queryStrings = new URLSearchParams(this.props.location.search);
            queryStrings.set('order_tab', tabID);

            let columnSeleted = appConfig.ORDER_COLUMN.filter((item) => item.isSelected);
            // this.props.history;
            this.props.history.replace({ search: queryStrings.toString() });
            switch (tabID) {
                case appConfig.ORDER_FILTER_TYPE.DRAFT:
                    search_v2 = {
                        status: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_STATUS.OPEN.CODE,
                        },
                        spam: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
                        },
                    };
                    break;
                case appConfig.ORDER_FILTER_TYPE.PAYMENT_PENDING:
                    search_v2 = {
                        status: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_STATUS.OPEN.CODE,
                        },
                        spam: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
                        },
                        payment_status: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: [appConfig.PAYMENT_STATUS.PENDING.CODE],
                        },
                    };
                    break;
                case appConfig.ORDER_FILTER_TYPE.SHIPPING_PENDING:
                    search_v2 = {
                        status: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_STATUS.OPEN.CODE,
                        },
                        spam: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_SPAM.NOT_SPAM.CODE,
                        },
                        payment_shipping_status: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: [appConfig.SHIPPING_STATUS.PENDING.CODE],
                        },
                    };
                    break;
                case appConfig.ORDER_FILTER_TYPE.SPAM:
                    search_v2 = {
                        spam: {
                            operator: appConfig.OPERATORS.EQUAL.CODE,
                            value: appConfig.ORDER_SPAM.SPAM.CODE,
                        },
                    };
                    break;
                case appConfig.ORDER_FILTER_TYPE.ALL:
                    break;
            }
            let dataConditions = {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            };
            let isShowFilterCondition = false;

            if (!orderTabDefault.includes(tabID)) {
                let filter = this.state.filterCustomShow.find((item) => item.filter_id == tabID);

                if (filter) {
                    let condition = [];
                    if (filter.config && filter.config.dataConditions) {
                        condition = filter.config.dataConditions.conditions || [];
                        isShowFilterCondition = true;
                    }
                    if (filter.config && !baseHelper.isEmpty(filter.config.columns)) {
                        columnSeleted = filter.config.columns;
                    }
                    dataConditions.conditions = condition;
                    search_v2 = this.parseSearch(condition);
                }
            }

            this.setState(
                {
                    dataConditions,
                    search_v2,
                    tabSelected: tabID,
                    isShowFilterCondition,
                    columnSeleted,
                    // searchStatus,
                    // searchSpam,
                    // searchPaymentStatus,
                    // searchPaymentMethod,
                    // searchPaymentType,
                    // searchShippingStatus,
                },
                () => {
                    this.subscribe();
                    this.list();
                }
            );
        });
    };

    clearFilter = (callback) => {
        this.setState(
            {
                duplicationID: '',
                duplicationNo: '',
                // searchName: "",
                searchStatus: null,
                searchSpam: null,
                // searchTag: {},
                // fromDate: null,
                // toDate: null,
                selectedTags: [],
                searchStatus: null,
                searchAssigneeID: '',
                searchCreatorID: '',
                searchPaymentStatus: [],
                searchShippingStatus: [],
                searchProductTypes: [],
                product_ids: [],
                searchPaymentMethod: [],
                searchIP: '',
                searchUtm: {
                    urlPage: '',
                    source: '',
                    medium: '',
                    campaign: '',
                    term: '',
                    content: '',
                    ref: '',
                    ad_id: '',
                    fb_id: '',
                },
                page_checkout_ids: [],
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    /******************************CALLBACK MODAL*********************/
    exportOrder = (type) => {
        let ids;
        // const { selectedIDs } = this.state;

        const selectedIDs = this.orderTableRef.current.getSelectedIDs();

        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_ORDER.ALL_ORDER:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_ORDER.ON_PAGE:
                const onPageIDs = map(this.props.order.orders, (item) => item.order_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_ORDER.SELECTED_ORDER:
                if (baseHelper.isEmpty(selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = selectedIDs;
                break;
        }

        const { search_v2, searchName, fromDate, toDate, searchSpam, searchPaymentType, tabSelected } = this.state;

        let arrayPaymentType = [];
        for (let item of searchPaymentType) {
            arrayPaymentType = [...arrayPaymentType, ...JSON.parse(item)];
        }

        let search = {
            mark_spam: searchSpam,
            name: searchName,
            from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
        };

        this.props.exportOrder({ ids, search_v2, search, type: tabSelected });
    };

    exportTransaction = (selected) => {
        let ids = [];

        if (selected == appConfig.EXPORT_ORDER.ALL_ORDER) {
            ids = ['all'];
        } else {
            ids = selected;
        }

        this.props.exportTransaction(ids);
    };

    /******************************************CANCEL********************************/

    openModalConfirmPayment = async () => {
        let orders = this.props.order.orders.filter((item) => this.state.selectedIDs.includes(item.order_id));
        let isOrder = false;

        for await (let item of orders) {
            if (
                item.is_draft === 1 ||
                item.payment_status !== appConfig.PAYMENT_STATUS.PENDING.CODE ||
                item.status !== appConfig.ORDER_STATUS.OPEN.CODE
            ) {
                isOrder = true;
                break;
            }
        }

        if (isOrder) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.CHOOSE_UNPAID_ORDERS'));
            return;
        }

        this.setState({
            isShowModalConfirmPayment: true,
        });
    };

    hideModalConfirmPayment = () => {
        this.setState({
            isShowModalConfirmPayment: false,
        });
    };

    openModalSaveCondition = () => {
        this.setState({
            isShowModalSaveCondition: true,
        });
    };

    setDataCondition = async (data) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        let index = conditions.findIndex((item) => item.value == data.value);
        if (index > -1) {
            conditions[index] = data;
        } else conditions.push(data);

        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
                tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
            },
            () => {
                this.list();
            }
        );
    };

    removeCondition = async (index) => {
        let dataConditions = this.state.dataConditions;
        let conditions = dataConditions.conditions || [];

        if (index > -1) conditions.splice(index, 1);
        dataConditions.conditions = conditions;

        let search = this.parseSearch(conditions);

        this.setState(
            {
                search_v2: search,
                dataConditions: dataConditions,
            },
            () => {
                this.list();
            }
        );
    };

    hideModalSaveCondition = () => {
        this.setState({
            isShowModalSaveCondition: false,
        });
    };

    hideModalCancel = () => {
        this.setState({
            isShowModalCancel: false,
        });
    };

    cancelOrders = (reason) => {
        this.props.cancelOrders(this.state.selectedIDs, reason);
    };

    confirmPayments = async (payment_gateway_code, payment_type, is_auto_shipping) => {
        await this.setState({
            payment_gateway_code,
            payment_type,
            is_auto_shipping,
            isShowModalConfirmPayment: false,
        });

        window.LadiUI.showModal('confirm-last-payment');
    };

    confirmLastPayment = () => {
        let data = {
            order_ids: this.state.selectedIDs,
            payment_gateway_code: this.state.payment_gateway_code,
            is_auto_shipping: this.state.is_auto_shipping,
            payment_type: this.state.payment_type,
        };

        this.props.markAsPaids(data);
    };
    /********************************************************************************/

    setDuplication = (orderID, referenceNo) => {
        this.setState(
            {
                duplicationID: orderID,
                duplicationNo: `#${referenceNo}`,
                tabSelected: appConfig.ORDER_FILTER_TYPE.DUPLICATION,
            },
            () => {
                document.getElementById('triggerTabDuplication').click();
                this.list(true);
            }
        );
    };

    eventSelectAction = (action) => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        switch (action) {
            case 'REMOVE_MARK_SPAM':
                window.LadiUI.showModal('confirm-remove-spam');
                break;
            case 'MARK_SPAM':
                window.LadiUI.showModal('confirm-mark-spam');
                break;
            case 'CANCEL_ORDER':
                this.setState({
                    isShowModalCancel: true,
                });
                break;
            case 'CONFIRM_PAYMENT':
                this.openModalConfirmPayment();
                break;
            case 'PRINT':
                this.props.prints(this.state.selectedIDs);
                break;
            default:
        }
    };

    eventSelectActionFilter = (action, data) => {
        switch (action) {
            case 'CHANGE_NAME':
                this.setState({
                    filterAction: data,
                    isShowModalFilterRename: true,
                });
                break;
            case 'CHANGE_COLUMN':
                this.setState({
                    filterAction: data,
                    isShowModalColumnFilter: true,
                });
                break;
            case 'DUPLICATE':
                this.props.filterDuplicate({ filter_id: data.filter_id });
                break;
            case 'DELETE':
                this.setState({
                    filterAction: data,
                });
                window.LadiUI.showModal('confirm-remove-filter');
                break;
            default:
                break;
        }
    };

    handleClickTag = (item, selectedTags) => {
        this.setState(
            {
                searchTag: {
                    ...this.state.searchTag,
                    order_tag_id: item,
                },
                selectedTags,
            },
            () => {
                this.list();
            }
        );
    };

    showFilterAction = (index) => {
        let filterCustom = this.state.filterCustom;

        filterCustom = filterCustom.map((item, _index) => {
            if (_index != index) {
                item.show = false;
            } else {
                if (!item.show) item.show = true;
                else item.show = false;
            }
            return item;
        });

        this.setState({
            filterCustom: filterCustom,
        });
    };

    selectFilterDropdown = (data) => {
        let { filterCustomShow, filterCustomDropdown } = this.state;

        let index = filterCustomDropdown.findIndex((item) => item.filter_id == data.filter_id);

        if (index == -1) return;

        let filter = filterCustomDropdown.splice(index, 1);

        let filter2 = filterCustomShow[1];
        filterCustomShow[1] = filter[0];
        filterCustomDropdown.push(filter2);
        this.setState(
            {
                filterCustomShow,
                filterCustomDropdown,
            },
            () => {
                this.onChangeTab(filterCustomShow[1].filter_id);
            }
        );
    };

    /****************************************************************/
    render() {
        const { t } = this.props;
        const { totalRecord, totalDuplication } = this.props.order;
        const {
            tabSelected,
            conditions,
            searchName,
            userInfo,
            duplicationNo,
            conditionProp,
            dataConditions,
            listOrder,
            isShowFilterCondition,
            filterCustomShow,
            filterCustomDropdown,
            isLoadingRemoveFilter,
            totalAmount,
        } = this.state;

        let fromItem = 0;
        let toItem = 0;
        if ((this.props.order.orders || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.order.orders.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t('PAGINATION.OF')}
     ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')} ${t('ORDERS.INCLUDE_DUPLICATION', totalDuplication)}`;
        const isLoadingData = this.props.order.loading && this.props.order.waiting == types.LIST_ORDER;
        const isLoadingExportOrder = this.props.order.loading && includes([types.EXPORT_ORDER], this.props.order.waiting);
        const isLoadingExportTransaction = this.props.order.loading && includes([types.EXPORT_TRANSACTION], this.props.order.waiting);
        const isLoadingAssign = this.props.order.loading && types.ASSIGN_ORDER == this.props.order.waiting;
        const isLoadingUpdateSpam = this.props.order.loading && types.UPDATE_SPAMS == this.props.order.waiting;
        const isLoadingRemoveDuplication = this.props.order.loading && types.REMOVE_DUPLICATION_ORDER == this.props.order.waiting;
        const isLoadingCancels = this.props.order.loading && types.CANCEL_ORDERS == this.props.order.waiting;
        const isLoadingPrint = this.props.printReducer.loading && this.props.printReducer.waiting == printTypes.PRINTS_ORDER;
        const isLoadingModalConfirmPayment = this.props.order.loading && types.MARK_AS_PAIDS == this.props.order.waiting;

        const allTags = this.props.orderTagReducer.allTags || [];
        const allStorePages = this.props.storePageReducer.store_pages || [];
        const productLists = this.props.productReducer.products || [];
        const staffs = userInfo.staffs;
        const listAction = [
            { value: 'PRINT', name: t('COMMON.PRINT') },
            { value: 'REMOVE_MARK_SPAM', name: t('ORDERS.REMOVE_MARK_SPAM') },
            { value: 'MARK_SPAM', name: t('ORDERS.MARK_SPAM') },
            { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
            { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
        ];

        return (
            <div className='page-content'>
                {(isLoadingAssign || isLoadingUpdateSpam || isLoadingRemoveDuplication || isLoadingPrint) && <LoadingScene blur={true} />}
                <div className='header-page'>
                    <div className='heading'>
                        <h1>{t('ORDERS.TITLE')}</h1>
                        <p>{t('ORDERS.SUB_TITLE')}</p>
                    </div>
                    <div className='button-action'>
                        <button
                            className='btn-export-order'
                            onClick={() =>
                                this.setState({
                                    isShowModalExportOrder: true,
                                })
                            }
                        >
                            {t('ORDERS.BTN_EXPORT_ORDER')}
                        </button>
                        <button type='button' className='btn-order-create' onClick={() => this.props.history.push('/orders/create')}>
                            <i className='icon-ldp-add' />
                            <div className='ladiui btn-custom-text '>{t('ORDERS.ADD_ORDER')}</div>
                        </button>
                    </div>
                </div>
                <div className='block-filter'>
                    <div className='block-search'>
                        <div className='ladiui search-group'>
                            <i className='ladiui icon icon-search' onClick={this.list}></i>
                            <input
                                id='keyword_search'
                                className='ladiui search-field dropdown-toggle form-control search-width'
                                name='searchName'
                                placeholder={t('ORDERS.SEARCH_ORDER')}
                                aria-expanded='false'
                                value={searchName}
                                onChange={this.onChangeInput}
                                onKeyDown={this.onKeyDownInput}
                            />
                        </div>
                        <div className='block-filter-button'>
                            <div className='date-filter'>
                                <OrderDateRangePicker
                                    startDateId='fromDate'
                                    endDateId='toDate'
                                    startDate={this.state.fromDate}
                                    endDate={this.state.toDate}
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState(
                                            {
                                                fromDate: baseHelper.getFromDate(startDate),
                                                toDate: baseHelper.getToDate(endDate),
                                            },
                                            () => {
                                                if ((startDate && endDate) || (!startDate && !endDate)) {
                                                    this.list();
                                                }
                                            }
                                        );
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput) => {
                                        this.setState({ focusedInput });
                                    }}
                                    showDefaultInputIcon={true}
                                    inputIconPosition='after'
                                    small={true}
                                    hideKeyboardShortcutsPanel={true}
                                    customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                    startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                    endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                    isOutsideRange={() => false}
                                    isDayHighlighted={(date) => {
                                        return this.moment().isSame(date, 'day');
                                    }}
                                    minimumNights={0}
                                    showClearDates={true}
                                    anchorDirection={'right'}
                                />
                            </div>
                            <button
                                type='button'
                                className='btn-advanced-filter ladiui btn btn-md'
                                onClick={() => this.setState({ isShowFilterCondition: !isShowFilterCondition })}
                            >
                                <div className='ladiui'>{t('COMMON.MORE_FILTER')}</div>
                                <i className='ldicon-sort' />
                            </button>
                        </div>
                    </div>
                    {isShowFilterCondition && (
                        <div className='block-condition mt-12'>
                            <FilterConditionOrderv2
                                openModalSaveCondition={this.openModalSaveCondition}
                                setDataCondition={this.setDataCondition}
                                removeCondition={this.removeCondition}
                                conditionProp={conditionProp}
                                conditionFields={appConfig.ORRDER_CONDITION_FILEDS}
                                dataConditions={dataConditions}
                                staffs={staffs}
                                products={productLists}
                                // allTags={allTags}
                                allStorePages={allStorePages}
                            />
                        </div>
                    )}
                </div>

                <div className='block-content'>
                    <div className='order-tab'>
                        <ul className='ladiui nav nav-tabs overflow-hide' role='tab-order-all'>
                            {appConfig.ORDER_FILTER_DEFAULT.map((item, index) => {
                                return (
                                    <li key={index} className='ladiui nav-item' id='tabAll' role='tab-order-all-item'>
                                        <span
                                            className={`ladiui tab-link ${tabSelected === item.type ? 'active' : ''}`}
                                            data-toggle='tab'
                                            data-tab='tabAll'
                                            onClick={() => this.onChangeTab(item.type)}
                                        >
                                            {item.name}
                                        </span>
                                    </li>
                                );
                            })}
                            <li
                                className='ladiui nav-item'
                                id='tabDuplication'
                                role='tab-order-all-item'
                                style={
                                    this.state.tabSelected == appConfig.ORDER_FILTER_TYPE.DUPLICATION
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.DUPLICATION ? 'active' : ''}`}
                                    data-toggle='tab'
                                    id='triggerTabDuplication'
                                    data-tab='tabDuplication'
                                >
                                    {t('ORDERS.TAB_SEARCH_DUPLICATION', duplicationNo)}
                                </span>
                            </li>

                            {filterCustomShow.map((item, index) => {
                                return (
                                    <li
                                        className='ladiui nav-item item-filter-new'
                                        id={item.filter_id}
                                        role='tab-order-all-item'
                                        key={index}
                                    >
                                        <span
                                            className={`ladiui tab-link ${tabSelected == item.filter_id ? 'active' : ''}`}
                                            data-toggle='tab'
                                            data-tab={`${item.filter_id}`}
                                            onClick={(event) => this.onChangeTab(item.filter_id, event, item)}
                                        >
                                            {item.name}
                                        </span>

                                        <div className='ladiui btn-group'>
                                            <div className='ladiui dropdown'>
                                                <button
                                                    className={`ladiui btn btn-outline-light dropdown-toggle`}
                                                    data-toggle='dropdown'
                                                ></button>
                                                <ul className={`ladiui dropdown-menu filter-actions`}>
                                                    <li
                                                        onClick={() => {
                                                            this.eventSelectActionFilter('CHANGE_NAME', item);
                                                        }}
                                                    >
                                                        <a className='ladiui dropdown-item'>
                                                            <i className='ldicon-info' />
                                                            <span>{t('ACTIONS.CHANGE_NAME')}</span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.eventSelectActionFilter('CHANGE_COLUMN', item);
                                                        }}
                                                    >
                                                        <a className='ladiui dropdown-item'>
                                                            <i className='ldicon-info' />
                                                            <span>{t('ACTIONS.CHANGE_COLUME')}</span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.eventSelectActionFilter('DUPLICATE', item);
                                                        }}
                                                    >
                                                        <a className='ladiui dropdown-item'>
                                                            <i className='ldicon-info' />
                                                            <span>{t('ACTIONS.DUPLICATE')}</span>
                                                        </a>
                                                    </li>
                                                    <li
                                                        onClick={() => {
                                                            this.eventSelectActionFilter('DELETE', item);
                                                        }}
                                                    >
                                                        <a className='ladiui dropdown-item'>
                                                            <i className='ldicon-info' />
                                                            <span>{t('ACTIONS.DELETE')}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                            {filterCustomDropdown && filterCustomDropdown.length > 0 && (
                                <Dropdown
                                    data={filterCustomDropdown}
                                    onSelectItem={(item) => {
                                        this.selectFilterDropdown(item);
                                    }}
                                    _key='filter_id'
                                    _value='name'
                                    placeHolder='Chế độ xem khác'
                                    wrapperClassName='dropdown-filter-more'
                                    innerClassName='dropdown-filter-more'
                                />
                            )}

                            <li
                                className='ladiui nav-item'
                                id='tabSearch'
                                role='tab-order-all-item'
                                style={
                                    this.state.tabSelected == appConfig.ORDER_FILTER_TYPE.SEARCH_ALL
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <span
                                    className={`ladiui tab-link ${tabSelected === appConfig.ORDER_FILTER_TYPE.SEARCH_ALL ? 'active' : ''}`}
                                    data-toggle='tab'
                                    id='triggerTabSearch'
                                    data-tab='tabSearch'
                                >
                                    {t('ORDERS.TAB_SEARCH')}
                                </span>
                            </li>
                        </ul>
                    </div>

                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card ladiui-table-responsive'>
                                <OrdersTable
                                    ref={this.orderTableRef}
                                    columnSeleted={this.state.columnSeleted}
                                    _listOrder={listOrder}
                                    openModalConfirmPayment={this.openModalConfirmPayment}
                                    setDuplication={this.setDuplication}
                                    handleClickTag={this.handleClickTag}
                                    totalRecord={totalRecord}
                                    totalAmount={totalAmount}
                                    listOrderNow={this.list}
                                />
                            </div>
                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('CUSTOM_FIELD.ORDER')}
                                    message={msgShowItem}
                                    items={this.props.order.orders}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            )}
                        </div>
                    )}
                </div>

                {this.state.isShowModalExportOrder && (
                    <ModalExportOrder
                        visible={this.state.isShowModalExportOrder}
                        isLoadingExportOrder={isLoadingExportOrder}
                        isLoadingExportTransaction={isLoadingExportTransaction}
                        onExportOrder={this.exportOrder}
                        onExportTransaction={this.exportTransaction}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportOrder: false,
                            });
                        }}
                    />
                )}

                <ConfirmModal
                    id='confirm-last-payment'
                    title={t('ORDERS.CONFIRM_PAYMENT')}
                    content={t('ORDERS.MSG_CONFIRM_LAST_PAYMENT', this.state.payment_gateway_code)}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.OK')}
                    onOk={this.confirmLastPayment}
                    isBtnPrimary={true}
                    isLoading={isLoadingModalConfirmPayment}
                />

                {this.state.isShowModalCancel && (
                    <ModalCancel
                        visible={this.state.isShowModalCancel}
                        onOk={this.cancelOrders}
                        onCancel={this.hideModalCancel}
                        isLoading={isLoadingCancels}
                    />
                )}

                {this.state.isShowModalConfirmPayment && (
                    <ModalConfirmPayment
                        visible={this.state.isShowModalConfirmPayment}
                        onCancel={this.hideModalConfirmPayment}
                        onOk={this.confirmPayments}
                        isLoading={isLoadingModalConfirmPayment}
                        currentStoreID={userInfo.currentStore.store_id}
                    />
                )}
                {this.state.isShowModalSaveCondition && (
                    <ModalSaveCondition
                        visible={this.state.isShowModalSaveCondition}
                        hideModalSaveCondition={this.hideModalSaveCondition}
                        dataConditions={dataConditions}
                        filters={this.state.filterCustom}
                    />
                )}
                {this.state.isShowModalColumnFilter && (
                    <ModalColumeCondition
                        visible={this.state.isShowModalColumnFilter}
                        hideModal={() => {
                            this.setState({
                                isShowModalColumnFilter: false,
                            });
                        }}
                        filterSelected={this.state.filterAction}
                    />
                )}

                {this.state.isShowModalFilterRename && (
                    <ModalRename
                        visible={this.state.isShowModalFilterRename}
                        hideModal={() => {
                            this.setState({
                                isShowModalFilterRename: false,
                            });
                        }}
                        dataConditions={this.state.filterAction}
                    />
                )}

                <ConfirmModal
                    id='confirm-remove-filter'
                    title={t('CONDITIONS_FILTER.DELETE_TITLE')}
                    content={t('CONDITIONS_FILTER.DELETE_CONTENT')}
                    cancelText={t('ACTIONS.REMOVE')}
                    okText={t('ACTIONS.OK')}
                    onOk={() => {
                        this.props.filterDelete({
                            filter_id: this.state.filterAction.filter_id,
                        });
                    }}
                    isLoading={isLoadingRemoveFilter}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(orderActions.list(data)),
        listOrder: (data) => dispatch(orderActions.listOrder(data)),
        cancel: (orderID) => dispatch(orderActions.cancel(orderID)),
        show: (orderID) => dispatch(orderActions.show(orderID)),
        markAsPaids: (data) => dispatch(orderActions.markAsPaids(data)),
        cancelOrders: (orderIDs, reason) => dispatch(orderActions.cancels(orderIDs, reason)),
        wsAppendNewOrder: (orders, totalRecord) => dispatch(orderActions.appendNewOrder(orders, totalRecord)),
        assignOrder: (orderID) => dispatch(orderActions.assign(orderID)),
        removeDuplication: (orderID) => dispatch(orderActions.removeDuplication(orderID)),
        exportOrder: (data) => dispatch(orderActions.exportOrder(data)),
        exportTransaction: (data) => dispatch(orderActions.exportTransaction(data)),
        wsAssignedOrder: (orders) => dispatch(orderActions.assignedOrder(orders)),
        updateSpams: (data) => dispatch(orderActions.updateSpams(data)),
        listAllTags: () => dispatch(orderTagActions.listAll()),
        prints: (orderIDs) => dispatch(printActions.printsOrder(orderIDs)),
        listStores: (data) => dispatch(storePageActions.storePageList(data)),
        searchProduct: (data) => dispatch(productActions.search(data)),
        filterList: (data) => dispatch(filterActions.list(data)),
        filterDelete: (data) => dispatch(filterActions.deletes(data)),
        filterDuplicate: (data) => dispatch(filterActions.duplicate(data)),
    };
};

const mapStateToProps = (state) => ({
    order: { ...state.order },
    orderTagReducer: { ...state.orderTag },
    printReducer: { ...state.print },
    storeReducer: { ...state.store },
    storePageReducer: { ...state.storePage },
    productReducer: { ...state.product },
    filterReducer: { ...state.filterReducer },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Order));
