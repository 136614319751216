import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';
import SelectOption from '../../../components/SelectOption';
import LoadingTable from '../../../components/LoadingTable';

import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
import PieChart from "highcharts-react-official";

import reportActions from "../../../redux/futures/report/actions";
import * as reportTypes from "../../../redux/futures/report/types";

export default function ReportByOrder(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const { num_orders_day, num_orders_day_compare, fromDate, toDate, fromDateCompare, toDateCompare, isCompare } = props;

    const reportReducer = useSelector((state) => state.report);
    const storeReducer = useSelector((state) => state.store);

    const [isLoading, setIsLoading] = useState(false);


    const getOption = () => {
        if (!num_orders_day || num_orders_day.length === 0 || !fromDate || !toDate) return [];

        let categories = [];

        let dataSuccess = []
        let dataSuccessCompare = [];
        let dataPending = []
        let dataCancel = []

        for (
            let datex = moment(fromDate).format("YYYY/MM/DD");
            datex <= moment(toDate).format("YYYY/MM/DD");
            datex = moment(datex, 'YYYY/MM/DD').add(1, "days").format("YYYY/MM/DD")
        ) {
            let xxxxx = moment(datex).format("DD/MM/YYYY");
            let xxxxx2 = moment(datex);
            categories.push(xxxxx);

            // let datax = dataRevenueTotal[idDate];


            let itemSuccess = num_orders_day.orderSuccess.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
            if (itemSuccess) dataSuccess.push(itemSuccess.total_success); else dataSuccess.push(0);

            // let itemPending = num_orders_day.orderPending.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)

            // if (itemPending) dataPending.push(itemPending.total_pending); else dataPending.push(0);

            // let itemCancel = num_orders_day.orderCancel.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)

            // if (itemCancel) dataCancel.push(itemCancel.total_canceled); else dataCancel.push(0);


            let _days = moment(new Date(toDate)).diff(moment(new Date(fromDate)), 'days');

            if (num_orders_day_compare && num_orders_day_compare.orderSuccess) {
                let itemOrderCompare = num_orders_day_compare.orderSuccess.find((item) => {

                    if (isCompare && isCompare == 1) {
                        xxxxx2.subtract(_days + 1, "days");
                    } else if (isCompare && isCompare == 2) {
                        xxxxx2.subtract(1, "month");
                    } else if (isCompare && isCompare == 3) {
                        xxxxx2.subtract(1, "year");
                    }

                    if (moment(item.date).format("DD/MM/YYYY") === xxxxx2.format("DD/MM/YYYY")) {
                        return item
                    }
                })

                if (itemOrderCompare) dataSuccessCompare.push(itemOrderCompare);
            }

        }


        let _series = [];

        if (fromDate && toDate) {
            _series.push(
                {
                    name: moment(fromDate).format("DD/MM/YYYY") + "-" + moment(toDate).format("DD/MM/YYYY"),
                    data: dataSuccess,
                },
            )
        }

        if (fromDateCompare && toDateCompare) {
            _series.push(
                {
                    name: moment(fromDateCompare).format("DD/MM/YYYY") + "-" + moment(toDateCompare).format("DD/MM/YYYY"),
                    data: dataSuccessCompare,
                },
            )
        }

        const options = {
            chart: {
                type: "spline",
            },
            title: {
                text: "",
            },
            colors: ['#775EEC', '#6C99FB', '#A8D24C', '#F7B500'],
            xAxis: {
                categories: categories,
                gridLineWidth: 0.5,
                gridLineColor: "#f5f5f5"

            },
            yAxis: {
                gridLineColor: "#f5f5f5",
                title: {
                    text: "",
                },
                allowDecimals: false,
                stackLabels: {
                    enabled: false,
                },
            },
            // tooltip: {
            //     shared: true,
            //     backgroundColor: "#061438",
            //     borderColor: "#061438",
            //     borderRadius: 3,
            //     style: {
            //         color: "#ffffff",
            //     },
            // },
            tooltip: {
                shared: true,
                backgroundColor: "#061438",
                borderColor: "#061438",
                borderRadius: 3,
                style: {
                    color: "#ffffff",
                },
                useHTML: true,
                formatter: function () {
                    let text = ``;
                    let total = 0;
                    this.points.map((item) => {
                        total = item.total;
                        text += `
                <br/>
                <div class="text-tooltip">  
                  <div class="point-tolltip" style="background:${item.point.color}"></div> 
                  <div>
                    ${item.point.series.name}: ${baseHelper.formatMoneyPostFix(item.point.y, 'đơn')}
                  </div> 
                </div>
                `
                    })
                    // Đoạn chữ Doanh thu kia cũ là ${this.x}
                    return (
                        `
                        Số lượng đơn hàng
                 <br/>
                ${text}
                <br/>
                <div>
              </div> `
                    );
                },
            },
            labels: {},
            // series: [
            //     {
            //         name: t("REPORTS.ORDER_REPORT.SUCCESS"),
            //         data: dataSuccess,
            //     },
            //     {
            //         name: t("REPORTS.ORDER_REPORT.PENDING"),
            //         data: dataPending,
            //     },
            //     {
            //         name: t("REPORTS.ORDER_REPORT.CANCEL"),
            //         data: dataCancel,
            //     },
            // ],
            series: _series,
        };
        return options;
    };



    return (
        <div className='block-content-item mt-20'>

            <h3 className='mb-24'>Thống kê số lượng đơn hàng</h3>

            <div className="content-chart">{!isLoading ? <PieChart highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
        </div>
    );
}
