import React from 'react';

class CheckoutLayout extends React.Component {
    render() {
        return (
            <div> This is checkout page</div>
        )
    }
}

export default CheckoutLayout;