import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as customerTypes from '../../../../redux/futures/customer/types';
import customerActions from '../../../../redux/futures/customer/actions';
import Location from '../../../../components/Location';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCustomerCreate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible, hideModal, selectedCustomer } = props;

    const customerReducer = useSelector((state) => state.customer);
    const loading = useMemo(() => {
        return customerReducer.loading || false;
    }, [customerReducer.loading]);

    const [customer, setCustomer] = useState('');
    const [address, setAddress] = useState({
        country_code: 'VN',
        country_name: 'Việt Nam',
    });

    useEffect(() => {
        if (customerReducer.action == customerTypes.CREATE_CUSTOMER) {
            if (customerReducer.status) {
                selectedCustomer(customerReducer.customer, [customerReducer.customer.address_default]);
                hideModal();
            } else {
                window.LadiUI.showErrorMessage('Thông báo', customerReducer.message, 'OK');
            }
        }
    }, [customerReducer.action]);

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        if (!customer.first_name) {
            window.LadiUI.toastCustom('danger', '', t('CUSTOMERS.NAME_EMPTY'));
            return;
        }
        if (!customer.email && !customer.phone) {
            window.LadiUI.toastCustom('danger', '', t('CUSTOMERS.EMAIL_OR_PHONE_EMPTY'));
            return;
        }

        let dataSend = {
            ...customer,
            address: {
                ...customer,
                ...address,
            },
        };

        dispatch(customerActions.create(dataSend));
    };

    const customerEdit = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setCustomer((pre) => {
            let tg = { ...pre };
            tg[name] = value;
            return tg;
        });
    };

    const onChangeLocation = (data) => {
        setAddress(data);
    };

    return (
        <Modal
            width={600}
            id='modal-customer-create'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('CUSTOMERS.ADD_CUSTOMER')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('CUSTOMERS.CREATE')}
            isLoading={loading}
            position_unset={true}
        >
            <div className='content-customer-create'>
                <div>
                    <label>{t('CUSTOMERS.FULL_NAME')}</label>
                    <Input className='mt-4' type='text' name='first_name' value={customer.first_name} onChange={customerEdit} />
                </div>
                <div className='mt-16 customer-email-phone'>
                    <div className='item'>
                        <label>{t('COMMON.EMAIL')}</label>
                        <Input className='mt-4' type='text' name='email' value={customer.email} onChange={customerEdit} />
                    </div>
                    <div className='item'>
                        <label>{t('COMMON.PHONE')}</label>
                        <Input
                            className='mt-4'
                            type='text'
                            name='phone'
                            value={customer.phone}
                            onChange={customerEdit}
                            placeholder='Vd: 0981111111'
                        />
                    </div>
                </div>
                <div className='mt-20 customer-location'>
                    <Location
                        position_unset={false}
                        address={address}
                        autoLoad={true}
                        onChangeInput={onChangeLocation}
                        validations={{
                            isRequiredCountry: true,
                            isRequiredState: true,
                            isRequiredDistrict: true,
                            isRequiredWard: true,
                        }}
                    />
                </div>
                <div className='mt-16'>
                    <label>{t('CUSTOMERS.ADDRESS')}</label>
                    <Input className='mt-4' type='text' name='address' value={customer.address} onChange={customerEdit} />
                </div>
            </div>
        </Modal>
    );
}

export default ModalCustomerCreate;
