/** @format */

import React from 'react';
import { connect } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { cloneDeep, map, compact } from 'lodash';
import Input from '../../../components/Input';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import Location from '../../../components/Location';
import appConfig from '../../../config/app';
import timezones from '../../../config/timezone';
import Dropdown from '../../../components/Dropdown';
import Image from '../../../components/Image';
import baseHelper from '../../../helpers/BaseHelper';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import storeActions from '../../../redux/futures/store/actions';
import * as storeTypes from '../../../redux/futures/store/types';

import LoadingScene from '../../../components/LoadingScene';
import ConfirmModal from '../../../components/ConfirmModal';

import CURRENCIES from '../../../config/currencies';
import { Editor } from '@tinymce/tinymce-react';

class ModalSettingGeneral extends React.Component {
    static propTypes = {
        visible: PropTypes.bool,
        store: PropTypes.object,
        tabFocus: PropTypes.string,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        isLoading: PropTypes.bool,
        isLoadingVerify: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            store: {
                name: '',
                country_code: '',
                country_name: '',
                postal_code: '',
                state_id: '',
                state_name: '',
                district_id: '',
                district_name: '',
                ward_id: '',
                ward_name: '',
                order_prefix: '',
                ticket_seat_prefix: '',
                currency_code: '',
                currency_symbol: '',
                phone: '',
                address: '',
                timezone: '',
                logo_url: '',
                favicon_url: '',
                cover_url: '',
                description: '',
                terms_of_use: '',
                privacy_policy: '',
                refund_policy: '',
            },
            tabIndexActive: 0,
            domain: '',
            isShowModalDomain: false,
            searchTimeZone: '',
        };
        this.initialState = cloneDeep(this.state);
        this.ref = React.createRef();
        this.inputGeneralRef = new Set();
        this.inputDomainRef = new Set();
        this.locationRef = React.createRef();

        this.TAB_STORES = {
            TAB_GENERAL: 0,
            TAB_TERMS_OF_USE: 1,
            TAB_PRIVACY_POLICY: 2,
            TAB_REFUND_POLICY: 3,
        };
    }

    componentDidMount() {
        // this.props.showStore(baseHelper.getCookie(appConfig.COOKIE.STORE_ID));
        if (this.props.tabFocus == 'domain') {
            setTimeout(() => {
                document.getElementById('trigger-tab-domain').click();
            }, 0);
        }
    }

    componentWillMount() {
        const { store } = this.state;
        if (store) {
            const newStore = {};
            map(this.state.store, (value, key) => {
                if (key == 'domain_verified') {
                    newStore[key] = store[key] || appConfig.STATUS.INACTIVE;
                } else {
                    newStore[key] = store[key] ? store[key] : '';
                }
            });

            this.setState({
                store: newStore,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.store && this.props.store.action != nextProps.store.action) {
            if (nextProps.store.action == storeTypes.SHOW_STORE) {
                if (nextProps.store.status) {
                    this.setState({
                        store: nextProps.store.store,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.store.message, 'OK');
                }
            }

            // if (nextProps.store.action == storeTypes.SHOW_TRACKING_STORE) {
            //     if (nextProps.store.status) {
            //         // this.setState({
            //         //     isShowModalSettingTracking: true,
            //         // })
            //     } else {
            //         window.LadiUI.toastCustom('danger', '', nextProps.store.message);
            //     }
            // }

            if (nextProps.store.action == storeTypes.UPDATE_STORE) {
                if (nextProps.store.status) {
                    // setTimeout(() => {
                    //     this.props.reloadStore(baseHelper.getCookie(appConfig.COOKIE.STORE_ID));
                    // }, 0);
                    window.LadiUI.toastCustom('success', '', nextProps.store.message);
                    this.props.getUserInfo();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.store.message, 'OK');
                }
            }
        }

        // if (store != nextProps._store) {
        //     const newStore = {};
        //     map(this.state.store, (value, key) => {
        //         if (key == 'domain_verified') {
        //             newStore[key] = nextProps._store[key] || appConfig.STATUS.INACTIVE;
        //         } else {
        //             newStore[key] = nextProps._store[key] ? nextProps._store[key] : '';
        //         }
        //     })

        //     this.setState({
        //         store: newStore
        //     });
        // }

        if (this.props.fileReducer.action != nextProps.fileReducer.action) {
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_LOGO_URL) {
                if (nextProps.fileReducer.status) {
                    this.setState({
                        store: {
                            ...this.state.store,
                            logo_url: nextProps.fileReducer.logo_url,
                        },
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                }
            }
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_FAVICON_URL) {
                if (nextProps.fileReducer.status) {
                    this.setState({
                        store: {
                            ...this.state.store,
                            favicon_url: nextProps.fileReducer.favicon_url,
                        },
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                }
            }
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_COVER_URL) {
                if (nextProps.fileReducer.status) {
                    this.setState({
                        store: {
                            ...this.state.store,
                            cover_url: nextProps.fileReducer.cover_url,
                        },
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                }
            }
        }
    }

    resetState = () => {
        this.setState({
            ...cloneDeep(this.initialState),
        });
    };

    selectCountry = (selected) => {
        this.setState({
            store: {
                ...this.state.store,
                country_code: selected.code,
            },
        });
    };

    onChangeInput = (event) => {
        let { name, value } = event.target;
        if (name == 'order_prefix') {
            this.setState({
                store: {
                    ...this.state.store,
                    [name]: value.toUpperCase(),
                },
            });
        } else {
            this.setState({
                store: {
                    ...this.state.store,
                    [name]: value,
                },
            });
        }
    };

    onChangeLocation = (address) => {
        this.setState({
            store: {
                ...this.state.store,
                ...address,
            },
        });
    };

    selectCurrency = (selectedItem) => {
        this.setState({
            store: {
                ...this.state.store,
                currency_code: selectedItem.code,
                currency_symbol: selectedItem.symbol_native,
            },
        });
    };

    selectTimezone = (selectedItem) => {
        this.setState({
            store: {
                ...this.state.store,
                timezone: selectedItem.CODE,
            },
        });
    };

    // getCurrencyName = (symbolNative) => {
    //     let matched = {};
    //     map(CURRENCIES, item => {
    //         if (item.symbol_native == symbolNative) {
    //             matched = item;
    //         }
    //     });

    //     return matched.code;
    // }

    submit = (event) => {
        event.preventDefault();
        const expression = /^[a-zA-Z0-9]{0,10}$/;
        const regex = new RegExp(expression);

        // Validate
        let errors = [];
        const _inputGeneralRef = compact(Array.from(this.inputGeneralRef));
        map(_inputGeneralRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });
        errors = errors.concat(this.locationRef.current.validate());
        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            let elm = document.getElementById('trigger-tab-general');
            if (elm) elm.click();
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { store } = this.state;
        const address = this.locationRef.current.getData();

        // this.props.onSubmit({ ...store, ...address });

        let { ticket_seat_prefix, order_prefix } = this.state.store;
        let isNotMatch = false;

        if (order_prefix) {
            // if (ticket_seat_prefix.match(regex)) {
            //     isNotMatch = false;
            // } else {
            //     isNotMatch = true;
            // }

            if (order_prefix.match(regex)) {
                isNotMatch = false;
            } else {
                isNotMatch = true;
            }
        }
        // if (order_prefix) {
        //     if (order_prefix.match(regex)) {
        //         this.props.updateStore({ ...store, ...address });
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', "Tiền tố không chứa các ký tự đặc biệt, tối đa 10 ký tự!");
        //         return;
        //     }
        // }
        if (isNotMatch) {
            let order_prefix_input = document.getElementById('order_prefix_input');
            order_prefix_input.classList.add('input_err');
            window.LadiUI.toastCustom('danger', '', 'Tiền tố đơn hàng không chứa các ký tự đặc biệt, tối đa 10 ký tự!');
        } else {
            let order_prefix_input = document.getElementById('order_prefix_input');
            order_prefix_input.classList.remove('input_err');
            this.props.updateStore({ ...store, ...address });
        }
    };

    fileChangedHandler = (event, type) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                switch (type) {
                    case 'logo':
                        this.props.uploadLogo(form);
                        break;
                    case 'favicon':
                        this.props.uploadFavicon(form);
                        break;
                    case 'cover':
                        this.props.uploadCover(form);
                        break;
                }
            }
        }
    };

    onChangeDescription = (value) => {
        this.setState({
            store: {
                ...this.state.store,
                description: value,
            },
        });
    };

    handleEditorChangeTermsOfUse = (value) => {
        this.setState({
            store: {
                ...this.state.store,
                terms_of_use: value,
            },
        });
    };

    handleEditorChangePrivacyPolicy = (value) => {
        this.setState({
            store: {
                ...this.state.store,
                privacy_policy: value,
            },
        });
    };

    handleEditorChangeRefundPolicy = (value) => {
        this.setState({
            store: {
                ...this.state.store,
                refund_policy: value,
            },
        });
    };

    handleCopyEmailHook = (emailHook) => {
        let { t } = this.props;
        if (emailHook) {
            navigator.clipboard.writeText(emailHook);
            window.LadiUI.toastCustom('success', '', t('COMMON.COPIED'));
        }
    };

    render() {
        const { t } = this.props;
        const { store, isShowModalDomain, domain } = this.state;
        const isLoadingUpload =
            this.props.fileReducer.loading &&
            [fileTypes.UPLOAD_LOGO_URL, fileTypes.UPLOAD_FAVICON_URL, fileTypes.UPLOAD_COVER_URL].includes(this.props.fileReducer.waiting);
        const { TAB_GENERAL, TAB_TERMS_OF_USE, TAB_PRIVACY_POLICY, TAB_REFUND_POLICY } = this.TAB_STORES;

        return (
            <div id='modal-setting-general' className='page-setting'>
                {isLoadingUpload && <LoadingScene blur={true} />}
                <React.Fragment>
                    <div className='block-title-action'>
                        <h3 className='title'>Cài đặt store</h3>
                        <div className='btn-action'>
                            <button type='button' onClick={(e) => this.submit(e)} className='ladiui btn btn-primary btn-sm'>
                                {t('SETTINGS.SAVE_CHANGE')}
                            </button>
                        </div>
                    </div>
                    <ul className='ladiui nav nav-tabs'>
                        <li className={`ladiui nav-item`} onClick={() => this.setState({ tabIndexActive: TAB_GENERAL })}>
                            <span className={`ladiui tab-link ${this.state.tabIndexActive == TAB_GENERAL ? 'active' : ''}`}>
                                {t('STORES.EDIT_STORE')}
                            </span>
                        </li>
                        <li className={` ladiui nav-item`} onClick={() => this.setState({ tabIndexActive: TAB_TERMS_OF_USE })}>
                            <span className={`ladiui tab-link ${this.state.tabIndexActive == TAB_TERMS_OF_USE ? 'active' : ''}`}>
                                {t('STORES.TERMS_OF_USE')}
                            </span>
                        </li>
                        <li className={` ladiui nav-item`} onClick={() => this.setState({ tabIndexActive: TAB_PRIVACY_POLICY })}>
                            <span className={`ladiui tab-link ${this.state.tabIndexActive == TAB_PRIVACY_POLICY ? 'active' : ''}`}>
                                {t('STORES.PRIVACY_POLICY')}
                            </span>
                        </li>
                        <li className={` ladiui nav-item`} onClick={() => this.setState({ tabIndexActive: TAB_REFUND_POLICY })}>
                            <span className={`ladiui tab-link ${this.state.tabIndexActive == TAB_REFUND_POLICY ? 'active' : ''}`}>
                                {t('STORES.REFUND_POLICY')}
                            </span>
                        </li>
                    </ul>

                    <div className='ladiui tab-content form-setting max-width-content' id='myTabContent'>
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == TAB_GENERAL ? 'show open' : ''}`}
                            id='tab-general'
                        >
                            <div className='w100'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('STORES.NAME')}</label>
                                    <Input
                                        ref={(ref) => this.inputGeneralRef.add(ref)}
                                        name='name'
                                        validationName={t('STORES.NAME')}
                                        placeholder={t('STORES.NAME')}
                                        value={store.name}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group mb-0'>
                                    <label className='ladiui-label'>{t('COMMON.ADDRESS')}</label>
                                    <Input
                                        name='address'
                                        validationName={t('COMMON.ADDRESS')}
                                        placeholder={t('COMMON.ADDRESS')}
                                        value={store.address}
                                        onChange={this.onChangeInput}
                                        ref={(ref) => this.inputGeneralRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                            </div>
                            <div className='flex' style={{ alignItems: 'end' }}>
                                <div className='ladiui form-group mb-0'>
                                    <label className='ladiui-label'>{t('STORES.EMAIL_HOOK')}</label>
                                    <Input value={store.email_hook} disabled={true} />
                                </div>
                                <button
                                    className='ladiui btn btn-outline-primary ml-24'
                                    onClick={() => this.handleCopyEmailHook(store.email_hook)}
                                >
                                    {t('ACTIONS.COPY')}
                                </button>
                            </div>
                            <div className='ladiui-row'>
                                <div className='col-50'>
                                    <div className='ladiui form-group mb-0'>
                                        <label className='ladiui-label'>{t('STORES.TICKET_SEAT_PREFIX')}</label>
                                        <Input
                                            ref={(ref) => this.inputGeneralRef.add(ref)}
                                            name='ticket_seat_prefix'
                                            validationName={t('STORES.TICKET_SEAT_PREFIX')}
                                            placeholder='DH'
                                            value={store.ticket_seat_prefix}
                                            onChange={this.onChangeInput}
                                        />
                                    </div>
                                    <div className='ladiui form-group mb-0'>
                                        <label className='ladiui-label'>{t('COMMON.PHONE')}</label>
                                        <Input
                                            name='phone'
                                            validationName={t('COMMON.PHONE')}
                                            placeholder={t('COMMON.PHONE')}
                                            value={store.phone}
                                            onChange={this.onChangeInput}
                                            ref={(ref) => this.inputGeneralRef.add(ref)}
                                            validations={{ phone: true, isRequired: true }}
                                        />
                                    </div>
                                </div>
                                <div className='col-50'>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label'>{t('STORES.ORDER_PREFIX')}</label>
                                        <Input
                                            ref={(ref) => this.inputGeneralRef.add(ref)}
                                            id='order_prefix_input'
                                            name='order_prefix'
                                            validationName={t('STORES.ORDER_PREFIX')}
                                            placeholder='Nhập tiền tố đơn hàng'
                                            value={store.order_prefix}
                                            onChange={this.onChangeInput}
                                        />
                                    </div>

                                    <div className='ladiui form-group input-general-custom'>
                                        <label className='ladiui-label'>{t('COMMON.TIMEZONE')}</label>
                                        <Dropdown
                                            data={timezones}
                                            onSelectItem={this.selectTimezone}
                                            currentKey={store.timezone}
                                            _key={'CODE'}
                                            _value={'NAME'}
                                            isShowBlockInputSearch={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='ladiui form-group' style={{ display: 'unset' }}>
                                <Location
                                    ref={this.locationRef}
                                    wrapperClassName='ladiui form-group'
                                    wrapperProps={{ style: { margin: 'auto' } }}
                                    address={store}
                                    autoLoad={true}
                                    onChangeInput={this.onChangeLocation}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <div className='ladiui-row'>
                                    <div className='col-50'>
                                        <div className='ladiui form-group mt-0'>
                                            <label className='ladiui-label'>{t('STORES.CURRENCY_CODE')}</label>

                                            <Dropdown
                                                data={CURRENCIES}
                                                onSelectItem={this.selectCurrency}
                                                currentKey={store.currency_symbol}
                                                currentValue={store.currency_symbol}
                                                _key={'code'}
                                                _value={'code'}
                                                isShowBlockInputSearch={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-50'></div>
                            </div>

                            <div className='ladiui form-group mb-0' style={{ display: 'inline-block', marginTop: '9px' }}>
                                <label className='ladiui-label'>{t('COMMON.DESCRIPTION')}</label>
                                <Editor
                                    tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                    licenseKey="gpl"
                                    value={store.description}
                                    init={{
                                        selector: 'textarea',
                                        min_height: 200,
                                        max_height: 800,
                                        autoresize_overflow_padding: 5,
                                        autoresize_bottom_margin: 24,
                                        plugins:
                                            'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                        toolbar:
                                            'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                        toolbar_drawer: 'sliding',
                                        // language_url: '../../../language/tinymce-vi.js',
                                        content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                        // valid_elements: '*[*]',
                                    }}
                                    onEditorChange={this.onChangeDescription}
                                />
                            </div>
                            <div className='group-store-logo'>
                                <div className='ladiui form-group' style={{ marginRight: 24 }}>
                                    <div className='text-center image setting-block-image'>
                                        <Image src={store.logo_url} width={150} />
                                        <div className='ladiui-button-upload image block-upload-image'>
                                            <div className='block-overlay' style={{ marginTop: '30px' }}>
                                                <button type='button' className='ladiui btn btn-primary icon'>
                                                    <i className='ladi-icon icon-data-upload' /> <br />
                                                    <p>{t('COMMON.CHOOSE_FILES')}</p>
                                                </button>
                                                <p>Logo(150x150px)</p>

                                                <input
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    onChange={(event) => this.fileChangedHandler(event, 'logo')}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='ladiui form-group' style={{ marginRight: 24 }}>
                                    <div className='text-center image setting-block-image'>
                                        <Image src={store.favicon_url} width={150} />
                                        <div className='ladiui-button-upload image block-upload-image'>
                                            <div className='block-overlay' style={{ marginTop: '30px' }}>
                                                <button type='button' className='ladiui btn btn-primary icon'>
                                                    <i className='ladi-icon icon-data-upload' /> <br />
                                                    <p>{t('COMMON.CHOOSE_FILES')}</p>
                                                </button>
                                                <p>Favicon(150x150px)</p>

                                                <input
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    onChange={(event) => this.fileChangedHandler(event, 'favicon')}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='ladiui form-group'>
                                    <div className='text-center image setting-block-image'>
                                        <Image src={store.cover_url} width={300} />
                                        <div className='ladiui-button-upload image block-upload-image'>
                                            <div className='block-overlay' style={{ marginTop: '30px' }}>
                                                <button type='button' className='ladiui btn btn-primary icon'>
                                                    <i className='ladi-icon icon-data-upload' /> <br />
                                                    <p>{t('COMMON.CHOOSE_FILES')}</p>
                                                </button>
                                                <p>Banner(90x404px)</p>

                                                <input
                                                    type='file'
                                                    name='file'
                                                    accept='image/*'
                                                    onChange={(event) => this.fileChangedHandler(event, 'cover')}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == TAB_TERMS_OF_USE ? 'show open' : ''}`}
                            id='tab-terms-of-use'
                        >
                            <div className='ladiui form-group'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('STORES.TERMS_OF_USE_CONTENT')}</label>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={store.terms_of_use}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            // language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={this.handleEditorChangeTermsOfUse}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == TAB_PRIVACY_POLICY ? 'show open' : ''}`}
                            id='tab-privacy-policy'
                        >
                            <div className='ladiui form-group'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label' style={{ color: '#6d6d6d' }}>
                                        {t('STORES.PRIVACY_POLICY_CONTENT')}
                                    </label>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={store.privacy_policy}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            // language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={this.handleEditorChangePrivacyPolicy}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`ladiui tab-pane fade ${this.state.tabIndexActive == TAB_REFUND_POLICY ? 'show open' : ''}`}
                            id='tab-refund-policy'
                        >
                            <div className='ladiui form-group'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label' style={{ color: '#6d6d6d' }}>
                                        {t('STORES.REFUND_POLICY_CONTENT')}
                                    </label>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={store.refund_policy}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            // language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={this.handleEditorChangeRefundPolicy}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadLogo: (form) => dispatch(fileActions.uploadLogo(form)),
        uploadFavicon: (form) => dispatch(fileActions.uploadFavicon(form)),
        uploadCover: (form) => dispatch(fileActions.uploadCover(form)),
        reloadStore: (storeID) => dispatch(storeActions.reload(storeID)),
        updateStore: (store) => dispatch(storeActions.update(store)),
        showStore: (storeID) => dispatch(storeActions.show(storeID)),
        getUserInfo: () => dispatch(storeActions.getUserInfo()),
    };
};

const mapStateToProps = (state) => ({
    fileReducer: { ...state.file },
    store: { ...state.store },
    cookieReducer: { ...state.cookieReducer },
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(ModalSettingGeneral));
