import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.SETTING_SHIPPING_CONNECT: {
            return {
                ...state,
                action: types.SETTING_SHIPPING_CONNECT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_SHIPPING_DISCONNECT: {
            return {
                ...state,
                action: types.SETTING_SHIPPING_DISCONNECT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_SHIPPING_INACTIVE: {
            return {
                ...state,
                action: types.SETTING_SHIPPING_INACTIVE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_SHIPPING_ACTIVE: {
            return {
                ...state,
                action: types.SETTING_SHIPPING_ACTIVE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_CREATE_TEMPLATE: {
            return {
                ...state,
                action: types.SETTING_CREATE_TEMPLATE,
                status: action.status,
                message: action.message,
                template: action.status ? action.payload.template : {},
            };
        }
        case types.SETTING_LIST_TEMPLATE_PRINT: {
            return {
                ...state,
                action: types.SETTING_LIST_TEMPLATE_PRINT,
                status: action.status,
                message: action.message,
                templates: action.status ? action.payload.templates : [],
            };
        }
        case types.SETTING_LIST_TEMPLATE_EMAIL: {
            return {
                ...state,
                action: types.SETTING_LIST_TEMPLATE_EMAIL,
                status: action.status,
                message: action.message,
                templates: action.status ? action.payload.templates : [],
            };
        }
        case types.SETTING_UPDATE_TEMPLATE_PRINT: {
            return {
                ...state,
                action: types.SETTING_UPDATE_TEMPLATE_PRINT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_UPDATE_TEMPLATE_EMAIL: {
            return {
                ...state,
                action: types.SETTING_UPDATE_TEMPLATE_EMAIL,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_RESTORE_TEMPLATE_PRINT: {
            return {
                ...state,
                action: types.SETTING_RESTORE_TEMPLATE_PRINT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_RESTORE_TEMPLATE_EMAIL: {
            return {
                ...state,
                action: types.SETTING_RESTORE_TEMPLATE_EMAIL,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_PAYMENT_CONNECT: {
            return {
                ...state,
                action: types.SETTING_PAYMENT_CONNECT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_PAYMENT_ACTIVE: {
            return {
                ...state,
                action: types.SETTING_PAYMENT_ACTIVE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_PAYMENT_INACTIVE: {
            return {
                ...state,
                action: types.SETTING_PAYMENT_INACTIVE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_PAYMENT_DISCONNECT: {
            return {
                ...state,
                action: types.SETTING_PAYMENT_DISCONNECT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_LIST_WEB_HOOK: {
            return {
                ...state,
                action: types.SETTING_LIST_WEB_HOOK,
                status: action.status,
                message: action.message,
                web_hooks: action.status ? action.payload.web_hooks : [],
                web_hook_scopes: action.status ? action.payload.web_hook_scopes : {},
            };
        }
        case types.SETTING_WEB_HOOK_CREATE: {
            return {
                ...state,
                action: types.SETTING_WEB_HOOK_CREATE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_WEB_HOOK_UPDATE: {
            return {
                ...state,
                action: types.SETTING_WEB_HOOK_UPDATE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_WEB_HOOK_DELETE: {
            return {
                ...state,
                action: types.SETTING_WEB_HOOK_DELETE,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_WEB_HOOK_TRANSACTION_LIST: {
            return {
                ...state,
                action: types.SETTING_WEB_HOOK_TRANSACTION_LIST,
                status: action.status,
                message: action.message,
                web_hook_transactions: action.status ? action.payload.web_hook_transactions : [],
                total_record: action.status ? action.payload.total_record : 0,
                total_page: action.status ? action.payload.total_page : 0,
            };
        }
        case types.SETTING_LIST_NOTIFICATION_EMAIL: {
            return {
                ...state,
                action: types.SETTING_LIST_NOTIFICATION_EMAIL,
                status: action.status,
                message: action.message,
                notification_emails: action.status ? action.payload.notification_emails : [],
                notification_email_configs: action.status ? action.payload.notification_email_configs : {},
            };
        }
        case types.SETTING_LIST_CUSTOM_FIELD: {
            return {
                ...state,
                action: types.SETTING_LIST_CUSTOM_FIELD,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_CREATE_NOTIFICATION_EMAIL: {
            return {
                ...state,
                action: types.SETTING_CREATE_NOTIFICATION_EMAIL,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_SEND_NOTIFICATION_EMAIL: {
            return {
                ...state,
                action: types.SETTING_SEND_NOTIFICATION_EMAIL,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_DELETE_NOTIFICATION_EMAIL: {
            return {
                ...state,
                action: types.SETTING_DELETE_NOTIFICATION_EMAIL,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL: {
            return {
                ...state,
                action: types.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG: {
            return {
                ...state,
                action: types.SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_PAYMENT_GATEWAY: {
            return {
                ...state,
                action: types.SETTING_PAYMENT_GATEWAY,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_ADD_CUSTOM_FIELD: {
            return {
                ...state,
                action: types.SETTING_ADD_CUSTOM_FIELD,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_LIST_BY_CUSTOM_FIELD: {
            return {
                ...state,
                action: types.SETTING_LIST_BY_CUSTOM_FIELD,
                status: action.status,
                message: action.message,
                customFields: action.payload.custom_fields,
                total_page: action.payload.total_page,
                totalRecord: action.payload.total_record,
            };
        }
        case types.SETTING_DELETE_CUSTOM_FIELD: {
            return {
                ...state,
                action: types.SETTING_DELETE_CUSTOM_FIELD,
                status: action.status,
                message: action.message,
            };
        }
        case types.SETTING_UPDATE_CUSTOM_FIELD: {
            return {
                ...state,
                action: types.SETTING_UPDATE_CUSTOM_FIELD,
                status: action.status,
                message: action.message,
            };
        }

        case types.LIST_BANKS_VIET_QR: {
            return {
                ...state,
                list_banks: action.payload && action.payload.length > 0 ? action.payload : [],
                action: types.LIST_BANKS_VIET_QR,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
