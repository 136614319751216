import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';
import productVariantAction from '../../../../redux/futures/product_variant/actions';

import * as orderTagTypes from '../../../../redux/futures/order_tag/types';
import orderTagAction from '../../../../redux/futures/order_tag/actions';
import ModalOrderTagCreate from './ModalOrderTagCreate';

// eslint-disable-next-line max-lines-per-function
export const OrderTags = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { orderTag, orderData } = props;

    const storeReducer = useSelector((state) => state.store);
    const orderTagReducer = useSelector((state) => state.orderTag);

    const tags = useMemo(() => {
        return orderTagReducer.orderTags || [];
    }, [orderTagReducer.orderTags]);

    const [tagSelected, setTagSelected] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isShowModalCreate, setShowModalCreate] = useState(false);
    const [isShowList, setShowList] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            return tagSelected;
        },
    }));

    useEffect(() => {
        dispatch(orderTagAction.search(''));
    }, []);

    useEffect(() => {
        if (orderData && orderData.order_id) setTagSelected(orderTag || []);
    }, [orderTag, orderData]);

    const tagSearch = () => {
        dispatch(orderTagAction.search(searchValue));
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            dispatch(orderTagAction.search(searchValue));
        }, 500);

        return () => clearTimeout(getData);
    }, [searchValue]);

    const selectedTag = (item, isCreate = false) => {
        if (!tagSelected.find((_item) => _item.order_tag_id == item.order_tag_id)) tagSelected.push(item);
        if (isCreate) tagSearch();
    };

    const removeTag = (index) => {
        let tg = [...tagSelected];
        tg.splice(index, 1);
        setTagSelected(tg);
    };

    return (
        <>
            <div className='pb-12'>
                <label className='ladiui label-18'>{t('TAGS.ORDER_TAG_LABEL')}</label>
            </div>
            <div
                className='ladiui search-group search-section search-order-tag'
                onFocus={() => setShowList(true)}
                onBlur={() => {
                    setSearchValue('');
                    setTimeout(() => setShowList(false), 300);
                }}
            >
                <i className='ladiui icon icon-search' onClick={tagSearch}></i>
                <input
                    id='keyword_search'
                    className='ladiui search-field dropdown-toggle form-control search-width'
                    name='searchName'
                    placeholder={t('TAGS.SEARCH_TAG')}
                    aria-expanded='false'
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            tagSearch();
                        }
                    }}
                />

                <div className={`search-section-detail ${isShowList ? 'show' : ''}`}>
                    <div
                        className='create-btn'
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowModalCreate(true);
                        }}
                    >
                        <i className='new-ldicon-add-circle' /> <span className='ladiui text-14-brand'>{t('TAGS.TITLE_TAG')}</span>
                    </div>
                    <div className='search-section-list'>
                        {tags.map((item, index) => {
                            return (
                                <div
                                    className='search-section-item ladiui '
                                    key={index}
                                    onClick={() => selectedTag(item)}
                                    // style={{ color: 'var(--main-default)', backgroundColor: item.color }}
                                >
                                    {item.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className='order-tag-list'>
                {tagSelected.map((item, index) => {
                    return (
                        <div
                            key={index}
                            style={{ color: 'var(--main-default)', backgroundColor: item.color }}
                            className='order-tag-list-item'
                        >
                            <span className='ladiui text-14'>{item.name}</span>
                            <div>
                                <i className='new-ldicon-close-fill-2' onClick={() => removeTag(index)} />
                            </div>
                        </div>
                    );
                })}
            </div>
            <ModalOrderTagCreate
                visible={isShowModalCreate}
                hideModal={() => {
                    setShowModalCreate(false);
                }}
                selectedTag={selectedTag}
            />
        </>
    );
});
