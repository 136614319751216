import React from 'react';

import { connect } from 'react-redux';
import ModalSettingAccount from './components/ModalSettingAccount';
import ModalSettingShipping from './components/ModalSettingShipping/ModalSettingShipping';
import ModalSettingGeneral from './components/ModalSettingGeneral';
import ModalSettingAddress from './components/ModalSettingAddress';
import ModalSettingNotification from './components/ModalSettingNotification';
import ModalSettingApi from './components/ModalSettingApi';
import PageSettingPayment from './components/PageSettingPayment';
import ModalSettingAssign from './components/ModalSettingAssign/ModalSettingAssign';
import ModalSettingIP from './components/ModalSettingIP';
import ModalSettingTemplatePrint from './components/ModalSettingTemplatePrint';
import ModalSettingTemplateEmail from './components/ModalSettingTemplateEmail';
import ModalSettingWebHook from './components/ModalSettingWebHook';
import ModalSettingCheckoutConfig from './components/ModalSettingCheckoutConfig';
import ModalCreditTransaction from './components/ModalCreditTransaction';
import ModalSettingTracking from './components/ModalSettingTracking';
import PageSettingDomain from './components/PageSettingDomain';
import ModalSettingCustomField from './components/ModalSettingCustomField';

import PageGeneralSetting from './components/PageSettingGeneralV4';
import PageStorePolicy from './components/PageStorePolicyV4';
import PageSettingTracking from './components/PageSettingTrackingV4';
import PageSettingNotification from './components/PageSettingNotificationV4';

import staffActions from '../../redux/futures/staff/actions';
import * as staffTypes from '../../redux/futures/staff/types';

import storeActions from '../../redux/futures/store/actions';
import * as storeTypes from '../../redux/futures/store/types';

import storeAddressActions from '../../redux/futures/store_address/actions';
import * as storeAddressTypes from '../../redux/futures/store_address/types';

import shippingActions from '../../redux/futures/shipping/actions';
import * as shippingTypes from '../../redux/futures/shipping/types';

import addressConfigActions from '../../redux/futures/address_config/actions';
import * as addressConfigTypes from '../../redux/futures/address_config/types';

import shippingRateActions from '../../redux/futures/shipping_rate/actions';
import * as shippingRateTypes from '../../redux/futures/shipping_rate/types';

import apiActions from '../../redux/futures/api/actions';
import * as apiTypes from '../../redux/futures/api/types';

import assignActions from '../../redux/futures/assign/actions';
import * as assignTypes from '../../redux/futures/assign/types';

import paymentActions from '../../redux/futures/payment/actions';
import * as paymentTypes from '../../redux/futures/payment/types';

import settingActions from '../../redux/futures/setting/actions';
import * as settingTypes from '../../redux/futures/setting/types';

import { has, includes, pick } from 'lodash';
import { withTranslation } from 'react-i18next';

import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';
import queryString from 'query-string';
import TabPackageInfo from './components/TabPackageInfo';
import SettingDefined from './SettingDefined';

class Setting extends React.Component {
    constructor(props) {
        super(props);

        // let hash = window.location.hash;
        // let search = window.location.search;
        // const params = new URLSearchParams(search);

        let hash = baseHelper.getQueryDataUrlByKey('tab');

        if (!hash) {
            hash = 'GENERAL';
        }
        if (hash === 'AUTO_ASSIGN') {
            // hash = '#';
            this.openModalSettingAssign();
        }
        this.state = {
            isShowModalSettingGeneral: false,
            isShowModalSettingAccount: false,
            isShowModalSettingAddress: false,
            isShowModalSettingNotification: false,
            isShowModalSettingShipping: false,
            isShowModalSettingApi: false,
            isShowModalSettingPayment: false,
            isShowModalSettingAssign: false,
            isShowModalSettingIP: false,
            isShowModalSettingTemplatePrint: false,
            isShowModalSettingTemplateEmail: false,
            isShowModalSettingWebHook: false,
            isShowModalSettingCheckoutConfig: false,
            isShowModalCreditTransaction: false,
            isShowModalSettingTracking: false,
            isShowModalSettingDomain: false,
            isShowModalSettingCustomField: false,
            selected: hash,
        };
    }

    componentDidMount() {
        window.LadiUI.init();

        let { type } = queryString.parse(this.props.location.search);

        if (type == 'setting-general') {
            this.openModalSettingGeneral();
        }

        if (type == 'setting-checkout') {
            this.openModalSettingCheckoutConfig();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.state.selected) {
        }

        if (this.props.location.search !== nextProps.location.search) {
            let hash = baseHelper.getQueryDataUrlByKey('tab');
            if (hash != this.state.selected) {
                this.setState({
                    selected: hash,
                });
            }
        }

        if (this.props.shipping.action != nextProps.shipping.action) {
            if (nextProps.shipping.action == shippingTypes.SHIPPING_LIST_PARTNERS) {
                if (nextProps.shipping.status) {
                    // this.setState({
                    //     isShowModalSettingShipping: true,
                    // })
                    this.setSelectedState('SHIPPING');
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.shipping.message);
                }
            }
        }

        if (this.props.store.action != nextProps.store.action) {
            if (nextProps.store.action == storeTypes.SHOW_STORE) {
                if (nextProps.store.status) {
                    this.setState({
                        isShowModalSettingGeneral: true,
                    });
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.store.message);
                }
            }
        }

        if (this.props.settingReducer.action != nextProps.settingReducer.action) {
            if (nextProps.settingReducer.action == settingTypes.SETTING_LIST_CUSTOM_FIELD) {
                if (nextProps.settingReducer.status) {
                    this.setState({
                        isShowModalSettingCustomField: true,
                    });
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
        }

        if (this.props.settingReducer.action != nextProps.settingReducer.action) {
            if (nextProps.settingReducer.action == settingTypes.SETTING_LIST_WEB_HOOK) {
                if (nextProps.settingReducer.status) {
                    // this.setState({
                    //     isShowModalSettingWebHook: true,
                    // })
                    this.setSelectedState('WEB_HOOK');
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
        }

        if (this.props.assignReducer.action != nextProps.assignReducer.action) {
            if (nextProps.assignReducer.action == assignTypes.ASSIGN_LIST) {
                if (nextProps.assignReducer.status) {
                    // this.setState({
                    //     isShowModalSettingAssign: true,
                    // })
                    this.setSelectedState('AUTO_ASSIGN');
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.assignReducer.message);
                }
            }

            if (nextProps.assignReducer.action == assignTypes.ASSIGN_APPLY_BASIC) {
                if (nextProps.assignReducer.status) {
                    this.props.reloadAssignRules();
                    window.LadiUI.toastCustom('success', '', nextProps.assignReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.assignReducer.message);
                }
            }

            if (nextProps.assignReducer.action == assignTypes.ASSIGN_CREATE_ADVANCED) {
                if (nextProps.assignReducer.status) {
                    this.props.reloadAssignRules();
                    window.LadiUI.toastCustom('success', '', nextProps.assignReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.assignReducer.message);
                }
            }

            if (nextProps.assignReducer.action == assignTypes.ASSIGN_UPDATE_STORE_STATUS) {
                if (nextProps.assignReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.assignReducer.message);
                    this.props.reloadAssignRules();
                } else {
                    // window.LadiUI.toastCustom("danger", "", nextProps.assignReducer.message);
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.assignReducer.message, 'OK');
                }
            }
        }

        if (this.props.settingReducer.action != nextProps.settingReducer.action) {
            if (nextProps.settingReducer.action == settingTypes.SETTING_LIST_TEMPLATE_PRINT) {
                if (nextProps.settingReducer.status) {
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_RESTORE_TEMPLATE_PRINT) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    this.props.listTemplatePrint();
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_UPDATE_TEMPLATE_PRINT) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_LIST_TEMPLATE_EMAIL) {
                if (nextProps.settingReducer.status) {
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_RESTORE_TEMPLATE_EMAIL) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    this.props.listTemplateEmail();
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }

            if (nextProps.settingReducer.action == settingTypes.SETTING_UPDATE_TEMPLATE_EMAIL) {
                if (nextProps.settingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.settingReducer.message);
                    this.props.listTemplateEmail();
                } else {
                    window.LadiUI.toastCustom('danger', '', nextProps.settingReducer.message);
                }
            }
        }
    }

    openModalSettingAccount = () => {
        this.props.listStaffs();
    };

    hideModalSettingAccount = () => {
        this.setState({
            isShowModalSettingAccount: false,
        });
    };

    openModalSettingShipping = () => {
        this.setSelectedState('SHIPPING');
        // this.props.listShippingPartners();
        // this.props.listShippingRate();
        // this.props.listStates('VN');
    };

    hideModalSettingShipping = () => {
        this.setState({
            isShowModalSettingShipping: false,
        });
    };

    setSelectedState = (data) => {
        // window.location.hash = `#${data}`;

        let _queryStrings = new URLSearchParams();
        _queryStrings.set('tab', data);
        this.props.history.replace({ search: _queryStrings.toString() });

        // this.setState({
        //     selected: data,
        // });

        const myDiv = document.getElementById('elm-main');
        if (myDiv) {
            let position = myDiv.getBoundingClientRect();
            window.scrollTo({
                left: position.left,
                top: position.top + window.scrollY,
                behavior: 'smooth',
            });
        }
    };

    hideModalSettingGeneral = () => {
        this.setState({
            isShowModalSettingGeneral: false,
        });
    };

    hideModalSettingAddress = () => {
        this.setState({
            isShowModalSettingAddress: false,
        });
    };

    hideModalSettingListNotification = () => {
        this.setState({
            isShowModalSettingNotification: false,
        });
    };

    hideModalSettingCustomField = () => {
        this.setState({
            isShowModalSettingCustomField: false,
        });
    };

    openModalSettingListNotification = () => {
        this.props.listNotificationEmail();
    };

    openModalSettingCustomField = () => {
        this.props.listCustomField();
    };

    openModalSettingApi = () => {
        this.props.listThirdParties();
    };

    hideModalSettingApi = () => {
        this.setState({
            isShowModalSettingApi: false,
        });
    };

    openModalSettingPayment = () => {
        this.setState({
            isShowModalSettingPayment: true,
        });
    };

    hideModalSettingPayment = () => {
        this.setState({
            isShowModalSettingPayment: false,
        });
    };

    openModalSettingAssign = () => {
        this.props.listAssignRules();
    };

    hideModalSettingAssign = () => {
        this.setState({
            isShowModalSettingAssign: false,
        });
    };

    // openModalSettingWebHook = () => {
    //     this.props.listWebHook();
    // }

    hideModalSettingWebHook = () => {
        this.setState({
            isShowModalSettingWebHook: false,
        });
    };

    openModalSettingIP = () => {
        this.setState({
            isShowModalSettingIP: true,
        });
    };

    // hideModalSettingIP = () => {
    //     this.setState({
    //         isShowModalSettingIP: false,
    //     });
    // }

    openModalSettingTemplatePrint = () => {
        this.props.listTemplatePrint();
    };

    updateTemplatePrint = (data) => {
        this.props.updateTemplatePrint(data);
    };

    restoreTemplatePrint = (storeTemplateID) => {
        this.props.restoreTemplatePrint(storeTemplateID);
    };

    // hideModalSettingTemplatePrint = () => {
    //     this.setState({
    //         isShowModalSettingTemplatePrint: false,
    //     });
    // }

    openModalSettingTemplateEmail = () => {
        this.props.listTemplateEmail();
    };

    updateTemplateEmail = (data) => {
        this.props.updateTemplateEmail(data);
    };

    restoreTemplateEmail = (storeTemplateID) => {
        this.props.restoreTemplateEmail(storeTemplateID);
    };

    // hideModalSettingTemplateEmail = () => {
    //     this.setState({
    //         isShowModalSettingTemplateEmail: false,
    //     });
    // }

    openModalSettingCheckoutConfig = () => {
        this.setState({
            isShowModalSettingCheckoutConfig: true,
        });
    };

    // hideModalSettingCheckoutConfig = () => {
    //     this.setState({
    //         isShowModalSettingCheckoutConfig: false
    //     });
    // }

    openModalCreditTransaction = () => {
        this.setState({
            isShowModalCreditTransaction: true,
        });
    };

    // openModalSettingTracking = () => {
    //     this.props.showTracking();
    // }

    submitAssign = (data, type) => {
        if (type == appConfig.ASSIGN_TYPE.BASIC) {
            this.props.applyAssignBasic(data);
        } else {
            this.props.createAssignAdvanced(data);
        }
    };

    updateStoreAssignStatus = (status) => {
        this.props.updateStoreAssignStatus(status);
    };

    /****************************************************************/

    render() {
        const {
            t,
            shipping: { partners },
        } = this.props;
        const selectedItem = this.state.selected;

        const isLoadingData =
            (this.props.staff.loading && staffTypes.LIST_STAFFS == this.props.staff.waiting) ||
            (this.props.shipping.loading && shippingTypes.SHIPPING_LIST_PARTNERS == this.props.shipping.waiting) ||
            (this.props.store.loading && storeTypes.SHOW_STORE == this.props.store.waiting) ||
            (this.props.store.loading && storeTypes.SHOW_TRACKING_STORE == this.props.store.waiting) ||
            (this.props.storeAddress.loading && storeAddressTypes.LIST_STORE_ADDRESS == this.props.storeAddress.waiting) ||
            (this.props.shippingRate.loading && shippingRateTypes.SHIPPING_RATE_LIST == this.props.shippingRate.waiting) ||
            (this.props.addressConfig.loading && addressConfigTypes.LIST_STATES == this.props.addressConfig.waiting) ||
            (this.props.api.loading && apiTypes.API_LIST_THIRD_PARTY == this.props.api.waiting) ||
            (this.props.assignReducer.loading && assignTypes.ASSIGN_LIST == this.props.assignReducer.waiting) ||
            (this.props.settingReducer.loading && settingTypes.SETTING_LIST_TEMPLATE_PRINT == this.props.settingReducer.waiting) ||
            (this.props.settingReducer.loading && settingTypes.SETTING_LIST_TEMPLATE_EMAIL == this.props.settingReducer.waiting) ||
            (this.props.settingReducer.loading && settingTypes.SETTING_LIST_WEB_HOOK == this.props.settingReducer.waiting) ||
            (this.props.settingReducer.loading && settingTypes.SETTING_LIST_NOTIFICATION_EMAIL == this.props.settingReducer.waiting);

        const isSubmitGeneral = this.props.store.loading && includes([storeTypes.UPDATE_STORE], this.props.store.waiting);
        const isSubmitAccount =
            this.props.staff.loading && includes([staffTypes.CREATE_STAFF, staffTypes.UPDATE_STAFF], this.props.staff.waiting);
        const isLoadingRemoveStaff = this.props.staff.loading && includes([staffTypes.REMOVE_STAFF], this.props.staff.waiting);
        const isApplyAssignBasic =
            this.props.assignReducer.loading &&
            includes([assignTypes.ASSIGN_APPLY_BASIC, assignTypes.ASSIGN_CREATE_ADVANCED], this.props.assignReducer.waiting);
        const isUpdateTemplatePrint =
            this.props.settingReducer.loading && includes([settingTypes.SETTING_UPDATE_TEMPLATE_PRINT], this.props.settingReducer.waiting);
        const isUpdateTemplateEmail =
            this.props.settingReducer.loading && includes([settingTypes.SETTING_UPDATE_TEMPLATE_EMAIL], this.props.settingReducer.waiting);
        const isRestoreTemplatePrint =
            this.props.settingReducer.loading && includes([settingTypes.SETTING_RESTORE_TEMPLATE_PRINT], this.props.settingReducer.waiting);
        const isRestoreTemplateEmail =
            this.props.settingReducer.loading && includes([settingTypes.SETTING_RESTORE_TEMPLATE_EMAIL], this.props.settingReducer.waiting);

        const locationSetting = window.location;

        return (
            <div className='setting-page setting-page-general page-order'>
                <div className='menu-left'>
                    <div className='title'>{t('SETTINGS.TITLE')}</div>
                    <div className='menu-list'>
                        {SettingDefined.MENU_LEFT.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`menu-item ${selectedItem == item.action ? 'active' : ''}`}
                                    onClick={() => {
                                        this.setSelectedState(item.action);
                                    }}
                                >
                                    <i className={item.iconClass} /> <span>{item.title}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='page-max'>
                    <div className='right-content'>
                        {this.state.selected == 'GENERAL' && <PageGeneralSetting />}
                        {this.state.selected == 'STORE_POLICY' && <PageStorePolicy />}
                        {this.state.selected == 'DOMAIN' && <PageSettingDomain />}
                        {this.state.selected == 'STORE_ADDRESS' && <ModalSettingAddress />}
                        {this.state.selected == 'ACCOUNT' && (
                            <ModalSettingAccount
                                visible={this.state.isShowModalSettingAccount}
                                isLoading={isSubmitAccount}
                                isLoadingRemove={isLoadingRemoveStaff}
                            />
                        )}
                        {this.state.selected == 'PAYMENT' && (
                            <PageSettingPayment visible={this.state.isShowModalSettingPayment} onCancel={this.hideModalSettingPayment} />
                        )}
                        {this.state.selected == 'CONFIG_CHECKOUT' && <ModalSettingCheckoutConfig />}
                        {this.state.selected == 'CREDIT' && <TabPackageInfo />}
                        {this.state.selected == 'API' && <ModalSettingApi reload={() => this.props.reloadThirdParties()} />}
                        {this.state.selected == 'AUTO_ASSIGN' && (
                            <ModalSettingAssign
                                visible={this.state.isShowModalSettingAssign}
                                isLoading={isApplyAssignBasic}
                                basicRule={this.props.assignReducer.basicRule}
                                advancedRules={this.props.assignReducer.advancedRules}
                                storeConfig={this.props.assignReducer.storeConfig}
                                onCancel={this.hideModalSettingAssign}
                                onSubmit={this.submitAssign}
                                updateStoreAssignStatus={this.updateStoreAssignStatus}
                            />
                        )}
                        {this.state.selected == 'TEMPLATE_PRINT' && (
                            <ModalSettingTemplatePrint
                                visible={this.state.isShowModalSettingTemplatePrint}
                                // onCancel={this.hideModalSettingTemplatePrint}
                                onSubmit={this.updateTemplatePrint}
                                restore={this.restoreTemplatePrint}
                                templates={this.props.settingReducer.templates}
                                isLoading={isUpdateTemplatePrint}
                                isLoadingRestore={isRestoreTemplatePrint}
                            />
                        )}
                        {this.state.selected == 'TEMPLATE_EMAIL' && (
                            <ModalSettingTemplateEmail
                                visible={this.state.isShowModalSettingTemplateEmail}
                                // onCancel={this.hideModalSettingTemplateEmail}
                                onSubmit={this.updateTemplateEmail}
                                restore={this.restoreTemplateEmail}
                                templates={this.props.settingReducer.templates}
                                isLoading={isUpdateTemplateEmail}
                                isLoadingRestore={isRestoreTemplateEmail}
                            />
                        )}
                        {this.state.selected == 'IP' && <ModalSettingIP />}
                        {this.state.selected == 'WEB_HOOK' && (
                            <ModalSettingWebHook
                                visible={this.state.isShowModalSettingWebHook}
                                onCancel={this.hideModalSettingWebHook}
                                // webHooks={this.props.settingReducer.web_hooks}
                                webHookScopes={this.props.settingReducer.web_hook_scopes}
                            />
                        )}
                        {/* {this.state.selected == "TRACKING" && <ModalSettingTracking onSubmit={this.submitGeneral} />} */}
                        {this.state.selected == 'TRACKING' && <PageSettingTracking onSubmit={this.submitGeneral} />}

                        {/* {this.state.selected == "NOTIFICATION" && (
            <ModalSettingNotification
            />
          )} */}
                        {this.state.selected == 'NOTIFICATION' && <PageSettingNotification />}

                        {/* {this.state.selected == "CUSTOM_FIELD" && <ModalSettingCustomField />} */}

                        {this.state.selected == 'SHIPPING' && (
                            <ModalSettingShipping
                                partners={partners}
                                _states={this.props.addressConfig.states}
                                shippingRates={this.props.shippingRate.shipping_rates}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => dispatch(storeActions.getUserInfo()),
        // store: (storeID) => dispatch(storeActions.show(storeID)),
        reloadStore: (storeID) => dispatch(storeActions.reload(storeID)),
        updateStore: (store) => dispatch(storeActions.update(store)),
        listStaffs: () => dispatch(staffActions.list()),
        // listWebHook: () => dispatch(settingActions.listWebHook()),
        listShippingPartners: () => dispatch(shippingActions.listPartners()),
        listStoreAddress: () => dispatch(storeAddressActions.list()),
        listShippingRate: () => dispatch(shippingRateActions.list()),
        // listStates: (countryCode) => dispatch(addressConfigActions.listStates(countryCode)),
        listThirdParties: () => dispatch(apiActions.list()),
        reloadThirdParties: () => dispatch(apiActions.reload()),
        listAssignRules: () => dispatch(assignActions.list()),
        reloadAssignRules: () => dispatch(assignActions.reload()),
        applyAssignBasic: (data) => dispatch(assignActions.applyBasic(data)),
        createAssignAdvanced: (data) => dispatch(assignActions.createAdvanced(data)),
        updateStoreAssignStatus: (status) => dispatch(assignActions.updateStoreAssignStatus(status)),
        listTemplatePrint: () => dispatch(settingActions.listTemplatePrint()),
        updateTemplatePrint: (data) => dispatch(settingActions.updateTemplatePrint(data)),
        restoreTemplatePrint: (storeTemplateID) => dispatch(settingActions.restoreTemplatePrint(storeTemplateID)),
        listTemplateEmail: () => dispatch(settingActions.listTemplateEmail()),
        updateTemplateEmail: (data) => dispatch(settingActions.updateTemplateEmail(data)),
        restoreTemplateEmail: (storeTemplateID) => dispatch(settingActions.restoreTemplateEmail(storeTemplateID)),
        showTracking: () => dispatch(storeActions.showTracking()),
        listNotificationEmail: () => dispatch(settingActions.listNotificationEmail()),
        listCustomField: () => dispatch(settingActions.listCustomField()),
    };
};

const mapStateToProps = (state) => ({
    staff: { ...state.staff },
    shipping: { ...state.shipping },
    store: { ...state.store },
    storeAddress: { ...state.storeAddress },
    shippingRate: { ...state.shippingRate },
    addressConfig: { ...state.addressConfig },
    api: { ...state.api },
    payment: { ...state.payment },
    assignReducer: { ...state.assign },
    settingReducer: { ...state.setting },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Setting));
