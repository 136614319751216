import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';

import pageCheckoutDefined from '../components/pageCheckoutDefined';

import pageCheckoutActions from '../../../../redux/futures/page_checkout/actions';
import checkoutConfigAction from '../../../../redux/futures/checkout_config/actions';

import CustomerInfo from './customerInfo/CustomerInfo';
import ShippingInfo from './shippingInfo/ShippingInfo';
import PaymentMethod from './paymentMethod/PaymentMethod';
import Setting from './setting/Setting';
import Dropdown from '../../../../components/Dropdown';
import config from '../../../../config/config';
import BaseHelper from '../../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function

const CheckoutConfig = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, pageCheckout } = props;

    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);

    const customerInfotRef = useRef();
    const shippingInfoRef = useRef();
    const paymentMethodRef = useRef();
    const settingRef = useRef();

    const [menuSelected, setMenuSelected] = useState('');
    const [checkoutConfigData, setCheckoutConfigData] = useState({});
    const [checkoutConfigCustomData, setCheckoutConfigCustomData] = useState({});
    const [configCheckoutType, setConfigCheckoutType] = useState(appConfig.CHECKOUT_CONFIG_TYPE.SHARED);

    const checkoutConfigs = useMemo(() => {
        return checkoutConfigReducer.checkout_configs || [];
    }, [checkoutConfigReducer.checkout_configs]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let checkout_config = {
                // checkout_config_id: checkoutConfigData.checkout_config_id,

                type: configCheckoutType,
            };

            if (configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.CUSTOMIZE) {
                const customerConfig = customerInfotRef.current.getData();

                if (customerConfig.error) {
                    return customerConfig;
                }

                const shippingConfig = shippingInfoRef.current.getData();

                if (shippingConfig.error) {
                    return shippingConfig;
                }

                const paymentConfig = paymentMethodRef.current.getData();

                if (paymentConfig.error) {
                    return paymentConfig;
                }
                const setting = settingRef.current.getData();

                let config = {
                    config_customer: customerConfig,
                    config_shipping: shippingConfig,
                    config_payment: paymentConfig,
                    config_discount: {
                        enable_discount: setting.isShowDiscountInput || false,
                    },
                    config_shipping_method: {
                        enable_shipping_method: setting.isShowShippingMethod || false,
                    },
                    config_one_page_checkout: {
                        enable_one_page_checkout: setting.isShowOnePageCheckout || false,
                    },
                    config_language: setting.config_language,
                    isShowCoupon: setting.isShowDiscountList || false,
                    isShowInvoice: setting.isShowInvoice || false,
                    // isShowCountry: setting.isShowCountryOnShipping ||  false,
                    hidden_multi_national: setting.hidden_multi_national || false,
                };
                checkout_config.config = config;
            }

            if (configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.SHARED) {
                checkout_config.checkout_config_id = checkoutConfigData.checkout_config_id;
            } else {
                checkout_config.checkout_config_id = checkoutConfigCustomData.checkout_config_id;
            }

            return checkout_config;
        },
    }));

    useEffect(() => {
        if (queryStrings) {
            let menu = queryStrings.get('checkout-config');
            if (!menu) {
                setMenuSelected('');
            } else {
                setMenuSelected(menu);
            }
        }
    }, [queryStrings]);

    useEffect(() => {
        if (pageCheckout && pageCheckout.page_checkout_id) {
            let checkout_config = pageCheckout.checkout_config || {};
            if (checkout_config.checkout_config_id) {
                if (checkout_config.type == appConfig.CHECKOUT_CONFIG_TYPE.SHARED) setCheckoutConfigData(checkout_config);
                else {
                    setConfigCheckoutType(checkout_config.type);
                    setCheckoutConfigCustomData(checkout_config);

                    if (checkoutConfigs && checkoutConfigs.length > 0) {
                        let checkoutDefault = checkoutConfigs.find((item) => item.is_default == 1);

                        if (!checkoutDefault) checkoutDefault = checkoutConfigs[0];
                        setCheckoutConfigData(checkoutDefault);
                    }
                }
            } else {
                if (checkoutConfigs && checkoutConfigs.length > 0) {
                    let checkoutDefault = checkoutConfigs.find((item) => item.is_default == 1);

                    if (!checkoutDefault) checkoutDefault = checkoutConfigs[0];
                    setCheckoutConfigData(checkoutDefault);
                }
            }
        }
    }, [checkoutConfigs, pageCheckout]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('checkout-config');
        if (!menuSelected) {
            _queryStrings.delete('tab');
        }
        history.replace({ search: _queryStrings.toString() });
    };

    const selectMenu = (action) => {
        let _queryStrings = queryStrings;
        _queryStrings.set('checkout-config', action);

        history.replace({ search: _queryStrings.toString() });
    };

    const getUrlConfigCheckout = (checkout_config_id) => {
        if (BaseHelper.isIframe()) {
            let query_ls = {
                type: 'setting-checkout',
                checkout_config_id: checkout_config_id,
            };
            return `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_PAYMENT}&query_ls=${JSON.stringify(query_ls)}`;
        }

        return `/settings?type=setting-checkout&checkout_config_id=${checkout_config_id}&tab=CONFIG_CHECKOUT`;
    };

    return (
        <div className={`page-checkout-config-item ${isShow ? 'show' : 'hide'}`}>
            {!menuSelected && (
                <>
                    <div className='header'>
                        <div className='display-flex-center gap-16'>
                            <div className='icon-back' onClick={backMenu}>
                                <i className='ldicon-chevron-left' />
                            </div>
                            <div className='display-flex-center gap-4'>
                                <span className='ladiui title-header'>Cấu hình thanh toán</span>
                            </div>
                        </div>
                    </div>
                    <div className='ladiui checkout-config-selected-box display-flex-center gap-16'>
                        <div
                            className={`ladiui checkout-config-selected-box-item`}
                            onClick={() => {
                                setConfigCheckoutType(appConfig.CHECKOUT_CONFIG_TYPE.SHARED);
                            }}
                        >
                            <input
                                id='checkout-config-type'
                                type='radio'
                                className='ladiui form-check-input'
                                name='type'
                                onChange={() => {}}
                                checked={configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.SHARED}
                            />

                            <span
                                className={`ladiui text-14 ${
                                    configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.SHARED ? 'text-primary' : ''
                                }`}
                            >
                                Cấu hình mặc định
                            </span>
                        </div>

                        <div
                            className={`ladiui  checkout-config-selected-box-item`}
                            onClick={() => {
                                setConfigCheckoutType(appConfig.CHECKOUT_CONFIG_TYPE.CUSTOMIZE);
                            }}
                        >
                            <input
                                id='checkout-config-type'
                                type='radio'
                                className='ladiui form-check-input '
                                name='type'
                                onChange={() => {}}
                                checked={configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.CUSTOMIZE}
                            />

                            <span
                                className={`ladiui text-14 ${
                                    configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.CUSTOMIZE ? 'text-primary' : ''
                                }`}
                            >
                                Cấu hình tuỳ chỉnh
                            </span>
                        </div>
                    </div>
                    {configCheckoutType == appConfig.CHECKOUT_CONFIG_TYPE.SHARED ? (
                        <div className='checkout-config-share-select'>
                            <label className='ladiui text-14'>Chọn cấu hình</label>
                            <div className='ladiui flex-center-between gap-12 mt-4'>
                                <Dropdown
                                    data={checkoutConfigs}
                                    _key='checkout_config_id'
                                    _value='name'
                                    currentKey={checkoutConfigData.checkout_config_id}
                                    onSelectItem={(item) => {
                                        setCheckoutConfigData({
                                            ...checkoutConfigData,
                                            checkout_config_id: item.checkout_config_id,
                                        });
                                    }}
                                />

                                <a
                                    className='ladiui btn btn-md btn-secondary'
                                    target='_blank'
                                    href={getUrlConfigCheckout(checkoutConfigData.checkout_config_id)}
                                >
                                    Chỉnh sửa
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className='widget-menu ladiui border-t-50p'>
                            {pageCheckoutDefined.CHECKOUT_CONFIG_MENU.map((item, index) => {
                                return (
                                    <div key={index} className='widget-menu-item' onClick={() => selectMenu(item.action)}>
                                        <div className='display-flex-center gap-16'>
                                            <i className={` ladiui icon-new ${item.iconClass}`} />
                                            <span className='widget-menu-label'>{item.title}</span>
                                        </div>
                                        <i className='ldicon-chevron-right' />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            )}

            <CustomerInfo ref={customerInfotRef} isShow={menuSelected == 'CUSTOMER_INFO'} pageCheckout={pageCheckout} />
            <ShippingInfo ref={shippingInfoRef} isShow={menuSelected == 'SHIPPING_INFO'} pageCheckout={pageCheckout} />
            <PaymentMethod ref={paymentMethodRef} isShow={menuSelected == 'PAYMENT_METHOD'} pageCheckout={pageCheckout} />
            <Setting ref={settingRef} isShow={menuSelected == 'SETTING'} pageCheckout={pageCheckout} />
        </div>
    );
});

export default CheckoutConfig;
