import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.FILTER_LIST,
    meta: {
        endpoint: endpoint.FILTER_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const create = (data) => ({
    type: types.FILTER_CREATE,
    meta: {
        endpoint: endpoint.FILTER_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const update = (data) => ({
    type: types.FILTER_UPDATE,
    meta: {
        endpoint: endpoint.FILTER_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const deletes = (data) => ({
    type: types.FILTER_DELETE,
    meta: {
        endpoint: endpoint.FILTER_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const duplicate = (data) => ({
    type: types.FILTER_DUPLICATE,
    meta: {
        endpoint: endpoint.FILTER_DUPLICATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

export default {
    list,
    create,
    update,
    deletes,
    duplicate,
};
