import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import fileActions from '../redux/futures/file/actions';
import * as fileTypes from '../redux/futures/file/types';

import Input from './Input';
import baseHelper from '../helpers/BaseHelper';
import { isEmpty } from 'lodash';
import ListImageProduct from './ListProductImageV2';
import UploadImageCustom from './UploadImageCustom';

const CDN = 'https://w.ladicdn.com/';

const ImageSharingSeoSocialSetting = forwardRef((props, ref) => {
    let { isShowTitle } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducerDefault = useSelector((state) => state.file);

    const [descriptionPage, setDescriptionPage] = useState('');
    const [keyWordPage, setKeyWordPage] = useState('');
    const [titlePage, setTittlePage] = useState('');
    const [urlImage, setUrlImage] = useState('');
    const [urlFavicon, setUrlFavicon] = useState('');

    const inputsRef = new Set();

    const handleChangeImageUrl = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadImages(form));
            }
        }
    };

    // useEffect(() => {
    //   if (fileReducerDefault.status) {
    //     if (fileReducerDefault.url_images) {
    //       setUrlImage(fileReducerDefault.url_images[0]);
    //     }
    //     if (fileReducerDefault.favicon_url) {
    //       setUrlFavicon("https://w.ladicdn.com/" + fileReducerDefault.favicon_url[0]);
    //     }
    //   }
    // }, [fileReducerDefault]);

    useEffect(() => {
        if (fileReducerDefault.action == fileTypes.UPLOAD_IMAGES) {
            if (fileReducerDefault.status) {
                if (fileReducerDefault.url_images) {
                    setUrlImage(fileReducerDefault.url_images[0]);
                }
            }
        }

        if (fileReducerDefault.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducerDefault.status) {
                setUrlFavicon(fileReducerDefault.favicon_url);
            }
        }
    }, [fileReducerDefault]);

    useEffect(() => {
        if (props.productInfoSeo && !isEmpty(props.productInfoSeo)) {
            let { title, description, keywords, favicon, image } = props.productInfoSeo;
            setTittlePage(title);
            setDescriptionPage(description);
            setUrlImage(image);
            setKeyWordPage(keywords);
            setUrlFavicon(favicon);
        }
    }, [props.productInfoSeo]);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                title: titlePage ? titlePage : '',
                description: descriptionPage ? descriptionPage : '',
                keywords: keyWordPage ? keyWordPage : '',
                image: urlImage ? urlImage : '',
                favicon: urlFavicon ? urlFavicon : '',
            };
        },
    }));

    const handleSetListImageProducts = (urlImage) => {
        setUrlImage(urlImage);
    };

    const handleDeleteImage = (item) => {
        setUrlImage('');

    };
    
    return (
        <>
            <div className='' style={{width: 'auto', height: 'auto'}}>
                <div className='block-content-item ls-p-0 ls-shadow-none'>
                    <div className='block-list-images ls-p-0 ls-shadow-none'>
                        <UploadImageCustom
                            handleChangeImageUrl={handleChangeImageUrl}
                            listImage={urlImage != '' ? [urlImage] : []}
                            handleDeleteImage={handleDeleteImage}
                            handleSetImage={(items) => {}}
                            isEditProduct={true}
                            productID={null}
                            handleSetListImageProducts={handleSetListImageProducts}
                            imageWidth={240}
                            imageHeight={126}
                            isShowButtonUpload={false}
                            uploadId='upload-share-image'
                        />
                    </div>
                </div>
            </div>
        </>
    );
});

ImageSharingSeoSocialSetting.propTypes = {
    titlePage: PropTypes.string,
    descriptionPage: PropTypes.string,
    img_publish: PropTypes.string,
    favicon: PropTypes.string,
};

export default ImageSharingSeoSocialSetting;
