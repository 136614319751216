import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const uploadFile = (form) => ({
    type: types.UPLOAD_FILES,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadProductImages = (form, target = false) => ({
    type: types.UPLOAD_PRODUCT_IMAGES,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
    target
});

const uploadProductPreviewImages = (form) => ({
    type: types.UPLOAD_PRODUCT_REVIEW_IMAGES,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadImages = (form) => ({
    type: types.UPLOAD_IMAGES,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadTicketImage = (form) => ({
    type: types.UPLOAD_VARIANT_IMAGE,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadSwatchImage = (form) => ({
    type: types.UPLOAD_SWATCH_IMAGE,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadLogo = (form) => ({
    type: types.UPLOAD_LOGO_URL,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadFavicon = (form) => ({
    type: types.UPLOAD_FAVICON_URL,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadCover = (form) => ({
    type: types.UPLOAD_COVER_URL,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});
const uploadCheckoutImages = (form) => ({
    type: types.UPLOAD_CHECKOUT_IMAGES,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadFileDigital = (form) => ({
    type: types.UPLOAD_FILE_DIGITAL,
    meta: {
        endpoint: endpoint.UPLOAD_FILE_DIGITAL,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadFileLesson = (form) => ({
    type: types.UPLOAD_FILE_LESSON,
    meta: {
        endpoint: endpoint.UPLOAD_FILE_LESSON,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadCartImage = (form) => ({
    type: types.UPLOAD_CART_IMAGE,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadCartLogo = (form) => ({
    type: types.UPLOAD_CART_LOGO,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadFooterLogo = (form) => ({
    type: types.UPLOAD_FOOTER_LOGO,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadCartBackground = (form) => ({
    type: types.UPLOAD_CART_BACK_GROUND,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadThumbnail = (form) => ({
    type: types.UPLOAD_THUMBNAIL,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadAvatar = (form) => ({
    type: types.UPLOAD_AVATAR,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadBannerStore = (form) => ({
    type: types.UPLOAD_BANNER_STORE,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const uploadCategoryImage = (form) => ({
    type: types.UPLOAD_CATEGORY_IMAGE,
    meta: {
        endpoint: endpoint.UPLOAD_FILES,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const bunnyCreateVideo = (form) => ({
    type: types.BUNNY_CREATE_VIDEO,
    meta: {
        endpoint: endpoint.BUNNY_CREATE_VIDEO,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    },
});

const chunkVideo = (data, query) => ({
    type: types.CHUNK_VIDEO,
    meta: {
        endpoint: `${endpoint.CHUNK_VIDEO}?${query}`,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        isStream: true,
    },
});

export default {
    uploadProductImages,
    uploadTicketImage,
    uploadSwatchImage,
    uploadLogo,
    uploadFavicon,
    uploadCover,
    uploadImages,
    uploadCheckoutImages,
    uploadFile,
    uploadFileDigital,
    uploadCartImage,
    uploadCartLogo,
    uploadFooterLogo,
    uploadCartBackground,
    uploadThumbnail,
    uploadCategoryImage,
    uploadFileLesson,
    bunnyCreateVideo,
    chunkVideo,
    uploadBannerStore,
    uploadAvatar,
    uploadProductPreviewImages
};
