/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';

import settingActions from '../../../../redux/futures/setting/actions';
import * as settingTypes from '../../../../redux/futures/setting/types';

import LoadingTable from '../../../../components/LoadingTable';
import ConfirmModal from '../../../../components/ConfirmModal';
import LadiPagination from '../../../../components/LadiPagination';
import LadiDropdownMenu from '../../../../components/LadiDropdownMenu';

import Image from '../../../../components/Image';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';
import Dropdown from '../../../../components/Dropdown';

import { includes, map, remove, values, cloneDeep, compact } from 'lodash';

let CDN = 'https://w.ladicdn.com/';

export default function ProductListCustomField(props) {
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const inputsRef = new Set();

    const settingReducer = useSelector((state) => state.setting);

    const [activePage, setActivePage] = useState(1);
    const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
    const [selectedIDs, setSelectedIDs] = useState([]);

    const [valueSearch, setValueSearch] = useState('');
    const [mode, setMode] = useState(null);

    const [dataCustomField, setDataCustomField] = useState({
        name: '',
        label: '',
        data_type: '',
        group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.PRODUCT.CODE,
        data_values: '',
    });

    const [isShowModalCustomField, setIsShowModalCustomField] = useState(false);
    const [isValidationName, setIsValidationName] = useState(true);
    const [isDuplicates, setIsDuplicates] = useState(false);

    let selectedID = '';
    // let selectedIDs = [];

    // let sortBy = {
    //   page_checkout_id: "DESC",
    // };
    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const Moment = baseHelper.getMoment();

    useEffect(() => {
        let payload = {
            group_type: 'PRODUCT',
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            keyword: '',
            sort: {},
        };
        dispatch(settingActions.listByCustomField(payload));
    }, [activePage, selectedLimit]);

    useEffect(() => {
        if (settingReducer.action === settingTypes.SETTING_LIST_BY_CUSTOM_FIELD) {
            if (settingReducer.status) {
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }
        if (settingReducer.action === settingTypes.SETTING_ADD_CUSTOM_FIELD) {
            if (settingReducer.status) {
                let payload = {
                    page: 1,
                    limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
                    keyword: '',
                    group_type: 'PRODUCT',
                    sort: {},
                };
                dispatch(settingActions.listByCustomField(payload));
                setIsShowModalCustomField(false);
                window.LadiUI.toastCustom('success', '', settingReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }
        if (settingReducer.action === settingTypes.SETTING_UPDATE_CUSTOM_FIELD) {
            if (settingReducer.status) {
                let payload = {
                    page: 1,
                    limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
                    keyword: '',
                    group_type: 'PRODUCT',
                    sort: {},
                };
                dispatch(settingActions.listByCustomField(payload));
                setIsShowModalCustomField(false);
                window.LadiUI.toastCustom('success', '', settingReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }

        if (settingReducer.action === settingTypes.SETTING_DELETE_CUSTOM_FIELD) {
            if (settingReducer.status) {
                let payload = {
                    page: 1,
                    limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
                    keyword: '',
                    group_type: 'PRODUCT',
                    sort: {},
                };
                dispatch(settingActions.listByCustomField(payload));
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                window.LadiUI.closeModal('confirm-delete-custom-field');
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }
    }, [settingReducer]);

    /**
     * Tim kiem theo name
     */

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    const onChangeLimit = (option) => {
        setSelectedLimit(option.value);
        setActivePage(1);
    };

    /**
     * Change page
     */
    const onPageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/

    // const onKeyDownInputSearch = (event) => {
    //     if (event.key === "Enter") {
    //         const data = {
    //             search: {
    //                 name: valueSearch,
    //             },
    //             paged: activePage,
    //             limit: selectedLimit,
    //         };
    //         dispatch(productTagActions.list(data))
    //     }
    // };

    // ------------------------------------customfield product -----------------------------------------------------------------------------
    const openModalCreate = () => {
        setMode(appConfig.FORM_MODE.CREATE);
        setIsShowModalCustomField(true);
        setDataCustomField({
            name: '',
            label: '',
            data_type: '',
            data_values: '',
        });
    };
    const openModalEdit = (item) => {
        setDataCustomField({
            ...dataCustomField,
            custom_field_id: item.custom_field_id,
            name: item.name,
            label: item.label,
            data_values: item.data_values,
            data_type: item.data_type,
        });

        setIsShowModalCustomField(true);
        setMode(appConfig.FORM_MODE.EDIT);
    };

    const openModalConfirmDelete = (customFieldID) => {
        setDataCustomField({
            ...dataCustomField,
            custom_field_id: customFieldID,
        });
        window.LadiUI.showModal('confirm-delete-custom-field');
    };

    const onChangeInput = (event, isValidate) => {
        const { value } = event.target;
        if (isValidate) {
            const dataValidates = ['name', 'email', 'phone', 'address', 'country', 'state', 'district', 'ward', 'message', 'coupon'];
            const expression = /[^A-Za-z0-9-_]/;
            const regex = new RegExp(expression);

            // Bạn chỉ có thể sử dụng 'a-z,A-Z, 0-9, -_
            if (value.match(regex)) {
                setIsValidationName(false);
            } else if (dataValidates.includes(value) && dataCustomField.group_type == appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE) {
                setIsDuplicates(true);
                setIsValidationName(true);
            } else {
                setDataCustomField({
                    ...dataCustomField,
                    [event.target.name]: event.target.value,
                });
                setIsValidationName(true);
                setIsDuplicates(false);
            }
        }
        setDataCustomField({
            ...dataCustomField,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCusTomFieldDataTypes = (item) => {
        setDataCustomField({
            ...dataCustomField,
            data_type: item.CODE,
        });
    };

    const submit = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        let element = document.getElementById('show-message-error');

        const data = {
            name: dataCustomField.name,
            label: dataCustomField.label,
            group_type: 'PRODUCT',
            data_type: dataCustomField.data_type,
        };

        if (dataCustomField.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE) {
            data.data_values = dataCustomField.data_values;
        }

        if (mode == appConfig.FORM_MODE.CREATE) {
            dispatch(settingActions.addCustomField(data));
        } else {
            data.custom_field_id = dataCustomField.custom_field_id;
            dispatch(settingActions.updateCustomField(data));
        }
    };

    const deleteCustomField = () => {
        const customField = {
            custom_field_id: dataCustomField.custom_field_id,
        };
        dispatch(settingActions.deleteCustomField(customField));
    };

    const onKeyDownInputSearchCustomField = () => {
        let payload = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            keyword: valueSearch,
            group_type: 'PRODUCT',
            sort: {},
        };
        dispatch(settingActions.listByCustomField(payload));
    };

    const { totalRecord, totalPage } = settingReducer;

    let fromItem = 0;
    let toItem = 0;
    if ((settingReducer.reviews || []).length > 0) {
        fromItem = (activePage - 1) * selectedLimit + 1;
        toItem = fromItem + settingReducer.reviews.length - 1;
    }

    const isLoadingData = false;
    const isLoadingInfo = false;
    const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
        'PAGINATION.OF'
    )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

    const isLoadingProductView = settingReducer.loading && includes([settingTypes.SETTING_LIST_BY_CUSTOM_FIELD], settingReducer.waiting);

    const isLoadingDelete = settingReducer.loading && includes([settingTypes.SETTING_DELETE_CUSTOM_FIELD], settingReducer.waiting);

    const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

    return (
        <div id='panel-product-list-custom-field' className='page-content'>
            <div>
                {isLoadingData ? (
                    <LoadingTable />
                ) : (
                    <div>
                        <div className='header-page'>
                            <div className='heading'>
                                <h3>Trường tùy chỉnh</h3>
                                <p>Thêm các trường tùy chỉnh cho sản phẩm.</p>
                            </div>
                            <div className='action-btn'>
                                <button className='ladiui btn btn-primary flex' onClick={openModalCreate}>
                                    <img src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg' className='mr-8' />
                                    <span>Tạo trường tùy chỉnh</span>
                                </button>
                            </div>
                        </div>
                        <div className='block-filter'>
                            <div className='ladiui search-group'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width height-40'
                                    name='searchName'
                                    placeholder={t('Tìm kiếm trường tùy chỉnh')}
                                    aria-expanded='false'
                                    value={valueSearch}
                                    onChange={(event) => {
                                        setValueSearch(event.target.value);
                                    }}
                                    onKeyDown={onKeyDownInputSearchCustomField}
                                />
                                <i className='ladiui icon icon-search'></i>
                            </div>
                        </div>
                        <div className='block-content'>
                            {totalRecord <= 0 ? (
                                <div className='block-no-data'>
                                    <div>
                                        <img src='https://w.ladicdn.com/ladiui/ladipage/icon-not-foder.svg' />
                                        <p>Chưa có trường tùy chỉnh nào được tạo</p>
                                    </div>
                                </div>
                            ) : (
                                <div className='ladi-card ladiui-table-responsive'>
                                    <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''}`}>
                                        <thead style={{ position: 'relative' }}>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col' className={`text-left`}>
                                                    Tên hiển thị
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Tên trường
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Kiểu dữ liệu
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Ngày tạo
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Ngày cập nhật
                                                </th>

                                                <th scope='col' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(settingReducer.customFields, (item, index) => {
                                                return (
                                                    <tr key={index} className='ladiui table-vertical main'>
                                                        <td scope='row'>{item.label}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.CODE
                                                                ? appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.NAME
                                                                : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.CODE
                                                                ? appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.NAME
                                                                : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.CODE
                                                                ? appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.NAME
                                                                : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.CODE
                                                                ? appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.NAME
                                                                : appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.NAME}
                                                        </td>
                                                        <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                                        <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                                                        <td className='text-right pd-0'>
                                                            <div className='ladiui btn-group'>
                                                                <div className='ladiui dropdown hide-mt ba-c'>
                                                                    <button
                                                                        data-toggle='dropdown'
                                                                        className='ladiui-btn-dropdown dropdown-toggle'
                                                                    >
                                                                        <i className='ladiui icon icon-ldp-dot'></i>
                                                                    </button>
                                                                    <ul className='ladiui dropdown-menu r-0'>
                                                                        <li>
                                                                            <a
                                                                                onClick={() => openModalEdit(item)}
                                                                                className='ladiui dropdown-item'
                                                                            >
                                                                                <i className='new-ldicon-edit' />
                                                                                <span> {t('ACTIONS.EDIT')} </span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                onClick={() => openModalConfirmDelete(item.custom_field_id)}
                                                                                className='ladiui dropdown-item'
                                                                            >
                                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                                <span className='ladiui text-danger'>
                                                                                    {t('ACTIONS.DELETE')}
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {totalRecord <= 0 && (
                                                <tr className='text-center'>
                                                    <td colSpan='100%'>{t('NO_DATA')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={{ page: activePage, limit: selectedLimit }}
                                    listName={t('PAGINATION.ITEMS')}
                                    message={''}
                                    items={settingReducer.customFields || []}
                                    total={totalRecord}
                                    limitAction={onChangeLimit}
                                    pageAction={onPageChange}
                                />
                            )}
                        </div>
                    </div>
                )}

                {isShowModalCustomField && (
                    <Modal
                        id='modal-custom-field'
                        title={
                            mode == appConfig.FORM_MODE.CREATE ? t('CUSTOM_FIELD.CREATE_CUSTOM_FIELD') : t('CUSTOM_FIELD.EDIT_CUSTOM_FIELD')
                        }
                        visible={isShowModalCustomField}
                        onCancel={() => setIsShowModalCustomField(false)}
                        onOk={submit}
                        bodyClassName='position-initial'
                        width={450}
                        hasFooter={isValidationName && !isDuplicates}
                    >
                        <div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.LABEL')}</label>
                                <Input
                                    name='label'
                                    validationName={t('CUSTOM_FIELD.LABEL')}
                                    placeholder={t('CUSTOM_FIELD.LABEL')}
                                    value={dataCustomField.label}
                                    onChange={onChangeInput}
                                    ref={(ref) => inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.NAME')}</label>
                                <Input
                                    id='show-message-error'
                                    name='name'
                                    validationName={t('CUSTOM_FIELD.ENTER_NAME')}
                                    placeholder={t('CUSTOM_FIELD.ENTER_NAME')}
                                    value={dataCustomField.name}
                                    onChange={(e, isValidate) => onChangeInput(e, (isValidate = true))}
                                    ref={(ref) => inputsRef.add(ref)}
                                    validations={{ isRequired: true }}
                                />
                                {!isValidationName && <span className='show-error-text'>{t('CUSTOM_FIELD.VALIDATION_NAME')}</span>}
                                {isDuplicates && <span className='show-error-text'>{t('CUSTOM_FIELD.DUPLICATE_NAME')}</span>}
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>{t('CUSTOM_FIELD.DATA_TYPES')}</label>
                                <Dropdown
                                    data={appConfig.CUSTOM_FIELD.DATA_TYPES}
                                    onSelectItem={(item) => handleChangeCusTomFieldDataTypes(item)}
                                    currentKey={dataCustomField.data_type}
                                    _key='CODE'
                                    _value='NAME'
                                    ref={(ref) => inputsRef.add(ref)}
                                    placeHolder={t('CUSTOM_FIELD.DATA_TYPES')}
                                    validations={{ isRequired: true }}
                                    validationName={t('CUSTOM_FIELD.DATA_TYPES')}
                                    wrapperClassName='w100 position-initial'
                                    idBlock='dropdown-customer-field'
                                />
                            </div>

                            {dataCustomField.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE && (
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('CUSTOM_FIELD.VALUE')}</label>
                                    <textarea
                                        ref={(ref) => inputsRef.add(ref)}
                                        name='data_values'
                                        className='ladiui textarea'
                                        value={dataCustomField.data_values}
                                        onChange={onChangeInput}
                                        placeholder={t('CUSTOM_FIELD.HELP_VALUE_DROPDOWN')}
                                        validations={{ isRequired: true }}
                                        validationName={t('CUSTOM_FIELD.ENTER_DROPDOWN_VALUE')}
                                    />
                                </div>
                            )}
                        </div>
                    </Modal>
                )}

                <ConfirmModal
                    id='confirm-delete-custom-field'
                    title={t('SETTINGS.MSG_DELETE_CUSTOM_FIELD')}
                    content={t('SETTINGS.MSG_DELETE_CUSTOM_FIELD_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={deleteCustomField}
                    isLoading={isLoadingDelete}
                    maxWidth={450}
                />
            </div>
        </div>
    );
}
