import React from "react";
import PropTypes from "prop-types";
import { map, find, includes, forEach, join } from "lodash";
import i18n from "../../../i18n";
import Tag from "../../../components/Tag";
import Input from "../../../components/Input";
import baseHelper from "../../../helpers/BaseHelper";

const TYPE = {
  radio: "radio",
  checkbox: "checkbox",
  input: "input",
  tag: "tag",
};

// type Props = {};

// class ItemFilter extends React.Component<Props> {
class ItemFilter extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.any,
    selectedRadio: PropTypes.any,
    onChangeRadio: PropTypes.func,
    selectedCheckbox: PropTypes.array,
    onChangeCheckbox: PropTypes.func,
    inputText: PropTypes.string,
    inputName: PropTypes.string,
    onChangeInputText: PropTypes.func,
    showClear: PropTypes.bool,
    _key: PropTypes.string,
    _value: PropTypes.string,
    selectedTags: PropTypes.array,
    clearTag: PropTypes.func,
    searchTagRef: PropTypes.any,
  };

  static defaultProps = {
    _key: "CODE",
    _value: "NAME",
    showClear: true,
    type: TYPE.radio,
  };

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };
  }

  onChangeCollapse = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
  };

  clear = () => {
    const { type } = this.props;

    switch (type) {
      case TYPE.radio:
        this.props.onChangeRadio(null);
        break;
      case TYPE.checkbox:
        this.props.onChangeCheckbox(null);
        break;
      case TYPE.tag:
        this.props.clearTag();
        break;
      case TYPE.input:
        this.props.onChangeInputText({
          target: {
            name: this.props.inputName,
            value: "",
          },
        });
        break;
    }
  };

  /*************************RADIO*****************************/
  getRadioContent = () => {
    const { data, selectedRadio, _key, _value, id } = this.props;
    return (
      <ul className="mt-6">
        {map(data, (item, index) => {
          return (
            <li key={index}>
              <input
                id={`${id}_${item[_key]}`}
                type="radio"
                value={item[_key]}
                onChange={() => {
                  this.props.onChangeRadio(item);
                }}
                checked={item[_key] == selectedRadio}
              />
              <label htmlFor={`${id}_${item[_key]}`}>{item[_value]}</label>
            </li>
          );
        })}
      </ul>
    );
  };

  getShortRadioContent = () => {
    const { data, selectedRadio, _key, _value } = this.props;
    let found = find(data, (item) => item[_key] == selectedRadio);
    if (!found) {
      return null;
    }

    return <span className="short-content">{found[_value]}</span>;
  };
  /*************************RADIO*****************************/

  /*************************CHECKBOX*****************************/
  getCheckboxContent = () => {
    const { data, selectedCheckbox, _key, _value } = this.props;
    return (
      <ul className="mt-6">
        {map(data, (item, index) => {
          return (
            <li key={index}>
              <input
                id={item[_key]}
                onChange={() => {
                  this.props.onChangeCheckbox(item);
                }}
                checked={includes(selectedCheckbox, item[_key])}
                type="checkbox"
                className="ladiui checkbox size-checkbox form-check-input-checkbox"
              />
              <label> {item[_value]}</label>
            </li>
          );
        })}
      </ul>
    );
  };

  getShortCheckboxContent = () => {
    const { data, selectedCheckbox, _key, _value } = this.props;
    let names = [];
    forEach(selectedCheckbox, (item) => {
      const found = find(data, (_item) => _item[_key] == item);
      if (found) {
        names.push(found[_value]);
      }
    });

    if (names.length <= 0) {
      return null;
    }

    return (
      <span>
        {map(names, (item, index) => {
          return (
            <span key={index} className="short-content">
              {item}
            </span>
          );
        })}
      </span>
    );
  };
  /*************************CHECKBOX*****************************/

  /*************************TAG*****************************/
  getTagContent = () => {
    const { data, selectedTags, searchTagRef } = this.props;
    return <Tag ref={searchTagRef} id="search-tag" allTags={data} selectedTags={selectedTags} hideTitle={true} className="mt-6" />;
  };

  getShortTagContent = () => {
    if (!this.props.searchTagRef.current) {
      return null;
    }
    const selectedTags = this.props.searchTagRef.current.getData();

    if (selectedTags.length <= 0) {
      return null;
    }

    const result = join(selectedTags, ", ");

    return <span className="short-content">{result}</span>;
  };
  /*************************TAG*****************************/

  /*************************INPUT*****************************/
  getInputContent = () => {
    const { inputText, inputName } = this.props;
    return <Input className="mt-6" value={inputText} name={inputName} onChange={this.props.onChangeInputText} />;
  };

  getShortInputContent = () => {
    const { inputText } = this.props;
    if (baseHelper.isEmpty(inputText)) {
      return null;
    }

    return <span className="short-content">{inputText}</span>;
  };
  /*************************INPUT*****************************/

  getFullContent = () => {
    const { type } = this.props;

    switch (type) {
      case TYPE.radio:
        return this.getRadioContent();
      case TYPE.checkbox:
        return this.getCheckboxContent();
      case TYPE.tag:
        return this.getTagContent();
      case TYPE.input:
        return this.getInputContent();
    }
  };

  getShortContent = () => {
    const { type } = this.props;

    switch (type) {
      case TYPE.radio:
        return this.getShortRadioContent();
      case TYPE.checkbox:
        return this.getShortCheckboxContent();
      case TYPE.tag:
        return this.getShortTagContent();
      case TYPE.input:
        return this.getShortInputContent();
    }
  };

  render() {
    const { title, type } = this.props;
    const { collapse } = this.state;

    return (
      <div className={`item ${collapse ? "collapse" : ""}`}>
        <div onClick={this.onChangeCollapse} className="title" tabIndex={0}>
          {collapse ? (
            <button className="arrow">
              <i className="ladi-icon icon-down-arrow" />
            </button>
          ) : (
            <button className="arrow">
              <i className="ladi-icon icon-up-arrow" />
            </button>
          )}
          <label className="ladiui-label">{title}</label>
          {!collapse && this.getShortContent()}
        </div>
        <div className={`content ${collapse ? "full" : ""} ${type == TYPE.tag ? "tag" : "without-tag"}`}>
          {this.getFullContent()}
          {
            <span onClick={this.clear} className="clear">
              {i18n.t("ACTIONS.CLEAR")}
            </span>
          }
        </div>
      </div>
    );
  }
}

export default ItemFilter;
