import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (shippingID, paged) => ({
    type: types.LIST_SHIPPING_HISTORY,
    meta: {
        endpoint: endpoint.LIST_SHIPPING_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_id: shippingID,
            paged
        },
        hasAuth: true,
    }
});

const deleteHistory = (shippingID, shippingHistoryID) => ({
    type: types.DELETE_SHIPPING_HISTORY,
    meta: {
        endpoint: endpoint.DELETE_SHIPPING_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_history: {
                shipping_id: shippingID,
                shipping_history_id: shippingHistoryID
            }
        },
        hasAuth: true,
    }
});

const create = (shippingID, content) => ({
    type: types.CREATE_SHIPPING_HISTORY,
    meta: {
        endpoint: endpoint.CREATE_SHIPPING_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_history: {
                shipping_id: shippingID,
                content,
            }
        },
        hasAuth: true,
    }
});

const update = (shippingID, shippingHistoryID, content) => ({
    type: types.UPDATE_SHIPPING_HISTORY,
    meta: {
        endpoint: endpoint.UPDATE_SHIPPING_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_history: {
                shipping_id: shippingID,
                shipping_history_id: shippingHistoryID,
                content,
            }
        },
        hasAuth: true,
    }
});

const wsNew = (histories) => ({
    type: types.WS_NEW_SHIPPING_HISTORY,
    payload: {
        histories,
    },
});

const wsUpdated = (histories) => ({
    type: types.WS_UPDATED_SHIPPING_HISTORY,
    payload: {
        histories,
    },
});

const wsDeleted = (histories) => ({
    type: types.WS_DELETED_SHIPPING_HISTORY,
    payload: {
        histories,
    },
});

export default {
    list,
    delete: deleteHistory,
    create,
    update,
    wsNew,
    wsUpdated,
    wsDeleted
}