import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (customerID, paged) => ({
    type: types.LIST_CUSTOMER_HISTORY,
    meta: {
        endpoint: endpoint.LIST_CUSTOMER_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            customer_id: customerID,
            paged
        },
        hasAuth: true,
    }
});

const deleteHistory = (customerID, customerHistoryID) => ({
    type: types.DELETE_CUSTOMER_HISTORY,
    meta: {
        endpoint: endpoint.DELETE_CUSTOMER_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            customer_history: {
                customer_id: customerID,
                customer_history_id: customerHistoryID
            }
        },
        hasAuth: true,
    }
});

const create = (customerID, content) => ({
    type: types.CREATE_CUSTOMER_HISTORY,
    meta: {
        endpoint: endpoint.CREATE_CUSTOMER_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            customer_history: {
                customer_id: customerID,
                content,
            }
        },
        hasAuth: true,
    }
});

const update = (customerID, customerHistoryID, content) => ({
    type: types.UPDATE_CUSTOMER_HISTORY,
    meta: {
        endpoint: endpoint.UPDATE_CUSTOMER_HISTORY,
        method: REQUEST_METHOD.POST,
        body: {
            customer_history: {
                customer_id: customerID,
                customer_history_id: customerHistoryID,
                content,
            }
        },
        hasAuth: true,
    }
});

const wsNew = (histories) => ({
    type: types.WS_NEW_CUSTOMER_HISTORY,
    payload: {
        histories,
    },
});

const wsUpdated = (histories) => ({
    type: types.WS_UPDATED_CUSTOMER_HISTORY,
    payload: {
        histories,
    },
});

const wsDeleted = (histories) => ({
    type: types.WS_DELETED_CUSTOMER_HISTORY,
    payload: {
        histories,
    },
});

export default {
    list,
    delete: deleteHistory,
    create,
    update,
    wsNew,
    wsUpdated,
    wsDeleted
}