import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, debounce, find, remove, map } from 'lodash';

import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import Image from '../../../components/Image';
import baseHelper from '../../../helpers/BaseHelper';

import { withTranslation } from 'react-i18next';

import variantActions from '../../../redux/futures/product_variant/actions';
import * as variantTypes from '../../../redux/futures/product_variant/types';

import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import produce from 'immer';

import ModalAddProduct from '../../orders/OrderCreate/components/ModalAddProduct';
import appConfig from '../../../config/app';
import Input from '../../../components/Input';

class AttachedProduct extends React.Component {
    static propTypes = {
        parentID: PropTypes.any,
        selectedVariants: PropTypes.array,
        productType: PropTypes.string,
        displayProductType: PropTypes.any,
    };

    static defaultPropTypes = {
        selectedVariants: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedVariants: produce(props.selectedVariants || [], (draft) => {}),
            searchVariant: '',
            isShowModalAddProduct: false,
        };

        this.firstFocusVariant = true;
        this.searchVariant = debounce(this.searchVariant, 500);
    }

    componentDidMount() {
        this.searchVariant();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.selectedVariants != nextProps.selectedVariants) {
            this.setState({
                selectedVariants: produce(nextProps.selectedVariants || [], (draft) => {}),
            });
        }
    }

    getData() {
        return map(this.state.selectedVariants, (item) => {
            return {
                product_id: item.product_id,
                product_variant_id: item.product_variant_id,
                position: item.position,
                quantity: item.quantity,
            };
        });
    }

    /**
     * Tim kiem theo name
     */

    searchVariant = () => {
        const ignoreProductIDs = this.props.parentID ? [this.props.parentID] : null;
        this.props.searchVariant({
            search: this.state.searchVariant,
            ignore_product_ids: ignoreProductIDs,
            product_type: this.props.productType,
            search_type: 'UpSell',
        });
    };

    /**
     * @description:
     * Vi code JS doi value cua displaySearchVariant nhung state trong React khong doi
     * =>Khong render lai ma phai thay doi state cua displaySearchVariant de React hieu duoc
     */
    selectVariant = (dataVariant) => {
        let variant = { ...dataVariant };
        variant.quantity = 1;
        variant.position = 0;

        let { selectedVariants } = this.state;

        let { displayProductType } = this.props;

        if (find(selectedVariants, (item) => item.product_variant_id == variant.product_variant_id)) {
            return;
        }

        const newSelectedVariants = produce(selectedVariants, (draft) => {
            Object.values(draft).forEach((item, index) => {
                item.position = index + 1;
                if (displayProductType !== appConfig.PRODUCT_TYPE.COMBO.CODE) {
                    item.quantity = 1;
                }
            });

            draft.splice(0, 0, variant);
        });

        this.setState({
            selectedVariants: newSelectedVariants,
        });
    };

    removeVariant = (index) => {
        const { selectedVariants } = this.state;

        const newSelectedVariants = produce(selectedVariants, (draft) => {
            draft.splice(index, 1);

            Object.values(draft).forEach((item, index) => {
                item.position = index;
            });
        });
        this.setState({
            selectedVariants: newSelectedVariants,
        });
    };

    onChangeSearchVariantInput = (event) => {
        this.setState(
            {
                searchVariant: event.target.value,
            },
            () => this.searchVariant()
        );
    };

    //   onFocusVariantInput = (event) => {
    //     if (this.firstFocusVariant) {
    //       this.searchVariant();
    //     }

    //     this.firstFocusVariant = false;
    //   };

    changeQuantityVariant = (value, index) => {
        let { selectedVariants } = this.state;

        const newVariants = produce(selectedVariants, (draft) => {
            if (draft[index].quantity + value <= 1) {
                draft[index].quantity = 1;
            } else {
                draft[index].quantity += value;
            }
        });
        this.setState({
            selectedVariants: newVariants,
        });
    };

    onDragEnd = (result) => {
        const { selectedVariants } = this.state;
        // dropped outside the list
        if (!result.destination || result.source.index == result.destination.index) {
            return;
        }

        const newVariants = produce(selectedVariants, (draft) => {
            const [removed] = draft.splice(result.source.index, 1);
            draft.splice(result.destination.index, 0, removed);

            Object.values(draft).forEach((item, index) => {
                item.position = index;
            });
        });

        this.setState({
            selectedVariants: newVariants,
        });
    };

    handleShowModalAddProduct = (status) => {
        this.setState({
            isShowModalAddProduct: status,
        });
    };

    render() {
        const { t } = this.props;
        const { searchVariant, selectedVariants } = this.state;
        const { variants: foundVariants = [] } = this.props.variantReducer;
        const isLoadingSearchVariant =
            this.props.variantReducer.loading && this.props.variantReducer.waiting == variantTypes.SEARCH_VARIANT;

        return (
            <div>
                <div style={{ maxWidth: '40%' }}>
                    {selectedVariants.length > 0 && (
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId='droppable'>
                                {(provided, snapshot) => (
                                    <table className='ladiui table text-left table-custom' ref={provided.innerRef}>
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th className='w-50' />
                                                <th className='text-left'>{t('PRODUCTS.NAME')}</th>
                                                <th className='text-right'>{t('PRODUCTS.PRICE')}</th>
                                                {this.props.displayProductType &&
                                                    this.props.displayProductType == appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE && (
                                                        <th className='text-left' width={100}>
                                                            {t('PRODUCTS.QTY')}
                                                        </th>
                                                    )}
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(selectedVariants, (item, index) => (
                                                <Draggable
                                                    key={item.product_variant_id || index}
                                                    draggableId={'' + (item.product_variant_id || index)}
                                                    index={index}
                                                >
                                                    {(provided, snapshot) => (
                                                        <tr
                                                            className='ladiui table-vertical'
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                userSelect: 'none',
                                                                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                                display: 'table-row',
                                                            }}
                                                        >
                                                            <td width={50}>
                                                                <img
                                                                    src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                    alt=''
                                                                />
                                                            </td>
                                                            <td>
                                                                <div className='flex'>
                                                                    <Image src={item.src} width={40} height={40} className='mr-16' />
                                                                    <a target='blank' href={baseHelper.getUrlVariant(item)}>
                                                                        {item.name}
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className='text-right' style={{ whiteSpace: 'nowrap' }}>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    item.price,
                                                                    this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </td>
                                                            {this.props.displayProductType &&
                                                                this.props.displayProductType ==
                                                                    appConfig.PRODUCT_TYPE_CONFIG.COMBO.CODE && (
                                                                    <td className='text-left'>
                                                                        {/* <input
                                      type="number"
                                      className="ladiui input"
                                      name="quantity_product"
                                      value={item.quantity}
                                      onChange={(event) => {
                                        this.onChangeQuantityProduct(
                                          event,
                                          index
                                        );
                                      }}
                                    /> */}
                                                                        <div className='w-120 item-variant-price'>
                                                                            <button
                                                                                className='ladiui btn btn-sm'
                                                                                onClick={() => this.changeQuantityVariant(-1, index)}
                                                                            >
                                                                                -
                                                                            </button>
                                                                            <Input
                                                                                id={`quantity-${item.variant_id}`}
                                                                                type='number'
                                                                                className='item-variant-price-input'
                                                                                min={1}
                                                                                value={item.quantity}
                                                                                disabled={true}
                                                                                style={{ textAlign: 'center' }}
                                                                            />
                                                                            <button
                                                                                className='ladiui btn btn-sm'
                                                                                onClick={() => this.changeQuantityVariant(1, index)}
                                                                            >
                                                                                +
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            <td className='text-right'>
                                                                <a onClick={() => this.removeVariant(index)} style={{ color: '#6d6d6d' }}>
                                                                    <i className='ladi-icon icon-bin' />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </tbody>
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                </div>

                <div className='cursor-pointer'>
                    <a className='flex-center-left cursor-pointer my-24 pd-0' onClick={() => this.handleShowModalAddProduct(true)}>
                        <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                        <div> {t('PRODUCTS.ADD_NEW')}</div>
                    </a>
                </div>

                {this.state.isShowModalAddProduct && (
                    <ModalAddProduct
                        onClose={this.handleShowModalAddProduct}
                        isShow={this.state.isShowModalAddProduct}
                        listVariant={foundVariants}
                        isSelectedTypeProduct={false}
                        innerStyle={{ display: 'none' }}
                        selectVariant={this.selectVariant}
                        selectedProducts={selectedVariants || []}
                        onChangeSearchVariantInput={this.onChangeSearchVariantInput}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchVariant: (name, ignoreProductIDs, productType) => dispatch(variantActions.search(name, ignoreProductIDs, productType)),
    };
};

const mapStateToProps = (state) => ({
    variantReducer: { ...state.variant },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(AttachedProduct));
