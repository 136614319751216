import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { withTranslation } from "react-i18next";
import appConfig from "../config/app";
import { t } from "i18next"

function ModalImportProduct(props) {
    const { isLoadingImportData, onCancel, visible } = props;

    const [productType, setProductType] = useState('');

    const handleImportData = () => {
        if (productType) {
            props.onImportData(productType);
        } else {
            window.LadiUI.toastCustom('danger', '', ("Vui lòng chọn loại dữ liệu để thực hiện hành động !"));
        }
    }

    const onChangeProductType = (e) => {
        setProductType(e.target.value);
    }
    return (
        <Modal
            id="modal-import-data-by-type"
            title={t("Nhập danh sách sản phẩm")}
            visible={visible}
            onCancel={onCancel}
            width={600}
            footer={
                <div className="ladiui-modal-footer">
                    <button onClick={onCancel} className="ladiui btn btn-secondary">
                        {t("ACTIONS.CANCEL")}
                    </button>
                    <button
                        onClick={handleImportData}
                        className={`ladiui btn btn-primary ${isLoadingImportData ? "loader" : ""}`}
                        disabled={isLoadingImportData}
                    >
                        {" "}
                        Nhập dữ liệu
                    </button>
                </div>
            }
        >
            <div className="block-content">
                <div className="content-item content-left">
                    <h3>Loại dữ liệu</h3>
                    <div className="block-list">
                        <div className="item">
                            <input
                                id="product"
                                type="radio"
                                name="radio-group"
                                className="ladiui form-check-input"
                                value={appConfig.PRODUCT_TYPE.PHYSICAL.CODE}
                                onChange={(e) => onChangeProductType(e)}
                                checked={productType == appConfig.PRODUCT_TYPE.PHYSICAL.CODE}
                            />
                            <label htmlFor="product">{'Danh sách sản phẩm'}</label>
                        </div>
                        <div className="item">
                            <input
                                id="event"
                                type="radio"
                                name="radio-group"
                                className="ladiui form-check-input"
                                value={appConfig.PRODUCT_TYPE.EVENT.CODE}
                                onChange={onChangeProductType}
                                checked={productType == appConfig.PRODUCT_TYPE.EVENT.CODE}
                            />
                            <label htmlFor="event">{'Danh sách sự kiện'}</label>
                        </div>
                        <div className="item">
                            <input
                                id="service"
                                type="radio"
                                name="radio-group"
                                className="ladiui form-check-input"
                                value={appConfig.PRODUCT_TYPE.SERVICE.CODE}
                                onChange={onChangeProductType}
                                checked={productType == appConfig.PRODUCT_TYPE.SERVICE.CODE}
                            />
                            <label htmlFor="service">{'Danh sách dịch vụ'}</label>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="item">
          <input
            id="onPage"
            type="radio"
            name="radio-group"
            className="ladiui form-check-input"
            value={appConfig.EXPORT_EVENT.ON_PAGE}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_EVENT.ON_PAGE}
          />
          <label htmlFor="onPage">{t("PRODUCT_EVENTS.EXPORT_ON_PAGE")}</label>
        </div> */}
        </Modal>
    )
}
export default withTranslation()(ModalImportProduct);