import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

// import settingActions from '../redux/futures/setting/actions';
// import * as settingTypes from '../redux/futures/setting/types';

import * as productTypes from '../../../../redux/futures/product/types';
import productActions from '../../../../redux/futures/product/actions';

import * as productCaregoryTypes from '../../../../redux/futures/product_category/types';
import productCaregoryActions from '../../../../redux/futures/product_category/actions';

import appConfig from '../../../../config/app';
import BaseHelper from '../../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function ModalAddProduct(props) {
    const { onClose, isShow, innerStyle, productId, handleAddProduct } = props;
    const { t } = useTranslation();
    const wrapperRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const productReducer = useSelector((state) => state.product);
    const productCategoryReducer = useSelector((state) => state.productCategory);
    const storeReducer = useSelector((state) => state.store);
    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const pageCheckout = useMemo(() => {
        return pageCheckoutReducer.page_checkout || {};
    }, [pageCheckoutReducer.page_checkout]);

    const [numProduct, setNumProduct] = useState(0);

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [products, setProduct] = useState([]);
    const [category, setCategory] = useState([]);

    const [type, setType] = useState('PRODUCT');

    const [productSelected, setProductSelected] = useState([]);
    const [categorySelected, setCategorySelected] = useState([]);

    const [valueSearch, setValueSearch] = useState('');
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (productReducer.action == productTypes.SEARCH_PRODUCT_V2) {
            if (productReducer.status) {
                let products = productReducer.products || [];

                setProduct(products);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), productReducer.message, 'OK');
            }
        }
    }, [productReducer.action]);

    useEffect(() => {
        if (productCategoryReducer.action == productCaregoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                let listSelects = productCategoryReducer.listSelects || [];

                setCategory(listSelects);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer.action]);

    useEffect(() => {
        let getData = setTimeout(() => {
            searchData();
        }, 800);
        return () => clearTimeout(getData);
    }, [type, valueSearch]);

    useEffect(() => {}, [productReducer]);

    const eventCancel = () => {
        onClose(false);
    };

    const searchData = () => {
        let payload = {};

        if (type == 'PRODUCT') {
            payload = {
                paged: page,
                search: valueSearch,
                selected_product_ids: [],
            };
            let tab = queryStrings.get('tab');

            if (tab == 'WIDGET_CONFIG' && pageCheckout && pageCheckout.page_checkout_id) {
                payload.page_checkout_id = pageCheckout.page_checkout_id;
            }
            dispatch(productActions.searchV2(payload));
        } else {
            payload = {
                search: {
                    type: 'Product',
                    key_word: valueSearch,
                },
            };

            dispatch(productCaregoryActions.listSelectCategory(payload));
        }
    };

    const onKeyDownInputSearch = (event) => {
        if (event.key == 'Enter') {
            searchData();
        }
    };

    const selectProduct = (item) => {
        let productSelecTg = [...productSelected];
        let index = productSelecTg.findIndex((_item) => _item.product_id == item.product_id);
        if (index == -1) {
            productSelecTg.push(item);
        } else {
            productSelecTg.splice(index, 1);
        }
        setNumProduct(productSelecTg.length);
        setProductSelected(productSelecTg);
    };

    const selectCategory = (item) => {
        let dataTg = [...categorySelected];
        let index = dataTg.findIndex((_item) => _item.product_category_id == item.product_category_id);

        if (index == -1) {
            dataTg.push(item);
        } else {
            dataTg.splice(index, 1);
        }
        let productTg = [];

        for (let data of dataTg) {
            let dataTg = data.products || [];
            productTg = [...productTg, ...dataTg];
        }

        productTg = productTg.filter((item, index) => productTg.findIndex((_item) => _item.product_id == item.product_id) === index);
        setNumProduct(productTg.length);
        setCategorySelected(dataTg);
    };

    const addProduct = () => {
        if (type == 'PRODUCT') handleAddProduct(productSelected);
        else {
            let productTg = [];
            let dataTg = [...categorySelected];
            for (let data of dataTg) {
                let dataTg = data.products || [];
                productTg = [...productTg, ...dataTg];
            }

            productTg = productTg.filter((item, index) => productTg.findIndex((_item) => _item.product_id == item.product_id) === index);
            handleAddProduct(productTg);
        }
        onClose(false);
    };

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-customfield-checkout-config'>
            <div className='ladiui modal-dialog modal-dialog-centered w-800' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <div>
                            <div className='ladiui label-20 '>Thêm sản phẩm</div>
                            <span className='mt-4 ladiui text-14'>Thêm sản phẩm vào trang thanh toán của bạn</span>
                        </div>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>

                    <div className='ladiui modal-body ' style={{ position: 'initial' }}>
                        <div className='ladiui form-group'>
                            <div className='custom-field-tyle-list'>
                                <div
                                    className={`custom-field-tyle-item ${type == 'PRODUCT' ? 'active' : ''}`}
                                    onClick={() => {
                                        setType('PRODUCT');
                                        setNumProduct(0);
                                        setProductSelected([]);
                                        setCategorySelected([]);
                                        setValueSearch('');
                                    }}
                                >
                                    <div className={`ladiui checked-radio-v2 ${type == 'PRODUCT' ? 'selected' : ''}`}>
                                        <div className={`background`} />
                                    </div>
                                    <span>Chọn sản phẩm đơn lẻ</span>
                                </div>

                                <div
                                    className={`custom-field-tyle-item ${type == 'CATEGORY' ? 'active' : ''}`}
                                    onClick={() => {
                                        setType('CATEGORY');
                                        setNumProduct(0);
                                        setProductSelected([]);
                                        setCategorySelected([]);
                                        setValueSearch('');
                                    }}
                                >
                                    <div className={`ladiui checked-radio-v2 ${type == 'CATEGORY' ? 'selected' : ''}`}>
                                        <div className={`background`} />
                                    </div>
                                    <span>Chọn sản phẩm theo danh mục</span>
                                </div>
                            </div>
                        </div>
                        <div className='ladiui search-group mb-24'>
                            <input
                                className='ladiui search-field dropdown-toggle form-control'
                                value={valueSearch}
                                placeholder={'Tìm kiếm...'}
                                data-toggle='dropdown'
                                aria-expanded='false'
                                onChange={(e) => {
                                    setValueSearch(e.target.value);
                                }}
                                onKeyDown={onKeyDownInputSearch}
                            />
                            <i className='ladiui icon icon-search' onClick={searchData}></i>
                        </div>

                        {type == 'PRODUCT' && (
                            <div className='product-list'>
                                {products.map((item, index) => {
                                    let imageSrc = BaseHelper.getImageUrl(item.src);
                                    const isSelected = productSelected.find((_item) => _item.product_id == item.product_id);

                                    return (
                                        <div key={index} className='product-item' onClick={() => selectProduct(item)}>
                                            <div className='product-info'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        // onClick={(event) => checkItem(item)}
                                                        onChange={() => {}}
                                                        checked={isSelected || false}
                                                        value={isSelected || false}
                                                        className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    />
                                                </div>
                                                <img className='product-image' src={imageSrc} />
                                                <div className='product-name'>
                                                    <span>{item.name}</span>
                                                </div>
                                            </div>
                                            <div className='product-price'>
                                                <span>
                                                    {BaseHelper.formatMoneyPostFix(
                                                        item.min_price,
                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                    )}
                                                </span>
                                                {/* {item.min_price != item.max_price && (
                                                    <span>
                                                        {' - '}
                                                        {BaseHelper.formatMoneyPostFix(
                                                            item.max_price,
                                                            storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </span>
                                                )} */}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {type == 'CATEGORY' && (
                            <div className='product-list'>
                                {category.map((item, index) => {
                                    let imageSrc = BaseHelper.getImageUrl(item.src);
                                    const isSelected = categorySelected.find(
                                        (_item) => _item.product_category_id == item.product_category_id
                                    );
                                    let pdl = 32 * (item.level - 1);

                                    return (
                                        <div
                                            key={index}
                                            className='product-item'
                                            onClick={() => selectCategory(item)}
                                            style={{ paddingLeft: pdl || 0 }}
                                        >
                                            <div className='product-info'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        onChange={() => {}}
                                                        checked={isSelected || false}
                                                        value={isSelected || false}
                                                        className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    />
                                                </div>
                                                <img className='product-image' src={imageSrc} />
                                                <div className='product-name'>
                                                    <span>{item.name}</span>
                                                </div>
                                            </div>
                                            <div className='product-price'>
                                                <span>
                                                    {BaseHelper.formatNumber(item.product_number)}
                                                    {` sản phẩm`}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <div className='ladiui modal-footer justify-space-between '>
                        <div>{`Đã chọn ${numProduct} sản phẩm`}</div>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-md btn-secondary'
                                onClick={eventCancel}
                            >
                                Huỷ bỏ
                            </button>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-md btn-primary'
                                onClick={addProduct}
                            >
                                Thêm sản phẩm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalAddProduct;
