import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import appConfig from '../../../config/app';
import Modal from '../../../components/Modal';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';

class ModalConfirmResendMail extends React.Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onOk: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            manipulation: appConfig.MANIPULATION_ORDER.RESET.CODE,
        };
    }

    submit = () => {
        let manipulation = this.state.manipulation;

        this.props.onOk(manipulation);
    };

    render() {
        const { t } = this.props;
        const { manipulation, reasonDetail } = this.state;

        return (
            <Modal
                id='modal-confirm-resend-mail'
                title={t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE')}
                okText={"Gửi email"}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                width={400}
                isLoading={this.props.isLoading}
                position_unset={true}
            >
                {/* <p>{t('ORDERS.MSG_CANCEL_ORDERS_CONTENT')}</p> */}
                <div className='ladiui form-group' style={{ marginTop: 20 }}>
                    Khách hàng sẽ nhận được email xác nhận thanh toán thành công cho đơn hàng này.
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(ModalConfirmResendMail);
