import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const listBankHub = (data) => ({
    type: types.LIST_BANK_HUB,
    meta: {
        endpoint: endpoint.LIST_BANK_HUB,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const getBankAccountHolder = (data) => ({
    type: types.GET_BANK_ACCOUNT_HOLDER,
    meta: {
        endpoint: endpoint.GET_BANK_ACCOUNT_HOLDER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const connectBankHub = (data) => ({
    type: types.CONNECT_BANK_HUB,
    meta: {
        endpoint: endpoint.CONNECT_BANK_HUB,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const submitOtpConnect = (data) => ({
    type: types.SUBMIT_OTP_CONNECT,
    meta: {
        endpoint: endpoint.SUBMIT_OTP_CONNECT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const resendOtpConnect = (data, bankItem) => ({
    type: types.RESEND_OTP_CONNECT,
    meta: {
        endpoint: endpoint.RESEND_OTP_CONNECT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
    bankItem
});
const submitOtpDelete = (data) => ({
    type: types.SUBMIT_OTP_DELETE,
    meta: {
        endpoint: endpoint.SUBMIT_OTP_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});
const requestOtpDelete = (data, bankItem) => ({
    type: types.REQUEST_OTP_DELETE,
    meta: {
        endpoint: endpoint.REQUEST_OTP_DELETE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
    bankItem
});



export default {
    listBankHub,
    getBankAccountHolder,
    requestOtpDelete,
    submitOtpDelete,
    resendOtpConnect,
    submitOtpConnect,
    connectBankHub

};
