/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, filter, map, compact } from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import appConfig from '../../../config/app';
import { withTranslation } from 'react-i18next';
import Image from '../../../components/Image';
import Modal from '../../../components/Modal';
import NumberInput from '../../../components/NumberInput';
import { appLocalStorage } from '../../../localforage';
import baseHelper from '../../../helpers/BaseHelper';

import LoadingScene from '../../../components/LoadingScene';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import { Editor } from '@tinymce/tinymce-react';

class ModalPackage extends React.Component {
    static propTypes = {
        currentStore: PropTypes.object,
        currentProductInfo: PropTypes.object,
        mode: PropTypes.string,
        currentVariantInfo: PropTypes.object,
        images: PropTypes.array,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.moment = baseHelper.getMoment();

        this.state = {
            variant: {
                option_value_value: '',
                src: '',
                // src_hash: '',
                sku: '',
                price: 0,
                quantity: 1,
                price: '',
                package_price: '',
                package_quantity: '1',
                package_addition_quantity: '',
                max_buy: null,
                min_buy: 1,
                total_sold: 0,
                package_quantity_unit: '', //appConfig.PACKAGE_QUANTITY_UNIT[1],
                timezone: 'Asia/Bangkok',
                total_quantity: props.currentProductInfo.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE ? 0 : 1,
                description: '',
                inventory_checked:
                    props.currentProductInfo.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE
                        ? appConfig.STATUS.ACTIVE
                        : appConfig.STATUS.INACTIVE,
                position: 0,
                file: '',
            },
            isShowAdvanced: false,
            userInfo: {
                currentStore: {},
            },
        };

        this.ref = React.createRef();
        this.inputsRef = new Set();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        window.LadiUI.init();
        window.LadiUI.customInit();

        if (this.props.currentVariantInfo) {
            this.init(this.props);
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
        window.LadiUI.customInit();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.currentVariantInfo !== nextProps.currentVariantInfo) {
            this.init(nextProps);
        }

        if (this.props.fileReducer.action != nextProps.fileReducer.action) {
            if (nextProps.fileReducer.action == fileTypes.UPLOAD_VARIANT_IMAGE) {
                if (nextProps.fileReducer.status) {
                    this.setState({
                        variant: {
                            ...this.state.variant,
                            src: nextProps.fileReducer.ticket_images[0],
                        },
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                    // window.LadiUI.toastCustom("danger", "", nextProps.fileReducer.message);
                }
            }

            if (nextProps.fileReducer.action == fileTypes.UPLOAD_FILES) {
                if (nextProps.fileReducer.status) {
                    this.setState({
                        variant: {
                            ...this.state.variant,
                            file: nextProps.fileReducer.file_url,
                        },
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.fileReducer.message, 'OK');
                    // window.LadiUI.toastCustom("danger", "", nextProps.fileReducer.message);
                }
            }
        }
    }

    init = (props) => {
        if (props.mode == appConfig.FORM_MODE.EDIT) {
            const variant = cloneDeep(props.currentVariantInfo) || {};

            this.setState({
                variant,
            });
        }
    };

    fileChangedHandler = (event) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                this.props.uploadTicketImage(form);
            }
        }
    };

    onChangeInput = (event) => {
        const { name, value } = event.target;
        this.setState(
            {
                variant: {
                    ...this.state.variant,
                    [name]: value,
                },
            },
            () => {
                if (['price', 'package_quantity'].includes(name)) {
                    let avgPrice = 0;
                    if (this.state.variant.price > 0 && this.state.variant.package_quantity > 0) {
                        avgPrice = this.state.variant.price / this.state.variant.package_quantity;
                    }

                    this.setState({
                        variant: {
                            ...this.state.variant,
                            package_price: avgPrice,
                        },
                    });
                }

                if ('total_quantity' == name) {
                    this.setState({
                        variant: {
                            ...this.state.variant,
                            quantity: value,
                        },
                    });
                }
            }
        );
    };

    handleChangeUploadFile = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUploadFile(files, 32);
            if (form) {
                this.props.uploadFile(form);
            }
        }
    };

    onSubmit = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const _inputRef = compact(Array.from(this.inputsRef));
        map(_inputRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });
        errors = compact(errors);
        if (errors.length > 0) {
            // const errorMessage = errors.join('<br/>');
            // window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const _variant = {
            ...this.state.variant,
            price: this.state.variant.price || 0,
            quantity: this.state.variant.quantity || 0,
            package_price: this.state.variant.package_price || 0,
            package_quantity: this.state.variant.package_quantity || 1,
            package_addition_quantity: this.state.variant.package_addition_quantity || 0,
        };

        this.props.onSubmit(_variant);
    };

    handleEditorChange = (value) => {
        this.setState({
            variant: {
                ...this.state.variant,
                description: value,
            },
        });
    };

    render() {
        const { t } = this.props;
        const { variant } = this.state;
        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.state.userInfo.currentStore;
        const isLoadingUploadImage = this.props.fileReducer.loading && this.props.fileReducer.waiting == fileTypes.UPLOAD_VARIANT_IMAGE;
        const title =
            this.props.mode === appConfig.FORM_MODE.CREATE ? t('PRODUCT_SERVICES.LB_CREATE_NEW_PACKAGE') : t('PRODUCTS.LB_EDIT_PACKAGE');
        return (
            <Modal
                id='modal-event'
                title={title}
                onCancel={this.props.onCancel}
                onOk={(event) => this.onSubmit(event)}
                isLoading={this.props.isLoading}
                visible={this.props.visible}
                width={900}
            >
                {isLoadingUploadImage && <LoadingScene blur={true} />}
                <div className='block-content-item'>
                    <h3>{t('PRODUCT_SERVICE_V4.PACKAGE_INFO_LB')}</h3>
                    <div className='ladiui flex gap-12 mt-12'>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('PRODUCT_SERVICES.LB_PACKAGE_NAME')}</label>
                            <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                validationName={t('PRODUCTS.VARIANT_PACKAGE_DEFAULT')}
                                value={variant.option_value_value}
                                name='option_value_value'
                                onChange={this.onChangeInput}
                                validations={{ isRequired: true }}
                                placeholder={t('PRODUCT_SERVICE_V4.PACKAGE_NAME_PLH')}
                            />
                        </div>
                        <div className='ladiui form-group mt-0'>
                            <label className='ladiui-label'>{'Mã dịch vụ'}</label>
                            <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                validationName={t('PRODUCT_SERVICES.SKU')}
                                value={variant.sku}
                                name='sku'
                                onChange={this.onChangeInput}
                                validations={{ isRequired: true }}
                            />
                        </div>
                    </div>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>{t('PRODUCT_SERVICE_V4.PACKAGE_DES_LB')}</label>
                        <textarea
                            rows='4'
                            className='ladiui text-area'
                            value={variant.description}
                            name='description'
                            onChange={this.onChangeInput}
                        />
                    </div>
                </div>

                <div className='block-content-item mt-20'>
                    <h3>Giá bán và thời hạn sử dụng</h3>
                    <div className='flex items-center gap-16'>
                        <div className='ladiui form-group mt-24'>
                            <label className='ladiui-label new'>{t('PRODUCTS.PRICE')}</label>
                            <NumberInput name='price' prefix={currencySymbol} value={variant.price} onChange={this.onChangeInput} />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label new'>{t('PRODUCT_SERVICES.PACKAGE_QUANTITY_UNIT')}</label>
                            <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                validationName={t('PRODUCT_SERVICES.PACKAGE_QUANTITY_UNIT')}
                                value={variant.package_quantity_unit}
                                name='package_quantity_unit'
                                onChange={this.onChangeInput}
                                validations={{ isRequired: true }}
                                placeholder={t('PRODUCT_SERVICES.PL_PACKAGE_QUANTITY_UNIT')}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label new'>{`Số ${(
                                this.state.variant.package_quantity_unit || ''
                            ).toLowerCase()}`}</label>
                            <NumberInput name='package_quantity' value={variant.package_quantity} onChange={this.onChangeInput} />
                        </div>
                    </div>
                    <div className='flex gap-16'>
                        <div className='ladiui form-group mt-24' style={{ width: 'calc(33% - 8px)' }}>
                            <label className='ladiui-label new'>{`Số ${(
                                this.state.variant.package_quantity_unit || ''
                            ).toLowerCase()} cộng thêm`}</label>
                            <NumberInput
                                name='package_addition_quantity'
                                value={variant.package_addition_quantity}
                                onChange={this.onChangeInput}
                            />
                        </div>
                        {variant.inventory_checked == appConfig.STATUS.ACTIVE && (
                            <div className='ladiui form-group' style={{ width: 'calc(33% - 8px)' }}>
                                <label className='ladiui-label new'>{t('PRODUCT_SERVICE_V4.PACKAGE_TOTAL')}</label>
                                <NumberInput
                                    name='total_quantity'
                                    disabled={
                                        this.props.currentProductInfo.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE
                                    }
                                    value={variant.total_quantity}
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        )}

                        <label className='flex items-end mb-12'>
                            <input
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                name='inventory_checked'
                                value={variant.inventory_checked}
                                disabled={this.props.currentProductInfo.ticket_creation_form == appConfig.TICKET_CREATION_FORM.IMPORT.CODE}
                                onChange={() => {
                                    this.setState(
                                        {
                                            variant: {
                                                ...this.state.variant,
                                                inventory_checked:
                                                    variant.inventory_checked == appConfig.STATUS.ACTIVE
                                                        ? appConfig.STATUS.INACTIVE
                                                        : appConfig.STATUS.ACTIVE,
                                            },
                                        },
                                        () => {
                                            if (!variant.inventory_checked) {
                                                this.setState({
                                                    variant: {
                                                        ...this.state.variant,
                                                        quantity: -1,
                                                    },
                                                });
                                            }
                                        }
                                    );
                                }}
                                checked={variant.inventory_checked == appConfig.STATUS.ACTIVE}
                            />
                            {t('PRODUCT_SERVICES.LB_INVENTORY_CHECKED')}
                        </label>
                    </div>
                </div>

                <div className='mt-20 flex gap-16'>
                    <div className='pull-left'>
                        <div className='block-content-item'>
                            <h3 className='mb-24'>Hình ảnh gói dịch vụ</h3>
                            <div className='image setting-block-image'>
                                <div style={{ width: '100%', height: 250 }}>
                                    <Image width={250} height={250} resize={appConfig.RESIZE.SMALL} src={variant.src} />

                                    <div className='ladiui-button-upload image block-upload-image'>
                                        <div className='block-overlay' style={{ marginTop: '90px' }}>
                                            <button type='button' className='ladiui btn btn-primary icon'>
                                                <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-gallery.svg' alt='' />
                                                <p>
                                                    Bấm vào đây để chọn ảnh sản phẩm <br /> từ máy tính của bạn
                                                </p>
                                            </button>
                                            <input
                                                type='file'
                                                name='file'
                                                accept='image/*'
                                                onChange={this.fileChangedHandler}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='pull-right w100'>
                        <div className='block-content-item' style={{ height: '100%' }}>
                            <h3>Thiết lập nâng cao</h3>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label new'>{t('SERVICES.LB_MIN_BUY')}</label>
                                <NumberInput
                                    name='min_buy'
                                    value={variant.min_buy}
                                    onChange={this.onChangeInput}
                                    onlyNumber={true}
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    allowNegative={true}
                                    validations={{
                                        min: 1,
                                    }}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                    validationName={t('SERVICES.LB_MIN_BUY')}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label new'>{t('SERVICES.LB_MAX_BUY')}</label>
                                <NumberInput
                                    name='max_buy'
                                    value={variant.max_buy}
                                    onChange={this.onChangeInput}
                                    onlyNumber={true}
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    allowNegative={true}
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        uploadTicketImage: (form) => dispatch(fileActions.uploadTicketImage(form)),
        uploadFile: (form) => dispatch(fileActions.uploadFile(form)),
    };
};

const mapStateToProps = (state) => ({
    variantReducer: { ...state.variant },
    fileReducer: { ...state.file },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalPackage)
);
