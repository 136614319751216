import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line max-lines-per-function
function ModalMembershipKey(props) {
    const { t, onClose, isShow, innerStyle, isStudentCourse, selectedReason, selectedTitle } = props;
    const wrapperRef = useRef();

    const [reason, setReason] = useState('');

    const dispatch = useDispatch();

    const eventCancel = () => {
        onClose(false);
    };

    const getTitleModal = () => {
        let title = '';
        if (!isStudentCourse) {
            title = 'Lý do vô hiệu tài khoản';
        } else {
            title = 'Lý do khóa học viên';
        }
        return title;
    };

    const getLabelReson = () => {
        let label = '';
        if (!isStudentCourse) {
            label = 'Lý do vô hiệu';
        } else {
            label = 'Lý do khóa';
        }
        return label;
    };

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-reason-lock'>
            <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>{selectedTitle ? selectedTitle : getTitleModal()}</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body'>
                        <div className='cursor-pointer' style={innerStyle}>
                            <a className='flex-center-left cursor-pointer my-24 pd-0'>
                                <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                <div> {t('PRODUCTS.ADD_NEW')}</div>
                            </a>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{getLabelReson()}</label>
                            <textarea
                                style={{ height: 200 }}
                                name='reson'
                                className='ladiui textarea'
                                placeholder='Nhập lý do'
                                value={selectedReason}
                            />
                        </div>
                    </div>

                    <div className='ladiui modal-footer'>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.CLOSE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalMembershipKey);
