import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { source: '' }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.PRINT_ORDER: {
            return {
                ...state,
                action: types.PRINT_ORDER,
                status: action.status,
                message: action.message,
                source: action.status ? action.payload.source : ''
            }
        }
        case types.PRINTS_ORDER: {
            return {
                ...state,
                action: types.PRINTS_ORDER,
                status: action.status,
                message: action.message,
                source: action.status ? action.payload.source : ''
            }
        }
        case types.PRINT_SHIPPING: {
            return {
                ...state,
                action: types.PRINT_SHIPPING,
                status: action.status,
                message: action.message,
                source: action.status ? action.payload.source : ''
            }
        }
        case types.PRINTS_SHIPPING: {
            return {
                ...state,
                action: types.PRINTS_SHIPPING,
                status: action.status,
                message: action.message,
                source: action.status ? action.payload.source : ''
            }
        }
        default:
            return state;
    }
}

