import React from 'react';
import Input from '../../../components/Input';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { cloneDeep, } from 'lodash';

import shippingActions from '../../../redux/futures/shipping/actions';
import * as shippingTypes from '../../../redux/futures/shipping/types';

import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import NumberInput from '../../../components/NumberInput';

class ModalShippingFee extends React.Component {
    static propTypes = {
        shippingID: PropTypes.number,
        fee: PropTypes.number,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.state = {
            fee: props.fee,
        }

        this.initialState = cloneDeep(this.state);
    }

    componentDidMount() {
        this.setState({
            fee: cloneDeep(this.props.fee),
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.fee != nextProps.fee) {
            this.setState({
                fee: cloneDeep(nextProps.fee),
            })
        }
    }

    resetState = () => {
        this.setState({
            ...cloneDeep(this.initialState),
        })
    }

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        })
    }

    submit = () => {
        this.props.onSubmit(this.props.shippingID, this.state.fee);
    }

    render() {
        const { t } = this.props;
        const { fee } = this.state;
        const {
            currency_symbol: currencySymbol,
            currency_code: currencyCode,
        } = this.props.store.userInfo.currentStore;

        return (
            <Modal
                id='modal-shipping-fee'
                title={t('SHIPPINGS.UPDATE_TITLE_FEE')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                isLoading={this.props.isLoading}
                width={350}
                bodyClassName="unset-modal"
            >
                <NumberInput
                    name="fee"
                    prefix={currencySymbol}
                    value={fee}
                    onChange={this.onChangeInput}
                />
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    store: { ...state.store }
});

export default connect(mapStateToProps, null)(withTranslation()(ModalShippingFee));