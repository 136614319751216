import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import LoadingSence from '../../../../components/LoadingScene';
import { connect } from 'react-redux';
import shippingActions from '../../../../redux/futures/shipping/actions';
import * as shippingTypes from '../../../../redux/futures/shipping/types';

import appConfig from '../../../../config/app';
import { includes, isEqual, map } from 'lodash';
import baseHelper from '../../../../helpers/BaseHelper';
import SelectOption from '../../../../components/SelectOption';

class ViettelPostForm extends React.Component {
    static propTypes = {
        orderID: PropTypes.number,
        weight: PropTypes.any,
        selectedHub: PropTypes.any,
        codAmount: PropTypes.any,
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedService: {
                code: '',
                name: '',
                fee: '',
            },
            coupon: '',
            width: 0,
            height: 0,
            length: 0,
            selectOrderPayment: appConfig.VIETTEL_POST_ORDER_PAYMENT[1],
            listService: [],
            hasInsurance: false,
        };

        this.inputsRef = new Set();
    }

    componentDidMount() {
        if (this.props.selectedHub.code) {
            this.getFee();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
            if (includes([shippingTypes.SHIPPING_LIST_SERVICES, shippingTypes.SHIPPING_RESET_SERVICES], nextProps.shippingReducer.action)) {
                if (nextProps.shippingReducer.status) {
                    const selectedService = nextProps.shippingReducer.services.length > 0 ? nextProps.shippingReducer.services[0] : {};

                    this.onChangeService(selectedService, true);
                } else {
                    this.onChangeService({});
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedHub.code != this.props.selectedHub.code || prevProps.codAmount != this.props.codAmount) {
            this.getFee();
        }

        if (shippingTypes.SHIPPING_GET_FEE == prevProps.shippingReducer.action) {
            const listService = this.props.shippingReducer.fee || [];
            this.setState({
                listService: listService,
                selectedService: listService.length > 0 ? listService[0] : this.state.selectedService,
            });
        }
    }

    listServices = () => {
        this.props.resetServices();
        if (!this.validate()) {
            return;
        }

        const data = {
            order_id: this.props.orderID,
            shipping_partner_code: appConfig.SHIPPING_PARTNERS.VTPOST.CODE,
            weight: this.props.weight,
            height: this.state.height,
            length: this.state.length,
            width: this.state.width,
        };

        this.props.listServices(data);
    };

    onChangeService = (selectedService, forceGetFee = false) => {
        this.setState({
            selectedService,
        });
    };

    getFee = (forceGetFee) => {
        if (baseHelper.isEmpty(this.state.selectedService)) {
            window.LadiUI.toastCustom('danger', '', this.props.t('SHIPPINGS.SETUP_AT_LEAST_ONE_SERVICE'));
            return;
        }

        if (!this.validate()) {
            return;
        }

        const data = {
            order_id: this.props.orderID,
            shipping_partner_code: appConfig.SHIPPING_PARTNERS.VTPOST.CODE,
            viettel_post_data: {
                selected_hub: this.props.selectedHub,
                from_province_id: this.props.selectedHub.province_id,
                from_district_id: this.props.selectedHub.district_id,
                has_insurance: this.state.hasInsurance,
            },
            weight: this.props.weight,
            height: this.state.height,
            length: this.state.length,
            width: this.state.width,
            cod_amount: this.props.codAmount,
        };

        if (forceGetFee) {
            this.oldData = data;
            this.props.getFee(data);
        } else if (!isEqual(this.oldData, data)) {
            this.oldData = data;
            this.props.getFee(data);
        }
    };

    /**
     *
     * @param {*} forListService
     * true: only validate for listService, else validate all
     */
    validate = (forListService = true) => {
        const { t, weight, selectedHub } = this.props;
        if (!selectedHub) {
            window.LadiUI.toastCustom('danger', '', t('SHIPPINGS.SETUP_AT_LEAST_ONE_HUB'));
            return false;
        }

        if (baseHelper.parseFloat(weight) <= 0) {
            document.getElementById('weight').classList.add('error');
            window.LadiUI.toastCustom('danger', '', t('VALIDATION.GREATER_THAN', t('COMMON.WEIGHT'), '0'));
            return false;
        }

        if (!forListService) {
            const { selectedService } = this.state;
            if (!selectedService.code) {
                window.LadiUI.toastCustom('danger', '', t('SHIPPINGS.VIETTEL_POST_REQUIRED_SERVICE'));
                return false;
            }
        }

        return true;
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    onChangeOrderType = (item, forceGetFee = false) => {
        this.setState({
            selectOrderPayment: item,
        });
    };

    getData = () => {
        const { selectedService, width, height, length, coupon, selectOrderPayment } = this.state;

        return {
            service_id: selectedService.code,
            width,
            height,
            length,
            coupon,
            order_payment_code: selectOrderPayment.CODE,
            has_insurance: this.state.hasInsurance,
        };
    };

    render() {
        const { t } = this.props;
        const { selectedService, width, height, length, coupon, listService } = this.state;
        const loadingData =
            this.props.shippingReducer.loading &&
            includes([shippingTypes.SHIPPING_LIST_SERVICES, shippingTypes.SHIPPING_GET_FEE], this.props.shippingReducer.waiting);

        return (
            <div className='vtpost-form'>
                {loadingData && <LoadingSence blur={true} />}

                <div className='ladiui-row gap-16'>
                    <div className='ladiui form-group'>
                        <label className='ladiui text-14'>{t('SHIPPINGS.ORDER_TYPE')}</label>
                        <div className='ladiui data-hub mt-4'>
                            <SelectOption
                                wrapperStyle={{ zIndex: 6 }}
                                innerClassName='select-hub'
                                innerStyle={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                }}
                                customClassName='w-100-p'
                                currentValue={this.state.selectOrderPayment.NAME}
                                _key='CODE'
                                _value='NAME'
                                items={appConfig.VIETTEL_POST_ORDER_PAYMENT || {}}
                                onSelectItem={this.onChangeOrderType}
                            />
                        </div>
                    </div>

                    <div className='ladiui form-group m-0'>
                        <label className='ladiui text-14'>{t('SHIPPINGS.VIETTEL_POST_SERVICE')}</label>
                        <div className='ladiui data-hub mt-4'>
                            <div className='ladiui btn-group w100'>
                                <div className='ladiui dropdown w100'>
                                    <button
                                        style={{ minWidth: '100%' }}
                                        className='ladiui btn btn-md btn-outline-light dropdown-toggle select-dropdown'
                                        id='dropdownMenuButton3'
                                        data-toggle='dropdown'
                                    >
                                        <i></i>
                                        <span className='ladiui dropdown-text'>{selectedService.name}</span>
                                    </button>
                                    <ul className='ladiui dropdown-menu w100'>
                                        {map(listService, (item) => (
                                            <li key={item.code} onClick={() => this.onChangeService(item)}>
                                                <a className='ladiui dropdown-item'>{item.name}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='display-flex-center ladiui-row gap-16 mt-16'>
                    <div className='ladiui form-group coupon custom-form-group'>
                        <label className='ladiui text-14'>{t('SHIPPINGS.VIETTEL_POST_COUPON')}</label>
                        <Input
                            className='mt-4'
                            ref={(ref) => this.inputsRef.add(ref)}
                            name='coupon'
                            value={coupon}
                            onChange={this.onChangeInput}
                            onBlur={() => this.getFee()}
                        />
                    </div>

                    <div className='ladiui form-group size'>
                        <label className='ladiui text-14'>{t('SHIPPINGS.WIDTH_SHORTCUT')}</label>
                        <Input
                            className='mt-4'
                            ref={(ref) => this.inputsRef.add(ref)}
                            type='number'
                            name='width'
                            value={width}
                            onChange={this.onChangeInput}
                            onBlur={() => this.getFee()}
                        />
                    </div>

                    <div className='ladiui form-group size'>
                        <label className='ladiui text-14'>{t('SHIPPINGS.LENGTH_SHORTCUT')}</label>
                        <Input
                            className='mt-4'
                            ref={(ref) => this.inputsRef.add(ref)}
                            name='length'
                            type='number'
                            value={length}
                            onChange={this.onChangeInput}
                            onBlur={() => this.getFee()}
                        />
                    </div>

                    <div className='ladiui form-group size'>
                        <label className='ladiui text-14'>{t('SHIPPINGS.HEIGHT_SHORTCUT')}</label>
                        <Input
                            className='mt-4'
                            ref={(ref) => this.inputsRef.add(ref)}
                            name='height'
                            type='number'
                            value={height}
                            onChange={this.onChangeInput}
                            onBlur={() => this.getFee()}
                        />
                    </div>
                </div>

                <div className='ladiui-row mt-16'>
                    <div className='ladiui form-group flex mb-0'>
                        <input
                            checked={this.state.hasInsurance}
                            onChange={() => {
                                this.setState(
                                    {
                                        hasInsurance: !this.state.hasInsurance,
                                    },
                                    () => {
                                        this.getFee();
                                    }
                                );
                            }}
                            type='checkbox'
                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                        />
                        <label className='ladiui label'> {t('SHIPPINGS.HAS_INSURANCE')}</label>
                    </div>
                </div>

                <div className='ladiui form-group mt-16'>
                    <label className='ladiui text-14'>{t('SHIPPINGS.ESTIMATE_FEE')}</label>
                    <Input
                        ref={(ref) => this.inputsRef.add(ref)}
                        name='fee'
                        value={baseHelper.formatMoneyPostFix(selectedService.fee)}
                        disabled={true}
                        className='mt-4'
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listServices: (data) => dispatch(shippingActions.listServices(data)),
        resetServices: () => dispatch(shippingActions.resetServices()),
        getFee: (data) => {
            return dispatch(shippingActions.getFee(data));
        },
    };
};

const mapStateToProps = (state) => ({
    shippingReducer: { ...state.shipping },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ViettelPostForm)
);
