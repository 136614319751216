import React from 'react';

import { connect } from 'react-redux';
import customerActions from '../../redux/futures/customer/actions';
import { includes, map, remove } from 'lodash';
import moment from 'moment';
import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';
import * as types from '../../redux/futures/customer/types';
import ModalCustomer from './components/ModalCustomer';
import { withTranslation } from 'react-i18next';
import LoadingTable from '../../components/LoadingTable';
import ConfirmModal from '../../components/ConfirmModal';
import { appLocalStorage } from '../../localforage';
import queryString from 'query-string';
import ModalExportCustomer from './components/ModalExportCustomer';
import LoadingScene from '../../components/LoadingScene';
import Tag from '../../components/Tag';
import OrderDateRangePicker from '../../components/OrderDateRangePicker';

import * as customerTagTypes from '../../redux/futures/customer_tag/types';
import customerTagActions from '../../redux/futures/customer_tag/actions';

import LadiPagination from '../../components/LadiPagination';
import FilterConditionCustomer from './components/FilterConditionCustomer';
import FilterConditionOrderv2 from '../orders/components/FilterCondition/FilterConditionOrderv2';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import Dropdown from '../../components/Dropdown';

class Customer extends React.Component {
    constructor(props) {
        super(props);
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };
        this.state = {
            activePage: 1,
            searchName: '',
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            currentCustomerInfo: {},
            mode: '',
            isShowModalCustomer: false,
            userInfo: {},
            isShowModalExportCustomer: false,
            startDate: null,
            fromDate: null,
            focusedInput: null,
            conditions: defaultConditions,
            selectedIDs: [],
            tag_ids: [],
            isShowFilterCondition: false,
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
            dataConditions: {
                operator: appConfig.OPERATORS.SWITCH_AND_OR.AND,
                conditions: [],
            },
        };

        this.sortBy = {
            customer_id: 'DESC',
        };
        this.selectedID = '';
        // this.selectedIDs = [];
        this.formRef = React.createRef();
        this.searchTagRef = React.createRef();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.list();

        let { customer_id: customerID } = queryString.parse(this.props.location.search);
        if (customerID) {
            this.openModalEdit(customerID);
        }

        this.props.listAllTags();
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.customerReducer.action != nextProps.customerReducer.action) {
            if (nextProps.customerReducer.action === types.CREATE_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    this.setState({
                        currentCustomerInfo: {},
                        isShowModalCustomer: false,
                    });

                    window.LadiUI.toastCustom('success', '', nextProps.customerReducer.message);
                    this.list();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }

            if (nextProps.customerReducer.action === types.UPDATE_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    this.props.reload(this.state.currentCustomerInfo.customer_id);
                    this.list();
                    this.setState({
                        isShowModalCustomer: false,
                    });

                    window.LadiUI.toastCustom('success', '', nextProps.customerReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }

            if (nextProps.customerReducer.action === types.SHOW_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    this.setState({
                        mode: appConfig.FORM_MODE.EDIT,
                        currentCustomerInfo: nextProps.customerReducer.customer,
                        isShowModalCustomer: true,
                    });
                }
            }

            if (nextProps.customerReducer.action === types.RELOAD_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    this.setState({
                        currentCustomerInfo: nextProps.customerReducer.customer,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }

            if (includes([types.DELETE_CUSTOMERS, types.DELETE_CUSTOMER], nextProps.customerReducer.action)) {
                if (nextProps.customerReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.customerReducer.message);

                    this.list();

                    window.LadiUI.closeModal('confirm-customer-delete');
                    window.LadiUI.closeModal('confirm-customer-deletes');
                } else {
                    window.LadiUI.closeModal('confirm-customer-delete');
                    window.LadiUI.closeModal('confirm-customer-deletes');
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }

            if (nextProps.customerReducer.action == types.EXPORT_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.customerReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }

            if (nextProps.customerReducer.action == types.IMPORT_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    this.list();
                    window.LadiUI.toastCustom('success', '', nextProps.customerReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }

            if (nextProps.customerReducer.action == types.LIST_CUSTOMER) {
                if (nextProps.customerReducer.status) {
                    window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
                        item.classList.remove('up');

                        if (this.sortBy[item.getAttribute('name')] == 'DESC') {
                            item.classList.add('up');
                        }
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.customerReducer.message, 'OK');
                }
            }
        }
    }

    /**
     * list customer
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const { searchName, selectedLimit, conditions, fromDate, toDate } = this.state;
        let activePage = conditions.page;
        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;
            this.setState({
                conditions,
            });
        }

        // Reset selectedIDs
        this.setState({
            selectedIDs: [],
        });

        // const tagIDs = this.searchTagRef.current.getIDs();
        const tagIDs = this.state.tag_ids;

        const data = {
            search: {
                name: searchName,
                tag_ids: tagIDs,
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsCm = [];
        if (checked) {
            selectedIDsCm = map(this.props.customerReducer.customers, (customer) => customer.customer_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs: selectedIDsCm,
        });
    };

    checkItem = (event, customerID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, customerID)) {
                selectedIDs.push(customerID);
            }

            if (selectedIDs.length == this.props.customerReducer.customers.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(selectedIDs, (selectedID) => selectedID == customerID);
        }

        this.setState({
            selectedIDs: selectedIDs,
        });
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.list();
    };

    openModalCreate = () => {
        this.setState({
            currentCustomerInfo: null,
            mode: appConfig.FORM_MODE.CREATE,
            isShowModalCustomer: true,
        });
    };

    openModalEdit = (customerID) => {
        this.setState({
            currentCustomerInfo: null,
            mode: appConfig.FORM_MODE.EDIT,
        });

        this.props.show(customerID);
    };

    hideModalCustomer = () => {
        this.setState({
            isShowModalCustomer: false,
        });
    };

    submit = (newCustomer) => {
        if (this.state.mode == appConfig.FORM_MODE.CREATE) {
            this.props.create(newCustomer);
        } else {
            this.props.update(newCustomer);
        }
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedID = selectedID;

        window.LadiUI.showModal('confirm-customer-delete');
    };

    openOptionDeletesModal = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-customer-deletes');
    };

    /****************************************************************/

    /******************************CALLBACK MODAL*********************/
    exportCustomer = (type) => {
        let ids;
        switch (baseHelper.parseInt(type)) {
            case appConfig.EXPORT_CUSTOMER.ALL_CUSTOMER:
                ids = appConfig.EXPORT_TYPE.ALL;
                break;
            case appConfig.EXPORT_CUSTOMER.ON_PAGE:
                const onPageIDs = map(this.props.customerReducer.customers, (item) => item.customer_id);
                ids = onPageIDs;
                break;
            case appConfig.EXPORT_CUSTOMER.SELECTED_CUSTOMER:
                if (baseHelper.isEmpty(this.state.selectedIDs)) {
                    window.LadiUI.toastCustom('warning', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
                    return;
                }
                ids = this.state.selectedIDs;
                break;
        }

        this.props.exportCustomer({ ids });
    };
    /****************************************************************/

    import = (event) => {
        const file = event.target.files[0];

        const form = new FormData();
        form.append('file', file, file.name);

        this.props.importCustomer(form);

        event.target.value = '';
    };

    eventSelectAction = (action, event) => {
        switch (action) {
            case 'DELETE_CUSTOMER':
                this.openOptionDeletesModal();
                break;
            default:
        }
    };

    clearFilter = (callback) => {
        this.setState(
            {
                tag_ids: [],
            },
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    };

    filterActionProp = async (conditions) => {
        this.clearFilter();

        // let filter = {
        //     tabSelected: appConfig.ORDER_FILTER_TYPE.SEARCH_ALL,
        //     searchName: ""
        // }

        for await (const condition of conditions) {
            switch (condition.attributeFilter.value) {
                case 'tag_ids':
                    // filter.searchRule = condition.listFilter.map(item => item.value);
                    this.setState({
                        tag_ids: condition.listFilter.map((item) => item.value),
                    });
                    break;
                default:
                    break;
            }
        }

        // this.setState({
        //     ...filter
        // }, () => {
        //     this.list();
        // })
        this.list();
    };

    render() {
        const { t } = this.props;
        const { totalRecord, totalPage } = this.props.customerReducer;
        const moment = baseHelper.getMoment();

        const {
            activePage,
            selectedLimit,
            searchName,
            userInfo,
            fromDate,
            toDate,
            focusedInput,
            conditions,
            isShowFilterCondition,
            conditionProp,
            dataConditions,
        } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.customerReducer.customers || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.customerReducer.customers.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
            'PAGINATION.OF'
        )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;
        const isLoadingData = this.props.customerReducer.loading && this.props.customerReducer.waiting == types.LIST_CUSTOMER;
        const isLoadingInfo = this.props.customerReducer.loading && this.props.customerReducer.waiting == types.SHOW_CUSTOMER;
        const isLoadingDelete =
            this.props.customerReducer.loading &&
            includes([types.DELETE_CUSTOMER, types.DELETE_CUSTOMERS], this.props.customerReducer.waiting);
        const isSubmitLoading =
            this.props.customerReducer.loading &&
            includes([types.CREATE_CUSTOMER, types.UPDATE_CUSTOMER], this.props.customerReducer.waiting);
        const isLoadingExportCustomer =
            this.props.customerReducer.loading && includes([types.EXPORT_CUSTOMER], this.props.customerReducer.waiting);
        const isLoadingImportCustomer =
            this.props.customerReducer.loading && includes([types.IMPORT_CUSTOMER], this.props.customerReducer.waiting);

        const allTags = this.props.customerTagReducer.allTags || [];

        const listAction = [
            {
                value: 'DELETE_CUSTOMER',
                name: t('ACTIONS.DELETE'),
            },
        ];

        return (
            <div id='page-customer' className='customer-page page-content'>
                {isLoadingImportCustomer && <LoadingScene blur={true} />}
                <input
                    id='uploadFile'
                    type='file'
                    name='file'
                    accept='.xlsx, .xls'
                    onChange={this.import}
                    onClick={(event) => {
                        event.target.value = null;
                    }}
                    style={{ display: 'none' }}
                />
                <div className='header-page'>
                    <div className='heading'>
                        <h1>Danh sách khách hàng</h1>
                        <p>Những khách hàng đã tương tác với trang thanh toán, khoá học và cửa hàng.</p>
                    </div>

                    <div className='action-btn flex items-center'>
                        <div className='ladiui btn-group custom-btn-list-landingpage mr-16'>
                            <div className='ladiui dropdown hide-mt ba-c'>
                                <button className='dropdown-toggle btn btn-import-export flex items-center' data-toggle='dropdown'>
                                    <i className='icon-export mr-8' />
                                    {t('PRODUCTS.IMPORT_EXPORT_DATA')}
                                </button>
                                <ul className='ladiui dropdown-menu r-0'>
                                    <li
                                        onClick={() => {
                                            document.getElementById('uploadFile').click();
                                        }}
                                    >
                                        <a className='ladiui dropdown-item'>{t('ACTIONS.IMPORT_DATA')}</a>
                                    </li>
                                    <li
                                        onClick={() =>
                                            this.setState({
                                                isShowModalExportCustomer: true,
                                            })
                                        }
                                    >
                                        <a className='ladiui dropdown-item'>{t('ACTIONS.EXPORT_DATA')}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button
                            type='button'
                            className='ladiui btn btn-primary btn-lg btn-open-modal'
                            data-toggle='modal'
                            onClick={this.openModalCreate}
                        >
                            <img
                                className='ladiui btn-custom-img'
                                src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg'
                                alt=''
                            />
                            <div className='ladiui btn-custom-text '>{t('CUSTOMERS.ADD_CUSTOMER')}</div>
                        </button>
                    </div>
                </div>

                <div className='block-filter'>
                    <div className='block-search'>
                        <div className='ladiui search-group'>
                            <input
                                id='keyword_search'
                                className='ladiui search-field dropdown-toggle form-control search-width'
                                name='searchName'
                                placeholder='Tìm kiếm'
                                aria-expanded='false'
                                value={searchName}
                                onChange={this.onChangeInput}
                                onKeyDown={this.onKeyDownInput}
                            />
                            <i className='ladiui icon icon-search' onClick={this.list}></i>
                        </div>

                        <div className='date-filter w-300'>
                            <OrderDateRangePicker
                                startDateId='fromDate'
                                endDateId='toDate'
                                startDate={fromDate}
                                endDate={toDate}
                                onDatesChange={({ startDate, endDate }) => {
                                    this.setState(
                                        {
                                            fromDate: baseHelper.getFromDate(startDate),
                                            toDate: baseHelper.getToDate(endDate),
                                        },
                                        () => {
                                            if ((startDate && endDate) || (!startDate && !endDate)) {
                                                this.list();
                                            }
                                        }
                                    );
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={(focusedInput) => this.setState({ focusedInput: focusedInput })}
                                showDefaultInputIcon={true}
                                inputIconPosition='after'
                                small={true}
                                hideKeyboardShortcutsPanel={true}
                                customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                isOutsideRange={() => false}
                                isDayHighlighted={(date) => {
                                    return moment().isSame(date, 'day');
                                }}
                                minimumNights={0}
                                showClearDates={true}
                                anchorDirection={'right'}
                            />
                        </div>

                        <Dropdown
                            data={allTags || []}
                            onSelectItem={(item) => {
                                this.setState(
                                    {
                                        tag_ids: [item.customer_tag_id],
                                    },
                                    () => {
                                        this.list();
                                    }
                                );
                            }}
                            currentKey={this.state.tag_ids[0]}
                            _key={'customer_tag_id'}
                            _value={'name'}
                            validationName={t('Tag khách hàng')}
                            placeHolder={t('Chọn Tag khách hàng')}
                            ref={(ref) => (this.paymentRef = ref)}
                            validations={{ isRequired: true }}
                            // idBlock='dropdown-confirm-payment'
                            wrapperClassName='w-auto'
                        />

                        {/* <button
                                type='button'
                                className='btn-advanced-filter ladiui btn btn-md'
                                onClick={() => this.setState({ isShowFilterCondition: !isShowFilterCondition })}
                            >
                                <div className='ladiui'>{t('COMMON.MORE_FILTER')}</div>
                                <i className='ldicon-sort' />
                            </button> */}
                    </div>
                </div>

                <div className='block-content'>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card ladiui-table-responsive ladiui pos-rel'>
                                {this.state.selectedIDs.length > 0 && (
                                    <LadiDropdownMenu
                                        labelDropdown={t('ACTIONS.LABEL')}
                                        listProp={listAction}
                                        defaultTextProp={t('ACTIONS.TITLE')}
                                        hideLabel={true}
                                        cbProp={this.eventSelectAction}
                                        classSize='btn-sm'
                                        classDropdown='w-180 action-record-table'
                                    />
                                )}
                                <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''}`}>
                                    <thead style={{ position: 'relative' }}>
                                        <tr className='ladiui table-vertical'>
                                            <th scope='col' className='ladiui checkall'>
                                                <input
                                                    id='checkAllItem'
                                                    onClick={this.checkAllItem}
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle'
                                                />
                                            </th>
                                            {/* {this.state.selectedIDs.length <= 0 ? ( */}
                                            <th scope='col' name='full_name'>
                                                {t('COMMON.FULL_NAME')}
                                            </th>
                                            {/* ) : (
                                                <th>
                                                    {t('COMMON.FULL_NAME')}
                                                    <LadiDropdownMenu
                                                        labelDropdown={'Chọn hành động'}
                                                        listProp={listAction}
                                                        defaultTextProp={t('ACTIONS.TITLE')}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectAction}
                                                        classSize='btn-sm '
                                                       
                                                        classDropdown='w-180 action-record-table'
                                                    />
                                                </th>
                                            )} */}
                                            <th scope='col' name='email' className='text-left'>
                                                {t('COMMON.EMAIL')}
                                            </th>
                                            <th scope='col' name='phone' className='text-left'>
                                                {t('CUSTOMERS.PHONE')}
                                            </th>
                                            <th
                                                scope='col'
                                                name='created_at'
                                                onClick={this.sort}
                                                className={`text-left sorting ${this.sortBy['created_at'] == 'DESC' ? 'up' : ''}`}
                                            >
                                                {t('COMMON.CREATED_AT')}
                                            </th>
                                            <th scope='col' className='text-center'>
                                                {t('CUSTOMERS.TOTAL_ORDER')}
                                            </th>
                                            <th scope='col' className='text-right pr-18'>
                                                {t('CUSTOMERS.TOTAL')}
                                            </th>
                                            {/* <th scope='col' className='text-left'>
                                                {t('COMMON.CREATOR')}
                                            </th> */}
                                            <th scope='col' />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(this.props.customerReducer.customers, (customer) => {
                                            return (
                                                <tr key={customer.customer_id} className='ladiui table-vertical main'>
                                                    <td>
                                                        <input
                                                            type='checkbox'
                                                            onClick={(event) => this.checkItem(event, customer.customer_id)}
                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                        />
                                                    </td>
                                                    <td>
                                                        <a onClick={() => this.openModalEdit(customer.customer_id)}>{`${baseHelper.getText(
                                                            customer.last_name
                                                        )} ${baseHelper.getText(customer.first_name)}`}</a>
                                                    </td>
                                                    <td>{customer.email}</td>
                                                    <td>{customer.phone}</td>
                                                    <td>{baseHelper.formatStrToDate(customer.created_at)}</td>
                                                    <td className='text-center'>{customer.count_order}</td>
                                                    <td className='text-right pr-18'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            customer.total,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </td>
                                                    {/* <td>
                                                        {baseHelper.getStaffNameById(
                                                            userInfo.staffs,
                                                            customer.creator_id,
                                                            userInfo.ladi_uid
                                                        )}
                                                    </td> */}
                                                    <td className='text-right pd-0'>
                                                        <div className='ladiui btn-group'>
                                                            <div className='ladiui dropdown hide-mt ba-c'>
                                                                {/* <i className="ladi-icon icon-dots ladiui dropdown-toggle select-dropdown pd-5" data-toggle="dropdown"></i> */}
                                                                <button
                                                                    data-toggle='dropdown'
                                                                    className='ladiui-btn-dropdown dropdown-toggle'
                                                                >
                                                                    <i className='ladiui icon icon-ldp-dot'></i>
                                                                </button>
                                                                <ul className='ladiui dropdown-menu r-0'>
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() => this.openModalEdit(customer.customer_id)}
                                                                        >
                                                                            <i className='new-ldicon-edit' />
                                                                            <span> {t('ACTIONS.EDIT')} </span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a
                                                                            className='ladiui dropdown-item'
                                                                            onClick={() => this.openOptionDeleteModal(customer.customer_id)}
                                                                        >
                                                                            <i className='ladiui icon-new new-ldicon-delete' />
                                                                            <span className='ladiui text-danger'>
                                                                                {t('ACTIONS.DELETE')}
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            {totalRecord <= 0 && (
                                <div className='table-empty'>
                                    <i className='new-ldicon-search' />
                                    <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                                    <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                                </div>
                            )}
                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('PAGINATION.ITEMS')}
                                    message={''}
                                    items={this.props.customerReducer.customers}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            )}
                        </div>
                    )}
                </div>

                {this.state.isShowModalCustomer && (
                    <ModalCustomer
                        ref={this.formRef}
                        currentCustomerInfo={this.state.currentCustomerInfo}
                        mode={this.state.mode}
                        visible={this.state.isShowModalCustomer}
                        onCancel={this.hideModalCustomer}
                        onSubmit={this.submit}
                        loading={isSubmitLoading}
                    />
                )}

                <ConfirmModal
                    id='confirm-customer-delete'
                    title={t('CUSTOMERS.MSG_DELETE_CUSTOMER_TITLE')}
                    content={t('CUSTOMERS.MSG_DELETE_CUSTOMER_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(this.selectedID)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id='confirm-customer-deletes'
                    title={t('CUSTOMERS.MSG_DELETE_CUSTOMER_TITLE')}
                    content={t('CUSTOMERS.MSG_DELETE_CUSTOMERS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deletes(this.state.selectedIDs)}
                    isLoading={isLoadingDelete}
                />

                {this.state.isShowModalExportCustomer && (
                    <ModalExportCustomer
                        visible={this.state.isShowModalExportCustomer}
                        isLoadingExportCustomer={isLoadingExportCustomer}
                        onExportCustomer={this.exportCustomer}
                        onCancel={() => {
                            this.setState({
                                isShowModalExportCustomer: false,
                            });
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create: (customer) => dispatch(customerActions.create(customer)),
        update: (customer) => dispatch(customerActions.update(customer)),
        list: (data) => dispatch(customerActions.list(data)),
        show: (customerID) => dispatch(customerActions.show(customerID)),
        reload: (customerID) => dispatch(customerActions.reload(customerID)),
        deletes: (customerIDs) => dispatch(customerActions.deletes(customerIDs)),
        delete: (customerID) => dispatch(customerActions.delete(customerID)),
        exportCustomer: (data) => dispatch(customerActions.exportCustomer(data)),
        importCustomer: (form) => dispatch(customerActions.importCustomer(form)),
        listAllTags: () => dispatch(customerTagActions.listAll()),
    };
};

const mapStateToProps = (state) => ({
    customerReducer: { ...state.customer },
    customerTagReducer: { ...state.customerTag },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Customer));
