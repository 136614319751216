import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Product from './Product';
import ProductCategory from '../products/Category';
import Inventory from './Inventory';
import PageManageReview from './components/PageManageReviews';
import ProductListTag from './components/Tag/ProductListTag';
import ProductListCustomField from './components/CustomField/ProductListCustomField';

export default function ProductV2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const { search } = useLocation();

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const [tabSelected, setTabSelected] = useState(1);

    const [filters, setFilter] = useState({
        fromDate: moment(new Date()).subtract(30, 'd'),
        toDate: moment(new Date()),
    });

    useEffect(() => {
        const tab = queryStrings.get('tab');
        setTabSelected(tab || 1);
    }, [queryStrings]);

    const selectTab = (index) => {
        queryStrings.set('tab', index);

        props.history.replace({ search: queryStrings.toString() });
        // setTabSelected(index);
    };

    return (
        <div className='page-order page-product-v2'>
            <div className='menu-left'>
                <div className='title'>{t('Sản phẩm')}</div>
                <div className='menu-list'>
                    <div
                        className={`menu-item ${tabSelected == 1 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(1);
                        }}
                    >
                        <i className='new-ldicon-box-sub-menu' />
                        <span>{t('Danh sách sản phẩm')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 2 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(2);
                        }}
                    >
                        <i className='new-ldicon-warehouse' />
                        <span>{t('Quản lý kho')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 3 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(3);
                        }}
                    >
                        <i className='new-ldicon-template' />
                        <span>{t('Danh mục sản phẩm')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 4 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(4);
                        }}
                    >
                        <i className='new-ldicon-review' />
                        <span>{t('Quản lý đánh giá')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 5 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(5);
                        }}
                    >
                        <i className='new-ldicon-tag' />
                        <span>{t('Quản lý Tag')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 6 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(6);
                        }}
                    >
                        <i className='new-ldicon-custom-field' />
                        <span>{t('Trường tùy chỉnh')}</span>
                    </div>
                </div>
            </div>
            <div className='page-max'>
                <div className='page-right'>
                    {tabSelected == 1 && <Product location={location} history={props.history} />}
                    {tabSelected == 2 && <Inventory />}
                    {tabSelected == 3 && <ProductCategory selectedProductType='Product' location={location} history={props.history} />}
                    {tabSelected == 4 && <PageManageReview history={props.history} />}
                    {tabSelected == 5 && <ProductListTag />}
                    {tabSelected == 6 && <ProductListCustomField />}
                </div>
            </div>
        </div>
    );
}
