import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input';

import appConfig from '../../../config/app';
import Dropdown from '../../../components/Dropdown';

import { produce, find, compact, map, includes, remove } from 'lodash';
import baseHelper from '../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function ModalAddCategory(props) {
    const { t, onClose, isShow, innerStyle, dataSelectCategories, selectedCategories } = props;
    const wrapperRef = useRef();

    const dispatch = useDispatch();

    const inputsRef = new Set();

    const [dataCategories, setDataCategories] = useState([]);
    const [selectedCates, setSelectedCates] = useState([]);
    const [categoriesSelected, setCategoriesSelected] = useState([]);

    const eventCancel = () => {
        onClose(false);
    };

    useEffect(() => {
        if (dataSelectCategories && dataSelectCategories.length > 0) {
            setDataCategories(dataSelectCategories);
        }
    }, [dataSelectCategories]);

    const getMenus = async (menuIds) => {
        let Menus = [];
        for await (let menu of menuIds) {
            Menus.push(menu);
            if (menu.children) {
                let _Menu = await getMenus(menu.children);
                Menus = [...Menus, ..._Menu];
            }
        }

        return Menus;
    };

    useEffect(() => {
        const getMenuSelected = async () => {
            let _selectedCategories = await getMenus(selectedCategories);
            setSelectedCates(_selectedCategories);
        };
        if (selectedCategories && selectedCategories.length > 0) {
            getMenuSelected();
        }
    }, [selectedCategories]);

    const checkItem = (event, item) => {
        const { checked } = event.target;

        // let _selectedCategories = [...selectedCates];
        let _selectedCategories = [...categoriesSelected];

        if (checked) {
            let isSelected = _selectedCategories.some((value) => value.product_category_id == item.product_category_id);
            if (!isSelected) {
                _selectedCategories.push(item);
            }
        } else {
            remove(_selectedCategories, (value) => value.product_category_id === item.product_category_id);
        }

        // setSelectedCates(_selectedCategories);
        setCategoriesSelected(_selectedCategories);
    };

    const checkAllItem = (event) => {
        let _categories = [];
        let _selected_cate_ids = selectedCates && selectedCates.length > 0 ? selectedCates.map((item) => item.product_category_id) : [];
        const { checked } = event.target;
        if (checked) {
            // setSelectedCates(dataCategories)
            if (dataCategories && dataCategories.length > 0) {
                map(dataCategories, (item) => {
                    if (!_selected_cate_ids.includes(item.product_category_id)) {
                        _categories.push(item);
                    }
                });
                // setDataCategories(_categories);
                setCategoriesSelected(_categories);
            }
        } else {
            // setDataCategories([]);

            setCategoriesSelected([]);
        }
    };

    const renderItem = () => {
        return map(dataCategories, (item, index) => {
            let isExits = selectedCates.some((value) => value.product_category_id == item.product_category_id);
            if (!isExits) {
                return (
                    <li
                        className='ladiui check-element'
                        key={index}
                        style={{ marginLeft: item.level && item.level > 1 ? (item.level - 1) * 16 + 'px' : '' }}
                    >
                        <label className='ladiui search-checkbox default-text flex cursor-pointer' data-id={index}>
                            <input
                                name='checkbox'
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-0'
                                onChange={(event) => checkItem(event, item)}
                                checked={categoriesSelected.some((value) => value.product_category_id == item.product_category_id)}
                            />
                            {item.level && item.level > 1 && <i className='ldicon-subcategory ml-8' />}
                            <p className='ml-8'>
                                {item.name}{' '}
                                {item.isShow == 0 ? <span style={{ fontSize: '12px', color: '#D94F45' }}>(Danh mục ẩn)</span> : ''}
                            </p>
                        </label>
                    </li>
                );
            }
        });

        // return items;
    };

    const handleAddCategories = (e) => {
        e.preventDefault();
        // props.handleAddCategories(selectedCates)
        props.handleAddCategories(categoriesSelected);
    };

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-category-child'>
            <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>Chọn danh mục</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body' style={{ position: 'initial' }}>
                        {dataCategories && dataCategories.length > 0 && (
                            <div className='block-list-cates'>
                                <ul>{renderItem()}</ul>
                                <div className='flex mt-24'>
                                    <input
                                        name='checkAll'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        onChange={(event) => checkAllItem(event)}
                                        checked={categoriesSelected.length == dataCategories.length - selectedCates.length}
                                    />
                                    <span>Chọn tất cả</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='ladiui modal-footer'>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary bold'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.CANCEL')}
                            </button>
                            <button
                                type='button'
                                data-dismiss='modal'
                                onClick={(e) => handleAddCategories(e)}
                                data-target='add-product'
                                className='ladiui btn btn-primary bold ml-16'
                            >
                                {t('ACTIONS.ADD_CATEGORY')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalAddCategory);
