import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../config/app';

import * as paymentTypes from '../../../../../redux/futures/payment/types';
import paymentActions from '../../../../../redux/futures/payment/actions';
import config from '../../../../../config/config';

// eslint-disable-next-line max-lines-per-function

const PaymentMethod = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { isShow, pageCheckout } = props;

    const paymentReducer = useSelector((state) => state.payment);

    const gateways = useMemo(() => {
        return paymentReducer.gateways || [];
    }, [paymentReducer.gateways]);

    const [paymentMethod, setPaymentMethod] = useState([]);
    const [checkoutConfigID, setCheckoutConfigID] = useState(null);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let numActive = 0;
            let configPayment = paymentMethod.map((item) => {
                if (item.isEnable) numActive += 1;
                return {
                    code: item.code,
                    is_active: item.isEnable,
                    methods: item.paymentMethod,
                };
            });

            if (numActive == 0) {
                return {
                    error: 'Bạn phải chọn ít nhất 1 hình thức thanh toán',
                };
            }

            return configPayment;
        },
    }));

    useEffect(() => {
        dispatch(paymentActions.listGateways());
    }, []);

    useEffect(() => {
        let gatewaysTg = gateways.map((item) => {
            item.isEnable = true;
            let paymentMethod = item.config.active_payment_methods;
            item.paymentMethod = paymentMethod;
            item.isShowMethod = false;
            return item;
        });

        if (pageCheckout && pageCheckout.page_checkout_id) {
            let checkout_config = pageCheckout.checkout_config || {};

            if (checkout_config.checkout_config_id && checkout_config.type == appConfig.CHECKOUT_CONFIG_TYPE.CUSTOMIZE) {
                setCheckoutConfigID(checkout_config.checkout_config_id);

                let config = checkout_config.config || {};
                let config_payments = config.config_payment || [];

                gatewaysTg = config_payments.map((item) => {
                    let gateway = gateways.find((_item) => _item.code == item.code);

                    item.isEnable = item.is_active || false;
                    item.isShowMethod = false;
                    item.paymentMethod = item.methods || [];

                    if (gateway) {
                        let paymentMethod = gateway.config.active_payment_methods;
                        // item.paymentMethod = item.paymentMethod.filter((_item) => paymentMethod.includes(_item));
                        item.paymentMethod = gateway.paymentMethod;
                        item.name = gateway.name;
                    }
                    return item;
                });
            }
        }

        setPaymentMethod(gatewaysTg);
    }, [pageCheckout, gateways]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('checkout-config');

        history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        let remove = list[startIndex];
        list.splice(startIndex, 1);
        list.splice(endIndex, 0, remove);

        return list;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let paymentMethodTG = [...paymentMethod];
        if (result.type == 'droppableItem') {
            paymentMethodTG = reorder(paymentMethodTG, result.source.index, result.destination.index);

            setPaymentMethod(paymentMethodTG);
        } else if (result.type.includes('droppableSubItem')) {
            const parentCode = result.type.split('-')[1];
            const indexGateway = paymentMethodTG.findIndex((item) => item.code == parentCode);

            if (!parentCode) return;

            const itemSubItemMap = paymentMethodTG[indexGateway];

            const reorderedSubItems = reorder(itemSubItemMap.paymentMethod, result.source.index, result.destination.index);

            paymentMethodTG[indexGateway].paymentMethod = reorderedSubItems;
            setPaymentMethod(paymentMethodTG);
        }

        // const _columnSelected = reorder(columnSelected, result.source.index, result.destination.index);
        // setColumnSelected(_columnSelected);
    };

    const handleEnableMethod = (index) => {
        let paymentMethodTg = [...paymentMethod];

        paymentMethodTg[index].isEnable = !paymentMethodTg[index].isEnable;
        setPaymentMethod(paymentMethodTg);
    };

    const handleOpenMethod = (index) => {
        let paymentMethodTg = [...paymentMethod];

        paymentMethodTg[index].isShowMethod = !paymentMethodTg[index].isShowMethod;
        setPaymentMethod(paymentMethodTg);
    };

    const getDisplayPaymentByCode = (parentCode, childCode) => {
        if (parentCode && childCode) {
            let listMethod = appConfig.PAYMENT_METHODS[parentCode] ? appConfig.PAYMENT_METHODS[parentCode].TYPE : null;

            if (!listMethod) return '';

            const result = Object.values(listMethod).find((item) => item.CODE == childCode);
            if (result) {
                return result.NAME;
            }
        }
        return childCode;
    };

    return (
        <div className={`page-checkout-config-item  page-checkout-product-list ${isShow ? 'show' : 'hide'}`}>
            <div className='header'>
                <div className='display-flex-center gap-16'>
                    <div className='icon-back' onClick={backMenu}>
                        <i className='ldicon-chevron-left' />
                    </div>
                    <span className='ladiui title-header'>{t('PAGE_CHECKOUT.MENU.PAYMENT_METHOD')}</span>
                </div>
            </div>
            {/* <div className='payment-list'> */}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='gateway' type='droppableItem'>
                    {(provided, snapshot) => (
                        <div
                            className='gateway-list'
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            // style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {paymentMethod.map((item, index) => (
                                <Draggable key={item.code} draggableId={item.code} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            className='gateway-item'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className={`gateway-item-head ${item.isShowMethod ? 'active' : ''}`}>
                                                <div className='display-flex-center gap-16'>
                                                    <div>
                                                        <i className='icon-move' />
                                                    </div>
                                                    <div className='ladiui item-form switch '>
                                                        <label className='ladiui switch m-0'>
                                                            <input
                                                                type='checkbox'
                                                                checked={item.isEnable}
                                                                onChange={() => {
                                                                    handleEnableMethod(index);
                                                                }}
                                                            />
                                                            <span className='ladiui slider round'></span>
                                                        </label>
                                                    </div>
                                                    <div className='gateway-name'>{item.name}</div>
                                                </div>
                                                {item.paymentMethod && (
                                                    <div className='gateway-open-method' onClick={() => handleOpenMethod(index)}>
                                                        <i className='new-ldicon-arrow-up' />
                                                    </div>
                                                )}
                                            </div>
                                            {item.paymentMethod && item.isShowMethod && (
                                                <Droppable droppableId={`method_${item.code}`} type={`droppableSubItem-${item.code}`}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className='method-list'
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            // style={getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {item.paymentMethod.map((method, _index) => (
                                                                <Draggable
                                                                    key={`${item.code}_${method}`}
                                                                    draggableId={`${item.code}_${method}`}
                                                                    index={_index}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            className='method-item'
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <div className='display-flex-center gap-12'>
                                                                                <i className='icon-move' />
                                                                                <div className='gateway-name'>
                                                                                    {getDisplayPaymentByCode(item.code, method)}
                                                                                </div>
                                                                            </div>
                                                                            {/* {provided.placeholder} */}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            )}
                                            {/* {provided.placeholder} */}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {/* </div> */}

            {/* <a className='btn-add-payment ' href={`/settings?tab=PAYMENT`} target='_blank'> */}
            <a className='btn-add-payment ' href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.SETTING_PAYMENT_GATEWAY}`} target='_blank'>
                <i className='new-ldicon-add-circle' />
                <span className='ladiui text-brand'>Thêm phương thức thanh toán</span>
            </a>
        </div>
    );
});

export default PaymentMethod;
