import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import Input from '../../../components/Input';
import { Editor } from '@tinymce/tinymce-react';
import { map, compact, cloneDeep, find, pick, remove } from 'lodash';
import Dropdown from '../../../components/Dropdown';
import DatePicker from '../../../components/DatePicker/CustomDatePicker';
import timezones from '../../../config/timezone';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import actionsProductTag from '../../../redux/futures/product_tag/actions';

import actionsProduct from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import productImageActions from '../../../redux/futures/product_image/actions';
import * as productImageTypes from '../../../redux/futures/product_image/types';

import storePageActions from '../../../redux/futures/store_page/actions';
import * as storePageTypes from '../../../redux/futures/store_page/types';

import * as productCategoryTypes from '../../../redux/futures/product_category/types';
import productCategoryActions from '../../../redux/futures/product_category/actions';

import productVariantActions from '../../../redux/futures/product_variant/actions';
import * as productVariantTypes from '../../../redux/futures/product_variant/types';

import ListImageProduct from '../../../components/ListProductImageV2';
import ConfirmModal from '../../../components/ConfirmModal';

import PanelTicketSeat from '../../events/components/PanelTicketSeat';
import PanelProductUpsellV4 from '../../../components/PanelProductUpsellV4';

import PanelProductSellSocial from '../../../components/PanelProductSeoSocial';
import Header from './components/Header';
import { ProductInfo } from './ProductInfo';
import LoadingScene from '../../../components/LoadingScene';
import PanelPackageSeat from '../../services/components/PanelPackageSeat';
import PanelPageProduct from '../../../components/PanelPageProduct';

function DigitalCreate(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { t } = useTranslation();

    // const inputsRef = new Set();
    const productInfoRef = useRef();
    const panelSeoSocialRef = useRef();
    const productUpsellRef = useRef();
    const highlightProductRef = useRef();

    const { productID } = useParams();

    const moment = baseHelper.getMoment();

    const productCategoryReducer = useSelector((state) => state.productCategory);
    const storePageReducer = useSelector((state) => state.storePage);
    const productImageReducer = useSelector((state) => state.productImage);
    const storeReducer = useSelector((state) => state.store);
    const fileReducer = useSelector((state) => state.file);
    const productReducer = useSelector((state) => state.product);
    const productVariantReducer = useSelector((state) => state.variant);

    const productLoading = useMemo(() => {
        return productReducer.loading || false;
    }, [productReducer.loading]);

    const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);

    const [product, setProduct] = useState({
        product_id: '',
        product_variant_id: '',
        name: '',
        alias_name: '',
        domain: '',
        path: '',
        description: '',
        quantity: 0,
        content: '',
        type: appConfig.PRODUCT_TYPE.DIGITAL.CODE,
        checkout_config_id: '',
        status: appConfig.PRODUCT_STATUS.ACTIVE.CODE,
        external_link: '',
        ticket_creation_form: appConfig.TICKET_CREATION_FORM.AUTO.CODE,
        tags: [],
        select_many_service: true,
        cta: 0,
        selectedIconCTA: 'cta-add',
        seo: {},
        is_one_time: false,
        quantity_sold: 0,
        short_description: '',
        page_checkout: null,
    });

    const listProduct = [appConfig.PRODUCT_TYPE.PHYSICAL.CODE, appConfig.PRODUCT_TYPE.F_B.CODE, appConfig.PRODUCT_TYPE.COMBO.CODE];

    const [stores, setStores] = useState([]);
    const [selectedStores, setSelectedStores] = useState([]);
    const [isShowAllStore, setIsShowAllStore] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [isEditProduct, setIsEditProduct] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const [selectedTypeProduct, setSelectedTypeProduct] = useState('');

    const [selectedImage, setSelectedImage] = useState('');
    const [selectedProductUpsells, setSelectedProductUpsells] = useState([]);

    useEffect(() => {
        let payload = {
            search: {
                type: 'Product',
            },
        };

        const dataListStore = {
            keyword: '',
            search: {},
            paged: 1,
            limit: 20,
        };

        let type = queryURL.get('type');
        if (!type || !listProduct.includes(type)) {
            type = appConfig.PRODUCT_TYPE.PHYSICAL.CODE;
        }

        setSelectedTypeProduct(type);

        dispatch(storePageActions.storePageList(dataListStore));
        dispatch(actionsProductTag.listAll());
        dispatch(productCategoryActions.listSelectCategory(payload));
        let is_copy = queryURL.get('is_copy');

        if (productID && !is_copy) {
            dispatch(actionsProduct.show(productID));
            setIsEditProduct(true);
            setMode(appConfig.FORM_MODE.EDIT);
        }

        if (is_copy == 'true' && productID) {
            setIsEditProduct(false);
            dispatch(actionsProduct.show(productID));
        }
    }, [productID]);

    useEffect(() => {
        if (storePageReducer.action == storePageTypes.STORE_PAGE_LIST) {
            if (storePageReducer.status) {
                if (storePageReducer.store_pages && storePageReducer.store_pages.length > 0) {
                    let _data = [];
                    let _stores = [];
                    storePageReducer.store_pages.map((item, index) => {
                        _data.push({
                            name: item.name,
                            value: item.page_checkout_id,
                        });

                        _stores.push(item.page_checkout_id);
                    });

                    setSelectedStores(_stores);

                    setStores(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', storePageReducer.message, 'OK');
            }
        }
    }, [storePageReducer]);

    useEffect(() => {
        if (productReducer.action == productTypes.SHOW_PRODUCT) {
            if (productReducer.status) {
                let _dataProduct = { ...productReducer.product };
                if (!isEditProduct) {
                    delete _dataProduct.product_id;
                    _dataProduct.name += '-Copy';
                }
                setProduct(_dataProduct);
                setSelectedProductUpsells(_dataProduct.product_up_sells || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.RELOAD_PRODUCT) {
            if (productReducer.status) {
                let _dataProduct = { ...productReducer.product };
                setProduct(_dataProduct);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.CREATE_PRODUCT) {
            if (productReducer.status) {
                window.LadiUI.toastCustom('success', '', productReducer.message);
                history.push(`/digital/update/${productReducer.product.product_id}`);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
        if (productReducer.action == productTypes.UPDATE_PRODUCT) {
            if (productReducer.status) {
                window.LadiUI.toastCustom('success', '', productReducer.message);
                dispatch(actionsProduct.reload(product.product_id));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {
        if (productVariantReducer.action == productVariantTypes.DELETE_VARIANT) {
            if (productVariantReducer.status) {
                dispatch(actionsProduct.reload(product.product_id));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productVariantReducer.message, 'OK');
            }
        }
    }, [productVariantReducer]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                if (productCategoryReducer.listSelects && productCategoryReducer.listSelects.length > 0) {
                    let _data = [...categories];
                    productCategoryReducer.listSelects.map((item, index) => {
                        const isExitCategory = _data.some((_item) => _item.value == item.product_category_id);
                        if (!isExitCategory) {
                            _data.push({
                                level: item.level,
                                name: item.name,
                                value: item.product_category_id,
                            });
                        }
                    });

                    setCategories(_data);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    const submit = () => {
        let product = productInfoRef.current.getData();

        if (product.error) {
            window.LadiUI.toastCustom('danger', '', product.error);
            return;
        }

        let highlight_display = {};
        let quantity_sold = 0;
        if (highlightProductRef && highlightProductRef.current) {
            let highlights = highlightProductRef.current.getData();
            highlight_display = highlights.highlight_display;
            quantity_sold = Number(highlights.quantity_sold);
        }

        let productReq = {
            ...product,
            type: appConfig.PRODUCT_TYPE.DIGITAL.CODE,
            product_up_sells: selectedProductUpsells,
            seo: panelSeoSocialRef.current.getData(),
            highlight_display,
            quantity_sold,
        };

        if (!productReq.product_id) dispatch(actionsProduct.create(productReq));
        else {
            delete productReq.options;
            dispatch(actionsProduct.update(productReq));
        }
    };

    const deleteImageProduct = () => {
        dispatch(productImageActions.delete(product.product_id, selectedImage.src_hash));
    };

    const handleSelectProductUpsell = (variant, quantity = 1) => {
        let _selectedProducts = [...selectedProductUpsells];
        let productItem = {
            product_id: variant.product_id,
            name: variant.name,
            product_variant_id: variant.product_variant_id,
            position: selectedProductUpsells.length,
            src: variant.src,
            price: variant.price,
            quantity: variant.quantity,
        };

        _selectedProducts.push(productItem);

        setSelectedProductUpsells(_selectedProducts);
    };

    const handleDeleteProductUpsell = (productVariantID) => {
        let _products = selectedProductUpsells.filter((item) => item.product_variant_id != productVariantID);
        setSelectedProductUpsells(_products);
    };

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);
        list = list.map((item, index) => {
            item.position = index;
            return item;
        });

        return list;
    };

    const onDragEndProductUpSell = (result) => {
        if (!result.destination) {
            return;
        }

        const _products = reorder(selectedProductUpsells, result.source.index, result.destination.index);
        setSelectedProductUpsells(_products);
    };

    return (
        <div className='page-product' id='page-product-new'>
            {productLoading && <LoadingScene blur={true} />}
            <Header
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
                isEditProduct={isEditProduct}
                submit={submit}
                productData={product}
            />
            <div className='page-content-product-new'>
                <div className='ladiui page-content content-max-width'>
                    <div className='ladiui tab-content form-setting max-width-content' id='myTabContent'>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 1 ? 'show open' : ''}`} id='tab-product-info'>
                            <ProductInfo ref={productInfoRef} productData={product} isEditProduct={isEditProduct} />
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 2 ? 'show open' : ''}`} id='tab-event-code-management'>
                            <PanelPackageSeat
                                eventID={product.product_id}
                                ticketCreationForm={product.ticket_creation_form}
                                currentProductInfo={product}
                                options={product.options || []}
                            />
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 3 ? 'show open' : ''}`} id='tab-product-upsell'>
                            <PanelProductUpsellV4
                                ref={productUpsellRef}
                                selectedProductUpsells={selectedProductUpsells}
                                handleSelectProductUpsell={handleSelectProductUpsell}
                                handleDeleteProductUpsell={handleDeleteProductUpsell}
                                onDragEndProductUpSell={onDragEndProductUpSell}
                                productInfo={product}
                            />
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 4 ? 'show open' : ''}`} id='tab-product-seo-social'>
                            <div className='page-content-product-seo-social'>
                                <div className='block-content-item'>
                                    <h3>Thiết lập SEO & Social</h3>
                                    <PanelProductSellSocial ref={panelSeoSocialRef} productInfoSeo={product.seo || {}} />
                                    <div className='block-info-help flex items-center'>
                                        <i className='ladi-icon icon-c-question mr-8'></i>
                                        <span>
                                            Thiết lập SEO & Social giúp khách hàng dễ dàng tìm kiếm sản phẩm trên các công cụ tìm kiếm như
                                            Google.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`ladiui tab-pane fade ${selectedTabIndex === 5 ? 'show open' : ''}`} id='tab-product-highlight'>
                            <div className='page-content-product-seo-social'>
                                <div className='block-content-item'>
                                    <PanelPageProduct
                                        ref={highlightProductRef}
                                        quantitySold={product.quantity_sold || ''}
                                        highlightDisplay={product.highlight_display || {}}
                                        productType={product.type}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmModal
                style={{ zIndex: 1050 }}
                id='confirm-image-delete'
                title={t('PRODUCTS.MSG_DELETE_IMAGE_TITLE')}
                content={t('PRODUCTS.MSG_DELETE_IMAGE_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={deleteImageProduct}
                // isLoading={isLoadingDelete}
            />
        </div>
    );
}

export default DigitalCreate;
