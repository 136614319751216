import React from 'react';
import PropTypes from 'prop-types';
import { map, remove } from 'lodash';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';

ModalInfusion.propTypes = {
    formAccount: PropTypes.any,
    onChangeInput: PropTypes.func
}

export default function ModalInfusion(props) {

    const { formAccount, inputRefs, onChangeInput, handleSubmit, mode } = props;
    const { t } = useTranslation();

    return (
        <div>
            <div className="content">
                <div className="ladiui form-group">
                    <label className="ladiui-label">{t('INTEGRATE.ACCOUNT.LB_NAME')}</label>
                    <Input
                        ref={(ref) => inputRefs.add(ref)}
                        name="name"
                        validationName={t('INTEGRATE.ACCOUNT.LB_NAME')}
                        placeholder={t('INTEGRATE.ACCOUNT.LB_NAME')}
                        value={formAccount.name}
                        onChange={onChangeInput}
                        validations={{ isRequired: true }}
                    />
                </div>
                {/* <div className="ladiui form-group">
                    <div className="text-center">
                        {t('INTEGRATE.ACCOUNT.LB_INFUSION_SOFT')}
                    </div>
                </div> */}
                <div className='text-center mt-24'>
                    <button className='ladiui btn btn-secondary flex items-center mx-auto' onClick={() => handleSubmit()}>
                        <img src='https://w.ladicdn.com/ladiui/icons/ldicon-logo-infusionsoft.svg' />
                        <span className='ml-8'>Kết nối với Infusion</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

