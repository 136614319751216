import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const deleteTag = (data) => {
    // const data = {
    //     customer_tag_id: customerTagID,
    // }
    return {
        type: types.CUSTOMER_TAG_DELETE,
        meta: {
            endpoint: endpoint.CUSTOMER_TAG_DELETE,
            method: REQUEST_METHOD.POST,
            body: data,
            hasAuth: true,
        },
    };
};

const search = (name) => ({
    type: types.SEARCH_CUSTOMER_TAG,
    meta: {
        endpoint: endpoint.CUSTOMER_TAG_SEARCH,
        method: REQUEST_METHOD.POST,
        body: {
            search: name,
        },
        hasAuth: true,
    },
});

const listAll = () => ({
    type: types.LIST_ALL_CUSTOMER_TAG,
    meta: {
        endpoint: endpoint.CUSTOMER_TAG_LIST_ALL,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    },
});

const list = (data) => ({
    type: types.LIST_CUSTOMER_TAG,
    meta: {
        endpoint: endpoint.CUSTOMER_TAG_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const update = (customerTag) => ({
    type: types.UPDATE_CUSTOMER_TAG,
    meta: {
        endpoint: endpoint.CUSTOMER_TAG_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            customer_tag: customerTag,
        },
        hasAuth: true,
    },
});

const create = (customerTag) => ({
    type: types.CREATE_CUSTOMER_TAG,
    meta: {
        endpoint: endpoint.CUSTOMER_TAG_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            customer_tag: customerTag,
        },
        hasAuth: true,
    },
});

export default {
    deleteTag,
    search,
    listAll,
    list,
    update,
    create,
};
