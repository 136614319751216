export const LIST_CATEGORY = "LIST_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORIES = "DELETE_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const RELOAD_CATEGORY = "RELOAD_CATEGORY";
export const LIST_SELECT_CATEGORY = "LIST_SELECT_CATEGORY";
export const SHOW_CATEGORY = "SHOW_CATEGORY";
export const SEARCH_CATEGORY = "SEARCH_CATEGORY";
export const UPDATE_POSITION = "UPDATE_POSITION";
