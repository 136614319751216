import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import baseHelper from '../../../../../helpers/BaseHelper';

import * as fileTypes from '../../../../../redux/futures/file/types';
import fileActions from '../../../../../redux/futures/file/actions';

import PopoverAddChannel from './PopoverAddChannel';
import ImageUpload from '../../../../../components/ImageUpload';
import Input from '../../../../../components/Input';

// eslint-disable-next-line max-lines-per-function

const PageHomeChannel = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { sectionData, channelData } = props;

    const fileReducer = useSelector((state) => state.file);

    const popoverRef = useRef();
    const imageRef = useRef();

    const [cartContener, setCartContener] = useState([]);
    const [indexSelected, setIndexSelected] = useState();

    useImperativeHandle(ref, () => ({
        getData: () => {
            return cartContener;
        },
    }));

    useEffect(() => {
        setCartContener(sectionData || []);
    }, [sectionData]);

    const reorder = (list, startIndex, endIndex) => {
        let remove = list[startIndex];
        list.splice(startIndex, 1);
        list.splice(endIndex, 0, remove);

        return list;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let cartContenerTg = [...cartContener];

        cartContenerTg = reorder(cartContenerTg, result.source.index, result.destination.index);

        setCartContener(cartContenerTg);
    };

    const handleOpenMethod = (index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg[index].isShowDetail = !cartContenerTg[index].isShowDetail;

        setCartContener(cartContenerTg);
    };

    const removeItem = (index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg.splice(index, 1);
        setCartContener(cartContenerTg);
    };

    const handelChangeChannelUrl = (value, index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg[index].value = value;
        setCartContener(cartContenerTg);
    };

    return (
        <>
            {cartContener && cartContener.length > 0 && (
                <div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='cartSetting' type='droppableCart'>
                            {(provided, snapshot) => (
                                <div
                                    className='carts-item-list channel-list'
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {cartContener.map((item, index) => (
                                        <Draggable key={`${item.action}-${index}`} draggableId={`${item.action}-${index}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className='carts-item-item'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className={`carts-item-head ${item.isShowDetail ? 'active' : ''}`}>
                                                        <div className='display-flex-center gap-16'>
                                                            <i className='icon-move' />

                                                            <div className='gateway-name '>
                                                                <div>
                                                                    <img src={item.image_url} />
                                                                </div>
                                                                <span className='name'>{item.name}</span>
                                                            </div>
                                                        </div>

                                                        <div className='gateway-open-method' onClick={() => handleOpenMethod(index)}>
                                                            <i className='new-ldicon-arrow-up' />
                                                        </div>
                                                    </div>
                                                    {item.isShowDetail && (
                                                        <div>
                                                            <div className='section-channel'>
                                                                <label>Đường dẫn</label>
                                                                <Input
                                                                    className='mt-4'
                                                                    value={item.value}
                                                                    placeholder={item.placeholder}
                                                                    onChange={(e) => {
                                                                        handelChangeChannelUrl(e.target.value, index);
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className='action-delete' onClick={() => removeItem(index)}>
                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                <span className='ladiui text-danger'>Xoá kênh tương tác</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
            <PopoverAddChannel
                addChannel={(data) => {
                    let cartContenerTg = [...cartContener];
                    cartContenerTg.push({
                        ...data,
                        action: data.name,
                        isShowDetail: true,
                    });
                    setCartContener(cartContenerTg);
                }}
            />
        </>
    );
});

export default PageHomeChannel;
