import * as types from "./types";
import * as commonTypes from "../common/types";
import { map } from "lodash";

export default (state = {}, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.PRODUCT_LIST_VIDEO: {
      return {
        ...state,
        action: types.PRODUCT_LIST_VIDEO,
        status: action.status,
        message: action.message,
        total_page: action.status ? action.payload.total_page : 0,
        total_record: action.status ? action.payload.total_record : 0,
        videos: action.status ? action.payload.videos : [],
      };
    }
    case types.PRODUCT_SHOW_VIDEO: {
      return {
        ...state,
        duration_upload_video: action.status ? action.payload.product_video.duration : 0,
        link_video_src: action.status ? action.payload.product_video.src : '',
        action: types.PRODUCT_SHOW_VIDEO,
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
