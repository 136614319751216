/** @format */

import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                done: null,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                done: action.done,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_REVIEW: {
            return {
                ...state,
                action: types.LIST_REVIEW,
                reviews: action.status ? action.payload.reviews : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_REVIEW: {
            return {
                ...state,
                action: types.CREATE_REVIEW,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_REVIEW: {
            return {
                ...state,
                action: types.UPDATE_REVIEW,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_REVIEW: {
            return {
                ...state,
                action: types.DELETE_REVIEW,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_REVIEWS: {
            return {
                ...state,
                action: types.DELETE_REVIEWS,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_REVIEWS: {
            return {
                ...state,
                action: types.CREATE_REVIEWS,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
};
