import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import baseHelper from '../helpers/BaseHelper';
import Image from './Image';
import fileActions from '../redux/futures/file/actions';
import productImageActions from '../redux/futures/product_image/actions';
import * as productImageTypes from '../redux/futures/product_image/types';
import produce from 'immer';

import ValidatorHelper from '../helpers/ValidatorHelper';

import Popover from './Popover';
import Input from './Input';

export default function ListImageProduct(props) {
    let { listImage, productID, handleSetImage, isCopy, isEditProduct } = props;
    const { t, i18n } = useTranslation();
    const popoverRef = useRef();

    const dispatch = useDispatch();
    const [imageSelect, setImageSelect] = useState({});
    const [imgSelected, setImgSelected] = useState({});

    const [targetIdx, setTargetIdx] = useState(-1);
    const [listImages, setListImages] = useState([]);

    const [selectedUrlImage, setSelectedUrlImage] = useState('');

    const productImageReducer = useSelector((state) => state.productImage);

    const uploadFile = useSelector((state) => {
        return state.file;
    });

    useEffect(() => {
        if (listImage && listImage.length > 0) {
            setImageSelect(listImage[0]);
            setListImages(listImage);
        } else {
            setImageSelect({});
            setListImages([]);
        }
    }, [JSON.stringify(listImage)]);

    useEffect(() => {
        if (productImageReducer && productImageReducer.action == productImageTypes.PRODUCT_UPDATE_POSITION) {
            if (productImageReducer.status) {
                let images = listImages;

                let imageList = images.filter((img) => img.src_hash != imgSelected.src_hash);
                imageList.unshift(imgSelected);

                imageList.map((item, index) => {
                    item.position = index;
                });

                setImageSelect(imgSelected);

                handleSetImage(imageList);

                window.LadiUI.toastCustom('success', '', productImageReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productImageReducer.message, 'OK');
            }
        }
    }, [productImageReducer]);

    const fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                console.log(' targetIdx=  ', targetIdx);
                dispatch(fileActions.uploadProductImages(form, targetIdx));
                setTargetIdx(-1);
            }
        }
    };

    const setTargetImage = (targetIndex) => {
        setTargetIdx(targetIndex);
        handleClickUploadFile3();
    }
    const deleteImage = (item) => {
        if (!isEditProduct) {
            let imageList = listImages.filter((img) => img.src != item.src);
            props.handleSetImage(imageList);
        } else {
            props.handleDeleteImage(item);
        }
    };

    const sortImage = (item) => {
        if (isEditProduct) {
            if (item.position != 0) {
                let data = {
                    product_id: productID,
                    src_hash: item.src_hash,
                    position: item.position,
                };
                setImgSelected(item);
                dispatch(productImageActions.updatePosition(data));
            } else {
                return;
            }
        } else {
            if (item.position != 0) {
                let imageList = listImages.filter((img) => img.src != item.src);
                imageList.unshift(item);

                imageList.map((item, index) => {
                    item.position = index;
                });
                setImageSelect(item);
                setListImages(imageList);
                props.handleSetImage(imageList);
                // dispatch(productActions.updatePosition(data));
            } else {
                return;
            }
        }
    };

    const handleClickInputFile = () => {
        document.getElementById('input-upload-file').click();
    };

    const handleClickUploadFile2 = () => {
        document.getElementById('input-upload-file-2').click();
    };

    const handleClickUploadFile3 = () => {
        document.getElementById('input-upload-file-3').click();
    };
    const handleAddImageUrl = () => {
        if (selectedUrlImage && ValidatorHelper.isValidHttpUrl(selectedUrlImage)) {
            props.handleSetListImageProducts(selectedUrlImage);
            popoverRef.current.instanceRef.hide();
            setSelectedUrlImage('');
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };

    return (
        <div className={`ladiui list-image-product-v2`}>
            {listImages.length == 0 ? (
                <div className='text-center image-none'>
                    <div className='ladiui-button-image'>
                        <div className='btn-add-image'>
                            <div>
                                <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-import-image.svg'} alt='' />
                            </div>
                            <div className='mt-16'>
                                <span className='text-default'>
                                    <a className='pr-4' onClick={() => handleClickInputFile()}>
                                        Tải ảnh lên
                                    </a>
                                    hoặc
                                    <Popover
                                        ref={popoverRef}
                                        wrapperProps={{ className: 'relative' }}
                                        wrapperPopoverProps={{ className: 'popover-image-product' }}
                                        // isShowPopover={isShowPopover}
                                        content={
                                            <div className='popover-add-image-product'>
                                                <div className='popover'>
                                                    <div className='ladiui form-group'>
                                                        <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                        <Input
                                                            className='ladiui form-control'
                                                            placeholder='Nhập đường dẫn sản phẩm'
                                                            name='image_url'
                                                            value={selectedUrlImage}
                                                            onChange={(event) => {
                                                                setSelectedUrlImage(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='action-footer'>
                                                        <button
                                                            className='ladiui btn btn-secondary'
                                                            onClick={() => {
                                                                popoverRef.current.instanceRef.hide();
                                                            }}
                                                        >
                                                            Hủy
                                                        </button>
                                                        <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                            Thêm hình ảnh
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <a className='pl-4'>Thêm từ URL</a>
                                    </Popover>
                                </span>
                                <p className='rec-size'>Kích thước khuyến nghị 400x400 (px)</p>
                            </div>
                        </div>
                        <input
                            className='cursor-pointer display-none'
                            id='input-upload-file'
                            type='file'
                            name='file'
                            accept='image/*'
                            multiple='multiple'
                            onChange={fileChangedHandler}
                            onClick={(event) => {
                                event.target.value = null;
                            }}
                        />
                    </div>
                </div>
            ) : (
                <>
                    <div className={`list-images`}>
                        {listImages.map((item, index) => {
                            let prefixCDN = true;
                            if (item.src && item.src.startsWith('http')) {
                                prefixCDN = false;
                            }
                            return (
                                <div className='item-image relative' key={index}>
                                    <Image
                                        prefixCDN={prefixCDN}
                                        src={item.src}
                                        key={index}
                                        onClick={() => {
                                            setImageSelect({
                                                ...imageSelect,
                                                src: item.src || '',
                                            });
                                        }}
                                    />
                                    <div
                                        className='block-icon-star cursor-pointer'
                                        onClick={() => {
                                            sortImage(item);
                                        }}
                                    >
                                        <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-outline-star.svg'} alt='' />
                                    </div>
                                    <div
                                        className='block-delete-img cursor-pointer'
                                        onClick={() => {
                                            deleteImage(item);
                                        }}
                                    >
                                        <i style={{ color: '#fff' }} className='ladi-icon icon-bin' />
                                    </div>
                                    <div
                                        className='block-reup-img cursor-pointer'
                                        onClick={() => {
                                            setTargetImage(index);
                                        }}
                                        htmlFor="input-upload-file-3"
                                    >
                                        <i style={{ color: '#fff' }} className='ldicon-upload' />
                                    </div>
                                </div>
                            );
                        })}
                        <div className='text-center'>
                            <div className='ladiui-button-upload-image'>
                                <div>
                                    <p>
                                        <a onClick={() => handleClickUploadFile2()}>Tải ảnh lên</a>
                                    </p>
                                    <span className='block my-8'>Hoặc</span>
                                    <Popover
                                        ref={popoverRef}
                                        wrapperProps={{ className: 'relative' }}
                                        wrapperPopoverProps={{ className: 'popover-image-product' }}
                                        // isShowPopover={isShowPopover}
                                        content={
                                            <div className='popover-add-image-product'>
                                                <div className='popover'>
                                                    <div className='ladiui form-group'>
                                                        <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                        <Input
                                                            className='ladiui form-control'
                                                            placeholder='Nhập đường dẫn sản phẩm'
                                                            name='image_url'
                                                            value={selectedUrlImage}
                                                            onChange={(event) => {
                                                                setSelectedUrlImage(event.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='action-footer'>
                                                        <button
                                                            className='ladiui btn btn-secondary'
                                                            onClick={() => {
                                                                popoverRef.current.instanceRef.hide();
                                                            }}
                                                        >
                                                            Hủy
                                                        </button>
                                                        <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                            Thêm hình ảnh
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <a className='pl-4'>Thêm từ URL</a>
                                    </Popover>
                                </div>
                                <input
                                    className='cursor-pointer display-none'
                                    id='input-upload-file-2'
                                    type='file'
                                    name='file'
                                    accept='image/*'
                                    multiple='multiple'
                                    onChange={fileChangedHandler}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                />

                                <input
                                    className='cursor-pointer display-none'
                                    id='input-upload-file-3'
                                    type='file'
                                    name='file-3'
                                    accept='image/*'
                                    onChange={fileChangedHandler}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
