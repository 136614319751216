import React, { useEffect, useState, useRef } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import TagSelect from '../../../../components/TagSelect';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';
import { map } from 'lodash';
import { object } from 'prop-types';

import * as filterTypes from '../../../../redux/futures/filter/types';
import filterActions from '../../../../redux/futures/filter/actions';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalRename(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { visible, hideModal, dataConditions = {} } = props;
    const [conditionName, setConditionName] = useState(dataConditions.name || '');
    const [conditionNameError, setConditionNameError] = useState('');

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        if (!conditionName) {
            setConditionNameError(t('CONDITIONS_FILTER.NANE_EMPTY'));
            return;
        }

        let dataCreate = {
            ...dataConditions,
            name: conditionName,
        };

        dispatch(filterActions.update(dataCreate));
    };

    return (
        <Modal
            width={600}
            id='modal-filter-rename'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={t('CONDITIONS_FILTER.SAVE')}
            sub_title={t('CONDITIONS_FILTER.DESCRIPTION_SAVE')}
            cancelText={t('ACTIONS.REMOVE')}
            okText={t('CONDITIONS_FILTER.SAVE')}
            isLoading={props.isLoading}
        >
            <div className='mt-16'>
                <label>{t('CONDITIONS_FILTER.CONDITION_NAME')}</label>
                <Input className={'mt-4'} value={conditionName ? conditionName : ''} onChange={(e) => setConditionName(e.target.value)} />
                {conditionNameError && <div className='ladiui error-input'> {conditionNameError}</div>}
            </div>
        </Modal>
    );
}

export default ModalRename;
