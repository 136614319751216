import * as types from "./types";
import * as commonTypes from "../common/types";

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }

        case types.LIST_BANK_HUB: {
            return {
                ...state,
                action: types.LIST_BANK_HUB,
                banks: action.status ? action.payload : [],
                status: action.status,
                message: action.message,
            };
        }

        case types.GET_BANK_ACCOUNT_HOLDER: {
            return {
                ...state,
                action: types.GET_BANK_ACCOUNT_HOLDER,
                accountHolderName: action.status ? action.payload : '',
                status: action.status,
                message: action.message,
            };
        }

        case types.CONNECT_BANK_HUB: {
            return {
                ...state,
                action: types.CONNECT_BANK_HUB,
                bank_id: action.status ? action.payload.bank_id : '',
                request_id: action.status ? action.payload.request_id : '',
                status: action.status,
                message: action.message,
            };
        }

        case types.SUBMIT_OTP_CONNECT: {
            return {
                ...state,
                action: types.SUBMIT_OTP_CONNECT,
                status: action.status,
                message: action.message,
            };
        }

        case types.SUBMIT_OTP_DELETE: {
            return {
                ...state,
                action: types.SUBMIT_OTP_DELETE,
                status: action.status,
                message: action.message,
            };
        }

        // case types.RESEND_OTP_CONNECT: {
        //     return {
        //         ...state,
        //         action: types.RESEND_OTP_CONNECT,
        //         request_id: action.status ? action.payload.request_id : '',
        //         status: action.status,
        //         message: action.message,
        //     };
        // }

        case types.REQUEST_OTP_DELETE: {
            return {
                ...state,
                action: types.REQUEST_OTP_DELETE,
                request_id: action.status ? action.payload.request_id : '',
                status: action.status,
                message: action.message,
                bankItem: action.bankItem
            };
        }

        case types.RESEND_OTP_CONNECT: {
            return {
                ...state,
                action: types.RESEND_OTP_CONNECT,
                request_id: action.status ? action.payload.request_id : '',
                status: action.status,
                message: action.message,
                bankItem: action.bankItem
            };
        }

        default:
            return state;
    }
};
