import React, { useState, useRef, useMemo, useEffect } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';

import * as productTypes from '../redux/futures/product/types';
import productActions from '../redux/futures/product/actions';

import Modal from '../components/Modal';
import Input from '../components/Input';
import LoadingTable from '../components/LoadingTable';
import OrderDefined from '../pages/orders/OrderDefined';
import NumberInput from '../components/NumberInput';

function ProductSearch(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { selectProduct, productTypes = '', type = '', selected_product_ids = [], category_ids = null, page_checkout_id = null } = props;
    const storeReducer = useSelector((state) => state.store);
    const productReducer = useSelector((state) => state.product);

    const products = useMemo(() => {
        return productReducer.products || [];
    }, [productReducer.products]);

    const productLoading = useMemo(() => {
        return productReducer.loading || false;
    }, [productReducer.loading]);

    const [productSearch, setProductSearch] = useState('');
    const [isShowProducts, setShowProducts] = useState(false);
    const [isShowProductCustom, setShowProductCustom] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        // dispatch(productVariantAction.search({ search: '' }));
        if (productReducer.action == productTypes.SEARCH_PRODUCT) {
            if (productReducer.status) {
                if (totalPage != productReducer.totalPage) setTotalPage(productReducer.totalPage);

                if (productReducer.paged != page) {
                    setPage(productReducer.paged);
                }
            }
        }
    }, [productReducer.action]);

    useEffect(() => {
        let getData = setTimeout(() => {
            setPage(1);
            dispatch(productActions.search(productSearch, type, category_ids, selected_product_ids, productTypes, page_checkout_id, page));
        }, 800);
        return () => clearTimeout(getData);
    }, [productSearch, type]);

    const onChangePage = (_page) => {
        setPage(_page);
        dispatch(productActions.search(productSearch, type, category_ids, selected_product_ids, productTypes, page_checkout_id, _page));
    };

    const searchFocus = (e) => {
        setShowProducts(true);
    };

    const searchBlur = (e) => {
        setShowProducts(false);
    };

    const handleProductSearch = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        dispatch(productActions.search(productSearch, type, category_ids, selected_product_ids, productTypes, page_checkout_id, page));
    };

    const getPriceProduct = (item) => {
        let price = 0;
        if (!item.price) {
            if (item.min_price && item.max_price) {
                if (item.min_price == item.max_price) {
                    price = baseHelper.formatMoneyPostFix(item.max_price, storeReducer.userInfo.currentStore.currency_symbol);
                } else {
                    price =
                        baseHelper.formatMoneyPostFix(item.min_price, storeReducer.userInfo.currentStore.currency_symbol) +
                        ' - ' +
                        baseHelper.formatMoneyPostFix(item.max_price, storeReducer.userInfo.currentStore.currency_symbol);
                }
            } else {
                price = baseHelper.formatMoneyPostFix(item.max_price, storeReducer.userInfo.currentStore.currency_symbol);
            }
        } else {
            price = baseHelper.formatMoneyPostFix(item.price, storeReducer.userInfo.currentStore.currency_symbol);
        }

        return price;
    };
    return (
        <>
            {/* modal tạo sản phẩm tuỳ chỉnh */}
            {isShowProducts && <div className='ladiui backdrop' onClick={searchBlur} />}
            <div className='ladiui search-group product-search' style={{ zIndex: 15 }}>
                <i className='ladiui icon icon-search' onClick={handleProductSearch}></i>
                <input
                    id='keyword_search'
                    className='ladiui search-field dropdown-toggle form-control search-width'
                    name='searchName'
                    placeholder={t('PRODUCTS.SEARCH_PRODUCT')}
                    aria-expanded='false'
                    value={productSearch}
                    onFocus={searchFocus}
                    // onBlur={searchBlur}
                    onChange={(e) => {
                        setProductSearch(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleProductSearch();
                        }
                    }}
                />
                <div className={`product-variant-search ${isShowProducts ? 'show' : ''}`}>
                    <div className='product-variant-list'>
                        {productLoading ? (
                            <LoadingTable />
                        ) : (
                            products.map((item, index) => {
                                let imageSrc = baseHelper.getImageUrl(item.src);
                                let isNotOpenForSale = baseHelper.isNotOpenForSale(item);
                                let isSoldOut = baseHelper.isSoldOut(item);

                                let product_price = getPriceProduct(item);

                                // if (!baseHelper.isAvailableVariant(item)) return null;

                                return (
                                    <div
                                        className={`product-variant-item ${isNotOpenForSale || isSoldOut ? 'disable' : ''}`}
                                        key={index}
                                        onClick={() => {
                                            if (isNotOpenForSale || isSoldOut) return;
                                            searchBlur();
                                            selectProduct(item);
                                        }}
                                    >
                                        <div className='image-name-option display-flex-center'>
                                            <img src={imageSrc} alt='variant-iamge' className='mr-8' />
                                            <div className='name-option '>
                                                <div className='variant-name ladiui label-16'>{item.name}</div>
                                            </div>
                                        </div>
                                        <div className='variant-price-stock'>
                                            <div className='ladiui flex-column-end'>
                                                <div className='variant-price ladiui label-16'>{product_price}</div>
                                                <div className='mt-4'>
                                                    {isNotOpenForSale ? (
                                                        <span className='ladiui text-danger'>{t('PRODUCTS.OVER_TIME')}</span>
                                                    ) : isSoldOut ? (
                                                        <span className='ladiui text-danger'>{t('PRODUCTS.SOLD_OUT')}</span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>

                    <div className='variant-page footer'>
                        <i
                            className={`ldicon-chevron-left ${page == 1 ? 'disable' : ''}`}
                            onClick={() => {
                                if (page == 1) return;
                                onChangePage(page - 1);
                            }}
                        />

                        <i
                            className={`ldicon-chevron-right ${page >= totalPage ? 'disable' : ''}`}
                            onClick={() => {
                                if (page >= totalPage) return;
                                onChangePage(page + 1);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default withTranslation()(ProductSearch);
