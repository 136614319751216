import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import appConfig from "../../../config/app";
import Modal from "../../../components/Modal";
import baseHelper from "../../../helpers/BaseHelper";
import { map } from "lodash";

class ModalCancel extends React.Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reason: appConfig.CANCEL_ORDER_REASONS[0],
      reasonDetail: "",
    };
  }

  submit = () => {
    let fullReason = this.state.reason;
    if (!baseHelper.isEmpty(this.state.reasonDetail)) {
      fullReason += ` (${this.state.reasonDetail})`;
    }

    this.props.onOk(fullReason);
  };

  render() {
    const { t } = this.props;
    const { reason, reasonDetail } = this.state;

    return (
      <Modal
        id="modal-cancel"
        title={t("ORDERS.MSG_CANCEL_ORDER_TITLE")}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.submit}
        width={400}
        isLoading={this.props.isLoading}
      >
        <p>{t("ORDERS.MSG_CANCEL_ORDERS_CONTENT")}</p>
        <div className="ladiui form-group" style={{ marginTop: 20 }}>
          <label className="ladiui-label">{t("ORDERS.CANCEL_ORDER_REASON_TITLE")}</label>
          <div className="ladiui btn-group w100">
            <div className="ladiui dropdown w100">
              <button
                style={{ minWidth: "100%" }}
                className="ladiui btn btn-outline-light dropdown-toggle select-dropdown"
                id="dropdownMenuButton3"
                data-toggle="dropdown"
              >
                <i></i>
                <span className="ladiui dropdown-text">{reason}</span>
              </button>
              <ul className="ladiui dropdown-menu w100">
                {map(appConfig.CANCEL_ORDER_REASONS, (item, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      this.setState({
                        reason: item,
                      });
                    }}
                  >
                    <a className="ladiui dropdown-item">{item}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("ORDERS.CANCEL_ORDER_REASON_DETAIL")}</label>
          <textarea
            className="ladiui textarea"
            value={reasonDetail}
            onChange={(event) => {
              this.setState({
                reasonDetail: event.target.value,
              });
            }}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalCancel);
