import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

import Modal from '../../../components/ModalV4';
import Input from '../../../components/Input';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../../../config/app';

import settingActions from '../../../redux/futures/setting/actions';
import * as settingTypes from '../../../redux/futures/setting/types';

export default function ModalShowContentTemplateEmail(props) {
    let { onSubmit, onCancel, isLoading, visible, selectedTemplateEmail } = props;
    const dispatch = useDispatch();

    const [isShowModalGlossary, setIsShowModalGlossary] = useState(false);
    const [dataEmail, setDataEmail] = useState('');

    const handleChangeEditor = (value) => {
        props.handleChangeEditorValue(value);
    };

    useEffect(() => {
        if (selectedTemplateEmail) {
            setDataEmail(selectedTemplateEmail);
        }
    }, [selectedTemplateEmail]);

    const getCreateOrderGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const completedOrderGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{checkout.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const orderShippingGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>

                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.tracking_number}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TRACKING_NUMBER')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const paymentSuccessGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const notifiToShopGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const notiCancelOrderGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reason_cancel}}'}</td>
                        <td>{t('GLOSSARY.ORDER.REASON_CANCEL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const successOrderProductGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note_topping}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ORDER_NOTE_TOPPING')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const successOrderServiceGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note_topping}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ORDER_NOTE_TOPPING')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const successOrderEventGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.reference_no}'}</td>
                        <td>{t('GLOSSARY.ORDER.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.ordered_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.paid_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAID_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.note_topping}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ORDER_NOTE_TOPPING')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_fee}} '}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.product_name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.last_price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const EventTicketInfoGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.EVENT_TICKET_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{event.name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.EVENT_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.TICKET.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.code}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.option_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.OPTION_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CUSTOMER_FULL_NAME')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };
    const EventTicketDetailGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.logo_url}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_LOGO')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.EVENT_TICKET_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.product_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.EVENT_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.option_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.OPTION_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.code}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CUSTOMER_FULL_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.email}}'}</td>
                        <td>{t('GLOSSARY.TICKET.EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.phone}}'}</td>
                        <td>{t('GLOSSARY.TICKET.PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{tickit.qr_code_url}}'}</td>
                        <td>{t('GLOSSARY.TICKET.QRCODE')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const ServiceTicketInfoGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.order_prefix}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ORDER_PREFIX')}</td>
                    </tr>
                    {/* ---------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>

                    {/* -------------------------------------------- */}
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.SERVICE_TICKET_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{service.name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.SERVICE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.TICKET.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.code}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.option_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.OPTION_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.TICKET.CUSTOMER_FULL_NAME')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const getDefaultDataGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store.currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_address}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.STORE_EMAIL')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.ORDER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{created_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order_prefix}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.ORDER_PREFIX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{reference_no}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.REFERENCE_NO')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.CUSTOMER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.full_shipping_address}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.customer_note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NOTE')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.price}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.ITEM_TOTAL')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PAYMENT')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_guide}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_GUIDE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{discount_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const getModalGlossary = () => {
        let data = '';
        if (dataEmail && dataEmail.CODE) {
            switch (dataEmail.CODE) {
                case 3:
                    data = successOrderProductGlossary();
                    break;
                case 4:
                    data = successOrderServiceGlossary();
                    break;
                case 5:
                    data = successOrderEventGlossary();
                    break;
                case 6:
                    data = getCreateOrderGlossary();
                    break;
                case 7:
                    data = completedOrderGlossary();
                    break;
                case 8:
                    data = orderShippingGlossary();
                    break;
                case 9:
                    data = paymentSuccessGlossary();
                    break;
                case 10:
                case 16:
                    data = notifiToShopGlossary();
                    break;
                case 11:
                    data = notiCancelOrderGlossary();
                    break;
                case 12:
                    data = EventTicketInfoGlossary();
                    break;
                case 13:
                    data = ServiceTicketInfoGlossary();
                    break;
                case 15:
                    data = EventTicketDetailGlossary();
                    break;
                default:
                    data = getDefaultDataGlossary();
                    break;
            }
        }
        return (
            <Modal
                id='modal-setting-template-email'
                title={t('COMMON.GLOSSARY')}
                visible={isShowModalGlossary}
                onCancel={() => setIsShowModalGlossary(false)}
                width={650}
                bodyStyles={{ minHeight: '40vh' }}
                hasFooter={false}
            >
                {data}
            </Modal>
        );
    };

    const restore = (e) => {
        e.preventDefault();
        if (selectedTemplateEmail.store_template_id) {
            dispatch(settingActions.restoreTemplateEmail(selectedTemplateEmail.store_template_id));
        } else {
            dispatch(settingActions.createTemplate({ code: selectedTemplateEmail.CODE }));
        }

        // this.props.restore(selectedTemplateEmail.store_template_id);
    };

    return (
        <>
            <Modal
                id='modal-content-template-email'
                title={'Chỉnh sửa nội dung email'}
                onOk={onSubmit}
                onCancel={onCancel}
                isLoading={isLoading}
                visible={visible}
                bodyStyles={{ minHeight: '40vh' }}
                width={900}
            >
                <div className='ladiui flex-between'>
                    <a className='preview-note' onClick={() => setIsShowModalGlossary(true)}>
                        Chú giải
                    </a>
                    <button type='button' onClick={restore} className='back-default ladiui btn btn-outline-secondary btn-sm'>
                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-restore.svg' alt='' className='mr-8' />
                        {t('SETTINGS.BACK_TO_DEFAULT')}
                    </button>
                </div>

                <div className='ladiui form-group'>
                    <Editor
                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                        licenseKey="gpl"
                        value={props.contentEmail}
                        init={{
                            selector: 'textarea',
                            min_height: 200,
                            max_height: 800,
                            autoresize_overflow_padding: 5,
                            autoresize_bottom_margin: 24,
                            plugins:
                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                            toolbar:
                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                            toolbar_drawer: 'sliding',
                            language_url: '../../../language/tinymce-vi.js',
                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                            // valid_elements: "*[*]",
                        }}
                        onEditorChange={handleChangeEditor}
                    />
                </div>
            </Modal>
            {getModalGlossary()}
        </>
    );
}
