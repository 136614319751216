import React from 'react';

const NoData = ({ status, column = 3, title = null, icon = null }) => (<tr>
    <td colSpan={column}>
        <div className='text-center'>
            {icon ? <div className='margin-bottom-24'>{icon}</div> : <img className='ladiui icon-not-foder' src='https://w.ladicdn.com/ladiui/ladipage/icon-not-foder.svg' alt="" />}
            {title && <div className='ladiui main-title text-18' style={{ color: 'var(--text-secondary, #636363)' }}>{title}</div>}
            <span style={{ color: 'var(--text-primary, #636363)' }} dangerouslySetInnerHTML={{ __html: status }}></span>
        </div>
    </td>
</tr>
);

export default NoData;