import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as orderTagTypes from '../../redux/futures/order_tag/types';
import orderTagAction from '../../redux/futures/order_tag/actions';

import Order from './Order';
import Checkout from '../checkouts/Checkout';
import Shipping from '../shippings/Shipping';

import PageOrderTag from '../order_tags/PageOrderTag';
import OrderCustomField from './OrderCustomField/OrderCustomField';
import Input from '../../components/Input';
import BaseHelper from '../../helpers/BaseHelper';

export default function OrderV2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    // order_tags
    const orderTagReducer = useSelector((state) => state.orderTag);

    const order_tags = useMemo(() => {
        return orderTagReducer.order_tags || [];
    }, [orderTagReducer.order_tags]);

    // const queryStrings = new URLSearchParams(location.search);
    const tab = queryStrings.get('tab');

    const [listOrder, setListOrder] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [tabSelected, setTabSelected] = useState(tab || 1);
    const [tagSelected, setTagSelected] = useState([]);
    const [isShowSearch, setShowSearch] = useState(false);
    const [searchTag, setSearchTag] = useState('');

    const [filters, setFilter] = useState({
        fromDate: moment(new Date()).subtract(30, 'd'),
        toDate: moment(new Date()),
    });

    useEffect(() => {
        const tab = queryStrings.get('tab');
        const action = queryStrings.get('action');
        if (tab == 1) {
            dispatch(orderTagAction.listAll());
        }
        setTabSelected(tab || 1);
    }, [queryStrings]);

    useEffect(() => {
        if (queryStrings && order_tags.length > 0) {
            let tags = queryStrings.get('tags');
            if (!tags) {
                setTagSelected([]);
            } else {
                tags = tags.split('-');
                let tagTg = order_tags.filter((item) => tags.includes(item.order_tag_id + ''));
                setTagSelected(tagTg);
            }
        }
    }, [queryStrings, order_tags]);

    const selectTab = (index) => {
        let _queryStrings = new URLSearchParams();
        _queryStrings.set('tab', index);
        props.history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const selectTags = (data) => {
        let index = tagSelected.findIndex((item) => item.order_tag_id == data.order_tag_id);
        let tagTg = [...tagSelected];

        if (index == -1) {
            tagTg.push(data);
        } else {
            tagTg.splice(index, 1);
        }

        // let _queryStrings = new URLSearchParams();
        let tags = queryStrings.get('tags');
        if (!tags) tags = [];
        else tags = tags.split('-');

        let index2 = tags.findIndex((item) => item == data.order_tag_id);
        if (index2 == -1) {
            tags.push(data.order_tag_id);
        } else {
            tags.splice(index2, 1);
        }

        tags = tags.join('-');

        queryStrings.set('tags', tags);
        props.history.replace({ search: queryStrings.toString() });
        // setTagSelected(tagTg);
    };

    return (
        <div className='page-order'>
            <div className='menu-left'>
                <div className='title'>{t('ORDERS.SLIDEBAR_TITLE')}</div>
                <div className='menu-list'>
                    <div
                        className={`menu-item ${tabSelected == 1 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(1);
                        }}
                    >
                        <i className='new-ldicon-inbox' /> <span>{t('ORDERS.TAB_TITLE.ORDER_LIST')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 2 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(2);
                        }}
                    >
                        <i className='new-ldicon-abandoned-cart' /> <span>{t('ORDERS.TAB_TITLE.CHECKOUT_LIST')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 3 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(3);
                        }}
                    >
                        <i className='new-ldicon-transport' /> <span>{t('ORDERS.TAB_TITLE.SHIPPING_LIST')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 4 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(4);
                        }}
                    >
                        <i className='new-ldicon-tag' /> <span>{t('ORDERS.TAB_TITLE.ORDER_TAG')}</span>
                    </div>
                    <div
                        className={`menu-item ${tabSelected == 5 ? 'active' : ''}`}
                        onClick={() => {
                            selectTab(5);
                        }}
                    >
                        <i className='new-ldicon-custom-field' /> <span>{t('ORDERS.TAB_TITLE.ORDER_CUSTOM')}</span>
                    </div>
                </div>
                {order_tags && order_tags.length > 0 && tabSelected == 1 && (
                    <div className='list-order-tags'>
                        <div className='ladiui label-12-secondary flex-center-between'>
                            <span> {t('TAGS.FILTER_BY_TAGS')}</span>
                            <i className='ladiui new-ldicon-search' onClick={() => setShowSearch(!isShowSearch)} />
                        </div>
                        {isShowSearch && (
                            <input
                                className='input-search-tag'
                                value={searchTag}
                                onChange={(e) => setSearchTag(e.target.value)}
                                placeholder={t('TAGS.SEARCH_TAG')}
                            />
                        )}
                        <div className='mt-16 display-flex-center gap-8 ladiui flex-wap-wap'>
                            {order_tags.map((item, index) => {
                                let isSelect = tagSelected.find((_item) => _item.order_tag_id == item.order_tag_id);

                                let name1 = BaseHelper.getAliasName(item.name);
                                let name2 = BaseHelper.getAliasName(searchTag);

                                if (!name1.includes(name2)) return null;

                                return (
                                    <div
                                        onClick={() => selectTags(item)}
                                        key={index}
                                        className={`tag-item ladiui btn-tag cursor-pointer ${isSelect ? 'active' : ''}`}
                                        style={
                                            isSelect
                                                ? {}
                                                : {
                                                      backgroundColor: item.color,
                                                      color: 'var(--main-default)',
                                                  }
                                        }
                                    >
                                        {item.name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <div className='page-max'>
                <div className='page-right'>
                    {tabSelected == 1 && <Order location={location} history={props.history} />}
                    {tabSelected == 2 && <Checkout location={location} />}
                    {tabSelected == 3 && <Shipping location={location} />}
                    {tabSelected == 4 && <PageOrderTag location={location} />}
                    {tabSelected == 5 && <OrderCustomField />}
                </div>
            </div>
        </div>
    );
}
