export const LIST_PAGE_CHECKOUT = 'LIST_PAGE_CHECKOUT';
export const CREATE_PAGE_CHECKOUT = 'CREATE_PAGE_CHECKOUT';
export const CREATE_PAGE_CHECKOUT_V2 = 'CREATE_PAGE_CHECKOUT_V2';
export const UPDATE_PAGE_CHECKOUT = 'UPDATE_PAGE_CHECKOUT';
export const UPDATE_PAGE_CHECKOUT_V2 = 'UPDATE_PAGE_CHECKOUT_V2';
export const SHOW_PAGE_CHECKOUT = 'SHOW_PAGE_CHECKOUT';
export const SHOW_PAGE_CHECKOUT_V2 = 'SHOW_PAGE_CHECKOUT_V2';
export const RELOAD_PAGE_CHECKOUT = 'RELOAD_PAGE_CHECKOUT';
export const DELETE_PAGE_CHECKOUT = 'DELETE_PAGE_CHECKOUT';
export const DELETES_PAGE_CHECKOUT = 'DELETES_PAGE_CHECKOUT';
export const PAGE_COUSE_SHOW = 'PAGE_COUSE_SHOW';
export const PAGE_CHECKOUT_UPDATE_NAME = 'PAGE_CHECKOUT_UPDATE_NAME';
export const PAGE_COURSE_ACTIVE_AFFILIATE = 'PAGE_COURSE_ACTIVE_AFFILIATE';
