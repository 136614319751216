/** @format */

import React from 'react';

import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import { cloneDeep, compact, map, includes } from 'lodash';

import Modal from '../../../components/Modal';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PanelAddress from '../../../components/PanelAddress';

import { connect } from 'react-redux';
import * as orderTypes from '../../../redux/futures/order/types';

class BillingAddress extends React.Component {
    static propTypes = {
        mode: PropTypes.string,
        shippingAddress: PropTypes.object,
        addresses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        onChangeAddress: PropTypes.func,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            billingAddress: {},
            editBillingAddress: {},
            isShowBillingAddressModal: false,
            isShowBlockBillingAddress: true,
        };

        this.ref = React.createRef();
        this.addressPanelRef = React.createRef();
        this.inputsRef = new Set();
    }

    componentDidMount() {
        if (this.props.billingAddress) {
            const billingAddress = this.props.billingAddress || {};
            this.setState({
                billingAddress: cloneDeep(billingAddress),
                editBillingAddress: cloneDeep(billingAddress),
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.billingAddress != nextProps.billingAddress) {
            const billingAddress = nextProps.billingAddress || {};

            this.setState({
                billingAddress: cloneDeep(billingAddress),
                editBillingAddress: cloneDeep(billingAddress),
            });
        }

        if (this.props.orderReducer.action != nextProps.orderReducer.action) {
            if (includes([orderTypes.UPDATE_ORDER_ADDRESS], nextProps.orderReducer.action)) {
                if (nextProps.orderReducer.status) {
                    this.hideBillingAddressModal();
                }
            }
        }
    }

    onChangeAddressInput = (event) => {
        this.setState({
            editBillingAddress: {
                ...this.state.editBillingAddress,
                [event.target.name]: event.target.value,
            },
        });
    };

    openEditBillingAddressForm = () => {
        baseHelper.resetForm('modal-billing-address');
        const { billingAddress } = this.state;

        this.setState({
            editBillingAddress: cloneDeep(billingAddress),
            isShowBillingAddressModal: true,
        });
    };

    submit = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });
        errors = errors.concat(this.addressPanelRef.current.validate());
        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { mode } = this.props;

        const editBillingAddress = this.addressPanelRef.current.getData();
        this.setState({
            billingAddress: cloneDeep(editBillingAddress),
        });

        if (this.props.onChangeAddress) {
            this.props.onChangeAddress(editBillingAddress, appConfig.ADDRESS_TYPE.BILLING);
        }

        if (mode == appConfig.FORM_MODE.CREATE) {
            this.hideBillingAddressModal();
        }
    };

    onChangeBilling = (selectedBillingAddress) => {
        this.setState({
            editBillingAddress: cloneDeep(selectedBillingAddress),
        });

        let elm = document.getElementById('list-address-billing');
        elm.classList.remove('open');
    };

    hideBillingAddressModal = () => {
        this.setState({
            isShowBillingAddressModal: false,
        });
    };

    getData = () => {
        return this.state.billingAddress;
    };

    getBillingModal = () => {
        const { t, isLoading } = this.props;
        const { addresses } = this.props;
        const { editBillingAddress, isShowBillingAddressModal } = this.state;

        if (!isShowBillingAddressModal) {
            return null;
        }

        return (
            <Modal
                visible={isShowBillingAddressModal}
                id="modal-billing-address"
                zIndex={9999}
                title={t('ADDRESSES.EDIT_BILLING_ADDRESS')}
                onCancel={this.hideBillingAddressModal}
                onOk={this.submit}
                width={900}
                isLoading={isLoading}
            >
                <div className="action-item">
                    <div className="ladiui btn-group -horizontal">
                        <div className="ladiui dropdown">
                            <button className="ladiui btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                                {t('ADDRESSES.SELECT_ANOTHER_ADDRESS')}
                                {/* <i className="ladi-icon icon-down-arrow" /> */}
                            </button>
                            <ul
                                id="list-address-billing"
                                className="ladiui dropdown-menu"
                                style={{
                                    zIndex: 4,
                                    maxHeight: 500,
                                    overflow: 'scroll',
                                    width: '500px ',
                                }}
                            >
                                {map(addresses, (item, index) => {
                                    let addressInfos = [
                                        item.address,
                                        item.ward_name,
                                        item.district_name,
                                        item.state_name,
                                        baseHelper.getCountryNameByCode(item.country_code),
                                    ];
                                    addressInfos = compact(addressInfos);
                                    const address = addressInfos.join(', ');

                                    return (
                                        <li
                                            key={index}
                                            className="order-address-dropdown ladiui dropdown-item"
                                            onClick={() => this.onChangeBilling(item)}
                                        >
                                            <div style={{ fontWeight: 500 }}>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(
                                                item.first_name
                                            )}`}</div>
                                            <div>{address}</div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="ladiui-tab-content" style={{ padding: '15px 0px' }}>
                    <PanelAddress mode={appConfig.FORM_MODE.EDIT} ref={this.addressPanelRef} address={editBillingAddress} />
                </div>
            </Modal>
        );
    };

    render() {
        const { t, mode, classNameCustom } = this.props;
        const { billingAddress, isShowBlockBillingAddress } = this.state;
        if (baseHelper.isEmpty(billingAddress)) {
            return null;
        }

        let addressInfos = [
            billingAddress.address,
            billingAddress.ward_name,
            billingAddress.district_name,
            billingAddress.state_name,
            baseHelper.getCountryNameByCode(billingAddress.country_code),
        ];
        addressInfos = compact(addressInfos);
        const address = addressInfos.join(', ');

        const { isSameShippingAddress } = this.props;

        return (
            <div className={`order-customer ${classNameCustom}`}>
                <div className="order-heading title pb-24 pt-24">
                    <h3>{t('ADDRESSES.BILLING')}</h3>
                    {mode != appConfig.FORM_MODE.VIEW && (
                        <a onClick={this.openEditBillingAddressForm} className="ladiui order-customer-edit">
                            {t('ACTIONS.EDIT')}
                        </a>
                    )}
                    {/* <div className="hidden-custom-field">
                        <a href="#" onClick={() => { this.setState({ isShowBlockBillingAddress: !isShowBlockBillingAddress }) }} >
                            <i className="ladi-icon icon-down-arrow"></i>
                        </a>
                    </div> */}
                </div>
                {isShowBlockBillingAddress && (
                    <div className="customer-info">
                        {isSameShippingAddress && <span>{t('ORDERS.SAME_SHIPPING_ADDRESS')}</span>}
                        {!isSameShippingAddress && (billingAddress.first_name || billingAddress.last_name) && (
                            <span>
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-user.svg" />{' '}
                                {`${baseHelper.getText(billingAddress.last_name)} ${baseHelper.getText(billingAddress.first_name)}`}
                            </span>
                        )}
                        {!isSameShippingAddress && billingAddress.phone && (
                            <span>
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-phone.svg" />{' '}
                                {baseHelper.getText(billingAddress.phone)}
                            </span>
                        )}
                        {!isSameShippingAddress && address && (
                            <span>
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-map.svg" /> {address}
                            </span>
                        )}
                        {!isSameShippingAddress && billingAddress.apartment && (
                            <span>
                                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-map.svg" />{' '}
                                {baseHelper.getText(billingAddress.apartment)}
                            </span>
                        )}
                    </div>
                )}
                {this.getBillingModal()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state) => ({
    orderReducer: { ...state.order },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(BillingAddress));
