/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { includes, filter, isEmpty, forEach, map, compact, flow, find, remove, cloneDeep } from 'lodash';
// import ManageListLayout from '../layouts/ManageListLayout';
import baseHelper from '../../helpers/BaseHelper';
import { flowConfigchannels, integrationCategories, integrationTypes, iconIntegration } from '../../config/constans';
import LoadingScene from '../../components/LoadingScene';
import appConfig from '../../config/app';
import { appLocalStorage } from '../../localforage';
import i18n from '../../i18n';

// import action from '../../redux/futures/integration/actions';
import ConfirmModal from '../../components/ConfirmModal';
// import Dropdown from '../../components/DropdownV2';
import Dropdown from '../../components/Dropdown';

// import * as types from "../../redux/futures/form_config/types";
// import formConfigActions from "../../redux/futures/form_config/actions";

import * as formAccountTypes from '../../redux/futures/form_account/types';
import formAccountActions from '../../redux/futures/form_account/actions';
import LadiPagination from '../../components/LadiPagination';
import ModalIntegrationInfo from './components/ModalIntegrationInfoV4';
import EmptyDataCustom from './components/EmptyDataCustomv4';
import ManageListLayoutV4 from './components/ManageListLayoutV4';

class IntegrationV4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: null,
            activePage: 1,
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            searchName: '',
            currentInfo: {},
            mode: '',
            isShowModal: false,
            isShowModalIntegrationInfo: false,
            integrationInfo: null,
            selectedIDs: [],
            allTypes: [
                { type: 'ALL', name: 'Tất cả nền tảng', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.HARAVAN.CODE, name: 'Haravan', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.SAPO.CODE, name: 'Sapo', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.SHOPIFY.CODE, name: 'Shopify', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.WORDPRESS.CODE, name: 'Wordpress', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.KIOTVIET.CODE, name: 'KiotViet', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.GOOGLE_SHEET.CODE, name: 'Google Sheets', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.NHANH.CODE, name: 'Nhanh.vn', isShowCustomer: true },
                { type: appConfig.FORM_TYPE.GET_RESPONSE.CODE, name: 'Getresponse', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE, name: 'ActiveCampaign', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.HUBSPOT.CODE, name: 'Hubspot', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE, name: 'Infusionsoft', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.CONVERTKIT.CODE, name: 'Convertkit', isShowCustomer: false },
                { type: appConfig.FORM_TYPE.MAIL_CHIMP.CODE, name: 'MailChimp', isShowCustomer: false },
            ],
            selectedType: {
                type: 'ALL',
                name: 'Tất cả nền tảng',
                isShowCustomer: true,
            },
            paginationConditions: {
                limit: 10,
                page: 1,
            },
            typeIntegration: '',
            activeTab: 'system',
            categorySelected: [],
            allCategory: true,
            keyword: '',
            isSearchIntegrationLoading: false,
            notify: false,
            allConnects: [],
        };
        this.sortBy = {
            _id: 'DESC',
        };
        this.inputsRef = new Set();
        this.tagRef = React.createRef();
    }

    checkLoadingDataTab = () => {
        const { activeTab } = this.state;
        if (activeTab === 'system') {
        } else if (activeTab === 'my') {
            this.list(true);
        } else {
            this.props.history.push('?tab=system');
        }
    };

    componentDidMount() {
        document.addEventListener('scroll', this.scrollFunction);

        this.props.listAllConnect();

        if (this.props.location) {
            const tab = baseHelper.getQueryDataUrlByKey('tab');
            const idQuery = baseHelper.getQueryDataUrlByKey('id');
            const type = baseHelper.getQueryDataUrlByKey('type');
            this.setState(
                {
                    activeTab: tab,
                    notify: idQuery && true,
                    idQuery,
                    selectedType: {
                        type: type || 'ALL',
                    },
                },
                () => {
                    this.checkLoadingDataTab();
                }
            );
        }
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.location && nextProps.location && this.props.location.key !== nextProps.location.key) {
            const tab = baseHelper.getQueryDataUrlByKey('tab');
            const idQuery = baseHelper.getQueryDataUrlByKey('id');
            const type = baseHelper.getQueryDataUrlByKey('type');
            this.setState(
                {
                    activeTab: tab,
                    notify: idQuery && true,
                    idQuery,
                    selectedType: {
                        type: type || 'ALL',
                    },
                },
                () => {
                    this.checkLoadingDataTab();
                }
            );
        }

        if (this.props.formAccountReducer.action !== nextProps.formAccountReducer.action) {
            if (includes([formAccountTypes.INTEGRATE_FORM_ACCOUNT_DELETES], nextProps.formAccountReducer.action)) {
                if (nextProps.formAccountReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.formAccountReducer.message);
                    this.setState({
                        selectedIDs: [],
                    });
                    this.selectedIDs = [];
                    this.list(true);
                    window.LadiUI.closeModal('confirm-delete');
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.formAccountReducer.message, 'OK');
                }
            }

            if (nextProps.formAccountReducer.action === formAccountTypes.INTEGRATE_FORM_ACCOUNT_SHOW) {
                if (nextProps.formAccountReducer.status) {
                    const integration = nextProps.formAccountReducer.form_account;
                    const integrationInfo = integrationTypes.find((ele) => ele.type === integration.type);
                    this.setState(
                        {
                            currentInfo: integration,
                            mode: appConfig.FORM_MODE.EDIT,
                            isShowModal: true,
                            integrationInfo,
                        },
                        () => {
                            // window.LadiUI.showModal('modal-integration');
                            window.LadiUI.showModal('modal-integration-info');
                        }
                    );
                } else {
                    window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                }
            }

            if (nextProps.formAccountReducer.action === formAccountTypes.LIST_ALL_CONNECT) {
                if (nextProps.formAccountReducer.status) {
                    if (nextProps.formAccountReducer.allConnects && nextProps.formAccountReducer.allConnects.length > 0) {
                        this.setState({
                            allConnects: nextProps.formAccountReducer.allConnects || [],
                        });
                    }
                } else {
                    window.LadiUI.toast('danger', 'Error', nextProps.formAccountReducer.message, '5000', 'bottom-left');
                }
            }
        }
    }

    list = (reset = false) => {
        const { searchName, paginationConditions, selectedType } = this.state;

        let currentPage = paginationConditions.page;
        if (reset) {
            currentPage = 1;
            this.setState({
                paginationConditions: {
                    ...paginationConditions,
                    page: 1,
                },
            });
        }

        const data = {
            keyword: searchName,
            page: currentPage,
            limit: paginationConditions.limit,
            sort: this.sortBy,
        };

        if (selectedType && selectedType.type !== 'ALL') data.type = selectedType.type;

        this.props.list(data);
    };

    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    onChangeKeywordInput = (event) => {
        this.setState(
            {
                isSearchIntegrationLoading: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        [event.target.name]: event.target.value,
                        isSearchIntegrationLoading: false,
                    });
                }, 500);
            }
        );
    };

    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list(true);
        }
    };

    onChangeLimit = (option) => {
        this.setState({ selectedLimit: option.value }, () => {
            this.list();
        });
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        // window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
        //     if (item.getAttribute('name') !== name) {
        //         item.classList.remove('up');
        //     }
        // });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.list();
    };

    openModalIntegrationInfo = (integrationInfo, mode = appConfig.FORM_MODE.CREATE) => {
        this.setState(
            {
                mode,
                isShowModalIntegrationInfo: true,
                integrationInfo,
            },
            () => {
                window.LadiUI.showModal('modal-integration-info');
            }
        );
    };

    openModalCreate = () => {
        this.props.history.push('?tab=system');
    };

    openModalEdit = (data) => {
        this.props.show(data.id);
    };

    hideModal = () => {
        this.setState({
            isShowModal: false,
        });
        window.LadiUI.closeModal('modal-integration');
        window.LadiUI.closeModal('modal-create-integration');
    };

    hideModalIntegrationInfo = () => {
        this.setState({
            isShowModalIntegrationInfo: false,
            isShowModal: false,
        });
        window.LadiUI.closeModal('modal-integration-info');
    };

    submit = (data) => {
        if (!data) return;
        const { type } = data;
        if (type === appConfig.INTEGRATION.TYPE.LADIMAIL) {
            this.setState({
                mode: appConfig.FORM_MODE.EDIT,
            });
        } else {
            this.setState({
                isShowModal: false,
            });
        }
        this.list();
        this.hideModal();
    };

    submitCreate = (data) => {
        this.setState(
            {
                typeIntegration: data,
                isShowModal: true,
            },
            () => {
                window.LadiUI.closeModal('modal-create-integration');
                window.LadiUI.showModal('modal-integration');
            }
        );
    };

    sendSMS = (data) => {
        this.props.sendSMS(data);
    };

    openOptionDeleteModal = (selectedID) => {
        this.selectedIDs = [selectedID];
        window.LadiUI.showModal('confirm-delete');
    };

    openOptionDeletesModal = () => {
        if (this.selectedIDs.length <= 0) {
            window.LadiUI.toast('danger', 'Error', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'), '5000', 'bottom-left');
            return;
        }

        window.LadiUI.showModal('confirm-delete');
    };

    selectType = (type) => {
        this.setState(
            {
                selectedType: type,
            },
            () => {
                this.list(true);
            }
        );
    };

    // eslint-disable-next-line max-lines-per-function
    getType = (integration) => {
        let image = '';
        let type = 'Email';
        let name = baseHelper.getText(integration.name);

        if (integration.type == appConfig.FORM_TYPE.HARAVAN.CODE) {
            image = iconIntegration.HARAVAN;
            type = 'Haravan';
        }

        if (integration.type == appConfig.FORM_TYPE.SAPO.CODE) {
            image = iconIntegration.SAPO;
            type = 'Sapo';
        }

        if (integration.type == appConfig.FORM_TYPE.SHOPIFY.CODE) {
            image = iconIntegration.SHOPIFY;
            type = 'Shopify';
        }

        if (integration.type == appConfig.FORM_TYPE.WORDPRESS.CODE) {
            image = iconIntegration.WORDPRESS;
            type = 'Wordpress';
        }

        if (integration.type == appConfig.FORM_TYPE.KIOTVIET.CODE) {
            image = iconIntegration.KIOTVIET;
            type = 'KiotViet';
        }

        if (integration.type == appConfig.FORM_TYPE.GET_RESPONSE.CODE) {
            image = iconIntegration.GETRESPONSE;
            type = 'Getresponse';
        }

        if (integration.type == appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE) {
            image = iconIntegration.ACTIVE_CAMPAIGN;
            type = 'ActiveCampaign';
        }

        if (integration.type == appConfig.FORM_TYPE.HUBSPOT.CODE) {
            image = iconIntegration.HUBSPOT;
            type = 'Hubspot';
        }

        if (integration.type == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE) {
            image = iconIntegration.INFUSION_SOFT;
            type = 'Infusionsoft';
        }

        if (integration.type == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
            image = iconIntegration.GOOGLE_SHEET;
            type = 'Google Sheets';
        }

        if (integration.type == appConfig.FORM_TYPE.CONVERTKIT.CODE) {
            image = iconIntegration.CONVERTKIT;
            type = 'Convertkit';
        }

        if (integration.type == appConfig.FORM_TYPE.MAIL_CHIMP.CODE) {
            image = iconIntegration.MAIL_CHIMP;
            type = 'MailChimp';
        }

        if (integration.type == appConfig.FORM_TYPE.NHANH.CODE) {
            image = iconIntegration.NHANH;
            type = 'Nhanh.vn';
        }

        const data = {
            image,
            type,
            name,
        };
        return data;
    };

    onSelectedLimit = (limit) => {
        this.setState(
            {
                paginationConditions: {
                    ...this.state.paginationConditions,
                    limit,
                    page: 1,
                },
            },
            () => {
                this.list();
            }
        );
    };

    onCheckBoxItem = (e) => {
        const { selectedIDs } = this.state;
        const id = e.target.value;
        if (e.target.checked) {
            this.setState({
                selectedIDs: [...selectedIDs, id],
            });
            this.selectedIDs = [...selectedIDs, id];
        } else {
            const ids = filter(selectedIDs, (item) => item !== id);
            this.setState({
                selectedIDs: ids,
            });
            this.selectedIDs = ids;
        }
    };

    checkAllItem = (e) => {
        const ids = [];
        if (e.target.checked) {
            const items = document.getElementsByClassName('checkbox-element');

            for (let i = 0; i < items.length; i++) {
                ids.push(items[i].value);
            }
        }

        this.setState({
            selectedIDs: ids,
        });
        this.selectedIDs = ids;
    };

    renderItemAction = (item) => {
        const { t } = this.props;
        return (
            <div className='ladiui btn-group'>
                <div className='ladiui dropdown'>
                    <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle actions-expand'>
                        <i className='ladiui icon icon-ldp-dot'></i>
                    </button>
                    <ul className='ladiui dropdown-menu custom-dropdown-menu'>
                        <li>
                            <a className='ladiui dropdown-item btn-open-modal' onClick={() => this.openModalEdit(item)}>
                                <i className='ladiui icon new-ldicon-edit'></i> {t('ACTIONS.EDIT')}
                            </a>
                        </li>
                        <li>
                            <a className='ladiui dropdown-item delete-link' onClick={() => this.openOptionDeleteModal(item.id)}>
                                <i className='ladiui icon new-ldicon-delete'></i>
                                {t('ACTIONS.DELETE')}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    // eslint-disable-next-line max-lines-per-function
    renderTitleTable = () => {
        const { t, flowReducer } = this.props;
        const { selectedIDs } = this.state;
        return (
            <tr className='ladiui table-vertical'>
                <th className='ladiui checkall col-first' scope='col'>
                    <input
                        id='checkAllItem'
                        name='checkbox'
                        onChange={this.checkAllItem}
                        checked={flowReducer && !isEmpty(flowReducer.flows) && selectedIDs.length === flowReducer.flows.length}
                        type='checkbox'
                        className='ladiui checkbox-all size-checkbox form-check-input-checkbox vertical-middle'
                    />
                </th>
                <th className='ladiui col-one customer-name table-header-text' scope='col' style={{ width: 460 }}>
                    {selectedIDs.length > 0 ? (
                        <div className='box-multi-action'>
                            <div style={{ position: 'relative' }}>
                                <div className='ladiui btn-group'>
                                    <div className='ladiui dropdown'>
                                        <button
                                            data-toggle='dropdown'
                                            // className="ladiui-btn-dropdown dropdown-toggle "
                                            className='ladiui btn dropdown-toggle btn-outline-light'
                                            style={{ paddingRight: '24px' }}
                                        >
                                            {t('Hành động')}
                                        </button>
                                        <ul className='ladiui dropdown-menu custom-action-dropdown-menu'>
                                            <li>
                                                <a className='ladiui dropdown-item' onClick={this.openOptionDeletesModal}>
                                                    {t('ACTIONS.DELETE')}{' '}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        t('Tên')
                    )}
                </th>
                <th name='status' className='text-left table-header-text' style={{ width: 216 }} scope='col'>
                    {t('Loại tài khoản')}
                </th>
                <th
                    name='updated_at'
                    // onClick={this.sort}
                    className={`text-left sorting table-header-text ${this.sortBy.updated_at === 'DESC' ? 'up' : ''}`}
                    style={{ width: 216 }}
                >
                    {t('Ngày cập nhật')}
                </th>
                <th name='totalOptin' className='text-left table-header-text' style={{ width: 216 }}>
                    {t('Người tạo')}
                </th>
                <th className='ladiui' scope='col' width={50}></th>
            </tr>
        );
    };

    renderTableItem = (item) => {
        const { t } = this.props;
        const { userInfo, selectedIDs } = this.state;

        const integrationType = this.getType(item);
        const type = integrationType.type;
        const image = integrationType.image;
        const name = integrationType.name;

        return (
            <tr className='ladiui table-vertical main' key={item.id}>
                <td scope='row'>
                    <input
                        type='checkbox'
                        className='ladiui checkbox size-checkbox checkbox-element form-check-input-checkbox vertical-middle'
                        id='is-check'
                        name='checkbox'
                        checked={selectedIDs.includes(item.id)}
                        value={item.id}
                        onChange={this.onCheckBoxItem}
                    />
                </td>
                <td>
                    <span className='pointer fix-line-css title text-secondary font-500' onClick={() => this.openModalEdit(item)}>
                        {name}
                    </span>
                </td>
                <td className='text-left text-secondary' style={{ width: '100' }}>
                    <img src={image} className='icon-form-integration' alt='' />
                    {type}
                </td>
                <td className='text-left text-secondary'>{baseHelper.formatStrToDate(item.updated_at)}</td>
                <td className='text-left text-secondary'>
                    {userInfo && userInfo.staffs ? baseHelper.getStaffNameById(userInfo.staffs, item.creator_id, userInfo.user) : null}
                </td>
                <td className='ladiui-dropdown public text-right' width={90}>
                    {this.renderItemAction(item)}
                </td>
            </tr>
        );
    };

    handleResetSearchInput = () => {
        this.setState(
            {
                searchName: '',
            },
            () => {
                this.list(true);
            }
        );
    };

    handleReseFilter = () => {
        this.setState(
            {
                searchName: '',
                selectedType: {
                    type: 'ALL',
                    name: i18n.t('INTEGRATIONS.ALL_INTEGRATION'),
                    isShowCustomer: true,
                },
            },
            () => {
                this.list(true);
            }
        );
    };

    handleChooseCategory = (platform) => {
        const { categorySelected } = this.state;
        let categorySelectedCopy = cloneDeep(categorySelected);
        if (find(categorySelectedCopy, (item) => item === platform.value)) {
            categorySelectedCopy = filter(categorySelectedCopy, (item) => item !== platform.value);
        } else {
            categorySelectedCopy.push(platform.value);
        }

        const isAll = categorySelectedCopy.length === integrationCategories.length || categorySelectedCopy.length === 0;
        this.setState(
            {
                isSearchIntegrationLoading: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        categorySelected: isAll ? [] : categorySelectedCopy,
                        allCategory: isAll,
                        isSearchIntegrationLoading: false,
                    });
                }, 500);
            }
        );
    };

    handleCheckAll = () => {
        const newStatus = !this.state.allCategory;
        if (newStatus) {
            this.setState({
                allCategory: newStatus,
                categorySelected: [],
            });
        }
    };

    handleResetKeywordInput = () => {
        this.setState(
            {
                isSearchIntegrationLoading: true,
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        keyword: '',
                        isSearchIntegrationLoading: false,
                    });
                }, 500);
            }
        );
    };

    renderList = () => {
        const { t } = this.props;
        let { categorySelected, allCategory, keyword, allConnects } = this.state;
        keyword = keyword.toLocaleLowerCase();
        const categoryFiltered = filter(integrationCategories, (category) => {
            if (allCategory || categorySelected.includes(category.value)) {
                return true;
            }
            return false;
        });

        const listFiltered = filter(integrationTypes, (integration) => {
            if (
                !isEmpty(keyword) &&
                integration.name.toLocaleLowerCase().includes(keyword) &&
                categorySelected.includes(integration.category)
            ) {
                return true;
            }
            if (isEmpty(keyword) && categorySelected.includes(integration.category)) {
                return true;
            }

            if (!isEmpty(keyword) && integration.name.toLocaleLowerCase().includes(keyword) && allCategory) {
                return true;
            }

            if (isEmpty(keyword) && allCategory) {
                return true;
            }
        });

        return (
            <>
                {categoryFiltered.map((category) => {
                    const integrationTypeFiltered = filter(integrationTypes, (integration) => {
                        if (
                            !isEmpty(keyword) &&
                            integration.name.toLocaleLowerCase().includes(keyword) &&
                            integration.category === category.value
                        ) {
                            return true;
                        }
                        if (isEmpty(keyword) && integration.category === category.value) {
                            return true;
                        }
                    });
                    if (integrationTypeFiltered.length > 0) {
                        return (
                            <div className='row mt-24' key={category.value}>
                                <div className='flex flex-column justify-start '>
                                    <label className='text-secondary font-500 text-16'>{t(category.title)}</label>
                                    <div className='grid-block gap-24 mt-24' style={{ '--column': '1fr 1fr 1fr' }}>
                                        {map(integrationTypeFiltered, (integrationType, index) => {
                                            let haveConnect = allConnects.filter((item) => item.type == integrationType.type);
                                            return (
                                                <div
                                                    key={index}
                                                    className={'select-box-item'}
                                                    onClick={() => this.openModalIntegrationInfo(integrationType)}
                                                >
                                                    <div className='select-box-item-icon'>
                                                        <img className='ladiui' src={integrationType.icon} alt=''></img>
                                                    </div>
                                                    <div className='select-box-item-info w-full'>
                                                        <div className='flex justify-between items-center w-full'>
                                                            <div className='text-secondary font-500 text-18' style={{ '--line': 1 }}>
                                                                {t(integrationType.name)}
                                                            </div>
                                                        </div>

                                                        {haveConnect && haveConnect.length > 0 ? (
                                                            <div className='connect-count flex justify-start align-items-start flex-column'>
                                                                <div
                                                                    className='text-left ladiui caption text-placeholder'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.props.history.push(
                                                                            `/integrate?tab=my&type=${integrationType.type}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Đã có {haveConnect[0].count} liên kết
                                                                </div>
                                                                <div className='line-dotted'></div>
                                                            </div>
                                                        ) : (
                                                            <div className='text-left ladiui caption text-placeholder'>
                                                                {t('Chưa kết nối')}
                                                            </div>
                                                        )}
                                                        <div
                                                            className='text-left text-secondary text-14 ellipsis-text'
                                                            style={{ '--line': 2 }}
                                                        >
                                                            {t(integrationType.description)}
                                                        </div>
                                                        <a
                                                            className='ladiui mt-12 text-support-info'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.openModalIntegrationInfo(integrationType, appConfig.FORM_MODE.EDIT);
                                                            }}
                                                        >
                                                            {t('Kết nối')}
                                                        </a>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}

                {listFiltered.length === 0 && <EmptyDataCustom searching={true} />}
            </>
        );
    };

    render() {
        const { t } = this.props;
        const { total_record } = this.props.formAccountReducer;
        const {
            paginationConditions,
            activeTab,
            searchName,
            userInfo,
            allTypes,
            selectedType,
            categorySelected,
            allCategory,
            keyword,
            isSearchIntegrationLoading,
            notify,
        } = this.state;

        const isLoadingData =
            this.props.formAccountReducer.loading &&
            includes(
                [
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_SHOW,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_DELETES,
                    formAccountTypes.INTEGRATE_FORM_ACCOUNT_LIST,
                ],
                this.props.formAccountReducer.waitting
            );
        const integrations = this.props.formAccountReducer.form_accounts || [];

        const isLoading = isLoadingData || isSearchIntegrationLoading;

        const isSearching = (selectedType && selectedType.type !== 'ALL') || !isEmpty(searchName);
        return (
            <>
                {isLoading ? <LoadingScene blur={true} /> : null}

                <div id='content-list-landingpage' className='ladiui content-list-landingpage content-list-landingpage-v3 pt-0'>
                    <div className='ladiui content-main-full max-w-1220 pt-0 sticky-header-template'>
                        <div className='header-actions ladiui padding-top-24 padding-x-0 gap-24'>
                            <div className='header-actions-top'>
                                <div className='widget-left'>
                                    <div className='ladiui alert create-flow-alert'>
                                        <div className='title-heading'>
                                            <h3>Danh sách tích hợp</h3>
                                        </div>
                                        <div className='title-desc mt-8'>
                                            <p>
                                                Kết nối với nền tảng thứ ba để thu nhập, lưu trữ thông tin và gửi tin nhắn tới khách hàng.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='widget-right'>
                                    {activeTab === 'my' && (
                                        <div className='ladiui'>
                                            <button
                                                type='button'
                                                className='ladiui btn btn-primary btn-tb custom-page-lists'
                                                onClick={this.openModalCreate}
                                                id='button-integration-create'
                                            >
                                                <img
                                                    className='ladiui btn-custom-img'
                                                    src={'https://w.ladicdn.com/ladiui/ladipage/icon-ldp-add.svg'}
                                                    alt=''
                                                />
                                                <div className='ladiui btn-custom-text'>Tạo liên kết</div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            
                            <div className='tabs-v3 w-full'>
                                <ul className='ladiui tab-nav nav-tabs bg-white' id='myTab' role='tablist'>
                                    <li
                                        className='ladiui nav-item'
                                        onClick={() => {
                                            this.props.history.push('?tab=system');
                                        }}
                                    >
                                        <span
                                            className={`ladiui tab-link text-secondary text-14 ${activeTab === 'system' ? 'active' : ''}`}
                                            id='tab-system-template'
                                            data-toggle='tab'
                                            data-parent='myTab'
                                            data-target='system-template'
                                        >
                                            Thư viện tích hợp
                                        </span>
                                    </li>
                                    <li
                                        className='ladiui nav-item'
                                        onClick={() => {
                                            this.props.history.push('?tab=my');
                                        }}
                                    >
                                        <span
                                            className={`ladiui tab-link text-secondary text-14 ${activeTab === 'my' ? 'active' : ''}`}
                                            id='tab-my-template'
                                            data-toggle='tab'
                                            data-parent='myTab'
                                            data-target='my-template'
                                        >
                                            Tích hợp của tôi
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='template-page'>
                        <div className='ladiui content-main-full max-w-1220 pt-0'>
                            <div className='ladiui from-group'>
                                <div className='ladiui tab-content' id='myTabContent'>
                                    <div
                                        className={`ladiui tab-pane fade mt-0 min-h-full pt-24 ${activeTab === 'system' ? 'open' : ''}`}
                                        id='system-template'
                                        role='tabpanel'
                                        aria-labelledby='tab-system-template'
                                    >
                                        <div className='row'>
                                            <div className='flex-row-start-start'>
                                                <div className='ladiui search-group margin-right-16 search-widget w-full'>
                                                    <i onClick={this.list} className='ladiui icon icon-search'></i>
                                                    <input
                                                        autoComplete='off'
                                                        value={keyword}
                                                        className='ladiui search-field form-control'
                                                        style={{ minWidth: 270 }}
                                                        name='keyword'
                                                        placeholder={t('Tìm kiếm')}
                                                        onChange={this.onChangeInput}
                                                    />
                                                    {keyword && (
                                                        <i
                                                            onClick={this.handleResetKeywordInput}
                                                            className='ladiui icon new-ldicon-close-fill-2 cursor-pointer'
                                                        ></i>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row mt-24'>
                                            <div className='flex-row-start-start'>
                                                <div className='list-block'>
                                                    <div
                                                        className={`list-block-item ${allCategory && 'active'}`}
                                                        onClick={() => this.handleCheckAll()}
                                                    >
                                                        {t('COMMON.ALL')}
                                                    </div>
                                                    {integrationCategories.map((item, index) => {
                                                        const isActive = find(categorySelected, (value) => value === item.value);
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`list-block-item ${isActive ? 'active' : ''}`}
                                                                onClick={() => this.handleChooseCategory(item)}
                                                            >
                                                                {t(item.title)}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        {this.renderList()}
                                        {this.state.isShowModalIntegrationInfo && (
                                            <ModalIntegrationInfo
                                                mode={this.state.mode}
                                                currentInfo={this.state.currentInfo}
                                                integrationInfo={this.state.integrationInfo}
                                                typeIntegration={this.state.integrationInfo.type}
                                                visible={this.state.isShowModalIntegrationInfo}
                                                onSubmit={() => {}}
                                                onCancel={this.hideModalIntegrationInfo}
                                                onSuccess={(path) => this.props.history.push(path)}
                                            />
                                        )}
                                    </div>

                                    <div
                                        className={`ladiui tab-pane fade margin-top-24 ${activeTab === 'my' ? 'open' : ''}`}
                                        id='my-template'
                                        role='tabpanel'
                                        aria-labelledby='tab-my-template'
                                    >
                                        <ManageListLayoutV4
                                            wrapperClass=''
                                            listName={t('bản ghi')}
                                            parentContext={this}
                                            parentProps={this.props}
                                            parentState={this.state}
                                            isLoading={isLoading}
                                            isShowHeader={false}
                                            emptyDataTitle={t('Không tìm thấy kết quả nào')}
                                            emptyDataDescription={t('Thay đổi hoặc gỡ bỏ bộ lọc hiện tại để tìm kiếm kết quả bạn cần')}
                                            handleUpdateState={(data) => this.setState({ ...data })}
                                            isSearching={isSearching}
                                            actionsComponent={
                                                <div className='flex flex-column justify-start align-items-start gap-16 w-full'>
                                                    <div className='flex flex-row justify-between align-items-center w-full'>
                                                        <div
                                                            className='ladiui grid-block gap-16 w-full'
                                                            style={{ '--column': '1fr 200px' }}
                                                        >
                                                            <div className='ladiui search-group search-widget'>
                                                                <i onClick={this.list} className='ladiui icon icon-search'></i>
                                                                <input
                                                                    autoComplete='off'
                                                                    value={searchName}
                                                                    className='ladiui search-field form-control'
                                                                    style={{ minWidth: 270 }}
                                                                    name='searchName'
                                                                    placeholder={t('Tìm kiếm')}
                                                                    onKeyDown={this.onKeyDownInput}
                                                                    onChange={this.onChangeInput}
                                                                />
                                                                {searchName && (
                                                                    <i
                                                                        onClick={this.handleResetSearchInput}
                                                                        className='ladiui icon new-ldicon-close-fill-2 cursor-pointer'
                                                                    ></i>
                                                                )}
                                                            </div>
                                                            <div className='ladiui search-group'>
                                                                <Dropdown
                                                                    data={allTypes}
                                                                    key='type'
                                                                    _key='type'
                                                                    _value='name'
                                                                    pickTitle=''
                                                                    onSelectItem={this.selectType}
                                                                    currentKey={this.state.selectedType.type}
                                                                    currentValue={
                                                                        this.state.selectedType.name === 'ALL'
                                                                            ? t('INTERGRATED.SELECT_INTERGRATED')
                                                                            : this.state.selectedType.name
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {notify && (
                                                        <div className='ladiui alert alert-success alert-success-custom'>
                                                            <i className='ladiui icon icon-success-alert' />
                                                            <p className='text-secondary font-500'>
                                                                {t('Bạn đã thêm liên kết thành công')}
                                                            </p>
                                                            <button
                                                                onClick={() => {
                                                                    this.props.history.push('?tab=my');
                                                                }}
                                                                type='button'
                                                                className='ladiui close center-close'
                                                            >
                                                                <img
                                                                    src={appConfig.ICON.CLOSE_ICON}
                                                                    className='cursor-pointer block mr-0 close size-20'
                                                                />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                            tableDataConfigs={{
                                                renderTitleTable: this.renderTitleTable,
                                                renderTableItem: this.renderTableItem,
                                                tableData: {
                                                    items: integrations,
                                                    total_record,
                                                    paginationConditions,
                                                },
                                            }}
                                        >
                                            <ConfirmModal
                                                id='confirm-delete'
                                                title={'Xóa tài khoản liên kết'}
                                                content={
                                                    'Tài khoản liên kết này sẽ được xoá vĩnh viễn. Bạn có chắc chắn muốn xoá tài khoản liên kết này?'
                                                }
                                                cancelText={'Hủy'}
                                                okText={'Xóa'}
                                                onOk={() => this.props.delete(this.selectedIDs)}
                                                isConfirmModalDelete={true}
                                            />
                                            {this.state.isShowModal && (
                                                <ModalIntegrationInfo
                                                    currentInfo={this.state.currentInfo}
                                                    integrationInfo={this.state.integrationInfo}
                                                    typeIntegration={this.state.integrationInfo.type}
                                                    mode={appConfig.FORM_MODE.EDIT}
                                                    visible={this.state.isShowModal}
                                                    onSubmit={async () => {
                                                        this.setState({
                                                            isShowModal: false,
                                                        });
                                                        await this.list(true);
                                                    }}
                                                    onCancel={this.hideModalIntegrationInfo}
                                                    onSuccess={(path) => this.props.history.push(path)}
                                                />
                                            )}
                                        </ManageListLayoutV4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    list: (data) => dispatch(formAccountActions.listFormAccount(data)),
    delete: (IDs) => dispatch(formAccountActions.deleteFormAccount(IDs)),
    show: (data) => dispatch(formAccountActions.show(data)),
    listAllConnect: () => dispatch(formAccountActions.listAllConnect()),
});

const mapStateToProps = (state) => ({
    formAccountReducer: { ...state.formAccount },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IntegrationV4));
