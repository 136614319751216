import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";
import { withTranslation } from "react-i18next";

import appConfig from "../../../config/app";

class ModalExportEvent extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    isLoadingExportEvent: PropTypes.bool,
    isLoadingExportTransaction: PropTypes.bool,
    onExportEvent: PropTypes.func,
    onExportTransaction: PropTypes.func,
    onCancel: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      selected: appConfig.EXPORT_EVENT.ON_PAGE,
    };
  }

  onChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };

  exportEvent = () => {
    this.props.onExportEvent(this.state.selected);
  };

  exportTransaction = () => {
    this.props.onExportTransaction(this.state.selected);
  };

  render() {
    const { selected } = this.state;
    const { t, visible, onCancel, isLoadingExportEvent } = this.props;

    return (
      <Modal
        id="modal-export-data"
        title={t("PRODUCT_EVENTS.EXPORT_EVENT")}
        visible={visible}
        onCancel={onCancel}
        width={520}
        footer={
          <div className="ladiui-modal-footer">
            <button onClick={onCancel} className="ladiui btn btn-secondary">
              {t("ACTIONS.CANCEL")}
            </button>
            <button
              onClick={this.exportEvent}
              className={`ladiui btn btn-primary ${isLoadingExportEvent ? "loader" : ""}`}
              disabled={isLoadingExportEvent}
            >
              {" "}
              {t("PRODUCT_EVENTS.EXPORT_EVENT")}
            </button>
          </div>
        }
      >
        <div className="item">
          <input
            id="onPage"
            type="radio"
            name="radio-group"
            className="ladiui form-check-input"
            value={appConfig.EXPORT_EVENT.ON_PAGE}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_EVENT.ON_PAGE}
          />
          <label htmlFor="onPage">{t("PRODUCT_EVENTS.EXPORT_ON_PAGE")}</label>
        </div>

        <div className="item">
          <input
            id="allEvent"
            type="radio"
            name="radio-group"
            className="ladiui form-check-input"
            value={appConfig.EXPORT_EVENT.ALL_EVENT}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_EVENT.ALL_EVENT}
          />
          <label htmlFor="allEvent">{t("PRODUCT_EVENTS.EXPORT_ALL_EVENT")}</label>
        </div>

        <div className="item">
          <input
            id="selectedEvent"
            type="radio"
            name="radio-group"
            className="ladiui form-check-input"
            value={appConfig.EXPORT_EVENT.SELECTED_EVENT}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_EVENT.SELECTED_EVENT}
          />
          <label htmlFor="selectedEvent">{t("PRODUCT_EVENTS.EXPORT_SELECTED_EVENT")}</label>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalExportEvent);
