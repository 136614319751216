import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';
import i18n from '../../../i18n';

const list = () => ({
    type: types.SHIPPING_RATE_LIST,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_LIST,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const create = (shippingRate) => ({
    type: types.SHIPPING_RATE_CREATE,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_rate: shippingRate
        },
        hasAuth: true,
    }
});

const deleteShippingRate = (shippingRateID) => ({
    type: types.SHIPPING_RATE_DELETE,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_DELETE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_rate_id: shippingRateID
        },
        hasAuth: true,
    }
});

const createDetail = (shippingRateDetail) => ({
    type: types.SHIPPING_RATE_DETAIL_CREATE,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_DETAIL_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_rate_detail: shippingRateDetail
        },
        hasAuth: true,
    }
});

const updateDetail = (shippingRateDetail) => ({
    type: types.SHIPPING_RATE_DETAIL_UPDATE,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_DETAIL_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_rate_detail: shippingRateDetail
        },
        hasAuth: true,
    }
});

const deleteDetail = (shippingRateDetailID) => ({
    type: types.SHIPPING_RATE_DETAIL_DELETE,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_DETAIL_DELETE,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_rate_detail_id: shippingRateDetailID
        },
        hasAuth: true,
    }
});

const getAvailable = (data) => ({
    type: types.SHIPPING_RATE_GET_AVAILABLE,
    meta: {
        endpoint: endpoint.SHIPPING_RATE_GET_AVAILABLE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    list,
    create,
    delete: deleteShippingRate,
    createDetail,
    updateDetail,
    deleteDetail,
    getAvailable,
}