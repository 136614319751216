import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import fileActions from '../redux/futures/file/actions';
import * as fileTypes from '../redux/futures/file/types';

import Input from './Input';
import baseHelper from '../helpers/BaseHelper';
import { isEmpty } from 'lodash';

const CDN = 'https://w.ladicdn.com/';

const PanelProductSellSocial = forwardRef((props, ref) => {
    let { isShowTitle } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducerDefault = useSelector((state) => state.file);

    const [descriptionPage, setDescriptionPage] = useState('');
    const [keyWordPage, setKeyWordPage] = useState('');
    const [titlePage, setTittlePage] = useState('');
    const [urlImage, setUrlImage] = useState('');
    const [urlFavicon, setUrlFavicon] = useState('');

    const inputsRef = new Set();

    const handleChangeImageUrl = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadImages(form));
            }
        }
    };

    // useEffect(() => {
    //   if (fileReducerDefault.status) {
    //     if (fileReducerDefault.url_images) {
    //       setUrlImage(fileReducerDefault.url_images[0]);
    //     }
    //     if (fileReducerDefault.favicon_url) {
    //       setUrlFavicon("https://w.ladicdn.com/" + fileReducerDefault.favicon_url[0]);
    //     }
    //   }
    // }, [fileReducerDefault]);

    useEffect(() => {
        if (fileReducerDefault.action == fileTypes.UPLOAD_IMAGES) {
            if (fileReducerDefault.status) {
                if (fileReducerDefault.url_images) {
                    setUrlImage(fileReducerDefault.url_images[0]);
                }
            }
        }

        if (fileReducerDefault.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducerDefault.status) {
                setUrlFavicon(fileReducerDefault.favicon_url);
            }
        }
    }, [fileReducerDefault]);

    useEffect(() => {
        if (props.productInfoSeo && !isEmpty(props.productInfoSeo)) {
            let { title, description, keywords, favicon, image } = props.productInfoSeo;
            setTittlePage(title);
            setDescriptionPage(description);
            setUrlImage(image);
            setKeyWordPage(keywords);
            setUrlFavicon(favicon);
        }
    }, [props.productInfoSeo]);

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                title: titlePage ? titlePage : '',
                description: descriptionPage ? descriptionPage : '',
                keywords: keyWordPage ? keyWordPage : '',
                image: urlImage ? urlImage : '',
                favicon: urlFavicon ? urlFavicon : '',
            };
        },
    }));

    const fileChangedHandlerCartFavicon = (event) => {
        if (event.target.files.length > 0) {
            const uploadFile = event.target.files[0];
            const form = baseHelper.getFormDataUpload([uploadFile]);
            if (form) {
                dispatch(fileActions.uploadFavicon(form));
            }
        }
    };

    const removeCartFavicon = () => {
        setUrlFavicon('');
    };

    return (
        <div className='block-seo-social'>
            {isShowTitle && <h3 className='title'>SEO & Social</h3>}
            <div className='ladiui form-group config-panel'>
                <div className='panel'>
                    <div className='panel-line'>
                        <div className='main-description'>
                            Favicon
                            <br />
                            <div className='sub-description' style={{ whiteSpace: 'nowrap' }}>
                                Kích thước 16x16 (px)
                                <br />
                                (Định dạng .png, .jpg, .svg)
                            </div>
                        </div>
                        <div className=''>
                            <div className='preview-store-logo'>
                                <div id='store-logo'>
                                    <img
                                        className='store-logo'
                                        src={urlFavicon ? `${CDN}${urlFavicon}` : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'}
                                        alt=''
                                    />
                                    <div id='store-logo-overlay'>
                                        <div className='ladiui btn-group'>
                                            <div className='ladiui dropdown'>
                                                <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                    <i className='ladiui icon icon-ldp-pen-edit'></i>
                                                </button>
                                                <ul
                                                    className='ladiui dropdown-menu custom-dropdown-menu'
                                                    style={{ minWidth: '100px', textAlign: 'left' }}
                                                >
                                                    <li className='ladiui open-modal'>
                                                        <label htmlFor='upload-cart-favicon'>Thay ảnh</label>
                                                    </li>
                                                    <li className='ladiui dropdown-item danger-text' onClick={() => removeCartFavicon()}>
                                                        Xóa
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    type='file'
                                    id='upload-cart-favicon'
                                    name='file'
                                    accept='image/*'
                                    multiple={false}
                                    onChange={fileChangedHandlerCartFavicon}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('SEO_SOCIAL.TITLE_PAGE')}</label>
                <Input
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_TITLE')}
                    value={titlePage}
                    onChange={(event) => {
                        setTittlePage(event.target.value);
                    }}
                />
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('SEO_SOCIAL.DESCRIPTION')}</label>
                <textarea
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_DESCRIPTION')}
                    value={descriptionPage}
                    className='ladiui form-control'
                    onChange={(event) => {
                        setDescriptionPage(event.target.value);
                    }}
                ></textarea>
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label'>{t('SEO_SOCIAL.KEY_WORD')}</label>
                <Input
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_KEY_WORD')}
                    value={keyWordPage}
                    onChange={(event) => {
                        setKeyWordPage(event.target.value);
                    }}
                />
            </div>
            <div className='ladiui form-group'>
                <label className='ladiui-label'>Hình ảnh khi chia sẻ</label>
                <div className='input-upload-file relative w100'>
                    <Input
                        className='pr-100'
                        ref={(ref) => inputsRef.add(ref)}
                        value={urlImage}
                        placeholder={t('SEO_SOCIAL.CHOOSE_PICTURE')}
                        onChange={(event) => {
                            setUrlImage(event.target.value);
                        }}
                    />
                    <div className='ladiui-button-upload'>
                        <button type='button' className='flex'>
                            <i className='icon-export mr-4'></i>
                            <span>Tải lên</span>
                        </button>
                        <input type='file' name='file' accept='image/*' onChange={handleChangeImageUrl} />
                    </div>
                </div>
            </div>

            {/* <div className="ladiui form-group">
        <label className="ladiui-label">Hình ảnh Favicon</label>
        <div className="input-upload-file relative w100">
          <Input
            className="pr-100"
            ref={(ref) => inputsRef.add(ref)}
            value={urlFavicon}
            placeholder={t("SEO_SOCIAL.CHOOSE_FAVICON")}
            onChange={(event) => {
              setUrlFavicon(event.target.value);
            }}
          />
          <div className="ladiui-button-upload">
            <button type="button" className="flex">
              <i className="icon-export mr-4"></i>
              <span>Tải lên</span>
            </button>
            <input type="file" name="file" accept="image/*" onChange={handleChangeFaviconUrl} />
          </div>
        </div>
      </div> */}

            {/* <div className="ladiui form-group">
        <label className="ladiui-label">{t("SEO_SOCIAL.CANONICAL_URL")}</label>
        <Input
          ref={(ref) => inputsRef.add(ref)}
          placeholder={t("SEO_SOCIAL.ENTER_CANONICAL_URL")}
          value={canonicalUrlPage}
          onChange={(event) => {
            setCanonicalUrlPage(event.target.value);
          }}
        />
      </div> */}
        </div>
    );
});

PanelProductSellSocial.propTypes = {
    titlePage: PropTypes.string,
    descriptionPage: PropTypes.string,
    img_publish: PropTypes.string,
    favicon: PropTypes.string,
};

export default PanelProductSellSocial;
