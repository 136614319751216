import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import baseHelper from '../../../../../helpers/BaseHelper';
import appConfig from '../../../../../config/app';

import * as fileTypes from '../../../../../redux/futures/file/types';
import fileActions from '../../../../../redux/futures/file/actions';

import PopoverAddSection from './PopoverAddSection';
import ImageUpload from '../../../../../components/ImageUpload';
import Input from '../../../../../components/Input';
import HandelSectionProducts from './HandelSectionProducts';

// eslint-disable-next-line max-lines-per-function

const HandelSection = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { type } = props;

    const fileReducer = useSelector((state) => state.file);

    const sectionRef = useRef();

    const [cartContener, setCartContener] = useState([]);
    const [indexSelected, setIndexSelected] = useState();

    const sectionData = useMemo(() => {
        return props.sectionData || [];
    }, [props.sectionData]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let cartContenerTg = cartContener.map((item) => {
                if (item.action == 'PRODUCT') {
                    let productTg = item.products || [];
                    item.product_ids = productTg.map((_item) => _item.product_id);
                }

                return item;
            });
            return cartContenerTg;
        },
    }));

    useEffect(() => {
        if (sectionData) setCartContener(sectionData);
    }, [sectionData]);

    useEffect(() => {
        // let iframeElm = document.getElementById('iframe-page-checkout-dns');
        // if (iframeElm) {
        //     let sectionCover = cartContener.map((item) => {
        //         let itemreturn = { ...item };
        //         switch (item.action) {
        //             case 'PRODUCT_LIST':
        //                 itemreturn.type = 'PRODUCT_LIST';
        //                 // itemreturn.url = item.image_src;
        //                 break;
        //             case 'PRODUCT':
        //                 itemreturn.type = 'PRODUCT';
        //                 // itemreturn.url = item.image_src;
        //                 break;
        //             case 'IMAGE':
        //                 itemreturn.type = 'BANNER';
        //                 itemreturn.url = item.image_src;
        //                 break;
        //             case 'TEXT_EDITER':
        //                 itemreturn.type = 'TEXT_EDITER';
        //                 itemreturn.value = item.content;
        //                 delete itemreturn.content;
        //                 break;
        //             default:
        //                 break;
        //         }
        //         return itemreturn;
        //     });
        //     if (type == 'CART') {
        //         const dataSend = {
        //             type: 'LADI_UPDATE_STORE_WIDGET',
        //             key: 'cart_config',
        //             value: sectionCover,
        //         };
        //         iframeElm.contentWindow.postMessage(dataSend, '*');
        //     }
        // }
    }, [cartContener]);

    const reorder = (list, startIndex, endIndex) => {
        let remove = list[startIndex];
        list.splice(startIndex, 1);
        list.splice(endIndex, 0, remove);

        return list;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let cartContenerTg = [...cartContener];

        cartContenerTg = reorder(cartContenerTg, result.source.index, result.destination.index);
        setCartContener(cartContenerTg);
    };

    const handleOpenMethod = (index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg[index].isShowDetail = !cartContenerTg[index].isShowDetail;
        setCartContener(cartContenerTg);
    };

    const addImage = () => {
        let cartContenerTg = [...cartContener];
        cartContenerTg.push({
            title: 'Hình ảnh',
            iconClass: 'new-ldicon-photo',
            details: 'Image',
            action: 'IMAGE',
            isShowDetail: true,
            image_src: '',
        });
        setCartContener(cartContenerTg);

        if (type == 'SETUP_FOOTER') {
            setTimeout(() => {
                if (sectionRef) {
                    window.scrollTo({ left: 0, top: sectionRef.current.scrollHeight - 200, behavior: 'smooth' });
                }
            }, 500);
        } else
            setTimeout(() => {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            }, 500);
    };

    const addProductBlock = () => {
        let cartContenerTg = [...cartContener];
        cartContenerTg.push({
            title: 'Sản phẩm',
            iconClass: 'new-ldicon-box',
            details: 'Product',
            action: 'PRODUCT',
            isShowDetail: true,
            products: [],
            displayLayout: appConfig.PRODUCT_LAYOUT_TYPE.Carousel,
        });
        setCartContener(cartContenerTg);

        if (type == 'SETUP_FOOTER') {
            setTimeout(() => {
                if (sectionRef) {
                    window.scrollTo({ left: 0, top: sectionRef.current.scrollHeight - 200, behavior: 'smooth' });
                }
            }, 500);
        } else
            setTimeout(() => {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            }, 500);
    };

    const addUpSellBlock = () => {
        let cartContenerTg = [...cartContener];
        cartContenerTg.push({
            title: t('SALE_CHANNEL.PRODUCT_UPSELL_TITLE'),
            iconClass: 'new-ldicon-box',
            details: 'Variant',
            action: 'PRODUCT_UPSELL',
            isShowDetail: true,
            max_variant: 3,
        });
        setCartContener(cartContenerTg);

        if (type == 'SETUP_FOOTER') {
            setTimeout(() => {
                if (sectionRef) {
                    window.scrollTo({ left: 0, top: sectionRef.current.scrollHeight - 200, behavior: 'smooth' });
                }
            }, 500);
        } else
            setTimeout(() => {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            }, 500);
    };

    const addTextEditer = () => {
        let cartContenerTg = [...cartContener];
        cartContenerTg.push({
            title: 'Trình soạn thảo văn bản',
            iconClass: 'new-ldicon-create-content',
            isShowDetail: true,
            action: 'TEXT_EDITER',
            details: 'Text',
            content: '',
        });
        setCartContener(cartContenerTg);
        if (type == 'SETUP_FOOTER') {
            setTimeout(() => {
                if (sectionRef) {
                    window.scrollTo({ left: 0, top: sectionRef.current.scrollHeight - 200, behavior: 'smooth' });
                }
            }, 500);
        } else
            setTimeout(() => {
                window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
            }, 500);
    };

    const setSelectedUrlImage = (value, index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg[index].image_src = value.src;
        cartContenerTg[index].title = value.name;

        setCartContener(cartContenerTg);
    };

    const removeItem = (index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg.splice(index, 1);

        setCartContener(cartContenerTg);
    };

    const handleEditorChange = (value, index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg[index].content = value;
        setCartContener(cartContenerTg);
    };

    const handelProductChange = (name, value, index) => {
        let cartContenerTg = [...cartContener];
        cartContenerTg[index][name] = value;
        setCartContener(cartContenerTg);
    };

    return (
        <>
            {cartContener && cartContener.length > 0 && (
                <div ref={sectionRef}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='cartSetting' type='droppableCart'>
                            {(provided, snapshot) => (
                                <div
                                    className='carts-item-list'
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {cartContener.map((item, index) => (
                                        <Draggable key={`${item.action}-${index}`} draggableId={`${item.action}-${index}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className='carts-item-item'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div className={`carts-item-head ${item.isShowDetail ? 'active' : ''}`}>
                                                        <div className='display-flex-center gap-16'>
                                                            <i className='icon-move' />

                                                            <div className='gateway-name '>
                                                                <div>
                                                                    <i className={`ladiui icon-new ${item.iconClass}`} />
                                                                </div>
                                                                <span className='name'>{item.title}</span>
                                                            </div>
                                                        </div>
                                                        {item.details && (
                                                            <div className='gateway-open-method' onClick={() => handleOpenMethod(index)}>
                                                                <i className='new-ldicon-arrow-up' />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {item.action == 'IMAGE' && item.isShowDetail && (
                                                        <div className='section-value'>
                                                            <ImageUpload
                                                                isClickUrl={true}
                                                                imageClickUrl={item.link || ''}
                                                                handleImageUrl={(link) => {
                                                                    let cartContenerTg = [...cartContener];
                                                                    cartContenerTg[index].link = link;
                                                                    setCartContener(cartContenerTg);
                                                                }}
                                                                indexSection={`${type}-${index}`}
                                                                setImageData={(image) => {
                                                                    if (image.indexSection == `${type}-${index}`)
                                                                        setSelectedUrlImage(image, index);
                                                                }}
                                                                setImageSrc={item.image_src}
                                                            />

                                                            <div className='action-delete' onClick={() => removeItem(index)}>
                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                <span className='ladiui text-danger'>
                                                                    {t('SALE_CHANNEL.SECTION_DELETE_LB')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.action == 'TEXT_EDITER' && item.isShowDetail && (
                                                        <div className='section-value'>
                                                            <div className='container-image ladiui p-0'>
                                                                <Editor
                                                                    tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                                                    licenseKey="gpl"
                                                                    value={item.content || ''}
                                                                    init={{
                                                                        selector: 'textarea',
                                                                        min_height: 200,
                                                                        max_height: 800,
                                                                        autoresize_overflow_padding: 5,
                                                                        autoresize_bottom_margin: 24,
                                                                        plugins:
                                                                            'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                                                        toolbar:
                                                                            'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                                                        toolbar_drawer: 'sliding',
                                                                        language_url: '../../../language/tinymce-vi.js',
                                                                        content_style:
                                                                            'body { font-family:Roboto,sans-serif; font-size:14px }',
                                                                    }}
                                                                    onEditorChange={(value) => handleEditorChange(value, index)}
                                                                />
                                                            </div>

                                                            <div className='action-delete' onClick={() => removeItem(index)}>
                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                <span className='ladiui text-danger'>
                                                                    {t('SALE_CHANNEL.SECTION_DELETE_LB')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.action == 'PRODUCT' && item.isShowDetail && (
                                                        <div className='section-value'>
                                                            <div className='container-product-info'>
                                                                <div>
                                                                    <label className='ladiui text-14'>
                                                                        {t('SALE_CHANNEL.SECTION_TITLE_LB')}
                                                                    </label>
                                                                    <Input
                                                                        className='mt-4'
                                                                        name='title'
                                                                        placeholder='Vd: Sản phẩm nổi bật'
                                                                        value={item.title}
                                                                        onChange={(e) => {
                                                                            handelProductChange('title', e.target.value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='block-display-type'>
                                                                    <h3 className='ladiui-label'>
                                                                        {t('SALE_CHANNEL.SECTION_PRODUCT_LAYOUT_LB')}
                                                                    </h3>
                                                                    <div className='block-content'>
                                                                        <div
                                                                            className={`block-item left ${item.displayLayout == 'Carousel' ? 'active' : ''
                                                                                }`}
                                                                            onClick={() => {
                                                                                handelProductChange('displayLayout', 'Carousel', index);
                                                                            }}
                                                                        >
                                                                            <div className='list-pr-exam'>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={`block-item right ${item.displayLayout == 'Grid' ? 'active' : ''
                                                                                }`}
                                                                            onClick={() => {
                                                                                handelProductChange('displayLayout', 'Grid', index);
                                                                            }}
                                                                        >
                                                                            <div className='list-pr-exam'>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                                <div className='pr-item'>
                                                                                    <div className='top'></div>
                                                                                    <div className='bottom'></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='mt-24'>
                                                                    <HandelSectionProducts
                                                                        products={item.products || []}
                                                                        setProducts={(datas) => {
                                                                            handelProductChange('products', datas, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='action-delete' onClick={() => removeItem(index)}>
                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                <span className='ladiui text-danger'>
                                                                    {t('SALE_CHANNEL.SECTION_DELETE_LB')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {item.action == 'PRODUCT_UPSELL' && item.isShowDetail && (
                                                        <div className='section-value'>
                                                            <div className='container-product-info'>
                                                                <div>
                                                                    <label className='ladiui text-14'>
                                                                        {t('SALE_CHANNEL.SECTION_TITLE_LB')}
                                                                    </label>
                                                                    <Input
                                                                        className='mt-4'
                                                                        name='title'
                                                                        placeholder='Vd: Sản phẩm nổi bật'
                                                                        value={item.title}
                                                                        onChange={(e) => {
                                                                            handelProductChange('title', e.target.value, index);
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className='mt-12'>
                                                                    <label className='ladiui text-14'>{t('SALE_CHANNEL.UPSELL_MAX')}</label>
                                                                    <Input
                                                                        className='mt-4'
                                                                        type='number'
                                                                        name='max_variant'
                                                                        placeholder='Nhập số lượng tối đa'
                                                                        value={item.max_variant}
                                                                        onChange={(e) => {
                                                                            handelProductChange('max_variant', e.target.value, index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className='action-delete' onClick={() => removeItem(index)}>
                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                <span className='ladiui text-danger'>
                                                                    {t('SALE_CHANNEL.SECTION_DELETE_LB')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
            <PopoverAddSection
                addImage={addImage}
                addTextEditer={addTextEditer}
                addProductBlock={addProductBlock}
                addUpSellBlock={addUpSellBlock}
                type={type}
            />
        </>
    );
});

export default HandelSection;
