import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const storePageList = (data) => ({
  type: types.STORE_PAGE_LIST,
  meta: {
    endpoint: endpoint.STORE_PAGE_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const storePageDelete = (pageCheckoutId) => ({
  type: types.STORE_PAGE_DELETE,
  meta: {
    endpoint: endpoint.STORE_PAGE_DELETE,
    method: REQUEST_METHOD.POST,
    body: {
      page_checkout_id: pageCheckoutId,
    },
    hasAuth: true,
  },
});

const storePageDeletes = (pageCheckoutIds) => ({
  type: types.STORE_PAGE_DELETES,
  meta: {
    endpoint: endpoint.STORE_PAGE_DELETES,
    method: REQUEST_METHOD.POST,
    body: {
      page_checkout_ids: pageCheckoutIds,
    },
    hasAuth: true,
  },
});

export default {
  storePageList,
  storePageDelete,
  storePageDeletes
};
