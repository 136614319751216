import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep, filter, map, compact, includes, size } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import appConfig from "../../../config/app";

import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import Dropdown from "../../../components/Dropdown";

import * as formAccountTypes from "../../../redux/futures/form_account/types";
import formAccountActions from "../../../redux/futures/form_account/actions";
import baseHelper from "../../../helpers/BaseHelper";
import ModalConVerKit from "./ModalIntegrate/ModalConVerKit";
import ModalGoogleSheet from "./ModalIntegrate/ModalGoogleSheet";
import ModalGetResponse from "./ModalIntegrate/ModalGetResponse";
import ModalHubsPot from "./ModalIntegrate/ModalHubsPot";
import ModalActiveCampaign from "./ModalIntegrate/ModalActiveCampaign";
import ModalInfusion from "./ModalIntegrate/ModalInfusion";
import ModalMailChimp from "./ModalIntegrate/ModalMailChimp";
import ModalHaravan from "./ModalIntegrate/ModalHaravan";
import ModalSapo from "./ModalIntegrate/ModalSapo";
import ModalShopify from "./ModalIntegrate/ModalShopify";
import ModalNhanh from "./ModalIntegrate/ModalNhanh";
import ModalWordPress from "./ModalIntegrate/ModalWordPress";
import ModalKiotViet from "./ModalIntegrate/ModalKiotViet";

ModalFormAccount.propTypes = {
  mode: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  currentFormAccount: PropTypes.any,
  handleSetDataAccessAccount: PropTypes.func,
  selectedDataAccessAccount: PropTypes.object,
  handleSetSelectedAccountType: PropTypes.func
};

export default function ModalFormAccount(props) {
  const { t } = useTranslation();

  const { selectedDataAccessAccount } = props;
  const dispatch = useDispatch();
  const formAccountReducer = useSelector((state) => state.formAccount);
  const [selectAccountType, setSelectAccountType] = useState("");
  // const [selectAccountType, setSelectAccountType] = useState(props.currentFormAccount.type
  //     ? { CODE: props.currentFormAccount.type } : appConfig.FORM_TYPE.GOOGLE_SHEET);
  const [formAccount, setFormAccount] = useState(props.mode == appConfig.FORM_MODE.CREATE ? {} : props.currentFormAccount);
  const [textToggleInputType, setTextToggleInputType] = useState(false);
  const [keySearch, setKeySearch] = useState("");
  const [valueSearch, setValueSearch] = useState("");

  const [titleModal, setTitleModal] = useState(null);
  const [logoModal, setLogoModal] = useState(null);
  const [descriptionModal, setDescriptionModal] = useState(null);

  const inputRefs = new Set();

  useEffect(() => {
    window.LadiUI.init();
    window.LadiUI.customInit();
  });

  useEffect(() => {
    window.callBackEventInfusionSoft = (event) => {
      dispatch(
        formAccountActions.oauthAccess({
          code: event.data.code,
          type: appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE,
        })
      );
    };

    window.callBackEventGoogleSheet = (event) => {
      dispatch(
        formAccountActions.oauth2GoogleSheets({
          code: event.data.code,
          id: props.currentFormAccount.id,
        })
      );
    };
  }, []);

  useEffect(() => {
    if (props.currentDataAccountType) {
      setSelectAccountType(props.currentDataAccountType);
      setLogoModal(getAccountTypeIcon(props.currentDataAccountType.CODE));
      setTitleModal(props.currentDataAccountType.NAME);
      setDescriptionModal(props.currentDataAccountType.DESCRIPTION);
    }
  }, [props.currentDataAccountType])

  useEffect(() => {
    if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH_ACCESS) {
      if (formAccountReducer.status) {
        const data = {
          ...formAccount,
          type: selectAccountType.CODE,
          access_token: formAccountReducer.account.access_token,
          refresh_token: formAccountReducer.account.refresh_token,
        };

        props.onSubmit(data);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_OAUTH2_GET_LINK) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_GOOGLE_SHEETS) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_HARAVAN) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_SAPO) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_SHOPIFY) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    } else if (formAccountReducer.action === formAccountTypes.INTEGRATE_NHANH) {
      if (formAccountReducer.status) {
        window.LadiUI.popupWindow(formAccountReducer.url, "sharer", window, 550, 600);
      } else {
        window.LadiUI.showErrorMessage("Thông báo", formAccountReducer.message, "OK");
      }
    }
  }, [formAccountReducer]);

  const onChangeInput = (event) => {
    let accountData = cloneDeep(formAccount);
    accountData = {
      ...accountData,
      [event.target.name]: event.target.value,
    };
    setFormAccount(accountData);
  };

  const getLinkApiUrl = () => {
    if (selectAccountType.CODE == appConfig.FORM_TYPE.HARAVAN.CODE) {
      dispatch(formAccountActions.haravan());
      window.callBackEventHaravan = (event) => {
        dispatch(
          formAccountActions.createHaravan({
            ...formAccount,
            token: event.data.code,
            shop_id: event.data.shop,
            type: "HARAVAN",
          })
        );
      };
    }
    if (selectAccountType.CODE == appConfig.FORM_TYPE.SAPO.CODE) {
      dispatch(
        formAccountActions.sapo({
          store_url: formAccount.api_url,
        })
      );
      window.callBackEventSapo = (event) => {
        dispatch(
          formAccountActions.createSapo({
            ...formAccount,
            store_url: event.data.store,
            code: event.data.code,
          })
        );
      };
    }
    if (selectAccountType.CODE == appConfig.FORM_TYPE.SHOPIFY.CODE) {
      dispatch(
        formAccountActions.shopify({
          store_url: formAccount.api_url,
        })
      );
      window.callBackEventShopify = (event) => {
        // dispatch(formAccountActions.createShopify({
        //     ...formAccount,
        //     store_url: event.data.store,
        //     code: event.data.code,
        // }))
      };
    }

    if (selectAccountType.CODE == appConfig.FORM_TYPE.NHANH.CODE) {
      dispatch(formAccountActions.nhanh());
      window.callBackEventNhanh = (event) => {
        dispatch(
          formAccountActions.createNhanh({
            ...formAccount,
            code: event.data.code,
          })
        );
      };
    }
  };

  const submit = () => {
    let errors = [];

    if (inputRefs) {
      const inputsRef = compact(Array.from(inputRefs));
      map(inputsRef, (ref) => {
        if (ref.validate) {
          errors = errors.concat(ref.validate());
        }
      });

      errors = compact(errors);

      if (errors.length > 0) {
        const errorMessage = errors.join("<br/>");
        window.LadiUI.toastCustom("danger", "", errorMessage);
        return;
      }
    }

    const data = {
      ...formAccount,
      type: selectAccountType.CODE,
    };

    if (selectAccountType.CODE == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE) {
      dispatch(formAccountActions.oauth2GetLink(data.type));
    } else if (selectAccountType.CODE == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE) {
      dispatch(formAccountActions.googleSheets());
    } else {
      props.onSubmit(data);
    }
  };

  const getAccountTypeIcon = (type) => {
    return baseHelper.getAccountTypeIcon(type);
  };

  const handleSelectedAccountType = (item) => {
    if (item) {
      setSelectAccountType(item);
      setLogoModal(getAccountTypeIcon(item.CODE));
      setTitleModal(item.NAME);
      setDescriptionModal(item.DESCRIPTION);

      props.handleSetDataAccessAccount(item.CODE);
      props.handleSetSelectedAccountType(item);
    }
  };

  const handleResetValue = () => {
    setSelectAccountType("");
    setTitleModal(null);
    setLogoModal(null);
    props.handleResetDataSelectedAccountType();
  };

  const renderForm = () => {
    let formContent = <></>;
    switch (selectAccountType.CODE) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        formContent = <ModalGoogleSheet handleSubmit={() => submit()} mode={props.mode} selectedDataAccessAccount={props.selectedDataAccessAccount || {}} />;
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        formContent = (
          <ModalConVerKit
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        formContent = (
          <ModalGetResponse
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        formContent = (
          <ModalHubsPot
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        formContent = (
          <ModalActiveCampaign
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        formContent = <ModalInfusion inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} handleSubmit={() => submit()} />;
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        formContent = (
          <ModalMailChimp
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        formContent = <ModalHaravan inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} handleSubmit={() => getLinkApiUrl()} selectedDataAccessAccount={props.selectedDataAccessAccount || {}} />;
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        formContent = <ModalSapo inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} handleSubmit={() => getLinkApiUrl()} selectedDataAccessAccount={props.selectedDataAccessAccount || {}} />;
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        formContent = <ModalShopify inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} />;
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        formContent = (
          <ModalWordPress
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        formContent = (
          <ModalKiotViet
            inputRefs={inputRefs}
            textToggleInputType={textToggleInputType}
            setTextToggleInputType={setTextToggleInputType}
            formAccount={formAccount}
            onChangeInput={onChangeInput}
          />
        );
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        formContent = <ModalNhanh inputRefs={inputRefs} formAccount={formAccount} onChangeInput={onChangeInput} handleSubmit={() => getLinkApiUrl()} selectedDataAccessAccount={props.selectedDataAccessAccount || {}} />;
        break;
      default:
    }

    return formContent;
  };

  const getUrlHelpIntegrate = (type) => {
    let url = "";
    switch (type) {
      case appConfig.FORM_TYPE.GOOGLE_SHEET.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-google-sheet";
        break;
      case appConfig.FORM_TYPE.CONVERTKIT.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-convertkit";
        break;
      case appConfig.FORM_TYPE.GET_RESPONSE.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-getresponse";
        break;
      case appConfig.FORM_TYPE.HUBSPOT.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-hubspot";
        break;
      case appConfig.FORM_TYPE.ACTIVE_CAMPAIGN.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-activecampaign";
        break;
      case appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-infusion-soft";
        break;
      case appConfig.FORM_TYPE.MAIL_CHIMP.CODE:
        url = "https://help.ladipage.vn/form-data/cac-buoc-cai-dat-luu-data/luu-data-ve-mailchimp";
        break;
      case appConfig.FORM_TYPE.HARAVAN.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-haravan";
        break;
      case appConfig.FORM_TYPE.SAPO.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-sapo";
        break;
      case appConfig.FORM_TYPE.SHOPIFY.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-shopify";
        break;
      case appConfig.FORM_TYPE.WORDPRESS.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-wordpress";
        break;
      case appConfig.FORM_TYPE.KIOTVIET.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landing-page-sang-kiotviet";
        break;
      case appConfig.FORM_TYPE.NHANH.CODE:
        url =
          "https://help.ladipage.vn/dong-bo-don-hang-sang-cac-nen-tang-website-thuong-mai-dien-tu-va-phan-mem-quan-ly-ban-hang/dong-bo-don-hang-tu-landingpage-sang-nhanh.vn";
        break;
      default:
    }
    return url;
  };

  const handleSearchFormType = () => {
    setValueSearch(keySearch);
  };

  const handleChangeKeyPress = (event) => {
    if (event.key === "Enter") {
      setValueSearch(keySearch);
    }
  };

  const handleShowModalConfig = () => {
    props.handleCheckOpenModalFormConfig()
  }

  const handleCheckConnectSuccessAccount = () => {
    props.handleConnectSuccessAccount();
  }

  return (
    <Modal
      id="modal-form-account"
      // title={props.mode === appConfig.FORM_MODE.CREATE && !titleModal ? t("INTEGRATE.ADD_NEW_AFFILIATE_ACCOUNT") : titleModal}
      title="Thêm tài khoản liên kết"
      sub_title="Kết nối nền tảng bạn muốn tích hợp với LadiSales"
      onOk={["HARAVAN", "SAPO", "SHOPIFY", "NHANH"].includes(selectAccountType.CODE) ? getLinkApiUrl : submit}
      onCancel={props.onCancel}
      isLoading={formAccountReducer.loading}
      resetAccountType={handleResetValue}
      isShowIconBack={selectAccountType ? true : false}
      visible={props.visible}
      // descriptionModal={descriptionModal}
      // isShowDescription={descriptionModal ? true : false}
      // bodyStyles={{ minHeight: '50vh' }}
      // logo={logoModal}
      hasFooter={selectAccountType ? true : false}
      width={800}
      okText={t("ACTIONS.CONTINUE")}
      isCustomFooter={true}

      footer={
        <div className="ladiui-modal-footer">
          {/* <div id="data-tooltip-guide" className="ladiui help-integrate">
            <span className="tooltip-icon flex mr-10" data-tooltip-position="right">
              {" "}
              <i className="ladi-icon icon-c-question" />
            </span>
            <span>
              {t("INTEGRATE.HELP_CONNECT_ACCOUNT")} &nbsp;
              <a href={getUrlHelpIntegrate(selectAccountType.CODE)} target="_blank">
                {selectAccountType.NAME}
              </a>
            </span>
          </div> */}
          <button className="ladiui btn btn-secondary flex items-center" onClick={handleResetValue}>
            <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M4.64375 9.85623L0.14375 5.35623C0.0513399 5.26066 -0.000317797 5.13292 -0.000317791 4.99998C-0.000317785 4.86704 0.0513399 4.7393 0.14375 4.64373L4.64375 0.143731C4.7409 0.0639991 4.86423 0.0232532 4.98976 0.0294182C5.11528 0.0355833 5.23403 0.0882185 5.3229 0.177086C5.41176 0.265953 5.4644 0.384699 5.47056 0.510225C5.47673 0.635751 5.43598 0.759083 5.35625 0.856231L1.70625 4.49998L11.5 4.49998C11.6326 4.49998 11.7598 4.55266 11.8536 4.64643C11.9473 4.7402 12 4.86737 12 4.99998C12 5.13259 11.9473 5.25977 11.8536 5.35353C11.7598 5.4473 11.6326 5.49998 11.5 5.49998L1.70625 5.49998L5.35625 9.14373C5.43598 9.24088 5.47673 9.36421 5.47056 9.48974C5.4644 9.61526 5.41176 9.73401 5.32289 9.82288C5.23403 9.91174 5.11528 9.96438 4.98976 9.97054C4.86423 9.97671 4.7409 9.93596 4.64375 9.85623V9.85623Z'
                fill='#AFAFAF'
              />
            </svg>
            <span className="ml-8">Quay lại</span>
          </button>
          <div className="flex">
            <button onClick={props.onCancel} className="ladiui btn btn-secondary mr-12">
              {t("ACTIONS.REMOVE")}
            </button>

            {/* {
              ![
                appConfig.FORM_TYPE.HARAVAN.CODE,
                appConfig.FORM_TYPE.NHANH.CODE,
                appConfig.FORM_TYPE.SAPO.CODE,
                appConfig.FORM_TYPE.WORDPRESS.CODE,
              ].includes(selectAccountType.CODE) ? (
                <button
                  onClick={["HARAVAN", "SAPO", "SHOPIFY", "NHANH"].includes(selectAccountType.CODE) ? getLinkApiUrl : submit}
                  className={`ladiui btn btn-primary ${!props.selectedDataAccessAccount || !props.selectedDataAccessAccount.form_account_id ? "disabled" : ""} ${props.isLoading ? "loader" : ""}`}
                >
                  {t("ACTIONS.CONTINUE")}
                </button>
              ) : (
                <button
                  onClick={["HARAVAN", "SAPO", "SHOPIFY", "NHANH"].includes(selectAccountType.CODE) ? getLinkApiUrl : submit}
                  className={`ladiui btn btn-primary ${!props.selectedDataAccessAccount || !props.selectedDataAccessAccount.form_account_id ? "disabled" : ""} ${props.isLoading ? "loader" : ""}`}
                >
                  {t("ACTIONS.ADD_LINK")}
                </button>
              )
            } */}


            {/* <button
              onClick={["HARAVAN", "SAPO", "SHOPIFY", "NHANH"].includes(selectAccountType.CODE) ? getLinkApiUrl : submit}
              className={`ladiui btn btn-primary ${props.isLoading ? "loader" : ""}`}
            >
              {selectAccountType.CODE == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.HARAVAN.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.NHANH.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.SAPO.CODE
                ? t("ACTIONS.CONTINUE")
                : t("ACTIONS.ADD_LINK")}
            </button> */}

            {[
              appConfig.FORM_TYPE.HARAVAN.CODE,
              appConfig.FORM_TYPE.NHANH.CODE,
              appConfig.FORM_TYPE.SAPO.CODE,
              appConfig.FORM_TYPE.WORDPRESS.CODE,
            ].includes(selectAccountType.CODE) ? (
              <button
                className={`ladiui btn btn-primary ${props.isLoading ? "loader" : ""}`} onClick={handleCheckConnectSuccessAccount}
                disabled={!selectedDataAccessAccount || !selectedDataAccessAccount.form_account_id}
              >
                Hoàn thành
              </button>
            ) : (
              <button
                onClick={["GOOGLE_SHEET", "INFUSION_SOFT_LADI"].includes(selectAccountType.CODE) ? handleShowModalConfig : submit}
                className={`ladiui btn btn-primary ${props.isLoading ? "loader" : ""}`}
                disabled={["GOOGLE_SHEET", "INFUSION_SOFT_LADI"].includes(selectAccountType.CODE) && !selectedDataAccessAccount.form_account_id}
              >
                {selectAccountType.CODE == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE ||
                  selectAccountType.CODE == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE
                  ? t("ACTIONS.CONTINUE")
                  : t("ACTIONS.ADD_LINK")}
              </button>
            )
            }

            {/* <button
              onClick={["GOOGLE_SHEET", "HARAVAN", "INFUSION_SOFT_LADI", "NHANH", "SAPO"].includes(selectAccountType.CODE) ? handleShowModalConfig : submit}
              className={`ladiui btn btn-primary ${props.isLoading ? "loader" : ""}`}
            >
              {selectAccountType.CODE == appConfig.FORM_TYPE.GOOGLE_SHEET.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.INFUSION_SOFT_LADI.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.HARAVAN.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.NHANH.CODE ||
                selectAccountType.CODE == appConfig.FORM_TYPE.SAPO.CODE
                ? t("ACTIONS.CONTINUE")
                : t("ACTIONS.ADD_LINK")}
            </button> */}


          </div>
        </div>
      }
    >
      <div>
        <div className="list-steps-integrate">
          <ul>
            <li>
              <div className="step-number" style={!selectAccountType ? { background: "#D8E7FE", color: "#1c00c2" } : { background: "#D8E7FE", color: "#3C72F9" }}>1</div>
              <span style={{ color: "#0E233C", fontWeight: 500 }}>Chọn nền tảng tích hợp</span>
            </li>
            <li>
              <div className="step-number" style={selectAccountType ? { background: "#D8E7FE", color: "#1c00c2" } : {}}>2</div>
              <span style={selectAccountType ? { color: "#0E233C", fontWeight: 500 } : {}}>Liên kết tài khoản</span>
            </li>
            {![
              appConfig.FORM_TYPE.HARAVAN.CODE,
              appConfig.FORM_TYPE.NHANH.CODE,
              appConfig.FORM_TYPE.SAPO.CODE,
              appConfig.FORM_TYPE.WORDPRESS.CODE,
              appConfig.FORM_TYPE.KIOTVIET.CODE
            ].includes(selectAccountType.CODE) ? (
              <li>
                <div className="step-number">3</div>
                <span>Cấu hình tích hợp</span>
              </li>
            ) : ""
            }
          </ul>
        </div>

        {
          selectAccountType &&
          <div className="block-info-connect-account">
            <div className="icon-logo-connect">
              <div className="icon-ladisales">
                <img src="https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales.svg" width={48} height={48} />
              </div>
              <div className="icon-connect-with">
                <i className="ladi-icon icon-sync" />
              </div>
              <div className="icon-account-connect">
                <img src={getAccountTypeIcon(selectAccountType.CODE)} width={48} height={48} />
              </div>
            </div>
            <h3>Kết nối LadiSales với {selectAccountType.NAME}</h3>
            <p>Lưu thông tin khách hàng từ LadiSales về {selectAccountType.NAME}</p>
          </div>
        }

        {!selectAccountType ? (
          <>
            <div className="ladiui-table-actions mb-16">
              <div className="action-item mr-0" style={{ width: "100%" }}>
                <div className="ladiui search-keyword" style={{ width: "100%" }}>
                  <input
                    type="text"
                    id="search"
                    placeholder={t("INTEGRATE.SEARCH_AFFILIATE_ACCOUNT_EVENT")}
                    name="search_name"
                    value={keySearch}
                    onChange={(e) => setKeySearch(e.target.value)}
                    className="ladiui form-control"
                    onKeyPress={(event) => handleChangeKeyPress(event)}
                  />
                  <i className="ladi-icon icon-zoom" onClick={() => handleSearchFormType()} />
                </div>
              </div>
            </div>
            <div className="list-integrate">
              {props.mode === appConfig.FORM_MODE.CREATE ? (
                map(appConfig.FORM_TYPE, (item, index) => {
                  if (item.NAME.toLowerCase().includes(valueSearch.toLocaleLowerCase())) {
                    return (
                      <div
                        key={index}
                        className="item-integrate"
                        style={
                          [appConfig.FORM_TYPE.SHOPIFY.CODE, appConfig.FORM_TYPE.WORDPRESS.CODE].includes(item.CODE)
                            ? { backgroundColor: "#f1f3f4" }
                            : {}
                        }
                        onClick={
                          [appConfig.FORM_TYPE.SHOPIFY.CODE, appConfig.FORM_TYPE.WORDPRESS.CODE].includes(item.CODE)
                            ? () => { }
                            : () => handleSelectedAccountType(item)
                        }
                      >
                        <div className="img-title">
                          <img src={getAccountTypeIcon(item.CODE)} className="icon-form-account" />
                          <span>{item.NAME}</span>
                        </div>
                        <div className="description">
                          <p>{item.DESCRIPTION}</p>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <div style={{ background: "#e8e8e8", borderRadius: "5px" }}>
                  <div style={{ padding: "10px" }}>
                    <div className="ladiui text">
                      <img src={getAccountTypeIcon(formAccount.type)} className="icon-form-account" />
                      {appConfig.FORM_TYPE[formAccount.type].NAME}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="box-app-integrate-content-setting">{renderForm()}</div>
          </>
        )}
      </div>
    </Modal>
  );
}
