/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import productTagActions from '../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../redux/futures/product_tag/types';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import LoadingTable from '../../../components/LoadingTable';
import ConfirmModal from '../../../components/ConfirmModal';
import LadiPagination from '../../../components/LadiPagination';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';

import Modal from '../../../components/Modal';
import Input from '../../../components/Input';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { includes, map, remove, values, cloneDeep, compact } from 'lodash';
import config from '../../../config/config';

let CDN = 'https://w.ladicdn.com/';

export default function ModalReorderProductTag(props) {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const queryStrings = new URLSearchParams(location.search);

    const inputsRef = new Set();

    const [keyword, setKeyWord] = useState('');

    const { tagSelected } = props;
    const isLoading = props.productTagReducer.loading && props.productTagReducer.waitting == productTagTypes.REORDER_PRODUCT_TAG;

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(tagSelected.products, result.source.index, result.destination.index);

        props.handleSetTagSelected(items);
    };

    const onPin = (index) => {
        const items = reorder(tagSelected.products, index, 0);

        props.handleSetTagSelected(items);
    };

    if (!props.isShowModalReorder) {
        return null;
    }

    const isMatched = (name) => {
        if (
            baseHelper.getAliasName(name).indexOf(baseHelper.getAliasName(keyword)) >= 0 ||
            baseHelper.getAliasName(keyword).indexOf(baseHelper.getAliasName(name)) >= 0
        ) {
            return true;
        }
        return false;
    };

    const FilteredProduct = () => {
        return tagSelected.products.map((item, index) => (
            <Draggable key={item.product_tag_value_id} draggableId={item.product_tag_value_id} index={index}>
                {(provided, snapshot) => (
                    <tr
                        className='ladiui table-vertical main'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                            ...provided.draggableProps.style,
                            userSelect: 'none',
                            background: snapshot.isDragging ? '#e8f0fe' : 'none',
                            display: isMatched(item.name) ? 'table-row' : 'none',
                        }}
                    >
                        <td className='text-center'>{index + 1}</td>
                        <td className='text-left'>
                            {/* <a href={`/products?product_id=${item.product_id}`}>{item.name}</a> */}
                            <a
                                href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_LIST}&query_ls=${JSON.stringify({
                                    product_id: item.product_id,
                                })}`}
                                target='_blank'
                            >
                                {item.name}
                            </a>
                        </td>
                        <td style={{ cursor: 'pointer' }}>
                            <i className='ladi-icon icon-arrow-up' onClick={() => onPin(index)} />
                        </td>
                    </tr>
                )}
            </Draggable>
        ));
    };

    const onChangeKeyword = (e) => {
        // e.preventDefault();
        setKeyWord(e.target.value);
    };

    const listProduct = () => {
        props.handleListProduct(keyword);
    };

    const reOrderProduct = () => {
        props.handleReorderProduct();
    };

    return (
        <Modal
            id='modal-tag-edit'
            title={t('TAGS.PRODUCT_REORDER_TILE')}
            visible={props.isShowModalReorder}
            onCancel={() =>
                // this.setState({
                //     isShowModalReorder: false,
                //     tagSelected: cloneDeep(this.tagSelectedBackup),
                // })
                props.onCancel()
            }
            onOk={reOrderProduct}
            width={600}
            isLoading={isLoading}
            disableOk={baseHelper.isEmpty(tagSelected.products)}
        >
            <div className='block-search'>
                <div className='ladiui search-keyword w100'>
                    <Input
                        id='keyword'
                        className='ladiui form-control search-width'
                        name='keyword'
                        placeholder={t('PRODUCTS.SEARCH_PRODUCT')}
                        value={keyword}
                        onChange={(e) => onChangeKeyword(e)}
                    />
                    <i className='ladi-icon icon-zoom' onClick={listProduct} />
                </div>
            </div>
            {baseHelper.isEmpty(tagSelected.products) ? (
                <div className='mt-8'>{t('NO_DATA')}</div>
            ) : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable'>
                        {(provided, snapshot) => (
                            <div className='ladi-card ladiui-table-responsive' style={{ overflowY: 'scroll', maxHeight: 500 }}>
                                {/* <div className="ladiui search-keyword">
                                    <Input
                                        id="keyword"
                                        className="ladiui form-control"
                                        name="keyword"
                                        placeholder={t("PRODUCTS.SEARCH_PRODUCT")}
                                        value={keyword}
                                        onChange={onChangeKeyword}
                                    />
                                    <i className="ladi-icon icon-zoom" onClick={listProduct} />
                                </div> */}
                                <table
                                    className='ladiui table text-left order-table'
                                    ref={provided.innerRef}
                                    style={{
                                        background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                    }}
                                >
                                    <thead>
                                        <tr className='ladiui table-vertical'>
                                            <th scope='col' style={{ width: 100 }} className='text-center'>
                                                {t('TAGS.STT')}
                                            </th>
                                            <th scope='col' className='text-left'>
                                                {t('TAGS.PRODUCT_NAME')}
                                            </th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <FilteredProduct />
                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </Modal>
    );
}
