import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

import Modal from "../../../components/ModalV4";
import Input from "../../../components/Input";
import { Editor } from "@tinymce/tinymce-react";

import settingActions from "../../../redux/futures/setting/actions";
import * as settingTypes from "../../../redux/futures/setting/types";

import appConfig from "../../../config/app";
import { map, find, remove, includes, keys, compact } from 'lodash';


export default function ModalShowContentTemplatePrint(props) {
    let { onSubmit, onCancel, isLoading, visible } = props;
    const dispatch = useDispatch();
    const _inputRef = new Set();

    const [email, setEmail] = useState('');

    const handleSubmit = () => {
        // Validate Input
        let errors = [];
        const inputsRef = compact(Array.from(_inputRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        dispatch(settingActions.createNotificationEmail(email))
    }

    return (
        <>
            <Modal
                visible={visible}
                id='modal-add-notification-email'
                zIndex={9999}
                width={500}
                title={t('SETTINGS.TITLE_ADD_NOTIFICATION_EMAIL')}
                onCancel={onCancel}
                onOk={handleSubmit}
                isLoading={isLoading}
            >
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('COMMON.EMAIL')}</label>
                    <Input
                        name='email'
                        validationName={t('COMMON.EMAIL')}
                        placeholder={t('COMMON.EMAIL')}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        ref={(ref) => _inputRef.add(ref)}
                        validations={{ email: true, isRequired: true }}
                    />
                </div>
            </Modal>
        </>
    )
}