/**
 * Import default package
 *
 * @format
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/** Import from third party */
import { map, includes, compact, omitBy } from 'lodash';

/** Import component from my app */
import Input from './Input';
import addressConfigActions from '../redux/futures/address_config/actions';
import * as addressConfigTypes from '../redux/futures/address_config/types';

import Dropdown from './Dropdown';
import { withTranslation } from 'react-i18next';
import baseHelper from '../helpers/BaseHelper';

class Location extends React.Component {
    static propTypes = {
        wrapperClassName: PropTypes.string,
        autoLoad: PropTypes.bool,
        validations: PropTypes.shape({
            isRequiredCountry: PropTypes.bool,
            isRequiredState: PropTypes.bool,
            isRequiredDistrict: PropTypes.bool,
            isRequiredWard: PropTypes.bool,
        }),
        onChangeInput: PropTypes.func,
        position_unset: PropTypes.bool,
    };

    static defaultProps = {
        autoLoad: false,
        position_unset: false,
        validations: {
            isRequiredCountry: true,
            isRequiredState: true,
            isRequiredDistrict: true,
            isRequiredWard: true,
        },
    };

    constructor(props) {
        super(props);
        const currentStore = this.props.store.userInfo || {};

        const { country_code: countryCode, country_name: countryName } = currentStore;

        this.state = {
            countries: [],
            states: [],
            districts: [],
            wards: [],
            address: {
                country_code: countryCode || props.t('DEFAULT_COUNTRY.CODE'),
                country_name: countryName || props.t('DEFAULT_COUNTRY.NAME'),
                postal_code: '',
                state_id: null,
                state_name: '',
                district_id: null,
                district_name: '',
                ward_id: null,
                ward_name: '',
            },
        };
        this.ref = React.createRef();
        this.inputsRef = new Set();
    }

    componentWillMount() {
        this.init(this.props);
    }

    componentDidMount() {
        const { address } = this.state;
        this.props.listCountries();
        this.props.listStates(address.country_code);

        if (this.props.autoLoad) {
            if (!baseHelper.isEmpty(address.country_code, address.state_id)) {
                this.props.listDistricts(address.country_code, address.state_id);
                if (address.district_id) {
                    this.props.listWards(address.country_code, address.state_id, address.district_id);
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }
        if (this.props.address != nextProps.address) {
            if (this.props.address.country_code != nextProps.address.country_code) {
                this.props.listStates(nextProps.address.country_code);
            }
            if (nextProps.address.country_code && nextProps.address.state_id) {
                this.props.listDistricts(nextProps.address.country_code, nextProps.address.state_id);
            }
            if (nextProps.address.country_code && nextProps.address.state_id && nextProps.address.district_id) {
                this.props.listWards(nextProps.address.country_code, nextProps.address.state_id, nextProps.address.district_id);
            }
        }

        if (this.props.address != nextProps.address) {
            this.init(nextProps);
        }

        if (this.props.addressConfigReducer.action != nextProps.addressConfigReducer.action) {
            if (nextProps.addressConfigReducer.action === addressConfigTypes.LIST_COUNTRIES) {
                if (nextProps.addressConfigReducer.status) {
                    if (nextProps.addressConfigReducer.countries) {
                        this.setState({
                            countries: nextProps.addressConfigReducer.countries,
                        });
                    } else {
                        this.resetCountries();
                        return;
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.addressConfigReducer.message, 'OK');
                }
            }

            if (nextProps.addressConfigReducer.action === addressConfigTypes.LIST_STATES) {
                if (nextProps.addressConfigReducer.status) {
                    if (nextProps.addressConfigReducer.states) {
                        this.setState({
                            states: nextProps.addressConfigReducer.states,
                        });
                    } else {
                        this.resetAddressStates();
                        return;
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.addressConfigReducer.message, 'OK');
                }
            }

            if (nextProps.addressConfigReducer.action === addressConfigTypes.LIST_DISTRICTS) {
                if (nextProps.addressConfigReducer.status) {
                    if (nextProps.addressConfigReducer.districts) {
                        this.setState({
                            districts: nextProps.addressConfigReducer.districts,
                        });
                    } else {
                        this.resetDistricts();
                        return;
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.addressConfigReducer.message, 'OK');
                }
            }

            if (nextProps.addressConfigReducer.action === addressConfigTypes.LIST_WARDS) {
                if (nextProps.addressConfigReducer.status) {
                    if (nextProps.addressConfigReducer.wards) {
                        this.setState({
                            wards: nextProps.addressConfigReducer.wards,
                        });
                    } else {
                        // this.resetWards();
                        // return;
                    }
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.addressConfigReducer.message, 'OK');
                }
            }
        }
    }

    init = (props) => {
        if (!props.address) {
            return;
        }
        const newAddress = {};
        map(this.state.address, (value, key) => {
            if (includes(['country_code', 'country_name'], key)) {
                newAddress[key] = !baseHelper.isEmpty(props.address[key]) ? props.address[key] : this.state.address[key];
            } else {
                newAddress[key] = !baseHelper.isEmpty(props.address[key]) ? props.address[key] : '';
            }
        });

        this.setState({
            address: newAddress,
        });
    };
    /*******************************HANDLE INPUT***************************/
    onChangeAddressInput = (event) => {
        this.props.onChangeInput(this.state.address);
    };

    resetCountries = () => {
        this.props.resetCountries();

        this.props.onChangeInput({
            country_code: '',
            country_name: '',
            state_id: null,
            state_name: '',
            district_id: null,
            district_name: '',
            ward_id: null,
            ward_name: '',
        });

        this.setState({
            // countries: [],
            states: [],
            districts: [],
            wards: [],
        });
    };

    resetAddressStates = () => {
        this.props.resetStates();

        this.props.onChangeInput({
            state_id: null,
            state_name: '',
            district_id: null,
            district_name: '',
            ward_id: null,
            ward_name: '',
        });
        this.setState({
            // countries: [],
            // states: [],
            districts: [],
            wards: [],
        });
    };

    resetDistricts = () => {
        this.props.resetDistricts();

        this.props.onChangeInput({
            district_id: null,
            district_name: '',
            ward_id: null,
            ward_name: '',
        });
        this.setState({
            // countries: [],
            // states: [],
            // districts: [],
            wards: [],
        });
    };

    resetWards = () => {
        this.props.resetWards();

        this.props.onChangeInput({
            ward_id: null,
            ward_name: '',
        });
    };

    selectCountry = (selected) => {
        this.props.onChangeInput({
            country_code: selected.code,
            country_name: selected.name,
            state_id: null,
            state_name: '',
            district_id: null,
            district_name: '',
            ward_id: null,
            ward_name: '',
        });

        this.setState({
            // countries: [],
            states: [],
            districts: [],
            wards: [],
        });
        this.props.listStates(selected.code);
    };

    selectState = (selected) => {
        const { address } = this.state;

        this.props.onChangeInput({
            country_code: address.country_code,
            country_name: address.country_name,
            state_id: selected.state_id,
            state_name: selected.name,
            district_id: null,
            district_name: '',
            ward_id: null,
            ward_name: '',
        });

        this.setState({
            // countries: [],
            // states: [],
            districts: [],
            wards: [],
        });
        this.props.listDistricts(address.country_code, selected.state_id);
    };

    selectDistrict = (selected) => {
        const { address } = this.state;

        this.props.onChangeInput({
            country_code: address.country_code,
            country_name: address.country_name,
            state_id: address.state_id,
            state_name: address.state_name,
            district_id: selected.district_id,
            district_name: selected.name,
            ward_id: null,
            ward_name: '',
        });
        this.setState({
            // countries: [],
            // states: [],
            // districts: [],
            wards: [],
        });

        this.props.listWards(address.country_code, address.state_id, selected.district_id);
    };

    selectWard = (selected) => {
        const { address } = this.state;

        this.props.onChangeInput({
            country_code: address.country_code,
            country_name: address.country_name,
            state_id: address.state_id,
            state_name: address.state_name,
            district_id: address.district_id,
            district_name: address.district_name,
            ward_id: selected.ward_id,
            ward_name: selected.name,
        });
    };
    /*****************************************************************/

    validate = () => {
        // Validate
        const { t } = this.props;
        const { countries, states, districts, wards } = this.props.addressConfigReducer;
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                if (ref.props.id == 'country' && !baseHelper.isEmpty(countries)) {
                    errors = errors.concat(ref.validate());
                }

                if (ref.props.id == 'state' && !baseHelper.isEmpty(states)) {
                    errors = errors.concat(ref.validate());
                }

                if (ref.props.id == 'district' && !baseHelper.isEmpty(districts)) {
                    errors = errors.concat(ref.validate());
                }

                if (ref.props.id == 'ward' && !baseHelper.isEmpty(wards)) {
                    errors = errors.concat(ref.validate());
                }
            }
        });

        errors = compact(errors);

        return errors;
    };

    getData = () => {
        return omitBy(this.state.address, baseHelper.isEmpty);
    };

    componentWillUnmount() {
        this.props.resetAll();
    }

    render() {
        const { t } = this.props;
        const { address } = this.state;
        const { wrapperClassName, wrapperProps, position_unset } = this.props;
        const { countries, states, districts, wards } = this.state;
        const { isRequiredCountry, isRequiredState, isRequiredDistrict, isRequiredWard } = this.props.validations;

        return (
            <div className={wrapperClassName} {...wrapperProps}>
                <div className='ladiui ladisales-location'>
                    <div className='ladiui country-district'>
                        <div className='ladiui form-group position-unset'>
                            <label className='ladiui-label new'>{t('COMMON.COUNTRY_OR_REGION')}</label>

                            <Dropdown
                                id='country'
                                data={countries}
                                onSelectItem={this.selectCountry}
                                currentKey={address.country_code}
                                currentValue={address.country_name}
                                validationName={t('COMMON.COUNTRY_OR_REGION')}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: isRequiredCountry }}
                                placeHolder={t('ADDRESSES.PLACE_HOLDER_COUNTRY')}
                                isShowBlockInputSearch={true}
                                position_unset={position_unset}
                            // widthListCustom='calc(50% - 34px)'
                            />
                        </div>
                        <div className='ladiui form-group custom-modal-add-customer mt-24'>
                            <label className='ladiui-label new'>{t('COMMON.DISTRICT')}</label>
                            <Dropdown
                                id='district'
                                data={districts}
                                onSelectItem={this.selectDistrict}
                                currentKey={address.district_id}
                                currentValue={address.district_name}
                                _key={'district_id'}
                                _value={'name'}
                                validationName={t('COMMON.DISTRICT')}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: address.country_code == 'US' ? false : isRequiredDistrict }}
                                placeHolder={t('ADDRESSES.PLACE_HOLDER_DISTRICT')}
                                isShowBlockInputSearch={true}
                                position_unset={position_unset}
                            // widthListCustom='calc(50% - 34px)'
                            />
                        </div>

                        {/* <div className='ladiui form-group'>
                            <label className='ladiui-label'>{t('COMMON.POSTAL')}</label>
                            <Input
                                value={address.postal_code}
                                onChange={(event) => {
                                    this.props.onChangeInput({
                                        ...address,
                                        postal_code: event.target.value,
                                    });
                                }}
                            />
                        </div> */}
                    </div>

                    <div className='state-ward'>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label new'>{t('COMMON.STATE_OR_CITY')}</label>
                            <Dropdown
                                id='state'
                                data={states}
                                onSelectItem={this.selectState}
                                currentKey={address.state_id}
                                currentValue={address.state_name}
                                _key={'state_id'}
                                _value={'name'}
                                validationName={t('COMMON.STATE_OR_CITY')}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: isRequiredState }}
                                placeHolder={t('ADDRESSES.PLACE_HOLDER_STATE')}
                                isShowBlockInputSearch={true}
                                position_unset={position_unset}
                            // widthListCustom='calc(50% - 34px)'
                            />
                        </div>

                        <div className='ladiui form-group mt-24'>
                            <label className='ladiui-label new'>{t('COMMON.WARD')} </label>
                            <Dropdown
                                id='ward'
                                data={wards}
                                onSelectItem={this.selectWard}
                                currentKey={address.ward_id}
                                currentValue={address.ward_name}
                                _key={'ward_id'}
                                _value={'name'}
                                validationName={t('COMMON.WARD')}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: address.country_code == 'US' ? false : isRequiredWard }}
                                placeHolder={t('ADDRESSES.PLACE_HOLDER_WARD')}
                                isShowBlockInputSearch={true}
                                position_unset={position_unset}
                            // widthListCustom='calc(50% - 34px)'
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listCountries: () => dispatch(addressConfigActions.listCountries()),
        listStates: (countryCode) => dispatch(addressConfigActions.listStates(countryCode)),
        listDistricts: (countryCode, stateID) => dispatch(addressConfigActions.listDistricts(countryCode, stateID)),
        listWards: (countryCode, stateID, districtID) => dispatch(addressConfigActions.listWards(countryCode, stateID, districtID)),
        resetCountries: () => dispatch(addressConfigActions.resetCountries()),
        resetStates: () => dispatch(addressConfigActions.resetStates()),
        resetDistricts: () => dispatch(addressConfigActions.resetDistricts()),
        resetWards: () => dispatch(addressConfigActions.resetWards()),
        resetAll: () => dispatch(addressConfigActions.resetAll()),
    };
};

const mapStateToProps = (state) => ({
    addressConfigReducer: { ...state.addressConfig },
    store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(Location)
);
