import React from 'react';
import PropTypes from 'prop-types';
import variantActions from '../../../redux/futures/product_variant/actions';
import productTagActions from '../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../redux/futures/product_tag/types';
import * as variantTypes from '../../../redux/futures/product_variant/types';
import * as customerTagTypes from '../../../redux/futures/customer_tag/types';
import * as customerTypes from '../../../redux/futures/customer/types';
import customerActions from '../../../redux/futures/customer/actions';
import customerTagActions from '../../../redux/futures/customer_tag/actions';
import { debounce, map, find, remove, compact, values, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../components/Input';
import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import DatePicker from '../../../components/DatePicker/CustomDatePicker';
import { withTranslation } from 'react-i18next';
import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import Image from '../../../components/Image';
import Modal from '../../../components/Modal';
import NumberInput from '../../../components/NumberInput';
import Tag from '../../../components/Tag';

import Dropdown from '../../../components/Dropdown';

import readXlsxFile from 'read-excel-file';

class ModalPromoCode extends React.Component {
    static propTypes = {
        currentDiscount: PropTypes.object,
        mode: PropTypes.string,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();

        this.state = {
            selectedCodes: '',
            discount: {
                discount_id: '',
                name: '',
                code: '',
                codes: [],
                type: appConfig.DISCOUNT.TYPE.FIXED.value,
                value: '',
                apply_to: {
                    [appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT]: appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT,
                },
                min_requirement: {
                    [appConfig.DISCOUNT.MIN_REQUIREMENT.NONE]: appConfig.DISCOUNT.MIN_REQUIREMENT.NONE,
                },
                customer_groups: {
                    [appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE]: appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE,
                },
                usage_limit: null,
                one_per_customer: appConfig.STATUS.ACTIVE,
                start_date: baseHelper.momentToDate(this.moment()),
                end_date: null,
                rule_type: appConfig.DISCOUNT.RULE_TYPE.CODE.CODE,
                allow_promotion: appConfig.STATUS.INACTIVE,
                coupon_create_type: 'CREATE_ONE',
                coupon_number: null,
                coupon_prefix: null,
                is_show_web: true,
                discount_type: 'ORDER',
                maximum_discount: null,
            },
            search: {
                product: '',
                productTag: '',
                customer: '',
                customerTag: '',
            },
            displaySearch: {
                product: props.t('PRODUCTS.SEARCH_PRODUCT'),
                productTag: props.t('PRODUCTS.SEARCH_PRODUCT_TAG'),
                customer: props.t('CUSTOMERS.SEARCH_CUSTOMER'),
                customerTag: props.t('CUSTOMERS.SEARCH_CUSTOMER_TAG'),
            },
            is_show_maximum_discount: false,
        };

        this.SEARCH_TYPE = {
            PRODUCT: 'product',
            PRODUCT_TAG: 'productTag',
            CUSTOMER: 'customer',
            CUSTOMER_TAG: 'customerTag',
        };

        this.ref = React.createRef();
        this.tagRef = React.createRef();
        this.customerTagRef = React.createRef();
        this.inputsRef = new Set();
        this.search = debounce(this.search, 500);
    }

    componentWillMount() {
        if (this.props.currentDiscountInfo) {
            this.init(this.props);
        }
    }

    componentDidMount() {
        if (this.props.defaultRuleType) {
            this.setState({
                discount: {
                    ...this.state.discount,
                    rule_type: this.props.defaultRuleType,
                },
            });
        }

        this.props.searchProductTags(this.state.search.productTag);

        this.props.searchCustomerTags(this.state.search.customerTag);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.currentDiscountInfo != nextProps.currentDiscountInfo) {
            if (nextProps.currentDiscountInfo) {
                this.init(nextProps);
            }
        }
        if (this.props.defaultRuleType != nextProps.defaultRuleType) {
            this.setState({
                discount: {
                    ...this.state.discount,
                    rule_type: nextProps.defaultRuleType,
                },
            });
        }
    }

    init = (props) => {
        const newDiscount = {};
        map(this.state.discount, (value, key) => {
            newDiscount[key] = !baseHelper.isEmpty(props.currentDiscountInfo[key]) ? props.currentDiscountInfo[key] : '';
        });

        if (newDiscount.start_date) {
            newDiscount.start_date = baseHelper.momentToDate(this.moment(newDiscount.start_date));
        }

        if (newDiscount.end_date) {
            newDiscount.end_date = baseHelper.momentToDate(this.moment(newDiscount.end_date));
        } else {
            newDiscount.end_date = null;
        }

        if (!newDiscount.usage_limit) {
            newDiscount.usage_limit = null;
        }

        this.setState({
            discount: newDiscount,
            is_show_maximum_discount: newDiscount.maximum_discount ? true : false,
        });
    };

    onChangeInput = (event) => {
        let { discount } = this.state;
        let { name, value } = event.target;
        if (name === 'coupon_prefix' || name === 'code') {
            value = value.toUpperCase();
        }
        if (name === 'value') {
            if (discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value) {
                if (value > 100) {
                    value = 100;
                }
            }
        }
        this.setState({
            discount: {
                ...this.state.discount,
                [name]: value,
            },
        });
    };

    onChangeListCoupon = (event) => {
        let { value } = event.target;
        this.setState({
            selectedCodes: value.toUpperCase(),
        });
    };

    /******************************DISCOUNT***********************************************/
    getDiscountNameByType = (value) => {
        const matchedDiscount = find(appConfig.DISCOUNT.TYPE, (item) => item.value == value);
        return matchedDiscount.name;
    };

    getDiscountTypeNameByType = (value) => {
        const matchedDiscount = find(appConfig.LIST_DISCOUNT_TYPE, (item) => item.value == value);
        return matchedDiscount.name;
    };

    getNameByRuleType = (value) => {
        const matchedDiscount = find(appConfig.DISCOUNT.RULE_TYPE, (item) => item.CODE == value);
        return matchedDiscount.NAME;
    };

    onChangeDiscountType = (type) => {
        const { discount } = this.state;
        discount.type = type;
        discount.value = 0;
        this.setState({
            discount,
        });
    };

    onChangeRuleType = (ruleType) => {
        const { discount } = this.state;
        discount.rule_type = ruleType;
        this.setState({
            discount,
        });
    };

    onChangeApplyTo = (type) => {
        const { discount } = this.state;

        if (type == appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT) {
            discount.apply_to = { [type]: type };
        } else {
            discount.apply_to = { [type]: [] };
        }

        this.setState({
            discount,
        });
    };

    onChangeCustomerGroup = (type) => {
        const { discount } = this.state;

        if (type == appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE) {
            discount.customer_groups = { [type]: type };
        } else {
            discount.customer_groups = { [type]: [] };
        }

        this.setState({
            discount,
        });
    };

    onChangeMinRequire = (type) => {
        const { discount } = this.state;

        if (type == appConfig.DISCOUNT.MIN_REQUIREMENT.NONE) {
            discount.min_requirement = { [type]: type };
        } else {
            discount.min_requirement = { [type]: '' };
        }

        this.setState({
            discount,
        });
    };

    onChangeMinRequireInput = (event) => {
        const { name, value } = event.target;
        const { discount } = this.state;
        discount.min_requirement = {
            [name]: value,
        };

        this.setState({
            discount,
        });
    };

    onChangeUsageLimit = (event) => {
        const { checked } = event.target;
        const { discount } = this.state;
        if (checked) {
            discount.usage_limit = '';
        } else {
            discount.usage_limit = null;
        }

        this.setState({
            discount,
        });
    };

    onChangeSetEndDate = (event) => {
        const { checked } = event.target;
        const { discount } = this.state;
        if (checked) {
            discount.end_date = baseHelper.momentToDate(this.moment());
        } else {
            discount.end_date = null;
        }

        this.setState({
            discount,
        });
    };

    onChangeUsePerCus = (event) => {
        const { checked } = event.target;
        const { discount } = this.state;
        if (checked) {
            discount.one_per_customer = appConfig.STATUS.ACTIVE;
        } else {
            discount.one_per_customer = appConfig.STATUS.INACTIVE;
        }

        this.setState({
            discount,
        });
    };

    onChangeAllowPromotion = (event) => {
        const { checked } = event.target;
        const { discount } = this.state;
        if (checked) {
            discount.allow_promotion = appConfig.STATUS.ACTIVE;
        } else {
            discount.allow_promotion = appConfig.STATUS.INACTIVE;
        }

        this.setState({
            discount,
        });
    };

    onChangeDateTime = (value, name) => {
        const { discount } = this.state;
        if (discount.end_date) {
            if (name == 'start_date') {
                if (value.getTime() >= discount.end_date.getTime()) {
                    window.LadiUI.toastCustom('danger', '', this.props.t('VALIDATION.END_DATE_AFTER_START_DATE'));
                    return;
                }
            } else {
                if (discount.start_date.getTime() >= value.getTime()) {
                    window.LadiUI.toastCustom('danger', '', this.props.t('VALIDATION.END_DATE_AFTER_START_DATE'));
                    return;
                }
            }
        }
        discount[name] = value;
        this.setState({
            discount,
        });
    };
    /******************************************************************************************/

    /******************************SEARCH***********************************************/
    onChangeSearchInput = (event, name) => {
        const { value } = event.target;
        this.setState(
            {
                search: {
                    ...this.state.search,
                    [name]: value,
                },
            },
            () => this.search(name)
        );
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownsearchInput = (event) => {
        const { name } = event.target;
        if (event.key === 'Enter') {
            this.search(name);
        }
    };

    search = (name) => {
        const { search } = this.state;
        switch (name) {
            case this.SEARCH_TYPE.PRODUCT:
                this.props.searchProduct(search.product);
                break;
            // case this.SEARCH_TYPE.PRODUCT_TAG:
            //   this.props.searchProductTags(search.productTag);
            //   break;
            case this.SEARCH_TYPE.CUSTOMER:
                this.props.searchCustomer(search.customer);
                break;
            // case this.SEARCH_TYPE.CUSTOMER_TAG:
            //   this.props.searchCustomerTags(search.customerTag);
            //   break;
        }
    };

    /******************************SELECT***********************************************/
    selectProductTag = (item) => {
        this.setState(
            {
                displaySearch: {
                    ...this.state.displaySearch,
                    productTag: '',
                },
            },
            () => {
                this.setState({
                    search: {
                        ...this.state.search,
                        productTag: '',
                    },
                    displaySearch: {
                        ...this.state.displaySearch,
                        productTag: this.props.t('PRODUCTS.SEARCH_PRODUCT_TAG'),
                    },
                });
            }
        );

        const { discount } = this.state;
        const applyToTags = discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS];

        if (!find(applyToTags, (_item) => _item.name == item.name)) {
            applyToTags.push(item);

            this.setState({
                discount,
            });
        }
    };

    removeProductTag = (item) => {
        const { discount } = this.state;
        const applyToTags = discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS];

        remove(applyToTags, (_item) => _item.name == item.name);
        this.setState({
            discount,
        });
    };

    removeProduct = (index) => {
        const { discount } = this.state;
        const applyToProducts = discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS];

        remove(applyToProducts, (_item, _index) => _index == index);
        this.setState({
            discount,
        });
    };

    selectProduct = (item) => {
        this.setState(
            {
                displaySearch: {
                    ...this.state.displaySearch,
                    product: '',
                },
            },
            () => {
                this.setState({
                    search: {
                        ...this.state.search,
                        product: '',
                    },
                    displaySearch: {
                        ...this.state.displaySearch,
                        product: 'Search products...',
                    },
                });
            }
        );

        const { discount } = this.state;
        const applyToProducts = discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS];

        if (find(applyToProducts, (_item) => _item.product_id == item.product_id && _item.product_variant_id == item.product_variant_id)) {
            return;
        }

        applyToProducts.push(item);

        this.setState({
            discount,
        });
    };

    selectCustomerTag = (item) => {
        this.setState(
            {
                displaySearch: {
                    ...this.state.displaySearch,
                    customerTag: '',
                },
            },
            () => {
                this.setState({
                    search: {
                        ...this.state.search,
                        customerTag: '',
                    },
                    displaySearch: {
                        ...this.state.displaySearch,
                        customerTag: this.props.t('CUSTOMERS.SEARCH_CUSTOMER_TAG'),
                    },
                });
            }
        );

        const { discount } = this.state;
        const customerGroupsTags = discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS];

        if (!find(customerGroupsTags, (_item) => _item.name == item.name)) {
            customerGroupsTags.push(item);

            this.setState({
                discount,
            });
        }
    };

    selectCustomer = (item) => {
        this.setState(
            {
                displaySearch: {
                    ...this.state.displaySearch,
                    customer: '',
                },
            },
            () => {
                this.setState({
                    search: {
                        ...this.state.search,
                        customer: '',
                    },
                    displaySearch: {
                        ...this.state.displaySearch,
                        customer: this.props.t('CUSTOMERS.SEARCH_CUSTOMER'),
                    },
                });
            }
        );

        const { discount } = this.state;
        const customerGroups = discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS];

        if (find(customerGroups, (_item) => _item.customer_id == item.customer_id)) {
            return;
        }

        customerGroups.push(item);

        this.setState({
            discount,
        });
    };

    removeCustomerTag = (item) => {
        const { discount } = this.state;
        const customerGroupTags = discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS];

        remove(customerGroupTags, (_item) => _item.name == item.name);
        this.setState({
            discount,
        });
    };

    removeCustomer = (index) => {
        const { discount } = this.state;
        const customerGroups = discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS];

        remove(customerGroups, (_item, _index) => _index == index);
        this.setState({
            discount,
        });
    };
    /******************************************************************************************/

    submit = () => {
        // Validate Input
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const { discount, selectedCodes } = this.state;
        const productTags = this.tagRef.current ? this.tagRef.current.getIDs() : [];
        const customerTags = this.customerTagRef.current ? this.customerTagRef.current.getIDs() : [];

        // Validate Apply_to
        const discountReq = cloneDeep(discount);

        let { apply_to: applyTo } = discountReq;

        if (productTags && productTags.length > 0) {
            applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS] = productTags;
        }
        if (!applyTo[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT]) {
            if (values(applyTo)[0].length <= 0) {
                window.LadiUI.toastCustom('danger', '', this.props.t('DISCOUNTS.MSG_SPECIFIC_PRODUCT_OR_TAG_REQUIRED'));
                return;
            }
        }
        // Validate Customer groups
        let { customer_groups: customerGroups } = discountReq;
        if (customerTags && customerTags.length > 0) {
            customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS] = customerTags;
        }
        if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            if (!customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE]) {
                if (values(customerGroups)[0].length <= 0) {
                    window.LadiUI.toastCustom('danger', '', this.props.t('DISCOUNTS.MSG_SPECIFIC_CUSTOMER_OR_TAG_REQUIRED'));
                    return;
                }
            }
        }

        // Validate Date
        if (discount.end_date) {
            if (discount.start_date.getTime() >= discount.end_date.getTime()) {
                window.LadiUI.toastCustom('danger', '', this.props.t('VALIDATION.END_DATE_AFTER_START_DATE'));
                return;
            }
        }

        // Chuan hoa discount request

        applyTo = discountReq.apply_to;
        if (applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCTS]) {
            const variantIDs = map(applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCTS], (item) => item.product_variant_id);
            applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCTS] = variantIDs;
        } else if (applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
            const productTagIDs = map(applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS], (item) => item);

            applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS] = productTagIDs;
        }

        // Chuan hoa discount request
        if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            customerGroups = discountReq.customer_groups;
            if (customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS]) {
                const customerIDs = map(customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS], (item) => item.customer_id);
                customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS] = customerIDs;
            } else if (customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]) {
                const customerTagIDs = map(customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS], (item) => item);
                customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS] = customerTagIDs;
            }
        }

        if (discountReq.coupon_create_type == 'CREATE_HANDMADE') {
            if (selectedCodes) {
                discountReq.codes = selectedCodes.split(',');
            }
        }

        this.props.onSubmit(discountReq);
    };

    getApplyToPanel = () => {
        const { t } = this.props;
        const { discount, search } = this.state;
        const foundProductTags = this.props.productTag.productTags || [];
        const foundVariants = this.props.variant.variants || [];
        const loadingSearchProductTag =
            this.props.productTag.loading && this.props.productTag.waiting == productTagTypes.SEARCH_PRODUCT_TAG;
        const loadingSearchProduct = this.props.variant.loading && this.props.variant.waiting == variantTypes.SEARCH_VARIANT;

        // if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
        //   return (
        //     <div>
        //       <div className="discount-item">
        //         <AsyncAutoComplete
        //           id="autocomplete-product-tag"
        //           items={foundProductTags}
        //           getItemValue={(item) => search.productTag}
        //           loading={loadingSearchProductTag}
        //           renderItem={(item) => (
        //             <div key={item.product_tag_id} className="item">
        //               <div className="name text-left">{item.name}</div>
        //             </div>
        //           )}
        //           value={search.productTag}
        //           onChange={(event) =>
        //             this.onChangeSearchInput(event, this.SEARCH_TYPE.PRODUCT_TAG)
        //           }
        //           onSelect={(value, item) => {
        //             this.selectProductTag(item, this.SEARCH_TYPE.PRODUCT_TAG);
        //           }}
        //           inputProps={{
        //             onFocus: () => {
        //               if (foundProductTags.length == 0) {
        //                 this.search(this.SEARCH_TYPE.PRODUCT_TAG);
        //               }
        //             },
        //             className:
        //               "ladiui input form-control custom-input-autocomplete",
        //             placeholder: t("TAGS.SEARCH_TAG"),
        //           }}
        //           wrapperProps={{ className: "ladiui-search-custom" }}
        //         />
        //       </div>
        //       <table
        //         className="ladiui table text-left"
        //         style={{ minWidth: "auto" }}
        //       >
        //         <thead></thead>

        //         <tbody>
        //           {map(
        //             discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS],
        //             (item, index) => (
        //               <tr
        //                 key={index}
        //                 className="ladiui table-vertical"
        //                 style={{ borderBottom: "1px solid #efefef" }}
        //               >
        //                 <td>{item.name}</td>
        //                 <td className="text-right">
        //                   <a
        //                     onClick={() => this.removeProductTag(item)}
        //                     style={{ color: "#6d6d6d" }}
        //                   >
        //                     <i className="ladi-icon icon-bin" />
        //                     {/* <span style={{ color: "red" }}>Delete</span> */}
        //                   </a>
        //                 </td>
        //               </tr>
        //             )
        //           )}
        //         </tbody>
        //       </table>
        //     </div>
        //   );
        // }

        if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS]) {
            return (
                <div>
                    <div className='discount-item'>
                        <AsyncAutoComplete
                            id='autocomplete-variant'
                            items={foundVariants}
                            getItemValue={(item) => search.product}
                            loading={loadingSearchProduct}
                            renderItem={(item) => (
                                <div key={item.product_variant_id} className='item'>
                                    <div className='image-name'>
                                        <Image src={item.src} width={40} height={40} />
                                        <div className='name'>{item.name}</div>
                                    </div>
                                    <div className='price text-right'>
                                        {baseHelper.formatMoneyPostFix(item.price, this.props.store.userInfo.currentStore.currency_symbol)}
                                    </div>
                                    {!baseHelper.isAvailableVariant(item) && (
                                        <div className='badge badge-child-light sold-out'>
                                            <i className='ladi-icon icon-c-warning'></i>
                                            {t('COMMON.SOLD_OUT')}
                                        </div>
                                    )}
                                </div>
                            )}
                            value={search.product}
                            onChange={(event) => this.onChangeSearchInput(event, this.SEARCH_TYPE.PRODUCT)}
                            onSelect={(value, item) => {
                                this.selectProduct(item, this.SEARCH_TYPE.PRODUCT);
                            }}
                            inputProps={{
                                onFocus: () => {
                                    if (foundVariants.length == 0) {
                                        this.search(this.SEARCH_TYPE.PRODUCT);
                                    }
                                },
                                className: 'ladiui input form-control custom-input-autocomplete',
                                placeholder: t('PRODUCTS.SEARCH_PRODUCT'),
                            }}
                            wrapperProps={{ className: 'ladiui-search-custom' }}
                        />
                    </div>
                    <table className='ladiui table text-left' style={{ minWidth: 'auto' }}>
                        <thead></thead>

                        <tbody>
                            {map(discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS], (item, index) => (
                                <tr key={index} className='ladiui table-vertical' style={{ borderBottom: '1px solid #efefef' }}>
                                    <td>
                                        {' '}
                                        <Image src={item.src} width={25} height={25} />
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                textOverflow: 'ellipsis',
                                                marginLeft: 8,
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    </td>
                                    <td className='text-right'>
                                        <a onClick={() => this.removeProduct(index)} style={{ color: '#6d6d6d' }}>
                                            <i className='ladi-icon icon-bin' />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    getApplyProductTag = () => {
        const { t } = this.props;
        const { discount, search } = this.state;
        const foundProductTags = this.props.productTag.productTags || [];

        if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
            return (
                <div>
                    <Tag
                        tabIndex={6}
                        ref={this.tagRef}
                        id='product-tag'
                        allTags={foundProductTags}
                        selectedTags={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]}
                        hideTitle={true}
                    />
                </div>
            );
        }
    };

    getCustomerTags = () => {
        const { t } = this.props;
        const { discount, search } = this.state;
        const foundCustomerTags = this.props.customerTag.customerTags || [];

        if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]) {
            return (
                <div>
                    <Tag
                        tabIndex={6}
                        ref={this.customerTagRef}
                        id='customer-tag'
                        allTags={foundCustomerTags}
                        selectedTags={discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]}
                        hideTitle={true}
                    />
                </div>
            );
        }
    };

    getCustomerGroupPanel = () => {
        const { t } = this.props;
        const { discount, search } = this.state;
        const foundCustomerTags = this.props.customerTag.customerTags || [];
        const foundCustomers = this.props.customer.customers || [];
        const loadingSearchCustomerTag =
            this.props.customerTag.loading && this.props.customerTag.waiting == customerTagTypes.SEARCH_CUSTOMER_TAG;
        const loadingSearchCustomer = this.props.customer.loading && this.props.customer.waiting == customerTypes.SEARCH_CUSTOMER;

        // if (
        //   discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]
        // ) {
        //   return (
        //     <div>
        //       <div className="discount-item">
        //         <AsyncAutoComplete
        //           id="autocomplete-customer-tag"
        //           items={foundCustomerTags}
        //           getItemValue={(item) => search.customerTag}
        //           loading={loadingSearchCustomerTag}
        //           renderItem={(item) => (
        //             <div key={item.customer_tag_id} className="item">
        //               <div className="name text-left">{item.name}</div>
        //             </div>
        //           )}
        //           value={search.customerTag}
        //           onChange={(event) =>
        //             this.onChangeSearchInput(event, this.SEARCH_TYPE.CUSTOMER_TAG)
        //           }
        //           onSelect={(value, item) => {
        //             this.selectCustomerTag(item, this.SEARCH_TYPE.CUSTOMER_TAG);
        //           }}
        //           inputProps={{
        //             onFocus: () => {
        //               if (foundCustomerTags.length == 0) {
        //                 this.search(this.SEARCH_TYPE.CUSTOMER_TAG);
        //               }
        //             },
        //             className:
        //               "ladiui input form-control custom-input-autocomplete",
        //             placeholder: t("TAGS.SEARCH_TAG"),
        //           }}
        //           wrapperProps={{ className: "ladiui-search-custom" }}
        //         />
        //       </div>
        //       <table
        //         className="ladiui table text-left"
        //         style={{ minWidth: "auto" }}
        //       >
        //         <thead></thead>

        //         <tbody>
        //           {map(
        //             discount.customer_groups[
        //               appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS
        //             ],
        //             (item, index) => (
        //               <tr
        //                 key={index}
        //                 className="ladiui table-vertical"
        //                 style={{ borderBottom: "1px solid #efefef" }}
        //               >
        //                 <td>{item.name}</td>
        //                 <td className="text-right">
        //                   <a
        //                     onClick={() => this.removeCustomerTag(item)}
        //                     style={{ color: "#6d6d6d" }}
        //                   >
        //                     <i className="ladi-icon icon-bin" />
        //                   </a>
        //                 </td>
        //               </tr>
        //             )
        //           )}
        //         </tbody>
        //       </table>
        //     </div>
        //   );
        // }

        if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS]) {
            return (
                <div>
                    <div className='discount-item'>
                        <AsyncAutoComplete
                            id='autocomplete-customer'
                            items={foundCustomers}
                            getItemValue={(item) => search.customer}
                            loading={loadingSearchCustomer}
                            renderItem={(item) => (
                                <div key={item.customer_id} className='item'>
                                    <div className='name'>
                                        <span>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(item.first_name)}`}</span>
                                        <div className='email'>{item.email}</div>
                                    </div>
                                </div>
                            )}
                            value={search.customer}
                            onChange={(event) => this.onChangeSearchInput(event, this.SEARCH_TYPE.CUSTOMER)}
                            onSelect={(value, item) => {
                                this.selectCustomer(item, this.SEARCH_TYPE.CUSTOMER);
                            }}
                            inputProps={{
                                onFocus: () => {
                                    if (foundCustomers.length == 0) {
                                        this.search(this.SEARCH_TYPE.CUSTOMER);
                                    }
                                },
                                className: 'ladiui input form-control custom-input-autocomplete',
                                placeholder: t('CUSTOMERS.SEARCH_CUSTOMER'),
                            }}
                            wrapperProps={{ className: 'ladiui-search-custom' }}
                        />
                    </div>
                    <table className='ladiui table text-left' style={{ minWidth: 'auto' }}>
                        <thead></thead>

                        <tbody>
                            {map(discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS], (item, index) => (
                                <tr key={index} className='ladiui table-vertical' style={{ borderBottom: '1px solid #efefef' }}>
                                    <td>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(item.first_name)}`}</td>
                                    <td>{item.email}</td>
                                    <td className='text-right'>
                                        <a onClick={() => this.removeCustomer(index)} style={{ color: '#6d6d6d' }}>
                                            <i className='ladi-icon icon-bin' />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    openInputUploadListCode = () => {
        document.getElementById('input-upload-list-code').click();
    };
    handleChangeUploadFile = async (event) => {
        let _listCoupon = [];
        const { files } = event.target;
        await readXlsxFile(files[0]).then((items) => {
            if (items && items.length > 0) {
                items.map((item) => {
                    _listCoupon.push(item[0]);
                });
            }
        });

        if (_listCoupon && _listCoupon.length > 0) {
            this.setState({
                selectedCodes: _listCoupon.join(',').toUpperCase(),
            });
        }
    };

    render() {
        const { t } = this.props;
        const { discount, selectedCodes } = this.state;
        let title = '';
        if (this.props.mode == appConfig.FORM_MODE.CREATE) {
            if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
                title = 'Tạo khuyến mãi mới';
            } else {
                title = 'Tạo chương trình khuyến mãi';
            }
        }
        if (this.props.mode == appConfig.FORM_MODE.EDIT) {
            if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
                title = 'Sửa khuyến mãi';
            } else {
                title = 'Sửa chương trình khuyến mãi';
            }
        }
        // this.props.mode === appConfig.FORM_MODE.CREATE  ? t("DISCOUNTS.ADD_DISCOUNT") : t("DISCOUNTS.EDIT_DISCOUNT");

        const isCode = discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE;
        const isFixedType = discount.type == appConfig.DISCOUNT.TYPE.FIXED.value;
        const { currency_code: currencyCode, currency_symbol: currencySymbol } = this.props.store.userInfo.currentStore;

        let applyOverview = '';
        if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT]) {
            applyOverview = 'toàn bộ đơn hàng';
        } else if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
            applyOverview = t('DISCOUNTS.OVERVIEW.APPLY_TO_TAGS', discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS].length);
        } else {
            applyOverview = t('DISCOUNTS.OVERVIEW.APPLY_TO_PRODUCTS');
        }

        const applyToOverview = `${t('DISCOUNTS.OVERVIEW.INCREASE')} ${
            isFixedType ? baseHelper.formatMoneyPostFix(discount.value, currencyCode) : `${baseHelper.parseInt(discount.value)} %`
        } ${discount.discount_type == 'SHIPPING' ? t('DISCOUNTS.DISCOUNT_SHIPPING') : ' '} ${applyOverview}`;
        let availableOverview = `${t('DISCOUNTS.OVERVIEW.AVAILABLE_DATE')} ${baseHelper.formatStrToDate(discount.start_date)}`;
        if (discount.end_date) {
            availableOverview += ` ${t('DISCOUNTS.OVERVIEW.DATE_TO')} ${baseHelper.formatStrToDate(discount.end_date)}`;
        }

        let minRequireOverview;
        if (discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT]) {
            minRequireOverview = `${t(
                'DISCOUNTS.OVERVIEW.MIN_REQUIREMENT_AMOUNT',
                baseHelper.formatMoneyPostFix(
                    discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT],
                    this.props.store.userInfo.currentStore.currency_symbol
                )
            )}`;
        } else if (discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY]) {
            minRequireOverview = `${t(
                'DISCOUNTS.OVERVIEW.MIN_REQUIREMENT_QUANTITY',
                baseHelper.parseInt(discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY])
            )}`;
        }

        let customerGroupOverview = '';
        if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE]) {
            customerGroupOverview = t('DISCOUNTS.OVERVIEW.CUSTOMER_GROUP_EVERY_ONE');
        } else if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]) {
            customerGroupOverview = t('DISCOUNTS.OVERVIEW.CUSTOMER_GROUP_TAGS');
        } else if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS]) {
            customerGroupOverview = t(
                'DISCOUNTS.OVERVIEW.CUSTOMER_GROUP_CUSTOMERS',
                discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS].length
            );
        }

        let usageLimitOverview = '';
        if (isCode && discount.usage_limit) {
            usageLimitOverview = t('DISCOUNTS.OVERVIEW.USAGE_LIMIT', discount.usage_limit);
        }

        let onePerCustomerOverview = '';
        if (isCode && discount.one_per_customer == appConfig.STATUS.ACTIVE) {
            onePerCustomerOverview = t('DISCOUNTS.OVERVIEW.ONE_PER_CUSTOMER');
        }

        let maximumDiscountOverView = '';

        if (discount && discount.maximum_discount) {
            maximumDiscountOverView = `${t(
                'DISCOUNTS.OVERVIEW.MAXIMUM_DISCOUNT',
                baseHelper.formatMoneyPostFix(discount.maximum_discount, this.props.store.userInfo.currentStore.currency_symbol)
            )}`;
        }
        return (
            <Modal
                id='modal-discount'
                width={950}
                title={title}
                onOk={this.submit}
                onCancel={this.props.onCancel}
                isLoading={this.props.isLoading}
                visible={this.props.visible}
            >
                <div className='ladi-row'>
                    <div className='col-50 ml-0'>
                        {/* {this.props.mode === appConfig.FORM_MODE.CREATE ? (
              <>
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("DISCOUNTS.RULE_TYPE.TITLE")}</label>
                  <div className="ladiui dropdown" style={{ width: "100%" }}>
                    <button className="ladiui btn btn-outline-light dropdown-toggle" data-toggle="dropdown" style={{ width: "100%" }}>
                      {this.getNameByRuleType(discount.rule_type)}
                    </button>
                    <ul className="ladiui dropdown-menu custom-form-dynamic" style={{ width: "100%" }}>
                      {map(appConfig.DISCOUNT.RULE_TYPE, (item, index) => (
                        <li key={index} onClick={() => this.onChangeRuleType(item.CODE)}>
                          <a className="ladiui dropdown-item" href="#">
                            {item.NAME}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            ) : (
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("DISCOUNTS.RULE_TYPE.TITLE")}</label>
                <input className="ladiui form-control" defaultValue={this.getNameByRuleType(discount.rule_type)} disabled />
              </div>
            )} */}
                        {this.props.mode === appConfig.FORM_MODE.EDIT && (
                            <>
                                {/* <div className="ladiui form-group">
                  <label className="ladiui-label">{t("DISCOUNTS.RULE_TYPE.TITLE")}</label>
                  <input className="ladiui form-control" defaultValue={this.getNameByRuleType(discount.rule_type)} disabled />
                </div> */}

                                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label'>{t('DISCOUNTS.CODE')}</label>
                                        <Input
                                            name='code'
                                            validationName={t('DISCOUNTS.CODE')}
                                            placeholder='e.g. SPRINGSALE'
                                            value={discount.code}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true }}
                                            ref={(ref) => this.inputsRef.add(ref)}
                                        />
                                    </div>
                                )}
                            </>
                        )}

                        {this.props.mode === appConfig.FORM_MODE.CREATE && discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                            <>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>Hình thức tạo mã khuyến mãi</label>
                                    <div className='coupon-create-types'>
                                        <div className='item'>
                                            <input
                                                type='radio'
                                                name='radio-group-type'
                                                className='ladiui form-check-input mr-8'
                                                checked={discount.coupon_create_type == 'CREATE_ONE'}
                                                onChange={() => {
                                                    this.setState({
                                                        discount: {
                                                            ...this.state.discount,
                                                            coupon_create_type: 'CREATE_ONE',
                                                        },
                                                    });
                                                }}
                                            />
                                            <span>Tạo một mã</span>
                                        </div>
                                        <div className='item'>
                                            <input
                                                type='radio'
                                                name='radio-group-type'
                                                className='ladiui form-check-input mr-8'
                                                checked={discount.coupon_create_type == 'CREATE_AUTO'}
                                                onChange={() => {
                                                    this.setState({
                                                        discount: {
                                                            ...this.state.discount,
                                                            coupon_create_type: 'CREATE_AUTO',
                                                        },
                                                    });
                                                }}
                                            />
                                            <span>Tạo mã tự động</span>
                                        </div>
                                        <div className='item'>
                                            <input
                                                type='radio'
                                                name='radio-group-type'
                                                className='ladiui form-check-input mr-8'
                                                checked={discount.coupon_create_type == 'CREATE_HANDMADE'}
                                                onChange={() => {
                                                    this.setState({
                                                        discount: {
                                                            ...this.state.discount,
                                                            coupon_create_type: 'CREATE_HANDMADE',
                                                        },
                                                    });
                                                }}
                                            />
                                            <span>Tạo mã thủ công</span>
                                        </div>
                                    </div>
                                </div>
                                {discount.coupon_create_type == 'CREATE_ONE' && (
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-sub-title block'>Mã khuyến mãi</label>
                                        <Input
                                            name='code'
                                            validationName={t('DISCOUNTS.CODE')}
                                            placeholder='Nhập mã khuyến mãi'
                                            value={discount.code}
                                            onChange={this.onChangeInput}
                                            validations={{ isRequired: true }}
                                            ref={(ref) => this.inputsRef.add(ref)}
                                        />
                                    </div>
                                )}

                                {discount.coupon_create_type == 'CREATE_AUTO' && (
                                    <div className='flex mb-24'>
                                        <div className='ladiui form-group mb-0' style={{ width: '40%' }}>
                                            <label className='ladiui-sub-title block'>Tiền Tố</label>
                                            <Input
                                                name='coupon_prefix'
                                                validationName={'tiền tố'}
                                                placeholder='Nhập tiền tố'
                                                value={discount.coupon_prefix}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: false }}
                                                maxLength='10'
                                                ref={(ref) => this.inputsRef.add(ref)}
                                            />
                                        </div>
                                        <div className='ladiui form-group mb-0 mt-0 pl-16'>
                                            <label className='ladiui-sub-title block'>Số lượng mã tạo tự động (tối đa 1000)</label>
                                            <Input
                                                name='coupon_number'
                                                type='number'
                                                max={100}
                                                validationName={'số lượng mã tạo tự động'}
                                                placeholder='Nhập số lượng mã'
                                                value={discount.coupon_number}
                                                onChange={this.onChangeInput}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => this.inputsRef.add(ref)}
                                            />
                                        </div>
                                    </div>
                                )}

                                {discount.coupon_create_type == 'CREATE_HANDMADE' && (
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-sub-title block'>Danh sách mã khuyến mãi</label>
                                        <textarea
                                            style={{
                                                minHeight: '120px',
                                                border: '1px solid #e5e5e5',
                                                padding: '8px 12px',
                                                borderRadius: '2px',
                                                width: '100%',
                                            }}
                                            placeholder='Nhập danh sách mã khuyến mãi. Mỗi mã sẽ cách nhau bằng một dấu phẩy.Ví dụ: PH001, PH002,...'
                                            name='selectedCodes'
                                            value={selectedCodes}
                                            onChange={this.onChangeListCoupon}
                                            validations={{ isRequired: true }}
                                            ref={(ref) => this.inputsRef.add(ref)}
                                        ></textarea>
                                        <div className='mt-16 mb-16'>Hoặc</div>
                                        <div className='block-upload-file-code'>
                                            <input
                                                type='file'
                                                name='file'
                                                id='input-upload-list-code'
                                                accept='.xsl, .xlsx'
                                                // multiple="multiple"
                                                onChange={this.handleChangeUploadFile}
                                                style={{ display: 'none' }}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                            <button className='ladiui btn btn-outline-primary' onClick={this.openInputUploadListCode}>
                                                Nhập danh sách mã từ file Excel
                                            </button>
                                            <span
                                                className='block'
                                                style={{
                                                    paddingTop: '8px',
                                                    fontSize: '12px',
                                                    color: '#4d4d4d',
                                                }}
                                            >
                                                Lưu ý: Mặc định mã sẽ được lấy nội dung cột đầu tiên trong file sheet.
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE && (
                            <>
                                <div className='ladiui form-group' style={{ marginBottom: 25 }}>
                                    <label className='ladiui-label'>{t('DISCOUNTS.PROMOTION')}</label>
                                    <Input
                                        name='name'
                                        validationName={t('DISCOUNTS.PROMOTION')}
                                        placeholder='e.g. CTKM T10'
                                        value={discount.name}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                </div>
                            </>
                        )}

                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && discount.discount_type != 'SHIPPING' && (
                            <div
                                className='ladiui form-group'
                                style={{
                                    marginBottom: 25,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <input
                                    id='allow_promotion'
                                    type='checkbox'
                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                    onChange={this.onChangeAllowPromotion}
                                    checked={discount.allow_promotion == appConfig.STATUS.ACTIVE}
                                />
                                <label htmlFor='allow_promotion'> {t('DISCOUNTS.ALLOW_PROMOTION')}</label>
                            </div>
                        )}

                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                            <div className='ladiui from-group'>
                                <label className='ladiui-label'>{t('DISCOUNTS.DISCOUNT_TYPE')}</label>
                                <Dropdown
                                    data={appConfig.LIST_DISCOUNT_TYPE}
                                    onSelectItem={(item) => {
                                        this.setState({
                                            discount: {
                                                ...this.state.discount,
                                                discount_type: item.value,
                                            },
                                        });
                                    }}
                                    currentKey={this.state.discount.discount_type}
                                    _key={'value'}
                                    _value={'name'}
                                    validationName={'Chọn loại khuyến mãi'}
                                    placeHolder={'Chọn loại khuyến mãi'}
                                />
                            </div>
                        )}

                        {discount.discount_type == 'SHIPPING' && (
                            <div className='note-shipping' style={{ marginTop: 8 }}>
                                <span style={{ fontSize: 12 }}>
                                    (*)Lưu ý: Bạn cần sửa{' '}
                                    <a href='https://help.ladisales.vn/cai-dat-tai-khoan/mau-in' target='_blank'>
                                        {' '}
                                        mẫu in hóa đơn{' '}
                                    </a>{' '}
                                    và{' '}
                                    <a href='https://help.ladisales.vn/cai-dat-tai-khoan/mau-email' target='_blank'>
                                        mẫu email{' '}
                                    </a>{' '}
                                    để hiển thị phí vận chuyển.
                                </span>
                            </div>
                        )}

                        <div className='flex mt-24' style={{ alignItems: 'end' }}>
                            <div className='ladiui form-group m-0'>
                                <label className='ladiui-label'>Giảm giá</label>
                                {discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value ? (
                                    <NumberInput
                                        name='value'
                                        prefix='%'
                                        validationName={t('DISCOUNTS.VALUE')}
                                        style={{ paddingLeft: '22px' }}
                                        value={discount.value}
                                        onChange={this.onChangeInput}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        validations={{ min: 0, max: 100, isRequired: true }}
                                    />
                                ) : (
                                    <NumberInput
                                        name='value'
                                        prefix={currencySymbol}
                                        validationName={t('DISCOUNTS.VALUE')}
                                        value={discount.value}
                                        style={{ paddingLeft: '22px' }}
                                        onChange={this.onChangeInput}
                                        validations={{ isRequired: true }}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                )}
                            </div>
                            <div className='ladiui form-group m-0 ml-16' style={{ width: 'auto' }}>
                                {/* <label className="ladiui-label">{t("DISCOUNTS.OPTIONS")}</label> */}
                                <div className='ladiui dropdown' style={{ width: '100%' }}>
                                    <button
                                        className='ladiui btn btn-outline-light dropdown-toggle'
                                        data-toggle='dropdown'
                                        style={{ width: '100%', minWidth: '135px' }}
                                    >
                                        {this.getDiscountNameByType(discount.type)}
                                    </button>
                                    <ul className='ladiui dropdown-menu custom-form-dynamic' style={{ width: '100%', minWidth: '135px' }}>
                                        {map(appConfig.DISCOUNT.TYPE, (item, index) => (
                                            <li key={index} onClick={() => this.onChangeDiscountType(item.value)}>
                                                <a className='ladiui dropdown-item' href='#'>
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && (
                            <div className='ladiui form-group'>
                                <div className='flex pb-8'>
                                    <input
                                        id='limit-maximum-discount'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                        onChange={(e) => {
                                            let { checked } = e.target;
                                            this.setState({
                                                discount: {
                                                    ...discount,
                                                    maximum_discount: checked ? 0 : null,
                                                },
                                                is_show_maximum_discount: !this.state.is_show_maximum_discount,
                                            });
                                        }}
                                        checked={this.state.is_show_maximum_discount}
                                    />
                                    <label htmlFor='limit-maximum-discount'>Giới hạn số tiền giảm giá tối đa</label>
                                </div>
                                {this.state.is_show_maximum_discount && (
                                    // <Input
                                    //   name="maximum_discount"
                                    //   type="number"
                                    //   value={discount.maximum_discount}
                                    //   onChange={this.onChangeInput}
                                    // />
                                    <NumberInput
                                        name='maximum_discount'
                                        prefix={currencySymbol}
                                        validationName={t('số tiền giảm giá tối đa')}
                                        value={discount.maximum_discount}
                                        style={{ paddingLeft: '22px' }}
                                        onChange={this.onChangeInput}
                                        // validations={{ isRequired: true }}
                                        // ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                )}
                            </div>
                        )}

                        <div className='line-bar' style={{ borderBottom: '1px solid #eee', margin: '24px 0px 24px 0px' }}></div>
                    </div>
                    <div className='col-50 mr-0'>
                        <div className='block-preview-discount'>
                            <div className='block-overview'>
                                <h3 className='title'>
                                    Tổng quan{' '}
                                    {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE
                                        ? 'khuyến mãi'
                                        : 'chương trình khuyến mãi'}
                                </h3>

                                <div className='block-item'>
                                    <label className='ladiui-label'>
                                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE
                                            ? 'Mã khuyến mãi'
                                            : 'Tên chương trình'}
                                        :
                                    </label>
                                    <span>{isCode ? discount.code : discount.name}</span>
                                </div>
                                <div className='block-item'>
                                    <label className='ladiui-label'>Hình thức:</label>
                                    <span>{this.getNameByRuleType(discount.rule_type)}</span>
                                </div>
                                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                                    <div className='block-item'>
                                        <label className='ladiui-label'>Loại khuyến mãi:</label>
                                        <span>{this.getDiscountTypeNameByType(discount.discount_type)}</span>
                                    </div>
                                )}
                                <div className='block-item'>
                                    <label className='ladiui-label'>Giảm giá theo:</label>
                                    <span>{this.getDiscountNameByType(discount.type)}</span>
                                </div>
                            </div>
                            <div className='line-bar'></div>
                            <div className='block-detail-discount'>
                                <h3 className='title'>Chi tiết khuyến mãi</h3>
                                <div className='overview-content'>
                                    <span className='overview-item'>{`• ${applyToOverview}`}</span>

                                    {maximumDiscountOverView && <span className='overview-item'>{`• ${maximumDiscountOverView}`}</span>}

                                    {minRequireOverview && <span className='overview-item'>{`• ${minRequireOverview}`}</span>}

                                    {customerGroupOverview && <span className='overview-item'>{`• ${customerGroupOverview}`}</span>}

                                    {usageLimitOverview && <span className='overview-item'>{`• ${usageLimitOverview}`}</span>}

                                    {onePerCustomerOverview && <span className='overview-item'>{`• ${onePerCustomerOverview}`}</span>}
                                    <span className='overview-item'>{`• ${availableOverview}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                    <div className='flex' style={{ width: '100%' }}>
                        <div className='ladiui item-form switch'>
                            <label className='ladiui switch m-0'>
                                <input
                                    type='checkbox'
                                    onChange={(event) => {
                                        this.setState({
                                            discount: {
                                                ...this.state.discount,
                                                is_show_web: !this.state.discount.is_show_web,
                                            },
                                        });
                                    }}
                                    checked={this.state.discount.is_show_web}
                                />
                                <span className='ladiui slider round'></span>
                            </label>
                        </div>
                        <div className='ml-8'>
                            <span className='ladiui-label pb-0'>Hiển thị trên trang bán hàng</span>
                        </div>
                    </div>
                )}

                {/* <div className="promotion-conditions-apply"> */}
                <div className='line-title-condition'>
                    <h3>Điều kiện khuyến mãi</h3>
                </div>
                <div className='ladi-row'>
                    <div className='col-50 ml-0'>
                        <div className='ladiui form-group condition-group form-check-inputs item-condition'>
                            <label className='ladiui-label' style={{ fontWeight: 500 }}>
                                {t('DISCOUNTS.APPLY_TO')}
                            </label>

                            {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                                <div className='discount-item'>
                                    <input
                                        id='applyToEntire'
                                        type='radio'
                                        name='radio-group-apply-to'
                                        className='ladiui form-check-input'
                                        value={appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT}
                                        onChange={() => {
                                            this.onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT);
                                        }}
                                        checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT] || false}
                                    />
                                    <label htmlFor='applyToEntire'>{t('DISCOUNTS.APPLY_TO_ENTIRE_ORDER')}</label>
                                </div>
                            )}
                            {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE && (
                                <div className='discount-item'>
                                    <input
                                        id='applyToEntireProduct'
                                        type='radio'
                                        name='radio-group-apply-to'
                                        className='ladiui form-check-input'
                                        value={appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT}
                                        onChange={() => {
                                            this.onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT);
                                        }}
                                        checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT] || false}
                                    />
                                    <label htmlFor='applyToEntireProduct'>{t('DISCOUNTS.APPLY_TO_ENTIRE_PRODUCT')}</label>
                                </div>
                            )}

                            <div className='discount-item'>
                                <input
                                    id='applyToTags'
                                    type='radio'
                                    name='radio-group-apply-to'
                                    className='ladiui form-check-input'
                                    value={appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS}
                                    onChange={() => {
                                        this.onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS);
                                    }}
                                    checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS] || false}
                                />
                                <label htmlFor='applyToTags'>{t('DISCOUNTS.APPLY_TO_SPECIFIC_TAG')}</label>
                            </div>

                            {this.getApplyProductTag()}

                            <div className='discount-item'>
                                <input
                                    id='applyToProducts'
                                    type='radio'
                                    name='radio-group-apply-to'
                                    className='ladiui form-check-input'
                                    value={appConfig.DISCOUNT.APPLY_TO.PRODUCTS}
                                    onChange={() => {
                                        this.onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.PRODUCTS);
                                    }}
                                    checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS] || false}
                                />
                                <label htmlFor='applyToProducts'>{t('DISCOUNTS.APPLY_TO_SPECIFIC_PRODUCTS')}</label>
                            </div>

                            {this.getApplyToPanel()}
                        </div>
                    </div>
                    <div className='col-50 mr-0'>
                        <div className='ladiui form-group condition-group form-check-inputs item-condition'>
                            <label className='ladiui-label' style={{ fontWeight: 500 }}>
                                {t('DISCOUNTS.MIN_REQUIREMENT')}
                            </label>
                            <div className='discount-item'>
                                <input
                                    id='minRequireNone'
                                    type='radio'
                                    name='radio-group-min-require'
                                    className='ladiui form-check-input'
                                    value={appConfig.DISCOUNT.MIN_REQUIREMENT.NONE}
                                    onChange={() => {
                                        this.onChangeMinRequire(appConfig.DISCOUNT.MIN_REQUIREMENT.NONE);
                                    }}
                                    checked={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.NONE] != undefined}
                                />
                                <label htmlFor='minRequireNone'>{t('DISCOUNTS.MIN_REQUIREMENT_NONE')}</label>
                            </div>
                            <div className='discount-item'>
                                <input
                                    id='minRequireAmount'
                                    type='radio'
                                    name='radio-group-min-require'
                                    className='ladiui form-check-input'
                                    value={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT}
                                    onChange={() => {
                                        this.onChangeMinRequire(appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT);
                                    }}
                                    checked={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT] != undefined}
                                />
                                <label htmlFor='minRequireAmount'>{t('DISCOUNTS.MIN_REQUIREMENT_AMOUNT')}</label>
                            </div>
                            {discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT] != undefined && (
                                <div className='discount-item'>
                                    <NumberInput
                                        name={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT}
                                        validationName={t('DISCOUNTS.MIN_REQUIREMENT_AMOUNT')}
                                        prefix={currencySymbol}
                                        value={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT]}
                                        onChange={this.onChangeMinRequireInput}
                                        validations={{ isRequired: true }}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                </div>
                            )}
                            <div className='discount-item'>
                                <input
                                    id='minRequireQuantity'
                                    type='radio'
                                    name='radio-group-min-require'
                                    className='ladiui form-check-input'
                                    value={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY}
                                    onChange={() => {
                                        this.onChangeMinRequire(appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY);
                                    }}
                                    checked={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY] != undefined}
                                />
                                <label htmlFor='minRequireQuantity'>{t('DISCOUNTS.MIN_REQUIREMENT_QUANTITY')}</label>
                            </div>
                            {discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY] != undefined && (
                                <div className='discount-item'>
                                    <Input
                                        name={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY}
                                        validationName={t('DISCOUNTS.MIN_REQUIREMENT_QUANTITY')}
                                        type='number'
                                        value={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY]}
                                        onChange={this.onChangeMinRequireInput}
                                        validations={{ isRequired: true }}
                                        ref={(ref) => this.inputsRef.add(ref)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                    <div className='ladiui form-group condition-group form-check-inputs item-condition'>
                        <label className='ladiui-label' style={{ fontWeight: 500 }}>
                            {t('DISCOUNTS.CUSTOMER_GROUPS')}
                        </label>
                        <div className='discount-item'>
                            <input
                                id='customerGroupEveryone'
                                type='radio'
                                name='radio-group-customerGroup'
                                className='ladiui form-check-input'
                                value={appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE}
                                onChange={() => {
                                    this.onChangeCustomerGroup(appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE);
                                }}
                                checked={discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE] != undefined}
                            />
                            <label htmlFor='customerGroupEveryone'>{t('DISCOUNTS.CUSTOMER_GROUPS_EVERYONE')}</label>
                        </div>
                        <div className='discount-item'>
                            <input
                                id='customerGroupTags'
                                type='radio'
                                name='radio-group-customerGroup'
                                className='ladiui form-check-input'
                                value={appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS}
                                onChange={() => {
                                    this.onChangeCustomerGroup(appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS);
                                }}
                                checked={discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS] != undefined}
                            />
                            <label htmlFor='customerGroupTags'>{t('DISCOUNTS.CUSTOMER_GROUPS_SPECIFIC_TAG')}</label>
                        </div>
                        {this.getCustomerTags()}
                        <div className='discount-item'>
                            <input
                                id='customerGroupCustomers'
                                type='radio'
                                name='radio-group-customerGroup'
                                className='ladiui form-check-input'
                                value={appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS}
                                onChange={() => {
                                    this.onChangeCustomerGroup(appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS);
                                }}
                                checked={discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS] != undefined}
                            />
                            <label htmlFor='customerGroupCustomers'>{t('DISCOUNTS.CUSTOMER_GROUPS_SPECIFIC_CUSTOMERS')}</label>
                        </div>

                        {this.getCustomerGroupPanel()}
                    </div>
                )}
                {/* </div> */}

                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                    <div className='ladiui form-group condition-group'>
                        <label className='ladiui-label' style={{ fontWeight: 500 }}>
                            {t('DISCOUNTS.USAGE_LIMIT')}
                        </label>
                        <div className='discount-item'>
                            <input
                                id='limitTime'
                                onChange={this.onChangeUsageLimit}
                                checked={discount.usage_limit != null}
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                            />
                            <label> {t('DISCOUNTS.USAGE_LIMIT_CAN_BE_USED')}</label>
                        </div>

                        {discount.usage_limit != null && (
                            <div className='discount-item'>
                                <Input
                                    name='usage_limit'
                                    type='number'
                                    value={discount.usage_limit || ''}
                                    onChange={this.onChangeInput}
                                    validationName={t('DISCOUNTS.USAGE_LIMIT')}
                                    validations={{ isRequired: true }}
                                    ref={(ref) => this.inputsRef.add(ref)}
                                />
                            </div>
                        )}

                        <div className='discount-item'>
                            <input
                                id='limitOnePerCus'
                                onChange={this.onChangeUsePerCus}
                                checked={discount.one_per_customer == appConfig.STATUS.ACTIVE}
                                type='checkbox'
                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                            />
                            <label> {t('DISCOUNTS.USAGE_LIMIT_ONE_PER_CUSTOMER')}</label>
                        </div>
                    </div>
                )}

                <div className='ladiui form-group condition-group'>
                    <label className='ladiui-label ' style={{ fontWeight: 500 }}>
                        {t('DISCOUNTS.ACTIVE_DATE')}
                    </label>
                    <div className='discount-date-box'>
                        <div className='discount-from-date'>
                            <label>{t('COMMON.START_DATE')}</label>
                            <DatePicker
                                name='start_date'
                                minDate={baseHelper.momentToDate(this.moment())}
                                selected={discount.start_date}
                                onChange={(value) => this.onChangeDateTime(value, 'start_date')}
                                showTimeSelect
                                timeFormat={appConfig.DEFAUT_TIME}
                                timeIntervals={15}
                                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                timeCaption='Time'
                            />
                        </div>
                        {discount.end_date != null && (
                            <div className='discount-to-date'>
                                <label>{t('COMMON.END_DATE')}</label>
                                <DatePicker
                                    name='end_date'
                                    minDate={discount.start_date}
                                    selected={discount.end_date}
                                    onChange={(value) => this.onChangeDateTime(value, 'end_date')}
                                    showTimeSelect
                                    timeFormat={appConfig.DEFAUT_TIME}
                                    timeIntervals={15}
                                    dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                    timeCaption='Time'
                                />
                            </div>
                        )}
                        <div className='discount-check-endate flex'>
                            <input
                                id='limitTime'
                                onChange={this.onChangeSetEndDate}
                                type='checkbox'
                                checked={discount.end_date != null}
                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                            />
                            <label> {t('DISCOUNTS.SET_END_DATE')}</label>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchProduct: (name) => dispatch(variantActions.search(name, null, null, 'all')),
        searchProductTags: (name) => dispatch(productTagActions.search(name)),
        searchCustomer: (name) => dispatch(customerActions.search(name)),
        searchCustomerTags: (name) => dispatch(customerTagActions.search(name)),
    };
};

const mapStateToProps = (state) => ({
    variant: { ...state.variant },
    productTag: { ...state.productTag },
    customer: { ...state.customer },
    customerTag: { ...state.customerTag },
    store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(ModalPromoCode));
