import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line max-lines-per-function
function ModalMembershipKey(props) {
    const { t, onClose, isShow, onFocusVariantInput, innerStyle, handleLockCustomer, handleCancelLockCustomer, isStudentCourse } = props;
    const wrapperRef = useRef();

    const [reason, setReason] = useState('');

    //handleClickOutSide

    // useEffect(() => {
    //   /**
    //    * Alert if clicked on outside of element
    //    */
    //   function handleClickOutside(event) {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       onClose(false);
    //     }
    //   }
    //   // Bind the event listener
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     // Unbind the event listener on clean up
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [wrapperRef]);

    const dispatch = useDispatch();

    const eventCancel = () => {
        onClose(false);
    };

    const lockCustomer = () => {
        handleLockCustomer(reason);
    };

    // const cancelLockCustomer = () => {
    //   handleCancelLockCustomer(reason);
    // };

    const getTitleModal = () => {
        let title = '';
        if (!isStudentCourse) {
            title = 'Vô hiệu tài khoản';
        } else {
            title = 'Vô hiệu tài khoản';
        }
        return title;
    };

    const getLabelReson = () => {
        let label = '';
        if (!isStudentCourse) {
            label = 'Lý do vô hiệu tài khoản này là gì ?';
        } else {
            label = 'Lý do vô hiệu tài khoản này là gì ?';
        }
        return label;
    };

    const getDescriptionModal = () => {
        let textNote = '';
        if (!isStudentCourse) {
            textNote = 'Tài khoản bị vô hiệu sẽ không thể đăng nhập vào trang khóa học. Bạn có chắc muốn thực hiện hành động này?';
        } else {
            textNote = 'Học viên sẽ không thể tham gia vào khoá học sau khi đã bị khoá. Bạn có chắc muốn thực hiện hành động này?';
        }

        return textNote;
    };

    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-membership-key'>
            <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>{getTitleModal()}</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body'>
                        <div className='cursor-pointer' style={innerStyle}>
                            <a className='flex-center-left cursor-pointer my-24 pd-0'>
                                <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
                                <div> {t('PRODUCTS.ADD_NEW')}</div>
                            </a>
                        </div>
                        <div className='ladiui form-group mt-0'>
                            <span>{getDescriptionModal()}</span>
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>{getLabelReson()}</label>
                            <textarea
                                style={{ height: 200 }}
                                name='reason'
                                className='ladiui textarea'
                                placeholder='Nhập lý do'
                                value={reason}
                                onChange={(event) => {
                                    setReason(event.target.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className='ladiui modal-footer'>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.CANCEL')}
                            </button>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-primary'
                                onClick={lockCustomer}
                            >
                                {t('ACTIONS.DISABLE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalMembershipKey);
