import React from "react";
import PropTypes from "prop-types";
import { find, remove, map } from "lodash";
import { withTranslation } from "react-i18next";
import Modal from "./Modal";
import Input from "./Input";
import { cloneDeep, compact } from "lodash";
import baseHelper from "../helpers/BaseHelper";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

class ModalInfoApiKey extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    visible: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      api_key: props.infoApi || "",
      isShowApiKey: false,
    };

    this.inputsRef = new Set();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.infoApi != nextProps.infoApi) {
      this.setState({
        api_key: nextProps.infoApi || "",
      });
    }
  }

  onChangeContent = (value) => {
    this.setState({
      content: value,
    });
  };

  onClickCopyApiKey = (api_key) => {
    navigator.clipboard.writeText(api_key);
    window.LadiUI.toastCustom("success", "", "Đã copy api_key vào clipboard!");
  };

  handleCoppyCode = () => {
    let { api_key } = this.state;

    if (api_key) {
      let scriptCode = `<script src="https://w.ladicdn.com/ladisales/sdk/sdk.js" id="${api_key}" async></script>`;
      navigator.clipboard.writeText(scriptCode);
      window.LadiUI.toastCustom("success", "", "Đã copy mã tích hợp vào clipboard!");
    }
  };

  render() {
    const { t, visible, onCancel } = this.props;
    const { name, content } = this.state;
    return (
      <Modal
        id="modal-show-api-key"
        visible={visible}
        onCancel={onCancel}
        onOk={this.submit}
        title="Trang thanh toán của bạn đã hoàn tất"
        width={600}
        isActiveBtnSaveData={true}
      >
        <div>
          <div className="ladiui form-group">
            <label className="ladiui-label" style={{ paddingBottom: 8 }}>
              Api key
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #F1F3F4",
                padding: "8px 12px",
              }}
            >
              {this.state.isShowApiKey ? this.state.api_key : baseHelper.maskText(this.state.api_key, 1)}
              <ul className="flex">
                <li className="mr-8">
                  <a style={{ color: "#000" }} onClick={() => this.setState({ isShowApiKey: !this.state.isShowApiKey })}>
                    <i className={this.state.isShowApiKey ? "ladi-icon icon-preview" : "ladi-icon icon-b-preview"}></i>
                  </a>
                </li>
                <li>
                  <a style={{ color: "#000" }} onClick={() => this.onClickCopyApiKey(this.state.api_key)}>
                    <i className="ladi-icon icon-copy"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="ladiui form-group">
            <label className="ladiui-label" style={{ paddingBottom: 8 }}>
              Mã tích hợp
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <input
                className="ladiui form-control"
                value={`<script src="https://w.ladicdn.com/ladisales/sdk/sdk.js" id="${this.state.api_key}" async></script>`}
              />
              <button className="ladiui btn btn-outline-primary ml-16" onClick={() => this.handleCoppyCode()}>
                Sao chép
              </button>
            </div>
          </div>
          <div className="ladiui form-group">
            <div className="suggest" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <img src="https://w.ladicdn.com/ladiui/icons/ldicon-info.svg" />
              <span className="ml-16">
                Sao chép mã tích hợp và dán vào trình chỉnh sửa HTML trang web bạn muốn hiển thị trang thanh toán.
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {};

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation", { withRef: true })(ModalInfoApiKey)));
