import { combineReducers } from 'redux';
import commonReducer from '../futures/common/reducer';
import authReducer from '../futures/auth/reducer';
import storeReducer from '../futures/store/reducer';
import customerReducer from '../futures/customer/reducer';
import customerTagReducer from '../futures/customer_tag/reducer';
import customerAddressReducer from '../futures/customer_address/reducer';
import productReducer from '../futures/product/reducer';
import productCrossSellReducer from '../futures/product_cross_sell/reducer';
import productTagReducer from '../futures/product_tag/reducer';
import variantReducer from '../futures/product_variant/reducer';
import productOptionReducer from '../futures/product_option/reducer';
import productImageReducer from '../futures/product_image/reducer';
import orderReducer from '../futures/order/reducer';
import paymentReducer from '../futures/payment/reducer';
import discountReducer from '../futures/discount/reducer';
import staffReducer from '../futures/staff/reducer';
import packageReducer from '../futures/package/reducer';
import addressConfigReducer from '../futures/address_config/reducer';
import shippingReducer from '../futures/shipping/reducer';
import settingReducer from '../futures/setting/reducer';
import storeAddressReducer from '../futures/store_address/reducer';
import reportReducer from '../futures/report/reducer';
import shippingRateReducer from '../futures/shipping_rate/reducer';
import orderTagReducer from '../futures/order_tag/reducer';
import apiReducer from '../futures/api/reducer';
import customerHistoryReducer from '../futures/customer_history/reducer';
import orderHistoryReducer from '../futures/order_history/reducer';
import shippingHistoryReducer from '../futures/shipping_history/reducer';
import inventoryReducer from '../futures/inventory/reducer';
import assignReducer from '../futures/assign/reducer';
import printReducer from '../futures/print/reducer';
import checkoutReducer from '../futures/checkout/reducer';
import fileReducer from '../futures/file/reducer';
import ticketSeatReducer from '../futures/ticket_seat/reducer';
import checkoutConfigReducer from '../futures/checkout_config/reducer';
import creditReducer from '../futures/credit/reducer';
import pageCheckoutReducer from '../futures/page_checkout/reducer';
import domainReducer from '../futures/domain/reducer';
import formConfigReducer from '../futures/form_config/reducer';
import formAccountReducer from './form_account/reducer';
import courseReducer from './course/reducer';
import productCategoryReducer from '../futures/product_category/reducer';
import productVideoReducer from '../futures/product_video/reducer';
import storePageReducer from '../futures/store_page/reducer';
import productReviewReducer from '../futures/product_review/reducer';
import filterReducer from '../futures/filter/reducer';
import cookieReducer from '../futures/cookies/redurce';
import bankHubReducer from '../futures/bank_hub/reducer';

import { connectRouter } from 'connected-react-router';

export default (history) =>
    combineReducers({
        common: commonReducer,
        auth: authReducer,
        store: storeReducer,
        customer: customerReducer,
        customerTag: customerTagReducer,
        customerAddress: customerAddressReducer,
        product: productReducer,
        productCrossSell: productCrossSellReducer,
        productTag: productTagReducer,
        variant: variantReducer,
        productOption: productOptionReducer,
        productImage: productImageReducer,
        order: orderReducer,
        payment: paymentReducer,
        discount: discountReducer,
        staff: staffReducer,
        package: packageReducer,
        addressConfig: addressConfigReducer,
        shipping: shippingReducer,
        setting: settingReducer,
        storeAddress: storeAddressReducer,
        report: reportReducer,
        shippingRate: shippingRateReducer,
        orderTag: orderTagReducer,
        api: apiReducer,
        customerHistory: customerHistoryReducer,
        orderHistory: orderHistoryReducer,
        shippingHistory: shippingHistoryReducer,
        inventory: inventoryReducer,
        assign: assignReducer,
        print: printReducer,
        checkout: checkoutReducer,
        file: fileReducer,
        ticketSeatReducer,
        checkoutConfigReducer,
        credit: creditReducer,
        pageCheckoutReducer,
        domainReducer,
        formConfigReducer,
        router: connectRouter(history),
        router: connectRouter(history),
        formAccount: formAccountReducer,
        course: courseReducer,
        productCategory: productCategoryReducer,
        productVideo: productVideoReducer,
        storePage: storePageReducer,
        productReview: productReviewReducer,
        filterReducer: filterReducer,
        cookieReducer,
        bankHub: bankHubReducer
    });
