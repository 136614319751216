import React from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { map, includes, find, remove } from 'lodash';
import moment from 'moment';

import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';

import * as types from '../../redux/futures/checkout/types';
import checkoutActions from '../../redux/futures/checkout/actions';

import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';

import OrderDateRangePicker from '../../components/OrderDateRangePicker';
import SelectOption from '../../components/SelectOption';
import ConfirmModal from '../../components/ConfirmModal';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import Dropdown from '../../components/Dropdown';

import LadiPagination from '../../components/LadiPagination';
import FilterConditionCheckout from './component/FilterConditionCheckout';

class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.moment = baseHelper.getMoment();
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };

        this.state = {
            activePage: 1,
            conditionProp: {
                status: {
                    value: appConfig.ORDER_STATUS.OPEN.CODE,
                    name: appConfig.ORDER_STATUS.OPEN.NAME,
                    condition: '=',
                },
            },
            searchName: '',
            searchEmailStatus: appConfig.CHECKOUT.EMAIL_STATUS.ALL.CODE,
            // searchOrderStatus: '',
            fromDate: null,
            toDate: null,
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            checkoutSelected: {},
            conditions: defaultConditions,
            selectedIDs: [],
            totalAmount: 0,
            // emailStatus: appConfig.CHECKOUT.EMAIL_STATUS.ALL.CODE,
        };

        this.sortBy = {};
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate() {
        window.LadiUI.init();
        window.LadiUI.customInit();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.checkoutReducer.action != nextProps.checkoutReducer.action) {
            if (nextProps.checkoutReducer.action === types.DELETE_CHECKOUT) {
                if (nextProps.checkoutReducer.status) {
                    this.list();

                    window.LadiUI.closeModal('confirm-checkout-deletes');
                    window.LadiUI.toastCustom('success', '', nextProps.checkoutReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.checkoutReducer.message, 'OK');
                }
            }

            if (nextProps.checkoutReducer.action === types.LIST_CHECKOUT) {
                if (nextProps.checkoutReducer.status) {
                    this.setState({
                        totalAmount: nextProps.checkoutReducer.total,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.checkoutReducer.message, 'OK');
                }
            }
        }
    }

    /**
     * list order
     */
    list = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const { searchName, selectedLimit, fromDate, toDate, searchEmailStatus, searchOrderStatus, conditions } = this.state;
        let activePage = conditions.page;

        if (resetActivePageFlag) {
            activePage = 1;

            conditions.page = 1;
            this.setState({
                conditions,
            });
        }

        // Reset selectedIDs
        // this.selectedIDs = [];
        this.setState({
            selectedIDs: [],
        });

        const data = {
            search: {
                name: searchName,
                email_status: searchEmailStatus < 3 ? searchEmailStatus : '',
                from_date: fromDate ? moment(new Date(fromDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
                to_date: toDate ? moment(new Date(toDate).getTime()).format(appConfig.CUSTOM_FORMAT_DATE_TIME) : null,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.list(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.list();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;

        // this.setState({ selectedLimit: option.value }, () => {
        this.setState({ conditions: conditions }, () => {
            this.list();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;

        this.setState({ conditions }, () => {
            this.list(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { selectedIDs } = this.state;
        const checkouts = this.props.checkoutReducer.checkouts;

        let ids = [];
        if (selectedIDs.length < checkouts.length) {
            ids = checkouts.map((item) => item.checkout_id);
        }

        this.setState({
            selectedIDs: ids,
        });
    };

    checkItem = (checkoutID) => {
        // const { checked } = event.target;
        let { selectedIDs } = this.state;

        let index = selectedIDs.indexOf(checkoutID);

        if (index > -1) {
            selectedIDs.splice(index, 1);
        } else {
            selectedIDs.push(checkoutID);
        }
        this.setState({
            selectedIDs: selectedIDs,
        });
    };

    getEmailStatus = (emailStatus) => {
        const found = find(appConfig.CHECKOUT.EMAIL_STATUS, (item) => item.CODE == emailStatus);
        if (found) {
            return found.NAME;
        }
    };

    openModalDeletes = () => {
        if (this.state.selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        window.LadiUI.showModal('confirm-checkout-deletes');
    };

    eventSelectAction = (action) => {
        switch (action) {
            case 'DELETE':
                this.openModalDeletes();
                break;
            // case 'MARK_SPAM':
            //     this.openMarkSpamModal();
            //     break;
            // case 'CANCEL_ORDER':
            //     this.openModalCancel();
            //     break;
            // case 'CONFIRM_PAYMENT':
            //     this.openModalConfirmPayment();
            //     break;
            // case 'PRINT':
            //     this.prints();
            //     break;
            default:
        }
    };

    /****************************************************************/
    render() {
        const { t } = this.props;
        const { totalRecord } = this.props.checkoutReducer;
        const { activePage, selectedLimit, searchName, searchEmailStatus, searchOrderStatus, conditions, conditionProp, selectedIDs } =
            this.state;

        const checkouts = this.props.checkoutReducer.checkouts || [];

        let fromItem = 0;
        let toItem = 0;
        if ((this.props.checkoutReducer.checkouts || []).length > 0) {
            fromItem = (activePage - 1) * selectedLimit + 1;
            toItem = fromItem + this.props.checkoutReducer.checkouts.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
            'PAGINATION.OF'
        )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

        const isLoadingData = this.props.checkoutReducer.loading && this.props.checkoutReducer.waiting == types.LIST_CHECKOUT;
        const isLoadingDelete = this.props.checkoutReducer.loading && this.props.checkoutReducer.waiting == types.DELETE_CHECKOUT;

        const listAction = [
            { value: 'DELETE', name: t('ACTIONS.DELETE') },
            // { value: 'REMOVE_MARK_SPAM', name: t('ORDERS.REMOVE_MARK_SPAM') },
            // { value: 'MARK_SPAM', name: t('ORDERS.MARK_SPAM') },
            // { value: 'CANCEL_ORDER', name: t('ORDERS.CANCEL_ORDER') },
            // { value: 'CONFIRM_PAYMENT', name: t('ORDERS.CONFIRM_PAYMENT') },
        ];

        return (
            <div className='page-content'>
                <div className='page-checkout'>
                    <div className='checkout-header'>
                        <div className='ladiui label-20'>{t('ORDERS.TAB_TITLE.CHECKOUT_LIST')}</div>
                        <div className='ladiui text-14 mt-4'>{t('CHECKOUTS.SUB_TITLE')}</div>
                    </div>
                    <div className='block-filter'>
                        <div className='ladiui block-search'>
                            <div className='ladiui search-group'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width'
                                    name='searchName'
                                    placeholder={t('CHECKOUTS.SEARCH_ORDER')}
                                    aria-expanded='false'
                                    value={searchName}
                                    onChange={this.onChangeInput}
                                    onKeyDown={this.onKeyDownInput}
                                />
                                <i className='ladiui icon icon-search' onClick={this.list}></i>
                            </div>
                            <div className='block-filter-button'>
                                <div className='date-filter'>
                                    <OrderDateRangePicker
                                        startDateId='fromDate'
                                        endDateId='toDate'
                                        startDate={this.state.fromDate}
                                        endDate={this.state.toDate}
                                        onDatesChange={({ startDate, endDate }) => {
                                            this.setState(
                                                {
                                                    fromDate: baseHelper.getFromDate(startDate),
                                                    toDate: baseHelper.getToDate(endDate),
                                                },
                                                () => {
                                                    if ((startDate && endDate) || (!startDate && !endDate)) {
                                                        this.list();
                                                    }
                                                }
                                            );
                                        }}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={(focusedInput) => {
                                            this.setState({ focusedInput });
                                        }}
                                        showDefaultInputIcon={true}
                                        inputIconPosition='after'
                                        small={true}
                                        hideKeyboardShortcutsPanel={true}
                                        customInputIcon={<i className='ladi-icon icon-calendar-date-2' />}
                                        startDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                        endDatePlaceholderText={appConfig.DEFAULT_FORMAT_DATE}
                                        isOutsideRange={() => false}
                                        isDayHighlighted={(date) => {
                                            return this.moment().isSame(date, 'day');
                                        }}
                                        minimumNights={0}
                                        showClearDates={true}
                                        anchorDirection={'right'}
                                    />
                                </div>
                                <Dropdown
                                    data={Object.values(appConfig.CHECKOUT.EMAIL_STATUS)}
                                    currentKey={searchEmailStatus}
                                    _key='CODE'
                                    _value='NAME'
                                    onSelectItem={(item) => {
                                        this.setState(
                                            {
                                                searchEmailStatus: item.CODE,
                                            },
                                            () => {
                                                this.list();
                                            }
                                        );
                                    }}
                                    wrapperClassName=''
                                />
                            </div>
                        </div>
                    </div>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div>
                            <div className='ladi-card ladiui-table-responsive'>
                                <table className='ladiui table text-left order-table table-order'>
                                    <thead style={{ position: 'relative' }}>
                                        <tr className='ladiui table-vertical'>
                                            <th scope='col' className='ladiui pos-rel'>
                                                <input
                                                    id='checkAllItem'
                                                    onClick={this.checkAllItem}
                                                    checked={selectedIDs.length == checkouts.length && selectedIDs.length > 0}
                                                    onChange={() => {}}
                                                    type='checkbox'
                                                    className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-16'
                                                />
                                                <span>{t('ORDERS.REF_NO')}</span>
                                                {selectedIDs.length > 0 && (
                                                    <LadiDropdownMenu
                                                        labelDropdown={'Chọn hành động'}
                                                        listProp={listAction}
                                                        defaultTextProp={t('ACTIONS.TITLE')}
                                                        hideLabel={true}
                                                        cbProp={this.eventSelectAction}
                                                        classSize='btn-sm'
                                                        classDropdown='w-180 action-table-order-tag '
                                                    />
                                                )}
                                            </th>
                                            <th scope='col'>{t('CHECKOUTS.LB_CUSTOMER')}</th>
                                            <th scope='col'>{t('CHECKOUTS.LB_CUSTOMER_PHONE')}</th>
                                            <th scope='col'>{t('CHECKOUTS.LB_CUSTOMER_EMAIL')}</th>
                                            <th scope='col'>{t('COMMON.CREATED_AT')}</th>
                                            <th scope='col'>{t('CHECKOUTS.LB_EMAIL_STATUS')}</th>
                                            <th scope='col' className='ladiui text-right'>
                                                {t('ORDERS.TOTAL')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {map(checkouts, (item) => {
                                            let isSelected = selectedIDs.includes(item.checkout_id);
                                            return (
                                                <tr key={item.checkout_id} className={`ladiui table-vertical main`}>
                                                    <td>
                                                        <input
                                                            type='checkbox'
                                                            onClick={(event) => this.checkItem(item.checkout_id)}
                                                            onChange={() => {}}
                                                            checked={isSelected}
                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-16'
                                                        />

                                                        <NavLink
                                                            className='ladiui text-primary'
                                                            to={`/checkouts/${item.checkout_id}`}
                                                        >{`${item.checkout_id}`}</NavLink>
                                                    </td>
                                                    <td>{`${baseHelper.getText(item.customer_first_name)}`}</td>
                                                    <td>{`${baseHelper.getText(item.customer_phone)}`}</td>
                                                    <td>{`${baseHelper.getText(item.customer_email)}` || t('COMMON.NO_EMAIL')}</td>
                                                    <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                                    <td className={`ladiui email-status-${item.email_status} display-flex-center`}>
                                                        {this.getEmailStatus(item.email_status)}
                                                    </td>
                                                    <td className='ladiui text-right'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            item.total,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    {totalRecord > 0 && (
                                        <tfoot>
                                            <tr>
                                                <td colSpan={6}>
                                                    <strong>{t('ORDERS.TOTAL_AMOUNT')}</strong>
                                                </td>
                                                <td className='ladiui text-right'>
                                                    <strong className='pl-0'>
                                                        {baseHelper.formatMoneyPostFix(
                                                            this.state.totalAmount,
                                                            this.props.storeReducer.userInfo.currentStore.currency_symbol
                                                        )}
                                                    </strong>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                            </div>
                            {totalRecord > 0 ? (
                                <LadiPagination
                                    conditionsProp={conditions}
                                    listName={t('CUSTOM_FIELD.ORDER')}
                                    message={''}
                                    items={this.props.checkoutReducer.checkouts}
                                    total={totalRecord}
                                    limitAction={this.onChangeLimit}
                                    pageAction={this.onPageChange}
                                />
                            ) : (
                                <div className='table-empty'>
                                    <i className='new-ldicon-search' />
                                    <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                                    <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <ConfirmModal
                    id='confirm-checkout-deletes'
                    title={t('CHECKOUTS.MSG_DELETE_CHECKOUT_TITLE')}
                    content={t('CHECKOUTS.MSG_DELETE_CHECKOUTS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.deletes({ checkout_ids: this.state.selectedIDs })}
                    isLoading={isLoadingDelete}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        list: (data) => dispatch(checkoutActions.list(data)),
        show: (checkoutID) => dispatch(checkoutActions.show(checkoutID)),
        deletes: (data) => dispatch(checkoutActions.deletes(data)),
    };
};

const mapStateToProps = (state) => ({
    checkoutReducer: { ...state.checkout },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Checkout));
