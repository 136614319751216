import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";

ModalGHN.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
  ghnAccount: PropTypes.any,
  isSubmitLoading: PropTypes.bool,
};

export default function ModalGHN(props) {
  const {
    paymentGatewayConfigured,
    ghnAccount,
    isSubmitLoading,
    onCancel,
    onChangeGHNInput,
    ghnMode,
    connect,
    inputsGHNRef,
    setGHNModal,
    logo,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      id="modal-ghn"
      title={!paymentGatewayConfigured ? t("SHIPPINGS.SET_UP_TRANSPORT") : t("SHIPPINGS.DETAIL_TRANSPORT")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={() => connect(appConfig.SHIPPING_PARTNERS.GHN.CODE)}
      visible={true}
      logo={logo}
      isActiveBtnSaveData={paymentGatewayConfigured ? true : false}
      width={600}
      bodyStyles={{ minHeight: "30vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("COMMON.TOKEN")}</label>
                <Input value={paymentGatewayConfigured.config.token} disabled={true} />
              </div>

              <div className="ladiui form-group">
                <label className="ladiui-label">{t("SHIPPINGS.GHN_SHOP_ID")}</label>
                <Input value={paymentGatewayConfigured.config.shop_id} disabled={true} />
              </div>

              <div className="ladiui form-group">
                <label className="ladiui-label">{t("SHIPPINGS.CLIENT_ORDER_CODE")}</label>
                <Input value={paymentGatewayConfigured.config.client_order_code} disabled={true} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("COMMON.TOKEN")}</label>
                <Input
                  ref={(ref) => inputsGHNRef.add(ref)}
                  value={ghnAccount.token}
                  validationName={t("COMMON.TOKEN")}
                  name="token"
                  onChange={(event) => onChangeGHNInput(event)}
                  validations={{ isRequired: true }}
                />
              </div>

              <div className="ladiui form-group">
                <label className="ladiui-label">{t("SHIPPINGS.GHN_SHOP_ID")}</label>
                <Input
                  ref={(ref) => inputsGHNRef.add(ref)}
                  name="shop_id"
                  value={ghnAccount.shop_id}
                  validationName={t("COMMON.GHN_SHOP_ID")}
                  onChange={(event) => onChangeGHNInput(event)}
                  validations={{ isRequired: true }}
                />
              </div>

              <div id="data-tooltip-guide" className="ladiui form-group client-order-code">
                <label className="ladiui-label">
                  <span className="tooltip-paymet-guide">{t("SHIPPINGS.CLIENT_ORDER_CODE")}</span>
                  <span className="tooltip-icon" data-tooltip={t("SHIPPINGS.LB_TT_PAYMENT_GUIDE")} data-tooltip-position="right">
                    {" "}
                    <i className="ladi-icon icon-c-question" />
                  </span>
                </label>
                <Input
                  ref={(ref) => inputsGHNRef.add(ref)}
                  name="client_order_code"
                  o
                  value={ghnAccount.client_order_code}
                  onChange={(event) => onChangeGHNInput(event)}
                />
              </div>

              {baseHelper.isDevelopEnv() && (
                <div className="ladiui form-group flex">
                  <input
                    id="ghn_mode"
                    type="checkbox"
                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                    onChange={setGHNModal}
                    defaultChecked={ghnMode == appConfig.MODE.TEST}
                  />
                  <label htmlFor="ghn_mode"> {t("MODE.TEST")}</label>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
