export const SHIPPING_LIST_PARTNERS = "SHIPPING_LIST_PARTNERS";
export const SHIPPING_LIST_HUBS = "SHIPPING_LIST_HUBS";
export const SHIPPING_LIST_SERVICES = "SHIPPING_LIST_SERVICES";
export const SHIPPING_GET_FEE = "SHIPPING_GET_FEE";
export const SHIPPING_RESET_SERVICES = "SHIPPING_RESET_SERVICES";
export const SHIPPING_CANCEL = "SHIPPING_CANCEL";
export const LIST_SHIPPING = "LIST_SHIPPING";
export const DELETE_SHIPPINGS = "DELETE_SHIPPINGS";
export const DELETE_SHIPPING = "DELETE_SHIPPING";
export const SHOW_SHIPPING = "SHOW_SHIPPING";
export const CREATE_SHIPPING = "CREATE_SHIPPING";
export const UPDATE_SHIPPING = "UPDATE_SHIPPING";
export const SHIPPING_UPDATE_FEE = "SHIPPING_UPDATE_FEE";
export const SHIPPING_UPDATE_TRACKING_NUMBER = "SHIPPING_UPDATE_TRACKING_NUMBER";
export const SHIPPING_UPDATE_STATUS = "SHIPPING_UPDATE_STATUS";
export const SHIPPING_UPDATE_PAYMENT_STATUS = "SHIPPING_UPDATE_PAYMENT_STATUS";
export const SHIPPING_RESET_FEE = "SHIPPING_RESET_FEE";
export const SHIPPING_LIST_OTHER_HUBS = "SHIPPING_LIST_OTHER_HUBS";
export const SHIPPING_LIST_GHN_HUBS = "SHIPPING_LIST_GHN_HUBS";
export const SHIPPING_LIST_GHTK_HUBS = "SHIPPING_LIST_GHTK_HUBS";
export const SHIPPING_LIST_VNPOST_HUBS = "SHIPPING_LIST_VNPOST_HUBS";
export const SHIPPING_LIST_VTPOST_HUBS = "SHIPPING_LIST_VTPOST_HUBS";
export const SHIPPING_LIST_STATIONS = "SHIPPING_LIST_STATIONS";
