/** Import default package */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

/** Import from third party */
import { cloneDeep, map, compact } from "lodash";

/** Import component from my app */
import Input from "../../../components/Input";

import baseHelper from "../../../helpers/BaseHelper";

import { withTranslation } from "react-i18next";

type Props = {};

class PanelBillingInfo extends React.Component<Props> {
  static propTypes = {
    billingInfo: PropTypes.object,
    mode: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isRequiredState: false,
    isRequiredDistrict: false,
    isRequiredWard: false,
    isRequiredPhone: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      billing: {
        billing_company: "",
        billing_tax_code: "",
        billing_email: "",
        billing_company_address: "",
      },
    };
    this.initialState = cloneDeep(this.state);
    this.ref = React.createRef();
    this.inputsRef = new Set();
    this.locationRef = React.createRef();
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentDidMount() {
    window.LadiUI.init();
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }
  }

  init = (props) => {
    const newAddress = {};
    map(this.state.billing, (value, key) => {
      newAddress[key] = !baseHelper.isEmpty(props.billingInfo[key])
        ? props.billingInfo[key]
        : "";
    });

    this.setState({
      billing: newAddress,
    });
  };

  //   init = (props) => {
  //     const newAddress = {};
  //     map(this.state.address, (value, key) => {
  //       newAddress[key] = !baseHelper.isEmpty(props.address[key])
  //         ? props.address[key]
  //         : "";
  //     });

  //     this.setState({
  //       address: newAddress,
  //     });
  //   };
  /*******************************HANDLE INPUT***************************/
  onChangeAddressInput = (event) => {
    this.setState({
      billing: {
        ...this.state.billing,
        [event.target.name]: event.target.value,
      },
    });
  };

  /*****************************************************************/

  validate = () => {
    // Validate
    const { t } = this.props;
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });

    errors = compact(errors);

    return errors;
  };

  getData = () => {
    return { ...this.state.billing };
  };

  render() {
    const { t, styleCustom } = this.props;
    const { billing } = this.state;
    const { language } = this.props.store.userInfo;

    return (
      <div>
        <div className="ladiui-row">
          <div className="ladiui form-group">
            <label className="ladiui-label">{"Tên công ty"}</label>
            <Input
              name="billing_company"
              validationName={"tên công ty"}
              placeholder={t("Tên công ty")}
              value={billing.billing_company}
              onChange={this.onChangeAddressInput}
              ref={(ref) => this.inputsRef.add(ref)}
              validations={{ isRequired: true }}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{"Mã số thuế"}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="billing_tax_code"
              validationName={"mã số thuế"}
              placeholder={t("Mã số thuế")}
              value={billing.billing_tax_code}
              onChange={this.onChangeAddressInput}
              validations={{
                isRequired: true,
              }}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{"Email"}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="billing_email"
              validationName={"đúng định dạng email"}
              placeholder={t("Email")}
              value={billing.billing_email}
              onChange={this.onChangeAddressInput}
              validations={{ email: true }}
            />
          </div>

          <div className="ladiui form-group">
            <label className="ladiui-label">{"Địa chỉ công ty"}</label>
            <Input
              ref={(ref) => this.inputsRef.add(ref)}
              name="billing_company_address"
              validationName={"địa chỉ công ty"}
              placeholder={t("Địa chỉ")}
              value={billing.billing_company_address}
              onChange={this.onChangeAddressInput}
              validations={{
                isRequired: true,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  store: { ...state.store },
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withTranslation("translation", { withRef: true })(PanelBillingInfo)
);
