import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';

import * as customerTagTypes from '../../../redux/futures/customer_tag/types';
import customerTagAction from '../../../redux/futures/customer_tag/actions';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCustomerTagCreate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isUpdate = false, visible, hideModal, selectedTag, _tagSelected } = props;
    const customerTagReducer = useSelector((state) => state.customerTag);

    const tagDefault = {
        name: '',
    };
    const [tagSelected, setTagSelected] = useState(tagDefault);

    const loading = useMemo(() => {
        return customerTagReducer.loading || false;
    }, [customerTagReducer.loading]);

    useEffect(() => {
        if (_tagSelected && _tagSelected.customer_tag_id) setTagSelected(_tagSelected);
        else setTagSelected({ color: appConfig.TAG_COLORS[0], name: '' });
    }, [_tagSelected]);

    const onCancel = () => {
        hideModal();
    };

    useEffect(() => {
        if (customerTagReducer.action == customerTagTypes.CREATE_CUSTOMER_TAG) {
            if (customerTagReducer.status) {
                window.LadiUI.toastCustom('success', '', customerTagReducer.message);
                selectedTag(customerTagReducer.order_tag || {}, true);
                setTagSelected(tagDefault);
                hideModal();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), customerTagReducer.message, 'OK');
        }
        if (customerTagReducer.action == customerTagTypes.UPDATE_CUSTOMER_TAG) {
            if (customerTagReducer.status) {
                window.LadiUI.toastCustom('success', '', customerTagReducer.message);
                selectedTag(true);
                setTagSelected(tagDefault);
                hideModal();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), customerTagReducer.message, 'OK');
        }
    }, [customerTagReducer.action]);

    const onOk = () => {
        if (!tagSelected.name) {
            window.LadiUI.toastCustom('danger', '', t('TAGS.NAME_EMPTY'));
            return;
        }
        if (isUpdate && _tagSelected && _tagSelected.customer_tag_id) {
            dispatch(customerTagAction.update(tagSelected));
        } else dispatch(customerTagAction.create(tagSelected));
    };

    return (
        <Modal
            id='modal-customer-tag-edit'
            title={!isUpdate ? t('TAGS.ADD_TAG') : t('TAGS.UPDATE_TAG')}
            sub_title={!isUpdate ? t('TAGS.ADD_TAG_SUB') : t('TAGS.UPDATE_TAG_SUB')}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            width={400}
            cancelText={t('ACTIONS.REMOVE')}
            okText={!isUpdate ? t('TAGS.ADD_TAG') : t('TAGS.UPDATE_TAG_AVTION')}
            isLoading={loading}
            position_unset={true}
        >
            <div className='ladiui form-group'>
                <label className='ladiui text-14'>{t('TAGS.COL_TAG')}</label>
                <Input
                    className='mt-4'
                    value={tagSelected.name}
                    onChange={(event) => {
                        let value = event.target.value;
                        setTagSelected((pre) => {
                            let tg = { ...pre };
                            tg.name = value;
                            return tg;
                        });
                    }}
                    validationName={t('TAGS.COL_TAG')}
                    validations={{ isRequired: true }}
                />
            </div>
        </Modal>
    );
}

export default ModalCustomerTagCreate;
