import React, { useEffect, useState } from 'react';
import { map, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import creditActions from '../../../redux/futures/credit/actions';
import * as creditTypes from '../../../redux/futures/credit/types';
import Pagination from 'react-js-pagination';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrderDateRangePicker from '../../../components/OrderDateRangePicker';
import LadiPagination from '../../../components/LadiPagination';

export default function TabPackageInfo(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const moment = baseHelper.getMoment();

    const storeReducer = useSelector((state) => state.store);

    const currentStore = storeReducer.userInfo.currentStore;
    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    }, []);

    return (
        <div className='page-setting' id='tab-package-info'>
            <div className='block-title-action credit'>
                <h3 className='title'>{t('PACKAGE.TITLE')}</h3>
                <p className='sub-title'>{t('PACKAGE.SUB_TITLE')}</p>
            </div>

            <div className='ladiui-setting package-content-info'>
                <ul>
                    <li className='package-item'>
                        <span>{t('PACKAGE.NAME')}</span>
                        <label className='ladiui item-title'>{baseHelper.getPackageName(currentStore.pkg_name)}</label>
                    </li>
                    <li className='package-item'>
                        <span>{t('PACKAGE.ACTIVE_DATE')}</span>
                        <span>{baseHelper.formatStrToDate(currentStore.pkg_actived_at, appConfig.DEFAULT_FORMAT_DATE)}</span>
                    </li>
                    <li className='package-item'>
                        <span>{t('PACKAGE.EXPIRED_DATE')}</span>

                        <span>
                            {currentStore.pkg_name == appConfig.PACKAGE_NAME.STARTER
                                ? 'Vĩnh viễn'
                                : currentStore.pkg_exp
                                ? baseHelper.formatStrToDate(currentStore.pkg_exp, appConfig.DEFAULT_FORMAT_DATE)
                                : 'LIFETIME'}
                        </span>
                    </li>
                    <li className='package-item'>
                        <span>{t('PACKAGE.ORDER_NUM')}</span>
                        <span>{`${baseHelper.parseInt(currentStore.order_use)} / ${baseHelper.parseInt(currentStore.order_num)}`}</span>
                    </li>
                </ul>
                <div className='pkg-btn-upgrade'>
                    <button
                        onClick={() => {
                            window.open('https://www.ladisales.vn/', '_blank');
                        }}
                        className='ladiui btn btn-primary'
                    >
                        {t('PACKAGE.UPGRADE')}
                    </button>
                </div>
            </div>
        </div>
    );
}
