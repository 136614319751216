import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";


const list = (data) => ({
    type: types.LIST_REVIEW,
    meta: {
        endpoint: endpoint.LIST_REVIEW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const create = (data) => ({
    type: types.CREATE_REVIEW,
    meta: {
        endpoint: endpoint.CREATE_REVIEW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const creates = (data) => ({
    type: types.CREATE_REVIEWS,
    meta: {
        endpoint: endpoint.CREATE_REVIEWS,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const update = (data) => ({
    type: types.UPDATE_REVIEW,
    meta: {
        endpoint: endpoint.UPDATE_REVIEW,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const deleteReview = (reviewID) => ({
    type: types.DELETE_REVIEW,
    meta: {
        endpoint: endpoint.DELETE_REVIEW,
        method: REQUEST_METHOD.POST,
        body: {
            product_reviews_id: reviewID
        },
        hasAuth: true,
    },
});

const deleteReviews = (reviewIDS) => ({
    type: types.DELETE_REVIEWS,
    meta: {
        endpoint: endpoint.DELETE_REVIEWS,
        method: REQUEST_METHOD.POST,
        body: {
            product_reviews_ids: reviewIDS
        },
        hasAuth: true,
    },
});



export default {
    list,
    create,
    creates,
    update,
    deleteReview,
    deleteReviews
};
