import React from 'react';
import Input from '../../components/Input';
import storeActions from '../../redux/futures/store/actions';
import * as storeTypes from '../../redux/futures/store/types';
import { compact, map } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LoadingScene from '../../components/LoadingScene';
import appConfig from '../../config/app';
import Location from '../../components/Location';

class StoreCreate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            store: {
                name: '',
            },
            address: {
                country_code: '',
                country_name: '',
                postal_code: '',
                state_id: '',
                state_name: '',
                district_id: '',
                district_name: '',
                ward_id: '',
                ward_name: '',
                address: '',
                phone: '',
            },
        }

        this.inputsRef = new Set();
        this.locationRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if ((this.props.storeReducer.action != nextProps.storeReducer.action) && (nextProps.storeReducer.action === storeTypes.CREATE_STORE)) {
            if (nextProps.storeReducer.status) {
                window.LadiUI.toastCustom('success', '', nextProps.storeReducer.message);

                window.location = '/';
            } else {
                window.LadiUI.toastCustom('danger', '', nextProps.storeReducer.message);
            }
        }
    }

    componentDidMount() {
        window.LadiUI.init();

        document.body.style.backgroundColor = '#fff';
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    onChangeInput = (event) => {
        this.setState({
            store: {
                ...this.state.store,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeAddressInput = (event) => {
        this.setState({
            address: {
                ...this.state.address,
                [event.target.name]: event.target.value
            }
        });
    }

    onChangeLocation = (address) => {
        this.setState({
            address: {
                ...this.state.address,
                ...address
            }
        })
    }

    submit = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, ref => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });
        errors = errors.concat(this.locationRef.current.validate());

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join("<br/>");
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        this.props.create(this.state.store, this.state.address);
    }

    render() {
        const { t } = this.props;
        const isSubmitLoading = this.props.storeReducer.loading && storeTypes.CREATE_STORE == this.props.storeReducer.waiting;
        return (
            <div className="welcome text-center">
                {
                    isSubmitLoading && <LoadingScene blur={true} />
                }
                <div className="heading">
                    <h1 className="init-store ladiui-label">Khởi tạo cửa hàng</h1>
                </div>

                <div className="ladiui form-group">
                    <Input
                        value={this.state.store.name}
                        placeholder={t('STORES.STORE_CREATE_HOLDER')}
                        name="name"
                        validationName={t('STORES.STORE_CREATE_HOLDER')}
                        ref={(ref) => this.inputsRef.add(ref)}
                        validations={{ isRequired: true }}
                        onChange={this.onChangeInput}
                    />
                </div>

                <div className="ladiui-row text-left">
                    <div className="col-50">
                        <div className="ladiui form-group">
                            <label className="ladiui-label">{t('COMMON.PHONE')}</label>
                            <Input
                                ref={(ref) => this.inputsRef.add(ref)}
                                name="phone"
                                validationName={t('COMMON.PHONE')}
                                placeholder={t('COMMON.PHONE')}
                                value={this.state.address.phone}
                                onChange={this.onChangeAddressInput}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: true, phone: true }}
                            />
                        </div>
                    </div>
                    <div className="col-50">
                        <div className="ladiui form-group">
                            <label className="ladiui-label">{t('COMMON.ADDRESS')}</label>
                            <Input
                                value={this.state.address.address}
                                placeholder={t('STORES.STORE_CREATE_ADDRESS_HOLDER')}
                                name="address"
                                validationName={t('STORES.STORE_CREATE_ADDRESS_HOLDER')}
                                ref={(ref) => this.inputsRef.add(ref)}
                                validations={{ isRequired: true }}
                                onChange={this.onChangeAddressInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="ladiui form-group text-left">
                    <Location
                        ref={this.locationRef}
                        wrapperClassName="ladiui form-group"
                        address={this.state.address}
                        autoLoad={false}
                        onChangeInput={this.onChangeLocation}
                        validations={{
                            isRequiredCountry: true,
                            isRequiredState: true,
                        }}
                    />
                </div>
                <div className="btn-create-store">
                    <button onClick={this.submit} className="ladiui button primary"><i className="ladi-icon icon-c-add" /> {t('STORES.BTN_CREATE')}</button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        create: (store, address) => dispatch(storeActions.create(store, address)),
    }
}

const mapStateToProps = (state) => ({
    storeReducer: { ...state.store }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation('translation', { withRef: true })(StoreCreate)));