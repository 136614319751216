import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input';

import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import courseActions from '../../../redux/futures/course/actions';
import * as courseTypes from '../../../redux/futures/course/types';

import DropdownCheckboxSearch from '../../../components/DropdownCheckboxSearch';

import appConfig from '../../../config/app';
import Dropdown from '../../../components/Dropdown';

import ModalAddProduct from '../../orders/OrderCreate/components/ModalAddProduct';

import { produce, find, compact, map } from 'lodash';
import baseHelper from '../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function
function ModalAddStudent(props) {
    const { t, onClose, isShow, innerStyle } = props;
    const wrapperRef = useRef();

    const dispatch = useDispatch();

    const productReducer = useSelector((state) => state.product);

    const storeReducer = useSelector((state) => state.store);

    const courseReducer = useSelector((state) => state.course);

    const inputsRef = new Set();

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [listCustomers, setListCustomers] = useState([]);

    const [dataSelectedCustomer, setDataSelectedCustomer] = useState([]);

    const [dataStudent, setDataStudent] = useState({
        full_name: '',
        email: '',
        phone: '',
    });

    const [dataCourses, setDataCourses] = useState([]);
    const [isShowModalAddCourse, setIsShowModalAddCourse] = useState(false);
    const [valueSearchCourse, setValueSearchCourse] = useState('');
    const [selectedCourses, setSelectedCourses] = useState([]);

    useEffect(() => {
        dispatch(productActions.search('', 'Course'));
    }, []);

    useEffect(() => {
        if (productReducer.action == productTypes.SEARCH_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    setDataCourses(productReducer.products);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    const eventCancel = () => {
        onClose(false);
    };

    const onChangeDataStudent = (event) => {
        let { name, value } = event.target;

        setDataStudent({
            ...dataStudent,
            [name]: value,
        });
    };

    const handleAddStudent = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let payload = {
            full_name: dataStudent.full_name ? dataStudent.full_name : '',
            email: dataStudent.email ? dataStudent.email : '',
            phone: dataStudent.phone ? dataStudent.phone : '',
            selected_course_ids: selectedCourses && selectedCourses.length > 0 ? selectedCourses.map((item) => item.product_id) : [],
        };
        dispatch(courseActions.createAccount(payload));
    };

    const setSelectedStudent = (data) => {
        let _selectedDataCustomers = [...dataSelectedCustomer];

        if (data) {
            _selectedDataCustomers.push(data);
        }

        setDataSelectedCustomer(_selectedDataCustomers);
    };

    const handleDeleteItem = (index) => {
        let _selectedCourses = [...selectedCourses];

        _selectedCourses.splice(index, 1);
        setSelectedCourses(_selectedCourses);
    };

    const onChangeSearchValueCourse = async (event) => {
        let value = event.target.value;
        await setValueSearchCourse(value);
        dispatch(productActions.search(value, 'Course'));
    };

    const selectCourse = (data) => {
        let course = { ...data };

        if (!course.price) {
            if (course.max_price == course.min_price) {
                course.price = course.max_price;
            }
        }

        if (find(selectedCourses, (item) => item.product_id == course.product_id)) {
            return;
        }
        let _selectedCourses = [...selectedCourses];

        _selectedCourses.push(course);

        setSelectedCourses(_selectedCourses);
    };

    const fetchListSelectedCourses = () => {
        return selectedCourses.map((item, index) => {
            return (
                <tr key={index} className='ladiui table-vertical'>
                    <td>
                        <div className='flex'>
                            <div className='ls-no-image-block ls-mr-12'>
                                <i className='new-ldicon-photo ls-size-24'></i>
                            </div>
                            {/* <img
                                src='https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                className='mr-16'
                                width={40}
                                height={40}
                                style={{
                                    objectFit: 'cover',
                                }}
                            /> */}
                            <span className='limit-text-vertical' style={{ width: '250px' }}>
                                {item.name ? item.name : ''}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className='ls-flex ls-justify-center ls-items-center ls-gap-4'>
                            <i className='new-ldicon-user ls-size-16 ls-bg-primary'></i>
                            <span>
                                {item.total_student ? item.total_student : 0}
                            </span>
                        </div> 
                    </td>
                    <td>
                        {item.price ? baseHelper.formatMoneyPostFix(item.price, storeReducer?.userInfo?.currentStore?.currency_symbol) : 0}
                    </td>
                    <td className='text-right'>
                        <div className='btn-delete cursor-pointer' onClick={() => handleDeleteItem(index)}>
                            <i className='ladi-icon icon-bin'>
                            </i>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const isLoadingAddStudent = courseReducer.loading && courseReducer.waiting == courseTypes.CREATE_ACCOUNT;

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-student'>
                <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Thêm mới học viên</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body' style={{ position: 'initial' }}>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Họ và tên</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    name='full_name'
                                    validationName={'tên tài khoản'}
                                    placeholder={'Nhập tên tài khoản'}
                                    value={dataStudent.full_name}
                                    onChange={(e) => onChangeDataStudent(e)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Email học viên</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    name='email'
                                    validationName={'email'}
                                    placeholder={'Nhập email'}
                                    value={dataStudent.email}
                                    onChange={(e) => onChangeDataStudent(e)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Số điện thoại</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    type='number'
                                    name='phone'
                                    validationName={'số điện thoại'}
                                    placeholder={'Nhập số điện thoại'}
                                    value={dataStudent.phone}
                                    onChange={(e) => onChangeDataStudent(e)}
                                    validations={{ isRequired: true }}
                                />
                            </div>
                            <div className='block-list-courses mt-24'>
                                <label className='ladiui-label'>Khóa học tham gia</label>
                                {selectedCourses && selectedCourses.length > 0 && (
                                    <table className={`ladiui table text-left ls-table-separate pt-12`}>
                                        {/* <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col'>Tên khóa học</th>
                                                <th scope='col'>Số học viên</th>
                                                <th scope='col'>Giá khóa học</th>
                                                <th scope='col' />
                                            </tr>
                                        </thead> */}
                                        <tbody>{fetchListSelectedCourses()}</tbody>
                                    </table>
                                )}
                                <div className='cursor-pointer pt-12'>
                                    <a className='flex-center-left cursor-pointer pd-0' onClick={() => setIsShowModalAddCourse(true)}>
                                        <img className='mr-8' src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' />
                                        <span>Chọn khóa học</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className='ladiui modal-footer'>
                            <div className='ladiui footer-modal-btn'>
                                <button
                                    type='button'
                                    data-dismiss='modal'
                                    data-target='add-product'
                                    className='ladiui btn btn-secondary'
                                    onClick={eventCancel}
                                >
                                    {t('ACTIONS.REMOVE')}
                                </button>
                                <button
                                    type='button'
                                    data-dismiss='modal'
                                    data-target='add-product'
                                    onClick={() => handleAddStudent()}
                                    className={`ladiui btn btn-primary ${isLoadingAddStudent ? 'loader' : ''}`}
                                    disabled={isLoadingAddStudent}
                                >
                                    {t('ACTIONS.ADD')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowModalAddCourse && (
                <ModalAddProduct
                    onClose={() => setIsShowModalAddCourse(false)}
                    titleModal={'Danh sách khóa học'}
                    isShow={isShowModalAddCourse}
                    listVariant={dataCourses || []}
                    innerStyle={{ display: 'none' }}
                    selectVariant={selectCourse}
                    isSelectedTypeProduct={true}
                    isFeaturedCourse={true}
                    selectedProducts={selectedCourses}
                    searchVariantValue={valueSearchCourse}
                    onChangeSearchVariantInput={onChangeSearchValueCourse}
                />
            )}
        </>
    );
}

export default withTranslation()(ModalAddStudent);
