import React from "react";
import PropTypes from "prop-types";
import { cloneDeep, map, remove, compact } from "lodash";
import Modal from "./Modal";
import { withTranslation } from "react-i18next";
import SelectOption from "./SelectOption";
import Input from "./Input";
import appConfig from "../config/app";
import Popover from "./Popover";
import { ChromePicker } from "react-color";
import Image from "./Image";
import baseHelper from "../helpers/BaseHelper";
import { connect } from "react-redux";

import fileActions from "../redux/futures/file/actions";
import * as fileTypes from "../redux/futures/file/types";
import { produce } from "immer";
import LoadingScene from "./LoadingScene";
class ModalSwatch extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: appConfig.FORM_MODE.CREATE,
  };

  constructor(props) {
    super(props);

    this.state = {
      option: {},
      selectedIndex: 0,
    };
  }

  componentDidMount() {
    window.LadiUI.init();
    // window.LadiUI.customInit();();

    if (this.props.option) {
      this.init(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.fileReducer.action != nextProps.fileReducer.action) {
      if (nextProps.fileReducer.action == fileTypes.UPLOAD_SWATCH_IMAGE) {
        if (nextProps.fileReducer.status) {
          const { option, selectedIndex } = this.state;
          //   const newOption = produce(option, (draft) => {
          //     draft.values[selectedIndex] = produce(
          //       draft.values[selectedIndex],
          //       (_draft) => {
          //         _draft.value = nextProps.fileReducer.swatch_image;
          //       }
          //     );
          //   });

          option.values[selectedIndex].value = nextProps.fileReducer.swatch_image;
          option.values[selectedIndex].type = appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE;
          this.setState({
            option,
          });
        } else {
          window.LadiUI.showErrorMessage("Thông báo", nextProps.fileReducer.message, "OK");
        }
      }
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
    window.LadiUI.customInit();
  }

  init(props) {
    this.setState({
      option: cloneDeep(props.option),
    });
  }

  onSelectColor = (color) => {
    const { selectedIndex, option } = this.state;
    option.values[selectedIndex].value = color.hex;
    option.values[selectedIndex].type = appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE;
    this.setState({
      option,
    });
  };

  fileChangedHandler = (event) => {
    if (event.target.files.length > 0) {
      const { option, selectedIndex } = this.state;

      //   const newOption = produce(option, (draft) => {
      //     draft.values[selectedIndex] = produce(
      //       draft.values[selectedIndex],
      //       (_draft) => {
      //         _draft.type = appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE;
      //       }
      //     );
      //   });

      option.values[selectedIndex].value = "";
      option.values[selectedIndex].type = appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE;

      this.setState({
        option,
      });

      const uploadFile = event.target.files[0];
      const form = baseHelper.getFormDataUpload([uploadFile]);
      if (form) {
        this.props.uploadSwatchImage(form);
      }
    }
  };

  onChangeInputPanelText = (event, index) => {
    const { option } = this.state;
    const { values } = option;

    values[index].name = event.target.value;

    this.setState({
      option,
    });
  };

  onChangeInputVisual = (event, index) => {
    const { option } = this.state;
    const { values } = option;

    values[index].name = event.target.value;

    option.values = values;
    this.setState({
      option,
    });
  };

  removeValue = (index) => {
    const { option } = this.state;
    const { values } = option;

    remove(values, (item, _index) => _index == index);

    option.values = values;
    this.setState({
      option,
    });
  };

  getPanelText = () => {
    const { t, mode } = this.props;
    const { option } = this.state;
    const { values } = option;

    const isModeCreate = mode == appConfig.FORM_MODE.CREATE;

    return (
      <div className="swatch-text">
        {map(values, (item, index) => {
          return (
            <div key={index} className="ladiui form-group">
              <Input value={item.name} onChange={(event) => this.onChangeInputPanelText(event, index)} disabled={!isModeCreate} />
              {isModeCreate && (
                <a onClick={() => this.removeValue(index)} style={{ color: "rgb(109, 109, 109)" }}>
                  <i className="ladi-icon icon-bin" />
                </a>
              )}
            </div>
          );
        })}

        {isModeCreate && (
          // <a
          //   onClick={() => {
          //     values.push({
          //       name: "",
          //     });
          //     option.values = values;
          //     this.setState({
          //       option,
          //     });
          //   }}
          //   className="ladiui button secondary new-variant-option"
          // >
          //   {t("PRODUCTS.ADD_OPTION")}
          // </a>

          <div
            className="ladiui border-none btn-add-option mt-10"
            onClick={() => {
              values.push({
                name: "",
              });
              option.values = values;
              this.setState({
                option,
              });
            }}
          >
            <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
            <div className="btn-add-product"> {t("PRODUCTS.ADD_OPTION")}</div>
          </div>
        )}
      </div>
    );
  };

  getPanelVisual = () => {
    const { t, mode } = this.props;
    const { option } = this.state;
    const { values } = option;

    const isModeCreate = mode == appConfig.FORM_MODE.CREATE;
    const isLoadingUpload = this.props.fileReducer.loading && this.props.fileReducer.waiting == fileTypes.UPLOAD_SWATCH_IMAGE;

    return (
      <div className="visual-swatch">
        {isLoadingUpload && <LoadingScene blur={true} />}
        {map(values, (item, index) => {
          return (
            // <div key={index} className="ladiui form-group">
            <div key={index} className="ladiui form-items flex-row">
              <div className="ladiui item-forms">
                <div className="ladiui btn-group">
                  <Popover
                    wrapperPopoverProps={{ className: "popover-picker" }}
                    content={<ChromePicker color={item.value} onChange={this.onSelectColor} disableAlpha={true} />}
                  >
                    <div id={`picker-id-${index}`}> </div>
                  </Popover>

                  <div className="ladiui dropdown hide-mt ba-c">
                    {/* <a className='ladiui dropdown-toggle' data-toggle="dropdown"> */}
                    <div className="swatch-picker">
                      {isModeCreate ? (
                        <div
                          data-toggle="dropdown"
                          className={`ladiui dropdown-toggle ${
                            item.type == appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE
                              ? " color"
                              : item.type == appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE
                              ? " image"
                              : ""
                          }`}
                          style={item.type == appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE && item.value ? { backgroundColor: item.value } : {}}
                        >
                          {item.type == appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE && item.value && <Image src={item.value} />}
                        </div>
                      ) : (
                        <div
                          data-toggle="dropdown"
                          className={`ladiui dropdown-toggle${
                            item.type == appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE
                              ? " color"
                              : item.type == appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE
                              ? " image"
                              : ""
                          }`}
                          style={item.type == appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE && item.value ? { backgroundColor: item.value } : {}}
                        >
                          {item.type == appConfig.VISUAL_SWATCH_TYPE.IMAGE.CODE && item.value && <Image src={item.value} />}
                        </div>
                      )}
                      <ul className="ladiui dropdown-menu t-40">
                        <li>
                          <a
                            className="ladiui dropdown-item"
                            onClick={() => {
                              this.setState(
                                {
                                  selectedIndex: index,
                                },
                                () => {
                                  document.getElementById(`picker-id-${index}`).click();
                                }
                              );
                            }}
                          >
                            {t("PRODUCTS.SWATCH_PICK_COLOR")}
                          </a>
                        </li>
                        <li>
                          <a
                            className="ladiui dropdown-item"
                            onClick={() => {
                              this.setState(
                                {
                                  selectedIndex: index,
                                },
                                () => {
                                  document.getElementById("upload-visual-swatch").click();
                                }
                              );
                            }}
                          >
                            {t("PRODUCTS.SWATCH_PICK_IMAGE")}
                          </a>
                        </li>
                        <li>
                          <a
                            className="ladiui dropdown-item"
                            onClick={() => {
                              this.setState(
                                {
                                  selectedIndex: index,
                                },
                                () => {
                                  item.type = appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE;
                                  item.value = "";

                                  this.setState({
                                    option,
                                  });
                                }
                              );
                            }}
                          >
                            {t("PRODUCTS.SWATCH_CLEAR")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* </a> */}
                  </div>
                </div>
              </div>

              <Input
                value={item.name}
                onChange={(event) => this.onChangeInputVisual(event, index)}
                disabled={!isModeCreate}
                className="ladiui item-forms"
              />
              {isModeCreate && (
                <a onClick={() => this.removeValue(index)} className="ml-18" style={{ color: "rgb(109, 109, 109)" }}>
                  <i className="ladiui item-forms ladi-icon icon-bin" />
                </a>
              )}
            </div>
          );
        })}

        {isModeCreate && (
          // <a
          //   onClick={() => {
          //     values.push({
          //       name: "",
          //       type: appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE,
          //       value: "",
          //     });
          //     option.values = values;
          //     this.setState({
          //       option,
          //     });
          //   }}
          //   className="ladiui button secondary new-variant-option"
          // >
          //   {t("PRODUCTS.ADD_OPTION")}
          // </a>
          <div
            className="ladiui border-none btn-add-option mt-10"
            onClick={() => {
              values.push({
                name: "",
                type: appConfig.VISUAL_SWATCH_TYPE.COLOR.CODE,
                value: "",
              });
              option.values = values;
              this.setState({
                option,
              });
            }}
          >
            <img className="mr-8" src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg"} alt="" />
            <div className="btn-add-product"> {t("PRODUCTS.ADD_OPTION")}</div>
          </div>
        )}

        <div className="ladiui-button-upload">
          <input
            id="upload-visual-swatch"
            type="file"
            name="file"
            accept="image/*"
            multiple={false}
            onChange={this.fileChangedHandler}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
      </div>
    );
  };

  submit = () => {
    const { option } = this.state;
    const optionReq = produce(option, (draft) => {
      remove(draft.values, (item) => baseHelper.isEmpty(item.name));
      draft.values = compact(draft.values);
    });

    this.props.onSubmit(optionReq);
  };

  render() {
    const { t } = this.props;
    const { option } = this.state;

    return (
      <Modal
        id="modal-adjust"
        title={t("PRODUCTS.EDIT_ATTRIBUTE")}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.submit}
        width={600}
      >
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("PRODUCTS.PICK_OPTION_TYPE")}</label>

          <SelectOption
            items={appConfig.PRODUCT_OPTION_TYPE}
            _key="CODE"
            _value="NAME"
            currentKey={option.type}
            onSelectItem={(item) => {
              option.type = item.CODE;
              this.setState({
                option,
              });
            }}
          />
        </div>

        <div className="ladiui form-group">
          <label className="ladiui-label">{t("PRODUCTS.LIST_ATTRIBUTE")}</label>
          {option.type == appConfig.PRODUCT_OPTION_TYPE.TEXT.CODE && this.getPanelText()}

          {option.type == appConfig.PRODUCT_OPTION_TYPE.VISUAL_SWATCH.CODE && this.getPanelVisual()}
          <div></div>
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadSwatchImage: (form) => dispatch(fileActions.uploadSwatchImage(form)),
  };
};

const mapStateToProps = (state) => ({
  fileReducer: { ...state.file },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(ModalSwatch));
