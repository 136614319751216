/** @format */

import React from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import ItemProduct from './ItemProduct';

import appConfig from '../../../config/app';

function ModalSelectProduct(props) {
    const { t, i18n, showModal, onCancel, userEmail } = props;

    const listProductType = [
        {
            path: '/products/create',
            search: appConfig.PRODUCT_TYPE.PHYSICAL.CODE,
            icon: 'https://w.ladicdn.com/ladiui/ladisales/icons/icon-product.svg',
            name: 'eCommerce',
            des: 'Các sản phẩm thương mại như quần áo, giày dép, mỹ phẩm, trang sức...',
        },
        {
            path: '/services/create',
            search: appConfig.PRODUCT_TYPE.SERVICE.CODE,
            icon: 'https://w.ladicdn.com/ladiui/ladisales/icons/icon-headset.svg',
            name: 'Dịch vụ',
            des: 'Thu phí cho các trải nghiệm có tính chất thời hạn hoặc duy trì.',
        },
        {
            path: '/events/create',
            search: appConfig.PRODUCT_TYPE.EVENT.CODE,
            icon: 'https://w.ladicdn.com/ladiui/ladisales/icons/icon-party.svg',
            name: appConfig.PRODUCT_TYPE.EVENT.NAME,
            des: 'Tạo và quản lý bán vé, đặt trước các sự kiện trực tiếp hoặc sự kiện online.',
        },
        {
            path: '/digital/create',
            search: appConfig.PRODUCT_TYPE.DIGITAL.CODE,
            icon: 'https://w.ladicdn.com/ladiui/ladisales/icons/icon-Ibm-product.svg',
            name: appConfig.PRODUCT_TYPE.DIGITAL.NAME,
            des: 'Các sản phẩm dữ liệu số khách hàng có thể tải về như tài liệu, phần mềm, ebook, bản quyền...',
        },
        {
            path: '/products/create',
            search: appConfig.PRODUCT_TYPE.F_B.CODE,
            icon: 'https://w.ladicdn.com/ladiui/ladisales/icons/icon-cutlery.svg',
            name: appConfig.PRODUCT_TYPE.F_B.NAME,
            des: 'Các sản phẩm thuộc ngành hàng F&B (Ăn uống, thực phẩm...)',
        },
        {
            path: '/products/create',
            search: appConfig.PRODUCT_TYPE.COMBO.CODE,
            icon: 'https://w.ladicdn.com/ladiui/ladisales/icons/icon-box.svg',
            name: 'Combo',
            des: 'Các nhóm sản phẩm bán kèm nhau, giúp gia tăng giá trị đơn hàng và số lượng tiêu thụ.',
        },
        // {
        //   path: "/courses/create",
        //   search: appConfig.PRODUCT_TYPE.COURSE.CODE,
        //   icon: "https://w.ladicdn.com/ladiui/icons/ldicon-topic.svg",
        //   name: appConfig.PRODUCT_TYPE.COURSE.NAME,
        //   des: "Tạo và quản lý nội dung, học viên, tài liệu dành cho khóa học của bạn.",
        // },
    ];

    return (
        <Modal
            id='modal-product-select-type'
            title={t('Bạn muốn thêm sản phẩm nào ?')}
            sub_title='Mỗi sản phẩm sẽ có những đặc tính và tùy chỉnh khác nhau.'
            visible={showModal}
            onCancel={onCancel}
            width={900}
            hasFooter={false}
        >
            <div className='list-product-type'>
                {listProductType.map((item, index) => {
                    return <ItemProduct info={item} key={index} />;
                })}
            </div>
        </Modal>
    );
}

export default withTranslation()(ModalSelectProduct);
