import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Input from '../../../components/Input';
// import Modal from '../../../components/Modal';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import producer from 'immer';

import appConfig from '../../../config/app';
import ModalShowContentTemplateEmail from '../../settings/components/ModalShowContentTemplateEmail';
import ModalShowContentTemplatePrint from '../../settings/components/ModalShowContentTemplatePrint';
// import ModalCreateEmail from './ModalCreateEmail';
import ConfirmModal from '../../../components/ConfirmModal';

import settingActions from '../../../redux/futures/setting/actions';
import * as settingTypes from '../../../redux/futures/setting/types';
import { map, find, includes } from 'lodash';

export default function PageSettingNotification(props) {
    const dispatch = useDispatch();
    let { isShow } = props;
    const settingReducer = useSelector((state) => state.setting);

    // const [emailSelected, setEmailSelected] = useState();

    const [listEmailTemplates, setListEmailTemplates] = useState(appConfig.TEMPLATE_EMAIL_COURSE);
    const [templateEmails, setTemplateEmails] = useState([]);
    const [isShowModalContentEmail, setIsShowModalContentEmail] = useState(false);

    const [contentTemplateEmail, setContentTemplateEmail] = useState('');

    const [selectedTemplateEmail, setSelectedTemplateEmail] = useState(null);

    const [isShowModalContentSprint, setIsShowModalContentSprint] = useState(false);
    const [templateSprints, setTemplateSprints] = useState([]);
    // const [selectedTemplateSprint, setSelectedTemplateSprint] = useState(null);
    const [contentTemplateSprint, setContentTemplateSprint] = useState('');

    useEffect(() => {
        // dispatch(settingActions.listNotificationEmail());
        dispatch(settingActions.listTemplateEmailCourse());
        // dispatch(settingActions.listTemplatePrint());
    }, []);

    useEffect(() => {
        // if (settingReducer.action == settingTypes.SETTING_LIST_NOTIFICATION_EMAIL) {
        //     if (settingReducer.status) {
        //         setListEmails(settingReducer.notification_emails);
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', settingReducer.message);
        //     }
        // }
        // if (settingReducer.action == settingTypes.SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL) {
        //     if (settingReducer.status) {
        //         dispatch(settingActions.listNotificationEmail());
        //         window.LadiUI.toastCustom('success', '', settingReducer.message);
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', settingReducer.message);
        //     }
        // }
        if (settingReducer.action == settingTypes.SETTING_RESTORE_TEMPLATE_EMAIL) {
            if (settingReducer.status) {
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                dispatch(settingActions.listTemplateEmailCourse());
            } else {
                window.LadiUI.toastCustom('danger', '', settingReducer.message);
            }
        }
        if (settingReducer.action == settingTypes.SETTING_CREATE_TEMPLATE) {
            if (settingReducer.status) {
                // setIsShowModalContentEmail(false);
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                let template = settingReducer.template;

                setContentTemplateEmail(template.content);

                setSelectedTemplateEmail(template);

                dispatch(settingActions.listTemplateEmailCourse());
            } else {
                window.LadiUI.toastCustom('danger', '', settingReducer.message);
            }
        }
        if (settingReducer.action == settingTypes.SETTING_LIST_TEMPLATE_EMAIL) {
            if (settingReducer.status) {
                setTemplateEmails(settingReducer.templates);
                let templateEmailsTG = settingReducer.templates || [];

                if (selectedTemplateEmail) {
                    const templateOrder =
                        find(templateEmailsTG, (_item) => _item.store_template_id == selectedTemplateEmail.store_template_id) || {};
                    setContentTemplateEmail(templateOrder.content);
                }
            } else {
                window.LadiUI.toastCustom('danger', '', settingReducer.message);
            }
        }
        if (settingReducer.action == settingTypes.SETTING_UPDATE_TEMPLATE_EMAIL) {
            if (settingReducer.status) {
                setIsShowModalContentEmail(false);
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                dispatch(settingActions.listTemplateEmailCourse());
            } else {
                window.LadiUI.toastCustom('danger', '', settingReducer.message);
            }
        }
        // if (settingReducer.action == settingTypes.SETTING_LIST_TEMPLATE_PRINT) {
        //     if (settingReducer.status) {
        //         setTemplateSprints(settingReducer.templates);
        //         let templateSprintsTg = settingReducer.templates;

        //         if (selectedTemplateSprint) {
        //             const templatePrint =
        //                 find(templateSprintsTg, (_item) => _item.store_template_id == selectedTemplateSprint.store_template_id) || {};
        //             setContentTemplateSprint(templatePrint.content);
        //         }
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', settingReducer.message);
        //     }
        // }
        // if (settingReducer.action == settingTypes.SETTING_UPDATE_TEMPLATE_PRINT) {
        //     if (settingReducer.status) {
        //         setIsShowModalContentSprint(false);
        //         window.LadiUI.toastCustom('success', '', settingReducer.message);
        //         dispatch(settingActions.listTemplatePrint());
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', settingReducer.message);
        //     }
        // }

        // if (settingReducer.action == settingTypes.SETTING_CREATE_NOTIFICATION_EMAIL) {
        //     if (settingReducer.status) {
        //         dispatch(settingActions.listNotificationEmail());
        //         setIsShowModalCreateEmail(false);
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', settingReducer.message);
        //     }
        // }
        // if (settingReducer.action == settingTypes.SETTING_DELETE_NOTIFICATION_EMAIL) {
        //     if (settingReducer.status) {
        //         dispatch(settingActions.listNotificationEmail());
        //         window.LadiUI.closeModal('confirm-delete-email');
        //         window.LadiUI.toastCustom('success', '', settingReducer.message);
        //     } else {
        //         window.LadiUI.toastCustom('danger', '', settingReducer.message);
        //     }
        // }
    }, [settingReducer]);

    useEffect(() => {
        let _emailTemplates = [...listEmailTemplates];
        if (templateEmails && templateEmails.length > 0) {
            map(_emailTemplates, (item) => {
                let findItem = find(templateEmails, (_item) => _item.type == item.CODE);
                if (findItem) {
                    item.is_enable = findItem.is_enable == null || findItem.is_enable > 0 ? true : false;
                } else {
                    item.is_enable = false;
                }
                return item;

            });
        }

        setListEmailTemplates(_emailTemplates);
    }, [templateEmails]);

    // const changeStatus = (emailSelected, status) => {
    //     dispatch(settingActions.changeStatusNotificationEmail(emailSelected, status));
    // };

    // const handleDeleteEmail = (email) => {
    //     setEmailSelected(email);
    //     window.LadiUI.showModal('confirm-delete-email');
    // };

    const onChangeTemplateEmail = (template) => {
        const templateOrder = find(templateEmails, (_item) => _item.type == template.CODE);
        if (templateOrder) {
            let payload = {
                store_template_id: templateOrder.store_template_id,
                is_enable: !template.is_enable,
                content: templateOrder.content,
            };

            dispatch(settingActions.updateTemplateEmail(payload));

            setSelectedTemplateEmail(template);
        } else {
            dispatch(settingActions.createTemplate({ code: template.CODE }));
        }
    };

    const handleSaveContentEmail = () => {
        const templateOrder = find(templateEmails, (_item) => _item.type == selectedTemplateEmail.CODE);
        if (templateOrder) {
            let payload = {
                store_template_id: templateOrder.store_template_id,
                is_enable: selectedTemplateEmail.is_enable,
                content: contentTemplateEmail,
            };

            dispatch(settingActions.updateTemplateEmail(payload));
        }
    };

    const handleShowModalContentEmail = (item) => {
        const templateOrder = find(templateEmails, (_item) => _item.type == item.CODE) || {};
        setContentTemplateEmail(templateOrder.content);
        setIsShowModalContentEmail(true);

        item.store_template_id = templateOrder.store_template_id;
        setSelectedTemplateEmail(item);
    };

    // const handleShowModalContentSprint = (item) => {
    //     const templatePrint = find(templateSprints, (_item) => _item.type == item.CODE) || {};
    //     setContentTemplateSprint(templatePrint.content);
    //     setIsShowModalContentSprint(true);

    //     item.store_template_id = templatePrint.store_template_id;
    //     setSelectedTemplateSprint(item);
    // };

    // const handleSaveContentSprint = () => {
    //     const templatePrint = find(templateSprints, (_item) => _item.type == selectedTemplateSprint.CODE);
    //     if (templatePrint) {
    //         let payload = {
    //             store_template_id: templatePrint.store_template_id,
    //             is_enable: selectedTemplateSprint.is_enable,
    //             content: contentTemplateSprint,
    //         };

    //         dispatch(settingActions.updateTemplatePrint(payload));
    //     }
    // };

    const isLoadingDelete = settingReducer.loading && includes([settingTypes.SETTING_DELETE_NOTIFICATION_EMAIL], settingReducer.waiting);

    return (
        <div className='setting-page setting-page-general page-order' style={isShow ? { display: 'block' } : { display: 'none' }}>
            <div className='page-max'>
                <div >
                    <div className='page-setting-notification-v4'>
                        <div className='header-title'>
                            <h3>{t('SETTINGS.TEMPLATE_EMAIL')}</h3>
                            <p>{t('NOTIFICATION_V4.EMAIL_TEMP_LABLE_SUB')}</p>
                        </div>
                        <div className='content'>
                            <div className='list-blocks'>
                                <div className='line-space-block'></div>
                                <div className='block-item'>

                                    <div className='content-block'>
                                        <div className='table-list-email-template'>
                                            <table className='ladiui table text-left'>
                                                <thead>
                                                    <tr className='ladiui table-vertical'>
                                                        <th scope='col'></th>
                                                        <th scope='col' name='email' className='text-left'>
                                                            {t('NOTIFICATION_V4.TEMP_NAME')}
                                                        </th>
                                                        <th scope='col' name='address' className='text-left'>
                                                            {t('NOTIFICATION_V4.EMAIL_DES')}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {map(listEmailTemplates, (item, index) => {
                                                        return (
                                                            <tr key={index} className='ladiui table-vertical main'>
                                                                <td>
                                                                    <div className='ladiui item-form switch'>
                                                                        <label className='ladiui switch'>
                                                                            <input
                                                                                type='checkbox'
                                                                                checked={item.is_enable || false}
                                                                                onChange={() => onChangeTemplateEmail(item)}
                                                                            />
                                                                            <span className='ladiui slider round'></span>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <a className='cursor-pointer' onClick={() => handleShowModalContentEmail(item)}>
                                                                        {item.NAME}
                                                                    </a>
                                                                </td>
                                                                <td>{item.DESCRIPTION}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <ModalShowContentTemplateEmail
                            visible={isShowModalContentEmail}
                            onCancel={() => {
                                setIsShowModalContentEmail(false);
                            }}
                            onSubmit={handleSaveContentEmail}
                            contentEmail={contentTemplateEmail}
                            selectedTemplateEmail={selectedTemplateEmail}
                            handleChangeEditorValue={(value) => {
                                setContentTemplateEmail(value);
                            }}
                        />
                        {/* <ModalShowContentTemplatePrint
                            visible={isShowModalContentSprint}
                            onCancel={() => {
                                setIsShowModalContentSprint(false);
                            }}
                            onSubmit={handleSaveContentSprint}
                            contentTemplatePrint={contentTemplateSprint}
                            selectedTemplatePrint={selectedTemplateSprint}
                            handleChangeEditorValue={(value) => {
                                setContentTemplateSprint(value);
                            }}
                        /> */}

                        {/* <ModalCreateEmail
                visible={isShowModalCreateEmail}
                onCancel={() => {
                    setIsShowModalCreateEmail(false);
                }}
            /> */}

                        {/* <ConfirmModal
                            id='confirm-delete-email'
                            title={t('SETTINGS.MSG_DELETE_NOTIFICATION_EMAIL')}
                            content={t('SETTINGS.MSG_DELETE_NOTIFICATION_EMAIL_CONTENT', emailSelected)}
                            cancelText={t('ACTIONS.CANCEL')}
                            okText={t('ACTIONS.DELETE')}
                            onOk={() => dispatch(settingActions.deleteNotificationEmail(emailSelected))}
                            isLoading={isLoadingDelete}
                            maxWidth={450}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
