export const LIST_PRODUCT = 'LIST_PRODUCT';
export const LIST_ALL_PRODUCT = 'LIST_ALL_PRODUCT';
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SHOW_PRODUCT = 'SHOW_PRODUCT';
export const COPY_PRODUCT = 'COPY_PRODUCT';
export const RELOAD_PRODUCT = 'RELOAD_PRODUCT';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const LIST_ALL_CUSTOM_FIELD = 'LIST_ALL_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD_BY_ORDER = 'UPDATE_CUSTOM_FIELD_BY_ORDER';
export const IMPORT_PRODUCT = 'IMPORT_PRODUCT';
export const EXPORT_PRODUCT = 'EXPORT_PRODUCT';
export const IMPORT_EVENT = 'IMPORT_EVENT';
export const EXPORT_EVENT = 'EXPORT_EVENT';
export const IMPORT_SERVICE = 'IMPORT_SERVICE';
export const EXPORT_SERVICE = 'EXPORT_SERVICE';
export const SEARCH_PRODUCT_V2 = 'SEARCH_PRODUCT_V2';
