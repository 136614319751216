import i18next from 'i18next';
import appConfig from '../../../../config/app';

export default {
    PAGE_CHECKOUT_MENU: [
        {
            iconClass: 'new-ldicon-paint',
            title: i18next.t('PAGE_CHECKOUT.MENU.WIDGET_CONFIG'),
            sub_title: 'Tùy chỉnh hình ảnh, màu sắc, bố cục trang',
            action: 'WIDGET_CONFIG',
        },
        {
            iconClass: 'new-ldicon-box',
            title: i18next.t('MENU.PRODUCTS'),
            sub_title: i18next.t('PAGE_CHECKOUT_V2.PRODUCT_TITLE_SUB'),
            action: 'PRODUCT_LIST',
        },
        {
            iconClass: 'ldicon-payment',
            title: 'Cấu hình thanh toán',
            sub_title: 'Phương thức thanh toán thông tin đặt hàng',
            action: 'CHECKOUT_CONFIG',
        },
        {
            iconClass: 'new-ldicon-duotone-search',
            title: i18next.t('PAGE_CHECKOUT.MENU.SOCIAL_CONFIG'),
            sub_title: 'Seo & Social',
            action: 'SOCIAL_CONFIG',
        },
        // {
        //     iconClass: 'ldicon-payment',
        //     title: i18next.t('PAGE_CHECKOUT.MENU.SETUP_AFTER_PAYMENT'),
        //     action: 'SETUP_AFTER_PAYMENT',
        // },
    ],
    WIDGET_MENU: [
        {
            iconClass: 'new-ldicon-paint',
            title: i18next.t('MENU.DISPLAY'),
            action: 'GENERAL_SETTINGS',
            types: [appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE, appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'new-ldicon-home',
            title: i18next.t('MENU.DASH_BOARD'),
            action: 'PAGE_HOME_CONFIG',
            types: [appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'new-ldicon-cart',
            title: i18next.t('SALE_CHANNEL.PAGE_CART'),
            action: 'CART',
            types: [appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'ldicon-delivery-truck',
            title: i18next.t('MENU.PAGE_CHECKOUT'),
            action: 'PAYMENT',
            types: [appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE, appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'ldicon-payment',
            title: i18next.t('SALE_CHANNEL.PAGE_THANK'),
            action: 'SETUP_AFTER_PAYMENT',
            types: [appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE, appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'new-ldicon-footer-sticky',
            title: i18next.t('SALE_CHANNEL.PAGE_FOOTER'),
            action: 'SETUP_FOOTER',
            types: [appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
    ],
    WIDGET_PAYMENT_MENU: [
        {
            iconClass: 'new-ldicon-paint',
            title: 'Thông tin khách hàng',
            action: 'WIDGET_CUSTOMER',
            types: [appConfig.PAGE_CHECKOUT.SINGLE_PRODUCT.CODE, appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'new-ldicon-home',
            title: 'Thông tin vận chuyển',
            action: 'WIDGET_SHIPPING',
            types: [appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
        {
            iconClass: 'new-ldicon-cart',
            title: 'Thông tin thanh toán',
            action: 'WIDGET_PAYMENT',
            types: [appConfig.PAGE_CHECKOUT.STORE.CODE],
        },
    ],
    CHECKOUT_CONFIG_MENU: [
        {
            iconClass: 'new-ldicon-user',
            title: i18next.t('PAGE_CHECKOUT.MENU.CUSTOMER_INFO'),
            action: 'CUSTOMER_INFO',
        },
        {
            iconClass: 'ldicon-delivery-truck',
            title: i18next.t('PAGE_CHECKOUT.MENU.SHIPPING_INFO'),
            action: 'SHIPPING_INFO',
        },
        {
            iconClass: 'new-ldicon-bankcard',
            title: i18next.t('PAGE_CHECKOUT.MENU.PAYMENT_METHOD'),
            action: 'PAYMENT_METHOD',
        },
        {
            iconClass: 'new-ldicon-setting',
            title: i18next.t('PAGE_CHECKOUT.MENU.SETTING'),
            action: 'SETTING',
        },
    ],
    CART_ITEM_DEFAULT: [
        {
            iconClass: 'new-ldicon-box',
            title: 'Danh sách sản phẩm',
            action: 'PRODUCT_LIST',
        },
    ],
    PAYMENT_ITEM_DEFAULT: [
        {
            iconClass: 'new-ldicon-user',
            title: 'Thông tin khách hàng',
            action: 'CUSTOMER_INFO',
        },
        {
            iconClass: 'ldicon-delivery-truck',
            title: 'Thông tin vận chuyển',
            action: 'SHIPPING_INFO',
        },
        {
            iconClass: 'new-ldicon-bankcard',
            title: 'Phương thức thanh toán',
            action: 'PAYMENT_METHOD',
        },
    ],
    PAYMENT_MULTIPAGE_ITEM_DEFAULT: [
        {
            iconClass: 'ldicon-delivery-truck',
            title: 'Thông tin thanh toán',
            action: 'PAYMENT_INFO',
        },
    ],
    PAGE_HOME_SECTION_DEFAULT: [],
    BTN_BUY_DEFAULT: {
        title: 'Mua ngay',
        font: 1,
        fontSize: 12,
        boderRadius: 4,
        padding: {
            top: 4,
            right: 8,
            bottom: 4,
            left: 8,
        },
        margin: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
    SETTING_SHOW: [
        {
            value: 'isShowDiscountInput',
            title: 'Hiển thị ô nhập mã giảm giá',
        },
        {
            value: 'isShowDiscountList',
            title: 'Hiển thị danh sách mã giảm giá',
        },
        {
            value: 'isShowShippingMethod',
            title: 'Hiển thị phương thức vận chuyển',
        },
        // {
        //     value: 'isShowStoreInfo',
        //     title: 'Hiển thị thông tin cửa hàng',
        // },
        {
            value: 'isShowOnePageCheckout',
            title: 'Sử dụng giao diện One Page Checkout',
        },
        {
            value: 'isShowInvoice',
            title: 'Hiển thị xuất hoá đơn',
        },
        // {
        //     value: 'isShowCountryOnShipping',
        //     title: 'Hiển thị quốc gia trên địa chỉ vận chuyển',
        // },
        {
            value: 'hidden_multi_national',
            title: 'Ẩn đa quốc gia',
        },
    ],
    GRADIENT_DIRECTION: [
        {
            value: 'to top right',
            icon: 'new-ldicon-arrow-rightup2',
        },
        {
            value: 'to right',
            icon: 'ldicon-arrow-right',
        },
        {
            value: 'to bottom right',
            icon: 'new-ldicon-arrow-rightdown2',
        },
        {
            value: 'to bottom',
            icon: 'ldicon-arrow-down',
        },
        {
            value: 'to bottom left',
            icon: 'new-ldicon-arrow-leftdown2',
        },
        {
            value: 'to left',
            icon: 'ldicon-arrow-left',
        },
        {
            value: 'to top left',
            icon: 'new-ldicon-arrow-leftup2',
        },
        {
            value: 'to top',
            icon: 'ldicon-arrow-up',
        },
    ],
    DNS_PAGE: [
        {
            type: 'HOME_PAGE',
            title: 'Trang chủ cửa hàng',
            endPoint: '/?preview=true',
            icon: 'new-ldicon-home ladiui icon-new',
        },
        // {
        //     type: 'PRODUCT_PAGE',
        //     title: 'Trang sản phẩm',
        //     endPoint: '/?preview=true',
        //     icon: 'new-ldicon-cart ladiui icon-new'
        // },

        {
            type: 'PAGE_CART',
            title: 'Trang giỏ hàng',
            endPoint: '/carts/preview?preview=true',
            icon: 'new-ldicon-cart ladiui icon-new',
        },
        {
            type: 'PAGE_CHECKOUT',
            title: 'Trang thanh toán',
            endPoint: '/checkouts/preview?preview=true',
            icon: 'new-ldicon-bankcard ladiui icon-new',
        },
        // {
        //     type: 'PAGE_THANKYOU',
        //     title: 'Trang cảm ơn',
        //     endPoint: '/thankyou?preview=true',
        // },
    ],
    DNS_PRODUCT: [
        {
            type: 'PRODUCT_PAGE',
            title: 'Trang sản phẩm',
            endPoint: '/?preview=true',
            icon: 'new-ldicon-home ladiui icon-new',
        },

        // {
        //     type: 'PAGE_CART',
        //     title: 'Trang giỏ hàng',
        //     endPoint: '/cart?preview=true',
        // },
        // {
        //     type: 'PAGE_CHECKOUT',
        //     title: 'Trang thanh toán',
        //     endPoint: '/checkout?preview=true',
        // },
        // {
        //     type: 'PAGE_THANKYOU',
        //     title: 'Trang cảm ơn',
        //     endPoint: '/thankyou?preview=true',
        // },
    ],
    DOMAIN_PRODUCT_HEADER_ITEMS: [
        {
            key: 'create',
            name: i18next.t('DOMAIN.ADD'),
            icon: 'icon-c-add',
            className: 'prevent-hide',
            is_header: true,
        },
        {
            key: 'refresh',
            name: i18next.t('COMMON.REFRESH_LIST'),
            icon: 'icon-sync',
            className: 'prevent-hide',
            is_header: true,
        },
        {
            key: 'default',
            name: 'checkout.ladi.sale',
            domain: 'checkout.ladi.sale',
            className: 'prevent-hide',
            is_header: true,
        },
    ],
    DOMAIN_STORE_HEADER_ITEMS: [
        {
            key: 'create',
            name: i18next.t('DOMAIN.ADD'),
            icon: 'icon-c-add',
            className: 'prevent-hide',
            is_header: true,
        },
        {
            key: 'refresh',
            name: i18next.t('COMMON.REFRESH_LIST'),
            icon: 'icon-sync',
            className: 'prevent-hide',
            is_header: true,
        },
    ],

    WIDGET_CUSTOMER_DEFAULT: [
        {
            iconClass: 'new-ldicon-user',
            title: 'Thông tin khách hàng',
            action: 'CUSTOMER_INFO',
        },
    ],
    WIDGET_SHIPPING_DEFAULT: [
        {
            iconClass: 'ldicon-delivery-truck',
            title: 'Thông tin vận chuyển',
            action: 'SHIPPING_INFO',
        },
    ],
    WIDGET_PATMENT_DEFAULT: [
        {
            iconClass: 'new-ldicon-bankcard',
            title: 'Phương thức thanh toán',
            action: 'PAYMENT_METHOD',
        },
    ],
};
