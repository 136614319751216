import React from 'react';
import PropTypes from 'prop-types';

/** Import from third party */
import { cloneDeep, map, includes, find, join, uniq } from 'lodash';

/** Import component from my app */
import Input from '../../../../../components/Input';
import ModalVariant from './ModalVariant';
import ModalEditOption from './ModalEditOption';
import ModalImageVariant from './ImageVariant';

/** Import redux */
import { connect } from 'react-redux';
import * as variantTypes from '../../../../../redux/futures/product_variant/types';
import variantActions from '../../../../../redux/futures/product_variant/actions';

import productActions from '../../../../../redux/futures/product/actions';
import * as productActionTypes from '../../../../../redux/futures/product/types';

import * as productOptionTypes from '../../../../../redux/futures/product_option/types';
import productOptionActions from '../../../../../redux/futures/product_option/actions';

import appConfig from '../../../../../config/app';
import { withTranslation } from 'react-i18next';
import ConfirmModal from '../../../../../components/ConfirmModal';
import NumberInput from '../../../../../components/NumberInput';
import Image from '../../../../../components/Image';
import LoadingScene from '../../../../../components/LoadingScene';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import produce from 'immer';

class PanelEditVariant extends React.Component {
  static propTypes = {
    product: PropTypes.object,
    variants: PropTypes.array,
    options: PropTypes.array,
    images: PropTypes.array,
    openVariantID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  constructor(props) {
    super(props);

    this.state = {
      variants: cloneDeep(this.props.variants) || [],
      options: cloneDeep(this.props.options) || [],

      mode: '',
      isShowModalVariant: false,
      isShowModalEditOption: false,
      selectedVariant: {},
      isShowModalAdjust: false,
      isShowModalImageVariant: false,
    };

    this.formVariantRef = React.createRef();

    this.inputsRef = new Set();
  }

  componentDidMount() {
    if (this.props.openVariantID) {
      this.openModalVariantEdit(this.props.openVariantID);
    }
  }

  componentDidUpdate() {
    window.LadiUI.init();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props == nextProps) {
      return;
    }

    if (this.props.variants != nextProps.variants) {
      const variants = cloneDeep(nextProps.variants);
      this.setState({
        variants,
      });
    }

    if (this.props.options != nextProps.options) {
      const options = cloneDeep(nextProps.options);
      this.setState({
        options,
      });
    }

    if (this.props.variantReducer.action != nextProps.variantReducer.action) {
      if (nextProps.variantReducer.action === variantTypes.CREATE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.setState({
            isShowModalVariant: false,
          });
          this.props.reloadProduct(this.props.product.product_id);
          window.LadiUI.toastCustom('success', '', nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.variantReducer.message, 'OK');
        }
      }

      if (nextProps.variantReducer.action === variantTypes.UPDATE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.props.reloadProduct(this.state.selectedVariant.product_id);

          this.props.reloadProductVariant(this.state.selectedVariant.product_id, this.state.selectedVariant.product_variant_id);
          window.LadiUI.toastCustom('success', '', nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.variantReducer.message, 'OK');
        }
      }

      if (nextProps.variantReducer.action === variantTypes.RE_ORDER_VARIANT) {
        if (nextProps.variantReducer.status) {
          // this.props.reloadProduct(this.state.selectedVariant.product_id);

          // this.props.reloadProductVariant(this.state.selectedVariant.product_id, this.state.selectedVariant.product_variant_id);
          window.LadiUI.toastCustom('success', '', nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.variantReducer.message, 'OK');
        }
      }


      if (nextProps.variantReducer.action === variantTypes.CHANGE_IMAGE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.setState({
            isShowModalImageVariant: false,
          });
          this.props.reloadProduct(this.state.selectedVariant.product_id);
          window.LadiUI.toastCustom('success', '', nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.variantReducer.message, 'OK');
        }
      }

      if (nextProps.variantReducer.action === variantTypes.SHOW_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.setState({
            mode: appConfig.FORM_MODE.EDIT,
            selectedVariant: nextProps.variantReducer.variant,
            isShowModalVariant: true,
          });
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.variantReducer.message, 'OK');
        }
      }

      if (nextProps.variantReducer.action === variantTypes.DELETE_VARIANT) {
        if (nextProps.variantReducer.status) {
          this.props.reloadProduct(this.props.product.product_id);

          window.LadiUI.closeModal('confirm-variant-delete');
          window.LadiUI.toastCustom('success', '', nextProps.variantReducer.message);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.variantReducer.message, 'OK');
        }
      }
    }

    if (this.props.productOption.action != nextProps.productOption.action) {
      if (nextProps.productOption.action == productOptionTypes.CREATE_UPDATE_OPTION) {
        if (nextProps.productOption.status) {
          this.setState({
            isShowModalEditOption: false,
          });
          window.LadiUI.toastCustom('success', '', nextProps.productOption.message);
          this.props.reloadProduct(this.props.product.product_id);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.productOption.message, 'OK');
        }
      }

      if (nextProps.productOption.action == productOptionTypes.DELETE_OPTION) {
        if (nextProps.productOption.status) {
          this.setState({
            isShowModalEditOption: false,
          });
          window.LadiUI.toastCustom('success', '', nextProps.productOption.message);
          window.LadiUI.closeModal('confirm-delete-option');
          window.LadiUI.closeModal('confirm-delete-variant');
          this.props.reloadProduct(this.props.product.product_id);
        } else {
          window.LadiUI.showErrorMessage('Thông báo', nextProps.productOption.message, 'OK');
        }
      }
    }
  }

  /*******************************VARIANTS***************************/
  onChangeVariantInput = (event, index) => {
    const { name, value } = event.target;
    const { variants } = this.state;
    const newVariants = cloneDeep(variants);
    const newVariant = cloneDeep(variants[index]);
    newVariant[name] = value;
    newVariants[index] = newVariant;
    map(newVariants, (newVariant, index) => (newVariant.position = index));

    this.setState({
      variants: newVariants,
    });
  };

  onBlurVariantOption = (event, variantIndex, variantOptionIndex) => {
    let { value } = event.target;
    const { variants } = this.state;
    const variant = variants[variantIndex];
    const variantOption = variant.options[variantOptionIndex];

    if (!value) {
      variantOption.option_value_value.name = variantOption.old_option_value_value_name;
    }

    this.setState({
      variants,
    });
  };

  onChangeVariantOption = (event, variantIndex, variantOptionIndex) => {
    let { value } = event.target;
    const { variants } = this.state;

    const variant = variants[variantIndex];
    const variantOption = variant.options[variantOptionIndex];

    if (variantOption.option_value_value.name) {
      variantOption.old_option_value_value_name = variantOption.option_value_value.name;
    }
    variantOption.option_value_value.name = value;

    this.setState({
      variants,
    });
  };

  // openModalEditOption = () => {
  //   this.setState({
  //     isShowModalEditOption: true,
  //   });
  // };

  openModalVariantCreate = () => {
    const options = cloneDeep(this.state.options);

    map(options, (option) => {
      option.option_name = option.name;
      option.option_value_value = {};
    });

    const selectedVariant = {};
    selectedVariant.product_id = this.props.product.product_id;
    selectedVariant.options = options;

    this.setState({
      selectedVariant,
      mode: appConfig.FORM_MODE.CREATE,
      isShowModalVariant: true,
    });
  };

  openModalVariantEdit = (variantID) => {
    this.setState({
      mode: appConfig.FORM_MODE.EDIT,
    });

    this.props.show(this.props.product.product_id, variantID);
  };

  hideModalVariant = () => {
    this.setState({
      isShowModalVariant: false,
    });
  };

  openModelDelete = (variant) => {
    this.setState({
      selectedVariant: variant,
    });

    window.LadiUI.showModal('confirm-variant-delete');
  };
  /*****************************************************************/

  /*******************************OPTIONS***************************/
  createOption = (data) => {
    this.props.createOption(data);
  };

  deleteOption = (data) => {
    this.props.deleteOption(data);
  };

  openModalImageVariant = (variant) => {
    this.setState({
      selectedVariant: variant,
      isShowModalImageVariant: true,
    });
  };

  changeImage = (image) => {
    this.setState(
      {
        selectedVariant: {
          ...this.state.selectedVariant,
          src: image.src,
          src_hash: image.src_hash,
        },
      },
      () => {
        const updateVariant = {
          product_id: this.state.selectedVariant.product_id,
          variant_id: this.state.selectedVariant.product_variant_id,
          src: this.state.selectedVariant.src,
          src_hash: this.state.selectedVariant.src_hash,
        };
        this.props.changeImage(updateVariant);
      }
    );
  };

  getData = () => {
    const variants = cloneDeep(this.state.variants);

    map(variants, (item, index) => {
      item.quantity = item.rest_quantity;
      item.position = index;
    });
    return {
      variants,
    };
  };

  submit = (variant) => {
    if (this.state.mode === appConfig.FORM_MODE.CREATE) {
      this.props.create(variant);
    } else {
      this.props.update(variant);
    }
  };

  onChangeImage = (variant) => {
    this.props.changeImage(variant);
  };

  onDragEnd = (result) => {
    const { variants } = this.state;
    // dropped outside the list
    if (!result.destination || result.source.index == result.destination.index) {
      return;
    }

    this.setState(
      {
        variants: produce(variants, (draft) => {
          const [removed] = draft.splice(result.source.index, 1);
          draft.splice(result.destination.index, 0, removed);
        }),
      },
      () => {
        this.props.reOrderPackage({
          product_id: this.props.product.product_id,
          product_variant_ids: map(this.state.variants, (item) => item.product_variant_id),
        });
      }
    );
  };

  getVariantName = (variants) => {
    let optionNames = [];
    optionNames = map(variants, (value, key) => value);

    const name = join(optionNames, '/');
    return <span className='variant-option-value'>{name}</span>;
  };

  filteredVariant = () => {
    const { t } = this.props;
    const { variants, options } = this.state;
    const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.props.store.userInfo.currentStore;
    // Kiểm tra xem có variant nào quản lý kho hay không?
    const hasInventoryChecked = find(variants, (item) => item.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE);

    return variants.map((variant, index) => {
      const variantOptions = variant.options;
      const variantOptionNames = [];

      {
        map(variantOptions, (variantOption, variantOptionIndex) => {
          const matched = find(options, (option) => option.option_id == variantOption.option_id);
          if (matched) {
            variantOptionNames.push(variantOption.option_value_value.name);
            // return (
            //   <td key={matched.option_id}>
            //     <Input
            //       value={variantOption.option_value_value.name}
            //       onBlur={(event) => this.onBlurVariantOption(event, index, variantOptionIndex)}
            //       onChange={(event) => this.onChangeVariantOption(event, index, variantOptionIndex)}
            //       allowSpecialChar={false}
            //     />
            //   </td>
            // );
          }
        });
      }

      return (
        <Draggable key={variant.variant_id || index} draggableId={'' + (variant.variant_id || index)} index={index}>
          {(provided, snapshot) => (
            <tr
              className='ladiui table-vertical'
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                userSelect: 'none',
                background: snapshot.isDragging ? '#e8f0fe' : 'none',
                display: 'table-row',
              }}
            >
              <td>
                {/* <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'} /> */}
                <i className='ldicon-drag-drop' />
              </td>
              <td>
                <Image
                  src={variant.src}
                  resize={appConfig.RESIZE.THUMB}
                  width={40}
                  height={40}
                  onClick={() => {
                    this.openModalImageVariant(variant);
                  }}
                />
              </td>
              <td key={index}>{this.getVariantName(variantOptionNames)}</td>
              <td>
                <NumberInput
                  name='price'
                  prefix={currencySymbol}
                  value={variant.price}
                  onChange={(event) => this.onChangeVariantInput(event, index)}
                />
              </td>
              <td>
                <Input
                  ref={(ref) => this.inputsRef.add(ref)}
                  name='sku'
                  value={variant.sku}
                  placeholder='Nhập mã SKU'
                  onChange={(event) => this.onChangeVariantInput(event, index)}
                />
              </td>
              {hasInventoryChecked && variant.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE && (
                <td>
                  <NumberInput
                    name='rest_quantity'
                    value={variant.rest_quantity}
                    onChange={(event) => this.onChangeVariantInput(event, index)}
                    onlyNumber={true}
                    decimalScale={0}
                    thousandSeparator={false}
                    allowNegative={true}
                    placeholder='Nhập số lượng'
                  />
                </td>
              )}

              {hasInventoryChecked && variant.inventory_checked == appConfig.INVENTORY_STATUS.INACTIVE.CODE && (
                <td className='dont-check-inventory'>
                  <span data-tooltip={t('PRODUCTS.DONT_CHECK_INVENTORY')} data-tooltip-position='right'>
                    N/A
                  </span>
                </td>
              )}

              <td>{variant.product_variant_id}</td>

              <td className='text-right pd-0'>
                <div className='ladiui btn-group'>
                  <div className='ladiui dropdown hide-mt ba-c'>
                    <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                      <i className='ladiui icon icon-ldp-dot'></i>
                    </button>
                    <ul className='ladiui dropdown-menu r-0'>
                      <li>
                        <a
                          className='ladiui dropdown-item'
                          onClick={() => this.openModalVariantEdit(variant.product_variant_id)}
                        >
                          {t('ACTIONS.EDIT')}
                        </a>
                      </li>
                      <li>
                        <a className='ladiui dropdown-item' onClick={() => this.openModelDelete(variant)}>
                          {t('ACTIONS.DELETE')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </Draggable>
      );
    });
  };

  render() {
    const { t } = this.props;
    const { variants, options, selectedVariant } = this.state;
    const { currency_code: currencyCode } = this.props.store.userInfo.currentStore;

    // Kiểm tra xem có variant nào quản lý kho hay không?
    const hasInventoryChecked = find(variants, (item) => item.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE);

    const isLoadingInfo = this.props.variantReducer.loading && this.props.variantReducer.waiting == variantTypes.SHOW_VARIANT;
    const isSubmitLoading =
      this.props.variantReducer.loading &&
      includes([variantTypes.CREATE_VARIANT, variantTypes.UPDATE_VARIANT], this.props.variantReducer.waiting);
    const isLoadingSubmitOption =
      this.props.productOption.loading && productOptionTypes.CREATE_UPDATE_OPTION == this.props.productOption.waiting;
    const isLoadingDeleteOption =
      this.props.productOption.loading && productOptionTypes.DELETE_OPTION == this.props.productOption.waiting;
    const isLoadingDeleteVariant =
      this.props.variantReducer.loading && variantTypes.DELETE_VARIANT == this.props.variantReducer.waiting;
    const isLoadingChangeImage =
      this.props.variantReducer.loading && this.props.variantReducer.waiting == variantTypes.CHANGE_IMAGE_VARIANT;

    return (
      <div>
        <ModalEditOption
          ref={this.formEditOptionRef}
          product_id={this.props.product.product_id}
          options={options}
          onCancel={() =>
            this.setState({
              isShowModalEditOption: false,
            })
          }
          onSubmit={this.createOption}
          deleteOption={this.deleteOption}
          loadingDelete={isLoadingDeleteOption}
          loadingDeleteVariant={isLoadingDeleteVariant}
        />
        <div className='block-content-item mt-20'>
          <div className='header-top flex items-center space-between'>
            <h3>Danh sách mẫu mã</h3>
            <div className='info-help flex items-center'>
              <i className='ladi-icon icon-c-question mr-8'></i>
              <span>Tìm hiểu thêm</span>
            </div>
          </div>
          <div className='list-variants'>
            {/* <div className='ladiui-form-group variant-option-head'>
              <span className='ml-0 mb-0'>{t('PRODUCTS.VARIANT_LIST')}</span>
            </div> */}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId='droppable'>
                {(provided, snapshot) => (
                  <div className='ladi-card block-table-list-variants'>
                    {variants.length > 0 && (
                      <table
                        className='ladiui table panel-package table-custom'
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDragging ? '#e8f0fe' : 'none',
                        }}
                      >
                        <thead>
                          <tr className='ladiui table-vertical'>
                            <td colSpan={2} />
                            <th className='text-left'>Biến thể</th>
                            {/* ))} */}
                            <th className='text-left'>{t('PRODUCTS.PRICE')}</th>
                            <th className='text-left'>{t('PRODUCTS.SKU')}</th>
                            {hasInventoryChecked && <th className='text-left'>{t('COMMON.QUANTITY')}</th>}
                            <th className='text-left'>{t('PRODUCTS.ID')}</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {this.filteredVariant()}
                          {provided.placeholder}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className='ladiui border-none btn-add-option mt-12' onClick={this.openModalVariantCreate}>
            <img className='mr-8' src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg'} alt='' />
            <div className='btn-add-product'> {t('PRODUCTS.ADD_VARIANT')}</div>
          </div>
        </div>

        {this.state.isShowModalVariant && (
          <ModalVariant
            ref={this.formVariantRef}
            mode={this.state.mode}
            currentVariantInfo={this.state.selectedVariant}
            images={this.props.images}
            onCancel={this.hideModalVariant}
            onSubmit={this.submit}
            onChangeImage={this.changeImage}
            isLoading={isSubmitLoading}
            visible={this.state.isShowModalVariant}
          />
        )}

        {this.state.isShowModalImageVariant && (
          <ModalImageVariant
            variant={selectedVariant}
            visible={this.state.isShowModalImageVariant}
            images={this.props.images}
            onCancel={() =>
              this.setState({
                isShowModalImageVariant: false,
              })
            }
            submit={this.changeImage}
            isLoading={isLoadingChangeImage}
          />
        )}

        <ConfirmModal
          id='confirm-variant-delete'
          title={t('PRODUCTS.MSG_DELETE_PRODUCT_VARIANT_TITLE')}
          content={t('PRODUCTS.MSG_DELETE_PRODUCT_VARIANT_CONTENT', this.state.selectedVariant.title)}
          cancelText={t('ACTIONS.CANCEL')}
          okText={t('ACTIONS.DELETE')}
          onOk={() => this.props.delete(this.props.product.product_id, this.state.selectedVariant.product_variant_id)}
          isLoading={isLoadingDeleteVariant}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    create: (variant) => dispatch(variantActions.create(variant)),
    update: (variant) => dispatch(variantActions.update(variant)),
    changeImage: (variant) => dispatch(variantActions.changeImage(variant)),
    show: (productID, variantID) => dispatch(variantActions.show(productID, variantID)),
    reloadProductVariant: (productID, variantID) => dispatch(variantActions.show(productID, variantID)),
    delete: (productID, variantID) => dispatch(variantActions.delete(productID, variantID)),
    reloadProduct: (productID) => dispatch(productActions.reload(productID)),
    createOption: (data) => dispatch(productOptionActions.createAndUpdate(data)),
    deleteOption: (data) => dispatch(productOptionActions.delete(data)),
    reOrderPackage: (data) => dispatch(variantActions.reOrder(data)),
  };
};

const mapStateToProps = (state) => ({
  variantReducer: { ...state.variant },
  store: { ...state.store },
  productOption: { ...state.productOption },
  productActions: { ...state.product },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation('translation', { withRef: true })(PanelEditVariant));
