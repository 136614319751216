import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import Dropdown from '../../../../../components/Dropdown';
import Modal from '../../../../../components/Modal';
import appConfig from '../../../../../config/app';

type Props = {};

class ModalShippingRate extends React.Component<Props> {

    static propTypes = {
        visible: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        isLoading: PropTypes.bool,
        _states: PropTypes.array,
    }

    constructor(props) {
        super(props);
        this.state = {
            _states: [],
            state_id: '',
            state_name: '',
        }
    }

    componentWillMount() {
        const _states = cloneDeep(this.props._states);
        _states.unshift({
            country_code: 'VN',
            state_id: appConfig.OTHER_STATE,
            name: this.props.t('SHIPPING_RATES.OTHER_STATE'),
        });
        this.setState({
            _states,
            state_id: _states ? _states[0].state_id : '',
            state_name: _states ? _states[0].name : '',
        });
    }

    componentDidMount() {
        window.LadiUI.init();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    selectItem = (selectedItem) => {
        this.setState({
            state_id: selectedItem.state_id,
            state_name: selectedItem.name,
        });
    }

    submit = () => {
        this.props.onSubmit({
            country_code: 'VN',
            state_id: this.state.state_id,
            state_name: this.state.state_name,
        });
    }

    render() {
        const { t } = this.props;

        return (
            <Modal
                id='modal-shipping-rate'
                title={t('SHIPPING_RATES.ADD_SHIPPING_RATE')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                isLoading={this.props.isLoading}
                width={350}
                bodyClassName="unset-modal"
            >
                <Dropdown
                    data={this.state._states}
                    currentKey={this.state.state_id}
                    currentValue={this.state.state_name}
                    onSelectItem={this.selectItem}
                    _key={'state_id'}
                    _value={'name'}
                />
            </Modal>
        )
    }
}

export default (withTranslation('translation', { withRef: true })(ModalShippingRate));