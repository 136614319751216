import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import appConfig, { endpoint } from '../../../config/app';


const list = () => ({
    type: types.API_LIST_THIRD_PARTY,
    meta: {
        endpoint: endpoint.API_LIST_THIRD_PARTY,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const reload = () => ({
    type: types.API_RELOAD_THIRD_PARTY,
    meta: {
        endpoint: endpoint.API_LIST_THIRD_PARTY,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const revoke = (apiKeyID) => ({
    type: types.API_REVOKE_TOKEN,
    meta: {
        endpoint: endpoint.API_REVOKE_TOKEN,
        method: REQUEST_METHOD.POST,
        body: {
            api_key_id: apiKeyID,
        },
        hasAuth: true,
    },
});

const resetApiKey = (apiKeyID) => ({
    type: types.API_RESET_API_KEY,
    meta: {
        endpoint: endpoint.API_RESET_API_KEY,
        method: REQUEST_METHOD.POST,
        body: {
            api_key_id: apiKeyID,
        },
        hasAuth: true,
    },
});

export default {
    list,
    reload,
    revoke,
    resetApiKey
}
