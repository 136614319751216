import config from './config/config';
import appConfig from './config/app';
import baseHelper from './helpers/BaseHelper';
import io from "socket.io-client";

class Socket {
    init(storeID) {
        this.socket = io
        .connect(config.API_V2_0.replace(appConfig.VER, ''), { transports: ['websocket', 'polling'], query: { authorization: baseHelper.getCookie(appConfig.COOKIE.SSID), store_id: storeID } })
        .connect(config.WS_API_CHECKOUT_V1_0, { transports: ['websocket', 'polling'], query: { authorization: baseHelper.getCookie(appConfig.COOKIE.SSID), store_id: storeID } });
        this.socket.on('connect', function () {
            console.log('connected');
        });
    }

    getInstance() {
        if (!this.socket) {
            const storeID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
            this.init(storeID);
        }

        return this.socket;
    }
}

export default new Socket();
