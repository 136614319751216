export const LIST_DISCOUNT = "LIST_DISCOUNT";
export const DELETE_DISCOUNTS = "DELETE_DISCOUNTS";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const SHOW_DISCOUNT = "SHOW_DISCOUNT";
export const RELOAD_DISCOUNT = "RELOAD_DISCOUNT";
export const VALIDATE_DISCOUNT = "VALIDATE_DISCOUNT";
export const ACTIVE_DISCOUNT = "ACTIVE_DISCOUNT";
export const EXPIRE_DISCOUNT = "EXPIRE_DISCOUNT";
export const EXPORT_DISCOUNT = "EXPORT_DISCOUNT";
export const EXPORT_TRANSACTION = "EXPORT_TRANSACTION";
