import * as types from "./types";
import * as commonTypes from "../common/types";
import appConfig from "../../../config/app";

export default (state = { shipping: {}, services: [] }, action) => {
  switch (action.type) {
    case commonTypes.ASYNC_START: {
      if (!types[action.waiting]) {
        return state;
      }

      return {
        ...state,
        loading: true,
        waiting: action.waiting,
        action: commonTypes.ASYNC_START,
      };
    }
    case commonTypes.ASYNC_END: {
      if (!types[action.done]) {
        return state;
      }

      return {
        ...state,
        waiting: null,
        loading: false,
        action: commonTypes.ASYNC_END,
      };
    }
    case types.LIST_SHIPPING: {
      return {
        ...state,
        action: types.LIST_SHIPPING,
        shippings: action.status ? action.payload.shippings : [],
        totalRecord: action.status ? action.payload.total_record : 0,
        totalPage: action.status ? action.payload.total_page : 0,
        total: action.status ? action.payload.total : 0,
        status: action.status,
        message: action.message,
      };
    }
    case types.DELETE_SHIPPINGS: {
      return {
        ...state,
        action: types.DELETE_SHIPPINGS,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHOW_SHIPPING: {
      return {
        ...state,
        action: types.SHOW_SHIPPING,
        status: action.status,
        message: action.message,
        shipping: action.status ? action.payload.shipping : {},
      };
    }
    case types.DELETE_SHIPPING: {
      return {
        ...state,
        action: types.DELETE_SHIPPING,
        status: action.status,
        message: action.message,
      };
    }
    case types.CREATE_SHIPPING: {
      return {
        ...state,
        action: types.CREATE_SHIPPING,
        status: action.status,
        message: action.message,
      };
    }
    case types.UPDATE_SHIPPING: {
      return {
        ...state,
        action: types.UPDATE_SHIPPING,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_LIST_PARTNERS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_PARTNERS,
        status: action.status,
        message: action.message,
        partners: action.status ? action.payload.partners : [],
        hubs: [],
        otherHubs: [],
        loadedOtherHub: false,
        ghnHubs: [],
        loadedGhnHub: false,
        ghtkHubs: [],
        loadedGhtkHub: false,
      };
    }
    case types.SHIPPING_LIST_HUBS: {
      const partnerCode = action.meta.body.shipping_partner_code;
      let otherHubs = state.otherHubs;
      let loadedOtherHub = state.loadedOtherHub;
      let ghnHubs = state.ghnHubs;
      let ghnShifts = state.ghnShifts;
      let loadedGhnHub = state.loadedGhnHub;
      let ghtkHubs = state.ghtkHubs;
      let vnPostHubs = state.vnPostHubs;
      let vtPostHubs = state.vtPostHubs;

      let loadedGhtkHub = state.loadedGhtkHub;
      let loadedVnPostHub = state.loadedVnPostHub;
      let loadedVtPostHub = state.loadedVtPostHub;
      const hubs = action.status ? action.payload.hubs : [];
      const shifts = action.status ? action.payload.shifts : [];
      switch (partnerCode) {
        case appConfig.SHIPPING_PARTNERS.OTHER.CODE:
          loadedOtherHub = true;
          otherHubs = hubs;
          break;
        case appConfig.SHIPPING_PARTNERS.GHN.CODE:
          loadedGhnHub = true;
          ghnHubs = hubs;
          ghnShifts = shifts;
          break;
        case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
          loadedGhtkHub = true;
          ghtkHubs = hubs;
          break;
        case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
          loadedVnPostHub = true;
          vnPostHubs = hubs;
        case appConfig.SHIPPING_PARTNERS.VTPOST.CODE:
          loadedVtPostHub = true;
          vtPostHubs = hubs;
          break;
      }

      return {
        ...state,
        action: types.SHIPPING_LIST_HUBS,
        status: action.status,
        message: action.message,
        hubs: action.status ? action.payload.hubs : [],
        otherHubs,
        loadedOtherHub,
        ghnHubs,
        ghnShifts,
        loadedGhnHub,
        ghtkHubs,
        loadedGhtkHub,
        vnPostHubs,
        loadedVnPostHub,
        vtPostHubs,
        loadedVtPostHub,
      };
    }
    case types.SHIPPING_LIST_OTHER_HUBS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_OTHER_HUBS,
        hubs: state.otherHubs,
        status: true,
      };
    }
    case types.SHIPPING_LIST_GHN_HUBS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_GHN_HUBS,
        hubs: state.ghnHubs,
        status: true,
      };
    }
    case types.SHIPPING_LIST_GHTK_HUBS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_GHTK_HUBS,
        hubs: state.ghtkHubs,
        status: true,
      };
    }
    case types.SHIPPING_LIST_VNPOST_HUBS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_VNPOST_HUBS,
        hubs: state.vnPostHubs,
        status: true,
      };
    }
    case types.SHIPPING_LIST_VTPOST_HUBS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_VTPOST_HUBS,
        hubs: state.vtPostHubs,
        status: true,
      };
    }
    case types.SHIPPING_LIST_SERVICES: {
      return {
        ...state,
        action: types.SHIPPING_LIST_SERVICES,
        status: action.status,
        message: action.message,
        services: action.status ? action.payload.services : [],
      };
    }
    case types.SHIPPING_RESET_SERVICES: {
      return {
        ...state,
        action: types.SHIPPING_RESET_SERVICES,
        status: true,
        message: "",
        services: [],
        fee: "",
      };
    }
    case types.SHIPPING_RESET_FEE: {
      return {
        ...state,
        action: types.SHIPPING_RESET_FEE,
        status: true,
        message: "",
        fee: "",
      };
    }
    case types.SHIPPING_GET_FEE: {
      return {
        ...state,
        action: types.SHIPPING_GET_FEE,
        status: action.status,
        message: action.message,
        fee: action.status ? action.payload.fee : "",
      };
    }
    case types.SHIPPING_CANCEL: {
      return {
        ...state,
        action: types.SHIPPING_CANCEL,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_UPDATE_FEE: {
      return {
        ...state,
        action: types.SHIPPING_UPDATE_FEE,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_UPDATE_TRACKING_NUMBER: {
      return {
        ...state,
        action: types.SHIPPING_UPDATE_TRACKING_NUMBER,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_UPDATE_STATUS: {
      return {
        ...state,
        action: types.SHIPPING_UPDATE_STATUS,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_UPDATE_PAYMENT_STATUS: {
      return {
        ...state,
        action: types.SHIPPING_UPDATE_PAYMENT_STATUS,
        status: action.status,
        message: action.message,
      };
    }
    case types.SHIPPING_LIST_STATIONS: {
      return {
        ...state,
        action: types.SHIPPING_LIST_STATIONS,
        stations: action.status ? action.payload.stations : [],
        status: action.status,
        message: action.message,
      };
    }
    default:
      return state;
  }
};
