import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const create = (address) => ({
    type: types.CREATE_STORE_ADDRESS,
    meta: {
        endpoint: endpoint.STORE_ADDRESS_CREATE,
        method: REQUEST_METHOD.POST,
        body: {
            address,
        },
        hasAuth: true,
    }
});

const update = (address) => ({
    type: types.UPDATE_STORE_ADDRESS,
    meta: {
        endpoint: endpoint.STORE_ADDRESS_UPDATE,
        method: REQUEST_METHOD.POST,
        body: {
            address,
        },
        hasAuth: true,
    }
});

const deleteAddress = (addressID) => ({
    type: types.DELETE_STORE_ADDRESS,
    meta: {
        endpoint: endpoint.STORE_ADDRESS_DELETE,
        method: REQUEST_METHOD.POST,
        body: {
            store_address_id: addressID,
        },
        hasAuth: true,
    }
});

const list = () => ({
    type: types.LIST_STORE_ADDRESS,
    meta: {
        endpoint: endpoint.STORE_ADDRESS_LIST,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});
export default {
    create,
    update,
    delete: deleteAddress,
    list,
}