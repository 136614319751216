import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";

ModalGHTK.propTypes = {
  onCancel: PropTypes.func,
  paymentGatewayConfigured: PropTypes.any,
  ghtkAccount: PropTypes.any,
  isSubmitLoading: PropTypes.bool,
};

export default function ModalGHTK(props) {
  const {
    paymentGatewayConfigured,
    ghtkAccount,
    isSubmitLoading,
    onCancel,
    onChangeGHTKInput,
    ghtkMode,
    connect,
    inputsGHTKRef,
    setGHTKModal,
    logo,
  } = props;
  const { t } = useTranslation();

  return (
    <Modal
      id="modal-ghtk"
      title={!paymentGatewayConfigured ? t("SHIPPINGS.SET_UP_TRANSPORT") : t("SHIPPINGS.DETAIL_TRANSPORT")}
      onCancel={onCancel}
      hasFooter={true}
      onOk={() => connect(appConfig.SHIPPING_PARTNERS.GHTK.CODE)}
      visible={true}
      isActiveBtnSaveDate={paymentGatewayConfigured ? true : false}
      width={600}
      logo={logo}
      bodyStyles={{ minHeight: "30vh" }}
    >
      <div className="content">
        {paymentGatewayConfigured ? (
          <React.Fragment>
            <div className="partner-account">
              <div className="ladiui form-group">
                <label className="ladiui-label">{t("SETTINGS.GHTK_SHOP_CODE")}</label>
                <Input value={paymentGatewayConfigured.config.code} disabled={true} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <div className="partner-account">
                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("COMMON.EMAIL")}</label>
                  <Input
                    ref={(ref) => inputsGHTKRef.add(ref)}
                    value={ghtkAccount.email}
                    validationName={t("COMMON.EMAIL")}
                    name="email"
                    onChange={(event) => onChangeGHTKInput(event)}
                    validations={{ isRequired: true, email: true }}
                  />
                </div>

                <div className="ladiui form-group">
                  <label className="ladiui-label">{t("COMMON.PASSWORD")}</label>
                  <Input
                    ref={(ref) => inputsGHTKRef.add(ref)}
                    name="password"
                    type="password"
                    value={ghtkAccount.password}
                    validationName={t("COMMON.PASSWORD")}
                    onChange={(event) => onChangeGHTKInput(event)}
                    validations={{ isRequired: true }}
                  />
                </div>
              </div>

              {baseHelper.isDevelopEnv() && (
                <div className="ladiui form-group flex">
                  <input
                    id="ghtk_mode"
                    type="checkbox"
                    className="ladiui checkbox size-checkbox form-check-input-checkbox mr-8"
                    onChange={setGHTKModal}
                    checked={ghtkMode == appConfig.MODE.TEST}
                  />
                  <label htmlFor="ghtk_mode"> {t("MODE.TEST")}</label>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
}
