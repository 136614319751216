import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Input from './Input';
import baseHelper from '../helpers/BaseHelper';
import ValidatorHelper from '../helpers/ValidatorHelper';
import SelectOption from './SelectOption';
import { filter, find, isEmpty } from 'lodash';
import appConfig from '../config/app';
import config from '../config/config';

import Dropdown from './Dropdown';
import DatePicker from './DatePicker/CustomDatePicker';

import { compact, map } from 'lodash';
import LoadingScene from './LoadingScene';
import produce from 'immer';

import moment from 'moment';
import TimePicker from 'react-time-picker';

// import TimeInput from 'react-time-picker-input';

import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

// import 'react-time-picker-input/dist/components/TimeInput.css';

const LIST_FLASH_SALE = [
    {
        code: 'Minute',
        name: 'Phút',
    },
    {
        code: 'TimeOut',
        name: 'Thời gian kết thúc',
    },
    {
        code: 'Daily',
        name: 'Hằng ngày',
    },
];

const PanelPageProduct = forwardRef((props, ref) => {
    let date_start = new Date();
    let time = date_start.getTime();
    let time_last = time + 7 * 24 * 60 * 60 * 1000;
    let date_end = new Date(time_last);

    const [dataConfigGeneral, setDataConfigGeneral] = useState({
        quantity_sold: 0,
    });
    const [configHighlight, setConfigHighLight] = useState({
        type: 'NOT_USE',
        title: '',
        config: {
            type: 'Daily',
            start: '00:00',
            end: '00:00',
            end_date_time: new Date(),
            minute: '',
            is_show_day: true,
            is_show_hour: true,
            is_show_minute: true,
            is_show_second: true,
        },
    });
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const inputsRef = new Set();

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {
                ...dataConfigGeneral,
                highlight_display: {
                    ...configHighlight,
                },
            };
        },
    }));

    useEffect(() => {
        if (props.highlightDisplay && !isEmpty(props.highlightDisplay)) {
            setConfigHighLight({
                ...props.highlightDisplay,
                config: {
                    ...props.highlightDisplay.config,
                    start: props.highlightDisplay.config.start ? props.highlightDisplay.config.start : null,
                    end: props.highlightDisplay.config.end ? props.highlightDisplay.config.end : null,
                    end_date_time: props.highlightDisplay.config.end_date_time
                        ? new Date(props.highlightDisplay.config.end_date_time)
                        : new Date(),
                },
            });
        }
    }, [props.highlightDisplay]);

    useEffect(() => {
        if (props.quantitySold) {
            setDataConfigGeneral({
                ...dataConfigGeneral,
                quantity_sold: props.quantitySold ? props.quantitySold : 0,
            });
        }
    }, [props.quantitySold]);

    const onChangeDateTime = (value, name) => {
        setConfigHighLight({
            ...configHighlight,
            config: {
                ...configHighlight.config,
                [name]: value,
            },
        });
    };

    const handleChangeConfigGeneral = (event) => {
        let { name, value } = event.target;

        setDataConfigGeneral((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className='product-highlight'>
            <div className='ladiui form-group mt-0'>
                <label className='ladiui-label'>Số lượng đã bán</label>
                <Input
                    type='number'
                    name='quantity_sold'
                    ref={(ref) => inputsRef.add(ref)}
                    placeholder={t('SEO_SOCIAL.ENTER_TITLE')}
                    value={dataConfigGeneral.quantity_sold}
                    onChange={(event) => handleChangeConfigGeneral(event)}
                />
                <span style={{ fontSize: '12px', marginTop: '8px', display: 'block' }}>
                    Số lượng sản phẩm đã bán sẽ hiển thị trên trang bán hàng
                </span>
            </div>
            {[appConfig.PRODUCT_TYPE.COMBO.CODE, appConfig.PRODUCT_TYPE.F_B.CODE, appConfig.PRODUCT_TYPE.PHYSICAL.CODE].includes(
                props.productType
            ) && (
                <div className='highlight-content mt-20'>
                    <h3 className='ladiui-label'>Thiết lập vị trí hiển thị nổi bật</h3>
                    <div className='ladiui form-group group-type-highlight'>
                        <div className='flex'>
                            <input
                                id='radioHighLight'
                                type='radio'
                                className='ladiui form-check-input'
                                name='radio-group'
                                value={configHighlight.type}
                                onChange={() => {
                                    setConfigHighLight({
                                        ...configHighlight,
                                        type: 'NOT_USE',
                                    });
                                }}
                                checked={configHighlight.type == 'NOT_USE'}
                            />
                            <label htmlFor='radioShippingCustom'>Không sử dụng</label>
                        </div>
                        <div className='flex'>
                            <input
                                id='radioHighLight'
                                type='radio'
                                className='ladiui form-check-input'
                                name='radio-group'
                                value={configHighlight.type}
                                onChange={() => {
                                    setConfigHighLight({
                                        ...configHighlight,
                                        type: 'FLASH_SALE',
                                    });
                                }}
                                checked={configHighlight.type == 'FLASH_SALE'}
                            />
                            <label htmlFor='radioShippingCustom'>Flash Sale</label>
                        </div>
                        <div className='flex'>
                            <input
                                id='radioHighLight'
                                type='radio'
                                className='ladiui form-check-input'
                                name='radio-group'
                                value={configHighlight.type}
                                onChange={() => {
                                    setConfigHighLight({
                                        ...configHighlight,
                                        type: 'DISCOUNT_CUSTOM',
                                    });
                                }}
                                checked={configHighlight.type == 'DISCOUNT_CUSTOM'}
                            />
                            <label htmlFor='radioShippingCustom'>Khuyến mãi tùy chỉnh</label>
                        </div>
                    </div>

                    <div className='block-content-highlight'>
                        {configHighlight.type == 'FLASH_SALE' && (
                            <>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>Tiêu đề Flash Sale</label>
                                    <Input
                                        ref={(ref) => inputsRef.add(ref)}
                                        placeholder={'Nhập tiêu đề'}
                                        value={configHighlight.title}
                                        onChange={(event) => {
                                            setConfigHighLight({
                                                ...configHighlight,
                                                title: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>Kiểu Flash Sale</label>
                                    <Dropdown
                                        id='platform'
                                        data={LIST_FLASH_SALE}
                                        onSelectItem={(selected) => {
                                            setConfigHighLight({
                                                ...configHighlight,
                                                config: {
                                                    ...configHighlight.config,
                                                    type: selected.code,
                                                },
                                            });
                                        }}
                                        currentKey={configHighlight.config.type}
                                        _key={'code'}
                                        _value={'name'}
                                        ref={(ref) => inputsRef.add(ref)}
                                        placeHolder={t('Chọn kiểu')}
                                    />
                                </div>
                                {configHighlight.config.type == 'Daily' && (
                                    <div className='flas-sale-dayly'>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label'>Bắt đầu lúc</label>
                                            <div className='product-time-picker'>
                                                <TimePicker
                                                    onChange={(value) => {
                                                        setConfigHighLight({
                                                            ...configHighlight,
                                                            config: {
                                                                ...configHighlight.config,
                                                                start: value,
                                                            },
                                                        });
                                                    }}
                                                    value={configHighlight.config.start}
                                                    // disableClock={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label'>Kết thúc lúc</label>
                                            <div className='product-time-picker'>
                                                <TimePicker
                                                    onChange={(value) => {
                                                        setConfigHighLight({
                                                            ...configHighlight,
                                                            config: {
                                                                ...configHighlight.config,
                                                                end: value,
                                                            },
                                                        });
                                                    }}
                                                    value={configHighlight.config.end}
                                                    // disableClock={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {configHighlight.config.type == 'TimeOut' && (
                                    <>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label'>Kết thúc lúc</label>
                                            <div style={{ width: '100%' }}>
                                                <DatePicker
                                                    name='end_date_time'
                                                    minDate={baseHelper.momentToDate(moment())}
                                                    selected={configHighlight.config.end_date_time}
                                                    onChange={(value) => onChangeDateTime(value, 'end_date_time')}
                                                    showTimeSelect
                                                    timeFormat={appConfig.DEFAUT_TIME}
                                                    timeIntervals={15}
                                                    dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                    timeCaption='Time'
                                                    customPlaceholder='Chọn thời gian'
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {configHighlight.config.type == 'Minute' && (
                                    <>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label'>Số phút chạy</label>
                                            <Input
                                                type='number'
                                                ref={(ref) => inputsRef.add(ref)}
                                                placeholder={'Nhập số phút'}
                                                value={configHighlight.config.minute}
                                                onChange={(event) => {
                                                    setConfigHighLight({
                                                        ...configHighlight,
                                                        config: {
                                                            ...configHighlight.config,
                                                            minute: event.target.value,
                                                        },
                                                    });
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className='group-show-time'>
                                    <div className='flex'>
                                        <div className='ladiui item-form switch'>
                                            <label className='ladiui switch m-0'>
                                                <input
                                                    type='checkbox'
                                                    onChange={(event) => {
                                                        setConfigHighLight({
                                                            ...configHighlight,
                                                            config: {
                                                                ...configHighlight.config,
                                                                is_show_day: !configHighlight.config.is_show_day,
                                                            },
                                                        });
                                                    }}
                                                    checked={configHighlight.config.is_show_day}
                                                />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        <div className='ml-8'>
                                            <span className='ladiui title-text-form-lever2 mb-0'>Hiện ngày</span>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='ladiui item-form switch'>
                                            <label className='ladiui switch m-0'>
                                                <input
                                                    type='checkbox'
                                                    onChange={(event) => {
                                                        setConfigHighLight({
                                                            ...configHighlight,
                                                            config: {
                                                                ...configHighlight.config,
                                                                is_show_hour: !configHighlight.config.is_show_hour,
                                                            },
                                                        });
                                                    }}
                                                    checked={configHighlight.config.is_show_hour}
                                                />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        <div className='ml-8'>
                                            <span className='ladiui title-text-form-lever2 mb-0'>Hiện giờ</span>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='ladiui item-form switch'>
                                            <label className='ladiui switch m-0'>
                                                <input
                                                    type='checkbox'
                                                    onChange={(event) => {
                                                        setConfigHighLight({
                                                            ...configHighlight,
                                                            config: {
                                                                ...configHighlight.config,
                                                                is_show_minute: !configHighlight.config.is_show_minute,
                                                            },
                                                        });
                                                    }}
                                                    checked={configHighlight.config.is_show_minute}
                                                />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        <div className='ml-8'>
                                            <span className='ladiui title-text-form-lever2 mb-0'>Hiện phút</span>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='ladiui item-form switch'>
                                            <label className='ladiui switch m-0'>
                                                <input
                                                    type='checkbox'
                                                    onChange={(event) => {
                                                        setConfigHighLight({
                                                            ...configHighlight,
                                                            config: {
                                                                ...configHighlight.config,
                                                                is_show_second: !configHighlight.config.is_show_second,
                                                            },
                                                        });
                                                    }}
                                                    checked={configHighlight.config.is_show_second}
                                                />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        <div className='ml-8'>
                                            <span className='ladiui title-text-form-lever2 mb-0'>Hiện giây</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {configHighlight.type == 'DISCOUNT_CUSTOM' && (
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Tiêu đề khuyến mãi</label>
                                <Input
                                    ref={(ref) => inputsRef.add(ref)}
                                    placeholder={'Nhập tiêu đề'}
                                    value={configHighlight.title}
                                    onChange={(event) => {
                                        setConfigHighLight({
                                            ...configHighlight,
                                            title: event.target.value,
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
});

PanelPageProduct.propTypes = {};
export default PanelPageProduct;
