import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import productVariantAction from '../../../redux/futures/product_variant/actions';

import * as ProductTagTypes from '../../../redux/futures/product_tag/types';
import ProductTagActions from '../../../redux/futures/product_tag/actions';
// import ModalOrderTagCreate from './ModalOrderTagCreate';

// eslint-disable-next-line max-lines-per-function
export const ProductTags = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { productTag } = props;

    const storeReducer = useSelector((state) => state.store);
    const productTagReducer = useSelector((state) => state.productTag);

    const tags = useMemo(() => {
        return productTagReducer.productTags || [];
    }, [productTagReducer.productTags]);

    const [tagSelected, setTagSelected] = useState([]);

    const [searchValue, setSearchValue] = useState('');

    const [isShowModalCreate, setShowModalCreate] = useState(false);
    const [isShowList, setShowList] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            // return tagSelected.map((item) => item.name);
            return tagSelected;
        },
    }));

    // useEffect(() => {
    //     dispatch(ProductTagActions.search(''));
    // }, []);

    useEffect(() => {
        let getData = setTimeout(() => {
            tagSearch();
        }, 800);
        return () => clearTimeout(getData);
    }, [searchValue]);

    useEffect(() => {
        let tagTG = productTag || [];
        tagTG = tagTG.map((item) => item.name);
        setTagSelected(tagTG);
    }, [productTag]);

    const tagSearch = () => {
        dispatch(ProductTagActions.search(searchValue));
    };

    useEffect(() => {
        const getData = setTimeout(() => {
            dispatch(ProductTagActions.search(searchValue));
        }, 500);

        return () => clearTimeout(getData);
    }, [searchValue]);

    const selectedTag = (item, isCreate = false) => {
        if (!item) return;
        if (!tagSelected.find((_item) => _item == item)) tagSelected.push(item);
        setTagSelected(tagSelected);
        setShowList(false);
    };

    const removeTag = (index) => {
        let tg = [...tagSelected];
        tg.splice(index, 1);
        setTagSelected(tg);
    };

    const createTag = () => {
        if (!searchValue) return;
        if (!tagSelected.find((_item) => _item == searchValue)) tagSelected.push(searchValue);
        setTagSelected(tagSelected);
        setShowList(false);
        setSearchValue('');
    };

    return (
        <>
            {isShowList && (
                <div
                    className='ladiui backdrop'
                    onClick={() => {
                        setSearchValue('');
                        setShowList(false);
                    }}
                />
            )}
            <div className='ladiui search-group search-section search-order-tag mt-0'>
                <i className='ladiui icon icon-search' onClick={tagSearch}></i>
                <input
                    id='keyword_search'
                    className='ladiui search-field dropdown-toggle form-control search-width'
                    name='searchName'
                    placeholder={t('TAGS.SEARCH_TAG')}
                    aria-expanded='false'
                    value={searchValue}
                    onFocus={() => setShowList(true)}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            createTag();
                        }
                    }}
                />
                <div className={`search-section-detail ${isShowList ? 'show' : ''}`}>
                    <div className='search-section-list'>
                        {tags.map((item, index) => {
                            return (
                                <div className='search-section-item ladiui ' key={index} onClick={() => selectedTag(item.name)}>
                                    {item.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className='product-tag-list'>
                {tagSelected.map((item, index) => {
                    return (
                        <div key={index} style={{ color: 'var(--main-default)' }} className='product-tag-list-item'>
                            <span className='ladiui text-14'>{item}</span>
                            <i className='new-ldicon-close-fill-2' onClick={() => removeTag(index)} />
                        </div>
                    );
                })}
            </div>
        </>
    );
});
