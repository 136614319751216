import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import productTagActions from '../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../redux/futures/product_tag/types';
import variantActions from '../../../redux/futures/product_variant/actions';
import * as variantTypes from '../../../redux/futures/product_variant/types';
import customerActions from '../../../redux/futures/customer/actions';
import * as customerTypes from '../../../redux/futures/customer/types';
import customerTagActions from '../../../redux/futures/customer_tag/actions';
import * as customerTagTypes from '../../../redux/futures/customer_tag/types';
import discountActions from '../../../redux/futures/discount/actions';
import * as discountTypes from '../../../redux/futures/discount/types';

import LoadingScene from '../../../components/LoadingScene';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import Input from '../../../components/Input';
import NumberInput from '../../../components/NumberInput';
import readXlsxFile from 'read-excel-file';
import Tag from '../../../components/Tag';
import Image from '../../../components/Image';
import AsyncAutoComplete from '../../../components/AsyncAutoComplete';
import DatePicker from '../../../components/DatePicker/CustomDatePicker';
import { debounce, map, find, remove, compact, values, cloneDeep, omit, includes, isEmpty } from 'lodash';

export default function PageDiscountCode(props) {
    const dispatch = useDispatch();
    const queryURL = baseHelper.useQuery();
    const inputsRef = new Set();
    const tagRef = useRef();
    const customerTagRef = useRef();

    const { discountID } = useParams();

    let moment = baseHelper.getMoment();

    const storeReducer = useSelector((state) => state.store);
    const productTagReducer = useSelector((state) => state.productTag);
    const customerReducer = useSelector((state) => state.customer);
    const customerTagReducer = useSelector((state) => state.customerTag);
    const variantReducer = useSelector((state) => state.variant);
    const discountReducer = useSelector((state) => state.discount);

    const SEARCH_TYPE = {
        PRODUCT: 'product',
        PRODUCT_TAG: 'productTag',
        CUSTOMER: 'customer',
        CUSTOMER_TAG: 'customerTag',
    };

    const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);

    const [discount, setDiscount] = useState({
        discount_id: '',
        name: '',
        code: '',
        codes: [],
        type: appConfig.DISCOUNT.TYPE.FIXED.value,
        value: '',
        apply_to: {
            [appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT]: appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT,
        },
        min_requirement: {
            [appConfig.DISCOUNT.MIN_REQUIREMENT.NONE]: appConfig.DISCOUNT.MIN_REQUIREMENT.NONE,
        },
        customer_groups: {
            [appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE]: appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE,
        },
        usage_limit: null,
        one_per_customer: appConfig.STATUS.ACTIVE,
        start_date: baseHelper.momentToDate(moment()),
        end_date: null,
        rule_type: appConfig.DISCOUNT.RULE_TYPE.CODE.CODE,
        allow_promotion: appConfig.STATUS.INACTIVE,
        coupon_create_type: 'CREATE_ONE',
        coupon_number: null,
        coupon_prefix: null,
        is_show_web: true,
        discount_type: 'ORDER',
        maximum_discount: null,
    });
    const [search, setSearch] = useState({
        product: '',
        productTag: '',
        customer: '',
        customerTag: '',
    });

    const [searchType, setSearchType] = useState('');

    const [displaySearch, setDisplaySearch] = useState({
        product: t('PRODUCTS.SEARCH_PRODUCT'),
        productTag: t('PRODUCTS.SEARCH_PRODUCT_TAG'),
        customer: t('CUSTOMERS.SEARCH_CUSTOMER'),
        customerTag: t('CUSTOMERS.SEARCH_CUSTOMER_TAG'),
    });
    const [selectedCodes, setSelectedCodes] = useState('');

    const [isShowMaximumDiscount, setIsShowMaximumDiscount] = useState(false);

    useEffect(() => {
        let _ruleType = queryURL.get('rule_type');

        if (_ruleType) {
            setDiscount({
                ...discount,
                rule_type: parseInt(_ruleType),
            });
        }

        if (discountID) {
            setMode(appConfig.FORM_MODE.EDIT);
            dispatch(discountActions.show(discountID));
        }

        dispatch(productTagActions.search(search.productTag));
        dispatch(customerTagActions.search(search.customerTag));
    }, []);

    useEffect(() => {
        if (discountReducer.action == discountTypes.SHOW_DISCOUNT) {
            if (discountReducer.status) {
                initUpdate(discountReducer.discount);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', discountReducer.message, 'OK');
            }
        }
        if (discountReducer.action == discountTypes.CREATE_DISCOUNT) {
            if (discountReducer.status) {
                window.LadiUI.toastCustom('success', '', discountReducer.message);
                if (discountReducer.discountIdSelected) {
                    props.history.push(`/discount-promotion/update/${discountReducer.discountIdSelected}?rule_type=${discount.rule_type}`);
                } else {
                    props.history.push('/discounts');
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', discountReducer.message, 'OK');
            }
        }

        if (discountReducer.action == discountTypes.UPDATE_DISCOUNT) {
            if (discountReducer.status) {
                window.LadiUI.toastCustom('success', '', discountReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', discountReducer.message, 'OK');
            }
        }
    }, [discountReducer]);

    useEffect(() => {
        let getData = setTimeout(() => {
            searchData(searchType);
        }, 800);
        return () => clearTimeout(getData);
    }, [searchType, search.customer, search.product]);

    const initUpdate = (dataUpdate) => {
        const newDiscount = {};
        map(discount, (value, key) => {
            newDiscount[key] = !baseHelper.isEmpty(dataUpdate[key]) ? dataUpdate[key] : '';
        });

        if (newDiscount.start_date) {
            newDiscount.start_date = baseHelper.momentToDate(moment(newDiscount.start_date));
        }

        if (newDiscount.end_date) {
            newDiscount.end_date = baseHelper.momentToDate(moment(newDiscount.end_date));
        } else {
            newDiscount.end_date = null;
        }

        if (!newDiscount.usage_limit) {
            newDiscount.usage_limit = null;
        }

        setDiscount(newDiscount);
        setIsShowMaximumDiscount(newDiscount.maximum_discount ? true : false);
    };

    const handleSetDiscountType = (value) => {
        setDiscount({
            ...discount,
            discount_type: value,
        });
    };

    const onChangeInput = (event) => {
        let { name, value } = event.target;
        if (name === 'coupon_prefix' || name === 'code') {
            value = value.toUpperCase();
        }
        if (name === 'value') {
            if (discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value) {
                if (value > 100) {
                    value = 100;
                }
            }
        }
        setDiscount({
            ...discount,
            [name]: value,
        });
    };

    const onChangeListCoupon = (event) => {
        let { value } = event.target;

        setSelectedCodes(value.toUpperCase());
    };

    const handleChangeUploadFile = async (event) => {
        let _listCoupon = [];
        const { files } = event.target;
        await readXlsxFile(files[0]).then((items) => {
            if (items && items.length > 0) {
                items.map((item) => {
                    _listCoupon.push(item[0]);
                });
            }
        });

        if (_listCoupon && _listCoupon.length > 0) {
            setSelectedCodes(_listCoupon.join(',').toUpperCase());
        }
    };

    const openInputUploadListCode = () => {
        document.getElementById('input-upload-list-code').click();
    };

    const onChangeApplyTo = (type) => {
        let _discount = { ...discount };

        if (type == appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT) {
            _discount.apply_to = { [type]: type };
        } else {
            _discount.apply_to = { [type]: [] };
        }
        setDiscount(_discount);
    };

    const getApplyProductTag = () => {
        const foundProductTags = productTagReducer.productTags || [];

        if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
            return (
                <div>
                    <Tag
                        tabIndex={6}
                        ref={tagRef}
                        id='product-tag'
                        allTags={foundProductTags}
                        selectedTags={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]}
                        hideTitle={true}
                        className='mb-16 mt-0'
                    />
                </div>
            );
        }
    };

    const searchData = (name) => {
        switch (name) {
            case SEARCH_TYPE.PRODUCT:
                dispatch(
                    variantActions.search({
                        search: search.product,
                    })
                );
                break;
            case SEARCH_TYPE.CUSTOMER:
                dispatch(customerActions.search(search.customer));
                break;
        }
    };

    const onChangeSearchInput = (event, name) => {
        const { value } = event.target;

        setSearch({
            ...search,
            [name]: value,
        });
        setSearchType(name);
    };

    const removeProductTag = (item) => {
        let _discount = { ...discount };
        const applyToTags = _discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS];

        remove(applyToTags, (_item) => _item.name == item.name);
        setDiscount(_discount);
    };

    const removeProduct = (index) => {
        let _discount = { ...discount };
        const applyToProducts = _discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS];

        remove(applyToProducts, (_item, _index) => _index == index);
        setDiscount(_discount);
    };

    const selectProduct = (item) => {
        // this.setState(
        //   {
        //     displaySearch: {
        //       ...this.state.displaySearch,
        //       product: "",
        //     },
        //   },
        //   () => {
        //     this.setState({
        //       search: {
        //         ...this.state.search,
        //         product: "",
        //       },
        //       displaySearch: {
        //         ...this.state.displaySearch,
        //         product: "Search products...",
        //       },
        //     });
        //   }
        // );

        setSearch({
            ...search,
            product: '',
        });
        setDisplaySearch({
            ...displaySearch,
            product: 'Search products...',
        });

        const _discount = { ...discount };
        const applyToProducts = _discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS];

        if (find(applyToProducts, (_item) => _item.product_id == item.product_id && _item.product_variant_id == item.product_variant_id)) {
            return;
        }

        applyToProducts.push(item);

        setDiscount(_discount);
    };

    const getApplyToPanel = () => {
        const foundProductTags = productTagReducer.productTags || [];
        const foundVariants = variantReducer.variants || [];
        const loadingSearchProductTag = productTagReducer.loading && productTagReducer.waiting == productTagTypes.SEARCH_PRODUCT_TAG;
        const loadingSearchProduct = variantReducer.loading && variantReducer.waiting == variantTypes.SEARCH_VARIANT;

        if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS]) {
            return (
                <div>
                    <div className='discount-item'>
                        <AsyncAutoComplete
                            id='autocomplete-variant'
                            items={foundVariants}
                            getItemValue={(item) => search.product}
                            loading={loadingSearchProduct}
                            renderItem={(item) => (
                                <div key={item.product_variant_id} className='item'>
                                    <div className='image-name'>
                                        <Image src={item.src} width={40} height={40} />
                                        <div className='name'>{item.name}</div>
                                    </div>
                                    <div className='price text-right'>
                                        {baseHelper.formatMoneyPostFix(item.price, storeReducer.userInfo.currentStore.currency_symbol)}
                                    </div>
                                    {!baseHelper.isAvailableVariant(item) && (
                                        <div className='badge badge-child-light sold-out'>
                                            <i className='ladi-icon icon-c-warning'></i>
                                            {t('COMMON.SOLD_OUT')}
                                        </div>
                                    )}
                                </div>
                            )}
                            value={search.product}
                            onChange={(event) => onChangeSearchInput(event, SEARCH_TYPE.PRODUCT)}
                            onSelect={(value, item) => {
                                selectProduct(item, SEARCH_TYPE.PRODUCT);
                            }}
                            inputProps={{
                                onFocus: () => {
                                    if (foundVariants.length == 0) {
                                        searchData(SEARCH_TYPE.PRODUCT);
                                    }
                                },
                                className: 'ladiui input form-control custom-input-autocomplete',
                                placeholder: t('PRODUCTS.SEARCH_PRODUCT'),
                            }}
                            wrapperProps={{ className: 'ladiui-search-custom' }}
                        />
                    </div>
                    <table className='ladiui table text-left' style={{ minWidth: 'auto' }}>
                        <thead></thead>

                        <tbody>
                            {map(discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS], (item, index) => (
                                <tr key={index} className='ladiui table-vertical' style={{ borderBottom: '1px solid #efefef' }}>
                                    <td>
                                        {' '}
                                        <Image src={item.src} width={25} height={25} />
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                display: '-webkit-box',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                textOverflow: 'ellipsis',
                                                marginLeft: 8,
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    </td>
                                    <td className='text-right'>
                                        <a onClick={() => removeProduct(index)} style={{ color: '#6d6d6d' }}>
                                            <i className='ladi-icon icon-bin' />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    const onChangeMinRequire = (type) => {
        let _discount = { ...discount };
        if (type == appConfig.DISCOUNT.MIN_REQUIREMENT.NONE) {
            _discount.min_requirement = { [type]: type };
        } else {
            _discount.min_requirement = { [type]: '' };
        }

        setDiscount(_discount);
    };

    const onChangeMinRequireInput = (event) => {
        const { name, value } = event.target;
        let _discount = { ...discount };
        _discount.min_requirement = {
            [name]: value,
        };

        setDiscount(_discount);
    };

    const onChangeCustomerGroup = (type) => {
        let _discount = { ...discount };

        if (type == appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE) {
            _discount.customer_groups = { [type]: type };
        } else {
            _discount.customer_groups = { [type]: [] };
        }

        setDiscount(_discount);
    };

    const getCustomerTags = () => {
        const foundCustomerTags = customerTagReducer.customerTags || [];

        if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]) {
            return (
                <div>
                    <Tag
                        tabIndex={6}
                        ref={customerTagRef}
                        id='customer-tag'
                        allTags={foundCustomerTags}
                        selectedTags={discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]}
                        hideTitle={true}
                        className='mt-0 mb-16'
                    />
                </div>
            );
        }
    };

    const selectCustomerTag = (item) => {
        // this.setState(
        //   {
        //     displaySearch: {
        //       ...this.state.displaySearch,
        //       customerTag: "",
        //     },
        //   },
        //   () => {
        //     this.setState({
        //       search: {
        //         ...this.state.search,
        //         customerTag: "",
        //       },
        //       displaySearch: {
        //         ...this.state.displaySearch,
        //         customerTag: this.props.t("CUSTOMERS.SEARCH_CUSTOMER_TAG"),
        //       },
        //     });
        //   }
        // );

        setSearch({
            ...search,
            customerTag: '',
        });

        setDisplaySearch({
            ...displaySearch,
            customerTag: t('CUSTOMERS.SEARCH_CUSTOMER_TAG'),
        });

        let _discount = { ...discount };
        const customerGroupsTags = _discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS];

        if (!find(customerGroupsTags, (_item) => _item.name == item.name)) {
            customerGroupsTags.push(item);

            setDiscount(_discount);
        }
    };

    const selectCustomer = (item) => {
        // this.setState(
        //     {
        //         displaySearch: {
        //             ...this.state.displaySearch,
        //             customer: "",
        //         },
        //     },
        //     () => {
        //         this.setState({
        //             search: {
        //                 ...this.state.search,
        //                 customer: "",
        //             },
        //             displaySearch: {
        //                 ...this.state.displaySearch,
        //                 customer: this.props.t("CUSTOMERS.SEARCH_CUSTOMER"),
        //             },
        //         });
        //     }
        // );

        setSearch({
            ...search,
            customer: '',
        });
        setDisplaySearch({
            ...displaySearch,
            customer: t('CUSTOMERS.SEARCH_CUSTOMER'),
        });

        let _discount = { ...discount };
        const customerGroups = _discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS];

        if (find(customerGroups, (_item) => _item.customer_id == item.customer_id)) {
            return;
        }

        customerGroups.push(item);

        setDiscount(_discount);
    };

    const removeCustomerTag = (item) => {
        let _discount = { ...discount };
        const customerGroupTags = _discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS];

        remove(customerGroupTags, (_item) => _item.name == item.name);
        setDiscount(_discount);
    };

    const removeCustomer = (index) => {
        let _discount = { ...discount };
        const customerGroups = _discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS];

        remove(customerGroups, (_item, _index) => _index == index);
        setDiscount(_discount);
    };

    const getCustomerGroupPanel = () => {
        const foundCustomerTags = customerTagReducer.customerTags || [];
        const foundCustomers = customerReducer.customers || [];
        const loadingSearchCustomer = customerReducer.loading && customerReducer.waiting == customerTypes.SEARCH_CUSTOMER;

        if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS]) {
            return (
                <div>
                    <div className='discount-item'>
                        <AsyncAutoComplete
                            id='autocomplete-customer'
                            items={foundCustomers}
                            getItemValue={(item) => search.customer}
                            loading={loadingSearchCustomer}
                            renderItem={(item) => (
                                <div key={item.customer_id} className='item'>
                                    <div className='name'>
                                        <span>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(item.first_name)}`}</span>
                                        <div className='email'>{item.email}</div>
                                    </div>
                                </div>
                            )}
                            value={search.customer}
                            onChange={(event) => onChangeSearchInput(event, SEARCH_TYPE.CUSTOMER)}
                            onSelect={(value, item) => {
                                selectCustomer(item, SEARCH_TYPE.CUSTOMER);
                            }}
                            inputProps={{
                                onFocus: () => {
                                    if (foundCustomers.length == 0) {
                                        searchData(SEARCH_TYPE.CUSTOMER);
                                    }
                                },
                                className: 'ladiui input form-control custom-input-autocomplete',
                                placeholder: t('CUSTOMERS.SEARCH_CUSTOMER'),
                            }}
                            wrapperProps={{ className: 'ladiui-search-custom' }}
                        />
                    </div>
                    <table className='ladiui table text-left' style={{ minWidth: 'auto' }}>
                        <thead></thead>

                        <tbody>
                            {map(discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS], (item, index) => (
                                <tr key={index} className='ladiui table-vertical' style={{ borderBottom: '1px solid #efefef' }}>
                                    <td>{`${baseHelper.getText(item.last_name)} ${baseHelper.getText(item.first_name)}`}</td>
                                    <td>{item.email}</td>
                                    <td className='text-right'>
                                        <a onClick={() => removeCustomer(index)} style={{ color: '#6d6d6d' }}>
                                            <i className='ladi-icon icon-bin' />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    const onChangeUsageLimit = (event) => {
        const { checked } = event.target;
        let _discount = { ...discount };
        if (checked) {
            _discount.usage_limit = '';
        } else {
            _discount.usage_limit = null;
        }

        setDiscount(_discount);
    };

    const onChangeUsePerCus = (event) => {
        const { checked } = event.target;
        let _discount = { ...discount };
        if (checked) {
            _discount.one_per_customer = appConfig.STATUS.ACTIVE;
        } else {
            _discount.one_per_customer = appConfig.STATUS.INACTIVE;
        }

        setDiscount(_discount);
    };

    const onChangeDateTime = (value, name) => {
        let _discount = { ...discount };
        if (_discount.end_date) {
            if (name == 'start_date') {
                if (value.getTime() >= discount.end_date.getTime()) {
                    window.LadiUI.toastCustom('danger', '', t('VALIDATION.END_DATE_AFTER_START_DATE'));
                    return;
                }
            } else {
                if (_discount.start_date.getTime() >= value.getTime()) {
                    window.LadiUI.toastCustom('danger', '', t('VALIDATION.END_DATE_AFTER_START_DATE'));
                    return;
                }
            }
        }
        _discount[name] = value;
        setDiscount(_discount);
    };

    const onChangeSetEndDate = (event) => {
        const { checked } = event.target;
        let _discount = { ...discount };
        if (checked) {
            _discount.end_date = baseHelper.momentToDate(moment());
        } else {
            _discount.end_date = null;
        }

        setDiscount(_discount);
    };

    const getNameByRuleType = (value) => {
        const matchedDiscount = find(appConfig.DISCOUNT.RULE_TYPE, (item) => item.CODE == value);
        return matchedDiscount.NAME;
    };
    const getDiscountTypeNameByType = (value) => {
        const matchedDiscount = find(appConfig.LIST_DISCOUNT_TYPE, (item) => item.value == value);
        return matchedDiscount.name;
    };
    const getDiscountNameByType = (value) => {
        const matchedDiscount = find(appConfig.DISCOUNT.TYPE, (item) => item.value == value);
        return matchedDiscount.name;
    };

    const onChangeAllowPromotion = (event) => {
        const { checked } = event.target;
        let _discount = { ...discount };
        if (checked) {
            _discount.allow_promotion = appConfig.STATUS.ACTIVE;
        } else {
            _discount.allow_promotion = appConfig.STATUS.INACTIVE;
        }

        setDiscount(_discount);
    };

    const handleSubmitData = () => {
        // Validate Input
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const productTags = tagRef.current ? tagRef.current.getIDs() : [];
        const customerTags = customerTagRef.current ? customerTagRef.current.getIDs() : [];

        // Validate Apply_to
        const discountReq = cloneDeep(discount);

        let { apply_to: applyTo } = discountReq;

        if (productTags && productTags.length > 0) {
            applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS] = productTags;
        }
        if (!applyTo[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT]) {
            if (values(applyTo)[0].length <= 0) {
                window.LadiUI.toastCustom('danger', '', t('DISCOUNTS.MSG_SPECIFIC_PRODUCT_OR_TAG_REQUIRED'));
                return;
            }
        }
        // Validate Customer groups
        let { customer_groups: customerGroups } = discountReq;
        if (customerTags && customerTags.length > 0) {
            customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS] = customerTags;
        }
        if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            if (!customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE]) {
                if (values(customerGroups)[0].length <= 0) {
                    window.LadiUI.toastCustom('danger', '', t('DISCOUNTS.MSG_SPECIFIC_CUSTOMER_OR_TAG_REQUIRED'));
                    return;
                }
            }
        }

        if (discount.usage_limit && discount.usage_limit < 0) {
            window.LadiUI.toastCustom('danger', '', t('DISCOUNTS.LIMIT_ERROR'));
            return;
        }

        // Validate Date
        if (discount.end_date) {
            if (discount.start_date.getTime() >= discount.end_date.getTime()) {
                window.LadiUI.toastCustom('danger', '', t('VALIDATION.END_DATE_AFTER_START_DATE'));
                return;
            }
        }

        // Chuan hoa discount request

        applyTo = discountReq.apply_to;
        if (applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCTS]) {
            const variantIDs = map(applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCTS], (item) => item.product_variant_id);
            applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCTS] = variantIDs;
        } else if (applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
            const productTagIDs = map(applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS], (item) => item);

            applyTo[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS] = productTagIDs;
        }

        // Chuan hoa discount request
        if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            customerGroups = discountReq.customer_groups;
            if (customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS]) {
                const customerIDs = map(customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS], (item) => item.customer_id);
                customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS] = customerIDs;
            } else if (customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]) {
                const customerTagIDs = map(customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS], (item) => item);
                customerGroups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS] = customerTagIDs;
            }
        }

        if (discountReq.coupon_create_type == 'CREATE_HANDMADE') {
            if (selectedCodes) {
                discountReq.codes = selectedCodes.split(',');
            }
        }

        if (mode === appConfig.FORM_MODE.CREATE) {
            dispatch(discountActions.create(omit(discountReq, ['discount_id'])));
        } else {
            dispatch(discountActions.update(discountReq));
        }
    };

    const { currency_code: currencyCode, currency_symbol: currencySymbol } = storeReducer.userInfo.currentStore;

    let title = '';
    if (mode == appConfig.FORM_MODE.CREATE) {
        if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            title = 'Tạo khuyến mãi mới';
        } else {
            title = 'Tạo chương trình khuyến mãi';
        }
    }
    if (mode == appConfig.FORM_MODE.EDIT) {
        if (discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE) {
            title = 'Sửa khuyến mãi';
        } else {
            title = 'Sửa chương trình khuyến mãi';
        }
    }

    const isCode = discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE;
    const isFixedType = discount.type == appConfig.DISCOUNT.TYPE.FIXED.value;
    let applyOverview = '';
    if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT]) {
        applyOverview = 'toàn bộ đơn hàng';
    } else if (discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS]) {
        applyOverview = t('DISCOUNTS.OVERVIEW.APPLY_TO_TAGS', discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS].length);
    } else {
        applyOverview = t('DISCOUNTS.OVERVIEW.APPLY_TO_PRODUCTS');
    }
    let maximumDiscountOverView = '';

    if (discount && discount.maximum_discount) {
        maximumDiscountOverView = `${t(
            'DISCOUNTS.OVERVIEW.MAXIMUM_DISCOUNT',
            baseHelper.formatMoneyPostFix(discount.maximum_discount, storeReducer.userInfo.currentStore.currency_symbol)
        )}`;
    }

    let minRequireOverview;
    if (discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT]) {
        minRequireOverview = `${t(
            'DISCOUNTS.OVERVIEW.MIN_REQUIREMENT_AMOUNT',
            baseHelper.formatMoneyPostFix(
                discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT],
                storeReducer.userInfo.currentStore.currency_symbol
            )
        )}`;
    } else if (discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY]) {
        minRequireOverview = `${t(
            'DISCOUNTS.OVERVIEW.MIN_REQUIREMENT_QUANTITY',
            baseHelper.parseInt(discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY])
        )}`;
    }

    let customerGroupOverview = '';
    if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE]) {
        customerGroupOverview = t('DISCOUNTS.OVERVIEW.CUSTOMER_GROUP_EVERY_ONE');
    } else if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS]) {
        customerGroupOverview = t('DISCOUNTS.OVERVIEW.CUSTOMER_GROUP_TAGS');
    } else if (discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS]) {
        customerGroupOverview = t(
            'DISCOUNTS.OVERVIEW.CUSTOMER_GROUP_CUSTOMERS',
            discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS].length
        );
    }

    let onePerCustomerOverview = '';
    if (isCode && discount.one_per_customer == appConfig.STATUS.ACTIVE) {
        onePerCustomerOverview = t('DISCOUNTS.OVERVIEW.ONE_PER_CUSTOMER');
    }

    let availableOverview = `${t('DISCOUNTS.OVERVIEW.AVAILABLE_DATE')} ${baseHelper.formatStrToDate(discount.start_date)}`;
    if (discount.end_date) {
        availableOverview += ` ${t('DISCOUNTS.OVERVIEW.DATE_TO')} ${baseHelper.formatStrToDate(discount.end_date)}`;
    }

    let usageLimitOverview = '';
    if (isCode && discount.usage_limit) {
        usageLimitOverview = t('DISCOUNTS.OVERVIEW.USAGE_LIMIT', discount.usage_limit);
    }

    const applyToOverview = `${t('DISCOUNTS.OVERVIEW.INCREASE')} ${isFixedType ? baseHelper.formatMoneyPostFix(discount.value, currencyCode) : `${baseHelper.parseInt(discount.value)} %`
        } ${discount.discount_type == 'SHIPPING' ? t('DISCOUNTS.DISCOUNT_SHIPPING') : ' '} ${applyOverview}`;

    const isLoadingData =
        discountReducer.loading && includes([discountTypes.CREATE_DISCOUNT, discountTypes.UPDATE_DISCOUNT], discountReducer.waitting);

    return (
        <>
            {isLoadingData ? <LoadingScene blur={true} /> : null}
            <div className='page-discount-code-v4'>
                <div className='page-header'>
                    <div className='content-header'>
                        <div className='title-action'>
                            <h3>{title}</h3>
                            <button type='button' className='ladiui btn btn-primary' onClick={handleSubmitData}>
                                {mode == appConfig.FORM_MODE.CREATE ? 'Tạo mới' : 'Cập nhật'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='ladiui page-content content-max-width'>
                    <div className='content-left'>
                        {mode === appConfig.FORM_MODE.EDIT && (
                            <>
                                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                                    <div className='block-content-item'>
                                        <h3>Mã khuyến mãi</h3>
                                        <div className='ladiui form-group'>
                                            <Input
                                                name='code'
                                                validationName={t('DISCOUNTS.CODE')}
                                                placeholder='e.g. SPRINGSALE'
                                                value={discount.code}
                                                onChange={onChangeInput}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.PROMOTION.CODE && (
                            <>
                                <div className='block-content-item'>
                                    <h3>Tiêu đề chương trình khuyến mãi</h3>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>{t('DISCOUNTS.PROMOTION')}</label>
                                        <Input
                                            name='name'
                                            validationName={t('DISCOUNTS.PROMOTION')}
                                            placeholder='e.g. CTKM T10'
                                            value={discount.name}
                                            onChange={onChangeInput}
                                            validations={{ isRequired: true }}
                                            ref={(ref) => inputsRef.add(ref)}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                            <div className='block-discount-type'>
                                <div className='block-content-item'>
                                    <h3 className='mb-24'>Loại mã khuyến mãi</h3>
                                    <div className='group-item-check'>
                                        <div className='item' onClick={() => handleSetDiscountType('ORDER')}>
                                            <input
                                                type='radio'
                                                name='check-type-discount'
                                                checked={discount.discount_type == 'ORDER'}
                                                className='ladiui form-check-input mr-8'
                                            />
                                            <span>Giảm giá đơn hàng</span>
                                        </div>
                                        <div className='item' onClick={() => handleSetDiscountType('SHIPPING')}>
                                            <input
                                                type='radio'
                                                name='check-type-discount'
                                                checked={discount.discount_type == 'SHIPPING'}
                                                className='ladiui form-check-input mr-8'
                                            />
                                            <span>Giảm giá vận chuyển</span>
                                        </div>
                                    </div>
                                    {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE &&
                                        discount.discount_type != 'SHIPPING' && (
                                            <div
                                                className='ladiui form-group'
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <input
                                                    id='allow_promotion'
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                    onChange={onChangeAllowPromotion}
                                                    checked={discount.allow_promotion == appConfig.STATUS.ACTIVE}
                                                />
                                                <label htmlFor='allow_promotion'> {t('DISCOUNTS.ALLOW_PROMOTION')}</label>
                                            </div>
                                        )}

                                    <div className='block-info-help flex items-center'>
                                        <i className='ladi-icon icon-c-question mr-8'></i>
                                        <span>Khuyến mãi sẽ áp dụng vào mã đơn hàng</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {mode === appConfig.FORM_MODE.CREATE && discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                            <div className='block-generation-form'>
                                <div className='block-content-item'>
                                    <h3 className='mb-20'>Hình thức tạo mã</h3>
                                    <div className='group-item-check'>
                                        <div
                                            className='item'
                                            onClick={() => {
                                                setDiscount({
                                                    ...discount,
                                                    coupon_create_type: 'CREATE_ONE',
                                                });
                                            }}
                                        >
                                            <input
                                                type='radio'
                                                name='radio-group-type'
                                                className='ladiui form-check-input mr-8'
                                                checked={discount.coupon_create_type == 'CREATE_ONE'}
                                            />
                                            <span>Tạo một mã</span>
                                        </div>
                                        <div
                                            className='item'
                                            onClick={() => {
                                                setDiscount({
                                                    ...discount,
                                                    coupon_create_type: 'CREATE_AUTO',
                                                });
                                            }}
                                        >
                                            <input
                                                type='radio'
                                                name='radio-group-type'
                                                className='ladiui form-check-input mr-8'
                                                checked={discount.coupon_create_type == 'CREATE_AUTO'}
                                            />
                                            <span>Tạo mã tự động</span>
                                        </div>
                                        <div
                                            className='item'
                                            onClick={() => {
                                                setDiscount({
                                                    ...discount,
                                                    coupon_create_type: 'CREATE_HANDMADE',
                                                });
                                            }}
                                        >
                                            <input
                                                type='radio'
                                                name='radio-group-type'
                                                className='ladiui form-check-input mr-8'
                                                checked={discount.coupon_create_type == 'CREATE_HANDMADE'}
                                            />
                                            <span>Tạo mã thủ công</span>
                                        </div>
                                    </div>
                                    {discount.coupon_create_type == 'CREATE_ONE' && (
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-sub-title block'>Mã khuyến mãi</label>
                                            <Input
                                                name='code'
                                                validationName={t('DISCOUNTS.CODE')}
                                                placeholder='Nhập mã khuyến mãi'
                                                value={discount.code}
                                                onChange={onChangeInput}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    )}

                                    {discount.coupon_create_type == 'CREATE_AUTO' && (
                                        <div className='flex mt-24'>
                                            <div className='ladiui form-group mb-0 mt-0' style={{ width: '40%' }}>
                                                <label className='ladiui-sub-title block'>Tiền Tố</label>
                                                <Input
                                                    name='coupon_prefix'
                                                    validationName={'tiền tố'}
                                                    placeholder='Nhập tiền tố'
                                                    value={discount.coupon_prefix}
                                                    onChange={onChangeInput}
                                                    validations={{ isRequired: false }}
                                                    maxLength='10'
                                                    ref={(ref) => inputsRef.add(ref)}
                                                />
                                            </div>
                                            <div className='ladiui form-group mb-0 mt-0 pl-16'>
                                                <label className='ladiui-sub-title block'>Số lượng mã tạo tự động (tối đa 1000)</label>
                                                <Input
                                                    name='coupon_number'
                                                    type='number'
                                                    max={100}
                                                    validationName={'số lượng mã tạo tự động'}
                                                    placeholder='Nhập số lượng mã'
                                                    value={discount.coupon_number}
                                                    onChange={onChangeInput}
                                                    validations={{ isRequired: true }}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {discount.coupon_create_type == 'CREATE_HANDMADE' && (
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-sub-title block'>Danh sách mã khuyến mãi</label>
                                            <textarea
                                                style={{
                                                    minHeight: '120px',
                                                    border: '1px solid #e5e5e5',
                                                    padding: '8px 12px',
                                                    borderRadius: '2px',
                                                    width: '100%',
                                                }}
                                                placeholder='Nhập danh sách mã khuyến mãi. Mỗi mã sẽ cách nhau bằng một dấu phẩy.Ví dụ: PH001, PH002,...'
                                                name='selectedCodes'
                                                value={selectedCodes}
                                                onChange={onChangeListCoupon}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => inputsRef.add(ref)}
                                            ></textarea>
                                            <div className='mt-16 mb-16'>Hoặc</div>
                                            <div className='block-upload-file-code'>
                                                <input
                                                    type='file'
                                                    name='file'
                                                    id='input-upload-list-code'
                                                    accept='.xsl, .xlsx'
                                                    // multiple="multiple"
                                                    onChange={handleChangeUploadFile}
                                                    style={{ display: 'none' }}
                                                    onClick={(event) => {
                                                        event.target.value = null;
                                                    }}
                                                />
                                                <button className='ladiui btn btn-outline-primary' onClick={openInputUploadListCode}>
                                                    Nhập danh sách mã từ file Excel
                                                </button>
                                                <span
                                                    className='block'
                                                    style={{
                                                        paddingTop: '8px',
                                                        fontSize: '12px',
                                                        color: '#4d4d4d',
                                                    }}
                                                >
                                                    Lưu ý: Mặc định mã sẽ được lấy nội dung cột đầu tiên trong file sheet.
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className='block-conditions'>
                            <div className='block-content-item'>
                                <h3>Giảm giá</h3>
                                <div className='option-discount-value mt-24'>
                                    <div className='option'>
                                        <div
                                            className={`item ${discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value ? 'active' : ''}`}
                                            onClick={() => {
                                                setDiscount({
                                                    ...discount,
                                                    type: appConfig.DISCOUNT.TYPE.PERCENT.value,
                                                    value: 0,
                                                });
                                            }}
                                        >
                                            Phần trăm
                                        </div>
                                        <div
                                            className={`item ${discount.type == appConfig.DISCOUNT.TYPE.FIXED.value ? 'active' : ''}`}
                                            onClick={() => {
                                                setDiscount({
                                                    ...discount,
                                                    type: appConfig.DISCOUNT.TYPE.FIXED.value,
                                                    value: 0,
                                                });
                                            }}
                                        >
                                            Số tiền
                                        </div>
                                    </div>
                                    <div className='ladiui form-group m-0 value'>
                                        {discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value ? (
                                            <NumberInput
                                                name='value'
                                                prefix='%'
                                                validationName={t('DISCOUNTS.VALUE')}
                                                style={{ paddingLeft: '22px' }}
                                                value={discount.value}
                                                onChange={onChangeInput}
                                                ref={(ref) => inputsRef.add(ref)}
                                                validations={{ min: 0, max: 100, isRequired: true }}
                                            />
                                        ) : (
                                            <NumberInput
                                                name='value'
                                                prefix={currencySymbol}
                                                validationName={t('DISCOUNTS.VALUE')}
                                                value={discount.value}
                                                style={{ paddingLeft: '22px' }}
                                                onChange={onChangeInput}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        )}
                                    </div>
                                </div>
                                {discount.type == appConfig.DISCOUNT.TYPE.PERCENT.value && (
                                    <div className='ladiui form-group'>
                                        <div className='flex pb-8'>
                                            <input
                                                id='limit-maximum-discount'
                                                type='checkbox'
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                onChange={(e) => {
                                                    let { checked } = e.target;
                                                    setDiscount({
                                                        ...discount,
                                                        maximum_discount: checked ? 0 : null,
                                                    });
                                                    setIsShowMaximumDiscount(!isShowMaximumDiscount);
                                                }}
                                                checked={isShowMaximumDiscount}
                                            />
                                            <label htmlFor='limit-maximum-discount'>Giới hạn số tiền giảm giá tối đa</label>
                                        </div>
                                        {isShowMaximumDiscount && (
                                            <NumberInput
                                                name='maximum_discount'
                                                prefix={currencySymbol}
                                                validationName={t('số tiền giảm giá tối đa')}
                                                value={discount.maximum_discount}
                                                style={{ paddingLeft: '22px' }}
                                                onChange={onChangeInput}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className='list-conditions'>
                                    <span>Áp dụng khuyến mãi cho</span>
                                    <div className='discount-item'>
                                        <input
                                            id='applyToEntire'
                                            type='radio'
                                            name='radio-group-apply-to'
                                            className='ladiui form-check-input'
                                            value={appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT}
                                            onChange={() => {
                                                onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT);
                                            }}
                                            checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.ENTIRE_ORDER_OR_PRODUCT] || false}
                                        />
                                        <label htmlFor='applyToEntire'>{t('DISCOUNTS.APPLY_TO_ENTIRE_ORDER')}</label>
                                    </div>
                                    <div className='discount-item'>
                                        <input
                                            id='applyToTags'
                                            type='radio'
                                            name='radio-group-apply-to'
                                            className='ladiui form-check-input'
                                            value={appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS}
                                            onChange={() => {
                                                onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS);
                                            }}
                                            checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCT_TAGS] || false}
                                        />
                                        <label htmlFor='applyToTags'>{t('DISCOUNTS.APPLY_TO_SPECIFIC_TAG')}</label>
                                    </div>
                                    {getApplyProductTag()}
                                    <div className='discount-item'>
                                        <input
                                            id='applyToProducts'
                                            type='radio'
                                            name='radio-group-apply-to'
                                            className='ladiui form-check-input'
                                            value={appConfig.DISCOUNT.APPLY_TO.PRODUCTS}
                                            onChange={() => {
                                                onChangeApplyTo(appConfig.DISCOUNT.APPLY_TO.PRODUCTS);
                                            }}
                                            checked={discount.apply_to[appConfig.DISCOUNT.APPLY_TO.PRODUCTS] || false}
                                        />
                                        <label htmlFor='applyToProducts'>{t('DISCOUNTS.APPLY_TO_SPECIFIC_PRODUCTS')}</label>
                                    </div>
                                    {getApplyToPanel()}
                                </div>
                            </div>
                        </div>
                        <div className='block-conditions'>
                            <div className='block-content-item'>
                                <h3>Yêu cầu tối thiểu</h3>
                                <div className='list-conditions'>
                                    <div className='discount-item'>
                                        <input
                                            id='minRequireNone'
                                            type='radio'
                                            name='radio-group-min-require'
                                            className='ladiui form-check-input'
                                            value={appConfig.DISCOUNT.MIN_REQUIREMENT.NONE}
                                            onChange={() => {
                                                onChangeMinRequire(appConfig.DISCOUNT.MIN_REQUIREMENT.NONE);
                                            }}
                                            checked={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.NONE] != undefined}
                                        />
                                        <label htmlFor='minRequireNone'>{t('DISCOUNTS.MIN_REQUIREMENT_NONE')}</label>
                                    </div>
                                    <div className='discount-item'>
                                        <input
                                            id='minRequireAmount'
                                            type='radio'
                                            name='radio-group-min-require'
                                            className='ladiui form-check-input'
                                            value={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT}
                                            onChange={() => {
                                                onChangeMinRequire(appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT);
                                            }}
                                            checked={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT] != undefined}
                                        />
                                        <label htmlFor='minRequireAmount'>{t('DISCOUNTS.MIN_REQUIREMENT_AMOUNT')}</label>
                                    </div>
                                    {discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT] != undefined && (
                                        <div className='discount-item'>
                                            <NumberInput
                                                name={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT}
                                                validationName={t('DISCOUNTS.MIN_REQUIREMENT_AMOUNT')}
                                                prefix={currencySymbol}
                                                value={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_AMOUNT]}
                                                onChange={onChangeMinRequireInput}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    )}
                                    <div className='discount-item'>
                                        <input
                                            id='minRequireQuantity'
                                            type='radio'
                                            name='radio-group-min-require'
                                            className='ladiui form-check-input'
                                            value={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY}
                                            onChange={() => {
                                                onChangeMinRequire(appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY);
                                            }}
                                            checked={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY] != undefined}
                                        />
                                        <label htmlFor='minRequireQuantity'>{t('DISCOUNTS.MIN_REQUIREMENT_QUANTITY')}</label>
                                    </div>
                                    {discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY] != undefined && (
                                        <div className='discount-item'>
                                            <Input
                                                name={appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY}
                                                validationName={t('DISCOUNTS.MIN_REQUIREMENT_QUANTITY')}
                                                type='number'
                                                value={discount.min_requirement[appConfig.DISCOUNT.MIN_REQUIREMENT.MIN_QUANTITY]}
                                                onChange={onChangeMinRequireInput}
                                                validations={{ isRequired: true }}
                                                ref={(ref) => inputsRef.add(ref)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                            <div className='block-conditions'>
                                <div className='block-content-item'>
                                    <h3>Đối tượng áp dụng</h3>
                                    <div className='list-conditions'>
                                        <div className='discount-item'>
                                            <input
                                                id='customerGroupEveryone'
                                                type='radio'
                                                name='radio-group-customerGroup'
                                                className='ladiui form-check-input'
                                                value={appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE}
                                                onChange={() => {
                                                    onChangeCustomerGroup(appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE);
                                                }}
                                                checked={
                                                    discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.EVERY_ONE] != undefined
                                                }
                                            />
                                            <label htmlFor='customerGroupEveryone'>{t('DISCOUNTS.CUSTOMER_GROUPS_EVERYONE')}</label>
                                        </div>
                                        <div className='discount-item'>
                                            <input
                                                id='customerGroupTags'
                                                type='radio'
                                                name='radio-group-customerGroup'
                                                className='ladiui form-check-input'
                                                value={appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS}
                                                onChange={() => {
                                                    onChangeCustomerGroup(appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS);
                                                }}
                                                checked={
                                                    discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMER_TAGS] != undefined
                                                }
                                            />
                                            <label htmlFor='customerGroupTags'>{t('DISCOUNTS.CUSTOMER_GROUPS_SPECIFIC_TAG')}</label>
                                        </div>
                                        {getCustomerTags()}
                                        <div className='discount-item'>
                                            <input
                                                id='customerGroupCustomers'
                                                type='radio'
                                                name='radio-group-customerGroup'
                                                className='ladiui form-check-input'
                                                value={appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS}
                                                onChange={() => {
                                                    onChangeCustomerGroup(appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS);
                                                }}
                                                checked={
                                                    discount.customer_groups[appConfig.DISCOUNT.CUSTOMER_GROUPS.CUSTOMERS] != undefined
                                                }
                                            />
                                            <label htmlFor='customerGroupCustomers'>
                                                {t('DISCOUNTS.CUSTOMER_GROUPS_SPECIFIC_CUSTOMERS')}
                                            </label>
                                        </div>

                                        {getCustomerGroupPanel()}
                                    </div>
                                </div>
                            </div>
                        )}
                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                            <div className='block-conditions'>
                                <div className='block-content-item'>
                                    <h3>Giới hạn sử dụng</h3>
                                    <div className='list-conditions'>
                                        <div className='discount-item'>
                                            <input
                                                id='limitTime'
                                                onChange={onChangeUsageLimit}
                                                checked={discount.usage_limit != null}
                                                type='checkbox'
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            />
                                            <label> {t('DISCOUNTS.USAGE_LIMIT_CAN_BE_USED')}</label>
                                        </div>
                                        {discount.usage_limit != null && (
                                            <div className='discount-item'>
                                                <Input
                                                    name='usage_limit'
                                                    type='number'
                                                    value={discount.usage_limit || ''}
                                                    onChange={onChangeInput}
                                                    validationName={t('DISCOUNTS.USAGE_LIMIT')}
                                                    validations={{ isRequired: true }}
                                                    ref={(ref) => inputsRef.add(ref)}
                                                />
                                            </div>
                                        )}
                                        <div className='discount-item'>
                                            <input
                                                id='limitOnePerCus'
                                                onChange={onChangeUsePerCus}
                                                checked={discount.one_per_customer == appConfig.STATUS.ACTIVE}
                                                type='checkbox'
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            />
                                            <label> {t('DISCOUNTS.USAGE_LIMIT_ONE_PER_CUSTOMER')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='block-discount-date'>
                            <div className='block-content-item'>
                                <h3>{t('DISCOUNTS.ACTIVE_DATE')}</h3>
                                <div className='flex gap-16'>
                                    <div className='ladiui form-group' style={{ width: '50%' }}>
                                        <label className='ladiui-label new'>{t('COMMON.START_DATE')}</label>
                                        <DatePicker
                                            name='start_date'
                                            minDate={baseHelper.momentToDate(moment())}
                                            selected={discount.start_date}
                                            onChange={(value) => onChangeDateTime(value, 'start_date')}
                                            showTimeSelect
                                            timeFormat={appConfig.DEFAUT_TIME}
                                            timeIntervals={15}
                                            dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                            timeCaption='Time'
                                        />
                                    </div>
                                    {discount.end_date != null && (
                                        <div className='ladiui form-group' style={{ width: '50%' }}>
                                            <label className='ladiui-label new'>{t('COMMON.END_DATE')}</label>
                                            <DatePicker
                                                name='end_date'
                                                minDate={discount.start_date}
                                                selected={discount.end_date}
                                                onChange={(value) => onChangeDateTime(value, 'end_date')}
                                                showTimeSelect
                                                timeFormat={appConfig.DEFAUT_TIME}
                                                timeIntervals={15}
                                                dateFormat={`${appConfig.DEFAULT_DATE_PICKER_FORMAT_DATE} ${appConfig.DEFAUT_TIME}`}
                                                timeCaption='Time'
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='flex mt-16'>
                                    <input
                                        id='limitTime'
                                        onChange={onChangeSetEndDate}
                                        type='checkbox'
                                        checked={discount.end_date != null}
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                    />
                                    <label> {t('DISCOUNTS.SET_END_DATE')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-right'>
                        <div className='content'>
                            <div className='block-content-item'>
                                <div className='block-over-view'>
                                    <h3 className='title'>
                                        Tổng quan{' '}
                                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE
                                            ? 'khuyến mãi'
                                            : 'chương trình khuyến mãi'}
                                    </h3>
                                    <div className='content'>
                                        <div className='block-item'>
                                            <label className='ladiui-label'>
                                                {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE
                                                    ? 'Mã khuyến mãi'
                                                    : 'Tên chương trình'}
                                                :&nbsp;
                                            </label>
                                            <span>{isCode ? discount.code : discount.name}</span>
                                        </div>
                                        <div className='block-item'>
                                            <label className='ladiui-label'>Hình thức:&nbsp; </label>
                                            <span>{getNameByRuleType(discount.rule_type)}</span>
                                        </div>
                                        {discount.rule_type == appConfig.DISCOUNT.RULE_TYPE.CODE.CODE && (
                                            <div className='block-item'>
                                                <label className='ladiui-label'>Loại khuyến mãi:&nbsp; </label>
                                                <span>{getDiscountTypeNameByType(discount.discount_type)}</span>
                                            </div>
                                        )}
                                        <div className='block-item'>
                                            <label className='ladiui-label'>Giảm giá theo:&nbsp;</label>
                                            <span>{getDiscountNameByType(discount.type)} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='block-detail-discount'>
                                    <h3 className='title'>Chi tiết khuyến mãi</h3>
                                    <div className='overview-content'>
                                        <span className='overview-item'>{`• ${applyToOverview}`}</span>
                                        {maximumDiscountOverView && <span className='overview-item'>{`• ${maximumDiscountOverView}`}</span>}

                                        {minRequireOverview && <span className='overview-item'>{`• ${minRequireOverview}`}</span>}

                                        {customerGroupOverview && <span className='overview-item'>{`• ${customerGroupOverview}`}</span>}

                                        {usageLimitOverview && <span className='overview-item'>{`• ${usageLimitOverview}`}</span>}

                                        {onePerCustomerOverview && <span className='overview-item'>{`• ${onePerCustomerOverview}`}</span>}
                                        <span className='overview-item'>{`• ${availableOverview}`}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
