import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const printOrder = (orderID) => ({
    type: types.PRINT_ORDER,
    meta: {
        endpoint: endpoint.PRINT_ORDER,
        method: REQUEST_METHOD.POST,
        body: {
            order_id: orderID,
        },
        hasAuth: true,
    }
});

const printsOrder = (orderIDs) => ({
    type: types.PRINTS_ORDER,
    meta: {
        endpoint: endpoint.PRINTS_ORDER,
        method: REQUEST_METHOD.POST,
        body: {
            order_ids: orderIDs,
        },
        hasAuth: true,
    }
});

const printShipping = (shippingID) => ({
    type: types.PRINT_SHIPPING,
    meta: {
        endpoint: endpoint.PRINT_SHIPPING,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_id: shippingID,
        },
        hasAuth: true,
    }
});

const printsShipping = (shippingIDs) => ({
    type: types.PRINTS_SHIPPING,
    meta: {
        endpoint: endpoint.PRINTS_SHIPPING,
        method: REQUEST_METHOD.POST,
        body: {
            shipping_ids: shippingIDs,
        },
        hasAuth: true,
    }
});

export default {
    printOrder,
    printsOrder,
    printShipping,
    printsShipping,
}