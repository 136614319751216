import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

import Modal from '../../../components/ModalV4';
import Input from '../../../components/Input';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../../../config/app';
import { map, find, remove, includes, keys } from 'lodash';

import settingActions from '../../../redux/futures/setting/actions';

export default function ModalShowContentTemplatePrint(props) {
    const dispatch = useDispatch();

    let { onSubmit, onCancel, isLoading, visible, selectedTemplatePrint } = props;
    const [dataPrint, setDataPrint] = useState('');

    const [isShowModalGlossary, setIsShowModalGlossary] = useState(false);

    // const [contentEmail, setContentEmail] = useState("");

    // useEffect(() => {
    //     setContentEmail(props.contentEmail);
    // }, [props.contentEmail])

    useEffect(() => {
        if (selectedTemplatePrint) {
            setDataPrint(selectedTemplatePrint);
        }
    }, [selectedTemplatePrint]);

    const handleChangeEditor = (value) => {
        props.handleChangeEditorValue(value);
    };

    const getOrderGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_address}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_email}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{created_at}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.CUSTOMER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{customer_note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.CUSTOMER_NOTE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_full_name}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_email}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_address}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_note}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_NOTE')}</td>
                    </tr>

                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.price}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.PRICE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.discount_fee}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.total}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.TOTAL')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.ORDER.PAYMENT')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{payment_method}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_METHOD')}</td>
                    </tr>
                    <tr>
                        <td>{'{{payment_status}}'}</td>
                        <td>{t('GLOSSARY.ORDER.PAYMENT_STATUS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{gateway_transaction_id}}'}</td>
                        <td>{t('GLOSSARY.ORDER.GATEWAY_TRANSACTION_ID')}</td>
                    </tr>
                    <tr>
                        <td>{'{{discount_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total_after_discount}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL_AFTER_DISCOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order.discount_shipping_fee}}'}</td>
                        <td>{t('GLOSSARY.ORDER.DISCOUNT_SHIPPING_FEE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{total}}'}</td>
                        <td>{t('GLOSSARY.ORDER.TOTAL')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const getShippingGlossary = () => {
        return (
            <table className='ladiui table glossary'>
                <tbody>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.STORE.STORE_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_name}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{currency_symbol}}'}</td>
                        <td>{t('GLOSSARY.CURRENCY_SYMBOL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_partner_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PARTER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_address}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_phone}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{store_email}}'}</td>
                        <td>{t('GLOSSARY.STORE.STORE_EMAIL')}</td>
                    </tr>
                    <tr>
                        <td>{'{{created_at}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.CREATED_AT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{order_prefix}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.ORDER_PREFIX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{reference_no}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.REFERENCE_NO')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.SHIPPING.CUSTOMER_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_customer_name}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_CUSTOMER_NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_address}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_ADDRESS')}</td>
                    </tr>
                    <tr>
                        <td>{'{{shipping_phone}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SHIPPING_PHONE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{sort_code}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.SORT_CODE')}</td>
                    </tr>
                    <tr>
                        <td>{'{{cod_amount}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.COD_AMOUNT')}</td>
                    </tr>
                    <tr>
                        <td>{'{{note}}'}</td>
                        <td>{t('GLOSSARY.SHIPPING.NOTE')}</td>
                    </tr>
                    <tr>
                        <td className='header' colSpan={2}>
                            {t('GLOSSARY.SHIPPING.PRODUCT_INFO')}
                        </td>
                    </tr>
                    <tr className='header'>
                        <td>{t('GLOSSARY.CODE')}</td>
                        <td>{t('GLOSSARY.MEAN')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.index}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.INDEX')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.name}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.NAME')}</td>
                    </tr>
                    <tr>
                        <td>{'{{item.quantity}}'}</td>
                        <td>{t('GLOSSARY.PRODUCT.QUANTITY')}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const getModalGlossary = () => {
        let data;
        if (dataPrint && dataPrint.CODE) {
            switch (dataPrint.CODE) {
                case 1:
                    data = getOrderGlossary();
                    break;
                case 2:
                    data = getShippingGlossary();
                    break;
            }
        }
        return (
            <Modal
                id='modal-setting-template-print'
                title={t('COMMON.GLOSSARY')}
                visible={isShowModalGlossary}
                onCancel={() => {
                    setIsShowModalGlossary(false);
                }}
                width={650}
                bodyStyles={{ minHeight: '40vh' }}
                hasFooter={false}
            >
                {data}
            </Modal>
        );
    };

    const restore = (e) => {
        e.preventDefault();

        dispatch(settingActions.restoreTemplatePrint(selectedTemplatePrint.store_template_id));

        // this.props.restore(selectedTemplateEmail.store_template_id);
    };

    return (
        <>
            <Modal
                id='modal-content-template-print'
                title={'Chỉnh sửa nội dung mẫu in'}
                onOk={onSubmit}
                onCancel={onCancel}
                isLoading={isLoading}
                visible={visible}
                bodyStyles={{ minHeight: '40vh' }}
                width={900}
            >
                <div className='ladiui flex-between'>
                    <a className='preview-note' onClick={() => setIsShowModalGlossary(true)}>
                        Chú giải
                    </a>
                    <button type='button' onClick={restore} className='back-default ladiui btn btn-outline-secondary btn-sm'>
                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-restore.svg' alt='' className='mr-8' />
                        {t('SETTINGS.BACK_TO_DEFAULT')}
                    </button>
                </div>

                <div className='ladiui form-group'>
                    <Editor
                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                        licenseKey="gpl"
                        value={props.contentTemplatePrint}
                        init={{
                            selector: 'textarea',
                            min_height: 200,
                            max_height: 800,
                            autoresize_overflow_padding: 5,
                            autoresize_bottom_margin: 24,
                            plugins:
                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                            toolbar:
                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                            toolbar_drawer: 'sliding',
                            language_url: '../../../language/tinymce-vi.js',
                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                            valid_elements: "*[*]",
                        }}
                        onEditorChange={handleChangeEditor}
                    />
                </div>
            </Modal>
            {getModalGlossary()}
        </>
    );
}
