import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import Image from '../../../components/Image';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ModalChooseIcon from './ModalChooseIcon';
import appConfig from '../../../config/app';
import { isEmpty } from 'lodash';
import SelectOption from '../../../components/SelectOption';
// eslint-disable-next-line max-lines-per-function
function ModalAddMenuItem(props) {
    const {
        t,
        onClose,
        isShow,
        innerStyle,
        selectedDiscount,
        handleAddContentLocation,
        dataSelect,
        selectedIndex,
        selectedData,
        handleUpdateMenuItem,
        handleCreateMenuItem,
    } = props;
    const { data, index, type } = selectedData;
    const wrapperRef = useRef(null);

    const [isShowModalChooseIcon, setIsShowModalChooseIcon] = useState(false);
    const [discounts, setDiscounts] = useState([]);

    const dispatch = useDispatch();

    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [openLinkTarget, setOpenLinkTarget] = useState('_blank');
    const [icon, setIcon] = useState(null);
    const [isUpdateModal, setIsUpdateModal] = useState(false);
    const [menuType, setMenuType] = useState(appConfig.MENU_CONFIG_TYPES.VERTICAL);

    useEffect(() => {
        if (selectedData) {
            setTitle(selectedData.data && selectedData.data.title ? selectedData.data.title : title);
            setLink(selectedData.data && selectedData.data.link ? selectedData.data.link : link);
            setOpenLinkTarget(
                selectedData.data && selectedData.data.open_link_target ? selectedData.data.open_link_target : openLinkTarget
            );
            setIcon(selectedData.data && selectedData.data.icon ? selectedData.data.icon : icon);
            setIsUpdateModal(selectedData.isUpdate ? selectedData.isUpdate : isUpdateModal);
            setMenuType(selectedData.type || menuType);
        }
    }, [selectedData]);

    const eventCancel = () => {
        onClose(false);
    };

    const onChangeTitle = (e) => {
        let { value } = e.target;

        setTitle(value);
    };

    const onChangeLink = (e) => {
        let { value } = e.target;

        setLink(value);
    };

    const handleClickCreateOrUpdate = () => {
        let payload = {
            title,
            icon,
            link,
            open_link_target: openLinkTarget,
        };

        if (isEmpty(String(title))) {
            window.LadiUI.showErrorMessage('Thông báo', 'Vui lòng nhập tiêu đề menu', 'OK');
            // window.LadiUI.toast('error', '', 'Vui lòng nhập tên nút', 5000, 'bottom-left');
            return;
        }

        if (isUpdateModal) {
            handleUpdateMenuItem(menuType, index, payload);
        } else {
            handleCreateMenuItem(menuType, payload);
        }
    };

    const onSelectIcon = (value) => {
        setIcon(value);
    };

    const handleDeleteIconMenu = () => {
        setIcon(null);
    }

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='homepage-discount-modal'>
                <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Cấu hình menu</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body'>
                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Tiêu đề menu</label>
                                <input
                                    className='ladiui form-control'
                                    value={title}
                                    placeholder='Nhập vào tiêu đề menu'
                                    onChange={onChangeTitle}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Link</label>
                                <input className='ladiui form-control' value={link} placeholder='Đường dẫn liên kết của menu. (VD: https://www.duonglinkcuaban.com)' onChange={onChangeLink} />
                            </div>

                            <div className='ladiui form-group'>
                                <label className='ladiui-label'>Mở link</label>
                                <SelectOption
                                    wrapperStyle={{
                                        minWidth: '250px',
                                        width: '100%',
                                        display: 'inline-block',
                                        zIndex: 999,
                                    }}
                                    innerStyle={{ width: '100%', minWidth: '100%' }}
                                    customStyle={{ padding: '0px', width: '100%', zIndex: '1000' }}
                                    wrapperClassName='select-option-checkout-config'
                                    innerClassName='ellipsis'
                                    currentKey={openLinkTarget}
                                    items={
                                        [
                                            {
                                                key: '_blank',
                                                name: 'Mở trong cửa sổ mới',
                                            },
                                            {
                                                key: '_self',
                                                name: 'Mở trong cửa sổ hiện tại',
                                            },
                                        ]
                                    }
                                    _key={'key'}
                                    _value={'name'}
                                    onSelectItem={(item) => {
                                        setOpenLinkTarget(item.key);
                                    }}
                                />
                            </div>

                            <div className='ladiui form-group'>
                                <div class='choose-icon-area'>
                                    <button type='button' className='ladiui btn btn-primary' onClick={() => setIsShowModalChooseIcon(true)}>
                                        Chọn biểu tượng
                                    </button>
                                    {icon && (
                                        <>
                                            <div class='icon-item-selected relative'>
                                                <i class='material-symbols-outlined'>{icon}</i>
                                            </div>
                                            <div class='ls-delete-text' onClick={() => handleDeleteIconMenu()}>Xoá biểu tượng</div>
                                        </>
                                    
                                    )}
                                </div>
                            </div>
                            
                        </div>

                        <div className='ladiui modal-footer '>
                            <div className='ladiui footer-modal-btn'>
                                <button type='button' className='ladiui btn btn-secondary bold' onClick={eventCancel}>
                                    {t('ACTIONS.CLOSE')}
                                </button>
                                <button type='button' className='ladiui btn btn-primary' onClick={() => handleClickCreateOrUpdate()}>
                                    {isUpdateModal ? 'Cập nhật menu' : 'Thêm menu'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isShowModalChooseIcon && (
                <ModalChooseIcon
                    onClose={(status) => setIsShowModalChooseIcon(status)}
                    isShow={isShowModalChooseIcon}
                    onSelectIcon={onSelectIcon}
                />
            )}
        </>
    );
}

export default withTranslation()(ModalAddMenuItem);
