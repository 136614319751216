import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker, TwitterPicker } from 'react-color';
import BaseHelper from '../../../../../helpers/BaseHelper';

import pageCheckoutDefined from '../../components/pageCheckoutDefined';
import Popover from '../../../../../components/Popover';
import Input from '../../../../../components/Input';
import HandelSection from './HandelSection';
import PageHomeChannel from './PageHomeChannel';

// eslint-disable-next-line max-lines-per-function

const PageFooter = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { isShow, footerConfig, pageStoreConfig } = props;

    const handelSectionRef = useRef();
    const handelPageHomeChannelRef = useRef();

    const [isShowPolicy, setShowPolicy] = useState(false);
    const [sections, setSections] = useState([]);
    const [channels, setChannels] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');

    useImperativeHandle(ref, () => ({
        getData: () => {
            let sections = handelSectionRef.current.getData();
            let channels = handelPageHomeChannelRef.current.getData();
            return {
                sections,
                is_show_policy: isShowPolicy,
                backgroundColor,
                channels,
            };
        },
    }));

    useEffect(() => {
        if (footerConfig) {
            setShowPolicy(footerConfig.is_show_policy);
            setSections(footerConfig.sections || []);
            setBackgroundColor(footerConfig.backgroundColor || '#ffffff');
        }
    }, [footerConfig]);

    useEffect(() => {
        if (pageStoreConfig) {
            setChannels(pageStoreConfig.channels || []);
        }
    }, [pageStoreConfig]);

    return (
        <div className={`widget-config-item  ${isShow ? 'show' : 'hide'} page-footer`}>
            <div>
                <label className='ladiui label-14'> {t('SALE_CHANNEL.PAGE_FOOTER_LABEL')}</label>
                <div className='ladiui text-14'>{t('SALE_CHANNEL.PAGE_FOOTER_SUB_LABEL')}</div>
            </div>

            <div>
                <div className='ladiui mt-20'>
                    <div className='ladiui text-14 '>Màu nền</div>
                    <div className='mt-8'>
                        {/* <div className='ladiui text-14'>Màu chủ đạo</div> */}
                        <div className=' change-color-block'>
                            <Popover
                                wrapperProps={{ className: 'w-100-p' }}
                                wrapperPopoverProps={{ className: 'popover-picker' }}
                                styleCustom={{ bottom: 50, left: 0, position: 'absolute' }}
                                content={
                                    <SketchPicker
                                        color={backgroundColor}
                                        onChange={(color) => {
                                            setBackgroundColor(BaseHelper.addAlpha(color.hex, color.rgb.a));
                                        }}
                                    />
                                }
                            >
                                <div className='color-change w-100-p' style={{ background: backgroundColor }}></div>
                            </Popover>
                            <Input
                                className='color-input'
                                value={backgroundColor}
                                onChange={(e) => {
                                    setBackgroundColor(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <HandelSection ref={handelSectionRef} sectionData={sections} type='SETUP_FOOTER' />
                <div className='mt-20 pt-20 ladiui border-t-50p'>
                    <label className='ladiui label-14'>{t('SALE_CHANNEL.CHANNEL_LABLE')}</label>
                    <div className='ladiui text-14 mt-4'>{t('SALE_CHANNEL.CHANNEL_LABLE_SUB')}</div>

                    <PageHomeChannel ref={handelPageHomeChannelRef} sectionData={channels || []} />
                </div>
                <div className='ladiui-label style-switch mt-20'>
                    <div className='ladiui item-form switch '>
                        <label className='ladiui switch'>
                            <input
                                type='checkbox'
                                name='using_new_style'
                                value={isShowPolicy}
                                onChange={(event) => {
                                    setShowPolicy(!isShowPolicy);
                                }}
                                checked={isShowPolicy || false}
                            />
                            <span className='ladiui slider round'></span>
                        </label>
                    </div>
                    <span>{t('SALE_CHANNEL.USING_POLICY')}</span>
                </div>
            </div>
        </div>
    );
});

export default PageFooter;
