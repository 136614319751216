import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, map, compact, omit } from 'lodash';
import { connect } from 'react-redux';
import Input from '../../../../../components/Input';
import appConfig from '../../../../../config/app';
import { withTranslation } from 'react-i18next';
import ModalImageVariant from './ImageVariant';
import Image from '../../../../../components/Image';
import Modal from '../../../../../components/Modal';
import NumberInput from '../../../../../components/NumberInput';
import SelectOption from '../../../../../components/SelectOption';
import { appLocalStorage } from '../../../../../localforage';
import baseHelper from '../../../../../helpers/BaseHelper';

import variantActions from '../../../../../redux/futures/product_variant/actions';
import * as variantTypes from '../../../../../redux/futures/product_variant/types';

class ModalVariant extends React.Component {
    static propTypes = {
        currentStore: PropTypes.object,
        mode: PropTypes.string,
        currentVariantInfo: PropTypes.object,
        images: PropTypes.array,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            variant: {
                product_id: '',
                variant_id: '',
                price: '0',
                price_compare: '',
                cost_per_item: '',
                src: '',
                min_buy: 1,
                max_buy: null,
                src_hash: '',
                sku: '',
                weight: '',
                weight_unit: appConfig.WEIGHT_UNITS.G.CODE,
                inventory_checked: appConfig.INVENTORY_STATUS.INACTIVE.CODE,
                allow_sold_out: appConfig.STATUS.INACTIVE,
                quantity: 0,
            },
            options: [],
            userInfo: {
                currentStore: {},
            },
            isShowModalImageVariant: false,
        };

        this.ref = React.createRef();
        this.inputsRef = new Set();
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        if (this.props.currentVariantInfo) {
            this.init(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.currentVariantInfo !== nextProps.currentVariantInfo) {
            this.init(nextProps);
        }

        if (this.props.variantReducer.action != nextProps.variantReducer.action) {
            if (nextProps.variantReducer.action === variantTypes.CHANGE_IMAGE_VARIANT) {
                if (nextProps.variantReducer.status) {
                    this.setState({
                        isShowModalImageVariant: false,
                        isLoadingChangeImage: false,
                    });
                }
            }
        }
    }

    init = (props) => {
        const variant = cloneDeep(props.currentVariantInfo) || {};
        const options = cloneDeep(variant.options);

        const newVariant = {};
        map(this.state.variant, (value, key) => {
            let newValue = !baseHelper.isEmpty(variant[key]) ? variant[key] : '';
            if (props.mode == appConfig.FORM_MODE.CREATE) {
                if (!newValue) {
                    newValue = value;
                }
            }
            newVariant[key] = newValue;
        });
        newVariant.variant_id = variant.product_variant_id;

        const newOptions = options;
        this.setState({
            variant: newVariant,
            options: newOptions,
        });
    };

    onChangeInput = (event) => {
        this.setState({
            variant: {
                ...this.state.variant,
                [event.target.name]: event.target.value,
            },
        });
    };

    onChangeOption = (event, index) => {
        const { options } = this.state;
        const { value } = event.target;
        options[index].option_value_value.name = value;

        this.setState({
            options,
        });
    };

    openModalImageVariant = () => {
        this.setState({
            isShowModalImageVariant: true,
        });
    };

    changeImage = (image) => {
        this.setState(
            {
                variant: {
                    ...this.state.variant,
                    src: image.src,
                    src_hash: image.src_hash,
                },
            },
            () => {
                if (this.props.mode == appConfig.FORM_MODE.CREATE) {
                    this.setState({
                        isShowModalImageVariant: false,
                    });
                } else {
                    const updateVariant = {
                        product_id: this.state.variant.product_id,
                        variant_id: this.state.variant.variant_id,
                        src: this.state.variant.src,
                        src_hash: this.state.variant.src_hash,
                    };
                    this.props.changeImage(updateVariant);
                }
            }
        );
    };

    deleteImage = () => {
        this.setState(
            {
                variant: {
                    ...this.state.variant,
                    src: '',
                    src_hash: '',
                },
            },
            () => {
                if (this.props.mode == appConfig.FORM_MODE.CREATE) {
                    this.setState({
                        isShowModalImageVariant: false,
                    });
                } else {
                    const updateVariant = {
                        product_id: this.state.variant.product_id,
                        variant_id: this.state.variant.variant_id,
                        src: '',
                        src_hash: '',
                    };
                    this.props.changeImage(updateVariant);
                }
            }
        );
    };

    submit = () => {
        // Validate
        let errors = [];
        const inputsRef = compact(Array.from(this.inputsRef));
        map(inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        const options = cloneDeep(this.state.options);
        const variant = cloneDeep(omit(this.state.variant));

        if (variant.inventory_checked && variant.quantity < 0) {
            const errorMessage = this.props.t('PRODUCTS.VARIANT_QUANTITY_ERROR');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        if (this.props.mode === appConfig.FORM_MODE.CREATE) {
            variant.options = map(options, (option) => {
                return {
                    option_id: option.option_id,
                    option_value_value: option.option_value_value,
                };
            });
        } else {
            variant.options = options;
        }

        this.props.onSubmit(variant);
    };

    render() {
        const { t } = this.props;
        const { variant, options } = this.state;

        const { currency_symbol: currencySymbol, currency_code: currencyCode } = this.state.userInfo.currentStore;

        const title = this.props.mode === appConfig.FORM_MODE.CREATE ? t('PRODUCTS.ADD_VARIANT') : t('PRODUCTS.EDIT_VARIANT');
        const isLoadingChangeImage =
            this.props.variantReducer.loading && this.props.variantReducer.waiting == variantTypes.CHANGE_IMAGE_VARIANT;
        return (
            <>
                <Modal
                    id='modal-variant'
                    zIndex={1050}
                    title={title}
                    onCancel={this.props.onCancel}
                    onOk={this.submit}
                    isLoading={this.props.isLoading}
                    visible={this.props.visible}
                    bodyStyles={{ minHeight: '50vh' }}
                    width={900}
                    position_unset={true}
                >
                    <div className='ladiui-col-3 pull-left'>
                        <div className='text-center  image setting-block-image'>
                            <div style={{ width: '100%', height: 250 }}>
                                <Image src={variant.src} />
                            </div>
                            <div className='ladiui-button-upload image block-upload-image'>
                                <div className='block-overlay' style={{ marginTop: '80px' }}>
                                    <div className='ladiui btn btn-change-image' onClick={this.openModalImageVariant}>
                                        <i className='ladiui icon-new new-ldicon-refresh' />
                                        <span>Thay đổi hình ảnh</span>
                                    </div>
                                    {variant.src && (
                                        <div className='btn-delete-variant-image' onClick={this.deleteImage}>
                                            <i className='ladiui icon-new new-ldicon-delete' />
                                            <span>Xóa ảnh</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* <div className="ladiui-button-upload text-center">
                                <button className="ladiui btn btn-primary bn-sm " onClick={this.openModalImageVariant}><i className="ladi-icon icon-data-upload" />{t('ACTIONS.PICK_IMAGE')}</button>
                            </div> */}
                        </div>
                    </div>
                    <div className='ladiui-col-7 pull-right'>
                        <div>
                            {map(options, (option, index) => (
                                <div key={index} className='ladiui form-group'>
                                    <label className='ladiui-label'>{option.option_name}</label>
                                    <Input
                                        ref={(ref) => this.inputsRef.add(ref)}
                                        validationName={option.option_name}
                                        value={option.option_value_value.name}
                                        onChange={(event) => this.onChangeOption(event, index)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                            ))}
                            <div className='ladiui form-group group-prices'>
                                <div className='price-item variant-price mr-12'>
                                    <label className='ladiui-label'>{t('PRODUCTS.PRICE')}</label>
                                    <div className='ladiui-input-group'>
                                        <NumberInput
                                            name='price'
                                            prefix={currencySymbol}
                                            value={variant.price}
                                            onChange={this.onChangeInput}
                                        />
                                    </div>
                                </div>
                                <div className='price-item variant-price mr-12'>
                                    <label className='ladiui-label'>{t('PRODUCTS.PRICE_COMPARE')}</label>
                                    <div className='ladiui-input-group'>
                                        <NumberInput
                                            name='price_compare'
                                            prefix={currencySymbol}
                                            value={variant.price_compare}
                                            onChange={this.onChangeInput}
                                        />
                                    </div>
                                </div>
                                <div className='price-item'>
                                    <label className='ladiui-label'>{t('PRODUCTS.COST')}</label>
                                    <div className='ladiui-input-group'>
                                        <NumberInput
                                            name='cost_per_item'
                                            prefix={currencySymbol}
                                            value={variant.cost_per_item}
                                            onChange={this.onChangeInput}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-end gap-24'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>{t('PRODUCTS.SKU')}</label>
                                    <Input name='sku' value={variant.sku} onChange={this.onChangeInput} />
                                </div>

                                <div className='ladiui form-group group-weight pos-rel'>
                                    <label className='ladiui-label'>{t('COMMON.WEIGHT')}</label>
                                    <NumberInput name='weight' value={variant.weight} onChange={this.onChangeInput} onlyNumber={true} />
                                    <SelectOption
                                        customStyle={{
                                            width: '45px',
                                            height: '42px',
                                            position: 'absolute',
                                            top: '22px',
                                            right: '0px',
                                        }}
                                        directionUp={true}
                                        wrapperClassName='mini-dropdown'
                                        innerClassName='select-weight mini-dropdown mini-dropdown-pd'
                                        currentValue={variant.weight_unit}
                                        items={appConfig.WEIGHT_UNITS}
                                        _key='CODE'
                                        _value='NAME'
                                        onSelectItem={(item) => {
                                            this.setState({
                                                variant: {
                                                    ...this.state.variant,
                                                    weight_unit: item.CODE,
                                                },
                                            });
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='flex items-end mt-24 gap-24'>
                                <div className='ladiui form-group mt-0' style={{ width: 'calc(50% - 12px)' }}>
                                    <label className='ladiui-label new'>{t('ORDERS.INVENTORY')}</label>
                                    <SelectOption
                                        innerClassName='select-weight pr-30'
                                        customStyle={{ width: '100%' }}
                                        innerStyle={{ width: '100%' }}
                                        currentKey={variant.inventory_checked}
                                        items={appConfig.INVENTORY_STATUS}
                                        _key={'CODE'}
                                        _value={'NAME'}
                                        onSelectItem={(item) => {
                                            this.setState({
                                                variant: {
                                                    ...this.state.variant,
                                                    inventory_checked: item.CODE,
                                                },
                                            });
                                        }}
                                    />
                                </div>

                                {variant.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE && (
                                    <div className='ladiui form-group mt-0' style={{ width: 'calc(50% - 12px)' }}>
                                        <label className='ladiui-label'>{t('COMMON.QUANTITY')}</label>
                                        <NumberInput
                                            name='quantity'
                                            value={variant.quantity}
                                            onChange={this.onChangeInput}
                                            onlyNumber={true}
                                            thousandSeparator={false}
                                            decimalScale={0}
                                            allowNegative={true}
                                            min={0}
                                        />
                                    </div>
                                )}
                            </div>

                            {variant.inventory_checked == appConfig.INVENTORY_STATUS.ACTIVE.CODE && (
                                <div className='ladiui form-group flex' style={{}}>
                                    <input
                                        id='allowPurchaseWhenSoldOut'
                                        onChange={(event) => {
                                            this.setState({
                                                variant: {
                                                    ...this.state.variant,
                                                    allow_sold_out:
                                                        variant.allow_sold_out == appConfig.STATUS.ACTIVE
                                                            ? appConfig.STATUS.INACTIVE
                                                            : appConfig.STATUS.ACTIVE,
                                                },
                                            });
                                        }}
                                        checked={variant.allow_sold_out == appConfig.STATUS.ACTIVE}
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                    />
                                    <label> {t('INVENTORY.ALLOW_SOLD_OUT')}</label>
                                </div>
                            )}

                            {this.state.isShowAdvanced && (
                                <div className='flex items-end gap-24'>
                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>{t('PRODUCTS.PR_MIN_BUY')}</label>
                                        <NumberInput
                                            name='min_buy'
                                            value={variant.min_buy}
                                            onChange={this.onChangeInput}
                                            onlyNumber={true}
                                            thousandSeparator={false}
                                            decimalScale={0}
                                            allowNegative={true}
                                            validations={{
                                                min: 1,
                                            }}
                                            ref={(ref) => this.inputsRef.add(ref)}
                                            validationName={t('PRODUCTS.PR_MIN_BUY')}
                                        />
                                    </div>

                                    <div className='ladiui form-group'>
                                        <label className='ladiui-label new'>{t('PRODUCTS.PR_MAX_BUY')}</label>
                                        <NumberInput
                                            name='max_buy'
                                            value={variant.max_buy}
                                            onChange={this.onChangeInput}
                                            onlyNumber={true}
                                            thousandSeparator={false}
                                            decimalScale={0}
                                            allowNegative={true}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='is-Show-Advanced product mt-24'>
                                <button
                                    onClick={() =>
                                        this.setState({
                                            isShowAdvanced: !this.state.isShowAdvanced,
                                        })
                                    }
                                    className='ladiui btn btn-secondary'
                                >
                                    {this.state.isShowAdvanced ? t('COMMON.HIDE') : t('PRODUCT_EVENTS.BTN_TICKET_SHOW_ADVANCED')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {this.state.isShowModalImageVariant && (
                    <ModalImageVariant
                        variant={variant}
                        visible={this.state.isShowModalImageVariant}
                        images={this.props.images}
                        onCancel={() =>
                            this.setState({
                                isShowModalImageVariant: false,
                            })
                        }
                        submit={this.changeImage}
                        isLoading={isLoadingChangeImage}
                    />
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeImage: (variant) => dispatch(variantActions.changeImage(variant)),
    };
};

const mapStateToProps = (state) => ({
    variantReducer: { ...state.variant },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    withTranslation('translation', { withRef: true })(ModalVariant)
);
