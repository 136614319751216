import React from 'react';

import baseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';
import { cloneDeep, includes, map, find } from 'lodash';

import * as shippingTypes from '../../redux/futures/shipping/types';
import shippingActions from '../../redux/futures/shipping/actions';

import * as printTypes from '../../redux/futures/print/types';
import printActions from '../../redux/futures/print/actions';

import { connect } from 'react-redux';

import ConfirmModal from '../../components/ConfirmModal';
import PanelCustomer from '../../pages/orders/components/OrderCustomer';
import PanelShippingAddress from '../../pages/orders/components/ShippingAddress';
import { withTranslation } from 'react-i18next';
import LoadingSence from '../../components/LoadingScene';
import ShippingInfo from './components/ShippingInfo';
import ModalShippingFee from './components/ModalShippingFee';
import ModalShippingStatus from './components/ModalShippingStatus';
import ModalPaymentStatus from './components/ModalPaymentStatus';
import Image from '../../components/Image';
import History from '../../components/History';
import Header from './components/Header';
import { ShippingProducts } from './components/ShippingProducts';
import { ShippingPayment } from './components/ShippingPayment';

class ShippingEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            note: '',
            fee: null,
            trackingNumber: null,
            isShowModalShippingFee: false,
            isShowModalShippingStatus: false,
            isShowModalPaymentStatus: false,
            updatedTrackingNumber: false,
            statusCode: '',
        };
    }

    componentDidMount() {
        this.props.show(this.props.match.params['shippingID']);
    }

    componentDidUpdate() {
        window.LadiUI.init();
        setTimeout(() => {
            window.LadiUI.customInit();
        }, 0);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.shippingReducer.action != nextProps.shippingReducer.action) {
            if (nextProps.shippingReducer.action === shippingTypes.SHOW_SHIPPING) {
                if (nextProps.shippingReducer.status) {
                    const {
                        shipping: { note },
                    } = nextProps.shippingReducer;
                    this.setState({
                        note: cloneDeep(note),
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                    this.props.history.push('/shippings');
                }
            }

            if (nextProps.shippingReducer.action === shippingTypes.UPDATE_SHIPPING) {
                if (nextProps.shippingReducer.status) {
                    this.props.show(this.props.match.params['shippingID']);
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_FEE) {
                if (nextProps.shippingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);
                    this.hideModalShippingFee();
                    this.props.show(this.props.match.params['shippingID']);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (nextProps.shippingReducer.action == shippingTypes.DELETE_SHIPPING) {
                if (nextProps.shippingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);
                    window.LadiUI.closeModal('confirm-shipping-delete');

                    this.props.history.push('/shippings');
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_TRACKING_NUMBER) {
                if (nextProps.shippingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);
                    this.setState({
                        updatedTrackingNumber: true,
                    });

                    this.props.show(this.props.match.params['shippingID']);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_STATUS) {
                if (nextProps.shippingReducer.status) {
                    if (this.state.statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE) {
                        window.LadiUI.closeModal('confirm-shipping-cancel');
                    }
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);

                    this.props.show(this.props.match.params['shippingID']);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }

            if (nextProps.shippingReducer.action == shippingTypes.SHIPPING_UPDATE_PAYMENT_STATUS) {
                if (nextProps.shippingReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.shippingReducer.message);
                    // this.hideModalPaymentStatus();

                    this.props.show(this.props.match.params['shippingID']);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.shippingReducer.message, 'OK');
                }
            }
        }

        if (this.props.printReducer.action != nextProps.printReducer.action) {
            if (nextProps.printReducer.action == printTypes.PRINT_SHIPPING) {
                if (nextProps.printReducer.status) {
                    baseHelper.print(nextProps.printReducer.source);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.printReducer.message, 'OK');
                }
            }
        }
    }

    onChangeInput = (event) => {
        this.setState({
            ...this.state.shipping,
            [event.target.name]: event.target.value,
        });
    };

    /*******************************MODAL***************************/
    // openModalShippingStatus = () => {
    //     this.setState({
    //         statusCode: this.props.shippingReducer.shipping.status,
    //         isShowModalShippingStatus: true,
    //     });
    // }

    // hideModalShippingStatus = () => {
    //     this.setState({
    //         isShowModalShippingStatus: false,
    //     });
    // }

    // openModalPaymentStatus = () => {
    //     this.setState({
    //         paymentStatusCode: this.props.shippingReducer.shipping.payment_status,
    //         isShowModalPaymentStatus: true,
    //     });
    // }

    // hideModalPaymentStatus = () => {
    //     this.setState({
    //         isShowModalPaymentStatus: false,
    //     });
    // }

    openModalShippingFee = () => {
        this.setState({
            fee: this.props.shippingReducer.shipping.fee,
            isShowModalShippingFee: true,
        });
    };

    hideModalShippingFee = () => {
        this.setState({
            isShowModalShippingFee: false,
        });
    };
    /****************************************************************/

    /*******************************CHANGE STATUS***************************/
    // updateStatus = (shippingID, statusCode) => {
    //     this.setState({
    //         statusCode
    //     });

    //     if (statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE) {
    //         window.LadiUI.showModal('confirm-shipping-cancel');
    //         return;
    //     }
    //     this.props.updateStatus(shippingID, statusCode);
    // }

    onChangeShippingStatus = (shippingID, statusCode) => {
        this.setState({
            statusCode,
        });

        if (statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE) {
            window.LadiUI.showModal('confirm-shipping-cancel');
            return;
        }
        this.props.updateStatus(shippingID, statusCode);
    };

    onChangePaymentStatus = (shippingID, statusCode) => {
        this.props.updatePaymentStatus(shippingID, statusCode);
    };

    // updatePaymentStatus = (shippingID, paymentStatusCode) => {
    //     this.props.updatePaymentStatus(shippingID, paymentStatusCode);
    // }

    updateFee = (shippingID, fee) => {
        this.props.updateFee(shippingID, fee);
    };

    updateTrackingNumber = (shippingID, trackingNumber) => {
        this.props.updateTrackingNumber(shippingID, trackingNumber);
    };
    /*********************************************************************/

    onChangeAddress = (address, addressType) => {
        const { shipping } = this.props.shippingReducer;
        let updateShipping;
        if (addressType == appConfig.ADDRESS_TYPE.BILLING) {
            updateShipping = {
                shipping_id: shipping.shipping_id,
                billing_address: {
                    ...address,
                },
            };
        } else {
            updateShipping = {
                shipping_id: shipping.shipping_id,
                shipping_address: {
                    ...address,
                },
            };
        }

        this.props.update(updateShipping);
    };

    openOptionDeleteModal = () => {
        window.LadiUI.showModal('confirm-shipping-delete');
    };

    print = () => {
        this.props.print(this.props.match.params['shippingID']);
    };

    submit = () => {
        const data = {
            shipping_id: this.props.shippingReducer.shipping.shipping_id,
            note: this.state.note,
            tags: [],
        };

        this.props.update(data);
    };

    getNameShippingByCode = (code) => {
        const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == code) || {};
        return matched.NAME;
    };
    getNamPaymentByCode = (code) => {
        const matched = find(appConfig.SHIPPING_PAYMENT_STATUS, (item) => item.CODE == code) || {};
        return matched.NAME;
    };

    render() {
        const { t } = this.props;
        const { shipping } = this.props.shippingReducer;
        const { note, fee, statusCode } = this.state;
        const isLoadingData =
            this.props.shippingReducer.loading &&
            includes([shippingTypes.SHOW_SHIPPING, shippingTypes.UPDATE_SHIPPING], this.props.shippingReducer.waiting);
        const isLoadingPrint = this.props.printReducer.loading && printTypes.PRINT_SHIPPING == this.props.printReducer.waiting;
        const isLoadingDelete =
            this.props.shippingReducer.loading && includes([shippingTypes.DELETE_SHIPPING], this.props.shippingReducer.waiting);
        const isLoadingUpdateStatus =
            this.props.shippingReducer.loading &&
            shippingTypes.SHIPPING_UPDATE_STATUS == this.props.shippingReducer.waiting &&
            statusCode != appConfig.SHIPPING_STATUS.CANCELED.CODE;
        const isLoadingCancel =
            this.props.shippingReducer.loading &&
            shippingTypes.SHIPPING_UPDATE_STATUS == this.props.shippingReducer.waiting &&
            statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE;
        const isLoadingUpdatePaymentStatus =
            this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_PAYMENT_STATUS == this.props.shippingReducer.waiting;
        const isLoadingUpdateFee =
            this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_FEE == this.props.shippingReducer.waiting;
        const isLoadingUpdateTrackingNumber =
            this.props.shippingReducer.loading && shippingTypes.SHIPPING_UPDATE_TRACKING_NUMBER == this.props.shippingReducer.waiting;

        const { items } = shipping;

        const shippingAddress = baseHelper.isEmpty(shipping)
            ? {}
            : {
                  first_name: shipping.shipping_first_name,
                  last_name: shipping.shipping_last_name,
                  company: shipping.shipping_company,
                  phone: shipping.shipping_phone,
                  address: shipping.shipping_address,
                  apartment: shipping.shipping_apartment,
                  ward_name: shipping.shipping_ward_name,
                  district_name: shipping.shipping_district_name,
                  state_name: shipping.shipping_state_name,
                  country_name: shipping.shipping_country_name,
                  postal_code: shipping.shipping_postal_code,
              };

        return (
            <div className='shipping-details'>
                {(isLoadingData || isLoadingPrint) && <LoadingSence blur={true} />}
                <Header print={this.print} submit={this.submit} shippingID={shipping.shipping_id} />

                <div className='shipping-edits'>
                    <div className='page-content ladiui content-max-width'>
                        <div className='ladiui label-18 display-flex-center gap-8'>
                            {`${t('SHIPPINGS.TITLE')} #${shipping.shipping_id}`}
                        </div>
                        <div className='order-assign-store display-flex-center gap-12 mt-10'>
                            {shipping && shipping.created_at && (
                                <div>
                                    {`${t('SHIPPINGS.CREATED_AT')} ${baseHelper.formatDateToStr(
                                        shipping.created_at,
                                        appConfig.DEFAULT_FORMAT_DATE_TIME
                                    )}`}
                                </div>
                            )}
                        </div>
                        <div className='ladiui group-body mt-20'>
                            <div className='ladiui body-right product-payment-comment '>
                                <div className='ladiui container-place body-right'>
                                    <ShippingProducts
                                        variantSelected={items}
                                        shipping={shipping}
                                        onChangeShippingStatus={this.onChangeShippingStatus}
                                    />
                                </div>
                                <div className='ladiui container-place body-right mt-20'>
                                    <ShippingPayment
                                        shipping={shipping}
                                        openModalShippingFee={this.openModalShippingFee}
                                        onChangePaymentStatus={this.onChangePaymentStatus}
                                        getNamPaymentByCode={this.getNamPaymentByCode}
                                    />
                                </div>
                                <div className={`order-historys mt-20`}>
                                    <div className='ladiui label-16 mb-20 '> {t('COMMON.ACTIVE')}</div>
                                    <History type={appConfig.HISTORY_FORM.SHIPPING} shippingID={this.props.match.params['shippingID']} />
                                </div>
                            </div>

                            <div className='ladiui body-left customer-shipping'>
                                <div className='ladiui container-place body-left'>
                                    <ShippingInfo
                                        shipping={shipping}
                                        onSubmit={this.updateTrackingNumber}
                                        isLoading={isLoadingUpdateTrackingNumber}
                                        updated={this.state.updatedTrackingNumber}
                                    />
                                </div>
                                <div className='ladiui container-place body-left mt-20'>
                                    <PanelCustomer
                                        mode={appConfig.FORM_MODE.VIEW}
                                        checkout={shipping}
                                        orderCustomer={{
                                            customer_id: shipping.customer_id,
                                            first_name: shipping.customer_first_name,
                                            last_name: shipping.customer_last_name,
                                            phone: shipping.customer_phone,
                                            email: shipping.customer_email,
                                        }}
                                    />
                                </div>
                                <div className='ladiui container-place body-left mt-20'>
                                    <div className='ladiui label-18 pb-12'>{t('SHIPPINGS.NOTE_LABEL')}</div>
                                    <textarea
                                        className='order-note-value'
                                        placeholder={t('SHIPPINGS.NOTE')}
                                        value={note}
                                        name='note'
                                        onChange={this.onChangeInput}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='block-content-shippings ladiui pos-rel'>
                            {/* <div className='ladiui-order-left'>
                                <div className='ladi-card order-products' style={{ marginTop: 12, marginBottom: '24px' }}>
                                    <div className='footer-order'>
                                        <div className='w-49-p'>
                                            <div className='title-lable mb-10'>Ghi chú</div>
                                            <textarea
                                                className='ladiui textarea'
                                                value={note || ''}
                                                name='note'
                                                onChange={this.onChangeInput}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='ladiui-order-right t-145'>
                                <div className='shiping-info-edit custom'>
                                    <ShippingInfo
                                        shipping={shipping}
                                        onSubmit={this.updateTrackingNumber}
                                        isLoading={isLoadingUpdateTrackingNumber}
                                        updated={this.state.updatedTrackingNumber}
                                    />
                                </div>
                                <div className='shipping-info-customer shipping-edit'>
                                    <PanelCustomer
                                        mode={appConfig.FORM_MODE.VIEW}
                                        orderCustomer={{
                                            customer_id: shipping.customer_id,
                                            first_name: shipping.customer_first_name,
                                            last_name: shipping.customer_last_name,
                                            phone: shipping.customer_phone,
                                            email: shipping.customer_email,
                                        }}
                                    />
                                    <div className='shipping-address'>
                                        <PanelShippingAddress
                                            ref={this.panelShippingAddressRef}
                                            mode={appConfig.FORM_MODE.VIEW}
                                            shippingAddress={shippingAddress}
                                            onChangeAddress={this.onChangeAddress}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isShowModalShippingFee && (
                    <ModalShippingFee
                        shippingID={shipping.shipping_id}
                        fee={fee}
                        visible={this.state.isShowModalShippingFee}
                        onSubmit={this.updateFee}
                        onCancel={this.hideModalShippingFee}
                        isLoading={isLoadingUpdateFee}
                    />
                )}

                {/* {
                    this.state.isShowModalShippingStatus
                    &&
                    <ModalShippingStatus
                        shippingID={shipping.shipping_id}
                        statusCode={shipping.status}
                        visible={this.state.isShowModalShippingStatus}
                        onSubmit={this.updateStatus}
                        onCancel={this.hideModalShippingStatus}
                        isLoading={isLoadingUpdateStatus}
                    />
                } */}

                {/* {
                    this.state.isShowModalPaymentStatus
                    &&
                    <ModalPaymentStatus
                        shippingID={shipping.shipping_id}
                        paymentStatusCode={shipping.payment_status}
                        visible={this.state.isShowModalPaymentStatus}
                        onSubmit={this.updatePaymentStatus}
                        onCancel={this.hideModalPaymentStatus}
                        isLoading={isLoadingUpdatePaymentStatus}
                    />
                } */}

                <ConfirmModal
                    id='confirm-shipping-delete'
                    title={t('SHIPPINGS.MSG_DELETE_SHIPPING_TITLE')}
                    content={t('SHIPPINGS.MSG_DELETE_SHIPPINGS_CONTENT')}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => this.props.delete(shipping.shipping_id)}
                    isLoading={isLoadingDelete}
                />

                <ConfirmModal
                    id={`confirm-shipping-cancel`}
                    title={t('SHIPPINGS.MSG_CANCEL_SHIPPING_TITLE')}
                    content={t('SHIPPINGS.MSG_CANCEL_SHIPPING_CONTENT')}
                    cancelText={t('ACTIONS.CLOSE')}
                    okText={t('ACTIONS.CANCEL')}
                    onOk={() => this.props.updateStatus(shipping.shipping_id, statusCode)}
                    isLoading={isLoadingCancel}
                    maxWidth={600}
                />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        show: (shippingID) => dispatch(shippingActions.show(shippingID)),
        delete: (shippingID) => dispatch(shippingActions.delete(shippingID)),
        update: (shipping) => dispatch(shippingActions.update(shipping)),
        updateStatus: (shippingID, status) => dispatch(shippingActions.updateStatus(shippingID, status)),
        updatePaymentStatus: (shippingID, paymentStatusCode) =>
            dispatch(shippingActions.updatePaymentStatus(shippingID, paymentStatusCode)),
        updateFee: (shippingID, fee) => dispatch(shippingActions.updateFee(shippingID, fee)),
        updateTrackingNumber: (shippingID, trackingNumber) => dispatch(shippingActions.updateTrackingNumber(shippingID, trackingNumber)),
        print: (shippingID) => dispatch(printActions.printShipping(shippingID)),
    };
};

const mapStateToProps = (state) => ({
    shippingReducer: { ...state.shipping },
    printReducer: { ...state.print },
    storeReducer: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ShippingEdit));
