import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import appConfig from '../../../config/app';
import Modal from '../../../components/Modal';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';

class ModalConfirmCancel extends React.Component {
    static propTypes = {
        visible: PropTypes.bool.isRequired,
        onOk: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            manipulation: appConfig.MANIPULATION_ORDER.RESET.CODE,
        };
    }

    submit = () => {
        let manipulation = this.state.manipulation;

        this.props.onOk(manipulation);
    };

    render() {
        const { t } = this.props;
        const { manipulation, reasonDetail } = this.state;

        return (
            <Modal
                id='modal-confirm-cancel'
                title={t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                width={400}
                isLoading={this.props.isLoading}
                position_unset={true}
            >
                {/* <p>{t('ORDERS.MSG_CANCEL_ORDERS_CONTENT')}</p> */}
                <div className='ladiui form-group' style={{ marginTop: 20 }}>
                    <label className='ladiui-label'>{t('ORDERS.CONFIM_METHOD_TITLE')}</label>
                    <div className='ladiui form-group mt-0 mb-0'>
                        <div className='ladiui dropdown position-unset' style={{ width: '100%' }}>
                            <button
                                style={{ minWidth: '100%' }}
                                className='ladiui btn btn-outline-light dropdown-toggle select-dropdown'
                                id='dropdownMenuButton3'
                                data-toggle='dropdown'
                            >
                                <i></i>
                                <span className='ladiui dropdown-text'>{appConfig.MANIPULATION_ORDER.RESET.NAME}</span>
                            </button>
                            <ul className='ladiui dropdown-menu' style={{ width: 'calc(100% - 48px)' }}>
                                {map(appConfig.MANIPULATION_ORDER, (item, index) => (
                                    <li
                                        key={index}
                                        onClick={() => {
                                            this.setState({
                                                manipulation: item.CODE,
                                            });
                                        }}
                                    >
                                        <a className='ladiui dropdown-item' href='#'>
                                            {item.NAME}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(ModalConfirmCancel);
