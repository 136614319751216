import React from "react";
import PropTypes from "prop-types";
import { map, remove } from "lodash";
import Modal from "../../../../components/Modal";

import baseHelper from "../../../../helpers/BaseHelper";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Input";
import appConfig from "../../../../config/app";

ModalConVerKit.propTypes = {
  formAccount: PropTypes.any,
  setTextToggleInputType: PropTypes.func,
  onChangeInput: PropTypes.func,
};

export default function ModalConVerKit(props) {
  const { formAccount, setTextToggleInputType, inputRefs, textToggleInputType, onChangeInput } = props;
  const { t } = useTranslation();

  const inputPaddingRight = textToggleInputType ? "34px" : "60px";

  return (
    <div>
      <div className="content">
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("INTEGRATE.ACCOUNT.LB_NAME")}</label>
          <Input
            ref={(ref) => inputRefs.add(ref)}
            name="name"
            validationName={t("INTEGRATE.ACCOUNT.LB_NAME")}
            placeholder={t("INTEGRATE.ACCOUNT.LB_NAME")}
            value={formAccount.name}
            onChange={onChangeInput}
            validations={{ isRequired: true }}
          />
        </div>
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("INTEGRATE.ACCOUNT.LB_API_KEY")}</label>
          <Input
            style={{ paddingRight: inputPaddingRight }}
            type={textToggleInputType ? "text" : "password"}
            ref={(ref) => inputRefs.add(ref)}
            name="api_key"
            validationName={t("INTEGRATE.ACCOUNT.LB_API_KEY")}
            placeholder={t("INTEGRATE.ACCOUNT.LB_API_KEY")}
            value={formAccount.api_key}
            onChange={onChangeInput}
            validations={{ isRequired: true }}
          />
          <span className="text-toggle-input-type" onClick={() => setTextToggleInputType(!textToggleInputType)}>
            {textToggleInputType ? (
              <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-show-password.svg" alt="" />
            ) : (
              <img src="https://w.ladicdn.com/ladiui/ladisales/icons/icon-hide-password.svg" alt="" />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
