import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";
import { withTranslation } from "react-i18next";

import appConfig from "../../../config/app";

class ModalExportOrder extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    isLoadingExportOrder: PropTypes.bool,
    isLoadingExportTransaction: PropTypes.bool,
    onExportOrder: PropTypes.func,
    onExportTransaction: PropTypes.func,
    onCancel: PropTypes.func,
  };
  constructor(props) {
    super(props);

    this.state = {
      selected: appConfig.EXPORT_ORDER.ON_PAGE,
    };
  }

  onChange = (event) => {
    this.setState({
      selected: event.target.value,
    });
  };

  exportOrder = () => {
    this.props.onExportOrder(this.state.selected);
  };

  exportTransaction = () => {
    this.props.onExportTransaction(this.state.selected);
  };

  render() {
    const { selected } = this.state;
    const { t, visible, onCancel, onExportTransaction, isLoadingExportOrder, isLoadingExportTransaction } = this.props;

    return (
      <Modal
        id="modal-export-order"
        title={t("ORDERS.EXPORT_ORDER")}
        visible={visible}
        onCancel={onCancel}
        width={520}
        footer={
          <div className="ladiui-modal-footer">
            <button onClick={onCancel} className="ladiui btn btn-secondary">
              {t("ACTIONS.REMOVE")}
            </button>
            {/* <button onClick={onExportTransaction} className={`ladiui button primary icon icon-cloud-download ${isLoadingExportTransaction ? 'loader' : ''}`} disabled={isLoadingExportTransaction}> {t('ORDERS.EXPORT_TRANSACTION')}</button> */}
            <button
              onClick={this.exportOrder}
              className={`ladiui btn btn-primary ${isLoadingExportOrder ? "loader" : ""}`}
              disabled={isLoadingExportOrder}
            >
              {" "}
              {t("ACTIONS.EXPORT_DATA")}
            </button>
          </div>
        }
      >
        <div className="item">
          <input
            type="radio"
            className="ladiui form-check-input"
            id="onPage"
            name="radio-group"
            value={appConfig.EXPORT_ORDER.ON_PAGE}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_ORDER.ON_PAGE}
          />
          <label htmlFor="onPage">{t("ORDERS.EXPORT_ON_PAGE")}</label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="ladiui form-check-input"
            id="allOrder"
            name="radio-group"
            value={appConfig.EXPORT_ORDER.ALL_ORDER}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_ORDER.ALL_ORDER}
          />
          <label htmlFor="allOrder">{t("ORDERS.EXPORT_ALL_ORDER")}</label>
        </div>

        <div className="item">
          <input
            type="radio"
            className="ladiui form-check-input"
            id="selectedOrder"
            name="radio-group"
            value={appConfig.EXPORT_ORDER.SELECTED_ORDER}
            onChange={this.onChange}
            checked={selected == appConfig.EXPORT_ORDER.SELECTED_ORDER}
          />
          <label htmlFor="selectedOrder">{t("ORDERS.EXPORT_SELECTED_ORDER")}</label>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalExportOrder);
