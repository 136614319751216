/** Import default package */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/** Import from third party */
import { cloneDeep, map, compact } from 'lodash';
import customerActions from '../../../redux/futures/customer/actions';

/** Import component from my app */

import { withTranslation } from 'react-i18next';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import Modal from '../../../components/Modal';
import PanelCustomField from '../../../components/PanelCustomField';

class PanelCutomerField extends React.Component {
    static propTypes = {
        customFields: PropTypes.array,
        mode: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);

        this.state = {
            customFields: '',
        };
        this.initialState = cloneDeep(this.state);
        this.ref = React.createRef();
        this.inputsRef = new Set();
        this.customFieldRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }
    }

    getData = () => {
        return { ...this.state.billing };
    };

    openEditCustomerFieldForm = () => {
        baseHelper.resetForm('modal-edit-custom-field');

        this.setState({
            isShowModalCustomField: true,
        });
    };

    hideModalCustomField = () => {
        this.setState({
            isShowModalCustomField: false,
        });
    };

    submit = () => {
        // e.preventDefault();

        let customFieldRef = this.customFieldRef.current.getData();
        let data = {
            customer_id: this.props.customerID,
            custom_fields: customFieldRef,
        };
        this.props.updateCustomfieldByCustomer(data);
    };

    getCustomerFieldModal = () => {
        const { t, isLoading } = this.props;
        const { addresses } = this.props;
        const { editBillingInfo, isShowModalCustomField } = this.state;

        if (!isShowModalCustomField) {
            return null;
        }

        return (
            <Modal
                visible={isShowModalCustomField}
                id='modal-edit-custom-field'
                zIndex={9999}
                title={'Chỉnh sửa trường tùy chỉnh'}
                onCancel={this.hideModalCustomField}
                onOk={this.submit}
                width={600}
                isLoading={isLoading}
            >
                <div className='ladiui-tab-content' style={{ padding: '15px 0px' }}>
                    <PanelCustomField
                        ref={this.customFieldRef}
                        customFields={this.props.customFields}
                        groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE}
                        actionType={appConfig.FORM_MODE.EDIT}
                        orderID={this.props.orderID}
                    />
                </div>
            </Modal>
        );
    };

    getNameDropDown = (item) => {
        let labelName = '';
        let dropdownValue = item.data_values.split('\n');
        if (dropdownValue) {
            const data = dropdownValue.map((item, index) => {
                item = {
                    NAME: item.split('|')[0],
                    CODE: item.split('|')[1],
                };
                return item;
            });
            data.map((_item) => {
                if (_item.CODE == item.value) {
                    labelName = _item.NAME;
                }
            });
        }

        return labelName;
    };

    render() {
        const { t, mode, classNameCustom, customFields } = this.props;
        let selectedNameDropDown = '';

        return (
            <div className={`order-customer block-customer-field ${classNameCustom}`}>
                <div className='order-heading title'>
                    <h3>{'Thông tin bổ sung'}</h3>
                    {mode != appConfig.FORM_MODE.VIEW && (
                        <a onClick={this.openEditCustomerFieldForm} className='ladiui text-14 customer-action'>
                            {t('ACTIONS.CHANGE')}
                        </a>
                    )}
                </div>
                <div className='customer-info mt-12 '>
                    {customFields &&
                        customFields.map((item, index) => {
                            return (
                                <span key={index} className='item-info'>
                                    <span>
                                        <label className='ladiui text-w500 mr-4'>{item.label}: </label>
                                        {item.data_type == 'DATE'
                                            ? baseHelper.formatDateToStr(item.value, appConfig.DEFAULT_FORMAT_DATE)
                                            : item.data_type == 'DROPDOWN'
                                            ? this.getNameDropDown(item)
                                            : item.value}
                                    </span>
                                </span>
                            );
                        })}
                </div>

                {this.getCustomerFieldModal()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCustomfieldByCustomer: (data) => dispatch(customerActions.updateCustomfieldByCustomer(data)),
    };
};

const mapStateToProps = (state) => ({
    store: { ...state.store },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(withTranslation('translation', { withRef: true })(PanelCutomerField));
