import * as types from './types';

export default (state = {}, action) => {
    switch (action.type) {
        case types.LIST_CREDIT_TRANSACTION: {
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: types.LIST_CREDIT_TRANSACTION,
                credit_transactions: action.status ? action.payload.credit_transactions : [],
                total_record: action.status ? action.payload.total_record : 0,
                total_page: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

