import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const listVideo = (data) => ({
  type: types.PRODUCT_LIST_VIDEO,
  meta: {
    endpoint: endpoint.PRODUCT_LIST_VIDEO,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const showVideo = (data) => ({
  type: types.PRODUCT_SHOW_VIDEO,
  meta: {
    endpoint: endpoint.PRODUCT_SHOW_VIDEO,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  listVideo,
  showVideo
};
