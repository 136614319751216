import React from "react";

import baseHelper from "../../../helpers/BaseHelper";
import appConfig from "../../../config/app";
import { cloneDeep, compact, map, includes } from "lodash";

import Modal from "../../../components/Modal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PanelAddress from "../../../components/PanelAddress";
import PanelBillingInfo from "./PanelBillingInfo";

import { connect } from "react-redux";
import * as orderTypes from "../../../redux/futures/order/types";

class BillingInfomation extends React.Component {
  static propTypes = {
    mode: PropTypes.string,
    onChangeAddress: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      billingInfomation: {},
      editBillingInfo: {},
      isShowBillingInfoModal: false,
      isShowBlockBillingAddress: true,
    };

    this.ref = React.createRef();
    this.billingPanelRef = React.createRef();
    this.inputsRef = new Set();
  }

  componentDidMount() {
    if (this.props.billingInfomation) {
      const billingInfomation = this.props.billingInfomation || {};

      this.setState({
        billingInfomation: cloneDeep(billingInfomation),
        editBillingInfo: cloneDeep(billingInfomation),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) {
      return;
    }
  }

  submit = () => {
    // Validate
    let errors = [];
    const inputsRef = compact(Array.from(this.inputsRef));
    map(inputsRef, (ref) => {
      if (ref.validate) {
        errors = errors.concat(ref.validate());
      }
    });
    errors = errors.concat(this.billingPanelRef.current.validate());
    errors = compact(errors);
    if (errors.length > 0) {
      const errorMessage = errors.join("<br/>");
      window.LadiUI.toastCustom("danger", "", errorMessage);
      return;
    }

    const { mode } = this.props;

    const editBillingInfo = this.billingPanelRef.current.getData();
    this.setState({
      billingInfomation: cloneDeep(editBillingInfo),
    });

    if (this.props.onChangeBillingInfo) {
      this.props.onChangeBillingInfo(editBillingInfo, appConfig.ADDRESS_TYPE.BILLING_INFO);
    }

    if (mode == appConfig.FORM_MODE.CREATE) {
      this.hideBillingInfoModal();
    }
  };

  onChangeBilling = (selectedBillingAddress) => {
    this.setState({
      editBillingInfo: cloneDeep(selectedBillingAddress),
    });
  };

  hideBillingInfoModal = () => {
    this.setState({
      isShowBillingInfoModal: false,
    });
  };

  getData = () => {
    return this.state.billingAddress;
  };

  openEditBillingInfoForm = () => {
    baseHelper.resetForm("modal-billing-info");
    const { billingAddress } = this.state;

    this.setState({
      editBillingAddress: cloneDeep(billingAddress),
      isShowBillingInfoModal: true,
    });
  };

  getBillingModal = () => {
    const { t, isLoading } = this.props;
    const { addresses } = this.props;
    const { editBillingInfo, isShowBillingInfoModal } = this.state;

    if (!isShowBillingInfoModal) {
      return null;
    }

    return (
      <Modal
        visible={isShowBillingInfoModal}
        id="modal-billing-info"
        zIndex={9999}
        title={"Chỉnh sửa thông tin xuất hóa đơn"}
        onCancel={this.hideBillingInfoModal}
        onOk={this.submit}
        width={600}
        isLoading={isLoading}
      >
        <div className="ladiui-tab-content" style={{ padding: "15px 0px" }}>
          {/* <PanelAddress
            mode={appConfig.FORM_MODE.EDIT}
            ref={this.billingPanelRef}
            address={editBillingInfo}
          /> */}
          <PanelBillingInfo mode={appConfig.FORM_MODE.EDIT} ref={this.billingPanelRef} billingInfo={editBillingInfo} />
        </div>
      </Modal>
    );
  };

  render() {
    const { t, mode, classNameCustom } = this.props;
    const { billingInfomation, isShowBlockBillingAddress } = this.state;

    return (
      <div className={`order-customer block-billing-infomation ${classNameCustom}`}>
        <div className="order-heading title pb-24 pt-24">
          <h3>{"Thông tin xuất hóa đơn"}</h3>
          {mode != appConfig.FORM_MODE.VIEW && (
            <a onClick={this.openEditBillingInfoForm} className="ladiui order-customer-edit">
              {t("ACTIONS.EDIT")}
            </a>
          )}
        </div>

        <div className="customer-info">
          <span className="item-info">
            <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg"} alt="" style={{ marginRight: "8px" }} />
            <span>
              <label>Cửa Hàng: </label>
              {billingInfomation.billing_company ? billingInfomation.billing_company : "Chưa có thông tin"}
            </span>
          </span>
          <span className="item-info">
            <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg"} alt="" style={{ marginRight: "8px" }} />
            <span>
              <label>Mã số thuế: </label>
              {billingInfomation.billing_tax_code ? billingInfomation.billing_tax_code : "Chưa có thông tin"}
            </span>
          </span>
          <span className="item-info">
            <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg"} alt="" style={{ marginRight: "8px" }} />
            <span>
              <label>Email: </label>
              {billingInfomation.billing_email ? billingInfomation.billing_email : "Chưa có thông tin"}
            </span>
          </span>
          <span className="item-info">
            <img src={"https://w.ladicdn.com/ladiui/ladisales/icons/icon-not-print-order.svg"} alt="" style={{ marginRight: "8px" }} />
            <span>
              <label>Địa chỉ công ty: </label>
              {billingInfomation.billing_company_address ? billingInfomation.billing_company_address : "Chưa có thông tin"}
            </span>
          </span>
        </div>

        {this.getBillingModal()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => ({
  orderReducer: { ...state.order },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withTranslation("translation", { withRef: true })(BillingInfomation));
