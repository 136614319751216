import i18next from 'i18next';
import appConfig from '../config/app';

const productHelper = {
    getPackageInfo(variants) {
        if (!variants || variants.length == 0) return {};
        let minPrice = -1;
        let total_sold = 0;
        for (let variant of variants) {
            if (variant.price < minPrice || minPrice == -1) minPrice = variant.price;
            total_sold += variant.total_sold;
        }

        return {
            minPrice,
            package_quantity_unit: variants[0].package_quantity_unit,
            total_sold,
        };
    },

    // parse variant sang package theo variantcos chung variants.option[0] là value của options[0].values
    async getPackages(options, variants) {
        if (!options || options.length == 0) return [];

        let packages = [];

        let optionValues = options[0].values;
        let index = 0;

        for await (let option of optionValues) {
            let _variants = variants.filter((_variant) => {
                if (!_variant.options) return false;
                return _variant.options[0].option_value_value.name == option.name;
            });

            if (_variants && _variants.length > 0) {
                let variant = _variants[0];

                let _package = {
                    package_name: option.name,
                    description: variant.description,
                    package_quantity_unit: variant.package_quantity_unit,
                    src: variant.src,
                    option_id: options[0].option_id,
                    option_value_id: option.option_value_id,
                    position: index,
                    // chỉ lấy các thông tin cần thiết
                    options: _variants.map((item) => {
                        return {
                            sku: item.sku,
                            package_quantity: item.package_quantity,
                            price: item.price,
                            package_addition_quantity: item.package_addition_quantity,
                            inventory_checked: item.inventory_checked,
                            total_quantity: item.total_quantity,
                            total_sold: item.total_sold,

                            product_variant_id: item.product_variant_id,
                            variant_value_id: item.options[1] ? item.options[1].variant_value_id : '',
                            option_value_id: item.options[1] ? item.options[1].option_value_id : '',
                            option_id: item.options[1] ? item.options[1].option_id : '',
                        };
                    }),
                };
                packages.push(_package);
            }
            index += 1;
        }
        return packages;
    },

    getOptionUpsellNew(info, productType = appConfig.PRODUCT_TYPE.SERVICE.CODE) {
        let arayMes = [];

        if (info.options) {
            for (const [index, option] of info.options.entries()) {
                arayMes.push(
                    `${index > 0 ? ' ' : ''}${
                        [appConfig.PRODUCT_TYPE.EVENT.CODE, appConfig.PRODUCT_TYPE.SERVICE.CODE].includes(productType)
                            ? ''
                            : option.option_name
                    } ${option.option_value_value} ${
                        productType == appConfig.PRODUCT_TYPE.SERVICE.CODE && index == 1 ? info.package_quantity_unit : ''
                    }`
                );
            }

            if (productType == appConfig.PRODUCT_TYPE.SERVICE.CODE && info.options.length == 1) {
                // arayMes.push(` ${info.package_quantity} ${info.package_quantity_unit}`);
                arayMes[0] += ` ${info.package_quantity} ${info.package_quantity_unit}`;
            }
        }

        if (info.up_sell_variants) {
            for (const [index, upsell] of info.up_sell_variants.entries()) {
                arayMes.push(`${index > 0 ? ' ' : ''}${upsell.name}`);
            }
        }

        const mes = arayMes.toString();
        return mes;
    },
};
export default productHelper;
