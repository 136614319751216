import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

export default function ListTags(props) {
    const { tagValues, tagNames, tagColors, handleClickTagItem, isProduct, classTagName = '' } = props;

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const handleClickTag = (item, index) => {
        const selectedTags = [
            {
                order_tag_id: item,
                name: tagNames[index],
            },
        ];
        handleClickTagItem(item, selectedTags);
    };

    return (
        <div className={`tag-color ${classTagName}`}>
            <div className='ladiui-tags'>
                <div className='ladiui-tag-list'>
                    {map(tagValues, (item, index) => {
                        // if (index > 2) {
                        //   return null;
                        // }
                        return (
                            <span
                                onClick={() => !isProduct && handleClickTag(item, index)}
                                style={
                                    !tagColors[index]
                                        ? { color: 'var(--main-default)' }
                                        : {
                                              backgroundColor: tagColors[index],
                                              color: 'var(--main-default)',
                                          }
                                }
                                key={index}
                                className='ladiui btn-tag custom cursor-pointer'
                            >
                                {/* {tagNames[index] ? tagNames[index] : { item }} */}
                                {tagNames[index] ? tagNames[index] : item}
                            </span>
                        );
                    })}
                    {/* {tagValues.length > 3 && (
            <div className="ladiui btn-group">
              <div className="ladiui dropdown hide-mt ba-c">
                <span className="ladiui dropdown-toggle select-dropdown px-5 mt-5" data-toggle="dropdown">
                  ...
                </span>
                <ul className="ladiui dropdown-menu r-0" style={{ width: "250px" }}>
                  {map(tagValues, (item, index) => {
                    if (index < 3) {
                      return null;
                    }
                    return (
                      <li onClick={() => handleClickTag(item, index)} key={index}>
                        {tagNames[index] ? (
                          <span className="ladiui dropdown-item items-center" style={{ display: "flex" }}>
                            <img src="https://w.ladicdn.com/ladiui/icons/icon-tag.svg" className="mr-8" />
                            {tagNames[index]}
                          </span>
                        ) : (
                          <span className="ladiui dropdown-item items-center" style={{ display: "flex" }}>
                            <img src="https://w.ladicdn.com/ladiui/icons/icon-tag.svg" className="mr-8" />
                            {item}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )} */}
                </div>
            </div>
        </div>
    );
}
