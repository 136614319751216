import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ItemProduct(props) {
  const { t, i18n, onClick, info } = props;

  return (
    <Link
      className="item-product flex"
      to={{
        pathname: info.path,
        state: { fromDashboard: true },
        search: `?type=${info.search}`,
      }}
    >
      {/* <div>
        <div className="flex mb-16">
          <img src={info.icon} alt="" />
          <span className="title-16 ml-12">{info.name}</span>
        </div>
        <div className="des-product">{info.des}</div>
      </div> */}
      <div className="block-icon">
        <div className="img">
          <img src={info.icon} alt="" />
        </div>
      </div>
      <div className="text-content">
        <h3>{info.name}</h3>
        <p>{info.des}</p>
      </div>
    </Link>
  );
}

export default withTranslation()(ItemProduct);
