import React from 'react';

export default class LoadingTable extends React.Component {
    render() {
        return (
            <section className="ladiui-page-loader">
                <article className="background">
                    <div className="a"></div>
                    <div className="b"></div>
                    <div className="c"></div>
                    <div className="d"></div>
                    <div className="e"></div>
                    <div className="f"></div>
                    <div className="g"></div>
                    <div className="h"></div>
                    <div className="i"></div>
                    <div className="j"></div>
                    <div className="k"></div>
                    <div className="l"></div>
                </article>
                <article className="background">
                    <div className="a"></div>
                    <div className="b"></div>
                    <div className="c"></div>
                    <div className="d"></div>
                    <div className="e"></div>
                    <div className="f"></div>
                    <div className="g"></div>
                    <div className="h"></div>
                    <div className="i"></div>
                    <div className="j"></div>
                    <div className="k"></div>
                    <div className="l"></div>
                </article>

                <article className="background">
                    <div className="a"></div>
                    <div className="b"></div>
                    <div className="c"></div>
                    <div className="d"></div>
                    <div className="e"></div>
                    <div className="f"></div>
                    <div className="g"></div>
                    <div className="h"></div>
                    <div className="i"></div>
                    <div className="j"></div>
                    <div className="k"></div>
                    <div className="l"></div>
                </article>
            </section>
        )
    }
}