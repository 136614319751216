import { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';

function LadiDropdownMenu(props) {
    const { labelDropdown, listProp, defaultTextProp, hideLabel, cbProp, classDropdown, classSize, classWidth, style } = props;

    const [nameDropdown, setNameDropdown] = useState();

    const eventSelectDropdown = (item) => {
        if (isFunction(cbProp)) {
            cbProp(item.value);
        }
    };
    useEffect(() => {
        setNameDropdown(defaultTextProp);
    }, [defaultTextProp]);
    return (
        <>
            <div className={classDropdown} style={style}>
                {!hideLabel ? (
                    <div>
                        <label className='ladiui title-text-form-lever2'>{labelDropdown}</label>
                    </div>
                ) : (
                    <></>
                )}
                <div className='ladiui btn-group'>
                    <div className='ladiui dropdown'>
                        <button
                            className={`ladiui btn btn-outline-light dropdown-toggle ${classSize || ''} ${classWidth || ''}`}
                            data-toggle='dropdown'
                        >
                            {nameDropdown}
                        </button>
                        <ul className={`ladiui dropdown-menu  ${classWidth || ''}`}>
                            {listProp.map((item) => (
                                <li key={item.value + item.name.replace(' ', '')} onClick={() => eventSelectDropdown(item)}>
                                    <a className='ladiui dropdown-item'>{item.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LadiDropdownMenu;
