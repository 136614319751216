import React from 'react';

import { connect } from 'react-redux';

import * as inventoryTypes from '../../redux/futures/inventory/types';
import inventoryActions from '../../redux/futures/inventory/actions';

import { includes, map, remove, cloneDeep, forEach, find, cond } from 'lodash';
import appConfig from '../../config/app';
import Pagination from 'react-js-pagination';
import baseHelper from '../../helpers/BaseHelper';

import LoadingTable from '../../components/LoadingTable';
import { withTranslation } from 'react-i18next';
import LoadingScene from '../../components/LoadingScene';
import { appLocalStorage } from '../../localforage';
import NumberInput from '../../components/NumberInput';
import Modal from '../../components/Modal';
import LadiPagination from '../../components/LadiPagination';
import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import Popover from '../../components/Popover';
import config from '../../config/config';
class Inventory extends React.Component {
    constructor(props) {
        super(props);
        const defaultConditions = {
            page: 1,
            limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
        };
        this.state = {
            activePage: 1,
            selectedLimit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
            userInfo: {},
            searchProductName: '',
            variants: [],
            isShowUpdateBatchModal: false,
            adjustBatch: 0,
            modeBatch: appConfig.INVENTORY_UPDATE_MODE.ADD,
            selectedVariants: [],
            conditions: defaultConditions,
            selectedIDs: [],
        };

        // this.selectedIDs = [];

        this.popoverRef = React.createRef(this.popoverRef);
    }

    componentWillMount() {
        appLocalStorage.getItem(appConfig.LOCAL_FORAGE.USER_INFO).then((result) => {
            this.setState({
                userInfo: result,
            });
        });
    }

    componentDidMount() {
        this.listVariant();
    }

    componentDidUpdate() {
        window.LadiUI.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.inventory.action != nextProps.inventory.action) {
            if (includes([inventoryTypes.INVENTORY_LIST_VARIANT], nextProps.inventory.action)) {
                if (nextProps.inventory.status) {
                    const variants = cloneDeep(nextProps.inventory.variants);
                    forEach(variants, (item) => {
                        item.mode = appConfig.INVENTORY_UPDATE_MODE.ADD;
                        item.adjust = 0;
                    });
                    this.setState({
                        variants,
                    });
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.inventory.message, 'OK');
                }
            }

            if (
                includes([inventoryTypes.INVENTORY_UPDATES_QUANTITY, inventoryTypes.INVENTORY_UPDATE_QUANTITY], nextProps.inventory.action)
            ) {
                if (nextProps.inventory.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.inventory.message);
                    this.setState({
                        isShowUpdateBatchModal: false,
                    });
                    this.listVariant();
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.inventory.message, 'OK');
                }
            }
        }
    }

    /**
     * list variant
     */
    listVariant = (resetActivePageFlag = true) => {
        if (document.getElementById('checkAllItem')) {
            document.getElementById('checkAllItem').checked = false;
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        const { searchProductName, selectedLimit, conditions } = this.state;
        let activePage = conditions.page;
        if (resetActivePageFlag) {
            activePage = 1;
            conditions.page = 1;

            this.setState({
                conditions: conditions,
            });
        }

        // Reset selectedIDs
        this.selectedIDs = [];

        const data = {
            search: {
                name: searchProductName,
            },
            paged: activePage,
            limit: conditions.limit,
            sort: this.sortBy,
        };

        this.props.listVariant(data);
    };

    /*******************************SEARCH***************************/
    onChangeInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    /**
     * Tim kiem theo name
     */
    onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            this.listVariant();
        }
    };

    /****************************************************************/

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    onChangeLimit = (option) => {
        let { conditions } = this.state;
        conditions.limit = option.value;
        this.setState({ conditions: conditions }, () => {
            this.listVariant();
        });
    };

    /**
     * Change page
     */
    onPageChange = (pageNumber) => {
        let { conditions } = this.state;
        conditions.page = pageNumber;
        this.setState({ conditions: conditions }, () => {
            this.listVariant(false);
        });
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/
    checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsTg = [];
        if (checked) {
            selectedIDsTg = map(this.props.inventory.variants, (item) => item.product_variant_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        this.setState({
            selectedIDs: selectedIDsTg,
        });
    };

    checkItem = (event, variantID) => {
        const { checked } = event.target;
        const { selectedIDs } = this.state;

        if (checked) {
            if (!includes(selectedIDs, variantID)) {
                selectedIDs.push(variantID);
            }

            if (selectedIDs.length == this.props.inventory.variants.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(selectedIDs, (item) => item == variantID);
        }

        this.setState({
            selectedIDs,
        });
    };

    sort = (event) => {
        const { classList } = event.target;
        const name = event.target.getAttribute('name');

        window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui table .sorting'), (item) => {
            if (item.getAttribute('name') != name) {
                item.classList.remove('up');
            }
        });

        this.sortBy = {};

        // Change arrow up and down
        if (includes(classList, 'up')) {
            classList.remove('up');
            this.sortBy[name] = 'ASC';
        } else {
            classList.add('up');
            this.sortBy[name] = 'DESC';
        }

        this.setState({}, () => this.listVariant());
    };

    onChangeAdjustInput = (event, index) => {
        const { variants } = this.state;
        let { value } = event.target;

        if (value < 0) {
            value = 0;
        }

        const variant = variants[index];
        variant.adjust = value;

        this.adjust(variant);
        this.setState({
            variants,
        });
    };

    onChangeQuantityAdjust = (qty, index) => {
        const { variants } = this.state;

        const variant = variants[index];
        variant.adjust = parseInt(variant.adjust) + parseInt(qty) < 0 ? 0 : parseInt(variant.adjust) + parseInt(qty);

        this.adjust(variant);
        this.setState({
            variants,
        });
    };

    adjust = (variant) => {
        switch (variant.mode) {
            case appConfig.INVENTORY_UPDATE_MODE.ADD:
                variant.after_adjust = baseHelper.parseInt(variant.quantity) + baseHelper.parseInt(variant.adjust);
                break;
            case appConfig.INVENTORY_UPDATE_MODE.SET:
                variant.after_adjust = baseHelper.parseInt(variant.adjust);
                break;
        }
    };

    onChangeMode = (mode, index) => {
        const { variants } = this.state;

        const variant = variants[index];
        variant.mode = mode;

        this.adjust(variant);

        this.setState({
            variants,
        });
    };

    onChangeModeBatch = (mode) => {
        this.setState(
            {
                modeBatch: mode,
            },
            () => {
                this.adjustBatch();
            }
        );
    };

    adjustBatch = () => {
        const { selectedVariants } = this.state;
        switch (this.state.modeBatch) {
            case appConfig.INVENTORY_UPDATE_MODE.ADD:
                forEach(selectedVariants, (item) => {
                    item.after_adjust = baseHelper.parseInt(item.quantity) + baseHelper.parseInt(this.state.adjustBatch);
                });
                break;
            case appConfig.INVENTORY_UPDATE_MODE.SET:
                forEach(selectedVariants, (item) => {
                    item.after_adjust = baseHelper.parseInt(this.state.adjustBatch);
                });
                break;
        }

        this.setState({
            selectedVariants,
        });
    };

    updateQuantity = (index) => {
        const { variants } = this.state;

        const variant = variants[index];

        this.props.updateQuantity({
            product_id: variant.product_id,
            product_variant_id: variant.product_variant_id,
            mode: variant.mode,
            val: variant.adjust,
        });
    };

    updatesQuantity = () => {
        const data = {
            mode: this.state.modeBatch,
            val: this.state.adjustBatch,
            product_variant_ids: this.state.selectedIDs,
        };

        this.props.updatesQuantity(data);
    };

    openAdjustBatchModal = () => {
        let { selectedIDs } = this.state;
        if (selectedIDs.length <= 0) {
            window.LadiUI.toastCustom('danger', '', this.props.t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        const selectedVariants = [];

        forEach(selectedIDs, (item) => {
            const matched = find(this.state.variants, (_item) => _item.product_variant_id == item);

            selectedVariants.push({
                product_variant_id: matched.product_variant_id,
                name: matched.name,
                quantity: matched.quantity,
            });
        });

        this.setState(
            {
                selectedVariants,
            },
            () => {
                this.setState({
                    isShowUpdateBatchModal: true,
                });
            }
        );
    };

    eventSelectAction = (action) => {
        switch (action) {
            case 'UPDATE_BATCH':
                this.openAdjustBatchModal();
                break;
            default:
        }
    };

    onChangeAdjustBatch = (value) => {
        let { adjustBatch } = this.state;
        this.setState(
            {
                adjustBatch: parseInt(adjustBatch) + parseInt(value) > 0 ? parseInt(adjustBatch) + parseInt(value) : 0,
            },
            () => {
                this.adjustBatch();
            }
        );
    };

    /****************************************************************/
    render() {
        const { t } = this.props;
        const { totalRecord, totalPage } = this.props.inventory;
        const { activePage, selectedLimit, searchProductName, userInfo, conditions } = this.state;
        let fromItem = 0;
        let toItem = 0;
        if ((this.props.inventory.variants || []).length > 0) {
            fromItem = (conditions.page - 1) * conditions.limit + 1;
            toItem = fromItem + this.props.inventory.variants.length - 1;
        }

        const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
            'PAGINATION.OF'
        )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

        const isLoadingData = this.props.inventory.loading && this.props.inventory.waiting == inventoryTypes.INVENTORY_LIST_VARIANT;
        const isLoadingUpdate =
            this.props.inventory.loading && includes([inventoryTypes.INVENTORY_UPDATE_QUANTITY], this.props.inventory.waiting);
        const isLoadingUpdates =
            this.props.inventory.loading && includes([inventoryTypes.INVENTORY_UPDATES_QUANTITY], this.props.inventory.waiting);
        const isLoadingReload = this.props.inventory.loading && inventoryTypes.INVENTORY_RELOAD == this.props.inventory.waiting;

        const listAction = [{ value: 'UPDATE_BATCH', name: t('INVENTORY.UPDATE_BATCH') }];

        return (
            <div className='page-content'>
                {(isLoadingReload || isLoadingUpdate) && <LoadingScene blur={true} />}
                <div>
                    <div className='header-page'>
                        <div className='heading'>
                            <h3>Quản lý kho</h3>
                            <p>Quản lý tồn kho các loại sản phẩm của bạn.</p>
                        </div>
                        <div>
                            {/* <a href='/products?product_tab=ALL&tab=1' className='btn-product-view'> */}
                            <a
                                href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.PRODUCT_LIST}`}
                                target='_parent'
                                className='btn-product-view'
                            >
                                <i className='ladiui icon-new new-ldicon-box' />
                                <span> Xem danh sách sản phẩm</span>
                            </a>
                        </div>
                    </div>

                    <div className='block-filter'>
                        <div className='action-item w-100-p mr-0'>
                            <div className='ladiui search-keyword w-100-p'>
                                <input
                                    type='text'
                                    id='search'
                                    placeholder={t('PRODUCTS.SEARCH_PRODUCT')}
                                    name='searchProductName'
                                    value={searchProductName}
                                    onChange={this.onChangeInput}
                                    onKeyDown={this.onKeyDownInput}
                                    className='ladiui search-field dropdown-toggle form-control search-width height-40'
                                />
                                <i className='ladi-icon icon-zoom' onClick={this.listVariant} />
                            </div>
                        </div>
                    </div>
                    {isLoadingData ? (
                        <LoadingTable />
                    ) : (
                        <div className='block-content'>
                            <div>
                                <div className='ladi-card'>
                                    <table className={`ladiui table text-left ${isLoadingData ? 'loader' : ''}`}>
                                        <thead style={{ position: 'relative' }}>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col' className='ladiui checkall'>
                                                    <input
                                                        id='checkAllItem'
                                                        onClick={this.checkAllItem}
                                                        type='checkbox'
                                                        className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                    />
                                                </th>
                                                <th scope='col'>
                                                    {this.state.selectedIDs.length <= 0 ? (
                                                        t('INVENTORY.VARIANT')
                                                    ) : (
                                                        <LadiDropdownMenu
                                                            labelDropdown={'Chọn hành động'}
                                                            listProp={listAction}
                                                            defaultTextProp={t('ACTIONS.TITLE')}
                                                            hideLabel={true}
                                                            cbProp={this.eventSelectAction}
                                                            classSize='btn-sm '
                                                            classDropdown='w-160 action-record-table'
                                                            style={{ left: '30px' }}
                                                            classWidth='w-160'
                                                        />
                                                    )}
                                                </th>
                                                <th scope='col'>{t('PRODUCTS.SKU')}</th>
                                                <th scope='col'>{t('PRODUCTS.PRICE')}</th>
                                                <th scope='col' style={{ minWidth: 120 }}>
                                                    {t('COMMON.QUANTITY')}
                                                </th>
                                                {/* <th className='text-right' scope='col'>
                                                    {t('INVENTORY.UPDATE_QUANTITY')}
                                                </th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(this.state.variants, (item, index) => {
                                                return (
                                                    <tr key={item.product_variant_id} className='ladiui table-vertical main'>
                                                        <td>
                                                            <input
                                                                type='checkbox'
                                                                onClick={(event) => this.checkItem(event, item.product_variant_id)}
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                                                            />
                                                        </td>
                                                        <td>
                                                            <a href={baseHelper.getUrlVariant(item)} target='_blank'>
                                                                {item.name}
                                                            </a>
                                                        </td>
                                                        <td>{item.sku}</td>
                                                        <td>{baseHelper.formatMoneyPostFix(item.price, userInfo.currency_symbol)}</td>
                                                        <td className='text-center'>
                                                            <div className='add-quantity-inventory-v4'>
                                                                <div className='qty-popup'>
                                                                    <span>{item.quantity}</span>

                                                                    <Popover
                                                                        ref={this.popoverRef}
                                                                        wrapperProps={{ className: 'relative' }}
                                                                        wrapperPopoverProps={{ className: 'popover-qty-inventory' }}
                                                                        // isShowPopover={isShowPopover}
                                                                        content={
                                                                            <div className='popover-add-qty-inventory'>
                                                                                <div className='popover'>
                                                                                    <div className='content-header'>
                                                                                        <div className='btn-group'>
                                                                                            <button
                                                                                                className={`btn-item ${
                                                                                                    item.mode ==
                                                                                                    appConfig.INVENTORY_UPDATE_MODE.ADD
                                                                                                        ? 'active'
                                                                                                        : ''
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                    this.onChangeMode(
                                                                                                        appConfig.INVENTORY_UPDATE_MODE.ADD,
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                Thêm
                                                                                            </button>
                                                                                            <button
                                                                                                className={`btn-item ${
                                                                                                    item.mode ==
                                                                                                    appConfig.INVENTORY_UPDATE_MODE.SET
                                                                                                        ? 'active'
                                                                                                        : ''
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                    this.onChangeMode(
                                                                                                        appConfig.INVENTORY_UPDATE_MODE.SET,
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                Đặt lại
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className='variants-quantity w100'>
                                                                                            <div
                                                                                                className='variant-quantity-up-down w100'
                                                                                                style={{ justifyContent: 'space-between' }}
                                                                                            >
                                                                                                <input
                                                                                                    type='number'
                                                                                                    value={item.adjust}
                                                                                                    onChange={(event) => {
                                                                                                        this.onChangeAdjustInput(
                                                                                                            event,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <div className='variant-up-down'>
                                                                                                    <i
                                                                                                        className='new-ldicon-arrow-up'
                                                                                                        onClick={() => {
                                                                                                            this.onChangeQuantityAdjust(
                                                                                                                +1,
                                                                                                                index
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                    <i
                                                                                                        className='new-ldicon-arrow-down'
                                                                                                        onClick={() => {
                                                                                                            this.onChangeQuantityAdjust(
                                                                                                                -1,
                                                                                                                index
                                                                                                            );
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='product-info'>
                                                                                        <span>{item.name}</span>
                                                                                        <div className='product-qty'>
                                                                                            {item.quantity}
                                                                                            {(item.adjust != 0 ||
                                                                                                (item.adjust == 0 &&
                                                                                                    item.mode ==
                                                                                                        appConfig.INVENTORY_UPDATE_MODE
                                                                                                            .SET)) && (
                                                                                                <i className='ladi-icon icon icon-triangle-right inventory-adjust-triagle' />
                                                                                            )}
                                                                                            {(item.adjust != 0 ||
                                                                                                (item.adjust == 0 &&
                                                                                                    item.mode ==
                                                                                                        appConfig.INVENTORY_UPDATE_MODE
                                                                                                            .SET)) &&
                                                                                                item.after_adjust}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='action-footer'>
                                                                                        <button
                                                                                            className='ladiui btn btn-secondary'
                                                                                            onClick={async () => {
                                                                                                await this.popoverRef.current.instanceRef.hide();
                                                                                            }}
                                                                                        >
                                                                                            Hủy
                                                                                        </button>
                                                                                        <button
                                                                                            className='ladiui btn btn-primary'
                                                                                            onClick={() => this.updateQuantity(index)}
                                                                                        >
                                                                                            Áp dụng
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <div className='icon-show-popup'>
                                                                            <i className='new-ldicon-control' />
                                                                        </div>
                                                                    </Popover>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {/* {totalRecord <= 0 && (
                                                <tr className='text-center'>
                                                    <td colSpan='100%'>{t('NO_DATA')}</td>
                                                </tr>
                                            )} */}
                                        </tbody>
                                    </table>
                                </div>
                                {totalRecord > 0 ? (
                                    <div className='ladiui pagination-block'>
                                        <LadiPagination
                                            conditionsProp={conditions}
                                            listName={t('PAGINATION.ITEMS')}
                                            message={''}
                                            items={this.props.inventory.variants}
                                            total={totalRecord}
                                            limitAction={this.onChangeLimit}
                                            pageAction={this.onPageChange}
                                        />
                                    </div>
                                ) : (
                                    <div className='table-empty'>
                                        <i className='new-ldicon-search' />
                                        <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                                        <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                {this.state.isShowUpdateBatchModal && (
                    <Modal
                        id='modal-update-batch'
                        title={t('INVENTORY.MSG_UPDATE_BATCH_TITLE', this.state.selectedVariants.length)}
                        onCancel={() => {
                            this.setState({
                                isShowUpdateBatchModal: false,
                            });
                        }}
                        onOk={this.updatesQuantity}
                        visible={this.state.isShowUpdateBatchModal}
                        isLoading={isLoadingUpdates}
                        disableOk={this.state.adjustBatch == 0 && this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.ADD}
                    >
                        <div>
                            {/* <div className='group-adjust-quantity flex'>
                                <button
                                    onClick={() => {
                                        this.onChangeModeBatch(appConfig.INVENTORY_UPDATE_MODE.ADD);
                                    }}
                                    className={`ladiui btn btn-secondary mode remove-right ${this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.ADD ? 'active' : ''
                                        }`}
                                >
                                    {t('INVENTORY_UPDATE_MODE.ADD')}
                                </button>
                                <button
                                    onClick={() => {
                                        this.onChangeModeBatch(appConfig.INVENTORY_UPDATE_MODE.SET);
                                    }}
                                    className={`ladiui btn btn-secondary mode remove-right ${this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET ? 'active' : ''
                                        }`}
                                >
                                    {t('INVENTORY_UPDATE_MODE.SET')}
                                </button>
                                <NumberInput
                                    value={this.state.adjustBatch}
                                    onChange={(event) => {
                                        this.setState(
                                            {
                                                adjustBatch: event.target.value,
                                            },
                                            () => {
                                                this.adjustBatch();
                                            }
                                        );
                                    }}
                                    onlyNumber={true}
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    allowNegative={true}
                                />
                            </div> */}

                            <div className='content-header'>
                                <div className='btn-group'>
                                    <button
                                        className={`btn-item ${
                                            this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.ADD ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            this.onChangeModeBatch(appConfig.INVENTORY_UPDATE_MODE.ADD);
                                        }}
                                    >
                                        Thêm
                                    </button>
                                    <button
                                        className={`btn-item ${
                                            this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            this.onChangeModeBatch(appConfig.INVENTORY_UPDATE_MODE.SET);
                                        }}
                                    >
                                        Đặt lại
                                    </button>
                                </div>
                                <div className='variants-quantity w100'>
                                    <div className='variant-quantity-up-down w100' style={{ justifyContent: 'space-between' }}>
                                        <input
                                            type='number'
                                            value={this.state.adjustBatch}
                                            onChange={(event) => {
                                                this.setState(
                                                    {
                                                        adjustBatch: event.target.value,
                                                    },
                                                    () => {
                                                        this.adjustBatch();
                                                    }
                                                );
                                            }}
                                        />
                                        <div className='variant-up-down'>
                                            <i
                                                className='new-ldicon-arrow-up'
                                                onClick={() => {
                                                    this.onChangeAdjustBatch(+1);
                                                }}
                                            />
                                            <i
                                                className='new-ldicon-arrow-down'
                                                onClick={() => {
                                                    this.onChangeAdjustBatch(-1);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='block-adjustBatch' style={{ marginTop: 25 }}>
                            <ul className='list'>
                                {map(this.state.selectedVariants, (item, index) => {
                                    return (
                                        <li className='item flex space-between' key={index}>
                                            <span style={{ maxWidth: '300px' }}>{item.name}</span>
                                            <span className='text-center'>
                                                {item.quantity}
                                                {(this.state.adjustBatch != 0 ||
                                                    (this.state.adjustBatch == 0 &&
                                                        this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET)) && (
                                                    <i className='ladi-icon icon icon-triangle-right inventory-adjust-triagle' />
                                                )}
                                                {(this.state.adjustBatch != 0 ||
                                                    (this.state.adjustBatch == 0 &&
                                                        this.state.modeBatch == appConfig.INVENTORY_UPDATE_MODE.SET)) &&
                                                    item.after_adjust}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listVariant: (data) => dispatch(inventoryActions.listVariant(data)),
        updateQuantity: (data) => dispatch(inventoryActions.updateQuantity(data)),
        updatesQuantity: (data) => dispatch(inventoryActions.updatesQuantity(data)),
        reload: (data) => dispatch(inventoryActions.reload(data)),
    };
};

const mapStateToProps = (state) => ({
    inventory: { ...state.inventory },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Inventory));
