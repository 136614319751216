import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../config/app';
import Input from '../../../../components/Input';
import Modal from '../../../../components/Modal';

import * as orderTagTypes from '../../../../redux/futures/order_tag/types';
import orderTagAction from '../../../../redux/futures/order_tag/actions';

// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalOrderTagCreate(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { isUpdate = false, visible, hideModal, selectedTag, _tagSelected } = props;

    const orderTagReducer = useSelector((state) => state.orderTag);
    const tagDefault = {
        color: appConfig.TAG_COLORS[0],
        name: '',
    };
    const [tagSelected, setTagSelected] = useState(tagDefault);

    const loading = useMemo(() => {
        return orderTagReducer.loading || false;
    }, [orderTagReducer.loading]);

    useEffect(() => {
        if (_tagSelected && _tagSelected.order_tag_id) setTagSelected(_tagSelected);
        else setTagSelected({ color: appConfig.TAG_COLORS[0], name: '' });
    }, [_tagSelected]);

    const onCancel = () => {
        hideModal();
    };

    useEffect(() => {
        if (orderTagReducer.action == orderTagTypes.CREATE_ORDER_TAG) {
            if (orderTagReducer.status) {
                window.LadiUI.toastCustom('success', '', orderTagReducer.message);
                selectedTag(orderTagReducer.order_tag || {}, true);
                setTagSelected(tagDefault);
                hideModal();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderTagReducer.message, 'OK');
        }
        if (orderTagReducer.action == orderTagTypes.UPDATE_ORDER_TAG) {
            if (orderTagReducer.status) {
                window.LadiUI.toastCustom('success', '', orderTagReducer.message);
                selectedTag(true);
                setTagSelected(tagDefault);
                hideModal();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderTagReducer.message, 'OK');
        }
    }, [orderTagReducer.action]);

    const onOk = () => {
        if (!tagSelected.name) {
            window.LadiUI.toastCustom('danger', '', t('TAGS.NAME_EMPTY'));
            return;
        }
        if (isUpdate && _tagSelected && _tagSelected.order_tag_id) {
            dispatch(orderTagAction.update(tagSelected));
        } else dispatch(orderTagAction.create(tagSelected));
    };

    return (
        <Modal
            id='modal-tag-edit'
            title={!isUpdate ? t('TAGS.ADD_TAG') : t('TAGS.UPDATE_TAG')}
            sub_title={!isUpdate ? t('TAGS.ADD_TAG_SUB') : t('TAGS.UPDATE_TAG_SUB')}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            width={400}
            cancelText={t('ACTIONS.REMOVE')}
            okText={!isUpdate ? t('TAGS.ADD_TAG') : t('TAGS.UPDATE_TAG_AVTION')}
            isLoading={loading}
            position_unset={true}
        >
            <div className='ladiui form-group'>
                <label className='ladiui text-14'>{t('TAGS.COL_TAG')}</label>
                <Input
                    className='mt-4'
                    value={tagSelected.name}
                    onChange={(event) => {
                        let value = event.target.value;
                        setTagSelected((pre) => {
                            let tg = { ...pre };
                            tg.name = value;
                            return tg;
                        });
                    }}
                    validationName={t('TAGS.COL_TAG')}
                    validations={{ isRequired: true }}
                />
            </div>
            {tagSelected.color && tagSelected.name && (
                <span className='order-tag-item mt-16' style={{ color: 'var(--main-default)', backgroundColor: tagSelected.color }}>
                    {tagSelected.name}
                </span>
            )}

            <div className='form-default-color'>
                <label className='ladiui text-14'>{t('TAGS.PICK_COLOR')}</label>
                <div className='list-pick-color-tag-customer mt-4'>
                    {appConfig.TAG_COLORS.map((item) => (
                        <span
                            key={item}
                            className='item'
                            style={{ backgroundColor: item }}
                            onClick={() => {
                                setTagSelected((pre) => {
                                    let tg = { ...pre };
                                    tg.color = item;
                                    return tg;
                                });
                            }}
                        >
                            {item == tagSelected.color && <i className='ladi-icon icon-d-check' />}
                        </span>
                    ))}
                </div>
            </div>
        </Modal>
    );
}

export default ModalOrderTagCreate;
