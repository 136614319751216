import React from 'react';
import Pagination from 'react-js-pagination';
import appConfig from '../config/app';

function LadiPagination(props) {
    const { conditionsProp, listName, items, total, limitAction, pageAction, message, innerClassName } = props;

    return (
        <>
            <div className={`ladiui search-action ${innerClassName}`}>
                <div className='ladiui from-filter flex-row'>
                    <div className='ladiui pre-page'>
                        <div className='ladiui btn-group custom-pre-page'>
                            <div className='ladiui dropdown'>
                                <button
                                    className='ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-pre-page'
                                    id='dropdownMenuButton3'
                                    data-toggle='dropdown'
                                >
                                    <i></i>
                                    <span className='ladiui dropdown-text custom-pre-page'>{conditionsProp.limit}</span>
                                </button>
                                <ul className='ladiui dropdown-menu custom-pre-page dropup'>
                                    {appConfig.PAGINATION.LIMIT_OPTIONS.map((limit, indexLimit) => (
                                        <li key={indexLimit} onClick={() => limitAction(limit)}>
                                            <a className='ladiui dropdown-item'>{limit.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='ladiui showing'>
                        {message && message != '' ? (
                            message
                        ) : (
                            <span>
                                {`Đang hiển thị ${1 + (conditionsProp.page - 1) * conditionsProp.limit} đến ${
                                    items.length + (conditionsProp.page - 1) * conditionsProp.limit
                                } của ${total} ${listName} ${message || ''}`}
                            </span>
                        )}
                        {/* <span>Đang hiển thị&nbsp;</span>
                    {1 + (conditionsProp.page - 1) * conditionsProp.limit}
                    <span>&nbsp;đến&nbsp;</span>
                    {items.length + (conditionsProp.page - 1) * conditionsProp.limit}
                    <span>&nbsp;của&nbsp;</span>
                    {total}
                    <span>&nbsp;{listName}</span> */}
                    </div>
                </div>

                <div className='ladiui pagination'>
                    <Pagination
                        activePage={conditionsProp.page}
                        itemsCountPerPage={conditionsProp.limit}
                        totalItemsCount={total}
                        pageRangeDisplayed={5}
                        prevPageText={<i className='ladiui icon-new new-ldicon-arrow-right' />}
                        nextPageText={<i className='ladiui icon-new new-ldicon-arrow-left' />}
                        firstPageText={<i className='ladiui icon-new new-ldicon-double-arrow-left' />}
                        lastPageText={<i className='ladiui icon-new new-ldicon-double-arrow-right' />}
                        onChange={pageAction}
                        itemClass='ladiui number-page'
                        innerClass='ladiui page-container'
                        activeClass='active'
                        activeLinkClass='active'
                        linkClassFirst='p-2'
                        linkClassPrev='p-2'
                        linkClassNext='p-2'
                        linkClassLast='p-2'
                    />
                </div>
            </div>
        </>
    );
}

export default LadiPagination;
