import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { map, compact } from 'lodash';

import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import productVariantActions from '../../../redux/futures/product_variant/actions';
import * as productVariantTypes from '../../../redux/futures/product_variant/types';

import Image from '../../../components/Image';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import NumberInput from '../../../components/NumberInput';

import LoadingScene from '../../../components/LoadingScene';
import { setOptions } from 'highcharts';
import ImageUpload from '../../../components/ImageUpload';

function ModalPackageV4(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { mode, currentVariantInfo = {}, images, onCancel, onSubmit, currentProductInfo, visible, openModelDelete } = props;

    const storeRedurce = useSelector((state) => state.store);
    const variantReducer = useSelector((state) => state.variant);

    const currentStore = useMemo(() => {
        if (storeRedurce.userInfo && storeRedurce.userInfo.currentStore) return storeRedurce.userInfo.currentStore || {};
        return {};
    }, [storeRedurce.userInfo]);

    const optionVariantDefault = {
        sku: '',
        package_quantity: 0,
        price: 0,
        package_addition_quantity: 0,
        inventory_checked: appConfig.STATUS.INACTIVE,
        total_quantity: 0, //tồn kho
    };

    const [packageInfo, setPackageInfo] = useState({});
    const [optionVariants, setOptionVariant] = useState([]);
    const [inputsRefs, setInputRef] = useState(new Set());

    const isLoading = useMemo(() => {
        return variantReducer.loading || false;
    }, [variantReducer.loading]);

    useEffect(() => {
        if (mode == appConfig.FORM_MODE.CREATE) {
            setOptionVariant([optionVariantDefault]);
            return;
        }
        if (currentVariantInfo && mode == appConfig.FORM_MODE.EDIT) {
            if (currentVariantInfo.options) {
                let options = currentVariantInfo.options;

                setPackageInfo(currentVariantInfo);
                setOptionVariant(options);
            }
        }
    }, [JSON.stringify(currentVariantInfo), mode]);

    const onChangeInput = (event) => {
        const { name, value } = event.target;
        setPackageInfo((pre) => {
            return {
                ...pre,
                [name]: value,
            };
        });
    };

    const onChangeOptionVariant = (e, index) => {
        const { name, value } = e.target;
        let optionTg = [...optionVariants];
        optionTg[index][name] = value;

        setOptionVariant(optionTg);
    };

    const handelAddOptionVariant = (e) => {
        e.preventDefault();

        let optionTg = [...optionVariants];
        optionTg.push(optionVariantDefault);
        setOptionVariant(optionTg);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let errors = [];
        const _inputsRef = compact(Array.from(inputsRefs));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        const _packageInfo = {
            ...packageInfo,
            options: optionVariants,
        };

        onSubmit(_packageInfo);
    };

    const handelDeleteOption = (item, index) => {
        if (item.product_variant_id) {
            openModelDelete({
                product_variant_id: item.product_variant_id,
                option_value_value: `${packageInfo.package_name} - ${item.package_quantity} ${packageInfo.package_quantity_unit || ''}`,
            });
            // dispatch(productVariantActions.delete(currentProductInfo.product_id, item.product_variant_id));
        } else {
            let optionTg = [...optionVariants];
            optionTg.splice(index, 1);
            setOptionVariant(optionTg);
        }
    };

    return (
        <Modal
            id='modal-event'
            title={mode == appConfig.FORM_MODE.CREATE ? t('PRODUCT_SERVICES.LB_CREATE_NEW_PACKAGE') : t('PRODUCTS.LB_EDIT_PACKAGE')}
            onCancel={onCancel}
            onOk={(event) => handleSubmit(event)}
            isLoading={isLoading}
            visible={visible}
            width={900}
        >
            <div className='block-content-item'>
                <h3>{t('PRODUCT_SERVICE_V4.PACKAGE_INFO_LB')}</h3>
                <div className='ladiui flex gap-12 mt-12'>
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>{t('PRODUCT_SERVICES.LB_PACKAGE_NAME')}</label>
                        <Input
                            ref={(ref) =>
                                setInputRef((pre) => {
                                    if (ref) pre.add(ref);
                                    return pre;
                                })
                            }
                            validationName={t('PRODUCTS.VARIANT_PACKAGE_DEFAULT')}
                            value={packageInfo.package_name || ''}
                            name='package_name'
                            onChange={onChangeInput}
                            validations={{ isRequired: true }}
                            placeholder={t('PRODUCT_SERVICE_V4.PACKAGE_NAME_PLH')}
                        />
                    </div>
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('PRODUCT_SERVICE_V4.PACKAGE_DES_LB')}</label>
                    <textarea
                        rows='4'
                        className='ladiui text-area'
                        value={packageInfo.description || ''}
                        name='description'
                        onChange={onChangeInput}
                    />
                </div>
            </div>

            <div className='block-content-item mt-20'>
                <div className='ladiui flex-center-between gap-4 package-vriant'>
                    <span className='package-variant-lb'>{t('PRODUCT_SERVICE_V4.PACKAGE_VARIANT_LB')}</span>

                    <div className='ladiui flex-center gap-8'>
                        <span className='package-unit-lb'>{t('PRODUCT_SERVICES.PACKAGE_QUANTITY_UNIT')}</span>
                        <Input
                            ref={(ref) =>
                                setInputRef((pre) => {
                                    if (ref) pre.add(ref);
                                    return pre;
                                })
                            }
                            validationName={t('PRODUCT_SERVICES.PACKAGE_QUANTITY_UNIT')}
                            value={packageInfo.package_quantity_unit || ''}
                            name='package_quantity_unit'
                            onChange={onChangeInput}
                            validations={{ isRequired: true }}
                            placeholder={t('PRODUCT_SERVICES.PL_PACKAGE_QUANTITY_UNIT')}
                            className='w-200'
                        />
                    </div>
                </div>
                <div className='option-variants'>
                    <div className='option-variant-header option-variant-item'>
                        <div className='option-variant-sku col'>{t('PRODUCTS.SKU')}</div>
                        <div className='option-variant-num-unit col'>
                            {t('PRODUCT_SERVICE_V4.PACKAGE_OPTION_NUM_UNIT_LB', packageInfo.package_quantity_unit || '')}
                        </div>
                        <div className='option-variant-price col'>{t('PRODUCTS.PRICE')}</div>
                        <div className='option-variant-add col'>
                            {t('PRODUCT_SERVICE_V4.PACKAGE_OPTION_UNIT_ADD_LB', packageInfo.package_quantity_unit || '')}
                        </div>
                        <div className='option-variant-inventory col'>{t('PRODUCT_SERVICE_V4.PACKAGE_LIMIT_LB')}</div>
                        <div className='option-variant-delete col'></div>
                    </div>
                    <div className='option-variant-items'>
                        {optionVariants.map((item, index) => {
                            return (
                                <div className=' option-variant-item' key={index}>
                                    <div className='option-variant-sku col'>
                                        <Input
                                            ref={(ref) =>
                                                setInputRef((pre) => {
                                                    if (ref) pre.add(ref);
                                                    return pre;
                                                })
                                            }
                                            value={item.sku}
                                            name='sku'
                                            onChange={(e) => onChangeOptionVariant(e, index)}
                                            validations={{ isRequired: true }}
                                        />
                                    </div>
                                    <div className='option-variant-num-unit col'>
                                        <NumberInput
                                            ref={(ref) =>
                                                setInputRef((pre) => {
                                                    if (ref) pre.add(ref);
                                                    return pre;
                                                })
                                            }
                                            name='package_quantity'
                                            value={item.package_quantity}
                                            onChange={(e) => onChangeOptionVariant(e, index)}
                                        />
                                    </div>
                                    <div className='option-variant-price col'>
                                        <NumberInput
                                            name='price'
                                            prefix={currentStore.currency_symbol}
                                            value={item.price}
                                            onChange={(e) => onChangeOptionVariant(e, index)}
                                        />
                                    </div>
                                    <div className='option-variant-add col'>
                                        <NumberInput
                                            name='package_addition_quantity'
                                            value={item.package_addition_quantity}
                                            onChange={(e) => onChangeOptionVariant(e, index)}
                                        />
                                    </div>
                                    {/* check lại là nếu sản phẩm tạo mã là thủ công thì k đc chỉnh sửa chỗ này */}
                                    <div className='option-variant-inventory col'>
                                        <div className='ladiui item-form switch mr-8'>
                                            <label className='ladiui switch m-0'>
                                                <input
                                                    type='checkbox'
                                                    checked={item.inventory_checked}
                                                    onChange={(e) => {
                                                        let optionTg = [...optionVariants];
                                                        optionTg[index].inventory_checked = e.target.checked;
                                                        setOptionVariant(optionTg);
                                                    }}
                                                />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        {item.inventory_checked == true && (
                                            <NumberInput
                                                name='total_quantity'
                                                value={item.total_quantity}
                                                onChange={(e) => onChangeOptionVariant(e, index)}
                                            />
                                        )}
                                    </div>
                                    <div className='option-variant-delete col' onClick={() => handelDeleteOption(item, index)}>
                                        <i className='ladiui icon-new new-ldicon-delete' />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='ladiui btn-add-new' onClick={handelAddOptionVariant}>
                        <i className='new-ldicon-add-circle' />
                        <span>{t('PRODUCT_SERVICE_V4.PACKAGE_VARIANT_ADD_LB')}</span>
                    </div>
                </div>
            </div>

            <div className='mt-20 flex gap-16'>
                <div className='block-content-item w-100-p'>
                    <h3 className='mb-24'>Hình ảnh gói dịch vụ</h3>
                    <div className='image setting-block-image'>
                        <ImageUpload
                            indexSection='PACKAGE-IMAGE'
                            setImageSrc={packageInfo.src}
                            setImageData={(image) => {
                                if (image.indexSection == 'PACKAGE-IMAGE') {
                                    setPackageInfo((pre) => {
                                        return {
                                            ...pre,
                                            src: image.src,
                                        };
                                    });
                                }
                            }}
                            isDelete={true}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModalPackageV4;
