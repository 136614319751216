import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../config/app';

// eslint-disable-next-line max-lines-per-function

const ButtonAddCart = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { isShow } = props;

    return <div className={`pwidget-config-item  ${isShow ? 'show' : 'hide'}`}>ButtonAddCart</div>;
});

export default ButtonAddCart;
