import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import baseHelper from '../../../helpers/BaseHelper';
import appConfig from '../../../config/app';
import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';

import fileActions from '../../../redux/futures/file/actions';
import * as fileTypes from '../../../redux/futures/file/types';

import productCategoryActions from '../../../redux/futures/product_category/actions';
import * as productCategoryTypes from '../../../redux/futures/product_category/types';

import produce from 'immer';
import { Editor } from '@tinymce/tinymce-react';
import Input from '../../../components/Input';
import { map } from 'lodash';
import Dropdown from '../../../components/Dropdown';
import Popover from '../../../components/Popover';
import ValidatorHelper from '../../../helpers/ValidatorHelper';
import Image from '../../../components/Image';

import ProductSearchV4 from '../../../components/ProductSearchV4';

import { t } from 'i18next';

const CDN = 'https://w.ladicdn.com/';

function PanelPageCategoryInfo(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { productCategoryID } = useParams();

    const inputsRef = new Set();
    const popoverRef = useRef();

    const productReducer = useSelector((state) => state.product);
    const productCategoryReducer = useSelector((state) => state.productCategory);
    const fileReducer = useSelector((state) => state.file);

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });

    let [categoryInfo, setCategoryInfo] = useState({
        name: '',
        parent_id: null,
        src: null,
        description: '',
        isShow: true,
        product_ids: [],
    });

    let [listSelects, setListSelects] = useState([]);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedUrlImage, setSelectedUrlImage] = useState('');
    const [isEditCategory, setIsEditCategory] = useState(false);
    const [type, setType] = useState('Product');

    useEffect(() => {
        let _type = 'Product';

        if (queryStrings.get('type')) _type = queryStrings.get('type');
        setType(_type);

        let payload = {
            search: {
                type: _type,
            },
        };
        dispatch(productCategoryActions.listSelectCategory(payload));

        if (productCategoryID) {
            dispatch(productCategoryActions.show({ product_category_id: productCategoryID }));
            setIsEditCategory(true);
        }
    }, []);

    useEffect(() => {
        if (props.listSelectCategories && props.listSelectCategories.length > 0) {
            setListSelects(props.listSelectCategories);
        }
    }, [props.listSelectCategories]);

    useEffect(() => {
        if (productCategoryReducer.action == productCategoryTypes.LIST_SELECT_CATEGORY) {
            if (productCategoryReducer.status) {
                setListSelects(productCategoryReducer.listSelects || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
        if (productCategoryReducer.action == productCategoryTypes.CREATE_CATEGORY) {
            if (productCategoryReducer.status) {
                window.LadiUI.toastCustom('success', '', productCategoryReducer.message);
                history.push(`/product-category/update/${productCategoryReducer.productCategoryId}?type=${type}`);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
        if (productCategoryReducer.action == productCategoryTypes.SHOW_CATEGORY) {
            if (productCategoryReducer.status) {
                setCategoryInfo({ ...productCategoryReducer.productCategory });
                setSelectedProducts(productCategoryReducer.productCategory.products || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
        if (productCategoryReducer.action == productCategoryTypes.UPDATE_CATEGORY) {
            if (productCategoryReducer.status) {
                window.LadiUI.toastCustom('success', '', productCategoryReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productCategoryReducer.message, 'OK');
            }
        }
    }, [productCategoryReducer]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_CATEGORY_IMAGE) {
            if (fileReducer.status) {
                if (fileReducer.image_url) {
                    setCategoryInfo({
                        ...categoryInfo,
                        src: fileReducer.image_url,
                    });
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    const onChangeInputCategory = (event) => {
        setCategoryInfo({
            ...categoryInfo,
            [event.target.name]: event.target.value,
        });
    };

    const handleEditorChange = (value) => {
        setCategoryInfo({
            ...categoryInfo,
            description: value,
        });
    };

    const handleSelectProduct = (product) => {
        let _selectedProducts = [...selectedProducts];
        let productItem = {
            name: product.name,
            product_id: product.product_id,
            product_variant_id: product.product_variant_id,
            price: product.price || '',
            max_price: product.max_price || '',
            min_price: product.min_price || '',
            src: product.src || '',
        };

        _selectedProducts.push(productItem);
        setSelectedProducts(_selectedProducts);
    };

    const selectedParentCategory = (selected) => {
        setCategoryInfo({
            ...categoryInfo,
            parent_id: selected.product_category_id,
        });
    };

    const handleClickInputUpload = () => {
        document.getElementById('input-upload-image-category').click();
    };

    const fileChangedHandler = (event) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                dispatch(fileActions.uploadCategoryImage(form));
            }
        }
    };

    const handleDeleteProductSelected = (index) => {
        let _selectedProducts = [...selectedProducts];
        _selectedProducts.splice(index, 1);
        setSelectedProducts(_selectedProducts);
    };

    const handleAddImageUrl = () => {
        if (selectedUrlImage && ValidatorHelper.isValidHttpUrl(selectedUrlImage)) {
            setCategoryInfo({
                ...categoryInfo,
                src: selectedUrlImage,
            });
            popoverRef.current.instanceRef.hide();
            setSelectedUrlImage('');
        } else {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }
    };

    let prefixCDN = true;
    if (categoryInfo.src && categoryInfo.src.startsWith('http')) {
        prefixCDN = false;
    }

    const getPriceProduct = (item) => {
        let price = 0;
        return baseHelper.formatMoneyPostFix(item.min_price || 0, storeInfo.currentStore.currency_symbol);
        if (!item.price) {
            if (item.min_price && item.max_price) {
                if (item.min_price == item.max_price) {
                    price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                } else {
                    price =
                        baseHelper.formatMoneyPostFix(item.min_price, storeInfo.currentStore.currency_symbol) +
                        ' - ' +
                        baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                }
            } else {
                price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
            }
        } else {
            price = baseHelper.formatMoneyPostFix(item.price, storeInfo.currentStore.currency_symbol);
        }

        return price;
    };

    const handleCreateCategory = () => {
        let payloadCategory = {
            name: categoryInfo.name,
            parent_id: categoryInfo.parent_id,
            description: categoryInfo.description,
            src: categoryInfo.src,
            isShow: categoryInfo.isShow,
            type: type,
            product_ids: selectedProducts.map((item) => item.product_id),
        };
        if (categoryInfo.product_category_id) {
            payloadCategory.product_category_id = categoryInfo.product_category_id;
            dispatch(productCategoryActions.update(payloadCategory));
        } else {
            dispatch(productCategoryActions.create(payloadCategory));
        }
    };

    return (
        <div className='page-category-v4'>
            <div className='menu-header'>
                <div className='content'>
                    <div className='top-menu-header'>
                        <ul>
                            <li
                                className='cursor-pointer'
                                onClick={() => {
                                    let url = '/products?product_tab=ALL&tab=3&type=Product';

                                    if (type == 'Course') url = `/courses?tab=1&type=Course`;
                                    history.push(url);
                                }}
                            >
                                <span>Danh mục</span>
                            </li>
                            <li>
                                <strong>{!isEditCategory ? 'Tạo danh mục' : categoryInfo.name}</strong>
                            </li>
                        </ul>
                    </div>
                    <div className='title-action'>
                        <h3>{!isEditCategory ? t('CATEGORY.ADD_CATEGORY') : 'Chỉnh sửa danh mục'}</h3>
                        <button className='ladiui btn btn-primary' onClick={handleCreateCategory}>
                            {!isEditCategory ? 'Tạo danh mục' : 'Lưu danh mục'}
                        </button>
                    </div>
                </div>
            </div>
            <div className='page-content-category'>
                <div className='ladiui page-content content-max-width'>
                    <div className='page-category-info'>
                        <div className='content-left'>
                            <div className='block-content-item'>
                                <h3>Thông tin danh mục</h3>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>{t('CATEGORY.CATEGORY_NAME')}</label>
                                    <Input
                                        name='name'
                                        validationName='tên danh mục'
                                        placeholder='Nhập tên danh mục'
                                        value={categoryInfo.name}
                                        onChange={onChangeInputCategory}
                                        ref={(ref) => inputsRef.add(ref)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>Mô tả danh mục</label>
                                    <Editor
                                        tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.4/tinymce.min.js"
                                        licenseKey="gpl"
                                        value={categoryInfo.description}
                                        init={{
                                            selector: 'textarea',
                                            min_height: 200,
                                            max_height: 800,
                                            autoresize_overflow_padding: 5,
                                            autoresize_bottom_margin: 24,
                                            plugins:
                                                'preview fullpage visualblocks powerpaste checklist fullscreen table lists link image code paste autoresize email',
                                            toolbar:
                                                'bold italic underline | lineheight | formatselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | image | numlist bullist checklist | link forecolor backcolor casechange permanentpen formatpainter removeformat | fullscreen  preview email code | insertfile',
                                            toolbar_drawer: 'sliding',
                                            language_url: '../../../language/tinymce-vi.js',
                                            content_style: 'body { font-family:Roboto,sans-serif; font-size:14px }',
                                            // valid_elements: '*[*]',
                                        }}
                                        onEditorChange={handleEditorChange}
                                    />
                                </div>
                            </div>
                            <div className='block-content-item mt-20'>
                                <div className='header-top flex items-center space-between mb-20'>
                                    <h3>Sản phẩm thuộc danh mục</h3>
                                    <div className='info-help flex items-center'>
                                        <i className='ladi-icon icon-c-question mr-8' />
                                        <span>Tìm hiểu thêm</span>
                                    </div>
                                </div>

                                <ProductSearchV4 selectProduct={handleSelectProduct} type={type == 'Course' ? 'Course' : ''} />
                                <div className='block-list-products-category-v4'>
                                    {selectedProducts && selectedProducts.length > 0 ? (
                                        <div className='list-products'>
                                            <table className='ladiui table table-vertical text-left'>
                                                <thead>
                                                    <tr className='ladiui '>
                                                        <th>Tên sản phẩm</th>
                                                        <th>Trạng thái</th>
                                                        <th className='text-right'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {map(selectedProducts, (product, index) => {
                                                        return (
                                                            <tr className='ladiui table-vertical'>
                                                                <td>
                                                                    <div className='flex image-product items-center gap-12'>
                                                                        <div className='img'>
                                                                            <img
                                                                                src={
                                                                                    baseHelper.getImageUrl(product.src)
                                                                                    // product.src
                                                                                    //     ? CDN + product.src
                                                                                    //     : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                                }
                                                                                width={60}
                                                                                height={60}
                                                                            />
                                                                        </div>
                                                                        <div>{product.name}</div>
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    {/* {product.price
                                                                            ? baseHelper.formatMoneyPostFix(
                                                                                product.price,
                                                                                storeInfo.currentStore.currency_symbol
                                                                            )
                                                                            : 0} */}
                                                                    {/* {getPriceProduct(product)} */}

                                                                    <div className='product-status-v4'>
                                                                        {product.status == appConfig.PRODUCT_STATUS.ACTIVE.CODE ? (
                                                                            <div className='active space-nowrap flex items-center'>
                                                                                Hiển thị
                                                                            </div>
                                                                        ) : (
                                                                            <div className='inactive space-nowrap flex items-center'>
                                                                                Chưa hiển thị
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className='text-right'>
                                                                    <a href='#' onClick={() => handleDeleteProductSelected(index)}>
                                                                        <i className='ladi-icon icon-bin' />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <div className='no-products'>
                                            <div className='icon'>
                                                <i className='new-ldicon-template' />
                                            </div>
                                            <p className='text-center'>
                                                Chưa có sản phẩm nào trong danh mục này.
                                                <br />
                                                Tìm và bổ sung bằng thanh tìm kiếm.
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='content-right'>
                            <div className='block-content-item'>
                                <div className='flex items-center space-between'>
                                    <h3>Hiển thị danh mục</h3>
                                    <div className='ladiui item-form switch'>
                                        <label className='ladiui switch m-0'>
                                            <input
                                                type='checkbox'
                                                onChange={(event) => {
                                                    setCategoryInfo({
                                                        ...categoryInfo,
                                                        isShow: !categoryInfo.isShow,
                                                    });
                                                }}
                                                checked={categoryInfo.isShow}
                                            />
                                            <span className='ladiui slider round'></span>
                                        </label>
                                    </div>
                                </div>
                                <div className='block-info-help flex items-center'>
                                    <i className='ladi-icon icon-c-question mr-8' />
                                    <span>Hiển thị danh mục trên các cửa hàng cụ thể</span>
                                </div>
                            </div>
                            <div className='block-content-item mt-20'>
                                <h3>Danh mục cha</h3>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label new'>{t('CATEGORY.PARENT_CATEGORY')}</label>
                                    <Dropdown
                                        id='state'
                                        data={listSelects}
                                        onSelectItem={selectedParentCategory}
                                        currentKey={categoryInfo.parent_id}
                                        // currentValue={FIX_PARENT_CATEGORY[0].NAME}
                                        _key={'product_category_id'}
                                        _value={'name'}
                                        validationName={'Danh mục cha'}
                                        ref={(ref) => inputsRef.add(ref)}
                                        // validations={false}
                                        innerClassName='dropdown-custom-lds'
                                        placeHolder={'Chọn danh mục'}
                                        isShowBlockInputSearch={true}
                                        isParentCategoryDropdown={true}
                                    />
                                </div>
                            </div>
                            <div className='block-content-item mt-20'>
                                <input
                                    type='file'
                                    id='input-upload-image-category'
                                    name='file'
                                    accept='image/*'
                                    onChange={fileChangedHandler}
                                    style={{ display: 'none' }}
                                    onClick={(event) => {
                                        event.target.value = null;
                                    }}
                                />
                                <h3 className='mb-24'>Hình ảnh danh mục</h3>
                                {!categoryInfo.src || categoryInfo.src == '' ? (
                                    <div className='block-upload-image-category'>
                                        <div className='image'>
                                            <i className='new-ldicon-photo' />
                                        </div>
                                        <div className='text-add-image'>
                                            <div className='flex gap-4'>
                                                <a onClick={handleClickInputUpload}>Tải ảnh lên </a> hoặc
                                                <Popover
                                                    ref={popoverRef}
                                                    wrapperProps={{ className: 'relative' }}
                                                    wrapperPopoverProps={{ className: 'popover-image-category' }}
                                                    // isShowPopover={isShowPopover}
                                                    content={
                                                        <div className='popover-add-image-product'>
                                                            <div className='popover'>
                                                                <div className='ladiui form-group'>
                                                                    <label className='ladiui-label new text-left'>Đường dẫn hình ảnh</label>
                                                                    <Input
                                                                        className='ladiui form-control'
                                                                        placeholder='Nhập đường dẫn sản phẩm'
                                                                        name='image_url'
                                                                        value={selectedUrlImage}
                                                                        onChange={(event) => {
                                                                            setSelectedUrlImage(event.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='action-footer'>
                                                                    <button
                                                                        className='ladiui btn btn-secondary'
                                                                        onClick={() => {
                                                                            popoverRef.current.instanceRef.hide();
                                                                        }}
                                                                    >
                                                                        Hủy
                                                                    </button>
                                                                    <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                                        Thêm hình ảnh
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <a>Thêm từ URL</a>
                                                </Popover>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='block-show-image-category'>
                                        <div className='image'>
                                            <Image prefixCDN={prefixCDN} src={categoryInfo.src} />
                                            <div className='action-group'>
                                                {/* <div
                                                        className='block-icon-upload cursor-pointer'
                                                    // onClick={() => {
                                                    //     sortImage(item);
                                                    // }}
                                                    >
                                                        <img src={'https://w.ladicdn.com/ladiui/ladisales/icons/icon-outline-star.svg'} alt='' />
                                                    </div> */}
                                                <div
                                                    className='block-delete-img cursor-pointer'
                                                    onClick={() => {
                                                        setCategoryInfo({
                                                            ...categoryInfo,
                                                            src: '',
                                                        });
                                                    }}
                                                >
                                                    <i style={{ color: '#fff' }} className='ladi-icon icon-bin' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withTranslation()(PanelPageCategoryInfo);
