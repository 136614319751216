import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import baseHelper from '../helpers/BaseHelper';
import appConfig from '../config/app';
import ProductSearch from '../pages/orders/OrderCreate/components/ProductSearch';
import ProductSearchV4 from './ProductSearchV4';
import { map } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const CDN = 'https://w.ladicdn.com/';

// function PanelProductUpsellV4(props) {
const PanelProductUpsellV4 = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryURL = baseHelper.useQuery();
    const {
        t,
        i18n,
        handleSelectProductUpsell,
        selectedProductUpsells,
        handleDeleteProductUpsell,
        onDragEndProductUpSell,
        productInfo,
        selectedTypeProduct,
    } = props;

    const storeInfo = useSelector((state) => {
        if (state.store.action === 'ASYNC_END' && state.store.userInfo) return state.store.userInfo;
        else return [];
    });

    // useImperativeHandle(ref, () => ({
    //     getData: () => {

    //         return _product;
    //     },
    // }));

    const selectProductUpsell = (data) => {
        handleSelectProductUpsell(data);
    };

    const handleOnDrag = (result) => {
        onDragEndProductUpSell(result);
    };

    const deleteProductUpsell = (productID) => {
        handleDeleteProductUpsell(productID);
    };

    const getPriceProduct = (item) => {
        let price = 0;
        if (!item.price) {
            if (item.min_price && item.max_price) {
                if (item.min_price == item.max_price) {
                    price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                } else {
                    price =
                        baseHelper.formatMoneyPostFix(item.min_price, storeInfo.currentStore.currency_symbol) +
                        ' - ' +
                        baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
                }
            } else {
                price = baseHelper.formatMoneyPostFix(item.max_price, storeInfo.currentStore.currency_symbol);
            }
        } else {
            price = baseHelper.formatMoneyPostFix(item.price, storeInfo.currentStore.currency_symbol);
        }

        return price;
    };

    return (
        <div className='block-content-item'>
            <div className='header-top flex items-center space-between mb-20'>
                <h3>Sản phẩm bán kèm</h3>
                <div className='info-help flex items-center'>
                    <i className='ladi-icon icon-c-question mr-8'></i>
                    <span>Tìm hiểu thêm</span>
                </div>
            </div>
            {/* <ProductSearchV4 selectProduct={selectProductUpsell} /> */}
            <ProductSearch
                isNotShowBtnCreateProduct={true}
                selectVariant={selectProductUpsell}
                parentID={productInfo.product_id || null}
                displayProductType={selectedTypeProduct || ''}
                searchType='UpSell'
            />
            <div className='block-list-selected-product-upsells mt-16'>
                {!selectedProductUpsells || selectedProductUpsells.length == 0 ? (
                    <div className='none-product'>
                        <div className='img-text text-center'>
                            <img src='https://w.ladicdn.com/ladiui/icons/new-ldicon-template.svg' />
                            <p>Chưa có sản phẩm nào !</p>
                        </div>
                    </div>
                ) : (
                    <div className='block-list'>
                        <DragDropContext onDragEnd={handleOnDrag}>
                            <Droppable droppableId='droppable'>
                                {(provided, snapshot) => (
                                    <table
                                        className='ladiui table text-left'
                                        ref={provided.innerRef}
                                        style={{
                                            background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                        }}
                                    >
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th width='50'></th>
                                                <th>Tên sản phẩm</th>
                                                <th>Giá sản phẩm</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(selectedProductUpsells, (product, index) => {
                                                return (
                                                    <Draggable key={index} draggableId={'' + index} index={index}>
                                                        {(provided, snapshot) => (
                                                            <tr
                                                                key={index}
                                                                className='ladiui table-vertical'
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                    userSelect: 'none',
                                                                    background: snapshot.isDragging ? '#e8f0fe' : 'none',
                                                                }}
                                                            >
                                                                <td>
                                                                    <div className='flex image-product items-center'>
                                                                        {/* <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' /> */}
                                                                        <i className='ldicon-drag-drop' />
                                                                        <div className='img'>
                                                                            <img
                                                                                src={
                                                                                    product.src
                                                                                        ? CDN + product.src
                                                                                        : 'https://w.ladicdn.com/ladiui/ladisales/no-image.svg'
                                                                                }
                                                                                width={60}
                                                                                height={60}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{product.name}</td>
                                                                <td>
                                                                    {/* {product.price
                                                                        ? baseHelper.formatMoneyPostFix(
                                                                            product.price,
                                                                            storeInfo.currentStore.currency_symbol
                                                                        )
                                                                        : 0} */}
                                                                    {getPriceProduct(product)}
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        href='#'
                                                                        onClick={() => deleteProductUpsell(product.product_variant_id)}
                                                                    >
                                                                        <i className='ladi-icon icon-bin' />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        </tbody>
                                        {provided.placeholder}
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                )}
            </div>
        </div>
    );
});

export default PanelProductUpsellV4;
