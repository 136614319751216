import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';
import { map, includes, compact } from 'lodash';
import Dropdown from '../../../../components/Dropdown';

import { appLocalStorage } from '../../../../localforage';

import apiActions from '../../../../redux/futures/api/actions';
import * as apiTypes from '../../../../redux/futures/api/types';

import storeActions from '../../../../redux/futures/store/actions';
import storeTypes from '../../../../redux/futures/store/types';

import settingActions from '../../../../redux/futures/setting/actions';
import * as settingTypes from '../../../../redux/futures/setting/types';
import produce from 'immer';

ModalDisconnectNotificationBank.propTypes = {
  onCancel: PropTypes.func,
};

export default function ModalDisconnectNotificationBank(props) {
  const {
    logo,
    onCancel,
    onOpenModalNotifyConnectSuccessfulApi,
    isLoading,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bankRef = new Set();

  const settingReducer = useSelector((state) => state.setting);
  const apiReducer = useSelector((state) => state.api);
  const storeReducer = useSelector((state) => state.store);

  const [modalData, setModalData] = useState({
    
  });

  const [modeOTP, setModeOTP] = useState(false);

  const onChangeModalInput = (event) => {
    setModalData(
      produce(modalData, (draft) => {
          draft[event.target.name] = event.target.value;
      })
    );
  };

  const handleDisconnect = () => {
    // validate + action api continue
    let errors = [];
    const inputsRef = compact(Array.from(bankRef));

    map(inputsRef, (ref) => {
        if (ref.validate) {
            errors = errors.concat(ref.validate());
        }
    });

    errors = compact(errors);

    if (errors.length > 0) {
        const errorMessage = errors.join('<br/>');
        window.LadiUI.toastCustom('danger', '', errorMessage);
        return;
    }
    
    onCancel();
    onOpenModalNotifyConnectSuccessfulApi()
  }

  const handleResendOTP = () => {
    alert('resend otp');
  }

  return (
    <Modal
      id='modal-disconnect-notification-bank'
      title={t('Huỷ liên kết')}
      onCancel={onCancel}
      hasFooter={true}
      visible={true}
      width={600}
      logo={logo}
      bodyStyles={{ maxHeight: '350px' }}
      isCustomFooter={true}
      footer={
        <div className='ladiui-modal-footer'>
          <div className='ls-w-full ls-flex ls-justify-end'>
            <button onClick={onCancel} className='ladiui btn btn-secondary mr-12'>
              {t('ACTIONS.REMOVE')}
            </button>
            {
              modeOTP ? (
                <button
                  onClick={(handleDisconnect)}
                  className={`ladiui btn btn-danger ${props.isLoading ? 'loader' : ''}`}
                >
                  {t('Huỷ liên kết API và Xoá tài khoản')}
                </button>
              ) : (
                <button
                  onClick={() => {
                    setModeOTP(true);
                  }}
                  className={`ladiui btn btn-primary ${props.isLoading ? 'loader' : ''}`}
                >
                  {t('Lấy OTP huỷ liên kết')}
                </button>
              )
            }
            
          </div>
        </div>
      }
    >
      <div className='content mt-0'>
          <React.Fragment>
              <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-16'>
                <div className='ls-row-item'>
                  <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-bg-layer-warning-hover ls-p-16 ls-gap-8 ls-rounded-8'>
                    <div>
                      Việc huỷ liên kết ngân hàng này khỏi LadiSales đồng nghĩa với tất cả giao dịch, dữ liệu tích hợp liên quan đến tài khoản ngân hàng này sẽ bị xóa!
                    </div>
                  </div>
                </div>

                <div className='ls-row-item ls-grid ls-gap-16' style={{'--column': '1fr'}}>
                  <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'> 
                    <label className='ladiui-label'>Bằng việc chấp thuận hủy liên kết API tài khoản này với ngân hàng, LadiSales sẽ thực hiện xóa tài khoản của bạn.</label>
                  </div>
                </div>

                {
                  modeOTP ? (
                    <>
                      <div className='ls-row-item ls-grid ls-gap-16' style={{'--column': '1fr'}}>
                        <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'> 
                          <label className='ladiui-label'>Nhập mã OTP</label>
                          <div className='ls-relative ls-w-full'>
                            <Input
                              ref={(ref) => bankRef.add(ref)}
                              value={modalData?.input_5}
                              validationName={t('NOTIFICATION_BANKING.OTP_INPUT')}
                              name='input_5'
                              onChange={(event) => onChangeModalInput(event)}
                              validations={{ isRequired: true }}
                            />
                            <a className='ls-absolute ls-right-10 ls-top-12' onClick={handleResendOTP}>Gửi lại mã</a>
                          </div>
                        </div>
                      </div>

                      <div className='ls-row-item ls-grid ls-gap-16' style={{'--column': '1fr'}}>
                        <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'>
                          <label className='ladiui-label'>Trường hợp không nhận được mã OTP hoặc mã OTP hết hiệu lực, hãy chọn gửi lại mã.</label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='ls-row-item ls-grid ls-gap-16' style={{'--column': '1fr'}}>
                      <div className='ls-flex ls-flex-col ls-justify-start ls-items-start ls-w-full ls-gap-8'> 
                        <label className='ladiui-label'>Nhấn vào "Lấy OTP" để nhận mã xác thực được gửi từ phía ngân hàng MB.</label>
                      </div>
                    </div>
                  )
                }
               
            </div>
          </React.Fragment>
      </div>
    </Modal>
  );
}
