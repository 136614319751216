import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';

import * as fileTypes from '../redux/futures/file/types';
import fileActions from '../redux/futures/file/actions';

import Popover from './Popover';
import Image from './Image';
import Input from './Input';
import ValidatorHelper from '../helpers/ValidatorHelper';

// eslint-disable-next-line max-lines-per-function

const ImageLogoUpload = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const uploadRef = useRef();

    const { imageSrc, setImageData, type } = props;

    const fileReducer = useSelector((state) => state.file);

    const [image, setImage] = useState({
        src: '',
    });

    const [sectionUpload, setSectionUpload] = useState('');

    useEffect(() => {
        setImage({ src: imageSrc });
    }, [imageSrc]);

    useEffect(() => {
        // if (fileReducer.action == fileTypes.UPLOAD_LOGO_URL) {
        //     if (fileReducer.status) {
        //         let images = fileReducer.logo_url;

        //         if (setLogoUrl) setLogoUrl(images, sectionUpload);

        //         window.LadiUI.toastCustom('success', '', fileReducer.message);
        //     } else {
        //         window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
        //     }
        // }

        if (fileReducer.action == fileTypes.UPLOAD_FAVICON_URL) {
            if (fileReducer.status) {
                let images = fileReducer.favicon_url;

                if (setImageData) setImageData(images, sectionUpload);

                window.LadiUI.toastCustom('success', '', fileReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer.action]);

    const removeImage = () => {
        if (setImageData) setImageData('', type);
    };

    const handleClickUpImage = () => {
        uploadRef.current.click();
    };

    const fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                setSectionUpload(type);
                dispatch(fileActions.uploadFavicon(form));
            }
        }
    };

    return (
        <>
            <div className='content'>
                <div className='preview-store-logo'>
                    <div id='store-logo'>
                        <img className='store-logo' src={baseHelper.getImageUrl(image.src)} alt='' />
                        <div className='store-logo-overlay'>
                            <div className='ladiui btn-group'>
                                <div className='ladiui dropdown'>
                                    <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                        <i className='ladiui icon icon-ldp-pen-edit'></i>
                                    </button>
                                    <ul
                                        className='ladiui dropdown-menu custom-dropdown-menu'
                                        style={{ minWidth: '100px', textAlign: 'left' }}
                                    >
                                        <li className='ladiui open-modal' onClick={handleClickUpImage}>
                                            <label>Thay ảnh</label>
                                        </li>
                                        <li className='ladiui dropdown-item danger-text' onClick={() => removeImage()}>
                                            Xóa
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <input
                        ref={uploadRef}
                        type='file'
                        name='file'
                        accept='image/*'
                        multiple={false}
                        onChange={fileChangedHandler}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                        style={{ display: 'none' }}
                    />
                </div>
            </div>
        </>
    );
});

export default ImageLogoUpload;
