import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { gateways: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.LIST_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.LIST_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
                page_checkouts: action.status ? action.payload.page_checkouts : [],
                total_record: action.status ? action.payload.total_record : 0,
                total_page: action.status ? action.payload.total_page : 0,
            };
        }
        case types.CREATE_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.CREATE_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
                page_checkout: action.status ? action.payload.page_checkout : {},
            };
        }
        case types.CREATE_PAGE_CHECKOUT_V2: {
            return {
                ...state,
                action: types.CREATE_PAGE_CHECKOUT_V2,
                status: action.status,
                message: action.message,
                page_checkout: action.status ? action.payload.page_checkout : {},
            };
        }
        case types.SHOW_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.SHOW_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
                page_checkout: action.status ? action.payload.page_checkout : {},
            };
        }
        case types.SHOW_PAGE_CHECKOUT_V2: {
            return {
                ...state,
                action: types.SHOW_PAGE_CHECKOUT_V2,
                status: action.status,
                message: action.message,
                page_checkout: action.status ? action.payload.page_checkout : {},
            };
        }
        case types.UPDATE_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.UPDATE_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
            };
        }
        case types.UPDATE_PAGE_CHECKOUT_V2: {
            return {
                ...state,
                action: types.UPDATE_PAGE_CHECKOUT_V2,
                status: action.status,
                message: action.message,
            };
        }
        case types.RELOAD_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.RELOAD_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
                page_checkout: action.status ? action.payload.page_checkout : {},
            };
        }
        case types.DELETES_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.DELETES_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
            };
        }
        case types.DELETE_PAGE_CHECKOUT: {
            return {
                ...state,
                action: types.DELETE_PAGE_CHECKOUT,
                status: action.status,
                message: action.message,
            };
        }
        case types.PAGE_COUSE_SHOW: {
            return {
                ...state,
                dataPageCheckout: action.status ? action.payload.page_checkout : {},
                action: types.PAGE_COUSE_SHOW,
                status: action.status,
                message: action.message,
            };
        }
        case types.PAGE_CHECKOUT_UPDATE_NAME: {
            return {
                ...state,
                action: types.PAGE_CHECKOUT_UPDATE_NAME,
                status: action.status,
                message: action.message,
            };
        }

        case types.PAGE_COURSE_ACTIVE_AFFILIATE: {
            console.log('action: ', {action})
            return {
                ...state,
                dataLadishareStore: action.status ? action.payload : {},
                action: types.PAGE_COURSE_ACTIVE_AFFILIATE,
                status: action.status,
                message: action.message,
            };
        }

        default:
            return state;
    }
};
