import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const listVariant = (data) => ({
    type: types.INVENTORY_LIST_VARIANT,
    meta: {
        endpoint: endpoint.INVENTORY_LIST_VARIANT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const updateQuantity = (data) => ({
    type: types.INVENTORY_UPDATE_QUANTITY,
    meta: {
        endpoint: endpoint.INVENTORY_UPDATE_QUANTITY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const updatesQuantity = (data) => ({
    type: types.INVENTORY_UPDATES_QUANTITY,
    meta: {
        endpoint: endpoint.INVENTORY_UPDATES_QUANTITY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const reload = (data) => ({
    type: types.INVENTORY_LIST_VARIANT,
    meta: {
        endpoint: endpoint.INVENTORY_LIST_VARIANT,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

export default {
    listVariant,
    updateQuantity,
    updatesQuantity,
    reload,
}