import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { shipping: {} }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }
            
            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.SHIPPING_RATE_CREATE: {
            return {
                ...state,
                action: types.SHIPPING_RATE_CREATE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHIPPING_RATE_DELETE: {
            return {
                ...state,
                action: types.SHIPPING_RATE_DELETE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHIPPING_RATE_LIST: {
            return {
                ...state,
                action: types.SHIPPING_RATE_LIST,
                status: action.status,
                message: action.message,
                shipping_rates: action.status ? action.payload.shipping_rates : []
            }
        }
        case types.SHIPPING_RATE_DETAIL_CREATE: {
            return {
                ...state,
                action: types.SHIPPING_RATE_DETAIL_CREATE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHIPPING_RATE_DETAIL_UPDATE: {
            return {
                ...state,
                action: types.SHIPPING_RATE_DETAIL_UPDATE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHIPPING_RATE_DETAIL_DELETE: {
            return {
                ...state,
                action: types.SHIPPING_RATE_DETAIL_DELETE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHIPPING_RATE_GET_AVAILABLE: {
            return {
                ...state,
                action: types.SHIPPING_RATE_GET_AVAILABLE,
                status: action.status,
                message: action.message,
                shipping_rates: action.status ? action.payload.shipping_rates : [],
            }
        }
        default:
            return state;
    }
}

