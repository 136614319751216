import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../../../config/app';
import ModalAddCustomField from '../../../../../components/ModalAddCustomField';

// eslint-disable-next-line max-lines-per-function

const ShippingInfo = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { isShow, pageCheckout } = props;

    const defaultCus = {
        name: {
            title: t('CUSTOMERS.FULL_NAME'),
            isEnable: false,
            isRequired: false,
            value: 'name',
        },
        phone: {
            title: t('CUSTOMERS.PHONE'),
            isEnable: true,
            isRequired: true,
            value: 'phone',
        },
        email: {
            title: t('CUSTOMERS.EMAIL'),
            isEnable: true,
            isRequired: true,
            value: 'email',
        },
        location: {
            title: t('CUSTOMERS.LOCATION'),
            isEnable: false,
            isRequired: false,
            value: 'location',
        },
        address: {
            title: t('CUSTOMERS.ADDRESS'),
            isEnable: false,
            isRequired: false,
            value: 'address',
        },
        zipcode: {
            title: 'Zip code',
            isEnable: false,
            isRequired: false,
            value: 'zipcode',
        },
        note: {
            title: t('CUSTOMERS.NOTE'),
            isEnable: false,
            isRequired: false,
            value: 'note',
        },
    };

    const [customerConfig, setCustomerConfig] = useState(defaultCus);
    const [customerEnable, setCustomerEnable] = useState(true);
    const [dataCustomField, setDataCustomField] = useState([]);
    const [isShowModalAddCustomField, setShowModalAddCustomField] = useState(false);
    const [checkoutConfigID, setCheckoutConfigID] = useState(null);

    useImperativeHandle(ref, () => ({
        getData: () => {
            if (customerEnable) {
                if (!customerConfig.email.isEnable && !customerConfig.phone.isEnable) {
                    return {
                        error: 'Số điện điện thoại hoặc email vận chuyển phải được hiển thị',
                    };
                }

                if (customerConfig.email.isEnable && !customerConfig.phone.isEnable && !customerConfig.email.isRequired) {
                    return {
                        error: 'Số điện điện thoại hoặc email vận chuyển phải bắt buộc nhập',
                    };
                }

                if (customerConfig.phone.isEnable && !customerConfig.email.isEnable && !customerConfig.phone.isRequired) {
                    return {
                        error: 'Số điện điện thoại hoặc email vận chuyển phải bắt buộc nhập',
                    };
                }

                if (
                    customerConfig.phone.isEnable &&
                    customerConfig.email.isEnable &&
                    !customerConfig.phone.isRequired &&
                    !customerConfig.email.isRequired
                ) {
                    return {
                        error: 'Số điện điện thoại hoặc email vận chuyển phải bắt buộc nhập',
                    };
                }
            }

            let customFields = dataCustomField.map((item) => {
                item.is_enable = item.isEnable;
                item.is_required = item.isRequired;
                return item;
            });

            let configCustomer = {
                configurable: customerEnable,

                enable_full_name: customerConfig.name.isEnable,
                required_full_name: customerConfig.name.isRequired,

                enable_email: customerConfig.email.isEnable,
                required_email: customerConfig.email.isRequired,

                enable_phone: customerConfig.phone.isEnable,
                required_phone: customerConfig.phone.isRequired,

                enable_location: customerConfig.location.isEnable,
                required_location: customerConfig.location.isRequired,

                enable_address: customerConfig.address.isEnable,
                required_address: customerConfig.address.isRequired,

                enable_zipcode: customerConfig.zipcode.isEnable,
                required_zipcode: customerConfig.zipcode.isRequired,

                enable_note: customerConfig.note.isEnable,
                required_note: customerConfig.note.isRequired,

                custom_field: customFields,
            };
            return configCustomer;
        },
    }));

    useEffect(() => {
        if (pageCheckout && pageCheckout.page_checkout_id) {
            let checkout_config = pageCheckout.checkout_config || {};
            if (checkout_config.checkout_config_id) {
                setCheckoutConfigID(checkout_config.checkout_config_id);

                let config = checkout_config.config || {};
                let config_shipping = config.config_shipping || {};
                setCustomerEnable(config_shipping.configurable);

                let cusData = {
                    name: {
                        title: t('CUSTOMERS.FULL_NAME'),
                        isEnable: config_shipping.enable_full_name || false,
                        isRequired: config_shipping.required_full_name || false,
                        value: 'name',
                    },
                    phone: {
                        title: t('CUSTOMERS.PHONE'),
                        isEnable: config_shipping.enable_phone || false,
                        isRequired: config_shipping.required_phone || false,
                        value: 'phone',
                    },
                    email: {
                        title: t('CUSTOMERS.EMAIL'),
                        isEnable: config_shipping.enable_email || false,
                        isRequired: config_shipping.required_email || false,
                        value: 'email',
                    },
                    location: {
                        title: t('CUSTOMERS.LOCATION'),
                        isEnable: config_shipping.enable_location || false,
                        isRequired: config_shipping.required_location || false,
                        value: 'location',
                    },
                    address: {
                        title: t('CUSTOMERS.ADDRESS'),
                        isEnable: config_shipping.enable_address || false,
                        isRequired: config_shipping.required_address || false,
                        value: 'address',
                    },
                    zipcode: {
                        title: 'Zip code',
                        isEnable: config_shipping.enable_zipcode || false,
                        isRequired: config_shipping.required_zipcode || false,
                        value: 'zipcode',
                    },
                    note: {
                        title: t('CUSTOMERS.NOTE'),
                        isEnable: config_shipping.enable_note || false,
                        isRequired: config_shipping.required_note || false,
                        value: 'note',
                    },
                };

                setCustomerConfig(cusData);

                let custom_field = config_shipping.custom_field || [];
                setDataCustomField(custom_field);
            }
        }
    }, [pageCheckout]);

    const backMenu = () => {
        let _queryStrings = queryStrings;
        _queryStrings.delete('checkout-config');

        history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const handleEnable = (data) => {
        if (data.value == 'phone') {
            if (data.isEnable && !customerConfig.email.isEnable) {
                window.LadiUI.toastCustom('danger', '', 'Số điện điện thoại hoặc email phải được hiển thị');
                return;
            }
        }

        if (data.value == 'email') {
            if (data.isEnable && !customerConfig.phone.isEnable) {
                window.LadiUI.toastCustom('danger', '', 'Số điện điện thoại hoặc email phải được hiển thị');
                return;
            }
        }
        let customerConfigTg = { ...customerConfig };

        customerConfigTg[data.value] = {
            ...data,
            isEnable: !data.isEnable,
        };

        // if (data.isEnable) {
        //     customerConfigTg[data.value].isRequired = false;
        // }

        setCustomerConfig(customerConfigTg);
    };

    const handleRequired = (data) => {
        if (data.value == 'phone') {
            if (data.isEnable && data.isRequired && (!customerConfig.email.isEnable || !customerConfig.email.isRequired)) {
                window.LadiUI.toastCustom('danger', '', 'Số điện điện thoại hoặc email phải bắt buộc nhập');
                return;
            }
        }

        if (data.value == 'email') {
            if (data.isEnable && data.isRequired && (!customerConfig.phone.isEnable || !customerConfig.phone.isRequired)) {
                window.LadiUI.toastCustom('danger', '', 'Số điện điện thoại hoặc email phải bắt buộc nhập');
                return;
            }
        }
        let customerConfigTg = { ...customerConfig };

        customerConfigTg[data.value] = {
            ...data,
            isRequired: !data.isRequired,
        };

        // if (data.isEnable) {
        //     customerConfigTg[data.value].isRequired = false;
        // }

        setCustomerConfig(customerConfigTg);
    };

    const handleAddCustomfieldSelected = (data) => {
        if (data && data.length > 0) {
            setDataCustomField([...dataCustomField, ...data]);
            setShowModalAddCustomField(false);
        }
    };

    const handleRequiredCustomField = (index) => {
        let dataCustomFieldTg = [...dataCustomField];
        dataCustomFieldTg[index].isRequired = !dataCustomFieldTg[index].isRequired;
        setDataCustomField(dataCustomFieldTg);
    };

    const handleDeleteCustomField = (index) => {
        let dataCustomFieldTg = [...dataCustomField];
        dataCustomFieldTg.splice(index, 1);

        setDataCustomField(dataCustomFieldTg);
    };

    return (
        <>
            <ModalAddCustomField
                isShow={isShowModalAddCustomField}
                onClose={() => setShowModalAddCustomField(false)}
                handleAddCustomfieldSelected={handleAddCustomfieldSelected}
                selectedDataCustomField={dataCustomField || []}
            />

            <div className={`page-checkout-config-item  page-checkout-product-list ${isShow ? 'show' : 'hide'}`}>
                <div className='header ladiui flex-center-between'>
                    <div className='display-flex-center gap-16'>
                        <div className='icon-back' onClick={backMenu}>
                            <i className='ldicon-chevron-left' />
                        </div>
                        <span className='ladiui title-header'>{t('PAGE_CHECKOUT.MENU.SHIPPING_INFO')}</span>
                    </div>
                    <div>
                        <div className='ladiui item-form switch'>
                            <label className='ladiui switch m-0'>
                                <input
                                    type='checkbox'
                                    checked={customerEnable}
                                    onChange={() => {
                                        setCustomerEnable(!customerEnable);
                                    }}
                                />
                                <span className='ladiui slider round'></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`config-body ${customerEnable ? '' : 'not-active'}`}>
                    <div className='ladiui flex-center-between config-header'>
                        <div className='config-name'>Tên Trường</div>
                        <div className='config-require'>Bắt buộc nhập</div>
                    </div>
                    <div className='config-list'>
                        {Object.values(customerConfig).map((item, index) => {
                            return (
                                <div key={index} className='ladiui flex-center-between  config-item gap-24'>
                                    <div className='config-name display-flex-center gap-12'>
                                        <div className='ladiui item-form switch '>
                                            <label className='ladiui switch m-0'>
                                                <input
                                                    disabled={!customerEnable}
                                                    type='checkbox'
                                                    checked={item.isEnable}
                                                    onChange={() => {
                                                        handleEnable(item);
                                                    }}
                                                />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        <div>{item.title}</div>
                                    </div>
                                    <div className='config-require'>
                                        <input
                                            disabled={!customerEnable || !item.isEnable}
                                            type='checkbox'
                                            onChange={() => {
                                                handleRequired(item);
                                            }}
                                            checked={item.isRequired}
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {dataCustomField.map((item, index) => {
                            return (
                                <div key={index} className='ladiui flex-center-between  config-item gap-24'>
                                    <div className='config-name display-flex-center gap-12'>
                                        <div className='ladiui item-form switch '>
                                            <label className='ladiui switch m-0'>
                                                <input disabled={true} type='checkbox' checked={true} onChange={() => {}} />
                                                <span className='ladiui slider round'></span>
                                            </label>
                                        </div>
                                        <div>{item.label}</div>
                                        <i className='ladiui icon-new new-ldicon-delete ' onClick={() => handleDeleteCustomField(index)} />
                                    </div>
                                    <div className='config-require'>
                                        <input
                                            disabled={!customerEnable || !item.isEnable}
                                            type='checkbox'
                                            onChange={() => {
                                                handleRequiredCustomField(index);
                                            }}
                                            checked={item.isRequired}
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <div className='display-flex-center config-item gap-8 ' onClick={() => setShowModalAddCustomField(true)}>
                            <i className='new-ldicon-add-circle' />
                            <span className='ladiui text-brand'>Thêm trường tuỳ chỉnh</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default ShippingInfo;
