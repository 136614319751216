import React from 'react';
import routes from '../../routes';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import appConfig from '../../config/app';
import authActions from '../../redux/futures/auth/actions';
import * as assignTypes from '../../redux/futures/assign/types';
import assignActions from '../../redux/futures/assign/actions';
import SidebarItem from '../../components/SidebarItem';
import { endpoint } from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';
import i18n from '../../i18n';
import Modal from '../../components/Modal';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validAvatar: true,
            isShowModalStore: false,
            isShowModalPackage: false,
            onlineStatus: props.userInfo.online_status,
            isShowAvt: true,
            avtSelected: '',
        };
        this.infoSectionRef = React.createRef();
        this.infoContentRef = React.createRef();
    }

    changeStore = (storeID) => {
        // baseHelper.setCookie(appConfig.COOKIE.STORE_ID, storeID);
        // window.location.href = '/';
    };

    signOut = () => {
        this.props.signOut();
    };

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.userInfo != nextProps.userInfo) {
            this.setState({
                onlineStatus: nextProps.userInfo.online_status,
            });
        }

        if (this.props.assignReducer.action != nextProps.assignReducer.action) {
            if (nextProps.assignReducer.action == assignTypes.ASSIGN_UPDATE_ONLINE_STATUS) {
                if (nextProps.assignReducer.status) {
                    window.LadiUI.toastCustom('success', '', nextProps.assignReducer.message);
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', nextProps.assignReducer.message, 'OK');
                }
            }
        }
    }

    getTextAvatar = (name) => {
        let splitName = name.split(' ');
        let firstCharactor = splitName[0].split('')[0];
        let lastCharactor = splitName[splitName.length - 1].split('')[0];

        let sortName = firstCharactor + lastCharactor;

        return sortName;
    };

    randomBackgroundColor = () => {
        let LIST_BG_COLOR = ['#F24A72', '#333C83', '#53BF9D', '#BD4291', '#693C72', '#111D5E', '#799351', '#FD8C04'];

        let randomBgColor = Math.floor(Math.random() * LIST_BG_COLOR.length);

        return LIST_BG_COLOR[randomBgColor];
    };

    openModalPackage = () => {
        this.setState({
            isShowModalPackage: true,
        });
    };

    getPkgName = (name, pkg_exp) => {
        switch (name) {
            case appConfig.PACKAGE_NAME.PREMIUM:
                return (
                    <button onClick={this.openModalPackage} className='ladiui cursor-pointer status-premium flex-row'>
                        <div className='ladiui status-text'>{name}</div>
                    </button>
                );
            case appConfig.PACKAGE_NAME.STARTER:
                return (
                    <div className='flex'>
                        <button onClick={this.openModalPackage} className='ladiui mr-8 cursor-pointer status-premium flex-row'>
                            <div className='ladiui status-text'>{name}</div>
                        </button>
                        <span className='ladiui account-duration secondary-text caption'>{`  ${i18n.t(
                            'PACKAGE.PKG_EXP'
                        )}: Vĩnh viễn`}</span>
                    </div>
                );

            default:
                return (
                    <div className='flex'>
                        <button onClick={this.openModalPackage} className='ladiui cursor-pointer status-starter flex-row'>
                            <div className='ladiui status-text'>{name ? baseHelper.getPackageName(name) : ''}</div>
                        </button>
                        <span className='ladiui account-duration secondary-text caption ml-8'>{`  ${i18n.t(
                            'PACKAGE.PKG_EXP'
                        )}: ${baseHelper.formatDateToStr(pkg_exp, appConfig.DEFAULT_FORMAT_DATE)}`}</span>
                    </div>
                );
        }
    };

    getRemainingCapacity = () => {
        let { currentStore = {} } = this.props.userInfo;
        // baseHelper.parseInt(currentStore.order_use)
        let data = 0;

        data = (currentStore.bunny_total_size - currentStore.bunny_total_uploaded_size) / (1024 * 1024 * 1024);

        return baseHelper.formatNumber(data);
    };

    onChangeOnlineStatus = () => {
        this.setState(
            {
                onlineStatus:
                    this.state.onlineStatus == appConfig.STAFF_ONLINE_STATUS.ONLINE
                        ? appConfig.STAFF_ONLINE_STATUS.OFFLINE
                        : appConfig.STAFF_ONLINE_STATUS.ONLINE,
            },
            () => {
                this.props.updateOnlineStatus(this.state.onlineStatus);
            }
        );
    };

    render() {
        const { scopes, isDomainIPay, userInfo } = this.props;
        const { onlineStatus, isShowAvt, avtSelected } = this.state;
        const { fullname, email, avatar, stores, currentStore = {} } = this.props.userInfo;

        const availableItems = routes.getItemByScopes(scopes);

        return (
            <section className='ladiui wrapper' id='sidebar-left'>
                <aside className='ladiui aside'>
                    <nav className='ladiui nav'>
                        <ul className='ladiui menu'>
                            {/* <li className="ladiui logo-item logo">
              <a href="#" className="ladiui flex-row-menu-item">
                {isDomainIPay ? (
                  <img src="https://w.ladicdn.com/ladisales/ipay-icon.svg" alt="" style={{ height: "32px" }} />
                ) : (
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales.svg" alt="" />
                )}
              </a>
            </li>
            <li className="ladiui logo-item logo-hover">
              <a href="#" className="ladiui flex-row-menu-item">
                {isDomainIPay ? (
                  <img src="https://w.ladicdn.com/ladisales/ipay.svg" alt="" style={{ height: "32px", backgroundColor: "#f1f3f4" }} />
                ) : (
                  <img src="https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales-fw.svg" alt="" />
                )}
              </a>
            </li> */}
                            <li className='ladiui logo-item logo'>
                                <Link to='' className='ladiui flex-row-menu-item'>
                                    {isDomainIPay ? (
                                        <img src='https://w.ladicdn.com/ladisales/ipay-icon.svg' alt='' style={{ height: '32px' }} />
                                    ) : (
                                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/logo-ladisales.svg' alt='' />
                                    )}
                                </Link>
                            </li>
                            {map(availableItems, (item, index) => {
                                if (!userInfo.is_show_course && index == '/courses') {
                                    return null;
                                }
                                return (
                                    <SidebarItem
                                        keyStr={item.keyStr}
                                        key={item.key}
                                        classLi={item.classLi}
                                        to={item.to}
                                        classNav={item.classNav}
                                        classIcon={item.classIcon}
                                        title={item.title}
                                        children={item.children}
                                        userEmail={email ? email : ''}
                                    />
                                );
                                // if (!item.key.includes("courses")) {
                                //   return (
                                //     <SidebarItem
                                //       keyStr={item.keyStr}
                                //       key={item.key}
                                //       classLi={item.classLi}
                                //       to={item.to}
                                //       classNav={item.classNav}
                                //       classIcon={item.classIcon}
                                //       title={item.title}
                                //       children={item.children}
                                //       userEmail={userInfo ? userInfo.email : ""}
                                //     />
                                //   )
                                // } else {
                                //   if (userInfo && userInfo.email && appConfig.WHITE_LIST_EMAIL.includes(userInfo.email)) {
                                //     return (
                                //       <SidebarItem
                                //         keyStr={item.keyStr}
                                //         key={item.key}
                                //         classLi={item.classLi}
                                //         to={item.to}
                                //         classNav={item.classNav}
                                //         classIcon={item.classIcon}
                                //         title={item.title}
                                //         children={item.children}
                                //         userEmail={userInfo ? userInfo.email : ""}
                                //       />
                                //     )
                                //   }
                                // }
                            })}
                        </ul>
                        <div className='ladiui account-tool-setting'>
                            <div className='ladiui item-form switch mt-0'>
                                <label className='ladiui switch m-0'>
                                    <input
                                        type='checkbox'
                                        checked={onlineStatus == appConfig.STAFF_ONLINE_STATUS.ONLINE}
                                        onChange={this.onChangeOnlineStatus}
                                    />
                                    <span className='ladiui slider round'></span>
                                </label>
                            </div>
                            <div className='ladiui app-tools flex-row'>
                                <div className='ladiui tool-help tool-help-fix-top'>
                                    <button className='dropdown-toggle btn-help' data-toggle='dropdown'>
                                        <img src='https://w.ladicdn.com/design-system/icons/icon-ldp-help.svg' alt='' />
                                    </button>
                                    <ul className='ladiui dropdown-menu block-help-ladisales-top list-tool'>
                                        <li>
                                            <a className='ladiui dropdown-item' href='https://help.ladisales.vn/' target='_blank'>
                                                <i className='ladiui icon icon-ldp-helper-document'></i>
                                                {i18n.t('PACKAGE.MANUAL_DOCUMENT')}
                                            </a>
                                        </li>
                                        <li
                                            onClick={() => {
                                                window.LadiChat.push([['do', 'chat:open']]);
                                            }}
                                        >
                                            <a className='ladiui dropdown-item' href='#'>
                                                <i className='ladi-icon icon-blog'></i>
                                                {i18n.t('PACKAGE.LIVE_CHAT')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className='ladiui dropdown-item'
                                                href='https://www.facebook.com/groups/ladipagevietnam'
                                                target='_blank'
                                            >
                                                <i className='ladiui icon icon-ldp-fb-group'></i>
                                                {i18n.t('PACKAGE.COMMUNITY_LADIPAGE')}
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className='ladiui dropdown-item'
                                                href='https://www.ladiboost.vn/request?by=LadiSales'
                                                target='_blank'
                                            >
                                                <i className='ladi-icon icon-c-warning'></i>
                                                {i18n.t('PACKAGE.FEATURE_REQUEST')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className='ladiui account'>
                                <div className='ladiui my-account flex-row dropdown-toggle' data-toggle='dropdown'>
                                    <div className='ladiui my-account-avata'>
                                        {isShowAvt ? (
                                            <img
                                                src={avatar}
                                                onError={({ currentTarget }) => {
                                                    this.setState({
                                                        isShowAvt: false,
                                                    });
                                                }}
                                                alt=''
                                            />
                                        ) : (
                                            <div className='block-header show-avt-name' style={{ backgroundColor: avtSelected }}>
                                                {this.getTextAvatar(fullname)}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='ladiui account dropdown-menu'>
                                    <div className='ladiui account-content'>
                                        <div className='ladiui account-header'>
                                            <div className='ladiui account-avatar block-info'>
                                                {isShowAvt ? (
                                                    <img
                                                        src={avatar}
                                                        onError={({ currentTarget }) => {
                                                            this.setState({
                                                                isShowAvt: false,
                                                            });
                                                        }}
                                                        alt=''
                                                        className='ladiui avt'
                                                    />
                                                ) : (
                                                    <div className='block-header show-avt-name' style={{ backgroundColor: avtSelected }}>
                                                        {this.getTextAvatar(fullname)}
                                                    </div>
                                                )}
                                                {/* <a href="#" className="">
                      <i className="ladiui icon icon-camera"></i>
                    </a> */}
                                            </div>
                                            <div className='ladiui account-header-text'>
                                                <span className='ladiui account-name body-1 email-address-store-custom'>
                                                    {fullname}
                                                    <span className='ladiui role-text'>
                                                        ({currentStore && currentStore.role ? currentStore.role : ''})
                                                    </span>
                                                </span>
                                                <span className='ladiui account-email subtitle-2 secondary-text'>{email}</span>

                                                <div className='ladiui my-account-type'>
                                                    {this.getPkgName(currentStore.pkg_name, currentStore.pkg_exp)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='ladiui account-body'>
                                        {stores.map((store, index) => {
                                            return (
                                                <a
                                                    href='#'
                                                    className={`ladiui dropdown-item account-body-item ${
                                                        store.store_id === currentStore.store_id ? 'active' : ''
                                                    }`}
                                                    onClick={() => this.changeStore(store.store_id)}
                                                    key={index}
                                                >
                                                    <div className='ladiui block-avatar custom'>
                                                        <div className='ladiui avartar'>
                                                            <div
                                                                id='setBackgroudColor'
                                                                className={`ladiui show-avata-name ${
                                                                    store.store_id !== currentStore.store_id ? 'custom' : ''
                                                                }`}
                                                                // style={{ backgroundColor: this.randomBackgroundColor() }}
                                                            >
                                                                <div className='ladiui show-text-avata custom'>
                                                                    {this.getTextAvatar(store.name)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='ladiui account-body-content'>
                                                        <span className='ladiui account-name default-text subtitle-2'>{store.name}</span>
                                                        <div className='ladiui account-status'>
                                                            {store.pkg_actived_at && (
                                                                <span className='ladiui account-duration secondary-text caption'>
                                                                    {i18n.t('PACKAGE.ACTIVE_DATE')}:
                                                                    {baseHelper.formatDateToStr(
                                                                        store.pkg_actived_at,
                                                                        appConfig.DEFAULT_FORMAT_DATE
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {store.store_id === currentStore.store_id ? (
                                                        <i className='ladiui account-tick icon icon-account-check'></i>
                                                    ) : null}
                                                </a>
                                            );
                                        })}
                                    </div>

                                    <div className='ladiui account-content-footer'>
                                        <div className='ladiui account-footer'></div>
                                    </div>
                                    <a className='ladiui dropdown-item' target='_blank' href={endpoint.PROFILE}>
                                        <i className='ladiui icon icon-ldp-setting-builder'></i>
                                        {i18n.t('PACKAGE.ACCOUNT_MANAGEMENT')}
                                    </a>
                                    <a className='ladiui dropdown-item' onClick={this.signOut}>
                                        <i className='ladiui icon icon-ldp-log-out'></i>
                                        {i18n.t('ACTIONS.LOGOUT')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </aside>
                {this.state.isShowModalPackage && (
                    <Modal
                        id='modal-package'
                        title={i18n.t('PACKAGE.TITLE')}
                        visible={this.state.isShowModalPackage}
                        onCancel={() => {
                            this.setState({
                                isShowModalPackage: false,
                            });
                        }}
                        width={400}
                        zIndex={1050}
                        hasFooter={true}
                        footer={
                            <div className='ladiui-modal-footer'>
                                <div className='flex'>
                                    <button
                                        onClick={() => {
                                            window.open('https://www.ladisales.vn/', '_blank');
                                        }}
                                        className='ladiui btn btn-primary'
                                    >
                                        {i18n.t('PACKAGE.UPGRADE')}
                                    </button>
                                </div>
                            </div>
                        }
                    >
                        <div className='package-content-info'>
                            <ul>
                                <li className='package-item'>
                                    <span>{i18n.t('PACKAGE.NAME')}</span>
                                    <label className='ladiui item-title'>{baseHelper.getPackageName(currentStore.pkg_name)}</label>
                                </li>
                                <li className='package-item'>
                                    <span>{i18n.t('PACKAGE.ACTIVE_DATE')}</span>
                                    <span>{baseHelper.formatStrToDate(currentStore.pkg_actived_at, appConfig.DEFAULT_FORMAT_DATE)}</span>
                                </li>
                                <li className='package-item'>
                                    <span>{i18n.t('PACKAGE.EXPIRED_DATE')}</span>
                                    <span>{baseHelper.formatStrToDate(currentStore.pkg_exp, appConfig.DEFAULT_FORMAT_DATE)}</span>
                                </li>
                                <li className='package-item'>
                                    <span>{i18n.t('PACKAGE.ORDER_NUM')}</span>
                                    <span>{`${baseHelper.parseInt(currentStore.order_use)} / ${baseHelper.parseInt(
                                        currentStore.order_num
                                    )}`}</span>
                                </li>
                                <li className='package-item'>
                                    <span>{i18n.t('PACKAGE.REMAINING_CAPACITY')}</span>
                                    <span>
                                        {/* {`${baseHelper.parseInt(currentStore.order_use)} / ${baseHelper.parseInt(currentStore.order_num)}`} */}
                                        {this.getRemainingCapacity()} GB
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </Modal>
                )}
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(authActions.signOut()),
    updateOnlineStatus: (status) => dispatch(assignActions.updateOnlineStatus(status)),
});

const mapStateToProps = (state) => ({
    setting: { ...state.setting },
    auth: { ...state.auth },
    staff: { ...state.staff },
    assignReducer: { ...state.assign },
});

// export default withTranslation()(Sidebar);
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar));
