import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';
import orderAction from '../../../../redux/futures/order/actions';

import * as customerTypes from '../../../../redux/futures/customer/types';
import customerAction from '../../../../redux/futures/customer/actions';

import ModalCustomerCreate from './ModalCustomerCreate';
import ModalCustomerUpdate from './ModalCustomerUpdate';
import ModalCustomerAddressCreate from './ModalCustomerAddressCreate';
import config from '../../../../config/config';
import PanelCustomField from '../../../../components/PanelCustomField';
import PanelCustomerField from '../../components/PanelCustomerField';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
export const OrderCustomer = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        customerInfo,
        setCustomerInfo,
        customerShipping,
        setCustomerShipping,
        customerBilling,
        setCustomerBilling,
        orderData,
        isEditableOrder,
    } = props;

    const storeReducer = useSelector((state) => state.store);
    const customerReducer = useSelector((state) => state.customer);

    const customers = useMemo(() => {
        return customerReducer.customers || [];
    }, [customerReducer.customers]);

    const [address, setAddress] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [isShowList, setShowList] = useState(false);
    const [isShowListAddress, setShowListAddress] = useState(false);
    const [isShowListAddressBilling, setShowListAddressBilling] = useState(false);
    const [isSelectedBilling, setSelectedBilling] = useState(false);

    const [isShowModalCustomerCreate, setShowModalCustomerCreate] = useState(false);
    const [isShowModalCustomerUpdate, setShowModalCustomerUpdate] = useState(false);
    const [isShowModalCustomerAddressCreate, setShowModalCustomerAddressCreate] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            return customerInfo;
        },
    }));

    useEffect(() => {
        dispatch(customerAction.search(''));
    }, []);

    useEffect(() => {
        const getData = setTimeout(() => {
            customerSearch();
        }, 500);

        return () => clearTimeout(getData);
    }, [searchValue]);

    useEffect(() => {
        if (customerReducer.action == customerTypes.SHOW_CUSTOMER) {
            if (customerReducer.status) {
                let customer = customerReducer.customer;
                setCustomerInfo(customer);
                setAddress(customer.addresses || []);
            } else {
                // window.LadiUI.toastCustom('danger', '', customerReducer.message);
                // window.LadiUI.showErrorMessage('Thông báo', customerReducer.message, 'OK');
            }
        }
    }, [customerReducer.action]);

    const customerSearch = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        dispatch(customerAction.search(searchValue));
    };

    const selectedCustomer = (item, _address = []) => {
        setShowList(false);
        setCustomerInfo(item);
        setCustomerShipping(item.address_default || {});
        setCustomerBilling(item.address_default || {});
        setAddress([...address, ..._address]);
    };
    const selectedAddress = (item, isUpdate = false) => {
        setCustomerShipping(item);

        if (isUpdate) setAddress([...address, { ...item }]);

        if (orderData && orderData.order_id) {
            let dataSend = {
                order_id: orderData.order_id,
                shipping_address: item,
            };
            dispatch(orderAction.updateAddress(dataSend));
        }

        setShowListAddress(false);
    };

    const selectedBilling = (item, isUpdate = false) => {
        setCustomerBilling(item);
        if (isUpdate) setAddress([...address, { ...item }]);
        setShowListAddressBilling(false);

        if (orderData && orderData.order_id) {
            let dataSend = {
                order_id: orderData.order_id,
                billing_address: item,
            };
            dispatch(orderAction.updateAddress(dataSend));
        }
    };

    const getAddress = (item) => {
        let address_default = item || {};
        let addressArray = [];

        if (address_default.address) addressArray.push(address_default.address);
        if (address_default.ward_name) addressArray.push(address_default.ward_name);
        if (address_default.district_name) addressArray.push(address_default.district_name);
        if (address_default.state_name) addressArray.push(address_default.state_name);
        if (address_default.country_name) addressArray.push(address_default.country_name);
        return addressArray.join(', ');
    };

    const updateCustomer = (data) => {
        setCustomerInfo((pre) => {
            let tg = { ...pre, ...data };
            return tg;
        });
        if (orderData && orderData.order_id) dispatch(orderAction.updateOrderCustomerInfo(orderData.order_id, data.email, data.phone));
    };

    const getCustomerURL = (customer_id) => {
        let query_ls = { customer_id: customer_id };

        let url = `${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.CUSTOMER_LIST}&query_ls=${JSON.stringify(query_ls)}`;
        return url;
    };

    return (
        <>
            {isShowList && <div className='ladiui backdrop' onClick={() => setShowList(false)} />}
            <div className='order-customer-info ladiui container-place'>
                <div className='order-customer-title ladiui flex-center-between'>
                    <label className='ladiui label-18'>{t('CUSTOMERS.LABEL')}</label>
                    {isEditableOrder && customerInfo && customerInfo.customer_id && (
                        <i className='ldicon-close' onClick={() => setCustomerInfo({})} />
                    )}
                </div>

                {(!customerInfo || !customerInfo.customer_id) && (
                    <>
                        <div
                            className='ladiui search-group customer-search'
                            // onBlur={() => setTimeout(() => setShowList(false), 300)}
                        >
                            <i className='ladiui icon icon-search' onClick={customerSearch}></i>
                            <input
                                id='keyword_search'
                                className='ladiui search-field dropdown-toggle form-control search-width'
                                name='searchName'
                                placeholder={t('CUSTOMERS.SEARCH_CUSTOMER')}
                                aria-expanded='false'
                                value={searchValue}
                                onFocus={() => setShowList(true)}
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        customerSearch();
                                    }
                                }}
                            />
                            <div className={`customers-search-list ${isShowList ? 'show' : ''}`}>
                                <div
                                    className='customer-create-btn'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowList(false);
                                        setShowModalCustomerCreate(true);
                                    }}
                                >
                                    <i className='new-ldicon-add-circle' />
                                    <span className='ladiui text-14-brand'>{t('CUSTOMERS.ADD_NEW')}</span>
                                </div>

                                <div className='customer-list'>
                                    {customers.map((item, index) => {
                                        return (
                                            <div
                                                className='customer-item'
                                                key={index}
                                                onClick={() => {
                                                    selectedCustomer(item, item.addresses);
                                                }}
                                            >
                                                <div className='ladiui label-16'>
                                                    {item.first_name} {item.last_name}
                                                </div>
                                                <div className='ladiui text-14'> {item.email}</div>
                                                <div className='ladiui text-14'> {item.phone}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className='customer-empty'>
                            <i className='ladiui icon-new new-ldicon-user' />
                            <div className='mt-8 ladiui text-14'>{t('CUSTOMERS.EMPTY')}</div>
                        </div>
                    </>
                )}
                {customerInfo && customerInfo.customer_id && (
                    <div className='customer-info-detail'>
                        <div className='customer-name ladiui label-18'>
                            <a target='_blank' href={getCustomerURL(customerInfo.customer_id)}>
                                {customerInfo.first_name} {customerInfo.last_name}
                            </a>
                        </div>
                        {/* Thông tin liên hệ */}
                        <div className='customer-info customer-item'>
                            <div className='ladiui flex-center-between'>
                                <span className='ladiui label-02 '>{t('CUSTOMERS.INFO_LABEL')}</span>
                                <span className='ladiui text-14 customer-action' onClick={() => setShowModalCustomerUpdate(true)}>
                                    {t('ACTIONS.CHANGE')}
                                </span>
                            </div>
                            <div className='mt-12  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('COMMON.EMAIL')}: `} </span>
                                <span className='item-value'>{customerInfo.email}</span>
                            </div>
                            <div className='mt-8  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('COMMON.PHONE')}: `} </span>
                                <span className='item-value'>{customerInfo.phone}</span>
                            </div>
                        </div>
                        {/* Thông tin giao hàng */}
                        <div className='customer-address customer-item'>
                            <div className='  customer-address-label'>
                                <div className='ladiui flex-center-between'>
                                    <span className='ladiui label-02 '>{`${t('SHIPPINGS.ADDRESS')}`}</span>
                                    <div
                                        className='ladiui text-14 customer-action customer-address-action'
                                        onClick={() => setShowListAddress(true)}
                                    >
                                        {t('ACTIONS.CHANGE')}
                                    </div>
                                </div>
                                <div
                                    className={`ladiui-modal-backdrop  no-bg ${isShowListAddress ? '' : 'hide'}`}
                                    onClick={() => setShowListAddress(false)}
                                ></div>
                                <div className={`customers-search-list ${isShowListAddress ? 'show' : ''}`}>
                                    <div
                                        className='customer-create-btn'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowModalCustomerAddressCreate(true);
                                            setShowListAddress(false);
                                            setSelectedBilling(false);
                                        }}
                                    >
                                        <i className='icon-move' /> <span>{`${t('CUSTOMERS.ADDRESS_NEW')}`}</span>
                                    </div>

                                    <div className='customer-list'>
                                        {address.map((item, index) => {
                                            return (
                                                <div className='customer-item' key={index} onClick={() => selectedAddress(item)}>
                                                    <div className='ladiui label-16'>
                                                        {item.first_name} {item.last_name} {item.phone ? ` - ${item.phone}` : ''}
                                                    </div>
                                                    <div className='ladiui text-14'> {getAddress(item)}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className='mt-12 item-detail'>
                                <span className='item-label ladiui text-14'>{`${t('ORDERS.CUSTOMER')}: `}</span>
                                <span className='item-value'>
                                    {customerShipping.first_name} {customerShipping.last_name || ''}
                                </span>
                            </div>
                            <div className='mt-8  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('COMMON.PHONE')}: `}</span>
                                <span className='item-value'>{customerShipping.phone || ''}</span>
                            </div>
                            <div className='mt-8  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('CUSTOMERS.ADDRESS')}: `}</span>
                                <span className='item-value'>{getAddress(customerShipping)}</span>
                            </div>
                        </div>

                        {/* Thông tin hoá đơn */}
                        <div className=' customer-item'>
                            <div className=' ladiui pos-rel'>
                                <div className='ladiui flex-center-between'>
                                    <span className='ladiui label-02 '>{t('ADDRESSES.BILLING')}</span>
                                    <span className='ladiui text-14 customer-action' onClick={() => setShowListAddressBilling(true)}>
                                        {t('ACTIONS.CHANGE')}
                                    </span>
                                </div>
                                <div
                                    className={`ladiui-modal-backdrop no-bg ${isShowListAddressBilling ? '' : 'hide'}`}
                                    onClick={() => setShowListAddressBilling(false)}
                                ></div>
                                <div className={`customers-search-list ${isShowListAddressBilling ? 'show' : ''}`}>
                                    <div
                                        className='customer-create-btn'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowModalCustomerAddressCreate(true);
                                            setShowListAddressBilling(false);
                                            setSelectedBilling(true);
                                        }}
                                    >
                                        <i className='icon-move' /> <span>{t('CUSTOMERS.ADDRESS_NEW')}</span>
                                    </div>

                                    <div className='customer-list'>
                                        {address.map((item, index) => {
                                            return (
                                                <div className='customer-item' key={index} onClick={() => selectedBilling(item)}>
                                                    <div className='ladiui label-16'>
                                                        {item.first_name} {item.last_name} {item.phone ? ` - ${item.phone}` : ''}
                                                    </div>
                                                    <div className='ladiui text-14'> {getAddress(item)}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-12  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('ORDERS.CUSTOMER')}: `}</span>
                                <span className='item-value'>
                                    {customerBilling.first_name} {customerBilling.last_name}
                                </span>
                            </div>
                            <div className='mt-8  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('COMMON.PHONE')}: `}</span>
                                <span className='item-value'>{customerBilling.phone}</span>
                            </div>
                            <div className='mt-8  item-detail'>
                                <span className='item-label adiui text-14'>{`${t('CUSTOMERS.ADDRESS')}: `}</span>
                                <span className='item-value'>{getAddress(customerBilling)}</span>
                            </div>
                        </div>

                        {/* Thông tin tuỳ chỉnh */}
                        {customerInfo && customerInfo.custom_fields && customerInfo.custom_fields.length > 0 && (
                            <div className='customer-billing customer-item'>
                                <div className=' ladiui pos-rel'>
                                    <PanelCustomerField
                                        customFields={customerInfo.custom_fields}
                                        orderID={orderData.order_id}
                                        mode={appConfig.FORM_MODE.EDIT}
                                        customerID={customerInfo.customer_id}
                                        classNameCustom=''
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {isShowModalCustomerCreate && (
                <ModalCustomerCreate
                    visible={isShowModalCustomerCreate}
                    hideModal={() => {
                        setShowModalCustomerCreate(false);
                    }}
                    selectedCustomer={selectedCustomer}
                />
            )}
            {isShowModalCustomerUpdate && (
                <ModalCustomerUpdate
                    visible={isShowModalCustomerUpdate}
                    hideModal={() => setShowModalCustomerUpdate(false)}
                    _customer={customerInfo}
                    updateCustomer={updateCustomer}
                />
            )}
            {isShowModalCustomerAddressCreate && (
                <ModalCustomerAddressCreate
                    visible={isShowModalCustomerAddressCreate}
                    hideModal={() => setShowModalCustomerAddressCreate(false)}
                    selectedAddress={selectedAddress}
                    selectedBilling={selectedBilling}
                    customer={customerInfo}
                    isShowListAddressBilling={isSelectedBilling}
                />
            )}
        </>
    );
});
