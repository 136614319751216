import React from 'react';
import { useTranslation } from 'react-i18next';
import appConfig from '../../../../config/app';


export default function ItemShipping(props) {

    const { logo, description, name, dataGateWay, isShowModal, initData, active, openDisconnectModal, setShowModal, openInactiveModal, isLoadingActive } = props;
    const { t } = useTranslation();

    return (
        <div className="item">
            <div className="action">
                <div className="text-image">
                    <img src={logo} className="shipping-partner-logo" />
                    <span className="sub-name">{name}</span>
                </div>
                <div className="btn-action">

                    {
                        !dataGateWay
                            ?
                            <button
                                type="button"
                                className={`ladiui ml-16 btn btn-secondary btn-sm ${!dataGateWay ? "btn-payment-custom" : ""}`}
                                onClick={() => {
                                    setShowModal(!isShowModal)
                                }}

                            >{t('ACTIONS.SETUP')}</button>
                            :
                            <button
                                type="button"
                                className="ladiui ml-16 btn btn-secondary btn-sm"
                                onClick={() => {
                                    setShowModal(!isShowModal)
                                }}

                            >{t('ACTIONS.DETAIL')}</button>
                    }
                    {
                        dataGateWay
                        &&
                        <>
                            {
                                dataGateWay.status == appConfig.STATUS.ACTIVE
                                    ?
                                    <button onClick={() => openInactiveModal(dataGateWay)} className="ladiui ml-16 btn btn-danger btn-sm">{t('ACTIONS.INACTIVE')}</button>
                                    :
                                    <React.Fragment>
                                        <button onClick={() => active(dataGateWay)} className={`ladiui ml-16 btn btn-primary btn-sm ${isLoadingActive ? 'loader' : ''}`}>{t('ACTIONS.ENABLE')}</button>

                                        <button type="button" className="ladiui ml-16 btn btn-secondary btn-sm" onClick={() => openDisconnectModal(dataGateWay)} >
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.5 2H9V1.5C8.99835 1.10268 8.83979 0.722106 8.55884 0.441157C8.27789 0.160209 7.89732 0.00164523 7.5 0H4.5C4.10268 0.00164523 3.72211 0.160209 3.44116 0.441157C3.16021 0.722106 3.00165 1.10268 3 1.5V2H0.5C0.367392 2 0.240215 2.05268 0.146447 2.14645C0.0526785 2.24021 0 2.36739 0 2.5C0 2.63261 0.0526785 2.75979 0.146447 2.85355C0.240215 2.94732 0.367392 3 0.5 3H1V12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13H10C10.2652 13 10.5196 12.8946 10.7071 12.7071C10.8946 12.5196 11 12.2652 11 12V3H11.5C11.6326 3 11.7598 2.94732 11.8536 2.85355C11.9473 2.75979 12 2.63261 12 2.5C12 2.36739 11.9473 2.24021 11.8536 2.14645C11.7598 2.05268 11.6326 2 11.5 2ZM4 1.5C4 1.36739 4.05268 1.24021 4.14645 1.14645C4.24021 1.05268 4.36739 1 4.5 1H7.5C7.63261 1 7.75979 1.05268 7.85355 1.14645C7.94732 1.24021 8 1.36739 8 1.5V2H4V1.5ZM10 12H2V3H10V12ZM5 5.5V9.5C5 9.63261 4.94732 9.75979 4.85355 9.85355C4.75979 9.94732 4.63261 10 4.5 10C4.36739 10 4.24021 9.94732 4.14645 9.85355C4.05268 9.75979 4 9.63261 4 9.5V5.5C4 5.36739 4.05268 5.24021 4.14645 5.14645C4.24021 5.05268 4.36739 5 4.5 5C4.63261 5 4.75979 5.05268 4.85355 5.14645C4.94732 5.24021 5 5.36739 5 5.5ZM8 5.5V9.5C8 9.63261 7.94732 9.75979 7.85355 9.85355C7.75979 9.94732 7.63261 10 7.5 10C7.36739 10 7.24021 9.94732 7.14645 9.85355C7.05268 9.75979 7 9.63261 7 9.5V5.5C7 5.36739 7.05268 5.24021 7.14645 5.14645C7.24021 5.05268 7.36739 5 7.5 5C7.63261 5 7.75979 5.05268 7.85355 5.14645C7.94732 5.24021 8 5.36739 8 5.5Z" fill="#D94F45" />
                                            </svg>
                                        </button>
                                    </React.Fragment>
                            }
                        </>
                    }
                </div>
            </div>

            <div className="description">
                {description}
            </div>
        </div>
    )
}

