import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [], userInfo: { currentStore: {} } }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_FORM_CONFIG: {
            return {
                ...state,
                action: types.LIST_FORM_CONFIG,
                form_configs: action.status ? action.payload.form_configs : [],
                total_record: action.status ? action.payload.total_record : 0,
                total_page: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_FORM_CONFIG: {
            return {
                ...state,
                action: types.DELETE_FORM_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETES_FORM_CONFIG: {
            return {
                ...state,
                action: types.DELETES_FORM_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_FORM_CONFIG: {
            return {
                ...state,
                action: types.SHOW_FORM_CONFIG,
                status: action.status,
                message: action.message,
                form_config: action.status ? action.payload.form_config : {},
            }
        }
        case types.RELOAD_FORM_CONFIG: {
            return {
                ...state,
                action: types.RELOAD_FORM_CONFIG,
                status: action.status,
                message: action.message,
                form_config: action.status ? action.payload.form_config : {},
            }
        }
        case types.CREATE_FORM_CONFIG: {
            return {
                ...state,
                action: types.CREATE_FORM_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_FORM_CONFIG: {
            return {
                ...state,
                action: types.UPDATE_FORM_CONFIG,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_ACCOUNT_CONFIG: {
            return {
                ...state,
                action: types.SHOW_ACCOUNT_CONFIG,
                account_config: action.status ? action.payload.account_config: {},
                status: action.status,
                message: action.message,
            }
        }
        case types.ADD_ACCOUNT_CONFIG: {
            return {
                ...state,
                action: types.ADD_ACCOUNT_CONFIG,
                form_config: action.status ? action.payload.form_config: {},
                status: action.status,
                message: action.message,
            }
        }
        case types.CANCEL_ACCOUNT_CONFIG: {
            return {
                ...state,
                action: types.CANCEL_ACCOUNT_CONFIG,
                form_config: action.status ? action.payload.form_config: {},
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

