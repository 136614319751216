export const COURSE_MODULE_CREATE = "COURSE_MODULE_CREATE";
export const COURSE_MODULE_LIST = "COURSE_MODULE_LIST";
export const COURSE_MODULE_DELETE = "COURSE_MODULE_DELETE";
export const COURSE_MODULE_UPDATE = "COURSE_MODULE_UPDATE";
export const COURSE_LESSON_CREATE = "COURSE_LESSON_CREATE";
export const COURSE_LESSON_DELETE = "COURSE_LESSON_DELETE";
export const COURSE_LESSON_UPDATE = "COURSE_LESSON_UPDATE";
export const COURSE_DOCUMENT_DELETE = "COURSE_DOCUMENT_DELETE";
export const REPLICATION_MODULE = "REPLICATION_MODULE";
export const MODULE_UPDATE_POSITION = "MODULE_UPDATE_POSITION";
export const LESSON_UPDATE_POSITION = "LESSON_UPDATE_POSITION";
export const REPLICATION_LESSON = "REPLICATION_LESSON";
export const COURSE_DOCUMENT_CREATE = "COURSE_DOCUMENT_CREATE";
export const COURSE_CUSTOMER_LIST = "COURSE_CUSTOMER_LIST";
export const COURSE_CUSTOMER_LIST_MODAL = "COURSE_CUSTOMER_LIST_MODAL";
export const LOCK_CUSTOMER = "LOCK_CUSTOMER";
export const CANCEL_LOCK_CUSTOMER = "CANCEL_LOCK_CUSTOMER";
export const LOCK_COURSE = "LOCK_COURSE";
export const CANCEL_LOCK_COURSE = "CANCEL_LOCK_COURSE";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_MANY_CUSTOMERS = "CREATE_MANY_CUSTOMERS";
export const COURSE_CUSTOMER_LIST_OWNED_COURSE = "COURSE_CUSTOMER_LIST_OWNED_COURSE";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const ADD_COURSE_TO_STUDENT = "ADD_COURSE_TO_STUDENT";
