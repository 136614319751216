/* eslint-disable max-len */
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { map } from 'lodash';
import LoadingScene from '../../../components/LoadingScene';
import LadiPagination from '../../../components/LadiPagination';
import EmptyDataCustomv4 from './EmptyDataCustomv4';
import appConfig from '../../../config/app';
import BaseHelper from '../../../helpers/BaseHelper';
import i18n from '../../../i18n';

class ManageListLayout extends React.Component {
    static propTypes = {
        parentState: PropTypes.object,
        parentProps: PropTypes.object,
        parentContext: PropTypes.object,
        isLoading: PropTypes.bool,
        headerConfigs: PropTypes.object,
        tableDataConfigs: PropTypes.object,
        isSearching: PropTypes.bool,
        wrapperClass: PropTypes.string,
        listName: PropTypes.string,
        isShowHeader: PropTypes.bool,
        buttonComponent: PropTypes.element,
    };

    static defaultProps = {
        isLoading: false,
        isSearching: false,
        wrapperClass: 'ladiui content-list-landingpage content-list-landingpage-v3',
        listName: i18n.t('COMMON.RECORDS'),
        isShowHeader: true,
        buttonComponent: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            paginationConditions: {
                limit: 10,
                page: 1,
            },
            searchName: '',
            selectStatus: {
                code: '',
            },
            tagSelected: [],
            selectedIDs: [],
            selectTag: {
                _id: null,
            },
            listTags: [],
        };
        this.sortBy = {
            _id: 'DESC',
        };
        this.selectedID = '';
        this.selectedIDs = [];
    }

    // eslint-disable-next-line camelcase, max-lines-per-function
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }
    }

    renderHeader = () => {
        const { t, headerConfigs, buttonComponent } = this.props;
        return (
            <div className='ladiui alert create-flow-alert margin-bottom-40'>
                <div className='d-flex flex-row justify-between align-items-center'>
                    <div className='d-flex flex-column justify-start align-items-start'>
                        <div className='d-flex'>
                            <div className='ladiui text-black text-20 font-500 d-flex'>{headerConfigs.title}</div>
                        </div>
                        <div className='d-flex my-8 mb-0'>
                            <div className='text-secondary-v3 text-14'>{headerConfigs.description}</div>
                        </div>
                    </div>
                    {buttonComponent}
                </div>
            </div>
        );
    };

    convertStatus = (status) => {
        const { t } = this.props;

        switch (status) {
            case appConfig.FLOW.STATUS.DRAF:
                return (
                    <div className='dot-status'>
                        <div className='dot-status-pause'></div>
                        <span className='status-subscriber'>{t('FLOWS.STATUS_DRAF')}</span>
                    </div>
                );
            case appConfig.FLOW.STATUS.PUBLISHED:
                return (
                    <div className='dot-status'>
                        <div className='dot-status-active'></div>
                        <span className='status-subscriber'>{t('FLOWS.STATUS_PUBLISHED')}</span>
                    </div>
                );
            default:
                return (
                    <div className='dot-status'>
                        <div className='dot-status-pause'></div>
                        <span className='status-subscriber'>{t('FLOWS.STATUS_DRAF')}</span>
                    </div>
                );
        }
    };

    renderTableItem = (item) => {
        const { t } = this.props;
        const { userInfo, selectedIDs, isEmbedded } = this.state;

        return (
            <tr className='ladiui table-vertical main' key={item._id}>
                {!isEmbedded ? (
                    <td scope='row'>
                        {!item.is_default ? (
                            <input
                                type='checkbox'
                                className='ladiui checkbox size-checkbox checkbox-element form-check-input-checkbox'
                                id='is-check'
                                name='checkbox'
                                checked={selectedIDs.includes(item._id)}
                                value={item._id}
                                onChange={this.onCheckBoxItem}
                            />
                        ) : null}
                    </td>
                ) : null}
                <td className='ladiui name-landingpage customer-name text-left'>
                    <span
                        className='pointer fix-line-css title'
                        onClick={() => this.props.history.push(`${window.location.pathname}/${item._id}`)}
                    >
                        {item.name}
                        {item.is_default ? ` (${t('COMMON.DEFAULT')})` : null}
                    </span>
                </td>

                <td className='status text-left'>{this.convertStatus(item.status)}</td>
                {!isEmbedded ? (
                    <>
                        <td className='text-left'>
                            {userInfo && userInfo.store
                                ? BaseHelper.getStaffNameById(userInfo.store, item.creator_id, userInfo.user)
                                : null}
                        </td>
                        <td className='text-left'>{BaseHelper.formatStrToDate(item.updated_at)}</td>
                    </>
                ) : null}
                <td className='text-left' scope='col' style={{ width: 120 }}></td>
                <td className='text-left' scope='col' style={{ width: 120 }}></td>
                <td className='text-left' scope='col' style={{ width: 120 }}></td>
                <td className='ladiui-dropdown public text-right' width={90}></td>
            </tr>
        );
    };

    onSelectedLimit = (option) => {
        const { parentContext } = this.props;
        parentContext.setState(
            {
                paginationConditions: {
                    ...parentContext.state.paginationConditions,
                    limit: option.value,
                },
            },
            () => {
                parentContext.list();
            }
        );
    };

    onPageChange = (pageNumber) => {
        const { parentContext } = this.props;
        parentContext.setState(
            {
                paginationConditions: {
                    ...parentContext.state.paginationConditions,
                    page: pageNumber,
                },
            },
            () => {
                parentContext.list();
            }
        );
    };

    renderData = (isLoading) => {
        const { t, tableDataConfigs, isSearching, emptyDataDescription, emptyDataTitle, listName, tableClassname } = this.props;
        const { items, total_record, paginationConditions } = tableDataConfigs.tableData;

        return (
            <>
                <table className='table text-left table-margin-16 mt-14 w-full'>
                    <thead>{tableDataConfigs.renderTitleTable()}</thead>
                    <tbody>{map(items, (item) => tableDataConfigs.renderTableItem(item))}</tbody>
                </table>
                {!isLoading && items && items.length <= 0 ? (
                    <div className='margin-top-24'>
                        <EmptyDataCustomv4 status={emptyDataDescription} title={emptyDataTitle} searching={isSearching} />
                    </div>
                ) : null}
                {total_record > 0 && (
                    <LadiPagination
                        conditionsProp={paginationConditions}
                        listName={t(listName)}
                        items={items}
                        total={total_record}
                        limitAction={this.onSelectedLimit}
                        pageAction={this.onPageChange}
                    />
                )}
            </>
        );
    };

    handleReseFilter = () => {
        const { parentContext } = this.props;
        parentContext.setState(
            {
                selectTag: {
                    _id: null,
                },
                searchName: '',
                selectStatus: {
                    code: '',
                },
                tagSelected: [],
            },
            () => {
                parentContext.list(true);
            }
        );
    };

    renderTableAction = () => {
        const { t, actionsComponent, isSearching } = this.props;
        return (
            <>
                <div className='ladiui content-main-title flex-row'>{actionsComponent || null}</div>
            </>
        );
    };

    render() {
        const { isLoading, children, parentProps, parentState, parentContext, wrapperClass, isShowHeader } = this.props;
        const {} = this.state;
        return (
            <div className={wrapperClass}>
                {isLoading ? <LoadingScene /> : null}
                <div className='ladiui content-main-full max-w-1220'>
                    {isShowHeader && this.renderHeader(isLoading)}
                    {this.renderTableAction()}
                    {this.renderData(isLoading)}
                    {children}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManageListLayout));
