import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "../../../components/Modal";

class ModalSettingScope extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      role: [
        {
          name: "STAFF",
          scopes: {
            Order: ["Create", "Read", "Update", "Delete"],
          },
        },
      ],
    };

    this.inputsRef = new Set();
  }

  onChangeInput = (event) => {
    this.setState({
      staff: {
        ...this.state.staff,
        [event.target.name]: event.target.value,
      },
    });
  };

  submit = () => {};

  render() {
    const { t } = this.props;

    return (
      <Modal
        id="modal-ACTIONS-scope"
        title={t("ACTIONS.EDIT_SCOPE")}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        onOk={this.submit}
        bodyStyles={{ minHeight: "50vh" }}
      >
        <div className="ladiui form-group">
          <label className="ladiui-label">{t("ACCOUNTS.ROLE")}</label>
          <div className="ladiui data-permission">
            <div className="ladiui-select-options">
              <span className="ladiui-select-init">STAFF</span>
              <input type="hidden" name="select" defaultValue />
              <ul>
                <li className="select-item">
                  <a data-value={"STAFF"}>STAFF</a>
                </li>
                <li className="select-item">
                  <a data-value={"EDITOR"}>EDITOR</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div style={{ marginTop: 50 }}>
          <div className="scope-group">
            <div className="scope-parent">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Orders
              </label>
            </div>

            <div className="scope-children">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Create
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Update
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Delete
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Read
              </label>
            </div>
          </div>

          <div className="scope-group">
            <div className="scope-parent">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Orders
              </label>
            </div>

            <div className="scope-children">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Create
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Update
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Delete
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Read
              </label>
            </div>
          </div>

          <div className="scope-group">
            <div className="scope-parent">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Orders
              </label>
            </div>

            <div className="scope-children">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Create
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Update
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Delete
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Read
              </label>
            </div>
          </div>

          <div className="scope-group">
            <div className="scope-parent">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Orders
              </label>
            </div>

            <div className="scope-children">
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Create
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Update
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Delete
              </label>
              <label>
                <input type="checkbox" className="ladiui checkbox size-checkbox form-check-input-checkbox" /> Read
              </label>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation("translation", { withRef: true })(ModalSettingScope);
