import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as settingTypes from '../../../redux/futures/setting/types';
import settingActions from '../../../redux/futures/setting/actions';

import appConfig from '../../../config/app';
import Input from '../../../components/Input';
import Modal from '../../../components/Modal';
import Dropdown from '../../../components/Dropdown';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function ModalCreateCustomField(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible, hideModal, mode, _dataField = {} } = props;

    const settingReducer = useSelector((state) => state.setting);

    const loading = useMemo(() => {
        return settingReducer.loading || false;
    }, [settingReducer.loading]);

    const [dataField, setDataField] = useState({
        group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE,
    });
    const [isValidationName, setValidationName] = useState(true);
    const [isDuplicates, setDuplicates] = useState(false);

    useEffect(() => {
        setDataField({
            ..._dataField,
            group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE,
        });
    }, [visible]);

    const onCancel = () => {
        hideModal();
    };

    const onOk = () => {
        const data = {
            ...dataField,
            group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE,
        };

        if (data.data_type != appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE) {
            // data.data_values = data_values;
            delete data.data_values;
        }

        if (!data.label) {
            window.LadiUI.toastCustom('danger', '', `${t('INPUT_EMPTY')} ${t('CUSTOM_FIELD.LABEL')}`);
            return;
        }
        if (!data.name) {
            window.LadiUI.toastCustom('danger', '', `${t('INPUT_EMPTY')} ${t('CUSTOM_FIELD.ENTER_NAME')}`);
            return;
        }
        const expression = /[^A-Za-z0-9-_]/;
        const regex = new RegExp(expression);

        if (data.name.match(regex)) {
            setValidationName(false);
            return;
        }

        if (!data.data_type) {
            window.LadiUI.toastCustom('danger', '', `${t('INPUT_EMPTY')} ${t('CUSTOM_FIELD.DATA_TYPES')}`);
            return;
        }

        if (data.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE && !data.data_values) {
            window.LadiUI.toastCustom('danger', '', `${t('INPUT_EMPTY')} ${t('CUSTOM_FIELD.VALUE')}`);
            return;
        }

        if (mode == appConfig.FORM_MODE.CREATE && !data.custom_field_id) {
            dispatch(settingActions.addCustomField(data));
            // this.props.addCustomField(data);
        } else {
            if (data.custom_field_id) dispatch(settingActions.updateCustomField(data));
            // this.props.updateCustomField(data);
        }
    };

    const handleChangeCusTomFieldDataTypes = (item) => {
        setDataField((pre) => {
            let tg = { ...pre };
            tg.data_type = item.CODE;
            return tg;
        });
    };

    const onChangeInput = (event, isValidate) => {
        const { name, value } = event.target;
        if (isValidate) {
            const dataValidates = ['name', 'email', 'phone', 'address', 'country', 'state', 'district', 'ward', 'message', 'coupon'];
            const expression = /[^A-Za-z0-9-_]/;
            const regex = new RegExp(expression);

            // Bạn chỉ có thể sử dụng 'a-z,A-Z, 0-9, -_
            if (value.match(regex)) {
                setValidationName(false);
            } else {
                setValidationName(true);
            }
        }

        setDataField((pre) => {
            let tg = { ...pre };
            tg[name] = value;
            return tg;
        });
    };

    return (
        <Modal
            width={600}
            id='modal-order-custom-field-create'
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            title={mode == appConfig.FORM_MODE.CREATE ? t('CUSTOM_FIELD.CREATE_CUSTOM_FIELD') : t('CUSTOM_FIELD.EDIT_CUSTOM_FIELD')}
            sub_title={
                mode == appConfig.FORM_MODE.CREATE ? t('CUSTOM_FIELD.CREATE_CUSTOM_FIELD_SUB') : t('CUSTOM_FIELD.EDIT_CUSTOM_FIELD_SUB')
            }
            cancelText={t('ACTIONS.REMOVE')}
            okText={mode == appConfig.FORM_MODE.CREATE ? t('CUSTOM_FIELD.CREATE_CUSTOM_FIELD') : t('CUSTOM_FIELD.EDIT_CUSTOM_FIELD_SAVE')}
            isLoading={loading}
            position_unset={true}
        >
            <div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CUSTOM_FIELD.LABEL')}</label>
                    <Input
                        name='label'
                        validationName={t('CUSTOM_FIELD.LABEL')}
                        placeholder={t('CUSTOM_FIELD.LABEL')}
                        value={dataField.label || ''}
                        onChange={onChangeInput}
                        // ref={(ref) => this.inputsRef.add(ref)}
                        validations={{ isRequired: true }}
                    />
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CUSTOM_FIELD.NAME')}</label>
                    <Input
                        id='show-message-error'
                        name='name'
                        validationName={t('CUSTOM_FIELD.ENTER_NAME')}
                        placeholder={t('CUSTOM_FIELD.ENTER_NAME')}
                        value={dataField.name || ''}
                        onChange={(e) => onChangeInput(e, true)}
                        // ref={(ref) => this.inputsRef.add(ref)}
                        validations={{ isRequired: true }}
                    />
                    {!isValidationName && <span className='show-error-text'>{t('CUSTOM_FIELD.VALIDATION_NAME')}</span>}
                    {isDuplicates && <span className='show-error-text'>{t('CUSTOM_FIELD.DUPLICATE_NAME')}</span>}
                </div>
                <div className='ladiui form-group'>
                    <label className='ladiui-label'>{t('CUSTOM_FIELD.DATA_TYPES')}</label>
                    <Dropdown
                        data={appConfig.CUSTOM_FIELD.DATA_TYPES}
                        onSelectItem={(item) => handleChangeCusTomFieldDataTypes(item)}
                        currentKey={dataField.data_type}
                        _key='CODE'
                        _value='NAME'
                        // ref={(ref) => this.inputsRef.add(ref)}
                        placeHolder={t('CUSTOM_FIELD.DATA_TYPES')}
                        validations={{ isRequired: true }}
                        validationName={t('CUSTOM_FIELD.DATA_TYPES')}
                        wrapperClassName='w100 position-initial'
                        idBlock='dropdown-customer-field'
                    />
                </div>

                {dataField.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.CODE && (
                    <div className='ladiui form-group'>
                        <label className='ladiui-label'>{t('CUSTOM_FIELD.VALUE')}</label>
                        <textarea
                            // ref={(ref) => this.inputsRef.add(ref)}
                            name='data_values'
                            className='ladiui textarea'
                            value={dataField.data_values}
                            onChange={onChangeInput}
                            placeholder={t('CUSTOM_FIELD.HELP_VALUE_DROPDOWN')}
                            validations={{ isRequired: true }}
                            // validationname={t('CUSTOM_FIELD.ENTER_DROPDOWN_VALUE')}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ModalCreateCustomField;
