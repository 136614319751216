import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const list = (data) => ({
  type: types.LIST_DISCOUNT,
  meta: {
    endpoint: endpoint.DISCOUNT_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const deletes = (discountIDs) => ({
  type: types.DELETE_DISCOUNTS,
  meta: {
    endpoint: endpoint.DISCOUNT_DELETE_IDS,
    method: REQUEST_METHOD.POST,
    body: {
      discount_ids: discountIDs,
    },
    hasAuth: true,
  },
});

const deleteDiscount = (discountID) => ({
  type: types.DELETE_DISCOUNT,
  meta: {
    endpoint: endpoint.DISCOUNT_DELETE_ID,
    method: REQUEST_METHOD.POST,
    body: {
      discount_id: discountID,
    },
    hasAuth: true,
  },
});

const create = (discount) => ({
  type: types.CREATE_DISCOUNT,
  meta: {
    endpoint: endpoint.DISCOUNT_CREATE,
    method: REQUEST_METHOD.POST,
    body: {
      discount,
    },
    hasAuth: true,
  },
});

const update = (discount) => ({
  type: types.UPDATE_DISCOUNT,
  meta: {
    endpoint: endpoint.DISCOUNT_UPDATE,
    method: REQUEST_METHOD.POST,
    body: {
      discount: discount,
    },
    hasAuth: true,
  },
});

const show = (discountID) => ({
  type: types.SHOW_DISCOUNT,
  meta: {
    endpoint: endpoint.DISCOUNT_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      discount_id: discountID,
    },
    hasAuth: true,
  },
});

const validateDiscount = (data) => ({
  type: types.VALIDATE_DISCOUNT,
  meta: {
    endpoint: endpoint.VALIDATE_DISCOUNT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const active = (discountID) => ({
  type: types.ACTIVE_DISCOUNT,
  meta: {
    endpoint: endpoint.ACTIVE_DISCOUNT,
    method: REQUEST_METHOD.POST,
    body: {
      discount_id: discountID,
    },
    hasAuth: true,
  },
});

const expire = (discountID) => ({
  type: types.EXPIRE_DISCOUNT,
  meta: {
    endpoint: endpoint.EXPIRE_DISCOUNT,
    method: REQUEST_METHOD.POST,
    body: {
      discount_id: discountID,
    },
    hasAuth: true,
  },
});

const reload = (discountID) => ({
  type: types.RELOAD_DISCOUNT,
  meta: {
    endpoint: endpoint.DISCOUNT_SHOW,
    method: REQUEST_METHOD.POST,
    body: {
      discount_id: discountID,
    },
    hasAuth: true,
  },
});

const exportDiscount = (data) => ({
  type: types.EXPORT_DISCOUNT,
  meta: {
    endpoint: endpoint.EXPORT_DISCOUNT,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

export default {
  list,
  deletes,
  delete: deleteDiscount,
  create,
  update,
  show,
  validateDiscount,
  active,
  expire,
  reload,
  exportDiscount,
};
