import app from '../../../config/app';
import * as types from './types';

const initialState = {
    SSID: '',
    STORE_ID: '',
    IS_IFRAME: '',
    LADI_UID_OWNER: '',
    LADIPAGE_STORE_ID: '',
};

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_SSID:
            return {
                ...state,
                SSID: action.payload.data,
            };
        case types.SET_LS_STORE_ID:
            return {
                ...state,
                STORE_ID: action.payload.data,
            };
        case types.SET_LADIPAGE_INFO:
            return {
                ...state,
                LADI_UID_OWNER: action.payload.data.ladi_uid_owner,
                LADIPAGE_STORE_ID: action.payload.data.ladipage_store_id,
                SSID: action.payload.data.ssid,
                IS_IFRAME: action.payload.data.is_iframe,
            };
        default:
            return state;
    }
};

export default courseReducer;
