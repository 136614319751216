import React from 'react';
import { withTranslation } from 'react-i18next';
import { cloneDeep, find, map } from 'lodash';

import appConfig from '../../../config/app';

import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';

class FormPaymentStatus extends React.Component {
    static propTypes = {
        shippingID: PropTypes.number,
        paymentStatusCode: PropTypes.string,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            paymentStatusCode: props.paymentStatusCode,
        };
    }

    componentDidMount() {
        this.setState({
            paymentStatusCode: cloneDeep(this.props.paymentStatusCode),
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.paymentStatusCode != nextProps.paymentStatusCode) {
            this.setState({
                paymentStatusCode: cloneDeep(nextProps.paymentStatusCode),
            });
        }
    }

    getNameByCode = (code) => {
        const matched = find(appConfig.SHIPPING_PAYMENT_STATUS, (item) => item.CODE == code) || {};
        return matched.NAME;
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    onChangeStatus = (paymentStatusCode) => {
        this.setState({
            paymentStatusCode,
        });
    };

    submit = () => {
        this.props.onSubmit(this.props.shippingID, this.state.paymentStatusCode);
    };

    render() {
        const { t } = this.props;
        const { paymentStatusCode } = this.state;

        return (
            <Modal
                id='modal-shipping-paymentStatusCode'
                title={t('SHIPPINGS.PAYMENT_STATUS_UPDATE_TITLE')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                isLoading={this.props.isLoading}
                width={400}
                bodyClassName='unset-modal'
            >
                <div className='ladiui data-paymentStatus'>
                    {/* <div className="ladiui-select-options" style={{ minWidth: 180 }}>
                        <span className="ladiui-select-init">{this.getNameByCode(paymentStatusCode)}</span>
                        <input type="hidden" name="select" defaultValue />
                        <ul>
                            {
                                map(appConfig.SHIPPING_PAYMENT_STATUS, option =>
                                    <li key={option.CODE} className="select-item" onClick={() => this.onChangeStatus(option.CODE)}><a data-value={option.CODE}>{option.NAME}</a></li>
                                )
                            }
                        </ul>
                    </div> */}

                    <div className='ladiui btn-group w100'>
                        <div className='ladiui dropdown w100'>
                            <button
                                style={{ minWidth: '100%' }}
                                className='ladiui btn btn-outline-light dropdown-toggle'
                                data-toggle='dropdown'
                            >
                                {this.getNameByCode(paymentStatusCode)}
                            </button>
                            <ul className='ladiui dropdown-menu w100'>
                                {map(appConfig.SHIPPING_PAYMENT_STATUS, (option) => {
                                    return (
                                        <li key={option.CODE} onClick={() => this.onChangeStatus(option.CODE)}>
                                            <a className='ladiui dropdown-item' href='#'>
                                                {option.NAME}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(FormPaymentStatus);
