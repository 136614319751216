import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.CREATE_STAFF: {
            return {
                ...state,
                action: types.CREATE_STAFF,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_STAFF: {
            return {
                ...state,
                action: types.UPDATE_STAFF,
                status: action.status,
                message: action.message,
            }
        }
        case types.LIST_STAFFS: {
            return {
                ...state,
                action: types.LIST_STAFFS,
                status: action.status,
                message: action.message,
                staffs: action.status ? action.payload.staffs : [],
                userScopes: action.status ? action.payload.user_scopes : []
            }
        }
        case types.REMOVE_STAFF: {
            return {
                ...state,
                action: types.REMOVE_STAFF,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

