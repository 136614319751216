import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';
import SelectOption from '../../../components/SelectOption';
import LoadingTable from '../../../components/LoadingTable';

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import PieChart from "highcharts-react-official";

import reportActions from "../../../redux/futures/report/actions";
import * as reportTypes from "../../../redux/futures/report/types";

export default function ReportRevenue(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const { revenue_day, revenue_day_compare, fromDate, toDate, fromDateCompare, toDateCompare, isCompare } = props;

    const reportReducer = useSelector((state) => state.report);
    const storeReducer = useSelector((state) => state.store);

    const [isLoading, setIsLoading] = useState(false);


    const getOption = () => {
        if (!revenue_day || !revenue_day.revenueSuccess ||
            !revenue_day.revenuePending ||
            !fromDate ||
            !toDate
        ) return [];

        let categories = []
        // let dataRevenueExpected = []
        // let dataRevenueSuccess = []

        // for (
        //     let datex = moment(fromDate).format("YYYY/MM/DD");
        //     datex <= moment(toDate).format("YYYY/MM/DD");
        //     datex = moment(datex, 'YYYY/MM/DD').add(1, "d").format("YYYY/MM/DD")
        // ) {
        //     let xxxxx = moment(datex).format("DD/MM/YYYY");
        //     categories.push(xxxxx);

        //     let valueSuccess = 0;
        //     let valuePending = 0;

        //     let itemSuccess = revenue_day.revenueSuccess.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
        //     if (itemSuccess && !itemSuccess.refund)
        //         itemSuccess.refund = 0

        //     if (itemSuccess) valueSuccess = itemSuccess.total - itemSuccess.refund;

        //     let itemPending = revenue_day.revenuePending.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
        //     if (itemPending && !itemPending.refund)
        //         itemPending.refund = 0

        //     if (itemPending) valuePending = itemPending.total - itemPending.refund;

        //     dataRevenueSuccess.push(valueSuccess)
        //     dataRevenueExpected.push(valuePending)

        // }

        let dataRevenueDay = [];
        let dataRevenueDayCompare = []

        let _days = moment(new Date(toDate)).diff(moment(new Date(fromDate)), 'days');

        for (
            let datex = moment(fromDate).format("YYYY/MM/DD");
            datex <= moment(toDate).format("YYYY/MM/DD");
            datex = moment(datex, 'YYYY/MM/DD').add(1, "d").format("YYYY/MM/DD")
        ) {
            let xxxxx = moment(datex).format("DD/MM/YYYY");
            let xxxxx2 = moment(datex);

            categories.push(xxxxx);

            let valueRevenue = 0;
            let valueRevenueCompare = 0;

            let itemRevenueDay = revenue_day.revenueSuccess.find(item => moment(item.date).format("DD/MM/YYYY") === xxxxx)
            if (itemRevenueDay && !itemRevenueDay.refund)
                itemRevenueDay.refund = 0

            if (itemRevenueDay) valueRevenue = itemRevenueDay.total - itemRevenueDay.refund;

            if (revenue_day_compare && revenue_day_compare.revenueSuccess) {
                let itemRevenueDayCompare = revenue_day_compare.revenueSuccess.find((item) => {

                    if (isCompare && isCompare == 1) {
                        xxxxx2.subtract(_days + 1, "days");
                    } else if (isCompare && isCompare == 2) {
                        xxxxx2.subtract(1, "month");
                    } else if (isCompare && isCompare == 3) {
                        xxxxx2.subtract(1, "year");
                    }

                    if (moment(item.date).format("DD/MM/YYYY") === xxxxx2.format("DD/MM/YYYY")) {
                        return item
                    }
                })

                if (itemRevenueDayCompare && !itemRevenueDayCompare.refund)
                    itemRevenueDayCompare.refund = 0

                if (itemRevenueDayCompare) valueRevenueCompare = itemRevenueDayCompare.total - itemRevenueDayCompare.refund;

                dataRevenueDayCompare.push(valueRevenueCompare)
            }


            dataRevenueDay.push(valueRevenue)

        }

        let _series = [];

        if (fromDate && toDate) {
            _series.push(
                {
                    name: moment(fromDate).format("DD/MM/YYYY") + "-" + moment(toDate).format("DD/MM/YYYY"),
                    data: dataRevenueDay,
                },
            )
        }

        if (fromDateCompare && toDateCompare) {
            _series.push(
                {
                    name: moment(fromDateCompare).format("DD/MM/YYYY") + "-" + moment(toDateCompare).format("DD/MM/YYYY"),
                    data: dataRevenueDayCompare,
                },
            )
        }

        const options = {
            chart: {
                type: "spline",
            },
            colors: ['#775EEC', '#6C99FB', '#A8D24C', '#F7B500'],
            title: {
                text: "",
            },
            xAxis: {
                categories: categories,
                gridLineWidth: 0.5,
                gridLineColor: "#f5f5f5"
            },
            yAxis: {
                gridLineColor: "#f5f5f5",
                // min: 0,
                title: {
                    text: "",
                },
                stackLabels: {
                    enabled: false,
                },
                gridLineWidth: 1
            },
            tooltip: {
                shared: true,
                backgroundColor: "#061438",
                borderColor: "#061438",
                borderRadius: 3,
                style: {
                    color: "#ffffff",
                },
                useHTML: true,
                formatter: function () {
                    let text = ``;
                    let total = 0;
                    this.points.map((item) => {
                        total = item.total;
                        text += `
                <br/>
                <div class="text-tooltip">  
                  <div class="point-tolltip" style="background:${item.point.color}"></div> 
                  <div>
                    ${item.point.series.name}: ${baseHelper.formatMoneyPostFix(item.point.y, storeReducer.userInfo.currentStore.currency_symbol)}
                  </div> 
                </div>
                `
                    })
                    // Đoạn chữ Doanh thu kia cũ là ${this.x}
                    return (
                        `
                        Doanh thu
                 <br/>
                ${text}
                <br/>
                <div>
                ${t("REPORTS.REVENUE_REPORT.TOTAL")}: ${baseHelper.formatMoneyPostFix(total, storeReducer.userInfo.currentStore.currency_symbol)}
              </div> `
                    );
                },
            },
            legend: {
                enabled: true
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            // series: [
            //     {
            //         name: t("REPORTS.REVENUE_REPORT.SUCCESS"),
            //         data: dataRevenueDay,
            //     },
            //     {
            //         name: t("REPORTS.REVENUE_REPORT.PENDING"),
            //         data: dataRevenueDayCompare,
            //     },

            // ],
            series: _series,
        };
        return options;
    };

    return (
        <div className='block-content-item'>
            <div className="chart-item revenue-stage-chart" id='report-revenue-new'>
                <h3 className='mb-24'>Doanh thu thuần</h3>
                <div className="content-chart">{!isLoading ? <HighchartsReact highcharts={Highcharts} options={getOption()} /> : <LoadingTable />}</div>
            </div>
        </div>
    );
}
