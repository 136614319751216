import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import appConfig from '../config/app';
import baseHelper from '../helpers/BaseHelper';

import * as fileTypes from '../redux/futures/file/types';
import fileActions from '../redux/futures/file/actions';

import Popover from './Popover';
import Image from './Image';
import Input from './Input';
import ValidatorHelper from '../helpers/ValidatorHelper';
import LoadingScene from './LoadingScene';

// eslint-disable-next-line max-lines-per-function

const ImageUpload = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);
    const { setImageSrc, setImageData, indexSection, isClickUrl = false, handleImageUrl, imageClickUrl = '', isDelete = false } = props;

    const fileReducer = useSelector((state) => state.file);
    const imageRef = useRef();
    const uploadRef = useRef();

    const loading = useMemo(() => {
        return fileReducer.loading || false;
    }, [fileReducer.loading]);

    const [image, setImage] = useState({
        src: '',
        name: '',
    });

    const [imageUrl, setImageUrl] = useState('');
    const [sectionUpload, setSectionUpload] = useState('');

    useImperativeHandle(ref, () => ({
        getData: () => {
            return image;
        },
    }));

    useEffect(() => {
        setImage({
            src: setImageSrc,
            name: setImageSrc,
        });
    }, [setImageSrc]);

    useEffect(() => {
        if (fileReducer.action == fileTypes.UPLOAD_IMAGES) {
            if (fileReducer.status) {
                let images = fileReducer.images || [];
                let image = images[0];

                // if (!indexSection || indexSection == sectionUpload)
                //     setImage({
                //         src: image.path,
                //         name: image.name,
                //     });

                if (setImageData && sectionUpload)
                    setImageData({
                        src: image.path,
                        name: image.name,
                        indexSection: sectionUpload,
                    });

                setSectionUpload('');
                window.LadiUI.toastCustom('success', '', fileReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', fileReducer.message, 'OK');
            }
        }
    }, [fileReducer.action]);

    const handleClickUpImage = () => {
        uploadRef.current.click();
    };

    const handleAddImageUrl = () => {
        if (!ValidatorHelper.isValidHttpUrl(imageUrl)) {
            window.LadiUI.toastCustom('danger', '', 'Đường dẫn không hợp lệ');
        }

        if (setImageData)
            setImageData({
                src: imageUrl,
                name: imageUrl,
                indexSection,
            });
        if (imageRef && imageRef.current && imageRef.current.instanceRef) imageRef.current.instanceRef.hide();
    };

    const fileChangedHandler = (event) => {
        const { files } = event.target;

        if (files && files.length > 0) {
            const form = baseHelper.getFormDataUpload(files);
            if (form) {
                setSectionUpload(indexSection);
                dispatch(fileActions.uploadImages(form));
            }
        }
    };

    return (
        <div className='container-image'>
            <div className={`version-upload-image ${image.src ? '' : 'active'}`}>
                {loading && <LoadingScene />}
                {image.src && <Image width={'100%'} src={image.src} />}
                <div className='group-upload-image'>
                    <div>
                        <i className='new-ldicon-photo' />
                    </div>
                    <div className='display-flex-center gap-4 mt-16'>
                        <a onClick={() => handleClickUpImage()}>{t('SALE_CHANNEL.IMAGE_UPLOAD')}</a>
                        <span>{t('ACTIONS.OR')}</span>
                        <Popover
                            ref={imageRef}
                            wrapperProps={{ className: 'relative' }}
                            wrapperPopoverProps={{ className: 'popover-image-product ' }}
                            content={
                                <div className='popover-add-image-product'>
                                    <div className='popover'>
                                        <div className='ladiui form-group'>
                                            <label className='ladiui-label new text-left'>{t('SALE_CHANNEL.IMAGE_LINK')}</label>
                                            <Input
                                                className='ladiui form-control'
                                                placeholder={t('SALE_CHANNEL.IMAGE_LINK_PLA')}
                                                name='image_url'
                                                value={imageUrl}
                                                onChange={(event) => {
                                                    setImageUrl(event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className='action-footer'>
                                            <button
                                                className='ladiui btn btn-secondary'
                                                onClick={() => imageRef.current.instanceRef.hide()}
                                            >
                                                {t('CHECKOUTS.CANCEL')}
                                            </button>
                                            <button className='ladiui btn btn-primary' onClick={handleAddImageUrl}>
                                                {t('SALE_CHANNEL.IMAGE_ADD')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <a>{t('SALE_CHANNEL.IMAGE_URL')}</a>
                        </Popover>
                    </div>
                    {isDelete && image.src && (
                        <div
                            className='btn-delete-variant-image'
                            onClick={() => {
                                setImageData({
                                    src: '',
                                    name: '',
                                    indexSection: indexSection,
                                });
                            }}
                        >
                            <i className='ladiui icon-new new-ldicon-delete' />
                            <span>Xóa ảnh</span>
                        </div>
                    )}
                    {/* <div className='mt-8'>{t('PRODUCTS.IMAGE_SUGGEST')}</div> */}
                    <input
                        ref={uploadRef}
                        className='cursor-pointer display-none'
                        id={`input-upload-image`}
                        type='file'
                        name='file'
                        accept='image/*'
                        // multiple='multiple'
                        onChange={fileChangedHandler}
                        onClick={(event) => {
                            event.target.value = null;
                        }}
                    />
                </div>
            </div>
            {isClickUrl && (
                <div className='mt-12'>
                    <label className='ladiui text-14'>{t('SALE_CHANNEL.IMAGE_LINK_URL')}</label>
                    <Input
                        className='mt-4'
                        name='title'
                        placeholder='https://image url'
                        value={imageClickUrl}
                        onChange={(e) => {
                            if (handleImageUrl) handleImageUrl(e.target.value);
                        }}
                    />
                </div>
            )}
        </div>
    );
});

export default ImageUpload;
