import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable, DraggableCore } from 'react-beautiful-dnd';

import appConfig from '../../../config/app';

import productVariantActions from '../../../redux/futures/product_variant/actions';

import Input from '../../../components/Input';
import ModalCreate from './components/ModalCreate';
import BaseHelper from '../../../helpers/BaseHelper';

// eslint-disable-next-line max-lines-per-function

export const ProductVersion = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const { productData } = props;

    const compRef = useRef();

    const [variants, setProductData] = useState([]);
    const [versionSelected, setVersionSelected] = useState({});
    const [indexSelected, setIndexSelected] = useState(-1);

    const [isShowModalCreate, setShowMocalCreate] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let option = {
                name: t('PRODUCTS.VARIANT_VERSION_DEFAULT'),
                position: 1,
                type: 1,
                values: [],
            };

            let variantTg = variants.map((item, index) => {
                item.option_value_value = item.name;
                item.inventory_checked = appConfig.STATUS.INACTIVE;
                item.price = item.price ? Number(item.price) : 0;
                item.description = item.description || '';
                item.download_count = item.download_count ? Number(item.download_count) : 5;
                item.src = item.src || '';
                item.file = item.file || '';

                if (!item.options || !item.product_variant_id) {
                    item.options = {
                        [t('PRODUCTS.VARIANT_VERSION_DEFAULT')]: item.name,
                    };
                }

                option.values.push({ name: item.name });
                return item;
            });
            if (!variantTg || variantTg.length == 0) {
                return {
                    error: t('PRODUCTS.DIGITAL.VERSION_EMPTY'),
                };
            }

            return { variants: variantTg, options: [option] };
        },
        scrollIntoView: () => {
            compRef.current.classList.add('error-empty');
            compRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        },
    }));

    useEffect(() => {
        if (productData && productData.variants) {
            let variantsTg = productData.variants.map((item) => {
                item.name = item.option1;
                if (!productData.product_id) {
                    delete item.product_variant_id;
                    delete item.product_id;
                }
                return item;
            });

            setProductData(variantsTg);
        }
    }, [productData]);

    const addVersion = (data) => {
        let _variants = [...variants];

        if (!versionSelected || !versionSelected.product_variant_id) {
            _variants.push(data);
            setShowMocalCreate(false);
        }
        if (_variants.length > 0) {
            compRef.current.classList.remove('error-empty');
        }
        setProductData(_variants);
    };
    const updateVersion = (data) => {
        let _variants = [...variants];

        if (indexSelected < 0) return;
        _variants[indexSelected] = data;
        setShowMocalCreate(false);
        if (_variants.length > 0) {
            compRef.current.classList.remove('error-empty');
        }
        setProductData(_variants);
    };

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1);
        list.splice(endIndex, 0, removed);

        return list;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const _columnSelected = reorder(variants, result.source.index, result.destination.index);
        setProductData(_columnSelected);
    };

    const handleDeleteVariant = (item, index) => {
        if (item.product_id && item.product_variant_id) {
            dispatch(productVariantActions.delete(item.product_id, item.product_variant_id));
        } else {
            let _variants = [...variants];
            _variants.splice(index, 1);
            setProductData(_variants);
        }
    };

    return (
        <>
            <div className='ladiui block-content-item mt-20 scroll-mt-150' ref={compRef}>
                <div className='item-header'>
                    <h3>{t('PRODUCTS.DIGITAL.LB_VARIANT_NAME')}</h3>
                </div>
                <div className='item-body'>
                    {!variants || variants.length == 0 ? (
                        <div className='version-empty'>
                            <div className='ladiui text-18-secondary'>{t('PRODUCTS.DIGITAL.VERSION_ADD_LB')}</div>
                            <div className='ladiiui text-14'>{t('PRODUCTS.DIGITAL.VERSION_ADD_SUB_LB')}</div>
                            <div className='ladiui btn btn-add-version' onClick={() => setShowMocalCreate(true)}>
                                <i className='icon-ldp-add' />
                                <span> {t('PRODUCTS.DIGITAL.VERSION_ADD_BTN')} </span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='list-version'>
                                <div className='version-item'>
                                    <div className='version-move head'></div>
                                    <div className={`version-name head ${productData && productData.product_id ? '' : 'not-id'}`}>
                                        {t('PRODUCTS.DIGITAL.VERSION_NAME')}{' '}
                                    </div>
                                    <div className='version-price head'> {t('PRODUCTS.DIGITAL.VERSION_PRICE_LB')} </div>
                                    <div className='version-download-num head'> {t('PRODUCTS.DIGITAL.VERSION_NUM_DOWNLOAD_LB')} </div>
                                    {productData && productData.product_id && <div className='version-id head'> ID </div>}
                                    <div className='version-action head'> </div>
                                </div>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId='droppable'>
                                        {(provided, snapshot) => (
                                            <div
                                                // className='list-version'
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                // style={getListStyle(snapshot.isDraggingOver)}
                                            >
                                                {variants.map((item, index) => {
                                                    if (!item) return null;

                                                    return (
                                                        // <div className='version-item'>
                                                        <Draggable
                                                            key={index}
                                                            draggableId={`${index}`}
                                                            index={index}
                                                            // allowAnyClick={true}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className='version-item'
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <div className='version-move'>
                                                                        <i className=' icon-move' />
                                                                    </div>
                                                                    <div
                                                                        className={`version-name ${
                                                                            productData && productData.product_id ? '' : 'not-id'
                                                                        }`}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                    <div className='version-price'>{item.price}</div>
                                                                    <div className='version-download-num'>{item.download_count}</div>
                                                                    {productData && productData.product_id && (
                                                                        <div>
                                                                            <button
                                                                                className='version-id'
                                                                                onClick={() => {
                                                                                    BaseHelper.copyToClipboard(item.product_variant_id);
                                                                                }}
                                                                            >
                                                                                {item.product_variant_id}
                                                                            </button>

                                                                            {/* <span className='version-id'>{item.product_variant_id}</span> */}
                                                                        </div>
                                                                    )}
                                                                    <div className='ladiui btn-group version-action'>
                                                                        <div className='ladiui dropdown hide-mt ba-c'>
                                                                            <button
                                                                                data-toggle='dropdown'
                                                                                className='ladiui-btn-dropdown dropdown-toggle'
                                                                            >
                                                                                <i className='ladiui icon icon-ldp-dot'></i>
                                                                            </button>
                                                                            <ul className='ladiui dropdown-menu r-0'>
                                                                                <li>
                                                                                    <a
                                                                                        className='ladiui dropdown-item'
                                                                                        onClick={() => {
                                                                                            setVersionSelected(item);
                                                                                            setIndexSelected(index);
                                                                                            setShowMocalCreate(true);
                                                                                        }}
                                                                                    >
                                                                                        {t('ACTIONS.EDIT')}
                                                                                    </a>
                                                                                </li>
                                                                                <li>
                                                                                    <a
                                                                                        className='ladiui dropdown-item'
                                                                                        onClick={() => {
                                                                                            handleDeleteVariant(item, index);
                                                                                        }}
                                                                                    >
                                                                                        {t('ACTIONS.DELETE')}
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                        // </div>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div
                                className='ladiui flex-center-start pointer'
                                onClick={() => {
                                    setVersionSelected(null);
                                    setIndexSelected(-1);
                                    setShowMocalCreate(true);
                                }}
                            >
                                <i className='new-ldicon-add-circle mr-8' /> <span>{t('PRODUCTS.DIGITAL.VERSION_ADD_BTN')}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {isShowModalCreate && (
                <ModalCreate
                    visible={isShowModalCreate}
                    hideModal={() => setShowMocalCreate(false)}
                    versionSelected={versionSelected}
                    addVersion={addVersion}
                    updateVersion={updateVersion}
                    productData={productData}
                    indexSelected={indexSelected}
                />
            )}
        </>
    );
});
