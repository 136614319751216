import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';

import * as settingTypes from '../../../redux/futures/setting/types';
import settingActions from '../../../redux/futures/setting/actions';

import LoadingTable from '../../../components/LoadingTable';
import LadiPagination from '../../../components/LadiPagination';

import ConfirmModal from '../../../components/ConfirmModal';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';
import ModalCreateCustomField from './ModalCreateCustomField';

export default function CustomerCustomField(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];
    const { search } = useLocation();

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    // const tab = queryStrings.get('tab');

    const storeReducer = useSelector((state) => state.store);
    const settingReducer = useSelector((state) => state.setting);

    const isLoadingData = useMemo(() => {
        return settingReducer.loading || false;
    }, [settingReducer.loading]);

    const defaultConditions = {
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    };
    const [conditions, setConditions] = useState(defaultConditions);
    const [searchName, setSearchName] = useState('');
    const [totalRecord, setTotalRecord] = useState(0);
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [isShowModalCreate, setShowModalCreate] = useState(false);
    const [tagSelected, setTagSelected] = useState({});

    const [listCustomField, setListCustomField] = useState([]);

    useEffect(() => {
        list();

        const tab = queryStrings.get('tab');
        const action = queryStrings.get('action');

        if (tab == 3 && action == 'create') {
            setShowModalCreate(true);
        }
    }, [conditions]);

    useEffect(() => {
        if (settingReducer.action == settingTypes.SETTING_LIST_BY_CUSTOM_FIELD) {
            if (settingReducer.status) {
                setListCustomField(settingReducer.customFields || []);
                setTotalRecord(settingReducer.totalRecord);
            }
        }

        if (
            settingReducer.action == settingTypes.SETTING_ADD_CUSTOM_FIELD ||
            settingReducer.action == settingTypes.SETTING_UPDATE_CUSTOM_FIELD
        ) {
            if (settingReducer.status) {
                // setListCustomField(settingReducer.customFields || []);
                // setTotalRecord(settingReducer.totalRecord);
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                setTagSelected({});
                setSelectedIDs([]);
                setShowModalCreate(false);
                list();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), settingReducer.message, 'OK');
        }

        if (settingReducer.action == settingTypes.SETTING_DELETE_CUSTOM_FIELD) {
            if (settingReducer.status) {
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                window.LadiUI.closeModal('confirm-delete-custom-field');
                setTagSelected({});
                setSelectedIDs([]);
                list();
            } else window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), settingReducer.message, 'OK');
        }
    }, [settingReducer.action]);

    // useEffect(() => {
    //     // add a default parameter if not set
    //     // if (!searchParams.get('query')) {
    //       // this now throws a TypeScript compiler error
    //       // @ts-expect-error
    //       searchParams.set('query', 'pizza');
    //       history.replace({ search: searchParams.toString() });
    //     // }
    //   }, [queryStrings]);

    const selectTab = (index) => {
        // let _queryStrings = new URLSearchParams();
        // _queryStrings.set('tab', index);
        // props.history.replace({ search: _queryStrings.toString() });
        // setTabSelected(index);
    };

    const list = () => {
        const data = {
            page: conditions.page,
            limit: conditions.limit,
            keyword: searchName,
            group_type: appConfig.CUSTOM_FIELD.GROUP_TYPES.CUSTOMER.CODE,
            sort: {},
        };
        dispatch(settingActions.listByCustomField(data));
    };

    const openModalEdit = (item) => {
        setTagSelected(item);
        setShowModalCreate(true);
    };
    const openDeleteModal = (item) => {
        setSelectedIDs([item.custom_field_id]);
        window.LadiUI.showModal('confirm-delete-custom-field');
    };

    const onChangeLimit = (limit) => {
        setConditions((pre) => {
            let tg = { ...pre };
            tg.limit = Number(limit.value);
            return tg;
        });
    };
    const onPageChange = (page) => {
        setConditions((pre) => {
            let tg = { ...pre };
            tg.page = page;
            return tg;
        });
    };
    const checkAllItem = () => {
        if (selectedIDs.length < listCustomField.length) {
            let ids = listCustomField.map((item) => item.custom_field_id);
            setSelectedIDs(ids);
        } else setSelectedIDs([]);
    };
    const checkItem = (id) => {
        let ids = [...selectedIDs];
        let index = ids.indexOf(id);

        if (index > -1) {
            ids.splice(index, 1);
        } else {
            ids.push(id);
        }

        setSelectedIDs(ids);
    };

    const deleteCustomField = () => {
        let data = {};

        if (!selectedIDs || selectedIDs.length < 1) {
            window.LadiUI.toastCustom('error', '', t('TAGS.EMPTY'));
            return;
        }

        if (selectedIDs.length == 1) data.custom_field_id = selectedIDs[0];
        if (selectedIDs.length > 1) data.custom_field_ids = selectedIDs;

        dispatch(settingActions.deleteCustomField(data));
    };

    const eventSelectAction = (action) => {
        if (selectedIDs <= 0) {
            window.LadiUI.toastCustom('danger', '', t('COMMON.SELECT_AT_LEAST_ONE_RECORD'));
            return;
        }

        switch (action) {
            case 'DELETE':
                window.LadiUI.showModal('confirm-delete-custom-field');
                break;
            default:
        }
    };

    return (
        <div className='page-content'>
            <div className='header-page'>
                <div className='heading'>
                    <h1>{t('SETTINGS.CUSTOM_FIELD')}</h1>
                    <p>{t('CUSTOM_FIELD.SUB_LABEL_ORDER_CUSTOM')}</p>
                </div>
                <div className='button-action'>
                    <button type='button' className='btn-order-create' onClick={() => setShowModalCreate(true)}>
                        <i className='icon-ldp-add' />
                        <div className='ladiui btn-custom-text '>{t('CUSTOM_FIELD.CREATE_CUSTOM_FIELD')}</div>
                    </button>
                </div>
            </div>
            <div className='block-filter'>
                <div className='block-search'>
                    <div className='ladiui search-group w-100-p'>
                        <i className='ladiui icon icon-search' onClick={list}></i>
                        <input
                            id='keyword_search'
                            className='ladiui search-field dropdown-toggle form-control search-width'
                            name='searchName'
                            placeholder={t('TAGS.SEARCH_TAG')}
                            aria-expanded='false'
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    list();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            {isLoadingData ? (
                <LoadingTable />
            ) : (
                <div className='block-content'>
                    <div className='ladi-card ladiui-table-responsive'>
                        <table className='ladiui table text-left table-order main-table'>
                            <thead>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col' className='ladiui fix-column pos-rel'>
                                        <input
                                            id='checkAllItem'
                                            onClick={checkAllItem}
                                            type='checkbox'
                                            checked={selectedIDs.length == listCustomField.length && selectedIDs.length > 0}
                                            onChange={() => {}}
                                            className='ladiui-checkall ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle mr-16'
                                        />
                                        <span>{t('CUSTOM_FIELD.DISPLAY_NAME')} </span>

                                        {selectedIDs.length > 0 && (
                                            <LadiDropdownMenu
                                                labelDropdown={'Chọn hành động'}
                                                listProp={listAction}
                                                defaultTextProp={t('ACTIONS.TITLE')}
                                                hideLabel={true}
                                                cbProp={eventSelectAction}
                                                classSize='btn-sm'
                                                classDropdown='w-180 action-table-order-tag '
                                            />
                                        )}
                                    </th>

                                    <th scope='col'>{t('CUSTOM_FIELD.FIELD_NAME')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.DATA_TYPES')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.CREATED_AT')}</th>
                                    <th scope='col'>{t('CUSTOM_FIELD.UPDATED_AT')}</th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listCustomField.map((item, index) => {
                                    let isSelected = selectedIDs.includes(item.custom_field_id);
                                    return (
                                        <tr key={index} className='ladiui table-vertical main'>
                                            <td className=' fix-column display-flex-center '>
                                                <input
                                                    type='checkbox'
                                                    onClick={(event) => checkItem(item.custom_field_id)}
                                                    onChange={() => {}}
                                                    checked={isSelected}
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox mr-16'
                                                />
                                                {/* <span onClick={() => openModalEdit(item)}>{item.label}</span> */}
                                                <span>{item.label}</span>
                                            </td>
                                            <td>{item.name}</td>
                                            <td>
                                                {item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.NUMBER.NAME
                                                    : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.DATE.NAME
                                                    : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.INPUT_TEXT.NAME
                                                    : item.data_type == appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.CODE
                                                    ? appConfig.CUSTOM_FIELD.DATA_TYPES.TEXT_AREA.NAME
                                                    : appConfig.CUSTOM_FIELD.DATA_TYPES.DROPDOWN.NAME}
                                            </td>
                                            <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                            <td>{baseHelper.formatStrToDate(item.updated_at)}</td>

                                            {/* <td>{baseHelper.formatStrToDate(item.updated_at)}</td> */}
                                            <td className='text-right pd-0'>
                                                <div className='ladiui btn-group'>
                                                    <div className='ladiui dropdown hide-mt ba-c'>
                                                        <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                            <i className='ladiui icon icon-ldp-dot'></i>
                                                        </button>
                                                        <ul className='ladiui dropdown-menu r-0'>
                                                            <li>
                                                                <a
                                                                    className='ladiui dropdown-item'
                                                                    onClick={() => {
                                                                        openModalEdit(item);
                                                                    }}
                                                                >
                                                                    <i className='new-ldicon-edit' />
                                                                    <span> {t('ACTIONS.EDIT')} </span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a className='ladiui dropdown-item' onClick={() => openDeleteModal(item)}>
                                                                    <i className='ladiui icon-new new-ldicon-delete' />
                                                                    <span className='ladiui text-danger'>{t('ACTIONS.DELETE')}</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {totalRecord <= 0 && (
                        <div className='table-empty'>
                            <i className='new-ldicon-search' />
                            <div className='mt-24 ladiui text-18-secondary'> {t('NO_DATA')}</div>
                            <div className='mt-12 ladiui text-14'> {t('EDIT_FILTER')}</div>
                        </div>
                    )}
                    {totalRecord > 0 && (
                        <LadiPagination
                            conditionsProp={conditions}
                            listName={t('PAGINATION.ITEMS')}
                            message={''}
                            items={listCustomField}
                            total={totalRecord}
                            limitAction={onChangeLimit}
                            pageAction={onPageChange}
                        />
                    )}
                </div>
            )}

            <ConfirmModal
                id='confirm-delete-custom-field'
                title={t('SETTINGS.MSG_DELETE_CUSTOM_FIELD')}
                content={t('SETTINGS.MSG_DELETE_CUSTOM_FIELD_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={deleteCustomField}
                isLoading={isLoadingData}
                maxWidth={450}
            />

            <ModalCreateCustomField
                visible={isShowModalCreate}
                hideModal={() => setShowModalCreate(false)}
                mode={tagSelected && tagSelected.custom_field_id ? appConfig.FORM_MODE.EDIT : appConfig.FORM_MODE.CREATE}
                _dataField={tagSelected}
            />

            {/* <ModalOrderTagCreate
                visible={isShowModalCreate}
                hideModal={() => setShowModalCreate(false)}
                selectedTag={() => list()}
                isUpdate={tagSelected && tagSelected.order_tag_id}
                _tagSelected={tagSelected}
            /> */}

            {/* <ConfirmModal
                id='confirm-tag-delete'
                title={t('TAGS.MSG_DELETE_TAG_TITLE')}
                content={t('TAGS.MSG_DELETE_TAG_CONTENT')}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => deleteItem()}
                isLoading={isLoadingData}
                onCancel={() => setSelectedIDs([])}
            /> */}
        </div>
    );
}
