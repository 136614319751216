import React from 'react';
import baseHelper from '../../../helpers/BaseHelper';
import { find, includes } from 'lodash';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ModalTrackingNumber from './ModalTrackingNumber';
import appConfig from '../../../config/app';
import config from '../../../config/config';

class ShippingAddress extends React.Component {
    static propTypes = {
        shipping: PropTypes.object,
        onSubmit: PropTypes.func,
        isLoading: PropTypes.bool,
        updated: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            trackingNumber: props.shipping.tracking_number,
            isShowModalTrackingNumber: false,
        };

        this.ref = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.shipping != nextProps.shipping) {
            this.setState({
                trackingNumber: nextProps.shipping.tracking_number,
            });
        }

        if (this.props.updated != nextProps.updated) {
            if (nextProps.updated) {
                this.setState({
                    isShowModalTrackingNumber: false,
                });
            }
        }
    }

    openModalTrackingNumber = () => {
        this.setState({
            trackingNumber: this.props.shipping.tracking_number,
            isShowModalTrackingNumber: true,
        });
    };

    hideModalTrackingNumber = () => {
        this.setState({
            isShowModalTrackingNumber: false,
        });
    };

    submit = (shippingID, trackingNumber) => {
        this.props.onSubmit(shippingID, trackingNumber);
    };

    getNameByCode = (data, code) => {
        const matched = find(data, (item) => item.CODE == code) || {};
        return matched.NAME;
    };

    render() {
        const { t, shipping } = this.props;
        const { trackingNumber } = this.state;
        return (
            <div className='shipping-info'>
                <div className='shipping-info-heading'>
                    <h3>{t('SHIPPINGS.SHIPPING_INFO')}</h3>
                </div>
                <div className='shipping-info-content'>
                    <div className='item shipping_id'>
                        <span>{t('ORDERS.REF_NO')}</span>
                        {/* <a href={`/orders/${shipping.order_id}`}>{`#${baseHelper.getText(shipping.reference_no)}`}</a> */}
                        <a
                            href={`${config.BUILDER_LDP}${appConfig.LADIPAGE_URL.ORDER_UPDATE(shipping.order_id)}`}
                            target='_blank'
                        >{`#${baseHelper.getText(shipping.reference_no)}`}</a>
                    </div>
                    <div className='item partner'>
                        <span>{t('SHIPPINGS.PARTNER_TITLE')}</span>
                        <span>{shipping.shipping_partner_name}</span>
                    </div>
                    <div className='item tracking_number'>
                        <span>
                            {t('SHIPPINGS.SHIPPING_CODE')}
                            <a
                                onClick={this.openModalTrackingNumber}
                                style={{ cursor: 'pointer', marginLeft: 7 }}
                                className='ladi-icon icon-edit'
                            ></a>
                        </span>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <span>{baseHelper.getTrackingHref(shipping)}</span>
                        </div>
                    </div>

                    {/* <div className="item status" style={{ marginTop: 30 }}>
                        <span>{t('SHIPPINGS.SHIPPING_STATUS')}</span>
                        {
                            includes([appConfig.SHIPPING_STATUS.DELIVERED.CODE], shipping.status)
                                ?
                                <span className="ladiui button success rounded">{this.getNameByCode(appConfig.SHIPPING_STATUS, shipping.status)}</span>
                                : (
                                    includes([appConfig.SHIPPING_STATUS.CANCELED.CODE], shipping.status)
                                        ?
                                        <span className="ladiui button warning rounded">{this.getNameByCode(appConfig.SHIPPING_STATUS, shipping.status)}</span>
                                        :
                                        <span className="ladiui button info rounded">{this.getNameByCode(appConfig.SHIPPING_STATUS, shipping.status)}</span>
                                )
                        }

                    </div>

                    <div className="item status">
                        <span>{t('SHIPPINGS.SHIPPING_PAYMENT_STATUS')}</span>
                        {
                            includes([appConfig.SHIPPING_PAYMENT_STATUS.PAID.CODE, appConfig.SHIPPING_PAYMENT_STATUS.RECEIVED.CODE], shipping.payment_status)
                                ?
                                <span className="ladiui button success rounded">{this.getNameByCode(appConfig.SHIPPING_PAYMENT_STATUS, shipping.payment_status)}</span>
                                :
                                <span className="ladiui button warning rounded">{this.getNameByCode(appConfig.SHIPPING_PAYMENT_STATUS, shipping.payment_status)}</span>
                        }
                    </div> */}

                    {this.state.isShowModalTrackingNumber && (
                        <ModalTrackingNumber
                            shippingID={shipping.shipping_id}
                            trackingNumber={trackingNumber}
                            visible={this.state.isShowModalTrackingNumber}
                            onSubmit={this.submit}
                            onCancel={this.hideModalTrackingNumber}
                            isLoading={this.props.isLoading}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation('translation', { withRef: true })(ShippingAddress);
