import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import { map } from 'lodash';
import SelectOption from '../../../components/SelectOption';

import reportActions from "../../../redux/futures/report/actions";
import * as reportTypes from "../../../redux/futures/report/types";

export default function ReportTopProduct(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    let { fromDateCompare, toDateCompare, pageCheckoutId } = props;

    const _moment = baseHelper.getMoment();

    const now = _moment();
    const firstDay = now.set({
        date: 1,
    });

    const listValueTopProduct = [
        {
            name: t('REPORTS.BY_REVENUE'),
            value: 'total',
        },
        {
            name: t('REPORTS.BY_QUANTITY'),
            value: 'quantity',
        },
    ];

    const [toDate, setToDate] = useState(_moment());
    const [fromDate, setFromDate] = useState(firstDay);

    const reportReducer = useSelector((state) => state.report);
    const storeReducer = useSelector((state) => state.store);
    const [isLoadingData, setIsLoadingData] = useState(false);

    const { search } = useLocation();

    const [type, setType] = useState('total');
    const [dataTopProducts, setDataTopProducts] = useState([]);
    const [dataTopProductCompare, setDataTopProductCompare] = useState([]);


    useEffect(() => {
        let data = {
            from_date: fromDate,
            to_date: toDate,
            type: type,
            page_checkout_id: pageCheckoutId,
            compare: {
                from_date: fromDateCompare,
                to_date: toDateCompare
            }
        };
        dispatch(reportActions.topProductReport(data));
    }, [fromDate, toDate, type, fromDateCompare, toDateCompare, pageCheckoutId]);

    useEffect(() => {
        setFromDate(props.fromDate || fromDate);
        setToDate(props.toDate || toDate);
    }, [props.fromDate, props.toDate])


    useEffect(() => {
        if (reportReducer.action == reportTypes.REPORT_TOP_PRODUCT) {
            if (reportReducer.status) {
                let topProductCompare = reportReducer.data.report_compare.top_product || [];

                setDataTopProductCompare(topProductCompare);
                setDataTopProducts(reportReducer.data.top_product || []);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', reportReducer.message, 'OK');
            }
        }
    }, [reportReducer])

    return (
        <div className='block-content-item mt-20'>
            <div className='flex items-center space-between'>
                <h3>Top sản phẩm bán chạy</h3>
                <SelectOption
                    wrapperStyle={{ minWidth: 150 }}
                    items={listValueTopProduct}
                    _key='value'
                    _value='name'
                    currentKey={type}
                    onSelectItem={(item) => {
                        setType(item.value);
                    }}
                />
            </div>

            <table className={`ladiui table text-left ${isLoadingData ? 'loader' : ''}`}>
                <thead>
                    <tr className='ladiui table-vertical'>
                        <th scope='col' name='full_name' className={`text-left`}>
                            {t('REPORTS.PRODUCT.NAME')}
                        </th>
                        <th scope='col' name='email' className='text-right'>
                            {t('REPORTS.PRODUCT.QUANTITY')}
                        </th>
                        <th scope='col' name='phone' className='text-right'>
                            {t('REPORTS.PRODUCT.REVENUE')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {map(dataTopProducts, (product, index) => {
                        let _percentTotal = 0;
                        let _percentQty = 0;
                        let findItem = dataTopProductCompare.find(_item => _item.product_id == product.product_id);

                        if (findItem) {
                            _percentTotal = (product.total - findItem.total) * 100 / (findItem.total > 0 ? findItem.total : 1);
                            _percentQty = (product.quantity - findItem.quantity) * 100 / (findItem.quantity > 0 ? findItem.quantity : 1);
                        }
                        return (
                            <tr key={index} className='ladiui table-vertical'>
                                <td>{product.name}</td>
                                <td>
                                    <div className='flex flex-column items-end flex-end'>
                                        <span>{product.quantity}</span>
                                        {
                                            dataTopProductCompare && dataTopProductCompare.length > 0
                                            &&
                                            <span className={`flex items-center gap-4 ${findItem && findItem.quantity > product.quantity ? 'percent-down' : 'percent-up'}`}>
                                                {/* <i className='new-ldicon-arrow-up-fill' /> */}
                                                {
                                                    findItem && findItem.quantity > product.quantity
                                                        ?
                                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z" fill="#E01A1A" />
                                                        </svg>
                                                        :
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z" fill="#35A500" />
                                                        </svg>
                                                }
                                                {baseHelper.formatMoneyPostFix(_percentQty, '%')}
                                            </span>
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div className='flex flex-column items-end flex-end'>
                                        <span className='space-nowrap'> {baseHelper.formatMoneyPostFix(
                                            product.total,
                                            storeReducer.userInfo.currentStore.currency_symbol
                                        )}</span>
                                        {
                                            dataTopProductCompare && dataTopProductCompare.length > 0
                                            &&
                                            <span className={`flex items-center gap-4 ${findItem && findItem.total > product.total ? 'percent-down' : 'percent-up'}`}>
                                                {/* <i className='new-ldicon-arrow-up-fill'></i> */}
                                                {
                                                    findItem && findItem.total > product.total
                                                        ?
                                                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.1852 8.41757L9.40005 5.10233C9.60065 4.89547 9.47906 4.5 9.21485 4.5H2.78515C2.52094 4.5 2.39935 4.89547 2.59995 5.10233L5.8148 8.41757C5.92137 8.52748 6.07863 8.52748 6.1852 8.41757Z" fill="#E01A1A" />
                                                        </svg>
                                                        :
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.24698 5.44327L12.5334 9.86359C12.8009 10.1394 12.6388 10.6667 12.2865 10.6667H3.71358C3.3613 10.6667 3.19917 10.1394 3.46664 9.86359L7.7531 5.44327C7.8952 5.29674 8.10488 5.29674 8.24698 5.44327Z" fill="#35A500" />
                                                        </svg>
                                                }
                                                {baseHelper.formatMoneyPostFix(_percentTotal, '%')}
                                            </span>
                                        }
                                    </div>

                                </td>
                            </tr>
                        );
                    })}
                    {dataTopProducts.length <= 0 && (
                        <tr className='text-center'>
                            <td colSpan='100%'>{t('REPORTS.PRODUCT.NO_DATA')}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
