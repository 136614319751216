import React, { Component, useEffect, useMemo } from 'react';
import './assets/css/reset.css';
import './assets/css/fonts.css';
import './assets/css/orther.css';
import './assets/css/ladiui.css';

import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import coockieActions from './redux/futures/cookies/actions';
import storeActions from './redux/futures/store/actions';
import { useDispatch, useSelector } from 'react-redux';

import Layout from './layout/Layout';

import appConfig from './config/app';
import queryString from 'query-string';
import baseHelper from './helpers/BaseHelper';
import { authEndpoint } from './config/config';
import 'moment/locale/vi';
// import 'moment/locale/en-au';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import NoAccess from './layout/NoAccess';

// class App extends Component {
//     constructor(props) {
//         super(props);

//         const parser = queryString.parse(this.props.location.search);

//         let token = '';

//         if (parser.token) {
//             window.ls_token = parser.token;
//             token = parser.token;

//             // const restParam = queryString.stringify(parser);
//             // props.history.push(`?${restParam}`);
//         }

//         let ldp_data = {
//             token,
//             is_iframe: parser.is_iframe,
//             ladi_uid_owner: parser.ladi_uid_owner,
//             ladipage_store_id: parser.ladipage_store_id,
//             ssid: token,
//         };
//         this.props.setDataLadipage(ldp_data);

//         this.state = {
//             token,
//             ldp_data: {
//                 token,
//                 is_iframe: parser.is_iframe,
//                 ladi_uid_owner: parser.ladi_uid_owner,
//                 ladipage_store_id: parser.ladipage_store_id,
//             },
//         };
//     }

//     render() {
//         const location = useLocation();
//         console.log('location: ', location);
//         let token = baseHelper.getCookie(appConfig.COOKIE.SSID);
//         if (this.state.token) {
//             token = this.state.token;
//         }

//         if (!token) {
//             return <NoAccess />;
//             // const redirectParser = queryString.parseUrl(authEndpoint.AUTH_SIGNIN);
//             // redirectParser.query.callback_url = window.location.href;
//             // const redirectUrl = queryString.stringifyUrl(redirectParser);
//             // window.location.href = redirectUrl;
//             return;
//         }

//         return <Layout />;
//     }
// }

// const mapDispatchToProps = (dispatch) => {
//     return {
//         setDataLadipage: (data) => dispatch(coockieActions.setDataLadipage(data)),
//     };
// };

// const mapStateToProps = (state) => ({
//     storeReducer: { ...state.store },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(App);

function App(props) {
    const location = useLocation();
    const dispatch = useDispatch();

    const { search } = useLocation();

    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        let token = queryStrings.get('token');
        const is_iframe = queryStrings.get('is_iframe');
        const ladi_uid_owner = queryStrings.get('ladi_uid_owner');
        const ladipage_store_id = queryStrings.get('ladipage_store_id');
        const ladisale_store_id = queryStrings.get('store_id');

        const pathname = location.pathname;

        // if (!token) token = baseHelper.getCookie(appConfig.COOKIE.SSID);
        // else baseHelper.setCookie(appConfig.COOKIE.SSID, token);

        if (!token && appConfig.ROUTER_TICKET_CHECKIN.includes(pathname)) {
            const redirectParser = queryString.parseUrl(authEndpoint.AUTH_SIGNIN);
            redirectParser.query.callback_url = window.location.href;
            const redirectUrl = queryString.stringifyUrl(redirectParser);
            window.location.replace(redirectUrl);
            return;
        }
        let ldp_data = {
            token,
            is_iframe,
            ladi_uid_owner,
            ladipage_store_id,
            ssid: token,
            ladisale_store_id,
        };
        dispatch(coockieActions.setDataLadipage(ldp_data));
        dispatch(storeActions.getUserInfo());
    }, []);

    // return <NoAccess />;
    return <Layout />;
}

export default App;
