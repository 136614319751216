/** @format */

import { LIST_COUNTRIES, LIST_STATES, LIST_DISTRICTS, LIST_WARDS } from '../futures/address_config/types';
import { appIndexedDB } from '../../localforage';
import appConfig from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';

const versionCache = '2.0.0';

const cachedActions = {
    LIST_COUNTRIES: false,
    LIST_STATES: true,
    LIST_DISTRICTS: true,
    LIST_WARDS: true,
};

const keys = [
    appConfig.LOCAL_FORAGE.COUNTRY_KEY,
    appConfig.LOCAL_FORAGE.STATE_KEY,
    appConfig.LOCAL_FORAGE.DISTRICT_KEY,
    appConfig.LOCAL_FORAGE.STATE_KEY,
];

const cacheStorageService = (store) => (next) => async (action) => {
    if (!action.meta || !cachedActions[action.type]) {
        next(action);
        return;
    }

    const versionCacheNow = await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.VERSION_CACHE);

    if (versionCacheNow != versionCache) {
        for await (let key of keys) {
            await appIndexedDB.removeItem(key);
        }
        await appIndexedDB.setItem(appConfig.LOCAL_FORAGE.VERSION_CACHE, versionCache);
        next(action);
        return;
    }

    if (action.type == LIST_COUNTRIES) {
        const countries = await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.COUNTRY_KEY);
        if (!countries) {
            next(action);
            return;
        }

        action.status = true;
        action.payload = {
            countries,
        };
        delete action.meta;
        next(action);
        return;
    }

    if (action.type == LIST_STATES) {
        const countryCode = action.meta.body.country_code;
        const states = await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.STATE_KEY);

        if (!states || !states[countryCode]) {
            next(action);
            return;
        }

        action.status = true;
        action.payload = {
            states: states[countryCode],
        };
        delete action.meta;
        next(action);
    }

    if (action.type == LIST_DISTRICTS) {
        const countryCode = action.meta.body.country_code;
        const stateID = action.meta.body.state_id;

        const districts = await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.DISTRICT_KEY);

        if (!districts || !districts[countryCode] || !districts[countryCode][stateID]) {
            next(action);
            return;
        }

        action.status = true;
        action.payload = {
            districts: districts[countryCode][stateID],
        };
        delete action.meta;
        next(action);
    }

    if (action.type == LIST_WARDS) {
        const countryCode = action.meta.body.country_code;
        const stateID = action.meta.body.state_id;
        const districtID = action.meta.body.district_id;

        const wards = await appIndexedDB.getItem(appConfig.LOCAL_FORAGE.WARD_KEY);

        if (
            baseHelper.isEmpty(wards) ||
            baseHelper.isEmpty(wards[countryCode]) ||
            baseHelper.isEmpty(wards[countryCode][stateID]) ||
            baseHelper.isEmpty(wards[countryCode][stateID][districtID])
        ) {
            next(action);
            return;
        }

        action.status = true;
        action.payload = {
            wards: wards[countryCode][stateID][districtID],
        };
        delete action.meta;
        next(action);
    }
};

export default cacheStorageService;
export { cachedActions };
