import React from 'react';
import PropTypes from 'prop-types';

export default class ConfirmModal extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        onCancel: PropTypes.func,
        cancelText: PropTypes.string,
        onOk: PropTypes.func,
        okText: PropTypes.string,
        isLoading: PropTypes.bool,
        maxWidth: PropTypes.number,
        hasFooter: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        id: 'modal-confirm-action',
        hasFooter: true,
    };

    onCancel = () => {
        window.LadiUI.closeModal(this.props.id);
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    onOk = () => {
        if (this.props.onOk) {
            this.props.onOk();
        }
    };

    render() {
        const {
            id,
            title,
            content,
            cancelText,
            okText,
            isLoading,
            style,
            maxWidth,
            hasFooter,
            className,
            footer,
            isBtnPrimary,
            isHiddenBtnCancel,
        } = this.props;

        return (
            <div id={id} className={`ladiui modal fade ${className && className}`} tabIndex='-1' data-dismiss={id} style={style}>
                <div className='ladiui modal-dialog modal-dialog-centered'>
                    <div className='ladiui modal-content confirm' style={maxWidth ? { maxWidth: maxWidth } : {}}>
                        <div className='ladiui modal-header'>
                            <label className='ladiui title-text-form-lever1'>{title}</label>
                        </div>

                        <div className='ladiui modal-body'>{content}</div>

                        {hasFooter && (
                            <div className='ladiui modal-footer'>
                                {!isHiddenBtnCancel && (
                                    <button
                                        className='ladiui btn btn-secondary close-modal btn-sm'
                                        onClick={this.onCancel}
                                        data-dismiss={id}
                                    >
                                        {cancelText}
                                    </button>
                                )}
                                <button
                                    className={`ladiui btn ${isBtnPrimary ? 'btn-primary' : 'btn-danger'} btn-sm ${
                                        isLoading ? 'loader' : ''
                                    }`}
                                    onClick={this.onOk}
                                >
                                    {okText}
                                </button>
                            </div>
                        )}
                        {!hasFooter && footer && <div className='ladiui modal-footer'>{footer}</div>}
                    </div>
                </div>
            </div>
        );
    }
}
