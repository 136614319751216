import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
export const ShippingProducts = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const { variantSelected = [], shipping = {}, onChangeShippingStatus } = props;

    const storeReducer = useSelector((state) => state.store);

    const getNameShippingByCode = (code) => {
        const matched = Object.values(appConfig.SHIPPING_STATUS).find((item) => item.CODE == code) || {};
        return matched.NAME;
    };

    return (
        <>
            <div className='order-products'>
                <div className='ladiui flex-center-between'>
                    <div className='order-product-title pb-12'>
                        <label className='ladiui label-18'>{t('PRODUCTS.PRODUCT_INFO')}</label>
                    </div>
                </div>

                {variantSelected.length == 0 && (
                    <div className='product-empty'>
                        <i className='ladiui icon-new new-ldicon-box' />
                        <div className='mt-8 ladiui text-14'> {t('PRODUCTS.PRODUCT_EMPTY')}</div>
                    </div>
                )}

                {variantSelected.length > 0 && (
                    <div className='variant-selected-list'>
                        <div className='variant-selected-item display-flex-center'>
                            <div className='variant-image-name-option ladiui heading-label-2'>{t('MENU.PRODUCTS')}</div>
                            <div className='variant-quatity ladiui heading-label-2'> {t('COMMON.QUANTITY')} </div>
                            <div className='variant-price-discount ladiui heading-label-2'> {t('COMMON.PRICE')} </div>
                            <div className='variant-total ladiui heading-label-2'> {t('COMMON.TOTAL_AMOUNT')} </div>
                        </div>
                        {variantSelected.map((item, index) => {
                            let imageSrc = baseHelper.getImageUrl(item.src);
                            let optionNames = item.options ? item.options.map((option) => option.option_value_value) : [];
                            let optionName = optionNames.join(', ');
                            return (
                                <div className='variant-selected-item display-flex-center boder-top' key={index}>
                                    <div className='variant-image-name-option display-flex-center'>
                                        <img className='variant-image mr-24' src={imageSrc} alt='variant-selected-image' />
                                        <div className='variant-name-option'>
                                            <div className='variant-name ladiui label-16'>{item.product_name}</div>
                                            <div className='option ladiuid text-14 mt-4'>{optionName}</div>
                                        </div>
                                    </div>
                                    <div className='variant-quatity'>
                                        <span>{item.quantity}</span>
                                    </div>
                                    <div className='variant-price-discount'>
                                        <a className='ladiui-custom-popup-init'>
                                            {baseHelper.formatMoneyPostFix(
                                                item.price - (item.discount_fee || 0),
                                                storeReducer.userInfo.currentStore.currency_symbol
                                            )}
                                        </a>
                                        {item.discount_fee && item.discount_fee > 0 && (
                                            <span className='price-before-discount'>
                                                {baseHelper.formatMoneyPostFix(
                                                    item.price,
                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                )}
                                            </span>
                                        )}
                                    </div>
                                    <div className='variant-total ladiui text-14'>
                                        {baseHelper.formatMoneyPostFix(item.total, storeReducer.userInfo.currentStore.currency_symbol)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                <div className='payment-action ladiui flex-center-between footer'>
                    <div>{t('SHIPPINGS.SHIPPING_STATUS')}</div>

                    <div className='ladiui btn-group' style={{ width: '300px', float: 'right' }}>
                        <div className='ladiui dropdown w100'>
                            <button
                                style={{ minWidth: '100%' }}
                                className='ladiui btn btn-outline-light dropdown-toggle'
                                disabled={shipping.status == appConfig.SHIPPING_STATUS.CANCELED.CODE}
                                data-toggle='dropdown'
                            >
                                {getNameShippingByCode(shipping.status)}
                            </button>
                            <ul className='ladiui dropdown-menu w100'>
                                {Object.values(appConfig.SHIPPING_STATUS).map((option) => {
                                    if (option != appConfig.SHIPPING_STATUS.PENDING) {
                                        return (
                                            <li key={option.CODE} onClick={() => onChangeShippingStatus(shipping.shipping_id, option.CODE)}>
                                                <a className='ladiui dropdown-item'>{option.NAME}</a>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
