import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const listCountries = () => ({
    type: types.LIST_COUNTRIES,
    meta: {
        endpoint: endpoint.LIST_COUNTRIES,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const listStates = (countryCode) => ({
    type: types.LIST_STATES,
    meta: {
        endpoint: endpoint.LIST_STATES,
        method: REQUEST_METHOD.POST,
        body: {
            country_code: countryCode,
        },
        hasAuth: true,
    }
});

const listDistricts = (countryCode, stateID) => ({
    type: types.LIST_DISTRICTS,
    meta: {
        endpoint: endpoint.LIST_DISTRICTS,
        method: REQUEST_METHOD.POST,
        body: {
            country_code: countryCode,
            state_id: stateID,
        },
        hasAuth: true,
    }
});

const listWards = (countryCode, stateID, districtID) => ({
    type: types.LIST_WARDS,
    meta: {
        endpoint: endpoint.LIST_WARDS,
        method: REQUEST_METHOD.POST,
        body: {
            country_code: countryCode,
            state_id: stateID,
            district_id: districtID,
        },
        hasAuth: true,
    }
});

const resetCountries = () => ({
    type: types.ADDRESS_CONFIG_RESET_COUNTRIES,
});

const resetStates = () => ({
    type: types.ADDRESS_CONFIG_RESET_STATES,
});

const resetDistricts = () => ({
    type: types.ADDRESS_CONFIG_RESET_DISTRICTS,
});

const resetWards = () => ({
    type: types.ADDRESS_CONFIG_RESET_WARDS,
});

const resetAll = () => ({
    type: types.ADDRESS_CONFIG_RESET_ALL,
});


export default {
    listCountries,
    listStates,
    listDistricts,
    listWards,
    resetStates,
    resetDistricts,
    resetWards,
    resetCountries,
    resetAll,
}