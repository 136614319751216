import React, { useEffect, useState, useRef, useMemo, useImperativeHandle } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';

import baseHelper from '../../../../../helpers/BaseHelper';
import appConfig from '../../../../../config/app';

import * as fileTypes from '../../../../../redux/futures/file/types';
import fileActions from '../../../../../redux/futures/file/actions';

import PopoverAddSection from './PopoverAddSection';
import ImageUpload from '../../../../../components/ImageUpload';
import Input from '../../../../../components/Input';
import ModalAddProduct from '../../components/ModalAddProduct';

// eslint-disable-next-line max-lines-per-function

const HandelSectionProducts = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();
    const queryStrings = useMemo(() => new URLSearchParams(search), [search]);

    const { products, setProducts } = props;
    const storeReducer = useSelector((state) => state.store);

    const [isShowModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
        getData: () => {
            let productIds = products.map((item) => item.product_id);
            return productIds;
        },
    }));

    const reorder = (list, startIndex, endIndex) => {
        let remove = list[startIndex];
        list.splice(startIndex, 1);
        list.splice(endIndex, 0, remove);

        return list;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        let cartContenerTg = [...products];

        cartContenerTg = reorder(cartContenerTg, result.source.index, result.destination.index);

        setProducts(cartContenerTg);
    };

    const handleAddProduct = (data) => {
        let dataTg = [...products, ...data];
        dataTg = dataTg.filter((item, index) => dataTg.findIndex((_item) => _item.product_id == item.product_id) === index);

        setProducts(dataTg);
    };

    const handelDeleteProduct = (index) => {
        let dataTg = [...products];
        dataTg.splice(index, 1);

        setProducts(dataTg);
    };

    return (
        <div>
            <label className='ladiui text-14'>Danh sách sản phẩm</label>

            {!products || products.length == 0 ? (
                <div className='mt-32 pt-24 pb-24 display-flex-center'>
                    <div className='ladiui text-center w-100-p'>
                        <i className='ladiui icon-new new-ldicon-box' />
                        <div className='ladiui tex-14'> Chưa có sản phẩm trong trang thanh toán</div>
                    </div>
                </div>
            ) : (
                <div className='product-list'>
                    <div className='product-item'>
                        <div className='product-name'>Sản phẩm</div>
                        <div className='product-price'>Giá bán</div>
                        <div className='product-delete'></div>
                    </div>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='cartSetting' type='droppableCart'>
                            {(provided, snapshot) => (
                                <div
                                    className='product-list'
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    // style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {products.map((item, index) => {
                                        let imageSrc = baseHelper.getImageUrl(item.src);
                                        return (
                                            <Draggable
                                                key={`${item.product_id}-${index}`}
                                                draggableId={`${item.product_id}-${index}`}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        className='product-item'
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className='product-name display-flex-center gap-16'>
                                                            <img className='product-image' src={imageSrc} />
                                                            <div>{item.name}</div>
                                                        </div>
                                                        <div className='product-price'>
                                                            <span>
                                                                {baseHelper.formatMoneyPostFix(
                                                                    item.min_price,
                                                                    storeReducer.userInfo.currentStore.currency_symbol
                                                                )}
                                                            </span>
                                                            {/* {item.min_price != item.max_price && (
                                                                <span>
                                                                    {' - '}
                                                                    {baseHelper.formatMoneyPostFix(
                                                                        item.max_price,
                                                                        storeReducer.userInfo.currentStore.currency_symbol
                                                                    )}
                                                                </span>
                                                            )} */}
                                                        </div>
                                                        <div onClick={() => handelDeleteProduct(index)}>
                                                            <i className='ladiui icon-new new-ldicon-delete' />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            )}
            <div className='btn-add-section' onClick={() => setShowModal(true)}>
                <i className='ldicon-add' />
                <span className='ladiui text-brand'>Thêm sản phẩm</span>
            </div>
            {isShowModal && (
                <ModalAddProduct
                    isShow={isShowModal}
                    onClose={() => setShowModal(false)}
                    handleAddProduct={handleAddProduct}
                    selectedProduct={products || []}
                />
            )}
        </div>
    );
});

export default HandelSectionProducts;
