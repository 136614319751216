import React from 'react';
import PropTypes from 'prop-types';
import validatorHelper from '../helpers/ValidatorHelper';
import baseHelper from '../helpers/BaseHelper';
import i18n from '../i18n';

const TypeProps = {
    text: 'text',
    number: 'number',
    password: 'password',
    radio: 'radio',
};

const regexSpecialChar = /[<>,.?/:";'\{\}\[\]\\\|\!\@\#\$\^\&\*\~\`\(\)\_\-\+\=]/g;

class Input extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        autoFocus: PropTypes.bool,
        type: PropTypes.string,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        name: PropTypes.any,
        validationName: PropTypes.string,
        value: PropTypes.any,
        prefix: PropTypes.string,
        currency: PropTypes.bool,
        formatter: PropTypes.func,
        parser: PropTypes.func,
        placeholder: PropTypes.string,
        hasBlurValidate: PropTypes.bool,
        style: PropTypes.any,
        disabled: PropTypes.bool,
        tabIndex: PropTypes.number,
        className: PropTypes.string,
        allowSpecialChar: PropTypes.bool,

        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyPress: PropTypes.func,
        onKeyDown: PropTypes.func,

        validations: PropTypes.shape({
            isRequired: PropTypes.bool,
            isDomain: PropTypes.bool,
            isURL: PropTypes.bool,
            minLength: PropTypes.number,
            maxLength: PropTypes.number,
            min: PropTypes.number,
            max: PropTypes.number,
            email: PropTypes.bool,
            phone: PropTypes.bool,
        }),
    };

    static defaultProps = {
        autoFocus: false,
        type: TypeProps.text,
        hasBlurValidate: false,
        allowSpecialChar: true,
        toggleValidate: false,
        currency: false,
        disabled: false,
        validations: {
            isRequired: false,
            minLength: 0,
            maxLength: 300,
            min: 0,
            max: 99999999999999999999,
            email: false,
            phone: false,
        },
    };

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            errorValue: '',
        };
    }

    onBlur = (event) => {
        // Trim value
        let { value } = event.target;
        if (this.props.type != 'password') {
            value = value.trim();
        }

        const { parser, onBlur, allowSpecialChar } = this.props;
        if (parser) {
            value = parser(value);
        }

        if (!allowSpecialChar) {
            value = String(value).replace(regexSpecialChar, '');
        }

        event.target.value = value;

        this.props.onChange(event);

        if (onBlur) {
            onBlur(event);
        }

        if (!this.props.hasBlurValidate || !this.props.validations) {
            return;
        }

        event.preventDefault();

        this.validate();
        return false;
    };

    validate = () => {
        const errors = [];
        if (!this.ref.current) {
            return errors;
        }

        const { classList } = this.ref.current;
        classList.remove('error');

        let validateStatus = true;

        let { value, parser } = this.props;
        if (parser) {
            value = parser(value);
        }

        let { validationName, name } = this.props;
        if (!validationName) {
            validationName = name;
        }
        const { isDomain, isRequired, minLength, maxLength, email, phone, min, max, isURL } = this.props.validations;
        if (isRequired) {
            if (validatorHelper.isEmpty(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.ENTER', validationName));
            }
        }

        if (isDomain) {
            if (!validatorHelper.isDomain(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.DOMAIN', validationName));
            }
        }

        if (isURL) {
            // if (!validatorHelper.isURL(value)) {
            if (!validatorHelper.isValidHttpUrl(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.URL', validationName));
            }
        }

        if (minLength) {
            if (validatorHelper.isLengthMoreThan(value, minLength)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.LENGTH_MORE_THAN', validationName, minLength));
            }
        }

        if (maxLength) {
            if (validatorHelper.isLengthLessThan(value, maxLength)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.LENGTH_LESS_THAN', validationName, maxLength));
            }
        }

        if (min) {
            if (baseHelper.parseFloat(value) < baseHelper.parseFloat(min)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.GREATER_THAN', validationName, min));
            }
        }

        if (max) {
            if (baseHelper.parseFloat(value) > baseHelper.parseFloat(max)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.SMALLER_THAN', validationName, max));
            }
        }

        if (email) {
            if (!validatorHelper.isEmail(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.INVALID_EMAIL'));
            }
        }

        if (phone) {
            if (!validatorHelper.isPhone(value)) {
                validateStatus = false;
                errors.push(i18n.t('VALIDATION.INVALID_PHONE'));
            }
        }

        if (!validateStatus) {
            classList.add('error');
        }

        return errors;
    };

    onChange = (event) => {
        let { value } = event.target;
        const { parser, allowSpecialChar } = this.props;

        if (parser) {
            value = parser(value);
        }

        if (!allowSpecialChar) {
            value = String(value).replace(regexSpecialChar, '');
        }

        event.target.value = value;

        this.props.onChange({
            ...event,
            target: {
                name: event.target.name,
                value,
            },
        });
    };

    onKeyPress = (event) => {
        const keyValue = String.fromCharCode(event.charCode || event.keyCode);

        const { type, currency, prefix, allowSpecialChar } = this.props;
        if (type === TypeProps.number) {
            let result = true;

            if (currency) {
                result = RegExp('^([0-9]|\\.)$').test(keyValue);
            } else {
                result = RegExp('^[0-9]$').test(keyValue);
            }

            if (!result) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
        } else {
            if (!allowSpecialChar) {
                if (RegExp(regexSpecialChar).test(keyValue)) {
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                }
            }
        }

        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }
    };

    onKeyDown = (event) => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    render() {
        const {
            id,
            value,
            name,
            placeholder,
            formatter,
            prefix,
            type,
            currency,
            style,
            checked,
            min,
            max,
            disabled,
            tabIndex,
            onFocus,
            autoFocus,
            className,
            noFormControl,
            maxLength,
        } = this.props;

        const { errorValue } = this.state;

        let displayValue = !baseHelper.isEmpty(value) ? value : '';
        if (prefix && !displayValue) {
            displayValue = `${prefix} ${displayValue}`;
        }

        if (formatter) {
            displayValue = formatter(!baseHelper.isEmpty(value) ? value : '');
        }

        return (
            <div className='w-100-p'>
                <input
                    id={id}
                    // tabIndex={tabIndex}
                    type={currency ? 'text' : type}
                    min={min}
                    max={max}
                    maxLength={maxLength ? maxLength : 230}
                    ref={this.ref}
                    name={name}
                    className={`ladiui ${!noFormControl ? 'form-control' : ''} ${className ? className : ''}`}
                    style={style}
                    placeholder={placeholder}
                    value={displayValue}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onKeyPress={this.onKeyPress}
                    onKeyDown={this.onKeyDown}
                    checked={checked}
                    disabled={disabled}
                    onFocus={onFocus}
                    autoFocus={autoFocus}
                />
                {errorValue && <div className='ladiui text-danger mt-8'> {errorValue}</div>}
            </div>
        );
    }
}

export default Input;
