import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.DELETE_VARIANT: {
            return {
                ...state,
                action: types.DELETE_VARIANT,
                status: action.status,
                message: action.message,
            };
        }
        case types.CREATE_VARIANT: {
            return {
                ...state,
                action: types.CREATE_VARIANT,
                status: action.status,
                message: action.message,
            };
        }
        case types.UPDATE_VARIANT: {
            return {
                ...state,
                action: types.UPDATE_VARIANT,
                status: action.status,
                message: action.message,
            };
        }
        case types.CHANGE_IMAGE_VARIANT: {
            return {
                ...state,
                action: types.CHANGE_IMAGE_VARIANT,
                status: action.status,
                message: action.message,
            };
        }
        case types.SHOW_VARIANT: {
            return {
                ...state,
                action: types.SHOW_VARIANT,
                variant: action.status ? action.payload.variant : {},
                status: action.status,
                message: action.message,
            };
        }
        case types.SEARCH_VARIANT: {
            return {
                ...state,
                action: types.SEARCH_VARIANT,
                variants: action.status ? action.payload.variants : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                paged: action.status ? action.payload.paged : 1,
                status: action.status,
                message: action.message,
            };
        }
        case types.GET_VARIANT_INFOS: {
            return {
                ...state,
                action: types.GET_VARIANT_INFOS,
                variantInfos: action.status ? action.payload.variantInfos : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.RE_ORDER_VARIANT: {
            return {
                ...state,
                action: types.RE_ORDER_VARIANT,
                status: action.status,
                message: action.message,
            };
        }
        case types.TICKET_CREATION_FORM: {
            return {
                ...state,
                action: types.TICKET_CREATION_FORM,
                status: true,
                ticket_creation_form: action.payload.ticket_creation_form,
            };
        }
        case types.PACKAGE_CREATE: {
            return {
                ...state,
                action: types.PACKAGE_CREATE,
                status: action.status,
                message: action.message,
            };
        }
        case types.PACKAGE_UPDATE: {
            return {
                ...state,
                action: types.PACKAGE_UPDATE,
                status: action.status,
                message: action.message,
            };
        }
        case types.PACKAGE_UPDATE_POSITION: {
            return {
                ...state,
                action: types.PACKAGE_UPDATE_POSITION,
                status: action.status,
                message: action.message,
            };
        }
        case types.PACKAGE_DELETE: {
            return {
                ...state,
                action: types.PACKAGE_DELETE,
                status: action.status,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
