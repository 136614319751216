import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';

class Modal extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func,
    zIndex: PropTypes.number,
    closeable: PropTypes.bool,
    style: PropTypes.object,
    isLoading: PropTypes.bool,
    width: PropTypes.number,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    footer: PropTypes.any,
    bodyStyles: PropTypes.object,
    wrapperClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    hasHeader: PropTypes.bool,
    hasFooter: PropTypes.bool,
    disableOk: PropTypes.bool,
    closeComponent: PropTypes.object,
    logo: PropTypes.any,
    isActiveBtnSaveData: PropTypes.bool,
    isCustomFooter: PropTypes.bool,
    isShowDescription: PropTypes.bool,
    isShowAccountType: PropTypes.bool,
    position_unset: PropTypes.bool,
  };

  static defaultProps = {
    disableOk: false,
    closeable: true,
    visible: false,
    isLoading: false,
    cancelText: i18n.t('ACTIONS.CLOSE'),
    okText: i18n.t('ACTIONS.SAVE'),
    hasHeader: true,
    hasFooter: true,
    position_unset: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      id,
      title,
      children,
      okText,
      cancelText,
      onCancel,
      onOk,
      closeable,
      visible,
      isLoading,
      zIndex,
      width,
      footer,
      bodyStyles,
      wrapperClassName,
      bodyClassName,
      hasHeader,
      hasFooter,
      disableOk,
      closeComponent,
      hightLightTitle,
      logo,
      isShowIconBack,
      resetAccountType,
      isShowAccountType,
      logoAccount,
      accountName,
      isShowDescription,
      descriptionModal,
      isActiveBtnSaveData,
      isCustomFooter,
      sub_title,
      position_unset,
    } = this.props;
    if (!visible) {
      return null;
    }
    return (
      <div
        ref={this.ref}
        id={id}
        className={`ladiui modal fade visible ${wrapperClassName ? wrapperClassName : ''}`}
        tabIndex={-1}
        data-dismiss={id}
        style={zIndex ? { zIndex } : {}}
      >
        <div
          className={`ladiui modal-dialog modal-dialog-centered ${position_unset ? 'position-unset' : ''}`}
          style={width ? { width: width } : {}}
        >
          <div className='ladiui modal-content'>
            {hasHeader && (
              <div className='ladiui modal-header' style={{ position: 'relative' }}>
                {/* {isShowIconBack && (
                  <div
                    className='ladiui modal-header custom-ldp'
                    style={{ position: 'absolute', top: '16px', left: '16px' }}
                  >
                    <button className='ladiui btn btn-sm btn-link modal-back' onClick={resetAccountType}>
                      <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M4.64375 9.85623L0.14375 5.35623C0.0513399 5.26066 -0.000317797 5.13292 -0.000317791 4.99998C-0.000317785 4.86704 0.0513399 4.7393 0.14375 4.64373L4.64375 0.143731C4.7409 0.0639991 4.86423 0.0232532 4.98976 0.0294182C5.11528 0.0355833 5.23403 0.0882185 5.3229 0.177086C5.41176 0.265953 5.4644 0.384699 5.47056 0.510225C5.47673 0.635751 5.43598 0.759083 5.35625 0.856231L1.70625 4.49998L11.5 4.49998C11.6326 4.49998 11.7598 4.55266 11.8536 4.64643C11.9473 4.7402 12 4.86737 12 4.99998C12 5.13259 11.9473 5.25977 11.8536 5.35353C11.7598 5.4473 11.6326 5.49998 11.5 5.49998L1.70625 5.49998L5.35625 9.14373C5.43598 9.24088 5.47673 9.36421 5.47056 9.48974C5.4644 9.61526 5.41176 9.73401 5.32289 9.82288C5.23403 9.91174 5.11528 9.96438 4.98976 9.97054C4.86423 9.97671 4.7409 9.93596 4.64375 9.85623V9.85623Z'
                          fill='#AFAFAF'
                        />
                      </svg>
                    </button>
                  </div>
                )} */}
                <div>
                  <label className='ladiui title-text-form-lever1' style={{ display: 'flex', alignItems: 'center' }}>
                    {logo && (
                      <img
                        src={logo}
                        className='logo-header-modal'
                        style={{ objectFit: 'cover', maxHeight: '24px' }}
                      />
                    )}
                    <span>{title}</span>
                  </label>
                  <div className='sub-title'>{sub_title}</div>
                </div>
                {hightLightTitle && <span className='modal-hight-light-title'>{hightLightTitle}</span>}
                {closeable ? (
                  <button onClick={() => onCancel()} className='ladiui modal-close' data-dismiss={id}>
                    <i className='ladiui icon icon-ldp-close' data-dismiss={id} />
                  </button>
                ) : (
                  closeComponent
                )}
              </div>
            )}

            {isShowDescription && descriptionModal && (
              <div className='description-header'>
                <p>{descriptionModal}</p>
              </div>
            )}

            {isShowAccountType && (
              <div className='block-account-type'>
                <strong>Loại tài khoản: </strong>
                <img
                  src={logoAccount}
                  className='logo-account-modal'
                  style={{ objectFit: 'cover', maxHeight: '24px', margin: '0px 5px 0px 10px' }}
                />
                <span>{accountName}</span>
              </div>
            )}

            <div
              className={`ladiui modal-body ${bodyClassName ? bodyClassName : ''} ${position_unset ? 'position-unset' : ''}`}
              style={bodyStyles ? bodyStyles : {}}
            >
              {children}
            </div>
            {!hasFooter && <div style={{ borderTop: 0 }} className='ladiui-modal-footer' />}
            {hasFooter && footer && (
              <div className={`ladiui modal-footer ${isCustomFooter ? 'custom-footer' : ''}`}>{footer.props.children}</div>
            )}

            {hasFooter && !footer && (
              <div className='ladiui modal-footer'>
                <button onClick={onCancel} className='ladiui btn btn-secondary '>
                  {cancelText}
                </button>
                {!isActiveBtnSaveData && (
                  <button
                    onClick={onOk}
                    className={`ladiui btn btn-primary ${isLoading ? 'loader' : ''} ${disableOk ? 'disabled' : ''}`}
                    disabled={isLoading || disableOk}
                  >
                    {okText}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
