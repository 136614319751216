/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import appConfig from '../../../../config/app';
import baseHelper from '../../../../helpers/BaseHelper';

import productTagActions from '../../../../redux/futures/product_tag/actions';
import * as productTagTypes from '../../../../redux/futures/product_tag/types';

import fileActions from '../../../../redux/futures/file/actions';
import * as fileTypes from '../../../../redux/futures/file/types';

import LoadingTable from '../../../../components/LoadingTable';
import ConfirmModal from '../../../../components/ConfirmModal';
import LadiPagination from '../../../../components/LadiPagination';
import LadiDropdownMenu from '../../../../components/LadiDropdownMenu';

import Image from '../../../../components/Image';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';

import ModalReorderProductTag from '../ModalReorderProductTag';

import { includes, map, remove, values, cloneDeep, compact } from 'lodash';

let CDN = 'https://w.ladicdn.com/';

export default function ProductListTag(props) {
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const inputsRef = new Set();

    const productTagReducer = useSelector((state) => state.productTag);
    const fileReducer = useSelector((state) => state.file);

    const [activePage, setActivePage] = useState(1);
    const [selectedLimit, setSelectedLimit] = useState(appConfig.PAGINATION.LIMIT_OPTIONS[0].value);
    const [selectedIDs, setSelectedIDs] = useState([]);

    const [currentProductReview, setCurrentProductReview] = useState({});
    const [valueSearch, setValueSearch] = useState('');
    const [mode, setMode] = useState(null);

    const [isShowModalTag, setIsShowModalTag] = useState(false);
    const [isShowModalReorder, setIsShowModalReoder] = useState(false);

    const [tagSelected, setTagSelected] = useState({});

    let selectedID = '';
    // let selectedIDs = [];

    // let sortBy = {
    //   page_checkout_id: "DESC",
    // };
    const formRef = React.createRef();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const Moment = baseHelper.getMoment();

    useEffect(() => {
        handleSearchTag();
    }, [activePage, selectedLimit]);

    useEffect(() => {
        if (
            productTagReducer.action === productTagTypes.CREATE_PRODUCT_TAG ||
            productTagReducer.action === productTagTypes.UPDATE_PRODUCT_TAG
        ) {
            if (productTagReducer.status) {
                setIsShowModalTag(false);
                window.LadiUI.toastCustom('success', '', productTagReducer.message);
                const data = {
                    search: {
                        name: valueSearch,
                    },
                    paged: activePage,
                    limit: selectedLimit,
                };
                dispatch(productTagActions.list(data));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productTagReducer.message, 'OK');
            }
        }
        if (productTagReducer.action === productTagTypes.PRODUCT_TAG_DELETE) {
            if (productTagReducer.status) {
                window.LadiUI.closeModal('confirm-product-tag-delete');
                window.LadiUI.toastCustom('success', '', productTagReducer.message);
                const data = {
                    search: {
                        name: valueSearch,
                    },
                    paged: activePage,
                    limit: selectedLimit,
                };
                dispatch(productTagActions.list(data));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productTagReducer.message, 'OK');
            }
        }
        if (productTagReducer.action === productTagTypes.REORDER_PRODUCT_TAG) {
            if (productTagReducer.status) {
                window.LadiUI.toastCustom('success', '', productTagReducer.message);
                const data = {
                    search: {
                        name: valueSearch,
                    },
                    paged: activePage,
                    limit: selectedLimit,
                };
                dispatch(productTagActions.list(data));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productTagReducer.message, 'OK');
            }
        }
    }, [productTagReducer]);

    useEffect(() => {
        if (fileReducer.action === fileTypes.UPLOAD_COVER_URL) {
            if (fileReducer.action) {
                setTagSelected({
                    ...tagSelected,
                    image_url: fileReducer.cover_url || '',
                });
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productTagReducer.message, 'OK');
            }
        }
    }, [fileReducer]);

    /**
     * Tim kiem theo name
     */

    /*******************************PAGINATION***************************/
    /**
     * Change limit
     */
    const onChangeLimit = (option) => {
        setSelectedLimit(option.value);
        setActivePage(1);
    };

    /**
     * Change page
     */
    const onPageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    /****************************************************************/

    /*******************************CHECK AND ACTIONS***************************/

    const openModalEdit = (tagSelected) => {
        setMode(appConfig.FORM_MODE.EDIT);
        setTagSelected(cloneDeep(tagSelected));
        setIsShowModalTag(true);
    };

    const openModalConfirmDelete = (productTagID) => {
        // setDataCustomField({
        //     ...dataCustomField,
        //     custom_field_id: customFieldID
        // })
        selectedID = productTagID;
        window.LadiUI.showModal('confirm-product-tag-delete');
    };

    const fileChangedHandler = (event, type) => {
        const files = event.target.files;
        const form = baseHelper.getFormDataUpload(files);
        if (form) {
            if (form) {
                // switch (type) {
                //   case "logo":
                //     this.props.uploadLogo(form);
                //     break;
                //   case "favicon":
                //     this.props.uploadFavicon(form);
                //     break;
                //   case "cover":
                //     this.props.uploadCover(form);
                //     break;
                // }
                dispatch(fileActions.uploadCover(form));
            }
        }
    };

    const submit = (event) => {
        event.preventDefault();
        // Validate
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                errors = errors.concat(ref.validate());
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        let payload = {
            name: tagSelected.name,
        };

        if (mode == appConfig.FORM_MODE.CREATE) {
            payload = {
                name: tagSelected.name,
                image_url: tagSelected.image_url,
            };
            dispatch(productTagActions.create(payload));
        } else if (mode == appConfig.FORM_MODE.EDIT) {
            if (tagSelected.product_tag_id) {
                payload.product_tag_id = tagSelected.product_tag_id;
                payload.image_url = tagSelected.image_url;
                dispatch(productTagActions.update(payload));
            }
        }
    };

    const openModalCreateTag = () => {
        setMode(appConfig.FORM_MODE.CREATE);
        setIsShowModalTag(true);
        setTagSelected({
            color: appConfig.TAG_COLORS[0],
        });
    };

    const onKeyDownInputSearch = (event) => {
        if (event.key === 'Enter') {
            const data = {
                search: {
                    name: valueSearch,
                },
                paged: activePage,
                limit: selectedLimit,
            };
            dispatch(productTagActions.list(data));
        }
    };

    const handleSearchTag = () => {
        const data = {
            search: {
                name: valueSearch,
            },
            paged: activePage,
            limit: selectedLimit,
        };
        dispatch(productTagActions.list(data));
    };

    // ------------------------------------reorder product tag-----------------------------------------------------------------------------
    const openModalReorderProduct = (tagSelected) => {
        setIsShowModalReoder(true);
        setTagSelected(cloneDeep(tagSelected));

        // this.tagSelectedBackup = cloneDeep(tagSelected);
    };

    const handleReorder = () => {
        const positions = map(tagSelected.products, (item) => item.product_tag_value_id);
        let payload = {
            product_tag_id: tagSelected.product_tag_id,
            positions,
        };
        dispatch(productTagActions.reOrder(payload));
    };

    const { totalRecord, totalPage } = productTagReducer;

    let fromItem = 0;
    let toItem = 0;
    if ((productTagReducer.reviews || []).length > 0) {
        fromItem = (activePage - 1) * selectedLimit + 1;
        toItem = fromItem + productTagReducer.reviews.length - 1;
    }

    const isLoadingData = false;
    const isLoadingInfo = false;
    const msgShowItem = `${t('PAGINATION.SHOW')} ${fromItem} ${t('PAGINATION.TO')} ${toItem} ${t(
        'PAGINATION.OF'
    )} ${baseHelper.formatNumber(totalRecord)} ${t('PAGINATION.ITEMS')}`;

    const isLoadingProductView = productTagReducer.loading && includes([productTagTypes.LIST_PRODUCT_TAG], productTagReducer.waiting);

    const listAction = [{ value: 'DELETE', name: t('ACTIONS.DELETE') }];

    return (
        <div id='panel-product-list-tag' className='page-content'>
            <div>
                {isLoadingData ? (
                    <LoadingTable />
                ) : (
                    <div>
                        <div className='header-page'>
                            <div className='heading'>
                                <h3>Quản lý Tag</h3>
                                <p>Quản lý tất cả Tag cho sản phẩm của bạn.</p>
                            </div>
                            <div className='action-btn'>
                                <button className='ladiui btn btn-primary flex' onClick={openModalCreateTag}>
                                    <img src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg' className='mr-8' />
                                    <span>Tạo Tag mới</span>
                                </button>
                            </div>
                        </div>
                        <div className='block-filter'>
                            <div className='ladiui search-group'>
                                <input
                                    id='keyword_search'
                                    className='ladiui search-field dropdown-toggle form-control search-width height-40'
                                    name='searchName'
                                    placeholder={t('Tìm kiếm Tag')}
                                    aria-expanded='false'
                                    value={valueSearch}
                                    onChange={(event) => {
                                        setValueSearch(event.target.value);
                                    }}
                                    onKeyDown={onKeyDownInputSearch}
                                />
                                <i className='ladiui icon icon-search' onClick={handleSearchTag}></i>
                            </div>
                        </div>
                        <div className='block-content'>
                            {totalRecord <= 0 ? (
                                <div className='block-no-data'>
                                    <div>
                                        <img src='https://w.ladicdn.com/ladiui/ladipage/icon-not-foder.svg' />
                                        <p>Chưa có Tag nào được tạo</p>
                                    </div>
                                </div>
                            ) : (
                                <div className='ladi-card ladiui-table-responsive'>
                                    <table className={`ladiui table text-left ${isLoadingInfo ? 'loader' : ''}`}>
                                        <thead style={{ position: 'relative' }}>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col' className={`text-left`}>
                                                    Tag
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Số sản phẩm
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Ngày tạo
                                                </th>
                                                <th scope='col' className={`text-left`}>
                                                    Ngày cập nhật
                                                </th>
                                                <th scope='col' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {map(productTagReducer.allTags, (item) => {
                                                return (
                                                    <tr key={item.product_tag_id} className='ladiui table-vertical main'>
                                                        <td>
                                                            <span>{item.name}</span>
                                                        </td>
                                                        <td>{item.quantity}</td>
                                                        <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                                        <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                                                        <td>{item.date_created ? baseHelper.formatDateToStr(item.date_created) : ''}</td>
                                                        <td className='text-right pd-0'>
                                                            <div className='ladiui btn-group'>
                                                                <div className='ladiui dropdown hide-mt ba-c'>
                                                                    <button
                                                                        data-toggle='dropdown'
                                                                        className='ladiui-btn-dropdown dropdown-toggle'
                                                                    >
                                                                        <i className='ladiui icon icon-ldp-dot'></i>
                                                                    </button>
                                                                    <ul className='ladiui dropdown-menu r-0 w-180'>
                                                                        <li onClick={() => openModalEdit(item)}>
                                                                            <a className='ladiui dropdown-item'>
                                                                                <i className='new-ldicon-edit' />
                                                                                <span> {t('ACTIONS.EDIT')} </span>
                                                                            </a>
                                                                        </li>
                                                                        <li onClick={() => openModalConfirmDelete(item.product_tag_id)}>
                                                                            <a className='ladiui dropdown-item'>
                                                                                <i className='ladiui icon-new new-ldicon-delete' />
                                                                                <span className='ladiui text-danger'>
                                                                                    {t('ACTIONS.DELETE')}
                                                                                </span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a
                                                                                className='ladiui dropdown-item'
                                                                                onClick={() => {
                                                                                    openModalReorderProduct(item);
                                                                                }}
                                                                            >
                                                                                <i className='new-ldicon-edit' />
                                                                                <span>{t('TAGS.PRODUCT_REORDER_TILE')}</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {totalRecord <= 0 && (
                                                <tr className='text-center'>
                                                    <td colSpan='100%'>{t('NO_DATA')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                            {totalRecord > 0 && (
                                <LadiPagination
                                    conditionsProp={{ page: activePage, limit: selectedLimit }}
                                    listName={t('PAGINATION.ITEMS')}
                                    message={''}
                                    items={productTagReducer.allTags || []}
                                    total={totalRecord}
                                    limitAction={onChangeLimit}
                                    pageAction={onPageChange}
                                />
                            )}
                        </div>
                    </div>
                )}

                {isShowModalTag && (
                    <Modal
                        id='modal-tag-edit'
                        title={mode == appConfig.FORM_MODE.CREATE ? t('TAGS.ADD_TAG') : t('TAGS.EDIT')}
                        visible={isShowModalTag}
                        onCancel={() => setIsShowModalTag(false)}
                        onOk={submit}
                        width={400}
                        // isLoading={isLoadingSubmit}
                        bodyStyles={mode == appConfig.FORM_MODE.CREATE ? { minHeight: '25vh' } : {}}
                    >
                        <div className='ladiui form-group'>
                            <label className='ladiui-label' style={{ marginBottom: '8px' }}>
                                {t('TAGS.COL_TAG')}
                            </label>
                            <Input
                                value={tagSelected.name}
                                onChange={(event) => {
                                    setTagSelected({
                                        ...tagSelected,
                                        name: event.target.value,
                                    });
                                }}
                                validationName={t('TAGS.COL_TAG')}
                                validations={{ isRequired: true }}
                                ref={(ref) => inputsRef.add(ref)}
                            />
                        </div>

                        <div className='ladiui form-group mb-0'>
                            <div className='text-center image setting-block-image'>
                                <div className='block-tag-image' style={{ width: '100%', height: '360px' }}>
                                    <Image src={tagSelected.image_url} />
                                </div>
                                <div className='ladiui-button-upload image block-upload-image'>
                                    <div className='block-overlay' style={{ marginTop: '130px' }}>
                                        <button type='button' className='ladiui btn btn-primary icon border-none'>
                                            <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-gallery.svg' alt='' />

                                            <p>
                                                Bấm vào đây để chọn ảnh sản phẩm <br /> từ máy tính của bạn
                                            </p>
                                        </button>
                                        <input
                                            type='file'
                                            name='file'
                                            accept='image/*'
                                            onChange={(event) => fileChangedHandler(event, 'cover')}
                                            onClick={(event) => {
                                                event.target.value = null;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )}

                <ModalReorderProductTag
                    tagSelected={tagSelected}
                    isShowModalReorder={isShowModalReorder}
                    handleListProduct={(keyword) => {}}
                    productTagReducer={productTagReducer}
                    onCancel={() => {
                        setIsShowModalReoder(false);
                    }}
                    handleSetTagSelected={async (items) => {
                        await setTagSelected({
                            ...tagSelected,
                            products: items,
                        });
                    }}
                    handleReorderProduct={() => handleReorder()}
                />

                <ConfirmModal
                    id='confirm-product-tag-delete'
                    title={'Xóa Tag?'}
                    content={'Tag sản phẩm này sẽ được xóa vĩnh viễn. Bạn có chắc muốn xóa Tag này ?'}
                    cancelText={t('ACTIONS.CANCEL')}
                    okText={t('ACTIONS.DELETE')}
                    onOk={() => dispatch(productTagActions.deleteTag(selectedID))}
                    // isLoading={isLoadingDelete}
                />
            </div>
        </div>
    );
}
