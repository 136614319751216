import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal';

import baseHelper from '../../../../helpers/BaseHelper';
import { useTranslation } from 'react-i18next';
import Input from '../../../../components/Input';
import appConfig from '../../../../config/app';

ModalMomoPay.propTypes = {
    onCancel: PropTypes.func,
    paymentGatewayConfigured: PropTypes.any,
};

export default function ModalMomoPay(props) {
    const {
        paymentGatewayConfigured,
        onCancel,
        upDateDataCofigPayment,
        connect,
        onchangeDataUpdateMomo,
        momoRef,
        momoAccount,
        onChangeMomoInput,
        dataUpdateMomo,
        setMomoMode,
        momoMode,
        logo,
    } = props;

    const { t } = useTranslation();
    return (
        <Modal
            id='modal-momo-pay'
            title={!paymentGatewayConfigured ? t('PAYMENTS.SET_UP_PAYMENT_METHOD') : t('PAYMENTS.DETAIL_PAYMENT_METHOD')}
            onCancel={onCancel}
            hasFooter={true}
            onOk={
                !paymentGatewayConfigured
                    ? () => connect(appConfig.PAYMENT_PARTNERS.MOMO.CODE)
                    : () => upDateDataCofigPayment(appConfig.PAYMENT_PARTNERS.MOMO.CODE, paymentGatewayConfigured)
            }
            visible={true}
            width={1000}
            logo={logo}
            bodyStyles={{ minHeight: '40vh' }}
        >
            <div className='content mt-0'>
                {paymentGatewayConfigured ? (
                    <React.Fragment>
                        <React.Fragment>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => momoRef.add(ref)}
                                        value={dataUpdateMomo.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(e) => onchangeDataUpdateMomo(e)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.MOMO.PARTNER_CODE')}</label>
                                    <Input value={paymentGatewayConfigured.config.partner_code} disabled={true} />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.MOMO.ACCESS_KEY')}</label>
                                    <Input value={paymentGatewayConfigured.config.access_key} disabled={true} />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.MOMO.SECRET_KEY')}</label>
                                    <Input value={paymentGatewayConfigured.config.secret_key} disabled={true} />
                                </div>

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        defaultValue={paymentGatewayConfigured.config.payment_guide}
                                        onChange={(e) => onchangeDataUpdateMomo(e)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            checked={paymentGatewayConfigured.mode === appConfig.MODE.TEST}
                                            disabled={true}
                                        />
                                        <label> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className='partner-account'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.LB_METHOD_NAME')}</label>
                                    <Input
                                        ref={(ref) => momoRef.add(ref)}
                                        value={momoAccount.method_name}
                                        validationName={t('PAYMENTS.LB_METHOD_NAME')}
                                        name='method_name'
                                        onChange={(event) => onChangeMomoInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.MOMO.PARTNER_CODE')}</label>
                                    <Input
                                        ref={(ref) => momoRef.add(ref)}
                                        name='partner_code'
                                        value={momoAccount.partner_code}
                                        validationName={t('PAYMENTS.MOMO.PARTNER_CODE')}
                                        onChange={(event) => onChangeMomoInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.MOMO.ACCESS_KEY')}</label>
                                    <Input
                                        ref={(ref) => momoRef.add(ref)}
                                        name='access_key'
                                        value={momoAccount.access_key}
                                        validationName={t('PAYMENTS.MOMO.ACCESS_KEY')}
                                        onChange={(event) => onChangeMomoInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('PAYMENTS.MOMO.SECRET_KEY')}</label>
                                    <Input
                                        ref={(ref) => momoRef.add(ref)}
                                        name='secret_key'
                                        value={momoAccount.secret_key}
                                        validationName={t('PAYMENTS.MOMO.SECRET_KEY')}
                                        onChange={(event) => onChangeMomoInput(event)}
                                        validations={{ isRequired: true }}
                                    />
                                </div>

                                <div id='data-tooltip-guide' className='ladiui form-group'>
                                    <label className='ladiui-label'>
                                        <span className='tooltip-paymet-guide'>{t('PAYMENTS.LB_PAYMENT_GUIDE')}</span>
                                        <span
                                            className='tooltip-icon'
                                            data-tooltip={t('PAYMENTS.LB_TT_PAYMENT_GUIDE')}
                                            data-tooltip-position='right'
                                        >
                                            {' '}
                                            <i className='ladi-icon icon-c-question' />
                                        </span>
                                    </label>
                                    <textarea
                                        name='payment_guide'
                                        className='ladiui textarea'
                                        value={momoAccount.payment_guide}
                                        onChange={(event) => onChangeMomoInput(event)}
                                    />
                                </div>
                                {baseHelper.isDevelopEnv() && (
                                    <div className='ladiui form-group flex'>
                                        <input
                                            id='momo_mode'
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            onChange={() => setMomoMode(!momoMode)}
                                            checked={momoMode == appConfig.MODE.TEST}
                                        />
                                        <label htmlFor='momo_mode'> {t('MODE.TEST')}</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        </Modal>
    );
}
