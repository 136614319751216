import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waiting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waiting: action.waiting,
                action: commonTypes.ASYNC_START,
            };
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waiting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            };
        }
        case types.LIST_DOMAIN: {
            return {
                ...state,
                action: types.LIST_DOMAIN,
                domains: action.status ? action.payload.domains : [],
                total_record: action.status ? action.payload.total_record : 0,
                total_page: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            };
        }
        case types.LIST_DOMAIN_STORE: {
            return {
                ...state,
                action: types.LIST_DOMAIN_STORE,
                store_domains: action.status ? action.payload.domains : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.LIST_VERIFIED_DOMAIN: {
            return {
                ...state,
                action: types.LIST_VERIFIED_DOMAIN,
                verified_domains: action.status ? action.payload.domains : [],
                status: action.status,
                message: action.message,
            };
        }
        case types.DELETE_DOMAIN: {
            return {
                ...state,
                action: types.DELETE_DOMAIN,
                status: action.status,
                message: action.message,
            };
        }
        case types.CREATE_DOMAIN: {
            return {
                ...state,
                action: types.CREATE_DOMAIN,
                domain: action.status ? action.payload.domain : {},
                status: action.status,
                message: action.message,
            };
        }
        case types.RELOAD_DOMAIN: {
            return {
                ...state,
                action: types.RELOAD_DOMAIN,
                domain: action.status ? action.payload.domain : {},
                status: action.status,
                message: action.message,
            };
        }
        case types.VERIFY_DOMAIN: {
            return {
                ...state,
                action: types.VERIFY_DOMAIN,
                status: action.status,
                message: action.message,
            };
        }
        case types.MARK_DEFAULT_DOMAIN: {
            return {
                ...state,
                action: types.MARK_DEFAULT_DOMAIN,
                status: action.status,
                message: action.message,
            };
        }
        case types.REMOVE_DEFAULT_DOMAIN: {
            return {
                ...state,
                action: types.REMOVE_DEFAULT_DOMAIN,
                status: action.status,
                message: action.message,
            };
        }
        default:
            return state;
    }
};
