import { Component } from "react";
import appConfig from "../../../config/app";

// Màn hình preview giỏ hàng trống
class FormButtonTemplate extends Component {
  renderData(content) {
    let CDN = "https://w.ladicdn.com/";
    let titleCart = "Giỏ hàng của bạn";
    if (content && content.title != "") {
      titleCart = content.title;
    }

    return `<div class="block-show-button">
        <h1>Show custom button</h1>
      </div>`;
  }
}

export default new FormButtonTemplate();
