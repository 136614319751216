import { REQUEST_METHOD } from "../../middlewares/services";

import * as types from "./types";
import { endpoint } from "../../../config/app";

const list = (data) => ({
  type: types.LIST_CATEGORY,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORY_LIST,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const create = (data) => ({
  type: types.CREATE_CATEGORY,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORY_CREATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const listSelectCategory = (data) => ({
  type: types.LIST_SELECT_CATEGORY,
  meta: {
    endpoint: endpoint.LIST_SELECT_CATEGORY,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const update = (data) => ({
  type: types.UPDATE_CATEGORY,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORY_UPDATE,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const updatePosition = (data) => ({
  type: types.UPDATE_POSITION,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORY_UPDATE_POSITION,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const show = (data) => ({
  type: types.SHOW_CATEGORY,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORY_SHOW,
    method: REQUEST_METHOD.POST,
    body: data,
    hasAuth: true,
  },
});

const search = (name, type) => ({
  type: types.SEARCH_CATEGORY,
  meta: {
    endpoint: endpoint.SEARCH_CATEGORY,
    method: REQUEST_METHOD.POST,
    body: {
      keyword: name,
      search: {
        type: type
      }
    },
    hasAuth: true,
  },
});

const deleteCategory = (data) => ({
  type: types.DELETE_CATEGORY,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORY_DELETE,
    method: REQUEST_METHOD.POST,
    body: {
      product_category_id: data,
    },
    hasAuth: true,
  },
});

const deleteCategories = (data) => ({
  type: types.DELETE_CATEGORIES,
  meta: {
    endpoint: endpoint.PRODUCT_CATEGORIES_DELETE,
    method: REQUEST_METHOD.POST,
    body: {
      product_category_ids: data,
    },
    hasAuth: true,
  },
});

export default {
  list,
  create,
  listSelectCategory,
  update,
  show,
  search,
  deleteCategory,
  deleteCategories,
  updatePosition,
};
