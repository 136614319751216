import React from 'react';
import { withTranslation } from 'react-i18next';
import { cloneDeep, find, map } from 'lodash';

import appConfig from '../../../config/app';

import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';

class ModalShippingStatus extends React.Component {
    static propTypes = {
        shippingID: PropTypes.number,
        statusCode: PropTypes.string,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        visible: PropTypes.bool,
        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            statusCode: props.statusCode,
        };
    }

    componentDidMount() {
        this.setState({
            statusCode: cloneDeep(this.props.statusCode),
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props == nextProps) {
            return;
        }

        if (this.props.statusCode != nextProps.statusCode) {
            this.setState({
                statusCode: cloneDeep(nextProps.statusCode),
            });
        }
    }

    getNameByCode = (code) => {
        const matched = find(appConfig.SHIPPING_STATUS, (item) => item.CODE == code) || {};
        return matched.NAME;
    };

    onChangeInput = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    };

    onChangeStatus = (statusCode) => {
        this.setState({
            statusCode,
        });
    };

    submit = () => {
        this.props.onSubmit(this.props.shippingID, this.state.statusCode);
    };

    render() {
        const { t } = this.props;
        const { statusCode } = this.state;

        const isCancel = this.props.statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE;

        return (
            <Modal
                id='modal-shipping-statusCode'
                title={t('SHIPPINGS.SHIPPING_STATUS_UPDATE_TITLE')}
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.submit}
                isLoading={this.props.isLoading}
                width={400}
                bodyClassName='unset-modal'
                disableOk={isCancel}
            >
                <div className='ladiui data-status'>
                    {/* <div className="ladiui-select-options" style={{ minWidth: 180 }}>
                        <span className="ladiui-select-init abc" disabled={this.props.statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE}>{this.getNameByCode(statusCode)}</span>
                        <input type="hidden" name="select" defaultValue />
                        <ul>
                            {
                                map(appConfig.SHIPPING_STATUS, option => {
                                    if (option != appConfig.SHIPPING_STATUS.PENDING) {
                                        return <li key={option.CODE} className="select-item" onClick={() => this.onChangeStatus(option.CODE)}><a data-value={option.CODE}>{option.NAME}</a></li>
                                    }
                                })
                            }
                        </ul>
                    </div> */}

                    <div className='ladiui btn-group w100'>
                        <div className='ladiui dropdown w100'>
                            <button
                                style={{ minWidth: '100%' }}
                                className='ladiui btn btn-outline-light dropdown-toggle'
                                disabled={this.props.statusCode == appConfig.SHIPPING_STATUS.CANCELED.CODE}
                                data-toggle='dropdown'
                            >
                                {this.getNameByCode(statusCode)}
                            </button>
                            <ul className='ladiui dropdown-menu w100'>
                                {map(appConfig.SHIPPING_STATUS, (option) => {
                                    if (option != appConfig.SHIPPING_STATUS.PENDING) {
                                        return (
                                            <li key={option.CODE} onClick={() => this.onChangeStatus(option.CODE)}>
                                                <a class='ladiui dropdown-item' href='#'>
                                                    {option.NAME}
                                                </a>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default withTranslation()(ModalShippingStatus);
